import { Button, IconButton } from '@mui/material';
import React, { useState, useEffect  } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from 'react-router-dom';
import Breadcrum from '../../../../../../parts/breadcrum';
import SectionTitle from "../../../../../../components/sectionTitle";
import { REPORTS_TYPE } from '../../../../../../utils/codesReportsTypes';
import { useSelector } from 'react-redux';
import { getCurrentDocument, getReports } from '../../../../../../parts/document/currentDocumentSlice';
import { signatoriesTypes } from '../../../../../../utils/typesSignatures';
import ReactHtmlParser from "react-html-parser";
import { NUMBERINGWORDSO } from '../../../../../../utils/numberings';

export const ViewContract = () => {
    const navigate = useNavigate();
    const currentDocument = useSelector(getCurrentDocument);
    const reportsData = useSelector(getReports);
    const [filteredClauses, setFilteredClauses] = useState([]);
    const [isShowPanel, setIsShowPanel] = useState(true);
    const [isShowPanelMilestones, setIsShowPanelMilestones] = useState(false);
    const [panelSelected, setPanelSelected] = useState({ title: "", subtitle: "" });
    const [typeReportSelected, setTypeReportSelected] = useState("");
    const handleShowMilestones = (item) => {
        setTypeReportSelected(item.title);
        setPanelSelected(item);
        setIsShowPanelMilestones(true);
        setIsShowPanel(false);
    };
    const handleShowPanel = () => {
        setIsShowPanelMilestones(false);
        setIsShowPanel(true);
    };
    const flashItemSelected = (idItem) => {
        let item = document.getElementById(idItem);
        const keyframes = [{ backgroundColor: '#FFC4C0' }, { backgroundColor: '#FFFFFF' }];
        const options = { duration: 1200, iterations: 1 };
        if (item) item.animate(keyframes, options);
    };
    useEffect(() => {
        setFilteredClauses(currentDocument.clauses);
    }, [currentDocument]);
    useEffect(() => {
        if (reportsData?.length <= 0) {
            navigate("/service/folders")
        }
    }, [reportsData]);
    useEffect(() => {
        setTimeout(() => {
            if (window.location.hash) {
                window.location.href = window.location.hash;
                flashItemSelected(window.location.hash.slice(1));
            }
        }, 100)
    }, []);
    const typeUsers = {
        natural: {
            M: signatoriesTypes.SignatariOPN.type,
            F: signatoriesTypes.SignatariAPN.type,
            Femenino: signatoriesTypes.SignatariAPN.type,
            Masculino: signatoriesTypes.SignatariOPN.type,
        },
        legal: {
            M: signatoriesTypes.SignatariOPJ.type,
            F: signatoriesTypes.SignatariAPJ.type,
            Femenino: signatoriesTypes.SignatariAPJ.type,
            Masculino: signatoriesTypes.SignatariOPJ.type,
        },
        getText({type = 'natural', gender = 'M', text = '', index = 0}){
            return `Signatario ${index + 1} (${this[type][gender]}), ${text}`
        }
    }
    return (
        <Container fluid>
            <Row>
                <Breadcrum />
            </Row>
            <Row xs={"auto"}>
                <SectionTitle title="Ver en contrato" />
            </Row>
            <br />
            <Row>
                <p className="caption">
                    Navega entre las entidades accediendo desde el menú.
                </p>
            </Row>
            <br />
            <Row >
                <Col id="panel-navigation" xs={4} className="custom-accordion__container-headers" style={{ backgroundColor: '#F6F8FC', padding: "8px" }}>
                    {isShowPanelMilestones && (<>
                        <Row style={{ alignItems: "center" }}>
                            <Col xs={"auto"}>
                                <IconButton
                                    onClick={handleShowPanel}
                                    className="custom-input__button__secondary-color navigate-link"
                                    sx={{
                                        border: "1px solid black",
                                        padding: "2px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <ArrowBackOutlinedIcon fontSize="large" />
                                </IconButton>
                            </Col>
                            <Col>
                                <p className="heading__primary-color">{panelSelected.title}</p>
                            </Col>
                        </Row>
                        <br />
                        {reportsData?.length > 0 && reportsData.map((item, index) =>
                            typeReportSelected === item.typeReport &&
                            typeReportSelected !== REPORTS_TYPE.SUMMARY.name && 
                            typeReportSelected !== REPORTS_TYPE.POLICIES.name && 
                            typeReportSelected !== REPORTS_TYPE.OBLIGATIONS.name &&(
                                <div key={item?.typeReport}>
                                    {item.reports.map((item, index) => (
                                        <div key={item?.typeReport}>
                                            <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                                <Col>
                                                    <p className="heading__primary-color">{`${index + 1}. ${item.type}`}</p>
                                                </Col>
                                            </Row>
                                            {item.titlesClauses.map( titles => (
                                                <>
                                                    <Row style={{ height: "10%", alignItems: "center" }}>
                                                        <Col>
                                                            <span className="subheading">
                                                                {"Nombre de la cláusula:  "}
                                                                <span
                                                                    className="caption"
                                                                >
                                                                    {`${titles.titleClause || ""}`}
                                                                </span>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <span className="subheading">
                                                            {`${panelSelected.subtitle}:  `}
                                                        </span>
                                                    </Row>
                                                    <br />
                                                    {titles.keysPhrases.map( ({ reports }) => (<>
                                                        {reports.map( report => (<>
                                                            <Row style={{ height: "10%", alignItems: "center" }}>
                                                                <Col>
                                                                    <span
                                                                        className="caption"
                                                                    >
                                                                        - {report.text}
                                                                    </span>
                                                                </Col>
                                                                <Col xs={"auto"}>
                                                                    <IconButton
                                                                        href={`#${report.id_text}`}
                                                                        onClick={() => flashItemSelected(report.id_text)}
                                                                        className="custom-input__button__secondary-color navigate-link"
                                                                        sx={{
                                                                            border: "1px solid black",
                                                                            padding: "2px",
                                                                            borderRadius: "5px",
                                                                        }}
                                                                    >
                                                                        <RemoveRedEyeOutlinedIcon fontSize="large" />
                                                                    </IconButton>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </>))}
                                                    </>))}
                                                    <Row style={{ borderBottom: "2px dashed gray" }} />
                                                    <br />
                                                    
                                                </>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        {reportsData?.length > 0 && reportsData.map((item, index) =>
                            typeReportSelected === item.typeReport &&
                            typeReportSelected === REPORTS_TYPE.SUMMARY.name && (
                                <div key={item?.typeReport}>
                                    {item.reports && (
                                        <div key={item?.typeReport}>
                                            <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                                <Col>
                                                    <p className="heading__primary-color">{`${index + 1}. Objeto del contrato`}</p>
                                                </Col>
                                                <Col xs={"auto"}>
                                                    <IconButton
                                                        href={`#${item?.reports?.type_object?.id_text}`}
                                                        onClick={() => flashItemSelected(item?.reports?.type_object?.id_text)}
                                                        className="custom-input__button__secondary-color navigate-link"
                                                        sx={{
                                                            border: "1px solid black",
                                                            padding: "2px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <RemoveRedEyeOutlinedIcon fontSize="large" />
                                                    </IconButton>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <span className="subheading">
                                                    {"Nombre de la cláusula:  "}
                                                    <span
                                                        className="caption"
                                                    >
                                                        {`${item?.reports?.type_object?.title || ''}`}
                                                    </span>
                                                </span>
                                            </Row>
                                            <br />
                                            <Row style={{ borderBottom: "2px dashed gray" }} />
                                            <br />
                                            <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                                <Col>
                                                    <p className="heading__primary-color">{`2. Valor del contrato`}</p>
                                                </Col>
                                                <Col xs={"auto"}>
                                                    <IconButton
                                                        href={`#${item?.reports?.type_clause_value?.id_text}`}
                                                        onClick={() => flashItemSelected(item?.reports?.type_clause_value?.id_text)}
                                                        className="custom-input__button__secondary-color navigate-link"
                                                        sx={{
                                                            border: "1px solid black",
                                                            padding: "2px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <RemoveRedEyeOutlinedIcon fontSize="large" />
                                                    </IconButton>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <span className="subheading">
                                                    {"Nombre de la cláusula:  "}
                                                    <span
                                                        className="caption"
                                                    >
                                                        {`${item?.reports?.type_clause_value?.titleClause || ''}`}
                                                    </span>
                                                </span>
                                            </Row>
                                            <br />
                                            <Row>
                                                <span className="subheading">
                                                    {`Texto de la cláusula:  `}
                                                    <span
                                                        className="caption"
                                                    >
                                                        {item?.reports?.type_clause_value?.keyPhrase}
                                                    </span>
                                                </span>
                                            </Row>
                                            <br />
                                            <Row style={{ borderBottom: "2px dashed gray" }} />
                                            <br />
                                            <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                                <Col>
                                                    <p className="heading__primary-color">{`3. Termino de duración`}</p>
                                                </Col>
                                                <Col xs={"auto"}>
                                                    <IconButton
                                                        href={`#${item?.reports?.type_clause_milestone?.duration?.id_text}`}
                                                        onClick={() => flashItemSelected(item?.reports?.type_clause_milestone?.duration?.id_text)}
                                                        className="custom-input__button__secondary-color navigate-link"
                                                        sx={{
                                                            border: "1px solid black",
                                                            padding: "2px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <RemoveRedEyeOutlinedIcon fontSize="large" />
                                                    </IconButton>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <span className="subheading">
                                                    {"Nombre de la cláusula:  "}
                                                    <span
                                                        className="caption"
                                                    >
                                                        {`${item?.reports?.type_clause_milestone?.duration?.titleClause || ''}`}
                                                    </span>
                                                </span>
                                            </Row>
                                            <br />
                                            <Row>
                                                <span className="subheading">
                                                    {`Texto de la cláusula:  `}
                                                    <span
                                                        className="caption"
                                                    >
                                                        {item?.reports?.type_clause_milestone?.duration?.keyPhrase}
                                                    </span>
                                                </span>
                                            </Row>
                                            <br />
                                            <Row style={{ borderBottom: "2px dashed gray" }} />
                                            <br />
                                            {item?.reports?.type_clause_milestone?.termination?.length &&
                                                item?.reports?.type_clause_milestone?.termination.map((item, index) => (
                                                    <div key={item?._id}>
                                                        <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                                            <Col>
                                                                <p className="heading__primary-color">{`4.${index + 1}. Renovación y terminación`}</p>
                                                            </Col>
                                                            <Col xs={"auto"}>
                                                                <IconButton
                                                                    href={`#${item?.id_text}`}
                                                                    onClick={() => flashItemSelected(item?.id_text)}
                                                                    className="custom-input__button__secondary-color navigate-link"
                                                                    sx={{
                                                                        border: "1px solid black",
                                                                        padding: "2px",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                >
                                                                    <RemoveRedEyeOutlinedIcon fontSize="large" />
                                                                </IconButton>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <span className="subheading">
                                                                {"Nombre de la cláusula:  "}
                                                                <span
                                                                    className="caption"
                                                                >
                                                                    {`${item?.titleClause || ''}`}
                                                                </span>
                                                            </span>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <span className="subheading">
                                                                {`Texto de la cláusula:  `}
                                                                <span
                                                                    className="caption"
                                                                >
                                                                    {item?.keyPhrase}
                                                                </span>
                                                            </span>
                                                        </Row>
                                                        <br />
                                                        <Row style={{ borderBottom: "2px dashed gray" }} />
                                                        <br />
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                    <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                        <Col>
                                            <p className="heading__primary-color">{`5. Ley aplicable al contrato`}</p>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <IconButton
                                                href={`#${item?.reports?.type_applicable_law?.id_text}`}
                                                onClick={() => flashItemSelected(item?.reports?.type_applicable_law?.id_text)}
                                                className="custom-input__button__secondary-color navigate-link"
                                                sx={{
                                                    border: "1px solid black",
                                                    padding: "2px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <RemoveRedEyeOutlinedIcon fontSize="large" />
                                            </IconButton>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <span className="subheading">
                                            {"Nombre de la cláusula:  "}
                                            <span
                                                className="caption"
                                            >
                                                {`${item?.reports?.type_applicable_law?.titleClause || ''}`}
                                            </span>
                                        </span>
                                    </Row>
                                    <br />
                                    <Row>
                                        <span className="subheading">
                                            {`Texto de la cláusula:  `}
                                            <span
                                                className="caption"
                                            >
                                                {item?.reports?.type_applicable_law?.text}
                                            </span>
                                        </span>
                                    </Row>
                                    <br />
                                    <Row style={{ borderBottom: "2px dashed gray" }} />
                                    <br />
                                </div>
                            ))}
                        {reportsData?.length > 0 && reportsData.map((item, index) => {

                            if (
                                typeReportSelected === item.typeReport &&
                                REPORTS_TYPE.POLICIES.name === typeReportSelected
                            ) {
                                return (
                                    <div key={item?.typeReport}>
                                        {item.reports.map((item, index) => (
                                            <div key={item.id_text}>
                                                <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                                                    <Col>
                                                        <p className="heading__primary-color">{`${index + 1}. ${item.name}`}</p>
                                                    </Col>
                                                    
                                                    <Col xs={"auto"}>
                                                        <IconButton
                                                            href={`#${item.id_text}.0.0`}
                                                            onClick={() => flashItemSelected(`${item.id_text}.0.0`)}
                                                            className="custom-input__button__secondary-color navigate-link"
                                                            sx={{
                                                                border: "1px solid black",
                                                                padding: "2px",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            <RemoveRedEyeOutlinedIcon fontSize="large" />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row style={{ height: "10%", alignItems: "center" }}>
                                                    <span className="subheading">
                                                        {"Valor:  "}
                                                        <span
                                                            className="caption"
                                                        >
                                                            {item?.value || ""}
                                                        </span>
                                                    </span>
                                                </Row>
                                                <br />
                                                <Row style={{ height: "10%", alignItems: "center" }}>
                                                    <span className="subheading">
                                                        {"Duración:  "}
                                                        <span
                                                            className="caption"
                                                        >
                                                            {item?.duration || ""}
                                                        </span>
                                                    </span>
                                                </Row>
                                                <br />
                                            </div>
                                        ))}
                                    </div>
                                );
                            } else if (
                                typeReportSelected === item.typeReport &&
                                REPORTS_TYPE.OBLIGATIONS.name === typeReportSelected
                            ) {
                                return (
                                    <ModuleReportObligations 
                                        key={item?.typeReport} 
                                        itemReport={item} 
                                        handleFlashItem={flashItemSelected}
                                    />
                                );
                            }
                        })}
                    </>)}
                    {isShowPanel && (<>
                        {reportsData?.length > 0 && reportsData?.map((item, index) => {
                            return (<Row key={item?.typeReport} style={{ height: "15%", borderBottom: "1px solid gray", alignItems: "center" }}> {/* //dashed */}
                                <Col>
                                    <p className="heading__primary-color">{item.typeReport}</p>
                                </Col>
                                {item.typeReport !== REPORTS_TYPE.PARTS.name && (
                                    <Col xs={"auto"}>
                                        <IconButton
                                            onClick={() => handleShowMilestones({
                                                title: item.typeReport,
                                                subtitle: item.typeReport === REPORTS_TYPE.MILESTONES_AND_DATES.name ? "Hitos" : "Valores"
                                            })}
                                            className="custom-input__button__primary-color navigate-link"
                                            sx={{
                                                color: "white",
                                                padding: "2px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <ArrowForwardOutlinedIcon fontSize="large" />
                                        </IconButton>
                                    </Col>
                                )}
                            </Row>)
                        })}
                    </>)}
                </Col>
                <Col xs={8} style={{ borderTop: "2px solid gray" }} className="custom-accordion__container-headers">
                    <Container fluid>
                        <Row className="heading__primary-color">Partes</Row>
                        <Row>
                            {currentDocument?.parts?.length > 0 &&
                                currentDocument.parts.map((part, index) => (
                                    <Container key={part?._id} className="container_text">
                                        <Row>
                                            <Col className="heading__primary-color">{`${index + 1}. ${part?.name || "Sin titulo"
                                                }`}</Col>
                                        </Row>
                                        <Row className="container_text__subclause">
                                            <ul>
                                                {part?.signatories?.length > 0 ? (
                                                    part.signatories.map((sig, i) => {
                                                        return (
                                                        <li key={sig?._id} className="label">
                                                            {typeUsers.getText({gender: sig.gender, type: sig.type, index: i, text: sig.name})}
                                                        </li>
                                                    )})
                                                ) : (
                                                    <li className="label">Sin signatarios</li>
                                                )}
                                            </ul>
                                        </Row>
                                        <Row className="container_text__subclause label">
                                            {ReactHtmlParser(part?.text || "Sin texto final")}
                                        </Row>
                                    </Container>
                                ))}
                        </Row>
                        <Row className="heading__primary-color">Encabezados</Row>
                        <Row>
                            {currentDocument?.headers?.length > 0 ? (
                                currentDocument.headers.map((header, index) => (
                                    <Container key={header?._id} className="container_text">
                                        <Row>
                                            <Col className="heading__primary-color">{`${index + 1}. ${header?.title || "Sin titulo"
                                                }`}</Col>
                                        </Row>
                                        <Row className="container_text__clause-text label">
                                            {ReactHtmlParser(header?.text || "")}
                                        </Row>
                                        <Row className="container_text__clause-text">
                                            <ul>
                                                {header?.childs?.length > 0 &&
                                                    header.childs.map((child, index2) => (
                                                        <Container
                                                            key={child?._id}
                                                            className="container_text__subclause"
                                                        >
                                                            <Row>
                                                                <Col className="heading__primary-color">{`${index + 1
                                                                    }.${index2 + 1}`}</Col>
                                                            </Row>
                                                            <Row className="container_text__clause-text label">
                                                                {ReactHtmlParser(child?.text || "")}
                                                            </Row>
                                                        </Container>
                                                    ))}
                                            </ul>
                                        </Row>
                                    </Container>
                                ))
                            ) : (
                                <p className="container_text__clause-text label">
                                    Sin encabezados.
                                </p>
                            )}
                        </Row>
                        <Row className="heading__primary-color">Cláusulas</Row>
                        <Row>
                            {filteredClauses?.length > 0 ? (
                                filteredClauses.map((clause, index) => {
                                    const subclauses = clause?.clause?.subclauses || clause?.subclauses || "";
                                    const paragraphs = clause?.clause?.paragraphs || clause?.paragraphs || "";
                                    return (
                                        <Row key={clause?._id}>
                                            <Container className="container_text" id={`${index + 1}.0.0`}>
                                                <Row>
                                                    <Col md={4} className="heading__primary-color">{`${index + 1}. ${clause?.title || clause?.clause?.title || "Sin titulo"}`}</Col>
                                                </Row>
                                                <Row className="container_text__clause-text label">
                                                    {ReactHtmlParser(clause?.text || clause?.clause?.text || "")}
                                                </Row>
                                            </Container>
                                            {subclauses?.length > 0 &&
                                                subclauses?.map((sub, index2) => (
                                                    <Container
                                                        key={sub?._id}
                                                        className="container_text__subclause"
                                                        id={`${index}.${index2 + 1}.0`}
                                                    >
                                                        <Row style={{ alignItems: "center" }}>
                                                            <Col xs={"auto"} className="heading__primary-color">
                                                                {`${index + 1}.${index2 + 1}. ${sub?.subclause?.title || ""}`}
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ alignItems: "center" }}>
                                                            <Col className="container_text__clause-text label">
                                                                {ReactHtmlParser(sub?.subclause?.text || "")}
                                                            </Col>
                                                        </Row>
                                                        <Row className="container_text__clause-text label">

                                                        </Row>
                                                        {sub?.subclause?.childs.length > 0 &&
                                                            sub?.subclause?.childs?.map((child, index4) => (
                                                                <Container
                                                                    key={child?._id}
                                                                    className="container_text__subclause"
                                                                >
                                                                    <Row>
                                                                        <Col className="heading__primary-color">{`${index + 1
                                                                            }.${index2 + 1}.${index4 + 1}. ${child?.title || ""
                                                                            }`}</Col>
                                                                    </Row>
                                                                    <Row className="container_text__clause-text label">
                                                                        {ReactHtmlParser(child?.text || "")}
                                                                    </Row>
                                                                </Container>
                                                            ))}
                                                    </Container>
                                                ))}
                                            {paragraphs?.length > 0 &&
                                                paragraphs?.map((para, index3) => (
                                                    <Container
                                                        key={"para" + para?._id}
                                                        className="container_text__subclause"
                                                        id={`${index}.0.${index3 + 1}`}
                                                    >
                                                        <Row>
                                                            <Col className="heading__primary-color">{`Parágrafo ${NUMBERINGWORDSO[index3 + 1]
                                                                } ${para?.paragraph?.title || ""}`}</Col>
                                                        </Row>
                                                        <Row className="container_text__clause-text label">
                                                            {ReactHtmlParser(para?.paragraph?.text || "")}
                                                        </Row>
                                                    </Container>
                                                ))}
                                        </Row>
                                    );
                                })
                            ) : (
                                <p className="container_text__clause-text label">
                                    Sin cláusulas.
                                </p>
                            )}
                        </Row>
                        <Row className="heading__primary-color">Firmas</Row>
                        <Row>
                            {currentDocument?.parts?.length > 0 &&
                                currentDocument.parts.map((part, index) => (
                                    <Container key={"part" + part?._id} className="container_text">
                                        <Row>
                                            <Col className="heading__primary-color">{`${index + 1
                                                }. Firma ${part.name || "sin titulo"}`}</Col>
                                        </Row>
                                        <Row className="container_text__subclause">
                                            <ul>
                                                {part?.signatories?.length > 0 ? (
                                                    part?.signatories?.map((sig, i) =>
                                                        sig?.signatures?.attributes.map((attri, index) => (
                                                            <li key={attri?._id} className="label">
                                                                {attri.name + " " + attri.value}
                                                            </li>
                                                        ))
                                                    )
                                                ) : (
                                                    <li className="label">Sin firmas</li>
                                                )}
                                            </ul>
                                        </Row>
                                    </Container>
                                ))}
                        </Row>
                    </Container>
                </Col>
            </Row>
            <br />
            <br />
            <Row xs={"auto"} style={{ justifyContent: "end" }}>
                <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                        navigate(`./../`)
                    }}
                    className={"custom-input__button__secondary-color"}
                >
                    <p className="button">Regresar</p>
                </Button>
            </Row>
        </Container>
    );
};

const ModuleReportObligations = ({itemReport, handleFlashItem}) => {
    return (
        <div key={itemReport?.typeReport}>
            {console.log(itemReport.reports)}
            {itemReport.reports.map((item, index) => (
                <div key={item.id_text}>
                    <Row style={{ height: "10%", alignItems: "center" }}> {/* //dashed */}
                        <Col>
                            <p className="heading__primary-color">{`${index + 1}.`}</p>
                        </Col>
                        
                        <Col xs={"auto"}>
                            <IconButton
                                href={`#${item.id_text}.0.0`}
                                onClick={() => handleFlashItem(`${item.id_text}.0.0`)}
                                className="custom-input__button__secondary-color navigate-link"
                                sx={{
                                    border: "1px solid black",
                                    padding: "2px",
                                    borderRadius: "5px",
                                }}
                            >
                                <RemoveRedEyeOutlinedIcon fontSize="large" />
                            </IconButton>
                        </Col>
                    </Row>
                    <br />
                    {item?.obligations?.length > 0 && 
                        item?.obligations?.map(obligation => (<div key={obligation?.id_text}>
                            <Row style={{ height: "10%", alignItems: "center" }}>
                                <span className="subheading">
                                    {"Deudor:  "}
                                    <span
                                        className="caption"
                                    >
                                        {obligation?.responsible || ""}
                                    </span>
                                </span>
                            </Row>
                            <br />
                            <Row style={{ height: "10%", alignItems: "center" }}>
                                <span className="subheading">
                                    {"Obligación:  "}
                                    <span
                                        className="caption"
                                    >
                                        {obligation?.content || ""}
                                    </span>
                                </span>
                            </Row>
                            <br />
                            <Row style={{ height: "10%", alignItems: "center" }}>
                                <span className="subheading">
                                    {"Plazo:  "}
                                    <span
                                        className="caption"
                                    >
                                        {obligation?.duration || ""}
                                    </span>
                                </span>
                            </Row>
                            <br />
                            <Row style={{ height: "10%", alignItems: "center" }}>
                                <span className="subheading">
                                    {"Incumplimiento:  "}
                                    <span
                                        className="caption"
                                    >
                                        {obligation?.consequence || ""}
                                    </span>
                                </span>
                            </Row>
                            <br />
                            <Row style={{ borderBottom: "2px dashed gray" }} />
                            <br />
                        </div>
                        ))}
                    <br />
                </div>
            ))}
        </div>
    );
}