import React from "react";
import { Col, Row } from "react-bootstrap";
export default function ProgressBar({
  used,
  capacity,
  colorProgress,
  colorBackground,
}) {
  if (used.percentage > capacity)
    return <>Error, capacidad debe ser mayor que cantidad usada.</>;

  const percentage = (Number(used.percentage) * 100) / Number(capacity);
  return (
    <div style={{ height: "inherit" }}>
      <Row className="align-items-center">
        <Col xs={6}>
          <span className="label__primary-color">{` ${used.percentage}% Completado - ${used.countBlank}/${used.totalBlank} espacios`}</span>
        </Col>
        <Col xs={6}>
          <div
            style={{
              background: colorBackground || "#eeeeee",
              borderRadius: "6px",
              height: 12,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: 12,
                transform: `translateX(${percentage - 100}%)`,
                background: colorProgress || "#00374F",
              }}
            ></div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
