import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import LoadingContent from "../../../../../../components/loadingContent";
import { SectionMultipleTitle } from "../../../../../../components/sectionMultipleTitle";
import { ConsultChat } from "./ConsultChat";
import HistoryConsults from "./historyConsults";

export const Consults = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const handleAction = (data) => {
    // data => info about search recent

    setTab(data.tab);
  };
  const options = [
    {
      label: "Conceptos",
      value: 0,
      content: <HistoryConsults action={handleAction} />,
    },
    // { label: "Búsqueda", value: 1, content: <ConsultChat tab={tab} /> },
  ];
  return (
    <>
      <Container fluid>
        {isLoading && <LoadingContent />}
        <Row>
          <Breadcrum />
        </Row>
        <Row>
          <Col xs={"auto"}>
            <SectionMultipleTitle options={options} setTab={setTab} tab={tab} />
          </Col>
        </Row>
      </Container>
      <Row className="p-3">{options[tab].content}</Row>
    </>
  );
};
