import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ClearIcon from "@mui/icons-material/Clear";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import ModalAssociateVersion from "../../../../../../components/Modals/modalAssociateVersion";
import { segmentDocument } from "../../../../../../services/documents/documentsRequest";
import {
  addVersion,
  createNewVersion,
} from "../../../../../../services/versions/versionRequest";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import { CODES } from "../../../../../../utils/codes";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import { useDispatch, useSelector } from "react-redux";
import {
  getFolders,
  getStatusFolders,
  fetchAllFolders,
} from "../../../../../../parts/folders/foldersSlice";

const UploadDocument = () => {
  //States
  const [documentData, setDocumentData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [versionSelected, setVersionSelected] = useState({});
  const [responseData, setResponseData] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Hooks
  const { upload, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schema =
    upload === "uploadVersion"
      ? yup.object().shape({
          owner: yup.string().required("*Este campo es obligatorio"),
        })
      : yup.object().shape({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);

  //handles
  const getUpload = () => {
    if (upload === "uploadWarranty") {
      return codesDocumentType.Warranty._id;
    } else if (upload === "uploadAttachments") {
      return codesDocumentType.Attachments._id;
    } else if (upload === "uploadMinutes") {
      return codesDocumentType.Minutes._id;
    } else if (upload === "uploadCommunication") {
      return codesDocumentType.Communication._id;
    } else if (upload === "uploadOther") {
      return codesDocumentType.Other._id;
    } else if (upload === "uploadSigned") {
      return codesDocumentType.contractSigned._id;
    } else if (upload === "uploadVersion") {
      return codesDocumentType.contract._id;
    } else {
      return "mal";
    }
  };
  const getUploadName = (upload) => {
    if (upload === "uploadWarranty") {
      return codesDocumentType.Warranty.name;
    } else if (upload === "uploadAttachments") {
      return codesDocumentType.Attachments.name;
    } else if (upload === "uploadMinutes") {
      return codesDocumentType.Minutes.name;
    } else if (upload === "uploadCommunication") {
      return codesDocumentType.Communication.name;
    } else if (upload === "uploadOther") {
      return codesDocumentType.Other.name;
    } else if (upload === "uploadSigned") {
      return codesDocumentType.contractSigned.name;
    } else {
      return "Versión del documento";
    }
  };

  const createVersionNew = async ({
    versionSelected,
    documentData,
    folderAssociated,
  }) => {
    try {
      setIsLoading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let version = {};
      if (versionSelected.fileType === codesDocumentType.contract._id) {
        version = await createNewVersion({
          name: documentData.name,
          fileType: getUpload(),
          documentContent: { name: documentData.name },
          file: documentData,
          contractAssociated: versionSelected._id,
          folderAssociated,
          versionFrom: "FOLDER",
          corporateUnit,
        });
      } else {
        version = await createNewVersion({
          name: documentData.name,
          fileType: getUpload(),
          documentContent: { name: documentData.name },
          file: documentData,
          signContractAssociated: versionSelected._id,
          folderAssociated,
          versionFrom: "FOLDER",
          corporateUnit,
        });
      }
      if (version.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseData({
          data: version,
          title: "Sin permisos suficientes",
        });
        setResponseModal(true);
      } else if (version.status === CODES.COD_RESPONSE_HTTP_ERROR) {
        setResponseData({
          data: version,
          title: "Error",
        });
        setResponseModal(true);
      } else if (
        version.data.success &&
        version.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createVersionUpload = async ({
    documentData,
    versionSelected,
    folderAssociated,
    data,
  }) => {
    try {
      setIsLoading(true);
      const payloadToken = JSON.parse(localStorage.payloadToken);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const segmentDoc = await segmentDocument({
        file: documentData,
        company: payloadToken.company,
      });
      const objVersion = {
        name: documentData.name,
        fileType: codesDocumentType.contract._id,
        documentContent: {
          ...segmentDoc.data.responseMessage,
          author: data.owner,
        },
        file: documentData,
      };
      let version = {};

      if (versionSelected?._id) {
        version = await addVersion({
          ...objVersion,
          _id: versionSelected._id,
        });
      } else {
        version = await createNewVersion({
          ...objVersion,
          folderAssociated,
          versionFrom: "FOLDER",
          corporateUnit,
        });
      }
      if (
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_ERROR ||
        version.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        version.status === CODES.COD_RESPONSE_HTTP_ERROR
      ) {
        setResponseData({ data: { ...version } });
        setResponseModal(true);
      } else if (
        segmentDoc.data.success &&
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        version.data.success &&
        (version.status === CODES.COD_RESPONSE_HTTP_OK ||
          version.status === CODES.COD_RESPONSE_HTTP_CREATED)
      ) {
        setResponseData({
          data: {
            ...version,
            data: { responseMessage: "Se creo con exito" },
          },
        });
        setResponseModal(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequest = async (data) => {
    if (!documentData.name) return;
    if (upload === "uploadVersion") {
      await createVersionUpload({
        documentData,
        versionSelected,
        folderAssociated: id,
        data,
      });
    } else {
      await createVersionNew({
        versionSelected,
        documentData,
        folderAssociated: id,
      });
    }
  };

  useEffect(() => {
    if (foldersStatus === "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [dispatch, foldersStatus]);

  return (
    <Container fluid style={{ height: "100%" }}>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={getUploadName(upload)} />
      </Row>
      <Row className="heading" style={{ marginTop: "2%" }}>
        Cargue un documento en formato tipo pdf, docx o doc
      </Row>
      <Row style={{ padding: "2% 0" }}>
        <Col md={3}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__secondary-color"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Cargar documento
          </Button>
        </Col>
        {documentData.name && (
          <Col xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
            <span className="caption custom-badges__disabled">
              {`${documentData.name} `}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => {
                  setDocumentData({});
                }}
              />
            </span>
          </Col>
        )}
      </Row>
      <Form onSubmit={handleSubmit(handleRequest)}>
        {upload === "uploadVersion" && (
          <>
            <Row className="heading">Proviene de</Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("owner")}
                    placeholder="Proviene de..."
                    bsPrefix={
                      errors.owner
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.owner?.message}
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
        <Row className="heading">
          {upload === "uploadVersion"
            ? "Asociar a versión anterior"
            : "Asociar a un contrato"}
        </Row>
        <Row style={{ padding: "2% 0 3% 0" }}>
          <Col md={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => {
                setOpenModalVersion(true);
              }}
            >
              Asociar
            </Button>
          </Col>
          {versionSelected.name && (
            <Col xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
              <span className="caption custom-badges__disabled">
                {`${versionSelected.name} `}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => {
                    setVersionSelected({});
                  }}
                />
              </span>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={2}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => {
                navigate("/service/folders");
              }}
            >
              Cancelar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              type="submit"
              variant="contained"
              className={
                !documentData.name
                  ? "custom-input__button__disabled"
                  : "custom-input__button__primary-color"
              }
              disabled={!documentData.name}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setOpenModal(false);
        }}
        onClose={() => {
          setOpenModal(false);
        }}
        open={openModal}
        documentData={documentData}
        setDocumentData={setDocumentData}
        maxFileSize={78643200}
      />
      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalVersion(false);
          setVersionSelected(file);
        }}
        onClose={() => {
          setOpenModalVersion(false);
        }}
        open={openModalVersion}
        folders={folders}
        versionSelected={versionSelected}
        title="Asociar documento"
      />
      <ModalInfo
        title={responseData?.title || "Información"}
        responseData={responseData?.data || responseData}
        open={responseModal}
        onClose={() => {
          setResponseModal(false);
          navigate(-1);
        }}
      />
    </Container>
  );
};

export default UploadDocument;
