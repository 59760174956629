import { Button } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../utils/mixPanel";

const ButtonCreate = ({ path, value, mixPanelTrack = "" }) => {
  const handleClick = () => {
    if (mixPanelTrack) {
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      Mixpanel.track(`Btn ${value}`, {
        homeButton: mixPanelTrack,
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    }
  }
  return (
    <Button
      LinkComponent={Link}
      variant="contained"
      startIcon={<AddIcon />}
      className="custom-input__button__primary-color custom-input__button__no-hover"
      to={path}
      onClick={handleClick}
    >
      {value}
    </Button>
  );
};

export default ButtonCreate;
