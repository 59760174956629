import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CardInformative from "../../../components/Cards/CardInformative";
import LoadingContent from "../../../components/loadingContent";
import {
  getAlertsSummary,
  getFoldersSummary,
  getRequestSummary,
} from "../../../services/homePage/homeServices";
import { CODES } from "../../../utils/codes";
import { useDispatch, useSelector } from "react-redux";
import {
  getWrongRole,
  setWrongRole,
} from "../../../parts/warnings/warningRoleStorage";
import ModalInfo from "../../../components/Modals/modalInfo";
import { Mixpanel } from "../../../utils/mixPanel";

const HomeService = () => {
  const [requestCards, setRequestCards] = useState([]);
  const [alertsCards, setAlertsCards] = useState([]);
  const [foldersResume, setFoldersResume] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const wrongRoleStorage = useSelector(getWrongRole);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tokenInfo = JSON.parse(localStorage.payloadToken);
  const { userId } = tokenInfo;
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const fetchRequestData = async () => {
    setIsLoading(true);
    try {
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        userId: userId,
        companyId: companyId,
        corporateUnitId: corporateUnit,
      };
      const service = await getRequestSummary(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { data } = service.data?.responseMessage || {};
          // Se agregó para cambiar el orden de visualización tipo semáforo.
          let newObj = {
            expiredRequests: data?.expiredRequests,
            reviewAttorneyRequests: data?.reviewAttorneyRequests,
            openRequests: data?.openRequests,
          };
          const newArray = Object.entries(newObj).map((entry) => {
            const [key, value] = entry;
            if (key === "openRequests") {
              return {
                number: value,
                description: "Abiertas",
                icon: "linesArrow",
                color: "#CCF4E3",
                filterBy: "open",
              };
            }
            if (key === "reviewAttorneyRequests") {
              return {
                number: value,
                description: "En revisión",
                icon: "eye",
                color: "#FFD7B1",
                filterBy: "attorneyReview",
              };
            }
            if (key === "expiredRequests") {
              return {
                number: value,
                description: "Vencidas",
                icon: "linesArrow",
                color: "#F9DADE",
                filterBy: "expired",
              };
            }
          });
          setRequestCards(newArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchAlertsData = async () => {
    setIsLoading(true);
    try {
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        userId: userId,
        companyId: companyId,
        corporateUnitId: corporateUnit,
      };
      const service = await getAlertsSummary(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { data } = service.data?.responseMessage || {};
          // Se agregó para cambiar el orden de visualización tipo semáforo.
          let newObj = {
            expiredAlerts: data.expiredAlerts,
            toActivateAlerts: data.toActivateAlerts,
            activesAlerts: data.activesAlerts,
          };
          const newArray = Object.entries(newObj).map((entry) => {
            const [key, value] = entry;
            if (key === "expiredAlerts") {
              return {
                filterBy: key,
                number: value,
                description: "Vencidas",
                icon: "info",
                color: "#F9DADE",
              };
            }
            if (key === "activesAlerts") {
              return {
                filterBy: key,
                number: value,
                description: "Activas",
                icon: "info",
                color: "#CCF4E3",
              };
            }
            if (key === "toActivateAlerts") {
              return {
                filterBy: key,
                number: value,
                description: "Cercanas",
                icon: "info",
                color: "#FFD7B1",
              };
            }
          });
          setAlertsCards(newArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const fetchFoldersData = async () => {
    setIsLoading(true);
    try {
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const service = await getFoldersSummary({ corporateUnit });
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { responseMessage } = service.data;
          setFoldersResume(responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRequestData();
    fetchAlertsData();
    fetchFoldersData();
  }, []);

  const cardsInit = [
    {
      number: "Crear",
      description: "Crea un nuevo documento.",
      icon: "plus",
      navigateTo: "create",
    },
    {
      number: "Revisar",
      description: "Revisa un documento existente.",
      icon: "eye",
      navigateTo: "review",
    },
    {
      number: "Biblioteca",
      description: "Accede a tus minutas y cláusulas.",
      icon: "library",
      navigateTo: "library",
    },
  ];

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <br />
      {/**TITLE */}
      <Row className="home-title-row">Bienvenido</Row>
      {/**CLICKABLE CARDS */}
      <br />
      <Row className="home-init-cards-row">
        <Container fluid>
          <Row className="home-init-cards-row__title-row">
            ¿Qué quieres hacer?
          </Row>
          <Row className="home-init-cards-row__cards-container">
            {cardsInit.map((card) => (
              <Col md={4} key={card?.number}>
                <Container fluid style={{ cursor: "pointer", padding: "0" }}>
                  <CardInformative
                    number={card.number}
                    description={card.description}
                    navigateTo={card.navigateTo}
                    icon={
                      <img
                        className="home-img__clickable"
                        src={require(`../../../assets/svg/${card.icon}Icon.svg`)}
                      />
                    }
                    size="medium"
                  />
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </Row>
      <hr />
      {/* NEW HOME PAGE  */}
      <Row>
        <Col md={6}>
          <Container>
            <Row className="home-folders">
              <Container>
                <Row className="home-folders__title">Carpetas recientes</Row>
                <Container fluid style={{ padding: "0", height: "85%" }}>
                  {foldersResume.length > 0 &&
                    foldersResume.map((folder) => (
                      <Row className="home-folders__folders" key={folder?._id}>
                        <Col
                          xs={"auto"}
                          className="home-folders__folders__icon-container"
                        >
                          <Container className="home-folders__folders__icon">
                            <img
                              alt="folder-icon"
                              src={
                                require(`../../../assets/svg/folderIcon.svg`)
                                  .default
                              }
                            />
                          </Container>
                        </Col>
                        <Col md={6} className="home-folders__folders__title">
                          {folder.name}
                        </Col>
                        <Col
                          xs={"auto"}
                          className="home-folders__folders__alerts-text"
                        >
                          Alertas:{}
                        </Col>
                        <Col
                          xs={"auto"}
                          className="home-folders__folders__to-contain"
                        >
                          <Container className="home-folders__folders__alerts-number">
                            {folder.numberOfAlerts}
                          </Container>
                        </Col>
                        <Col
                          xs={"auto"}
                          className="home-folders__folders__to-contain"
                        >
                          <Button
                            className="home-folders__folders__button"
                            onClick={() => {
                              navigate(
                                `/service/folders/viewFolder/${folder._id}`
                              );
                              Mixpanel.track("Btn Inicio", {
                                homeButton: "Abrir Carpeta",
                                email: payloadToken?.email,
                                companyName: payloadToken?.companyName,
                              });
                            }}
                          >
                            Abrir
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  <br />
                  <Row>
                    <Col>
                      <Button
                        variant="contained"
                        startIcon={<FolderOutlinedIcon />}
                        className="custom-input__button__primary-color"
                        onClick={() => {
                          navigate(`/service/folders`);
                          Mixpanel.track("Btn Inicio", {
                            homeButton: "Abrir Carpeta",
                            email: payloadToken?.email,
                            companyName: payloadToken?.companyName,
                          });
                        }}
                      >
                        Ver más
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </Row>
          </Container>
        </Col>
        <Col md={6}>
          <Container>
            <Row className="home-info-cards-row">
              <Col md={6} className="home-info-cards-row__general-row">
                <Container
                  fluid
                  className="home-info-cards-row__general-row__layout"
                >
                  <Row className="home-info-cards-row__general-row__layout__title">
                    Solicitudes
                  </Row>
                  <Row className="home-info-cards-row__general-row__layout__cards">
                    {requestCards.length > 0 &&
                      requestCards.map((card) => (
                        <Col md={4} key={card?.number}>
                          <CardInformative
                            number={card.number}
                            description={card.description}
                            filterBy={card.filterBy}
                            navigateTo={"requests"}
                            icon={
                              <img
                                className="home-img__informative"
                                src={require(`../../../assets/svg/${card.icon}Icon.svg`)}
                              />
                            }
                            size="small"
                            color={card.color}
                            mixPanelTrack="Solicitudes"
                          />
                        </Col>
                      ))}
                  </Row>
                </Container>
              </Col>
              <Col md={6} className="home-info-cards-row__general-row">
                <Container className="home-info-cards-row__general-row__layout">
                  <Row className="home-info-cards-row__general-row__layout__title">
                    Alertas
                  </Row>
                  <Row className="home-info-cards-row__general-row__layout__cards">
                    {alertsCards.length > 0 &&
                      alertsCards.map((card) => (
                        <Col md={6} key={card?.number}>
                          <CardInformative
                            number={card.number}
                            description={card.description}
                            navigateTo={"alerts"}
                            icon={
                              <img
                                className="home-img__informative"
                                src={require(`../../../assets/svg/${card.icon}Icon.svg`)}
                              />
                            }
                            size="small"
                            color={card.color}
                            filterBy={card.filterBy}
                            mixPanelTrack="Alertas"
                          />
                        </Col>
                      ))}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {!isLoading && (
        <ModalInfo
          title="Advertencia"
          open={wrongRoleStorage}
          onClose={() => dispatch(setWrongRole(false))}
          responseData={{
            data: {
              message:
                "Si quieres revisar tu plan ingresa como usuario administrador.",
            },
          }}
          buttonClose={true}
        />
      )}
    </Container>
  );
};

export default HomeService;
