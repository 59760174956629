import { faCloudArrowUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Button, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { saveAs } from "file-saver";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import DiscussionBoard from "../../../../../../components/DiscussionBoard/DiscussionBoard";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateVersion from "../../../../../../components/Modals/modalAssociateVersion";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalInpuText from "../../../../../../components/Modals/modalInpuText";
import SectionTitle from "../../../../../../components/sectionTitle";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  cleanStore,
  setIdMongo,
  setInitialInfoLibrary,
  setVersions,
} from "../../../../../../parts/document/currentDocumentSlice";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../../../../../parts/folders/foldersSlice";
import { getCompanyPlan } from "../../../../../../parts/storage/storageSlice";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import {
  changeRequestState,
  findOneDataExternalRequest,
  SendInfoToApplicant,
} from "../../../../../../services/general/CarvajalServices";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../utils/codes";
import { dateNormal } from "../../../../../../utils/getDate";
import { getFile } from "../../../../../../utils/getFile";
import { validateEnablePlan } from "../../../../../../utils/getPlanInfo";
import { statusRequestName } from "../../../../../../utils/statusExternalRequest";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import AddIcon from "@mui/icons-material/Add";
import {
  CONST_QUESTION_REQUEST_EXTERNAL,
  buildTextParts,
} from "./request.utils";
import { REGEXP } from "../../../../../../utils/regexp";
export const validateCompleteSigns = (data, document) => {
  const info = data.find((sign) => sign._id === document.signature);
  if (!info) return false;
  const totalSigns = info.signatoriesInfo.length;
  const totalOkSigns = info.signatoriesInfo.filter(
    (sign) => sign.hasSigned
  ).length;
  return totalOkSigns === totalSigns;
};

const ViewRequestStandard = () => {
  const navigate = useNavigate();
  const { consecutive } = useParams();
  const dispatch = useDispatch();
  const foldersStatus = useSelector(getStatusFolders);
  const folders = useSelector(getFolders);

  const solvedData = {};
  const [openModalSolvedData, setOpenModalSolvedData] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [approveInfoModal, setApproveInfoModal] = useState(false);
  const [approveInfoModalReturn, setApproveInfoModalReturn] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalRequestResend, setShowModalRequestResend] = useState(false);
  const [
    showModalUploadDocumentPrincipal,
    setShowModalUploadDocumentPrincipal,
  ] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [isHideBtnUpDocPrincipal, setIsHideBtnUpDocPrincipal] = useState(false);
  const [createContractModal, setCreateContractModal] = useState(false);
  const [isOpenSolving, setIsOpenSolving] = useState(false);
  const [isOpenConfirmed, setIsOpenConfirmed] = useState(false);
  const [principalDocComment, setPrincipalDocComment] = useState("");
  const [isNewComment, setIsNewComment] = useState(false);
  const [dataRequest, setDataRequest] = useState({});
  const [isFindedData, setIsFindedData] = useState(false);
  const [isOpenModalIncompleteRequest, setIsOpenModalIncompleteRequest] =
    useState(false);
  const [disableSign, setDisableSign] = useState(false);

  const [dataTableDocumentPrincipal, setDataTableDocumentPrincipal] = useState({
    columns: [],
    rows: [],
  });

  const [requiredDocumentsTable, setRequiredDocumentsTable] = useState({});

  const [fileSelectedPrincipal, setFileSelectedPrincipal] = useState({});

  const [fileNames, setFileNames] = useState([]);
  const [openModalNavigateFolders, setOpenModalNavigateFolders] =
    useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});
  const [isStorageFulfilled, setIsStorageFulfilled] = useState(true);
  const companyPlan = useSelector(getCompanyPlan);
  const [isOpenModalSolveRequest, setIsOpenModalSolveRequest] = useState(false);
  const [responseData, setResponsedata] = useState({});
  const [isVersionContractSelected, setIsVersionContractSelected] = useState({
    isVersionRelated: false,
    versionRelated: "",
  });
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);

  const othersDocSchema = yup.object().shape({
    otherDoc1: yup.array().of(
      yup
        .mixed()
        .test("Required", "**Debe subir un documento", (value) => value[0])
        .test(
          "fileSize",
          "**Máximo 75MB por archivo, inténtalo de nuevo",
          (value) => value[0] && value[0].size <= 78643200
        )
        .test("acceptedFormats", "*Formato de archivo no válido", (value) => {
          if (value?.[0]) {
            const acceptedFormats = [".pdf", ".docx", ".doc"];
            const validFormat = acceptedFormats.some((format) =>
              value[0].name.endsWith(format)
            );
            return validFormat;
          }
        })
    ),
  });

  const {
    register: register1,
    watch: watch1,
    control: control1,
    handleSubmit,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(othersDocSchema),
  });

  const {
    fields: fields1,
    append: append1,
    remove: remove1,
  } = useFieldArray({
    control: control1,
    name: "otherDoc1",
  });

  const MAX_SIZE_FILE = 78643200;

  const STATE_COLOR_ID = {
    [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
    [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
    [CODES.COD_LIGHT_RED]: "#F9DADE",
    [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
    [CODES.COD_LIGHT_BLUE]: "#CBECEE",
  };

  const principalDocSchema = yup.object().shape({
    file: yup
      .mixed()
      .required("*Este campo es obligatorio")
      .test(
        "fileSize",
        "Máximo 75MB por archivo, inténtalo de nuevo",
        (value) => value[0] && value[0].size <= MAX_SIZE_FILE
      )
      .test("fileName", "*Este campo es obligatorio", (value) => {
        return value?.[0]?.name;
      })
      .test(
        "fileFormat",
        "Formato incorrecto",
        (value) =>
          (value[0] &&
            (value[0].type === "application/pdf" ||
              value[0].type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) ||
          value[0].type === "application/msword"
      ),
  });

  const {
    register: register10,
    reset: reset10,
    handleSubmit: handleSubmit10,
    formState: { errors: errors10 },
  } = useForm({
    resolver: yupResolver(principalDocSchema),
  });

  const returnSchema = yup.object().shape({
    returnComment: yup.string().required("*Este campo es obligatorio"),
  });

  const {
    register: returnRegister,
    handleSubmit: handleSubmitReturn,
    formState: { errors: returnErrors },
  } = useForm({
    resolver: yupResolver(returnSchema),
  });

  const handleDisagreeConfirmed = () => {
    setIsOpenConfirmed(false);
  };

  const handleAgreeConfirmed = () => {
    setIsOpenConfirmed(false);
    navigate(`/service/requests/associate/${consecutive}`);
  };

  const handleAgreeReturn = () => {
    setIsOpenConfirmed(false);
    navigate("/service/requests");
  };

  const handleDisagreeReturn = () => {
    setApproveInfoModalReturn(false);
  };

  const changeStateFunction = async ({ requestId, message, isActive }) => {
    const response = await changeRequestState({
      requestId,
      state: "Revisión solicitante",
      message,
      isActive,
      timeZone: new Date().getTimezoneOffset() / -60,
    });
    if (response.status) {
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        setShowModalRequestResend(false);
        navigate("/service/requests");
      }
    }
  };

  const sendInfoApplicantService = async ({ data, documents }) => {
    try {
      setIsLoading(true);
      const service = await SendInfoToApplicant(data);
      setResponseDataUnauthorized(service);
      if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
        let formatedMessage = "";
        if (documents.length) {
          formatedMessage = `${
            documents.length === 1
              ? "Se ha cargado el documento"
              : "Se han cargado los documento"
          }: ${documents.map((doc) => doc.name).join(", ")}`;
        }
        await changeStateFunction({
          requestId: dataRequest._id,
          message: formatedMessage,
          isActive: true,
        });
      } else if (
        service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        service.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReloadDocuments = async (data) => {
    try {
      setIsLoading(true);
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");

      let objOtherDocumentsAttorney = [];
      let obj;

      for (const element of data.otherDoc1) {
        objOtherDocumentsAttorney.push(await getFile(element?.[0]));
      }

      let requiredDocumentsObj = [];
      const arrayRequiredDocs = data?.requiredDocs || [];
      for (const doc of arrayRequiredDocs) {
        if (doc?.file?.[0]) {
          requiredDocumentsObj = [
            ...requiredDocumentsObj,
            { name: doc.name, file: doc.file[0] },
          ];
        }
      }

      let attorneyName = `${payloadToken?.firstName || ""} ${
        payloadToken?.firstSurname || ""
      }`;

      if (payloadToken.personType === "Juridica") {
        attorneyName = payloadToken.companyName;
      }

      obj = {
        companyId,
        corporateUnit,
        consecutive: consecutive,
        applicantId: dataRequest.applicantId,
        otherDocuments: objOtherDocumentsAttorney,
        comment: principalDocComment,
        principalDocument: fileSelectedPrincipal?.name
          ? fileSelectedPrincipal
          : null,
        attorneyName,
        requiredDocuments: requiredDocumentsObj,
        applicantName: dataRequest.applicant,
        ...isVersionContractSelected,
      };

      const documents = [];
      if (objOtherDocumentsAttorney.length > 0) {
        documents.push(...objOtherDocumentsAttorney);
      }
      if (obj.principalDocument) {
        documents.push(obj.principalDocument);
      }

      if (!documents.length) {
        return await changeStateFunction({
          requestId: dataRequest._id,
          state: "Revisión solicitante",
          message: "",
          isActive: true,
          timeZone: new Date().getTimezoneOffset() / -60,
        });
      }

      await sendInfoApplicantService({ data: obj, documents });
    } catch (e) {
      console.log("Error: ", e);
    } finally {
      setIsLoading(false);
    }
  };


  const handleClickDownloadNoExtension = async (document) => {
    try {
      const file = await getFileDocument(
        document.filenameInBucket,
        document.bucketName,
        false
      );
      const bf = file.data.responseMessage.buffer.data;
      const blob = new Blob([new Uint8Array(bf).buffer]);

      let fileName = file.data.responseMessage.filename;
      
      let extension = fileName.split(".").pop().toLowerCase();

      if (!EXTENSIONS.includes(extension)) {
        const contentType =
          file.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? ".docx"
            : ".pdf";
        fileName = fileName + contentType;
      } 

      saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickUploadOtherDocument = () => {
    append1({});
  };

  const handleClose = () => {
    setShowModalRequestResend(false);
    setShowModalUploadDocumentPrincipal(false);
  };

  const handleUploadDocument = (data) => {
    setShowModalUploadDocumentPrincipal(false);
    setPrincipalDocComment(data.comment);
  };

  const handleReview = (selectedDocument, id) => {
    dispatch(setVersions(selectedDocument.documentPrincipal));
    dispatch(setIdMongo({ id }));
    navigate("/service/review/in-negotiation/view-document/0");
  };

  const EXTENSIONS = ["doc", "docx", "docm", "pdf"];

  const handleUploadFromFolder = async (file) => {
    try {
      setOpenModalNavigateFolders(false);
      setIsLoading(true);
      let formatFile = null;
      const documentsNoConvert = [
        codesDocumentType?.contract?._id,
        codesDocumentType?.draft?._id,
      ];
      if (documentsNoConvert.includes(file?.fileType)) {
        setIsVersionContractSelected({
          isVersionRelated: true,
          versionRelated: file?._id,
        });
      }

      let extension = file.name.split(".").pop().toLowerCase();
      const getRequest = await getFileDocument(
        file.filenameInBucket,
        file.bucketName,
        EXTENSIONS.includes(extension) || extension === file?.name
      );
      if (
        getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        getRequest.data.success
      ) {
        let docView = getRequest.data.responseMessage.buffer;
        if (
          getRequest.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const { data } = getRequest.data.responseMessage.buffer;
          const contentType = getRequest?.data?.responseMessage?.contentType;
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);
          docView = pdfConverted?.data?.responseMessage?.data?.buffer;
        }
        const convertBlob = new Blob([new Uint8Array(docView.data).buffer], {
          type: "application/pdf",
        });
        formatFile = new File(
          [convertBlob],
          getRequest.data.responseMessage.filename.split(".")[0] + ".pdf",
          { type: convertBlob.type }
        );
      }
      setFileSelectedPrincipal(formatFile);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInformationToQuestions = (data) => {
    let information = {};

    const address =
      data?.requestDetails?.find(
        (detail) => detail?.question === CONST_QUESTION_REQUEST_EXTERNAL.ADDRESS
      )?.answer || "";

    const documentNumber =
      data?.requestDetails?.find(
        (detail) =>
          detail?.question === CONST_QUESTION_REQUEST_EXTERNAL.DOCUMENT_NUMBER
      )?.answer || "";

    const name = data?.requestDetails?.find(
      (detail) => detail?.question === CONST_QUESTION_REQUEST_EXTERNAL.NAME
    )?.answer;

    const isLegal =
      data?.requestDetails?.find(
        (detail) =>
          detail?.question === CONST_QUESTION_REQUEST_EXTERNAL.TYPE_PERSON
      )?.answer === "Sí";

    const typePerson = isLegal ? "Persona jurídica" : "Persona natural";

    information = {
      address,
      documentNumber,
      name,
      type: typePerson,
    };

    if (isLegal) {
      const nameSignatory = data?.requestDetails?.find(
        (detail) =>
          detail?.question === CONST_QUESTION_REQUEST_EXTERNAL.NAME_AGENT_LEGAL
      )?.answer;

      const addressSignatory = data?.requestDetails?.find(
        (detail) =>
          detail?.question ===
          CONST_QUESTION_REQUEST_EXTERNAL.ADDRESS_AGENT_LEGAL
      )?.answer;

      const documentTypeNumberSignatory = data?.requestDetails?.find(
        (detail) =>
          detail?.question ===
          CONST_QUESTION_REQUEST_EXTERNAL.DOCUMENT_NUMBER_AGENT_LEGAL
      )?.answer;

      const isOnlyNumbers = documentTypeNumberSignatory.match(
        REGEXP.ONLY_NUMBERS
      );
      const isOnlyLetters = documentTypeNumberSignatory.match(
        REGEXP.ONLY_LETTERS
      );

      const documentTypeSignatory = isOnlyLetters
        ? documentTypeNumberSignatory
        : documentTypeNumberSignatory.split(" ")?.[0];

      const documentNumberSignatory = isOnlyNumbers
        ? documentTypeNumberSignatory
        : documentTypeNumberSignatory.split(" ")?.[1];

      information = {
        ...information,
        addressSignatory,
        documentTypeSignatory,
        documentNumberSignatory,
        nameSignatory,
      };
    }

    return information;
  };

  const handleCreateDocument = ({ from, dataRequest }) => {
    setCreateContractModal(false);

    const infoQuestions = getInformationToQuestions(dataRequest);

    let infoSignatory = {};

    if (infoQuestions?.type === "Persona jurídica") {
      infoSignatory = {
        gender: "",
        information: {
          address: infoQuestions?.address,
          documentNumber: infoQuestions?.documentNumber,
          documentType: infoQuestions?.documentType,
          name: infoQuestions?.name,
          charge: "",
        },
        signatories: [
          {
            address: infoQuestions?.addressSignatory,
            documentNumber: infoQuestions?.documentNumberSignatory,
            documentType: infoQuestions?.documentTypeSignatory,
            name: infoQuestions?.nameSignatory || "",
            email: "",
            typeCreate: "request",
            indexSignatory: 0,
            text: buildTextParts({
              typePerson: infoQuestions.type,
              ...infoQuestions,
            }),
            type: infoQuestions?.type,
          },
        ],
        text: buildTextParts({
          typePerson: infoQuestions.type,
          ...infoQuestions,
        }),
        type: infoQuestions?.type,
      };
    } else if (infoQuestions?.type === "Persona natural") {
      infoSignatory = {
        gender: "",
        information: {
          address: infoQuestions?.address,
          documentNumber: infoQuestions?.documentNumber,
          documentType: infoQuestions?.documentType,
          name: infoQuestions?.name,
          charge: "",
        },
        signatories: [
          {
            address: infoQuestions?.address,
            email: "",
            documentNumber: infoQuestions?.documentNumber,
            documentType: infoQuestions?.documentType,
            name: infoQuestions?.name || "",
            indexSignatory: 0,
            typeCreate: "request",
            text: buildTextParts({
              typePerson: infoQuestions.type,
              ...infoQuestions,
            }),
            type: infoQuestions?.personType,
            charge: "",
          },
        ],
        text: buildTextParts({
          typePerson: infoQuestions.type,
          ...infoQuestions,
        }),
        type: infoQuestions?.type,
      };
    }

    const finalObj = {
      title: "Sin título",
      parts: [
        {
          name: "Rol A",
          text: "",
          signatories: [
            {
              gender: "",
              information: {
                address: "",
                email: "",
                documentNumber: "",
                documentType: "",
                name: dataRequest?.applicantCompany || "",
              },
              signatory: {},
              signatories: [],
              text: buildTextParts({ typePerson: infoQuestions.type }),
              type: "Persona jurídica",
            },
          ],
        },
        {
          name: "Rol B",
          text: "",
          signatories: [infoSignatory],
        },
      ],
    };
    if (from === "CLAUSES") {
      navigate(
        `/service/create/from-library/clause-selection?from=REQUEST&requestId=${consecutive}`,
        { state: { noCleanStore: true } }
      );
    } else if (from === "CONTRACTTYPES") {
      navigate(
        `/service/create/from-minuta?from=REQUEST&requestId=${consecutive}`,
        { state: { noCleanStore: true } }
      );
    }
    dispatch(setInitialInfoLibrary(finalObj));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);

        const corporateUnit = localStorage.getItem("corporateUnitId");
        const company = localStorage.getItem("company");

        const obj = {
          consecutive: consecutive,
          company,
          corporateUnit,
        };
        const GetDataRequest = await findOneDataExternalRequest(obj);

        if (GetDataRequest.data.responseCode === 0) {
          const data = GetDataRequest.data.responseMessage;
          const dataFilter = {
            _id: data._id,
            stateRequest: data?.requestStatus?.name,
            color: STATE_COLOR_ID[data?.requestStatus?.colorId] || "#F3DEBC",
            applicant: `${data?.applicant?.firstName || ""} ${
              data?.applicant?.secondName || ""
            } ${data?.applicant?.firstSurname || ""} ${
              data?.applicant?.secondSurname || ""
            }`,
            applicantId: data?.applicant?._id,
            attorneyInCharge: `${data?.attorneyInCharge?.firstName || ""} ${
              data?.attorneyInCharge?.secondName || ""
            } ${data?.attorneyInCharge?.firstSurname || ""} ${
              data?.attorneyInCharge?.secondSurname || ""
            }`,
            requestSubject: data.requestSubject,
            requestType: data.requestType,
            requestSubType: data.requestSubtype,
            requestTypeRedaction: data?.requestTypeRedaction,
            responseLimitDate: data.responseLimitDate,
            requestDetails: data.requestDetails,
            documentsOtherAttorney: data?.documents?.attorneyDocuments,
            documentsAttachments: data?.documents?.requiredDocuments,
            documentsOtherApplicant: data?.documents?.applicantDocuments,
            documentPrincipal: data?.documents?.mainDocuments.filter(
              (doc) =>
                !(
                  !doc.isRejected &&
                  !validateCompleteSigns(data.signatures, doc) &&
                  doc.subtype === "Signature"
                )
            ),
            comments: data.comments,
            applicantCompany: data.applicantCompany
              ? data.applicantCompany
              : "Applicant Company",
            isRequestActive: data.isActive,
            documentsAtTable: data?.documents?.requiredDocuments, //Documentos requeridos y documentos del solicitante que se mapean en la tabla
          };

          const getTitle = (item) => {
            if (item?.isSignatureFinish) return item?.tracking || "";
            if (item?.isApprovedWithComments)
              return " Documento aprobado con comentarios";
            if (item?.userRole === "EXTERNAL") return " Respuesta Solicitante";
            if (item?.userRole === "SERVICE") return " Respuesta Abogado";
            return " Documento devuelto con comentarios";
          };

          setDataRequest(dataFilter);
          setDataTableDocumentPrincipal({
            columns: [
              {
                label: [
                  <label aria-hidden="true" key="1">
                    Seguimiento
                  </label>,
                ],
                field: "followup",
              },
              {
                label: [
                  <label aria-hidden="true" key="2">
                    Descripción
                  </label>,
                ],
                field: "description",
              },
              {
                label: [
                  <label aria-hidden="true" key="3">
                    Creado por
                  </label>,
                ],
                field: "createdBy",
              },
              {
                label: [
                  <label aria-hidden="true" key="4">
                    Dirigido a
                  </label>,
                ],
                field: "assignedTo",
              },
              {
                label: [
                  <label aria-hidden="true" key="5">
                    Fecha de creación
                  </label>,
                ],
                field: "createdAt",
              },
              {
                label: [
                  <label aria-hidden="true" key="6">
                    Documento adjunto
                  </label>,
                ],
                field: "originalFilename",
              },
            ],
            rows: dataFilter.documentPrincipal.map((item, index) => {
              return {
                description: item.description,
                createdAt: dateNormal(item.createdAt),
                createdBy: item.createdBy,
                assignedTo: item.assignedTo,
                followup: (
                  <label className="caption">{`#${index + 1} ${getTitle(
                    item
                  )}`}</label>
                ),
                originalFilename: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      onClick={() => {
                        handleClickDownloadNoExtension(item);
                      }}
                      style={{
                        color: "#17B4BC",
                        cursor: "pointer",
                        fontSize: "1.5rem",
                        borderRadius: "0.8rem",
                        padding: "0.4rem",
                      }}
                    >
                      {item.originalFilename}
                    </label>
                    <div
                      style={{ border: "none" }}
                      onClick={() => {
                        dispatch(cleanStore());
                        handleReview(dataFilter, item._id);
                      }}
                    >
                      <IconButton aria-label="edit">
                        <RemoveRedEyeIcon fontSize="large" />
                      </IconButton>
                    </div>
                  </div>
                ),
              };
            }),
          });
          setIsFindedData(true);
        } else {
          setIsFindedData(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [isNewComment]);

  useEffect(() => {
    if (dataRequest?.documentsAttachments?.length > 0) {
      const { documentsAtTable } = dataRequest;

      setRequiredDocumentsTable({
        columns: [
          {
            label: [
              <label aria-hidden="true" key="1">
                Documento a subir
              </label>,
            ],
            field: "document",
          },
          {
            label: [
              <label aria-hidden="true" key="2">
                Relevancia
              </label>,
            ],
            field: "required",
          },
          {
            label: [
              <label aria-hidden="true" key="3">
                Archivo
              </label>,
            ],
            field: "file",
          },
          {
            label: [
              <label aria-hidden="true" key="4">
                Acciones
              </label>,
            ],
            field: "actions",
          },
        ],
        rows: documentsAtTable.map((item, index) => ({
          document: (
            <Form.Label
              {...register1(`requiredDocs.${index}.name`, {
                value: item.originalFilename,
              })}
            >
              {item.originalFilename}
            </Form.Label>
          ),
          required: item.isRequired ? "Obligatorio*" : "Opcional",
          file: item.originalFilename
            ? fileNames[index] || "Documento cargado"
            : "---",
          actions: (
            <>
              <Form.Control
                id={`requiredDocs.${index}.file`}
                className="input-upload"
                {...register1(`requiredDocs.${index}.file`)}
                onChange={(e) => {
                  if (e.target.files[0]) {
                    const newArray = [...fileNames];
                    newArray[index] = e.target.files[0].name;
                    setFileNames(newArray);
                  } else {
                    const newArray = [...fileNames];
                    newArray[index] = "";
                    setFileNames(newArray);
                  }
                }}
                type="file"
              />
              <Form.Label
                className="input-upload__button_primary_color"
                onClick={() => handleClickDownloadNoExtension(item)}
                style={{ marginRight: "1rem" }}
              >
                <DownloadIcon fontSize="large" />
              </Form.Label>
              {/* <Form.Label
                className={"input-upload__button"}
                htmlFor={`requiredDocs.${index}.file`}
              >
                <CloudUploadOutlinedIcon fontSize="large" />
                {" Volver a cargar"}
              </Form.Label> */}
            </>
          ),
        })),
      });
    }
  }, [fileNames, dataRequest]);
  useEffect(() => {
    if (foldersStatus == "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [dispatch, foldersStatus]);

  const handleReasignrequest = () => {
    navigate("reasign");
  };

  const handleBackOnePage = () => {
    navigate(-1);
  };

  const handleFinishRequest = async () => {
    setApproveModal(false);
    setIsLoading(true);
    try {
      let obj;

      obj = {
        requestId: dataRequest._id,
        state: "Revisión abogado",
        isActive: true,
        timeZone: new Date().getTimezoneOffset() / -60,
      };

      const changeStateRequest = await changeRequestState(obj);

      if (changeStateRequest.status) {
        if (changeStateRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("/service/requests");
        } else if (
          changeStateRequest.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setResponseDataUnauthorized(changeStateRequest);
          setIsOpenModalUnauthorized(true);
        } else {
          setErrorModal("true");
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReturnRequest = async ({ returnComment }) => {
    setIsLoading(true);
    setReturnModal(false);
    try {
      let obj;

      obj = {
        requestId: dataRequest._id,
        state: "Devuelta",
        message: returnComment,
        isActive: false,
        timeZone: new Date().getTimezoneOffset() / -60,
      };

      const changeStateRequest = await changeRequestState(obj);

      if (changeStateRequest.status) {
        if (changeStateRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
          setApproveInfoModalReturn(true);
        } else if (
          changeStateRequest.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setResponseDataUnauthorized(changeStateRequest);
          setIsOpenModalUnauthorized(true);
        } else {
          setErrorModal("true");
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAgreeToFinishRequest = async () => {
    try {
      setIsLoading(true);
      const objState = {
        requestId: dataRequest._id,
        state: "Solucionada",
        timeZone: new Date().getTimezoneOffset() / -60,
        isActive: true,
      };
      const changeState = await changeRequestState(objState);
      if (changeState.status) {
        if (changeState.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("/service/requests");
        } else if (
          changeState.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setResponseDataUnauthorized(changeState);
          setIsOpenModalUnauthorized(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSolveRequest = async () => {
    //Validar que espacio de almacenamiento del plan

    if (!isStorageFulfilled) {
      handleAgreeToFinishRequest();
      return;
    }
    if (dataRequest.documentPrincipal.length <= 0) {
      setIsOpenModalIncompleteRequest(true);
    } else {
      setIsOpenSolving(true);
    }
  };

  const handleModalSolvedRequest = () => {
    setOpenModalSolvedData(false);
    navigate(-1);
  };

  useEffect(() => {
    (async () => {
      const companyPlan = await validateEnablePlan();
      setIsStorageFulfilled(companyPlan.isStorageEnable);
    })();
  }, []);

  const buildActions = (data) => {
    let actions = {
      approvals: false,
      sign: false,
      solvedRequest: false,
      reasign: false,
      send: false,
      saveDocuments: false,
      create: false,
    };

    if (
      !["Nueva", "Devuelta", "Cancelada"].includes(data.stateRequest) ||
      data.comments.find((comment) => comment.approverName)
    ) {
      actions = {
        ...actions,
        approvals: true,
      };
    }

    if (
      ["Revisión solicitante", "Revisión abogado"].includes(data.stateRequest)
    ) {
      actions = {
        ...actions,
        sign: true,
        solvedRequest: true,
        send: true,
      };
    }

    if (
      ["Sin asignar", "Revisión abogado", "Revisión solicitante"].includes(
        data.stateRequest
      )
    ) {
      actions = {
        ...actions,
        reasign: true,
      };
    }

    //Validar que ya tenga espacio de almacenamiento
    const documents = dataRequest.documentPrincipal.concat(
      dataRequest.documentsAttachments,
      dataRequest.documentsOtherApplicant,
      dataRequest.documentsOtherAttorney
    );

    if (
      ["Solucionada"].includes(data.stateRequest) &&
      dataRequest.isRequestActive &&
      isStorageFulfilled &&
      documents.length
    ) {
      actions = {
        ...actions,
        saveDocuments: true,
      };
    }

    if (
      !["Solucionada", "Cancelada"].includes(data.stateRequest) &&
      !dataRequest?.requestTypeRedaction.match(/Consulta jurídica/i)
    ) {
      actions = {
        ...actions,
        create: true,
      };
    }

    return actions;
  };

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isSignatureEnable) {
        setDisableSign(true);
      }
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  const handleSolveRequestAgree = async () => {
    try {
      setIsLoading(true);
      setShowModalRequestResend(false);
      setIsOpenModalIncompleteRequest(false);
      setIsOpenSolving(false);
      const objState = {
        requestId: dataRequest._id,
        state: statusRequestName.SOLVE,
        timeZone: new Date().getTimezoneOffset() / -60,
        isActive: false,
      };
      const changeState = await changeRequestState(objState);
      if (changeState?.status === CODES.COD_RESPONSE_HTTP_OK) {
        setResponsedata({
          ...changeState,
          data: {
            responseMessage: "Se ha terminado la solicitud correctamente.",
          },
        });
        setIsOpenModalSolveRequest(true);
        return;
      } else if (changeState?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(changeState);
        return;
      }
      setResponsedata({
        ...changeState,
        data: {
          responseMessage: "No se ha podido terminado la solicitud.",
        },
      });
      setIsOpenModalUnauthorized(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      {isFindedData ? (
        <>
          <Row xs={"auto"}>
            <SectionTitle title={"Solicitud"} />
            <button
              className="btn_white_background"
              onClick={() => setIsHideDiscussion(false)}
              style={{
                alignItems: "center",
                position: "absolute",
                right: "9rem",
                marginRight: "1rem",
                padding: "0.3rem",
              }}
            >
              <ArticleOutlinedIcon fontSize="large" />
              Mensajes
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#17B4BC",
                alignItems: "center",
                position: "absolute",
                right: "5rem",
                marginRight: "1rem",
                padding: "0.3rem",
              }}
            >
              <NotificationsNoneOutlinedIcon fontSize="large" />
              {dataRequest.comments ? dataRequest.comments.length : 0}
            </button>
          </Row>
          <div
            className="data-table dt-list-items"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "calc(100vh - 8rem)",
            }}
          >
            <Row>
              <Row
                style={{
                  color: "#00374F",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <h1 className="label__title">Detalles de la solicitud</h1>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">Estado de la solicitud: </h2>
                </Col>
                <Col xs={2}>
                  <h2
                    style={{
                      backgroundColor: dataRequest.color,
                      fontSize: "1.5rem",
                      borderRadius: "0.8rem",
                      padding: "0.4rem",
                      marginTop: "1rem",
                      width: "max-content",
                    }}
                    className="label__description"
                  >
                    {dataRequest.stateRequest}
                  </h2>
                </Col>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">Solicitante: </h2>
                </Col>
                <Col xs={2}>
                  <h2 className="label__description">
                    {dataRequest?.applicant || ""}
                  </h2>
                </Col>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">Abogado a cargo: </h2>
                </Col>
                <Col xs={2}>
                  <h2 className="label__description">
                    {dataRequest?.attorneyInCharge || ""}
                  </h2>
                </Col>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">Asunto de la solicitud: </h2>
                </Col>
                <Col md={3}>
                  <h2 className="label__description">
                    {dataRequest.requestSubject}
                  </h2>
                </Col>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">Tipo de solicitud: </h2>
                </Col>
                <Col xs={"auto"}>
                  <h2 className="label__description">
                    {dataRequest?.requestTypeRedaction}
                  </h2>
                </Col>
              </Row>
              {dataRequest?.requestTypeRedaction.toLowerCase() !==
                "consulta jurídica" && (
                <>
                  <Row style={{ marginLeft: "1rem" }}>
                    <Col xs={2}>
                      <h2 className="label__subtitle">Tipo de documento: </h2>
                    </Col>
                    <Col xs={"auto"}>
                      <h2 className="label__description">
                        {dataRequest.requestType?.group}
                      </h2>
                    </Col>
                  </Row>
                  {dataRequest?.requestType?.group?.toLowerCase() !==
                    "otrosí" &&
                    dataRequest.requestSubType !== "" && (
                      <Row style={{ marginLeft: "1rem" }}>
                        <Col xs={2}>
                          <h2 className="label__subtitle">
                            Subtipo de documento:{" "}
                          </h2>
                        </Col>
                        <Col xs={"auto"}>
                          <h2 className="label__description">
                            {dataRequest?.requestSubType?.name}
                          </h2>
                        </Col>
                      </Row>
                    )}
                </>
              )}
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">Empresa: </h2>
                </Col>
                <Col xs={"auto"}>
                  <h2 className="label__description">
                    {dataRequest.applicantCompany}
                  </h2>
                </Col>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                <Col xs={2}>
                  <h2 className="label__subtitle">
                    Fecha límite de respuesta:{" "}
                  </h2>
                </Col>
                <Col xs={2}>
                  <h2 className="label__description">
                    {dataRequest?.responseLimitDate || "No aplica"}
                  </h2>
                </Col>
              </Row>
            </Row>
            <Row>
              <Row
                style={{
                  color: "#00374F",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                <h1 className="label__title">Información para la solicitud</h1>
              </Row>
              <Row style={{ marginLeft: "1rem" }}>
                {dataRequest.requestDetails.map((item, i) => {
                  return (
                    <Row key={JSON.stringify(item)}>
                      {item.answer != undefined && item.answer !== "" && (
                        <>
                          <h2 className="label__subtitle">{item.question}</h2>
                          <h2 className="label__description">{item.answer}</h2>
                        </>
                      )}
                    </Row>
                  );
                })}
              </Row>
            </Row>
            {/* Tabla de documentos requeridos y opcionales */}
            {dataRequest?.documentsAttachments?.length > 0 && (
              <Row>
                <Row style={{ color: "#00374F" }}>
                  <h1 className="label__title">Documentos Anexos</h1>
                </Row>
                <Row className="data-table dt-list-items">
                  <MDBDataTableV5
                    hover
                    pagingTop
                    searchBottom={false}
                    entries={20}
                    paging={false}
                    data={requiredDocumentsTable}
                    entriesLabel=""
                    infoLabel={["Mostrando", "a", "de", "solicitudes"]}
                    fullPagination
                    noRecordsFoundLabel="No se encontraron resultados"
                  />
                </Row>
              </Row>
            )}
            <Row style={{ color: "#00374F", marginTop: "2rem" }}>
              <h1 className="label__title">
                Documentos cargados por el solicitante
              </h1>
              <Col md={6}>
                {dataRequest.documentsOtherApplicant?.length > 0 ? (
                  dataRequest.documentsOtherApplicant.map((item, index) => {
                    return (
                      <Row
                        key={uuidv4()}
                        style={{
                          color: "#00374F",
                          margin: "1rem 0rem",
                          padding: "2rem",
                          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          borderRadius: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Col md={9}>
                          <p className="label__title">
                            {" "}
                            {item.originalFilename}
                          </p>
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "end" }}>
                          <IconButton
                            aria-label="edit"
                            className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                            sx={{ borderRadius: "8px" }}
                            onClick={() => {
                              handleClickDownloadNoExtension(item);
                            }}
                          >
                            <DownloadIcon fontSize="large" htmlColor="white" />
                          </IconButton>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <label className="label__description">
                    El solicitante no ha cargado documentos todavía
                  </label>
                )}
              </Col>
            </Row>
            <Form onSubmit={handleSubmit(handleReloadDocuments)}>
              {dataRequest.stateRequest !== "Nueva" && (
                <>
                  <Row>
                    <hr />
                    <Row style={{ color: "#00374F", marginTop: "2rem" }}>
                      <h1 className="label__title">
                        Otros documentos cargados por el abogado
                      </h1>
                      <Col md={6}>
                        {dataRequest.documentsOtherAttorney?.length > 0 ? (
                          dataRequest.documentsOtherAttorney.map(
                            (item, index) => {
                              return (
                                <Row
                                  key={uuidv4()}
                                  style={{
                                    color: "#00374F",
                                    margin: "1rem 0rem",
                                    padding: "2rem",
                                    boxShadow:
                                      "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                                    borderRadius: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col>
                                    <p className="label__title">
                                      {" "}
                                      {item.originalFilename}
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <IconButton
                                      aria-label="edit"
                                      className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                                      sx={{ borderRadius: "8px" }}
                                      onClick={() => {
                                        handleClickDownloadNoExtension(item);
                                      }}
                                    >
                                      <DownloadIcon
                                        fontSize="large"
                                        htmlColor="white"
                                      />
                                    </IconButton>
                                  </Col>
                                </Row>
                              );
                            }
                          )
                        ) : (
                          <label className="label__description">
                            El abogado no ha cargado documentos todavía
                          </label>
                        )}
                      </Col>
                    </Row>
                    <Row id="ParentUploadDocuments">
                      {fields1.map((item, index) => {
                        let nameDoc;
                        if (watch1(`otherDoc1.${index}`)) {
                          nameDoc = watch1("otherDoc1")[index][0]?.name;
                        }
                        return (
                          <div key={uuidv4()}>
                            <input
                              id={`otherDocs${index}`}
                              className="dropzone_upload"
                              type="file"
                              {...register1(`otherDoc1.${index}`)}
                            />
                            <Row>
                              <Col>
                                <label
                                  htmlFor={`otherDocs${index}`}
                                  className="btn_blue_background"
                                  type="button"
                                  style={{
                                    padding: "8px",
                                    marginTop: "1rem",
                                  }}
                                >
                                  {nameDoc || (
                                    <>
                                      <FontAwesomeIcon
                                        className="icon-navbar"
                                        icon={faCloudArrowUp}
                                        color="#ffffff"
                                        style={{
                                          backgroundColor: "#00374F",
                                          marginRight: "0.5rem",
                                        }}
                                      />
                                      {" adjuntar documento"}
                                    </>
                                  )}
                                </label>
                                <label
                                  className="btn_white_background"
                                  type="button"
                                  style={{
                                    marginLeft: "1rem",
                                    padding: "8px",
                                    marginTop: "1rem",
                                  }}
                                  onClick={() => {
                                    remove1(index);
                                  }}
                                >
                                  <DeleteIcon fontSize="large" />
                                </label>
                              </Col>
                            </Row>
                            <span className="caption custom-input__error">
                              {errors1?.otherDoc1 &&
                                errors1?.otherDoc1[index] &&
                                errors1?.otherDoc1[index].message}
                            </span>
                          </div>
                        );
                      })}
                    </Row>
                    {dataRequest.isRequestActive && (
                      <Col xs={"auto"}>
                        <ButtonToolTip
                          type="button"
                          variant={!isStoragePlanLimit && "contained"}
                          startIcon={<AddIcon />}
                          className={
                            isStoragePlanLimit
                              ? "custom-input__button__disabled"
                              : "custom-input__button__primary-color"
                          }
                          textButton={"Cargar otro documento"}
                          textTooltip={
                            "La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                          }
                          onClick={() => {
                            if (isStoragePlanLimit) return;
                            handleClickUploadOtherDocument();
                          }}
                          isOpenTooltip={isStoragePlanLimit}
                        />
                      </Col>
                    )}
                  </Row>
                  <hr />
                  <Box
                    sx={{
                      display: "flex",
                      borderColor: "divider",
                      paddingTop: "2rem",
                    }}
                  >
                    <Row>
                      <Row style={{ color: "#00374F" }}>
                        <h1 className="label__title">Documento solicitado</h1>
                      </Row>
                      <Row>
                        <label className="label__description">
                          {fileSelectedPrincipal?.name ? (
                            <Row
                              style={{
                                color: "#00374F",
                                height: "max-content",
                                width: "40%",
                                margin: "1rem 0rem",
                                boxShadow:
                                  "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                                borderRadius: "8px",
                              }}
                            >
                              <Col style={{ margin: "2rem 1.5rem" }} md={7}>
                                <Row className="label__title">
                                  Archivo de documento principal
                                </Row>
                                <Row>{fileSelectedPrincipal?.name || ""}</Row>
                              </Col>
                            </Row>
                          ) : (
                            "Aún no se ha cargado una versión del documento principal"
                          )}
                        </label>
                      </Row>
                      <Col md={10}>
                        {dataRequest.isRequestActive && (
                          <Row>
                            <Col xs={"auto"}>
                              <ButtonToolTip
                                type="button"
                                variant={!isStoragePlanLimit && "contained"}
                                startIcon={<PublishIcon />}
                                className={
                                  isStoragePlanLimit
                                    ? "custom-input__button__disabled"
                                    : "custom-input__button__primary-color"
                                }
                                textButton={"Cargar"}
                                textTooltip={
                                  "La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                                }
                                onClick={() => {
                                  if (isStoragePlanLimit) return;
                                  setShowModalUploadDocumentPrincipal(true);
                                }}
                                isOpenTooltip={isStoragePlanLimit}
                              />
                            </Col>
                            <Col xs={"auto"}>
                              <ButtonToolTip
                                type="button"
                                variant={!isStoragePlanLimit && "contained"}
                                startIcon={<FolderIcon />}
                                className={
                                  isStoragePlanLimit
                                    ? "custom-input__button__disabled"
                                    : "custom-input__button__primary-color"
                                }
                                textButton={"Añadir desde Legal AI"}
                                textTooltip={
                                  "La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                                }
                                onClick={() => {
                                  if (isStoragePlanLimit) return;
                                  setOpenModalNavigateFolders(true);
                                }}
                                isOpenTooltip={isStoragePlanLimit}
                              />
                            </Col>
                            {buildActions(dataRequest).create && (
                              <Col xs={"auto"}>
                                <Button
                                  variant="contained"
                                  className={
                                    isStoragePlanLimit
                                      ? "custom-input__button__disabled"
                                      : "custom-input__button__primary-color"
                                  }
                                  type="button"
                                  onClick={() => setCreateContractModal(true)}
                                >
                                  Crear
                                </Button>
                              </Col>
                            )}
                          </Row>
                        )}
                      </Col>
                      <Row>
                        <MDBDataTableV5
                          hover
                          pagingTop
                          searchBottom={false}
                          entries={5}
                          data={dataTableDocumentPrincipal}
                          entriesLabel=""
                          infoLabel={["Mostrando", "a", "de", "solicitudes"]}
                          fullPagination
                          noRecordsFoundLabel="No se encontraron resultados"
                        />
                      </Row>
                    </Row>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  borderTop: 1,
                  borderColor: "divider",
                  marginTop: "2rem",
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                }}
              >
                {buildActions(dataRequest).approvals && (
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color"
                      type="button"
                      onClick={() => {
                        navigate("approvals");
                      }}
                    >
                      {"Vistos buenos"}
                    </Button>
                  </Col>
                )}
                {buildActions(dataRequest).sign && (
                  <Col xs={"auto"}>
                    {disableSign ? (
                      <CustomToolTip
                        direction="top"
                        message="Sin firmas disponibles en el plan, contacta al usuario administrador"
                      >
                        <Button
                          variant="contained"
                          className="custom-input__button__secondary-color"
                          type="button"
                        >
                          Firmar
                        </Button>
                      </CustomToolTip>
                    ) : (
                      <Button
                        variant="contained"
                        className="custom-input__button__secondary-color"
                        type="button"
                        onClick={() => {
                          navigate(`/service/requests/${consecutive}/sign`);
                        }}
                      >
                        Firmar
                      </Button>
                    )}
                  </Col>
                )}
                <Col />
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    className="custom-input__button__secondary-color"
                    type="button"
                    onClick={handleBackOnePage}
                  >
                    Regresar
                  </Button>
                </Col>
                {buildActions(dataRequest).solvedRequest && (
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color"
                      type="button"
                      onClick={handleSolveRequest}
                    >
                      Solucionar solicitud
                    </Button>
                  </Col>
                )}
                {buildActions(dataRequest).reasign && (
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color"
                      type="button"
                      onClick={handleReasignrequest}
                    >
                      Reasignar
                    </Button>
                  </Col>
                )}
                {buildActions(dataRequest).saveDocuments && (
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color"
                      type="button"
                      onClick={() => {
                        const route = `/service/requests/associate/${consecutive}/${dataRequest._id}`;
                        navigate(route);
                      }}
                    >
                      Guardar documentos
                    </Button>
                  </Col>
                )}
                {buildActions(dataRequest).send && (
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__primary-color"
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </Col>
                )}
              </Box>
            </Form>
          </div>
          <ModalDecision
            title={"Aprobar solicitud"}
            message={"¿Estás seguro de que quieres aprobar esta solicitud?"}
            open={approveModal}
            onClose={setApproveModal}
            agreeText="Aceptar"
            disagreeText="Cancelar"
            handleAgree={handleFinishRequest}
            handleDisagree={() => {
              setApproveModal(false);
            }}
          />
          <ModalDecision
            title={"Terminar la solicitud"}
            message={"¿Estás seguro de que quieres terminar esta solicitud?"}
            open={showModalRequestResend}
            onClose={setShowModalRequestResend}
            agreeText="Solucionar"
            disagreeText="Cancelar"
            handleAgree={() => {
              setApproveInfoModal(true);
            }}
            handleDisagree={() => {
              setShowModalRequestResend(false);
            }}
          />
          <ModalInfo
            title={"¡Solucionar Solicitud!"}
            responseData={solvedData}
            open={openModalSolvedData}
            onClose={handleModalSolvedRequest}
          />
          <ModalInfo
            title={"¡Solicitud Aprobada!"}
            responseData={{
              status: CODES.COD_RESPONSE_HTTP_OK,
              data: {
                responseMessage: {
                  message: `Se ha aprobado con éxito la solicitud ${consecutive}. Por favor, elige una ubicación para almacenar los documentos que fueron adjutados.`,
                },
              },
            }}
            open={approveInfoModal}
            onClose={setApproveInfoModal}
          />
          <ModalDecision
            title={"¡Exitosa!"}
            message={"Se ha devuelto la solicitud de manera exitosa."}
            open={approveInfoModalReturn}
            onClose={setApproveInfoModalReturn}
            agreeText="Continuar"
            disagreeText="Cerrar"
            handleAgree={handleAgreeReturn}
            handleDisagree={handleDisagreeReturn}
          />
          <ModalInfo
            title={"Error"}
            responseData={{
              status: CODES.COD_RESPONSE_HTTP_ERROR,
              data: {
                responseMessage: {
                  message: "Ha ocurrido un error al devolver la solicitud.",
                },
              },
            }}
            open={errorModal}
            onClose={setErrorModal}
          />

          <ModalDecision
            title={"Terminar la solicitud"}
            message={"¿Estás seguro de que quieres terminar esta solicitud?"}
            open={isOpenSolving}
            onClose={() => setIsOpenSolving(false)}
            agreeText="Solucionar"
            disagreeText="Cancelar"
            handleAgree={handleSolveRequestAgree}
            handleDisagree={() => setIsOpenSolving(false)}
          />

          <ModalDecision
            title={"Solicitud incompleta"}
            message={
              "¿Estás seguro de que quieres terminar esta solicitud sin un documento solicitado?"
            }
            open={isOpenModalIncompleteRequest}
            onClose={() => setIsOpenModalIncompleteRequest(false)}
            agreeText="Aceptar"
            disagreeText="Cancelar"
            handleAgree={handleSolveRequestAgree}
            handleDisagree={() => setIsOpenModalIncompleteRequest(false)}
          />

          <ModalDecision
            title={"¡Solicitud Aprobada!"}
            message={`Se ha aprobado con éxito la Solicitud ${consecutive}. Por favor, elige una ubicación para almacenar los documentos que fueron adjuntados.`}
            open={isOpenConfirmed}
            onClose={handleDisagreeConfirmed}
            agreeText="Continuar"
            disagreeText="Cerrar"
            handleAgree={handleAgreeConfirmed}
            handleDisagree={handleDisagreeConfirmed}
          />
          {/*Modal Subir Documento Principal*/}
          <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            show={showModalUploadDocumentPrincipal}
            onHide={handleClose}
          >
            <form onSubmit={handleSubmit10(handleUploadDocument)}>
              <Modal.Header style={{ padding: "1rem" }}>
                <Modal.Title
                  className="label__title"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Detalles del documento
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label className="label__description">
                  Descripción o comentarios
                </label>
                <input
                  {...register10("comment")}
                  placeholder="Escribe un nuevo comentario..."
                  style={{
                    width: "100%",
                    fontSize: "1.4rem",
                    fontWeight: "100",
                    lineHeight: "2.4rem",
                    borderRadius: "0.5rem",
                    border: "0.1rem solid #c5c7d0",
                    padding: "1rem",
                  }}
                />
                <Row>
                  {errors10?.comment ? (
                    <label
                      style={{
                        color: "#e44258",
                        margin: "1rem",
                      }}
                    >
                      Obligatorio agregar un comentario*
                    </label>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row>
                  {!fileSelectedPrincipal?.name ? (
                    <div>
                      <label
                        hidden={isHideBtnUpDocPrincipal}
                        htmlFor="uploadFile"
                        className={
                          errors10?.file
                            ? "dropzone_btn_error"
                            : "btn_white_background"
                        }
                        style={{
                          marginTop: "1rem",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          htmlFor="uploadFile"
                          size="lg"
                          icon={faPlus}
                        />
                        {errors10?.file
                          ? " Debes cargar un documento"
                          : " Cargar documento principal"}
                      </label>
                    </div>
                  ) : (
                    <Row
                      style={{
                        width: "95%",
                        color: "#00374F",
                        height: "max-content",
                        margin: "1rem",
                        boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                        borderRadius: "8px",
                      }}
                    >
                      <Col style={{ margin: "1.5rem" }} md={9}>
                        <Row className="label__title">
                          Archivo de documento principal
                        </Row>
                        <Row>{fileSelectedPrincipal?.name}</Row>
                      </Col>
                      <Col style={{ margin: "1.5rem" }} md={1}>
                        <Row>
                          <Col md={6}>
                            <label
                              htmlFor="uploadFile"
                              className="btn_blue_background"
                              color="#676879"
                              style={{
                                backgroundColor: "#00374F",
                                marginRight: "1rem",
                                padding: "7px",
                                cursor: "pointer",
                              }}
                            >
                              <EditOutlinedIcon fontSize="large" />
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Row>
                <span className="caption custom-input__error">
                  {errors10.file?.message}
                </span>
                <input
                  {...register10("file")}
                  id="uploadFile"
                  className="dropzone_upload"
                  type="file"
                  onChange={(e) => {
                    setIsHideBtnUpDocPrincipal(true);
                    setFileSelectedPrincipal(e.target.files[0]);
                  }}
                />
              </Modal.Body>
              <Modal.Footer style={{ padding: "1rem" }}>
                <label
                  className="btn_white_background"
                  style={{ padding: "12px 24px", cursor: "pointer" }}
                  onClick={() => {
                    reset10();
                    setIsHideBtnUpDocPrincipal(false);
                    setFileSelectedPrincipal({});
                    setPrincipalDocComment("");
                    handleClose();
                  }}
                >
                  Descartar
                </label>
                <button
                  type="submit"
                  className="btn_blue_background"
                  style={{ padding: "12px 24px" }}
                >
                  Guardar cambios
                </button>
              </Modal.Footer>
            </form>
          </Modal>
          <Modal
            size="md"
            centered
            backdrop="static"
            keyboard={false}
            show={returnModal}
            onHide={handleClose}
          >
            <form onSubmit={handleSubmitReturn(handleReturnRequest)}>
              <Modal.Header>
                <Modal.Title
                  className="label__title"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Devolver solicitud
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label className="label__description">
                  Por favor, escriba la razón por la cual se va a devolver la
                  solicitud.
                </label>
                <Form.Control
                  as="textarea"
                  {...returnRegister("returnComment")}
                />
                <Row>
                  <Col>
                    {!!returnErrors.returnComment ? (
                      <label
                        style={{
                          color: "#e44258",
                          margin: "1rem",
                        }}
                      >
                        Obligatorio agregar un comentario*
                      </label>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn_white_background"
                  style={{ padding: "12px 24px", cursor: "pointer" }}
                  onClick={() => setReturnModal(false)}
                >
                  Cancelar
                </button>
                <button
                  className="btn_blue_background"
                  //   onClick={handleReturnRequest}
                  style={{ padding: "12px 24px", cursor: "pointer" }}
                  type="submit"
                >
                  Devolver Solicitud
                </button>
              </Modal.Footer>
            </form>
          </Modal>
          <ModalInfo
            title={
              responseDataUnauthorized?.data?.responseCode ===
              CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
                ? "Sin permisos suficientes"
                : "Ha ocurrido un error"
            }
            onClose={() => setIsOpenModalUnauthorized(false)}
            open={isOpenModalUnauthorized}
            responseData={responseDataUnauthorized}
            confirmationText="Aceptar"
          />
          <DiscussionBoard
            isHideDiscussion={isHideDiscussion}
            setIsHideDiscussion={setIsHideDiscussion}
            comments={dataRequest.comments}
            id={dataRequest._id}
            setIsNewComment={setIsNewComment}
            isRequestActive={dataRequest.isRequestActive}
            state={dataRequest.stateRequest}
          />
          {folders?.length > 0 && (
            <ModalAssociateVersion
              agreeText={"Aceptar"}
              disagreeText={"Cancelar"}
              handleAgree={(file) => {
                handleUploadFromFolder(file);
              }}
              onClose={() => {
                setOpenModalNavigateFolders(false);
              }}
              open={openModalNavigateFolders}
              folders={folders}
              versionSelected={fileSelectedPrincipal}
              title="Asociar documento"
            />
          )}

          <ModalDecision
            title={"Crear"}
            message={"¿Cómo quieres crear tu contrato?"}
            open={createContractModal}
            onClose={() => setCreateContractModal(false)}
            agreeText="Desde cláusulas"
            disagreeText="Desde minutas"
            handleAgree={() =>
              handleCreateDocument({ from: "CLAUSES", dataRequest })
            }
            handleDisagree={() =>
              handleCreateDocument({ from: "CONTRACTTYPES", dataRequest })
            }
          />

          <ModalInfo
            title="Información"
            onClose={() => {
              let route = "";
              const documents = dataRequest.documentPrincipal.concat(
                dataRequest.documentsAttachments,
                dataRequest.documentsOtherApplicant,
                dataRequest.documentsOtherAttorney
              );
              if (documents.length) {
                route = `/service/requests/associate/${consecutive}/${dataRequest._id}`;
              } else {
                route = `/service/requests`;
              }
              navigate(route);
            }}
            open={isOpenModalSolveRequest}
            responseData={responseData}
            confirmationText="Continuar"
          />
        </>
      ) : (
        <>
          <br />
          {!isLoading && (
            <Row
              style={{
                color: "#00374F",
                marginBottom: "2rem",
              }}
            >
              <h1 className="label__title">
                No se ha encontrado información sobre la solictud{" "}
                {`${consecutive}`}
              </h1>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

const ButtonToolTip = ({
  className = "custom-input__button__secondary-color",
  startIcon,
  onClick,
  variant = "contained",
  type = "button",
  textButton,
  textTooltip,
  isOpenTooltip,
}) => {
  const [isOpenTooltipAction, setIsOpenTooltipAction] = useState(false);
  return (
    <Tooltip
      open={isOpenTooltipAction}
      onOpen={() => {
        if (!isOpenTooltip) return;
        setIsOpenTooltipAction(true);
      }}
      onClose={() => setIsOpenTooltipAction(false)}
      title={<h5>{textTooltip}</h5>}
    >
      <Button
        type={type}
        variant={variant}
        startIcon={startIcon}
        className={className}
        onClick={onClick}
      >
        {textButton}
      </Button>
    </Tooltip>
  );
};

export default ViewRequestStandard;
