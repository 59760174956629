import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../components/loadingContent";
import ModalInfo from "../../../components/Modals/modalInfo";
import {
  ReportAccount,
  SendInfoToSupport,
} from "../../../services/admin/manageUserService";
import { CODES } from "../../../utils/codes";

const ReportAccountExternal = () => {
  const [searchParams] = useSearchParams();
  const emailToReport = searchParams.get("email");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState("second");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isOpenModalNoFile, setIsOpenModalNoFile] = useState(false);
  const [isOpenModalInvalidFormat, setIsOpenModalInvalidFormat] =
    useState(false);
  const [documentIndex, setDocumentIndex] = useState(0);

  const [responseReportAccount, setResponseReportAccount] = useState({});
  const [isOpenModalInfoReportAccount, setIsOpenModalInfoReportAccount] =
    useState(false);

  const responseDataFileSize = {
    data: {
      responseMessage:
        "No ha sido posible enviar el formulario porque uno o más de los archivos excede el peso de 20MB.",
    },
    status: 1000,
  };

  const responseDataNoFile = {
    data: {
      responseMessage:
        "No ha sido posible enviar el formulario porque uno o más de la cajas no cuenta con archivo cargado. Elimínala y carga una nueva.",
    },
    status: 1000,
  };

  const responseDataInvalidFormat = {
    data: {
      responseMessage:
        "No ha sido posible enviar el formulario porque uno o más de de los archivos no se encuentra en formato Word, PDF, png o jpg.",
    },
    status: 1000,
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),
    subject: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(250, "Debe conterner máximo 250 caracateres"),
    description: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(1000, "Debe conterner máximo 1000 caracateres"),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "supportDocuments",
    control,
  });

  useEffect(() => {
    const inputElement = document.getElementById(
      `supportDocuments.${documentIndex}`
    );

    if (inputElement) {
      inputElement.dispatchEvent(new MouseEvent("click"));
    }
  }, [fields]);

  const handleInfoToAdminSupport = async (data) => {
    try {
      setIsLoading(true);

      if (data.supportDocuments.length > 0) {
        data.supportDocuments.map((document) => {
          if (Object.keys(document).length === 0) {
            setIsOpenModalNoFile(true);
            return "exit";
          }

          if (
            document[0]?.type !== "application/pdf" &&
            document[0]?.type !== "application/msword" &&
            document[0]?.type !== "image/jpeg" &&
            document[0]?.type !== "image/png" &&
            document[0]?.type !==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            setIsOpenModalInvalidFormat(true);
            return "exit";
          }
          if (document[0]?.size > 20000000) {
            setIsOpenModalInfo(true);
            return "exit";
          }
        });
      }

      const obj = {
        subject: data.subject,
        message: data.description,
        userEmail: data.email,
        supportDocuments: data.supportDocuments.map((document) => {
          return document[0];
        }),
      };

      const service = await SendInfoToSupport(obj);
      if (service) {
        if (service.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          setResponseData(service);
          setIsOpenModal(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOtherDocument = (index) => {
    remove(index);
  };

  const handleUploadOtherDocument = (index) => {
    setDocumentIndex(index);
    append({});
  };

  const handleDownloadRecentDocument = (index) => {
    const getFile = getValues(`supportDocuments.${index}`);

    let file = "";
    if (getFile) {
      file = getFile[0];
    }

    if (file) {
      const blob = new Blob([file]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const response = await ReportAccount({ email: emailToReport });
        if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
          setResponseReportAccount({
            ...response,
            data: {
              responseMessage: "La cuenta ha sido reportada correctamente.",
            },
          });
          setIsOpenModalInfoReportAccount(true);
        }
      } catch (error) {
        console.error("Error: no se ha podido reportar la cuenta.");
        window.location.replace("/");
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <Container
      fluid
      style={{
        height: "vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {isLoading && <LoadingContent />}
      <Container
        style={{
          width: "30%",
          boxShadow: "rgb(0 0 0 / 5%) 4px 4px 25px 3px",
        }}
      >
        <Row xs={"auto"}>
          <p
            className="home-init-cards-row__title-row"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            Por favor diligencia la siguiente información:
          </p>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit(handleInfoToAdminSupport)}>
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label className="caption">
                    Correo electrónico:
                  </Form.Label>
                  <Form.Control
                    {...register("email")}
                    type="text"
                    placeholder="Usuario@ejemplo.com"
                    size="lg"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.email?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">Asunto: </Form.Label>
                  <Form.Control
                    {...register("subject")}
                    type="text"
                    placeholder="Solicitud de..."
                    size="lg"
                    rows={3}
                    maxLength="250"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.subject?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">Descripción: </Form.Label>
                  <Form.Control
                    {...register("description")}
                    type="text"
                    as="textarea"
                    placeholder="Necesito ayuda en..."
                    size="lg"
                    rows={5}
                    maxLength="1000"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.description?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <>
              {fields.map((field, index) => {
                const file =
                  watch(`supportDocuments.${index}`) &&
                  watch(`supportDocuments.${index}`)[0];
                return (
                  <Row key={field.id}>
                    <Col xs={12}>
                      <Form.Control
                        key={field.id}
                        id={`supportDocuments.${index}`}
                        className="input-upload"
                        {...register(`supportDocuments.${index}`)}
                        type="file"
                      />
                      <Row
                        htmlFor={`supportDocuments.${index}`}
                        style={{
                          color: "#00374F",
                          height: "max-content",
                          margin: "1rem 0rem",
                          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          borderRadius: "8px",
                        }}
                      >
                        <Col style={{ margin: "1.5rem" }} md={7}>
                          <Row className="label__title">{`Documento ${
                            index + 1
                          }`}</Row>
                          <Row>{file ? `${file.name}` : ""}</Row>
                        </Col>
                        <Col style={{ margin: "1.5rem" }} md={3}>
                          <Row>
                            <Col md={6}>
                              <Form.Label
                                onClick={() =>
                                  handleDownloadRecentDocument(index)
                                }
                                className="btn_blue_background"
                                style={{
                                  backgroundColor: "#00374F",
                                  marginRight: "1rem",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                              >
                                <DownloadIcon fontSize="large" />
                              </Form.Label>
                            </Col>
                            <Col md={6}>
                              <Form.Label
                                className="btn_blue_background"
                                color="#676879"
                                style={{
                                  backgroundColor: "#00374F",
                                  marginRight: "1rem",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDeleteOtherDocument(index)}
                              >
                                <DeleteIcon fontSize="large" />
                              </Form.Label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
              <br />
              {fields.length < 3 && (
                <Row xs={"auto"}>
                  <Col xs={"auto"}>
                    <Button
                      type="button"
                      variant="contained"
                      startIcon={<AddIcon fontSize="large" />}
                      className="custom-input__button__secondary-color"
                      onClick={() => handleUploadOtherDocument(fields.length)}
                    >
                      Cargar Documentos
                    </Button>
                  </Col>
                </Row>
              )}
            </>
            <br />
            <br />
            <Row>
              <Col xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  type="submit"
                  variant="contained"
                  className="custom-input__button__primary-color"
                >
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      className="spinner__blue_background"
                    />
                  ) : (
                    "Enviar"
                  )}
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
        </Row>
      </Container>
      <ModalInfo
        title={"Mensaje enviado a soporte"}
        responseData={responseData}
        open={isOpenModal}
        onClose={() => navigate("/")}
      />
      <ModalInfo
        title="Cajas de archivos vacías"
        open={isOpenModalNoFile}
        onClose={() => setIsOpenModalNoFile(false)}
        responseData={responseDataNoFile}
      />
      <ModalInfo
        title="Formato de archivos inválido"
        open={isOpenModalInvalidFormat}
        onClose={() => setIsOpenModalInvalidFormat(false)}
        responseData={responseDataInvalidFormat}
      />
      <ModalInfo
        title="Peso de archivos inválido"
        open={isOpenModalInfo}
        onClose={() => setIsOpenModalInfo(false)}
        responseData={responseDataFileSize}
      />
      <ModalInfo
        title="Reporte de cuenta"
        open={isOpenModalInfoReportAccount}
        onClose={() => setIsOpenModalInfoReportAccount(false)}
        responseData={responseReportAccount}
      />
    </Container>
  );
};

export default ReportAccountExternal;
