import { VisibilityOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FeedIcon from "@mui/icons-material/Feed";
import FolderIcon from "@mui/icons-material/Folder";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import TaskIcon from "@mui/icons-material/Task";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from "@mui/material";
import { MDBBadge, MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import DetailsBar from "../../../../../../components/DetailsBar/DetailsBar";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  deleteAlert,
  DeleteTaskInAlert,
  DeleteTaskPaperBasketInAlert,
  findAllDeletedAlerts,
  findAllDeletedTasks,
  updateAlertStates,
} from "../../../../../../services/alerts/alertsServices";
import {
  deleteFolder,
  findDeletedFolder,
  updateDeletedFolder,
} from "../../../../../../services/business_folder/businessFolderService";
import {
  deleteClause,
  findAllDeletedClauses,
  updateDeletedClause,
} from "../../../../../../services/clauses/clausesServices";
import {
  deleteContractTypeById,
  getAllDeleteContractTypes,
  updateDeletedContractTypes,
} from "../../../../../../services/contractTypes/contractTypesService";
import {
  deleteVersion,
  getFileDocument,
} from "../../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import {
  getDocumentDeletedList,
  updateStateDocument,
} from "../../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import {
  DOCUMENT_STATE,
  FOLDER_STATE,
} from "../../../../../../utils/documentStates";
import { dateNormal } from "../../../../../../utils/getDate";
import {
  NUMBERINGWORDSA,
  NUMBERINGWORDSO,
} from "../../../../../../utils/numberings";
import { TASK_STATE } from "../../../../../../utils/tasksState";
import { setAlertStatus } from "../../alert/alertSlice";
import {
  deletePermanentPrederminatedText,
  getAllDeletePrederminatedText,
  restorePrederminatedText,
} from "../../../../../../services/predeterminatedTexts/predeterminatedTextsService";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { handlePreviewTemplate } from "../../create/other/utils";
import {
  deletePermanentTemplete,
  getAllDeleteTempleteDocument,
  restoreTempleteDocument,
} from "../../../../../../services/documentTemplates/templeteServices";

const STATE_COLOR_ID = {
  [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
  [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
  [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
  [CODES.COD_LIGHT_BLUE]: "#FFD7B1",
};

const PaperBasket = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //state
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de eliminación
        </label>,
      ],
      field: "deleteAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Días restantes
        </label>,
      ],
      field: "countDays",
    },
    {
      label: [<label aria-hidden="true" key="1"></label>],
      field: "actions",
    },
  ];
  const [documentList, setDocumentList] = useState({
    rows: [],
    columns: columns,
  });
  const [filterDocumentList, setFilterDocumentList] = useState({
    rows: [],
    columns: columns,
  });
  const [anchorSelected, setAnchorSelected] = useState(null);
  const openSelected = Boolean(anchorSelected);
  const [selectedElement, setSelectedElement] = useState({
    id: "",
    type: "",
    name: "",
  });

  const [openModalInfo, setOpenModalInfo] = useState({
    message: "",
    title: "",
    isOpen: false,
  });
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [filterMyId, setFilterMyId] = useState(false);
  const [documentListData, setDocumentListData] = useState(null);
  const [businessFolderFatherId, setBusinessFolderFatherId] = useState(null);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const optionMenu = [
    {
      title: "Restaurar",
      icon: <RestoreFromTrashOutlinedIcon fontSize="large" />,
      option: 0,
    },
    {
      title: "Eliminar permanentemente",
      icon: <DeleteForeverOutlinedIcon fontSize="large" />,
      option: 1,
    },
    {
      title: "Ver detalles",
      icon: <InfoOutlinedIcon fontSize="large" />,
      option: 2,
    },
    {
      title: "Visualizar",
      icon: <VisibilityOutlined fontSize="large" />,
      option: 3,
    },
  ];

  const TYPE_ITEM_DELETED = {
    undefined: "",
    document: "documento",
    folder: "carpeta",
    clause: "cláusula",
    alert: "alerta",
    contractType: "minuta",
    task: "tarea",
    predeterminatedText: "bloque",
    documentTemplate: "plantilla",
    conceptLegal: "concepto jurídico",
  };

  const TYPE_DELETE_ELEMENT = {
    folder: {
      name: "Carpeta",
      icon: <FolderIcon fontSize="large" className="heading__primary-color" />,
      option: 1,
    },
    alert: {
      name: "Alerta",
      icon: (
        <NotificationsIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      option: 2,
    },
    contractType: {
      name: "Minuta",
      icon: (
        <AssignmentIcon fontSize="large" className="heading__primary-color" />
      ),
      option: 3,
    },
    clause: {
      name: "Cláusula",
      icon: (
        <StickyNote2Icon fontSize="large" className="heading__primary-color" />
      ),
      option: 4,
    },
    task: {
      name: "Tarea",
      icon: <TaskIcon fontSize="large" className="heading__primary-color" />,
      option: 5,
    },
    predeterminatedText: {
      name: "Bloque",
      icon: (
        <ArticleOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      option: 6,
    },
    documentTemplate: {
      name: "Plantilla de otro documento",
      icon: (
        <SummarizeOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      option: 7,
    },
    conceptLegal: {
      name: "Consulta jurídica",
      icon: (
        <PolicyOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      option: 8,
    },
  };

  const getIconByValidation = (doc, fileType) => {
    const defaultIcon = (
      <FeedIcon fontSize="large" className="heading__primary-color" />
    );

    return TYPE_DELETE_ELEMENT?.[doc?.typeElementDeleted]?.icon || defaultIcon;
  };

  const getNameByType = (doc, fileType) => {
    if (fileType) return fileType?.name;
    return (
      TYPE_DELETE_ELEMENT?.[doc?.typeElementDeleted]?.name || "No definido"
    );
  };

  //handles
  const handleCloseSelected = () => {
    setAnchorSelected(null);
    setSelectedElement({ id: "", type: "", name: "" });
  };
  const documentDeletedRequest = async () => {
    try {
      setIsloading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const companyId = localStorage.getItem("company");
      const [
        { value: documentListRequest },
        { value: folderListRequest },
        { value: alertListRequest },
        { value: ClausesListRequest },
        { value: minutesListRequest },
        { value: tasksListRequest },
        { value: predeterminatedTextListDelete },
        { value: templeteDocumentsListDelete },
      ] = await Promise.allSettled([
        getDocumentDeletedList({
          companyId,
          corporateUnit,
        }),
        findDeletedFolder({
          corporateUnit,
          companyId,
        }),
        findAllDeletedAlerts({
          corporateUnit,
          company: companyId,
        }),
        findAllDeletedClauses({
          corporateUnit,
          company: companyId,
        }),
        getAllDeleteContractTypes({
          corporateUnit,
          company: companyId,
        }),
        findAllDeletedTasks({
          corporateUnitId: corporateUnit,
          companyId,
        }),
        getAllDeletePrederminatedText({
          corporateUnit,
          company: companyId,
        }),
        getAllDeleteTempleteDocument({
          corporateUnit,
          company: companyId,
        }),
      ]);
      let finalList = [];
      if (
        documentListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        documentListRequest.data.responseMessage.data
      ) {
        const documents = documentListRequest.data.responseMessage.data.map(
          (item) => ({ ...item, typeElementDeleted: "document" })
        );
        finalList = finalList.concat(documents);
      }
      if (
        folderListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        folderListRequest.data.responseMessage.data
      ) {
        const folders = folderListRequest.data.responseMessage.data.map(
          (item) => ({ ...item, typeElementDeleted: "folder" })
        );
        finalList = finalList.concat(folders);
      }
      if (
        alertListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        alertListRequest.data.responseMessage.data
      ) {
        const alerts = alertListRequest.data.responseMessage.data.map(
          (item) => ({ ...item, typeElementDeleted: "alert" })
        );
        finalList = finalList.concat(alerts);
      }
      if (
        ClausesListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        ClausesListRequest.data.responseMessage.data
      ) {
        const clauses = ClausesListRequest.data.responseMessage.data.map(
          (item) => ({ ...item, typeElementDeleted: "clause" })
        );
        finalList = finalList.concat(clauses);
      }
      if (
        minutesListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        minutesListRequest.data.responseMessage.data
      ) {
        const contractType = minutesListRequest.data.responseMessage.data.map(
          (item) => ({ ...item, typeElementDeleted: "contractType" })
        );
        finalList = finalList.concat(contractType);
      }
      if (
        tasksListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        tasksListRequest.data.responseMessage.data
      ) {
        const tasks = tasksListRequest.data.responseMessage.data.map(
          (item) => ({ ...item, typeElementDeleted: "task" })
        );
        finalList = finalList.concat(tasks);
      }
      if (
        predeterminatedTextListDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
        predeterminatedTextListDelete.data.responseMessage.data
      ) {
        const predeterminatedText =
          predeterminatedTextListDelete.data.responseMessage.data.map(
            (item) => ({ ...item, typeElementDeleted: "predeterminatedText" })
          );
        finalList = finalList.concat(predeterminatedText);
      }
      if (
        templeteDocumentsListDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
        templeteDocumentsListDelete.data.responseMessage.data
      ) {
        const documentTemplete =
          templeteDocumentsListDelete.data.responseMessage.data.map((item) => ({
            ...item,
            typeElementDeleted: "documentTemplate",
          }));
        finalList = finalList.concat(documentTemplete);
      }
      setDocumentListData(finalList);
      const componentDocumentList = finalList.map((doc) => {
        const fileType = Object.values(codesDocumentType).find(
          (code) => code._id === doc.fileType
        );
        const modified = doc.deletedAt
          ? moment(doc.deletedAt).format("DD/MM/YYYY HH:mm:ss")
          : moment(doc.updatedAt).format("DD/MM/YYYY HH:mm:ss");
        return {
          element: (
            <Row
              style={{
                paddingLeft: "2%",
                paddingTop: "1%",
                cursor: "pointer",
              }}
              onClick={() => {}}
            >
              <Col xs={"auto"} className="datatable-CB__tr-name">
                {getIconByValidation(doc, fileType)}
              </Col>
              <Col className="datatable-CB__tr-name">
                {doc.name ? doc.name : doc.title}
              </Col>
            </Row>
          ),
          type: getNameByType(doc, fileType),
          deleteAt: modified,
          countDays: doc.daysUntilDelete,
          deletedBy: doc.deletedBy,
          actions: (
            <IconButton
              onClick={(event) => {
                setSelectedElement(doc);
                setAnchorSelected(event.currentTarget);
              }}
            >
              <MoreHorizIcon fontSize="large" />
            </IconButton>
          ),
        };
      });
      setDocumentList({ rows: componentDocumentList, columns: columns });
      setFilterDocumentList({
        rows: componentDocumentList,
        columns: columns,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const previewTextPredeterminated = async (document) => {
    try {
      setIsloading(true);
      await handlePreviewTemplate(document);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleManageOptionsMenu = (option) => {
    switch (option) {
      case 0:
        handleRestore();
        break;
      case 1:
        setOpenModalDecision(true);
        break;
      case 2:
        setAnchorSelected(null);
        setOpenDetailsDrawner(true);
        break;
      case 3:
        setAnchorSelected(null);
        if (selectedElement.fileType) {
          handlePreviewPdf(selectedElement);
        } else if (
          ["predeterminatedText", "documentTemplate"].includes(
            selectedElement?.typeElementDeleted
          )
        ) {
          previewTextPredeterminated(selectedElement);
        } else {
          setBusinessFolderFatherId(selectedElement._id);
          setOpenModal(true);
        }
        break;
      default:
        break;
    }
  };
  const restoreServiceByOption = async (selectedElement) => {
    const option = selectedElement.fileType
      ? 0
      : TYPE_DELETE_ELEMENT?.[selectedElement?.typeElementDeleted]?.option;
    const corporateUnitId = localStorage.getItem("corporateUnitId");
    const companyId = localStorage.getItem("company");
    if (option === 0) {
      return await updateStateDocument({
        newStatus: DOCUMENT_STATE.active._id,
        id: selectedElement._id,
      });
    }
    if (option === 1) {
      return await updateDeletedFolder({
        newStatus: FOLDER_STATE.new._id,
        id: selectedElement._id,
      });
    }
    if (option === 2) {
      return await updateAlertStates({
        id: selectedElement._id,
        newStatus: FOLDER_STATE.new._id,
      });
    }
    if (option === 3) {
      return await updateDeletedContractTypes({
        id: selectedElement._id,
        company: companyId,
        corporateUnit: corporateUnitId,
        state: DOCUMENT_STATE.active._id,
      });
    }
    if (option === 4) {
      return await updateDeletedClause({
        id: selectedElement._id,
        company: companyId,
        corporateUnit: corporateUnitId,
        state: DOCUMENT_STATE.active._id,
      });
    }
    if (option === 5) {
      return await DeleteTaskPaperBasketInAlert({
        id: selectedElement._id,
        newStatus: selectedElement.assignedUsers.length
          ? TASK_STATE.INACTIVA._id
          : TASK_STATE.SIN_ASIGNAR._id,
      });
    }
    if (option === 6) {
      return await restorePrederminatedText({
        id: selectedElement._id,
        company: companyId,
        corporateUnit: corporateUnitId,
      });
    }
    if (option === 7) {
      return await restoreTempleteDocument({
        id: selectedElement._id,
        company: companyId,
        corporateUnit: corporateUnitId,
      });
    }
  };

  const deleteServiceByOption = async (selectedElement) => {
    const option = selectedElement.fileType
      ? 0
      : TYPE_DELETE_ELEMENT?.[selectedElement?.typeElementDeleted]?.option;

    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");

    if (option === 0) {
      return await deleteVersion(
        selectedElement._id,
        selectedElement.fileType,
        "FOLDER"
      );
    }
    if (option === 1) {
      return await deleteFolder({
        id: selectedElement._id,
      });
    }
    if (option === 2) {
      return await deleteAlert({ id: selectedElement._id, company });
    }
    if (option === 3) {
      return await deleteContractTypeById(selectedElement._id);
    }
    if (option === 4) {
      return await deleteClause({
        clauseId: selectedElement._id,
        corporateUnit,
      });
    }
    if (option === 5) {
      //Pendiente retorno de back el id de la alerta donde se encuentra la tarea
      return await DeleteTaskInAlert({
        alertId: selectedElement.alertAssociated,
        taskId: selectedElement._id,
      });
    }
    if (option === 6) {
      return await deletePermanentPrederminatedText({
        id: selectedElement._id,
      });
    }
    if (option === 7) {
      return await deletePermanentTemplete({
        id: selectedElement._id,
      });
    }
  };

  const handleRestore = async () => {
    try {
      setIsloading(true);
      setAnchorSelected(null);
      const requestRestoreDoc = await restoreServiceByOption(selectedElement);
      if (
        requestRestoreDoc.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestRestoreDoc.data.success
      ) {
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Correcto",
          isOpen: true,
        });
        if (selectedElement.typeElementDeleted === "alert") {
          dispatch(setAlertStatus({ status: "fetch" }));
        }
      } else {
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Error",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log("========== HANDLE RESTOR START ERROR ==========");
      console.error(error);
      console.log("========== HANDLE RESTOR END ERROR ==========");
    } finally {
      setIsloading(false);
    }
  };

  const handleDeletePerma = async () => {
    try {
      setAnchorSelected(null);
      setIsloading(true);
      const requestRestoreDoc = await deleteServiceByOption(selectedElement);
      if (
        requestRestoreDoc.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestRestoreDoc.data.success
      ) {
        setIsloading(false);
        setOpenModalDecision(false);
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Correcto",
          isOpen: true,
        });
      } else {
        setIsloading(false);
        setOpenModalDecision(false);
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Error",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePreviewPdf = async (version) => {
    try {
      setIsloading(true);
      const getRequest = await getFileDocument(
        version.filenameInBucket,
        version.bucketName,
        version.isWord
      );
      if (
        getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        getRequest.data.success
      ) {
        let docView = getRequest.data.responseMessage.buffer;
        if (
          getRequest.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const { data } = getRequest.data.responseMessage.buffer || {};
          const { contentType } = getRequest.data?.responseMessage || {};
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);
          docView = pdfConverted?.data?.responseMessage?.data?.buffer;
        }
        const file = URL.createObjectURL(
          new Blob([new Uint8Array(docView?.data).buffer], {
            type: "application/pdf",
          })
        );
        window.open(file);
      } else if (
        getRequest?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        getRequest?.data?.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(getRequest);
        throw new Error("No tienes los permisos suficientes");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };
  const getpageName = (selectedElement) => {
    if (selectedElement?.fileType) {
      return "Document";
    } else if (selectedElement?.alertDate) {
      return "Alert";
    } else if (selectedElement?.clauses) {
      return "Minute";
    } else if (selectedElement?.inFavor) {
      return "Clause";
    } else if (selectedElement?.typeElementDeleted === "predeterminatedText") {
      return "predeterminatedText";
    } else if (selectedElement?.typeElementDeleted === "documentTemplate") {
      return "documentTemplate";
    } else if (selectedElement?.typeElementDeleted === "conceptLegal") {
      return "conceptLegal";
    } else {
      return "FolderBasket";
    }
  };
  //useEffect
  useEffect(() => {
    documentDeletedRequest();
  }, []);
  useEffect(() => {
    if (filterMyId) {
      const infoUser = JSON.parse(localStorage.payloadToken);
      setFilterDocumentList((prev) => ({
        rows: prev.rows.filter(
          (row) =>
            row?.deletedBy?.firstName === infoUser.firstName &&
            row?.deletedBy?.firstSurname === infoUser.firstSurname
        ),
        columns: columns,
      }));
    } else {
      setFilterDocumentList(documentList);
    }
  }, [filterMyId]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col xs="auto">
          <SectionTitle title={"Basurero de documentos"} />
        </Col>
      </Row>
      <Row style={{ padding: "2% 1%" }} className="caption">
        Cualquier documento puede ser restaurado hasta 30 días después de ser
        eliminado.
      </Row>
      <Row>
        <Col xs={5}>
          <CustomSearch
            dataTableInfo={documentList}
            setFilteredDataTable={setFilterDocumentList}
          />
        </Col>
        <Col xs={3}>
          <Form.Group className="body" controlId="Terminos">
            <Form.Check
              className="label__description__default"
              value={filterMyId}
              type="checkbox"
              label="Solo eliminados por mí"
              onChange={(e) => {
                setFilterMyId(e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={filterDocumentList}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "elementos"]}
          fullPagination
          noRecordsFoundLabel={"No se han encontrado resultados"}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorSelected}
          open={openSelected}
          onClose={handleCloseSelected}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {optionMenu.map((doc, index) => (
            <MenuItem
              key={uuidv4()}
              onClick={() => {
                handleManageOptionsMenu(doc.option);
              }}
            >
              <ListItemIcon>{doc.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={`${doc.title} ${
                  doc.option === 0
                    ? TYPE_ITEM_DELETED[selectedElement?.typeElementDeleted]
                    : ""
                }`}
              />
            </MenuItem>
          ))}
        </Menu>
      </Row>
      <ModalInfo
        title={openModalInfo.title}
        open={openModalInfo.isOpen}
        onClose={() => {
          setOpenModalInfo((prev) => ({ ...prev, isOpen: false }));
          navigate(-1);
        }}
        responseData={openModalInfo.message}
      />
      <ModalDecision
        title={"Eliminar"}
        message={`¿Estás seguro de eliminar definitivamente?`}
        agreeText={"Sí, eliminar definitivamente"}
        disagreeText={"No eliminar"}
        handleAgree={() => {
          handleDeletePerma();
        }}
        handleDisagree={() => setOpenModalDecision(false)}
        onClose={() => setOpenModalDecision(false)}
        open={openModalDecision}
      />
      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedElement}
        pageName={getpageName(selectedElement)}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Visualizar"}
        message={ChooseModalComponentByElemente(
          selectedElement,
          documentListData,
          setSelectedElement,
          businessFolderFatherId,
          setOpenModal,
          handlePreviewPdf
        )}
        open={openModal}
        onClose={setOpenModal}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setOpenModal(false);
        }}
        handleDisagree={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
};

const ChooseModalComponentByElemente = (
  selectedElement,
  documentListData,
  setSelectedElement,
  businessFolderFatherId,
  setOpenModal,
  handlePreviewPdf
) => {
  if (selectedElement.typeElementDeleted === "alert") {
    return <ModalComponentAlerts selectedElement={selectedElement} />;
  }
  if (selectedElement.typeElementDeleted === "contractType") {
    return <ModalComponentContractTypes selectedElement={selectedElement} />;
  }
  if (selectedElement.typeElementDeleted === "clause") {
    return <ModalComponentClauses selectedElement={selectedElement} />;
  }
  if (selectedElement.typeElementDeleted === "task") {
    return <ModalComponentTasks selectedElement={selectedElement} />;
  }
  return (
    <ModalComponentSubFolders
      selectedElement={selectedElement}
      documentListData={documentListData}
      setSelectedElement={setSelectedElement}
      businessFolderFatherId={businessFolderFatherId}
      setOpenModal={setOpenModal}
      handlePreviewPdf={handlePreviewPdf}
    />
  );
};

const ModalComponentClauses = ({ selectedElement }) => (
  <>
    <Row>
      <p className="heading__primary-color">{selectedElement.title}</p>
    </Row>
    <br />
    <Row style={{ display: "flex" }}>
      <span className="subheading">
        A favor de: <span className="caption">{selectedElement.inFavor}</span>
      </span>
    </Row>
    <br />
    <Row>
      <span className="subheading">
        Categorías:{" "}
        {selectedElement?.categories?.length > 0 &&
          selectedElement.categories.map((category) => {
            return (
              <span
                key={category._id || category.name}
                style={{ marginRight: "1%" }}
                className="caption custom-badges__secundary"
              >
                {category.name}
              </span>
            );
          })}
      </span>
    </Row>
    <br />
    <Row>
      <span className="subheading">
        Etiquetas:{" "}
        {selectedElement?.labels?.length > 0 &&
          selectedElement.labels.map((tag) => {
            return (
              <span
                key={tag.name}
                style={{ marginRight: "1%" }}
                className="caption custom-badges__disabled"
              >
                {tag.name}
              </span>
            );
          })}
      </span>
    </Row>
    <br />
    <br />
    <Row>
      <span className="caption">
        {/* Text clause */}
        {ReactHtmlParser(selectedElement.text)}
        <ol className="clauses">
          {selectedElement?.subclauses?.map((item, i) => {
            return (
              <li key={uuidv4()} className="justify-text clauses-number">
                <b>{item.subclause?.title && `${item.subclause.title}:`}</b>
                <span className="caption">
                  {ReactHtmlParser(item.subclause?.text)}
                </span>
                <ol>
                  {item.subclause?.childs &&
                    item.subclause?.childs.map((child, i) => {
                      return (
                        <li
                          key={uuidv4()}
                          className="justify-text clauses-number"
                        >
                          <b>{child.title || ""}</b>
                          <span className="caption">
                            {ReactHtmlParser(child.text)}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </li>
            );
          })}
        </ol>
        <br />
        <ol className="paragraphs clauses-number">
          {selectedElement.paragraphs.map((el, i) => {
            return (
              <li
                key={uuidv4()}
                className="justify-text clauses-number"
                numbering={`Parágrafo ${NUMBERINGWORDSO[i + 1]}`}
              >
                <span className="caption">
                  {ReactHtmlParser(el.paragraph?.text)}
                </span>
              </li>
            );
          })}
        </ol>
      </span>
    </Row>
  </>
);

const ModalComponentContractTypes = ({ selectedElement }) => (
  <Container fluid style={{ height: "100%" }}>
    <Row className="heading__primary-color">Partes</Row>
    <Row>
      {
        <Container className="container_text">
          <Row>
            <Col className="heading__primary-color">{` ${
              selectedElement.parts.partA.name || "Sin titulo"
            }`}</Col>
          </Row>
          <Row className="container_text__subclause label">
            {ReactHtmlParser(
              selectedElement.parts.partA.text || "Sin texto final"
            )}
          </Row>
          <Row>
            <Col className="heading__primary-color">{` ${
              selectedElement.parts.partB.name || "Sin titulo"
            }`}</Col>
          </Row>
          <Row className="container_text__subclause label">
            {ReactHtmlParser(
              selectedElement.parts.partB.text || "Sin texto final"
            )}
          </Row>
          {selectedElement.parts.partC && (
            <>
              <Row>
                <Col className="heading__primary-color">{` ${
                  selectedElement.parts.partC.name || "Sin titulo"
                }`}</Col>
              </Row>
              <Row className="container_text__subclause label">
                {ReactHtmlParser(
                  selectedElement.parts.partC.text || "Sin texto final"
                )}
              </Row>
            </>
          )}
          {selectedElement.parts.partD && (
            <>
              <Row>
                <Col className="heading__primary-color">{` ${
                  selectedElement.parts.partD.name || "Sin titulo"
                }`}</Col>
              </Row>
              <Row className="container_text__subclause label">
                {ReactHtmlParser(
                  selectedElement.parts.partD.text || "Sin texto final"
                )}
              </Row>
            </>
          )}
        </Container>
      }
    </Row>
    <Row className="heading__primary-color">Cláusulas</Row>
    <Row>
      {selectedElement.clauses.length > 0 ? (
        selectedElement.clauses.map((cla, index) => (
          <Row key={JSON.stringify(cla)}>
            <Container className="container_text">
              <Row>
                <Col className="heading__primary-color">{`Cláusula ${
                  NUMBERINGWORDSA[index + 1]
                }
                . ${cla?.clause?.title || "Sin titulo"}`}</Col>
              </Row>
              <Row className="container_text__clause-text label">
                {ReactHtmlParser(`<p>${cla?.clause?.text}</p>`)}
              </Row>
            </Container>
            {cla?.clause?.subclauses &&
              cla?.clause?.subclauses.map((sub, index2) => (
                <Container key={uuidv4()} className="container_text__subclause">
                  <Row style={{ alignItems: "center" }}>
                    <Col xs={"auto"} className="heading__primary-color">
                      {`${index + 1}.${index2 + 1}. ${
                        sub.subclause.title || ""
                      }`}
                    </Col>
                    <Col className="container_text__clause-text label">
                      {ReactHtmlParser(`<p>${sub.subclause.text}</p>`)}
                    </Col>
                  </Row>
                  <Row className="container_text__clause-text label"></Row>
                  {sub?.subclause?.childs?.map((child, index4) => (
                    <Container
                      key={uuidv4()}
                      className="container_text__subclause"
                    >
                      <Row>
                        <Col className="heading__primary-color">{`${
                          index + 1
                        }.${index2 + 1}.${index4 + 1}. ${
                          child.title || ""
                        }`}</Col>
                      </Row>
                      <Row className="container_text__clause-text label">
                        {ReactHtmlParser(`<p>${child.text}</p>`)}
                      </Row>
                    </Container>
                  ))}
                </Container>
              ))}
            {cla?.paragraphs &&
              cla?.paragraphs?.map((para, index3) => (
                <Container key={uuidv4()} className="container_text__subclause">
                  <Row>
                    <Col className="heading__primary-color">{`Parágrafo ${
                      NUMBERINGWORDSO[index3 + 1]
                    } ${para?.paragraph?.title || ""}`}</Col>
                  </Row>
                  <Row className="container_text__clause-text label">
                    {ReactHtmlParser(`<p>${para.paragraph.text}</p>`)}
                  </Row>
                </Container>
              ))}
          </Row>
        ))
      ) : (
        <p className="container_text__clause-text label">Sin cláusulas.</p>
      )}
    </Row>
  </Container>
);

const ModalComponentAlerts = ({ selectedElement }) => (
  <>
    <Row>
      <Col className="home-init-cards-row__alert-title">
        {selectedElement.name}
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Fecha de vencimiento de tareas:{" "}
          {
            <small className="caption">
              {dateNormal(selectedElement.tasksDeadline)}
            </small>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Estado de alerta:{" "}
          {
            <MDBBadge
              color=""
              pill
              className="caption"
              style={{
                backgroundColor:
                  STATE_COLOR_ID[selectedElement?.state?.colorId] || "#F9DADE",
                fontSize: "1.2rem",
                borderRadius: "0.8rem",
                padding: "0.4rem",
              }}
            >
              <span className="custom-badge" style={{ color: "black" }}>
                {selectedElement?.state?.name}
              </span>
            </MDBBadge>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Carpeta:{" "}
          {
            <small>
              {selectedElement?.documentAssociated?.folderAssociated?.name}
            </small>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Documento:{" "}
          {<small>{selectedElement?.documentAssociated?.name}</small>}
        </h4>
      </Col>
    </Row>
  </>
);

const ModalComponentTasks = ({ selectedElement }) => (
  <>
    <Row>
      <Col className="home-init-cards-row__alert-title">
        {selectedElement.name}
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Alerta asociada:{" "}
          {<small className="caption">{selectedElement.alertAssociated}</small>}
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Estado de la tarea:{" "}
          {
            <MDBBadge
              color=""
              pill
              className="caption"
              style={{
                backgroundColor:
                  STATE_COLOR_ID[selectedElement?.state?.colorId] || "#F9DADE",
                fontSize: "1.2rem",
                borderRadius: "0.8rem",
                padding: "0.4rem",
              }}
            >
              <span className="custom-badge" style={{ color: "black" }}>
                {selectedElement?.state?.name}
              </span>
            </MDBBadge>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Completada:{" "}
          {
            <small>
              {
                <MDBBadge
                  color=""
                  pill
                  className="caption"
                  style={{
                    backgroundColor: selectedElement?.isCompleted
                      ? "#CCF4E3"
                      : "#FFD7B1",
                    fontSize: "1.2rem",
                    borderRadius: "0.8rem",
                    padding: "0.4rem",
                  }}
                >
                  <span className="custom-badge" style={{ color: "black" }}>
                    {selectedElement?.isCompleted ? "Sí" : "No"}
                  </span>
                </MDBBadge>
              }
            </small>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Usuarios asignados:{" "}
          <small>
            {selectedElement?.assignedUsers?.length > 0
              ? selectedElement?.assignedUsers
                  ?.map((user) => `${user.firstName} ${user.firstSurname}`)
                  .join(", ")
              : "Sin usuarios asignados"}
          </small>
        </h4>
      </Col>
    </Row>
  </>
);

const ModalComponentSubFolders = ({
  selectedElement,
  documentListData,
  setSelectedElement,
  businessFolderFatherId,
  setOpenModal,
  handlePreviewPdf,
}) => (
  <>
    {selectedElement?.businessFolderFatherId && (
      <Row
        style={{
          height: "auto",
          marginBottom: "3%",
          alignItems: "center",
        }}
      >
        <Col
          xs={"auto"}
          onClick={() => {
            const folder = documentListData.find(
              (item) => item._id === businessFolderFatherId
            );
            setSelectedElement(folder);
          }}
        >
          <SvgIcon
            style={{
              width: "2rem",
              height: "2rem",
              border: "1px solid",
              borderRadius: "4px",
              backgroundColor: "#E7F9FC",
              color: "#C5C7D0",
              cursor: "pointer",
            }}
          >
            <ArrowBackIcon />
          </SvgIcon>
        </Col>
        <Col md={"auto"} className="subheading">
          Volver
        </Col>
      </Row>
    )}
    <Row style={{ height: "auto", marginBottom: "3%" }}>
      <Col md={"auto"} className="subheading">
        Nombre:
      </Col>
      <Col className="caption">{selectedElement?.name}</Col>
    </Row>
    <Row style={{ height: "auto", marginBottom: "3%" }}>
      <Col md={"auto"} className="subheading">
        Descripción:
      </Col>
      <Col md={"auto"} className="caption">
        {selectedElement?.description}
      </Col>
    </Row>
    <Row
      style={{
        height: "auto",
        marginBottom: "3%",
      }}
    >
      <Col md={"auto"} className="subheading">
        Etiquetas:
      </Col>
      <Col className="caption">
        <Row style={{ rowGap: "3%", columnGap: "2%" }}>
          {selectedElement?.labels?.length > 0 ? (
            selectedElement.labels.map((label, index) => (
              <Col
                className="drawner-details__labels"
                md={"auto"}
                key={uuidv4()}
              >
                {label.name}
              </Col>
            ))
          ) : (
            <Col md={"auto"} className="caption">
              Sin etiquetas
            </Col>
          )}
        </Row>
      </Col>
    </Row>
    <Row style={{ height: "auto", marginBottom: "3%" }}>
      <Col md={"auto"} className="subheading">
        Documentos:
      </Col>
    </Row>
    {selectedElement?.documents?.length > 0 ? (
      selectedElement.documents
        .filter((document) => document.status === DOCUMENT_STATE.active._id)
        .map((document, index) => (
          <Row style={{ height: "auto", marginBottom: "3%" }} key={uuidv4()}>
            <Col
              md={"auto"}
              className="caption__other-Color-Active"
              onClick={() => {
                setOpenModal(false);
                handlePreviewPdf(document);
              }}
            >
              {document.name}
            </Col>
          </Row>
        ))
    ) : (
      <Row style={{ height: "auto", marginBottom: "3%" }}>
        <Col md={"auto"} className="caption">
          Sin documentos
        </Col>
      </Row>
    )}
    {selectedElement?.subFolders?.length > 0 ? (
      <>
        <Row style={{ height: "auto", marginBottom: "3%" }}>
          <Col md={"auto"} className="subheading">
            Subcarpetas:
          </Col>
        </Row>
        {selectedElement?.subFolders?.map((subfolder, idxSubfolder) => (
          <Row style={{ height: "auto", marginBottom: "3%" }} key={uuidv4()}>
            <Col
              md={"auto"}
              className="caption__other-Color-Active"
              onClick={() => {
                setSelectedElement(subfolder);
              }}
            >
              {subfolder.name}
            </Col>
          </Row>
        ))}
      </>
    ) : (
      <></>
    )}
  </>
);

export default PaperBasket;
