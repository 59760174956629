import {
  createAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import {
  createClause,
  createManyClauses,
  getClauseById,
  getClausesByCompany,
  updateClause,
  updateDeletedClause,
} from "../../../../../../services/clauses/clausesServices";
import { CODES } from "../../../../../../utils/codes";
import { DOCUMENT_STATE } from "../../../../../../utils/documentStates";

export const clauseSlice = createSlice({
  name: "clauses",
  initialState: {
    clausesList: [],
    currentClause: null,
    status: "fetch",
    addNewClauseStatus: "fetch",
    addManyClauseStatus: "",
    updateClauseStatus: "fetch",
    removeClauseStatus: "fetch",
    getClauseByIdStatus: "fetch",
    error: null,
    errorAddNewClause: null,
    errorRemoveClause: null,
    errorUpdateClause: null,
    errorGetClauseById: null,
  },
  reducers: {
    setStatusClause(state, action){
      const status = action.payload.status;
      state.status = status;

    },
    cleanStoreClausesSlice(state) {
      state.currentClause = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClauses.pending, (state, _) => {
        state.status = "loading";
      })
      .addCase(fetchClauses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clausesList = action.payload.data.responseMessage;
      })
      .addCase(fetchClauses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewClause.pending, (state, _) => {
        state.addNewClauseStatus = "loading";
      })
      .addCase(addNewClause.fulfilled, (state, action) => {
        state.addNewClauseStatus = "succeeded";
        if(action.payload.status === CODES.COD_RESPONSE_HTTP_CREATED){
          state.clausesList = action.payload.clausesList;
        }
      })
      .addCase(addNewClause.rejected, (state, action) => {
        state.addNewClauseStatus = "failed";
        state.errorAddNewClause = action.error.message;
      })
      .addCase(removeClause.pending, (state, _) => {
        state.removeClauseStatus = "loading";
      })
      .addCase(removeClause.fulfilled, (state, action) => {
        state.removeClauseStatus = "succeeded";
        if(action.payload.status === CODES.COD_RESPONSE_HTTP_OK){
          const newClausesList = state.clausesList.filter(
            (clause) => clause.id !== action.payload.clauseId
          );
          state.clausesList = newClausesList;
        }
      })
      .addCase(removeClause.rejected, (state, action) => {
        state.removeClauseStatus = "failed";
        state.errorremoveClause = action.error.message;
      })
      .addCase(fetchClauseById.pending, (state, _) => {
        state.getClauseByIdStatus = "loading";
      })
      .addCase(fetchClauseById.fulfilled, (state, action) => {
        state.getClauseByIdStatus = "succeeded";
        state.currentClause = action.payload.data.responseMessage;
      })
      .addCase(fetchClauseById.rejected, (state, action) => {
        state.getClauseByIdStatus = "failed";
        state.errorGetClauseById = action.error.message;
      })
      .addCase(modifyClause.pending, (state, _) => {
        state.updateClauseStatus = "loading";
      })
      .addCase(modifyClause.fulfilled, (state, action) => {
        state.updateClauseStatus = "succeeded";
        if(action.payload.status === CODES.COD_RESPONSE_HTTP_OK){
          state.clausesList = action.payload.clausesList;
        }
      })
      .addCase(modifyClause.rejected, (state, action) => {
        state.updateClauseStatus = "failed";
        state.errorUpdateClause = action.error.message;
      })
      .addCase(setClauseByIdStatus, (state, action) => {
        state.currentClause = null
        state.getClauseByIdStatus = action.payload.status;
      })
      .addCase(addManyClauses.pending, (state, _) => {
        state.addManyClauseStatus = "loading";
      })
      .addCase(addManyClauses.fulfilled, (state, action) => {
        state.addManyClauseStatus = "succeeded";
        if (action.payload.status !== CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
          state.clausesList = action.payload.clausesList;
        }
      })
      .addCase(addManyClauses.rejected, (state, action) => {
        state.addManyClauseStatus = "failed";
        state.errorAddNewClause = action.error.message;
      });
  },
});


export const getClausesList = (state) => state.clauses.clausesList;

export const getClausesStatus = (state) => state.clauses.status;

export const getCurrentClause = (state) => state.clauses.currentClause;

export const { setStatusClause, cleanStoreClausesSlice } = clauseSlice.actions;

export const getCurrentClauseStatus = (state) =>
  state.clauses.getClauseByIdStatus;

export const setClauseByIdStatus = createAction(
  "clauses/setClauseByIdStatus",
  () => ({
    payload: { status: "fetch" },
  })
);

export const fetchClauses = createAsyncThunk(
  "clauses/fetchClauses",
  async (textSearch) => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    let obj = {
      company,
      corporateUnit,
      searchTerm: textSearch,
    };
    const response = await getClausesByCompany(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export const addManyClauses = createAsyncThunk(
  "clauses/addManyClause",
  async (request, { rejectWithValue }) => {
    try {
      const company = localStorage.getItem("company");
      const response = await createManyClauses({...request, company});
      if (
        response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        response.data.success
      ) {
        const companyId = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company: companyId,
          corporateUnit,
        };
        const clausesResponse = await getClausesByCompany(obj);
        return {
          status: response.status,
          data: response.data,
          clausesList: clausesResponse.data.responseMessage,
        };
      }
      return {
        status: response.status,
        data: response.data,
        clausesList: [],
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addNewClause = createAsyncThunk(
  "clauses/addNewClause",
  async (request, { rejectWithValue }) => {
    try {
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const response = await createClause({...request, corporateUnit});
      if (
        response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        response.data.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          company: companyId,
          corporateUnit,
        };
        const clausesResponse = await getClausesByCompany(obj);
        return {
          status: response.status,
          data: response.data,
          clausesList: clausesResponse.data.responseMessage,
        };
      }
      return {
        status: response.status,
        data: response.data
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const modifyClause = createAsyncThunk(
  "clauses/updateClause",
  async (request, { rejectWithValue }) => {
    try {
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const company = localStorage.getItem("company");
      const response = await updateClause({...request, company, corporateUnit});
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          company: companyId,
          corporateUnit,
        };
        const clausesResponse = await getClausesByCompany(obj);
        return {
          status: response.status,
          data: response.data,
          clausesList: clausesResponse.data.responseMessage,
        };
      }
      return {
        status: response.status,
        data: response.data
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fetchClauseById = createAsyncThunk(
  "clauses/clauseDetail",
  async (request, { rejectWithValue }) => {
    try {
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let obj = {
        clauseId: request,
        corporateUnit,
      };
      const response = await getClauseById(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const removeClause = createAsyncThunk(
  "clauses/removeClause",
  async (request, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      const corporateUnitId = localStorage.getItem("corporateUnitId");
      let obj = {
        id: request,
        company: companyId,
        corporateUnit: corporateUnitId,
        state: DOCUMENT_STATE.deleted._id
      };
      const response = await updateDeletedClause(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        let obj = {
          company: companyId,
          corporateUnit: companyId,
        };
        const clausesResponse = await getClausesByCompany(obj);
        return {
          status: response.status,
          data: response.data,
          clauseId: request,
          clausesList: clausesResponse.data.responseMessage,
        };
      }
      return {
        status: response.status,
        data: response.data
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export default clauseSlice.reducer;
