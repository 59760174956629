import { Button } from "@mui/material";
import { MDBBadge } from "mdbreact";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import { statusList } from "../../../../../../utils/statusExternalRequest";
const InfoStatus = () => {
  const navigate = useNavigate();

  const getBackgroundColor = (status) => {
    switch (status) {
      case "Nueva":
        return "#CCF4E3";
      case "Revisión solicitante":
        return "#CBECEE";
      case "Revisión abogado":
        return "#FFD7B1";
      case "Solucionada":
        return "#E6E9EF";
      default:
        return "#F9DADE";
    }
  };

  return (
    <Container>
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={"Información acerca de los estados de una solicitud"}
        />
      </Row>
      <br />
      <br />
      <br />
      <div
        className="data-table dt-list-items"
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "calc(100vh - 10rem)",
        }}
      >
        {statusList.map((status, index) => (
          <Row key={JSON.stringify(status)} style={{ margin: "2% 0" }}>
            <Col md={2}>
              <MDBBadge
                color=""
                pill
                style={{
                  backgroundColor: getBackgroundColor(status?.Status),
                  fontSize: "1.5rem",
                  borderRadius: "0.8rem",
                  padding: "0.4rem",
                }}
              >
                <span className="heading__subtitle">
                  {status.Status.toLocaleLowerCase().replace(/^\w/, (c) =>
                    c.toUpperCase()
                  )}
                </span>
              </MDBBadge>
            </Col>
            <Col className="label__description">{status.description}</Col>
          </Row>
        ))}
        <Row className="justify-content-md-end" style={{ marginTop: "20%" }}>
          <Col md={1}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => {
                navigate(-1);
              }}
            >
              Regresar
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default InfoStatus;
