export const customListPlugin = {
  name: "customListPlugin",
  display: "submenu",
  title: "Custom List",
  add: function (core, targetElement) {
    let listDiv = this.setSubmenu.call(core);

    var self = this;
    listDiv.querySelectorAll(".se-btn-list").forEach(function (btn) {
      btn.addEventListener("click", self.onClick.bind(core));
    });

    core.initMenuTarget(this.name, targetElement, listDiv);
  },
  setSubmenu: function () {
    const listDiv = this.util.createElement("DIV");
    listDiv.className = "se-submenu se-list-layer";
    listDiv.innerHTML =
      '<div class="se-list-inner"><ul class="se-list-basic">' +
      '<li><button type="button" class="se-btn-list" value="upper-alpha"> Numeración con letras </button></li>' +
      '<li><button type="button" class="se-btn-list" value="upper-roman">Numeración con números romanos</button></li>' +
      "</ul></div>";

    return listDiv;
  },
  onClick: function (e) {
    const value = e.target.value; // 'upper-roman' para números romanos o 'upper-alpha' para letras
    this.execCommand("insertOrderedList"); // Inserta una nueva lista ordenada

    // Encuentra la lista recién insertada y cambia su estilo
    const selectionNode = this.getSelection().anchorNode;
    const list =
      selectionNode.tagName === "OL"
        ? selectionNode
        : this.util.getParentElement(selectionNode, "OL");
    if (list) {
      list.style.listStyleType = value;
    }

    this.submenuOff(); // Cierra el submenú
  },
};
