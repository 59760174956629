import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CODES } from "../../../../../../utils/codes";
import {
  contractTypeCreate,
  getContractsTypesByCompany,
  updateDeletedContractTypes,
} from "../../../../../../services/contractTypes/contractTypesService";
import { DOCUMENT_STATE } from "../../../../../../utils/documentStates";



export const contractTypeSlice = createSlice({
  name: "contractTypes",
  initialState: {
    contractTypesList: [],
    currentContractType: null,
    status: "fetch",
    createStatus: "",
    deleteStatus: "",
  },
  reducers: {
    setStatusFetchContractTypes(state, action) {
      state.status = "fetch";
    },
    cleanStoreContractTypeSlice(state) {
      state.currentContractType = null;
    },
    setCurrentContractType(state, action) {
      state.currentContractType = action.payload.contractType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractTypes.pending, (state, _) => {
        state.status = "loading";
      })
      .addCase(fetchContractTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contractTypesList = action.payload.data.responseMessage;
      })
      .addCase(fetchContractTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createContractTypes.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          state.contractTypesList = action.payload.contractTypes;
        }
      })
      .addCase(createContractTypes.pending, (state, action) => {
        state.createStatus = "loading";
      })
      .addCase(createContractTypes.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteContractTypes.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK) {
          state.contractTypesList = action.payload.contractTypes;
        }
      })
      .addCase(deleteContractTypes.pending, (state, action) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteContractTypes.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setStatusFetchContractTypes, cleanStoreContractTypeSlice } =
  contractTypeSlice.actions;

export const { setCurrentContractType } = contractTypeSlice.actions;

export const getStatus = (state) => state.contractTypes.status;

export const getContractsTypesList = (state) =>
  state.contractTypes.contractTypesList;

export const getCurrentContractType = (state) =>
  state.contractTypes.currentContractType;

export const fetchContractTypes = createAsyncThunk(
  "contractTypes/fetchContractTypes",
  async ({ isPublish }) => {
    const companyId = localStorage.getItem("company");
    let obj = {
      companyId: companyId,
      isPublish,
    };
    const response = await getContractsTypesByCompany(obj);
    if (response.data.success) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export const createContractTypes = createAsyncThunk(
  "contractTypes/createContractTypes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await contractTypeCreate(data);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        response.data.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          companyId: companyId,
          isPublish: false,
        };
        const responseFetch = await getContractsTypesByCompany(obj);
        if (responseFetch.data.success) {
          return {
            status: response.status,
            data: response.data,
            contractTypes: responseFetch.data.responseMessage,
          };
        }
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteContractTypes = createAsyncThunk(
  "contractTypes/deleteContractTypes",
  async (id, { rejectWithValue }) => {
    try {
      const corporateUnitId = localStorage.getItem("corporateUnitId");
      const companyId = localStorage.getItem("company");
      const response = await updateDeletedContractTypes({
        id,
        company: companyId,
        corporateUnit: corporateUnitId,
        state: DOCUMENT_STATE.deleted._id,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        let obj = {
          companyId: companyId,
        };
        const responseFetch = await getContractsTypesByCompany(obj);
        if (responseFetch.data.success) {
          return {
            status: response.status,
            data: response.data,
            contractTypes: responseFetch.data.responseMessage,
          };
        }
      }
      return {
        status: response.status,
        data: response.data,
        id,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export default contractTypeSlice.reducer;
