import { Button } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import { useDispatch, useSelector } from "react-redux";
import {
  setClausesSelected,
  setClausesSelectedInMinute,
  setMinuteSelected,
} from "../../../../../../parts/document/currentDocumentSlice";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LoadingContent from "../../../../../../components/loadingContent";
import ClearIcon from "@mui/icons-material/Clear";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import {
  fetchContractTypes,
  getContractsTypesList,
  getStatus,
} from "../../library/contractTypes/contractTypesSlice";
import {
  fetchTagsContractTypes,
  getTagContractTypesList,
  getTagContractTypesStatus,
} from "../../../../../../parts/manageTags/contractTypes/tagContractTypesSlice";
import { Mixpanel } from "../../../../../../utils/mixPanel";
import { setCreateFromRequest } from "../../request/requestSlice";

const SelectMinuta = () => {
  /**Basic data information */
  //TABLE COLUMNS
  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "icon",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Minuta
        </label>,
      ],
      field: "minuta",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          A favor de
        </label>,
      ],
      field: "inFavor",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Etiquetas
        </label>,
      ],
      field: "labels",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];

  const rows = [];

  /** Get Params */

  const [searchParams] = useSearchParams();
  const fromCreate = searchParams.get("from");
  const requestId = searchParams.get("requestId");

  /** React Router */

  const navigate = useNavigate();

  /**Use Selector */
  const dispatch = useDispatch();
  const status = useSelector(getStatus);

  //TAGS
  const statusTagsContractTypes = useSelector(getTagContractTypesStatus);
  const tagContractTypesList = useSelector(getTagContractTypesList);

  //contractTypes
  const contractTypes = useSelector(getContractsTypesList);

  /**Use States */

  //TABLE DATA
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  //TAGS
  const [tagsSelected, setTagsSelected] = useState([]);

  //LOADING PAGE
  const [isLoading, setIsLoading] = useState(true);

  //MODAL
  const [selectedMinuta, setSelectedMinuta] = useState({});

  const [openSelectedModal, setOpenSelectedModal] = useState(false);

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  /** Use Effect */

  //TABLE DATA

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (status === "fetch") {
          dispatch(fetchContractTypes({ isPublish: false }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [status, dispatch]);

  useEffect(() => {
    if (status === "loading" && statusTagsContractTypes === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [status, statusTagsContractTypes]);

  useEffect(() => {
    const formatRows = buildRows(contractTypes);
    setFilteredDataTable({
      columns: columns,
      rows: formatRows,
    });
    setDataTable({
      columns: columns,
      rows: formatRows,
    });
  }, [contractTypes]);

  //TAGS

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsContractTypes === "fetch") {
          dispatch(fetchTagsContractTypes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsContractTypes, dispatch]);

  /** Handles */

  //CONTRUCCIÓN DE ROWS PARA LA DATATABLE MINUTAS
  const buildRows = (rows) => {
    if (typeof rows !== "string") {
      const returnRows = rows.map((contracType, index) => {
        return {
          _id: contracType._id,
          icon: (
            <Row style={{ justifyContent: "start", alignItems: "center" }}>
              <Col xs={"auto"}>
                <BusinessCenterIcon color="#00374F" fontSize="large" />
              </Col>
            </Row>
          ),
          minuta: contracType.name,
          inFavor: contracType.inFavor,
          labels: contracType.labels.map((label) => label.name).join(", "),
          actions: (
            <Row>
              <Button
                className="button-primary-folders__select"
                onClick={() => {
                  handleSelectedMinuta(contracType);
                  Mixpanel.track("Crear desde Minuta seleccionada ", {
                    email: payloadToken?.email,
                    companyName: payloadToken?.companyName,
                  });
                }}
                startIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
              >
                Seleccionar
              </Button>
            </Row>
          ),
        };
      });
      return returnRows;
    }
    return [];
  };

  //TAGS
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });

      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  //MINUTAS
  const handleSelectedMinuta = (minuta) => {
    setSelectedMinuta(minuta);
    setOpenSelectedModal(true);
  };

  //MODAL
  const handleClose = () => {
    setOpenSelectedModal(false);
  };

  //Agree

  const handleAgreeMinuta = () => {
    const newClauses = selectedMinuta.clauses
      .filter((cla) => cla.clause)
      .map((clause) => {
        let arrayParts = [];
        const parts = clause.clause.parts;
        if (parts) {
          if (parts.partA) {
            arrayParts.push(parts.partA);
          }
          if (parts.partB) {
            arrayParts.push(parts.partB);
          }
          if (parts.partC) {
            arrayParts.push(parts.partC);
          }
          if (parts.partD) {
            arrayParts.push(parts.partD);
          }
        }
        return { clause: { ...clause.clause, parts: arrayParts } };
      });

    dispatch(setMinuteSelected({ ...selectedMinuta, clauses: newClauses }));
    setOpenSelectedModal(false);
    if (fromCreate === "REQUEST") {
      dispatch(setClausesSelectedInMinute());
      dispatch(setCreateFromRequest({ requestId, fromCreate }));
      navigate("contract-info/create-document");
    } else {
      navigate("contract-info");
    }
  };

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container"
    >
      {/**Loading page */}
      {isLoading && <LoadingContent />}

      {/**Miga de Pan */}
      <Row className="col-start">
        <Breadcrum />
      </Row>
      {/** Nombre, pasos, notificaciones */}
      <Row>
        <Col md={3} className="col-start">
          <SectionTitle title="Seleccionar minuta de contrato" />
        </Col>
        <Col md={8}></Col>
      </Row>
      <br />
      <Row className="text-description">
        Selecciona la minuta desde donde quieres crear tu nuevo documento.
      </Row>
      <br />
      <br />
      {/**Filtros */}
      <Row>
        <Col md={5} className="col-start">
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col></Col>
        <Col className="col-flex"></Col>
        <Col className="col-flex">
          <FilterTag
            tagsList={tagContractTypesList}
            list={filteredDataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
            widthStyle="153px"
            heightStyle="36px"
          />
        </Col>
      </Row>
      <br />
      <br />
      {/**Filtro de tags */}
      <Row>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      {/**Tabla */}
      <Row className="custom-datatable__container dt-list-icon">
        {
          <MDBDataTableV5
            noRecordsFoundLabel="No se encontraron resultados"
            hover
            pagingTop
            searchBottom={false}
            entries={6}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "minutas"]}
            fullPagination
          />
        }
      </Row>
      {/**Modal de confirmacion */}
      <ModalDecision
        title={"Seleccionar minuta"}
        message={`¿Estás seguro que quieres usar la minuta de ${selectedMinuta.name}?`}
        open={openSelectedModal}
        onClose={handleClose}
        agreeText="Continuar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeMinuta}
        handleDisagree={handleClose}
      />
    </Container>
  );
};

export default SelectMinuta;
