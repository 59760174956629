import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import CustomSearchFilter from "../Search/CustomSearchFilter";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { reorderClauseIndex } from "../../parts/minutas/clausesSelectedSlice";
import { useDispatch } from "react-redux";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalSortList = ({ index, list }) => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [newPosition, setNewPosition] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    const result = {
      source: { index: index },
      destination: { index: newPosition }
    }
    dispatch(reorderClauseIndex({ result }));
    setOpen(false);
    setNewPosition(null);
  };
  const handleOpenModalSortList = () => {
    setOpen(true);
    setFilteredData(list.filter((__, indexFilter) => indexFilter !== index));
  }
  return (
    <div style={{ height: "100%" }}>
      <Button
        type="button"
        variant="contained"
        startIcon={<ImportExportOutlinedIcon fontSize="large" />}
        className="custom-input__button__secondary-color"
        style={{
          height: "100%",
          border: "1px solid #00374F",
        }}
        onClick={handleOpenModalSortList}
      >
        Ordenar
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">Ordenar cláusula</label>
        </DialogTitleContainer>
        <DialogContent dividers className="body-content">
          <Container>
            <Row className="mb-4">
              <Col xs={"auto"}>
                La cláusula{" "}
                <b>{list[index]?.title || list[index]?.clause?.title || ""}</b> se
                ubicará arriba de la cláusula que seleccione
              </Col>
            </Row>
            <Row>
              <CustomSearchFilter
                list={list}
                setFilteredData={setFilteredData}
                placeholder="Buscar cláusula"
              />
            </Row>
            <br />
            <Row className="custom-radio__container">
              <RadioGroup name="radio-group-list">
                {filteredData.map((clause, i) => (
                  <p
                    key={clause?._id}
                    className="heading__primary-color"
                    style={{
                      padding: "1rem",
                      borderBottom: "1px solid #E6E9EF",
                    }}
                  >
                    <Radio
                      value={i}
                      onClick={(e) => {
                        setNewPosition(i);
                      }}
                      style={{
                        ".MuiRadioRoot": {
                          width: "1.15em",
                          height: "1.15em",
                        },
                      }}
                    />
                    {clause?.title || clause?.clause?.title}
                  </p>
                ))}
              </RadioGroup>
            </Row>
          </Container>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleAgree}
            >
              Aceptar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSortList;
