import { Button } from "@mui/material";
import { Col, Container, Form, Row } from "react-bootstrap";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { setInitialInfoLibrary } from "../../../../../../parts/document/currentDocumentSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import { CLAUSES_INFO } from "../../../../../../utils/popoverInfo";
import { Mixpanel } from "../../../../../../utils/mixPanel";

const FormLibraryContract = () => {
  /**
   * Constantes por comodidad
   */

  const placeholders = ["Rol A", "Rol B", "Rol C", "Rol D"];
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  /**
   * Esquemas
   */
  const schemaInfoDocument = yup.object().shape({
    documentName: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(250, "Máximo 250 caracteres permitidos")
      .matches(/^[^#?!@$%^&*-]+$/, "Caracteres #?!@$%^&*- no permitidos"),
    parts: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required("*Este campo es obligatorio"),
        })
      )
      .test("unique", "*Los roles no deben ser iguales", function (values) {
        if (values.some((part) => !part.name)) return true;
        const names = values.map((part) => part.name.trim().toLowerCase());
        return new Set(names).size === names.length;
      }),
  });

  /**
   * UseForm
   */

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaInfoDocument),
  });

  const {
    fields: partsFields,
    append: partsAppend,
    remove: partsRemove,
  } = useFieldArray({
    control,
    name: "parts",
  });

  /**
   * UseSelector
   */

  const dispatch = useDispatch();

  /**
   * UseNavigate
   */

  const navigate = useNavigate();

  /**
   * UseEffect
   */

  useEffect(() => {
    const mockPart = {
      name: "",
      text: "",
      blankspaces: [],
      signatories: [],
    };

    partsAppend(mockPart);
    partsAppend(mockPart);
  }, []);

  /**
   * Handles
   */

  const handleSubmitInfo = (data) => {
    const cleanParts = data.parts.map((part) => ({
      ...part,
      name: part.name.trim(),
    }));
    const finalObj = {
      title: data.documentName,
      parts: cleanParts,
    };

    Mixpanel.track("Título del contrato desde cláusula exitoso ", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
    dispatch(setInitialInfoLibrary(finalObj));

    navigate("clause-selection");
  };

  const handleAddPart = () => {
    const newPart = {
      name: "",
      text: "",
      blankspaces: [],
      signatories: [],
    };

    partsAppend(newPart);
  };

  const handleRemovePart = (index) => {
    partsRemove(index);
  };

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row className="col-start" style={{ height: "7%" }}>
        <Breadcrum />
      </Row>

      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Datos del documento" />
        </Col>

        <Col md={9}></Col>

        {/* <Col className="col-flex">
          <Steps currentStep="3" totalSteps="4" />
        </Col> */}

        {/* <Col className="col-flex" md={"auto"} style={{textAlign: "right"}}>
          <IconButton size="large">
            <NotificationsNoneOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </IconButton>
        </Col> */}
      </Row>

      <Row style={{ height: "90%" }}>
        <Form onSubmit={handleSubmit(handleSubmitInfo)}>
          <Row style={{ height: "20%" }}>
            <Container fluid style={{ height: "100%" }}>
              <Row style={{ height: "45%" }} className="heading-black__title">
                Título del contrato para visualizar dentro del documento
              </Row>

              <Row style={{ height: "50%" }} className="col-start">
                <Form.Group>
                  <Form.Control
                    {...register("documentName")}
                    placeholder="Nombre de documento"
                    className="field-container"
                    bsPrefix={
                      errors.documentName
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                    maxLength={250}
                  />
                </Form.Group>
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  <p className="caption custom-input__error mb-2">
                    {errors.documentName?.message}
                  </p>

                  <label
                    className={
                      watch("documentName")?.length == 250
                        ? "caption__warning"
                        : "caption__primary-color"
                    }
                  >
                    Máximo 250 caracteres permitidos
                  </label>
                </div>
              </Row>
            </Container>
          </Row>
          <Row style={{ height: "50%" }}>
            <Container fluid>
              <Row style={{ height: "15%" }}>
                <Col md={"auto"} className="heading-black">
                  Roles contractuales
                </Col>
                <Col md={"auto"} className="col-flex">
                  <PopoverInfo section={CLAUSES_INFO.partsDefinitionContract} />
                </Col>
              </Row>

              {/** AÑADIR NUEVAS PARTES */}
              {partsFields.map((_, index) => (
                <Row
                  key={_.id}
                  index={index}
                  style={{ height: "17%" }}
                  className="col-start"
                >
                  <Col md={5}>
                    <Form.Group>
                      <Form.Control
                        {...register(`parts.${index}.name`)}
                        placeholder={placeholders[index]}
                        style={{ width: "98%" }}
                        className={
                          errors.parts
                            ? errors.parts[index]
                              ? "input-group-container__no-icon-error label text-fields"
                              : "input-group-container__no-icon label text-fields"
                            : "input-group-container__no-icon label text-fields"
                        }
                      />
                    </Form.Group>
                  </Col>
                  {index > 1 && (
                    <Col xs={1}>
                      <Button
                        variant="contained"
                        size="small"
                        className="custom-input__button__primary-color"
                        onClick={() => {
                          handleRemovePart(index);
                        }}
                      >
                        <DeleteOutlineIcon fontSize="large" />
                      </Button>
                    </Col>
                  )}
                  <div className="caption custom-input__error">
                    {errors.parts
                      ? errors.parts[index]
                        ? errors.parts[index].name.message
                        : ""
                      : ""}
                  </div>
                </Row>
              ))}
              {errors?.parts?.message && (
                <div className="caption custom-input__error">
                  {errors?.parts?.message}
                </div>
              )}
              <Row className="add-part__container">
                <Button
                  className={
                    partsFields.length >= 4 ? "add-part__disabled" : "add-part"
                  }
                  startIcon={<AddIcon />}
                  onClick={() => {
                    handleAddPart();
                  }}
                  disabled={partsFields.length >= 4}
                >
                  Agregar parte
                </Button>
              </Row>
            </Container>
          </Row>

          <Row>
            <Button
              className="button-primary-folders__select"
              endIcon={<ArrowForwardIcon />}
              type="submit"
            >
              Continuar
            </Button>
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default FormLibraryContract;
