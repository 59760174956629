import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../components/loadingContent";

import {
  fetchClauses,
  getClausesList,
  getClausesStatus,
} from "../../pages/views/userService/modules/library/clauses/clauseSlice";

import { NUMBERINGWORDSO } from "../../utils/numberings";

import FilterTag from "../filters/FilterTag";
import { fetchTagsNoUpdate } from "../manageTags/tagSlice";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import ModalDecision from "../../components/Modals/modalDecision";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const ImportClauseModal = ({ show, setShow, onSelect }) => {
  const dispatch = useDispatch();

  const clausesStatus = useSelector(getClausesStatus);
  const clausesList = useSelector(getClausesList);

  const [tagsClausesList, setTagClauseList] = useState([]);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [clauses, setClauses] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [clausesSelected, setClausesSelected] = useState([]);
  const [isOpenModalTitleDecision, setIsOpenModalTitleDecision] =
    useState(false);
  const [countChecked, setCountChecked] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChecked = (e, clauses) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(1);
      setClausesSelected([clauses]);
    } else {
      setCountChecked(0);
      setClausesSelected(
        clausesSelected.filter((clause) => clause._id !== clauses._id)
      );
    }
  };
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.name));
      });
      setFilteredClauses(filterTags);
    } else {
      setFilteredClauses(clauses);
    }
  };

  const resetModalInfo = () => {
    setShow(false);
    setCountChecked(0);
    setClausesSelected([]);
    setFilteredClauses(clauses);
  };

  const handleAgreeModalTitleDecision = () => {
    resetModalInfo();
    onSelect(clausesSelected[0], true);
    setIsOpenModalTitleDecision(false);
  };

  const handleDiseagreeModalTitleDecision = () => {
    resetModalInfo();
    onSelect(clausesSelected[0], false);
    setIsOpenModalTitleDecision(false);
  };
  /* UseEffects Fetch Clauses Tags */

  useEffect(() => {
    try {
      const getTags = async () => {
        const tagResponse = await dispatch(
          fetchTagsNoUpdate("CLAUSES", false)
        ).unwrap();
        setTagClauseList(tagResponse?.data?.responseMessage);
      };

      getTags();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (tagsClausesList) {
      setFilteredTags(tagsClausesList);
    }
  }, [tagsClausesList]);

  /* UseEffects Fetch Clauses */

  useEffect(() => {
    if (clausesStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [clausesStatus]);

  useEffect(() => {
    try {
      if (clausesStatus === "fetch") {
        dispatch(fetchClauses());
      }
    } catch (error) {
      console.error(error);
    }
  }, [clausesStatus, dispatch]);

  useEffect(() => {
    if (clausesList) {
      setFilteredClauses(clausesList);
      setClauses(clausesList);
    }
  }, [clausesList]);

  return (
    <>
      <Modal
        show={show}
        fullscreen
        onHide={() => {
          setShow(false);
        }}
      >
        {isLoading && <LoadingContent />}
        <Modal.Header>
          <Modal.Title>
            <p className="label__primary-color__page-title">
              Importar cláusulas
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Busca las cláusulas y selecciona las subcláusulas que quieres
                  añadir al contrato:
                </p>
              </Col>
            </Row>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <CustomSearchFilter
                  list={clauses}
                  setFilteredData={setFilteredClauses}
                  placeholder="Buscar cláusula"
                />
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} cláusulas seleccionadas
                </p>
              </Col>

              <Col xs={"auto"}>
                <FilterTag
                  list={filteredClauses}
                  tagsList={filteredTags}
                  setFilteredInfo={setFilteredClauses}
                  handleTagsSelected={handleTagsSelected}
                  currentTagSelected={tagsSelected}
                />
              </Col>
            </Row>
            <br />
            <Row sm={"auto"} style={{ justifyContent: "right" }}>
              {tagsSelected.map((tag, idx) => {
                return (
                  <Col xs={"auto"} key={tag?._id}>
                    <span className="caption custom-badges__disabled">
                      {tag + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
            <br />
            <br />
            <Row>
              <div className="custom-accordion__container">
                {filteredClauses.length > 0 ? (
                  filteredClauses.map((clause, index) => {
                    return (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={clause?._id}
                      >
                        <Col>
                          <Accordion className="custom-accordion">
                            <Row style={{ alignItems: "center" }}>
                              <Col xs={"auto"}>
                                <Checkbox
                                  checked={clausesSelected.some(
                                    (item) => item?._id === clause?.id
                                  )}
                                  onClick={(e) => handleChecked(e, clause)}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 28,
                                    },
                                    ".MuiAccordionSummary-gutters": {
                                      disableGutters: true,
                                    },
                                    color: "#00374f",
                                    "&.Mui-checked": {
                                      color: "#00374f",
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <AccordionSummary
                                  sx={{
                                    ".MuiAccordionSummary-content": {
                                      alignItems: "center",
                                    },
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Col sm={4}>
                                    <p className="heading__primary-color">
                                      {clause.title + " "}
                                    </p>
                                  </Col>
                                  <Col style={{ textAlign: "right" }}>
                                    <span className="subheading">
                                      A favor de: {clause.inFavor + " "}
                                    </span>
                                  </Col>
                                  {clause.categories.length > 0 && (
                                    <Col
                                      xs={"auto"}
                                      style={{ textAlign: "right" }}
                                    >
                                      <Col>
                                        <span className="subheading">
                                          Categorías:{" "}
                                          {clause.categories.map(
                                            (category, i) => {
                                              return (
                                                <span
                                                  key={category?._id}
                                                  style={{ marginRight: "1%" }}
                                                  className="caption custom-badges__secundary"
                                                >
                                                  {category.name}
                                                </span>
                                              );
                                            }
                                          )}
                                        </span>
                                      </Col>
                                    </Col>
                                  )}
                                </AccordionSummary>
                              </Col>
                            </Row>
                            <AccordionDetails
                              style={{
                                width: "100%",
                                boxShadow:
                                  "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                              }}
                            >
                              <Row>
                                <span className="subheading">
                                  Etiquetas:{" "}
                                  {clause.labels.map((tag, i) => {
                                    return (
                                      <span
                                        key={tag?._id}
                                        style={{ marginRight: "1%" }}
                                        className="caption custom-badges__disabled"
                                      >
                                        {tag.name}
                                      </span>
                                    );
                                  })}
                                </span>
                              </Row>
                              <br />
                              <br />
                              <Row>
                                <span className="caption">
                                  {/* Text clause */}
                                  <Row style={{ alignItems: "center" }}>
                                    <Col>{ReactHtmlParser(clause.text)}</Col>
                                  </Row>
                                  <br />
                                  <br />
                                  {/* Mapea estado de las subclausulas para selecionara */}
                                  {clause?.subclauses?.map((item, i) => (
                                    <Row key={item?._id}>
                                      <Col xs={"auto"}>
                                        <b>
                                          {`${i + 1}. `}
                                          {item?.subclause?.title &&
                                            `${item?.subclause?.title}: `}
                                        </b>
                                      </Col>
                                      <Col>
                                        <span className="caption">
                                          {ReactHtmlParser(
                                            item?.subclause?.text
                                          )}
                                        </span>
                                      </Col>
                                      {item?.subclause?.childs &&
                                        item?.subclause?.childs.map(
                                          (child, index) => (
                                            <Row
                                              key={child?._id}
                                              style={{ marginLeft: "2rem" }}
                                            >
                                              <Col xs={"auto"}>
                                                <b>
                                                  {`${i + 1}.${index + 1}. `}
                                                  {child?.title}
                                                </b>
                                              </Col>
                                              <Col>
                                                <span className="caption">
                                                  {ReactHtmlParser(child?.text)}
                                                </span>
                                              </Col>
                                            </Row>
                                          )
                                        )}
                                    </Row>
                                  ))}
                                  <br />
                                  <ol className="paragraphs clauses-number">
                                    {clause.paragraphs.map((el, i) => {
                                      return (
                                        <li
                                          key={el?._id}
                                          className="justify-text clauses-number"
                                          numbering={`Parágrafo ${
                                            NUMBERINGWORDSO[i + 1]
                                          }`}
                                        >
                                          <span className="caption">
                                            {ReactHtmlParser(
                                              el.paragraph?.text
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ol>
                                </span>
                              </Row>
                            </AccordionDetails>
                          </Accordion>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography variant="h5">
                    No se han encontrado resultados
                  </Typography>
                )}
              </div>
            </Row>
            {/* Botones de confirmación */}
            <Row
              xs={"auto"}
              style={{ marginTop: "1.5rem", justifyContent: "end" }}
            >
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={resetModalInfo}
                >
                  <p className="button">Regresar</p>
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  disabled={!clausesSelected.length}
                  onClick={() => {
                    setIsOpenModalTitleDecision(true);
                  }}
                >
                  <p className="button">Importar cláusulas</p>
                </Button>
              </Col>
            </Row>

            <ModalDecision
              title={"Importar título"}
              message={"¿Quieres importar el título de la cláusula?"}
              agreeText={"Incluir título"}
              disagreeText={"No incluir título"}
              handleAgree={handleAgreeModalTitleDecision}
              handleDisagree={handleDiseagreeModalTitleDecision}
              open={isOpenModalTitleDecision}
              onClose={setIsOpenModalTitleDecision}
              agreeIconStart={<CheckCircleOutlineOutlinedIcon />}
              disagreeIconStart={<CancelOutlinedIcon />}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { ImportClauseModal };
