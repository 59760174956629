import React from "react";
import TimelineRegister from "../components/TimelineBar/Timeline";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ModalSupport from "../components/Modals/modalSupport";

const RegisterBar = ({ listPhases }) => {
  const navigate = useNavigate();

  return (
    <Container fluid style={{ height: "inherit" }}>
      <Row style={{ height: "15%" }}>
        <img
          src={require("../assets/svg/logoLight.svg").default}
          alt="Logo LegalIA"
        />
      </Row>
      <Row style={{ height: "70%" }}>
        <hr />
        <Container fluid style={{ height: "100%" }}>
          <Row style={{ margin: "10% 0" }}>
            <p className="heading">Creación de cuenta</p>
            <p className="caption">usuario administrador</p>
          </Row>
          <Row>
            <TimelineRegister timelineItems={listPhases} />
          </Row>
          <Row>
            <Col>
              <ModalSupport />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="justify-content-md-center">
        <hr />
        <Button
          variant="outlined"
          className="button-bar"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          <p className="button">Página principal</p>
        </Button>
      </Row>
    </Container>
  );
};

export default RegisterBar;
