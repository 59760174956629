import axios from "axios";
import GetToken from "../../../utils/getToken";
import { env } from "../../../env";

export function createHeading(data) {
  return axios({
    method: "POST",
    url: `letterheads`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateHeading(data) {
  return axios({
    method: "PUT",
    url: `letterheads`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteHeading(data) {
  return axios({
    method: "PUT",
    url: `letterheads/updateStatus`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function findAllHeadings(data) {
  return axios({
    method: "GET",
    url: `letterheads/findAll`,
    baseURL: env.REACT_APP_API_URL,
    params: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deletePermanentlyHeading(data) {
  return axios({
    method: "DELETE",
    url: `letterheads`,
    baseURL: env.REACT_APP_API_URL,
    params: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function findOneHeading({ letterheadId, companyId }) {
  return axios({
    method: "GET",
    url: `letterheads`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      letterheadId,
      companyId,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function uploadDocumentLetterhead({
  file,
  companyId,
  nameDocument,
  versionFrom,
  letterheadResourceType,
}) {
  const payload = JSON.parse(localStorage.getItem("payloadToken"));
  const formData = new FormData();
  formData.append("file", file);
  formData.append("companyId", companyId);
  formData.append("name", nameDocument);
  formData.append("versionFrom", versionFrom);
  formData.append("letterheadResourceType", letterheadResourceType);
  formData.append("email", payload.email);

  return axios({
    method: "POST",
    url: `letterheads/createResource`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export const previewHeadingDocument = ({
  documentPdfTemplate,
  documentDocxTemplate,
  file,
  originalname,
  contentType,
}) => {
  const formData = new FormData();
  formData.append("file", file);
  if (documentDocxTemplate) {
    formData.append("documentDocxTemplate", documentDocxTemplate);
  }

  formData.append("documentPdfTemplate", documentPdfTemplate);
  formData.append("originalname", originalname);
  formData.append("contentType", contentType);
  return axios({
    method: "POST",
    url: `letterheads/previewApplyToDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};

export const ApplyHeadingToDocument = (data) => {
  return axios({
    method: "POST",
    url: `letterheads/applyToDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};
