import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Container, Spinner } from "react-bootstrap";
import { CODES } from "../../utils/codes";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  CreateComment,
  DownloadDocsByURL,
  changeRequestState,
} from "../../services/general/requestServices";
import { useNavigate, useParams } from "react-router-dom";
import ModalInfo from "../Modals/modalInfo";
import dateFormat, { i18n } from "dateformat";

const DiscussionBoard = ({
  isHideDiscussion,
  setIsHideDiscussion,
  comments,
  id,
  setIsNewComment,
  isRequestActive = false,
  state = "",
}) => {
  const navigate = useNavigate();
  const { consecutive } = useParams();
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNewOrReturned, setIsNewOrReturned] = useState(false);
  const info = JSON.parse(localStorage.payloadToken);
  const { userId } = info;
  const [data, setData] = useState([
    {
      createdAt: "",
      authorName: "",
      message: "",
    },
  ]);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  useEffect(() => {
    if (state === "Nueva" || state === "Devuelta") {
      setIsNewOrReturned(true);
    }
  }, [state]);

  useEffect(() => {
    setData(comments);
  }, [comments]);

  const { formState: { errors }, register, handleSubmit, reset } = useForm({
    /* resolver: yupResolver(suplySchema), */
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handleClickDownload = async (id, documentType) => {
    try {
        
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        consecutive: consecutive,
        documentId: id,
        documentType: documentType,
        company,
        corporateUnit
      };
      const service = await DownloadDocsByURL(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { responseMessage } = service.data;
          window.open(responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateComment = async (data) => {
    setIsLoadingSend(true);
    setIsNewComment(true);
    try {
      let obj;
      obj = {
        requestId: id,
        commentMessage: data.comment,
      };
      const sendComment = await CreateComment(obj);
      if (sendComment.status) {
        if (sendComment.status === CODES.COD_RESPONSE_HTTP_OK) {
          let objState = {
            requestId: id,
            state: "Revisión solicitante",
          };
          const response = await changeRequestState(objState);
          if (
            response.status &&
            response.status === CODES.COD_RESPONSE_HTTP_OK
          ) {
            setData(sendComment.data.responseMessage.comments);
            setIsLoadingSend(false);
            setShowModal(false);
            reset();
          }
        } else if (
          sendComment.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setResponseDataUnauthorized(sendComment);
          setIsOpenModalUnauthorized(true);
          setShowModal(false);
        }
      }
      setIsNewComment(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSend(false);
    }
  };

  i18n.monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return (
    <Container
      hidden={isHideDiscussion}
      style={{
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
      fluid
    >
      <div
        style={{
          left: "0",
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "#000000",
          opacity: "0.3",
        }}
        onClick={() => setIsHideDiscussion(true)}
      />
      <Col
        style={{
          backgroundColor: "#FFFFFF",
          width: "447px",
          height: "100vh",
          position: "fixed",
          right: "0",
          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Row
          style={{
            borderBottom: "1px solid #E6E9EF",
            height: "5%",
            margin: "12px",
            color: "#00374F",
          }}
        >
          <Col md={11}>
            <label className="label__title">Panel de discusiones</label>
          </Col>
          <Col md={1}>
            <CloseIcon
              fontSize="large"
              style={{ cursor: "pointer" }}
              onClick={() => setIsHideDiscussion(true)}
            />
          </Col>
        </Row>
        <Row style={{ margin: "12px", height: "80%", overflow: "auto" }}>
          <div
            style={{
              color: "#00374F",
              height: "max-content",
              boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
              border: "1px solid #E6E9EF",
              borderRadius: "8px",
            }}
          >
            <label
              className="label__title"
              style={{
                height: "4rem",
                color: "#045380",
                padding: "8px",
                width: "100%",
                borderBottom: "1px solid #E6E9EF",
              }}
            >
              Mensajes
            </label>
            {data.map((comment, i) => {
              if (!comment?.approvalId && !comment?.isSignatureMultipleUsers) {
                return (
                  <Row
                    key={comment?._id}
                    style={{
                      borderBottom: "1px solid #E6E9EF",
                      padding: "8px 20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1.4rem",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {dateFormat(comment.createdAt, "d mmmm yyyy, h:MM TT")}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        color: "#000000",
                        alignItems: "center",
                      }}
                    >
                      <CircleIcon
                        color={
                          userId === comment.authorId ? "success" : "warning"
                        }
                      />
                      {comment.authorName}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        textAlign: "justify",
                        color: "#000000",
                      }}
                    >
                      {comment.message}
                    </label>
                  </Row>
                );
              } else if (comment?.isSignatureMultipleUsers) {
                return (
                  <Row
                    key={comment?._id}
                    style={{
                      borderBottom: "1px solid #E6E9EF",
                      padding: "8px 20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1.4rem",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {dateFormat(comment.createdAt, "d mmmm yyyy, h:MM TT")}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        color: "#000000",
                        alignItems: "center",
                      }}
                    >
                      <CircleIcon
                        color={
                          userId === comment?.authorId ? "success" : "warning"
                        }
                      />
                      {comment?.authorName || ""}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        textAlign: "justify",
                        color: "#000000",
                      }}
                    >
                      {`Varios usuarios ha iniciado el proceso de firma. `}
                      <span
                        style={{
                          color: "#067bbd",
                          cursor: "pointer",
                          textDecoration: "underline",
                          marginTop: "3%",
                        }}
                        onClick={() => {
                          navigate(
                            `/service/requests/viewRequest/${consecutive}/signatures`
                          );
                        }}
                      >
                        Ver gestión de firmas
                      </span>
                    </label>
                  </Row>
                );
              } else if (comment.isFirstComment) {
                return (
                  <Row
                    key={comment?._id}
                    style={{
                      borderBottom: "1px solid #E6E9EF",
                      padding: "8px 20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1.4rem",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {dateFormat(comment.createdAt, "d mmmm yyyy, h:MM TT")}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        color: "#000000",
                        alignItems: "center",
                      }}
                    >
                      <CircleIcon
                        color={
                          userId === comment.authorId ? "success" : "warning"
                        }
                      />
                      {comment.aplicantName}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        textAlign: "justify",
                        color: "#000000",
                      }}
                    >
                      {`Se han solicitado aprobaciones al documento ${comment.associatedDocument.originalFilename} de esta solicitud`}
                    </label>
                  </Row>
                );
              } else if (comment.isDesisted) {
                return (
                  <Row
                    key={comment?._id}
                    style={{
                      borderBottom: "1px solid #E6E9EF",
                      padding: "8px 20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1.4rem",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {dateFormat(comment.createdAt, "d mmmm yyyy, h:MM TT")}
                    </label>
                    <label>
                      <p
                        style={{
                          fontSize: "1.2rem",
                          fontStyle: "normal",
                          textAlign: "justify",
                          color: "#000000",
                        }}
                      >
                        El usuario {comment.userPosition}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {comment.userName}
                        </span>{" "}
                        ha desistido de la solicitud de aprobación sobre el
                        documento {comment.authorName} por el siguiente motivo:
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                          marginTop: "3%",
                          fontSize: "1.2rem",
                          textAlign: "justify",
                          color: "#000000",
                        }}
                      >
                        "{comment.message}"
                      </p>
                      <p
                        style={{
                          color: "#067bbd",
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          textDecoration: "underline",
                          marginTop: "3%",
                        }}
                        onClick={() => {
                          const id = comment.approvalId._id;
                          const route = `/service/requests/viewRequest/${consecutive}/approvals/viewApproval/${id}`;
                          navigate(route);
                        }}
                      >
                        Ver detalles de la aprobación
                      </p>
                    </label>
                  </Row>
                );
              } else if (
                comment?.approvalId?.state?.name === "Aprobada" &&
                comment?.approvalId?.approvalComments?.slice(-1)[0]?.message ===
                  comment?.message
              ) {
                return (
                  <Row
                    key={comment?._id}
                    style={{
                      borderBottom: "1px solid #E6E9EF",
                      padding: "8px 20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1.4rem",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {dateFormat(comment.createdAt, "d mmmm yyyy, h:MM TT")}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        textAlign: "justify",
                        color: "#000000",
                      }}
                    >
                      <p>
                        El aprobador{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {comment?.approvalId?.approverName}
                        </span>{" "}
                        aprobó el documento{" "}
                        <span
                          style={{
                            color: "#067bbd",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            handleClickDownload(
                              comment?.approvalId?.associatedDocument?._id,
                              comment?.approvalId?.associatedDocument?.type
                            );
                          }}
                        >
                          {
                            comment?.approvalId?.associatedDocument
                              ?.originalFilename
                          }
                        </span>{" "}
                        el día{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {dateFormat(
                            comment.createdAt,
                            "d mmmm yyyy, h:MM TT"
                          )}
                        </span>
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                          marginTop: "3%",
                        }}
                      >
                        "{comment.message}"
                      </p>
                      <p
                        style={{
                          color: "#067bbd",
                          cursor: "pointer",
                          textDecoration: "underline",
                          marginTop: "3%",
                        }}
                        onClick={() => {
                          navigate(
                            `/service/requests/viewRequest/${consecutive}/approvals/viewApproval/${comment.approvalId._id}`
                          );
                        }}
                      >
                        Ver detalles de la aprobación
                      </p>
                    </label>
                  </Row>
                );
              } else {
                return (
                  <Row
                    key={comment?._id}
                    style={{
                      borderBottom: "1px solid #E6E9EF",
                      padding: "8px 20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1.4rem",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      {dateFormat(comment.createdAt, "d mmmm yyyy, h:MM TT")}
                    </label>
                    <label
                      style={{
                        fontSize: "1.2rem",
                        fontStyle: "normal",
                        textAlign: "justify",
                        color: "#000000",
                      }}
                    >
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {comment.userName}
                        </span>{" "}
                        ha realizado un comentario sobre una aprobación.
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                          marginTop: "3%",
                        }}
                      >
                        "{comment.message}"
                      </p>
                      <p
                        style={{
                          color: "#067bbd",
                          cursor: "pointer",
                          textDecoration: "underline",
                          marginTop: "3%",
                        }}
                        onClick={() => {
                          navigate(
                            `/service/requests/viewRequest/${consecutive}/approvals/viewApproval/${comment.approvalId._id}`
                          );
                        }}
                      >
                        Ver detalles de la aprobación
                      </p>
                    </label>
                  </Row>
                );
              }
            })}
          </div>
        </Row>
        {isRequestActive || isNewOrReturned ? (
          <Row style={{ margin: "12px", height: "15%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                height: "fit-content",
              }}
            >
              <button
                className="btn_blue_background"
                style={{ padding: "12px 24px", marginTop: "1rem" }}
                onClick={() => setShowModal(true)}
              >
                <FontAwesomeIcon size="lg" icon={faPlus} />
                {" Nuevo Comentario"}
              </button>
            </div>
          </Row>
        ) : (
          <></>
        )}
      </Col>
      <Modal
        size="md"
        centered
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton style={{ padding: "1rem" }}>
          <Modal.Title
            className="label__title"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Nuevo comentario
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleCreateComment)}>
          <Modal.Body>
            <textarea
              {...register("comment", {
                required: {
                  value: true,
                  message: "*Este campo es obligatorio",
                }
              })}
              placeholder="Escribe un nuevo comentario..."
              style={{
                width: "100%",
                fontSize: "1.4rem",
                fontWeight: "100",
                lineHeight: "2.4rem",
                borderRadius: "0.5rem",
                border: "0.1rem solid #c5c7d0",
                padding: "1rem",
              }}
              className={
                errors?.comment
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div
              className="caption custom-input__error"
              style={{ border: "1px sollid red" }}
            >
              {errors?.comment?.message}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ padding: "1rem" }}>
            <label
              className="btn_white_background"
              style={{ padding: "12px 24px" }}
              onClick={handleClose}
            >
              Cancelar
            </label>
            <button
              type="submit"
              className="btn_blue_background"
              style={{ padding: "12px 24px" }}
            >
              {isLoadingSend ? (
                <Spinner
                  animation="border"
                  className="spinner__white-background"
                />
              ) : (
                "Aceptar"
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default DiscussionBoard;
