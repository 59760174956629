import {
  createSlice,
} from "@reduxjs/toolkit";

export const clausesSelectedSlice = createSlice({
  name: "clausesSelected",
  initialState: {
    clausesSelected: [],
    subClausesSelected: [],
  },
  reducers: {
    setSelectedClauses(state, action) {
      const clauses = action.payload;
      state.clausesSelected = clauses;
    },
    setSelectedSubClauses(state, action) {
      const subclauses = action.payload;
      state.subClausesSelected = subclauses;
    },
    setSelectedSubClausesInClause(state, action) {
      let oldClauses = state.subClausesSelected;
      const id = action.payload.clauseId;
      const subClauses = action.payload.subClauses;
      const numerals = oldClauses.map((clause, idx) => {
        let addNumeral = {};
        if (clause.clause === id) {
          addNumeral = {
            clause: clause.clause,
            subclause: subClauses,
            index: idx,
          };
        } else {
          addNumeral = {
            ...clause,
          };
        }
        return addNumeral;
      });
      state.subClausesSelected = numerals;
    },
    reorderClause(state, action) {
      const { source, destination } = action.payload.result;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }
      const clauses = state.clausesSelected;
      const [removed] = clauses.splice(source.index, 1);
      clauses.splice(destination.index, 0, removed);
      state.clausesSelected = clauses;
    },
    reorderClauseIndex(state, action) {
      const { source, destination } = action.payload.result;
      if (!destination) {
        return;
      }
      if (source.index === destination.index - 1) {
        return;
      }
      const clauses = state.clausesSelected;
      const [removed] = clauses.splice(source.index, 1);
      clauses.splice(destination.index, 0, removed);
      state.clausesSelected = clauses;
    },
    cleanStoreClausesSelectedSlice(state) {
      state.clausesSelected = [];
      state.subClausesSelected = [];
    }
  },
  extraReducers(builder) {
    builder
    .addCase(setSelectedClauses , (state, action) => {
        state.clausesSelected = action.payload;
    })
    .addCase(setSelectedSubClauses , (state, action) => {
      state.subClausesSelected = action.payload;
    });
  }
});

export const getSelectedClauses = (state) => state.clausesSelected.clausesSelected;
export const getSelectedSubClauses = (state) => state.clausesSelected.subClausesSelected;

export const { setSelectedClauses } = clausesSelectedSlice.actions;
export const { setSelectedSubClauses } = clausesSelectedSlice.actions;
export const { setSelectedSubClausesInClause } = clausesSelectedSlice.actions;

export const { reorderClause } = clausesSelectedSlice.actions;
export const { reorderClauseIndex, cleanStoreClausesSelectedSlice } = clausesSelectedSlice.actions;

export default clausesSelectedSlice.reducer;