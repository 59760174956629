import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//.._.Material Import
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import { MDBBadge, MDBDataTableV5 } from "mdbreact";
//._parts
import Breadcrum from "../../breadcrum";
//_...components
import CustomSearch from "../../../components/Datatable/customSearch";

import LoadingContent from "../../../components/loadingContent";
import SectionTitle from "../../../components/sectionTitle";
//.Icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
//._.Redux
import { useDispatch, useSelector } from "react-redux";
//Services
import { Buffer } from "buffer";
import {
  fetchAlerts,
  getAlertsList,
  getAlertsStatus,
  setAlertStatus,
} from "../../../pages/views/userService/modules/alert/alertSlice";
//Utils
import fileDownload from "js-file-download";
import dateFormat from "dateformat";
import { WordBlobDocument } from "../../../components/WordCreator/WordCreator";
import {
  getDocById,
  getFileDocument,
} from "../../../services/documents/documentsRequest";
import { CODES } from "../../../utils/codes";
import {
  codesDocumentType,
  codesDocumentTypeForAlert,
} from "../../../utils/codesDocumentType";
import DetailsBar from "../../../components/DetailsBar/DetailsBar";
import {
  getAlerts,
  updateAlertStates,
} from "../../../services/alerts/alertsServices";
import ModalUploadDocs from "../../../components/Modals/ModalUploadDocs";
import ModalAssociateVersion from "../../../components/Modals/modalAssociateVersion";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../folders/foldersSlice";
import { FOLDER_STATE } from "../../../utils/documentStates";
import ModalDecision from "../../../components/Modals/modalDecision";
import ModalInfo from "../../../components/Modals/modalInfo";
import { NoResultsFound } from "../../../components/Results/NoResultsFound";
import { setName } from "../../document/currentDocumentSlice";
const FolderAlerts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const businessFolderid = searchParams.get("folder");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [filterMyId, setFilterMyId] = useState(false);
  //Cambiar estado del loading
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredWord, setFilteredWord] = useState("");
  const statusAlerts = useSelector(getAlertsStatus);
  const alertsList = useSelector(getAlertsList);
  const openMenu = Boolean(anchorEl);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreate = Boolean(anchorElCreate);
  const [documentData, setDocumentData] = useState({});
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [documentSelected, setDocumentSelected] = useState({});
  const [documentType, setDocumentType] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState({
    title: "",
    isOpen: false,
    response: {},
  });

  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  /**
   * useSelector
   */

  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const columns = [
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Nombre de la alerta
        </label>,
      ],
      field: "alert",
    },
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Responsable
        </label>,
      ],
      field: "assignedUser",
    },
    {
      label: [
        <label
          key="2"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          {" "}
          Documento
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label
          key="3"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "150px" }}
        >
          Fecha de activación
        </label>,
      ],
      field: "alertdate",
    },
    {
      label: [
        <label
          key="4"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "150px" }}
        >
          Fecha de vencimiento
        </label>,
      ],
      field: "expiration",
      width: 150,
    },
    {
      label: [
        <label
          key="5"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "120px" }}
        >
          Estado
        </label>,
      ],
      field: "state",
    },
    {
      label: [
        <label
          key="6"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "192px" }}
        >
          Acciones
        </label>,
      ],
      field: "seeAlert",
    },
  ];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [stateSelected, setStateSelected] = useState("");

  const STATE_COLOR_ID = {
    [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
    [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
    [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
    [CODES.COD_LIGHT_BLUE]: "#FFD7B1",
    default: "#F9DADE",
  };

  const buildRows = (alertsFilter) => {
    let rows = [];
    if (alertsList.length > 0) {
      let alertsFilter = [];
      alertsFilter = alertsList.filter(
        (alert) => alert?.documentAssociated?.folderAssociated?._id === id
      );
      if (businessFolderid) {
        alertsFilter = alertsList.filter(
          (alert) => alert?.documentAssociated?._id === id
        );
      }
      rows = alertsFilter.map((alert) => {
        return {
          alert: alert?.name,
          assignedUser: getUserName(alert),
          document: (
            <label
              style={{
                textDecoration: "underline",
                color: "#067BBD",
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
              onClick={() => {
                handleDownload(
                  alert.documentAssociated?.filenameInBucket,
                  alert.documentAssociated?.bucketName,
                  alert.documentAssociated?.name,
                  alert.documentAssociated?.fileType,
                  alert.documentAssociated?._id
                );
              }}
            >
              {alert.documentAssociated?.name}
            </label>
          ),
          alertdate: dateFormat(alert.alertDate, "dd/mm/yyyy", true),
          expiration: dateFormat(alert.tasksDeadline, "dd/mm/yyyy", true),
          state: (
            <Row style={{ alignItems: "center" }}>
              <Col xs={"auto"}>
                <MDBBadge
                  color=""
                  pill
                  className="caption"
                  style={{
                    backgroundColor:
                      STATE_COLOR_ID[alert?.state?.colorId || "default"],
                    fontSize: "1.2rem",
                    boxShadow: "none",
                    padding: "0.4rem",
                  }}
                >
                  <span className="custom-badge">{alert.state.name}</span>
                </MDBBadge>
              </Col>
            </Row>
          ),
          seeAlert: (
            <Row>
              <Col xs={"auto"}>
                <IconButton
                  className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                  sx={{ borderRadius: "8px" }}
                  onClick={() => {
                    setSelectedAlert(alert);
                    setOpenModalDelete(true);
                  }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="large" />
                </IconButton>
              </Col>
              <Col xs={"auto"}>
                <IconButton
                  className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                  sx={{ borderRadius: "8px" }}
                  onClick={() => {
                    navigate(`/service/alerts/detail/${alert._id}/edit/${alert?.documentAssociated?._id}`);
                  }}
                >
                  <VisibilityOutlinedIcon fontSize="large" />
                </IconButton>
              </Col>
              <Col xs={"auto"}>
                <IconButton
                  className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                  sx={{ borderRadius: "8px" }}
                  onClick={() => {
                    setOpenDetailsDrawner(true);
                    setSelectedAlert(alert);
                  }}
                >
                  <InfoOutlinedIcon fontSize="large" />
                </IconButton>
              </Col>
            </Row>
          ),
        };
      });
    }
    return rows;
  };

  const handleDownload = async (
    filenameInBucket,
    bucketName,
    name,
    type,
    id
  ) => {
    try {
      let newName = name;
      if (type === codesDocumentType.draft._id) {
        const doc = await getDocById(id);
        const { documentContent } = doc.data.responseMessage;
        const blob = await WordBlobDocument({
          title: documentContent.title,
          headers: documentContent.headers,
          parts: documentContent.parts,
          clauses: documentContent.clauses,
          partHeader: "",
          numberingType: "number",
          otherSignatories: [],
        });
        fileDownload(blob, name + ".docx");
      } else {
        const downloadDocument = await getFileDocument(
          filenameInBucket,
          bucketName,
          false
        );
        const contentType =
          downloadDocument.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? ".docx"
            : ".pdf";
        if (!name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)) {
          newName = name + contentType;
        }
        const bf = Buffer.from(
          downloadDocument.data.responseMessage.buffer.data
        );
        fileDownload(bf, newName);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusAlerts === "fetch") {
          const company = localStorage.getItem("company");
          const corporateUnit = JSON.parse(
            localStorage.getItem("corporateUnit")
          );
          const obj = {
            company: company,
            corporateUnit: corporateUnit._id,
            filterMyId: filterMyId,
          };
          dispatch(fetchAlerts(obj));
        }
        const rows = buildRows();
        setDataTable({
          columns: columns,
          rows: rows,
        });
        setFilteredDataTable({
          columns: columns,
          rows: rows,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusAlerts, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusAlerts !== "fetch" && filterMyId) {
          setIsLoading(true);
          const company = localStorage.getItem("company");
          const corporateUnit = JSON.parse(
            localStorage.getItem("corporateUnit")
          );
          const obj = {
            company: company,
            corporateUnit: corporateUnit._id,
            filterMyId: filterMyId.toString(),
          };

          const service = await getAlerts(obj);
          if (service.status) {
            if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
              const rows = buildRows(service.data.responseMessage.data);
              setFilteredDataTable({
                columns: columns,
                rows: rows,
              });
            }
          }

          setIsLoading(false);
        } else if (!filterMyId) {
          const rows = buildRows(alertsList);
          setFilteredDataTable({
            columns: columns,
            rows: rows,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [filterMyId]);

  useEffect(() => {
    if (foldersStatus === "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [foldersStatus]);
  useEffect(() => {
    if (statusAlerts === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusAlerts]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterByState = (state) => {
    setStateSelected(state);
    const rowsFilter = alertsList.filter((alert) => alert.state.name === state);
    setFilteredDataTable({
      columns: columns,
      rows: buildRows(rowsFilter),
    });
    setAnchorEl(null);
  };
  const handleDeleteFilterState = () => {
    setStateSelected("");
    setFilteredDataTable({
      columns: columns,
      rows: buildRows(alertsList),
    });
  };
  const handleInfoStatus = () => navigate("/service/alerts/infoStatus");

  const handleClick = (event) => {
    setAnchorElCreate(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElCreate(null);
  };

  const handleDeleteAlert = async (idAlert, isSeries) => {
    try {
      setOpenModalDelete(false);
      setIsLoading(true);
      const updateRequest = await updateAlertStates({
        id: idAlert,
        newStatus: FOLDER_STATE.deleted._id,
        ...(isSeries && { isDeleteAllSerie: true }),
      });
      if (
        updateRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        updateRequest.data.success
      ) {
        dispatch(setAlertStatus({ status: "fetch" }));
        navigate("/service/alerts/delete");
      } else {
        setIsOpenModalInfo({
          isOpen: true,
          title: "Error",
          response: updateRequest.data.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (documentData.name) {
      setFolderSelectedModal(true);
    }
  }, [documentData]);

  function getUserName(user) {
    const assignedUser = user?.assignedTo;

    if (assignedUser?.firstName && assignedUser?.firstSurname) {
      return `${assignedUser.firstName} ${assignedUser.firstSurname}`;
    } else if (
      assignedUser?.companies &&
      assignedUser.companies[0]?.companyData?.companyName
    ) {
      return assignedUser.companies[0].companyData.companyName;
    } else {
      return "";
    }
  }

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Alertas"} />
        </Col>
        <Col>
          <Row style={{ justifyContent: "end" }} xs={"auto"}>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<AutoDeleteOutlinedIcon />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  navigate(`/service/alerts/delete`);
                }}
              >
                <p className="button">Papelera</p>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row xs={"auto"} className="mt-3">
        <p className="text-description">
          Crea, gestiona y personaliza tus alertas
        </p>
      </Row>
      <br />

      <Row>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            onClick={handleClick}
          >
            <p className="button">Crear alerta</p>
          </Button>
          <Menu
            anchorEl={anchorElCreate}
            open={openCreate}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenModalUploadDocument(true);
                setAnchorElCreate(null);
              }}
            >
              <ListItemIcon>
                <UploadOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Cargar documento y asignar alerta
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenModalVersion(true);
                setAnchorElCreate(null);
              }}
            >
              <ListItemIcon>
                <FolderOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Seleccionar documento desde Legal AI
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                navigate("/service/alerts/create/without-document");
              }}
            >
              <ListItemIcon>
                <NotificationsOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Crear alerta sin asociar a documento
              </ListItemText>
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={4}>
          <CustomSearch
            placeholder="Buscar alerta"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
            maxLength={20}
            setFilteredWord={setFilteredWord}
          />
        </Col>
        <Col
          md={7}
          style={{ display: "flex", justifyContent: "end" }}
          className="ml-auto"
        >
          <Col
            md={4}
            className="body-content d-flex align-items-center justify-content-end"
          >
            <Form.Group controlId="Terminos">
              <Form.Check
                value={filterMyId}
                type="checkbox"
                label="Solo alertas creadas por mí"
                onChange={(e) => {
                  setFilterMyId(e.target.checked);
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              endIcon={<ExpandMoreIcon />}
              className="custom-input__button__secondary-color"
              onClick={handleFilterClick}
            >
              Filtrar por estado
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleFilterByState("Inactiva")}>
                <ListItemText>Inactiva</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleFilterByState("Activa")}>
                <ListItemText>Activa</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleFilterByState("Vencida")}>
                <ListItemText>Vencida</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleFilterByState("Completada")}>
                <ListItemText>Completada</ListItemText>
              </MenuItem>
            </Menu>
          </Col>
        </Col>
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right", marginRight: "1rem" }}>
        {stateSelected != "" && (
          <Col xs={"auto"}>
            <span className="caption custom-badges__disabled">
              {stateSelected + " "}{" "}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilterState()}
              />
            </span>
          </Col>
        )}
      </Row>
      <Row className="data-table dt-list-items">
        <MDBDataTableV5
          noRecordsFoundLabel={
            !filteredWord ? "Aún no tienes alertas creadas" : <NoResultsFound />
          }
          hover
          pagingTop
          searchBottom={false}
          entries={10}
          data={filteredDataTable}
          infoLabel={["Mostrando", "a", "de", "alertas"]}
          fullPagination
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <InfoOutlinedIcon fontSize="large" />
        </Col>
        <Col className="caption">
          Si quieres saber más acerca de los estados de una alerta
          <span
            className="caption__other-Color-Active"
            onClick={handleInfoStatus}
          >
            {` Haz click aquí`}
          </span>
        </Col>
      </Row>
      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedAlert}
        pageName={"Alert"}
      />

      <ModalUploadDocs
        open={openModalUploadDocument}
        title={"Selecciona el documento para crear la alerta"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o word y pesar máximo 75mb"
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setOpenModalUploadDocument(false);
        }}
        typeDocument={"Contrato"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf o Word"
        maxFileSize={78643200} //75MB
        additionalFields={
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Select
                  className="label"
                  defaultValue={""}
                  onChange={(event) => {
                    const { value } = event.target;
                    setDocumentType(value);
                  }}
                >
                  <option value="" disabled defaultValue className="label">
                    Selecciona el tipo de documento
                  </option>
                  {codesDocumentTypeForAlert.map((type) => {
                    return (
                      <option value={type._id} className="label">
                        {type.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        }
      />
      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalVersion(false);
          setDocumentSelected(file);
          navigate(`/service/alerts/create/${file._id}`);
        }}
        onClose={() => {
          setOpenModalVersion(false);
        }}
        open={openModalVersion}
        folders={folders}
        versionSelected={documentSelected}
        title="Asociar documento"
      />
      <ModalDecision
        title={"Eliminar alerta"}
        message={
          selectedAlert?.isRepeated
            ? "¿Qué quieres eliminar?"
            : "¿Seguro que quieres eliminar la alerta?"
        }
        open={openModalDelete}
        onClose={setOpenModalDelete}
        agreeText={selectedAlert?.isRepeated ? "Solo esta alerta" : "Aceptar"}
        disagreeText={
          selectedAlert?.isRepeated
            ? "Todas las repeticiones de la alerta"
            : "Cancelar"
        }
        handleAgree={() => {
          handleDeleteAlert(selectedAlert._id);
        }}
        handleDisagree={() => {
          if (selectedAlert?.isRepeated) {
            handleDeleteAlert(selectedAlert._id, true);
          } else {
            setOpenModalDelete(false);
          }
        }}
      />
      <ModalInfo
        title={isOpenModalInfo.title}
        onClose={() =>
          setIsOpenModalInfo((prev) => ({ ...prev, isOpen: false }))
        }
        open={isOpenModalInfo.isOpen}
        responseData={isOpenModalInfo.response}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Guardar documento"}
        message={"Almacena este documento en una de tus carpetas"}
        open={folderSelectedModal}
        onClose={setFolderSelectedModal}
        agreeText="Seleccionar carpeta"
        handleAgree={() => {
          console.log(documentData.name);
          dispatch(setName({ name: documentData.name }));
          navigate(`/service/alerts/alert/folder/select-folder`, {
            state: {
              documentData,
              documentType,
              noCleanStore: true,
            },
          });
        }}
      />
    </Container>
  );
};

export default FolderAlerts;
