import { yupResolver } from "@hookform/resolvers/yup";
import { PaletteOutlined, PostAddOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Buffer } from "buffer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LoadingContent from "../../../../../components/loadingContent";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import ModalPDFView from "../../../../../components/Modals/modalPDFView";
import ModalSaveFileAs from "../../../../../components/Modals/ModalSaveFileAs";
import PopoverInfo from "../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../components/sectionTitle";
import {
  WordBlobDocument,
  WordCreator,
  wordCreatorFile,
} from "../../../../../components/WordCreator/WordCreator";
import { ImportClause } from "../../../../../parts/associations/ImportClause";
import Breadcrum from "../../../../../parts/breadcrum";
import {
  addEditedPart,
  cleanStore,
  deleteClause,
  deleteHeader,
  deleteSignatoryWithoutPart,
  getCurrentDocument,
  getReferenceNormativeStatus,
  reorderClause,
  reorderHeader,
  reorderSignatoriesWithoutParts,
  setClauseSelected,
  setIdDraft,
  setName,
  setNamePartClause,
  setPartsCorrection,
  setReferenceNormative,
  setSelectedClauses,
  setSelectedClausesWithoutCheckPart,
  setTitle,
  swapParts,
} from "../../../../../parts/document/currentDocumentSlice";
import {
  getSelectedHeader,
  getSelectedHeaderId,
} from "../../../../../parts/headings/headingsSlice";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import { previewHeadingDocument } from "../../../../../services/admin/documents/headingsServices";
import {
  autoSaveDraft,
  createDraft,
  getFileDocument,
  getRefNormativeDocument,
} from "../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../utils/codes";
import {
  NUMBERINGWORDSA,
  NUMBERINGWORDSO,
} from "../../../../../utils/numberings";
import {
  checkPartClause,
  cleanCheckPartClause,
} from "../../../../../utils/partsCorrection";
import { IOSSwitch } from "../../../admin/modules/rulesReview/rulesReview";
import { Mixpanel } from "../../../../../utils/mixPanel";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {
  DragDropContextParts,
  DragDropContextSignatories,
  GetReferenceNormative,
} from "./utils";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { saveAs } from "file-saver";

const GeneralViewDocument = () => {
  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentDocument = useSelector(getCurrentDocument);
  const statusRefNormative = useSelector(getReferenceNormativeStatus);
  const selectedHeader = useSelector(getSelectedHeaderId);
  const selectedHeaderObject = useSelector(getSelectedHeader);
  const companyPlan = useSelector(getCompanyPlan);
  //States
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorElSignatory, setAnchorElSignatory] = useState(null);

  const openSignatory = Boolean(anchorElSignatory);

  const [openPreview, setOpenPreview] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [blankSpacesModal, setBlankSpacesModal] = useState(false);
  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  const [saveAsModal, setSaveAsModal] = useState(false);
  const [missingBlankspaces, setMissingBlankspaces] = useState([]);
  const [saveType, setSaveType] = useState("");
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [initAutoSave, setInitAutoSave] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [lastSave, setLastSave] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalInfoSaveDocument, setIsOpenModalInfoSaveDocument] =
    useState(false);
  const [responseDataModalInfo, setResponseDataModalInfo] = useState({});
  const [countInterval, setCountInterval] = useState(0);
  const [numeration, setNumeration] = useState("number");
  const [openModalSaveAsDraft, setOpenModalSaveAsDraft] = useState(false);
  const [responseSaveAsDraft, setResponseSaveAsDraft] = useState({});
  const [isOpenModalLimitPlan, setIsOpenModalLimitPlan] = useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);

  const [fileName, setFileName] = useState("");
  const [partsErrorsModal, setPartsErrorsModal] = useState(false);
  const [updateCurrentDraft, setUpdateCurrentDraft] = useState(false);

  const [checkPartsGrammar, setCheckPartsGrammar] = useState(false);
  const [countGeneralBlankspaces, setCountGeneralBlankspaces] = useState({});
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const [openModalSelectSignatory, setOpenModalSelectSignatory] =
    useState(false);

  //CONST VARIABLES AND UTILS

  const SAVE__AS__EXISTING__DRAFT = "1";
  const SAVE__AS__NEW__DRAFT = "2";
  const SAVE__AS__LEGAL__VERSION = "3";
  const SAVE__AS__WORD__VERSION = "4";

  const schema = yup.object().shape({
    fileName: yup
      .string()
      .required("**Este campo es requerido")
      .matches(/^[^#?!@$%^&*-]+$/, "Caracteres #?!@$%^&*- no permitidos"),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Handles
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickExternalSignatory = (event) => {
    setAnchorElSignatory(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSubMenu = (type) => {
    if (type === "con") {
      navigate("add-header-with");
    } else if (type === "sin") {
      navigate("add-header-without");
    }
    handleClose();
  };
  const handleFilter = (text) => {
    const clausesFilter = text
      ? currentDocument.clauses.filter((cla) => cla.clause.title.includes(text))
      : currentDocument.clauses;
    setFilteredClauses(clausesFilter);
  };
  const getFillBlankSpaces = (clause) => {
    let count = 0;
    count += clause?.blankspaces?.filter((blank) => blank.value).length ?? 0;
    if (clause?.subclauses?.length) {
      for (const sub of clause.subclauses) {
        count +=
          sub?.subclause?.blankspaces?.filter((blank) => blank.value).length ??
          0;
        if (sub?.subclause?.childs?.length) {
          for (const child of sub.subclause.childs) {
            count +=
              child?.blankspaces?.filter((blank) => blank.value).length ?? 0;
          }
        }
      }
    }
    if (clause?.paragraphs?.length) {
      for (const para of clause.paragraphs) {
        count +=
          para?.paragraph?.blankspaces?.filter((blank) => blank.value).length ??
          0;
      }
    }

    return count;
  };
  const getAllBlankSpaces = (clause) => {
    let count = 0;
    count += clause?.blankspaces?.length ?? 0;

    if (clause?.subclauses?.length) {
      for (const sub of clause.subclauses) {
        count += sub?.subclause?.blankspaces?.length ?? 0;
        if (sub?.subclause?.childs?.length) {
          for (const child of sub?.subclause?.childs) {
            if (child.blankspaces) {
              count += child.blankspaces.length;
            }
          }
        }
      }
    }

    if (clause?.paragraphs?.length) {
      for (const par of clause.paragraphs) {
        count += par?.paragraph?.blankspaces?.length ?? 0;
      }
    }

    return count;
  };
  const validateBlankspaces = () => {
    const listMissingBlankspaces = currentDocument.clauses.filter(
      (cla) => getFillBlankSpaces(cla.clause) !== getAllBlankSpaces(cla.clause)
    );
    setMissingBlankspaces(listMissingBlankspaces);
  };

  const handleAutoDraftRequest = async () => {
    const newDocument =
      currentDocument.parts.length === 4
        ? {
            ...currentDocument,
            parts: {
              partA: currentDocument.parts[0],
              partB: currentDocument.parts[1],
              partC: currentDocument.parts[2],
              partD: currentDocument.parts[3],
            },
            clauses: currentDocument.clauses.map((cla, index) => {
              return {
                ...cla,
                clause: {
                  ...cla.clause,
                  parts: {
                    partA: cla.clause.parts[0],
                    partB: cla.clause.parts[1],
                    partC: cla.clause.parts[2],
                    partD: cla.clause.parts[3],
                  },
                },
              };
            }),
            name: fileName || "Borrador sin nombre",
          }
        : currentDocument.parts.length === 3
        ? {
            ...currentDocument,
            parts: {
              partA: currentDocument.parts[0],
              partB: currentDocument.parts[1],
              partC: currentDocument.parts[2],
            },
            clauses: currentDocument.clauses.map((cla, index) => {
              return {
                ...cla,
                clause: {
                  ...cla.clause,
                  parts: {
                    partA: cla.clause.parts[0],
                    partB: cla.clause.parts[1],
                    partC: cla.clause.parts[2],
                    partD: cla.clause.parts[3],
                  },
                },
              };
            }),
            name: fileName || "Borrador sin nombre",
          }
        : {
            ...currentDocument,
            parts: {
              partA: currentDocument.parts[0],
              partB: currentDocument.parts[1],
            },
            clauses: currentDocument.clauses.map((cla, index) => {
              return {
                ...cla,
                clause: {
                  ...cla.clause,
                  parts: {
                    partA: cla.clause.parts[0],
                    partB: cla.clause.parts[1],
                    partC: cla.clause.parts[2],
                    partD: cla.clause.parts[3],
                  },
                },
              };
            }),
            name: fileName || "Borrador sin nombre",
          };
    const createDraftFrom = pathname.includes("from-minuta");
    const autoDraftRequest = await autoSaveDraft({
      documentContent: newDocument,
      draftId: currentDocument.idDraft,
      versionFrom: "CONTRACT",
      contractFrom: createDraftFrom ? "CONTRACTTYPES" : "CLAUSES",
      ...(selectedHeader && { letterheadAssociated: selectedHeader }),
      name: fileName || "Borrador sin nombre",
    });
    if (
      autoDraftRequest.data.success &&
      autoDraftRequest.status === CODES.COD_RESPONSE_HTTP_OK
    ) {
      setLastSave(
        moment(autoDraftRequest.data.responseMessage.data.updatedAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )
      );
      setTimeout(() => {
        setCountInterval((prev) => (prev += 1));
      }, 60000);
    } else if (
      autoDraftRequest.data.responseCode ===
      CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
    ) {
      setIsOpenModalLimitPlan(true);
    } else {
      alert("Algo salio mal");
    }
  };

  const handleEditClause = (id, clause) => {
    if (id === undefined) {
      id = "UsedThisContract";
    }
    dispatch(setClauseSelected(clause));
    navigate(`edit-clause/${id}`);
  };

  const handleFirstDraftSave = async () => {
    const createDraftFrom = pathname.includes("from-minuta");
    const newDocument =
      currentDocument.parts.length === 4
        ? {
            ...currentDocument,
            parts: {
              partA: currentDocument.parts[0],
              partB: currentDocument.parts[1],
              partC: currentDocument.parts[2],
              partD: currentDocument.parts[3],
            },
            clauses: currentDocument.clauses.map((cla, index) => {
              return {
                ...cla,
                clause: {
                  ...cla.clause,
                  parts: {
                    partA: cla.clause.parts[0],
                    partB: cla.clause.parts[1],
                    partC: cla.clause.parts[2],
                    partD: cla.clause.parts[3],
                  },
                },
              };
            }),
            name: fileName || "Borrador sin nombre",
          }
        : currentDocument.parts.length === 3
        ? {
            ...currentDocument,
            parts: {
              partA: currentDocument.parts[0],
              partB: currentDocument.parts[1],
              partC: currentDocument.parts[2],
            },
            clauses: currentDocument.clauses.map((cla, index) => {
              return {
                ...cla,
                clause: {
                  ...cla.clause,
                  parts: {
                    partA: cla.clause.parts[0],
                    partB: cla.clause.parts[1],
                    partC: cla.clause.parts[2],
                    partD: cla.clause.parts[3],
                  },
                },
              };
            }),
            name: fileName || "Borrador sin nombre",
          }
        : {
            ...currentDocument,
            parts: {
              partA: currentDocument.parts[0],
              partB: currentDocument.parts[1],
            },
            clauses: currentDocument.clauses.map((cla, index) => {
              return {
                ...cla,
                clause: {
                  ...cla.clause,
                  parts: {
                    partA: cla.clause.parts[0],
                    partB: cla.clause.parts[1],
                    partC: cla.clause.parts[2],
                    partD: cla.clause.parts[3],
                  },
                },
              };
            }),
            name: fileName || "Borrador sin nombre",
          };
    if (!currentDocument.idDraft) {
      const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
      const draftRequest = await createDraft({
        name: fileName || "Borrador sin nombre",
        documentContent: newDocument,
        versionFrom: "CONTRACT",
        contractFrom: createDraftFrom ? "CONTRACTTYPES" : "CLAUSES",
        corporateUnit: corporateUnit._id,
        modifiedBy: userId,
        ...(selectedHeader && { letterheadAssociated: selectedHeader }),
      });
      if (draftRequest) {
        if (
          draftRequest.data.responseCode === CODES.COD_RESPONSE_HTTP_OK &&
          draftRequest.data.success
        ) {
          dispatch(
            setIdDraft({ id: draftRequest.data.responseMessage.data._id })
          );
        } else if (
          draftRequest.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setIsOpenModalLimitPlan(true);
        } else {
          setResponseDataModalInfo(draftRequest);
          setIsOpenModalInfoSaveDocument(true);
        }
      }
    }
    setInitAutoSave(true);
  };

  const handleSaveSubmit = ({ saveType, fileName }) => {
    setFileName(fileName);
    dispatch(setName({ name: fileName }));
    switch (saveType) {
      case SAVE__AS__EXISTING__DRAFT:
        setUpdateCurrentDraft(!updateCurrentDraft);
        setSaveAsModal(false);

        break;
      case SAVE__AS__LEGAL__VERSION:
        setSaveType("legal");
        if (isValidSaveVersionParts()) {
          setPartsErrorsModal(true);
        } else if (isValidSaveVersion()) {
          setBlankSpacesModal(true);
        } else {
          setFolderSelectedModal(true);
        }
        break;

      case SAVE__AS__NEW__DRAFT:
        setDraftModal(true);

        break;
      case SAVE__AS__WORD__VERSION:
        setSaveType("word");
        if (isValidSaveVersionParts()) {
          setPartsErrorsModal(true);
        } else if (isValidSaveVersion()) {
          setBlankSpacesModal(true);
        } else {
          setFolderSelectedModal(true);
        }
        break;

      default:
        setDraftModal(true);
        break;
    }
  };
  //Effects
  useEffect(() => {
    validateBlankspaces();
    setFilteredClauses(currentDocument.clauses);
    handleFirstDraftSave();
    dispatch(setPartsCorrection(false));
  }, []);

  const handleSwitchParts = async (clause) => {
    dispatch(swapParts({ clauseId: clause._id }));
  };

  useEffect(() => {
    if (!checkPartsGrammar) {
      let newClauses = cleanCheckPartClause(currentDocument);

      dispatch(setSelectedClausesWithoutCheckPart({ clauses: newClauses }));
    } else {
      let newClauses = checkPartClause(currentDocument);

      dispatch(setSelectedClauses({ clauses: newClauses }));
    }
  }, [checkPartsGrammar]);

  useEffect(() => {
    const handleIntervalAutoSave = async () => {
      const createDraftFrom = pathname.includes("from-minuta");
      if (initAutoSave) {
        const newDocument =
          currentDocument.parts.length === 4
            ? {
                ...currentDocument,
                parts: {
                  partA: currentDocument.parts[0],
                  partB: currentDocument.parts[1],
                  partC: currentDocument.parts[2],
                  partD: currentDocument.parts[3],
                },
                clauses: currentDocument.clauses.map((cla, index) => {
                  return {
                    ...cla,
                    clause: {
                      ...cla.clause,
                      parts: {
                        partA: cla.clause.parts[0],
                        partB: cla.clause.parts[1],
                        partC: cla.clause.parts[2],
                        partD: cla.clause.parts[3],
                      },
                    },
                  };
                }),
                name: fileName || "Borrador sin nombre",
              }
            : currentDocument.parts.length === 3
            ? {
                ...currentDocument,
                parts: {
                  partA: currentDocument.parts[0],
                  partB: currentDocument.parts[1],
                  partC: currentDocument.parts[2],
                },
                clauses: currentDocument.clauses.map((cla, index) => {
                  return {
                    ...cla,
                    clause: {
                      ...cla.clause,
                      parts: {
                        partA: cla.clause.parts[0],
                        partB: cla.clause.parts[1],
                        partC: cla.clause.parts[2],
                        partD: cla.clause.parts[3],
                      },
                    },
                  };
                }),
                name: fileName || "Borrador sin nombre",
              }
            : {
                ...currentDocument,
                parts: {
                  partA: currentDocument.parts[0],
                  partB: currentDocument.parts[1],
                },
                clauses: currentDocument.clauses.map((cla, index) => {
                  return {
                    ...cla,
                    clause: {
                      ...cla.clause,
                      parts: {
                        partA: cla.clause.parts[0],
                        partB: cla.clause.parts[1],
                        partC: cla.clause.parts[2],
                        partD: cla.clause.parts[3],
                      },
                    },
                  };
                }),
                name: fileName || "Borrador sin nombre",
              };
        const autoDraftRequest = await autoSaveDraft({
          documentContent: newDocument,
          draftId: currentDocument.idDraft,
          versionFrom: "CONTRACT",
          contractFrom: createDraftFrom ? "CONTRACTTYPES" : "CLAUSES",
          ...(selectedHeader && { letterheadAssociated: selectedHeader }),
          name: fileName || "Borrador sin nombre",
        });
        if (
          autoDraftRequest.data.success &&
          autoDraftRequest.status === CODES.COD_RESPONSE_HTTP_OK
        ) {
          setLastSave(
            moment(autoDraftRequest.data.responseMessage.data.updatedAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          );
          setCountInterval((prev) => (prev += 1));
        } else if (
          autoDraftRequest.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setIsOpenModalLimitPlan(true);
        } else {
          setResponseDataModalInfo(autoDraftRequest);
          setIsOpenModalInfoSaveDocument(true);
        }
      }
    };
    handleIntervalAutoSave();
  }, [initAutoSave, updateCurrentDraft]);

  useEffect(() => {
    (() => {
      if (countInterval > 0) {
        handleAutoDraftRequest();
      }
    })();
  }, [countInterval]);

  useEffect(() => {
    const onunload = (e) => {
      e.preventDefault();
      e.returnValue = "hola";
    };
    window.addEventListener("beforeunload", onunload);
    return () => {
      window.removeEventListener("beforeunload", onunload);
    };
  }, []);

  useEffect(() => {
    return () => {
      const idIntervalSave = localStorage.getItem("timerSave");
      clearInterval(idIntervalSave);
    };
  }, []);

  const handlePreviewPdf = async () => {
    try {
      Mixpanel.track("Crear Btn Vista previa ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      setIsLoading(true);
      let fileWord = await WordBlobDocument({
        title: currentDocument.title,
        headers: currentDocument.headers,
        parts: currentDocument.parts,
        clauses: currentDocument.clauses,
        partHeader: currentDocument.partsHeader,
        numberingType: numeration,
        otherSignatories: currentDocument.otherSignatories,
      });
      const response = await convertToPdfService(fileWord);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        const dataFile = response.data.responseMessage?.data;
        if (!selectedHeader) {
          const file = URL.createObjectURL(
            new Blob([new Uint8Array(dataFile?.buffer?.data).buffer], {
              type: "application/pdf",
            })
          );
          window.open(file);
        } else {
          const docView = response.data.responseMessage.data.buffer.data;

          const bf = Buffer.from(docView);
          fileWord = new File([bf], currentDocument.title + ".pdf", {
            type: "application/pdf",
          });
          saveAs(fileWord, "doc.pdf");
          const obj = {
            file: fileWord,
            ...(selectedHeaderObject.documentDocxId && {
              documentDocxTemplate: selectedHeaderObject.documentDocxId._id,
            }),
            documentPdfTemplate: selectedHeaderObject.documentPdfId._id,
            originalname: currentDocument.title + ".pdf",
            contentType: "application/pdf",
          };

          const responseHeading = await previewHeadingDocument(obj);
          if (
            responseHeading.status === CODES.COD_RESPONSE_HTTP_OK &&
            responseHeading.data.success
          ) {
            handleDownloadDocument(
              responseHeading.data.responseMessage.data,
              false
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyPersonalization = () => {
    navigate("select-headings");
    Mixpanel.track("Crear Btn Aplicar membretes", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  const handleDownloadDocument = async (actualDocument, isWord) => {
    try {
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const finalFile = URL.createObjectURL(
        new Blob([bf], {
          type: "application/pdf",
        })
      );
      window.open(finalFile);
    } catch (error) {
      console.log(error);
    }
  };

  const validateDiffParts = (currentParts, newParts) => {
    const isDiff = newParts.some(
      (part, index) => part !== currentParts[index]?.name
    );

    return !!isDiff;
  };

  const handleUpdatePartsDocument = () => {
    for (const { clause } of currentDocument.clauses) {
      for (const [index, part] of clause.parts.entries()) {
        const isDiff = validateDiffParts(currentDocument?.parts, clause?.parts);
        if (index <= currentDocument?.parts?.length && isDiff) {
          dispatch(
            setNamePartClause({
              name: currentDocument.parts[index]?.name || part,
              clauseId: clause._id,
              index,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    setFilteredClauses(currentDocument.clauses);
    handleUpdatePartsDocument();
    handleCountGeneralFillBlankspaces();
  }, [currentDocument]);

  const handleReferenceNormative = async () => {
    try {
      setIsLoading(true);

      const updateClauses = await GetReferenceNormative(
        currentDocument.clauses
      );
      dispatch(setSelectedClauses({ clauses: updateClauses }));
    } catch (error) {
      console.log("=======Start Handle Reference Normative=======");
      console.error(error);
      console.log("=======End Handle Reference Normative=======");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentDocument.clauses && statusRefNormative === "fetch") {
      handleReferenceNormative();
      dispatch(setReferenceNormative());
    }
  }, [statusRefNormative]);

  const handleChangeEnumeration = (event) => {
    const { value } = event.target;
    setNumeration(value);
  };

  const handleAddPartToDocument = () => {
    const index = currentDocument.parts.length;
    if (index < 4) {
      const value = `Sin título ${index + 1}`;
      dispatch(
        addEditedPart({
          index,
          editedPart: { name: value, text: "", signatories: [] },
        })
      );
      // setEditTitlePart({ index, isEdit: true, value });
    }
  };

  const isValidSaveVersion = () => {
    const isMissingSignatories = currentDocument.parts.some(
      (par) => !par?.signatories?.length
    );

    return !!(missingBlankspaces.length > 0 || isMissingSignatories);
  };

  const getTotalBlankSpacesToFill = () => {
    let counter = 0;
    for (const cla of currentDocument.clauses) {
      const diff =
        getAllBlankSpaces(cla.clause) - getFillBlankSpaces(cla.clause);
      counter += diff;
    }
    return counter;
  };

  const isValidSaveVersionParts = () => {
    if (checkPartsGrammar) {
      let errorCounter = 0;
      for (const { clause } of currentDocument.clauses) {
        errorCounter += clause.partErrors;
      }
      return errorCounter > 0;
    } else {
      return false;
    }
  };
  const handleSaveAsDraft = async () => {
    try {
      setDraftModal(false);
      setIsLoading(true);
      const newDocument =
        currentDocument.parts.length === 4
          ? {
              ...currentDocument,
              parts: {
                partA: currentDocument.parts[0],
                partB: currentDocument.parts[1],
                partC: currentDocument.parts[2],
                partD: currentDocument.parts[3],
              },
              clauses: currentDocument.clauses.map((cla, index) => {
                return {
                  ...cla,
                  clause: {
                    ...cla.clause,
                    parts: {
                      partA: cla.clause.parts[0],
                      partB: cla.clause.parts[1],
                      partC: cla.clause.parts[2],
                      partD: cla.clause.parts[3],
                    },
                  },
                };
              }),
              name: fileName || "Borrador sin nombre",
            }
          : currentDocument.parts.length === 3
          ? {
              ...currentDocument,
              parts: {
                partA: currentDocument.parts[0],
                partB: currentDocument.parts[1],
                partC: currentDocument.parts[2],
              },
              clauses: currentDocument.clauses.map((cla, index) => {
                return {
                  ...cla,
                  clause: {
                    ...cla.clause,
                    parts: {
                      partA: cla.clause.parts[0],
                      partB: cla.clause.parts[1],
                      partC: cla.clause.parts[2],
                      partD: cla.clause.parts[3],
                    },
                  },
                };
              }),
              name: fileName || "Borrador sin nombre",
            }
          : {
              ...currentDocument,
              parts: {
                partA: currentDocument.parts[0],
                partB: currentDocument.parts[1],
              },
              clauses: currentDocument.clauses.map((cla, index) => {
                return {
                  ...cla,
                  clause: {
                    ...cla.clause,
                    parts: {
                      partA: cla.clause.parts[0],
                      partB: cla.clause.parts[1],
                      partC: cla.clause.parts[2],
                      partD: cla.clause.parts[3],
                    },
                  },
                };
              }),
              name: fileName || "Borrador sin nombre",
            };
      const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
      const draftRequest = await createDraft({
        name: fileName || "Borrador sin nombre",
        documentContent: newDocument,
        folderAssociated: undefined,
        versionAssociated: newDocument.versionAssociated,
        versionFrom: "CONTRACT",
        corporateUnit: corporateUnit._id,
        modifiedBy: userId,
        ...(selectedHeader && { letterheadAssociated: selectedHeader }),
      });
      if (
        draftRequest?.data?.responseCode === CODES.COD_RESPONSE_HTTP_OK &&
        draftRequest?.data?.success
      ) {
        setResponseSaveAsDraft({
          status: 200,
          data: {
            message: `El documento ha quedado guardado en la carpeta "Borradores"`,
          },
        });
        setOpenModalSaveAsDraft(true);
      }
      if (draftRequest.status === CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED) {
        setResponseDataModalInfo(draftRequest);
        setIsOpenModalInfoSaveDocument(true);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = (name) => {
    return register(name);
  };

  const handleChangeGrammarCheck = (event) => {
    dispatch(setPartsCorrection(event.target.checked));
    setCheckPartsGrammar(event.target.checked);
  };

  useEffect(() => {
    if (currentDocument) {
      for (const { clause } of currentDocument.clauses) {
        for (const [index, part] of clause.parts.entries()) {
          dispatch(
            setNamePartClause({
              name: currentDocument.parts[index]?.name || part,
              clauseId: clause._id,
              index,
            })
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  const handleCountGeneralFillBlankspaces = () => {
    if (currentDocument) {
      let count = 0;
      let total = 0;

      currentDocument.clauses.forEach((clause) => {
        const fillBlankSpaces = getFillBlankSpaces(clause.clause);

        const allBlankSpaces = getAllBlankSpaces(clause.clause);

        count += fillBlankSpaces;
        total += allBlankSpaces;
      });

      setCountGeneralBlankspaces({
        countBlank: count,
        totalBlank: total,
      });
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Datos del documento" />
        </Col>

        <Col md={9}></Col>
      </Row>
      <Row style={{ margin: "3% 0", alignItems: "center" }}>
        <Col md={3}>
          <InputGroup bsPrefix="input-group-container">
            <Form.Control
              bsPrefix="custom-input"
              placeholder="Buscar"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
            <InputGroup.Text bsPrefix="container-icon">
              <SearchIcon fontSize="large" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col />
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<PolicyOutlinedIcon />}
            className="custom-input__button__iaColor"
            size="medium"
            onClick={() => {
              Mixpanel.track("Crear Btn Identificar ref. Normativas ", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
              handleReferenceNormative();
            }}
          >
            Identificar ref. normativas
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<SubjectOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={handleClick}
          >
            Antecedentes o consideraciones
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                handleCloseSubMenu("con");
                Mixpanel.track("Crear Btn Antecedentes", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                });
              }}
            >
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Con numeraciones
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseSubMenu("sin");

                Mixpanel.track("Crear Btn Antecedentes", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                });
              }}
            >
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Sin numeraciones
              </ListItemText>
            </MenuItem>
          </Menu>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            size="medium"
            onClick={() => {
              navigate("create-clause", {
                state: {
                  from: "create",
                },
              });
              Mixpanel.track("Crear Btn Crear cláusula", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
            }}
          >
            Crear cláusula
          </Button>
        </Col>
        <Col xs={"auto"}>
          <ImportClause mixPanelTrack="Crear Btn Importar cláusula" />
        </Col>
      </Row>
      <Row>
        <Col md={4} style={{ display: "flex", alignItems: "center" }}>
          {isEditTitle ? (
            <Form.Group
              style={{ padding: "0%", margin: "2% 0" }}
              onBlur={() => {
                setIsEditTitle(false);
              }}
            >
              <Form.Control
                autoFocus={true}
                placeholder={"Escriba aqui"}
                bsPrefix={"input-group-container__no-icon label"}
                value={currentDocument.title}
                onChange={(e) => {
                  dispatch(setTitle({ title: e.target.value }));
                }}
                maxLength={250}
              />
            </Form.Group>
          ) : (
            <p className="heading word-break__break-all">
              {currentDocument.title}
            </p>
          )}
          <IconButton
            onClick={() => {
              setIsEditTitle(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Col>
        <Col
          md={3}
          className="caption"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isStoragePlanLimit ? (
            <IconNoSaveInfo />
          ) : (
            <label className="caption">
              Último guardado fue: <b>{lastSave}</b>
            </label>
          )}
        </Col>
        <Col
          md={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Row>
            <Col xs={12} className="d-flex gap-2 align-items-center">
              <p className="caption">Tipo de enumeración:</p>
              <Form.Select
                className="label mb-1"
                size="lg"
                bsPrefix={"input-group-container__no-icon label"}
                defaultValue={numeration}
                style={{ width: "50%" }}
                onChange={(event) => {
                  handleChangeEnumeration(event);
                }}
              >
                <option className="label" value="" disabled>
                  Enumeración
                </option>
                <option className="label" value={"number"}>
                  Por números
                </option>
                <option className="label" value={"letter"}>
                  Por letras
                </option>
              </Form.Select>
            </Col>
            <Col xs={12} className="d-flex gap-3 align-items-center">
              <p className="caption">Revisión Gramatical:</p>
              <p className="body-content">Desactivada</p>
              <FormControlLabel
                control={
                  <IOSSwitch checked={checkPartsGrammar} sx={{ m: 1 }} />
                }
                onChange={handleChangeGrammarCheck}
                label=""
              />
              <p className="body-content">Activada</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div
      // className="custom-accordion__container-document"
      // style={{ overflowX: "hidden" }}
      >
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            flexDirection: "column",
            marginTop: "2%",
            marginBottom: "0.5%",
          }}
        >
          <p className="crud-titles__titles-contract">PARTES</p>
          <DragDropContextParts partsList={currentDocument.parts} />
        </Row>
        <Row style={{ padding: "0 1%" }}>
          {currentDocument.parts.length < 4 && (
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<GroupsOutlinedIcon />}
                className="custom-input__button__primary-color"
                size="medium"
                onClick={handleAddPartToDocument}
              >
                Agregar rol contractual
              </Button>
            </Col>
          )}
        </Row>
        <br />
        <Divider />

        {currentDocument?.headers?.length > 0 && (
          <>
            <Row
              style={{
                width: "100%",
                padding: "0 1%",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              <p
                className="crud-titles__titles-contract"
                style={{ marginBottom: "1%" }}
              >
                ANTECEDENTES Y CONSIDERACIONES
              </p>
              <DragDropContext
                onDragEnd={(result) => {
                  dispatch(reorderHeader({ result }));
                }}
              >
                <Droppable droppableId="headers">
                  {(droppableProvided) => (
                    <Col
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {currentDocument?.headers &&
                        currentDocument.headers.map((enca, index) => (
                          <Draggable
                            key={
                              enca._id
                                ? enca._id
                                : "draggable" + enca.title + index
                            }
                            draggableId={
                              enca._id
                                ? enca._id
                                : "draggable" + enca.title + index
                            }
                            index={index}
                          >
                            {(draggablePorvider) => (
                              <Accordion
                                {...draggablePorvider.draggableProps}
                                {...draggablePorvider.dragHandleProps}
                                className="custom-accordion"
                                ref={draggablePorvider.innerRef}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Row style={{ width: "100%" }}>
                                    <Col md={"auto"} className="col-start">
                                      <DragIndicatorIcon
                                        fontSize="large"
                                        className="drag-color"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <p className="heading__primary-color">{`Encabezado ${
                                        index + 1
                                      }: ${ReactHtmlParser(enca.title)}`}</p>
                                    </Col>
                                  </Row>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Container>
                                    <Row
                                      className="caption"
                                      style={{ margin: "1% 0" }}
                                    >
                                      {ReactHtmlParser(enca.text)}
                                    </Row>
                                    <Row style={{ paddingLeft: "2%" }}>
                                      {enca.childs.map((sig, index2) => (
                                        <div key={uuidv4()}>
                                          <p className="heading__primary-color">{`Numeral ${
                                            index2 + 1
                                          }`}</p>
                                          <span className="caption">
                                            {ReactHtmlParser(sig.text)}
                                          </span>
                                        </div>
                                      ))}
                                    </Row>
                                    <Row className="caption">
                                      {enca.textFinal}
                                    </Row>
                                    <Row style={{ justifyContent: "right" }}>
                                      <Col md={3}>
                                        <Button
                                          variant="contained"
                                          startIcon={<EditIcon />}
                                          className="custom-input__button__secondary-color"
                                          onClick={() => {
                                            navigate(`edit-header/${index}`);
                                          }}
                                        >
                                          Editar encabezado
                                        </Button>
                                      </Col>
                                      <Col md={2}>
                                        <Button
                                          variant="contained"
                                          startIcon={<DeleteIcon />}
                                          className="custom-input__button__primary-color"
                                          onClick={() => {
                                            dispatch(
                                              deleteHeader({ index: index })
                                            );
                                          }}
                                        >
                                          <p className="button">Eliminar</p>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Container>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </Draggable>
                        ))}
                      {droppableProvided.placeholder}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>
            <Divider />
          </>
        )}
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            marginTop: "1%",
            marginBottom: "0.5%",
          }}
        >
          <p className="crud-titles__titles-contract">CLÁUSULAS</p>
          <Row className="align-items-center" style={{ justifyContent: "end" }}>
            {countGeneralBlankspaces.totalBlank > 0 && (
              <>
                <Col xs={3} className="text-right">
                  <span className="subheading__primary-active">{`Campos totales por rellenar: ${countGeneralBlankspaces.countBlank}/${countGeneralBlankspaces.totalBlank} `}</span>
                </Col>
                <Col xs={2}>
                  <Button
                    variant="contained"
                    startIcon={<DescriptionOutlinedIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      navigate(`blankspaces`);
                    }}
                  >
                    Rellenar todos
                  </Button>
                </Col>
              </>
            )}

            {countGeneralBlankspaces.totalBlank === 0 && (
              <>
                <Col xs={3} className="text-right">
                  <span className="subheading__error-color">{`No tienes espacios en blanco en este documento`}</span>
                </Col>
              </>
            )}
          </Row>
          <DragDropContext
            onDragEnd={(result) => {
              dispatch(reorderClause({ result }));
            }}
          >
            <Droppable droppableId="clauses">
              {(droppableProvided) => (
                <Col
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {filteredClauses?.length > 0 &&
                    filteredClauses.map((cla, index) => {
                      return (
                        <Draggable
                          key={
                            cla.clause._id
                              ? cla.clause._id
                              : "draggable" + index
                          }
                          draggableId={
                            cla.clause._id
                              ? cla.clause._id
                              : "draggable" + index
                          }
                          index={index}
                        >
                          {(draggableProvider) => (
                            <Accordion
                              {...draggableProvider.draggableProps}
                              {...draggableProvider.dragHandleProps}
                              className="custom-accordion"
                              ref={draggableProvider.innerRef}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Row style={{ width: "100%" }}>
                                  <Col md={"auto"} className="col-start">
                                    <DragIndicatorIcon
                                      fontSize="large"
                                      className="drag-color"
                                    />
                                  </Col>
                                  <Col md={7}>
                                    <p className="heading__primary-color">
                                      {`${
                                        numeration === "number"
                                          ? index + 1
                                          : "Cláusula " +
                                            NUMBERINGWORDSA[index + 1]
                                      }. ${cla.clause.title}`}
                                    </p>
                                  </Col>
                                  <Col md={4}>
                                    <Row>
                                      <Col xs={6}>
                                        {getAllBlankSpaces(cla.clause) > 0 && (
                                          <p className="subheading__primary-active">
                                            {`Campos por rellenar ${getFillBlankSpaces(
                                              cla.clause
                                            )}/${getAllBlankSpaces(
                                              cla.clause
                                            )}`}
                                          </p>
                                        )}
                                      </Col>
                                      <Col xs={6}>
                                        {cla.clause.partErrors > 0 && (
                                          <p className="subheading__error-color">
                                            {`Errores de partes: ${cla.clause.partErrors}`}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Row style={{ margin: "2% 0" }}>
                                  <Col className="subheading">
                                    <b>Categorías:</b>{" "}
                                    {cla?.clause?.categories &&
                                      cla.clause.categories.map((cat, i) => {
                                        return (
                                          <span
                                            key={uuidv4()}
                                            style={{ marginRight: "1%" }}
                                            className="caption custom-badges__secundary"
                                          >
                                            {cat.name}
                                          </span>
                                        );
                                      })}
                                    <b>Etiquetas:</b>{" "}
                                    {cla?.clause?.labels &&
                                      cla.clause.labels.map((tag, i) => {
                                        return (
                                          <span
                                            key={uuidv4()}
                                            style={{ marginRight: "1%" }}
                                            className="caption custom-badges__disabled"
                                          >
                                            {tag.name}
                                          </span>
                                        );
                                      })}
                                  </Col>
                                </Row>
                                <Row
                                  style={{ margin: "2% 0", display: "flex" }}
                                >
                                  <p
                                    className="subheading"
                                    style={{ display: "flex", maxWidth: "50%" }}
                                  >
                                    <b>Nombre de las partes:</b>{" "}
                                    {`${cla?.clause?.parts
                                      ?.map((part, index) => {
                                        return index ===
                                          cla.clause.parts.length - 1
                                          ? part
                                          : `${part}/`;
                                      })
                                      .join("")}`}
                                  </p>
                                  {currentDocument?.parts?.length === 2 && (
                                    <Col>
                                      <Button
                                        variant="text"
                                        size="medium"
                                        className="custom-input__button__link"
                                        onClick={() => {
                                          handleSwitchParts(cla?.clause);
                                        }}
                                      >
                                        Click aquí para intercambiar partes
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                                <Row style={{ margin: "1% 0" }}>
                                  <p className="subheading">
                                    <b>A favor de :</b>{" "}
                                    {`${cla.clause.inFavor}`}
                                  </p>
                                </Row>
                                <Row style={{ paddingLeft: "1.3%" }}>
                                  <span className="caption">
                                    {ReactHtmlParser(cla.clause.text)}
                                    {cla?.clause?.subclauses &&
                                      cla.clause.subclauses.map((sub, i) => {
                                        return (
                                          <Row
                                            key={uuidv4()}
                                            className="justify-text clauses-number"
                                            style={{ margin: "1% 0" }}
                                          >
                                            <Col xs={"auto"}>
                                              <b>
                                                {`${index + 1}.${i + 1} `}
                                                {sub?.subclause?.title &&
                                                  `${sub?.subclause?.title}: `}
                                              </b>
                                            </Col>
                                            <Col>
                                              <span className="caption">
                                                {ReactHtmlParser(
                                                  sub?.subclause?.text
                                                )}
                                              </span>
                                            </Col>
                                            <Container>
                                              {sub?.subclause?.childs &&
                                                sub.subclause.childs.map(
                                                  (child, index2) => {
                                                    return (
                                                      <Row
                                                        key={uuidv4()}
                                                        className="justify-text clauses-number"
                                                        style={{
                                                          paddingLeft: "2rem",
                                                          margin: "1% 0",
                                                        }}
                                                      >
                                                        <Col xs={"auto"}>
                                                          <b>
                                                            {`${index + 1}.${
                                                              i + 1
                                                            }.${index2 + 1} `}
                                                            {child?.title}
                                                          </b>
                                                        </Col>
                                                        <Col>
                                                          <span className="caption">
                                                            {ReactHtmlParser(
                                                              child?.text
                                                            )}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    );
                                                  }
                                                )}
                                            </Container>
                                          </Row>
                                        );
                                      })}
                                    <Container
                                      fluid
                                      style={{ paddingLeft: "1%" }}
                                    >
                                      {cla?.clause?.paragraphs &&
                                        cla.clause.paragraphs.map(
                                          (paragraph, index3) => {
                                            return (
                                              <Row
                                                key={paragraph?.paragraph?.text}
                                                className="justify-text mb-3"
                                              >
                                                <Col xs={"auto"}>
                                                  <b>{`Parágrafo ${
                                                    NUMBERINGWORDSO[index3 + 1]
                                                  }`}</b>
                                                </Col>
                                                <Col xs={"auto"}>
                                                  {ReactHtmlParser(
                                                    paragraph?.paragraph?.text
                                                  )}
                                                </Col>
                                              </Row>
                                            );
                                          }
                                        )}
                                    </Container>
                                  </span>
                                </Row>
                                <Row
                                  style={{
                                    justifyContent: "right",
                                    margin: "2% 0",
                                  }}
                                >
                                  <Col md={3}>
                                    {getAllBlankSpaces(cla.clause) > 0 && (
                                      <Button
                                        variant="contained"
                                        startIcon={<DescriptionOutlinedIcon />}
                                        className="custom-input__button__secondary-color"
                                        onClick={() => {
                                          // clearInterval(idInterval);
                                          navigate(`blankspaces/${index}`);
                                        }}
                                      >
                                        Rellenar espacios
                                      </Button>
                                    )}
                                  </Col>
                                  <Col md={3}>
                                    <Button
                                      variant="contained"
                                      startIcon={<EditIcon />}
                                      className="custom-input__button__secondary-color"
                                      onClick={() =>
                                        handleEditClause(
                                          cla.clause._id,
                                          cla.clause
                                        )
                                      }
                                    >
                                      Editar cláusula
                                    </Button>
                                  </Col>
                                  <Col md={2}>
                                    <Button
                                      variant="contained"
                                      startIcon={<DeleteIcon />}
                                      className="custom-input__button__primary-color"
                                      onClick={() => {
                                        dispatch(
                                          deleteClause({ index: index })
                                        );
                                      }}
                                    >
                                      <p className="button">Eliminar</p>
                                    </Button>
                                  </Col>
                                </Row>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Draggable>
                      );
                    })}
                  {droppableProvided.placeholder}
                </Col>
              )}
            </Droppable>
          </DragDropContext>
        </Row>
        <Divider />
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            marginTop: "1%",
          }}
        >
          <p className="crud-titles__titles-contract">FIRMAS</p>
          <Row>
            <Col md={"auto"}>
              <Button
                variant="contained"
                startIcon={<PersonAddAlt1Icon />}
                endIcon={<KeyboardArrowDownIcon />}
                className="custom-input__button__primary-color"
                onClick={handleClickExternalSignatory}
              >
                Agregar tercero como firmante
              </Button>

              <Menu
                anchorEl={anchorElSignatory}
                open={openSignatory}
                onClose={() => {
                  setAnchorElSignatory(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() =>
                    navigate(
                      `without-part/${currentDocument.otherSignatories.length}/create-signatory`
                    )
                  }
                >
                  <ListItemIcon>
                    <PostAddOutlined
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "1.4rem",
                      },
                    }}
                  >
                    Desde cero
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(
                      `select-signatory/${currentDocument.otherSignatories.length}/without-part`
                    );
                  }}
                >
                  <ListItemIcon>
                    <LocalLibraryOutlinedIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "1.4rem",
                      },
                    }}
                  >
                    Desde biblioteca
                  </ListItemText>
                </MenuItem>
              </Menu>
            </Col>
          </Row>
          <DragDropContextSignatories partsList={currentDocument?.parts} />
        </Row>
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            marginBottom: "0.5%",
            paddingBottom: "3rem",
          }}
        >
          <DragDropContext
            onDragEnd={(result) => {
              dispatch(reorderSignatoriesWithoutParts({ result }));
            }}
          >
            <Droppable droppableId="signatures-without-part">
              {(droppableProvided) => (
                <Col
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {currentDocument?.otherSignatories &&
                    currentDocument?.otherSignatories.map((par, index) => (
                      <Draggable
                        key={"draggable-without-part" + par.name}
                        draggableId={"draggable-without-part" + par.name}
                        index={index}
                      >
                        {(draggablePorvider) => (
                          <Accordion
                            {...draggablePorvider.draggableProps}
                            {...draggablePorvider.dragHandleProps}
                            className="custom-accordion"
                            ref={draggablePorvider.innerRef}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Row style={{ width: "100%" }}>
                                <Col md={"auto"} className="col-start">
                                  <DragIndicatorIcon
                                    fontSize="large"
                                    className="drag-color"
                                  />
                                </Col>
                                <Col md={4}>
                                  <p className="heading__primary-color">
                                    {`Firma ${par.name}`}
                                  </p>
                                </Col>
                              </Row>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Container>
                                <ul>
                                  {par?.signatories?.length > 0 ? (
                                    par.signatories.map(
                                      (sig, indexSignatory) => (
                                        <li
                                          className="caption__strong"
                                          key={sig?.information?.name}
                                          style={{ listStyle: "none" }}
                                        >
                                          <Row
                                            style={{
                                              alignItems: "center",
                                              backgroundColor: "#E6E9EF",
                                              padding: "2rem 2rem",
                                            }}
                                          >
                                            <Col>
                                              {sig.type ===
                                                "Persona natural" && (
                                                <>
                                                  <p className="caption">{` ${
                                                    sig?.information?.name ||
                                                    "¬Nombre¬"
                                                  }`}</p>
                                                  <p className="caption">{` ${
                                                    sig?.information
                                                      ?.documentType ||
                                                    "¬Tipo de documento¬"
                                                  }, ${
                                                    sig?.information
                                                      ?.documentNumber ||
                                                    "¬número de documento¬"
                                                  }`}</p>
                                                </>
                                              )}
                                              {sig.type ===
                                                "Persona jurídica" && (
                                                <>
                                                  <p className="caption">{` ${
                                                    sig?.signatory?.name ||
                                                    "¬Representante legal¬"
                                                  }`}</p>
                                                  {/* <p className="caption">{` ${sig?.information?.charge || "¬Cargo¬"}`}</p> */}
                                                  <p className="caption">{` ${
                                                    sig?.information?.name ||
                                                    "¬razón social¬"
                                                  }`}</p>
                                                </>
                                              )}
                                            </Col>
                                            <Col xs="auto">
                                              <IconButton
                                                className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                                                sx={{
                                                  borderRadius: "8px",
                                                  color: "#FFFFFF",
                                                }}
                                                onClick={() => {
                                                  navigate(
                                                    `without-part/${index}/edit-signatory/${indexSignatory}`
                                                  );
                                                }}
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Col>
                                            <Col xs="auto">
                                              <IconButton
                                                className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                                                sx={{
                                                  borderRadius: "8px",
                                                  color: "#FFFFFF",
                                                }}
                                                onClick={() => {
                                                  dispatch(
                                                    deleteSignatoryWithoutPart({
                                                      partIndex: index,
                                                    })
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Col>
                                          </Row>
                                        </li>
                                      )
                                    )
                                  ) : (
                                    <li className="caption">Sin firmas</li>
                                  )}
                                </ul>
                              </Container>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Draggable>
                    ))}
                  {droppableProvided.placeholder}
                </Col>
              )}
            </Droppable>
          </DragDropContext>
        </Row>
        <br />
      </div>

      <Row className="sidebar__bottom__container">
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<PaletteOutlined />}
            className="custom-input__button__secondary-color"
            onClick={handleApplyPersonalization}
          >
            {selectedHeader ? "Cambiar Membrete" : "Aplicar membretes"}
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<VisibilityIcon />}
            className="custom-input__button__secondary-color"
            onClick={handlePreviewPdf}
          >
            Vista Previa
          </Button>
        </Col>
        <Col xs={"auto"}>
          <WordCreator
            title={currentDocument.title}
            headers={currentDocument.headers}
            parts={currentDocument.parts}
            partHeader={currentDocument.partsHeader}
            clauses={currentDocument.clauses}
            numberingType={numeration}
            otherSignatories={currentDocument.otherSignatories}
            message={"Descargar word"}
            selectedHeader={selectedHeaderObject}
            setIsLoading={setIsLoading}
            mixPanelTrack="Crear Btn Descargar word "
          />
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            className="custom-input__button__secondary-color"
            onClick={() => {
              dispatch(cleanStore());
              navigate("/service/folders");
              Mixpanel.track("Crear Btn Descartar  ", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
            }}
          >
            Descartar
          </Button>
        </Col>

        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<PostAddOutlined />}
            className="custom-input__button__primary-color"
            onClick={() => {
              if (isStoragePlanLimit) return;
              setSaveAsModal(true);
              Mixpanel.track("Crear Btn Guardar como", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
            }}
            disabled={isStoragePlanLimit}
          >
            Guardar como
          </Button>
        </Col>
      </Row>

      <ModalPDFView
        title="Preview"
        open={openPreview}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setOpenPreview(false);
        }}
        handleDisagree={() => {
          setOpenPreview(false);
        }}
        onClose={() => {
          setOpenPreview(false);
        }}
        content={<p className="subheading">En progreso</p>}
      />

      <ModalDecision
        title={"Agregar tercero como firmante"}
        message={"¿Cómo quieres agregar tus firmantes?"}
        open={openModalSelectSignatory}
        onClose={setOpenModalSelectSignatory}
        agreeText="Desde Biblioteca"
        agreeIcon={<LocalLibraryOutlinedIcon />}
        disagreeIcon={<AddIcon />}
        disagreeText="Desde Cero"
        handleAgree={() => {
          navigate("select-signatory");
        }}
        handleDisagree={() => {
          navigate(
            `without-part/${currentDocument.otherSignatories.length}/create-signatory`
          );
        }}
      />
      <ModalDecision
        title={"Documento incompleto"}
        message={
          <Container fluid className="caption">
            <Row>Tu contrato parece incompleto, encontramos que:</Row>
            <br />
            <Row style={{ paddingLeft: "5%" }}>
              <ul>
                {currentDocument.parts.some(
                  (par) => !par?.signatories?.length
                ) && <li>Falta información de las partes.</li>}
                {getTotalBlankSpacesToFill() > 0 && (
                  <li>{`Hay ${getTotalBlankSpacesToFill()} espacios en blanco sin rellenar.`}</li>
                )}
              </ul>
            </Row>
            <Row> ¿Seguro quieres continuar con el guardado?</Row>
          </Container>
        }
        open={blankSpacesModal}
        onClose={() => setBlankSpacesModal(false)}
        agreeText="Continuar"
        disagreeText="Volver"
        handleAgree={() => {
          setFolderSelectedModal(true);
          setBlankSpacesModal(false);
        }}
        handleDisagree={() => setBlankSpacesModal(false)}
      />

      <ModalDecision
        title={"Guardar documento"}
        message={
          "A continuación por favor seleccione una carpeta para guardar el documento."
        }
        open={folderSelectedModal}
        onClose={setFolderSelectedModal}
        agreeText="Seleccionar carpeta"
        disagreeText="Cancelar"
        handleAgree={() => {
          dispatch(setName({ name: fileName }));

          saveType
            ? navigate(`${saveType}/folder/select-folder`)
            : navigate(`draft/folder/select-folder`);
        }}
        handleDisagree={() => {
          setFolderSelectedModal(false);
        }}
      />
      <ModalInfo
        title={"Sin permisos suficientes"}
        responseData={responseDataModalInfo}
        onClose={() => {
          setIsOpenModalInfoSaveDocument(false);
          navigate("/service/create");
        }}
        open={isOpenModalInfoSaveDocument}
        confirmationText={"Aceptar"}
      />
      <ModalDecision
        title={"Guardar borrador"}
        message={`Escoge la carpeta en la que quieras guardar el documento o has clic en "guardar" para que quede en la carpeta de "Borradores".`}
        open={draftModal}
        onClose={() => {
          setDraftModal(false);
        }}
        agreeText="Seleccionar carpeta"
        disagreeText="Guardar"
        handleAgree={() => {
          dispatch(setName({ name: fileName }));
          navigate(`draft/folder/select-folder`);
        }}
        handleDisagree={() => {
          handleSaveAsDraft();
        }}
      />
      <ModalDecision
        title={"Errores gramaticales encontrados"}
        message={
          <Container fluid className="caption">
            <Row>
              Hemos detectado algunas inconsistencias gramaticales sin corregir,
              ¿seguro quieres continuar?
            </Row>
            <br />
          </Container>
        }
        open={partsErrorsModal}
        onClose={() => setPartsErrorsModal(false)}
        agreeText="Guardar sin corregir"
        disagreeText="Cancelar"
        handleAgree={() => {
          setPartsErrorsModal(false);
          if (!isValidSaveVersion) {
            setFolderSelectedModal(true);
          } else {
            setBlankSpacesModal(true);
          }
        }}
        handleDisagree={() => {
          setPartsErrorsModal(false);
          setSaveAsModal(false);
        }}
      />
      <ModalInfo
        open={openModalSaveAsDraft}
        onClose={() => navigate("/service/folders")}
        responseData={responseSaveAsDraft}
        title={"Guardado exitoso"}
        confirmationText="Aceptar"
        handleCloseDefault={() => setOpenModalSaveAsDraft(false)}
        buttonClose={true}
      />
      <ModalDecision
        title={"Alerta de no guardado"}
        message={
          "En el momento no está disponible la funcionalidad de guardado ni autoguardado, porque la compañía ha alcanzado el máximo de almacenamiento de su plan. Comunícate con el usuario administrador para adaptar el plan. ¿Deseas continuar de todas formas?"
        }
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        handleDisagree={() => {
          navigate(-1);
        }}
        handleAgree={() => {
          setIsOpenModalLimitPlan(false);
        }}
        onClose={() => {
          setIsOpenModalLimitPlan(false);
        }}
        open={isOpenModalLimitPlan}
        type={"warning"}
      />
      <ModalSaveFileAs
        open={saveAsModal}
        onClose={setSaveAsModal}
        handleAgree={handleSubmit(handleSaveSubmit)}
        errors={errors}
        register={register}
        handleRegister={handleRegister}
        reset={reset}
        watch={watch}
      />
    </Container>
  );
};

export const IconNoSaveInfo = ({ style }) => (
  <Row style={{ alignItems: "center" }}>
    <Col xs={"auto"}>
      <label
        className="caption"
        style={{ ...style, fontWeight: 800, marginBottom: 0, color: "red" }}
      >
        Sin guardado automático disponible
      </label>
    </Col>
    <Col xs={"auto"}>
      <PopoverInfo
        icon={<InfoOutlinedIcon fontSize="large" color="error" />}
        section={{
          description:
            "La compañía ha alcanzado el máximo de almacenamiento de su plan, contáctate con el usuario administrador.",
        }}
      />
    </Col>
  </Row>
);

export default GeneralViewDocument;
