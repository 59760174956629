import { createSlice } from "@reduxjs/toolkit";

const headersSlice = createSlice({
  name: "headers",
  initialState: {
    selectedId: null,
    selectedHeader: null,
  },
  reducers: {
    setSelectedHeaderId: (state, action) => {
      state.selectedId = action.payload;
    },

    setSelectedHeader: (state, action) => {
      state.selectedHeader = action.payload;
    },
  },
});

export const { setSelectedHeaderId } = headersSlice.actions;
export const getSelectedHeaderId = (state) => state.headers.selectedId;

export const { setSelectedHeader } = headersSlice.actions;
export const getSelectedHeader = (state) => state.headers.selectedHeader;
export default headersSlice.reducer;
