export const TYPE_CHARGES = {
    NATURAL: "Nombre propio",
    LEGAL: "Representante legal",
};

export const PERSON_TYPE = {
    NATURAL: "NATURAL",
    LEGAL: "JURIDICA",
};

export const PARTS_LIBRARY_STATE = {
   DELETED: {
    _id: "621fdcd7e1b7dfa85f10bf46",
    name: "Eliminada",
  },
  ACTIVE: {
    _id: "621fdce7e1b7dfa85f10bf48",
    name: "Activo",
  }
};

export const SIGNATORIES_STATE = {
   DELETED: {
    _id: "621fdcd7e1b7dfa85f10bf46",
    name: "Eliminada",
  },
  ACTIVE: {
    _id: "621fdce7e1b7dfa85f10bf48",
    name: "Activo",
  }
};