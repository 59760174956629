import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../parts/breadcrum";
import SectionTitle from "../../../../../components/sectionTitle";
import DatatableChipsAndButtons from "../../../../../components/Datatable/tableChipsandButtons";
import {
  SaveRequestDocumentsInFolders,
} from "../../../../../services/general/requestServices";
import { CODES } from "../../../../../utils/codes";
import { useNavigate, useParams } from "react-router-dom";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";

const RequestFolder = () => {
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const { company: companyId } = payloadToken;
  const { consecutive } = useParams();
  /**INFORMACION DE LA TABLA */
  const columns = [
    {
      label: "Carpetas",
      field: "folder",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Carpetas",
      },
    },
    {
      label: "Etiquetas",
      field: "labels",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Etiquetas",
      },
    },
    {
      label: "Acciones",
      field: "actions",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Etiquetas",
      },
    },
  ];

  const navigate = useNavigate();
  const dataRows = [];
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const selectedFolder = { id: "", name: "" };
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const selectedRequest = { id: "", cons: "" };
  const dataF = {
    data: {
      responseMessage: `Los documentos fueron almacenados en la carpeta ${selectedFolder.name}.`,
    },
    status: CODES.COD_RESPONSE_HTTP_OK,
  };

  const handleAgreeModalDesicion = async () => {
    //REQUEST TO BUSINESSFOLDER
    try {
        
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");

      const obj = {
        consecutive: parseInt(consecutive),
        folderId: selectedFolder.id,
        companyId,
        corporateUnit
      };

      const service = await SaveRequestDocumentsInFolders(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          //Abrir modal de confirmación y navegate
          setIsOpenModalDecision(false);
          setIsOpenModalInfo(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisagreeModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
    navigate("/service/requests");
  };

  return (
    <Container fluid style={{ height: "100%vh " }}>
      <Row style={{ height: "5%" }}>
        <Breadcrum />
      </Row>
      <Row
        style={{ height: "10%", width: "8%", marginLeft: "0.3%" }}
        className="tab-title"
      >
        <SectionTitle title="  Carpetas" />
      </Row>
      <Row style={{ height: "5%", marginBottom: "2%" }} className="tab-text">
        Haz clic en la carpeta a la que quieres asociar esta solicitud.
      </Row>
      <Row>
        <DatatableChipsAndButtons columns={columns} rows={dataRows} />
      </Row>
      <ModalDecision
        title={"Almacenar documentos"}
        message={`¿Estás seguro de que quieres almacenar los documentos de la solicitud ${selectedRequest.cons} en la carpeta ${selectedFolder.name}?`}
        open={isOpenModalDecision}
        onClose={handleDisagreeModalDesicion}
        agreeText="Continuar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title={"Documentos guardados"}
        responseData={dataF}
        open={isOpenModalInfo}
        onClose={handleCloseModalInfo}
      />
    </Container>
  );
};

export default RequestFolder;
