import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";

import CustomSearchFilterButton from "../../../../../../../components/Search/CustomSearchFilterButton";

import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import { useDispatch, useSelector } from "react-redux";

import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import LoadingContent from "../../../../../../../components/loadingContent";
import {
  findPartById,
  findSignatoriesByCompany,
} from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../../utils/codes";

import { PARTS_LIBRARY_STATE } from "../utils";
import ModalSelectRoleSignatory from "../../../../../../../components/Modals/ModalSelectRoleSignatory";
import {
  addEditedSignatoryWithoutPart,
  addNewSignatoriesToPart,
  deleteSignatoryWithPartById,
  deleteSignatoryWithoutPartById,
  getCurrentDocumentParts,
  getCurrentSignatoriesWithoutPart,
} from "../../../../../../../parts/document/currentDocumentSlice";
import { PopoverLimits } from "../../../../../../../components/Popover/PopoverLimits";
export const SelectSignatoryForContract = () => {
  //Redux
  const dispatch = useDispatch();

  const currentSignatories = useSelector(getCurrentSignatoriesWithoutPart);

  const currentParts = useSelector(getCurrentDocumentParts);

  //States

  const [signatoriesList, setSignatoriesList] = useState([]);

  const [part, setPart] = useState({});

  const [openModalNoSignatories, setOpenModalNoSignatories] = useState(false);

  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Parte relacionada.
        </label>,
      ],
      field: "partName",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Cargo del firmante en la Parte
        </label>,
      ],
      field: "partPosition",
    },
  ];

  const columnsNoParts = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
  ];
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });

  const [selectedSignatories, setSelectedSignatories] = useState([]);

  const [deletedSignatoryList, setDeletedSignatoryList] = useState([]);

  const [isLoading, setIsLoading] = useState(null);

  const [openModalSelectSignatoryRole, setOpenModalSelectSignatoryRole] =
    useState(false);

  //Router

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { partIndex, type } = useParams();

  const { state } = useLocation();

  //Handles

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedSignatories((oldArray) => [...oldArray, item]);

      setDeletedSignatoryList(
        deletedSignatoryList.filter((itm) => itm?._id !== item?._id)
      );
    } else {
      setDeletedSignatoryList([...deletedSignatoryList, item]);
      setSelectedSignatories(
        selectedSignatories.filter((itm) => itm?._id !== item?._id)
      );
    }
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      const signatory = item.signatory;
      let name = getName(signatory);
      let id = `${signatory?.documentType}. ${signatory?.documentNumber}`;
      const contact = `${signatory?.email || ""}`;
      const address = signatory?.residence?.physicalAddress;
      const country = signatory?.residence?.country;
      const city = `${signatory?.residence?.province?.concat(", ") || ""} ${
        signatory?.residence?.city || ""
      }`;
      const number = `+${signatory?.mobileNumberData?.countryCode || ""} ${
        signatory?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${signatory?.countryCodeTelephone || ""} ${
        signatory?.cityCode || ""
      } ${signatory?.numberTelephone || ""}`;

      formatedData.push({
        name,

        partName: (
          <Row className="align-items-center">
            <Col xs={12}>
              <span className="caption__primary-color__active ">
                {part?.information?.name}
              </span>
            </Col>
          </Row>
        ),
        partPosition: (
          <Row className="align-items-center">
            <Col xs={"auto"} lg={8}>
              <span className="caption__primary-color__active ">
                {item.position}
              </span>
            </Col>

            {item?.limitations && (
              <Col xs={"auto"} lg={4}>
                <PopoverLimits
                  limitationsUpdatedDate={item?.limitationsUpdatedDate}
                  createdAt={item?.signatory?.createdAt}
                  limitations={item?.limitations}
                />
              </Col>
            )}
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const buildDataNoPart = (data) => {
    if (!data.length) return [];
    const formatedData = [];

    for (const item of data) {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;

      formatedData.push({
        name,

        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const buildSignatoryData = (data) => {
    let signatory = {};
    let information = {
      address: data?.residence?.physicalAddress,
      email: data?.email,
      documentNumber: data?.documentNumber,
      documentType: data?.documentType,
      name: getName(data),
    };

    return {
      gender: "",
      information,
      signatory,
      text: getSignatoryText(data),
      type: "Persona natural",
    };
  };

  const buildSignatoryDataWithPart = (signatory) => {
    return {
      address: signatory?.signatory?.residence?.physicalAddress,
      documentNumber: signatory?.signatory?.documentNumber,
      documentType:
        signatory?.signatory?.otherTypeDocument ||
        signatory?.signatory?.documentType ||
        "",
      name: getName(signatory?.signatory) || "",
      email: signatory?.signatory?.email,
      charge: signatory?.position,
      text: getSignatoryText(signatory?.signatory),
      isDefault: false,
      _id: signatory.signatory?._id,
    };
  };

  const getSignatoryText = (signatory) => {
    let text = `${getName(signatory) || "¬Nombre¬"} quien se identifica con ${
      signatory?.otherTypeDocument ||
      signatory?.documentType ||
      "¬tipo de documento¬"
    } número ${
      signatory?.documentNumber || "¬número de documento¬"
    } y con domicilio en ${
      signatory?.residence?.physicalAddress || "¬domicilio¬"
    }`;
    return text;
  };

  const handleCreateSignatory = () => {
    try {
      setIsLoading(true);
      if (type === "without-part") {
        let indexSignatory = partIndex;
        selectedSignatories.forEach((signatory) => {
          const values = buildSignatoryData(signatory);

          const obj = {
            name: signatory?.role,
            signatories: [values],
            _id: signatory._id,
          };
          dispatch(
            addEditedSignatoryWithoutPart({
              partIndex: indexSignatory,
              editedSignatory: obj,
            })
          );
          indexSignatory++;
        });

        if (deletedSignatoryList.length) {
          deletedSignatoryList.forEach((element) => {
            dispatch(
              deleteSignatoryWithoutPartById({ signatoryId: element._id })
            );
          });
        }
      } else {
        console.log(selectedSignatories);
        selectedSignatories.forEach((signatory) => {
          const values = buildSignatoryDataWithPart(signatory);

          dispatch(
            addNewSignatoriesToPart({
              indexPart: partIndex,
              index: state.roleIndex,
              newSignatory: values,
            })
          );
        });

        if (deletedSignatoryList.length) {
          deletedSignatoryList.forEach((element) => {
            dispatch(
              deleteSignatoryWithPartById({
                indexPart: partIndex,
                index: state.roleIndex,
                signatoryId: element._id,
              })
            );
          });
        }
      }
      navigate(-1);
    } catch (error) {
      console.log(
        "============ Handle Create Signatory Error Start ============"
      );
      console.error(error);
      console.log(
        "============ Handle Create Signatory Error End ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  //Use effects

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = localStorage.getItem("company");

        let associatedCorporateUnits = [
          localStorage.getItem("corporateUnitId"),
        ];

        const status = PARTS_LIBRARY_STATE.ACTIVE._id;
        setIsLoading(true);

        let response = {};
        if (type === "without-part") {
          response = await findSignatoriesByCompany({
            company,
            status,
            associatedCorporateUnits,
          });
        } else {
          response = await findPartById({
            partId:
              currentParts[partIndex]?.signatories[state.roleIndex]?.information
                ?._id,
          });
        }

        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          if (
            (type === "without-part" &&
              !response.data?.responseMessage?.data?.length) ||
            (type === "with-part" &&
              !response.data?.responseMessage?.data?.relatedSignatories?.length)
          ) {
            setOpenModalNoSignatories(true);
          }
          if (type === "without-part") {
            setSignatoriesList(response.data?.responseMessage?.data);
          } else {
            setPart(currentParts[partIndex]?.signatories[state.roleIndex]);
            setSignatoriesList(
              response.data?.responseMessage?.data?.relatedSignatories
            );
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (currentSignatories && signatoriesList) {
      if (type === "without-part") {
        currentSignatories?.forEach((signatory) => {
          let foundSignatory = signatoriesList.find(
            (item) => item?._id === signatory?._id
          );
          if (foundSignatory) {
            setSelectedSignatories((oldArray) => [...oldArray, foundSignatory]);
          }
        });
      } else {
        part?.signatories?.forEach((signatory) => {
          let foundSignatory = signatoriesList.find(
            (item) => item?.signatory?._id === signatory?._id
          );
          if (foundSignatory) {
            setSelectedSignatories((oldArray) => [...oldArray, foundSignatory]);
          }
        });
      }
    }
  }, [signatoriesList, part]);

  useEffect(() => {
    let tableColumns = [];
    let tranformData = [];
    if (type === "without-part") {
      tableColumns = columnsNoParts;
      tranformData = buildDataNoPart(signatoriesList);
    } else {
      tableColumns = columns;
      tranformData = buildData(signatoriesList);
    }

    setData({ rows: tranformData, columns: tableColumns });
    setFilteredData({ rows: tranformData, columns: tableColumns });
  }, [signatoriesList, selectedSignatories]);

  return (
    <Container fluid className="custom-container-scroll ">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Firmantes guardados`} />
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"} className="caption">
          {`Escoge el o los firmantes que quieras:`}
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
      </Row>

      <br />

      <br />
      <Row className="dt-list-center-content " style={{ marginBottom: "7rem" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes partes creadas"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckIcon fontSize="large" />}
            disabled={
              !selectedSignatories.length && !deletedSignatoryList.length
            }
            onClick={() => {
              if (type === "without-part") {
                setOpenModalSelectSignatoryRole(true);
              } else {
                handleCreateSignatory();
              }
            }}
          >
            Seleccionar
          </Button>
        </Col>
      </Row>

      <ModalInfo
        title="Sin firmantes guardados"
        onClose={() => setOpenModalNoSignatories(false)}
        open={openModalNoSignatories}
        responseData={{
          data: {
            message: "No existen firmantes guardados en biblioteca",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
      <ModalSelectRoleSignatory
        selectedSignatories={selectedSignatories}
        setSelectedSignatories={setSelectedSignatories}
        setIsOpen={setOpenModalSelectSignatoryRole}
        isOpen={openModalSelectSignatoryRole}
        title={"Terceros como Firmantes"}
        description={
          "Escribe la calidad que corresponda para entender por qué esta persona firmará el contrato si no está relacionada con ninguna de las partes"
        }
        submitForm={handleCreateSignatory}
      />
    </Container>
  );
};
