import React, { useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useOutletContext } from "react-router-dom";

const RegisterPhase1 = () => {
  const { userData, handleGeneralPhases, setUserData } = useOutletContext();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    personType: yup.string().required("*Este campo es obligatorio"),
    Tratamiento: yup
      .boolean()
      .required()
      .oneOf([true], "*Debe autorizar el tratamiento de datos"),
    Terminos: yup
      .boolean()
      .required()
      .oneOf([true], "*Debe aceptar los terminos de uso"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    userData.personType && setValue("personType", userData.personType);
    userData.term && setValue("Terminos", userData.term);
    userData.treatment && setValue("Tratamiento", userData.treatment);
  }, []);

  const handlePhase1 = (data) => {
    navigate(`/register/phase2`);
    setUserData((prev) => ({
      ...prev,
      personType: data.personType,
      term: data.Terminos,
      treatment: data.Tratamiento,
    }));
    handleGeneralPhases(1, true, false);
  };

  const handleBack = () => {
    navigate(`/login`);
  };
  return (
    <Container style={{ height: "100%", padding: "7% 5%" }}>
      <Row className="display-x-large__primary-color" style={{ height: "10%" }}>
        Bienvenido a Legal AI
      </Row>
      <Row style={{ height: "10%" }}>
        <p className="body-content">
          Para empezar, completa los siguientes datos:{" "}
        </p>
        <p className="caption">(Los campos que tengan * son obligatorios)</p>
      </Row>
      <Form onSubmit={handleSubmit(handlePhase1)}>
        <Row className="caption" style={{ height: "15%" }}>
          <Form.Select
            className="label mb-1"
            size="lg"
            style={{ width: "25%" }}
            bsPrefix={
              errors.personType
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
            defaultValue=""
            {...register("personType")}
          >
            <option className="label" value="" disabled>
              Escoja un tipo de persona
            </option>
            <option className="label" value="Natural">
              Natural
            </option>
            <option className="label" value="Juridica">
              Juridica
            </option>
          </Form.Select>
          <p className="caption custom-input__error">
            {errors.personType?.message}
          </p>
        </Row>
        <Row style={{ height: "20%", margin: "3% 0" }}>
          <p className="body-content">Información de importancia</p>
          <p className="caption">
            Los datos suministrados estarán regidos bajo nuestra política de
            manejo de datos y bajo nuestros términos y condiciones.
          </p>
        </Row>
        <Row className="caption" style={{ height: "20%" }}>
          <Form.Group className="mb-4" controlId="Terminos">
            <Form.Check
              {...register("Terminos")}
              type="checkbox"
              label="He leído los términos y condiciones contractuales respecto de las
        actividades de corretaje electrónico que realiza CARVAJAL T&S a través
        de esta plataforma información, los cuales acepto de forma libre, a
        partir de comprensión de la información suministrada."
            />
            <p className="caption custom-input__error">
              {errors.Terminos?.message}
            </p>
          </Form.Group>
          <Form.Group className="mb-5" controlId="Tratamiento">
            <Form.Check
              {...register("Tratamiento")}
              type="checkbox"
              label="Autorizo el tratamiento de mis datos personales, los de mi representada
        y los demás datos personal es que suministre por medio de la presente
        plataforma en los términos y para las finalidades definidos en el Aviso
        de Privacidad."
            />
            <p className="caption custom-input__error">
              {errors.Tratamiento?.message}
            </p>
          </Form.Group>
        </Row>
        <Row style={{ height: "15%" }}>
          <Col md={2}>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              className="custom-input__button__secondary-color"
              onClick={handleBack}
            >
              Regresar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              className="custom-input__button__primary-color"
            >
              Siguiente
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default RegisterPhase1;
