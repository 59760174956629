import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../components/loadingContent";
import ModalAssociateFolder from "../../../../../components/Modals/ModalAssociateFolder";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import { TerminateRequest } from "../../../../../services/general/requestServices";
import { CODES } from "../../../../../utils/codes";

const RequestAssociate = () => {
  const { consecutive, requestId } = useParams();
  const navigate = useNavigate();
  const [openModalSelectFolder, setOpenModalSelectFolder] = useState(false);
  const [folderSelected, setFolderSelected] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  useEffect(() => {
    Object.keys(folderSelected).length !== 0
      ? setIsModalOpen(true)
      : setIsModalOpen(false);
  }, [folderSelected]);

  const handleAgreeToFinishRequest = async () => {
    try {
      setIsModalOpen(false);
      setIsLoading(true);
      const obj = {
        requestId: requestId,
        folderAssociated: folderSelected?._id,
      };
      const service = await TerminateRequest(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("/service/requests");
        }
      } else if (service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(service);
        setIsOpenModalUnauthorized(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container fluid style={{ height: "100% !important" }}>
        {isLoading && <LoadingContent />}
        <Row>
          <Breadcrum />
        </Row>
        <Row className="tab-title">
          <SectionTitle title="Asociar solicitud" />
        </Row>
        <Row className="tab-text">
          Asocia la solicitud a una carpeta nueva o una carpeta existente
        </Row>

        <Row className="tab-cards">
          <Col md={6} style={{ padding: "0% 1% 0% 2%" }}>
            <div className="section-card">
              <Row style={{ height: "inherit" }}>
                <Col xs={2} className="cards__icon-section">
                  <img
                    className="tab-cards__folder"
                    src={
                      require(`../../../../../assets/svg/folderIcon.svg`)
                        .default
                    }
                  />
                </Col>
                <Col className="section-card__title">
                  <Row>
                    <p className="heading__primary-color">Carpeta existente</p>
                  </Row>
                  <br />
                  <Row>
                    <p className="caption__justify">
                      Asocia este nuevo documento a una carpeta existente.
                    </p>
                  </Row>
                  <br />
                  <Row>
                    <div
                      className="cards__nav-button"
                      onClick={() => setOpenModalSelectFolder(true)}
                    >
                      <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} style={{ padding: "0% 1% 0% 2%" }}>
            <div className="section-card">
              <Row>
                <Col xs={2} className="cards__icon-section">
                  <img
                    src={
                      require(`../../../../../assets/svg/borderFolderIcon.svg`)
                        .default
                    }
                  />
                </Col>
                <Col className="section-card__title">
                  <Row>
                    <p className="heading__primary-color">Nueva carpeta</p>
                  </Row>
                  <br />
                  <Row>
                    <p className="caption__justify">
                      Crea una nueva carpeta, a la que estará asociada los
                      documentos de esta solicitud.
                    </p>
                  </Row>
                  <br />
                  <Row>
                    <div
                      className="cards__nav-button"
                      onClick={() =>
                        navigate(
                          `/service/requests/associate/newFolder/${consecutive}/${requestId}`
                        )
                      }
                    >
                      <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <ModalAssociateFolder
        type="folder"
        open={openModalSelectFolder}
        setDataSelected={setFolderSelected}
        close={() => setOpenModalSelectFolder(false)}
      />
      <ModalDecision
        title={"Almacenar documentos"}
        message={`¿Estás seguro de que quieres almacenar los documentos de la solicitud ${consecutive} en la carpeta "${folderSelected?.name}"?`}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        agreeText="Continuar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeToFinishRequest}
        handleDisagree={() => setIsModalOpen(false)}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </>
  );
};

export default RequestAssociate;
