import AddIcon from "@mui/icons-material/Add";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Button, Divider, IconButton } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import ModalImportNumeral from "../../../../../../../parts/modals/ModalImportNumeral";
import RichEditorText from "../../../../../../../parts/RichEditorText";
import { getBlankSpaceIndex } from "../../../../../../../utils/blankspaces";
import { plainTextTagsLinks } from "../../../../../../../utils/partsCorrection";
import SubNumeralsUpdate from "../SubNumeralsUpdate";
import { changeNameParts } from "../utils";

export default function NumeralsModule({
  control,
  register,
  getValues,
  watch,
  errors,
  setClause,
  clause,
  editTitle,
  setEditTitle,
  setValue,
  subClauseRichEditorTextRef,
  isUpdate
}) {
  const [subClauseTitleIdx, setSubClauseTitleIdx] = useState(null);
  const [isOpenModalDecisionAddNumeral, setIsOpenModalDecisionAddNumeral] =
    useState(false);
  const [isOpenModalImportNumeral, setIsOpenModalImportNumeral] =
    useState(false);

  const {
    fields: subClausesFields,
    append: subClausesAppend,
    remove: subClausesRemove,
  } = useFieldArray({
    control,
    name: "subClauses",
  });

  const onChangeSubClauseTitle = (e, idx) => {
    const value = e.target.value;
    if (!value) {
      setValue(`subClauses.${idx}.title`, "");
    } else {
      setValue(`subClauses.${idx}.title`, e.target.value);
    }
  };

  const handleSubClauseTitle = (idx) => {
    setSubClauseTitleIdx(idx);
    setEditTitle(true);
  };

  const handleSubClauseBlankSpaces = (blankSpace, subClauseIdx) => {
    let blankSpaceId = getBlankSpaceIndex(
      clause.subClauses[subClauseIdx].blankSpaces
    );
    let item = {
      id: blankSpaceId,
      name: `blankSpace-${blankSpaceId}`,
    };

    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.map((subClause, idx) => {
        if (idx === subClauseIdx) {
          return {
            ...subClause,
            blankSpaces: [...subClause.blankSpaces, item],
          };
        }
        return subClause;
      }),
    }));

    subClauseRichEditorTextRef.current[subClauseIdx].current.addBlankSpace(
      clause.subClauses[subClauseIdx].blankSpaces
    );
  };

  const handleDeleteSubClause = (index) => {
    subClauseRichEditorTextRef.current[index].current.removeImportedNumeral();
    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.filter((_, idx) => idx !== index),
    }));

    subClausesRemove(index);
  };

  const onChangeSubNumeralTitle = (e, idx, subNumeralIdx) => {
    const value = e.target.value;
    if (!value) {
      setValue(`subClauses.${idx}.numerals.${subNumeralIdx}.title`, "");
    } else {
      setValue(
        `subClauses.${idx}.numerals.${subNumeralIdx}.title`,
        e.target.value
      );
    }
  };

  const subClauseRemoveBlankSpace = (index, subClauseIdx) => {
    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.map((subClause, idx) => {
        if (idx === subClauseIdx) {
          return {
            ...subClause,
            blankSpaces: subClause.blankSpaces.filter(
              (blankSpace) => blankSpace.id !== index
            ),
          };
        }
        return subClause;
      }),
    }));
    subClauseRichEditorTextRef.current[subClauseIdx].current.removeBlankSpace(
      index
    );
  };

  const handleCreateSubClause = () => {
    let item = {
      title: "",
      text: "",
      blankSpaces: [],
      isImported: false,
      numerals: [],
      id: null,
    };
    setClause((clause) => ({
      ...clause,
      subClauses: [...clause.subClauses, item],
    }));
    subClausesAppend(item);
    const index = clause?.subClauses?.length ?? 0;
    subClauseRichEditorTextRef.current[index] = createRef();
  };

  const handleCloseModalDesicionAddNumeral = () => {
    setIsOpenModalDecisionAddNumeral(false);
  };

  const handleAgreeModalDesicionCreateNumeral = () => {
    handleCreateSubClause();
    setIsOpenModalDecisionAddNumeral(false);
  };

  const handleAgreeModalDesicionImportNumeral = () => {
    setIsOpenModalImportNumeral(true);
    setIsOpenModalDecisionAddNumeral(false);
  };

  const buildPrevNumeralsBlankSpaces = (blankspaces) => {
    const blankSpacesArr = [];
    for (const blankSpace of blankspaces) {
      blankSpacesArr.push({
        id: blankSpace.index,
        name: blankSpace.name,
      });
    }
    return blankSpacesArr;
  };

  const buildPrevSubNumerals = (subNumerals) => {
    const subNumeralsArr = [];
    for (const item of subNumerals) {
      const obj = {
        title: item.title,
        text: item.text,
        blankSpaces: buildPrevNumeralsBlankSpaces(item.blankspaces),
        isImported: true,
      };
      subNumeralsArr.push(obj);
    }
    return subNumeralsArr;
  };

  const handleSubClausesSelected = (importedNumerals) => {
    let countNumeral = subClausesFields.length;
    for (const numeral of importedNumerals) {
      let item = {
        id: numeral.subclause._id,
        title: numeral.subclause.title || "",
        text: numeral.subclause.text,
        isImported: true,
        numerals: buildPrevSubNumerals(numeral.subclause.childs),
        blankSpaces: buildPrevNumeralsBlankSpaces(
          numeral.subclause.blankspaces
        ),
      };

      setClause((clause) => ({
        ...clause,
        subClauses: [...clause.subClauses, item],
      }));
      subClausesAppend(item);
      const newIdx = countNumeral;
      subClauseRichEditorTextRef.current[newIdx] =
        subClauseRichEditorTextRef.current[newIdx] ?? createRef();
      countNumeral++;
    }
  };

  useEffect(() => {
    clause.subClauses.forEach((subClause, idx) => {
      if (
        subClause.isImported &&
        subClauseRichEditorTextRef.current[idx].current
      ) {
        const parts = getValues("parts").map((part) => part.title);
        const text = changeNameParts(subClause.text, parts);
        subClauseRichEditorTextRef?.current[idx]?.current?.setEditorContents(
          plainTextTagsLinks(text)
        );
        setClause((clause) => ({
          ...clause,
          subClauses: clause.subClauses.map((subclause, index) => {
            if (subclause?.blankSpaces?.length) {
              for (const blankSpace of subclause?.blankSpaces) {
                subClauseRichEditorTextRef?.current?.[idx]?.current.setBlankSpacesValues(blankSpace.id)
              }
            }
            if (index === idx) return { ...subclause, isImported: false, text };
            return subclause;
          }),
        }));
      }
    });
  }, [subClausesFields]);

  return (
    <>
      {subClausesFields.map((subclause, idx) => {
        return (
          <Container key={subclause?.id} fluid>
            <Row className="mb-5" style={{ paddingLeft: "3%" }}>
              <Col>
                <Row className="mb-4">
                  <Col className="subClause-title">
                    {editTitle && subClauseTitleIdx === idx ? (
                      <>
                        <Form.Control
                          {...register(`subClauses.${idx}.title`, {
                            onChange: (e) => {
                              onChangeSubClauseTitle(e, idx);
                            },
                          })}
                          bsPrefix="input-group-container__no-icon label"
                        />
                        <IconButton
                          onClick={(e) => {
                            setEditTitle(false);
                          }}
                        >
                          <CheckOutlinedIcon
                            className="heading__primary-color"
                            fontSize="large"
                          />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {watch(`subClauses.${idx}.title`) ? (
                          <p className="heading__primary-color">
                            {`1.${idx + 1} ` +
                              getValues(`subClauses.${idx}.title`)}
                          </p>
                        ) : (
                          <p className="heading__subtitle">
                            {`1.${idx + 1} Sin título`}
                          </p>
                        )}
                        <IconButton
                          onClick={() => {
                            handleSubClauseTitle(idx);
                          }}
                        >
                          <ModeEditOutlineOutlinedIcon
                            className="heading__primary-color"
                            fontSize="large"
                          />
                        </IconButton>
                      </>
                    )}
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      {getValues("parts").map((item, idxParts) => {
                        if (watch(`parts.${idxParts}.title`)) {
                          return (
                            <Col
                              className="create-clause-coachmark-step-3"
                              xs={"auto"}
                              key={item?.title}
                            >
                              <Button
                                onClick={() =>
                                  subClauseRichEditorTextRef.current[
                                    idx
                                  ].current.addPart(item.title, idxParts)
                                }
                                variant="contained"
                                className="custom-input__button__secondary-color"
                              >
                                {item.title}
                              </Button>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Col
                        className="create-clause-coachmark-step-4"
                        xs={"auto"}
                      >
                        <Button
                          variant="contained"
                          startIcon={<AddIcon fontSize="large" />}
                          className="custom-input__button__secondary-color"
                          onClick={() =>
                            handleSubClauseBlankSpaces(
                              clause.subClauses[idx].blankSpaces.length + 1,
                              idx
                            )
                          }
                        >
                          Espacio en blanco
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__primary-color"
                      onClick={() => handleDeleteSubClause(idx)}
                    >
                      <DeleteOutlineIcon fontSize="large" />
                    </Button>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "120rem" }}>
                  <Col xs={12}>
                    <Controller
                      control={control}
                      name={`subClauses.${idx}.text`}
                      render={({ field: { onChange, onBlur } }) => {
                        return (
                          <RichEditorText
                            ref={subClauseRichEditorTextRef.current[idx]}
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            customClassName={
                              errors?.subClauses?.[idx]?.text?.message
                                ? "input-group-container__no-icon-error label"
                                : "input-group-container__no-icon label"
                            }
                            deleteBlankSpace={(id) => {
                              subClauseRemoveBlankSpace(id, idx)
                            }}
                          />
                        );
                      }}
                    />
                    <div className="caption custom-input__error">
                      {errors?.subClauses?.[idx]?.text?.message || ""}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <Container>
                  <Row>
                    <p className="label">Espacios en blanco:</p>
                  </Row>
                  {clause?.subClauses?.[idx]?.blankSpaces?.length > 0 &&
                    clause.subClauses[idx].blankSpaces.map(
                      (blankSpace, idxBlankSpace) => {
                        return (
                          <Row xs={"auto"} key={blankSpace?.id}>
                            <p className="heading__primary-color">
                              ESPACIO #{blankSpace.id}{" "}
                              <IconButton
                                onClick={() =>
                                  subClauseRemoveBlankSpace(blankSpace.id, idx)
                                }
                              >
                                <DeleteOutlineIcon
                                  className="heading__primary-color"
                                  fontSize="large"
                                />
                              </IconButton>
                            </p>
                          </Row>
                        );
                      }
                    )}
                </Container>
              </Col>
            </Row>
            <SubNumeralsUpdate
              idx={idx}
              onChangeSubNumeralTitle={onChangeSubNumeralTitle}
              setClause={setClause}
              clause={clause}
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              {...{ control, register, getValues, watch, errors }}
              setValue={setValue}
              isUpdate={isUpdate}
            />
            <Row className="mb-5">
              <Divider />
            </Row>
          </Container>
        );
      })}
      <br />
      <br />
      <Row xs={"auto"} style={{ justifyContent: "right" }}>
        <Button
          variant="contained"
          startIcon={<AddIcon fontSize="large" />}
          className="custom-input__button__secondary-color"
          onClick={() => setIsOpenModalDecisionAddNumeral(true)}
        >
          Numeral
        </Button>
      </Row>
      <ModalDecision
        title={"Agregar numeral"}
        message={"¿Quieres importar o crear un nuevo numeral?"}
        open={isOpenModalDecisionAddNumeral}
        onClose={handleCloseModalDesicionAddNumeral}
        agreeText="Crear"
        disagreeText="Importar"
        handleAgree={handleAgreeModalDesicionCreateNumeral}
        handleDisagree={handleAgreeModalDesicionImportNumeral}
      />
      <ModalImportNumeral
        open={isOpenModalImportNumeral}
        onClose={() => setIsOpenModalImportNumeral(false)}
        handleSubClausesSelected={handleSubClausesSelected}
      />
    </>
  );
}
