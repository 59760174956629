import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import CustomSearchFilter from "../Search/CustomSearchFilter";
import { Col, Container, Row } from "react-bootstrap";
import ModalCreateTag from "../../parts/modals/ModalCreateTag";
import {
  fetchTags,
  getTagList,
  getStatus,
} from "../../parts/manageTags/tagSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFolderTags,
  getFolderTagsList,
  getFolderTagsStatus,
} from "../../parts/folders/folderTagSlice";
import LoadingContent from "../loadingContent";
import {
  fetchTagsContractTypes,
  getTagContractTypesList,
  getTagContractTypesStatus,
} from "../../parts/manageTags/contractTypes/tagContractTypesSlice";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import {
  fetchTagsDocumentTempletes,
  getTagDocumentTempletesList,
  getTagDocumentTempleteStatus,
} from "../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalAssociateTags = ({
  previousTagsSelected,
  handleTagsSelected,
  type,
}) => {
  /**
   * Constantes
   */

  const opposites = {
    opcional: "Requerida",
    requerida: "Opcional",
    especifica: "Estándar",
    estandar: "Específica",
    sensible: "No sensible",
    nosensible: "Sensible",
  };

  const dispatch = useDispatch();
  const tagsOtherList = useSelector(getTagList);
  const folderTagsList = useSelector(getFolderTagsList);
  const statusTags = useSelector(getStatus);
  const statusFolderTags = useSelector(getFolderTagsStatus);
  const tagsContractTypesList = useSelector(getTagContractTypesList);
  const tagsContractTypesStatus = useSelector(getTagContractTypesStatus);

  const tagTempleteList = useSelector(getTagDocumentTempletesList);
  const statusTagsDocumentTempletes = useSelector(getTagDocumentTempleteStatus);
  const [open, setOpen] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setTagSelected(previousTagsSelected);
    setFilteredTags(tagsList);
    setOpen(true);
  };

  const handleCheckOpposites = (item, tag) => {
    const name = tag.name
      .toLowerCase()
      .normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "")
      .replace(" ", "");

    const opposite = opposites[name];
    const newArray = tagsList.map((internalTag) => {
      if (internalTag.name === opposite) {
        return { ...internalTag, oppositeSelected: item.checked };
      } else {
        return internalTag;
      }
    });
    setFilteredTags(newArray);
    setTagsList(newArray);
  };

  const handleChecked = (e, tag) => {
    const item = e.target;
    if (type === "CLAUSES") {
      handleCheckOpposites(item, tag);
    }
    if (item.checked) {
      setTagSelected((oldArray) => [...oldArray, tag]);
    } else {
      setTagSelected(tagSelected.filter((t) => t._id !== tag._id));
    }
  };

  const handleFilterTags = (tagsSelected) => {
    handleTagsSelected(tagsSelected);
    handleClose();
  };

  /* USEEFFECTS FETCH TAGS FOR CLAUSES */

  useEffect(() => {
    if (statusTags === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusTags]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          statusTags === "fetch" &&
          ["CLAUSES", "PARTSLIBRARY"].includes(type)
        ) {
          dispatch(fetchTags(type));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (type === "CLAUSES") {
      if (filteredTags.length <= 0) {
        const oppositeAttribute = tagsOtherList.map((tag) => {
          return { ...tag, oppositeSelected: false };
        });
        setTagsList(oppositeAttribute);
        setFilteredTags(oppositeAttribute);
      } else {
        setTagsList(tagsOtherList);
        setFilteredTags(tagsOtherList);
      }
    } else {
      setTagsList(tagsOtherList);
      setFilteredTags(tagsOtherList);
    }
  }, [tagsOtherList]);

  /* USEEFFECTS FETCH TAGS FOR CONTRACT TYPES */

  useEffect(() => {
    if (tagsContractTypesStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [tagsContractTypesStatus]);

  useEffect(() => {
    try {
      if (type === "CONTRACTTYPES" && tagsContractTypesStatus === "fetch") {
        dispatch(fetchTagsContractTypes());
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsContractTypesStatus, dispatch]);

  useEffect(() => {
    if (type === "CONTRACTTYPES") {
      setTagsList(tagsContractTypesList);
      setFilteredTags(tagsContractTypesList);
    }
  }, [tagsContractTypesList]);

  /* USEEFFECTS FETCH TAGS FOR FOLDERS */

  useEffect(() => {
    if (statusFolderTags === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusFolderTags]);

  useEffect(() => {
    try {
      if (type === "BUSINESSFOLDER" && statusFolderTags === "fetch") {
        dispatch(fetchFolderTags());
      }
    } catch (error) {
      console.error(error);
    }
  }, [statusFolderTags, dispatch]);

  useEffect(() => {
    if (type === "BUSINESSFOLDER") {
      setTagsList(folderTagsList);
      setFilteredTags(folderTagsList);
    }
  }, [folderTagsList]);

  useEffect(() => {
    try {
      if (type === "DOCUMENT_TEMPLETES" && statusFolderTags === "fetch") {
        dispatch(fetchTagsDocumentTempletes());
      }
    } catch (error) {
      console.error(error);
    }
  }, [statusFolderTags, dispatch]);

  useEffect(() => {
    if (type === "DOCUMENT_TEMPLETES") {
      setTagsList(tagTempleteList);
      setFilteredTags(tagTempleteList);
    }
  }, [tagTempleteList]);

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<BookmarkBorderOutlinedIcon fontSize="large" />}
        className="custom-input__button__primary-color"
        onClick={handleClickOpen}
      >
        Añadir etiquetas
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        {isLoading && <LoadingContent />}
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">{"Asociar etiqueta"}</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Container fluid>
            <Row>
              <Col>
                <CustomSearchFilter
                  list={tagsList}
                  setFilteredData={setFilteredTags}
                  placeholder={"Buscar etiqueta"}
                />
              </Col>
              <Col xs={"auto"}>
                <ModalCreateTag type={type} />
              </Col>
            </Row>
            <Row>
              {filteredTags.map((tag) => {
                return (
                  <Row
                    key={tag?._id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col xs={"auto"}>
                      <Checkbox
                        checked={tagSelected.some((t) => t._id === tag._id)}
                        onClick={(e) => handleChecked(e, tag)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                          color: "#00374f",
                          "& .Mui-checked": {
                            color: "#00374f",
                          },
                        }}
                        disabled={tag.oppositeSelected}
                      />
                    </Col>
                    <Col>
                      <Accordion className="custom-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <label className="body-content">{tag.name}</label>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            width: "100%",
                            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <p className="caption">{tag.description}</p>
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                  </Row>
                );
              })}
            </Row>
          </Container>
        </DialogContent>
        <DialogActions>
          <Container fluid>
            <Row xs={"auto"} style={{ justifyContent: "right" }}>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => handleFilterTags(tagSelected)}
                >
                  Aceptar
                </Button>
              </Col>
            </Row>
          </Container>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalAssociateTags;
