import EditIcon from "@mui/icons-material/Edit";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import RichEditorText from "../../../../../../parts/RichEditorText";
import Breadcrum from "../../../../../../parts/breadcrum";
import { CODES } from "./../../../../../../utils/codes";
import { createPrederminatedText } from "./prederminatedTextsSlice";
import { REGEXP } from "../../../../../../utils/regexp";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import { getTagDocumentTempletesList } from "../../../../../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import ModalDecision from "../../../../../../components/Modals/modalDecision";

const CreatePredeterminatedText = () => {
  const DEFAULT_VALUE_DECISION = {
    open: false,
    title: "",
    handleAgree: null,
    handleDisagree: null,
    disagreeText: "",
    agreeText: "",
    message: "",
    onClose: null,
  };
  //REDUX
  const tagsList = useSelector(getTagDocumentTempletesList);

  // USE STATES
  const [isLoading, setIsLoading] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [modalResponse, setModalResponse] = useState({
    open: false,
    response: {},
    title: "",
    action: null,
  });
  const [tagsSelected, setTagsSelected] = useState([]);
  const [modalDecision, setModalDecision] = useState(DEFAULT_VALUE_DECISION);

  const [predeterminatedText, setPredeterminatedText] = useState({
    name: "",
    text: "",
    labes: [],
  });

  // SCHEMA
  const schema = yup.object().shape({
    nameText: yup.string().required("*Este campo es obligatorio"),
    predeterminatedText: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.RICH_EDITOR_CONTENT, {
        message: "*Este campo no puede estar vacío",
      }),
  });

  // OTHER HOOKS
  const navigate = useNavigate();
  const predeterminatedTextRef = useRef();
  const dispatch = useDispatch();

  // CONFIG FORM
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  console.log({ values: getValues() });
  // HANDLE FUNCTIONS

  const handleSavePredeterminatedText = async (data) => {
    try {
      setIsLoading(true);
      let obj = {
        name: data?.nameText || "",
        text: data?.predeterminatedText || "",
        labels: tagsSelected.map((t) => t._id),
      };
      const createPredeterminatedTextService = await dispatch(
        createPrederminatedText(obj)
      ).unwrap();
      if (
        createPredeterminatedTextService.status ===
        CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setModalResponse({
          open: true,
          title: "Sin permisos suficientes",
          response: {
            ...createPredeterminatedTextService,
            data: {
              responseMessage: "Sin permisos suficientes",
            },
          },
          action: () =>
            setModalResponse({
              open: false,
              response: {},
              title: "",
              action: null,
            }),
        });
        return;
      } else if (
        createPredeterminatedTextService.status ===
        CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        setModalResponse({
          open: true,
          title: "Creación exitosa",
          response: {
            ...createPredeterminatedTextService,
            data: {
              responseMessage: "Se ha creado correctamente el bloque",
            },
          },
          action: () => navigate("/service/library/predeterminedTexts"),
        });
      } else {
        setModalResponse({
          open: true,
          title: "Ha ocurrido un error",
          response: {
            ...createPredeterminatedTextService,
            data: {
              responseMessage: "Ha ocurrido un error creando el bloque",
            },
          },
          action: () =>
            setModalResponse({
              open: false,
              response: {},
              title: "",
              action: null,
            }),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Crear bloque"} />
      </Row>
      <br />
      <Form onSubmit={handleSubmit(handleSavePredeterminatedText)}>
        <Row>
          <Col md={4} style={{ display: "flex", alignItems: "center" }}>
            {isEditTitle ? (
              <Form.Group
                style={{ padding: "0%", margin: "2% 0" }}
                onBlur={() => {
                  setIsEditTitle(false);
                }}
              >
                <Form.Control
                  {...register("nameText")}
                  autoFocus={true}
                  placeholder={"Escriba aqui"}
                  bsPrefix={"input-group-container__no-icon label"}
                  value={predeterminatedText?.name} // Nombre actual del texto predeterminado
                  onChange={(e) => {
                    const text = e.target.value;
                    setPredeterminatedText((predeterminatedText) => ({
                      ...predeterminatedText,
                      name: text,
                    }));
                    setValue("nameText", text);
                  }}
                />
              </Form.Group>
            ) : (
              <p className="heading">
                {predeterminatedText?.name || "Nombre bloque"}
              </p> // Nombre actual del texto predeterminado
            )}
            <IconButton
              onClick={() => {
                setIsEditTitle(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Col>
          {errors?.nameText?.message && (
            <div className="caption custom-input__error">
              {errors.nameText?.message}
            </div>
          )}

          <Col />
          <Col xs={"auto"}>
            <ModalAssociateTags
              tags={tagsList}
              handleTagsSelected={setTagsSelected}
              previousTagsSelected={tagsSelected}
              type="DOCUMENT_TEMPLETES"
            />
          </Col>
        </Row>
        <br />
        <Row style={{ justifyContent: "end" }}>
          {tagsSelected.map((tag) => {
            return (
              <Col
                xs={"auto"}
                style={{ display: "flex", alignItems: "center" }}
                key={tag?._id}
              >
                <span className="caption custom-badges__disabled">
                  {tag.name + " "}{" "}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() =>
                      setTagsSelected(
                        tagsSelected.filter((t) => t._id !== tag._id)
                      )
                    }
                  />
                </span>
              </Col>
            );
          })}
        </Row>
        <br />
        <Row
          style={{
            height: "80%",
          }}
        >
          <Controller
            control={control}
            name="predeterminatedText"
            render={({ field: { onChange, onBlur } }) => (
              <RichEditorText
                onChange={onChange} // send value to hook form
                onBlur={onBlur} // notify when input is touched/blur
                ref={predeterminatedTextRef}
                className={
                  errors.predeterminatedText
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                } // add class error if there is an error
                placeholder="Escribe el bloque aquí"
                minHeight="40rem"
              />
            )}
          />
          {errors?.predeterminatedText?.message && (
            <div className="caption custom-input__error">
              {errors.predeterminatedText?.message}
            </div>
          )}
        </Row>

        <br />

        <Row
          className="sidebar__bottom__container"
          sm={"auto"}
          style={{ justifyContent: "right" }}
        >
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<CloseIcon />}
              className="custom-input__button__secondary-color"
              type="button"
              onClick={() =>
                setModalDecision({
                  title: "Cancelar",
                  open: true,
                  handleAgree: () => navigate(-1),
                  handleDisagree: () =>
                    setModalDecision(DEFAULT_VALUE_DECISION),
                  onClose: () => setModalDecision(DEFAULT_VALUE_DECISION),
                  disagreeText: "Cancelar",
                  agreeText: "Aceptar",
                  message:
                    "¿Estás seguro de que quieres cancelar la creación del bloque?",
                })
              }
            >
              <p className="button">Cancelar</p>
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<SaveOutlinedIcon />}
              className="custom-input__button__primary-color"
              type="submit"
            >
              <p className="button">Aceptar</p>
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalInfo
        title={modalResponse.title}
        open={modalResponse.open}
        onClose={modalResponse.action}
        responseData={modalResponse.response}
      />
      <ModalDecision {...modalDecision} />
    </Container>
  );
};

export default CreatePredeterminatedText;
