import React, { useState } from "react";
import Breadcrum from "../../../../../../../parts/breadcrum";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getSelectedSignatory } from "../../../../../../../parts/parts/partsSlice";
import { useParams } from "react-router-dom";
import LoadingContent from "../../../../../../../components/loadingContent";
import { UpdateSignatoryForm } from "./updateSignatoryForm";
import SectionTitle from "../../../../../../../components/sectionTitle";

export const UpdateSignatoryLibrary = () => {
  const { id } = useParams();
  //States
  const [isLoading, setIsLoading] = useState(false);

  const currentSignatory = useSelector(getSelectedSignatory);
  return (
    <>
      <Container fluid className="custom-container-scroll">
        {isLoading && <LoadingContent />}
        <Row className="mb-4">
          <Breadcrum />
        </Row>
        <Row>
          <Col xs={"auto"}>
            <SectionTitle title={"Editar firmante"} />
          </Col>
        </Row>
        <Row>
          <Col className="text-description my-3">
            <p>Diligencia los siguientes campos para editar al firmante.</p>
          </Col>
        </Row>
        <Row>
          <UpdateSignatoryForm
            id={id}
            currentSignatory={currentSignatory}
            setIsLoading={setIsLoading}
          />
        </Row>
      </Container>
    </>
  );
};
