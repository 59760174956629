import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import FolderIcon from "@mui/icons-material/Folder";
import { Button, IconButton } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import {
  WordBlobDocument,
  WordBlobOtherDocument,
  wordCreatorFile,
} from "../../../../../../components/WordCreator/WordCreator";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  cleanStore,
  getCurrentDocument,
  setIdDraft,
} from "../../../../../../parts/document/currentDocumentSlice";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import {
  fetchAllFolders,
  fetchFolderById,
  getFolders,
  getStatusFolders,
  setFoldersStatus,
} from "../../../../../../parts/folders/foldersSlice";
import {
  fetchFolderTags,
  getFolderTagsList,
  getFolderTagsStatus,
} from "../../../../../../parts/folders/folderTagSlice";
import {
  getSelectedHeader,
  getSelectedHeaderId,
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../../parts/headings/headingsSlice";
import {
  ApplyHeadingToDocument,
  previewHeadingDocument,
} from "../../../../../../services/admin/documents/headingsServices";
import {
  createDraft,
  deleteVersion,
  getFileDocument,
  segmentDocument,
} from "../../../../../../services/documents/documentsRequest";
import {
  createNewVersion,
  createNewVersionOtherDocument,
} from "../../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import { findOneDataExternalRequest } from "../../../../../../services/general/CarvajalServices";
import {
  SendInfoToApplicant,
  changeRequestState,
} from "../../../../../../services/general/requestServices";
import { getCreateFrom } from "../../request/requestSlice";
import { resetOtherDocumentInfo } from "../../../../../../parts/document/otherDocumentSlice";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { Buffer } from "buffer";

const BusinessFolderView = () => {
  //TABLE COLUMNS
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Carpetas
        </label>,
      ],
      field: "folder",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Etiquetas
        </label>,
      ],
      field: "labels",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];
  const rows = [];
  const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { saveAs } = useParams();

  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const currentDocument = useSelector(getCurrentDocument);
  const createFrom = useSelector(getCreateFrom);

  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  const [tagsSelected, setTagsSelected] = useState([]);
  const [folderSelected, setFolderSelected] = useState({});
  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [saveBothFiles, setSaveBothFiles] = useState(false);
  const [typeFilesSaved, setTypeFilesSaved] = useState([]);
  const [saveRequestModal, setSaveRequestModal] = useState(false);
  const [isOpenModalInfoSaveDocument, setIsOpenModalInfoSaveDocument] =
    useState(false);

  const [isOpenModalInfoSaveOther, setIsOpenModalInfoSaveOther] =
    useState(false);
  const [responseDataModalInfo, serResponseDataModalInfo] = useState({
    requestResponse: {},
    title: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [folderFatherInfo, setFolderFatherInfo] = useState(null);
  const tagsList = useSelector(getFolderTagsList);
  const statusTags = useSelector(getFolderTagsStatus);
  const [saveModalDraft, setSaveModalDraft] = useState(false);
  const [alertCancelModal, setAlertCancelModal] = useState(false);
  const [alertData, setAlertData] = useState({});
  //Extrated from state - navigate
  const [savedReview, setSavedReview] = useState(location?.state?.savedReview);
  const [savedOriginal, setSavedOriginal] = useState(
    location?.state?.savedOriginal
  );
  const documentData = location?.state?.documentData;
  const originalDocument = location?.state?.originalDocument;
  const fileName = location?.state?.fileName;
  const expressions = location?.state?.expressions;
  const rule = location?.state?.rule;
  const selectedOption = location?.state?.selectedOption;
  const inReview = location?.state?.inReview;
  const documentType = location?.state?.documentType;
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const selectedHeader = useSelector(getSelectedHeaderId);
  const selectedHeaderObject = useSelector(getSelectedHeader);
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  const handleViewFolder = async (id) => {
    try {
      setIsLoading(true);
      if (id) {
        const selectedFolder = await dispatch(fetchFolderById(id));
        setFolderFatherInfo(selectedFolder.payload);
        const processedFolders = selectedFolder.payload.subFolders.map(
          (folder) => {
            let labels = "";

            for (const element of folder.labels) {
              labels += element.name + ",";
            }
            const created =
              moment(folder.createdAt).format("DD/MM/YYYY") !== "Invalid date"
                ? moment(folder.createdAt).format("DD/MM/YYYY")
                : folder.createdAt;
            return {
              businessFolderFatherId: folder.businessFolderFatherId,
              folder: (
                <Row
                  style={{
                    paddingLeft: "2%",
                    paddingTop: "1%",
                  }}
                >
                  <Col xs={"auto"} className="datatable-CB__tr-name">
                    <FolderIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </Col>
                  <Col className="datatable-CB__tr-name">{folder.name}</Col>
                </Row>
              ),
              labels: labels,
              createdAt: created,
              action: (
                <Row>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        handleViewFolder(folder._id);
                      }}
                    >
                      Abrir
                    </Button>
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__primary-color"
                      onClick={() => {
                        setFolderSelected(folder);
                        setFolderSelectedModal(true);
                      }}
                    >
                      Seleccionar carpeta
                    </Button>
                  </Col>
                </Row>
              ),
            };
          }
        );
        setDataTable({ columns: columns, rows: processedFolders });
        setFilteredDataTable({ columns: columns, rows: processedFolders });
      } else {
        fetchData();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (foldersStatus === "fetch") {
        dispatch(fetchAllFolders());
      }

      const processedFolders = folders.map((folder) => {
        let labels = "";

        for (const element of folder.labels) {
          labels += element.name + ",";
        }
        const created =
          moment(folder.createdAt).format("DD/MM/YYYY") !== "Invalid date"
            ? moment(folder.createdAt).format("DD/MM/YYYY")
            : folder.createdAt;
        return {
          folder: (
            <Row
              style={{
                paddingLeft: "2%",
                paddingTop: "1%",
              }}
            >
              <Col xs={"auto"} className="datatable-CB__tr-name">
                <FolderIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </Col>
              <Col className="datatable-CB__tr-name">{folder.name}</Col>
            </Row>
          ),
          labels: labels,
          createdAt: created,
          action: (
            <Row>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    handleViewFolder(folder._id);
                  }}
                >
                  Abrir
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setFolderSelected(folder);
                    setFolderSelectedModal(true);
                  }}
                >
                  Seleccionar carpeta
                </Button>
              </Col>
            </Row>
          ),
        };
      });

      setDataTable({ columns: columns, rows: processedFolders });
      setFilteredDataTable({ columns: columns, rows: processedFolders });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveRequest = async () => {
    try {
      setFolderSelectedModal(false);
      setIsLoading(true);
      if (saveAs === "alert") {
        const payloadToken = JSON.parse(localStorage.payloadToken);
        let documentContent = { name: currentDocument.name };
        if (documentType === codesDocumentType.contract._id) {
          let dataFile = documentData;
          if (dataFile?.type !== "application/pdf") {
            const convertDoc = await convertToPdfService(dataFile, "file");
            const docView = convertDoc.data.responseMessage.data.buffer.data;
            const bf = Buffer.from(docView);
            const name = documentData?.name.substring(
              0,
              dataFile?.name.lastIndexOf(".")
            );
            dataFile = new File([bf], name + ".pdf", {
              type: "application/pdf",
            });
          }
          const segmentDoc = await segmentDocument({
            file: dataFile,
            company: payloadToken.company,
          });
          documentContent = {
            ...documentContent,
            ...segmentDoc.data.responseMessage
          }
        }
        const saveRequest = await createNewVersion({
          name: currentDocument?.name || "Sin nombre",
          fileType: documentType,
          folderAssociated: folderSelected._id,
          documentContent,
          file: documentData,
          corporateUnit: corporateUnit._id,
          versionFrom: "FOLDER",
        });
        if (
          saveRequest.data.success &&
          saveRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          setSaveModal(true);
          setFolderSelectedModal(false);
          setAlertData(saveRequest.data?.responseMessage);
        } else {
          setIsOpenModalInfoSaveDocument(true);
          serResponseDataModalInfo({
            requestResponse: saveRequest,
            title: "Guardar documento",
          });
        }
      } else if (saveAs === "other") {
        const blob = new Blob([new Uint8Array(documentData.data).buffer], {
          type: "application/pdf",
        });

        const blob2 = new Blob(
          [new Uint8Array(originalDocument?.buffer?.data).buffer],
          {
            type: "application/pdf",
          }
        );

        const fileNameNoExtension = fileName;
        const file = new File([blob], fileNameNoExtension, { type: blob.type });
        const originalFile = new File([blob2], fileNameNoExtension, {
          type: blob.type,
        });

        let saveRequest1 = {};
        let saveRequest2 = {};
        if (selectedOption.indexOf("marked") !== -1) {
          saveRequest1 = await createNewVersion({
            name:
              selectedOption.indexOf("original") !== -1
                ? fileNameNoExtension + "_Revisado" + ".pdf" + ".pdf"
                : fileNameNoExtension + ".pdf" + ".pdf",
            fileType: codesDocumentType.Other._id,
            folderAssociated: folderSelected._id,
            documentContent: {
              name:
                selectedOption.indexOf("original") !== -1
                  ? fileNameNoExtension + "_Revisado" + ".pdf"
                  : fileNameNoExtension + ".pdf",
            },
            file,
            corporateUnit: corporateUnit._id,
            versionFrom: "CONTRACT",
            inReview: inReview ? inReview : false,
            reviewCounts: 1,
          });
          setTypeFilesSaved([...typeFilesSaved, "cargado"]);
          setSavedOriginal(true);
        }

        if (selectedOption.indexOf("original") !== -1) {
          saveRequest2 = await createNewVersion({
            name: fileNameNoExtension + ".pdf" + ".pdf",
            fileType: codesDocumentType.Other._id,
            folderAssociated: folderSelected._id,
            documentContent: { name: fileNameNoExtension },
            file: originalFile,
            corporateUnit: corporateUnit._id,
            versionFrom: "CONTRACT",
            inReview: inReview ? inReview : false,
            reviewCounts: 1,
          });
          setTypeFilesSaved([...typeFilesSaved, "revisado"]);
          setSavedReview(true);
        }

        if (
          selectedOption.indexOf("original") !== -1 &&
          selectedOption.indexOf("marked") !== -1
        ) {
          setSaveBothFiles(true);
        }

        if (
          (selectedOption.indexOf("marked") !== -1 &&
            saveRequest1?.data?.success &&
            saveRequest1?.status === CODES.COD_RESPONSE_HTTP_CREATED) ||
          (selectedOption.indexOf("original") !== -1 &&
            saveRequest2?.data?.success &&
            saveRequest2?.status === CODES.COD_RESPONSE_HTTP_CREATED)
        ) {
          saveRequest1.status = 200;

          if (saveRequest1.data) {
            saveRequest1.data.responseMessage = "Documento guardado con éxito.";
          }
          if (saveRequest2.data) {
            saveRequest2.data.responseMessage = "Documento guardado con éxito.";
          }
        }
        if (
          (!saveRequest1?.data?.success &&
            saveRequest1?.data.responseCode ===
              CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED) ||
          (!saveRequest2?.data?.success &&
            saveRequest2?.data.responseCode ===
              CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED)
        ) {
          let unsavedDocs = [];
          if (
            saveRequest1?.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
          ) {
            unsavedDocs.push("revisado");
          }

          if (
            saveRequest2?.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
          ) {
            unsavedDocs.push("cargado");
          }

          if (unsavedDocs.length === 2) {
            saveRequest1.data.responseMessage =
              "No has guardado el documento cargado y el documento revisado. La Compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el Usuario Administrador";
          } else {
            saveRequest1.data.responseMessage = `No has guardado el documento ${unsavedDocs[0]} . La Compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el Usuario Administrador`;
          }

          serResponseDataModalInfo({
            requestResponse: saveRequest1,
            title: "Documento sin guardar",
          });
          setIsOpenModalInfoSaveOther(true);
        } else {
          serResponseDataModalInfo({
            requestResponse: saveRequest1.data ? saveRequest1 : saveRequest2,
            title: "Guardado exitoso",
          });
          setIsOpenModalInfoSaveOther(true);
        }
      } else if (saveAs === "otherDoc") {
        const wordFile = await WordBlobOtherDocument({
          title: currentDocument?.name,
          content: currentDocument?.additionalInfoOtherDocument?.text,
        });

        const documentOtherAdditionalInfo =
          currentDocument?.additionalInfoOtherDocument;

        const saveRequest = await createNewVersionOtherDocument({
          name: currentDocument?.name || "Sin nombre",
          fileType: currentDocument.fileType,
          file: wordFile,
          folderAssociated: folderSelected._id,

          documentContent: {
            name: currentDocument.name,
            text: documentOtherAdditionalInfo.text,
            blankspaces: documentOtherAdditionalInfo.blankspaces,
            canEdit: documentOtherAdditionalInfo.canEdit || false,
            otherDocType: documentOtherAdditionalInfo.otherDocType,
          },

          corporateUnit: corporateUnit._id,
          versionFrom: "OTHER DOCUMENT",
        });
        if (
          saveRequest.data.success &&
          saveRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          setSaveModal(true);
          setFolderSelectedModal(false);
          setAlertData(saveRequest.data?.responseMessage);
        } else {
          setIsOpenModalInfoSaveDocument(true);
          serResponseDataModalInfo({
            requestResponse: saveRequest,
            title: "Guardar documento",
          });
        }
        dispatch(resetOtherDocumentInfo());
      } else {
        const file = await WordBlobDocument({
          title: currentDocument.title,
          headers: currentDocument.headers,
          parts: currentDocument.parts,
          clauses: currentDocument.clauses,
          partHeader: "",
          numberingType: "number",
          otherSignatories: [],
        });
        if (saveAs === "word") {
          const saveRequest = await createNewVersion({
            name: currentDocument?.name || "Sin nombre",
            fileType: codesDocumentType.word._id,
            folderAssociated: folderSelected._id,
            documentContent: { name: currentDocument.name },
            file,
            corporateUnit: corporateUnit._id,
            versionFrom: "CONTRACT",
            ...(selectedHeader && { letterheadAssociated: selectedHeader }),
          });
          if (
            saveRequest.data.success &&
            saveRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
          ) {
            if (selectedHeader) {
              const obj = {
                documentDocxTemplate: selectedHeaderObject.documentDocxId._id,
                documentPdfTemplate: selectedHeaderObject.documentPdfId._id,
                documentContrat: saveRequest.data.responseMessage._id,
              };

              const applyLetterheadRequest = await ApplyHeadingToDocument(obj);
              if (
                applyLetterheadRequest.data.success &&
                applyLetterheadRequest.status === CODES.COD_RESPONSE_HTTP_OK
              ) {
                setSaveModal(true);
                setFolderSelectedModal(false);
              } else {
                setIsOpenModalInfoSaveDocument(true);
                serResponseDataModalInfo({
                  requestResponse: applyLetterheadRequest,
                  title: "Guardar documento",
                });
              }
            } else {
              setSaveModal(true);
              setFolderSelectedModal(false);
            }
          } else {
            setIsOpenModalInfoSaveDocument(true);
            serResponseDataModalInfo({
              requestResponse: saveRequest,
              title: "Guardar documento",
            });
          }
        } else if (saveAs === "legal") {
          const newDocument =
            currentDocument.parts.length === 4
              ? {
                  ...currentDocument,
                  parts: {
                    ...currentDocument.parts,
                    partA: currentDocument.parts[0],
                    partB: currentDocument.parts[1],
                    partC: currentDocument.parts[2],
                    partD: currentDocument.parts[3],
                  },
                  clauses: currentDocument.clauses.map((cla, index) => {
                    return {
                      ...cla.clause,
                      parts: {
                        partA: cla.clause.parts[0],
                        partB: cla.clause.parts[1],
                        partC: cla.clause.parts[2],
                        partD: cla.clause.parts[3],
                      },
                    };
                  }),
                }
              : {
                  ...currentDocument,
                  parts: {
                    ...currentDocument.parts,
                    partA: currentDocument.parts[0],
                    partB: currentDocument.parts[1],
                    partC: currentDocument.parts[2],
                  },
                  clauses: currentDocument.clauses.map((cla, index) => {
                    return {
                      ...cla.clause,
                      parts: {
                        partA: cla.clause.parts[0],
                        partB: cla.clause.parts[1],
                        partC: cla.clause.parts[2],
                        partD: cla.clause.parts[3],
                      },
                    };
                  }),
                };
          const saveRequest = await createNewVersion({
            name: currentDocument?.name || "Sin nombre",
            fileType: codesDocumentType.contract._id,
            documentContent: newDocument,
            folderAssociated: folderSelected._id,
            file,
            corporateUnit: corporateUnit._id,
            versionFrom: "CONTRACT",
            ...(selectedHeader && { letterheadAssociated: selectedHeader }),
          });
          if (!currentDocument.versionAssociated) {
            await deleteVersion(newDocument.idDraft);
          }
          if (
            saveRequest.data.success &&
            saveRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
          ) {
            if (selectedHeader) {
              const obj = {
                documentDocxTemplate: selectedHeaderObject.documentDocxId._id,
                documentPdfTemplate: selectedHeaderObject.documentPdfId._id,
                documentContrat: saveRequest.data.responseMessage._id,
              };

              const applyLetterheadRequest = await ApplyHeadingToDocument(obj);
              if (
                applyLetterheadRequest.data.success &&
                applyLetterheadRequest.status === CODES.COD_RESPONSE_HTTP_OK
              ) {
                setSaveModal(true);
                setFolderSelectedModal(false);
              } else {
                setIsOpenModalInfoSaveDocument(true);
                serResponseDataModalInfo({
                  requestResponse: applyLetterheadRequest,
                  title: "Guardar documento",
                });
              }
            } else {
              setSaveModal(true);
              setFolderSelectedModal(false);
            }
          } else {
            setIsOpenModalInfoSaveDocument(true);
            serResponseDataModalInfo({
              requestResponse: saveRequest,
              title: "Guardar documento",
            });
          }
        } else if (saveAs === "draft") {
          const newDocument =
            currentDocument.parts.length === 4
              ? {
                  ...currentDocument,
                  parts: {
                    ...currentDocument.parts,
                    partA: currentDocument.parts[0],
                    partB: currentDocument.parts[1],
                    partC: currentDocument.parts[2],
                    partD: currentDocument.parts[3],
                  },
                  clauses: currentDocument.clauses.map((cla, index) => {
                    return {
                      clause: {
                        ...cla.clause,
                        parts: {
                          partA: cla.clause.parts[0],
                          partB: cla.clause.parts[1],
                          partC: cla.clause.parts[2],
                          partD: cla.clause.parts[3],
                        },
                      },
                    };
                  }),
                }
              : {
                  ...currentDocument,
                  parts: {
                    ...currentDocument.parts,
                    partA: currentDocument.parts[0],
                    partB: currentDocument.parts[1],
                    partC: currentDocument.parts[2],
                  },
                  clauses: currentDocument.clauses.map((cla, index) => {
                    return {
                      clause: {
                        ...cla.clause,
                        parts: {
                          partA: cla.clause.parts[0],
                          partB: cla.clause.parts[1],
                          partC: cla.clause.parts[2],
                          partD: cla.clause.parts[3],
                        },
                      },
                    };
                  }),
                };
          const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
          const draftRequest = await createDraft({
            name: newDocument?.name || "Borrador",
            documentContent: newDocument,
            folderAssociated: folderSelected._id,
            versionAssociated:
              currentDocument?.fileType?._id === codesDocumentType.draft._id
                ? ""
                : currentDocument.versionAssociated,
            versionFrom: "CONTRACT",
            modifiedBy: userId,
            ...(selectedHeader && { letterheadAssociated: selectedHeader }),
          });
          if (
            draftRequest.data.success &&
            draftRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
          ) {
            setSaveModalDraft(true);
            setFolderSelectedModal(false);
            setIsLoading(false);
          } else if (saveAs === "legal") {
            const newDocument =
              currentDocument.parts.length === 4
                ? {
                    ...currentDocument,
                    parts: {
                      ...currentDocument.parts,
                      partA: currentDocument.parts[0],
                      partB: currentDocument.parts[1],
                      partC: currentDocument.parts[2],
                      partD: currentDocument.parts[3],
                    },
                    clauses: currentDocument.clauses.map((cla, index) => {
                      return {
                        ...cla.clause,
                        parts: {
                          partA: cla.clause.parts[0],
                          partB: cla.clause.parts[1],
                          partC: cla.clause.parts[2],
                          partD: cla.clause.parts[3],
                        },
                      };
                    }),
                  }
                : {
                    ...currentDocument,
                    parts: {
                      ...currentDocument.parts,
                      partA: currentDocument.parts[0],
                      partB: currentDocument.parts[1],
                      partC: currentDocument.parts[2],
                    },
                    clauses: currentDocument.clauses.map((cla, index) => {
                      return {
                        ...cla.clause,
                        parts: {
                          partA: cla.clause.parts[0],
                          partB: cla.clause.parts[1],
                          partC: cla.clause.parts[2],
                          partD: cla.clause.parts[3],
                        },
                      };
                    }),
                  };
            const saveRequest = await createNewVersion({
              name: currentDocument?.name || "Sin nombre",
              fileType: codesDocumentType.contract._id,
              documentContent: newDocument,
              folderAssociated: folderSelected._id,
              corporateUnit: corporateUnit._id,
              file,
              versionFrom: "CONTRACT",
              ...(selectedHeader && { letterheadAssociated: selectedHeader }),
            });
            if (
              saveRequest.data.success &&
              saveRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
            ) {
              setSaveModalDraft(true);
              setFolderSelectedModal(false);
              setIsLoading(false);
            } else {
              alert("Algo salió mal");
            }
          } else if (saveAs === "draft") {
            const newDocument =
              currentDocument.parts.length === 4
                ? {
                    ...currentDocument,
                    parts: {
                      ...currentDocument.parts,
                      partA: currentDocument.parts[0],
                      partB: currentDocument.parts[1],
                      partC: currentDocument.parts[2],
                      partD: currentDocument.parts[3],
                    },
                    clauses: currentDocument.clauses.map((cla, index) => {
                      return {
                        clause: {
                          ...cla.clause,
                          parts: {
                            partA: cla.clause.parts[0],
                            partB: cla.clause.parts[1],
                            partC: cla.clause.parts[2],
                            partD: cla.clause.parts[3],
                          },
                        },
                      };
                    }),
                  }
                : {
                    ...currentDocument,
                    parts: {
                      ...currentDocument.parts,
                      partA: currentDocument.parts[0],
                      partB: currentDocument.parts[1],
                      partC: currentDocument.parts[2],
                    },
                    clauses: currentDocument.clauses.map((cla, index) => {
                      return {
                        clause: {
                          ...cla.clause,
                          parts: {
                            partA: cla.clause.parts[0],
                            partB: cla.clause.parts[1],
                            partC: cla.clause.parts[2],
                            partD: cla.clause.parts[3],
                          },
                        },
                      };
                    }),
                  };
            const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
            const draftRequest = await createDraft({
              name: newDocument?.name || "Sin nombre",
              documentContent: newDocument,
              folderAssociated: folderSelected._id,
              versionAssociated:
                currentDocument?.fileType?._id === codesDocumentType.draft._id
                  ? ""
                  : currentDocument.versionAssociated,
              versionFrom: "CONTRACT",
              modifiedBy: userId,
              ...(selectedHeader && { letterheadAssociated: selectedHeader }),
            });
            if (
              draftRequest.data.success &&
              draftRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
            ) {
              dispatch(
                setIdDraft({ id: draftRequest.data.responseMessage.data._id })
              );
              setSaveModalDraft(true);
              setFolderSelectedModal(false);
              setIsLoading(false);
            } else {
              alert("Algo salió mal");
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadDocument = async (actualDocument, isWord, name, type) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const blob = new File([bf], name, {
        type: type,
      });
      return blob;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddDocToRequest = async (id) => {
    try {
      setIsLoading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const company = localStorage.getItem("company");

      const consecutiveRequestInfo = {
        consecutive: id,
        company,
        corporateUnit,
      };
      const infoSelectedRequest = await findOneDataExternalRequest(
        consecutiveRequestInfo
      );
      const data = infoSelectedRequest.data.responseMessage;
      const fileWord = await wordCreatorFile({
        title: currentDocument.title,
        headers: currentDocument.headers,
        parts: currentDocument.parts,
        clauses: currentDocument.clauses,
      });
      let formatedDoc = new File([fileWord], currentDocument.name, {
        type: fileWord.type,
      });

      if (selectedHeader) {
        const obj = {
          file: formatedDoc,
          ...(selectedHeader.documentDocxId && {
            documentDocxTemplate: selectedHeader.documentDocxId._id,
          }),
          documentPdfTemplate: selectedHeader.documentPdfId._id,
          originalname: currentDocument.title + ".docx",
          contentType: fileWord.type,
        };
        const response = await previewHeadingDocument(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          const file = await handleDownloadDocument(
            response.data.responseMessage.data,
            false,
            currentDocument.title + ".docx",
            fileWord.type
          );

          formatedDoc = file;
        }
      }

      let obj = {
        companyId: company,
        corporateUnit,
        consecutive: data.consecutive,
        applicantId: data.applicant._id,
        otherDocuments: [],
        comment: "Documento agregado desde modulo crear",
        principalDocument: formatedDoc,
        attorneyName: `${payloadToken?.firstName || ""} ${
          payloadToken?.firstSurname || ""
        }`,
        requiredDocuments: [],
        applicantName: `${data?.applicant?.firstName || ""} ${
          data?.applicant?.firstSurname || ""
        }`,
      };

      const service = await SendInfoToApplicant(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          let objState = {
            requestId: data._id,
            state: "Revisión solicitante",
            message: "Documentos agregados.",
            isActive: true,
            timeZone: new Date().getTimezoneOffset() / -60,
          };
          const response = await changeRequestState(objState);
          if (response.status) {
            if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
              navigate("/service/folders");
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setSelectedHeader(null));
      dispatch(setSelectedHeaderId(null));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, foldersStatus]);

  const handleDisagreeSaveInRequest = () => {
    dispatch(cleanStore());
    setSaveRequestModal(false);
    navigate("/service/folders");
  };

  useEffect(() => {
    const onunload = (e) => {
      e.preventDefault();
    };
    window.addEventListener("beforeunload", onunload);
    return () => {
      window.removeEventListener("beforeunload", onunload);
    };
  }, []);

  useEffect(() => {
    const isAuthorValid = currentDocument.author;
    const isClausesValid = currentDocument.clauses.length;
    const isHeadersValid = currentDocument.headers.length;
    const isIdDraftValid = currentDocument.idDraft;
    const isNameValid = currentDocument.name;
    const isOtherSignatoriesValid = currentDocument.otherSignatories.length;
    const isPartsValid = currentDocument?.parts?.length;
    const isVersionsValid = currentDocument?.versions?.length;

    if (
      !isAuthorValid &&
      !isClausesValid &&
      !isHeadersValid &&
      !isIdDraftValid &&
      !isNameValid &&
      !isOtherSignatoriesValid &&
      !isPartsValid &&
      !isVersionsValid
    ) {
      navigate("/service/create");
    }
  }, [currentDocument]);

  const handleSaveDocumentRequest = async () => {
    setSaveModal(false);
    if (saveAs === "alert") {
      navigate(`/service/alerts/create/${alertData._id}`, { replace: true });
    } else if (createFrom.fromCreate === "REQUEST") {
      await handleAddDocToRequest(createFrom.requestId);
    } else {
      setSaveRequestModal(true);
    }
  };

  return (
    <Container fluid style={{ height: "100%" }}>
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row>
        <Col md={2} className="col-start">
          <SectionTitle title="Carpetas" />
        </Col>
        <Col md={9}></Col>
      </Row>
      <br />
      <Row>
        <Col className="label">
          Haz clic en la carpeta a la que quieres asociar este nuevo documento.
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={5}>
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<FolderIcon />}
            onClick={() => {
              dispatch(setFoldersStatus({ newStatus: "fetch" }));
              navigate(
                location.pathname.replace(
                  "select-folder",
                  folderFatherInfo?._id
                    ? `create-folder/create/${folderFatherInfo?._id}`
                    : `create-folder/create`
                )
              );
            }}
          >
            Crear nueva carpeta
          </Button>
        </Col>
        <Col xs={"auto"}>
          <FilterTag
            tagsList={tagsList}
            list={filteredDataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
            classStyle="custom-input__button__secondary-color"
            widthStyle="153px"
          />
        </Col>
      </Row>
      <Row style={{ height: "2%" }}>
        {tagsSelected.map((tag, idx) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      {(filteredDataTable.rows[0]?.businessFolderFatherId ||
        filteredDataTable.rows.length === 0) && (
        <Row>
          <Col xs={1}>
            <IconButton
              onClick={() => {
                setFolderFatherInfo({});
                handleViewFolder(
                  folderFatherInfo?.businessFolderFatherId
                    ? folderFatherInfo.businessFolderFatherId
                    : null
                );
              }}
            >
              <ArrowBackOutlinedIcon fontSize="large" />
            </IconButton>
          </Col>
        </Row>
      )}
      <Row style={{ height: "55%", overflowY: "scroll" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={"No se encuentran carpetas"}
            hover
            pagingTop
            searchBottom={false}
            entries={7}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "carpetas"]}
            fullPagination
          />
        }
      </Row>
      <ModalDecision
        title={"Seleccionar carpeta"}
        message={` ¿Seguro que quieres guardar tu documento ${currentDocument.name} en la carpeta ${folderSelected.name}?`}
        open={folderSelectedModal}
        onClose={setFolderSelectedModal}
        agreeText="Guardar"
        disagreeText="Cancelar"
        handleAgree={handleSaveRequest}
        handleDisagree={() => {
          setFolderSelectedModal(false);
          if (saveAs === "alert") {
            setAlertCancelModal(true);
          } else if (expressions) {
            navigate(-1);
          } else {
            navigate(-2);
          }
        }}
      />

      <ModalDecision
        title={"Atención"}
        message={`Si quieres crear una alerta asociada a un documento es necesario que lo guardes en Legal AI `}
        open={alertCancelModal}
        onClose={setAlertCancelModal}
        agreeText="Aceptar"
        handleAgree={() => {
          navigate(-1);
        }}
      />
      <ModalDecision
        title={"Guardado exitoso"}
        message={`Tu documento ${currentDocument.name} se ha guardado satisfactoriamente en la carpeta ${folderSelected.name}.`}
        open={saveModal}
        onClose={setSaveModal}
        agreeText="Aceptar"
        disagreeText={saveAs === "alert" ? "" : "Cancelar"}
        handleAgree={handleSaveDocumentRequest}
        handleDisagree={handleSaveDocumentRequest}
      />
      <ModalDecision
        title={"¿Quieres asignar este documento a una solicitud?"}
        message={`¿Quiere asignar el nuevo documento ${currentDocument.name} a una solicitud creada por un tercero?`}
        open={saveRequestModal}
        onClose={handleDisagreeSaveInRequest}
        agreeText="Asignar a una solicitud"
        disagreeText="Cancelar"
        handleAgree={() => {
          navigate(
            `${location.pathname.replace("select-folder", "select-request")}`
          );
        }}
        handleDisagree={handleDisagreeSaveInRequest}
      />
      <ModalInfo
        title={responseDataModalInfo.title}
        responseData={responseDataModalInfo.requestResponse}
        onClose={() => setIsOpenModalInfoSaveDocument(false)}
        open={isOpenModalInfoSaveDocument}
        confirmationText={"Aceptar"}
      />

      <ModalInfo
        title={responseDataModalInfo.title}
        responseData={responseDataModalInfo.requestResponse}
        onClose={() => {
          setIsOpenModalInfoSaveOther(false);
          navigate("/service/review/first-Time/another-document", {
            state: {
              data: {
                fileName: fileName,
                file: documentData,
                expressions: expressions,
                originalFile: originalDocument,
                savedReview: savedReview,
                savedOriginal: savedOriginal,
              },
              rule: rule,
              saved: saveBothFiles,
              typesSaved: typeFilesSaved,
            },
          });
        }}
        open={isOpenModalInfoSaveOther}
        buttonClose={true}
        confirmationText={"Aceptar"}
      />
      <ModalInfo
        title={"Guardado exitoso"}
        responseData={{
          status: 200,
          data: {
            message: `El documento ha quedado guardado en la carpeta ${folderSelected.name}.`,
          },
        }}
        handleCloseDefault={() => navigate("/service/folders")}
        onClose={() => navigate("/service/folders")}
        open={saveModalDraft}
        buttonClose={true}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default BusinessFolderView;
