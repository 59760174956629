export const REGEXP = {
  ONLY_LETTERS: /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
  PHONE_NUMBER:/^(\d{1,4}(\(\d{2,3}\))?)*\d{3,8}$/,
  ONLY_NUMBERS: /^\d+$/,
  LETTERS_COMMAS: /^[,A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
  ONE_UPPER_LETTER: /^(?=.*?[A-Z])/,
  ONE_LOWER_LETTER: /(?=.*?[a-z])/,
  ONE_NUMBER: /(?=.*?\d)/,
  ONE_SPECIAL_CHAR: /(?=.*?[#?!@$%^&*-])/,
  PERCENTAGE: /^[1-9]\d?$|^100$/,
  LETTERS_AND_NUMBERS: /^[a-zÁÉÍÓÚáéíóúñÑ0-9]+$/i,
  LETTERS_AND_NUMBERS_WITH_OTHER: /^[a-zÁÉÍÓÚáéíóúñÑ0-9 .&]+$/i,
  RICH_EDITOR_CONTENT: /<(p|table)[^>]*>.*?<\/\1>/g,
  MONGO_OBJECT_ID: /^[0-9a-fA-F]{24}$/,
  DATE_FORMAT_AMD:/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12]\d|3[01])$/,
  LETTER_NUMBERS_SPECIALCHAR: /^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9#?!@$%^&*\s-.;,]*$/,
};
