import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
} from "@mui/material";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";

import { ArticleOutlined } from "@mui/icons-material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import { WordBlobDocument, wordCreatorFile } from "../../../../../../components/WordCreator/WordCreator";
import { getCurrentDocument } from "../../../../../../parts/document/currentDocumentSlice";
import {
  getSelectedHeaderId,
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../../parts/headings/headingsSlice";
import {
  findAllHeadings,
  previewHeadingDocument,
} from "../../../../../../services/admin/documents/headingsServices";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../utils/codes";
import {
  fetchOneUnitCorporative,
  getUnitCorporativeById,
  getUnitsCorporativesStatusOne,
} from "../unitCorporativeSlice";

const MenuItemComponent = ({ handleClick, menuElement, ...props }) => {
  return (
    <div {...props}>
      <MenuItem
        onClick={() => {
          handleClick(menuElement.selectionClick);
        }}
        style={{ width: "351px" }}
      >
        <ListItemIcon>{menuElement.icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontFamily: "Roboto",
            fontSize: "1.4rem",
            fontWeight: "regular",
            lineHeight: "2rem",
            color: "#676879",
          }}
          primary={menuElement.title}
        />
      </MenuItem>
    </div>
  );
};

const ImageCard = ({ item, handleActions, selectedValue, ...props }) => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { value } = event.target;

    const selected = JSON.parse(value);

    dispatch(setSelectedHeaderId(selected._id));
    dispatch(setSelectedHeader(selected));
  };

  return (
    <Container
      fluid
      style={{ width: "225px" }}
      className={
        selectedValue === item._id ? "image__selected" : "image__notselected"
      }
    >
      <Row className="mt-3">
        <span className="home-init-cards-row__title-row">{item.name}</span>
      </Row>
      <Row className="align-items-center">
        <Col xs={"auto"}>
          <FormControlLabel
            control={
              <Radio
                checked={selectedValue === item._id}
                onChange={handleChange}
                value={JSON.stringify(item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            }
            label="Seleccionar"
          />
        </Col>
        <Col xs={"auto"}>
          <IconButton
            aria-label="view"
            className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
            sx={{ borderRadius: "8px" }}
            onClick={handleActions.view}
          >
            <VisibilityOutlinedIcon fontSize="large" />
          </IconButton>
        </Col>
      </Row>
    </Container>
  );
};

export default function SelectHeadings() {
  const [anchorElSort, setAnchorElSort] = useState(null);
  const openSortMenu = Boolean(anchorElSort);
  const selectedHeader = useSelector(getSelectedHeaderId);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFindall, setIsLoadingFindAll] = useState(false);

  const [filterDataHeadings, setFilterDataHeadings] = useState([]);
  const navigate = useNavigate();
  const statusFetchUnitCorporative = useSelector(getUnitsCorporativesStatusOne);
  const currentUnitCorporative = useSelector(getUnitCorporativeById);

  const currentDocument = useSelector(getCurrentDocument);
  const dispatch = useDispatch();
  const ID_STATUS_NEW = "64a74534ec9d9505220adfe5";
  const menuElement = [
    {
      selectionClick: 0,
      title: "Alfabéticamente (A-Z)",
      icon: (
        <SortOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
    },
    {
      selectionClick: 1,
      title: "Alfabéticamente (Z-A)",
      icon: (
        <SortOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
    },
    {
      selectionClick: 2,
      title: "Fecha de creación (Reciente - Antigua)",
      icon: (
        <CalendarMonthOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
    },
    {
      selectionClick: 3,
      title: "Fecha de creación (Antigua - Reciente)",
      icon: (
        <CalendarMonthOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const corporateUnit = localStorage.getItem("corporateUnitId");
      try {
        if (
          statusFetchUnitCorporative === "fetch" ||
          !currentUnitCorporative.name
        ) {
          setIsLoading(true);
          dispatch(fetchOneUnitCorporative(corporateUnit)).then(() => {
            setIsLoading(false);
          });
        }
      } catch (error) {
        console.log("=====Fetch Edit Unit Corporative Error=====");
        console.log(error);
        console.log("=====Finish Fetch Edit Unit Corporative Error=====");
      }
    };
    fetchData();
  }, [statusFetchUnitCorporative, dispatch]);
  //News

  const handleSortByAlpha = (sort) => {
    const sortedData = [...filterDataHeadings].sort((a, b) => {
      if (sort === "asc") {
        return a.name.localeCompare(b.name);
      } else if (sort === "desc") {
        return b.name.localeCompare(a.name);
      } else {
        return 0;
      }
    });

    setFilterDataHeadings(sortedData);
  };

  const handleSortByDate = (sort) => {
    const sortedData = [...filterDataHeadings].sort((a, b) => {
      if (sort === "asc") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sort === "desc") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return 0;
      }
    });

    setFilterDataHeadings(sortedData);
  };

  const handleSortElement = (event) => {
    setAnchorElSort(null);
    switch (event) {
      case 0:
        handleSortByAlpha("asc");
        break;
      case 1:
        handleSortByAlpha("desc");
        break;
      case 2:
        handleSortByDate("asc");
        break;
      case 3:
        handleSortByDate("desc");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      if (currentUnitCorporative.name) {
        const headingsService = await fetchData();
        let filteredHeadings = headingsService.filter(
          (item) => item.status._id === ID_STATUS_NEW
        );

        setFilterDataHeadings(filteredHeadings);
      }
    })();
  }, [currentUnitCorporative]);

  const fetchData = async () => {
    try {
      setIsLoadingFindAll(true);
      const companyId = localStorage.getItem("company");
      const corporateUnitId = localStorage.getItem("corporateUnitId");
      const applicableCorporateUnitIds = [corporateUnitId];
      const response = await findAllHeadings({
        companyId,
        applicableCorporateUnitIds,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return response.data.responseMessage.data;
      }
      return [];
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingFindAll(false);
    }
  };

  const handleView = async (data) => {
    try {
      setIsLoading(true);

      let originalName = currentDocument.title;

      let fileWord = await WordBlobDocument({
        title: currentDocument.title,
        headers: currentDocument.headers,
        parts: currentDocument.parts,
        clauses: currentDocument.clauses,
        partHeader: currentDocument.partsHeader,
        numberingType: "number",
        otherSignatories: currentDocument.otherSignatories,
      });

      const convertService = await convertToPdfService(fileWord);
      const docView = convertService.data.responseMessage.data.buffer.data;

      const bf = Buffer.from(docView);
      fileWord = new File([bf], originalName, {
        type: "application/pdf",
      });

      const obj = {
        file: fileWord,
        ...(data.documentDocxId && {
          documentDocxTemplate: data.documentDocxId._id,
        }),
        documentPdfTemplate: data.documentPdfId._id,
        originalname: originalName + ".pdf",
        contentType: "application/pdf",
      };

      const response = await previewHeadingDocument(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        handleDownloadDocument(response.data.responseMessage.data, false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadDocument = async (actualDocument, isWord) => {
    try {
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const finalFile = URL.createObjectURL(
        new Blob([bf], {
          type: "application/pdf",
        })
      );
      window.open(finalFile);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {(isLoading || loadingFindall) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>

      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Membretes y logos"} />
        </Col>
      </Row>

      <Row className="mt-4">
        <label className="caption">
          Elige el membrete que quieres usar en este documento
        </label>
      </Row>

      <Row style={{ rowGap: 20 }}>
        <Col></Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<SortOutlinedIcon fontSize="large" />}
            endIcon={<KeyboardArrowDownOutlinedIcon fontSize="large" />}
            onClick={(event) => setAnchorElSort(event.currentTarget)}
          >
            Ordenar
          </Button>
          <Menu
            anchorEl={anchorElSort}
            open={openSortMenu}
            onClose={() => setAnchorElSort(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuElement.map((item) => (
              <MenuItemComponent
                handleClick={handleSortElement}
                menuElement={item}
                key={item.selectionClick}
              />
            ))}
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Accordion className="custom-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`header-corporate-unit-${currentUnitCorporative?._id}`}
        >
          <Row style={{ alignItems: "center", width: "100%" }}>
            <Col xs={"auto"}>
              <span className="heading__primary-color">
                {currentUnitCorporative?.name || ""}
              </span>
            </Col>
            <Col></Col>
            <Col xs={"auto"}>
              <p className="caption" style={{ fontWeight: "bold" }}>
                Fecha de creación:{" "}
                <span className="caption">
                  {moment(currentUnitCorporative.createdAt).format(
                    "DD/MM/YYYY"
                  ) || ""}
                </span>
              </p>
            </Col>
          </Row>
        </AccordionSummary>
        <AccordionDetails>
          <Row style={{ rowGap: 25 }}>
            {filterDataHeadings.map((heading, index) => (
              <Col key={heading?._id} lg={3} md={6} xs={12}>
                <ImageCard
                  item={heading}
                  handleActions={{
                    view: () => handleView(heading),
                  }}
                  selectedValue={selectedHeader}
                />
              </Col>
            ))}
          </Row>
        </AccordionDetails>
      </Accordion>
      <Row className="justify-content-end">
        <Col xs={4} lg={1}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => {
              dispatch(setSelectedHeaderId(null));
              navigate(-1);
            }}
          >
            Cancelar
          </Button>
        </Col>

        {selectedHeader && (
          <Col xs={4} lg={3}>
            <Button
              variant="contained"
              startIcon={<ArticleOutlined />}
              className="custom-input__button__primary-color"
              onClick={() => {
                navigate(-1);
              }}
            >
              Seleccionar
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
}
