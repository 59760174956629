import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CODES } from "../../utils/codes";

import { CircularProgress } from "@mui/material";
import { activateUserRegistration } from "../../services/admin/manageUserService";

export const AuthorizeUser = () => {
  const { email, isAuthorized } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [responseMessage, setResponseMessage] = useState({});
  useEffect(() => {
    async function authorizedUser() {
      if (email && isAuthorized) {
        setIsLoading(true);
        try {
          const service = await activateUserRegistration({
            email,
            isAuthorized,
          });
          if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
            setIsLoading(false);
            setResponseMessage(service.data?.responseMessage);
          } else {
            setIsLoading(false);
            setResponseMessage({
              message: "No ha sido posible actualizar el estado del usuario",
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    authorizedUser();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00374f",
      }}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <div
          style={{
            width: "50%",
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F0F0F0",
            borderRadius: "17px",
          }}
        >
          <p
            className="heading__primary-color"
            style={{ fontFamily: "Raleway" }}
          >
            {responseMessage?.message}
          </p>
        </div>
      )}
    </div>
  );
};
