import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//Material Import
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
//Parts
import Breadcrum from "../../../../../parts/breadcrum";
//Components
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
import DiscussionBoard from "../../../../../components/DiscussionBoard/DiscussionBoard";

import ModalInfo from "../../../../../components/Modals/modalInfo";
//Icons
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOneUnitCorporative,
  fetchUnitCorporativeAllowedUsers,
  getUnitCorporativeAllowedUsers,
  getUnitCorporativeAllowedUserStatus,
  getUnitCorporativeById,
  getUnitsCorporativesStatusOne,
  modifyUnitCorporative,
} from "./unitCorporativeSlice";
import { CODES } from "../../../../../utils/codes";
import { IOSSwitch } from "../../../../../components/switch/switch";
//Services
//Utils

const EditUnitCorporative = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseDataInfo, setResponseDataInfo] = useState({});
  const [oldName, setOldName] = useState("");

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const statusFetchUnitCorporative = useSelector(getUnitsCorporativesStatusOne);
  const currentUnitCorporative = useSelector(getUnitCorporativeById);
  const currentUnitCorporativeAllowedUsers = useSelector(
    getUnitCorporativeAllowedUsers
  );
  const statusFetchUnitCorporativeAllowedUser = useSelector(
    getUnitCorporativeAllowedUserStatus
  );
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es requerido"),
    description: yup.string().required("*Este campo es requerido"),
    ans: yup.object().shape({
      contract: yup
        .number()
        .typeError("*Ingrese un número válido")
        .max(999, "*Este campo solo permite 3 caracteres")
        .min(1, "*Solo se permiten números mayores a 0")
        .required("*Este campo es requerido"),
      other: yup
        .number("*Debe ser un número")
        .typeError("*Ingrese un número válido")
        .max(999, "*Este campo solo permite 3 caracteres")
        .min(1, "*Solo se permiten números mayores a 0")
        .required("*Este campo es requerido"),
      documents: yup
        .number()
        .typeError("*Ingrese un número válido")
        .max(999, "*Este campo solo permite 3 caracteres")
        .min(1, "*Solo se permiten números mayores a 0")
        .required("*Este campo es requerido"),
    }),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
  };
  const handleUpdateUnitCorporative = async (data) => {
    try {
      setIsLoading(true);
      let obj = {
        id: id,
        name: data.name,
        oldName: oldName,
        description: data.description,
        isAssignmentLoad:
          data.reviewRuleUsers.length === 0 ? "true" : data.isAssignmentLoad,
        ANSValues: {
          ansContracts: data?.ans?.contract || 4,
          ansOthers: data?.ans?.documents || 3,
          ansOthersYes: data?.ans?.other || 4,
        },
        reviewRuleUsers: data.reviewRuleUsers,
        isCheckValidCERL: data.isCheckValidCERL,
      };
      const response = await dispatch(modifyUnitCorporative(obj)).unwrap();
      if (response.data.success) {
        navigate(-1);
      } else if (response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(response);
        setIsOpenModalUnauthorized(true);
      } else {
        setResponseDataInfo({
          data: response.data,
          status: response.data.responseCode,
        });
        setIsOpenModalInfo(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("==== HandleUpdateUnitCorporative Error ====");
      console.log(error);
      console.log("==== Finish HandleUpdateUnitCorporative Error ====");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameUser = (user) => {
    const name = user.firstName
      ? user.firstName +
        " " +
        user.secondName +
        " " +
        user.firstSurname +
        " " +
        user.secondSurname
      : payloadToken.companyName;
    return name;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusFetchUnitCorporative === "fetch") {
          dispatch(fetchOneUnitCorporative(id));
        }
      } catch (error) {
        console.log("=====Fetch Edit Unit Corporative Error=====");
        console.log(error);
        console.log("=====Finish Fetch Edit Unit Corporative Error=====");
      }
    };
    fetchData();
  }, [statusFetchUnitCorporative, dispatch]);
  useEffect(() => {
    if (statusFetchUnitCorporative === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusFetchUnitCorporative]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusFetchUnitCorporativeAllowedUser === "fetch") {
          if (currentUnitCorporative._id) {
            dispatch(
              fetchUnitCorporativeAllowedUsers({
                corporateUnitId: currentUnitCorporative._id,
                companyId: currentUnitCorporative.company._id,
              })
            );
          }
        }
      } catch (error) {
        console.log("=====Fetch Edit Unit Corporative Error=====");
        console.log(error);
        console.log("=====Finish Fetch Edit Unit Corporative Error=====");
      }
    };
    fetchData();
  }, [statusFetchUnitCorporativeAllowedUser, dispatch, currentUnitCorporative]);

  useEffect(() => {
    const {
      name,
      description,
      ANSValues,
      isAssignmentLoad,
      reviewRuleUsers,
      isCheckValidCERL,
    } = currentUnitCorporative;
    setOldName(name);
    setValue("name", name);
    setValue("description", description);
    setValue("isAssignmentLoad", isAssignmentLoad ? "true" : "false");
    setValue(
      "reviewRuleUsers",
      reviewRuleUsers?.map((item) => item._id)
    );

    setValue("isCheckValidCERL", isCheckValidCERL);
    setValue("ans.contract", ANSValues?.ansContracts || 4);
    setValue("ans.other", ANSValues?.ansOthersYes || 3);
    setValue("ans.documents", ANSValues?.ansOthers || 4);
  }, [currentUnitCorporative]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Editar unidad corporativa" />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Badge
            style={{ cursor: "pointer" }}
            badgeContent={0}
            color="primary"
            onClick={() => {
              setIsHideDiscussion(false);
            }}
          >
            <NotificationsNoneIcon fontSize="large" color="#00374F" />
          </Badge>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption">
          Llena los siguientes campos para la unidad corporativa.
        </Col>
      </Row>
      <br />
      <br />
      <Form onSubmit={handleSubmit(handleUpdateUnitCorporative)}>
        <Row className="mb-5">
          <Form.Group as={Col} xs={4}>
            <Form.Control
              {...register("name")}
              placeholder={"Nombre de la unidad corporativa"}
              bsPrefix={
                errors.name
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors.name?.message}
            </p>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} xs={4}>
            <Form.Control
              {...register("description")}
              placeholder={"Descripción de la unidad corporativa"}
              bsPrefix={
                errors.description
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              as="textarea"
              rows="4"
            />
            <p className="caption custom-input__error">
              {errors.description?.message}
            </p>
          </Form.Group>
        </Row>
        <Row>
          <label className="label__subtitle">
            Acuerdos de nivel de servicios (ANS)
          </label>
          <label className="label__description">
            Gestiona el tiempo máximo que debe tomar la solución de solicitudes
            para esta <b>unidad corporativa</b> definiendo los ANS
          </label>
          <label className="label__description">Número de días para:</label>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col xs={"auto"}>
            <label className="label__description">Contratos:</label>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={1}>
            <Form.Control
              maxLength={3}
              defaultValue={4}
              autoComplete="nope"
              {...register("ans.contract")}
              bsPrefix={
                errors?.ans?.contract
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            {errors?.ans?.contract && (
              <p className="caption custom-input__error">
                {errors?.ans?.contract?.message}
              </p>
            )}
          </Col>
          <Col xs={"auto"}>
            <label className="label__description">Otrosí:</label>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={1}>
            <Form.Control
              maxLength={3}
              defaultValue={3}
              autoComplete="nope"
              {...register("ans.other")}
              bsPrefix={
                errors?.ans?.other
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            {errors?.ans?.other && (
              <p className="caption custom-input__error">
                {errors?.ans?.other?.message}
              </p>
            )}
          </Col>
          {/*
          // El campo Otros Documentos se oculto ya que aun faltan HUs por implementar para completar su funcionalidad
          
           <Col xs={"auto"}>
            <label className="label__description">Otros documentos:</label>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={1}>
            <Form.Control
              maxLength={3}
              defaultValue={4}
              autoComplete="nope"
              {...register("ans.documents")}
              bsPrefix={
                errors?.ans?.documents
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            {errors?.ans?.documents && (
              <p className="caption custom-input__error">
                {errors?.ans?.documents?.message}
              </p>
            )}
          </Col> */}
        </Row>
        <Row>
          <label className="label__description__primary__hover">
            Máximo 3 caracteres permitidos por campo.
          </label>
        </Row>

        <Row className="mt-4">
          <label className="heading__default">
            Reglas de asignación en las solicitudes
          </label>
          <label className="label__subtitle mt-2">Solicitudes</label>
          <label className="caption">
            Elige la forma de asignación de solicitudes
          </label>

          <Form.Group as={Col} xs={4}>
            <Form.Select
              {...register("isAssignmentLoad")}
              onChange={(e) => {
                setValue("isAssignmentLoad", e.target.value);
                setValue("reviewRuleUsers", []);
              }}
              className="label mb-4"
            >
              <option value="" className="label" disabled>
                Asignación de solicitudes
              </option>
              <option value={true} className="label">
                Asignar por carga de trabajo
              </option>
              <option value={false} className="label">
                Asignar por usuario
              </option>
            </Form.Select>
          </Form.Group>

          {watch("isAssignmentLoad") === "false" && (
            <>
              <label className="caption">
                Elige el usuario al que deseas asignar
              </label>
              <Form.Group as={Col} xs={4}>
                <Controller
                  name="reviewRuleUsers"
                  control={control}
                  disableUnderline={true}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-100"
                      multiple
                      input={
                        <OutlinedInput
                          sx={{
                            maxHeight: "40px",
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                        />
                      }
                      renderValue={(selected) => (
                        <span className="label">
                          {currentUnitCorporativeAllowedUsers
                            ?.filter((user) => selected.includes(user._id))
                            ?.map((user) => handleNameUser(user))
                            .join(", ")}
                        </span>
                      )}
                    >
                      {currentUnitCorporativeAllowedUsers?.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          <Checkbox checked={field.value.includes(user._id)} />
                          <ListItemText
                            className="body-content__disabled"
                            primary={handleNameUser(user)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </Form.Group>
            </>
          )}
        </Row>
        <br />
        <br />
        <Row>
          <label className="label__subtitle">
            Certificado de Existencia y Representación Legal
          </label>
        </Row>
        <Row className="mb-3 align-items-center">
          <Col xs={"auto"}>
            <span className="label__description">
              ¿Quieres que el sistema compruebe que el Certificado de Existencia
              y Representación Legal haya sido expedido en los últimos 30 días?
            </span>
          </Col>
          <Col xs={"auto"}>
            <span className="label__description">No</span>
          </Col>

          <Col xs={"auto"}>
            <Controller
              control={control}
              name="isCheckValidCERL"
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <IOSSwitch
                  onChange={onChange}
                  sx={{ m: 1 }}
                  checked={value}
                  colorbase={"#00374F"}
                />
              )}
            />
          </Col>
          <Col xs={"auto"}>
            <span className="label__description">Si</span>
          </Col>
        </Row>
        <Row xs={"auto"}>
          <Col>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Actualizar unidad corporativa
            </Button>
          </Col>
        </Row>
        <Row className="mb-5"></Row>
      </Form>

      <ModalInfo
        title={"Información editar unidad corporativa"}
        responseData={responseDataInfo}
        onClose={handleCloseModalInfo}
        open={isOpenModalInfo}
      />
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={[]}
        id={""}
        addComment={false}
      />

      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default EditUnitCorporative;
