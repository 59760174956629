import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordService } from "../../services/general/authServices";
import { CODES } from "../../utils/codes";
import ModalInfo from "./modalInfo";
import { Mixpanel } from "../../utils/mixPanel";
import recaptcha from "../../assets/svg/recaptcha.png";
import recaptchaBadge from "../../assets/svg/recaptchaBadge.png";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState({});
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const captchaToken = await executeRecaptcha("Support");
      const obj = {
        email: data.email,
        token: captchaToken,
      };
      const forgotPasswordServiceResponse = await ForgotPasswordService(obj);
      if (
        forgotPasswordServiceResponse.status === CODES.COD_RESPONSE_HTTP_OK ||
        forgotPasswordServiceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        if (
          forgotPasswordServiceResponse.data.success &&
          forgotPasswordServiceResponse.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          Mixpanel.track("Login Olvidé mi contraseña", {
            email: data?.email,
          });
          navigate(`/forgotPassword/${data.email}`);
        }
      } else {
        setResponseData(forgotPasswordServiceResponse);
        setOpenModalInfo(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      await executeRecaptcha("Support");
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  return (
    <div>
      <Button className="custom-input__button" onClick={handleClickOpen}>
        <u className="custom-input__button__link">Olvidé mi contraseña</u>
      </Button>
      <Dialog
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Form onSubmit={handleSubmit(handleForgotPassword)}>
          <DialogTitleContainer onClose={handleClose}>
            <label className="heading__primary-color">
              Recuperar Contraseña
            </label>
          </DialogTitleContainer>
          <DialogContent dividers>
            <Form.Group className="mb-5">
              <Form.Label className="caption__primary-color">
                Al ingresar su correo se enviará un código con el que podrá
                recuperar su contraseña
              </Form.Label>
              <InputGroup
                bsPrefix={
                  errors.email
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <AlternateEmailIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("email")}
                  bsPrefix="custom-input"
                  placeholder="Correo electrónico"
                />
              </InputGroup>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.email?.message}
              </div>
            </Form.Group>

            <Row className="justify-content-center align-items-center pt-2 mt-4">
              <Col xs={2} className="text-end">
                <a href="https://policies.google.com/privacy">
                  <img src={recaptchaBadge} alt="recaptcha" />
                </a>
              </Col>

              <Col xs={8} className="text-start">
                <a href="https://policies.google.com/terms">
                  <img src={recaptcha} alt="recaptcha badge" />
                </a>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                className="custom-input__button__primary-color"
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    className="spinner__white-background"
                  />
                ) : (
                  "Aceptar"
                )}
              </Button>
            </div>
          </DialogActions>
        </Form>
      </Dialog>
      <ModalInfo
        title="Recuperar contraseña"
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        responseData={responseData}
      />
    </div>
  );
};

export default ModalForgotPassword;
