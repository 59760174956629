import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../utils/mixPanel";

const SectionCardButtonWithNavButton = (props) => {
  const {
    title,
    description,
    icon,
    navigationButton,
    navigationPath,
    mixPanelTrack,
  } = props;
  const navigate = useNavigate();
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  return (
    <div className="section-card">
      <Row style={{ height: "inherit" }}>
        <Col xs={"auto"} className="cards__icon-section">
          {icon}
        </Col>
        <Col className="section-card__title">
          <Row>
            <p className="heading__primary-color">{title}</p>
          </Row>
          <br />
          <Row>
            <p className="caption__justify">{description}</p>
          </Row>
          <br />
          <Row>
            <div
              data-testid="button-nav"
              className="cards__nav-button"
              onClick={() => {
                if (mixPanelTrack) {
                  Mixpanel.track(title, {
                    email: payloadToken?.email,
                    companyName: payloadToken?.companyName,
                  });
                }
                navigate(`/${navigationPath}`);
              }}
            >
              {navigationButton}
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SectionCardButtonWithNavButton;
