import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../components/loadingContent";
import SectionTitle from "../../../components/sectionTitle";
import Breadcrum from "../../breadcrum";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import ModalInfo from "../../../components/Modals/modalInfo";
import ModalDecision from "../../../components/Modals/modalDecision";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTagsContractTypes,
  getTagContractTypesList,
  getTagContractTypesStatus,
  getTagContractTypesStatusModify,
  modifyTagsContractTypes,
} from "./tagContractTypesSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CODES } from "../../../utils/codes";

const ContractTypeUpdateTag = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusTagsContractTypes = useSelector(getTagContractTypesStatus);
  const tagContractTypesList = useSelector(getTagContractTypesList);
  const statusTagsContractTypesModify = useSelector(
    getTagContractTypesStatusModify
  );
  const { id } = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es obligatorio"),
    description: yup.string().required("*Este campo es obligatorio"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tag = tagContractTypesList.find((item) => item._id === id);
        if (tag) {
          setValue("name", tag.name);
          setValue("description", tag.description);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [tagContractTypesList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsContractTypes === "fetch") {
          dispatch(fetchTagsContractTypes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsContractTypes, dispatch]);

  useEffect(() => {
    if (statusTagsContractTypes === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusTagsContractTypes]);

  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleUpdateTag = async (data) => {
    try {
      let obj = {
        id: id,
        updateLabel: {
          name: data.name,
          description: data.description,
        },
      };
      const updateTagService = await dispatch(
        modifyTagsContractTypes(obj)
      ).unwrap();
      if (updateTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(updateTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(updateTagService);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (statusTagsContractTypesModify === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusTagsContractTypesModify]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Editar etiqueta"} />
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleUpdateTag)}>
          <Form.Group as={Col} xs={{ span: 5, offset: 0 }} className="mb-5">
            <Form.Label className="heading">Título de la etiqueta</Form.Label>
            <Form.Control
              {...register("name")}
              bsPrefix={
                errors.name
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors.name?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} className="mb-5" xs={{ span: 8, offset: 0 }}>
            <Form.Label className="heading">Descripción</Form.Label>
            <Form.Control
              {...register("description")}
              as="textarea"
              bsPrefix={
                errors.description
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors.description?.message}
            </div>
          </Form.Group>
          <Row xs={"auto"}>
            <Col>
              <Button
                startIcon={<KeyboardBackspaceOutlinedIcon />}
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Volver
              </Button>
            </Col>
            <Col>
              <Button
                startIcon={<SaveOutlinedIcon />}
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Guardar Cambios
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Estado de los cambios"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Cancelar"}
        message={
          "¿Está seguro que desea cancelar? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />

      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default ContractTypeUpdateTag;
