import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SortIcon from "@mui/icons-material/Sort";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../components/loadingContent";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import {
  getClausesList,
  getClausesStatus,
} from "../../pages/views/userService/modules/library/clauses/clauseSlice";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../categories/clauseCategorySlice";
import FilterCategory from "../filters/FilterCategory";
import FilterTag from "../filters/FilterTag";
import { fetchTags, getStatus, getTagList } from "../manageTags/tagSlice";
import { v4 as uuidv4 } from "uuid";
const ModalImportNumeral = ({
  onClose,
  open,
  handleSubClausesSelected,
  clausesListAssociate = null,
}) => {
  const dispatch = useDispatch();
  const clausesList = useSelector(getClausesList);
  const statusClauses = useSelector(getClausesStatus);
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);
  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const [clauses, setClauses] = useState([]);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [subClauseSelected, setSubClauseSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /* UseEffects Fetch Clauses */

  useEffect(() => {
    if (statusClauses === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusClauses]);

  useEffect(() => {
    let clausesWithNumerals;
    if (clausesListAssociate) {
      clausesWithNumerals = clausesListAssociate.filter(
        (clause) => clause.subclauses.length > 0
      );
    } else {
      clausesWithNumerals = clausesList.filter(
        (clause) => clause.subclauses.length > 0
      );
    }
    setClauses(clausesWithNumerals);
    setFilteredClauses(clausesWithNumerals);
  }, [open, clausesList]);

  /* UseEffect Fetch Tags Clauses*/

  useEffect(() => {
    if (statusTags === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusTags]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("CLAUSES"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  /* UseEffect Fetch Categories Clauses*/

  useEffect(() => {
    if (statusCategories === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusCategories]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.title));
      });

      setFilteredClauses(filterTags);
    } else {
      setFilteredClauses(clauses);
    }
  };
  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });
      setFilteredClauses(filterCategories);
    } else {
      setFilteredClauses(clauses);
    }
  };
  const handleChecked = (e, clause) => {
    if (e.target.checked) {
      setSubClauseSelected((oldArray) => [...oldArray, clause]);
    } else {
      setSubClauseSelected(
        subClauseSelected.filter(
          (item) => item.subclause._id !== clause.subclause._id
        )
      );
    }
  };
  const handleSubClausesFiltered = (itemSelected) => {
    handleSubClausesSelected(itemSelected);
    handleClose();
  };
  const handleClose = () => {
    setSubClauseSelected([]);
    onClose();
  };
  return (
    <>
      <Modal show={open} fullscreen onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color__page-title">Importar numeral</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {isLoading && <LoadingContent />}
            <Row>
              <Col xs={5}>
                <CustomSearchFilter
                  list={clauses}
                  setFilteredData={setFilteredClauses}
                  placeholder="Buscar cláusula"
                />
              </Col>
              <Col xs={{ span: "auto", offset: 2 }}>
                <Button
                  variant="contained"
                  startIcon={<SortIcon />}
                  className="custom-input__button__secondary-color"
                >
                  <p className="button">Ordenar por</p>
                </Button>
              </Col>
              <Col xs={"auto"}>
                <FilterTag
                  tagsList={tagsList}
                  list={clauses}
                  setFilteredInfo={setFilteredClauses}
                  handleTagsSelected={handleTagsSelected}
                  currentTagSelected={tagsSelected}
                />
              </Col>
              <Col xs={"auto"}>
                <FilterCategory
                  categoriesList={categoriesList}
                  list={clauses}
                  setFilteredInfo={setFilteredClauses}
                  handleCategoriesSelected={handleCategoriesSelected}
                />
              </Col>
            </Row>
            <br />
            <Row sm={"auto"} style={{ justifyContent: "right" }}>
              {tagsSelected.map((tag) => {
                return (
                  <Col xs={"auto"} key={tag?._id}>
                    <span className="caption custom-badges__disabled">
                      {tag + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}

              {categoriesSelected.map((category) => {
                return (
                  <Col xs={"auto"} key={category?._id}>
                    <span className="caption custom-badges__secundary">
                      {category + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteCategory(category)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
            <br />
            <Row>
              {filteredClauses.length > 0 &&
                filteredClauses.map((clause) => {
                  return (
                    <div key={clause?._id}>
                      <Accordion className="custom-accordion__import-numeral">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Row style={{ width: "100%" }}>
                            <Col sm={4}>
                              <p className="heading__primary-color">
                                {clause.title}
                              </p>
                            </Col>
                            <Col
                              sm={{ span: 4, offset: 4 }}
                              style={{ textAlign: "right" }}
                            >
                              <Row>
                                <Col sm={"auto"}>
                                  <span className="subheading">
                                    A favor de:{" "}
                                    <span className="caption">
                                      {clause.inFavor}
                                    </span>
                                  </span>
                                </Col>
                                <Col>
                                  <span className="subheading">
                                    Categorías:{" "}
                                    {clause.categories.map((category, i) => {
                                      return (
                                        <span
                                          key={category?._id}
                                          style={{ marginRight: "1%" }}
                                          className="caption custom-badges__secundary"
                                        >
                                          {category.name}
                                        </span>
                                      );
                                    })}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Row>
                            <span className="subheading">
                              Etiquetas:{" "}
                              {clause?.labels?.map((tag, i) => {
                                return (
                                  <span
                                    key={tag?._id}
                                    style={{ marginRight: "1%" }}
                                    className="caption custom-badges__disabled"
                                  >
                                    {tag.name}
                                  </span>
                                );
                              })}
                            </span>
                          </Row>
                          <br />
                          <br />
                          <Row>
                            <span className="caption">
                              {ReactHtmlParser(clause.text)}
                              <br />
                              {clause.subclauses?.map((item, i) => (
                                <Row
                                  key={JSON.stringify(item)}
                                  style={{ alignItems: "center" }}
                                >
                                  <Col xs={"auto"}>
                                    <Checkbox
                                      onClick={(e) => handleChecked(e, item)}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 20,
                                        },
                                        color: "#00374f",
                                        "&.Mui-checked": {
                                          color: "#00374f",
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <Row style={{ alignItems: "center" }}>
                                      <Col xs={"auto"}>
                                        <b>
                                          {i + 1}.
                                          {item.subclause?.title &&
                                            `${item.subclause?.title}: `}
                                        </b>
                                      </Col>
                                      <Col>
                                        <span className="caption">
                                          {ReactHtmlParser(
                                            item.subclause?.text
                                          )}
                                        </span>
                                      </Col>
                                      <Row style={{ marginLeft: "2rem" }}>
                                        {item?.subclause?.childs &&
                                          item.subclause.childs.map(
                                            (child, index) => (
                                              <Row
                                                key={JSON.stringify(child)}
                                                className="justify-text mb-3"
                                                style={{
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Col xs={"auto"}>
                                                  <b>
                                                    {`${i + 1}.${index + 1} `}
                                                    {`${child.title} `}
                                                  </b>
                                                </Col>
                                                <Col>
                                                  <span className="caption">
                                                    {ReactHtmlParser(
                                                      child.text
                                                    )}
                                                  </span>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                      </Row>
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                              <br />
                            </span>
                          </Row>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                <p className="button">Cancelar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => {
                  handleSubClausesFiltered(subClauseSelected);
                }}
              >
                <p className="button">Guardar</p>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalImportNumeral;
