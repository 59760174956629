import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingContent from "../../../../../../components/loadingContent";
import { MDBDataTableV5 } from "mdbreact";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import fileDownload from "js-file-download";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import historyConsultsIcon from "../../../../../../assets/svg/historyConsults.svg";
import ModalUploadMultipleDocs from "../../../../../../components/Modals/ModalUploadMultipleDocs";
import {
  fetchConceptsList,
  getConceptsList,
  getConceptsListStatus,
  resetConceptListStatus,
} from "../../../../../../parts/chat/chatSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteConcept,
  uploadConcept,
  uploadDocumentToSearch,
} from "../../../../../../services/consults/consultsServices";
import { CODES } from "../../../../../../utils/codes";
import { Buffer } from "buffer";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import { onSortHTMLElement } from "../../../../../../utils/findComponentHTML";
import { hover } from "@testing-library/user-event/dist/hover";

export default function HistoryConsults({ action }) {
  /**
   * Tabla
   */
  const COLUMNS_CONSULTS_HISTORY = [
    {
      label: [
        <label aria-hidden="true" key="0">
          Conceptos jurídico
        </label>,
      ],
      field: "icon",
    },

    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseDataInfo, setResponseDataInfo] = useState({});
  const [selectedConcept, setSelectedConcept] = useState({});
  const [documentData, setDocumentData] = useState([]);
  const [data, setData] = useState({
    columns: COLUMNS_CONSULTS_HISTORY,
    rows: [],
  });
  const [filteredData, setFilteredData] = useState({
    columns: COLUMNS_CONSULTS_HISTORY,
    rows: [],
  });

  const conceptsList = useSelector(getConceptsList);
  const conceptsListStatus = useSelector(getConceptsListStatus);

  const dispatch = useDispatch();

  const handleDownloadDocuments = async ({
    filenameInBucket,
    bucketName,
    filename,
  }) => {
    try {
      setIsLoading(true);
      const downloadDocument = await getFileDocument(
        filenameInBucket,
        bucketName,
        false
      );
      console.log({ downloadDocument });
      const name =
        downloadDocument?.data?.responseMessage?.originalName || `${filename}`;
      const bf = Buffer.from(downloadDocument.data.responseMessage.buffer.data);
      fileDownload(bf, name);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadDocuments = async () => {
    try {

      setIsLoading(true);
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const { userId: createdBy = ""} = JSON.parse(localStorage.getItem("payloadToken"));
      const obj = {
        files: documentData,
        company,
        corporateUnit,
        createdBy
      };
      const service = await uploadDocumentToSearch(obj);
      if (service?.status === CODES.COD_RESPONSE_HTTP_CREATED) {
        dispatch(resetConceptListStatus());
      }
      setResponseDataInfo(service);
      setIsOpenModalInfo(true);
      setDocumentData({});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDocument = async () => {
    setOpenModalDelete(false);
    try {
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        conceptDocumentId: selectedConcept._id,
        company,
        corporateUnit,
      };

      setIsLoading(true);
      const service = await deleteConcept(obj);
      if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
        dispatch(resetConceptListStatus());
      }
      setResponseDataInfo(service);
      setIsOpenModalInfo(true);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewDocument = async ({ filenameInBucket, bucketName }) => {
    try {
      setIsLoading(true);
      const downloadDocument = await getFileDocument(
        filenameInBucket,
        bucketName,
        false
      );
      const pdfData = new Uint8Array(
        downloadDocument.data.responseMessage.buffer.data
      );

      const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

      const pdfURL = URL.createObjectURL(pdfBlob);

      window.open(pdfURL, "_blank");

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const buildRows = (data) => {
    if (!data?.length) return [];
    return data.map((item) => ({
      icon: (
        <Row
          style={{
            paddingLeft: "2%",
            paddingTop: "1%",
            cursor: "pointer",
          }}
          onClick={() => {
            handleViewDocument(item);
          }}
        >
          <Col xs={"auto"} className="datatable-CB__tr-name">
            <DescriptionOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </Col>
          <Col className="datatable-CB__tr-name">{item.filename}</Col>
        </Row>
      ),

      actions: (
        <Row xs={"auto"}>
          <Col xs={"auto"}>
            <Tooltip title={<h5>Ver concepto</h5>}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => handleViewDocument(item)}
              >
                <RemoveRedEyeOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </IconButton>
            </Tooltip>
          </Col>
          <Col xs={"auto"}>
            <Tooltip title={<h5>Descargar concepto</h5>}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => handleDownloadDocuments(item)}
              >
                <DownloadOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </IconButton>
            </Tooltip>
          </Col>
          <Col xs={"auto"}>
            <Tooltip title={<h5>Eliminar concepto</h5>}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  setOpenModalDelete(true);
                  setSelectedConcept(item);
                }}
              >
                <DeleteOutlineOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </IconButton>
            </Tooltip>
          </Col>
        </Row>
      ),
    }));
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const rows = buildRows(conceptsList);
      const data = { columns: COLUMNS_CONSULTS_HISTORY, rows };
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [conceptsList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (conceptsListStatus === "fetch") {
          dispatch(fetchConceptsList());
        }
        if (conceptsListStatus === "loading") {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [conceptsList, conceptsListStatus, dispatch]);

  useEffect(() => {
    if (documentData.length) {
      handleUploadDocuments();
    }
  }, [documentData]);

  return (
    <Container className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <br />
      <Row className="justify-content-between caption row">
        <Col xs={12}>
          <p>Carga y visualiza tus conceptos jurídicos</p>
        </Col>
      </Row>
      <br />

      <Row className="justify-content-between">
        <Col xs={4}>
          <CustomSearch
            placeholder="Buscar conceptos jurídicos"
            dataTableInfo={data}
            setFilteredDataTable={setFilteredData}
            characterLimit={20}
          />
        </Col>
        <Col xs={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            onClick={() => {
              setOpenModalUpload(true);
              setDocumentData([]);
            }}
          >
            Cargar conceptos
          </Button>
        </Col>
      </Row>
      <br />
      <Row className="data-table dt-list-items">
        <MDBDataTableV5
          noRecordsFoundLabel={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={historyConsultsIcon} alt="No hay resultados" />
            </div>
          }
          hover={filteredData.length > 0}
          pagingTop
          searchBottom={false}
          entries={6}
          data={filteredData}
          infoLabel={["Mostrando", "a", "de", "consultas"]}
          fullPagination
          onSort={(value) => {
            onSortHTMLElement({
              excludesColumns: ["actions"],
              sort: value,
              filteredDataTable: filteredData,
              setFilteredDataTable: setFilteredData,
            });
          }}
        />
      </Row>
      <ModalUploadMultipleDocs
        title={"Selecciona documentos para subir"}
        message={
          "Los archivos que estas a punto de subir debe estar en formato PDF."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        messageTypeValidation="*Solo se permiten documentos con formato .pdf"
        handleAgree={() => {
          setOpenModalUpload(false);
        }}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        typeDocument={"ONLY_PDF"}
        open={openModalUpload}
        documentData={documentData}
        setDocumentData={setDocumentData}
        allowMultiple={true}
        allowedFileTypes={".pdf"}
      />
      <ModalInfo
        title={"Conceptos Jurídicos"}
        responseData={responseDataInfo}
        onClose={() => setIsOpenModalInfo(false)}
        open={isOpenModalInfo}
        buttonClose={true}
        confirmationText="Aceptar"
        handleCloseDefault={() => setIsOpenModalInfo(false)}
      />
      <ModalDecision
        agreeText={
          <>
            <CheckCircleOutlinedIcon
              fontSize="large"
              style={{ marginRight: "5%" }}
            />
            <Typography variant="inherit">Aceptar</Typography>
          </>
        }
        disagreeText={
          <>
            <CloseOutlinedIcon fontSize="large" style={{ marginRight: "5%" }} />
            <Typography variant="inherit">Cancelar</Typography>
          </>
        }
        handleAgree={handleDeleteDocument}
        handleDisagree={() => setOpenModalDelete(false)}
        message={"¿Está seguro de eliminar este concepto?"}
        onClose={() => setOpenModalDelete(false)}
        open={openModalDelete}
        title={"Eliminar Concepto"}
      />
    </Container>
  );
}
