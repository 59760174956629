import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import {
  findSignatoryById,
  updateSignatory,
  manageSignatoriesPartsRelation,
} from "../../../../../../../services/parts/partsServices.js";
import {
  setSelectedSignatory,
  resetSignatoriesListStatus,
  resetPartListStatus,
  getPartsList,
  getPartsListStatus,
  fetchPartsList,
} from "../../../../../../../parts/parts/partsSlice.js";
import { CODES } from "../../../../../../../utils/codes.js";
import { countries } from "../../../../../../../utils/countries.js";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import {
  Button,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ModalInfo from "../../../../../../../components/Modals/modalInfo.jsx";
import ModalDecision from "../../../../../../../components/Modals/modalDecision.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { signatoryFormSchema } from "./utils/signatoryFormSchema.js";
import { PartsDtoFactory } from "../../../../../../../services/parts/factories/partsDtoFactory.js";
import { MANAGE_SIGNATORIES_PARTS_ACTIONS } from "../../../../../../../services/parts/consts/partsConsts.js";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import ModalAssignParts from "../../../../../../../components/Modals/ModalAssignParts";
import { getModifiedParts } from "./utils/functions.js";

export const UpdateSignatoryForm = (props) => {
  // Parameters
  const { id, currentSignatory, setIsLoading } = props;
  // States
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [updateSignatoryResponse, setUpdateSignatoryResponse] = useState({});
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreateMenu = Boolean(anchorElCreate);
  const [associatedParts, setAssociatedParts] = useState([]);
  const partsListStatus = useSelector(getPartsListStatus);
  const [isOpenModalAssignParts, setIsOpenModalAssignParts] = useState(false);

  // Router
  const navigate = useNavigate();
  const { state } = useLocation();

  // Redux
  const dispatch = useDispatch();
  const partsList = useSelector(getPartsList);

  // Form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signatoryFormSchema),
    defaultValues: {
      parts: associatedParts,
    },
  });

  // Functions
  const { fields, remove: partsRemove } = useFieldArray({
    control,
    name: "parts",
  });

  const removeItem = (indexToRemove) => {
    setAssociatedParts((prevItems) => {
      return prevItems.filter((item, index) => index !== indexToRemove);
    });
    partsRemove(indexToRemove);
    handleFormChange();
  };

  const getName = (item) => {
    return `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
  };

  const getPartName = (partId) => {
    let name = "";

    const part = partsList.find((item) => item?._id === partId?._id);
    name = getName(part);
    if (part?.typePart === "JURIDICA") {
      name = part?.business?.businessName;
    }
    return name;
  };

  // UseEffects
  useEffect(() => {
    const fetchFindSignatoryById = async () => {
      let findSignatoryByIdResponse = await findSignatoryById({
        signatoryId: id,
      });

      if (
        findSignatoryByIdResponse.status === CODES.COD_RESPONSE_HTTP_OK &&
        findSignatoryByIdResponse?.data?.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
      ) {
        dispatch(
          setSelectedSignatory(
            findSignatoryByIdResponse.data?.responseMessage?.data
          )
        );
      }
    };
    const setFormValues = () => {
      setValue("firstName", currentSignatory?.firstName);
      setValue("lastName", currentSignatory?.secondName);
      setValue("firstSurname", currentSignatory?.firstSurname);
      setValue("secondSurname", currentSignatory?.secondSurname);
      setValue("documentType", currentSignatory?.documentType);
      setValue("documentNumber", currentSignatory?.documentNumber);
      setValue("otherTypeDocument", currentSignatory?.otherTypeDocument);
      setValue("nameTradeNatural", currentSignatory?.comercialName);
      setValue("email", currentSignatory?.email);
      setValue("emailConfirmation", currentSignatory?.email);
      // mobileNumberData
      setValue("countryCode", currentSignatory?.mobileNumberData?.countryCode);
      setValue("numberPhone", currentSignatory?.mobileNumberData?.phone);
      // telephoneNumberData
      setValue(
        "countryCodeTelephone",
        currentSignatory?.telephoneNumberData?.countryCode
      );
      setValue("cityCode", currentSignatory?.telephoneNumberData?.cityCode);
      setValue("extension", currentSignatory?.telephoneNumberData?.extension);
      setValue(
        "telephoneNumber",
        currentSignatory?.telephoneNumberData?.telephone
      );
      // residence
      setValue("country", currentSignatory?.residence?.country);
      setValue("province", currentSignatory?.residence?.province);
      setValue("city", currentSignatory?.residence?.city);
      setValue("address", currentSignatory?.residence?.physicalAddress);
      // relatedParts
      currentSignatory?.relatedParties?.forEach((part, index) => {
        setValue(`parts.${index}.position`, part?.position);
      });
    };
    if (currentSignatory._id) {
      setFormValues();
    } else {
      setIsLoading(true);
      fetchFindSignatoryById();
      setIsLoading(false);
    }
  }, [currentSignatory]);

  useEffect(() => {
    if (currentSignatory?._id) {
      setAssociatedParts(currentSignatory?.relatedParties);
    }
  }, [currentSignatory, state]);

  useEffect(() => {
    setValue("parts", associatedParts);
  }, [associatedParts, setValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (partsListStatus === "fetch") {
          dispatch(fetchPartsList());
        }
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [partsListStatus, dispatch]);

  // Handles
  const handleUpdateSignatory = async (data) => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const modifiedParts = getModifiedParts(currentSignatory, associatedParts);
      const mobileNumberData = PartsDtoFactory.generateCreateContactDto(
        data?.countryCode || "",
        undefined,
        data?.numberPhone || "",
        "",
        undefined
      );
      const telephoneNumberData = PartsDtoFactory.generateCreateContactDto(
        data?.countryCode || "",
        data?.cityCode || "",
        "",
        data?.telephoneNumber || "",
        data?.extension || ""
      );
      const residence = PartsDtoFactory.generateCreateResidenceDto(
        data?.country || "",
        data?.province || "",
        data?.city || "",
        data?.address || ""
      );
      const signatory = PartsDtoFactory.generateCreateSignatoryDto(
        data?.firstName || "",
        data?.firstSurname || "",
        data?.documentType || "",
        data?.documentNumber || "",
        mobileNumberData,
        telephoneNumberData,
        residence,
        company,
        data?.lastName || "",
        data?.secondSurname || "",
        data?.otherTypeDocument || "",
        data?.email,
        undefined
      );
      const signatoryToUpdate = PartsDtoFactory.generateUpdateSignatoryDto(
        signatory,
        currentSignatory._id,
        undefined,
        userId
      );
      // Construction for update signatories/parts relation
      const signatoriesForPart = [];
      const partsForSignatory = [];
      if (modifiedParts?.partsToAdd?.length) {
        signatoriesForPart.push(
          PartsDtoFactory.generateManageSignatoryToPartsDto(
            modifiedParts.partsToAdd,
            MANAGE_SIGNATORIES_PARTS_ACTIONS.ADD
          )
        );
        partsForSignatory.push(
          PartsDtoFactory.generateManagePartToSignatoriesDto(
            modifiedParts.partsToAdd,
            MANAGE_SIGNATORIES_PARTS_ACTIONS.ADD
          )
        );
      }
      if (modifiedParts?.partsToDelete?.length) {
        signatoriesForPart.push(
          PartsDtoFactory.generateManageSignatoryToPartsDto(
            modifiedParts.partsToDelete,
            MANAGE_SIGNATORIES_PARTS_ACTIONS.DELETE
          )
        );
        partsForSignatory.push(
          PartsDtoFactory.generateManagePartToSignatoriesDto(
            modifiedParts.partsToDelete,
            MANAGE_SIGNATORIES_PARTS_ACTIONS.DELETE
          )
        );
      }
      if (modifiedParts?.partsToUpdate?.length) {
        signatoriesForPart.push(
          PartsDtoFactory.generateManageSignatoryToPartsDto(
            modifiedParts.partsToUpdate,
            MANAGE_SIGNATORIES_PARTS_ACTIONS.UPDATE
          )
        );
        partsForSignatory.push(
          PartsDtoFactory.generateManagePartToSignatoriesDto(
            modifiedParts.partsToUpdate,
            MANAGE_SIGNATORIES_PARTS_ACTIONS.UPDATE
          )
        );
      }

      const response = await updateSignatory(signatoryToUpdate);

      await manageSignatoriesPartsRelation(
        PartsDtoFactory.generateManageSignatoriesPartsRelationDto(
          signatoriesForPart,
          partsForSignatory
        )
      );
      setUpdateSignatoryResponse(response);
      handleOpenModalInfo();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFormChange = () => {
    if (!saveButtonEnabled) setSaveButtonEnabled(true);
  };
  const handlePositionChange = (index, value) => {
    const updatedItems = associatedParts.map((item, i) => {
      if (index !== i) {
        return item;
      }
      return { ...item, position: value };
    });
    setAssociatedParts(updatedItems);
  };
  // Decision modal handles
  const handleOpenModalDecision = () => {
    setIsOpenModalDecision(true);
  };
  const handleCloseModalDecision = () => {
    setIsOpenModalDecision(false);
  };
  const handleDisagreeModalDecision = () => {
    handleCloseModalDecision();
  };
  const handleAgreeModalDecision = () => {
    reset();
    navigate(-1);
  };

  // Info modal handles
  const handleOpenModalInfo = () => {
    setIsOpenModalInfo(true);
  };
  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
  };
  const handleAcceptModalInfo = () => {
    if (
      updateSignatoryResponse.status === CODES.COD_RESPONSE_HTTP_OK &&
      updateSignatoryResponse.data.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      navigate("/admin/library", { state: { signatory: true } });
      dispatch(resetSignatoriesListStatus());
      dispatch(resetPartListStatus());
    }
    handleCloseModalInfo();
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(handleUpdateSignatory)}
        onChange={handleFormChange}
      >
        <Row>
          <h1 className="heading__primary-color mt-3">
            Datos de identificación
          </h1>
        </Row>
        <Row>
          <Col xs={"auto"} md={"auto"} lg={3}>
            <Form.Group>
              <Form.Label className="form__label">
                Primer nombre<span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Control
                {...register("firstName")}
                placeholder={"Segundo nombre"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.firstName
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.firstName?.message}
              </div>
            </Form.Group>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={3}>
            <Form.Group>
              <Form.Label className="form__label">Segundo nombre</Form.Label>
              <Form.Control
                {...register("lastName")}
                placeholder={"Segundo nombre"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.lastName
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.lastName?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={"auto"} md={"auto"} lg={3}>
            <Form.Group>
              <Form.Label className="form__label">
                Primer apellido<span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Control
                {...register("firstSurname")}
                placeholder={"Primer apellido"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.firstSurname
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.firstSurname?.message}
              </div>
            </Form.Group>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={3}>
            <Form.Group>
              <Form.Label className="form__label">Segundo apellido</Form.Label>
              <Form.Control
                {...register("secondSurname")}
                placeholder={"Segundo apellido"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.secondSurname
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.secondSurname?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={"auto"} md={"auto"} lg={3}>
            <Form.Group>
              <Form.Label className="form__label">
                Tipo de documento
                <span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Select
                {...register("documentType")}
                className="label"
                defaultValue={""}
                bsPrefix={
                  errors?.documentType
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              >
                <option value="" disabled defaultValue className="label">
                  Tipo de documento
                </option>
                <option value="CEDULA DE CIUDADANIA" className="label">
                  Cédula de ciudadanía - COL
                </option>
                <option value="TARJETA DE IDENTIDAD" className="label">
                  Tarjeta de identidad - COL
                </option>
                <option value="CEDULA DE EXTRANJERIA" className="label">
                  Cédula de extranjería - COL
                </option>
                <option value="INE" className="label">
                  INE - MEX
                </option>
                <option value="CURP" className="label">
                  CURP - MEX
                </option>
                <option value="PASAPORTE" className="label">
                  Pasaporte
                </option>
                <option value="OTRO" className="label">
                  Otros
                </option>
              </Form.Select>
              <div className="caption custom-input__error">
                {errors?.documentType?.message}
              </div>
            </Form.Group>
          </Col>
          {watch("documentType") === "OTRO" && (
            <Col xs={"auto"} md={"auto"} lg={3}>
              <Form.Group>
                <Form.Label className="form__label">
                  Otro tipo de documento
                </Form.Label>
                <Form.Control
                  {...register("otherTypeDocument")}
                  placeholder={"Ingrese tipo de documento"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.otherTypeDocument
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.otherTypeDocument?.message}
                </div>
              </Form.Group>
            </Col>
          )}
          <Col xs={"auto"} md={"auto"} lg={3}>
            <Form.Group>
              <Form.Label className="form__label">
                Número de documento
                <span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Control
                {...register("documentNumber")}
                placeholder={"Número de documento"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.documentNumber
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.documentNumber?.message}
              </div>
            </Form.Group>
          </Col>

          {watch("documentType") === "TARJETA DE IDENTIDAD" && (
            <Col xs={"auto"} md={"auto"} lg={5}>
              <Row className="mt-4">
                <Form.Label className="form__label text-white">
                  Tipo de documento
                </Form.Label>
              </Row>
              <Row className="info__container">
                <Col xs={1}>
                  <InfoOutlinedIcon fontSize="large" />
                </Col>
                <Col xs={11}>
                  Recuerda que los contratos, así como otros documentos legales
                  deben ser firmados por personas que sean plenamente capaces
                  legalmente.
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Row>
          <h1 className="heading__primary-color mt-3">Datos de contacto</h1>
        </Row>
        <Row>
          <Form.Group as={Col} xs={"auto"} lg={3}>
            <Form.Label className="form__label">Correo electrónico</Form.Label>
            <InputGroup
              bsPrefix={
                errors.email
                  ? "input-group-container__error"
                  : "input-group-container"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <AlternateEmailIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                {...register("email")}
                bsPrefix="custom-input"
                placeholder="Correo electrónico"
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors.email?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} xs={"auto"} lg={3}>
            <Form.Label className="form__label">
              Confirma correo electrónico
            </Form.Label>
            <InputGroup
              bsPrefix={
                errors.emailConfirmation
                  ? "input-group-container__error"
                  : "input-group-container"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <AlternateEmailIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                {...register("emailConfirmation")}
                bsPrefix="custom-input"
                placeholder="Confirma correo electrónico"
                onPaste={(e) => e.preventDefault()}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors.emailConfirmation?.message}
            </div>
          </Form.Group>
        </Row>
        <Row className="mt-2">
          <Form.Label className="form__label">Número de celular</Form.Label>
          <Form.Group as={Col} md={4}>
            <Select
              defaultValue={
                currentSignatory?.mobileNumberData?.countryCode || "57"
              }
              className={
                errors.countryCode
                  ? "input-group-container__no-icon__country-list__error label"
                  : "input-group-container__no-icon__country-list label"
              }
              {...register("countryCode")}
              sx={{
                height: "auto !important",
                "& .MuiSelect-outlined": { padding: "0 !important" },
              }}
              onChange={handleFormChange}
            >
              {countries.map((c) => (
                <MenuItem
                  key={c.code}
                  value={c.phone}
                  className="input-group-container__no-icon__country-list label"
                  sx={{
                    "& .Mui-selected": { height: "fit-content !important" },
                  }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                    alt={`Flag of ${c.label}`}
                  />
                  {` ${c.label} (${c.code}) +${c.phone}`}
                </MenuItem>
              ))}
            </Select>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.numberPhone
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <PhoneAndroidOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("numberPhone")}
                inputMode="email"
                placeholder={"Número de celular"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.numberPhone?.message}
            </div>
          </Form.Group>
        </Row>

        <Row className="mt-2">
          <Form.Label className="form__label">Teléfono fijo</Form.Label>
          <Form.Group as={Col} md={2}>
            <Select
              defaultValue={
                currentSignatory?.telephoneNumberData?.countryCode || "57"
              }
              className={
                errors.countryCodeTelephone
                  ? "input-group-container__no-icon__country-list__error label"
                  : "input-group-container__no-icon__country-list label"
              }
              {...register("countryCodeTelephone")}
              sx={{
                height: "auto !important",
                "& .MuiSelect-outlined": { padding: "0 !important" },
              }}
              onChange={handleFormChange}
            >
              {countries.map((c) => (
                <MenuItem
                  key={c.code}
                  value={c.phone}
                  className="input-group-container__no-icon__country-list label"
                  style={{ border: "none !important" }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                    alt={`Flag of ${c.label}`}
                  />
                  {` ${c.label} (${c.code}) +${c.phone}`}
                </MenuItem>
              ))}
            </Select>
          </Form.Group>
          <Form.Group as={Col} md={1}>
            <Form.Control
              placeholder={"Ind. país"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={"input-group-container__no-icon label"}
              disabled
              value={watch("countryCodeTelephone")}
            />
          </Form.Group>
          <Form.Group as={Col} md={1}>
            <Form.Control
              {...register("cityCode")}
              placeholder={"Ind. ciudad"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.cityCode
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.cityCode?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} md={1}>
            <Form.Control
              {...register("extension")}
              placeholder={"Extensión"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.extension
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.extension?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Group>
              <InputGroup
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.telephoneNumber
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <LocalPhoneOutlinedIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  bsPrefix="custom-input"
                  {...register("telephoneNumber")}
                  inputMode="email"
                  placeholder={"Teléfono fijo"}
                />
              </InputGroup>
              <div className="caption custom-input__error">
                {errors?.telephoneNumber?.message}
              </div>
            </Form.Group>
          </Form.Group>
        </Row>
        <br />
        <Row>
          <Col className="home-init-cards-row__title-row">
            <span>Domicilio</span>
            {/* <PopoverInfo section={{ description: "Es la persona que directamente va a contratar, a tener derechos y/o obligaciones." }} /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={"auto"} md={"auto"} lg={4}>
            <Form.Group>
              <Form.Label className="form__label">País</Form.Label>
              <Form.Control
                {...register("country")}
                placeholder={"País"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.country
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.country?.message}
              </div>
            </Form.Group>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={4}>
            <Form.Group>
              <Form.Label className="form__label">
                Provincia o región
              </Form.Label>
              <Form.Control
                {...register("province")}
                placeholder={"Provincia o región"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.province
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.province?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "5rem" }}>
          <Col xs={"auto"} md={"auto"} lg={4}>
            <Form.Group>
              <Form.Label className="form__label">Ciudad</Form.Label>
              <Form.Control
                {...register("city")}
                placeholder={"Ciudad"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.city
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.city?.message}
              </div>
            </Form.Group>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={4}>
            <Form.Group>
              <Form.Label className="form__label">Dirección física</Form.Label>
              <Form.Control
                {...register("address")}
                placeholder={"Dirección física"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.address
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.address?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="home-init-cards-row__title-row">
            <span>Parte relacionada</span>
          </Col>
        </Row>

        {associatedParts?.length > 0 && (
          <Row className="mt-3">
            <Col xs={"auto"}>
              <p className="caption">Partes:</p>
            </Col>

            <Col xs={"auto"}>
              <Row>
                {fields.map((item, index) => {
                  return (
                    <Col
                      xs={"auto"}
                      style={{ display: "flex", alignItems: "center" }}
                      key={item?._id}
                    >
                      <Row>
                        <Col xs={12}>
                          <span className="caption custom-badges__disabled">
                            {`${getPartName(item?.part)} `}
                            <ClearIcon
                              className="custom-badges__delete-button"
                              onClick={() => {
                                removeItem(index);
                              }}
                            />
                          </span>
                        </Col>
                        <Col xs={12}>
                          <Form.Control
                            {...register(`parts.${index}.position`)}
                            onChange={(e) => {
                              handlePositionChange(index, e.target.value);
                              //handleFormChange();
                            }}
                            placeholder={`Cargo`}
                            bsPrefix={
                              errors?.parts?.[index]
                                ? "input-group-container__no-icon-error label"
                                : "input-group-container__no-icon label"
                            }
                          />
                          <div className="caption custom-input__error">
                            {errors?.parts?.[index]?.position?.message}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}
        <Row className="mt-3" style={{ paddingBottom: "8rem" }}>
          <Col xs={2}>
            <Button
              startIcon={<SupervisorAccountOutlinedIcon fontSize="large" />}
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => setIsOpenModalAssignParts(true)}
            >
              Agregar parte
            </Button>
          </Col>

          <Col xs={2}>
            <Button
              startIcon={<PersonAddOutlinedIcon fontSize="large" />}
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={(event) => setAnchorElCreate(event.currentTarget)}
            >
              Crear Parte
            </Button>
            <Menu
              anchorEl={anchorElCreate}
              open={openCreateMenu}
              onClose={() => setAnchorElCreate(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => navigate("/admin/library/create")}>
                <ListItemIcon>
                  <AddCircleRoundedIcon
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontFamily: "Roboto",
                    fontSize: "1.4rem",
                    fontWeight: "regular",
                    lineHeight: "2rem",
                    color: "#676879",
                  }}
                  primary={"Desde cero"}
                />
              </MenuItem>

              <MenuItem
                onClick={() => navigate("/admin/library/create/certificate")}
              >
                <ListItemIcon>
                  <NoteAddOutlinedIcon
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontFamily: "Roboto",
                    fontSize: "1.4rem",
                    fontWeight: "regular",
                    lineHeight: "2rem",
                    color: "#676879",
                  }}
                  primary={"Desde Certificado"}
                />
              </MenuItem>
            </Menu>
          </Col>
        </Row>
        <Row className="sidebar__bottom__container">
          <Col xs={2}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              startIcon={<CloseIcon fontSize="large" />}
              onClick={handleOpenModalDecision}
            >
              Descartar
            </Button>
          </Col>
          <Col xs={2}>
            <Button
              variant="contained"
              type="submit"
              className="custom-input__button__primary-color"
              startIcon={<SaveOutlinedIcon fontSize="large" />}
              disabled={!saveButtonEnabled}
            >
              Guardar
            </Button>
          </Col>
        </Row>
        <br />
      </Form>
      <ModalDecision
        title={"Descartar"}
        message={"¿Seguro deseas cancelar? Los cambios realizados se perderán."}
        open={isOpenModalDecision}
        onClose={handleCloseModalDecision}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDecision}
        handleDisagree={handleDisagreeModalDecision}
        type={"warning"}
      />
      <ModalInfo
        title={
          updateSignatoryResponse.status === CODES.COD_RESPONSE_HTTP_OK &&
          updateSignatoryResponse.data?.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
            ? "Actualización exitosa"
            : "Error actualizando al firmante"
        }
        responseData={updateSignatoryResponse}
        onClose={handleAcceptModalInfo}
        open={isOpenModalInfo}
        buttonClose={true}
        confirmationText="Aceptar"
        handleCloseDefault={handleCloseModalInfo}
      />
      <ModalAssignParts
        title={"Asignar Parte"}
        open={isOpenModalAssignParts}
        onClose={() => setIsOpenModalAssignParts(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setIsOpenModalAssignParts(false);
          associatedParts.forEach((item, index) => {
            if (item?.position.length === 0)
              setValue(`parts.${index}.position`, "");
            else setValue(`parts.${index}.position`, item?.position);
          });
        }}
        handleDisagree={() => setIsOpenModalAssignParts(false)}
        partsList={partsList}
        setAssociatedParts={setAssociatedParts}
        associatedParts={associatedParts}
      />
    </>
  );
};
