import React, { useState } from "react";
import { Container, Form, InputGroup, Row, Col } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";

const CustomSearchClauses = ({
  placeholder = "Buscar",
  handleCustomeSearch,
}) => {
  //state
  const [textSearch, setTextSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const handleSearch = (text) => {
    if (text) {
      setIsSearch(true);
      handleCustomeSearch(text)
    }
  };

  const handleSearchReset = () => {
    setIsSearch(false);
    handleCustomeSearch("");
  };

  return (
    <Container fluid>
      <InputGroup bsPrefix="input-group-search-clauses">
        <Row>
          <Col
            xs={10}
            style={{
              display: "flex",
              alignContent: "center",
              padding: "0 0 0 3%",
            }}
          >
            <Form.Control
              bsPrefix="custom-input"
              placeholder={placeholder}
              onChange={(e) => {
                setTextSearch(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value === "" && isSearch) {
                  handleSearchReset();
                }
              }}
              maxLength={20}
            />
          </Col>
          <Col xs={2}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => {
                handleSearch(textSearch);
              }}
            >
              <SearchIcon fontSize="large" />
            </Button>
          </Col>
        </Row>
      </InputGroup>
    </Container>
  );
};

export default CustomSearchClauses;
