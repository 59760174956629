import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";
import { saveAs } from "file-saver";


export const convertToPdfService = async (file, type = "blob") => {
  const formData = new FormData();
  const responseAuth = await axios({
    method: "GET",
    url: `documents/convertToPdf`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    }
  });

  let fileData = file;

  if (type === "blob") {
    fileData = new File([file], `${file.filename || "Sin nombre.docx"}`, { type: file.type });
  }

  formData.append("file", fileData);
  formData.append("task", responseAuth.data.responseMessage.data.task);
  
  const responseUpload = await axios({
    method: "POST",
    url: `https://${responseAuth.data.responseMessage.data.server}/v1/upload`,
    validateStatus: null,
    headers: {
      Authorization: responseAuth.data.responseMessage.data.token,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  const files = [{ server_filename: responseUpload.data.server_filename, filename: "Sin nombre.docx"}]
  
  await axios({
    method: "POST",
    url: `https://${responseAuth.data.responseMessage.data.server}/v1/process`,
    validateStatus: null,
    headers: {
      Authorization: responseAuth.data.responseMessage.data.token,
    },
    data: {
      task: responseAuth.data.responseMessage.data.task,
      tool: responseAuth.data.responseMessage.data.tool,
      files,
    },
  });
  
  const responseDownload = await axios({
    method: "GET",
    url: `https://${responseAuth.data.responseMessage.data.server}/v1/download/${responseAuth.data.responseMessage.data.task}`,
    headers: {
      Authorization: responseAuth.data.responseMessage.data.token,
    },
    responseType: 'arraybuffer'
  });

  return {
    ...responseDownload,
    data: {
      responseMessage: {
        data: {
          buffer: {
            data: responseDownload.data
          }
        }
      }
    }
  };

};

export const sendEmailDocument = (file, filename, email) => {
  const formData = new FormData();
  const company = localStorage.getItem("company");
  formData.append("file", file);
  formData.append("company", company);
  formData.append("filename", filename);
  formData.append("email", email);
  return axios({
    method: "POST",
    url: `documents/sendEmailDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};
