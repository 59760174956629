import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTagsByCompanyAndResourceType,
  getTagById,
  createTag,
  updateTag,
  deleteTag,
} from "../../services/tags/tagsServices";
import { CODES } from "../../utils/codes";

export const tagSlice = createSlice({
  name: "tags",
  initialState: {
    tagList: [],
    status: "fetch",
    statusCreate: "",
    statusUpdate: "",
    statusDelete: "",
    error: null,
    errorCreate: null,
    errorUpdate: null,
    errorDelete: null,
  },
  reducers: {
    setStatusFetchTag(state, action) {
      const status = action.payload.status;
      state.status = status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTags.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.tagList = action.payload.data.responseMessage;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createTags.pending, (state, action) => {
        state.statusCreate = "loading";
      })
      .addCase(createTags.fulfilled, (state, action) => {
        state.statusCreate = "succeeded";
        // Add any fetched posts to the array
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(createTags.rejected, (state, action) => {
        state.statusCreate = "failed";
        state.errorCreate = action.error.message;
      })
      .addCase(updateTagById.pending, (state, action) => {
        state.statusUpdate = "loading";
      })
      .addCase(updateTagById.fulfilled, (state, action) => {
        state.statusUpdate = "succeeded";
        // Add any fetched posts to the array
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(updateTagById.rejected, (state, action) => {
        state.statusUpdate = "failed";
        state.errorUpdate = action.error.message;
      })
      .addCase(deleteTagById.pending, (state, action) => {
        state.statusDelete = "loading";
      })
      .addCase(deleteTagById.fulfilled, (state, action) => {
        state.statusDelete = "succeeded";
        // Add any fetched posts to the array
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(deleteTagById.rejected, (state, action) => {
        state.statusDelete = "failed";
        state.errorDelete = action.error.message;
      });
  },
});

export const getTagList = (state) => state.tags.tagList;

export const getStatus = (state) => state.tags.status;

export const getStatusCreate = (state) => state.tags.statusCreate;

export const getStatusUpdate = (state) => state.tags.statusUpdate;

export const getStatusDelete = (state) => state.tags.statusDelete;

export const { setStatusFetchTag } = tagSlice.actions;

export const fetchTags = createAsyncThunk(
  "tags/fetchTags",
  async (resource) => {
    const companyId = localStorage.getItem("company");
    let obj = {
      companyId: companyId,
      resourceType: resource,
    };
    const response = await getTagsByCompanyAndResourceType(obj);
    return {
      data: response.data,
      status: response.status,
    };
  }
);

export const fetchTagsNoUpdate = createAsyncThunk(
  "tags/fetchTags",
  async (resource) => {
    const companyId = localStorage.getItem("company");
    let obj = {
      companyId: companyId,
      resourceType: resource,
    };
    const response = await getTagsByCompanyAndResourceType(obj);
    return {
      data: response.data,
      status: response.status,
    };
  }
);

export const fetchTagById = createAsyncThunk(
  "tags/fetchTagById",
  async (id) => {
    const response = await getTagById(id);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        data: response.data,
        status: response.status,
      };
    }
  }
);

export const createTags = createAsyncThunk("tags/createTag", async (data) => {
  const companyId = localStorage.getItem("company");
  let obj = {
    name: data.name,
    description: data.description,
    company: companyId,
    resourceType: data.resourceType,
  };
  const response = await createTag(obj);
  if (
    response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
    response.data.success
  ) {
    const companyId = localStorage.getItem("company");
    let obj = {
      companyId: companyId,
      resourceType: data.resourceType || "CLAUSES",
    };
    const responseFetch = await getTagsByCompanyAndResourceType(obj);
    return {
      dataToAdd: responseFetch.data.responseMessage,
      data: response.data,
      status: response.status,
    };
  }
  return {
    data: response.data,
    status: response.status,
  };
});

export const updateTagById = createAsyncThunk(
  "tags/updateTagById",
  async (data) => {
    let obj = {
      id: data.id,
      updateLabel: data.updateLabel,
      resourceType: data.updateLabel.resourceType,
    };
    const response = await updateTag(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        resourceType: data?.updateLabel?.resourceType || "CLAUSES",
      };
      const responseFetch = await getTagsByCompanyAndResourceType(obj);
      return {
        dataToAdd: responseFetch.data.responseMessage,
        data: response.data,
        status: response.status,
      };
    }
    return {
      data: response.data,
      status: response.status,
    };
  }
);

export const deleteTagById = createAsyncThunk(
  "tags/deleteTag",
  async (data) => {
    const responseDelete = await deleteTag(data);
    if (
      responseDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
      responseDelete.data.success
    ) {
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        resourceType: data.resourceType || "CLAUSES",
      };
      const responseFetch = await getTagsByCompanyAndResourceType(obj);
      return {
        dataToAdd: responseFetch.data.responseMessage,
        data: responseDelete.data,
        status: responseDelete.status,
      };
    }
    return {
      data: responseDelete.data,
      status: responseDelete.status,
    };
  }
);

export default tagSlice.reducer;
