import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { Col, Container, Form, Row } from "react-bootstrap";
import {
  getAllBlankSpaces,
  getFillBlankSpaces,
  getFillBlankSpacesOtherDocs,
} from "../../utils/countBlankSpaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { flashItemSelected } from "../../utils/ScrollDocItems";
import { getDocumentInfo } from "../../parts/document/otherDocumentSlice";
import { useSelector } from "react-redux";
export const FillBlankSpaceOtherDocument = ({
  indexClause,
  setCurrentDocInfo,
  currentDocInfo,
}) => {
  //States
  const [isCompleteBlankSpaces, setIsCompleteBlankspaces] = useState(false);
  //Selectors

  useEffect(() => {
    if (currentDocInfo) {
      const fillBlankSpaces = getFillBlankSpacesOtherDocs(currentDocInfo);
      const allBlankSpaces = currentDocInfo?.blankSpaces?.length;

      if (fillBlankSpaces > 0) {
        if (fillBlankSpaces / allBlankSpaces === 1 || allBlankSpaces === 0) {
          setIsCompleteBlankspaces(true);
        } else {
          setIsCompleteBlankspaces(false);
        }
      } else {
        setIsCompleteBlankspaces(false);
      }
    }
  }, [currentDocInfo]);

  const handleFillBlankspaces = (textHtml, name, value, originalValue) => {
    if (!textHtml || !name) return "";

    //Convertimos el string html a un Object Document
    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, "text/html");

    //Validamos que exista la data para cambiar su valor
    if (htmlElement?.querySelector(`[data-blank-space="${name}"]`)) {
      htmlElement.querySelector(`[data-blank-space="${name}"]`).innerText =
        value ? `¬${value}¬` : `¬${originalValue}¬`;
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument.replace(
      /<\/?(html|head|body)>/gi,
      ""
    );

    return cleanedString;
  };

  const handleBlankspacesParagraphs = (
    name,
    value,

    originalValue
  ) => {
    const newText = handleFillBlankspaces(
      currentDocInfo?.text,
      name,
      value,
      originalValue
    );

    setCurrentDocInfo((prev) => {
      return {
        ...prev,
        text: newText,
        blankSpaces: prev.blankSpaces?.map((blankspace, index) => {
          if (blankspace.name === name) {
            return { ...blankspace, value: value };
          } else return blankspace;
        }),
      };
    });
  };

  return (
    <Accordion
      className="blankspaces__box"
      defaultExpanded
      id={`c.${indexClause + 1}.0.0`}
    >
      <AccordionSummary>
        <Container
          fluid
          className="container-blankspaces__box"
          style={{ margin: "0 !important" }}
        >
          <Row>
            <Col xs={10} className="heading">
              Espacios en blanco
            </Col>
            <Col xs={1}>
              {isCompleteBlankSpaces ? (
                <CheckCircleOutlineIcon fontSize="large" />
              ) : (
                <InfoOutlinedIcon
                  className="heading__error-color"
                  fontSize="large"
                />
              )}
            </Col>
            <Col xs={1}>
              <KeyboardArrowDownIcon fontSize="large" />
            </Col>
          </Row>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        {currentDocInfo.blankSpaces && (
          <div>
            <Row>
              <Col md={12}>
                {currentDocInfo.blankSpaces?.map((blankspace, index) => (
                  <div key={`otherDoc_${index}`}>
                    <Row>
                      <Col className="mb-3"> {`Espacio # ${index + 1}`}</Col>
                    </Row>
                    <Row></Row>
                    <Form.Group
                      className="mb-3"
                      key={`blankspace-paragraph-${blankspace.index}`}
                    >
                      <Form.Control
                        placeholder={`Escribir aquí`}
                        defaultValue={blankspace.value}
                        bsPrefix={"input-group-container__no-icon label"}
                        onChange={(e) => {
                          let originalValue = `ESPACIO #${
                            blankspace?.id || ""
                          }`;
                          handleBlankspacesParagraphs(
                            blankspace.name,
                            e.target.value,

                            originalValue
                          );
                        }}
                        onFocus={() => {
                          const nameBlankspace = `[data-blank-space="${blankspace.name}"]`;
                          flashItemSelected(
                            nameBlankspace,
                            "query",
                            `content-otherDocxs`
                          );
                        }}
                      />
                    </Form.Group>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
