export const Modules = (permission) => {
  switch (permission.module) {
    case "Clauses":
      return {
        _id: permission._id,
        title: "Cláusulas",
        actions: [
          {
            title: "create",
            name: "Crear",
            isCheck: permission.actions.create,
          },
          {
            title: "update",
            name: "Editar",
            isCheck: permission.actions.update,
          },
          {
            title: "delete",
            name: "Eliminar",
            isCheck: permission.actions.delete,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "ContractTypes":
      return {
        _id: permission._id,
        title: "Minutas",
        actions: [
          {
            title: "create",
            name: "Crear",
            isCheck: permission.actions.create,
          },
          {
            title: "update",
            name: "Editar",
            isCheck: permission.actions.update,
          },
          {
            title: "delete",
            name: "Eliminar",
            isCheck: permission.actions.delete,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "CreateContracts":
      return {
        _id: permission._id,
        title: "Crear",
        actions: [
          {
            title: "create",
            name: "Crear contrato",
            isCheck: permission.actions.create,
          },
          {
            title: "createByClause",
            name: "Crear contrato desde cláusulas",
            isCheck: permission.actions.createByClause,
          },
          {
            title: "createByContractType",
            name: "Crear contrato desde minutas",
            isCheck: permission.actions.createByContractType,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "BusinessFolders":
      return {
        _id: permission._id,
        title: "Carpetas",
        actions: [
          {
            title: "create",
            name: "Crear",
            isCheck: permission.actions.create,
          },
          {
            title: "update",
            name: "Editar",
            isCheck: permission.actions.update,
          },
          {
            title: "delete",
            name: "Eliminar",
            isCheck: permission.actions.delete,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "Requests":
      return {
        _id: permission._id,
        title: "Solicitudes - Permisos frente a las solicitudes",
        actions: [
          {
            title: "receiveRequests",
            name: "Recibir asignación de solicitudes",
            isCheck: permission.actions.receiveRequests,
          },
          {
            title: "readAllRequests",
            name: "Ver todas las solicitudes",
            isCheck: permission.actions.readAllRequests,
          },
          {
            title: "readAsignedRequests",
            name: "Ver solo solicitudes asignadas",
            isCheck: permission.actions.readAsignedRequests,
          },
          {
            title: "answerRequests",
            name: "Responder solicitudes",
            isCheck: permission.actions.answerRequests,
          },
          {
            title: "solveRequests",
            name: "Solucionar solicitudes",
            isCheck: permission.actions.solveRequests,
          },
          {
            title: "returnRequests",
            name: "Devolver solicitudes",
            isCheck: permission.actions.returnRequests,
          },
          {
            title: "aproveRequests",
            name: "Aprobar solicitudes",
            isCheck: permission.actions.aproveRequests,
          },
          {
            title: "ReasignRequests",
            name: "Reasignar solicitudes",
            isCheck: permission.actions.ReasignRequests,
          },
          {
            title: "loadDocuments",
            name: "Cargar documentos",
            isCheck: permission.actions.loadDocuments,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "Alerts":
      return {
        _id: permission._id,
        title: "Alertas",
        actions: [
          {
            title: "create",
            name: "Crear",
            isCheck: permission.actions.create,
          },
          {
            title: "update",
            name: "Editar",
            isCheck: permission.actions.update,
          },
          {
            title: "delete",
            name: "Eliminar",
            isCheck: permission.actions.delete,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "CorporateUnits":
      return {
        _id: permission._id,
        title: "Unidades corporativas",
        actions: [
          {
            title: "create",
            name: "Crear",
            isCheck: permission.actions.create,
          },
          {
            title: "update",
            name: "Editar",
            isCheck: permission.actions.update,
          },
          {
            title: "delete",
            name: "Eliminar",
            isCheck: permission.actions.delete,
          },
        ],
        subModules: subModules(permission.subModules),
      };
    case "Signatures":
      return {
        _id: permission._id,
        title: "Firmas",
        actions: [
          {
            title: "signLoadDocuments",
            name: "Firmar documentos cargado",
            isCheck: permission.actions.signLoadDocuments,
          },
          {
            title: "signPlatformDocuments",
            name: "Firmar documentos de Legal AI",
            isCheck: permission.actions.signPlatformDocuments,
          },
        ],
        authorized: permission.authorized,
        subModules: subModules(permission.subModules),
      };
    case "Reviews":
      return {
        _id: permission._id,
        title: "Revisión",
        actions: [
          {
            title: "reviewExistingDocument",
            name: "Revisar documento existente",
            isCheck: permission.actions.reviewExistingDocument,
          },
          {
            title: "reviewExistingDocumentLegalAI",
            name: "Revisar documento existente desde Legal AI",
            isCheck: permission.actions.reviewExistingDocumentLegalAI,
          },
          {
            title: "reviewNewDocument",
            name: "Revisar nuevo documento",
            isCheck: permission.actions.reviewNewDocument,
          },
          {
            title: "reviewNewDocumentLegalAI",
            name: "Revisar nuevo documento desde Legal AI",
            isCheck: permission.actions.reviewNewDocumentLegalAI,
          },
        ],
        authorized: permission.authorized,
        subModules: subModules(permission.subModules),

      };
    case "Trash":
      return {
        _id: permission._id,
        title: "Basurero",
        actions: [
          {
            title: "permanentlyEliminate",
            name: "Eliminar elementos permanente",
            isCheck: permission.actions.permanentlyEliminate,
          },
          {
            title: "readTrash",
            name: "Ver elementos eliminados",
            isCheck: permission.actions.readTrash,
          },
          {
            title: "restoreDeletedElements",
            name: "Restaurar elementos eliminados",
            isCheck: permission.actions.restoreDeletedElements,
          },
        ],
        authorized: permission.authorized,
        subModules: subModules(permission.subModules),

      };
    case "Documents":
      return {
        _id: permission._id,
        title: "Documentos",
        actions: [],
        authorized: permission.authorized,
        subModules: subModules(permission.subModules),
      };
  }
};

const subModules = (sub) => {
  const response = sub.map((item) => {
    switch (item?.module) {
      case "Labels":
        return {
          _id: item._id,
          title: "Etiquetas",
          actions: [
            {
              title: "create",
              name: "Crear etiquetas",
              isCheck: item.actions.create,
            },
            {
              title: "update",
              name: "Editar etiquetas",
              isCheck: item.actions.update,
            },
            {
              title: "delete",
              name: "Eliminar etiquetas",
              isCheck: item.actions.delete,
            },
          ],
          authorized: item.authorized,
        };
      case "UploadDocuments":
        return {
          _id: item._id,
          title: "Cargar documentos",
          actions: [
            {
              title: "uploadContract",
              name: "Cargar contrato",
              isCheck: item.actions.uploadContract,
            },
            {
              title: "uploadSignContract",
              name: "Cargar contrato firmado",
              isCheck: item.actions.uploadSignContract,
            },
            {
              title: "uploadAttachedDocuments",
              name: "Cargar anexos",
              isCheck: item.actions.uploadAttachedDocuments,
            },
            {
              title: "updateDraft",
              name: "Editar borradores",
              isCheck: item.actions.updateDraft,
            },
            {
              title: "deleteDocuments",
              name: "Eliminar documentos",
              isCheck: item.actions.deleteDocuments,
            },
            {
              title: "deleteDraft",
              name: "Eliminar borradores",
              isCheck: item.actions.deleteDraft,
            },
            {
              title: "moveDocuments",
              name: "Mover documentos",
              isCheck: item.actions.moveDocuments,
            },
            {
              title: "readDocuments",
              name: "Ver documentos",
              isCheck: item.actions.readDocuments,
            },
          ],
          authorized: item.authorized,
        };
      case "Approvals":
        return {
          _id: item._id,
          title: "Aprobaciones",
          actions: [
            {
              title: "requestApproval",
              name: "Solicitar aprobaciones",
              isCheck: item.actions.requestApproval,
            },
            {
              title: "desistApproval",
              name: "Desistir aprobaciones",
              isCheck: item.actions.desistApproval,
            },
          ],
          authorized: item.authorized,
        };
      case "Signatures":
        return {
          _id: item._id,
          title: "Firmas",
          actions: [
            {
              title: "requestSignatures",
              name: "Solicitar firmas - solicitante",
              isCheck: item.actions.requestSignatures,
            },
          ],
          authorized: item.authorized,
        };
      case "Tasks":
        return {
          _id: item._id,
          title: "Tareas",
          actions: [
            {
              title: "create",
              name: "Crear tareas",
              isCheck: item.actions.create,
            },
            {
              title: "update",
              name: "Editar tareas",
              isCheck: item.actions.update,
            },
            {
              title: "delete",
              name: "Eliminar tareas",
              isCheck: item.actions.delete,
            },
            {
              title: "receiveAssignments",
              name: "Recibir asignaciones de tareas desde alertas",
              isCheck: item.actions.receiveAssignments,
            },
          ],
          authorized: item.authorized,
        };
      case "Users":
        return {
          _id: item._id,
          title: "Usuarios",
          actions: [
            {
              title: "create",
              name: "Crear usuarios",
              isCheck: item.actions.create,
            },
            {
              title: "update",
              name: "Editar usuarios",
              isCheck: item.actions.update,
            },
            {
              title: "delete",
              name: "Eliminar usuarios",
              isCheck: item.actions.delete,
            },
          ],
          authorized: item.authorized,
        };
      case "Letterheads":
        return {
          _id: item._id,
          title: "Membretes",
          actions: [
            {
              title: "create",
              name: "Crear membretes",
              isCheck: item.actions.create,
            },
            {
              title: "update",
              name: "Actualizar membretes",
              isCheck: item.actions.update,
            },
            {
              title: "delete",
              name: "Eliminar membretes",
              isCheck: item.actions.delete,
            },
            {
              title: "readAll",
              name: "Ver todos los membretes",
              isCheck: item.actions.readAll,
            },
            {
              title: "restore",
              name: "Restaurar membretes",
              isCheck: item.actions.restore,
            },
            {
              title: "permanentlyDelete",
              name: "Eliminar membrete permanente",
              isCheck: item.actions.permanentlyDelete,
            },
          ],
          authorized: item.authorized,
        };
    }
  });
  return response;
};

export const ModulesExternal = (permission) => {
  switch (permission.module) {
    case "Requests":
      return {
        _id: permission._id,
        title: "Solicitudes",
        actions: [
          {
            title: "readAsignedRequests",
            name: "Ver solicitudes",
            isCheck: permission.actions.readAsignedRequests,
          },
          {
            title: "createRequests",
            name: "Crear solicitudes",
            isCheck: permission.actions.createRequests,
          },
          {
            title: "cancelRequests",
            name: "Cancelar solicitudes",
            isCheck: permission.actions.cancelRequests,
          },
          {
            title: "loadDocuments",
            name: "Cargar documentos",
            isCheck: permission.actions.loadDocuments,
          },
        ],
        subModules: subModulesExternals(permission.subModules),
      };
    case "CreateContracts":
      return {
        _id: permission._id,
        title: "Contratos",
        actions: [
          {
            title: "create",
            name: "Crear contratos",
            isCheck: permission.actions.create,
          },
          {
            title: "createByContractType",
            name: "Crear contratos por minutas",
            isCheck: permission.actions.createByContractType,
          },
          {
            title: "download",
            name: "Descargar",
            isCheck: permission.actions.download,
          },
        ],
      };
    
  }
};

const subModulesExternals = (sub) => {
  const response = sub.map((item) => {
    switch (item?.module) {
      case "Approvals":
        return {
          _id: item._id,
          title: "Aprobaciones",
          actions: [
            {
              title: "requestApproval",
              name: "Solicitar aprobaciones",
              isCheck: item.actions.requestApproval,
            },
            {
              title: "desistApproval",
              name: "Desistir aprobaciones",
              isCheck: item.actions.desistApproval,
            },
          ],
          authorized: item.authorized,
        };
      case "Signatures":
        return {
          _id: item._id,
          title: "Firmas",
          actions: [
            {
              title: "requestSignatures",
              name: "Solicitar firmas",
              isCheck: item.actions.requestSignatures,
            },
          ],
          authorized: item.authorized,
        };
    }
  });
  return response;
};
