import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalWarningForm = ({ title, description, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        // maxWidth="xl"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__warning-color">
            <WarningAmberOutlinedIcon fontSize="large" />
            {" " + title}
          </label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          {description}
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleClose}
            >
              Aceptar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalWarningForm;
