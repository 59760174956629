import { Button } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FilterTag from "../../parts/filters/FilterTag";
import CustomSearch from "./customSearch";
import ClearIcon from "@mui/icons-material/Clear";

export const DatatableChipsAndButtons = ({ columns, rows }) => {
  const tagsList = [
    {
      id: 1,
      title: "Opcional",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate quia eum vel asperiores non recusandae numquam eligendi nihil ad alias! Quasi, autem quaerat consequuntur at pariatur aspernatur veniam! Dolorem, ipsum!",
    },
    {
      id: 2,
      title: "Estándar",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate quia eum vel asperiores non recusandae numquam eligendi nihil ad alias! Quasi, autem quaerat consequuntur at pariatur aspernatur veniam! Dolorem, ipsum!",
    },
    {
      id: 3,
      title: "No sensible",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate quia eum vel asperiores non recusandae numquam eligendi nihil ad alias! Quasi, autem quaerat consequuntur at pariatur aspernatur veniam! Dolorem, ipsum!",
    },
    {
      id: 4,
      title: "popo",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate quia eum vel asperiores non recusandae numquam eligendi nihil ad alias! Quasi, autem quaerat consequuntur at pariatur aspernatur veniam! Dolorem, ipsum!",
    },
    {
      id: 5,
      title: "rere",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate quia eum vel asperiores non recusandae numquam eligendi nihil ad alias! Quasi, autem quaerat consequuntur at pariatur aspernatur veniam! Dolorem, ipsum!",
    },
  ];

  //Se renderiza con el primero y nunca entra la petición
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  const [tagsSelected, setTagsSelected] = useState([]);

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.map((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  useEffect(() => {
    setDataTable({ ...filteredDataTable, rows: rows });
    setFilteredDataTable({ ...filteredDataTable, rows: rows });
  }, [rows]);

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row>
        <Col md={6} className="datatable-CB__col-searchbar">
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col md={2}></Col>
        <Col className="datatable-CB__col-sort">
          <Button className="datatable-CB__button-sort">
            <Row>
              <Col md={2}>
                <img src={require("../../assets/svg/sortIcon.svg").default} />
              </Col>
              <Col>Ordenar por</Col>
            </Row>
          </Button>
        </Col>

        <Col className="datatable-CB__col-label">
          <FilterTag
            tagsList={tagsList}
            list={filteredDataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
          />
        </Col>
      </Row>
      <Row
        style={{ height: "5%", marginTop: "1.5%" }}
        className="datatable-CB__chips"
      >
        {tagsSelected.map((tag, idx) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row style={{ height: "55%" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={"No se encuentran carpetas de negocio"}
            hover
            pagingTop
            searchBottom={false}
            entries={7}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "carpetas de negocio"]}
            fullPagination
          />
        }
      </Row>
    </Container>
  );
};

export default DatatableChipsAndButtons;
