import React from "react";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
const TimelineRegister = ({ timelineItems }) => (
  <Timeline position="right" sx={{ padding: "0%" }}>
    {timelineItems.map((value, index) => (
      <TimelineItem key={uuidv4()} className="delete-before">
        <TimelineSeparator>
          <TimelineDot color={value.check ? "primary" : "grey"} />
          {index !== timelineItems.length - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <p className="caption">{value.text}</p>
        </TimelineContent>
      </TimelineItem>
    ))}
  </Timeline>
);

export default TimelineRegister;
