import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  getCurrentDocument,
  setNamePartClause,
} from "../../../../../../parts/document/currentDocumentSlice";
import {
  getSegmentedDocument,
  swapParts,
} from "../../library/clauses/clauseBulkSlice";
const SwapParts = () => {
  //hooks
  const currentDocument = useSelector(getCurrentDocument);
  const clausesBulk = useSelector(getSegmentedDocument);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  //state
  const [selectedClause, setSelectedClause] = useState(undefined);
  const listParts = ["Parte A", "Parte B", "Parte C", "Parte D"];

  //handles
  const handleSave = (event, index) => {
    try {
      const testParts = currentDocument.clauses.find(
        (cla) => cla.clause._id === id
      );
      if (testParts) {
        dispatch(
          setNamePartClause({
            name: event.target.value,
            clauseId: id,
            index: index,
          })
        );
      } else {
        dispatch(
          swapParts({
            name: event.target.value,
            indexClause: parseInt(id),
            part: index,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //useEffect
  useEffect(() => {
    const currentDocumentParts = currentDocument.clauses.find(
      (cla) => cla.clause._id === id
    );
    if (currentDocumentParts) {
      setSelectedClause(currentDocumentParts);
    } else {
      const clausesBulkParts = clausesBulk.find((cla, index) => index == id);
      const parts = Object.values(clausesBulkParts.parts);
      setSelectedClause({
        clause: { ...clausesBulkParts, parts: parts.filter((part) => part) },
      });
    }
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row style={{ height: "10%" }} className="col-start">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Intercambiar partes" />
        </Col>
        <Col md={9}></Col>
        {/* <Col md={"auto"} className="col-flex">
          <IconButton size="large">
            <NotificationsNoneOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </IconButton>
        </Col> */}
      </Row>
      <Row style={{ height: "6%" }} className="heading">
        Editar denominación de partes para esta cláusula
      </Row>
      {selectedClause?.clause?.parts?.map((part, index) => {
        return (
          <div key={index}>
            <Row className="caption">{listParts[index]}</Row>
            <Row>
              <Col md={5} style={{ margin: "2% 0" }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    placeholder="Primer nombre*"
                    bsPrefix={"input-group-container__no-icon label"}
                    onChange={(e) => {
                      handleSave(e, index);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col
                md={2}
                className="caption"
                style={{ display: "flex", alignItems: "center" }}
              >{`Antes era: ${part}`}</Col>
            </Row>
          </div>
        );
      })}
      <Row>
        <Col md={2}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            className="custom-input__button__primary-color"
            size="medium"
            onClick={() => {
              navigate(-1);
            }}
          >
            Continuar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SwapParts;
