import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
//Material Import
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
//Components
import CustomSearchFilter from "../Search/CustomSearchFilter";
//Services
import { v4 as uuidv4 } from "uuid";
import { DialogTitleContainer } from "./DialogTitleContainer";

export const ModalListSelectCheckBox = ({
  className = "custom-input__button__secondary-color",
  icon,
  list = [],
  name,
  title,
  itemsSelected,
  setItemsSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const handleClickOpen = () => {
    setDataSelected(itemsSelected);
    setFilteredData(list);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChecked = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setDataSelected((oldArray) => [...oldArray, item]);
    } else {
      setDataSelected(dataSelected.filter((itm) => itm._id != item._id));
    }
  };
  const handleAgree = () => {
    const sortByName = dataSelected.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setItemsSelected(sortByName);
    setOpen(false);
  };
  const handleDisagree = () => {
    setOpen(false);
  };
  return (
    <>
      <Button startIcon={icon} className={className} onClick={handleClickOpen}>
        {name}
      </Button>
      <Dialog
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">{title}</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Row>
            <CustomSearchFilter
              list={list}
              setFilteredData={setFilteredData}
              placeholder={"Buscar"}
            />
          </Row>
          <br />
          {filteredData.length > 0 &&
            filteredData.map((item, index) => {
              return (
                <Row
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid #e0e0e0",
                    borderTop: "1px solid #e0e0e0",
                  }}
                >
                  <Col xs={"auto"}>
                    <Checkbox
                      checked={dataSelected?.some(
                        (itm) => itm._id === item._id
                      )}
                      onClick={(e) => handleChecked(e, item)}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        color: "#00374f",
                        "&.Mui-checked": {
                          color: "#00374f",
                        },
                      }}
                    />
                  </Col>
                  <Col>
                    <p
                      key={uuidv4()}
                      className="heading__primary-color"
                      style={{
                        padding: "1rem",
                      }}
                    >
                      {item.name}
                    </p>
                  </Col>
                </Row>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Row xs={"auto"} style={{ justifyContent: "right" }}>
            <Col>
              <Button
                type="button"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleDisagree}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                type="button"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={handleAgree}
              >
                Agregar
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </>
  );
};
