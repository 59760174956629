import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { setWrongRole } from "../../parts/warnings/warningRoleStorage";

const useAuth = ({ redirectPath = "/", requiredRole, children, path }) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const existAccessToken = !!accessToken;
  const infoToken = localStorage.getItem("infoToken");

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const existInfoToken = !!infoToken;

  if (!existAccessToken || !existInfoToken) {
    return <Navigate to={redirectPath} replace />;
  }

  const typeLogin = payloadToken["loginType"];
  if (typeLogin === requiredRole) {
    return children || <Outlet />;
  } else {
    if (typeLogin === "SERVICE") {
      if (path == "managePlan") {
        dispatch(setWrongRole(true));
      }

      return <Navigate to={"/service/home"} replace />;
    }
    if (typeLogin === "ADMIN") {
      return <Navigate to={"/admin/manageUser"} replace />;
    }
  }
};

export default useAuth;
