import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterTag from "../filters/FilterTag";
import FilterCategory from "../filters/FilterCategory";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../parts/categories/clauseCategorySlice";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import ModalDecision from "../../components/Modals/modalDecision";
import ReactHtmlParser from "react-html-parser";
import {
  setSelectedClauses,
  getSelectedClauses,
  setSelectedSubClauses,
  getSelectedSubClauses,
} from "../minutas/clausesSelectedSlice";
import {
  fetchClauses,
  getClausesList,
  getClausesStatus,
  setStatusClause,
} from "../../pages/views/userService/modules/library/clauses/clauseSlice";
import { getTagList, getStatus, fetchTags } from "../manageTags/tagSlice.js";
import LoadingContent from "../../components/loadingContent";
import CustomSearchClauses from "../../components/Search/CustomSearchClauses";
import {
  fetchContractTypes,
  getContractsTypesList,
  getCurrentContractType,
  getStatus as getStatusContractTypes,
} from "../../pages/views/userService/modules/library/contractTypes/contractTypesSlice";
import {
  IconDiffParts,
  isDiffNumberParts,
} from "../../pages/views/userService/modules/library/contractTypes/utilsContractTypes";
import FilterContractTypes from "../filters/FilterContractTypes";
import { getClausesByCompany } from "../../services/clauses/clausesServices";
import { CODES } from "../../utils/codes";

const AssociateToClauses = ({
  classStyle = "custom-input__button__secondary-color",
  widthStyle = "auto",
  clausesListAssociate = null,
}) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const contractTypesList = useSelector(getContractsTypesList);
  const statusContractTypes = useSelector(getStatusContractTypes);
  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const currentClausesSelected = useSelector(getSelectedClauses);
  const currentSubClausesSelected = useSelector(getSelectedSubClauses);
  const clausesList = useSelector(getClausesList);
  const statusClauses = useSelector(getClausesStatus);
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);
  const currentContractType = useSelector(getCurrentContractType);
  const [show, setShow] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [clauses, setClauses] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [clausesSelected, setClausesSelected] = useState([]);
  const [subClausesSelected, setSubClausesSelected] = useState([]);
  const [textCustomSearch, setTextCustomSearch] = useState("");
  const [
    isOpenModalDesicionAssociateClauses,
    setIsOpenModalDesicionAssociateClauses,
  ] = useState(false);
  const [
    isOpenModalDesicionCancelAssociateClauses,
    setIsOpenModalDesicionCancelAssociateClauses,
  ] = useState(false);
  const [countChecked, setCountChecked] = useState(0);
  const [countCheckedSubClauses, setCountCheckedSubClauses] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contractTypesSelected, setContractTypesSelected] = useState([]);

  const handleShow = () => {
    for (const item of currentClausesSelected) {
      setClausesSelected((oldArray) => [...oldArray, item]);
    }
    for (const item of currentSubClausesSelected) {
      setSubClausesSelected((oldArray) => [...oldArray, item]);
    }
    setFilteredClauses(clauses);
    setShow(true);
  };
  const handleAgreeModalDesicionAssociateClauses = () => {
    if (countChecked > 0) {
      const clauses = clausesSelected;
      const subclauses = subClausesSelected;
      let data = [];
      for (const subclause of subclauses) {
        for (const clause of clauses) {
          if (clause._id === subclause.clause) {
            data = [
              ...data,
              {
                ...clause,
                subclauses: [...subclause.subclause],
              },
            ];
          }
        }
      }
      dispatch(setSelectedClauses(data));
      setClausesSelected([]);
      setCountChecked(0);
      dispatch(setSelectedSubClauses(subclauses));
      setSubClausesSelected([]);
      setCountCheckedSubClauses(0);
      setShow(false);
      setIsOpenModalDesicionAssociateClauses(false);
      setTextCustomSearch("");
      dispatch(setStatusClause({ status: "fetch" }));
    } else {
      setIsOpenModalDesicionAssociateClauses(false);
    }
  };
  const handleCloseModalDesicionAssociateClauses = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleDisagreeModalDesicionAssociateClauses = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleCloseModalDesicionCancelAssociateClauses = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleAgreeModalDesicionCancelAssociateClauses = () => {
    setClausesSelected([]);
    setSubClausesSelected([]);
    setCountChecked(0);
    setCountCheckedSubClauses(0);
    setShow(false);
    setIsOpenModalDesicionCancelAssociateClauses(false);
  };
  const handleDisagreeModalDesicionCancelAssociateClauses = () => {
    setIsOpenModalDesicionCancelAssociateClauses(false);
  };

  const handleChecked = (e, clauses, index) => {
    const item = e.target;
    const subClausesToAdd = clauses.subclauses;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setCountCheckedSubClauses(
        countCheckedSubClauses + subClausesToAdd.length
      );
      setSubClausesSelected((oldArray) => [
        ...oldArray,
        {
          clause: clauses._id,
          subclause: subClausesToAdd,
        },
      ]);
      setClausesSelected((oldArray) => [...oldArray, clauses]);
    } else {
      setCountChecked(countChecked - 1);
      setCountCheckedSubClauses(
        countCheckedSubClauses - subClausesToAdd.length
      );
      setSubClausesSelected(
        subClausesSelected.filter(
          (subClause) => subClause.clause !== clauses._id
        )
      );
      setClausesSelected(
        clausesSelected.filter((clause) => clause._id !== clauses._id)
      );
    }
  };
  const handleCheckedSubClauses = (e, clause, subClauses) => {
    const item = e.target;
    if (item.checked) {
      setCountCheckedSubClauses(countCheckedSubClauses + 1);
      setSubClausesSelected((oldArray) => {
        return oldArray.map((item) => {
          let add = {};
          if (item.clause === clause._id) {
            add = {
              ...item,
              subclause: [...item.subclause, { subclause: subClauses }],
            };
          } else {
            return item;
          }
          return add;
        });
      });
    } else {
      setCountCheckedSubClauses(countCheckedSubClauses - 1);
      const deleteSubClauseSelected = subClausesSelected.map((selected) => {
        if (selected.clause === clause._id) {
          const subclause = selected.subclause.filter(
            (subclause) => subclause.subclause._id !== subClauses._id
          );
          return {
            clause: selected.clause,
            subclause: subclause,
          };
        } else {
          return selected;
        }
      });
      setSubClausesSelected(deleteSubClauseSelected);
    }
  };
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.name));
      });
      setFilteredClauses(filterTags);
    } else {
      setFilteredClauses(clauses);
    }
  };
  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };
  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });
      setFilteredClauses(filterCategories);
    } else {
      setFilteredClauses(clauses);
    }
  };

  const handleCustomSearch = async (textSearch) => {
    try {
      if (textSearch === "") {
        setFilteredClauses(clauses);
      } else {
        setTextCustomSearch(textSearch);
        setIsLoading(true);
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
          searchTerm: textSearch,
        };
        const response = await getClausesByCompany(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          setFilteredClauses(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /* UseEffects Fetch Clauses Categories */

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  /* UseEffects Fetch Clauses Categories */

  useEffect(() => {
    const fetchData = async () => {
      if (statusTags === "fetch") {
        dispatch(fetchTags("CLAUSES"));
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  /* UseEffects Fetch Clauses */

  useEffect(() => {
    if (statusClauses === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusClauses]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusClauses === "fetch") {
          dispatch(fetchClauses(textCustomSearch));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusClauses, dispatch]);

  useEffect(() => {
    if (clausesListAssociate) {
      setClauses(clausesListAssociate);
      setFilteredClauses(clausesListAssociate);
    } else {
      setClauses(clausesList);
      setFilteredClauses(clausesList);
    }
  }, [clausesList, clausesListAssociate]);

  //Minutas

  //Seleccionar minutas

  const handleContractTypesSelected = (contractTypes) => {
    setContractTypesSelected(contractTypes);
  };

  //Eliminar minuta seleccionada

  const handleDeleteContractType = (contractType) => {
    setContractTypesSelected(
      contractTypesSelected.filter((ct) => ct._id !== contractType._id)
    );
    const newContractTypes = contractTypesSelected.filter(
      (ct) => ct._id !== contractType._id
    );
    if (newContractTypes.length > 0) {
      let contractTypesClauses = [];
      for (const contractType of newContractTypes) {
        for (const { clause } of contractType.clauses) {
          contractTypesClauses.push(clause._id);
        }
      }
      const filterContractTypes = clauses.filter((item) =>
        contractTypesClauses.includes(item._id)
      );
      setFilteredClauses(filterContractTypes);
    } else {
      setFilteredClauses(clauses);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusContractTypes === "fetch") {
          dispatch(fetchContractTypes({ isPublish: false }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusContractTypes, dispatch]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        className={classStyle}
        onClick={handleShow}
        style={{ width: widthStyle }}
      >
        <p className="button">Importar cláusula</p>
      </Button>
      <Modal show={show} fullscreen enforceFocus>
        <Modal.Header>
          <Modal.Title>
            <p className="label__primary-color__page-title">Asociar cláusula</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={containerRef}>
          <Container fluid>
            {isLoading && <LoadingContent />}
            <br />
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Busca las cláusulas y selecciona los numerales que quieres
                  añadir al contrato:
                </p>
              </Col>
            </Row>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <CustomSearchClauses
                  placeholder="Buscar cláusula"
                  handleCustomeSearch={handleCustomSearch}
                />
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} cláusulas seleccionadas
                </p>
              </Col>
              <Col xs={"auto"}>
                <FilterContractTypes
                  list={filteredClauses}
                  contractTypesList={contractTypesList}
                  setFilteredInfo={setFilteredClauses}
                  handleContractTypesSelected={handleContractTypesSelected}
                />
              </Col>
              <Col xs={"auto"}>
                <FilterTag
                  list={filteredClauses}
                  tagsList={tagsList}
                  setFilteredInfo={setFilteredClauses}
                  handleTagsSelected={handleTagsSelected}
                  currentTagSelected={tagsSelected}
                />
              </Col>
              <Col xs={"auto"}>
                <FilterCategory
                  list={filteredClauses}
                  categoriesList={categoriesList}
                  setFilteredInfo={setFilteredClauses}
                  handleCategoriesSelected={handleCategoriesSelected}
                />
              </Col>
            </Row>
            <br />
            <Row sm={"auto"} style={{ justifyContent: "right" }}>
              {tagsSelected.map((tag) => {
                return (
                  <Col xs={"auto"} key={tag?._id}>
                    <span className="caption custom-badges__disabled">
                      {tag + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}

              {categoriesSelected.map((category) => {
                return (
                  <Col xs={"auto"} key={category?._id}>
                    <span className="caption custom-badges__secundary">
                      {category + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteCategory(category)}
                      />
                    </span>
                  </Col>
                );
              })}

              {contractTypesSelected.map((contractType) => (
                <Col xs={"auto"} key={contractType?._id}>
                  <span className="caption custom-badges__tertiary">
                    {contractType?.name + " "}{" "}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => handleDeleteContractType(contractType)}
                    />
                  </span>
                </Col>
              ))}
            </Row>
            <br />
            <br />
            <Row>
              <div className="custom-accordion__container__small">
                {filteredClauses.length > 0 ? (
                  filteredClauses.map((clause, index) => {
                    let count = 0;
                    subClausesSelected.forEach((subClauses) => {
                      if (subClauses.clause === clause._id) {
                        count = subClauses.subclause.length;
                      }
                    });
                    let state = false;
                    clausesSelected.forEach((item) => {
                      if (item.id === clause.id) {
                        state = true;
                      }
                    });
                    return (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={clause?._id}
                      >
                        <Col>
                          <Accordion className="custom-accordion">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Col sm={4}>
                                <p className="heading__primary-color">
                                  {clause.title + " "}
                                </p>
                              </Col>
                              <Col xs={"auto"}>
                                <IconDiffParts
                                  containerRef={containerRef}
                                  isDiff={isDiffNumberParts(
                                    currentContractType?.parts,
                                    clause?.parts
                                  )}
                                />
                              </Col>
                              <Col xs={"auto"}>
                                <span className="subheading">
                                  A favor de: {clause.inFavor + " "}
                                </span>
                              </Col>
                              <Col sm={3} style={{ textAlign: "right" }}>
                                <Col>
                                  <span className="subheading">
                                    Categorías:{" "}
                                    {clause.categories.map((category, i) => {
                                      return (
                                        <span
                                          key={category?._id}
                                          style={{ marginRight: "1%" }}
                                          className="caption custom-badges__secundary"
                                        >
                                          {category.name}
                                        </span>
                                      );
                                    })}
                                  </span>
                                </Col>
                              </Col>
                              <Col sm={2} style={{ textAlign: "right" }}>
                                <p className="caption__primary-color__active">
                                  {count
                                    ? `${count} numerales seleccionados`
                                    : "0 numerales seleccionados"}
                                </p>
                              </Col>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                width: "100%",
                                boxShadow:
                                  "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                              }}
                            >
                              <Row>
                                <span className="subheading">
                                  Etiquetas:{" "}
                                  {clause.labels.map((tag, i) => {
                                    return (
                                      <span
                                        key={tag?._id}
                                        style={{ marginRight: "1%" }}
                                        className="caption custom-badges__disabled"
                                      >
                                        {tag.name}
                                      </span>
                                    );
                                  })}
                                </span>
                              </Row>
                              <br />
                              <br />
                              <Row>
                                <span className="caption">
                                  {/* Text clause */}
                                  <Row style={{ alignItems: "center" }}>
                                    <Col xs={"auto"}>
                                      <Checkbox
                                        checked={state}
                                        onClick={(e) =>
                                          handleChecked(e, clause)
                                        }
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 28,
                                          },
                                          color: "#00374f",
                                          "&.Mui-checked": {
                                            color: "#00374f",
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col>{ReactHtmlParser(clause.text)}</Col>
                                  </Row>
                                  <br />
                                  <br />
                                  {/* Mapea estado de las subclausulas para selecionara */}
                                  {clause?.subclauses?.length > 0 &&
                                    clause.subclauses.map((subClause, i) => {
                                      return (
                                        <Row
                                          key={subClause?._id}
                                          className="justify-text clauses-number"
                                          style={{
                                            marginLeft: "1%",
                                            marginRight: "1%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Col
                                            xs={"auto"}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Checkbox
                                              disabled={!state}
                                              checked={subClausesSelected.some(
                                                (item) => {
                                                  const find =
                                                    subClause.subclause;
                                                  if (
                                                    item.clause === clause._id
                                                  ) {
                                                    return item.subclause.some(
                                                      (item) =>
                                                        item.subclause?._id ===
                                                        find._id
                                                    );
                                                  }
                                                  return false;
                                                }
                                              )}
                                              onClick={(e) => {
                                                handleCheckedSubClauses(
                                                  e,
                                                  clause,
                                                  subClause.subclause
                                                );
                                              }}
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 20,
                                                },
                                                color: "#00374f",
                                                "&.Mui-checked": {
                                                  color: "#00374f",
                                                },
                                              }}
                                            />
                                            <b>
                                              {i + 1}.{""}
                                            </b>
                                          </Col>
                                          <Col>
                                            {ReactHtmlParser(
                                              subClause.subclause?.text
                                            )}
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </span>
                              </Row>
                            </AccordionDetails>
                          </Accordion>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography variant="h5">
                    No se han encontrado resultados
                  </Typography>
                )}
              </div>
            </Row>
            <br />
            <br />
            {/* Botones de confirmación */}
            <Row xs={"auto"} style={{ justifyContent: "end" }}>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() =>
                    setIsOpenModalDesicionCancelAssociateClauses(true)
                  }
                >
                  <p className="button">Regresar</p>
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setIsOpenModalDesicionAssociateClauses(true);
                  }}
                >
                  <p className="button">Añadir cláusulas y numerales</p>
                </Button>
              </Col>
            </Row>
            <ModalDecision
              title={"Cláusulas asociadas"}
              message={
                countChecked > 0
                  ? `Se asociarán al contrato ${countChecked} cláusulas y ${countCheckedSubClauses} numerales`
                  : "No se han seleccionado cláusulas"
              }
              agreeText={"Aceptar"}
              disagreeText={"Cancelar"}
              handleAgree={handleAgreeModalDesicionAssociateClauses}
              handleDisagree={handleDisagreeModalDesicionAssociateClauses}
              open={isOpenModalDesicionAssociateClauses}
              onClose={handleCloseModalDesicionAssociateClauses}
            />
            <ModalDecision
              title={"Regresar"}
              message={"No se guardarán los cambios"}
              agreeText={"Aceptar"}
              disagreeText={"Cancelar"}
              handleAgree={handleAgreeModalDesicionCancelAssociateClauses}
              handleDisagree={handleDisagreeModalDesicionCancelAssociateClauses}
              open={isOpenModalDesicionCancelAssociateClauses}
              onClose={handleCloseModalDesicionCancelAssociateClauses}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { AssociateToClauses };
