import {
  faComments,
  faDownload,
  faEye,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../components/loadingContent";
import ModalDecision from "../components/Modals/modalDecision";
import ModalInfo from "../components/Modals/modalInfo";
import ModalInpuText from "../components/Modals/modalInpuText";
import { env } from "../env";
import { GetFileFromUrl } from "../services/files/fileServices";
import {
  DownloadDocs,
  DownloadDocsPanel,
  GenerateOTPCode,
  GenerateOTPCodePanel,
  LimitPlanState,
  LimitPlanStateSidePanel,
  ReturnSignWithComments,
  ReturnSignWithCommentsSidePanel,
  ValidateOTPCode,
  ValidateOTPCodePanel,
  VerifyTokenToSign,
  VerifyTokenToSignPanel,
} from "../services/general/requestServices";
import { CODES } from "../utils/codes";
import GetToken from "../utils/getToken";

const ExternalSign = () => {
  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;

  const { token, signatureId, signatureType } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [tokenStatusCode, setTokenStatusCode] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [consecutive, setConsecutive] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [returnSignModal, setReturnSignModal] = useState(false);
  const [isOpenModalOTP, setIsOpenModalOTP] = useState(false);
  const [show, setShow] = useState(false);
  const [path, setPath] = useState(null);
  const [consecutiveToSign, setConsecutiveToSign] = useState("");
  const [documentIdToSign, setDocumentIdToSign] = useState("");
  const [documentTypeToSign, setDocumentTypeToSign] = useState("");
  const [isOpenModalDownloadSign, setIsOpenModalDownloadSign] = useState(false);
  const [isOpenModalMaAttempts, setIsOpenModalMaAttempts] = useState(false);
  const [responseMaxAttemps, setResponseMaxAttemps] = useState("");
  const [isOpenModalBadOTP, setIsOpenModalBadOTP] = useState(false);
  const [isDisabledSendOTPCode, setIsDisabledSendOTPCode] = useState(false);
  const [otpCodeAttempts, setOtpCodeAttempts] = useState(0);
  const [openModalInfoErrorSign, setOpenModalInfoErrorSign] = useState(false);
  const [responseDataSignError, setResponseDataSignError] = useState({});
  const [isOpenModalAttemps, setisOpenModalAttemps] = useState(false);
  const [responseMaxSignatures, setResponseMaxSignatures] = useState("");
  const [isOpenModalMaxSignatures, setIsOpenModalMaxSignatures] =
    useState(false);

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const handleClose = () => {
    setIsOpenModal(false);
    navigate("/");
  };

  const handleClosePreview = () => {
    setShow(false);
  };

  const handleCloseBadOTP = () => {
    setIsOpenModalBadOTP(false);
  };

  const responseData = {
    data: {
      responseMessage:
        "No ha sido posible abrir el módulo para firmar su documento debido a que no cuenta con permisos o su token ha expirado. Por favor contactar a su abogado a cargo para volver a intentarlo",
    },
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const obj = {
          token: token,
          signatureId: signatureId,
        };
        let service;
        if (signatureType === "request") {
          service = await VerifyTokenToSign(obj);
        } else if (signatureType === "lateralPanel") {
          service = await VerifyTokenToSignPanel(obj);
        }
        if (service.status) {
          if (
            service.status === CODES.COD_RESPONSE_HTTP_OK &&
            service.data.responseCode !==
              CODES.COD_RESPONSE_ERROR_TOKEN_EXPIRED &&
            service.data.responseCode !== CODES.COD_RESPONSE_VALIDATION_TOKEN
          ) {
            const documentId =
              service?.data?.responseMessage?.documentId?._id ||
              service?.data?.responseMessage?.documentId;
            setConsecutive(service?.data?.responseMessage?.consecutive);
            setDocumentId(documentId);
            setDocumentType(service?.data?.responseMessage?.documentType);
            setTokenStatusCode(service?.data?.responseCode);
            return;
          }
        }
        setIsOpenModal(true);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const handleAgreeReturn = async (message) => {
    setIsLoading(true);
    try {
      const obj = {
        message: message,
        signatureId: signatureId,
      };
      let service;
      if (signatureType === "request") {
        service = await ReturnSignWithComments(obj);
      } else if (signatureType === "lateralPanel") {
        service = await ReturnSignWithCommentsSidePanel(obj);
      }
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("/");
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadDoc = async () => {
    try {
      setIsLoading(true);
        
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");

      const obj = {
        consecutive: consecutive,
        documentId: documentId,
        documentType: documentType,
        signatureId,
        downloadType: "original",
        company,
        corporateUnit
      };

      let service;

      if (signatureType === "request") {
        service = await DownloadDocs(obj);
      } else if (signatureType === "lateralPanel") {
        service = await DownloadDocsPanel(obj);
      }
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(service.data, `Previsualización_Documento_a_Firmar.pdf`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewDoc = async () => {
    try {
      setShow(true);
      if (path) return;
      setIsLoading(true);
      let url = "";
      if (signatureType === "request") {
        url = `${env.REACT_APP_API_URL}/thirdPartyRequest/get/document?consecutive=${consecutive}&documentId=${documentId}&documentType=${documentType}`;
      } else if (signatureType === "lateralPanel") {
        url = `${env.REACT_APP_API_URL}/signatures/sidePanel/document?consecutive=${consecutive}&documentId=${documentId}&documentType=${documentType}&signatureId=${signatureId}&downloadType="original"`;
      }
      const blobFile = await GetFileFromUrl(url);
      const newUrl = window.URL.createObjectURL(blobFile.data);
      setPath(newUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAgreeContinueToSign = async (code) => {
    try {
      setIsLoading(true);
      const obj = {
        signatureId: signatureId,
        otp: code,
      };

      let service;

      if (signatureType === "request") {
        service = await ValidateOTPCode(obj);
      } else if (signatureType === "lateralPanel") {
        service = await ValidateOTPCodePanel(obj);
      }
      if (service.status) {
        if (
          service.status === CODES.COD_RESPONSE_HTTP_OK &&
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const { consecutive, documentId, documentType } =
            service.data.responseMessage;

          setConsecutiveToSign(consecutive);
          setDocumentIdToSign(documentId);
          setDocumentTypeToSign(documentType);
          setIsOpenModalOTP(false);
          setIsOpenModalDownloadSign(true);
        }
        if (
          service.status === CODES.COD_RESPONSE_HTTP_OK &&
          service.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_MAXIMUM_ATTEMPTS_ALLOWED
        ) {
          setIsOpenModalDownloadSign(false);
          setIsOpenModalOTP(false);
          setResponseMaxAttemps(service);
          setIsOpenModalMaAttempts(true);
        }

        if (
          service.status === CODES.COD_RESPONSE_HTTP_OK &&
          service.data.responseCode === CODES.COD_RESPONSE_ERROR_VALIDATE
        ) {
          setResponseMaxAttemps(service);
          setIsOpenModalBadOTP(true);
          setisOpenModalAttemps(true);
        }
        if (
          service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
          service.data.responseCode === CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setIsOpenModalDownloadSign(false);
          setIsOpenModalOTP(false);
          setResponseMaxSignatures(service);
          setIsOpenModalMaxSignatures(true);
        }
        if (service.status === CODES.COD_RESPONSE_HTTP_ERROR) {
          setOpenModalInfoErrorSign(true);
          setResponseDataSignError(service);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadSignDoc = async () => {
    setIsLoading(true);
        
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    
    const obj = {
      consecutive: consecutiveToSign,
      documentId: documentIdToSign,
      documentType: documentTypeToSign,
      signatureId,
      downloadType: "signed",
      company,
      corporateUnit
    };

    let service;

    if (signatureType === "request") {
      service = await DownloadDocs(obj);
    } else if (signatureType === "lateralPanel") {
      service = await DownloadDocsPanel(obj);
    }

    if (service.status) {
      if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
        fileDownload(service.data, `archivo.pdf`);
      }
    }
    setIsLoading(false);
  };

  const handleCloseMaxAttempts = () => {
    setIsOpenModalMaAttempts(false);
    navigate("/");
  };

  const handleCloseMaxSignatures = () => {
    setIsOpenModalMaxSignatures(false);
    navigate("/");
  };

  const handleZoom = (doc, scale) => {
    console.log(`Zoom document to ${scale}`);
  };

  const handleGenerateOTPCode = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalOTP(false);
      let service;

      if (signatureType === "request") {
        service = await GenerateOTPCode(signatureId);
      } else if (signatureType === "lateralPanel") {
        service = await GenerateOTPCodePanel(signatureId);
      }
      if (service.status) {
        if (
          service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const { codeResendAttempts } = service.data.responseMessage;
          setOtpCodeAttempts(codeResendAttempts);
          setIsOpenModalOTP(true);
        }
        if (
          service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          service.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_MAXIMUM_ATTEMPTS_ALLOWED
        ) {
          setIsOpenModalDownloadSign(false);
          setIsOpenModalOTP(false);
          setResponseMaxAttemps(service);
          setIsOpenModalMaAttempts(true);
        }

        if (
          service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          service.data.responseCode === CODES.COD_RESPONSE_ERROR_VALIDATE
        ) {
          setResponseMaxAttemps(service);
          setIsOpenModalBadOTP(true);
        }

        if (
          service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
          service.data.responseCode === CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setIsOpenModalOTP(false);
          setResponseMaxSignatures(service);
          await handleSuspendSignature();
          setIsOpenModalMaxSignatures(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuspendSignature = async () => {
    try {
      setIsLoading(true);
      if (signatureType === "request") {
        await LimitPlanState({ signatureId });
      } else if (signatureType === "lateralPanel") {
        await LimitPlanStateSidePanel({ signatureId });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    otpCodeAttempts >= 4
      ? setIsDisabledSendOTPCode(true)
      : setIsDisabledSendOTPCode(false);
  }, [otpCodeAttempts]);

  let dataAttemps = {
    status: 200,
    data: {
      responseMessage: `Actualmente has gastado ${
        otpCodeAttempts - 1
      } de 3 intentos`,
    },
  };

  const closeModalAttempsInfo = () => {
    setisOpenModalAttemps(false);
    setIsOpenModalOTP(true);
  };

  return tokenStatusCode == 0 ? (
    <div className="login-page-form">
      <Container
        fluid
        className="container-forgot-password"
        style={{
          margin: "0% 20%",
          padding: "30px",
          maxWidth: "648px",
        }}
      >
        {isLoading && <LoadingContent />}
        <Row xs={"auto"} className="home-title-row">
          Firmar documento
        </Row>
        <Row xs={"auto"}>
          <p
            className="home-init-cards-row__title-row"
            style={{ marginTop: "20px" }}
          >
            Documento
          </p>
        </Row>
        <br />
        <Row>
          <Col sm={3} xs={12}>
            <button
              className="btn_white_background"
              type="button"
              style={{ padding: "12px 24px", marginLeft: "2rem" }}
              onClick={handlePreviewDoc}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faEye}
                style={{ marginRight: "8px" }}
              />
              Visualizar
            </button>
          </Col>
          <Col sm={3} xs={12}>
            <button
              className="btn_white_background"
              type="button"
              style={{ padding: "12px 24px", marginLeft: "2rem" }}
              onClick={handleDownloadDoc}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faDownload}
                style={{ marginRight: "8px" }}
              />
              Descargar
            </button>
          </Col>
        </Row>
        <Row xs={"auto"}>
          <p
            className="home-init-cards-row__title-row"
            style={{ marginTop: "20px" }}
          >
            Responder con comentarios
          </p>
        </Row>
        <Row xs={"auto"}>
          <p className="caption" style={{ marginTop: "20px" }}>
            Presione el siguiente botón en caso de que requiera responder al
            solicitante con un mensaje.
          </p>
        </Row>
        <br />
        <Row xs={"auto"}>
          <button
            className="btn_white_background"
            type="button"
            style={{ padding: "12px 24px", marginLeft: "2rem" }}
            onClick={() => {
              setReturnSignModal(true);
            }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faComments}
              style={{ marginRight: "8px" }}
            />
            Responder con comentarios
          </button>
        </Row>
        <Row xs={"auto"}>
          <p
            className="home-init-cards-row__title-row"
            style={{ marginTop: "20px" }}
          >
            Firmar
          </p>
        </Row>
        <br />
        <Row xs={"auto"}>
          <button
            className="btn_blue_background"
            type="button"
            style={{ padding: "12px 24px", marginLeft: "2rem" }}
            onClick={() => {
              handleGenerateOTPCode();
            }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faFileSignature}
              style={{ marginRight: "8px" }}
            />
            Firmar documento
          </button>
        </Row>
        {!isLoading && (
          <ModalInpuText
            title={"Devolver firma"}
            message={
              "Por favor escriba las razones por las cuales no se firmó el documento:"
            }
            placeholder={
              "Encuentro que el documento no está listo para firmar por los siguientes aspectos..."
            }
            open={returnSignModal}
            onClose={setReturnSignModal}
            agreeText="Devolver firma"
            disagreeText="Cancelar"
            handleAgree={handleAgreeReturn}
            handleDisagree={() => {
              setReturnSignModal(false);
            }}
          />
        )}
        {!isLoading && (
          <ModalInfo
            title={"Limite de firmas"}
            responseData={responseMaxSignatures}
            open={isOpenModalMaxSignatures}
            onClose={handleCloseMaxSignatures}
            confirmationText={"Aceptar"}
          />
        )}
        {!isLoading && (
          <ModalInpuText
            title={"Autenticación para la firma"}
            message={
              "Se ha enviado a su correo electrónico inscrito, un código para autenticar la firma. Por favor escriba el código a continuación:"
            }
            placeholder={"0000"}
            open={isOpenModalOTP}
            onClose={setIsOpenModalOTP}
            agreeText="Continuar con la firma"
            disagreeText="Volver a enviar código"
            handleAgree={handleAgreeContinueToSign}
            disableDisagreeText={isDisabledSendOTPCode}
            handleDisagree={() => handleGenerateOTPCode()}
          />
        )}
        {!isLoading && (
          <ModalDecision
            title={"Documento firmado"}
            message={"¡El documento fue firmado de manera exitosa!"}
            open={isOpenModalDownloadSign}
            onClose={() => setIsOpenModalDownloadSign(false)}
            agreeText="Continuar"
            disagreeText="Descargar documento firmado"
            handleAgree={() => navigate("/")}
            handleDisagree={handleDownloadSignDoc}
          />
        )}
        {!isLoading && (
          <ModalInfo
            title={"Número máximo de intentos permitidos"}
            responseData={responseMaxAttemps}
            open={isOpenModalMaAttempts}
            onClose={handleCloseMaxAttempts}
          />
        )}
        {!isLoading && (
          <ModalInfo
            title={"Código de validación incorrecto"}
            responseData={responseMaxAttemps}
            open={isOpenModalBadOTP}
            onClose={handleCloseBadOTP}
          />
        )}
        {!isLoading && (
          <ModalInfo
            title={"Firmar Documento"}
            responseData={responseDataSignError}
            open={openModalInfoErrorSign}
            onClose={() => setOpenModalInfoErrorSign(false)}
          />
        )}

        {!isLoading && (
          <ModalInfo
            title={"Información"}
            responseData={dataAttemps}
            open={isOpenModalAttemps}
            onClose={closeModalAttempsInfo}
          />
        )}

        <Worker workerUrl={WORKER_URL}>
          <Modal show={show} onHide={handleClosePreview} scrollable>
            <Modal.Header closeLabel="cerrar" closeButton>
              <Modal.Title
                className="heading-secondary"
                style={{ marginRight: "6rem" }}
              >
                Documento a firmar
              </Modal.Title>

              <>
                <ZoomOutButton />
                <ZoomPopover />
                <ZoomInButton />
              </>
            </Modal.Header>
            <Modal.Body>
              {isLoading && <LoadingContent />}
              {path && (
                <Viewer
                  plugins={[zoomPluginInstance]}
                  fileUrl={path}
                  onZoom={handleZoom}
                  httpHeaders={{
                    Authorization: GetToken(),
                  }}
                />
              )}
            </Modal.Body>
          </Modal>
        </Worker>
      </Container>
    </div>
  ) : (
    <>
      <ModalInfo
        title={"Ingreso incorrecto"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </>
  );
};

export default ExternalSign;
