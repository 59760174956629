import React from "react";
import ReactHtmlParser from "react-html-parser";
import { NUMBERINGWORDSO } from "./numberings";

function generateClauseHTML({ clause, isTitleIncluded }) {
  return (
    <div className="clause-container">
      {isTitleIncluded && (
        <div className="clause-title">
          <strong>{ReactHtmlParser(clause.title)}</strong>
        </div>
      )}
      {/* Text clause */}
      <div className="clause-text">{ReactHtmlParser(clause.text)}</div>
      {/* Mapea estado de las subclausulas para seleccionar */}
      {clause?.subclauses?.map((item, i) => (
        <div key={item?._id} className="subclause">
          <strong>
            {`${i + 1}. `}
            {item?.subclause?.title && `${item?.subclause?.title}: `}
          </strong>
          <div className="subclause-text">
            {ReactHtmlParser(item?.subclause?.text)}
          </div>
          {item?.subclause?.childs &&
            item?.subclause?.childs.map((child, index) => (
              <div key={child?._id} className="subclause-child">
                <strong>
                  {`${i + 1}.${index + 1}. `}
                  {child?.title}
                </strong>
                <div className="subclause-child-text">
                  {ReactHtmlParser(child?.text)}
                </div>
              </div>
            ))}
        </div>
      ))}
      <ol className="clause-paragraphs">
        {clause.paragraphs.map((el, i) => (
          <li key={el?._id} className="clause-paragraph">
            <strong>{`Parágrafo ${NUMBERINGWORDSO[i + 1]}`}</strong>
            <p>{ReactHtmlParser(el.paragraph?.text)}</p>
          </li>
        ))}
      </ol>
    </div>
  );
}

// Export the function
export { generateClauseHTML };
