import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import DiscussionBoard from "../../../../../../../components/DiscussionBoard/DiscussionBoard";
import LoadingContent from "../../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import ModalUploadDocs from "../../../../../../../components/Modals/ModalUploadDocs";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import {
  addCommentApproval,
  sendApprovalAgain,
  uploadDocumentForApproval,
} from "../../../../../../../services/approvals/approvalServices";
import { getApprovalById } from "../../../../../../../services/approvals/createApproval";
import { getFileDocument } from "../../../../../../../services/documents/documentsRequest";
import { findOneDataExternalRequest } from "../../../../../../../services/general/CarvajalServices";
import { APPROVAL_STATE } from "../../../../../../../utils/approvalsState";
import { CODES } from "../../../../../../../utils/codes";
import { dateNormal } from "../../../../../../../utils/getDate";
import { validateCompleteSigns } from "../ViewRequestStandard";

const RequestApprovalAgain = () => {
  //Hooks
  const { consecutive, type, approvalId } = useParams();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    message: yup.string().required("*Este campo es obligatorio"),
    file: yup.mixed().test("Required", "*Debe subir un documento", (value) => type !== "upload" || !!value),
    documentId: type === "history" ? yup.string().required("*Este campo es obligatorio") : yup.string().nullable().default(""),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [documentList, setDocumentsList] = useState("");
  const [isModalRequest, setIsModalRequest] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isNewComment, setIsNewComment] = useState(false);
  const [openModalDoc, setOpenModalDoc] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [responseData, setResponseData] = useState({});
  const [approvalData, setApprovalData] = useState({});
  const [requestCommentaries, setRequestCommentaries] = useState({
    _id: "",
    comments: [],
  });
  const [dataTableRequiredDocs, setDataTableRequiredDocs] = useState({
    columns: [],
    rows: [],
  });
  const [dataTableDocumentPrincipal, setDataTableDocumentPrincipal] = useState({
    columns: [],
    rows: [],
  });
  const [isOpenModalTypeDocument, setIsOpenModalTypeDocument] = useState(false);

  //Handler
  const handlePreRequest = (data) => {
    setDataForm(data);
    if (type !== "upload" && documentList) {
      const {
        documentPrincipal,
        documentsAttachments,
        documentsOtherApplicant,
        documentsOtherAttorney,
      } = documentList;
      const docs = [
        documentPrincipal,
        documentsAttachments,
        documentsOtherApplicant,
        documentsOtherAttorney,
      ].flat();
      const docFilter = docs.find((doc) => doc._id === data.documentId);
      const typeDocument = docFilter.filenameInBucket.split(".").pop();
      if (typeDocument !== "pdf") {
        setIsOpenModalTypeDocument(true);
        return;
      }
    }
    setIsModalRequest(true);
  };
  const handleClickDownload = async ({ filenameInBucket, bucketName }) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(filenameInBucket, bucketName, false);
      const bf = file.data.responseMessage.buffer.data;
      const blob = new Blob([new Uint8Array(bf).buffer]);
      let fileName = file.data.responseMessage.filename;
      const contentType =
        fileName.split(".").length === 1 &&
        file.data.responseMessage.contentType;
      if (
        contentType &&
        contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        fileName = fileName + ".docx";
      } else if (contentType && contentType === "application/pdf") {
        fileName = fileName + ".pdf";
      }
      saveAs(blob, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearchName = () => {
    if (documentList) {
      for (const document of documentList.documentPrincipal) {
        if (document._id === getValues("documentId")) {
          return document.originalFilename;
        }
      }
      for (const document of documentList.documentsAttachments) {
        if (document._id === getValues("documentId")) {
          return document.originalFilename;
        }
      }
    }
  };
  const handleRequestApprovalAgain = async () => {
    try {
      setIsLoading(true);
      setIsModalRequest(false);
      setIsOpenModalTypeDocument(false);
      const user = JSON.parse(localStorage.getItem("payloadToken"));
        
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");

      let userName = "";
      if (user?.personType === "Juridica") {
        userName = user?.companyName || "";
      } else {
        userName = `${user?.firstName || ""} ${user?.secondName || ""} ${
          user?.firstSurname || ""
        } ${user?.secondSurname || ""}`;
      }
      const userRole = localStorage.getItem("role");
      const updateApproval = {
        associatedDocument: dataForm.documentId,
        state: APPROVAL_STATE.PENDING._id,
      };
      const requestApprovalAgain = await sendApprovalAgain({
        consecutive,
        approvalId,
        updateApproval,
        companyId,
        corporateUnit
      });
      setResponseData(requestApprovalAgain);
      setIsOpenModal(true);
      setIsModalRequest(false);
      setIsOpenModalTypeDocument(false);
      if (
        requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        const approvalComment = {
          userName: userName,
          aplicantName:
            requestApprovalAgain.data.responseMessage.data.aplicantName,
          approverName: dataForm.name,
          userPosition: userRole === "EXTERNAL" ? "Solicitante" : "Abogado",
          message: dataForm.message,
          associatedDocument: dataForm.documentId,
          isCommentOnly: false,
          isSendMail: true,
        };
        const requestCreateCommentApproval = await addCommentApproval({
          consecutive,
          approvalId: requestApprovalAgain.data.responseMessage.data._id,
          approvalComment,
          isDiscussionComment: true,
          companyId,
          corporateUnit
        });
        if (
          requestCreateCommentApproval.status === CODES.COD_RESPONSE_HTTP_OK ||
          requestCreateCommentApproval.status ===
            CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          navigate(-1);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRequestApprovalAgainFromPC = async () => {
    try {
      setIsLoading(true);
      setIsModalRequest(false);
      setIsOpenModalTypeDocument(false);
      const user = JSON.parse(localStorage.getItem("payloadToken"));
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      
      let userName = "";
      if (user?.personType === "Juridica") {
        userName = user?.companyName || "";
      } else {
        userName = `${user?.firstName || ""} ${user?.secondName || ""} ${
          user?.firstSurname || ""
        } ${user?.secondSurname || ""}`;
      }
      const userRole = localStorage.getItem("role");
      const requestUploadDocs = await uploadDocumentForApproval(
        companyId,
        approvalId,
        consecutive,
        documentData,
        userName,
        approvalData?.approverName,
        "Aprobación",
      );
      if (
        requestUploadDocs.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestUploadDocs.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        const updateApproval = {
          associatedDocument: requestUploadDocs.data.responseMessage.data,
          state: APPROVAL_STATE.PENDING._id,
        };
        const requestApprovalAgain = await sendApprovalAgain({
          consecutive,
          approvalId,
          updateApproval,
          companyId
        });
        setResponseData(requestApprovalAgain);
        setIsOpenModal(true);
        setIsModalRequest(false);
        setIsOpenModalTypeDocument(false);
        if (
          requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_OK ||
          requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          const approvalComment = {
            userName: userName,
            aplicantName:
              requestApprovalAgain.data.responseMessage.data.aplicantName,
            approverName:
              requestApprovalAgain.data.responseMessage.data.approverName,
            userPosition: userRole === "EXTERNAL" ? "Solicitante" : "Abogado",
            message: dataForm.message,
            associatedDocument: requestUploadDocs?.data?.responseMessage?.data,
            isCommentOnly: false,
            isSendMail: true,
          };
          const requestCreateCommentApproval = await addCommentApproval({
            consecutive,
            approvalId: requestApprovalAgain.data.responseMessage.data._id,
            approvalComment,
            isDiscussionComment: true,
            companyId,
            corporateUnit
          });
          if (
            requestCreateCommentApproval.status ===
              CODES.COD_RESPONSE_HTTP_OK ||
            requestCreateCommentApproval.status ===
              CODES.COD_RESPONSE_HTTP_CREATED
          ) {
            setResponseData(requestApprovalAgain);
            setIsOpenModal(true);
            setIsModalRequest(false);
            navigate(-1);
          }
        }
      } else {
        setResponseData(requestUploadDocs);
        setIsOpenModal(true);
        setIsModalRequest(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //UseEffect
  useEffect(() => {
    const handleRequestDocument = async () => {
      try {
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const company = localStorage.getItem("company");

        const obj = {
          consecutive,
          company,
          corporateUnit,
        };
        const GetDataRequest = await findOneDataExternalRequest(obj);
        if (GetDataRequest.status) {
          if (GetDataRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
            const data = GetDataRequest.data.responseMessage;
            const dataFilter = {
              _id: data._id,
              stateRequest: data.requestStatus.name,
              color:
                data.requestStatus.colorId === 100
                  ? "#FFD7B1"
                  : data.requestStatus.colorId === 300
                  ? "#CCF4E3"
                  : data.requestStatus.colorId === 400
                  ? "#F9DADE"
                  : data.requestStatus.colorId === 200
                  ? "#E6E9EF"
                  : "light",
              applicant:
                data.applicant.firstName + " " + data.applicant.firstSurname,
              applicantId: data.applicant._id,
              attorneyInCharge:
                data.attorneyInCharge.firstName +
                " " +
                data.attorneyInCharge.firstSurname,
              requestSubject: data.requestSubject,
              requestType: data.requestType,
              requestSubType: data.requestSubType?.name,
              responseLimitDate: data.responseLimitDate,
              requestDetails: data.requestDetails,
              documentsOtherAttorney: data.documents.attorneyDocuments,
              documentsAttachments: data.documents.attorneyDocuments.concat(
                data.documents.applicantDocuments
              ),
              documentsOtherApplicant: data.documents.applicantDocuments,
              documentPrincipal: data?.documents?.mainDocuments.filter(
                (doc) =>
                  !(
                    !doc.isRejected &&
                    !validateCompleteSigns(data.signatures, doc) &&
                    doc.subtype === "Signature"
                  )
              ),
              comments: data.comments,
            };
            setDocumentsList(dataFilter);
            setDataTableDocumentPrincipal({
              columns: [
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Seleccionar documento
                    </label>,
                  ],
                  field: "select",
                },
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Seguimiento
                    </label>,
                  ],
                  field: "followup",
                },
                {
                  label: [
                    <label aria-hidden="true" key="2">
                      Descripción
                    </label>,
                  ],
                  field: "description",
                },
                {
                  label: [
                    <label aria-hidden="true" key="3">
                      Creado por
                    </label>,
                  ],
                  field: "createdBy",
                },
                {
                  label: [
                    <label aria-hidden="true" key="4">
                      Dirigido a
                    </label>,
                  ],
                  field: "assignedTo",
                },
                {
                  label: [
                    <label aria-hidden="true" key="5">
                      Fecha de creación
                    </label>,
                  ],
                  field: "createdAt",
                },
                {
                  label: [
                    <label aria-hidden="true" key="6">
                      Documento Adjunto
                    </label>,
                  ],
                  field: "originalFilename",
                },
              ],

              rows: dataFilter?.documentPrincipal
                .filter((document) => {
                  return !document.isRejected;
                })
                .reverse()
                .map((filterDocument) => {
                  return {
                    select: (
                      <input
                        {...register("documentId", { required: true })}
                        type="radio"
                        name="documentId"
                        value={filterDocument._id}
                        style={{
                          margin: "30px",
                        }}
                      />
                    ),
                    description: filterDocument.description,
                    createdAt: dateNormal(filterDocument.createdAt),
                    createdBy: filterDocument.createdBy,
                    assignedTo: filterDocument.assignedTo,
                    followup: filterDocument.isSignatureFinish ? (
                      <label className="caption">
                        {filterDocument.tracking}
                      </label>
                    ) : !filterDocument.isSignatureFinish &&
                      filterDocument.userRole === "EXTERNAL" ? (
                      <label className="caption">
                        {"#" +
                          filterDocument.consecutive +
                          " Respuesta Solicitante"}
                      </label>
                    ) : !filterDocument.isSignatureFinish &&
                      filterDocument.userRole === "SERVICE" ? (
                      <label className="caption">
                        {"#" +
                          filterDocument.consecutive +
                          " Respuesta Abogado"}
                      </label>
                    ) : (
                      <label className="caption">
                        Documento adjunto aprobación
                      </label>
                    ),
                    originalFilename: (
                      <label
                        onClick={() => {
                          handleClickDownload(filterDocument);
                        }}
                        style={{
                          color: "#17B4BC",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          borderRadius: "0.8rem",
                          padding: "0.4rem",
                        }}
                      >
                        {filterDocument.originalFilename}
                      </label>
                    ),
                  };
                }),
            });

            setDataTableRequiredDocs({
              columns: [
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Selecionar documento
                    </label>,
                  ],
                  field: "select",
                },
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Documento anexo
                    </label>,
                  ],
                  field: "requiredDoc",
                },
                {
                  label: [
                    <label aria-hidden="true" key="6">
                      Documento Adjunto
                    </label>,
                  ],
                  field: "originalFilename",
                },
              ],
              rows: dataFilter?.documentsAttachments.map((document) => {
                if (!document.filenameInBucket) {
                  return <></>;
                }
                return {
                  select: (
                    <input
                      {...register("documentId", { required: true })}
                      type="radio"
                      name="documentId"
                      value={document._id}
                      style={{
                        margin: "30px",
                      }}
                    />
                  ),
                  requiredDoc: document.originalFilename,
                  originalFilename: (
                    <label
                      onClick={() => {
                        handleClickDownload(document);
                      }}
                      style={{
                        color: "#17B4BC",
                        cursor: "pointer",
                        fontSize: "1.5rem",
                        borderRadius: "0.8rem",
                        padding: "0.4rem",
                      }}
                    >
                      {document.originalFilename}
                    </label>
                  ),
                };
              }),
            });
            setRequestCommentaries({
              _id: GetDataRequest.data.responseMessage._id,
              comments: GetDataRequest.data.responseMessage.comments,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleRequestDocument();
  }, []);
  useEffect(() => {
    const requestApprovalById = async () => {
      const requestApprovalByIdResponse = await getApprovalById({ approvalId });
      if (
        requestApprovalByIdResponse.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestApprovalByIdResponse.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        setApprovalData(requestApprovalByIdResponse.data.responseMessage.data);
      }
    };
    requestApprovalById();
  }, []);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <Col xs={3} className="col-start">
          <SectionTitle title={`Cargar documento principal`} />
        </Col>
        <Col xs={6}></Col>
        <Col className="col-flex" xs={2}>
          <Button
            variant="contained"
            startIcon={<ArticleOutlinedIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={() => setIsHideDiscussion(false)}
          >
            Mensajes
          </Button>
        </Col>
      </Row>
      <div className="custom-accordion__container-document">
        <Row className="heading" style={{ margin: "2% 0" }}>
          Datos del aprobador
        </Row>
        <Row style={{ margin: "2% 0" }}>
          <Col xs={2} className="subheading">
            Aprobador:
          </Col>
          <Col className="caption">{approvalData.approverName}</Col>
        </Row>
        <Row style={{ margin: "2% 0" }}>
          <Col xs={2} className="subheading">
            Cargo:
          </Col>
          <Col className="caption">{approvalData.approverPosition}</Col>
        </Row>
        <Form onSubmit={handleSubmit(handlePreRequest)}>
          <Row className="heading" style={{ margin: "1% 0" }}>
            Solicitar
          </Row>
          <Row className="caption" style={{ margin: "1% 0" }}>
            Escriba un mensaje para enviar al aprobador, junto con el archivo
            adjunto:
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("message")}
                  as="textarea"
                  placeholder="Cordial Saludo...*"
                  bsPrefix={
                    errors.message
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.message?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          {type === "upload" ? (
            <>
              <Row className="heading" style={{ margin: "0.5% 0" }}>
                Cargar documento
              </Row>
              <Row style={{ padding: "2% 0" }}>
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      setOpenModalDoc(true);
                    }}
                  >
                    Cargar nuevo documento
                  </Button>
                </Col>
                <div
                  className="caption custom-input__error"
                >
                  {errors?.file?.message}
                </div>
                {documentData.name && (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span className="caption custom-badges__disabled">
                      {`${documentData.name} `}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => {
                          setValue("file", null);
                          setDocumentData({});
                        }}
                      />
                    </span>
                  </Col>
                )}
              </Row>
            </>
          ) : (
            <>
              <Row className="caption" style={{ margin: "1% 0" }}>
                Elija el documento del cual solicita aprobación:
              </Row>
              <Row>
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.documentId?.message}
                </div>
                <Row className="data-table dt-list-items">
                  <MDBDataTableV5
                    noRecordsFoundLabel="No se encontraron resultados"
                    hover
                    data={dataTableDocumentPrincipal}
                    infoLabel={["Mostrando", "a", "de", "documentos"]}
                    entries={5}
                    pagesAmount={4}
                    searchBottom={false}
                    pagingTop
                  />
                </Row>
              </Row>
            </>
          )}
          <Row className="justify-content-md-end">
            <Col xs={2}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                size="medium"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Descartar
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
                size="medium"
              >
                Solicitar aprobación
              </Button>
            </Col>
          </Row>
          <br />
        </Form>
      </div>
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setOpenModalDoc(false);
        }}
        onClose={() => {
          setOpenModalDoc(false);
        }}
        open={openModalDoc}
        documentData={documentData}
        setDocumentData={(document) => {
          if (!document?.name || !document?.filename) return;
          setValue("file", document);
          clearErrors("file");
          setDocumentData(document);
        }}
      />
      <ModalDecision
        title={
          type === "upload"
            ? "Subir el documento para solicitar su aprobación"
            : "Confirmar solicitud de aprobación"
        }
        message={
          type === "upload"
            ? "Tu solicitud de aprobación se actualizará con el documento cargado."
            : `¿Estás seguro que quieres pedir la aprobación del documento ${handleSearchName()}?`
        }
        agreeText={type === "upload" ? "Continuar" : "Solicitar aprobación"}
        disagreeText={"Cancelar"}
        handleAgree={
          type === "upload"
            ? handleRequestApprovalAgainFromPC
            : handleRequestApprovalAgain
        }
        handleDisagree={() => {
          setIsModalRequest(false);
        }}
        open={isModalRequest}
        onClose={setIsModalRequest}
      />
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={requestCommentaries.comments}
        id={requestCommentaries._id}
        setIsNewComment={setIsNewComment}
      />
      <ModalDecision
        title={
          <Row
            style={{ alignItems: "center" }}
            className="heading__warning-color"
          >
            <Col>
              <WarningAmberOutlinedIcon fontSize="large" />
            </Col>
            <Col>
              <span>Aprobaciones</span>
            </Col>
          </Row>
        }
        message={`El documento es un formato diferente a .PDF por lo que podrás consultar si fue aprobado en la plataforma. Si quieres ver la marca de aprobación en el documento cárgalo en PDF. ¿Seguro quieres continuar?`}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        handleAgree={
          type === "upload"
            ? handleRequestApprovalAgainFromPC
            : handleRequestApprovalAgain
        }
        handleDisagree={() => {
          setIsOpenModalTypeDocument(false);
        }}
        open={isOpenModalTypeDocument}
        onClose={() => setIsOpenModalTypeDocument(false)}
      />
    </Container>
  );
};

export default RequestApprovalAgain;
