import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../parts/breadcrum";
import SectionTitle from "../../../../../components/sectionTitle";
import AddIcon from "@mui/icons-material/Add";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import CardButtonWithNavButton from "../../../../../components/Cards/CardButtonWithNavButton";
import {
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../parts/headings/headingsSlice";
import { useDispatch } from "react-redux";
import { cleanStore } from "../../../../../parts/document/currentDocumentSlice";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
const CreateService = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedHeaderId(null));
    dispatch(setSelectedHeader(null));
    dispatch(cleanStore());
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row>
        <Breadcrum title="" />
      </Row>
      <Row style={{ alignContent: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title="Crear" />
        </Col>
      </Row>
      <Row style={{ height: "6%" }} className="text-description">
        ¿Qué tipo de documento quieres crear?
      </Row>
      <Row style={{ height: "40%" }}>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<DescriptionOutlinedIcon className="cards__icon-svg" />}
            title="Contrato"
            description="Crear un contrato desde minutas o desde una biblioteca de cláusulas."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="contract"
            mixPanelTrack="Crear desde contrato"
          />
        </Col>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<LocalLibraryOutlinedIcon className="cards__icon-svg" />}
            title="Otro Documento"
            description="Crea un documento diferente a un contrato desde una biblioteca de plantillas o bloques."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="other"
            mixPanelTrack="Crear desde otro documento "
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateService;
