import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CODES } from "../../utils/codes";
import { getFolderById, getFoldersByCompany } from "../../services/business_folder/businessFolderService";

export const foldersSlice = createSlice({
  name: "folder",
  initialState: {
    selectedFolder: {},
    folders: [],
    folderStatus: "fetch",
    foldersStatus: "fetch",
    error: null,
  },
  reducers: {
    addNewFolder(state, action) {
      const { folder } = action.payload;
      const folders = state.folders;
      folders.unshift(folder);
      state.folders = folders;
    },

    addDocumentToFolder(state, action) {
      const { destination, document, origin } = action.payload;
      //Para folder destino
      const folders = state.folders;
      const arrayId = folders.map((item) => {
        return item._id;
      });
      const realIndex = arrayId.indexOf(destination);
      const newDocumentArray = folders[realIndex].documents;
      newDocumentArray.unshift(document);
      const finalFolder = {
        ...folders[realIndex],
        documents: newDocumentArray,
      };
      state.folders[realIndex] = finalFolder;

      //Cambio en folder origen
      const realIndexOrigin = arrayId.indexOf(origin);
      const documents = state.folders[realIndexOrigin];
      const filtered = documents.documents.filter(
        (element) => element._id !== document._id
      );
      const finalFolderOrigin = {
        ...folders[realIndexOrigin],
        documents: filtered,
      };
      state.folders[realIndexOrigin] = finalFolderOrigin;
    },
    setFoldersStatus(state, action) {
      const { newStatus } = action.payload;
      state.foldersStatus = newStatus;
    },
    setFolderStatus(state, action) {
      const { newStatus } = action.payload;
      state.folderStatus = newStatus;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFolderById.pending, (state, action) => {
        state.folderStatus = "loading";
      })
      .addCase(fetchFolderById.fulfilled, (state, action) => {
        state.folderStatus = "succeeded";
        // Add any fetched posts to the array
        state.selectedFolder = action.payload;
      })
      .addCase(fetchFolderById.rejected, (state, action) => {
        state.folderStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllFolders.pending, (state, action) => {
        state.foldersStatus = "loading";
      })
      .addCase(fetchAllFolders.fulfilled, (state, action) => {
        state.foldersStatus = "succeeded";
        // Add any fetched posts to the array
        state.folders = action.payload;
      })
      .addCase(fetchAllFolders.rejected, (state, action) => {
        state.foldersStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const getFolder = (state) => state.folders.selectedFolder;

export const getStatus = (state) => state.folders.folderStatus;

export const getFolders = (state) => state.folders.folders;

export const getStatusFolders = (state) => state.folders.foldersStatus;

export const getStatusFolder = (state) => state.folders.folderStatus;

export const { addNewFolder, addDocumentToFolder, setFoldersStatus, setFolderStatus } = foldersSlice.actions;

export const fetchFolderById = createAsyncThunk(
  "folder/fetchFolderById",
  async (id) => {
    const companyId = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const response = await getFolderById({companyId, id, corporateUnit});
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return response.data.responseMessage;
    }
  }
);

export const fetchAllFolders = createAsyncThunk("folder/fetchAll", async () => {
  const companyId = localStorage.getItem("company");
  const corporateUnit = localStorage.getItem("corporateUnitId");
  const response = await getFoldersByCompany({companyId,corporateUnit});
  if (response.status === CODES.COD_RESPONSE_HTTP_OK && response.data.success) {
    return response.data.responseMessage;
  }
});

export default foldersSlice.reducer;
