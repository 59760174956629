import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HelpButton = () => {
  const navigate = useNavigate();
  return (
    <Row>
      <button
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
          border: "none",
          backgroundColor: "#067BBD",
          position: "absolute",
          right: "2.5%",
          bottom: "2.5%",
        }}
        onClick={() => navigate("/admin/help")}
      >
        <FontAwesomeIcon
          size="lg"
          icon={faMessage}
          style={{
            color: "white",
            width: "24px",
            height: "24px",
            paddingTop: "3px",
          }}
        />
      </button>
    </Row>
  );
};

export default HelpButton;
