import axios from "axios";
import GetToken from "../../../utils/getToken";
import { env } from "../../../env";

export function findAllRulesByCompany(company) {
  return axios({
    method: "GET",
    url: `review-rules/find-all`,
    params: { company },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export function updateRules(data) {
  return axios({
    method: "PUT",
    url: `review-rules/update-many`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: { reviewRules: data },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export function findRulesByCorporateUnit(corporateUnit) {
  return axios({
    method: "GET",
    url: `review-rules/find-all`,
    params: {
      activeInCorporateUnits: [corporateUnit],
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export function getDocumentReviewRule({ documentId, company, file, type }) {
  const formData = new FormData();
  formData.append("documentId", documentId);
  formData.append("company", company);
  formData.append("file", file);
  formData.append("type", type);
  return axios({
    method: "POST",
    url: `review-rules/reviewOtherDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: formData,
    headers: {
      Authorization: GetToken(),
    },
  });
}
