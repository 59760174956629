import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMinutas } from "../../services/minutas/MinutasRequest";
import { CODES } from "../../utils/codes";

export const minutasSlice=createSlice({
    name: 'minutas',
    initialState:{
        minutasList:[],
        status:'fetch',
        error:'null'
    },
    reducers:{},
    extraReducers(builder) {
        builder
          .addCase(fetchMinutas.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(fetchMinutas.fulfilled, (state, action) => {
            state.status = "succeeded";
            // Add any fetched posts to the array
            state.minutasList = state.minutasList.concat(action.payload);
          })
          .addCase(fetchMinutas.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
          });
      },
});

export const getMinutasList = (state) => state.minutas.minutasList;

export const getStatus = (state) => state.minutas.status;


export const fetchMinutas = createAsyncThunk(
    "minutas/fetchMinutas",
    async () => {
      const response = await getAllMinutas();
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return response.data.responseMessage;
      }
    }
  );
  
  export default minutasSlice.reducer;