import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../utils/mixPanel";
const CardInformative = ({
  number,
  description,
  icon,
  size,
  color = "#00374f",
  navigateTo = "",
  filterBy = "",
  mixPanelTrack,
}) => {
  const navigate = useNavigate();
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleClick = (event) => {
    if (mixPanelTrack) {
      Mixpanel.track("Btn Inicio", {
        homeButton: mixPanelTrack,
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    } else {
      Mixpanel.track("Btn Inicio", {
        homeButton: number,
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    }

    if (size === "medium") {
      navigate(`/service/${navigateTo}`);
    }
  };

  return (
    <Container
      fluid
      className={`informative-cards__${size}`}
      style={{ margin: "10px" }}
    >
      <Row
        style={{ height: "inherit" }}
        data-testid="card-button"
        onClick={handleClick}
      >
        {size === "medium" ? (
          <Col md={2} className={`informative-cards__${size}__icon-section`}>
            {icon}
          </Col>
        ) : (
          <Col md={4}>
            <Container
              className={`informative-cards__${size}__icon-section`}
              style={{ backgroundColor: color }}
              onClick={() =>
                navigate(`/service/${navigateTo}/?filter=${filterBy}`)
              }
            >
              {icon}
            </Container>
          </Col>
        )}

        <Col className="informative-cards__body-section">
          <Container fluid>
            <Row>
              <p
                className={
                  size === "medium"
                    ? "heading__primary-color"
                    : "informative-cards__small__title"
                }
              >
                {number}
              </p>
            </Row>
            <Row>
              <p
                className={
                  size === "medium" ? "caption__justify" : "caption__disabled"
                }
              >
                {description}
              </p>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default CardInformative;
