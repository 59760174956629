import { Paper } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const MetricCountPercentage = ({ title, itemList }) => {
  return (
    <Paper elevation={1} style={{ padding: "2% 1%", height: "100%"}}>
      <Container fluid>
        <Row className="display-small">{title}</Row>
        <Row style={{ margin: "1% 0" }}>
          {itemList?.map((item) => (
            <Col xs={2} key={item?.title}>
              <Paper elevation={3} style={{ padding: "8% 5%", height: "100%"  }}>
                <Container fluid>
                  {item?.specialComponent ? (
                    item?.specialComponent
                  ) : (
                    <>
                      <Row>
                        <Col
                          className={
                            item?.classNameColorText
                              ? `display-large${item?.classNameColorText}`
                              : "display-large"
                          }
                          xs={item?.countTotal ? 8 : 12}
                        >
                          {item?.percentage}
                        </Col>
                        {item?.countTotal && (
                          <Col
                            xs={3}
                            className={
                              item?.classNameColorText
                                ? `subheading${item?.classNameColorText}`
                                : "subheading"
                            }
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item?.countTotal}
                          </Col>
                        )}
                      </Row>
                      {item?.title && (
                        <Row
                          className={
                            item?.classNameColorText
                              ? `button${item?.classNameColorText}`
                              : "button"
                          }
                        >
                          {item?.title}
                        </Row>
                      )}
                      <Row
                        className="caption"
                        style={
                          item?.title ? { height: "3rem" } : { height: "5rem" }
                        }
                      >
                        {item?.description}
                      </Row>
                    </>
                  )}
                </Container>
              </Paper>
            </Col>
          ))}
        </Row>
      </Container>
    </Paper>
  );
};

export default MetricCountPercentage;
