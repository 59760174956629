import React, { useState, useRef, useEffect, createRef } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SortIcon from "@mui/icons-material/Sort";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getCurrentDocument,
  setHeader,
} from "../../../../../../parts/document/currentDocumentSlice";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RichEditorText from "../../../../../../parts/RichEditorText";
import { REGEXP } from "../../../../../../utils/regexp";
import BlockerPage from "../../../../../../components/blocker/blocker";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AddHeader = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const richEditorTextRef = useRef();
  const richSubHeaderEditorTextRef = useRef([]);
  const currentDocument = useSelector(getCurrentDocument);

  const schema = yup.object().shape({
    title: yup.string().required("*Este campo es obligatorio"),
    text: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.RICH_EDITOR_CONTENT, {
        message: "*Este campo no puede estar vacío",
      }),
    childs: yup.array().of(
      yup.object().shape({
        text: yup.string(),
      })
    ),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    fields: childFields,
    append: childAppend,
    remove: childRemove,
    move: childMove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "childs", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const [blockNavigate, setBlockNavigate] = useState(true);
  const [indexEnableRol, setIndexEnableRol] = useState(null);
  //handle
  const handleDeleteText = () => {
    setValue("text", "");
    richEditorTextRef.current.setEditorContents("");
  };
  const handleAddHeader = (data) => {
    setBlockNavigate(false);
    dispatch(setHeader(data));
    navigate(-1);
  };

  //UseEffect

  useEffect(() => {
    if (childFields.length) {
      setIndexEnableRol(null);
      richSubHeaderEditorTextRef.current.map((item) =>
        item?.current?.setBlur()
      );
    }
  }, [childFields]);

  return (
    <Container fluid className="custom-container-scroll">
      <BlockerPage
        blockNavigate={blockNavigate}
        message={
          "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
        }
      />
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={"auto"} className="col-start">
          <SectionTitle title="Antecedentes o consideraciones" />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(handleAddHeader)}>
        <Row className="heading">Título del encabezado</Row>
        <Row style={{ margin: "2% 0", rowGap: "10px" }}>
          <Col md={5}>
            <Form.Group className="mb-3">
              <Form.Control
                {...register("title")}
                placeholder="Ej. Antecedentes"
                bsPrefix={
                  errors.title
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.title?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row
          className="heading"
          style={{ marginBottom: "2%", rowGap: "10px", alignItems: "center" }}
        >
          <Col xs={"auto"}>Texto del encabezado</Col>
          {currentDocument?.parts?.length > 0 &&
            currentDocument.parts.map((part, index) => (
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    richEditorTextRef.current.addPart(part?.name, index);
                  }}
                >
                  {part?.name || ""}
                </Button>
              </Col>
            ))}
        </Row>
        <Row style={{ margin: "2% 0" }}>
          <Col md={11}>
            <Controller
              control={control}
              name="text"
              render={({ field: { onChange, onBlur } }) => (
                <RichEditorText
                  ref={richEditorTextRef}
                  onBlur={onBlur}
                  onChange={onChange}
                  customClassName={
                    errors.text
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  placeholder={"Agrega aquí el texto del encabezado"}
                />
              )}
            />
            <div className="caption custom-input__error">
              {errors.text?.message}
            </div>
          </Col>
          <Col md={1}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleDeleteText}
            >
              <DeleteOutlinedIcon fontSize="large" />
            </Button>
          </Col>
        </Row>
        <DragDropContext
          onDragEnd={(result) => {
            const { source, destination } = result;
            childMove(source.index, destination.index);
          }}
        >
          <Droppable droppableId={"droppable-headers"}>
            {(droppableProvided) => (
              <Col
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {childFields?.length > 0 &&
                  childFields.map((child, index) => (
                    <Draggable
                      key={`${child.id}-draggable`}
                      draggableId={`${child.id}-draggable`}
                      index={index}
                    >
                      {(draggablePorvider) => (
                        <div
                          {...draggablePorvider.draggableProps}
                          {...draggablePorvider.dragHandleProps}
                          ref={draggablePorvider.innerRef}
                        >
                          <Container
                            fluid
                            style={{ padding: "0px 44px 0px 44px" }}
                            key={child.id}
                          >
                            <Row
                              className="heading"
                              style={{ margin: "1% 0", alignItems: "center" }}
                            >
                              <Col xs="auto">
                                <DragIndicatorIcon
                                  fontSize="large"
                                  className="drag-color"
                                />
                              </Col>
                              <Col xs="auto">
                                <span className="heading">
                                  {`${index + 1}.`}
                                </span>
                              </Col>
                              {currentDocument?.parts?.length > 0 &&
                                currentDocument.parts.map((part, indexPart) => (
                                  <Col xs={"auto"} key={part?._id}>
                                    <Button
                                      disabled={indexEnableRol !== child.id}
                                      variant="contained"
                                      className="custom-input__button__secondary-color"
                                      onClick={() => {
                                        richSubHeaderEditorTextRef.current[
                                          index
                                        ].current.addPart(
                                          part?.name,
                                          indexPart
                                        );
                                      }}
                                    >
                                      {part?.name || ""}
                                    </Button>
                                  </Col>
                                ))}
                            </Row>
                            <Row>
                              <Col md={11}>
                                <Controller
                                  control={control}
                                  name={`childs.${index}.text`}
                                  render={({ field: { onChange, onBlur } }) => (
                                    <RichEditorText
                                      ref={
                                        richSubHeaderEditorTextRef.current[
                                          index
                                        ]
                                      }
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      onFocus={() =>
                                        setIndexEnableRol(child.id)
                                      }
                                      customClassName={
                                        "input-group-container__no-icon label"
                                      }
                                      placeholder={
                                        "Agrega aquí el texto del numeral"
                                      }
                                    />
                                  )}
                                />
                                <div className="caption custom-input__error">
                                  {errors?.childs}
                                </div>
                              </Col>
                              <Col md={1}>
                                <Button
                                  variant="contained"
                                  className="custom-input__button__primary-color"
                                  onClick={() => {
                                    richSubHeaderEditorTextRef.current[
                                      index
                                    ].current.removeImportedNumeral();
                                    childRemove(index);
                                  }}
                                >
                                  <DeleteOutlinedIcon fontSize="large" />
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {droppableProvided.placeholder}
              </Col>
            )}
          </Droppable>
        </DragDropContext>
        {!location.pathname.includes("add-header-without") && (
          <Row className="justify-content-md-end" style={{ margin: "1% 0" }}>
            <Col md={2}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                startIcon={<SortIcon />}
                // onClick={addChild}
                onClick={() => {
                  let item = {
                    text: "",
                    isImported: true,
                  };
                  const index = childFields.length;
                  richSubHeaderEditorTextRef.current[index] = createRef();
                  childAppend(item);
                }}
              >
                Agregar nivel
              </Button>
            </Col>
          </Row>
        )}
        <br />
        <br />
        <br />
        <Row className="sidebar__bottom__container">
          <Col md={1}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => {
                navigate(-1);
              }}
            >
              Descartar
            </Button>
          </Col>
          <Col md={1}>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
              // onClick={handleAddHeader}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddHeader;
