import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RegisterBar from "../../parts/RegisterBar";
import RegisterPhase1 from "../../parts/RegisterPhases/Phase1";
import RegisterPhase2 from "../../parts/RegisterPhases/Phase2";
import RegisterPhase3 from "../../parts/RegisterPhases/Phase3";
import { CreateAdminUser } from "../../services/general/authServices";
import { CODES } from "../../utils/codes";
import ModalInfo from "../../components/Modals/modalInfo";
import { Outlet, useNavigate } from "react-router-dom";
import { Mixpanel } from "../../utils/mixPanel";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const RegisterUser = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [responseDataError, setResponseDataError] = useState({});
  const [listPhases, setListPhases] = useState([
    { text: "Tipo de persona", check: true },
    { text: "Nit / Doc. de identidad", check: false },
    { text: "Datos personales", check: false },
  ]);
  const [userData, setUserData] = useState({});
  const [selectPhase, setSelectPhase] = useState();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const actualPhase = listPhases.find((phase) => phase.check === false);

    if (actualPhase) {
      if (actualPhase.text === "Tipo de persona") {
        setSelectPhase(
          <RegisterPhase1
            handleGeneralPhases={handleGeneralPhases}
            setUserData={setUserData}
          />
        );
      } else if (actualPhase.text === "Nit / Doc. de identidad") {
        setSelectPhase(
          <RegisterPhase2
            userData={userData}
            handleGeneralPhases={handleGeneralPhases}
            setUserData={setUserData}
          />
        );
      }
    } else if (listPhases[2].finish) {
      setSelectPhase(
        <RegisterPhase3
          handleGeneralPhases={handleGeneralPhases}
          setUserData={setUserData}
        />
      );
      handleRegister(userData);
      Mixpanel.track("Btn Crear Usuario", {
        personType: userData.personType,
        documentType: userData.DocumentType
          ? userData.DocumentType
          : userData.DocumentType2,
        firstName: userData.FirstName,
        secondName: userData.SecondName,
        firstSurname: userData.LastName,
        secondSurname: userData.SurName,
        documentNumber: userData.NumberDocument,
      });
    }
  }, [userData]);

  const handleRegister = async (data) => {
    try {
      const captchaToken = await executeRecaptcha("Register");
      const {
        personType,
        email,
        password,
        phoneNumber,
        firstName,
        secondName,
        firstSurname,
        secondSurname,
        documentType,
        documentNumber,
        companyName,
        nit,
        verificationDigit,
        extension,
        landlineNumber,
        countryCode,
      } = data;
      let obj = {};
      if (personType === "Natural") {
        obj = {
          userData: {
            personType: personType,
            email: email,
            password: password,
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            extension: extension ?? "",
            landlineNumber: landlineNumber ?? "",
            firstName: firstName,
            secondName: secondName,
            firstSurname: firstSurname,
            secondSurname: secondSurname,
            documentType: documentType,
            documentNumber: documentNumber,
            permissions: [],
            role: "ADMIN",
            subRole: "ADMIN PROPIETARIO",
          },
          corporateUnits: [],
          token: captchaToken,
        };
      } else if (personType === "Juridica") {
        obj = {
          userData: {
            personType: personType,
            email: email,
            password: password,
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            documentType: "OTRO",
            documentNumber: nit,
            extension: extension ?? "",
            landlineNumber: landlineNumber ?? "",
            permissions: [],
            role: "ADMIN",
            subRole: "ADMIN PROPIETARIO",
          },
          companyData: {
            companyName: companyName,
            nit: nit,
            verificationDigit: verificationDigit,
          },
          corporateUnits: [],
          token: captchaToken,
        };
      }
      const createAdminUserServiceResponse = await CreateAdminUser(obj);
      if (createAdminUserServiceResponse) {
        if (
          createAdminUserServiceResponse.status ===
          CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          Mixpanel.track("Usuario creado exitosamente", {
            personType: userData.personType,
            documentType: userData.DocumentType
              ? userData.DocumentType
              : userData.DocumentType2,
            firstName: userData.FirstName,
            secondName: userData.SecondName,
            firstSurname: userData.LastName,
            secondSurname: userData.SurName,
            documentNumber: userData.NumberDocument,
          });
          setResponseData(createAdminUserServiceResponse);
          setIsOpenModal(true);
        } else {
          setResponseDataError(createAdminUserServiceResponse);
          setIsOpenModalError(true);
        }
      }
    } catch (error) {
      console.log("============== Handle Error Register ==============");
      console.log(error);
      console.log("============== Finish Handle Error Register ==============");
    }
  };

  const handleGeneralPhases = (index, check, finish) => {
    setListPhases((prev) => {
      const newList = [...prev];
      if (index === 2) {
        newList[index] = { ...prev[index], check: check, finish: finish };
      } else {
        newList[index] = { ...prev[index], check: check };
      }

      return newList;
    });
  };

  const handleClose = () => {
    setIsOpenModal(false);
    navigate("/login/admin");
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      await executeRecaptcha("Register");
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  return (
    <Container fluid>
      <Row style={{ height: "inherit" }}>
        <Col
          md={2}
          className="background-register-bar"
          style={{ height: "inherit" }}
        >
          <RegisterBar listPhases={listPhases} />
        </Col>
        <Col>
          <Outlet context={{ userData, handleGeneralPhases, setUserData }} />
        </Col>
      </Row>
      <ModalInfo
        title={"Registro de usuario"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
      <ModalInfo
        title={"Error registro de usuario"}
        responseData={responseDataError}
        open={isOpenModalError}
        onClose={() => setIsOpenModalError(false)}
      />
    </Container>
  );
};

export default RegisterUser;
