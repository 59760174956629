import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import dateFormat from "dateformat";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import LoadingContent from "../../../../../../../components/loadingContent";
import { reportPartsCreator } from "../../../../../../../components/WordCreator/reportMilestonesDocx";
import {
  getCurrentDocument,
  getReports,
} from "../../../../../../../parts/document/currentDocumentSlice";
import { convertToPdfService } from "../../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../../utils/codes";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { reportIAResult } from "../../..../../../../../../../services/admin/manageUserService";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { Mixpanel } from "../../../../../../../utils/mixPanel";

export const ReportsParts = ({ type }) => {
  const MENU_FILTER_BY = [
    {
      title: "Hitos relacionados con el plazo del contrato",
      selectionClick: 0,
    },
    {
      title: "Hitos relacionados con obligaciones del contrato",
      selectionClick: 1,
    },
    {
      title:
        "Hitos relacionados con términos para notificaciones o preavisos dentro del contrato",
      selectionClick: 2,
    },
    {
      title: "Hitos relacionados con la renovación o terminación del contrato",
      selectionClick: 3,
    },
    {
      title: "Fecha de suscripción del contrato",
      selectionClick: 4,
    },
    {
      title: "Otros hitos encontrados en el contrato",
      selectionClick: 5,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const dataReports = useSelector(getReports);
  const currentDocument = useSelector(getCurrentDocument);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [anchorFilterBy, setAnchorFilterBy] = useState(null);
  const [filterSelected, setFilterSelected] = useState([]);
  const [nameDocument, setNameDocument] = useState("");
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [itemReport, setItemReport] = useState(null);
  const openFilterBy = Boolean(anchorFilterBy);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleDeleteFilter = (deleteFilter) => {
    setFilterSelected(
      filterSelected.filter(
        (item) => item.selectionClick !== deleteFilter.selectionClick
      )
    );
  };
  const handleMenuItemClickFilterBy = (option) => {
    setAnchorFilterBy(null);
    const verify = filterSelected.find(
      (item) => item.selectionClick === option.selectionClick
    );
    if (verify) return;
    setFilterSelected((oldArray) => [...oldArray, option]);
  };
  const handleGenerateReport = async () => {
    try {
      Mixpanel.track("Generar PDF IA Informes  ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
        type: "Partes",
      });
      setIsLoading(true);
      const doc = await reportPartsCreator({
        reports: data,
        contract: nameDocument,
      });
      if (doc) {
        const pdf = await convertToPdfService(doc);
        if ( pdf.status === CODES.COD_RESPONSE_HTTP_OK ) {
          const dateName = dateFormat(new Date(), "isoDate");
          const filename = `${dateName}-Partes-${nameDocument}.pdf`;
          const dataFile = pdf?.data?.responseMessage?.data;
          const file = new Uint8Array(dataFile?.buffer?.data).buffer;
          fileDownload(file, filename);
        }
      }
    } catch (error) {
      console.log(
        "============ Start Error Download Report Values PDF ============"
      );
      console.error(error);
      console.log(
        "============ Start Error Download Report Values PDF ============"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleInfoToSupport = async (reportMessage) => {
    let isInfoSentToSupport = false;
    try {
      setIsLoading(true);
      const reportInformation = {
        message: reportMessage,
        functionalityName: `${REPORTS_TYPE.PARTS.name}`,
        currentPathname: location.pathname,
      };
      const serviceResponse = await reportIAResult(reportInformation);
      if (serviceResponse) {
        if (serviceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          isInfoSentToSupport = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return isInfoSentToSupport;
  };
  const handleAgreeModalInputReport = async (data) => {
    if (data === "") return;
    if (itemReport) {
      const isInfoSentToSupport = await handleInfoToSupport(data);
      setIsOpenModalInputReport(!isInfoSentToSupport);
    }
  };
  useEffect(() => {
    if (currentDocument) {
      setNameDocument(
        currentDocument?.title || currentDocument?.name || "Informe_de_partes"
      );
    }
  }, [currentDocument]);
  useEffect(() => {
    if (type) {
      const reportsData = dataReports.find(
        (item) => item.typeReport === type
      ).reports;
      setData(reportsData);
      setFilteredData(reportsData);
    }
  }, [dataReports]);
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ alignItems: "center" }}>
        <Col>
          {filterSelected.map((item, index) => (
            <span
              key={uuidv4()}
              style={{ marginRight: "1%" }}
              className="subheading caption custom-badges__disabled"
            >
              {item.title}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilter(item)}
              />
            </span>
          ))}
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={handleGenerateReport}
          >
            Generar PDF
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            hidden
            variant="contained"
            startIcon={<FilterAltIcon />}
            endIcon={<ExpandMoreIcon />}
            className={"custom-input__button__secondary-color"}
            onClick={(e) => {
              setAnchorFilterBy(e.currentTarget);
            }}
          >
            <p className="button">Filtrar por</p>
          </Button>
          <Menu
            id="menu-filter-by"
            anchorEl={anchorFilterBy}
            open={openFilterBy}
            onClose={() => setAnchorFilterBy(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {MENU_FILTER_BY.map((option, index) => (
              <MenuItem
                key={uuidv4()}
                onClick={() => handleMenuItemClickFilterBy(option)}
              >
                <span className="body-content">{option.title}</span>
              </MenuItem>
            ))}
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        {filteredData?.length > 0 &&
          filteredData.map((report, index) => (
            <Accordion className="custom-accordion" key={uuidv4()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="header-reports-milestionesAndDate"
              >
                <p className="heading__primary-color">
                  {report?.name || report?.designation || ""}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                {report?.where && (
                  <Row>
                    <span className="subheading">
                      {"Cláusula donde se encuentra:  "}
                      {/* <span
                                                className="caption"
                                            >
                                                {report.where}
                                            </span> */}
                    </span>
                  </Row>
                )}
                <br />
                {report?.designation && (
                  <Row>
                    <span className="subheading">
                      {"Nombre de la parte:  "}
                      <span className="caption">{report?.designation}</span>
                    </span>
                  </Row>
                )}
                <br />
                {report?.id_number && (
                  <Row>
                    <span className="subheading">
                      {"Identificación de la parte:  "}
                      <span className="caption">{report?.id_number}</span>
                    </span>
                  </Row>
                )}
                <br />
                {report?.signatories?.length > 0 && (
                  <Row>
                    <span className="subheading">
                      {"Signatarios e identificación:  "}
                      <span className="caption">
                        {report?.signatories
                          ?.map((sign) => `${sign?.name} (${sign?.id_number})`)
                          .join(", ")}
                        {/* {report.signatories && 
                                                    report.signatories.map(sign => `${sign?.name} (${sign?.type_id}: ${sign?.id_number})`).join(", ")
                                                } */}
                      </span>
                    </span>
                  </Row>
                )}
                <br />
                {report?.address && (
                  <Row>
                    <span className="subheading">
                      {"Domicilio:  "}
                      <span className="caption">{report?.address}</span>
                    </span>
                  </Row>
                )}
                <br />

                {report?.address && (
                  <Row>
                    <span className="subheading">
                      {"Dirección:  "}
                      <span className="caption">{report?.address}</span>
                    </span>
                  </Row>
                )}
                <br />
                {report?.email && (
                  <Row>
                    <span className="subheading">
                      {"Correo electrónico:  "}
                      <span className="caption">{report?.email}</span>
                    </span>
                  </Row>
                )}
                <br />
                {report?.tel && (
                  <Row>
                    <span className="subheading">
                      {"Datos de contacto:  "}
                      <span className="caption">{report?.tel}</span>
                    </span>
                  </Row>
                )}
                <br />
                <Row style={{ justifyContent: "end" }}>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      startIcon={<ErrorOutlineIcon />}
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        setItemReport(report);
                        setIsOpenModalInputReport(true);
                      }}
                    >
                      <p className="button">Reportar</p>
                    </Button>
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      startIcon={<RemoveRedEyeOutlinedIcon />}
                      className="custom-input__button__primary-color"
                      onClick={() => navigate(`viewContract`)}
                    >
                      <p className="button">Ver en contrato</p>
                    </Button>
                  </Col>
                </Row>
              </AccordionDetails>
            </Accordion>
          ))}
        {!filteredData?.length > 0 && (
          <span className="caption">No se han encontrado resultados</span>
        )}
      </Row>
      <ModalInpuText
        title={"Reportar parte"}
        message={"¿Deseas reportar la siguiente parte?"}
        agreeText={"Aceptar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={() => {
          setItemReport(null);
          setIsOpenModalInputReport(false);
        }}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
    </Container>
  );
};
