import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setName } from "../../parts/document/currentDocumentSlice";
import { useDispatch } from "react-redux";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalChooseNameAndType = ({
  handleDisagree,
  onClose,
  open,
  fileName,
  documentData,
  expressions,
  rule,
  originalDocument,
  savedReview,
  savedOriginal,
}) => {
  const [step, setStep] = useState(0);

  const [newFileName, setNewFileName] = useState();

  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setNewFileName(fileName.split(".pdf")[0]);
  }, [open]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleClose = () => {
    setStep(0);
    setChecked([]);
    setNewFileName(fileName.split(".pdf")[0]);
    onClose();
  };

  const handleStep = (action) => {
    if (action) {
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          {step === 0 && (
            <label className="heading__primary-color">
              Nombrar documento a guardar
            </label>
          )}
          {step === 1 && (
            <label className="heading__primary-color">
              Seleccione las versiones a guardar
            </label>
          )}
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          {step === 0 && (
            <Row>
              <Col xs={12}>
                <label className="caption">
                  Asigna un nuevo nombre a tu documento, o usa el nombre del
                  archivo por defecto:
                </label>
              </Col>
              <Col xs={12}>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Nombre del archivo"
                  value={newFileName}
                  onChange={(event) => setNewFileName(event.target.value)}
                />
              </Col>
            </Row>
          )}

          {step === 1 && (
            <List
              sx={{
                width: "100%",

                bgcolor: "background.paper",
              }}
            >
              <ListItem key="original" disablePadding>
                <ListItemButton onClick={handleToggle("original")} dense>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf("original") !== -1}
                    tabIndex={-1}
                    disableRipple
                  />

                  <ListItemText primary={`El documento cargado`} />
                </ListItemButton>
              </ListItem>

              <ListItem key="marked" disablePadding>
                <ListItemButton onClick={handleToggle("marked")} dense>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf("marked") !== -1}
                    tabIndex={-1}
                    disableRipple
                  />

                  <ListItemText
                    primary={`El documento con las expresiones resaltadas`}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Row>
            {step === 1 && (
              <>
                <Col>
                  <Button
                    variant="text"
                    className="custom-input__button__secondary-color__forced"
                    onClick={() => handleStep(false)}
                  >
                    Volver
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="contained"
                    disabled={checked.length === 0}
                    className="custom-input__button__primary-color__forced"
                    onClick={() => {
                      dispatch(setName({ name: newFileName }));
                      navigate(`other/folder/select-folder`, {
                        state: {
                          fileName: `${newFileName}`,
                          documentData: documentData,
                          expressions: expressions,
                          rule: rule,
                          originalDocument: originalDocument,
                          selectedOption: checked,
                          savedReview: savedReview,
                          savedOriginal: savedOriginal,
                          inReview: true
                        },
                      });
                    }}
                  >
                    Continuar
                  </Button>
                </Col>
              </>
            )}

            {step === 0 && (
              <>
                <Col>
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color__forced"
                    onClick={() => handleStep(true)}
                    disabled={newFileName === ""}
                  >
                    Guardar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalChooseNameAndType;
