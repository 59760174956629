import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { IOSSwitch } from "../../../../../../components/switch/switch";
import { Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SaveIcon from '@mui/icons-material/Save';

export default function ManageApprovalsModule () {

    const { control, handleSubmit } = useForm();

    const handleSaveAprovals = (data) => {
        console.log(data)
    }

    return (<Container fluid>
        <Row>
            <Breadcrum />
        </Row>
        <br />
        <Row>
            <Col xs={"auto"}>
                <SectionTitle title={"Personalización de aprobaciones"}/>
            </Col>
        </Row>
        <br />
        <Row>
            <label className="caption">Elige si quieres hacer visibles las aprobaciones y dónde verlas en el documento.</label>
        </Row>
        <br />
        <form onSubmit={handleSubmit(handleSaveAprovals)}>
            <Row style={{ rowGap: 20}}>
                <Col xs={12} md={6} lg={4} xl={3}>
                    <label className="label__subtitle">Visualización de aprobaciones</label>
                    <Row style={{rowGap: 20, alignItems: 'center'}}>
                        <Col xs={"auto"}>
                            <span className="caption">No visible</span>
                        </Col>
                        <Col xs={"auto"}>
                            <Controller
                                control={control}
                                name="typeApprovals"
                                defaultValue={true}
                                render={({ field: { value, onChange } }) => (
                                    <IOSSwitch
                                        onChange={onChange}
                                        sx={{ m:1 }}
                                        checked={value}
                                        colorbase={"#00374F"}
                                    />
                                )}
                            />
                        </Col>
                        <Col xs={"auto"}>
                            <span className="caption">Visible</span>
                        </Col>
                    </Row>
                </Col>
                <Col  xs={12} md={6} lg={4} xl={3}>
                    <label className="label__subtitle">Tipo de visualización del nombre</label>
                    <Row style={{rowGap: 20, alignItems: 'center'}}>
                        <Col xs={"auto"}>
                            <span className="caption">Nombre completo</span>
                        </Col>
                        <Col xs={"auto"}>
                            <Controller
                                control={control}
                                name="typeName"
                                defaultValue={false}
                                render={({ field: { value, onChange } }) => (
                                    <IOSSwitch
                                        onChange={onChange}
                                        sx={{ m:1 }}
                                        checked={value}
                                        colorbase={"#00374F"}
                                    />
                                )}
                            />
                        </Col>
                        <Col xs={"auto"}>
                            <span className="caption">Solo iniciales</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <Row style={{ justifyContent: 'end' }}>
                <Col xs={"auto"}>
                    <Button
                        variant="contained"
                        type="submit"
                        className="custom-input__button__secondary-color"
                    >
                        Cancelar
                    </Button>
                </Col>
                <Col xs={"auto"}>
                    <Button
                        variant="contained"
                        type="submit"
                        className="custom-input__button__primary-color"
                        endIcon={<SaveIcon fontSize="large" />}
                    >
                        Guardar cambios
                    </Button>
                </Col>
            </Row>
        </form>
    </Container>)
}