import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import LoadingLinear from "../../components/loadings/loadingLinear";
import SectionTitle from "../../components/sectionTitle";
import Breadcrum from "../breadcrum";

import { useForm } from "react-hook-form";
import PopoverInfo from "../../components/Popover/PopoverInfo";
import { Box, Button } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addEditedSignatoryToPart,
  addEditedSignatoryWithoutPart,
  getCurrentDocumentParts,
  getCurrentSignatoriesWithoutPart,
} from "../document/currentDocumentSlice";
import BlockerPage from "../../components/blocker/blocker";
import ModalDecision from "../../components/Modals/modalDecision";

export const FormSignatoriesForPart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { indexRole, indexPart, type } = useParams();
  const { state } = useLocation();
  const currentParts = useSelector(getCurrentDocumentParts);
  const currentSignatoriesWithoutPart = useSelector(
    getCurrentSignatoriesWithoutPart
  );

  const [isLoading, setIsLoading] = useState(false);
  const [personType, setPersonType] = useState("Persona natural");
  const [textSignatory, setTextSignatory] = useState("");
  const [indexSaveDataSignatory, setIndexSaveDataSignatory] = useState(0);
  const [currentPartData, setCurrentPartData] = useState({});
  const [sectionTitleDefault, setSectionTitleDefault] =
    useState("Agregar firmante");
  const [currentWithoutPartData, setCurrentWithoutPartData] = useState({});
  const [blockNavigate, setBlockNavigate] = useState(true);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);

  const schema = yup.object().shape({
    personType: yup.string().required("*Este campo es obligatorio"),
    designation: yup.string().required("*Este campo es obligatorio"),
    fullName: yup.string().required("*Este campo es obligatorio"),
    documentType: yup.string().required("*Este campo es obligatorio"),
    documentNumber: yup.string().required("*Este campo es obligatorio"),
    email: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .notRequired(),
    country: yup.string().notRequired(),
    city: yup.string().notRequired(),
    emailSignatory: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .notRequired(),
    countryAndCitySignatory: yup.string().notRequired(),
    documentNumberSignatory: yup.string().notRequired(),
    documentTypeSignatory: yup.string().notRequired(),
    fullNameSignatory: yup.string().notRequired(),
    charge: yup.string().notRequired(),
    companyName: yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const buildSignatoryData = (data) => {
    return {
      gender: "",
      address: data?.countryAndCity,
      documentNumber: data?.documentNumber,
      documentType: data?.documentType,
      name: data?.fullName || "",
      email: data?.email,
      charge: data?.designation,
      text: textSignatory,
      isDefault: getIsDefault(),

      type: "Persona natural",
    };
  };

  const getIsDefault = () => {
    if (type === "default") {
      return true;
    } else if (
      !currentParts[indexRole]?.signatories[indexPart]?.signatories.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCreateSignatory = (data) => {
    try {
      setBlockNavigate(false);
      setIsLoading(true);
      const values = buildSignatoryData(data);
      if (location.pathname.includes("without-part")) {
        const obj = {
          name: data?.designation,
          signatories: [values],
        };
        dispatch(
          addEditedSignatoryWithoutPart({
            partIndex: indexPart,
            signatoryIndex: indexSaveDataSignatory,
            editedSignatory: obj,
          })
        );
      } else {
        dispatch(
          addEditedSignatoryToPart({
            partIndex: indexPart,
            roleIndex: indexRole,
            signatoryIndex: indexSaveDataSignatory,
            editedSignatory: values,
          })
        );
      }
      navigate(-1);
    } catch (error) {
      console.log(
        "============ Handle Create Signatory Error Start ============"
      );
      console.error(error);
      console.log(
        "============ Handle Create Signatory Error End ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const setValueAllFields = () => {
    let currentData;
    if (location.pathname.includes("without-part")) {
      //   currentData =
      //     currentSignatoriesWithoutPart[indexPart]?.signatories[indexSignatory];
    } else {
      currentData =
        currentParts[indexRole]?.signatories[indexPart]?.signatories[
          state.indexSignatory
        ];
    }
    //Fill information basic

    setValue("designation", currentData?.charge);
    setValue("documentType", currentData?.documentType);
    setValue("documentNumber", currentData?.documentNumber);
    setValue("email", currentData?.email);
    setValue("countryAndCity", currentData?.address);
    setValue("fullName", currentData?.name);
  };

  useEffect(() => {
    watch((data) => {
      const { personType } = data;
      let text = "";
      if (personType === "Persona jurídica") {
        const {
          companyName,
          charge,
          documentType,
          documentNumber,
          fullNameSignatory,
          documentTypeSignatory,
          documentNumberSignatory,
          countryAndCitySignatory,
        } = data;
        text = `${fullNameSignatory || "¬Nombre¬"} quien se identifica con ${
          documentTypeSignatory || "¬tipo de documento¬"
        } número ${
          documentNumberSignatory || "¬número de documento¬"
        } y que actúa en calidad de ${charge || "¬cargo del firmante¬"} de ${
          companyName || "¬razón social¬"
        }, que se identifica con ${documentType || "¬tipo de documento¬"} ${
          documentNumber || "¬número de documento¬"
        } y con domicilio en ${countryAndCitySignatory || "¬domicilio¬"}`;
      } else if (personType === "Persona natural") {
        const { fullName, documentType, documentNumber, countryAndCity } = data;
        text = `${fullName || "¬Nombre¬"} quien se identifica con ${
          documentType || "¬tipo de documento¬"
        } número ${
          documentNumber || "¬número de documento¬"
        } y con domicilio en ${countryAndCity || "¬domicilio¬"}`;
      }
      setTextSignatory(text);
    });
  }, [watch]);

  useEffect(() => {
    (() => {
      let index = 0;

      const isWithoutPart = location.pathname.includes("without-part");
      //Fill type person
      setValue("personType", "Persona natural");
      setPersonType("Persona natural");
      if (type === "create")
        index =
          currentParts[indexRole]?.signatories[indexPart]?.signatories?.length;
      else if (type === "edit") {
        index = state.indexSignatory;
        setValueAllFields();
        setSectionTitleDefault("Editar firmante");
      } else if (type === "default") {
        index = state.indexSignatory;
        setSectionTitleDefault("Delegar firmante");
      }
      if (isWithoutPart) {
        setSectionTitleDefault("Agregar tercero como firmante");
        setCurrentWithoutPartData(currentSignatoriesWithoutPart[indexPart]);
      }

      setIndexSaveDataSignatory(index);
      setCurrentPartData(currentParts[indexPart]);
    })();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingLinear />}
      <BlockerPage
        blockNavigate={blockNavigate}
        message={
          "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
        }
      />
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={sectionTitleDefault} />
        </Col>
      </Row>
      <br />
      <Form onSubmit={handleSubmit(handleCreateSignatory)}>
        <Row>
          <h1 className="caption">
            {`Diligencia los campos del firmante que quieres agregar para ${currentParts[indexRole]?.name}`}{" "}
          </h1>
        </Row>
        <Row>
          <Col xs={"auto"} md={"auto"} lg={4}>
            <Form.Group>
              <Form.Label className="form__label">
                Calidad del firmante
                <span style={{ color: "red" }}>{"* "}</span>
                <PopoverInfo
                  section={{
                    description:
                      "Elige la denominación para entender por qué esta persona firmará el contrato si no es una de las partes. Ejemplo: testigo, aval, entre otros.",
                  }}
                />
              </Form.Label>
              <Form.Control
                {...register("designation")}
                placeholder={"Calidad del firmante"}
                style={{
                  border: "0.1rem solid #c5c7d0",
                }}
                bsPrefix={
                  errors?.designation
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors?.designation?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <br />
        {personType && (
          <>
            {personType === "Persona natural" && (
              <>
                <FormPersonNatural register={register} errors={errors} />
                <br />
                <BoxSignatoryNatural
                  fullName={watch("fullName")}
                  documentType={watch("documentType")}
                  documentNumber={watch("documentNumber")}
                  designation={watch("designation")}
                  countryAndCity={watch("countryAndCity")}
                />
              </>
            )}
          </>
        )}
        <br />
        <br />

        <Row className="sidebar__bottom__container">
          <Col xs={"auto"}>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => {
                setIsOpenModalDecision(true);
                setBlockNavigate(false);
              }}
            >
              Descartar cambios
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Guardar cambios
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalDecision
        title={"Descartar cambios"}
        message={
          "¿Está seguro que desea cancelar? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={() => setIsOpenModalDecision(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => handleCancel()}
        handleDisagree={() => setIsOpenModalDecision(false)}
      />
    </Container>
  );
};

const FormPersonNatural = ({ register, errors }) => (
  <Row style={{ alignItems: "center" }}>
    <Row>
      <Col className="home-init-cards-row__title-row">
        <span className="subheading__default">
          {" "}
          Datos de la persona natural:
        </span>
        <PopoverInfo
          section={{
            description:
              "Es la persona que directamente va a contratar, a tener derechos y/o obligaciones.",
          }}
        />
      </Col>
    </Row>
    <Col xs={"auto"} md={"auto"} lg={8}>
      <Form.Group>
        <Form.Label className="form__label">Nombre completo</Form.Label>
        <Form.Control
          {...register("fullName")}
          placeholder={"Nombre completo"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.fullName
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
        />
        <div className="caption custom-input__error">
          {errors?.fullName?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Tipo de documento</Form.Label>
        <Form.Control
          {...register("documentType")}
          placeholder={"Tipo de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentType
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
        />
        <div className="caption custom-input__error">
          {errors?.documentType?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Número de documento</Form.Label>
        <Form.Control
          {...register("documentNumber")}
          placeholder={"Número de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentNumber
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
        />
        <div className="caption custom-input__error">
          {errors?.documentNumber?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Correo electrónico</Form.Label>
        <Form.Control
          {...register("email")}
          placeholder={"ejemplo@correo.com"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.email
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
        />
        <div className="caption custom-input__error">
          {errors?.email?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Domicilio</Form.Label>
        <Form.Control
          {...register("countryAndCity")}
          className="label"
          placeholder="Ciudad"
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.countryAndCity
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
        />
        <div className="caption custom-input__error">
          {errors?.countryAndCity?.message}
        </div>
      </Form.Group>
    </Col>
  </Row>
);

const BoxSignatoryNatural = ({
  fullName,
  documentType,
  documentNumber,
  countryAndCity,
}) => (
  <>
    <h1 className="subheading__default mt-3 mb-5">
      Así se verá la información en la sección de firmantes del documento
    </h1>
    <Box style={{ backgroundColor: "#E6E9EF", padding: "2rem 2rem" }}>
      <p className="caption">
        <b>{fullName || "¬Nombre¬"} </b>quien se identifica con
        <b>{` ${documentType || "¬tipo de documento¬"} número ${
          documentNumber || "¬número de documento¬"
        } `}</b>{" "}
        y con domicilio en
        <b> {countryAndCity || "¬domicilio¬"}</b>
      </p>
    </Box>
  </>
);
