import React from "react";
import { Box, LinearProgress } from "@mui/material";

const LoadingLinear = () => {
  return (
    <div data-testid="linear-load" className="loading-content-linear loading-content-linear--spinner">
      <Box style={{
          position: 'fixed',
          right: 0,
          top: 0,
          width: '100%'
      }}>
          <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingLinear;
