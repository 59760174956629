import { Viewer, Worker } from "@react-pdf-viewer/core";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import { env } from "../../../../../../env";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  getDocById,
  getFileDocument,
} from "../../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import GetToken from "../../../../../../utils/getToken";

const ViewOthers = () => {
  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;

  //Hooks
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { word, contract, draft } = codesDocumentType;

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [documentInfo, setDocumentInfo] = useState({});
  const [documentFile, setDocumentFile] = useState(null);

  //Handle
  const handleRequestGetDocument = async () => {
    try {
      setIsLoading(true);
      const doc = await getDocById(documentId);
      const { fileType, filenameInBucket, bucketName, documentContent } =
        doc.data.responseMessage;

      if (doc && doc.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(doc);
        return;
      }

      const convert = async (document) => {
        if (
          !document?.data?.responseMessage?.buffer ||
          !document?.data?.responseMessage
        ) {
          return {
            data: {
              responseMessage: {
                buffer: [],
              },
            },
          };
        }

        const { buffer, contentType } = document.data.responseMessage;
        const blob = new Blob([new Uint8Array(buffer?.data).buffer], {
          type: contentType,
        });

        const pdf = await convertToPdfService(blob);

        return {
          data: {
            responseMessage: {
              buffer: pdf.data.responseMessage.data,
            },
          },
        };
      };

      let response;

      if (fileType === word._id || fileType === contract._id) {
        const dataFile = await convert(
          await getFileDocument(filenameInBucket, bucketName, true)
        );
        response = dataFile.data.responseMessage?.buffer?.buffer?.data;
      } else if (fileType === draft._id) {
        const dataFile = await convert(documentContent);
        response = dataFile.data.responseMessage?.buffer?.buffer?.data;
      } else {
        const dataFile = await getFileDocument(
          filenameInBucket,
          bucketName,
          false
        );
        response = dataFile?.data?.responseMessage?.buffer?.data;
        const contentType = dataFile.data.responseMessage.contentType;
        if (
          contentType &&
          contentType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const file = await convert(dataFile);
          response = file.data.responseMessage?.buffer?.buffer?.data;
        }
      }

      const newBlob = new Blob([new Uint8Array(response).buffer], {
        type: "application/pdf",
      });
      const newUrl = window.URL.createObjectURL(newBlob);
      setDocumentFile(newUrl);
      setDocumentInfo(doc.data.responseMessage);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //UseEffect
  useEffect(() => {
    handleRequestGetDocument();
  }, []);

  return (
    <Worker workerUrl={WORKER_URL}>
      <Container fluid>
        {isLoading && <LoadingContent />}
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={documentInfo.name} />
        </Row>
        <Row
          className="custom-accordion__container-document"
          style={{ marginTop: "2%" }}
        >
          {documentFile && (
            <Viewer
              fileUrl={documentFile}
              httpHeaders={{
                Authorization: GetToken(),
              }}
            />
          )}
        </Row>
        <ModalInfo
          title="Sin permisos suficientes"
          onClose={() => {
            setIsOpenModalUnauthorized(false);

            navigate(-1);
          }}
          open={isOpenModalUnauthorized}
          responseData={responseDataUnauthorized}
          confirmationText="Aceptar"
        />
      </Container>
    </Worker>
  );
};

export default ViewOthers;
