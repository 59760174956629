import axios from "axios";
import { env } from "../../env";
import GetToken from "../../utils/getToken";
import qs from "qs";

export function getEstimateUsers({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateUsers",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateTotalRequests({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateRequests",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateDocuments({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateDocuments",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId: companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimatePerfomanceRequests({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimatePerfomanceRequests",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function getEstimateWorkloadPerUser({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateWorkloadPerUser",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateSignaturesFlows({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateSignaturesFlows",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateSignaturesUsers({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateSignaturesUsers",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateAlerts({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateAlerts",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateTask({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateTasks",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getEstimateApprovals({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  searchParamerter,
}) {
  return axios({
    method: "GET",
    url: "dashboard/estimateApprovals",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      searchParamerter,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function downloadDashboardCsv({
  companyId,
  corporateUnitId,
  startDate,
  endDate,
  indicatorsSelected,
}) {
  let params = {};
  if (!startDate || !endDate) {
    params = {
      companyId,
      corporateUnitId,
      indicatorsSelected,
    };
  } else {
    params = {
      companyId,
      corporateUnitId,
      startDate,
      endDate,
      indicatorsSelected,
    };
  }
  return axios({
    method: "GET",
    url: "dashboard/downloadDashboardCSV",
    responseType: "arraybuffer",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
}
