import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";

const CardDocument = ({ title, name, edit, download }) => {

    return (
        <Container fluid className="cards-document">
            <Row style={{alignItems: "center"}}>
                <Col xs={"auto"} style={{ padding: "2rem" }}>
                    {title && (
                        <Row style={{ marginBottom: "1rem" }}>
                            <h1 className="label__title">
                                {title}
                            </h1>
                        </Row>
                    )}
                    <Row style={{ color: "#00374F" }}>
                        <h1 className="label__title">
                            {name}
                        </h1>
                    </Row>
                </Col>
                {/* <Col xs={"auto"}>
                    <IconButton
                        aria-label="edit"
                        className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                        sx={{ borderRadius: "4px" }}
                        onClick={download}
                    >
                        <DownloadIcon fontSize="large" htmlColor="white" />
                    </IconButton>
                </Col> */}
                <Col xs={"auto"}>
                    <IconButton
                        aria-label="edit"
                        className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                        sx={{ borderRadius: "4px" }}
                        onClick={edit}
                    >
                        <EditOutlinedIcon fontSize="large" htmlColor="white" />
                    </IconButton>
                </Col>
            </Row>
        </Container>
    );
};

export { CardDocument };