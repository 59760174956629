import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Button, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import RichEditorText from "../../../../../../parts/RichEditorText";
import Breadcrum from "../../../../../../parts/breadcrum";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrederminatedTextsList,
  setStatusPrederminatedTexts,
} from "./prederminatedTextsSlice";
import EditIcon from "@mui/icons-material/Edit";
import {
  createPrederminatedTextService,
  updatePrederminatedTextService,
} from "../../../../../../services/predeterminatedTexts/predeterminatedTextsService";
import { CODES } from "../../../../../../utils/codes";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { getTagDocumentTempletesList } from "../../../../../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import ClearIcon from "@mui/icons-material/Clear";

const UpdatePredeterminatedText = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // selectors redux
  const dispatch = useDispatch();
  const listPredeterminatedTexts = useSelector(getPrederminatedTextsList);
  const tagsList = useSelector(getTagDocumentTempletesList);

  const [isLoading, setIsLoading] = useState(false);
  const [predeterminatedText, setPredeterminatedText] = useState({
    name: "",
    text: "",
    blankSpaces: [],
    labes: [],
  });
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [modalResponse, setModalResponse] = useState({
    open: false,
    response: {},
    title: "",
    action: null,
  });
  const [tagsSelected, setTagsSelected] = useState([]);

  const predeterminatedTextRef = useRef();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  const handleCreatePredetermitedText = async () => {
    try {
      setIsLoading(false);
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        name: getValues("nameText"),
        text: getValues("predeterminatedText"),
        company,
        corporateUnit,
        createdBy: userId,
        labels: tagsSelected.map((t) => t._id),
      };
      const responseCreateTextService = await createPrederminatedTextService(
        obj
      );
      if (
        responseCreateTextService?.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        responseCreateTextService?.data?.success
      ) {
        dispatch(setStatusPrederminatedTexts());
        setModalResponse({
          open: true,
          response: responseCreateTextService,
          title: "Se ha creado el bloque",
          action: () => navigate(-1),
        });
        return;
      }
      setModalResponse({
        open: true,
        response: responseCreateTextService,
        title: "Ha ocurrido un error",
        action: () =>
          setModalResponse({
            open: false,
            response: {},
            title: "",
            action: null,
          }),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePredeterminatedText = async (data) => {
    try {
      setIsLoading(true);
      const obj = {
        predeterminedTextId: id,
        name: data?.nameText || "",
        text: data?.predeterminatedText || "",
        labels: tagsSelected.map((t) => t._id),
      };
      const responseUpdateText = await updatePrederminatedTextService(obj);
      if (
        responseUpdateText?.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseUpdateText?.data?.success
      ) {
        dispatch(setStatusPrederminatedTexts());
        setModalResponse({
          open: true,
          response: responseUpdateText,
          title: "Se ha actualizado el bloque",
          action: () => navigate(-1),
        });
        return;
      }
      setModalResponse({
        open: true,
        response: responseUpdateText,
        title: "Ha ocurrido un error",
        action: () =>
          setModalResponse({
            open: false,
            response: {},
            title: "",
            action: null,
          }),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!listPredeterminatedTexts.length) {
      return navigate("/service/library/predeterminedTexts", { replace: true });
    }
    const findPredeterminatedTexts = listPredeterminatedTexts.find(
      (item) => item?._id === id
    );
    setPredeterminatedText(findPredeterminatedTexts);
    if (predeterminatedTextRef.current)
      predeterminatedTextRef.current.addText(findPredeterminatedTexts.text);
    setValue("predeterminatedText", findPredeterminatedTexts?.text);
    setValue("nameText", findPredeterminatedTexts?.name);
    setTagsSelected(findPredeterminatedTexts?.labels);
  }, [listPredeterminatedTexts]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Editar bloque"} />
      </Row>
      <br />
      <Form onSubmit={handleSubmit(handleUpdatePredeterminatedText)}>
        <Row>
          <Col md={4} style={{ display: "flex", alignItems: "center" }}>
            {isEditTitle ? (
              <Form.Group
                style={{ padding: "0%", margin: "2% 0" }}
                onBlur={() => {
                  setIsEditTitle(false);
                }}
              >
                <Form.Control
                  {...register("nameText")}
                  autoFocus={true}
                  placeholder={"Escriba aqui"}
                  bsPrefix={"input-group-container__no-icon label"}
                  value={predeterminatedText?.name} // Nombre actual del texto predeterminado
                  onChange={(e) => {
                    const text = e.target.value;
                    setPredeterminatedText((predeterminatedText) => ({
                      ...predeterminatedText,
                      name: text,
                    }));
                    setValue("nameText", text);
                  }}
                />
              </Form.Group>
            ) : (
              <p className="heading">
                {predeterminatedText?.name || "Nombre bloque"}
              </p> // Nombre actual del texto predeterminado
            )}
            <IconButton
              onClick={() => {
                setIsEditTitle(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Col>
          <Col />
          <Col xs={"auto"}>
            <ModalAssociateTags
              tags={tagsList}
              handleTagsSelected={setTagsSelected}
              previousTagsSelected={tagsSelected}
              type="DOCUMENT_TEMPLETES"
            />
          </Col>
        </Row>
        <br />
        <Row style={{ justifyContent: "end" }}>
          {tagsSelected.map((tag) => {
            return (
              <Col
                xs={"auto"}
                style={{ display: "flex", alignItems: "center" }}
                key={tag?._id}
              >
                <span className="caption custom-badges__disabled">
                  {tag.name + " "}{" "}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() =>
                      setTagsSelected(
                        tagsSelected.filter((t) => t._id !== tag._id)
                      )
                    }
                  />
                </span>
              </Col>
            );
          })}
        </Row>
        <br />
        <Row
          style={{
            height: "80%",
          }}
        >
          <Controller
            control={control}
            name="predeterminatedText"
            render={({ field: { onChange, onBlur } }) => (
              <RichEditorText
                onChange={onChange} // send value to hook form
                onBlur={onBlur} // notify when input is touched/blur
                ref={predeterminatedTextRef}
                className={
                  errors.predeterminatedText
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                } // add class error if there is an error
                placeholder="Escribe el bloque aquí"
                deleteBlankSpace={(id) => {
                  setPredeterminatedText((predeterminatedT) => ({
                    ...predeterminatedT,
                    blankSpaces: predeterminatedText.blankSpaces.filter(
                      (blankSpace) => blankSpace.id !== id
                    ),
                  }));
                }}
              />
            )}
          />
          {errors?.predeterminatedText?.message && (
            <div className="caption custom-input__error">
              {errors.predeterminatedText?.message}
            </div>
          )}
        </Row>

        <br />

        <Row
          className="sidebar__bottom__container"
          sm={"auto"}
          style={{ justifyContent: "right" }}
        >
          <Col xs={"auto"}>
            <Button
              type="submit"
              variant="contained"
              startIcon={<SaveAsOutlinedIcon />}
              className="custom-input__button__secondary-color"
              style={{
                border: "1px solid #00374F",
              }}
            >
              <p className="button">Sobreescribir</p>
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<SaveOutlinedIcon />}
              className="custom-input__button__primary-color"
              onClick={handleCreatePredetermitedText}
            >
              <p className="button">Guardar como nuevo</p>
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalInfo
        title={modalResponse.title}
        open={modalResponse.open}
        onClose={modalResponse.action}
        responseData={modalResponse.response}
      />
    </Container>
  );
};

export default UpdatePredeterminatedText;
