import { CODES } from "./codes";

export const statusList = [
  {
    Status: "Revisión solicitante",
    description:
      "La solicitud fue enviada al solicitante para que haga una revisión y actualización de la solicitud.",
  },
  {
    Status: "Revisión abogado",
    description:
      "La solicitud fue enviada al abogado para que haga una revisión y actualización de la solicitud.",
  },
  {
    Status: "Solucionada",
    description:
      "La solicitud fue solucionada gracias a que se culminó con el proceso entregando el documento solicitado en su versión final.",
  },
  {
    Status: "Cancelada",
    description:
      "La solicitud fue cancelada debido a que el usuario solicitante solicitó su cancelación.",
  },
];

export const subTypesList = [
  "Confidencialidad",
  "Prestación de servicios",
  "Arrendamiento",
  "Promesa compraventa",
  "Compraventa",
  "Suminstro",
  "Obra",
  "Otro",
  "Otrosí",
];

export const carvajalTypeList = [
  "Contrato/Otrosí",
  "Respuesta al cliente/proveedor - NDA - Formatos",
  "Consulta Jurídica",
  "Formulario Solicitud de formularios RFP/RFI - Documentos licitaciones - Formatos proveedores",
];

export const elementStatus = ["En negociación", "En ejecución", "Finalizados"];
export const typePrincipal = [
  "Documento de trabajo",
  "Documento principal Firmado",
  "Borrador",
];
export const typeAssociate = [
  "Garantías",
  "Anexos",
  "Actas",
  "Comunicaciones",
  "Otros",
];

export const mockMinuta = [
  "Tipo de contrato 1",
  "Tipo de contrato 2",
  "Tipo de contrato 3",
];
export const mockSignatories = [
  "Elina Jaimes",
  "Sebastian León",
  "Eder Pineda",
  "Alvaro Perafan",
];

export const handleStyleStatusRequest = (colorId) => {
  if (colorId === CODES.COD_LIGHT_ORANGE) {
    return "#FFD7B1";
  } else if (colorId === CODES.COD_LIGHT_GREEN) {
    return "#CCF4E3";
  } else if (colorId === CODES.COD_LIGHT_RED) {
    return "#F9DADE";
  } else if (colorId === CODES.COD_LIGHT_PURPLE) {
    return "#E6E9EF";
  } else if (colorId === CODES.COD_LIGHT_BLUE) {
    return "#CBECEE";
  } else {
    return "#F3DEBC";
  }
};

export const statusRequestName = {
  SOLVE: 'Solucionada',
  REVIEW_APPLICANT: 'Revisión solicitante',
  REVIEW_ATTORNEY: 'Revisión abogado',
  CANCELED: 'Cancelada',
};
