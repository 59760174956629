import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LoadingContent from "../../../../../../components/loadingContent";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import { CLAUSES_INFO } from "../../../../../../utils/popoverInfo";
import ClearIcon from "@mui/icons-material/Clear";
import { AssociateToClauses } from "../../../../../../parts/associations/AssociateToClauses";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ReactHtmlParser from "react-html-parser";
import { NUMBERINGWORDSO } from "../../../../../../utils/numberings";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalImportNumeral from "../../../../../../parts/modals/ModalImportNumeral";
import ModalSortList from "../../../../../../components/Modals/ModalSortList";
import { REGEXP } from "../../../../../../utils/regexp";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  reorderClause,
  getSelectedClauses,
  setSelectedClauses,
  getSelectedSubClauses,
  setSelectedSubClauses,
} from "../../../../../../parts/minutas/clausesSelectedSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createContractTypes,
  getCurrentContractType,
  setCurrentContractType,
} from "./contractTypesSlice";
import { wordCreatorFile } from "../../../../../../components/WordCreator/WordCreator";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../utils/codes";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  IconDiffParts,
  countPartsClause,
  isDiffNumberParts,
} from "./utilsContractTypes";
import { countPartsErrorsClauses } from "../../../../../../utils/partsCorrection";

const CreateContractType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedClauses = useSelector(getSelectedClauses);
  const selectedSubClauses = useSelector(getSelectedSubClauses);
  const currentContractType = useSelector(getCurrentContractType);

  const PARTS_NAME = ["A", "B", "C", "D"];

  const [isLoading, setIsLoading] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [parts, setParts] = useState([]);
  const [indexDelete, setIndexDelete] = useState("");
  const [isOpenModalDecisionDeleteClause, setIsOpenModalDecisionDeleteClause] =
    useState(false);
  const [isOpenModalImportNumeral, setIsOpenModalImportNumeral] =
    useState(false);
  const [indexClauseImportNumeral, setIndexClauseImportNumeral] =
    useState(null);
  const [stateAssociateClauses, setStateAssociateClauses] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState({});
  const badge = false;

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [isOpenModalDiffNumberParts, setIsOpenModalDiffNumberParts] =
    useState(false);

  const [partsErrorsModal, setPartsErrorsModal] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es obligatorio"),
    parts: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
      })
    ),
    inFavor: yup
      .string("*Debe seleccionar una opción")
      .required("*Este campo es obligatorio"),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parts: [{ title: "" }, { title: "" }],
    },
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parts",
  });
  const handleDeletePart = (index) => {
    remove(index);
  };
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };
  const buildTags = (tags) => {
    const newTags = [];
    for (const tag of tags) {
      newTags.push(tag._id);
    }
    return newTags;
  };
  const buildSubClauses = (subClauses) => {
    const newSubClauses = [];
    for (const subClause of subClauses) {
      newSubClauses.push(subClause.subclause._id);
    }
    return newSubClauses;
  };
  const buildClauses = (clauses) => {
    let newClauses = [];
    for (const clause of clauses) {
      newClauses = [
        ...newClauses,
        {
          clause: clause._id,
          subclausesSelected: buildSubClauses(clause.subclauses),
        },
      ];
    }
    return newClauses;
  };
  const handleSaveContractTypesInfo = () => {
    const data = getValues();
    const parts = [
      { name: data.parts[0].title },
      { name: data.parts[1].title },
    ];
    if (data.parts[2]) parts.push({ name: data.parts[2].title });
    if (data.parts[3]) parts.push({ name: data.parts[3].title });
    let obj = {
      name: data?.name || "",
      parts,
      clauses: selectedClauses,
      inFavor: data.inFavor,
      labels: tagsSelected,
    };
    dispatch(setCurrentContractType({ contractType: obj }));
  };
  const handleValidateInfo = (data) => {
    if (!selectedClauses.length) {
      setStateAssociateClauses(true);
      return;
    }
    const isSomeDiffParts = selectedClauses.some((item) =>
      isDiffNumberParts(data.parts, item.parts)
    );
    if (isSomeDiffParts) {
      setIsOpenModalDiffNumberParts(true);
      return;
    }

    handleCreateContractType(data);
  };
  const handleCreateContractType = async (data) => {
    setIsLoading(true);
    try {
      let companyId = localStorage.getItem("company");
      let obj = {
        name: data.name,
        parts: {
          type: "ContractsTypes",
          partA: {
            name: data.parts[0].title,
          },
          partB: {
            name: data.parts[1].title,
          },
          partC: data?.parts[2]
            ? {
                name: data.parts[2].title,
              }
            : null,
          partD: data?.parts[3]
            ? {
                name: data.parts[3].title,
              }
            : null,
        },
        clauses: buildClauses(selectedClauses),
        inFavor: data.inFavor,
        labels: buildTags(tagsSelected),
        company: companyId,
      };

      if (selectedClauses.length !== 0) {
        setCurrentData(obj);

        let errorParts = countPartsErrorsClauses(selectedClauses);

        if (errorParts > 0) {
          setPartsErrorsModal(true);
          setIsLoading(false);
          return;
        }

        dispatchCreateContractType(obj);
      } else {
        setStateAssociateClauses(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dispatchCreateContractType = async (obj) => {
    setIsLoading(true);
    try {
      const response = await dispatch(createContractTypes(obj)).unwrap();
      if (response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(response);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setIsOpenModalInfo(true);
      setResponseData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOpenModalDesicionDeleteClause = (index) => {
    setIndexDelete(index);
    setIsOpenModalDecisionDeleteClause(true);
  };
  const handleAgreeDeleteClause = () => {
    const data = selectedClauses.filter((clause) => clause._id !== indexDelete);
    const subData = selectedSubClauses.filter(
      (clause) => clause.clause !== indexDelete
    );
    dispatch(setSelectedClauses(data));
    dispatch(setSelectedSubClauses(subData));
    setIsOpenModalDecisionDeleteClause(false);
  };
  const handleSubClausesSelected = (importedNumerals) => {
    let oldClauses = selectedSubClauses;
    for (const numeral of importedNumerals) {
      const numerals = oldClauses.map((clause, idx) => {
        let addNumeral = {};
        if (clause.clause === indexClauseImportNumeral) {
          addNumeral = {
            clause: clause.clause,
            subclause: [...clause.subclause, numeral],
            index: idx,
          };
        } else {
          addNumeral = {
            ...clause,
          };
        }
        return addNumeral;
      });
      oldClauses = numerals;
    }
    dispatch(setSelectedSubClauses(oldClauses));
    UpdateClauseToImport(oldClauses);
  };
  const UpdateClauseToImport = (subclauses) => {
    const clauses = selectedClauses;
    let data = [];
    for (const subclause of subclauses) {
      for (const clause of clauses) {
        if (clause._id === subclause.clause) {
          data = [
            ...data,
            {
              ...clause,
              subclauses: [...subclause.subclause],
            },
          ];
        }
      }
    }
    dispatch(setSelectedClauses(data));
  };
  const handleAddPartThisContractType = (data) => {
    const text = ReactHtmlParser(data);
    if (typeof text[0] === "string") {
      return data;
    }
    for (const item of text) {
      const children = item.props.children;
      for (const child of children) {
        if (typeof child != "string") {
          const part = child.props["data-part-number"];
          if (part === "0") {
            child.props.children[0] = `°${watch("parts")[0].title}°`;
          } else if (part === "1") {
            child.props.children[0] = `°${watch("parts")[1].title}°`;
          } else if (part === "2") {
            child.props.children[0] = `°${
              watch("parts")[2]?.title || "Parte sin definir"
            }°`;
          } else if (part === "3") {
            child.props.children[0] = `°${
              watch("parts")[3]?.title || "Parte sin definir"
            }°`;
          }
        }
      }
    }
    return text;
  };
  const handleCloseModalInfoCreate = () => {
    setIsOpenModalInfo(false);
    navigate(-1);
  };
  useEffect(() => {
    watch((data) => {
      setParts(data.parts);
    });
  }, [watch]);
  const handlePreviewDocument = async () => {
    try {
      setIsLoading(true);
      const parts =
        getValues("parts") &&
        getValues("parts").map((part) => ({
          blankspaces: [],
          name: part.title,
          signatories: [],
        }));
      const fileWord = await wordCreatorFile({
        title: getValues("name") || "Visualización Contrato Ejemplo",
        headers: [],
        parts,
        clauses: selectedClauses,
        numberingType: "number",
      });
      const response = await convertToPdfService(fileWord);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        const dataFile = response?.data?.responseMessage?.data;
        const file = URL.createObjectURL(
          new Blob([new Uint8Array(dataFile?.buffer?.data).buffer], {
            type: "application/pdf",
          })
        );
        window.open(file);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (currentContractType) {
      const currentParts = [
        { title: currentContractType.parts[0].name },
        { title: currentContractType.parts[1].name },
      ];
      if (currentContractType.parts[2])
        currentParts.push({ title: currentContractType.parts[2].name });
      if (currentContractType.parts[3])
        currentParts.push({ title: currentContractType.parts[3].name });

      setValue("name", currentContractType.name);
      setValue("parts", currentParts);
      setValue("inFavor", currentContractType.inFavor);
      setTagsSelected(currentContractType.labels);
    }
  }, [currentContractType]);
  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Crear Minuta"} />
        </Col>
        <Col>
          {badge && (
            <Row style={{ justifyContent: "end" }} xs={"auto"}>
              <Col xs={"auto"}>
                <Badge
                  style={{ cursor: "pointer" }}
                  badgeContent={1}
                  color="primary"
                  onClick={() => alert("DiscussionBoard")}
                >
                  <NotificationsNoneIcon fontSize="large" color="#00374F" />
                </Badge>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleValidateInfo)}>
          <Col xs={{ span: 6, offset: 0 }}>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Nombre de la minuta*
              </Form.Label>
              <Form.Control
                {...register("name", {
                  onChange: (e) => setValue("name", e.target.value.trimLeft()),
                  setValueAs: (value) => value.trim(),
                })}
                onBlur={handleSaveContractTypesInfo}
                placeholder="Minuta de ejemplo"
                bsPrefix={
                  errors.name
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.name?.message}
              </div>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">Rol contractual*</Form.Label>
              {fields.map((item, index) => {
                return (
                  <Row key={item.id}>
                    <Col xs={8}>
                      <Form.Control
                        {...register(`parts.${index}.title`, {
                          onChange: (e) => {
                            setValue(`parts.${index}.title`, e.target.value);
                          },
                        })}
                        placeholder={`Parte ${PARTS_NAME[index]}`}
                        bsPrefix={
                          errors?.parts?.[index]?.title
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                        onBlur={handleSaveContractTypesInfo}
                      />
                    </Col>
                    {index > 1 && (
                      <Col xs={1}>
                        <Button
                          variant="contained"
                          className="custom-input__button__primary-color"
                          onClick={() => handleDeletePart(index)}
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </Button>
                      </Col>
                    )}
                    <div className="caption custom-input__error  mb-4">
                      {errors?.parts?.[index]?.title?.message}
                    </div>
                  </Row>
                );
              })}
            </Form.Group>
            {fields.length < 4 && (
              <Row xs={"auto"} style={{ justifyContent: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    append({
                      title: "",
                    });
                  }}
                >
                  Agregar parte
                </Button>
              </Row>
            )}
            <br />
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                A favor de*
                <PopoverInfo section={CLAUSES_INFO.inFavor} />
              </Form.Label>
              <Row xs={"auto"}>
                <Form.Select
                  className="label mb-1"
                  defaultValue=""
                  onChange={(event) => {
                    handleSaveContractTypesInfo();
                    setValue("inFavor", event.target.value);
                  }}
                  bsPrefix={
                    errors.inFavor
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  {...register("inFavor")}
                >
                  <option
                    key={"defaultItems"}
                    className="label"
                    value=""
                    disabled
                  >
                    Seleccione uno
                  </option>
                  {parts.map((item, idx) => {
                    if (!watch(`parts.${idx}.title`))
                      return (
                        <option
                          key={item.id}
                          style={{ display: "none" }}
                        ></option>
                      );
                    return (
                      <option
                        key={item.id}
                        className="label"
                        value={item.title}
                      >
                        {item.title}
                      </option>
                    );
                  })}
                  <option key={"both"} className="label" value="Balanceada">
                    Balanceada
                  </option>
                </Form.Select>
                <p className="caption custom-input__error">
                  {errors.inFavor?.message}
                </p>
              </Row>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Etiquetas
                <PopoverInfo section={CLAUSES_INFO.tags} />
              </Form.Label>
              <Row>
                <Col xs={"auto"}>
                  <ModalAssociateTags
                    handleTagsSelected={handleTagsSelected}
                    previousTagsSelected={tagsSelected}
                    type="CONTRACTTYPES"
                  />
                </Col>
                {tagsSelected.map((tag) => {
                  return (
                    <Col
                      xs={"auto"}
                      style={{ display: "flex", alignItems: "center" }}
                      key={tag?._id}
                    >
                      <span className="caption custom-badges__disabled">
                        {tag.name + ""}{" "}
                        <ClearIcon
                          className="custom-badges__delete-button"
                          onClick={() => handleDeleteTag(tag)}
                        />
                      </span>
                    </Col>
                  );
                })}
              </Row>
            </Form.Group>
          </Col>
          <hr />
          <Col>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Asociar cláusulas a la minuta
                <PopoverInfo section={CLAUSES_INFO.contractType} />
              </Form.Label>
              <Row xs={"auto"}>
                <AssociateToClauses />
              </Row>
            </Form.Group>
            {selectedClauses.length <= 0 ? (
              <>
                <Row
                  style={{
                    height: "30rem",
                    border: `2px dashed ${
                      stateAssociateClauses ? "#E44258" : "#00374F"
                    }`,
                    borderRadius: "1rem",
                    color: `${stateAssociateClauses ? "#E44258" : "#00374F"}`,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5">
                    Aquí se mostrarán las cláusulas asociadas...
                    <br />
                    Para asociar cláusulas da clic en el botón “Asociar a
                    cláusulas“
                  </Typography>
                </Row>
                {stateAssociateClauses && (
                  <p className="caption custom-input__error">
                    *Seleccione al menos una cláusula para asociarla a la minuta
                  </p>
                )}
              </>
            ) : (
              <DragDropContext
                onDragEnd={(result) => {
                  dispatch(reorderClause({ result }));
                }}
              >
                <Droppable
                  droppableId="clauses"
                  droppableProvided="clausesselected"
                >
                  {(droppableProvided) => (
                    <Col
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                      className="custom-accordion__container__small"
                    >
                      {selectedClauses.map((clause, index) => (
                        <Draggable
                          key={clause?.id}
                          draggableId={clause?.id}
                          index={index}
                        >
                          {(dragProvided) => {
                            const text = handleAddPartThisContractType(
                              clause?.text
                            );
                            const inFavor = watch(`inFavor`);
                            return (
                              <Row
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                key={clause?._id}
                              >
                                <Col>
                                  <Accordion
                                    {...dragProvided.draggableProps}
                                    {...dragProvided.dragHandleProps}
                                    ref={dragProvided.innerRef}
                                    className="custom-accordion"
                                  >
                                    <Row style={{ alignItems: "center" }}>
                                      <Col xs={"auto"}>
                                        <DragIndicatorIcon
                                          fontSize="large"
                                          className="drag-color"
                                        />
                                      </Col>
                                      <Col>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                        >
                                          <Col
                                            style={{
                                              alignSelf: "center",
                                            }}
                                          >
                                            <p className="heading__primary-color">
                                              {clause.title}
                                            </p>
                                          </Col>
                                          <IconDiffParts
                                            isDiff={isDiffNumberParts(
                                              parts,
                                              clause.parts
                                            )}
                                          />
                                          <Col
                                            xs={"auto"}
                                            style={{
                                              textAlign: "right",
                                              alignSelf: "center",
                                            }}
                                          >
                                            <span className="subheading">
                                              A favor de: {inFavor}
                                            </span>
                                          </Col>
                                          <Col xs={"auto"}>
                                            <ModalSortList
                                              index={index}
                                              list={selectedClauses}
                                            />
                                          </Col>
                                          <Col xs={"auto"}>
                                            <IconButton
                                              aria-label="delete"
                                              className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                                              sx={{
                                                borderRadius: "4px",
                                                color: "white",
                                              }}
                                              onClick={() =>
                                                handleOpenModalDesicionDeleteClause(
                                                  clause._id
                                                )
                                              }
                                            >
                                              <DeleteOutlineOutlinedIcon fontSize="large" />
                                            </IconButton>
                                          </Col>
                                        </AccordionSummary>
                                      </Col>
                                    </Row>
                                    <AccordionDetails>
                                      <Row>
                                        <span className="subheading">
                                          Etiquetas:{" "}
                                          {clause.labels?.map((tag, i) => {
                                            return (
                                              <span
                                                key={tag?._id}
                                                style={{ marginRight: "1%" }}
                                                className="caption custom-badges__disabled"
                                              >
                                                {tag.name}
                                              </span>
                                            );
                                          })}
                                        </span>
                                      </Row>
                                      <br />
                                      <br />
                                      <Row>
                                        <span className="caption">
                                          {/* Text clause */}
                                          <span
                                            style={{ marginRight: "1%" }}
                                            className="caption"
                                          >
                                            {text}
                                          </span>
                                          {/* Mapea estado de las subclausulas para selecionara */}
                                          {clause?.subclauses &&
                                            clause?.subclauses?.map(
                                              (item, i) => {
                                                const textSubClause =
                                                  handleAddPartThisContractType(
                                                    item?.subclause?.text
                                                  );
                                                return (
                                                  <div key={item?._id}>
                                                    <br />
                                                    <br />
                                                    <Row
                                                      className="justify-text clauses-number"
                                                      style={{
                                                        marginLeft: "1%",
                                                        marginRight: "1%",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <Col xs={"auto"}>
                                                        <b>
                                                          {i + 1}.{""}
                                                        </b>
                                                        <b>
                                                          {item.subclause
                                                            .title &&
                                                            `${item.subclause.title}: `}
                                                        </b>
                                                      </Col>
                                                      <Col>
                                                        <span className="caption">
                                                          {textSubClause}
                                                        </span>
                                                      </Col>
                                                    </Row>
                                                    <br />
                                                  </div>
                                                );
                                              }
                                            )}
                                          <br />
                                          <br />
                                          <ol className="paragraphs clauses-number">
                                            {clause.paragraphs?.map((el, i) => {
                                              return (
                                                <li
                                                  key={el?.paragraph?._id}
                                                  className="justify-text clauses-number"
                                                  numbering={`Parágrafo ${
                                                    NUMBERINGWORDSO[i + 1]
                                                  }`}
                                                >
                                                  <span className="caption">
                                                    {ReactHtmlParser(
                                                      el.paragraph?.text
                                                    )}
                                                  </span>
                                                </li>
                                              );
                                            })}
                                          </ol>
                                        </span>
                                      </Row>
                                      <br />
                                      <Row xs={"auto"}>
                                        <Col>
                                          <Button
                                            variant="contained"
                                            startIcon={<ArticleOutlinedIcon />}
                                            className="custom-input__button__secondary-color"
                                            onClick={() => {
                                              setIndexClauseImportNumeral(
                                                clause._id
                                              );
                                              setIsOpenModalImportNumeral(true);
                                            }}
                                          >
                                            <p className="button">
                                              Importar numerales en esta
                                              cláusula
                                            </p>
                                          </Button>
                                        </Col>
                                        {clause?.company &&
                                          clause?.corporateUnit && (
                                            <Col>
                                              <Button
                                                type="button"
                                                variant="contained"
                                                startIcon={<EditOutlinedIcon />}
                                                className="custom-input__button__primary-color"
                                                onClick={() => {
                                                  handleSaveContractTypesInfo();
                                                  navigate(
                                                    `edit-clause/${clause._id}`
                                                  );
                                                }}
                                              >
                                                <p className="button">
                                                  Editar cláusula
                                                </p>
                                              </Button>
                                            </Col>
                                          )}
                                      </Row>
                                    </AccordionDetails>
                                  </Accordion>
                                </Col>
                              </Row>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Col>
          <br />
          <br />
          <Row xs={"auto"}>
            <Col>
              <Button
                type={"button"}
                variant="contained"
                startIcon={<VisibilityOutlinedIcon fontSize="large" />}
                className="custom-input__button__secondary-color"
                scroll={"paper"}
                onClick={handlePreviewDocument}
              >
                Vista previa
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
                className="custom-input__button__primary-color"
              >
                Terminar y guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalDecision
        title={"Borrar cláusula"}
        message={"¿Está seguro de borrar esta cláusula?"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeDeleteClause}
        handleDisagree={() => setIsOpenModalDecisionDeleteClause(false)}
        open={isOpenModalDecisionDeleteClause}
        onClose={() => setIsOpenModalDecisionDeleteClause(false)}
      />
      <ModalImportNumeral
        open={isOpenModalImportNumeral}
        onClose={() => setIsOpenModalImportNumeral(false)}
        handleSubClausesSelected={handleSubClausesSelected}
      />
      <ModalInfo
        title={"Agregar minuta"}
        responseData={responseData}
        open={isOpenModalInfo}
        onClose={handleCloseModalInfoCreate}
        buttonClose={true}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => {
          setIsOpenModalUnauthorized(false);
          navigate(-1);
        }}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Advertencia de guardado"}
        message={
          <Container fluid className="caption">
            <Row>
              En estas cláusulas hay partes que no corresponden con las
              definidas en la minuta o están sin definir:
            </Row>
            <br />
            <Row style={{ paddingLeft: "5%" }}>
              <ul>
                {selectedClauses.map((clause, index) => {
                  if (isDiffNumberParts(parts, clause.parts)) {
                    return (
                      <li key={clause?.id}>{`${index + 1}. ${
                        clause.title
                      } (${countPartsClause(clause?.parts)})`}</li>
                    );
                  }
                  return null;
                })}
              </ul>
            </Row>
          </Container>
        }
        agreeText={"Guardar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          const data = getValues();
          setIsOpenModalDiffNumberParts(false);
          handleCreateContractType(data);
        }}
        handleDisagree={() => setIsOpenModalDiffNumberParts(false)}
        open={isOpenModalDiffNumberParts}
        onClose={() => setIsOpenModalDiffNumberParts(false)}
      />
      <ModalDecision
        title={"Errores gramaticales encontrados"}
        message={
          <Container fluid className="caption">
            <Row>
              Hemos detectado algunas inconsistencias gramaticales sin corregir,
              ¿seguro quieres continuar?
            </Row>
            <br />
          </Container>
        }
        open={partsErrorsModal}
        onClose={() => setPartsErrorsModal(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setPartsErrorsModal(false);
          dispatchCreateContractType(currentData);
        }}
        handleDisagree={() => {
          setPartsErrorsModal(false);
        }}
      />
    </Container>
  );
};

export default CreateContractType;
