import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createTag,
  deleteTag,
  getTagsByCompanyAndResourceType,
  updateTag,
} from "../../../services/tags/tagsServices";
import { CODES } from "../../../utils/codes";

// Slice
export const tagDocumentTempletesSlice = createSlice({
  name: "tagsDocumentTempletes",
  initialState: {
    tagList: [],
    status: "fetch",
    statusCreate: "",
    statusModify: "",
    statusDelete: "",
    error: null,
    errorCreate: null,
    errorModify: null,
    errorDelete: null,
  },
  reducers: {
    resetOtherDocTagStatus(state, action) {
      state.status = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTagsDocumentTempletes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTagsDocumentTempletes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTagsDocumentTempletes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tagList = action.payload.data.responseMessage;
      })
      .addCase(modifyTagsDocumentTempletes.pending, (state, action) => {
        state.statusModify = "loading";
      })
      .addCase(modifyTagsDocumentTempletes.rejected, (state, action) => {
        state.statusModify = "failed";
        state.errorModify = action.error.message;
      })
      .addCase(modifyTagsDocumentTempletes.fulfilled, (state, action) => {
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
          state.statusModify = "succeeded";
        }
      })
      .addCase(deleteTagDocumentTempletes.pending, (state, action) => {
        state.statusDelete = "loading";
      })
      .addCase(deleteTagDocumentTempletes.rejected, (state, action) => {
        state.statusDelete = "failed";
        state.errorDelete = action.error.message;
      })
      .addCase(deleteTagDocumentTempletes.fulfilled, (state, action) => {
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
          state.statusDelete = "succeeded";
        }
      })
      .addCase(addTagDocumentTempletes.pending, (state, action) => {
        state.statusCreate = "loading";
      })
      .addCase(addTagDocumentTempletes.rejected, (state, action) => {
        state.statusCreate = "failed";
        state.errorCreate = action.error.message;
      })
      .addCase(addTagDocumentTempletes.fulfilled, (state, action) => {
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
          state.statusCreate = "succeeded";
          state.status = "succeeded";
        } else {
          state.statusCreate = "failed";
        }
      });
  },
});

// Selectors
export const getTagDocumentTempletesList = (state) =>
  state.tagsDocumentTempletes?.tagList;
export const getTagDocumentTempleteStatus = (state) =>
  state.tagsDocumentTempletes?.status;
export const getTagDocumentTempletesStatusModify = (state) =>
  state.tagsDocumentTempletes?.statusModify;
export const getTagDocumentTempletesStatusDelete = (state) =>
  state.tagsDocumentTempletes?.statusDelete;

export const { resetOtherDocTagStatus } = tagDocumentTempletesSlice.actions;

// Thunks
export const fetchTagsDocumentTempletes = createAsyncThunk(
  "tagsDocumentTempletes/fetchTagsDocumentTempletes",
  async (__, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      const response = await getTagsByCompanyAndResourceType({
        companyId,
        resourceType: "DOCUMENT_TEMPLETES",
      });

      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response);
    }
  }
);

export const modifyTagsDocumentTempletes = createAsyncThunk(
  "tagsDocumentTempletes/modifyTagsDocumentTempletes",
  async (data, { rejectWithValue }) => {
    try {
      let obj = {
        id: data.id,
        updateLabel: data.updateLabel,
        resourceType: "DOCUMENT_TEMPLETES",
      };

      const responseUpdate = await updateTag(obj);

      if (
        responseUpdate?.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseUpdate?.data?.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          companyId: companyId,
          resourceType: "DOCUMENT_TEMPLETES",
        };
        const responseGetAll = await getTagsByCompanyAndResourceType(obj);
        return {
          status: responseUpdate.status,
          data: responseUpdate.data,
          dataToAdd: responseGetAll.data.responseMessage,
        };
      }

      return {
        status: responseUpdate.status,
        data: responseUpdate.data,
        update: data.updateLabel,
        id: data.id,
      };
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response);
    }
  }
);

export const deleteTagDocumentTempletes = createAsyncThunk(
  "tagsDocumentTempletes/deleteTagDocumentTempletes",
  async (id, { rejectWithValue }) => {
    try {
      const responseDelete = await deleteTag({
        id,
        resourceType: "DOCUMENT_TEMPLETES",
      });
      if (
        responseDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDelete.data.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          companyId: companyId,
          resourceType: "DOCUMENT_TEMPLETES",
        };
        const responseGetAll = await getTagsByCompanyAndResourceType(obj);
        return {
          status: responseDelete.status,
          data: responseDelete.data,
          dataToAdd: responseGetAll.data.responseMessage,
        };
      }
      return {
        id,
        status: responseDelete.status,
        data: responseDelete.data,
      };
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response);
    }
  }
);

export const addTagDocumentTempletes = createAsyncThunk(
  "tagsDocumentTempletes/addTagDocumentTempletes",
  async (data, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let obj = {
        ...data,
        company: companyId,
        resourceType: "DOCUMENT_TEMPLETES",
        corporateUnit,
      };

      const responseCreate = await createTag(obj);

      if (
        responseCreate.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        responseCreate.data.success
      ) {
        const companyId = localStorage.getItem("company");

        let obj = {
          companyId: companyId,
          resourceType: "DOCUMENT_TEMPLETES",
        };
        const responseGetAll = await getTagsByCompanyAndResourceType(obj);
        return {
          status: responseCreate.status,
          data: responseCreate.data,
          dataToAdd: responseGetAll.data.responseMessage,
        };
      }
      return {
        status: responseCreate.status,
        data: responseCreate.data,
      };
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response);
    }
  }
);

export default tagDocumentTempletesSlice.reducer;
