import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//Material Import
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Button } from "@mui/material";
//Parts
import Breadcrum from "../../../../../parts/breadcrum";
//Components
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
import DiscussionBoard from "../../../../../components/DiscussionBoard/DiscussionBoard";

import ModalInfo from "../../../../../components/Modals/modalInfo";
//Icons
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addUnitCorporative,
  getUnitsCoporativesStatusAdd,
} from "./unitCorporativeSlice";
import { CODES } from "../../../../../utils/codes";
import { IOSSwitch } from "../../../../../components/switch/switch";
//Utils

const CreateUnitCorporative = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseDataInfo, setResponseDataInfo] = useState({});
  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const statusAdd = useSelector(getUnitsCoporativesStatusAdd);
  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es requerido"),
    description: yup.string().required("*Este campo es requerido"),
    ans: yup.object().shape({
      contract: yup
        .number()
        .typeError("*Ingrese un número válido")
        .max(999, "*Este campo solo permite 3 caracteres")
        .min(1, "*Solo se permiten números mayores a 0")
        .required("*Este campo es requerido"),
      other: yup
        .number()
        .typeError("*Ingrese un número válido")
        .max(999, "*Este campo solo permite 3 caracteres")
        .min(1, "*Solo se permiten números mayores a 0")
        .required("*Este campo es requerido"),
      // documents: yup.number()
      //   .typeError("*Ingrese un número válido")
      //   .max(999, "*Este campo solo permite 3 caracteres")
      //   .min(1, "*Solo se permiten números mayores a 0")
      //   .required("*Este campo es requerido"),
    }),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleCloseModalInfo = () => {
    if (responseDataInfo.status === CODES.COD_RESPONSE_HTTP_CREATED) {
      navigate(-1);
      return;
    }
    setIsOpenModalInfo(false);
  };
  const handleCreateUnitCorporative = async (data) => {
    try {
      setIsLoading(true);
      let obj = {
        name: data.name,
        description: data.description,
        isCheckValidCERL: data.isCheckValidCERL,
        ANSValues: {
          ansContracts: data?.ans?.contract || 4,
          ansOthers: data?.ans?.documents || 3,
          ansOthersYes: data?.ans?.other || 4,
        },
      };
      const response = await dispatch(addUnitCorporative(obj)).unwrap();
      if (
        !response.data.success &&
        response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setResponseDataUnauthorized(response);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseDataInfo(response);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.log("==== HandleCreateUnitCorporative Error ====");
      console.log(error);
      console.log("==== Finish HandleCreateUnitCorporative Error ====");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (statusAdd === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusAdd]);
  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Crear unidad corporativa" />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Badge
            style={{ cursor: "pointer" }}
            badgeContent={0}
            color="primary"
            onClick={() => {
              setIsHideDiscussion(false);
            }}
          >
            <NotificationsNoneIcon fontSize="large" color="#00374F" />
          </Badge>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="label__description">
          Llena los siguientes campos para la unidad corporativa.
        </Col>
      </Row>
      <br />
      <br />
      <Form onSubmit={handleSubmit(handleCreateUnitCorporative)}>
        <Row className="mb-5">
          <Form.Group as={Col} xs={4}>
            <Form.Control
              {...register("name")}
              placeholder={"Nombre de la unidad corporativa"}
              bsPrefix={
                errors.name
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors.name?.message}
            </p>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} xs={4}>
            <Form.Control
              {...register("description")}
              placeholder={"Descripción de la unidad corporativa"}
              bsPrefix={
                errors.description
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              as="textarea"
              rows="4"
            />
            <p className="caption custom-input__error">
              {errors.description?.message}
            </p>
          </Form.Group>
        </Row>
        <Row>
          <label className="label__subtitle">
            Acuerdos de nivel de servicios (ANS)
          </label>
          <label className="label__description">
            Gestiona el tiempo máximo que debe tomar la solución de solicitudes
            para esta <b>unidad corporativa</b> definiendo los ANS
          </label>
          <label className="label__description">Número de días para:</label>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col xs={"auto"}>
            <label className="label__description">Contratos:</label>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={1}>
            <Form.Control
              maxLength={3}
              defaultValue={4}
              autoComplete="nope"
              {...register("ans.contract")}
              bsPrefix={
                errors?.ans?.contract
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            {errors?.ans?.contract && (
              <p className="caption custom-input__error">
                {errors?.ans?.contract?.message}
              </p>
            )}
          </Col>
          <Col xs={"auto"}>
            <label className="label__description">Otrosí:</label>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={1}>
            <Form.Control
              maxLength={3}
              defaultValue={3}
              autoComplete="nope"
              {...register("ans.other")}
              bsPrefix={
                errors?.ans?.other
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            {errors?.ans?.other && (
              <p className="caption custom-input__error">
                {errors?.ans?.other?.message}
              </p>
            )}
          </Col>
          {/*
          // El campo Otros Documentos se oculto ya que aun faltan HUs por implementar para completar su funcionalidad

          <Col xs={"auto"}>
            <label className="label__description">Otros documentos:</label>
          </Col>
          <Col xs={"auto"} md={"auto"} lg={1}>
            <Form.Control
              maxLength={3}
              defaultValue={4}
              autoComplete="nope"
              {...register("ans.documents")}
              bsPrefix={
                errors?.ans?.documents
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            {errors?.ans?.documents && (
              <p className="caption custom-input__error">
                {errors?.ans?.documents?.message}
              </p>
            )}
          </Col> */}
        </Row>
        <Row>
          <label className="label__description__primary__hover">
            Máximo 3 caracteres permitidos por campo.
          </label>
        </Row>
        <br />
        <br />
        <Row>
          <label className="label__subtitle">
            Certificado de Existencia y Representación Legal
          </label>
        </Row>
        <Row className="mb-3 align-items-center">
          <Col xs={"auto"}>
            <span className="label__description">
              ¿Quieres que el sistema compruebe que el Certificado de Existencia
              y Representación Legal haya sido expedido en los últimos 30 días?
            </span>
          </Col>
          <Col xs={"auto"}>
            <span className="label__description">No</span>
          </Col>

          <Col xs={"auto"}>
            <Controller
              control={control}
              name="isCheckValidCERL"
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <IOSSwitch
                  onChange={onChange}
                  sx={{ m: 1 }}
                  checked={value}
                  colorbase={"#00374F"}
                />
              )}
            />
          </Col>
          <Col xs={"auto"}>
            <span className="label__description">Si</span>
          </Col>
        </Row>

        <Row xs={"auto"}>
          <Col>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Crear unidad corporativa
            </Button>
          </Col>
        </Row>
        <Row className="mb-5"></Row>
      </Form>

      <ModalInfo
        title={"Información unidad corporativa"}
        responseData={responseDataInfo}
        onClose={handleCloseModalInfo}
        open={isOpenModalInfo}
      />
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={[]}
        id={""}
        addComment={false}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default CreateUnitCorporative;
