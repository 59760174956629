import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { Button, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import BlockerPage from "../../../../../../components/blocker/blocker";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateCategories from "../../../../../../components/Modals/ModalAssociateCategories";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalPreviewClause from "../../../../../../components/Modals/ModalPreviewClause";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../parts/categories/clauseCategorySlice";
import {
  getClauseSelected,
  getCurrentDocument,
  setUpdateClause,
  setUpdateClauseIndex,
  setUpdateClauseThisDocument,
} from "../../../../../../parts/document/currentDocumentSlice";
import {
  fetchTags,
  getStatus,
  getTagList,
} from "../../../../../../parts/manageTags/tagSlice";
import {
  getSelectedClauses,
  setSelectedClauses,
  setSelectedSubClausesInClause,
} from "../../../../../../parts/minutas/clausesSelectedSlice";
import RichEditorText from "../../../../../../parts/RichEditorText";
import { getBlankSpaceIndex } from "../../../../../../utils/blankspaces";
import { CODES } from "../../../../../../utils/codes";
import {
  checkText,
  countPartErrors,
  plainTextTagsLinks,
} from "../../../../../../utils/partsCorrection";
import { CLAUSES_INFO } from "../../../../../../utils/popoverInfo";
import { REGEXP } from "../../../../../../utils/regexp";
import {
  fetchContractTypes,
  getContractsTypesList,
  getCurrentContractType,
  getStatus as getStatusContractTypes,
  setStatusFetchContractTypes,
} from "../contractTypes/contractTypesSlice";
import {
  getClauseBulkSelected,
  getClausesBulkSelectedId,
  getSegmentedDocument,
  setUpdateClauseBulkCreate,
} from "./clauseBulkSlice";
import NumeralsModule from "./clausesComponents/numeralsModule";
import ParagraphModule from "./clausesComponents/paragraphsModule";
import { addNewClause, modifyClause } from "./clauseSlice";
import { changeNameParts } from "./utils";
const EditDocumentClause = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);
  const currentDocumentClause = useSelector(getClauseSelected);
  const currentContractType = useSelector(getCurrentContractType);
  const { clauses, parts: currentPartsDocumentAssociated } =
    useSelector(getCurrentDocument);
  const currentClauseBulkCreateSelected = useSelector(getClauseBulkSelected);
  const segmentedDocument = useSelector(getSegmentedDocument);
  const selectedClauses = useSelector(getSelectedClauses);
  const contractTypeList = useSelector(getContractsTypesList);
  const statusContractTypes = useSelector(getStatusContractTypes);
  const clauseBulkSelectedId = useSelector(getClausesBulkSelectedId);
  const [partsErrorsModal, setPartsErrorsModal] = useState(false);
  const [saveType, setSaveType] = useState(0);
  const [currentData, setCurrentData] = useState({
    obj: "",
    data: "",
  });

  const [clause, setClause] = useState({
    title: "",
    parts: [],
    blankSpaces: [],
    sensitiveText: "",
    subClauses: [],
    paragraphs: [],
    text: "",
    inFavor: "",
    tags: [],
    categories: [],
  });
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalDecisionDelPart, setIsOpenModalDecisionDelPart] =
    useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [isSensitiveClause, setIsSensitiveClause] = useState(false);
  const [isOpenModalCategoriesEmpty, setIsOpenModalCategoriesEmpty] =
    useState(false);

  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [partDeleted, setPartDeleted] = useState(null);

  const [isOpenModalOverwriteDocument, setIsOpenModalOverwriteDocument] =
    useState(false);
  const [dataOverwriteDocument, setDataOverwriteDocument] = useState(null);
  const [blockNavigate, setBlockNavigate] = useState(true);
  const [currentDocument, setCurrentDocument] = useState(null);

  const [actions, setActions] = useState({
    saveInLibrary: false,
    overwriteClause: false,
    usedThisContract: false,
    saveChangesThisContract: false,
  });
  const [messageOverwriteClause, setMessageOverwriteClause] = useState(
    "¿Seguro quieres sobreescibir esta cláusula? la cláusula se actualizará en tu biblioteca."
  );
  const [currentPartsDocument, setCurrentPartsDocument] = useState([]);
  const [messageModalOverwriteClause, setMessageModalOverwriteClause] =
    useState(
      "La cláusula guardará solo en este contrato porque puede estar en algunas minutas con un número diferente de partes."
    );
  const [isOpenModalInfoPartsClause, setIsOpenModalInfoPartsClause] =
    useState(false);

  const schema = yup.object().shape({
    title: yup.string().required("*Este campo es obligatorio"),
    parts: yup.array().of(
      yup.object().shape({
        title: yup.string().required("*Este campo es obligatorio"),
      })
    ),

    sensitiveClauseText: !isSensitiveClause
      ? yup.string().nullable().default("")
      : yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.LETTERS_COMMAS, {
            message: "*Solo debe incluir letras",
          }),

    inFavor:
      params.id === "UsedThisContract"
        ? null
        : yup
            .string("*Este campo debe ser un numero")
            .required("*Este campo es obligatorio"),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parts: [{ title: "" }, { title: "" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: partsFields,
    append: partsAppend,
    remove: partsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parts", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const clauseRichEditorTextRef = useRef();
  const subClauseRichEditorTextRef = useRef([]);
  const paragraphRichEditorTextRef = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("CLAUSES"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (statusCategories === "loading" || statusTags === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusCategories, statusTags]);

  useEffect(() => {
    setIsLoading(true);
    if (
      currentDocument &&
      currentPartsDocument?.length &&
      params.id !== "bulkCreate"
    ) {
      const prevParts = [
        { title: currentPartsDocument[0].name },
        { title: currentPartsDocument[1].name },
      ];
      if (currentPartsDocument[2]) {
        prevParts.push({ title: currentPartsDocument[2].name });
      }
      if (currentPartsDocument[3]) {
        prevParts.push({ title: currentPartsDocument[3].name });
      }
      reset({
        parts:
          params.id !== "reviewDoc"
            ? prevParts
            : [{ title: "" }, { title: "" }],
        subClauses: buildPrevNumerals(currentDocument.subclauses),
        paragraphs: buildPrevParagraphs(currentDocument.paragraphs),
      });
      setValue("title", currentDocument.title);

      const parts = prevParts.map((part) => part.title);

      const clauseText = changeNameParts(currentDocument.text, parts);

      clauseRichEditorTextRef.current.setEditorContents(
        plainTextTagsLinks(clauseText)
      );

      if (params.id !== "reviewDoc") {
        setValue("parts", prevParts);
      }

      setValue("clauseText", clauseText);
      buildPrevClauseBlankSpaces(currentDocument.blankspaces);

      setIsSensitiveClause(currentDocument.isSensitive || false);
      setValue("sensitiveClauseText", currentDocument.sensitiveText);

      setCategoriesSelected(currentDocument.categories);
      setTagsSelected(currentDocument.labels);
      setValue("inFavor", currentDocument.inFavor);
    }
    setIsLoading(false);
  }, [currentDocument]);

  useEffect(() => {
    setIsLoading(true);
    if (currentClauseBulkCreateSelected && params.id === "bulkCreate") {
      const prevParts = [
        { title: currentClauseBulkCreateSelected?.parts?.partA },
        { title: currentClauseBulkCreateSelected?.parts?.partB },
      ];
      if (currentClauseBulkCreateSelected.parts[2]) {
        prevParts.push({ title: currentClauseBulkCreateSelected.parts[2] });
      }
      if (currentClauseBulkCreateSelected.parts[3]) {
        prevParts.push({ title: currentClauseBulkCreateSelected.parts[3] });
      }
      reset({
        parts: prevParts,
        subClauses: buildPrevNumerals(
          currentClauseBulkCreateSelected.subclauses
        ),
        paragraphs: buildPrevParagraphs(
          currentClauseBulkCreateSelected.paragraphs
        ),
      });
      setValue("title", currentClauseBulkCreateSelected.title);

      clauseRichEditorTextRef.current.setEditorContents(
        currentClauseBulkCreateSelected.text
      );
      setValue("clauseText", currentClauseBulkCreateSelected.text);
      buildPrevClauseBlankSpaces(currentClauseBulkCreateSelected.blankspaces);

      setIsSensitiveClause(currentClauseBulkCreateSelected.isSensitive);
      setValue(
        "sensitiveClauseText",
        currentClauseBulkCreateSelected.sensitiveText
      );

      setCategoriesSelected(currentClauseBulkCreateSelected.categories);
      setTagsSelected(currentClauseBulkCreateSelected.labels);
      setValue("inFavor", currentClauseBulkCreateSelected.inFavor);
    }
    setIsLoading(false);
  }, [currentClauseBulkCreateSelected]);

  useEffect(() => {
    if (!segmentedDocument && params.id === "bulkCreate") {
      navigate(-1);
    }
  }, [segmentedDocument]);

  const buildPrevNumerals = (numerals) => {
    const numeralsArr = [];
    for (const [idx, item] of numerals.entries()) {
      const subclauseText =
        item?.subclause?.text || item?.newSubclause?.text || "";
      const newSubclauseText = changeNameParts(
        subclauseText,
        currentPartsDocument
      );
      const obj = {
        title: item?.subclause?.title || item?.newSubclause?.title,
        text: newSubclauseText,
        blankSpaces: buildPrevNumeralsBlankSpaces(
          item?.subclause?.blankspaces || item?.newSubclause?.blankspaces
        ),
        isImported: true,
        numerals: buildPrevSubNumerals(
          item?.subclause?.childs || item?.newSubclause?.childs
        ),
        id: item?.subclause?._id || item?.newSubclause?._id,
      };
      numeralsArr.push(obj);
      setClause((clause) => ({
        ...clause,
        subClauses: [...clause.subClauses, obj],
      }));
      subClauseRichEditorTextRef.current[idx] =
        subClauseRichEditorTextRef.current[idx] ?? createRef();
    }
    return numeralsArr;
  };

  const buildPrevParagraphs = (paragraphs) => {
    const paragraphsArr = [];
    for (const [idx, item] of paragraphs.entries()) {
      const paragraphText = item?.paragraph?.text || item?.text || "";
      const newParagraphText = changeNameParts(
        paragraphText,
        currentPartsDocument
      );
      const blankSpacesParagraphs =
        item?.paragraph?.blankspaces || item?.blankspaces || [];
      const obj = {
        title: "",
        text: newParagraphText,
        isImported: true,
        blankspaces: buildPrevNumeralsBlankSpaces(blankSpacesParagraphs),
      };
      paragraphsArr.push(obj);
      setClause((clause) => ({
        ...clause,
        paragraphs: [...clause.paragraphs, obj],
      }));
      paragraphRichEditorTextRef.current[idx] =
        paragraphRichEditorTextRef.current[idx] ?? createRef();
    }
    return paragraphsArr;
  };

  const buildPrevClauseBlankSpaces = (blankspaces) => {
    let newBlankspaces = [];
    if (blankspaces) {
      newBlankspaces = blankspaces?.map((blankSpace) => {
        clauseRichEditorTextRef?.current?.setBlankSpacesValues(
          blankSpace.index
        );

        return {
          id: blankSpace.index,
          name: blankSpace.name,
        };
      });
    }
    setClause((prevClause) => ({
      ...prevClause,
      blankSpaces: newBlankspaces,
    }));
  };

  const buildPrevNumeralsBlankSpaces = (blankspaces) => {
    let blankSpacesArr = [];
    if (blankspaces) {
      for (const blankSpace of blankspaces) {
        blankSpacesArr.push({
          id: blankSpace.index,
          name: blankSpace.name,
        });
      }
    }
    return blankSpacesArr;
  };

  const buildPrevSubNumerals = (subNumerals = []) => {
    const subNumeralsArr = [];
    for (const item of subNumerals) {
      const newText = changeNameParts(item.text, currentPartsDocument);
      const obj = {
        title: item.title || "",
        text: newText,
        blankSpaces: buildPrevSubNumeralsBlankSpaces(item.blankspaces),
        isImported: true,
      };
      subNumeralsArr.push(obj);
    }
    return subNumeralsArr;
  };

  const buildPrevSubNumeralsBlankSpaces = (blankspaces) => {
    const blankSpacesArr = [];
    for (const blankSpace of blankspaces) {
      blankSpacesArr.push({
        id: blankSpace.index,
        name: blankSpace.name,
      });
    }
    return blankSpacesArr;
  };

  const handleClose = () => {
    setBlockNavigate(false);
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalCategoriesEmpty = () => {
    setIsOpenModalCategoriesEmpty(false);
  };

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleBlankSpaces = (blankSpace) => {
    let blankSpaceId = getBlankSpaceIndex(clause.blankSpaces);
    let item = {
      id: blankSpaceId,
      name: `blankSpace-${blankSpaceId}`,
    };
    setClause((clause) => ({
      ...clause,
      blankSpaces: [...clause.blankSpaces, item],
    }));
    clauseRichEditorTextRef.current.addBlankSpace(clause.blankSpaces);
  };

  const removeBlankSpace = (index) => {
    setClause((clause) => ({
      ...clause,
      blankSpaces: clause.blankSpaces.filter(
        (blankSpace) => blankSpace.id !== index
      ),
    }));
    clauseRichEditorTextRef.current.removeBlankSpace(index);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    setBlockNavigate(false);
    navigate(-1);
  };

  const handleCloseModalDesicionDelPart = () => {
    setIsOpenModalDecisionDelPart(false);
  };

  const handleDisagreeModalDesicionDelPart = () => {
    handleCloseModalDesicionDelPart();
  };

  const handleAgreeModalDesicionDelPart = () => {
    partsRemove(partDeleted);
    handleCloseModalDesicionDelPart();
  };

  const handleDeletePart = async (idx) => {
    setPartDeleted(idx);
    setIsOpenModalDecisionDelPart(true);
  };

  const buildNumerals = (numerals) => {
    const newNumerals = [];
    for (const [index, subClause] of numerals.entries()) {
      const clauseNumeral = {
        index: index + 1,
        subClauseId: subClause?.id || null,
        newSubclause: {
          ...subClause,
          title: subClause.title,
          text: plainTextTagsLinks(subClause.text),
          childs: [],
          blankspaces: buildBlankspaces(clause.subClauses[index].blankSpaces),
        },
      };
      for (const [subNumeralIdx, subNumeral] of subClause.numerals.entries()) {
        const numeral = {
          index: subNumeralIdx + 1,
          title: subNumeral.title,
          text: plainTextTagsLinks(subNumeral.text),
          blankspaces: buildBlankspaces(
            clause.subClauses[index].numerals[subNumeralIdx].blankSpaces
          ),
        };
        clauseNumeral.newSubclause.childs.push(numeral);
      }
      newNumerals.push(clauseNumeral);
    }

    return newNumerals;
  };

  const buildNumeralsDos = (numerals) => {
    const newNumerals = [];
    for (const [index, subClause] of numerals.entries()) {
      const clauseNumeral = {
        index: index + 1,
        _id: null,
        subclause: {
          title: subClause.title,
          text: plainTextTagsLinks(subClause.text),
          childs: [],
          blankspaces: buildBlankspaces(clause.subClauses[index].blankSpaces),
        },
      };
      for (const [subNumeralIdx, subNumeral] of subClause.numerals.entries()) {
        const numeral = {
          index: subNumeralIdx + 1,
          title: subNumeral.title,
          text: plainTextTagsLinks(subNumeral.text),
          blankspaces: buildBlankspaces(
            clause.subClauses[index].numerals[subNumeralIdx].blankSpaces
          ),
        };
        clauseNumeral.subclause.childs.push(numeral);
      }
      newNumerals.push(clauseNumeral);
    }

    return newNumerals;
  };

  const buildParagraphs = (paragraphs) => {
    const newParagraphs = [];
    for (const [index, paragraph] of paragraphs.entries()) {
      const clauseParagraph = {
        index: index + 1,
        text: plainTextTagsLinks(paragraph.text),
        blankspaces: buildBlankspaces(clause.paragraphs[index].blankspaces),
      };
      newParagraphs.push(clauseParagraph);
    }
    return newParagraphs;
  };

  const buildBlankspaces = (blankSpaces) => {
    const newBlankspaces = [];
    for (const [index, blankSpace] of blankSpaces.entries()) {
      const clauseBlankspaces = {
        index: index + 1,
        name: blankSpace.name,
        value: "",
        id: blankSpace?.id,
      };
      newBlankspaces.push(clauseBlankspaces);
    }
    return newBlankspaces;
  };

  const buildCategories = (categories) => {
    const newCategories = [];
    for (const category of categories) {
      newCategories.push(category._id);
    }
    return newCategories;
  };

  const buildTags = (tags) => {
    const newTags = [];
    for (const tag of tags) {
      newTags.push(tag._id);
    }
    return newTags;
  };

  const handleUpdateClause = async (data) => {
    try {
      setIsLoading(true);
      if (categoriesSelected?.length === 0) {
        setIsOpenModalCategoriesEmpty(true);
        setIsLoading(false);
        return;
      }
      const numerals = buildNumerals(data.subClauses);
      const paragraphs = buildParagraphs(data.paragraphs);
      const categories = buildCategories(categoriesSelected);
      const tags = buildTags(tagsSelected);
      const blankspaces = buildBlankspaces(clause.blankSpaces);

      const obj = {
        clauseContent: {
          title: data.title,
          parts: {
            partA: data.parts[0].title,
            partB: data.parts[1].title,
            partC: data.parts[2]?.title || null,
            partD: data.parts[3]?.title || null,
          },
          text: plainTextTagsLinks(data.clauseText),
          subclauses: numerals,
          paragraphs: paragraphs,
          categories: categories,
          labels: tags,
          inFavor: data.inFavor,
          isSensitive: isSensitiveClause,
          sensitiveText: data.sensitiveClauseText,
          blankspaces,
        },
        clauseId: params.id,
        timeZone: new Date().getTimezoneOffset() / -60,
      };
      const responseDispatch = await dispatch(modifyClause(obj)).unwrap();
      if (
        responseDispatch.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDispatch.data.success
      ) {
        dispatch(setStatusFetchContractTypes({ status: "fetch" }));
      }
      setResponseData(responseDispatch);
      setIsOpenModal(true);
      return responseDispatch;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveChanges = (data, id = params.id) => {
    try {
      const paragraphs = [];
      for (const [index, paragraph] of data.paragraphs.entries()) {
        const clauseParagraph = {
          paragraph: {
            index: index + 1,
            text: plainTextTagsLinks(paragraph.text),
            blankspaces: buildBlankspaces(clause.paragraphs[index].blankspaces),
          },
        };
        paragraphs.push(clauseParagraph);
      }

      let getParts = [data.parts[0].title, data.parts[1].title];

      if (data.parts[2]) {
        getParts.push(data.parts[2].title);
      }
      if (data.parts[3]) {
        getParts.push(data.parts[3].title);
      }

      const obj = {
        ...currentDocument,
        _id: id,
        title: data.title,
        parts: getParts,
        blankspaces: buildBlankspaces(clause.blankSpaces),
        text: plainTextTagsLinks(data.clauseText),
        subclauses: buildNumeralsDos(data.subClauses),
        paragraphs: paragraphs,
        categories: categoriesSelected,
        labels: tagsSelected,
        inFavor: data.inFavor,
        isSensitive: isSensitiveClause,
        sensitiveText: data.sensitiveClauseText,
        id: id,
      };
      return obj;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUseOnlyThisDocument = (data) => {
    try {
      setBlockNavigate(false);
      const paragraphs = [];
      for (const [index, paragraph] of data.paragraphs.entries()) {
        const blankSpaces =
          clause?.paragraphs[index]?.blankSpaces ||
          clause?.paragraphs[index]?.blankspaces;
        const clauseParagraph = {
          paragraph: {
            index: index + 1,
            text: plainTextTagsLinks(paragraph.text),
            blankspaces: buildBlankspaces(blankSpaces),
          },
        };
        paragraphs.push(clauseParagraph);
      }

      let getParts = [data.parts[0].title, data.parts[1].title];

      if (data.parts[2]) {
        getParts.push(data.parts[2].title);
      }
      if (data.parts[3]) {
        getParts.push(data.parts[3].title);
      }
      let obj = {
        thisDocument: "true",
        title: data.title,
        parts: getParts,
        blankspaces: clause.blankSpaces && buildBlankspaces(clause.blankSpaces),
        text: plainTextTagsLinks(data.clauseText),
        subclauses: buildNumeralsDos(data.subClauses),
        paragraphs: paragraphs,
        categories: categoriesSelected,
        labels: tagsSelected,
        inFavor: data.inFavor,
        isSensitive: isSensitiveClause,
        sensitiveText: data.sensitiveClauseText,
        id: params.id,
      };

      setSaveType(1);
      setCurrentData({
        ...currentData,
        obj: obj,
        data: data,
      });

      let errorParts = handleCheckParts(data);

      if (errorParts > 0) {
        setPartsErrorsModal(true);
        setIsLoading(false);
        return;
      }

      dispatchOnlyThisDocument(obj, data);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatchOnlyThisDocument = (obj, data) => {
    if (params.id.match(REGEXP.MONGO_OBJECT_ID)) {
      obj = {
        ...obj,
        _id: params.id,
      };
      dispatch(
        setUpdateClause({
          clause: obj,
          id: params.id,
        })
      );
    }

    if (params.id === "UsedThisContract") {
      let index;
      clauses.forEach((clause, idx) => {
        if (clause.clause === currentDocument) {
          index = idx;
        }
      });
      dispatch(
        setUpdateClauseThisDocument({
          clauses: obj,
          index: index,
        })
      );
    }

    //BulkCreateClauseUpdate
    if (params.id === "bulkCreate") {
      let idxBulkCreateClause = null;
      segmentedDocument.forEach((clause, idx) => {
        if (idx === clauseBulkSelectedId) {
          idxBulkCreateClause = idx;
        }
      });
      dispatch(
        setUpdateClauseBulkCreate({
          clause: {
            ...obj,
            parts: {
              partA: data?.parts[0].title,
              partB: data?.parts[1].title,
              partC: data?.parts[2]?.title || null,
              partD: data?.parts[3]?.title || null,
            },
            subclauses: buildNumerals(data.subClauses),
          },
          index: idxBulkCreateClause,
        })
      );
    }

    if (params.id === "reviewDoc") {
      dispatch(
        setUpdateClauseIndex({
          clause: obj,
          index: params.id,
        })
      );
    }
    setResponseData({
      status: 200,
      data: {
        responseMessage: {
          message: "La cláusula ha sido editada exitosamente",
        },
      },
    });
    setIsOpenModal(true);
    setIsLoading(false);
  };

  const handleModalConfirmationOverwriteDocument = (data) => {
    setSaveType(3);
    setCurrentData({
      ...currentData,
      obj: {},
      data: data,
    });

    let errorParts = handleCheckParts(data);

    if (errorParts > 0) {
      setPartsErrorsModal(true);

      return;
    }
    setIsLoading(false);

    dispatchOverwriteDocument(data);
  };

  const dispatchOverwriteDocument = (data) => {
    setDataOverwriteDocument({
      ...data,
      company: currentDocument.company,
      corporateUnit: currentDocument.company,
    });
    setIsOpenModalOverwriteDocument(true);
  };

  const handleOverwriteDocument = async () => {
    try {
      setIsOpenModalOverwriteDocument(false);
      const data = dataOverwriteDocument;
      const clausesUpdate = await handleUpdateClause(data);
      const clauseSelected = clausesUpdate.clausesList.find(
        (clause) => clause._id === params.id
      );
      const obj = handleSaveChanges(data);
      dispatch(
        setUpdateClause({
          clause: obj,
          id: params.id,
        })
      );
      if (location.pathname.includes("library/contractTypes")) {
        const newClausesSelected = selectedClauses.map((clause) => {
          if (clause._id === params.id)
            return {
              ...clauseSelected,
              parts: {
                type: "",
                partA: data.parts[0].title,
                partB: data.parts[1].title,
                partC: data.parts[2]?.title || null,
                partD: data.parts[3]?.title || null,
              },
            };
          return clause;
        });
        dispatch(setSelectedClauses(newClausesSelected));
        dispatch(
          setSelectedSubClausesInClause({
            clauseId: clauseSelected._id,
            subClauses: clauseSelected.subclauses,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateClause = async (data) => {
    try {
      if (data.inFavor === "") {
        setError("inFavor", {
          type: "required",
          message: "*Este campo es obligatorio",
        });
        return;
      }
      setIsLoading(true);
      if (categoriesSelected?.length === 0) {
        setIsOpenModalCategoriesEmpty(true);
        setIsLoading(false);
        return;
      }

      const numerals = buildNumerals(data.subClauses);
      const paragraphs = buildParagraphs(data.paragraphs);
      const clauseBlankSpaces = clause.blankSpaces
        ? buildBlankspaces(clause.blankSpaces)
        : [];
      const categories = buildCategories(categoriesSelected);
      const tags = buildTags(tagsSelected);

      const obj = {
        title: data.title,
        parts: {
          partA: data.parts[0].title,
          partB: data.parts[1].title,
          partC: data.parts[2]?.title || null,
          partD: data.parts[3]?.title || null,
        },
        blankspaces: clauseBlankSpaces,
        text: plainTextTagsLinks(data.clauseText),
        subclauses: numerals,
        paragraphs: paragraphs,
        categories: categories,
        labels: tags,
        inFavor: data.inFavor,
        isSensitive: isSensitiveClause || false,
        sensitiveText: data.sensitiveClauseText,
      };

      setSaveType(2);
      setCurrentData({
        ...currentData,
        obj: obj,
        data: data,
      });

      let errorParts = handleCheckParts(data);

      if (errorParts > 0) {
        setPartsErrorsModal(true);
        setIsLoading(false);
        return;
      }

      handleDispatchSave(obj, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDispatchSave = async (obj, data) => {
    setIsLoading(true);
    const responseDispatch = await dispatch(addNewClause(obj)).unwrap();

    if (responseDispatch.status === CODES.COD_RESPONSE_HTTP_CREATED) {
      const idNew = responseDispatch.data.responseMessage.data._id;

      const objToSave = !location.pathname.includes(
        "service/review/first-Time/view-document/edit-clause"
      )
        ? handleSaveChanges(data, idNew)
        : obj;
      if (params.id !== "reviewDoc") {
        dispatch(
          setUpdateClause({
            clause: objToSave,
            id: params.id,
          })
        );
      }
      if (location.pathname.includes("library/contractTypes")) {
        const newClausesSelected = selectedClauses.map((clause) => {
          if (clause._id === params.id)
            return {
              ...objToSave,
              parts: {
                partA: objToSave.parts[0],
                partB: objToSave.parts[1],
                partC: objToSave.parts[2] || null,
                partD: objToSave.parts[3] || null,
              },
            };
          return clause;
        });
        dispatch(setSelectedClauses(newClausesSelected));
        navigate(-1);
      }
    }
    setResponseData(responseDispatch);
    setIsLoading(false);
    setIsOpenModalCreate(true);
  };

  const handleIsClausesSensitive = (e) => {
    let checked = e.target.checked;
    setIsSensitiveClause(checked);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
  };

  const buildActions = (moduleLocation, clause) => {
    if (moduleLocation === "bulkCreate") {
      return {
        saveInLibrary: false,
        overwriteClause: false,
        usedThisContract: false,
        saveChangesThisContract: true,
      };
    }
    if (moduleLocation === "reviewDoc") {
      return {
        saveInLibrary: true,
        overwriteClause: false,
        usedThisContract: false,
        saveChangesThisContract: false,
      };
    }
    if (
      moduleLocation.match(REGEXP.MONGO_OBJECT_ID) &&
      clause?.company &&
      clause?.corporateUnit
    ) {
      return {
        saveInLibrary: true,
        overwriteClause: true,
        usedThisContract: true,
        saveChangesThisContract: false,
      };
    } else if (moduleLocation.match(REGEXP.MONGO_OBJECT_ID)) {
      return {
        saveInLibrary: true,
        overwriteClause: false,
        usedThisContract: true,
        saveChangesThisContract: false,
      };
    }
    if (moduleLocation === "UsedThisContract") {
      return {
        saveInLibrary: true,
        overwriteClause: false,
        usedThisContract: true,
        saveChangesThisContract: false,
      };
    }

    return {
      saveInLibrary: false,
      overwriteClause: false,
      usedThisContract: false,
      saveChangesThisContract: false,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusContractTypes === "fetch") {
          fetchContractTypes({ isPublish: false });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusContractTypes, dispatch]);

  useEffect(() => {
    if (
      location.pathname.includes("library/contractTypes") &&
      currentContractType
    ) {
      const clauseSelected = currentContractType.clauses.find(
        (clause) => clause._id === params.id
      );
      const transFormParts = [
        clauseSelected.parts.partA,
        clauseSelected.parts.partB,
      ];
      if (clauseSelected.parts.partC)
        transFormParts.push(clauseSelected.parts.partC);
      if (clauseSelected.parts.partD)
        transFormParts.push(clauseSelected.parts.partD);
      const newClause = {
        ...clauseSelected,
        parts: transFormParts,
      };
      const actions = {
        saveInLibrary: false,
        overwriteClause: true,
        usedThisContract: false,
        saveChangesThisContract: false,
      };
      const contractTypesClause = contractTypeList.filter((contractType) =>
        contractType.clauses.some((clause) => {
          const id = clause?.clause?._id || clause?._id;
          return id === params.id;
        })
      );
      setActions(actions);
      setCurrentDocument(newClause);
      setCurrentPartsDocument(currentContractType.parts);
      setMessageOverwriteClause(
        <p>
          Estás a punto de actualizar una o más cláusulas que están en las
          siguientes minutas:{" "}
          <ul>
            {contractTypesClause.map((contractType) => (
              <li key={contractType?._id}>{contractType.name}</li>
            ))}
          </ul>
        </p>
      );

      setMessageModalOverwriteClause(
        <p>
          La cláusula se guardará solo en esta minuta porque está en las
          siguientes minutas con un número diferente de partes:{" "}
          <ul>
            {contractTypesClause.map((contractType) => (
              <li key={contractType?._id}>{contractType.name}</li>
            ))}
          </ul>
        </p>
      );
    } else {
      setCurrentPartsDocument(currentPartsDocumentAssociated);
      setCurrentDocument(currentDocumentClause);
      const actions = buildActions(params.id, currentDocumentClause);
      setActions(actions);
    }
  }, [location, currentDocumentClause, currentContractType, params]);

  const handleCheckParts = (clause) => {
    let partsErrorsArray = [];
    let errorsClause = checkText({
      text: clause.clauseText ? clause.clauseText : clause.text,
    });

    partsErrorsArray.push(...errorsClause);

    if (clause.subClauses) {
      for (const subclause of clause.subClauses) {
        let errorsSubclause = checkText(subclause);
        partsErrorsArray.push(...errorsSubclause);

        for (const numeral of subclause.numerals) {
          let errorsNumeral = checkText(numeral);
          partsErrorsArray.push(...errorsNumeral);
        }
      }
    } else {
      for (const subclause of clause.subclauses) {
        let errorsSubclause = checkText(subclause);
        partsErrorsArray.push(...errorsSubclause);

        for (const numeral of subclause.numerals) {
          let errorsNumeral = checkText(numeral);
          partsErrorsArray.push(...errorsNumeral);
        }
      }
    }

    for (const paragraph of clause.paragraphs) {
      let errorsParagraph = checkText(paragraph);
      partsErrorsArray.push(...errorsParagraph);
    }
    return countPartErrors(partsErrorsArray);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {location.pathname.includes("edit-clause") &&
        !location.pathname.includes("bulkCreate") &&
        !location.pathname.includes("reviewDoc") &&
        !location.pathname.includes("contractTypes") && (
          <BlockerPage
            blockNavigate={blockNavigate}
            message={
              "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
            }
          />
        )}
      <Row></Row>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Actualizar cláusula"} />
        <IconButton aria-label="delete"></IconButton>
      </Row>
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleCreateClause)}>
          <Col
            xs={{ span: 6, offset: 0 }}
            className="create-clause-coachmark-step-1"
          >
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Título de la cláusula*
                <PopoverInfo section={CLAUSES_INFO.clauseTitle} />
              </Form.Label>
              <Form.Control
                {...register("title", {
                  onChange: (e) => setValue("title", e.target.value.trimLeft()),
                  setValueAs: (value) => value.trim(),
                })}
                placeholder="Título"
                bsPrefix={
                  errors.title
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.title?.message}
              </div>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Rol contractual*
                <PopoverInfo section={CLAUSES_INFO.partsDefinition} />
              </Form.Label>
              {partsFields.map((item, idx) => {
                return (
                  <Row key={item?.id}>
                    <Col xs={8}>
                      <Form.Control
                        {...register(`parts.${idx}.title`, {
                          onChange: (e) => {
                            setValue(`parts.${idx}.title`, e.target.value);
                          },
                        })}
                        disabled={!["reviewDoc"].includes(params.id)}
                        placeholder="Ej. Contratista, Contratante, etc."
                        bsPrefix={
                          errors?.parts?.[idx]?.title
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                    </Col>

                    <div className="caption custom-input__error  mb-4">
                      {errors?.parts?.[idx]?.title?.message}
                    </div>
                  </Row>
                );
              })}
            </Form.Group>
            {partsFields?.length < 4 &&
              (["reviewDoc", "bulkCreate"].includes(params.id) ? (
                <Row xs={"auto"} style={{ justifyContent: "right" }}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      partsAppend({
                        title: "",
                      });
                    }}
                  >
                    Parte
                  </Button>
                </Row>
              ) : (
                currentPartsDocument?.length > partsFields?.length && (
                  <Row xs={"auto"} style={{ justifyContent: "right" }}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        partsAppend({
                          title:
                            currentPartsDocument[partsFields.length]?.name ||
                            "",
                        });
                      }}
                    >
                      Parte
                    </Button>
                  </Row>
                )
              ))}
          </Col>
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Form.Group className="mb-5 create-clause-coachmark-step-2">
            <Row className="mb-2">
              <Col>
                <Row style={{ marginBottom: "3%" }}>
                  <Col>
                    <Form.Label className="heading mb-4">
                      1. Texto de la cláusula
                      <PopoverInfo section={CLAUSES_INFO.clauseText} />
                    </Form.Label>
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      {getValues("parts").map((item, idx) => {
                        if (watch(`parts.${idx}.title`)) {
                          return (
                            <Col
                              className="create-clause-coachmark-step-3"
                              xs={"auto"}
                              key={item?.title}
                            >
                              <Button
                                onClick={() =>
                                  clauseRichEditorTextRef.current.addPart(
                                    item.title,
                                    idx
                                  )
                                }
                                variant="contained"
                                className="custom-input__button__secondary-color"
                              >
                                {item.title}
                              </Button>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Col
                        className="create-clause-coachmark-step-4"
                        xs={"auto"}
                      >
                        <Button
                          variant="contained"
                          startIcon={<AddIcon fontSize="large" />}
                          className="custom-input__button__secondary-color"
                          onClick={() =>
                            handleBlankSpaces(clause?.blankSpaces?.length + 1)
                          }
                        >
                          Espacio en blanco
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "120rem" }}>
                  <Col xs={12}>
                    <Controller
                      control={control}
                      name="clauseText"
                      render={({ field: { onChange, onBlur } }) => (
                        <RichEditorText
                          ref={clauseRichEditorTextRef}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          customClassName={
                            errors.clauseText
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          deleteBlankSpace={(id) => {
                            setClause((clause) => ({
                              ...clause,
                              blankSpaces: clause.blankSpaces.filter(
                                (blankSpace) => blankSpace.id !== id
                              ),
                            }));
                          }}
                        />
                      )}
                    />
                    <div className="caption custom-input__error">
                      {errors.clauseText?.message}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <Container>
                  <Row>
                    <p className="label">Espacios en blanco:</p>
                  </Row>
                  {clause.blankSpaces?.map((item, idx) => {
                    return (
                      <Row xs={"auto"} key={item?.id}>
                        <p className="heading__primary-color">
                          ESPACIO #{item.id}{" "}
                          <IconButton onClick={() => removeBlankSpace(item.id)}>
                            <DeleteOutlineIcon
                              className="heading__primary-color"
                              fontSize="large"
                            />
                          </IconButton>
                        </p>
                      </Row>
                    );
                  })}
                </Container>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <NumeralsModule
              control={control}
              register={register}
              getValues={getValues}
              watch={watch}
              errors={errors}
              setClause={setClause}
              clause={clause}
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              setValue={setValue}
              subClauseRichEditorTextRef={subClauseRichEditorTextRef}
            />
          </Form.Group>
          <Row
            className="caption__disabled"
            style={{ marginLeft: "1%", alignItems: "center" }}
          >
            <Col xs={"auto"}>
              <InfoOutlinedIcon fontSize="large" />
            </Col>
            <Col>
              Para escribir en el numeral o subnumeral que quieras, haz clic por
              fuera de las casillas y luego haz clic en la casilla en la que
              quieres escribir.
            </Col>
          </Row>
          <br />
          <Divider />
          <br />
          <br />
          <Form.Group style={{ justifyContent: "right" }} className="mb-5">
            <ParagraphModule
              control={control}
              getValues={getValues}
              watch={watch}
              errors={errors}
              clause={clause}
              setClause={setClause}
              paragraphRichEditorTextRef={paragraphRichEditorTextRef}
            />
          </Form.Group>
          <Divider />
          <br />
          <br />

          <Form.Group className="mb-5 create-clause-coachmark-step-6">
            <Form.Label className="heading mb-4">
              Cláusula sensible
              <PopoverInfo section={CLAUSES_INFO.sensitiveClause} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSensitiveClause}
                        onChange={(e) => handleIsClausesSensitive(e)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          color: "#00374f",
                          "&.Mui-checked": {
                            color: "#00374f",
                          },
                        }}
                      />
                    }
                    label={<p className="body-content">Sensible</p>}
                  />
                </FormGroup>
              </Col>
              {isSensitiveClause && (
                <Col>
                  <Form.Control
                    {...register("sensitiveClauseText")}
                    bsPrefix={
                      errors.sensitiveClauseText
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div className="caption custom-input__error">
                    {errors.sensitiveClauseText?.message}
                  </div>
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Añadir Categorías
              <PopoverInfo section={CLAUSES_INFO.categories} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateCategories
                  categories={categoriesList}
                  handleCategoriesSelected={handleCategoriesSelected}
                  previousCategoriesSelected={categoriesSelected}
                />
              </Col>
              {categoriesSelected.map(
                (category) =>
                  category.name && (
                    <Col
                      xs={"auto"}
                      style={{ display: "flex", alignItems: "center" }}
                      key={category._id || category.name}
                    >
                      <span className="caption custom-badges__secundary">
                        {category.name + " "}
                        <ClearIcon
                          className="custom-badges__delete-button"
                          onClick={() => handleDeleteCategory(category)}
                        />
                      </span>
                    </Col>
                  )
              )}
            </Row>
          </Form.Group>

          <Form.Group xs={"auto"} className="mb-5">
            <Form.Label className="heading mb-4">
              A favor de
              <PopoverInfo section={CLAUSES_INFO.inFavor} />
            </Form.Label>
            <Row xs={"auto"}>
              <Form.Select
                className="label mb-1"
                defaultValue=""
                bsPrefix={
                  errors.inFavor
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("inFavor")}
              >
                <option className="label" value="" disabled>
                  Seleccione uno
                </option>
                <option className="label" value="Balanceada">
                  Balanceada
                </option>
                {getValues("parts").map((item, idx) => {
                  if (watch(`parts.${idx}.title`)) {
                    return (
                      <option
                        key={uuidv4()}
                        className="label"
                        value={item.title}
                      >
                        {item.title}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </Form.Select>
              <p className="caption custom-input__error">
                {errors.inFavor?.message}
              </p>
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Etiquetas
              <PopoverInfo section={CLAUSES_INFO.tags} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateTags
                  tags={tagsList}
                  handleTagsSelected={handleTagsSelected}
                  previousTagsSelected={tagsSelected}
                  type="CLAUSES"
                />
              </Col>
              {tagsSelected.map((tag, idx) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={uuidv4()}
                  >
                    <span className="caption custom-badges__disabled">
                      {tag.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>
          <Divider />
          <br />
          <br />
          <Row xs={"auto"}>
            <Col>
              <ModalPreviewClause clause={clause} hookFormInfo={watch} />
            </Col>
            <Col>
              <Button
                variant="contained"
                startIcon={<DeleteOutlineIcon fontSize="large" />}
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Descartar
              </Button>
            </Col>
            {actions.saveInLibrary && (
              <Col>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<LocalLibraryOutlinedIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                >
                  Guardar como nueva cláusula
                </Button>
              </Col>
            )}

            {actions.overwriteClause && (
              <Col>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<DifferenceOutlinedIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                  onClick={handleSubmit(
                    handleModalConfirmationOverwriteDocument
                  )}
                >
                  Sobreescribir cláusula
                </Button>
              </Col>
            )}
            {actions.usedThisContract && (
              <Col>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<ArticleOutlinedIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                  onClick={handleSubmit(handleUseOnlyThisDocument)}
                >
                  Usar solo en este contrato
                </Button>
              </Col>
            )}
            {actions.saveChangesThisContract && (
              <Col>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<SaveIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                  onClick={handleSubmit(handleUseOnlyThisDocument)}
                >
                  Guardar cambios
                </Button>
              </Col>
            )}
          </Row>
          <br />
          <br />
          <Divider />
        </Form>
      </Row>

      <ModalWarningForm
        title={"Advertencia"}
        description={
          "No se han asociado categorías a la clausula, estas son obligatorias"
        }
        open={isOpenModalCategoriesEmpty}
        onClose={handleCloseModalCategoriesEmpty}
      />

      <ModalInfo
        title={"Cambios guardados"}
        responseData={responseData}
        open={isOpenModalCreate}
        onClose={() => {
          setBlockNavigate(false);
          setIsOpenModalCreate(false);
          navigate(-1);
        }}
        buttonClose={true}
        handleCloseDefault={() => {
          setBlockNavigate(false);
          setIsOpenModalCreate(false);
          navigate(-1);
        }}
      />

      <ModalInfo
        title={"Cambios guardados"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
        buttonClose={true}
        handleCloseDefault={() => {
          setBlockNavigate(false);
          setIsOpenModal(false);
          navigate(-1);
        }}
      />

      <ModalDecision
        title={"Eliminar parte"}
        message={"¿Está seguro que desea eliminar la parte seleccionada?."}
        open={isOpenModalDecisionDelPart}
        onClose={handleCloseModalDesicionDelPart}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicionDelPart}
        handleDisagree={handleDisagreeModalDesicionDelPart}
      />

      <ModalDecision
        title={"Descartar"}
        message={
          "¿Seguro quieres cancelar? Los cambios en la cláusula se perderán y la cláusula no quedará guardada en tu biblioteca."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalDecision
        title={"Editar cláusula"}
        message={messageOverwriteClause}
        open={isOpenModalOverwriteDocument}
        onClose={() => setIsOpenModalOverwriteDocument(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleOverwriteDocument}
        handleDisagree={() => setIsOpenModalOverwriteDocument(false)}
      />
      <ModalDecision
        title={"Guardar cambios"}
        message={messageModalOverwriteClause}
        open={isOpenModalInfoPartsClause}
        onClose={() => setIsOpenModalInfoPartsClause(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setIsOpenModalInfoPartsClause(false);
          handleCreateClause(dataOverwriteDocument);
        }}
        handleDisagree={() => setIsOpenModalInfoPartsClause(false)}
      />
      <ModalDecision
        title={"Errores gramaticales encontrados"}
        message={
          <Container fluid className="caption">
            <Row>
              Hemos detectado algunas inconsistencias gramaticales sin corregir,
              ¿seguro quieres continuar?
            </Row>
            <br />
          </Container>
        }
        open={partsErrorsModal}
        onClose={() => setPartsErrorsModal(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          switch (saveType) {
            case 1:
              setIsLoading(true);
              setPartsErrorsModal(false);
              dispatchOnlyThisDocument(currentData.obj, currentData.data);
              break;
            case 2:
              setIsLoading(true);
              setPartsErrorsModal(false);
              handleDispatchSave(currentData.obj, currentData.data);
              break;
            case 3:
              setPartsErrorsModal(false);
              dispatchOverwriteDocument(currentData.data);
              break;

            default:
          }
        }}
        handleDisagree={() => {
          setPartsErrorsModal(false);
        }}
      />
    </Container>
  );
};

export default EditDocumentClause;
