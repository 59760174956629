export const flashItemSelected = (idItem, by = "id", idElement = "") => {
  try {
    let item;
    const docView = document.getElementById("content-document");
    if (by === "query") {
      const element = document.getElementById(idElement);
      item = element.querySelector(idItem);
      item.scrollIntoView({ behavior: "smooth" });
    } else {
      item = document.getElementById(idItem);
    }
    const keyframes = [
      { backgroundColor: "#FFC4C0" },
      { backgroundColor: "#FFFFFF" },
    ];
    const options = { duration: 4000, iterations: 1 };
    const elementRect = item.getBoundingClientRect();
    const position =
      docView.scrollTop +
      (elementRect.top - docView.getBoundingClientRect().height / 2);
    docView.scrollTo({
      top: position,
      behavior: "smooth",
    });
    if (item) item.animate(keyframes, options);
  } catch (error) {
    console.log(error);
  }
};

export const scrollToItem = (idItem) => {
  try {
    let item = document.getElementById(idItem);
    const docView = document.getElementById("clauses__document");

    const elementRect = item.getBoundingClientRect();
    const position =
      docView.scrollTop +
      (elementRect.top - docView.getBoundingClientRect().height / 2);
    docView.scrollTo({
      top: position,
      behavior: "smooth",
    });
  } catch (error) {
    console.log(error);
  }
};
