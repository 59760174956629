export const REPORTS_TYPE = {
    MILESTONES_AND_DATES: {
        _id: '1',
        name: 'Hitos y fechas'
    },
    VALUES: {
        _id: '2',
        name: 'Valores'
    },
    PARTS: {
        _id: '3',
        name: 'Partes'
    },
    POLICIES: {
        _id: '4',
        name: 'Pólizas'
    },
    OBLIGATIONS: {
        _id: '5',
        name: 'Obligaciones'
    },
    /*
    DEFINITIONS_DICTIONARY: {
        _id: '6',
        name: 'Diccionario de definiciones'
    } */
    SUMMARY: {
        _id: '7',   
        name: 'Resumen de contrato'
    },
}

export const TYPES_RELEVANCE_CLAUSES = {
    IRRELEVANT: "IRRELEVANT",
    RELEVANT: "RELEVANT",
}