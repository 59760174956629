export const TASK_STATE = {
  SIN_ASIGNAR: {
    _id: "632875993b69c9b321348bff",
    colorId: 0,
    description: "El abogado inicialmente asignado a la solicitud ha sido eliminado.",
    name: "Sin asignar",
  },
  INACTIVA: {
    _id: "626a9acf56c1dd7a1b145cb8",
    colorId: 200,
    description: "Aún no ha llegado la fecha de alerta",
    name: "Inactiva",
  },
};
