import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { segmentDocumentClassifyClauses } from "../../../../../../services/documents/documentsRequest";
import { handleAddPartThisContractType } from "../../../../../../parts/document/currentDocumentSlice";
import * as ReactDOMServer from "react-dom/server";

export const clauseBulkSlice = createSlice({
  name: "clausesBulk",
  initialState: {
    document: null,
    clauseSelected: null,
    status: "fetch",
    error: null,
    currentClause: null,
    parts: null,
    title: null,
    selectedIndex: null,
  },
  reducers: {
    swapParts(state, action) {
      const { name, indexClause, part } = action.payload;
      state.document.map((cla, indexState) => {
        if (indexState === indexClause) {
          part === 0
            ? (cla.parts.partA = name)
            : part === 1
            ? (cla.parts.partB = name)
            : part === 2
            ? (cla.parts.partC = name)
            : (cla.parts.getPartsSegmentedDocument = name);
          const partsArray = Object.values(cla.parts);
          const newText = handleAddPartThisContractType(cla.text, partsArray);
          cla.text = ReactDOMServer.renderToString(newText);

          cla?.subclauses?.map((sub) => {
            const newSubClause = handleAddPartThisContractType(
              sub.newSubclause.text,
              partsArray
            );
            sub.newSubclause.text = ReactDOMServer.renderToString(newSubClause);

            sub?.newSubclause?.childs?.map((child) => {
              const newSubClause = handleAddPartThisContractType(
                child.text,
                partsArray
              );
              child.text = ReactDOMServer.renderToString(newSubClause);
            });
          });

          cla?.paragraphs?.map((para) => {
            const newParagraphs = handleAddPartThisContractType(
              para.text,
              partsArray
            );
            para.text = ReactDOMServer.renderToString(newParagraphs);
          });
        }
      });
    },
    setClauseIndexSelected: (state, action) => {
      state.selectedIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClausesFromDocument.pending, (state, _) => {
        state.status = "loading";
      })
      .addCase(fetchClausesFromDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.document = action.payload.data.responseMessage.clauses;
        state.parts = action.payload.parts;
        state.title = action.payload.data.responseMessage.title;
      })
      .addCase(fetchClausesFromDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(setUpdateClauseBulkCreate, (state, action) => {
        const index = action.payload.index;
        const newClause = action.payload.clause;
        state.document[index] = newClause;
      })
      .addCase(setClauseSelected, (state, action) => {
        state.clauseSelected = action.payload.clause;
      })
      .addCase(setClearClauseBulk, (state, action) => {
        state.document = action.payload;
      });
  },
});

export const getSegmentedDocument = (state) => state.clausesBulk.document;

export const getClauseBulkSelected = (state) =>
  state.clausesBulk.clauseSelected;

export const getClausesBulkStatus = (state) => state.clausesBulk.status;

export const getClausesBulkSelectedId = (state) =>
  state.clausesBulk.selectedIndex;

export const getPartsSegmentedDocument = (state) => state.clausesBulk.parts;

export const getTitleSegmentedDocument = (state) => state.clausesBulk.title;

export const { swapParts } = clauseBulkSlice.actions;
export const { setClauseIndexSelected } = clauseBulkSlice.actions;

export const setUpdateClauseBulkCreate = createAction(
  "clausesBulk/setUpdateClauseBulkCreate",
  (data) => {
    return {
      payload: data,
    };
  }
);

export const setClearClauseBulk = createAction(
  "clausesBulk/setClearClauseBulk",
  () => {
    return {
      payload: null,
    };
  }
);

export const setClauseSelected = createAction(
  "clausesBulk/setClauseSelected",
  (clause) => {
    return {
      payload: {
        clause,
      },
    };
  }
);

export const fetchClausesFromDocument = createAsyncThunk(
  "clausesBulk/fetchClausesFromDocument",
  async (request, { rejectWithValue }) => {
    try {
      const response = await segmentDocumentClassifyClauses({
        parts: request.parts,
        files: request.documentData,
        company: request.payloadToken.company,
        onUploadProgress: request.onUploadProgress,
      });
      return {
        parts: request.parts,
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default clauseBulkSlice.reducer;
