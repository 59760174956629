import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTagsByCompanyAndResourceType } from "../../services/tags/tagsServices";

export const minutasTagSlice = createSlice({
  name: "minutasTags",
  initialState: {
    minutasTagList: [],
    status:"fetch",
    error:null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMinutasTags.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMinutasTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.minutasTagList = state.minutasTagList.concat(action.payload);
      })
      .addCase(fetchMinutasTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getTagList = (state) => state.minutasTags.minutasTagList;

export const getMinutasTagStatus = (state) => state.minutasTags.status;

export const fetchMinutasTags = createAsyncThunk("tags/fetchMinutasTags", async () => {
  const companyId = localStorage.getItem("company");
  let obj = {
    companyId: companyId,
    resourceType: 'CONTRACTTYPES',
  };
  const response = await getTagsByCompanyAndResourceType(obj);
  return response.data.responseMessage;
});

export default minutasTagSlice.reducer;
