import { SvgIcon } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Breadcrum = ({ state, setState }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (state) {
      setState(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <Container style={{ padding: "1%" }}>
      <Row xs={"auto"}>
        <Col onClick={() => handleNavigate()}>
          <SvgIcon
            style={{
              width: "2.4rem",
              height: "2.4rem",
              border: "1px solid #00374F",
              borderRadius: "24px",
              backgroundColor: "#E7F9FC",
              color: "#00374F",
              cursor: "pointer",
            }}
          >
            <ArrowBackIcon />
          </SvgIcon>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Ocultos por petición de funcional - Falta establecer la ruta de acuerdo donde se encuentre*/}
          <p
            onClick={() => handleNavigate()}
            style={{ cursor: "pointer" }}
            className="body-content__text-disabled"
          >
            Atrás {/* Miga de pan */}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Breadcrum;
