import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import {
  fetchContractTypes,
  getContractsTypesList,
  getStatus,
} from "../../pages/views/userService/modules/library/contractTypes/contractTypesSlice";
const AssociateContractTypes = ({
  classStyle = "custom-input__button__secondary-color",
  widthStyle = "auto",
  previousData,
  setPreviousData,
}) => {
  const dispatch = useDispatch();
  const contractTypes = useSelector(getContractsTypesList);
  const status = useSelector(getStatus);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleShow = () => {
    setShow(true);
    const filterData = contractTypes.filter(
      (contractType) => contractType?.company && contractType?.corporateUnit
    );
    setData(filterData);
    setFilteredData(filterData);
    setSelected(previousData);
  };
  const handleChecked = (e, item) => {
    const checked = e.target.checked;
    if (checked) {
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter((i) => i !== item));
    }
  };
  const handleAgree = () => {
    setPreviousData(selected);
    setShow(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (status === "fetch") {
          dispatch(fetchContractTypes({ isPublish: false }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [status, dispatch]);
  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon fontSize="large" />}
        className={classStyle}
        onClick={handleShow}
        style={{ width: widthStyle }}
      >
        Agregar a minuta
      </Button>
      <Modal
        size="lg"
        centered
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color">Asociar a Minutas</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <br />
            <Row className="filter-tag__headers">
              <p className="caption">
                Busca las minutas y seleccionala para asociar a la cláusula:
              </p>
            </Row>
            <br />
            <Row className="filter-tag__headers">
              <CustomSearchFilter
                list={data}
                setFilteredData={setFilteredData}
                placeholder="Buscar minuta"
              />
            </Row>
            <br />
            <Row>
              <div className="custom-accordion__container">
                {typeof filteredData !== "string" ? (
                  filteredData.map((item, index) => {
                    return (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={uuidv4()}
                      >
                        <Col className="custom-accordion">
                          <Row style={{ alignItems: "center" }}>
                            <Col xs={"auto"}>
                              <Checkbox
                                checked={selected.some(
                                  (itemSelected) =>
                                    itemSelected._id === item._id
                                )}
                                onClick={(e) => {
                                  handleChecked(e, item);
                                }}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                  },
                                  ".MuiAccordionSummary-gutters": {
                                    disableGutters: true,
                                  },
                                  color: "#00374f",
                                  "&.Mui-checked": {
                                    color: "#00374f",
                                  },
                                }}
                              />
                            </Col>
                            <Col>
                              <p className="heading__primary-color">
                                {item.name + " "}
                              </p>
                            </Col>
                            <Col
                              sm={3}
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <span className="subheading">
                                A favor de: {item.inFavor + " "}
                              </span>
                            </Col>
                            <Col
                              sm={4}
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <span className="subheading">
                                Etiquetas:{" "}
                                {item.labels.map((tag, index) => {
                                  return (
                                    <span
                                      key={uuidv4()}
                                      style={{ marginRight: "1%" }}
                                      className="caption custom-badges__disabled"
                                    >
                                      {tag.name + " "}
                                    </span>
                                  );
                                })}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography variant="h5">
                    No se han encontrado minutas
                  </Typography>
                )}
              </div>
            </Row>
            <br />
            <Row xs={"auto"} style={{ justifyContent: "end" }}>
              <Col>
                <Button
                  type="button"
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <p className="button">Regresar</p>
                </Button>
              </Col>
              <Col>
                <Button
                  type="button"
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={handleAgree}
                >
                  <p className="button">Asociar</p>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssociateContractTypes;
