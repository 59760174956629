import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { handleCopyClick } from "../../utils/copyText";
import dateFormat from "dateformat";
import botImage from "../../assets/svg/bot.png";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import PopoverActions from "./PopoverActions";
import ReactHtmlParser from "react-html-parser";
import { removeHtmlTags } from "../../utils/partsCorrection";
export const PopoverLimits = ({
  limitationsUpdatedDate,
  createdAt,
  limitations,
  containerRef,
}) => {
  const [isLarge, setIsLarge] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsLarge(true);

    const timer = setTimeout(() => {
      setIsLarge(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <PopoverActions
      parentId="clause-parent"
      type="xlarge"
      classNameButton=""
      setIsOpenExternal={setIsOpen}
      containerRef={containerRef}
      description={
        <div className="p-2">
          <Row className="align-items-center transition-col">
            <Col xs={12} md={isLarge ? 4 : 2} className="transition-col">
              <img
                alt="Preview Bot image"
                className="bot__image"
                src={botImage}
              />
            </Col>

            <Col xs={12} md={isLarge ? 8 : 10} className="transition-col">
              <Row>
                <Col xs={12}>
                  <span className="home-init-cards-row__title-row">
                    Limitaciones a la representación legal:{" "}
                  </span>
                </Col>

                <Col xs={12} className="text-start mb-3">
                  <span className="caption">
                    {createdAt &&
                      `Creado el ${dateFormat(
                        createdAt,
                        "d 'de' mmmm 'del 'yyyy"
                      )}`}
                  </span>
                </Col>

                <Col xs={12} className="text-start mb-3">
                  <span className="caption">
                    {limitationsUpdatedDate &&
                      `Modificado el ${dateFormat(
                        limitationsUpdatedDate,
                        "d 'de' mmmm 'del 'yyyy"
                      )}`}
                  </span>
                </Col>

                <Col xs={12} className="container__max-height">
                  <span className="caption">
                    {ReactHtmlParser(limitations)}
                  </span>
                </Col>

                <Col xs={12} className="mt-5 text-start">
                  <span
                    className="heading__hover cursor__pointer"
                    onClick={() => handleCopyClick(removeHtmlTags(limitations))}
                  >
                    Copiar texto{" "}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      icon={<ReportOutlinedIcon fontSize="large" style={{ fill: "#F9600B" }} />}
    />
  );
};
