import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../parts/breadcrum";
import SectionTitle from "../../../../../components/sectionTitle";
import AddIcon from "@mui/icons-material/Add";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import CardButtonWithNavButton from "../../../../../components/Cards/CardButtonWithNavButton";
import {
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../parts/headings/headingsSlice";
import { useDispatch } from "react-redux";
import { cleanStore } from "../../../../../parts/document/currentDocumentSlice";

const CreateContract = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedHeaderId(null));
    dispatch(setSelectedHeader(null));
    dispatch(cleanStore());
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row>
        <Breadcrum title="" />
      </Row>
      <Row style={{ alignContent: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title="Crear" />
        </Col>
      </Row>
      <Row style={{ height: "6%" }} className="text-description">
        ¿Cómo quieres crear tu nuevo documento?
      </Row>
      <Row style={{ height: "40%" }}>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<BusinessCenterIcon className="cards__icon-svg" />}
            title="Desde Minuta"
            description="Crea un documento a partir de las minutas de tu biblioteca."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="/service/create/from-minuta"
            mixPanelTrack="Crear desde minuta"
          />
        </Col>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<LocalLibraryOutlinedIcon className="cards__icon-svg" />}
            title="Desde Cláusulas"
            description="Crea un documento a partir de las cláusulas de tu biblioteca."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="/service/create/from-library"
            mixPanelTrack="Crear desde cláusula "
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateContract;
