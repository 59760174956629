import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";

import FilterCategory from "../../../../../../parts/filters/FilterCategory";
import { useEffect, useState } from "react";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ReactHtmlParser from "react-html-parser";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../parts/categories/clauseCategorySlice";
import { setSelectedClausesWithoutCheckPart } from "../../../../../../parts/document/currentDocumentSlice";
import LoadingContent from "../../../../../../components/loadingContent";
import FilterContractTypes from "../../../../../../parts/filters/FilterContractTypes";
import {
  fetchContractTypes,
  getContractsTypesList,
  getStatus,
} from "../../library/contractTypes/contractTypesSlice";
import {
  fetchTags,
  getTagList,
  getStatus as getTagsClausesStatus,
} from "../../../../../../parts/manageTags/tagSlice";
import {
  fetchClauses,
  getClausesList,
  getClausesStatus,
} from "../../library/clauses/clauseSlice";
import CustomSearchClauses from "../../../../../../components/Search/CustomSearchClauses";
import { getClausesByCompany } from "../../../../../../services/clauses/clausesServices";
import { CODES } from "../../../../../../utils/codes";
import { v4 as uuidv4 } from "uuid";
import { Mixpanel } from "../../../../../../utils/mixPanel";
import { setCreateFromRequest } from "../../request/requestSlice";
const SelectClausesContract = () => {

  /** Get Params */

  const [searchParams] = useSearchParams();
  const fromCreate = searchParams.get("from");
  const requestId = searchParams.get("requestId");
  
  /**
   * UseNavigate
   */

  const navigate = useNavigate();

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const contractTypesList = useSelector(getContractsTypesList);
  const statusContractTypes = useSelector(getStatus);
  const tagsClausesStatus = useSelector(getTagsClausesStatus);
  const tagsClausesList = useSelector(getTagList);
  const clausesStatus = useSelector(getClausesStatus);
  const clausesList = useSelector(getClausesList);

  /**Use States */

  //ACORDION CLAUSES

  const [clauses, setClauses] = useState([]);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [textCustomSearch, setTextCustomSearch] = useState("");

  //TAGS AND CATEGORIES
  const [filteredTags, setFilteredTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);

  //COUNTERS
  const [countChecked, setCountChecked] = useState(0);
  const [countCheckedSubClauses, setCountCheckedSubClauses] = useState(0);

  //SELECTED
  const [clausesSelected, setClausesSelected] = useState([]);
  const [subClausesSelected, setSubClausesSelected] = useState([]);
  const [contractTypesSelected, setContractTypesSelected] = useState([]);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  //MODALS
  const [
    isOpenModalDesicionAssociateClauses,
    setIsOpenModalDesicionAssociateClauses,
  ] = useState(false);

  //LOADING PAGE
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Handles
   */

  //CHECK CLAUSES

  const handleChecked = (e, clauses, index) => {
    const item = e.target;
    const subClausesToAdd = clauses.subclauses;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setCountCheckedSubClauses(
        countCheckedSubClauses + subClausesToAdd.length
      );
      setSubClausesSelected((oldArray) => [
        ...oldArray,
        {
          clause: clauses._id,
          subclause: subClausesToAdd,
        },
      ]);
      setClausesSelected((oldArray) => [...oldArray, clauses]);
    } else {
      setCountChecked(countChecked - 1);
      setCountCheckedSubClauses(
        countCheckedSubClauses - subClausesToAdd.length
      );
      setSubClausesSelected(
        subClausesSelected.filter(
          (subClause) => subClause.clause !== clauses._id
        )
      );
      setClausesSelected(
        clausesSelected.filter((clause) => clause._id !== clauses._id)
      );
    }
  };

  //CHECK SUBCLAUSES

  const handleCheckedSubClauses = (e, clause, subClauses) => {
    const item = e.target;
    if (item.checked) {
      setCountCheckedSubClauses(countCheckedSubClauses + 1);
      setSubClausesSelected((oldArray) => {
        return oldArray.map((item) => {
          let add = {};
          if (item.clause === clause._id) {
            add = {
              ...item,
              subclause: [...item.subclause, subClauses],
            };
          } else {
            return item;
          }
          return add;
        });
      });
    } else {
      setCountCheckedSubClauses(countCheckedSubClauses - 1);
      const deleteSubClauseSelected = subClausesSelected.map((selected) => {
        if (selected.clause === clause._id) {
          const subclause = selected.subclause.filter(
            (subclause) => subclause?._id !== subClauses?._id
          );
          return {
            clause: selected.clause,
            subclause: subclause,
          };
        } else {
          return selected;
        }
      });
      setSubClausesSelected(deleteSubClauseSelected);
    }
  };

  //TAGS
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.name));
      });
      setFilteredClauses(filterTags);
      refreshCategories();
    } else {
      setFilteredClauses(clauses);
      refreshCategories();
    }
  };
  const refreshTags = () => {
    if (tagsSelected.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => tagsSelected.includes(tag.name));
      });
      setFilteredClauses(filterTags);
    } else {
      setFilteredClauses(clauses);
    }
  };

  //CATEGORIES
  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });
      setFilteredClauses(filterCategories);
      refreshTags();
    } else {
      setFilteredClauses(clauses);
      refreshTags();
    }
  };
  const refreshCategories = () => {
    if (categoriesSelected.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          categoriesSelected.includes(category.name)
        );
      });
      setFilteredClauses(filterCategories);
    } else {
      setFilteredClauses(clauses);
    }
  };

  //MODALS

  //Importar

  const handleAgree = () => {
    setIsOpenModalDesicionAssociateClauses(false);

    const newClauses = clausesSelected.map((clause) => {
      let arrayParts = [];
      const parts = clause.parts;
      if (parts) {
        if (parts.partA) {
          arrayParts.push(parts.partA);
        }
        if (parts.partB) {
          arrayParts.push(parts.partB);
        }
        if (parts.partC) {
          arrayParts.push(parts.partC);
        }
        if (parts.partD) {
          arrayParts.push(parts.partD);
        }
      }
      return { clause: { ...clause, parts: arrayParts } };
    });

    let data = [];

    for (const subclause of subClausesSelected) {
      for (const { clause } of newClauses) {
        if (clause._id === subclause.clause) {
          data.push({
            clause: {
              ...clause,
              subclauses: [...subclause.subclause],
            },
          });
        }
      }
    }

    dispatch(setSelectedClausesWithoutCheckPart({ clauses: data }));
    dispatch(setCreateFromRequest({ requestId, fromCreate}));
    navigate("create-document");
  };

  //Cancelar

  const handleDisagree = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };

  //Cerrar

  const handleClose = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };

  //Seleccionar minutas

  const handleContractTypesSelected = (contractTypes) => {
    setContractTypesSelected(contractTypes);
  };

  //Eliminar minuta seleccionada

  const handleDeleteContractType = (contractType) => {
    setContractTypesSelected(
      contractTypesSelected.filter((ct) => ct._id !== contractType._id)
    );
    const newContractTypes = contractTypesSelected.filter(
      (ct) => ct._id !== contractType._id
    );
    if (newContractTypes.length > 0) {
      let contractTypesClauses = [];
      for (const contractType of newContractTypes) {
        for (const { clause } of contractType.clauses) {
          contractTypesClauses.push(clause._id);
        }
      }
      const filterContractTypes = clauses.filter((item) =>
        contractTypesClauses.includes(item._id)
      );
      setFilteredClauses(filterContractTypes);
    } else if (categoriesSelected.length > 0) {
      refreshCategories();
    } else if (tagsSelected.length > 0) {
      refreshTags();
    } else {
      setFilteredClauses(clauses);
    }
  };

  const handleCustomSearch = async (textSearch) => {
    try {
      if (textSearch === "") {
        setFilteredClauses(clauses);
      } else {
        setTextCustomSearch(textSearch);
        setIsLoading(true);
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
          searchTerm: textSearch,
        };
        const response = await getClausesByCompany(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          setFilteredClauses(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * UseEffects
   */

  //Categories
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  //Tags

  useEffect(() => {
    try {
      if (tagsClausesStatus === "fetch") {
        dispatch(fetchTags("CLAUSES"));
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsClausesStatus, dispatch]);

  useEffect(() => {
    if (tagsClausesList) {
      setFilteredTags(tagsClausesList);
    }
  }, [tagsClausesList]);

  //Clauses

  useEffect(() => {
    if (clausesStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [clausesStatus]);

  useEffect(() => {
    try {
      if (clausesStatus === "fetch") {
        dispatch(fetchClauses(textCustomSearch));
      }
    } catch (error) {
      console.error(error);
    }
  }, [clausesStatus, dispatch]);

  useEffect(() => {
    if (clausesList) {
      setFilteredClauses(clausesList);
      setClauses(clausesList);
    }
  }, [clausesList]);

  //Minutas

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusContractTypes === "fetch") {
          dispatch(fetchContractTypes({ isPublish: false }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusContractTypes, dispatch]);

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container"
    >
      {/**Loading page */}
      {isLoading && <LoadingContent />}
      <Row className="col-start">
        <Breadcrum />
      </Row>
      <Row>
        <Col md={3} className="col-start">
          <SectionTitle title="Seleccionar cláusulas" />
        </Col>
        <Col md={8}></Col>
      </Row>
      <br />
      <Row className="text-description">
        Busca las cláusulas y selecciona los numerales que quieres añadir al
        contrato:
      </Row>
      <br />
      <Container fluid>
        <Row className="filter-tag__headers">
          <Col>
            <CustomSearchClauses
              placeholder="Buscar cláusula"
              handleCustomeSearch={handleCustomSearch}
            />
          </Col>
          <Col xs={"auto"}>
            <p className="caption__primary-color__active">
              {countChecked} cláusulas seleccionadas
            </p>
          </Col>
          <Col xs={"auto"}>
            <FilterContractTypes
              list={clauses}
              contractTypesList={contractTypesList}
              setFilteredInfo={setFilteredClauses}
              handleContractTypesSelected={handleContractTypesSelected}
            />
          </Col>
          <Col xs={"auto"}>
            <FilterTag
              list={filteredClauses}
              tagsList={filteredTags}
              setFilteredInfo={setFilteredClauses}
              handleTagsSelected={handleTagsSelected}
            />
          </Col>
          <Col xs={"auto"}>
            <FilterCategory
              list={filteredClauses}
              categoriesList={categoriesList}
              setFilteredInfo={setFilteredClauses}
              handleCategoriesSelected={handleCategoriesSelected}
            />
          </Col>
        </Row>
        <br />
        <Row sm={"auto"} style={{ justifyContent: "right" }}>
          {tagsSelected.map((tag) => {
            return (
              <Col xs={"auto"} key={tag}>
                <span className="caption custom-badges__disabled">
                  {tag + " "}{" "}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() => handleDeleteTag(tag)}
                  />
                </span>
              </Col>
            );
          })}

          {categoriesSelected.map((category) => {
            return (
              <Col xs={"auto"} key={category}>
                <span className="caption custom-badges__secundary">
                  {category + " "}{" "}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() => handleDeleteCategory(category)}
                  />
                </span>
              </Col>
            );
          })}

          {contractTypesSelected.map((contractType) => (
            <Col xs={"auto"} key={contractType?._id}>
              <span className="caption custom-badges__tertiary">
                {contractType?.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteContractType(contractType)}
                />
              </span>
            </Col>
          ))}
        </Row>
        <br />
        <Row>
          <div className="custom-accordion__container__small">
            {typeof filteredClauses === "string" ? (
              <p className="caption">{filteredClauses}</p>
            ) : (
              filteredClauses.map((clause, index) => {
                let count = 0;
                subClausesSelected.forEach((subClauses) => {
                  if (subClauses.clause === clause._id) {
                    count = subClauses.subclause.length;
                  }
                });
                return (
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <Col>
                      <Accordion className="custom-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Col sm={4}>
                            <p className="heading__primary-color">
                              {clause.title + " "}
                              {clause.__v === 1 && (
                                <span
                                  className="heading__primary-color__number"
                                  style={{ color: "#27d287" }}
                                >
                                  (Modificada)
                                </span>
                              )}
                            </p>
                          </Col>
                          <Col style={{ textAlign: "right" }}>
                            <span className="subheading">
                              A favor de: {clause.inFavor + " "}
                            </span>
                          </Col>
                          {clause.categories.length > 0 && (
                            <Col style={{ textAlign: "right" }}>
                              <Col>
                                <span className="subheading">
                                  Categorías:{" "}
                                  {clause.categories.map((category, i) => {
                                    return (
                                      <span
                                        key={uuidv4()}
                                        style={{ marginRight: "1%" }}
                                        className="caption custom-badges__secundary"
                                      >
                                        {category.name}
                                      </span>
                                    );
                                  })}
                                </span>
                              </Col>
                            </Col>
                          )}
                          <Col sm={"auto"}>
                            <p className="caption__primary-color__active">
                              {count
                                ? `${count} numerales seleccionados`
                                : "0 numerales seleccionados"}
                            </p>
                          </Col>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            width: "100%",
                            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <Row>
                            <span className="subheading">
                              Etiquetas:{" "}
                              {clause.labels.map((tag, i) => {
                                return (
                                  <span
                                    key={uuidv4()}
                                    style={{ marginRight: "1%" }}
                                    className="caption custom-badges__disabled"
                                  >
                                    {tag.name}
                                  </span>
                                );
                              })}
                            </span>
                          </Row>
                          <br />
                          <br />
                          <Row>
                            <span className="caption">
                              {/* Text clause */}
                              <Row style={{ alignItems: "center" }}>
                                <Col xs={"auto"}>
                                  <Checkbox
                                    checked={
                                      clausesSelected.find(
                                        (clauseSel) =>
                                          clauseSel._id === clause._id
                                      )
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      handleChecked(e, clause, index)
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 28 },
                                      color: "#00374f",
                                      "&.Mui-checked": {
                                        color: "#00374f",
                                      },
                                    }}
                                  />
                                </Col>
                                <Col>{ReactHtmlParser(clause.text)}</Col>
                              </Row>
                              <br />
                              <br />
                              {/* Mapea estado de las subclausulas para selecionara */}
                              {clause.subclauses &&
                                clause.subclauses.map((subClause, i) => {
                                  return (
                                    <Row
                                      key={i}
                                      className="justify-text clauses-number"
                                      style={{
                                        marginLeft: "1%",
                                        marginRight: "1%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Col
                                        xs={"auto"}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          disabled={
                                            clausesSelected.find(
                                              (clauseSel) =>
                                                clauseSel._id === clause._id
                                            )
                                              ? false
                                              : true
                                          }
                                          checked={
                                            subClausesSelected.some((item) => {
                                              return item.subclause.find(
                                                (subcla) =>
                                                  subClause.subclause._id ===
                                                  subcla.subclause._id
                                              );
                                            })
                                              ? true
                                              : false
                                          }
                                          onClick={(e) =>
                                            handleCheckedSubClauses(
                                              e,
                                              clause,
                                              subClause
                                            )
                                          }
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 20,
                                            },
                                            color: "#00374f",
                                            "&.Mui-checked": {
                                              color: "#00374f",
                                            },
                                          }}
                                        />
                                        <b>
                                          {i + 1}.{""}
                                        </b>
                                      </Col>
                                      <Col>
                                        {ReactHtmlParser(
                                          subClause.subclause?.text
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                })}
                            </span>
                          </Row>
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                  </Row>
                );
              })
            )}
          </div>
        </Row>
      </Container>

      <Row style={{ height: "12%" }} className="col-end">
        <Col className="col-flex" md={"auto"}>
          <Button
            className="footer-button__softblue"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancelar
          </Button>
        </Col>
        <Col md={"auto"} className="col-flex">
          <Button
            className="footer-button__primary"
            onClick={() => {
              setIsOpenModalDesicionAssociateClauses(true);
              Mixpanel.track("Btn importar cláusulas", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
            }}
          >
            Importar
          </Button>
        </Col>
      </Row>
      <ModalDecision
        title={"Atención"}
        message={
          countChecked > 0
            ? `Estás importando ${countChecked} cláusulas y ${countCheckedSubClauses} numerales. ¿Deseas continuar?`
            : "No se han seleccionado cláusulas"
        }
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
        open={isOpenModalDesicionAssociateClauses}
        onClose={handleClose}
      />
    </Container>
  );
};

export default SelectClausesContract;
