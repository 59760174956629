import React, { useState, useRef } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Breadcrum from "../parts/breadcrum";
import SectionTitle from "../components/sectionTitle";
import { Controller, useForm } from "react-hook-form";
import RichEditorText from "../parts/RichEditorText";
import { Button, Popover } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const PocSelectionText = () => {

    const { control } = useForm({});

    const editorRef = useRef();

    const [selectedText, setSelectedText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [loadingSaveText, setLoadingSaveText] = useState(false);

    const handleSelectionChange = ({ event, selection }) => {
        setSelectedText(selection || "");
        console.log(event?.core)
        if (selection) {
            setAnchorEl(event?.core?.getSelection().anchorNode.parentElement)
        } else {
            setAnchorEl(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<Container fluid className="custom-container-scroll">
        <Row>
            <Breadcrum />
        </Row>
        <Row xs={"auto"}>
            <SectionTitle title={"POC - Module"} />
        </Row>
        <br />
        <br />
        <Row>
            <Col>
                <Row style={{ marginBottom: "1%" }}>
                    <Col md={3} style={{ marginTop: "0.3%" }}>
                    </Col>
                    <Col xs={"auto"}>
                        <Row>
                            <Col
                                className="create-clause-coachmark-step-4"
                                xs={"auto"}
                                style={{ marginTop: "1%", marginBottom: "0.5%" }}
                            >
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon fontSize="large" />}
                                    className="custom-input__button__secondary-color"
                                >
                                    Guardar selec. como texto predeterminado
                                </Button>
                            </Col>
                            <Col
                                className="create-clause-coachmark-step-4"
                                xs={"auto"}
                                style={{ marginTop: "1%", marginBottom: "0.5%" }}
                            >
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon fontSize="large" />}
                                    className="custom-input__button__secondary-color"
                                >
                                    Espacio en blanco
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Controller
                    control={control}
                    name="clauseText"
                    render={({ field: { onChange, onBlur } }) => (<>
                        <RichEditorText
                            ref={editorRef}
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            customClassName={"input-group-container__no-icon label"}
                            handleSelection={handleSelectionChange}
                        />
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            onClose={handleClose}
                            className="custom-popover__default"
                        >
                            <Row
                                className="custom-popover__body"
                                style={{ fontSize: '1.4rem', cursor: 'pointer' }}
                                onClick={() => {
                                    setLoadingSaveText(true);
                                    setAnchorEl(null);
                                    setLoadingSaveText(false);
                                }}
                            >
                                {loadingSaveText && (
                                    <div className="loading-content loading-content--spinner">
                                        <Spinner
                                            animation="border"
                                            className="spinner__white-background"
                                        />
                                    </div>
                                )}
                                <span>Guardar selección como texto predeterminado</span>
                            </Row>
                        </Popover>
                    </>)}
                />
            </Col>
        </Row>
    </Container>);
};

export default PocSelectionText;