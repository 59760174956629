import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function getClausesByCompany(data) {
  return axios({
    method: "GET",
    url: `clauses/getAll`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createManyClauses(data) {
  return axios({
    method: "POST",
    url: "clauses/createMultipleClauses",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createClause(data) {
  return axios({
    method: "POST",
    url: "clauses/createClause",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateClause(data) {
  return axios({
    method: "PUT",
    url: `clauses/updateClause/${data.clauseId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      ...data.clauseContent,
      corporateUnit: data.corporateUnit,
      company: data.company,
      ...data.timeZone,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getClauseById(data) {
  return axios({
    method: "GET",
    url: `clauses/getById/${data.clauseId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      corporateUnit: data.corporateUnit
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteClause(data) {
  return axios({
    method: "DELETE",
    url: `clauses/deleteClause/${data.clauseId}`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      corporateUnit: data.corporateUnit
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function findAllDeletedClauses({corporateUnit, company}) {
  return axios({
    method: "GET",
    url: `clauses/getAllDeletedClauses`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      corporateUnit,
      company,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export const updateDeletedClause = ({ id, company, corporateUnit, state }) => {
  return axios({
    method: "PUT",
    url: `clauses/updateDeletedClause/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      corporateUnit,
      company,
      state
    },
  });
};
