import { Button, Menu, MenuItem, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SegmentIcon from "@mui/icons-material/Segment";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MetricBarVertical = ({ name, description, list, filter }) => {
  //State
  const [anchorMenuFilter, setAnchorMenuFilter] = useState(null);
  const [anchorMenuPage, setAnchorMenuPage] = useState(null);
  const [actualFilter, setActualFilter] = useState("");
  const [pageWorkFlow, setPageWorkFlow] = useState(1);
  const [listPage, setListPage] = useState(null);

  const openMenuFilter = Boolean(anchorMenuFilter);
  const openMenuCount = Boolean(anchorMenuPage);

  const optionsBarChart = {
    indexAxis: "y",
    scales: actualFilter
      ? {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        }
      : {},
  };
  const dataBarChart = {
    labels: list
      ?.filter(
        (data, index) =>
          index <= pageWorkFlow * 5 && index >= pageWorkFlow * 5 - 5
      )
      .map((data) => data.name),
    datasets:
      actualFilter && pageWorkFlow
        ? filter
            .find((filterItem) => filterItem.name === actualFilter)
            .labelFilter.map((filterLabelList) => ({
              label: filterLabelList.name,
              data: list
                ?.filter(
                  (data, index) =>
                    index <= pageWorkFlow * 5 && index >= pageWorkFlow * 5 - 5
                )
                .map((data) => {
                  if (actualFilter === "Estados") {
                    const filterStatus = data.byStatus.find(
                      (filterItem) => filterItem.name === filterLabelList.name
                    ).value;
                    return filterStatus;
                  } else if (actualFilter === "Tipo de documento") {
                    const filterDocumentType = data.bySubtype.find(
                      (filterItem) => filterItem.name === filterLabelList.name
                    ).value;
                    return filterDocumentType;
                  } else if (actualFilter === "Subtipo de documento") {
                    const filterDocumentSubType = data?.byDocumentType.find(
                      (filterItem) => filterItem.name === filterLabelList.name
                    ).value;
                    return filterDocumentSubType;
                  } else if (actualFilter === "Tipo de solicitud") {
                    const filterDocumentSubType = data?.byType.find(
                      (filterItem) => filterItem.name === filterLabelList.name
                    ).value;
                    return filterDocumentSubType;
                  }
                }),
              backgroundColor: [filterLabelList.backgroundColor],
              borderRadius: 8,
              barThickness: 30,
            }))
        : [
            {
              label: name,
              data: list
                ?.filter(
                  (data, index) =>
                    index <= pageWorkFlow * 5 && index >= pageWorkFlow * 5 - 5
                )
                .map((data) => data.value),
              backgroundColor: ["#00374F"],
              borderRadius: 8,
              barThickness: 30,
            },
          ],
  };

  //Handle
  const handleCloseAnchorMenuFilter = () => {
    setAnchorMenuFilter(null);
  };
  const handleClickAnchorMenuFilter = (event) => {
    setAnchorMenuFilter(event.currentTarget);
  };
  const handleCloseAnchorMenuPage = () => {
    setAnchorMenuPage(null);
  };
  const handleClickAnchorMenuPage = (event) => {
    setAnchorMenuPage(event.currentTarget);
  };
  const handleGenerateMenuWorkflow = () => {
    let countPage = [];
    for (let page = 0; page < Math.ceil(list.length / 5); page++) {
      countPage.push(page + 1);
    }
    setListPage(countPage);
  };

  //useEffect
  useEffect(() => {
    handleGenerateMenuWorkflow();
  }, []);

  return (
    <Paper elevation={1} style={{ padding: "5%" }}>
      <Container fluid>
        <Row>
          <Col xs={7}>
            <Container fluid>
              <Row className="heading">{name}</Row>
              <Row className="caption">{description}</Row>
            </Container>
          </Col>
          <Col xs={3}>
            <Button
              fullWidth
              className="custom-input__button__secondary-color"
              startIcon={<FilterAltIcon fontSize="large" />}
              endIcon={<KeyboardArrowDownIcon fontSize="large" />}
              variant="contained"
              onClick={(e) => {
                handleClickAnchorMenuFilter(e);
              }}
            >
              Filtrar
            </Button>
            <Menu
              anchorEl={anchorMenuFilter}
              open={openMenuFilter}
              onClose={handleCloseAnchorMenuFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {filter?.map((option, index) => (
                <MenuItem
                  key={option.name}
                  onClick={() => {
                    setActualFilter(option.name);
                    handleCloseAnchorMenuFilter();
                  }}
                >
                  <span className="body-content">{option.name}</span>
                </MenuItem>
              ))}
            </Menu>
          </Col>
          <Col xs={1}>
            <Button
              fullWidth
              className="custom-input__button__secondary-color"
              variant="contained"
              onClick={(e) => {
                handleClickAnchorMenuPage(e);
              }}
            >
              <SegmentIcon fontSize="large" />
            </Button>
            <Menu
              anchorEl={anchorMenuPage}
              open={openMenuCount}
              onClose={handleCloseAnchorMenuPage}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {listPage?.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    setPageWorkFlow(page);
                    handleCloseAnchorMenuPage();
                  }}
                >
                  <span className="body-content">{`Página ${page}`} </span>
                </MenuItem>
              ))}
            </Menu>
          </Col>
        </Row>
        <Row>
          <Bar data={dataBarChart} options={optionsBarChart} />;
        </Row>
      </Container>
    </Paper>
  );
};

export default MetricBarVertical;
