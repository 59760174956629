import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ModalUploadDocs from "../../../../../../../components/Modals/ModalUploadDocs";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../../admin/modules/unitCorporative/unitCorporativeSlice";
import { createMassivePartsFromFile } from "../../../../../../../services/parts/partsServices";
import LoadingContent from "../../../../../../../components/loadingContent";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../../utils/codes";
import {
  resetPartListStatus,
  resetSignatoriesListStatus,
} from "../../../../../../../parts/parts/partsSlice";
import { useNavigate } from "react-router-dom";
export const CreatePartMassive = () => {
  //States
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseDataInfo, setResponseDataInfo] = useState({});
  //Redux
  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);
  //React Router
  const navigate = useNavigate();

  //handlers
  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = "/FORMATO_CARGUE_MASIVO_DE_PARTES.xlsx";
    link.download = "FORMATO_CARGUE_MASIVO_DE_PARTES.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadDoc = async () => {
    const company = localStorage.getItem("company");
    const createdBy = JSON.parse(localStorage.getItem("payloadToken")).userId;
    const corporateUnitDefault =
      listUnitsCorporatives.find((uc) => uc?.isDefault)?._id || "";
    const obj = {
      file: documentData,
      company,
      createdBy,
      ...(corporateUnitDefault && {
        associatedCorporateUnits: [corporateUnitDefault],
      }),
    };

    setIsLoading(true);
    const service = await createMassivePartsFromFile(obj);
    if (service.status) {
      setResponseDataInfo(service);
      setIsOpenModalInfo(true);
    }

    setIsLoading(false);
  };

  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
    if (responseDataInfo.status === CODES.COD_RESPONSE_HTTP_CREATED) {
      navigate(-1);
      dispatch(resetPartListStatus());
      dispatch(resetSignatoriesListStatus());
    }
  };
  //useEffect
  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
  }, [statusFetchUnits]);
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"} className="mt-4">
        <Col xs={2}>
          <SectionTitle title={"Cargue masivo de partes"} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <p className="caption">
            Cargue el documento donde tiene la información de partes
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <Row>
            <Col xs="auto">
              <p className="body-content__disabled">
                Descarga el formato y diligencia la información de las partes
              </p>
            </Col>

            <Col xs="auto">
              <span className="icon__button" onClick={downloadExcel}>
                <DownloadOutlinedIcon sx={{ fontSize: 24, color: "#676879" }} />
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      {documentData?.name && (
        <Row className="mt-3">
          <Col
            xs={5}
            style={{
              color: "#00374F",
              margin: "1rem 0rem",
              padding: "2rem",
              boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px",
              alignItems: "center",
            }}
          >
            <Row>
              <Col xs={12}>
                <p className="heading__primary-color">Documento</p>
              </Col>
              <Col xs={12}>
                <p className="caption__disabled"> {documentData.name}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col xs={3}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<AddIcon fontSize="large" />}
            onClick={() => setOpenModalUploadDocument(true)}
          >
            Cargar Documento
          </Button>
        </Col>
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            type="button"
            className={
              documentData?.name
                ? "custom-input__button__primary-color"
                : "custom-input__button__disabled"
            }
            disabled={!documentData?.name}
            startIcon={<SaveOutlinedIcon fontSize="large" />}
            onClick={() => {
              handleUploadDoc();
            }}
          >
            Guardar
          </Button>
        </Col>
      </Row>
      <ModalUploadDocs
        open={openModalUploadDocument}
        title={"Selecciona el documento para subir"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato xlsx. y con un peso máximo 75mb."
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setOpenModalUploadDocument(false);
        }}
        typeDocument={"ONLY_EXCEL"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato xlsx"
        maxFileSize={78643200}
      />
      <ModalInfo
        title={
          responseDataInfo.status === CODES.COD_RESPONSE_HTTP_CREATED
            ? "Creación de partes exitosa"
            : "Creación de partes fallida"
        }
        responseData={responseDataInfo}
        onClose={handleCloseModalInfo}
        open={isOpenModalInfo}
        buttonClose={true}
      />
    </Container>
  );
};
