import React from "react";
import {
    Dialog,
    DialogContent
} from "@mui/material";
import { Container, Row } from "react-bootstrap";

const ModalIcon = ({ icon, text, open, onClose }) => {
    return (<Dialog
        maxWidth="xs"
        open={open}
    >
        <DialogContent className="caption" dividers>
            <Container fluid>
                <Row style={{justifyContent: 'center'}}>
                    {icon}
                </Row>
                <br />
                <Row className="caption" style={{justifyContent: 'center'}}>
                    {text}
                </Row>
            </Container>
        </DialogContent>
    </Dialog>);
};

export default ModalIcon;
