import { createSlice } from "@reduxjs/toolkit";

const wrongRoleSlice = createSlice({
  name: "wrongRole",
  initialState: {
    wrongRole: false,
  },
  reducers: {
    setWrongRole: (state, action) => {
      state.wrongRole = action.payload;
    },
  },
});

export const { setWrongRole } = wrongRoleSlice.actions;
export const getWrongRole = (state) => state.wrongRole.wrongRole;

export default wrongRoleSlice.reducer;
