import { Avatar, Button, Divider } from "@mui/material";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import ModalDecision from "../Modals/modalDecision";
import { useNavigate, NavLink } from "react-router-dom";
import DetailsBar from "../../components/DetailsBar/DetailsBar";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Logout } from "../../services/general/authServices";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import IconButton from "@mui/material/IconButton";
import { Mixpanel } from "../../utils/mixPanel";
import DrawerSearchConsults from "../consults/searchBarConsults";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import {
  resetBackgroundInfoForModule,
  resetStatesButInfoForModule,
  selectAdditionalInfoForFinishedModule,
  selectIsAnyModuleProcessingFinished,
} from "../../parts/backgroundProcess/backgroundProcessSlice";
import { useDispatch, useSelector } from "react-redux";
const Sidebar = () => {
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [helpPath, setHelpPath] = useState("/admin/help");

  const [openModalConsult, setOpenModalConsult] = useState(false);

  //Background process selectors

  const backgroundProcessingInfo = useSelector(
    selectIsAnyModuleProcessingFinished
  );

  const dispatch = useDispatch();

  const SUBROL_TYPES = {
    "ADMIN PROPIETARIO": "propietario",
    "ADMIN ADMINISTRADOR": "administrador",
    "SERVICE DIRECTOR": "director",
    "SERVICE SENIOR": "senior",
    "SERVICE JUNIOR": "junior",
    "SERVICE PRACTICANTE": "practicante",
  };

  const corporateUnitName = JSON.parse(localStorage.getItem("corporateUnit"));
  const corporateUnit = corporateUnitName?.name || "";
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const typeUser =
    payloadToken?.loginType === "SERVICE" ? "Servicios" : "Administrador";
  const role = SUBROL_TYPES[payloadToken.subRole];

  useEffect(() => {
    if (payloadToken.loginType === "SERVICE") {
      setHelpPath("/service/help");
    }
  }, [payloadToken.loginType]);

  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  const handleLogoutAgree = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      await Logout({ refreshToken, email: payloadToken.email });
      setOpenLogoutModal(false);
      localStorage.clear();
      window.location.replace("/");
      Mixpanel.reset();
    } catch (error) {
      setOpenLogoutModal(false);
    }
  };

  const handleLogoutDisagree = () => {
    setOpenLogoutModal(false);
  };

  const serviceMenu = [
    {
      content: "Inicio",
      icon: (
        <HomeOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "home",
      prefix: "service",
    },
    {
      content: "Crear",
      icon: (
        <AddOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "create",
      prefix: "service",
    },
    {
      content: "Revisar",
      icon: (
        <VisibilityOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "review",
      prefix: "service",
    },
    {
      content: "Carpetas",
      icon: (
        <FolderOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "folders",
      prefix: "service",
    },
    {
      content: "Biblioteca",
      icon: (
        <LocalLibraryOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "library",
      prefix: "service",
    },
    {
      content: "Alertas",
      icon: (
        <InfoOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "alerts",
      prefix: "service",
    },
    {
      content: "Solicitudes",
      icon: (
        <EmailOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "requests",
      prefix: "service",
    },
    {
      content: "Firmar",
      icon: (
        <RateReviewOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "signView",
      prefix: "service",
    },
    {
      content: "Indicadores",
      icon: (
        <DonutLargeOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "dashboard",
      prefix: "service - admin",
    },
  ];

  const adminMenu = [
    // {
    //   content: "Perfil",
    //   icon: (
    //     <AccountCircleOutlinedIcon
    //       className="heading__primary-color"
    //       fontSize="large"
    //     />
    //   ),
    //   route: "profile",
    //   prefix: "admin",
    // },
    {
      content: "Administrar usuarios",
      icon: (
        <ManageAccountsOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "manageUser",
      prefix: "admin",
    },
    {
      content: "Unidades corporativas",
      icon: (
        <CorporateFareOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "unitsCorporatives",
      prefix: "admin",
    },

    {
      content: "Planes",
      icon: (
        <BusinessCenterIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "managePlan",
      prefix: "admin",
    },
    {
      content: "Biblioteca",
      icon: (
        <LocalLibraryOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "library",
      prefix: "admin",
    },
  ];

  const handleMixPanelAnalytics = (item) => {
    Mixpanel.track(`Btn Sidebar ${item.content}`, {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  return (
    <Container className="sidebar-base__container">
      {/**HEADER */}
      <Row className="sidebar-base__header-row">
        <Container fluid className="sidebar-base__header-row__header-container">
          <Row>
            <Avatar
              sx={{
                width: "4.8rem",
                height: "4.8rem",
                backgroundColor: "#00374F",
                fontSize: "2rem",
                fontFamily: "Roboto",
              }}
            >
              {payloadToken.firstName?.toUpperCase().charAt(0) || "P"}{" "}
              {payloadToken.firstSurname?.toUpperCase().charAt(0) || "J"}
            </Avatar>
          </Row>
          <Row className="sidebar-base__header-row__header-container__name">
            <p className="heading">
              {payloadToken.firstName} {payloadToken.firstSurname}
            </p>
          </Row>

          <Row
            className="sidebar-base__header-row__header-container__role"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Row
              className="sidebar-base__header-row__header-container__role"
              style={{ alignItems: "center" }}
            >
              <Col md={9}>
                <p className="caption mb-1">{`${typeUser} ${role}`}</p>
                <p className="caption">{corporateUnit}</p>
              </Col>
              {/* <Col md={3}>
                <IconButton
                  className="custom-input__button__iaColor"
                  sx={{ borderRadius: "16px", height: "40px", width: "40px" }}
                  onClick={() => {
                    setOpenModalConsult(true);
                  }}
                >
                  <SavedSearchIcon className="text-white" fontSize="large" />
                </IconButton>
              </Col> */}
            </Row>
          </Row>
        </Container>
      </Row>

      <Divider variant="fullWidth" />

      {/**NAVBAR */}
      <Row className="sidebar-base__navbar-row">
        <Container fluid className="sidebar-base__navbar-row__navbar-container">
          <Nav className="flex-column">
            {payloadToken["loginType"] === "SERVICE" && (
              <>
                {serviceMenu
                  .filter((option) =>
                    payloadToken["subRole"] === "SERVICE DIRECTOR" ||
                    payloadToken["loginType"] === "ADMIN" ||
                    payloadToken["subRole"] === "ADMIN PROPIETARIO" ||
                    payloadToken["subRole"] === "ADMIN ADMINISTRADOR"
                      ? option.prefix === "service - admin" ||
                        option.prefix === "service"
                      : option.prefix === "service"
                  )
                  .map((item) => {
                    return (
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-2"
                            : "sidebar-base__navbar-row__navbar-container__nav-row mt-2"
                        }
                        key={item?.route}
                        to={item.route}
                        style={{ textDecoration: "none" }}
                        onClick={() => handleMixPanelAnalytics(item)}
                      >
                        <Row>
                          <Col
                            md={2}
                            className="sidebar-base__navbar-row__navbar-container__icon-container"
                          >
                            {item.icon}
                          </Col>
                          <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                            {item.content}
                          </Col>
                        </Row>
                      </NavLink>
                    );
                  })}
              </>
            )}
            {payloadToken["loginType"] === "ADMIN" && (
              <>
                {adminMenu.map((item) => {
                  return (
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-2"
                          : "sidebar-base__navbar-row__navbar-container__nav-row mt-2"
                      }
                      key={item?.route}
                      to={item.route}
                      style={{ textDecoration: "none" }}
                      onClick={() => handleMixPanelAnalytics(item)}
                    >
                      <Row>
                        <Col
                          md={2}
                          className="sidebar-base__navbar-row__navbar-container__icon-container"
                        >
                          {item.icon}
                        </Col>
                        <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                          {item.content}
                        </Col>
                      </Row>
                    </NavLink>
                  );
                })}
              </>
            )}
          </Nav>
        </Container>
      </Row>

      {/**LOGOUT */}
      <Row className="sidebar-base__navbar-row__navbar-container__nav-row">
        <Button
          variant="text"
          className="sidebar-base__logout-row__button"
          onClick={() => {
            Mixpanel.track("Btn Sidebar Soporte técnico", {
              email: payloadToken?.email,
              companyName: payloadToken?.companyName,
            });
            navigate(helpPath);
          }}
        >
          <Row style={{ paddingLeft: "5%" }}>
            <Col md={2} className="sidebar-base__logout-row__logout-container">
              <img
                alt="helpicon"
                className="sidebar-base__logout-row__logout-icon"
                src={require("../../assets/svg/helpIcon.svg").default}
              />
            </Col>
            <Col className="sidebar-base__logout-row__logout-text">
              Soporte técnico
            </Col>
          </Row>
        </Button>
      </Row>
      <Row className="sidebar-base__navbar-row__navbar-container__nav-row">
        <Button
          variant="text"
          className="sidebar-base__logout-row__button"
          onClick={() => {
            Mixpanel.track("Btn Sidebar Cerrar sesión", {
              email: payloadToken?.email,
              companyName: payloadToken?.companyName,
            });
            setOpenLogoutModal(true);
          }}
        >
          <Row style={{ paddingLeft: "5%" }}>
            <Col md={2} className="sidebar-base__logout-row__logout-container">
              <img
                alt="logout"
                className="sidebar-base__logout-row__logout-icon"
                src={require("../../assets/svg/logoutIcon.svg").default}
              />
            </Col>
            <Col className="sidebar-base__logout-row__logout-text">
              Cerrar sesión
            </Col>
          </Row>
        </Button>
      </Row>

      <Divider />

      {/**FOOTER */}

      <Row className="sidebar-base__logo-row">
        <Col md={3} className="sidebar-base__logo-row__logo-container">
          <img
            alt="singleIcon"
            className="sidebar-base__logo-row__logo-svg"
            src={require("../../assets/svg/singleLogo.svg").default}
          />
        </Col>
        <Col className="sidebar-base__logo-row__logo-title">
          <p>Legal AI</p>
        </Col>
      </Row>

      <ModalDecision
        title={"Cerrar sesión"}
        message={"¿Está seguro?"}
        open={openLogoutModal}
        onClose={handleClose}
        agreeText="Cerrar sesión"
        disagreeText="Cancelar"
        handleAgree={handleLogoutAgree}
        handleDisagree={handleLogoutDisagree}
      />

      <DrawerSearchConsults
        open={openModalConsult}
        setOpen={setOpenModalConsult}
      />

      <ModalDecision
        title={"¡Información procesada!"}
        message={
          "Ya puedes ver la información que esperabas. Si necesitas terminar antes lo que estás haciendo, puedes volver a solicitar la información más tarde."
        }
        open={
          backgroundProcessingInfo.isFinished &&
          backgroundProcessingInfo.userLeft &&
          backgroundProcessingInfo?.navigationInfo?.url
        }
        onClose={() => {
          dispatch(
            resetBackgroundInfoForModule({
              module: backgroundProcessingInfo.moduleName,
            })
          );
        }}
        agreeText="Ver ahora"
        disagreeText="Procesar más tarde"
        handleAgree={() => {
          const { navigationInfo } = backgroundProcessingInfo || {};
          const { url = "", state = "", otherInfo = "" } = navigationInfo || {};
          if (!otherInfo) {
            dispatch(
              resetBackgroundInfoForModule({
                module: backgroundProcessingInfo.moduleName,
              })
            );
          } else {
            dispatch(
              resetStatesButInfoForModule({
                module: backgroundProcessingInfo.moduleName,
              })
            );
          }

          if (state) {
            navigate(url, { state });
          } else {
            navigate(url);
          }
        }}
        handleDisagree={() => {
          dispatch(
            resetBackgroundInfoForModule({
              module: backgroundProcessingInfo.moduleName,
            })
          );
        }}
      />
    </Container>
  );
};

export default Sidebar;
