export const RESPONSE_UNITS_CORPORATIVES = {
    /* CREATE_ERROR_WO_UC: Genera un error por no agregar al menos una unidad corporativa al crear o editar un usuario.*/
    CREATE_ERROR_WO_UC: {
        status: 400,
        data: {
          responseMessage: {
            message: 'Debe agregar al menos una unidad corporativa.'
          }
        }
    }
}