import Axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function getUnitsCorporatives() {
  return Axios({
    method: "GET",
    url: `corporate-unit/findAllCorporateUnits`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getOneUnitCorporativeById(id) {
  return Axios({
    method: "GET",
    url: `corporate-unit/findOneCorporateUnit/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createUnitCorporative(data) {
  return Axios({
    method: "POST",
    url: `users/createCorporateUnit`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function addCorporateUnitToOwnerUser({ corporateUnitId, userEmail }) {
  return Axios({
    method: "PUT",
    url: `users/addCorporateUnitToOwnerUser`,
    data: { corporateUnitId, userEmail },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateUnitCorporative(data) {
  return Axios({
    method: "PUT",
    url: `corporate-unit/updateCorporateUnit`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteUnitCorporative(data) {
  return Axios({
    method: "DELETE",
    url: `corporate-unit/deleteCorporateUnit`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function getUnitCorporativeAllowedUserService(data) {
  return Axios({
    method: "GET",
    url: `users/usersWithPermissionsForReadAndReceiveRequest?companyId=${data.companyId}&corporateUnitId=${data.corporateUnitId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
