import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FeedIcon from "@mui/icons-material/Feed";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Button, Drawer } from "@mui/material";
import dateFormat from "dateformat";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import FilterBar from "../../../../../../components/FilterBar/FilterBar";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateVersion from "../../../../../../components/Modals/modalAssociateVersion";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  setIdMongo,
  setInitialInfoLibrary,
  setName,
  setSelectedClauses,
} from "../../../../../../parts/document/currentDocumentSlice";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../../../../../parts/folders/foldersSlice";
import {
  getFolderById,
  moveDocumentBusinessFolder,
} from "../../../../../../services/business_folder/businessFolderService";
import { segmentDocument } from "../../../../../../services/documents/documentsRequest";
import { createNewVersion } from "../../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import { DOCUMENT_STATE } from "../../../../../../utils/documentStates";
import { onSortHTMLElement } from "../../../../../../utils/findComponentHTML";
const ReviewViewFolder = () => {
  /**
   * Tabla
   */
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última modificación
        </label>,
      ],
      field: "updatedAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const rows = [];

  /**
   * Use Navigate
   */
  const navigate = useNavigate();

  /**
   * Use Selector
   */
  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Params
   */
  const { businessFolderId } = useParams();

  /**
   * Use State
   */

  //Folder
  const [selectedFolder, setSelectedFolder] = useState({});
  const [documentData, setDocumentData] = useState({});

  //Modals
  const [openModal, setOpenModal] = useState(false);
  const [openModalMove, setOpenModalMove] = useState(false);
  const [versionSelected, setVersionSelected] = useState({});

  //Loading Data
  const [isLoading, setIsloading] = useState(false);

  //Datatable
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  //SelectedItem
  const [selectedElement, setSelectedElement] = useState({ id: "", type: "" });

  //Tags
  const [tagsSelected, setTagsSelected] = useState([]);

  //Filterbar
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);

  //Drawners
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);

  //Selection modal

  const [openSelectingModal, setOpenSelectingModal] = useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  /**
   * Handles
   */

  //Tags

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  /**
   * Handle Request
   */
  const handleRequest = async () => {
    try {
      setIsloading(true);
      const payloadToken = JSON.parse(localStorage.payloadToken);
      const segmentDoc = await segmentDocument({
        file: documentData,
        company: payloadToken.company,
      });
      await createNewVersion({
        name: documentData.name,
        fileType: codesDocumentType.contract._id,
        documentContent: segmentDoc.data.responseMessage,
        folderAssociated: businessFolderId,
        file: documentData,
      });
      setOptionsFilterBar((prev) => [...prev]);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestMove = async () => {
    try {
      setIsloading(true);
      const request = await moveDocumentBusinessFolder(
        businessFolderId,
        selectedElement.id,
        versionSelected._id
      );
      if (
        request?.data?.success &&
        request.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(request);
      }
      setOptionsFilterBar((prev) => [...prev]);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTempView = async (doc) => {
    if (
      doc.fileType === codesDocumentType.contract._id ||
      doc.fileType === codesDocumentType.draft._id
    ) {
      const a = { ...doc.documentContent.parts.partA };
      const b = { ...doc.documentContent.parts.partB };

      let parts = [a, b];

      if (doc.documentContent.parts.partC) {
        const c = { ...doc.documentContent.parts.partC };
        parts.push(c);
      }

      if (doc.documentContent.parts.partD) {
        const d = { ...doc.documentContent.parts.partD };
        parts.push(d);
      }

      const finalObj = {
        title: doc.documentContent.title,
        parts: parts,
      };
      dispatch(setInitialInfoLibrary(finalObj));
      dispatch(
        setSelectedClauses({
          clauses: doc.documentContent.clauses.map((cla) => {
            return cla.parts
              ? {
                  ...cla,
                  parts: cla.parts.partD
                    ? [
                        cla.parts.partA,
                        cla.parts.partB,
                        cla.parts.partC,
                        cla.parts.partD,
                      ]
                    : cla.parts.partC
                    ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
                    : [cla.parts.partA, cla.parts.partB],
                }
              : cla;
          }),
        })
      );
      dispatch(
        setName({
          name: doc.documentContent.name,
        })
      );
      dispatch(
        setIdMongo({
          id: doc.documentContent._id,
        })
      );
      navigate(`/service/review/in-negotiation/view-document/${doc._id}`);
    } else {
      navigate(`/service/folders/viewFolder/0/viewOther/${doc._id}`);
    }
  };

  /**
   * Handle Modals
   */
  const handleAgreeSelection = (id) => {
    navigate(`/service/review/in-negotiation/uploadDocument/${id}`);
  };

  /**
   * Use Effect
   */

  //Loading data table
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const companyId = localStorage.getItem("company");
        const response = await getFolderById({
          id: businessFolderId,
          companyId,
          corporateUnit,
        });
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          await setSelectedFolder(response.data.responseMessage);
        }

        const processedDocs = response.data.responseMessage.documents
          .filter((doc) => doc.status._id === DOCUMENT_STATE.active._id)
          .map((doc) => {
            const created = dateFormat(doc?.createdAt, "dd/mm/yyyy");
            const modified = dateFormat(doc?.updatedAt, "dd/mm/yyyy");
            const fileType = Object.values(codesDocumentType).find(
              (code) => code._id === doc.fileType
            );
            return {
              element: (
                <Row
                  style={{
                    paddingLeft: "2%",
                    paddingTop: "1%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleTempView(doc);
                  }}
                >
                  <Col xs={"auto"} className="datatable-CB__tr-name">
                    <FeedIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </Col>
                  <Col className="datatable-CB__tr-name">{doc.name}</Col>
                </Row>
              ),
              type: fileType.name,
              createdAt: created,
              updatedAt: modified,
              actions: (
                <Row>
                  <Col>
                    <Button
                      className="button-primary-folders"
                      onClick={(e) => {
                        setSelectedElement(doc);
                        setOpenSelectingModal(true);
                      }}
                    >
                      Seleccionar
                    </Button>
                  </Col>
                </Row>
              ),
            };
          });

        const proccesedRequest = response.data.responseMessage.requests.map(
          (req) => {
            const created = dateFormat(req?.createdAt, "dd/mm/yyyy");
            const modified = dateFormat(req?.updatedAt, "dd/mm/yyyy");
            return {
              element: (
                <Row style={{ paddingLeft: "2%", paddingTop: "1%" }}>
                  <Col xs={"auto"} className="datatable-CB__tr-name">
                    <FeedIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </Col>
                  <Col className="datatable-CB__tr-name">
                    Solicitud #{req.consecutive}
                  </Col>
                </Row>
              ),
              type: "Solicitudes",
              createdAt: created,
              updatedAt: modified,
              alerts: (
                <Row>
                  <Col>
                    <Button
                      className="button-primary-folders"
                      onClick={(e) => {
                        setSelectedElement(req);

                        setOpenSelectingModal(true);
                      }}
                    >
                      Seleccionar
                    </Button>
                  </Col>
                </Row>
              ),
            };
          }
        );

        const unified = processedDocs.concat(proccesedRequest);
        setDataTable({ columns: columns, rows: unified });
        setFilteredDataTable({ columns: columns, rows: unified });
        setTimeout(setIsloading(false), 50000);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [optionsFilterBar]);

  //Folders
  useEffect(() => {
    if (foldersStatus == "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [dispatch, foldersStatus]);

  //Upload first version
  useEffect(() => {
    documentData.name && handleRequest();
  }, [openModal]);

  //Handle UploadVersions
  useEffect(() => {
    versionSelected.name && handleRequestMove();
  }, [openModalMove]);

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container"
    >
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={3} className="col-start">
          <SectionTitle title={selectedFolder.name} />
        </Col>
        <Col md={4}></Col>
        <Col md={"auto"} className="col-flex">
          <Button
            className="button-softblue-folders__details"
            startIcon={<InfoOutlinedIcon />}
            onClick={() => {
              setOpenDetailsDrawner(true);
            }}
          >
            Detalles de la carpeta
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "10%" }} className="text-description">
        Haz clic en el botón “Seleccionar” del documento en negociación que
        buscas.
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={5} className="col-flex">
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col md={3}></Col>
        <Col className="col-flex">
          <Button
            className="button-softblue-folders__labels__disabled"
            startIcon={<LocalOfferIcon />}
            disabled
          >
            Ver por etiqueta
          </Button>
        </Col>

        <Col className="col-flex">
          <Button
            className="button-softblue-folders__filter"
            startIcon={<FilterAltIcon />}
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            Filtrar
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "2%" }}>
        {tagsSelected.map((tag, idx) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row style={{ height: "40%" }}>
        {
          <MDBDataTableV5
            hover
            pagingTop
            searchBottom={false}
            entries={6}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "elementos"]}
            fullPagination
            noRecordsFoundLabel="No se encontraron resultados"
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions"],
                sort: value,
                filteredDataTable,
                setFilteredDataTable,
              });
            }}
          />
        }
      </Row>
      <FilterBar
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setOptionsFilterBar={setOptionsFilterBar}
        folders={true}
      />

      <Drawer
        anchor="right"
        open={openDetailsDrawner}
        onClose={() => {
          setOpenDetailsDrawner(false);
        }}
      >
        <Container fluid style={{ width: "44.7rem", height: "inherit" }}>
          <Row className="col-flex" style={{ height: "10%" }}>
            <Col className="heading" md={10}>
              {selectedFolder.name}
            </Col>
            <Col md={1}>
              <CloseIcon
                fontSize="large"
                onClick={() => {
                  setOpenDetailsDrawner(false);
                }}
              />
            </Col>
          </Row>
          <hr style={{ margin: "0" }} />
          <Row style={{ height: "20%" }}>
            <Container fluid style={{ paddingLeft: "5%" }}>
              <Row style={{ height: "25%", marginTop: "5%" }}>
                <Col md={"auto"} className="subheading">
                  Etiquetas:
                </Col>
                <Col className="caption">
                  <Row style={{ gap: "2%" }}>
                    {selectedFolder?.labels?.map((label) => (
                      <Col
                        className="drawner-details__labels"
                        md={"auto"}
                        key={uuidv4()}
                      >
                        {label.name}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row style={{ height: "20%" }}>
                <Col md={"auto"} className="subheading">
                  Descripción:
                </Col>
                <Col className="caption">{selectedFolder.description}</Col>
              </Row>
              <Row style={{ height: "20%" }}>
                <Col md={"auto"} className="subheading">
                  Fecha de creación:
                </Col>
                <Col className="caption">{selectedFolder.createdAt}</Col>
              </Row>
              <Row style={{ height: "20%" }}>
                <Col md={"auto"} className="subheading">
                  Última modificación:
                </Col>
                <Col className="caption">{selectedFolder.updatedAt}</Col>
              </Row>
            </Container>
          </Row>

          <Row style={{ height: "60%" }}></Row>
          <Row className="drawner-details">
            <Col md={"auto"}>
              <Button
                type="submit"
                variant="contained"
                className="drawner-details__back"
                onClick={() => {
                  setOpenDetailsDrawner(false);
                }}
                startIcon={<ArrowBackOutlinedIcon fontSize="large" />}
              >
                Volver
              </Button>
            </Col>
            <Col md={"auto"}>
              <Button
                type="submit"
                variant="contained"
                className="drawner-details__edit"
                onClick={() => {
                  navigate(`/service/folders/edit/${businessFolderId}`);
                }}
                startIcon={<CreateOutlinedIcon fontSize="large" />}
              >
                Editar información
              </Button>
            </Col>
          </Row>
        </Container>
      </Drawer>
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setOpenModal(false);
        }}
        onClose={() => {
          setOpenModal(false);
        }}
        open={openModal}
        documentData={documentData}
        setDocumentData={setDocumentData}
      />
      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalMove(false);
          setVersionSelected(file);
        }}
        onClose={() => {
          setOpenModalMove(false);
        }}
        open={openModalMove}
        folders={folders}
        versionSelected={versionSelected}
        title="Mover a"
      />

      <ModalDecision
        open={openSelectingModal}
        title={"Asociar documento"}
        message={`¿Estás seguro de asociar el documento que vas a cargar a “${selectedElement.name}”?`}
        agreeText="Asociar"
        disagreeText={"Volver"}
        handleAgree={() => {
          handleAgreeSelection(selectedElement._id);
        }}
        handleDisagree={() => {
          setOpenSelectingModal(false);
        }}
        onClose={() => {
          setOpenSelectingModal(false);
        }}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default ReviewViewFolder;
