import axios from "axios";
import { env } from "../../env";
import GetToken from "../../utils/getToken";
import { STATES_PREDETERMINATED_TEXT } from "./enums.predeterminatedText";

export function getAllPrederminatedTexts({ company, corporateUnit }) {
  return axios({
    method: "GET",
    url: `predeterminatedTexts/all`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      company,
      corporateUnit,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createPrederminatedTextService(data) {
  return axios({
    method: "POST",
    url: `predeterminatedTexts/create`,
    baseURL: env.REACT_APP_API_URL,
    data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deletePrederminatedText(obj) {
  const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
  return axios({
    method: "PATCH",
    url: `predeterminatedTexts/one`,
    data: {
      company: obj.company,
      corporateUnit: obj.corporateUnit,
      predeterminedTextId: obj.id,
      status: STATES_PREDETERMINATED_TEXT.DELETED,
      deletedBy: userId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updatePrederminatedTextService(data) {
  return axios({
    method: "PATCH",
    url: `predeterminatedTexts/one`,
    baseURL: env.REACT_APP_API_URL,
    data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getAllDeletePrederminatedText(obj) {
  return axios({
    method: "GET",
    url: `predeterminatedTexts/all`,
    params: {
      company: obj.company,
      corporateUnit: obj.corporateUnit,
      status: STATES_PREDETERMINATED_TEXT.DELETED,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function restorePrederminatedText(obj) {
  return axios({
    method: "PATCH",
    url: `predeterminatedTexts/one`,
    data: {
      company: obj.company,
      corporateUnit: obj.corporateUnit,
      predeterminedTextId: obj.id,
      status: STATES_PREDETERMINATED_TEXT.ACTIVE,
      deletedBy: null,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deletePermanentPrederminatedText(obj) {
  return axios({
    method: "DELETE",
    url: `predeterminatedTexts/delete`,
    params: {
      predeterminedTextId: obj?.id,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteOtherDocumentPredeterminedText(data) {
  return axios({
    method: "PUT",
    url: "predeterminatedTexts/updateStatus",
    baseURL: env.REACT_APP_API_URL,
    data: {
      company: data?.company,
      corporateUnit: data?.corporateUnit,
      deletedBy: data?.userId,
      predeterminedTextId: data?.id,
      status: STATES_PREDETERMINATED_TEXT.DELETED,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
