import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAttorneys,
  reasignrequest,
} from "../../../../../../services/general/requestServices";
import { findOneDataExternalRequest } from "../../../../../../services/general/CarvajalServices";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { CODES } from "../../../../../../utils/codes";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalInfo from "../../../../../../components/Modals/modalInfo";

const Reasignrequest = () => {
  const { consecutive } = useParams();
  const navigate = useNavigate();
  //State
  const [isLoading, setIsLoading] = useState(false);
  const [listAttorneys, setListAttorneys] = useState([]);
  const [newAttorneys, setNewAttorneys] = useState("");
  const [requestId, setrequestId] = useState("");
  const [reasignMessage, setReasignMessage] = useState("");
  const previousAttorney = JSON.parse(localStorage.getItem("payloadToken"));

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [responseData, setResponseData] = useState({});
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const company = localStorage.getItem("company");
        const obj = {
          consecutive,
          company,
          corporateUnit
        };
        const GetDataRequest = await findOneDataExternalRequest(obj);
        if (GetDataRequest.status) {
          if (GetDataRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
            setrequestId(GetDataRequest.data.responseMessage._id);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const obj = {
          companyId: company,
          corporateUnit,
          consecutive,
        };
        const listOfAttorneys = await getAttorneys(obj);
        if (listOfAttorneys.status) {
          if (listOfAttorneys.status === CODES.COD_RESPONSE_HTTP_OK &&
            listOfAttorneys.data.responseCode === CODES.COD_RESPONSE_SUCCESS
          ) {
            setListAttorneys(listOfAttorneys.data.responseMessage);
          } else if (listOfAttorneys.data.responseCode === CODES.COD_RESPONSE_ERROR_FIND){
            setResponseData(listOfAttorneys);
            setIsOpenModalResponse(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const handleReasign = async () => {
    try {
      setIsLoading(true);
      if (newAttorneys === "") return;
      const obj = {
        newAttorney: newAttorneys,
        previousAttorney: previousAttorney.userId,
        requestId: requestId,
        reasignMessage: reasignMessage,
      };
      const service = await reasignrequest(obj);
      if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
        navigate("/service/requests");
      } else if (service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(service);
        setIsOpenModalUnauthorized(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNameAttorneys = (user) => {
    if (!user?.firstName || user?.personType === "Persona jurídica") {
      return `${user?.companies[0]?.companyData?.companyName} (Usuario propietario)`;
    }
    return `${user?.firstName || ""} ${user?.secondName || ""} ${user?.firstSurname || ""} ${user?.secondSurname || ""}`;
  }

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Solicitudes"} />
      </Row>
      <br />
      <Row>
        <Col md={6}>
          <p className="form__label" style={{ textAlign: "justify" }}>
            Por favor elige a que abogado deseas reasignar la solicitud y explica
            la razón por la que quieres hacerlo:
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          <Form.Select
            className="label mb-4"
            size="lg"
            bsPrefix={"input-group-container__no-icon label"}
            defaultValue=""
            onChange={(e) => {
              setNewAttorneys(e.target.value);
            }}
          >
            <option className="label" value="" disabled>
              Selecciona el abogado al que quieras reasignar
            </option>
            {listAttorneys.map((attorneys) => (
              <option key={attorneys._id} className="label" value={attorneys._id}>
                {getNameAttorneys(attorneys)}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-5">
            <Form.Control
              as="textarea"
              placeholder="Razón de la reasignación..."
              bsPrefix="input-group-container__no-icon label"
              onChange={(e) => {
                setReasignMessage(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={5}></Col>
        <Col md={1}>
          <Button
            type="submit"
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleReasign}
          >
            {isLoading ? (
              <Spinner
                animation="border"
                className="spinner__white-background"
              />
            ) : (
              "Siguiente"
            )}
          </Button>
        </Col>
      </Row>
      <ModalInfo
        title={responseDataUnauthorized?.data?.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ? 
          "Sin permisos suficientes" : 
          "Ha ocurrido un error"
        }
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title="Información"
        onClose={() => setIsOpenModalResponse(false)}
        open={isOpenModalResponse}
        responseData={responseData}
        confirmationText="Aceptar"
        buttonClose={true}
        handleCloseDefault={() => setIsOpenModalResponse(false)}
      />
    </Container>
  );
};

export default Reasignrequest;
