import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import CardButtonWithNavButton from "../../../../../components/Cards/CardButtonWithNavButton";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import { useSelector } from "react-redux";

const ReviewService = () => {
  const [isOpenModalPlan, setIsOpenModalPlan] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const companyPlan = useSelector(getCompanyPlan);
  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsOpenModalPlan(true);
        setDisableSave(true);
      }
    }
  }, [companyPlan]);
  return (
    <Container fluid style={{ height: "100%" }}>
      <Row>
        <Breadcrum title="" />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Revisar" />
        </Col>
        {/* <Col style={{display: "flex", justifyContent: "end"}}>
          <IconButton size="large">
            <NotificationsNoneOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </IconButton>
        </Col> */}
      </Row>
      <Row style={{ height: "10%" }} className="text-description">
        ¿El documento ya está en Legal AI?
      </Row>
      <Row style={{ height: "40%" }}>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<CloudUploadOutlinedIcon className="cards__icon-svg" />}
            title="Nuevo documento"
            description="Revisa un documento que no tiene versiones anteriores en Legal AI."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="first-Time"
            mixPanelTrack="Revisar nuevo documento "
          />
        </Col>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<ModeEditOutlinedIcon className="cards__icon-svg" />}
            title="Documento existente"
            description="Revisa y compara con versiones del documento que ya están en Legal AI."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="in-negotiation/select-document"
            disabled={disableSave}
            mixPanelTrack="Revisar Documento existente "
          />
        </Col>
      </Row>
      <ModalDecision
        title={"Guardado no disponible"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => setIsOpenModalPlan(false)}
        handleDisagree={() => setIsOpenModalPlan(false)}
        message={
          "La compañía ha alcanzado el máximo de almacenamiento de su plan. Comunícate con el usuario administrador de Legal AI."
        }
        onClose={() => setIsOpenModalPlan(false)}
        open={isOpenModalPlan}
        type={"warning"}
      />
    </Container>
  );
};

export default ReviewService;
