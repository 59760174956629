import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../utils/regexp";
import { useOutletContext, useNavigate } from "react-router-dom";

const RegisterPhase2 = () => {
  const { userData, handleGeneralPhases, setUserData } = useOutletContext();
  const navigate = useNavigate();

  const [verificationDigit, setVerificationDigit] = useState("");
  const [documentType, setDocumentType] = useState(false);

  const schemaNaturalPerson = yup.object().shape({
    FirstName: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" }),
    SecondName: yup.string().matches(REGEXP.ONLY_LETTERS, {
      message: "*Solo debe incluir letras",
      excludeEmptyString: true,
    }),
    LastName: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" }),
    SurName: yup
      .string()
      .notRequired()
      .test("SurName", "*Solo se permiten letras", function (value) {
        if (!!value) {
          const schemaNaturalPerson = yup.string().matches(REGEXP.ONLY_LETTERS);
          return schemaNaturalPerson.isValidSync(value);
        }
        return true;
      }),
    DocumentType: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
    DocumentType2: !documentType
      ? yup.string().nullable().default("")
      : yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
    NumberDocument: yup
      .string()
      .required("*Este campo es obligatorio")
      .min(5, "*Debe contener al menos 5 caracteres")
      .matches(REGEXP.LETTERS_AND_NUMBERS_WITH_OTHER, {
        message: "*Solo debe incluir números y letras",
      }),
  });

  const schemaLegalPerson = yup.object().shape({
    CompanyName: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.LETTERS_AND_NUMBERS_WITH_OTHER, {
        message: "*Solo debe incluir letras y números",
      }),

    NIT: yup
      .string()
      .required("*Este campo es obligatorio")
      .min(9, "Debe tener 9 números")
      .max(9, "Debe tener 9 números")
      .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir números" }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaNaturalPerson),
  });

  const {
    register: registerLegalPerson,
    handleSubmit: handleSubmitLegalPerson,
    formState: { errors: errorsLegalPerson },
    setValue: setValueLegalPerson,
  } = useForm({
    resolver: yupResolver(schemaLegalPerson),
  });

  useEffect(() => {
    userData.companyName &&
      setValueLegalPerson("CompanyName", userData.companyName);
    userData.nit && setValueLegalPerson("NIT", userData.nit);
    userData.verificationDigit &&
      setVerificationDigit(userData.verificationDigit);
    userData.firstName && setValue("FirstName", userData.firstName);
    userData.secondName && setValue("SecondName", userData.secondName);
    userData.firstSurname && setValue("LastName", userData.firstSurname);
    userData.secondSurname &&
      setValue("companySurNameName", userData.secondSurname);
    userData.documentType && setValue("DocumentType", userData.documentType);
    userData.documentType && setValue("DocumentType2", userData.documentType);
    userData.documentNumber &&
      setValue("NumberDocument", userData.documentNumber);
  }, []);

  const handlePhase2 = (data) => {
    navigate(`/register/phase3`);
    userData.personType === "Natural"
      ? setUserData((prev) => ({
          ...prev,
          documentType: data.DocumentType
            ? data.DocumentType
            : data.DocumentType2,
          firstName: data.FirstName,
          secondName: data.SecondName,
          firstSurname: data.LastName,
          secondSurname: data.SurName,
          documentNumber: data.NumberDocument,
        }))
      : setUserData((prev) => ({
          ...prev,
          companyName: data.CompanyName,
          nit: data.NIT,
          verificationDigit: verificationDigit,
        }));
    handleGeneralPhases(2, true, false);
  };

  const handleBack = () => {
    handleGeneralPhases(1, false, false);

    navigate(-1);
  };

  const HandleVerificationDigit = (nit) => {
    const numericalSeries = [41, 37, 29, 23, 19, 17, 13, 7, 3];
    const nitArray = nit.split("");

    if (nitArray.length === numericalSeries.length) {
      let nitCalculation =
        numericalSeries.reduce(
          (prev, current, index) => prev + current * Number(nitArray[index]),
          0
        ) % 11;

      nitCalculation =
        nitCalculation > 1 ? 11 - nitCalculation : nitCalculation;

      setVerificationDigit(nitCalculation + "");
    }
  };

  const handleSetDocument = (e) => {
    e.target.value === "OTRO" ? setDocumentType(true) : setDocumentType(false);
  };

  return (
    <Container style={{ height: "100%", padding: "7% 5%" }}>
      <Row className="display-x-large__primary-color" style={{ height: "10%" }}>
        Bienvenido a Legal AI
      </Row>
      <Row style={{ height: "10%" }}>
        <p className="body-content">
          {userData.personType !== "Juridica"
            ? "Completa el siguiente formulario con los datos solicitados"
            : "Completa el siguiente formulario con los datos de tu empresa"}
        </p>
        <p className="caption">(Los campos que tengan * son obligatorios)</p>
      </Row>

      {userData.personType === "Juridica" ? (
        <Form onSubmit={handleSubmitLegalPerson(handlePhase2)}>
          <Row style={{ height: "20%", margin: "3% 0" }}>
            <Col>
              <Container fluid>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-5">
                      <Form.Control
                        {...registerLegalPerson("CompanyName")}
                        placeholder="Razón social*"
                        bsPrefix={
                          errorsLegalPerson.CompanyName
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div
                        className="caption custom-input__error"
                        style={{ border: "1px sollid red" }}
                      >
                        {errorsLegalPerson.CompanyName?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-5">
                      <Form.Control
                        {...registerLegalPerson("NIT")}
                        placeholder="NIT*"
                        bsPrefix={
                          errorsLegalPerson.NIT
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                        onChange={(e) => {
                          HandleVerificationDigit(e.target.value);
                        }}
                      />
                      <Form.Text className="text-muted">
                        El NIT debe contener máximo 9 dígitos
                      </Form.Text>
                      <div
                        className="caption custom-input__error"
                        style={{ border: "1px sollid red" }}
                      >
                        {errorsLegalPerson.NIT?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-5">
                      <Form.Control
                        placeholder="Dígito de verificación*"
                        value={verificationDigit}
                        disabled
                        bsPrefix="input-group-container__no-icon label"
                      />
                      <Form.Text className="text-muted">
                        Este campo se completa automáticamente al ingresar el
                        NIT
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row style={{ height: "15%" }}>
            <Col md={2}>
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                className="custom-input__button__secondary-color"
                onClick={handleBack}
              >
                Regresar
              </Button>
            </Col>
            <Col md={2}>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                className="custom-input__button__primary-color"
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form onSubmit={handleSubmit(handlePhase2)}>
          <Row style={{ height: "20%", margin: "3% 0" }}>
            <Col md={4}>
              <Container fluid>
                <Row>
                  <Form.Select
                    className="label mb-1"
                    size="sm"
                    style={{ width: "100%" }}
                    bsPrefix={
                      errors.DocumentType
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                    {...register("DocumentType")}
                    onChange={handleSetDocument}
                  >
                    <option className="label" value="" disabled>
                      Escoja un tipo de documento
                    </option>
                    <option className="label" value="TARJETA DE IDENTIDAD">
                      Tarjeta de identidad
                    </option>
                    <option className="label" value="CEDULA DE CIUDADANIA">
                      Cédula de ciudadanía
                    </option>
                    <option className="label" value="PASAPORTE">
                      Pasaporte
                    </option>
                    <option className="label" value="CEDULA DE ESTRANJERIA">
                      CE
                    </option>
                    <option className="label" value="OTRO">
                      Otros
                    </option>
                  </Form.Select>
                  <p
                    className="caption custom-input__error"
                    style={{ paddingBottom: "3rem" }}
                  >
                    {errors.DocumentType?.message}
                  </p>
                </Row>
                <Row>
                  <Form.Group className="mb-5">
                    <Form.Control
                      {...register("FirstName")}
                      placeholder="Primer nombre*"
                      bsPrefix={
                        errors.FirstName
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.FirstName?.message}
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-5">
                    <Form.Control
                      {...register("LastName")}
                      placeholder="Primer apellido*"
                      bsPrefix={
                        errors.FirstName
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.LastName?.message}
                    </div>
                  </Form.Group>
                </Row>
              </Container>
            </Col>
            <Col md={4}>
              <Container fluid>
                <Row>
                  {documentType ? (
                    <Form.Group className="mb-5">
                      <Form.Control
                        {...register("DocumentType2")}
                        placeholder={"Ingrese tipo de documento*"}
                        bsPrefix={
                          errors.DocumentType2
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div
                        className="caption custom-input__error"
                        style={{ border: "1px sollid red" }}
                      >
                        {errors.DocumentType2?.message}
                      </div>
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-5">
                      <Form.Control
                        {...register("NumberDocument")}
                        placeholder={"Número de documento*"}
                        bsPrefix={
                          errors.NumberDocument
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div
                        className="caption custom-input__error"
                        style={{ border: "1px sollid red" }}
                      >
                        {errors.NumberDocument?.message}
                      </div>
                    </Form.Group>
                  )}
                </Row>
                <Row>
                  <Form.Group className="mb-5">
                    <Form.Control
                      {...register("SecondName")}
                      placeholder="Segundo nombre"
                      bsPrefix="input-group-container__no-icon label"
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.SecondName?.message}
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-5">
                    <Form.Control
                      {...register("SurName")}
                      placeholder="Segundo apellido"
                      bsPrefix="input-group-container__no-icon label"
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.SurName?.message}
                    </div>
                  </Form.Group>
                </Row>
              </Container>
            </Col>
            <Col md={4}>
              {documentType && (
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("NumberDocument")}
                    placeholder="Número de documento*"
                    type="text"
                    bsPrefix={
                      errors.NumberDocument
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.NumberDocument?.message}
                  </div>
                </Form.Group>
              )}
            </Col>
          </Row>
          <Row style={{ height: "15%" }}>
            <Col md={2}>
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                className="custom-input__button__secondary-color"
                onClick={handleBack}
              >
                Regresar
              </Button>
            </Col>
            <Col md={2}>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                className="custom-input__button__primary-color"
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default RegisterPhase2;
