import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalSaveFileAs = ({
  onClose,
  open,
  handleAgree,

  errors,
  register,
  reset,
  watch,
}) => {
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">Guardar como</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Row>
            <Col xs={12}>
              <label className="caption">
                Escriba el nombre para este nuevo archivo:
              </label>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Control
                {...register("fileName")}
                className="label"
                size="lg"
                defaultValue=""
                type="text"
                placeholder=""
                maxLength={250}
              />
            </Col>
            <Col xs={12} className="mt-2">
              <p className="caption custom-input__error">
                {errors.fileName?.message}
              </p>
              <label
                className={
                  watch("fileName") && watch("fileName")?.length == 250
                    ? "caption__warning"
                    : "caption__primary-color"
                }
              >
                Máximo 250 caracteres permitidos
              </label>
            </Col>
            <Col xs={12} className="mt-3">
              <label className="caption">
                Elige como quieres guardar el archivo
              </label>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Select
                {...register("saveType")}
                className="label mb-1"
                defaultValue=""
              >
                <option className="label" value="" disabled>
                  Guardar documento como
                </option>
                <option className="label" value="1">
                  Actualizar el borrador existente
                </option>
                <option className="label" value="2">
                  Guardar como un nuevo borrador
                </option>
                <option className="label" value="3">
                  Guardar como versión editable
                </option>
                <option className="label" value="4">
                  Guardar como versión no editable
                </option>
              </Form.Select>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                onClick={handleClose}
                className="custom-input__button__secondary-color__forced"
              >
                Volver
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleAgree}
                variant="contained"
                className="custom-input__button__primary-color__forced"
                disabled={!watch("saveType")}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSaveFileAs;
