import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";

import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addSignatureToPart } from "../../../../../../../parts/document/currentDocumentSlice";
import {
  PersonaJuridicaFields,
  PersonaNaturalFields,
  SignatariOPJ,
  SignatariOPN,
} from "../../../../../../../utils/signatoriesTypes";
const CreateSignature = () => {
  /**
   * Yup
   */

  const schemaCreate = yup.object().shape({
    signatoryName: yup.string().required("Este campo es obligatorio"),
  });

  /**
   * Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { partIndex } = useParams();

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();
  /**
   * Use Form
   */

  //Main form
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaCreate) });

  //Signatures array field
  const {
    fields: signaturesFields,
    append: signaturesAppend,
    remove: signaturesRemove,
  } = useFieldArray({
    control,
    name: "signatures",
  });

  const {
    fields: idField,
    append: idAppend,
    remove: idRemove,
  } = useFieldArray({
    control,
    name: "id",
  });

  /**
   * Use State
   */

  //Anchors
  const [anchorID, setAnchorID] = useState(null);
  const openID = Boolean(anchorID);

  const [anchorNewField, setAnchorNewField] = useState(null);
  const openNewField = Boolean(anchorNewField);

  //Modal

  const [openWarningModal, setOpenWarningModal] = useState(false);

  //Spaces counter

  const [spacesCounter, setSpacesCounter] = useState(0);

  //Provisional data for Modal

  const [provisionalData, setProvisionalData] = useState({});

  //Mutable menu

  const [newFieldMenu, setNetFieldMenu] = useState([
    {
      title: "Calidad en la que actua",
      isSelected: false,
      selectionClick: 0,
    },
    {
      title: "Razón Social",
      isSelected: false,
      selectionClick: 1,
    },
  ]);

  /**
   * Handles
   */

  //MENUS

  const handleClickID = (event) => {
    setAnchorID(event.currentTarget);
  };

  const handleClickNewField = (event) => {
    setAnchorNewField(event.currentTarget);
  };

  const handleCloseID = () => {
    setAnchorID(null);
  };

  const handleCloseNewField = () => {
    setAnchorNewField(null);
  };

  const handleMenuIDClick = (title) => {
    setAnchorID(null);
    idAppend({ name: title, value: "" });
  };

  const handleMenuNewFieldClick = (title) => {
    setAnchorNewField(null);
    const filteredMenu = newFieldMenu.map((menuElement) => {
      if (menuElement.title === title) {
        menuElement.isSelected = true;
      }
      return menuElement;
    });
    setNetFieldMenu(filteredMenu);
    signaturesAppend({ name: title, value: "" });
  };

  //Redux Setting

  const setNewInfoRedux = (data) => {
    const named = data.signatoryName;
    const gende = "M";
    let typ = "natural";

    if (data.id.length > 0 && data.id[0].name === "NIT") {
      typ = "legal";
    }

    let attribute = [];

    if (data.id.length > 0 && data.id[0].value !== "") {
      attribute.push(data.id[0]);
    }
    for (const element of data.signatures) {
      if (element.value !== "") {
        attribute.push(element);
      }
    }

    let blankspacesJuridica = [];
    let blankspacesNatural = [];

    let textJuri = SignatariOPJ.text;
    let textNatu = SignatariOPN.text;

    for (const field of PersonaJuridicaFields) {
      if (field.index === 1) {
        textJuri = textJuri.replace("nombre signatario", named);
        blankspacesJuridica.push({
          ...field,
          value: named,
        });
      } else if (field.index === 3) {
        textJuri = textJuri.replace("tipo de documento", data?.id[0].name);
        blankspacesJuridica.push({
          ...field,
          value: data?.id[0].name,
        });
      } else if (field.index === 5) {
        textJuri = textJuri.replace("número de documento", data?.id[0].value);
        blankspacesJuridica.push({
          ...field,
          value: data?.id[0].value,
        });
      } else {
        blankspacesJuridica.push(field);
      }
    }

    for (const field of PersonaNaturalFields) {
      if (field.index === 1) {
        textNatu = textNatu.replace("nombre signatario", named);
        blankspacesNatural.push({
          ...field,
          value: named,
        });
      } else if (field.index === 3) {
        textNatu = textNatu.replace("tipo de documento", data?.id[0].name);
        blankspacesNatural.push({
          ...field,
          value: data?.id[0].name,
        });
      } else if (field.index === 5) {
        textNatu = textNatu.replace("número de documento", data?.id[0].value);
        blankspacesNatural.push({
          ...field,
          value: data?.id[0].value,
        });
      } else {
        blankspacesNatural.push(field);
      }
    }

    const newSignatori = {
      name: named,
      gender: gende,
      text: typ === "legal" ? textJuri : textNatu,
      blankspaces: typ === "legal" ? blankspacesJuridica : blankspacesNatural,
      type: typ,
      signatures: { attributes: attribute },
    };

    dispatch(
      addSignatureToPart({ indexPart: partIndex, newSignatory: newSignatori })
    );
  };

  //Modal

  const handleAgreeWarning = () => {
    setOpenWarningModal(false);
    setNewInfoRedux(provisionalData);
    navigate(-1);
  };

  const handleDisagreeWarning = () => {
    setOpenWarningModal(false);
  };

  //Counting spaces

  const countLeftSpaces = (data) => {
    let count = 0;
    const nonRequired = data.signatures;
    const name = data.id;
    if (name.length > 0) {
      if (name[0].value === "") {
        count += 1;
      }
    }

    for (const element of nonRequired) {
      if (element.value === "") {
        count += 1;
      }
    }
    setSpacesCounter(count);
    return count;
  };

  //Submit Form

  const SubmitFormInfo = (data) => {
    const counter = countLeftSpaces(data);
    if (counter === 0) {
      setNewInfoRedux(data);
      navigate(-1);
    } else {
      setProvisionalData(data);
      setOpenWarningModal(true);
    }
  };

  //Delete signature field
  const handleDeleteField = (index, title) => {
    const filteredMenu = newFieldMenu.map((menuElement) => {
      if (menuElement.title === title) {
        menuElement.isSelected = false;
      }
      return menuElement;
    });
    setNetFieldMenu(filteredMenu);
    signaturesRemove(index);
  };

  /**
   * Constantes
   */

  const menuID = [
    {
      title: "NIT",
      selectionClick: 0,
    },
    {
      title: "Cédula de Ciudadanía",
      selectionClick: 1,
    },
    {
      title: "Pasaporte",
      selectionClick: 2,
    },
    {
      title: "Cédula de Extranjería",
      selectionClick: 3,
    },
  ];

  const menuNewField = [
    {
      title: "Calidad en la que actua",
      isSelected: false,
      selectionClick: 0,
    },
    {
      title: "Razón Social",
      isSelected: false,
      selectionClick: 1,
    },
  ];

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container"
    >
      {/**Breadcrum */}
      <Row className="col-start" style={{ height: "7%" }}>
        <Breadcrum />
      </Row>
      {/**Title and notification */}
      <Row style={{ height: "10%" }}>
        <Col md={3} className="col-start">
          <SectionTitle title="Crear firma" />
        </Col>
        <Col md={8}></Col>
        <Col className="col-flex" md={"auto"}>
          <IconButton size="large">
            <NotificationsOutlinedIcon
              className="heading__primary-color"
              fontSize="large"
            />
          </IconButton>
        </Col>
      </Row>
      {/**Text description */}
      <Row className="title-part" style={{ height: "5%" }}>
        Campos de información del signatario
      </Row>
      {/**Form new Signatory and its signatures */}
      <Row>
        <Form onSubmit={handleSubmit(SubmitFormInfo)}>
          <Row style={{ height: "7vh" }} className="col-start">
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  {...register("signatoryName")}
                  placeholder="Nombre completo del signatario"
                  className={
                    errors.signatoryName
                      ? "input-group-container__no-icon-error label text-fields"
                      : "input-group-container__no-icon label text-fields"
                  }
                />
              </Form.Group>
              <div className="caption custom-input__error">
                {errors.signatoryName?.message}
              </div>
            </Col>
          </Row>
          <Row className="cols-end">
            <Container fluid>
              {idField.map((id, index) => (
                <Row
                  key={id.id}
                  index={index}
                  className="col-start"
                  style={{ height: "7vh" }}
                >
                  <Col md={4}>
                    {" "}
                    <Form.Group>
                      <Form.Control
                        {...register(`id.0.value`)}
                        placeholder={id.name}
                        className="input-group-container__no-icon label text-fields"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Button
                      variant="contained"
                      className="button-softblue-folders"
                      onClick={() => {
                        idRemove(index);
                      }}
                    >
                      <DeleteOutlineIcon fontSize="large" />
                    </Button>
                  </Col>
                </Row>
              ))}

              {signaturesFields.map((signature, index) => (
                <div key={signature.id}>
                  <Row className="col-start" style={{ height: "7vh" }}>
                    <Col md={4}>
                      {" "}
                      <Form.Group>
                        <Form.Control
                          {...register(`signatures.${index}.value`)}
                          placeholder={signature.name}
                          className="input-group-container__no-icon label text-fields"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={1}>
                      <Button
                        variant="contained"
                        className="button-softblue-folders"
                        onClick={() => {
                          handleDeleteField(index, signature.name);
                        }}
                      >
                        <DeleteOutlineIcon fontSize="large" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}

              {idField.length < 1 && (
                <Row
                  className="cols-end"
                  style={{ height: "7vh", width: "142px" }}
                >
                  <Col md={2}>
                    <Button
                      className="button-softblue-folders__identification"
                      onClick={handleClickID}
                      startIcon={<AddIcon />}
                      endIcon={<ExpandMoreIcon />}
                    >
                      Agregar identificación
                    </Button>
                  </Col>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorID}
                    open={openID}
                    onClose={handleCloseID}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {menuID.map((menuElement, index) => (
                      <div key={uuidv4()}>
                        <MenuItem
                          onClick={() => {
                            handleMenuIDClick(menuElement.title);
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontFamily: "Roboto",
                              fontSize: "1.4rem",
                              fontWeight: "regular",
                              lineHeight: "2rem",
                              color: "#676879",
                            }}
                            primary={menuElement.title}
                          />
                        </MenuItem>
                        {index + 1 === menuID.length ? <></> : <hr />}
                      </div>
                    ))}
                  </Menu>
                </Row>
              )}

              {signaturesFields.length < 2 && (
                <Row
                  className="cols-end"
                  style={{ height: "7vh", width: "192px" }}
                >
                  <Col md={2}>
                    <Button
                      className="button-softblue-folders__details"
                      onClick={handleClickNewField}
                      startIcon={<AddIcon />}
                      endIcon={<ExpandMoreIcon />}
                    >
                      Agregar campo
                    </Button>
                  </Col>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorNewField}
                    open={openNewField}
                    onClose={handleCloseNewField}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {newFieldMenu.map(
                      (menuElement, index) =>
                        !menuElement.isSelected && (
                          <div key={uuidv4()}>
                            <MenuItem
                              onClick={() => {
                                handleMenuNewFieldClick(menuElement.title);
                              }}
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  fontFamily: "Roboto",
                                  fontSize: "1.4rem",
                                  fontWeight: "regular",
                                  lineHeight: "2rem",
                                  color: "#676879",
                                }}
                                primary={menuElement.title}
                              />
                            </MenuItem>
                            {index + 1 === menuNewField.length ? <></> : <hr />}
                          </div>
                        )
                    )}
                  </Menu>
                </Row>
              )}
            </Container>
          </Row>
          {/**Confirmation buttons */}
          <Row style={{ height: "13vh" }}>
            <Col md={"auto"} className="col-start">
              <Button
                className="button-softblue-folders__navigation"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Descartar
              </Button>
            </Col>
            <Col md={"auto"} className="col-start">
              <Button
                type="submit"
                className="button-primary-folders__navigation"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      {/**Modals */}
      <ModalDecision
        title={"Guardar"}
        message={
          <p>
            ¿Seguro que quieres guardar? Tienes{" "}
            <span style={{ fontWeight: "bold" }}>
              {spacesCounter} {spacesCounter > 1 ? "campos" : "campo"}
            </span>{" "}
            sin rellenar.
          </p>
        }
        open={openWarningModal}
        onClose={handleDisagreeWarning}
        agreeText="Guardar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeWarning}
        handleDisagree={handleDisagreeWarning}
      />
    </Container>
  );
};

export default CreateSignature;
