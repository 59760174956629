import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

export const SectionMultipleTitle = ({ options, setTab, tab }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setTab(newValue);
  };

  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        sx={{ borderBottom: 1, borderColor: "divider" }}
        value={selectedTab}
        onChange={handleChange}
      >
        {options?.map((item) => {
          return (
            <Tab
              className="label__primary-color__page-title"
              key={item.value}
              label={item.label}
              value={item.value}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
