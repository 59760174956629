import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LoadingContent from "../../../../../components/loadingContent";
import ModalAssociateTags from "../../../../../components/Modals/ModalAssociateTags";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import { setFoldersStatus } from "../../../../../parts/folders/foldersSlice";
import { createBusinessFolder } from "../../../../../services/business_folder/businessFolderService";
import { TerminateRequest } from "../../../../../services/general/requestServices";
import { CODES } from "../../../../../utils/codes";
const RequestAssociateNew = () => {
  const { requestId, consecutive } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [responseData, setResponseData] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [newFolderInfo, setNewFolderInfo] = useState({});
  const [isOpenModalSaveDocuments, setIsOpenModalSaveDocuments] =
    useState(false);

  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es obligatorio"),
    description: yup.string().nullable().notRequired(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleAgreeSaveDocuments = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalSaveDocuments(false);
      const { data } = newFolderInfo;

      const obj = {
        requestId: requestId,
        folderAssociated: data.responseMessage?.data?._id,
      };
      const service = await TerminateRequest(obj);
      if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
        navigate("/service/requests");
      }
      setResponseData(service);
      setResponseModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequest = async (data) => {
    try {
      setIsLoading(true);
      const payloadToken = JSON.parse(localStorage.payloadToken);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const labels = [];
      for (const select of tagsSelected) {
        labels.push(select._id);
      }
      const obj = {
        ...data,
        company: payloadToken.company,
        labels,
        corporateUnit,
        timeZone: new Date().getTimezoneOffset() / -60,
      };

      const create = await createBusinessFolder(obj);
      if (
        create.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        create.data.responseCode === CODES.COD_RESPONSE_HTTP_ERROR
      ) {
        setResponseData({
          status: create.data.responseCode,
          data: { responseMessage: create.data.responseMessage },
        });
        setResponseModal(true);
      } else if (
        create.data.success &&
        create.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setNewFolderInfo(create);
        setIsOpenModalSaveDocuments(true);
        dispatch(setFoldersStatus({ newStatus: "fetch" }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  return (
    <>
      <Container fluid style={{ height: "100%" }}>
        {isLoading && <LoadingContent />}
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={"Crear carpeta"} />
        </Row>
        <Form onSubmit={handleSubmit(handleRequest)}>
          <Row className="heading" style={{ marginTop: "4%" }}>
            Nombre de la carpeta*
          </Row>
          <Row style={{ marginTop: "1%" }}>
            <Col md={4}>
              <Form.Group className="mb-5">
                <Form.Control
                  {...register("name")}
                  placeholder="Escriba el nombre"
                  bsPrefix={
                    errors.name
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.name?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="heading">Descripción de la carpeta</Row>
          <Row style={{ marginTop: "1%" }}>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("description")}
                  placeholder="Escriba la descripción"
                  bsPrefix={
                    errors.description
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.description?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="heading" style={{ margin: "1% 0" }}>
            Asociar etiquetas a la carpeta
          </Row>
          <Row style={{ marginBottom: "3%" }}>
            <Col xs={"auto"}>
              <ModalAssociateTags
                handleTagsSelected={handleTagsSelected}
                previousTagsSelected={tagsSelected}
                type="BUSINESSFOLDER"
              />
            </Col>

            {tagsSelected.map((tag, idx) => (
              <Col
                xs={"auto"}
                style={{ display: "flex", alignItems: "center" }}
                key={uuidv4()}
              >
                <span className="caption custom-badges__disabled">
                  {tag.name + " "}{" "}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() => handleDeleteTag(tag)}
                  />
                </span>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={"auto"}>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                className="custom-input__button__primary-color"
                size="large"
              >
                Crear carpeta
              </Button>
            </Col>
          </Row>
          <ModalInfo
            title={"Información"}
            responseData={responseData}
            open={responseModal}
            onClose={() => {
              setResponseModal(false);
              navigate("/service/requests");
            }}
          />
          <ModalDecision
            title={"Almacenar documentos"}
            agreeText={"Continuar"}
            disagreeText={"Cancelar"}
            handleAgree={handleAgreeSaveDocuments}
            handleDisagree={() => navigate(-1)}
            message={
              <span>
                ¿Estás seguro de que quieres almacenar los documentos de la
                solicitud <b>{consecutive}</b> en la nueva carpeta{" "}
                <b>{newFolderInfo?.data?.responseMessage?.data?.name}</b>?
              </span>
            }
            onClose={() => navigate(-1)}
            open={isOpenModalSaveDocuments}
          />
        </Form>
      </Container>
    </>
  );
};

export default RequestAssociateNew;
