import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCategoriesByCompanyAndResourceType } from "../../services/categories/categoriesService";
import { CODES } from "../../utils/codes";

export const clauseCategorySlice = createSlice({
  name: "clauseCategories",
  initialState: {
    categoriesList: [],
    status: "fetch",
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchClauseCategories.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchClauseCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.categoriesList = action.payload;
      })
      .addCase(fetchClauseCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getClauseCategoriesList = (state) =>
  state.clauseCategories.categoriesList;

export const getClauseCategoriesStatus = (state) =>
  state.clauseCategories.status;

export const fetchClauseCategories = createAsyncThunk(
  "categories/fetchClauseCategories",
  async () => {
    const companyId = localStorage.getItem("company");
    let obj = {
      companyId: companyId,
      resourceType: "CLAUSES",
    };
    const response = await getCategoriesByCompanyAndResourceType(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return response.data.responseMessage;
    }
  }
);

export default clauseCategorySlice.reducer;
