import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import moment from "moment";
import * as yup from "yup";
//Material Import
import { Button, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

//Components
import MetricBarPie from "../../../../../components/dashboards/MetricBarPie";
import MetricBarVertical from "../../../../../components/dashboards/MetricBarVertical";
import MetricCountIcon from "../../../../../components/dashboards/MetricCountIcon";
import MetricCountPercentage from "../../../../../components/dashboards/MetricCountPercentage";
import MetricRequestGraph from "../../../../../components/dashboards/MetricRequestGraph";
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import {
  downloadDashboardCsv,
  getEstimateAlerts,
  getEstimateApprovals,
  getEstimateDocuments,
  getEstimatePerfomanceRequests,
  getEstimateSignaturesFlows,
  getEstimateSignaturesUsers,
  getEstimateTask,
  getEstimateTotalRequests,
  getEstimateUsers,
  getEstimateWorkloadPerUser,
} from "../../../../../services/dashboard/dashboardService";
import { ANS_FILTER } from "../../../../../utils/approvalsState";
import { CODES } from "../../../../../utils/codes";
import MetricProgressBar from "../../../../../components/dashboards/MetricProgressBar";
import { ModalListSelectCheckBoxV2 } from "../../../../../components/Modals/ModalListCheckBoxV2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardPanel = () => {
  //Hooks
  const navigate = useNavigate();
  const schemaDates = yup.object().shape({
    initialDate: yup
      .string("*Este campo es obligatorio")
      .test("max", "No debe ser mayor a la fecha final", function (value) {
        const finalDateRef = yup.ref("finalDate");
        return value < this.resolve(finalDateRef);
      }),

    finalDate: yup
      .string("*Este campo es obligatorio")
      .test("min", "No debe ser menor a la fecha inicial", function (value) {
        const initialDateRef = yup.ref("initialDate");
        return value > this.resolve(initialDateRef);
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaDates),
  });

  //Variables - useState
  const [isLoading, setIsloading] = useState(false);
  const [countDocUsers, setCountDocUsers] = useState({});
  const [countRequest, setCountRequest] = useState(null);
  const [countWorkloadUsers, setCountWorkloadUsers] = useState(null);
  const [countRequestExtra, setCountRequestExtra] = useState(null);
  const [countRequestAverageTime, setCountRequestAverageTime] = useState(null);
  const [countAlerts, setCountAlerts] = useState(null);
  const [countSignaturesFlows, setCountSignaturesFlows] = useState(null);
  const [countSignaturesUsers, setCountSignaturesUsers] = useState(null);
  const [countApprove, setCountApprove] = useState(null);
  const [countTask, setCountTask] = useState(null);
  const [filterDates, setFilterDates] = useState({
    initialDate: null,
    finalDate: null,
  });
  const [downloading, setDownloading] = useState(false);
  const [indicatorsSelected, setIndicatorsSelected] = useState([]);
  const [openDownloadBox, setOpenDownloadBox] = useState(false);

  //Constants
  const colorList = [
    "#00CA72",
    "#F9600B",
    "#71C9C1",
    "#753ABC",
    "#E44258",
    "#84BC00",
    "#067BBD",
    "#00374F",
    "#17B4BC",
  ];
  const itemCountTypeUsers = [
    {
      itemList: [
        {
          icon: <NoteAddOutlinedIcon fontSize="large" />,
          count: countDocUsers?.countDocument?.createdDoc,
          description: "Creados",
        },
        {
          icon: <VisibilityOutlinedIcon fontSize="large" />,
          count: countDocUsers?.countDocument?.reviewDoc,
          description: "Revisados",
        },
      ],
      title: "Documentos",
    },
    {
      itemList: [
        {
          icon: <ManageAccountsIcon fontSize="large" />,
          count: countDocUsers?.countUsers?.adminUser,
          description: "Administrador",
        },
        {
          icon: <ManageAccountsOutlinedIcon fontSize="large" />,
          count: countDocUsers?.countUsers?.serviceUser,
          description: "De servicios",
        },
        {
          icon: <PersonOutlineOutlinedIcon fontSize="large" />,
          count: countDocUsers?.countUsers?.externalUser,
          description: "Solicitantes",
        },
      ],
      title: "Usuarios",
    },
  ];
  const itemListOthers = [
    {
      title: "Firmas",
      icon: <FeedOutlinedIcon fontSize="large" />,
      generalCount: [
        {
          count: countSignaturesFlows?.generalCountSignatureExist,
          description: "Firmas existentes",
          icon: <FeedOutlinedIcon fontSize="large" />,
        },
        {
          count: countSignaturesFlows?.generalCountSignatureInProcess,
          description: "Firmas en proceso",
          icon: <RateReviewOutlinedIcon fontSize="large" />,
        },
      ],
      optionsChart: ["Procesos", "Usuarios"],
      dataChart1: countSignaturesFlows?.data,
      dataChart2: countSignaturesUsers?.data,
      filter: [
        {
          labelFilter: "Solicitudes",
          name: "signatures",
        },
        {
          labelFilter: "Panel lateral",
          name: "signaturesSidePanel",
        },
      ],
    },
    {
      title: "Aprobaciones",
      icon: <NotificationsNoneOutlinedIcon fontSize="large" />,
      generalCount: countApprove?.generalCount,
      description: "Aprobaciones existentes",
      dataChart1: countApprove?.data,
    },
    {
      title: "Alertas",
      icon: <NotificationsNoneOutlinedIcon fontSize="large" />,
      generalCount: countAlerts?.generalCount,
      description: "Alertas existentes",
      dataChart1: countAlerts?.data,
    },
  ];

  //Handlers
  const handleSubmitFilters = (data) => {
    setFilterDates({
      initialDate: moment(data.initialDate).format("YYYY/MM/DD"),
      finalDate: moment(data.finalDate).format("YYYY/MM/DD"),
    });
  };

  const handleRequestCount = async () => {
    try {
      setIsloading(true);
      const corporateUnitId = localStorage.getItem("corporateUnitId");
      const companyId = localStorage.getItem("company");

      const obj = {
        companyId,
        corporateUnitId,
        startDate: filterDates?.initialDate,
        endDate: filterDates?.finalDate,
      };

      const [
        { value: documentListRequest },
        { value: usersListRequest },
        { value: requestsListRequest },
        { value: averageTimeRequestListRequest },
        { value: worloadUserListRequest },
        { value: alertsListRequest },
        { value: approveListRequest },
        { value: taskListRequest },
        { value: signaturesFlowsListRequest },
        { value: signaturesUsersListRequest },
      ] = await Promise.allSettled([
        getEstimateDocuments(obj),
        getEstimateUsers(obj),
        getEstimateTotalRequests(obj),
        getEstimatePerfomanceRequests(obj),
        getEstimateWorkloadPerUser(obj),
        getEstimateAlerts(obj),
        getEstimateApprovals(obj),
        getEstimateTask(obj),
        getEstimateSignaturesFlows(obj),
        getEstimateSignaturesUsers(obj),
      ]);

      if (
        documentListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        documentListRequest.data.success
      ) {
        setCountDocUsers((prev) => ({
          ...prev,
          countDocument: {
            createdDoc:
              documentListRequest.data.responseMessage.data.byType[0].value,
            reviewDoc:
              documentListRequest.data.responseMessage.data.byType[1].value,
          },
        }));
      }
      if (
        usersListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        usersListRequest.data.success
      ) {
        setCountDocUsers((prev) => ({
          ...prev,
          countUsers: {
            adminUser: usersListRequest.data.responseMessage.data.find(
              (element) => element?.name?.toLowerCase()?.includes("admin")
            )?.value,
            externalUser: usersListRequest.data.responseMessage.data.find(
              (element) => element?.name?.toLowerCase()?.includes("externo")
            )?.value,
            serviceUser: usersListRequest.data.responseMessage.data.find(
              (element) => element?.name?.toLowerCase()?.includes("servicio")
            )?.value,
          },
        }));
      }
      if (
        requestsListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestsListRequest.data.success
      ) {
        let listFilter = [];
        requestsListRequest.data.responseMessage.data.byStatus.forEach(
          (requestState, index) => {
            if (
              requestState.name !== "En revisión" &&
              requestState.name !== "En proceso" &&
              requestState.name !== "Activa" &&
              requestState.name !== "Sin asignar" &&
              requestState.name !== "Nueva" &&
              requestState.name !== "Retornada"
            ) {
              listFilter.push({
                name: requestState.name,
                backgroundColor: colorList[index],
              });
            }
          }
        );
        setCountRequest({
          generalCount: requestsListRequest.data.responseMessage.data.Total,
          optionFilter: [
            {
              name: "Estados",
              data: requestsListRequest.data.responseMessage.data.byStatus.filter(
                (requestState) =>
                  requestState.name !== "En revisión" &&
                  requestState.name !== "En proceso" &&
                  requestState.name !== "Activa" &&
                  requestState.name !== "Sin asignar" &&
                  requestState.name !== "Nueva" &&
                  requestState.name !== "Retornada"
              ),
            },
            {
              name: "Tipo de documento",
              data: requestsListRequest.data.responseMessage.data.bySubtype,
              extra: "Request",
            },
            {
              name: "Tipo de solicitud",
              data: requestsListRequest.data.responseMessage.data.byType,
            },
          ],
          filter: listFilter,
        });
        setCountRequestExtra({
          byStatus:
            requestsListRequest.data.responseMessage.data.byStatus.filter(
              (requestState) => requestState.name === "Sin asignar"
            ),
        });
      }
      if (
        averageTimeRequestListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        averageTimeRequestListRequest.data.success
      ) {
        const optionsLabelRequestANS = [
          { description: "Dentro del ANS" },
          {
            description: (
              <p style={{ padding: "0", margin: "0" }}>
                Solucionado después del ANS{" "}
                <span
                  className="caption__other-Color-Active"
                  onClick={() => {
                    handleNavigateRequest(ANS_FILTER.EXTEMPORANEA.name);
                  }}
                >
                  Ver más
                </span>
              </p>
            ),
            classNameColorText: "__warning-color",
          },
          {
            description: (
              <p style={{ padding: "0", margin: "0" }}>
                Sin solución aunque venció el ANS{" "}
                <span
                  className="caption__other-Color-Active"
                  onClick={() => {
                    handleNavigateRequest(ANS_FILTER.VENCIDA.name);
                  }}
                >
                  Ver más
                </span>
              </p>
            ),
          },
          {
            description: (
              <p style={{ padding: "0", margin: "0" }}>
                Sin gestionar en las últimas 24 horas{" "}
                <span
                  className="caption__other-Color-Active"
                  onClick={() => {
                    handleNavigateRequest(ANS_FILTER.INACTIVA.name);
                  }}
                >
                  Ver más
                </span>
              </p>
            ),
            classNameColorText: "__error-color",
          },
        ];

        const listRequestANS =
          averageTimeRequestListRequest.data.responseMessage.data.byStatus
            .filter((request) => request.name !== "Sin Solucionar")
            .map((requestANS, index) => ({
              percentage:
                averageTimeRequestListRequest.data.responseMessage.data
                  .totalRequests === 0
                  ? "0%"
                  : `${Math.trunc(
                      (requestANS.value /
                        averageTimeRequestListRequest.data.responseMessage.data
                          .totalRequests) *
                        100
                    )}%`,
              countTotal: `(${requestANS.value}/${averageTimeRequestListRequest.data.responseMessage.data.totalRequests})`,
              title: requestANS.name,
              description: optionsLabelRequestANS[index].description,
              classNameColorText:
                optionsLabelRequestANS[index]?.classNameColorText,
            }));

        //Se calcula la cantidad de solicitudes cuyo tiempo de respuesta esta 'por debajo' al tiempo promedio de respuesta.
        const belowAverage =
          averageTimeRequestListRequest.data.responseMessage.data.byPerformanceTime.find(
            (item) => item.name === "Menor al promedio"
          );
        //Se calcula la cantidad de solicitudes cuyo tiempo de respuesta esta 'cercano' al tiempo promedio de respuesta.
        const aroundAverage =
          averageTimeRequestListRequest.data.responseMessage.data.byPerformanceTime.find(
            (item) => item.name === "Cercano al promedio"
          );
        //Se calcula la cantidad de solicitudes cuyo tiempo de respuesta esta 'por encima' al tiempo promedio de respuesta.
        const aboveAverage =
          averageTimeRequestListRequest.data.responseMessage.data.byPerformanceTime.find(
            (item) => item.name === "Superior al promedio"
          );

        setCountRequestAverageTime([
          ...listRequestANS,
          {
            // Grafica para visualizar la cantidad de solicitudes cercanas al tiempo promedio de respuesta
            specialComponent: (
              <>
                <Row
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    color: "#00374F",
                    fontWeight: "bolder",
                  }}
                >
                  {`${averageTimeRequestListRequest.data.responseMessage.data?.average.days} Día(s)`}
                </Row>
                <Row
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    color: "#00374F",
                    fontWeight: "bolder",
                  }}
                >
                  {`${averageTimeRequestListRequest.data.responseMessage.data?.average.hours}:${averageTimeRequestListRequest.data.responseMessage.data?.average.minutes} Hora(s)`}
                </Row>
                <Row className="caption">{`Tiempo promedio de atención`}</Row>
              </>
            ),
          },
          // {
          //   // Grafica para visualizar la cantidad de solicitudes cercanas al tiempo promedio de respuesta
          //   specialComponent: (
          //     <>
          //       <Row className="justify-content-md-center">
          //         <Container fluid>
          //           <MetricProgressBar
          //             text={belowAverage.name}
          //             value={belowAverage.value}
          //             maxValue={belowAverage.maxValue}
          //             colorProgress={"#4DCAFA"}
          //           />
          //           <MetricProgressBar
          //             text={aroundAverage.name}
          //             value={aroundAverage.value}
          //             maxValue={aroundAverage.maxValue}
          //             colorProgress={"#00CA72"}
          //           />
          //           <MetricProgressBar
          //             text={aboveAverage.name}
          //             value={aboveAverage.value}
          //             maxValue={aboveAverage.maxValue}
          //             colorProgress={"#E44258"}
          //           />
          //         </Container>
          //       </Row>
          //       <Row className="justify-content-md-center">
          //         <Col
          //           xs={10}
          //           style={{
          //             textAlign: "center",
          //             fontSize: "18px",
          //             color: "#00374F",
          //             fontWeight: "bolder",
          //           }}
          //         >{` - `}</Col>
          //       </Row>
          //     </>
          //   ),
          // },
        ]);
      }
      if (
        worloadUserListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        worloadUserListRequest.data.success
      ) {
        setCountWorkloadUsers(
          worloadUserListRequest.data.responseMessage.data.map((typeUsers) => ({
            ...typeUsers,
            filter: [
              {
                name: "Estados",
                labelFilter:
                  worloadUserListRequest.data.responseMessage.data[0]?.list[0]?.byStatus
                    ?.filter(
                      (requestState) =>
                        requestState.name !== "En revisión" &&
                        requestState.name !== "En proceso" &&
                        requestState.name !== "Activa" &&
                        requestState.name !== "Sin asignar"
                    )
                    .map((statusRequest, index) => ({
                      name: statusRequest.name,
                      backgroundColor: colorList[index],
                    })),
              },
              {
                name: "Tipo de documento",
                labelFilter:
                  worloadUserListRequest.data.responseMessage.data[0]?.list[0]?.bySubtype?.map(
                    (documentType, index) => ({
                      name: documentType.name,
                      backgroundColor: colorList[index],
                    })
                  ),
              },
              {
                name: "Subtipo de documento",
                labelFilter:
                  worloadUserListRequest.data.responseMessage.data[0]?.list[0]?.byDocumentType?.map(
                    (documentSubType, index) => ({
                      name: documentSubType.name,
                      backgroundColor: colorList[index],
                    })
                  ),
              },
              {
                name: "Tipo de solicitud",
                labelFilter:
                  worloadUserListRequest.data.responseMessage.data[0]?.list[0]?.byType?.map(
                    (documentType, index) => ({
                      name: documentType.name,
                      backgroundColor: colorList[index],
                    })
                  ),
              },
            ],
          }))
        );
      }
      if (
        signaturesFlowsListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        signaturesFlowsListRequest.data.success
      ) {
        // Response de 'estimateSignaturesFlows' - Estimado de Flujos de firmas
        setCountSignaturesFlows({
          generalCountSignatureInProcess:
            signaturesFlowsListRequest.data.responseMessage.data
              ?.totalSignaturesPending,
          generalCountSignatureExist:
            signaturesFlowsListRequest.data.responseMessage.data
              ?.totalSignatures,
          data: [
            {
              ...signaturesFlowsListRequest.data.responseMessage.data
                .signatures,
              name: "Solicitudes",
            },
            {
              ...signaturesFlowsListRequest.data.responseMessage.data
                .signaturesSidePanel,
              name: "Panel lateral",
            },
          ],
        });
      }
      if (
        signaturesUsersListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        signaturesUsersListRequest.data.success
      ) {
        // Response de 'estimateSignaturesFlows' - Estimado de firmantes
        setCountSignaturesUsers({
          generalCountSignatureInProcess:
            signaturesUsersListRequest.data.responseMessage.data
              ?.totalSignaturesPending,
          generalCountSignatureExist:
            signaturesUsersListRequest.data.responseMessage.data
              ?.totalSignatures,
          data: [
            {
              ...signaturesUsersListRequest.data.responseMessage.data
                .signatures,
              name: "Solicitudes",
            },
            {
              ...signaturesUsersListRequest.data.responseMessage.data
                .signaturesSidePanel,
              name: "Panel lateral",
            },
          ],
        });
      }
      if (
        alertsListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        alertsListRequest.data.success
      ) {
        setCountAlerts({
          generalCount:
            alertsListRequest.data.responseMessage.data?.totalAlerts.toString(),
          data: alertsListRequest.data.responseMessage.data?.byStatus,
        });
      }
      if (
        approveListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        approveListRequest.data.success
      ) {
        setCountApprove({
          generalCount:
            approveListRequest.data.responseMessage.data?.total.toString(),
          data: approveListRequest.data.responseMessage.data?.byType,
        });
      }
      if (
        taskListRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        taskListRequest.data.success
      ) {
        setCountTask({
          generalCount:
            taskListRequest.data.responseMessage.data?.totalAlerts.toString(),
          data: taskListRequest.data.responseMessage.data?.byStatus,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleNavigateRequest = (filterANS) => {
    navigate(`/service/requests/?filter=${filterANS}`);
  };

  const handleDownloadCSV = async (value) => {
    setDownloading(true);
    const corporateUnitId = localStorage.getItem("corporateUnitId");
    const company = localStorage.getItem("company");
    const downloadService = await downloadDashboardCsv({
      companyId: company,
      corporateUnitId,
      startDate: filterDates?.initialDate,
      endDate: filterDates?.finalDate,
      indicatorsSelected: value,
    });

    if (downloadService.status === CODES.COD_RESPONSE_HTTP_OK) {
      // 1. Definir nombre del archivo
      let actualFileName = "";
      if (filterDates?.initialDate) {
        let formattedInitalDate = moment(filterDates?.initialDate).format(
          "DD-MMM-YYYY"
        );
        let formattedEndDate = moment(filterDates?.finalDate).format(
          "DD-MMM-YYYY"
        );
        actualFileName = `${formattedInitalDate} a ${formattedEndDate}. Indicadores Legal AI.zip`;
      } else {
        actualFileName = `Indicadores Legal AI.zip`;
      }

      // 2. Obtener stream/zip de la respuesta del servicio. En el servicio de back, se definió 'application/zip' como content-type
      const blob = new Blob([downloadService.data], {
        type: downloadService.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // 3. Asignar el nombre al archivo para descargar
      link.setAttribute("download", actualFileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    setDownloading(false);
  };

  const handleOpenDownloadBox = () => {
    setOpenDownloadBox(true);
  };

  const handleCloseDownloadBox = () => {
    setIndicatorsSelected([]);
    setOpenDownloadBox(false);
  };

  const handleAcceptDownloadBox = async (value) => {
    setOpenDownloadBox(false);
    setIndicatorsSelected(value);
    await handleDownloadCSV(value);
  };

  //useEffects
  useEffect(() => {
    handleRequestCount();
  }, [filterDates]);

  return (
    <Container fluid className="custom-accordion__container-dashboard">
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={3} className="col-start">
          <SectionTitle title={"Indicadores"} />
        </Col>
      </Row>
      <Row style={{ padding: "1% 1%" }} className="display-large">
        Indicadores Estratégicos
      </Row>
      <Row style={{ padding: "1% 1%" }} className="body-content">
        A continuación podrás consultar los indicadores más relevantes de la
        gestión de tus documentos
      </Row>
      <Form onSubmit={handleSubmit(handleSubmitFilters)}>
        <Row>
          <Col xs={2} className="subheading">
            Fecha inicial
          </Col>
          <Col xs={2} className="subheading">
            Fecha final
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Form.Control
              type="date"
              className="form__date"
              {...register("initialDate")}
              placeholder="Fecha Inicial"
              bsPrefix={
                errors.initialDate
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              max={new Date().toISOString().split("T")[0]}
            />
            <span className="caption custom-input__error">
              {errors.initialDate?.message}
            </span>
          </Col>
          <Col xs={2}>
            <Form.Control
              type="date"
              className="form__date"
              {...register("finalDate")}
              placeholder="Fecha Final"
              bsPrefix={
                errors.finalDate
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              max={new Date().toISOString().split("T")[0]}
            />
            <span className="caption custom-input__error">
              {errors.finalDate?.message}
            </span>
          </Col>
          <Col xs={1}>
            <Button
              fullWidth
              className="custom-input__button__primary-color"
              type="submit"
              variant="contained"
            >
              Aplicar
            </Button>
          </Col>

          <Col xs={3}>
            <Button
              startIcon={<DownloadIcon fontSize="large" />}
              className="custom-input__button__secondary-color"
              onClick={() => {
                handleOpenDownloadBox();
              }}
              variant="contained"
            >
              {downloading ? "Descargando..." : "Descargar Información"}
            </Button>
          </Col>
        </Row>
      </Form>
      {countDocUsers.countDocument && countDocUsers.countUsers && (
        <Grid container direction="row" spacing={2} className="mt-1 mb-4">
          <Grid item xs key={itemCountTypeUsers[0].title}>
            <MetricCountIcon {...itemCountTypeUsers[0]} />
          </Grid>
          <Grid item xs key={itemCountTypeUsers[1].title}>
            <MetricCountIcon {...itemCountTypeUsers[1]} tooltip={true} />
          </Grid>
        </Grid>
      )}
      {countRequest && (
        <>
          <Row>
            <Col md={3} className="col-start">
              <SectionTitle title={"Solicitudes"} />
            </Col>
          </Row>
          <Row style={{ margin: "3% 0" }}>
            <MetricRequestGraph
              generalCount={countRequest.generalCount}
              generalCountDescription={"Solicitudes creadas"}
              iconButtonFilter={<TuneIcon fontSize="large" />}
              iconGeneralCount={<EmailOutlinedIcon fontSize="large" />}
              textButtonFilter={"Solicitudes filtradas por"}
              optionFilter={countRequest.optionFilter}
              filterColor={countRequest.filter}
            />
          </Row>
        </>
      )}
      {countRequestAverageTime && (
        <>
          <Row>
            <Col md={3} className="col-start">
              <SectionTitle title={"Indicadores de cumplimiento"} />
            </Col>
          </Row>
          <Row style={{ margin: "3% 0" }}>
            <MetricCountPercentage
              title={"SOLICITUDES"}
              itemList={countRequestAverageTime}
            />
          </Row>
        </>
      )}
      {countRequestExtra && countWorkloadUsers && (
        <>
          <Row>
            <Col md={3} className="col-start">
              <SectionTitle title={"Asignación de cargas"} />
            </Col>
          </Row>
          <Row style={{ margin: "3% 0" }}>
            {countWorkloadUsers.map((userType) => (
              <Col xs={6} key={userType.name}>
                <MetricBarVertical {...userType} />
              </Col>
            ))}
          </Row>
        </>
      )}
      {countTask &&
        countApprove &&
        countSignaturesFlows &&
        countSignaturesUsers &&
        countAlerts && (
          <>
            <Row>
              <Col md={3} className="col-start">
                <SectionTitle title={"Otros Indicadores"} />
              </Col>
            </Row>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
              className="mt-1 mb-4"
            >
              {itemListOthers.map((item, index) => (
                <Grid item xs={8} key={item.title}>
                  <MetricBarPie {...item} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      <ModalListSelectCheckBoxV2
        title={"Descargar Información"}
        subtitle={"Selecciona los indicadores que quieres descargar:"}
        inputLabelText={"Indicadores"}
        textButtonAccept={"Descargar"}
        textButtonCancel={"Cancelar"}
        iconAccept={<DownloadOutlinedIcon />}
        iconCancel={<CloseIcon />}
        list={["Solicitudes", "Firmas", "Aprobaciones", "Alertas"]}
        open={openDownloadBox}
        onClose={handleCloseDownloadBox}
        onCloseAndDownload={handleAcceptDownloadBox}
        setValuesSelected={setIndicatorsSelected}
      />
    </Container>
  );
};

export default DashboardPanel;
