export const CLAUSES_INFO = {
  clauseTitle: {
    title: "Título de la cláusula",
    description:
      "Incorpora el título de tu cláusula. Este título se mostrará cuando estés redactando tu contrato.",
  },
  partsDefinition: {
    title: "Rol contractual",
    description:
      "Incorpora los nombres que asignarás a las partes dentro de esta cláusula  (Ej.Contratante, Contratista), los cuales serán automáticamente reemplazados en el texto cuando pongas las denominaciones de las partes dentro de tu contrato, siempre y cuando hagas uso de los botones 'Parte A' y 'Parte B' ubicados en la parte superior de los cuadros de texto.",
  },

  clauseText: {
    title: "Texto de la cláusula",
    description:
      "Ingresa el texto de la cláusula. Puedes incluir espacios en blanco para que sean rellenados cuando estés redactando el contrato. Si quieres añadir numerales, usa el botón que se encuentra en la parte inferior del cuadro de texto. También puedes usar el botón de 'Añadir parágrafo' para que estos queden enumerados automáticamente o importar parágrafos de otras cláusulas.",
  },
  numeralTitle: {
    title: "Título del numeral",
    description:
      "Ingresa el título del numeral. Este se mostrará en el contrato junto al texto del numeral.",
  },
  subNumeralTitle: {
    title: "Título del subnumeral",
    description:
      "Ingresa el título del subnumeral. Este se mostrará en el contrato junto al texto del subnumeral.",
  },
  numeralText: {
    title: "Texto del numeral",
    description: "Ingresa el texto del numeral.",
  },
  subNumeralText: {
    title: "Texto del subnumeral",
    description: "Ingresa el texto del subnumeral.",
  },
  paragraphText: {
    title: "Texto del parágrafo",
    description: "Ingresa el texto del parágrafo.",
  },
  sensitiveClause: {
    title: "Cláusula sensible",
    description:
      "Las cláusulas sensibles son todas aquellas que, una vez identificadas en un contrato, requieren especial atención por parte del abogado.",
  },
  inFavor: {
    title: "A favor de",
    description:
      "Selecciona a favor de quién está redactada la presente cláusula. Esto te  servirá como filtro, cuando estés redactando contratos o generando minutas. Al elegir 'Balanceada' significa que la cláusula está redactada en doble vía.",
  },
  categories: {
    title: "Categorías",
    description:
      "Selecciona la categoría a la que pertenece esta cláusula, de la lista desplegable. Podrás elegir hasta dos categorías. Esta clasificación te servirá como filtro cuando estés redactando contratos o generando minutas.",
  },
  tags: {
    title: "Etiquetas",
    description:
      "Selecciona las etiquetas que quieras asociar a esta cláusula. Las etiquetas te permiten hacer filtros de forma posterior, para encontrar rápidamente las cláusulas que necesitas.",
  },
  contractType: {
    title: "Minuta",
    description:
      "Agrega esta cláusula a cualquiera de las minutas que ya tengas creadas en la biblioteca.",
  },
  partsDefinitionContract: {
    title: "Roles contractuales",
    description:
      "Incorpora los roles que asignarás a las partes dentro de este contrato (Ej.Contratante, Contratista).",
  },
};
