import { VisibilityOutlined } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FeedIcon from "@mui/icons-material/Feed";
import FolderIcon from "@mui/icons-material/Folder";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import TaskIcon from "@mui/icons-material/Task";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { MDBBadge, MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import CustomSearch from "../../../../../components/Datatable/customSearch";
import DetailsBar from "../../../../../components/DetailsBar/DetailsBar";
import LoadingContent from "../../../../../components/loadingContent";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import {
  deleteAlert,
  findAllDeletedAlerts,
  updateAlertStates,
} from "../../../../../services/alerts/alertsServices";
import { getFileDocument } from "../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../utils/codes";
import { codesDocumentType } from "../../../../../utils/codesDocumentType";
import { FOLDER_STATE } from "../../../../../utils/documentStates";
import { dateNormal } from "../../../../../utils/getDate";
import { setAlertStatus } from "./alertSlice";

const STATE_COLOR_ID = {
  [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
  [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
  [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
  [CODES.COD_LIGHT_BLUE]: "#FFD7B1",
};

const AlertsPaperBasket = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //state
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de eliminación
        </label>,
      ],
      field: "deleteAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Días restantes
        </label>,
      ],
      field: "countDays",
    },
    {
      label: [<label aria-hidden="true" key="1"></label>],
      field: "actions",
    },
  ];
  const [documentList, setDocumentList] = useState({
    rows: [],
    columns: columns,
  });
  const [filterDocumentList, setFilterDocumentList] = useState({
    rows: [],
    columns: columns,
  });
  const [anchorSelected, setAnchorSelected] = useState(null);
  const openSelected = Boolean(anchorSelected);
  const [selectedElement, setSelectedElement] = useState({
    id: "",
    type: "",
    name: "",
  });

  const [openModalInfo, setOpenModalInfo] = useState({
    message: "",
    title: "",
    isOpen: false,
  });
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [filterMyId, setFilterMyId] = useState(false);
  const [documentListData, setDocumentListData] = useState(null);
  const [businessFolderFatherId, setBusinessFolderFatherId] = useState(null);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const optionMenu = [
    {
      title: "Restaurar",
      icon: <RestoreFromTrashOutlinedIcon fontSize="large" />,
      option: 0,
    },
    {
      title: "Eliminar permanentemente",
      icon: <DeleteForeverOutlinedIcon fontSize="large" />,
      option: 1,
    },
    {
      title: "Ver detalles",
      icon: <InfoOutlinedIcon fontSize="large" />,
      option: 2,
    },
    {
      title: "Visualizar",
      icon: <VisibilityOutlined fontSize="large" />,
      option: 3,
    },
  ];

  const TYPE_ITEM_DELETED = {
    undefined: "",
    document: "documento",
    folder: "carpeta",
    clause: "cláusula",
    alert: "alerta",
    contractType: "minuta",
    task: "tarea",
  };

  const TYPE_DELETE_ELEMENT = {
    folder: {
      name: "Carpeta",
      icon: <FolderIcon fontSize="large" className="heading__primary-color" />,
      option: 1,
    },
    alert: {
      name: "Alerta",
      icon: (
        <NotificationsIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      option: 2,
    },
    contractType: {
      name: "Minuta",
      icon: (
        <AssignmentIcon fontSize="large" className="heading__primary-color" />
      ),
      option: 3,
    },
    clause: {
      name: "Cláusula",
      icon: (
        <StickyNote2Icon fontSize="large" className="heading__primary-color" />
      ),
      option: 4,
    },
    task: {
      name: "Tarea",
      icon: <TaskIcon fontSize="large" className="heading__primary-color" />,
      option: 5,
    },
  };

  const getIconByValidation = (doc, fileType) => {
    const defaultIcon = (
      <FeedIcon fontSize="large" className="heading__primary-color" />
    );

    return TYPE_DELETE_ELEMENT?.[doc?.typeElementDeleted]?.icon || defaultIcon;
  };

  const getNameByType = (doc, fileType) => {
    if (fileType) return fileType?.name;
    return (
      TYPE_DELETE_ELEMENT?.[doc?.typeElementDeleted]?.name || "No definido"
    );
  };

  //handles
  const handleCloseSelected = () => {
    setAnchorSelected(null);
    setSelectedElement({ id: "", type: "", name: "" });
  };
  const documentDeletedRequest = async () => {
    try {
      setIsloading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const companyId = localStorage.getItem("company");

      const alertListRequest = await findAllDeletedAlerts({
        corporateUnit,
        company: companyId,
      });

      if (alertListRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        let finalList = [];

        if (alertListRequest.data.responseMessage.data) {
          const alerts = alertListRequest.data.responseMessage.data.map(
            (item) => ({ ...item, typeElementDeleted: "alert" })
          );
          finalList = finalList.concat(alerts);
        }

        setDocumentListData(finalList);
        const componentDocumentList = finalList.map((doc) => {
          const fileType = Object.values(codesDocumentType).find(
            (code) => code._id === doc.fileType
          );
          const modified = doc.deletedAt
            ? moment(doc.deletedAt).format("DD/MM/YYYY HH:mm:ss")
            : moment(doc.updatedAt).format("DD/MM/YYYY HH:mm:ss");
          return {
            element: (
              <Row
                style={{
                  paddingLeft: "2%",
                  paddingTop: "1%",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                <Col xs={"auto"} className="datatable-CB__tr-name">
                  {getIconByValidation(doc, fileType)}
                </Col>
                <Col className="datatable-CB__tr-name">
                  {doc.name ? doc.name : doc.title}
                </Col>
              </Row>
            ),
            type: getNameByType(doc, fileType),
            deleteAt: modified,
            countDays: doc.daysUntilDelete,
            deletedBy: doc.deletedBy,
            actions: (
              <IconButton
                onClick={(event) => {
                  setSelectedElement(doc);
                  setAnchorSelected(event.currentTarget);
                }}
              >
                <MoreHorizIcon fontSize="large" />
              </IconButton>
            ),
          };
        });
        setDocumentList({ rows: componentDocumentList, columns: columns });
        setFilterDocumentList({
          rows: componentDocumentList,
          columns: columns,
        });

        setIsloading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleManageOptionsMenu = (option) => {
    switch (option) {
      case 0:
        handleRestore();
        break;
      case 1:
        setOpenModalDecision(true);
        break;
      case 2:
        setAnchorSelected(null);
        setOpenDetailsDrawner(true);
        break;
      case 3:
        setAnchorSelected(null);
        if (selectedElement.fileType) {
          handlePreviewPdf(selectedElement);
        } else {
          setBusinessFolderFatherId(selectedElement._id);
          setOpenModal(true);
        }
        break;
      default:
        break;
    }
  };
  const restoreServiceByOption = async (selectedElement) => {
    const option = selectedElement.fileType
      ? 0
      : TYPE_DELETE_ELEMENT?.[selectedElement?.typeElementDeleted]?.option;

    if (option === 2) {
      return await updateAlertStates({
        id: selectedElement._id,
        newStatus: FOLDER_STATE.new._id,
      });
    }
  };

  const deleteServiceByOption = async (selectedElement) => {
    const option = selectedElement.fileType
      ? 0
      : TYPE_DELETE_ELEMENT?.[selectedElement?.typeElementDeleted]?.option;

    const company = localStorage.getItem("company");

    if (option === 2) {
      return await deleteAlert({ id: selectedElement._id, company });
    }
  };

  const handleRestore = async () => {
    try {
      setIsloading(true);
      setAnchorSelected(null);
      const requestRestoreDoc = await restoreServiceByOption(selectedElement);
      if (
        requestRestoreDoc.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestRestoreDoc.data.success
      ) {
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Correcto",
          isOpen: true,
        });
        if (selectedElement.typeElementDeleted === "alert") {
          dispatch(setAlertStatus({ status: "fetch" }));
        }
      } else {
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Error",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log("========== HANDLE RESTOR START ERROR ==========");
      console.error(error);
      console.log("========== HANDLE RESTOR END ERROR ==========");
    } finally {
      setIsloading(false);
    }
  };

  const handleDeletePerma = async () => {
    try {
      setAnchorSelected(null);
      setIsloading(true);
      const requestRestoreDoc = await deleteServiceByOption(selectedElement);
      if (
        requestRestoreDoc.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestRestoreDoc.data.success
      ) {
        setIsloading(false);
        setOpenModalDecision(false);
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Correcto",
          isOpen: true,
        });
      } else {
        setIsloading(false);
        setOpenModalDecision(false);
        setOpenModalInfo({
          message: requestRestoreDoc,
          title: "Error",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePreviewPdf = async (version) => {
    try {
      setIsloading(true);
      const getRequest = await getFileDocument(
        version.filenameInBucket,
        version.bucketName,
        version.isWord
      );
      if (
        getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        getRequest.data.success
      ) {
        let docView = getRequest.data.responseMessage.buffer;
        if (
          getRequest.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const { data } = getRequest.data.responseMessage.buffer || {};
          const { contentType } = getRequest.data?.responseMessage || {};
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);
          docView = pdfConverted?.data?.responseMessage?.data?.buffer;
        }
        const file = URL.createObjectURL(
          new Blob([new Uint8Array(docView?.data).buffer], {
            type: "application/pdf",
          })
        );
        window.open(file);
      } else if (
        getRequest?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        getRequest?.data?.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(getRequest);
        throw new Error("No tienes los permisos suficientes");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };
  const getpageName = (selectedElement) => {
    if (selectedElement?.fileType) {
      return "Document";
    } else if (selectedElement?.alertDate) {
      return "Alert";
    } else if (selectedElement?.clauses) {
      return "Minute";
    } else if (selectedElement?.inFavor) {
      return "Clause";
    } else {
      return "FolderBasket";
    }
  };
  //useEffect
  useEffect(() => {
    documentDeletedRequest();
  }, []);
  useEffect(() => {
    if (filterMyId) {
      const infoUser = JSON.parse(localStorage.payloadToken);
      setFilterDocumentList((prev) => ({
        rows: prev.rows.filter(
          (row) =>
            row?.deletedBy?.firstName === infoUser.firstName &&
            row?.deletedBy?.firstSurname === infoUser.firstSurname
        ),
        columns: columns,
      }));
    } else {
      setFilterDocumentList(documentList);
    }
  }, [filterMyId]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={3} className="col-start">
          <SectionTitle title={"Basurero de alertas"} />
        </Col>
        <Col md={8}></Col>
      </Row>
      <Row style={{ padding: "2% 1%" }} className="caption">
        Cualquier alerta puede ser restaurada hasta 30 días después de ser
        eliminado.
      </Row>
      <Row>
        <Col xs={5}>
          <CustomSearch
            dataTableInfo={documentList}
            setFilteredDataTable={setFilterDocumentList}
          />
        </Col>
        <Col xs={3}>
          <Form.Group className="body" controlId="Terminos">
            <Form.Check
              className="label__description__default"
              value={filterMyId}
              type="checkbox"
              label="Solo eliminados por mí"
              onChange={(e) => {
                setFilterMyId(e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={filterDocumentList}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "elementos"]}
          fullPagination
          noRecordsFoundLabel={"No se han encontrado resultados"}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorSelected}
          open={openSelected}
          onClose={handleCloseSelected}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {optionMenu.map((doc, index) => (
            <MenuItem
              key={uuidv4()}
              onClick={() => {
                handleManageOptionsMenu(doc.option);
              }}
            >
              <ListItemIcon>{doc.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={`${doc.title} ${
                  doc.option === 0
                    ? TYPE_ITEM_DELETED[selectedElement?.typeElementDeleted]
                    : ""
                }`}
              />
            </MenuItem>
          ))}
        </Menu>
      </Row>
      <ModalInfo
        title={openModalInfo.title}
        open={openModalInfo.isOpen}
        onClose={() => {
          setOpenModalInfo((prev) => ({ ...prev, isOpen: false }));
          navigate(-1);
        }}
        responseData={openModalInfo.message}
      />
      <ModalDecision
        title={"Eliminar"}
        message={`¿Estás seguro de eliminar definitivamente?`}
        agreeText={"Sí, eliminar definitivamente"}
        disagreeText={"No eliminar"}
        handleAgree={() => {
          handleDeletePerma();
        }}
        handleDisagree={() => setOpenModalDecision(false)}
        onClose={() => setOpenModalDecision(false)}
        open={openModalDecision}
      />
      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedElement}
        pageName={getpageName(selectedElement)}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Visualizar"}
        message={ChooseModalComponentByElemente(
          selectedElement,
          documentListData,
          setSelectedElement,
          businessFolderFatherId,
          setOpenModal,
          handlePreviewPdf
        )}
        open={openModal}
        onClose={setOpenModal}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setOpenModal(false);
        }}
        handleDisagree={() => {
          setOpenModal(false);
        }}
      />
    </Container>
  );
};

const ChooseModalComponentByElemente = (selectedElement) => {
  if (selectedElement.typeElementDeleted === "alert") {
    return <ModalComponentAlerts selectedElement={selectedElement} />;
  }
};

const ModalComponentAlerts = ({ selectedElement }) => (
  <>
    <Row>
      <Col className="home-init-cards-row__alert-title">
        {selectedElement.name}
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Fecha de vencimiento de tareas:{" "}
          {
            <small className="caption">
              {dateNormal(selectedElement.tasksDeadline)}
            </small>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Estado de alerta:{" "}
          {
            <MDBBadge
              color=""
              pill
              className="caption"
              style={{
                backgroundColor:
                  STATE_COLOR_ID[selectedElement?.state?.colorId] || "#F9DADE",
                fontSize: "1.2rem",
                borderRadius: "0.8rem",
                padding: "0.4rem",
              }}
            >
              <span className="custom-badge" style={{ color: "black" }}>
                {selectedElement?.state?.name}
              </span>
            </MDBBadge>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Carpeta:{" "}
          {
            <small>
              {selectedElement?.documentAssociated?.folderAssociated?.name}
            </small>
          }
        </h4>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>
          Documento:{" "}
          {<small>{selectedElement?.documentAssociated?.name}</small>}
        </h4>
      </Col>
    </Row>
  </>
);

export default AlertsPaperBasket;
