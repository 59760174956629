import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
//Material Import
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { MDBBadge, MDBDataTableV5 } from "mdbreact";
//parts
import Breadcrum from "../../../../../parts/breadcrum";
//components
import CustomSearch from "../../../../../components/Datatable/customSearch";
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
//Icons
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Services
import {
  fetchAlerts,
  getAlertsList,
  getAlertsStatus,
  setAlertList,
  setAlertStatus,
} from "./alertSlice";
//Utils
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Buffer } from "buffer";
import dateFormat from "dateformat";
import fileDownload from "js-file-download";
import DetailsBar from "../../../../../components/DetailsBar/DetailsBar";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import { WordBlobDocument } from "../../../../../components/WordCreator/WordCreator";
import {
  getAlerts,
  getAlertsByFilter,
  updateAlertStates,
} from "../../../../../services/alerts/alertsServices";
import {
  getDocById,
  getFileDocument,
} from "../../../../../services/documents/documentsRequest";
import { CODES } from "../../../../../utils/codes";
import {
  codesDocumentType,
  codesDocumentTypeForAlert,
} from "../../../../../utils/codesDocumentType";
import { FOLDER_STATE } from "../../../../../utils/documentStates";
import { onSortHTMLElement } from "../../../../../utils/findComponentHTML";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ModalUploadDocs from "../../../../../components/Modals/ModalUploadDocs";
import ModalAssociateVersion from "../../../../../components/Modals/modalAssociateVersion";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../../../../parts/folders/foldersSlice";
import {
  setName,
  setReports,
  setSelectedReport,
} from "../../../../../parts/document/currentDocumentSlice";
import { NoResultsFound } from "../../../../../components/Results/NoResultsFound";
import { getDiffTime } from "../../../../../utils/getDate";
const AlertService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [filteredWord, setFilteredWord] = useState("");
  const [selectedAlert, setSelectedAlert] = useState({});

  const [documentType, setDocumentType] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState({
    title: "",
    isOpen: false,
    response: {},
  });
  const statusAlerts = useSelector(getAlertsStatus);
  const alertsList = useSelector(getAlertsList);
  const [documentData, setDocumentData] = useState({});
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [filterMyId, setFilterMyId] = useState(false);
  const openMenu = Boolean(anchorEl);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  const [documentSelected, setDocumentSelected] = useState({});
  const columns = [
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Nombre de la alerta
        </label>,
      ],
      field: "alert",
    },
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Responsable
        </label>,
      ],
      field: "assignedUser",
    },
    {
      label: [
        <label
          key="2"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          {" "}
          Documento
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label
          key="3"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "150px" }}
        >
          Fecha de activación
        </label>,
      ],
      field: "alertdate",
    },
    {
      label: [
        <label
          key="4"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "150px" }}
        >
          Fecha de vencimiento
        </label>,
      ],
      field: "expiration",
      width: 150,
    },
    {
      label: [
        <label
          key="5"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "120px" }}
        >
          Estado
        </label>,
      ],
      field: "state",
    },
    {
      label: [
        <label
          key="6"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "192px" }}
        >
          Acciones
        </label>,
      ],
      field: "seeAlert",
    },
  ];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [stateSelected, setStateSelected] = useState("");
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreate = Boolean(anchorElCreate);

  /* UseStates para filtros personalizados de las tablas */

  const [searchParams] = useSearchParams();
  const isQueryParam = searchParams.get("filter");

  /**
   * useSelector
   */

  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);

  /* UseEffects Fetch Alerts */

  useEffect(() => {
    if (foldersStatus === "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [foldersStatus]);
  useEffect(() => {
    if (statusAlerts === "loading" || statusAlerts === "fetch") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusAlerts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setReports({ reports: [] }));
        dispatch(setSelectedReport({ report: null }));
        if (statusAlerts === "fetch") {
          const company = localStorage.getItem("company");
          const corporateUnit = JSON.parse(
            localStorage.getItem("corporateUnit")
          );
          const obj = {
            company: company,
            corporateUnit: corporateUnit._id,
            filterMyId: filterMyId,
          };
          dispatch(fetchAlerts(obj));
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!isQueryParam) {
      fetchData();
    }
  }, [isQueryParam, statusAlerts, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusAlerts !== "fetch" && filterMyId) {
          setIsLoading(true);
          const company = localStorage.getItem("company");
          const corporateUnit = JSON.parse(
            localStorage.getItem("corporateUnit")
          );
          const obj = {
            company: company,
            corporateUnit: corporateUnit._id,
            filterMyId: filterMyId.toString(),
          };

          const service = await getAlerts(obj);
          if (service.status) {
            if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
              const rows = buildRows(service.data.responseMessage.data);
              setFilteredDataTable({
                columns: columns,
                rows: rows,
              });
            }
          }

          setIsLoading(false);
        } else if (!filterMyId) {
          const rows = buildRows(alertsList);
          setFilteredDataTable({
            columns: columns,
            rows: rows,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [filterMyId]);

  useEffect(() => {
    const fetchDataByFilter = async () => {
      try {
        setIsLoading(true);
        const payload = JSON.parse(localStorage.getItem("payloadToken"));
        const companyId = localStorage.getItem("company");
        const corporateUnitId = localStorage.getItem("corporateUnitId");
        let response;
        switch (isQueryParam) {
          case "expiredAlerts":
            response = await getAlertsByFilter({
              attorneyId: payload.userId,
              expiredAlerts: true,
              corporateUnitId,
              companyId,
            });
            break;
          case "activesAlerts":
            response = await getAlertsByFilter({
              attorneyId: payload.userId,
              activeAlerts: true,
              corporateUnitId,
              companyId,
            });
            break;
          case "toActivateAlerts":
            response = await getAlertsByFilter({
              attorneyId: payload.userId,
              toActivateAlerts: true,
              corporateUnitId,
              companyId,
            });
            break;

          default:
            break;
        }
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          const alerts =
            response.data.responseMessage.data.expiredAlerts ||
            response.data.responseMessage.data.toActivateAlerts ||
            response.data.responseMessage.data.activeAlerts ||
            [];
          dispatch(setAlertList({ alerts }));
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (isQueryParam) {
      fetchDataByFilter();
    }
  }, [isQueryParam]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rows = buildRows(alertsList);

        /* Datos transformados para tablas */
        setDataTable({
          columns: columns,
          rows: rows,
        });
        setFilteredDataTable({
          columns: columns,
          rows: rows,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [alertsList]);

  useEffect(() => {
    if (documentData.name) {
      setFolderSelectedModal(true);
    }
  }, [documentData]);

  const handleDownload = async (
    filenameInBucket,
    bucketName,
    name,
    type,
    id
  ) => {
    try {
      let newName = name;
      if (type === codesDocumentType.draft._id) {
        const doc = await getDocById(id);
        const { documentContent } = doc.data.responseMessage;
        const blob = await WordBlobDocument({
          title: documentContent.title,
          headers: documentContent.headers,
          parts: documentContent.parts,
          clauses: documentContent.clauses,
          partHeader: "",
          numberingType: "number",
          otherSignatories: [],
        });
        fileDownload(blob, name + ".docx");
      } else {
        const downloadDocument = await getFileDocument(
          filenameInBucket,
          bucketName,
          false
        );
        const contentType =
          downloadDocument.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? ".docx"
            : ".pdf";
        if (!name.match(/\.([0-9a-z]+)(?:[?#]|$)/i)) {
          newName = name + contentType;
        }
        const bf = Buffer.from(
          downloadDocument.data.responseMessage.buffer.data
        );
        fileDownload(bf, newName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAlert = async (idAlert, isSeries) => {
    try {
      setOpenModalDelete(false);
      setIsLoading(true);
      const updateRequest = await updateAlertStates({
        id: idAlert,
        newStatus: FOLDER_STATE.deleted._id,
        ...(isSeries && { isDeleteAllSerie: true }),
      });
      if (
        updateRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        updateRequest.data.success
      ) {
        dispatch(setAlertStatus({ status: "fetch" }));
        navigate("/service/alerts/delete");
      } else {
        setIsOpenModalInfo({
          isOpen: true,
          title: "Error",
          response: updateRequest.data.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAlertBackgroundColor = (colorId) => {
    switch (colorId) {
      case CODES.COD_LIGHT_ORANGE:
        return "#FFD7B1";
      case CODES.COD_LIGHT_GREEN:
        return "#CCF4E3";
      case CODES.COD_LIGHT_PURPLE:
        return "#E6E9EF";
      case CODES.COD_COLOR_BLUE:
        return "#FFD7B1";
      default:
        return "#F9DADE";
    }
  };

  function getUserName(user) {
    const assignedUser = user?.assignedTo;

    if (assignedUser?.firstName && assignedUser?.firstSurname) {
      return `${assignedUser.firstName} ${assignedUser.firstSurname}`;
    } else if (
      assignedUser?.companies &&
      assignedUser.companies[0]?.companyData?.companyName
    ) {
      return assignedUser.companies[0].companyData.companyName;
    } else {
      return "";
    }
  }

  /* buildRows transforma los datos de las alertas y los adapta para que sea agradable 
  a la vista del DataTable y el usuario, agregando componentes en algunas columnas */
  const buildRows = (alerts) => {
    const rows = alerts.map((alert) => {
      return {
        alert: (
          <Row style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{alert?.name}</Col>
            {getDiffTime(alert.createdAt, 3, "LESS_EQUAL_THAN") && (
              <Col xs={"auto"}>
                <label
                  className="caption custom-badges__secundary"
                  style={{ margin: 0 }}
                >
                  Nuevo
                </label>
              </Col>
            )}
          </Row>
        ),
        assignedUser: getUserName(alert),
        document: (
          <label
            onClick={() => {
              handleDownload(
                alert.documentAssociated?.filenameInBucket,
                alert.documentAssociated?.bucketName,
                alert.documentAssociated?.name,
                alert.documentAssociated?.fileType,
                alert.documentAssociated?._id
              );
            }}
            style={{
              textDecoration: "underline",
              color: "#067BBD",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          >
            {alert.documentAssociated?.name}
          </label>
        ),
        alertdate: dateFormat(alert.alertDate, "dd/mm/yyyy", true),
        expiration: dateFormat(alert.tasksDeadline, "dd/mm/yyyy", true),
        state: (
          <Row style={{ alignItems: "center" }}>
            <Col xs={"auto"}>
              <MDBBadge
                color=""
                pill
                className="caption"
                style={{
                  backgroundColor: getAlertBackgroundColor(alert.state.colorId),
                  fontSize: "1.2rem",
                  boxShadow: "none",
                  padding: "0.4rem",
                }}
              >
                <span className="custom-badge">{alert.state.name}</span>
              </MDBBadge>
            </Col>
          </Row>
        ),
        seeAlert: (
          <Row>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  setSelectedAlert(alert);
                  setOpenModalDelete(true);
                }}
              >
                <DeleteOutlineOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  const idDocument = alert?.documentAssociated?._id || "without-document";
                  navigate(`${alert?._id}/edit/${idDocument}`, { state: { alert } });
                }}
              >
                <VisibilityOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  setOpenDetailsDrawner(true);
                  setSelectedAlert(alert);
                }}
              >
                <InfoOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
        ),
      };
    });
    return rows;
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterByState = (state) => {
    setStateSelected(state);
    const rowsFilter = alertsList.filter((alert) => alert.state.name === state);
    setFilteredDataTable({
      columns: columns,
      rows: buildRows(rowsFilter),
    });
    setAnchorEl(null);
  };

  const handleDeleteFilterState = () => {
    setStateSelected("");
    setFilteredDataTable({
      columns: columns,
      rows: buildRows(alertsList),
    });
  };
  const handleInfoStatus = () => navigate("infoStatus");

  const handleClick = (event) => {
    setAnchorElCreate(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElCreate(null);
  };
  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Alertas"} />
        </Col>

        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<AutoDeleteOutlinedIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => {
                navigate(`delete`);
              }}
            >
              <p className="button">Papelera</p>
            </Button>
          </Col>
        </Col>
      </Row>
      <Row xs={"auto"} className="mt-4">
        <p className="text-description">
          Crea, gestiona y personaliza tus alertas
        </p>
      </Row>
      <br />
      <Row className="mt-3">
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            endIcon={<ExpandMoreIcon />}
            className="custom-input__button__primary-color"
            onClick={handleClick}
          >
            <p className="button">Crear alerta</p>
          </Button>

          <Menu
            anchorEl={anchorElCreate}
            open={openCreate}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenModalUploadDocument(true);
                setAnchorElCreate(null);
              }}
            >
              <ListItemIcon>
                <UploadOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Cargar documento y asignar alerta
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenModalVersion(true);
                setAnchorElCreate(null);
              }}
            >
              <ListItemIcon>
                <FolderOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Seleccionar documento desde Legal AI
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                navigate("create/without-document");
              }}
            >
              <ListItemIcon>
                <NotificationsOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Crear alerta sin asociar a documento
              </ListItemText>
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row className="align-items-center" style={{ rowGap: "15px"}}>
        <Col lg={4} md={8} xs={12}>
          <CustomSearch
            placeholder="Buscar alerta"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
            maxLength={20}
            setFilteredWord={setFilteredWord}
          />
        </Col>
        <Col />
        <Col
          lg={"auto"}
          md={6}
          xs={12}
        >
          <Form.Group controlId="Terminos">
            <Form.Check
              value={filterMyId}
              type="checkbox"
              label="Solo alertas creadas por mí"
              onChange={(e) => {
                setFilterMyId(e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={"auto"} md={6} xs={12}>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            endIcon={<ExpandMoreIcon />}
            className="custom-input__button__secondary-color"
            onClick={handleFilterClick}
          >
            Filtrar por estados
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleFilterByState("Inactiva")}>
              <ListItemText>Inactiva</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleFilterByState("Activa")}>
              <ListItemText>Activa</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleFilterByState("Vencida")}>
              <ListItemText>Vencida</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleFilterByState("Completada")}>
              <ListItemText>Completada</ListItemText>
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right", marginRight: "1rem" }}>
        {stateSelected !== "" && (
          <Col xs={"auto"}>
            <span className="caption custom-badges__disabled">
              {stateSelected + " "}{" "}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilterState()}
              />
            </span>
          </Col>
        )}
      </Row>
      <Row className="data-table dt-list-items">
        <MDBDataTableV5
          noRecordsFoundLabel={
            !filteredWord ? "Aún no tienes alertas creadas" : <NoResultsFound />
          }
          hover
          pagingTop
          searchBottom={false}
          entries={10}
          data={filteredDataTable}
          x
          infoLabel={["Mostrando", "a", "de", "alertas"]}
          fullPagination
          onSort={(value) => {
            onSortHTMLElement({
              excludesColumns: ["seeAlert"],
              sort: value,
              filteredDataTable,
              setFilteredDataTable,
            });
          }}
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <InfoOutlinedIcon fontSize="large" />
        </Col>
        <Col className="caption">
          Si quieres saber más acerca de los estados de una alerta
          <span
            className="caption__other-Color-Active"
            onClick={handleInfoStatus}
          >
            {` Haz click aquí`}
          </span>
        </Col>
      </Row>

      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedAlert}
        pageName={"Alert"}
      />
      <ModalInfo
        title={isOpenModalInfo.title}
        onClose={() =>
          setIsOpenModalInfo((prev) => ({ ...prev, isOpen: false }))
        }
        open={isOpenModalInfo.isOpen}
        responseData={isOpenModalInfo.response}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Eliminar alerta"}
        message={
          selectedAlert?.isRepeated
            ? "¿Qué quieres eliminar?"
            : "¿Seguro que quieres eliminar la alerta?"
        }
        open={openModalDelete}
        onClose={setOpenModalDelete}
        agreeText={selectedAlert?.isRepeated ? "Solo esta alerta" : "Aceptar"}
        disagreeText={
          selectedAlert?.isRepeated
            ? "Todas las repeticiones de la alerta"
            : "Cancelar"
        }
        handleAgree={() => {
          handleDeleteAlert(selectedAlert._id);
        }}
        handleDisagree={() => {
          if (selectedAlert?.isRepeated) {
            handleDeleteAlert(selectedAlert._id, true);
          } else {
            setOpenModalDelete(false);
          }
        }}
      />

      <ModalUploadDocs
        open={openModalUploadDocument}
        title={"Selecciona el documento para crear la alerta"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o word y pesar máximo 75mb"
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setOpenModalUploadDocument(false);
        }}
        typeDocument={"Contrato"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf o Word"
        maxFileSize={78643200} //75MB
        additionalFields={
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Select
                  className="label"
                  defaultValue={""}
                  onChange={(event) => {
                    const { value } = event.target;
                    setDocumentType(value);
                  }}
                >
                  <option value="" disabled defaultValue className="label">
                    Selecciona el tipo de documento
                  </option>
                  {codesDocumentTypeForAlert.map((type) => {
                    return (
                      <option value={type._id} className="label">
                        {type.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        }
      />

      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalVersion(false);
          setDocumentSelected(file);
          navigate(`create/${file._id}`);
        }}
        onClose={() => {
          setOpenModalVersion(false);
        }}
        open={openModalVersion}
        folders={folders}
        versionSelected={documentSelected}
        title="Asociar documento"
      />

      <ModalDecision
        title={"Guardar documento"}
        message={"Almacena este documento en una de tus carpetas"}
        open={folderSelectedModal}
        onClose={setFolderSelectedModal}
        agreeText="Seleccionar carpeta"
        handleAgree={() => {
          dispatch(setName({ name: documentData.name }));
          navigate(`/service/alerts/alert/folder/select-folder`, {
            state: {
              documentData,
              documentType,
            },
          });
        }}
      />
    </Container>
  );
};

export default AlertService;
