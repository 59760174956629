export const codesDocumentType = {
  test: {
    _id: "621e355b414c0a5baee099f4",
    name: "test",
  },
  contract: {
    _id: "624af416c455d0c496c43ed3",
    name: "Contrato",
  },
  contractSigned: {
    _id: "624af49ec455d0c496c43ed4",
    name: "Contrato Firmado",
  },
  Warranty: {
    _id: "624af4b6c455d0c496c43ed5",
    name: "Garantía",
  },
  Attachments: {
    _id: "624af4cdc455d0c496c43ed6",
    name: "Anexos",
  },
  Minutes: {
    _id: "624af4dfc455d0c496c43ed7",
    name: "Acta",
  },
  Communication: {
    _id: "624af534c455d0c496c43ed8",
    name: "Comunicación",
  },
  Other: {
    _id: "624af556c455d0c496c43ed9",
    name: "Otro",
  },
  word: {
    _id: "62697f7a53b77f66350b5b39",
    name: "Word",
  },
  draft: {
    _id: "6260550620423a03ae5392bb",
    name: "Borrador",
  },
  prueba: {
    _id: "6273e7d75029d3045d608261",
    name: "prueba",
  },
  otrosi: {
    _id: "64ef57959cd54202a0dd921b",
    name: "Otro sí",
  },

  OtherDocs: {
    _id: "65f9f1fc1a9cdf3f514a4ee0",
    name: "Otro documento",
  },
  alianza: {
    _id: "65e74cb1554814090a09f2fc",
    name: "Alianza",
  },
};

export const codesDocumentTypeForAlert = [
  {
    _id: "624af416c455d0c496c43ed3",
    name: "Contrato",
  },

  {
    _id: "624af4b6c455d0c496c43ed5",
    name: "Garantía",
  },
  {
    _id: "624af4cdc455d0c496c43ed6",
    name: "Anexos de contrato",
  },
  {
    _id: "624af4dfc455d0c496c43ed7",
    name: "Actas",
  },
  {
    _id: "624af534c455d0c496c43ed8",
    name: "Comunicaciones",
  },
  {
    _id: "624af556c455d0c496c43ed9",
    name: "Otros documentos",
  },
];
