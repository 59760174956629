import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Button, Checkbox, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  deleteUserOnly,
  getApplicants,
  transferUserDataAlerts,
  transferUserDataRequest,
  transferUserDataRequestExternal,
  transferUserDataTask,
  verifyUsersPermissions,
} from "../../../../../../services/admin/manageUserService";
import { CODES } from "../../../../../../utils/codes";
import {
  deleteUser,
  fetchCompanyUsersList,
  getCompanyUsersList,
  getCompanyUsersListStatus,
} from "../companyUsersSlice";
const TransferUserData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState({});
  const [userToAsign, setUserToAsign] = useState({});
  const [isOpenModalRejected, setIsOpenModalRejected] = useState(false);
  const [isOpenModalSucceed, setIsOpenModalSucceed] = useState(false);
  const [isOpenModalIsConfirmationDelete, setIsOpenModalIsConfirmationDelete] =
    useState(false);
  const [
    isOpenModalDontUsersToTranferInfo,
    setIsOpenModalDontUsersToTranferInfo,
  ] = useState(false);
  const companyUsersList = useSelector(getCompanyUsersList);
  const companyUsersListStatus = useSelector(getCompanyUsersListStatus);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isOpenModalInfoError, setIsOpenModalInfoError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companyUsersListStatus === "fetch") {
          dispatch(fetchCompanyUsersList());
        }
      } catch (error) {
        console.log("==================Fetch Users Error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [companyUsersListStatus, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      if (companyUsersListStatus === "loading") {
        setIsLoading(true);
      } else {
        await loadUsersTable();
        setIsLoading(false);
      }
    };
    fetch();
  }, [companyUsersListStatus]);

  /**
   * Handles
   */

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const deleteUser = await deleteUserOnly({ email: userToDelete.email });
      if (
        deleteUser.status === CODES.COD_RESPONSE_HTTP_OK ||
        deleteUser.data.responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        if (Object.keys(userToAsign).length) {
          setIsOpenModalSucceed(true);
        } else {
          handleTransferFinished();
        }
      } else {
        setIsOpenModalRejected(true);
      }
    } catch (error) {
      console.log("========= Handle Delete User Start =========");
      console.error(error);
      console.log("========= Handle Delete User End =========");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmateDeleteUser = async () => {
    setIsOpenModalIsConfirmationDelete(false);

    const companyUser = userToDelete?.companies[0];

    if (companyUser.role.name === "EXTERNAL") {
      const bodyExternal = {
        currentApplicantId: userToDelete._id,
        nextApplicantId: userToAsign._id,
      };

      const transferUserRequestsExternal =
        await transferUserDataRequestExternal(bodyExternal);

      if (
        transferUserRequestsExternal.status ||
        transferUserRequestsExternal.data.responseCode ===
          CODES.COD_RESPONSE_HTTP_OK
      ) {
        handleDelete();
      } else {
        setIsOpenModalRejected(true);
      }
    } else {
      const body = {
        currentAttorneyId: userToDelete._id,
        nextAttorneyId: userToAsign._id,
      };

      const transferUserRequests = await transferUserDataRequest(body);
      const transferUserAlerts = await transferUserDataAlerts(body);
      const transferUserTask = await transferUserDataTask(body);

      if (
        (transferUserRequests.status ||
          transferUserRequests.data.responseCode ===
            CODES.COD_RESPONSE_HTTP_OK) &&
        (transferUserAlerts.status ||
          transferUserAlerts.data.responseCode ===
            CODES.COD_RESPONSE_HTTP_OK) &&
        (transferUserTask.status ||
          transferUserTask.data.responseCode === CODES.COD_RESPONSE_HTTP_OK)
      ) {
        handleDelete();
      } else {
        setIsOpenModalRejected(true);
      }
    }
  };

  const handleConfirmation = async () => {
    try {
      setIsLoading(true);

      if (!isUserSelected) {
        setIsOpenModalInfoError(true);
        return;
      }

      const body = {
        currentAttorneyId: userToDelete._id,
        nextAttorneyId: userToAsign._id,
      };

      const verifyPermissions = await verifyUsersPermissions(body);

      if (verifyPermissions.data.responseMessage.data) {
        setIsOpenModalIsConfirmationDelete(true);
      } else {
        setIsOpenModalRejected(true);
      }
    } catch (error) {
      console.log("==================Transfer Users Error==================");
      console.log(error);
      console.log("====================================");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransferFinished = () => {
    dispatch(deleteUser({ index: id }));
    navigate("/admin/manageUser");
  };

  const loadUsersTable = async () => {
    try {
      setIsLoading(true);

      let userToShow = [];

      const userToDeleteExternal = companyUsersList[id];
      const companyUser = userToDeleteExternal?.companies[0];

      if (companyUser.role.name === "EXTERNAL") {
        setUserToDelete(companyUsersList[id]);
        const company = companyUser.companyData._id;
        const corporateUnits = userToDeleteExternal.corporateUnits.map(
          (item) => item._id
        );
        const reponseUserToTranferInfo = await getApplicants({
          company,
          corporateUnits,
          userId: userToDeleteExternal._id,
        });
        if (
          reponseUserToTranferInfo.status === CODES.COD_RESPONSE_HTTP_OK &&
          reponseUserToTranferInfo.data.success
        ) {
          if (reponseUserToTranferInfo.data.responseMessage.length > 0) {
            userToShow = reponseUserToTranferInfo.data.responseMessage;
          } else {
            setIsOpenModalDontUsersToTranferInfo(true);
          }
        }
      } else {
        let newUsers = [];

        for (let i = 0; i < companyUsersList.length; i++) {
          const role = companyUsersList[i].companies[0].role.name;
          if (i !== parseInt(id) && role !== "EXTERNAL") {
            newUsers.push({
              ...companyUsersList[i],
              checked: false,
              reduxIndex: i,
            });
          }
        }
        setUserToDelete(companyUsersList[id]);
        /* 
          Esta función revisa que las que el usuario 
          tenga las mismas unidades corporativas que 
          el usuario a eliminar. 
        */
        const sameCorporateUnits = (array1, array2) => {
          if (array1.length !== array2.length) return false;
          for (let [index, item] of array1.entries()) {
            if (item._id !== array2[index]._id) {
              return false;
            }
          }
          return true;
        };

        if (companyUsersList[id].corporateUnits.length > 0) {
          for (let user of newUsers) {
            if (companyUsersList[id].corporateUnits.length > 0) {
              if (
                sameCorporateUnits(
                  user.corporateUnits,
                  companyUsersList[id].corporateUnits
                )
              ) {
                userToShow = [...userToShow, user];
              }
            }
          }
        } else {
          userToShow = [...newUsers];
        }
      }

      setCurrentUsers(userToShow);
    } catch (error) {
      console.log("======= Setting User Info Error =======");
      console.log(error);
      console.log("======= Setting User Info Error Finish =======");
    } finally {
      setIsLoading(false);
    }
  };

  const buildFullname = (user) => {
    if (user?.personType && user?.personType === "Juridica") {
      return `Usuario propietario`;
    } else {
      const firstName = user.firstName;
      const firstSurname = user.firstSurname;
      const secondName = user?.secondName || "";
      const secondSurname = user?.secondSurname || "";
      return `${firstName} ${secondName} ${firstSurname} ${secondSurname}`;
    }
  };

  const handleSelectedUser = (index) => {
    setUserToAsign(currentUsers[index]);

    const justOneSelectedArray = currentUsers.map((user, idx) => {
      if (idx === index) {
        setIsUserSelected(!user.checked);
        return { ...user, checked: !user.checked };
      } else {
        return { ...user, checked: false };
      }
    });

    setCurrentUsers(justOneSelectedArray);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row className="col-start mt-3">
        <Breadcrum />
      </Row>
      <Row className="mt-1">
        <Col md={3} className="col-start">
          <SectionTitle title="Transferir información" />
        </Col>
        <Col md={{ span: "auto", offset: 8 }}>
          {" "}
          <IconButton size="large">
            <NotificationsOutlinedIcon
              className="heading__primary-color"
              fontSize="large"
            />
          </IconButton>
        </Col>
      </Row>
      <Row className="col-start heading__primary-color mt-5 ml-4">
        Usuario para transferir información
      </Row>
      <hr className="ml-2" style={{ width: "45%" }} />
      <Row className="col-start caption mt-4 ml-4" style={{ width: "35%" }}>
        Selecciona a que usuario quieres transferir las asignaciones de tu
        usuario eliminado:
      </Row>
      <Row
        className="col-start mt-4 custom-container-scroll"
        style={{ height: "50%" }}
      >
        <Container
          className="permissions-table"
          fluid
          style={{ width: "40%", marginLeft: "2%" }}
        >
          {currentUsers.length > 0 ? (
            currentUsers.map((user, idx) => {
              return (
                <Row
                  key={uuidv4()}
                  style={{
                    alignItems: "center",
                    borderBottom: "1px solid #e0e0e0",
                    height: "50px",
                  }}
                >
                  <Col xs={"auto"}>
                    <Checkbox
                      checked={user.checked}
                      onClick={(e) => {
                        handleSelectedUser(idx);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                        color: "#C5C7D0",
                        "&.Mui-checked": {
                          color: "#00374f",
                        },
                      }}
                    />
                  </Col>
                  <Col xs={"auto"}>
                    <p className="caption">
                      {idx + 1}. {buildFullname(user)}
                    </p>
                  </Col>
                </Row>
              );
            })
          ) : (
            <Typography className="caption">
              No hay usuarios compatibles para la transferencia de datos.
            </Typography>
          )}
        </Container>
      </Row>
      <hr className="ml-2 mt-4" style={{ width: "45%" }} />
      <Row className="cols-end mt-4" style={{ width: "45%" }}>
        <Col md={"auto"}>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="button-softblue-folders"
          >
            Cancelar proceso
          </Button>
        </Col>
        <Col md={"auto"}>
          <Button
            disabled={!isUserSelected}
            onClick={handleConfirmation}
            className={
              !isUserSelected
                ? "custom-input__button__disabled"
                : "button-primary-folders"
            }
          >
            Transferir información
          </Button>
        </Col>
      </Row>
      <ModalDecision
        title={"Eliminar usuario"}
        message={
          "El usuario seleccionado no tiene los permisos necesarios para esta operación. Por favor, modifique los permisos del usuario o elija un nuevo usuario."
        }
        open={isOpenModalRejected}
        onClose={() => {
          setIsOpenModalRejected(false);
        }}
        agreeText="Gestionar permisos"
        disagreeText="Elegir nuevamente"
        handleAgree={() => {
          navigate("/admin/manageUser");
        }}
        handleDisagree={() => {
          setIsOpenModalRejected(false);
        }}
      />
      <ModalDecision
        title={"Eliminar usuario"}
        message={
          <p className="caption">
            ¿Estás seguro de transferir la información de las{" "}
            <span style={{ fontWeight: "bold" }}>solicitudes</span> al usuario
            solicitante llamado{" "}
            <span style={{ fontWeight: "bold" }}>
              {userToAsign.firstName} {userToAsign.firstSurname}
            </span>
            ?
          </p>
        }
        open={isOpenModalIsConfirmationDelete}
        onClose={() => {
          setIsOpenModalIsConfirmationDelete(false);
        }}
        agreeText="Confirmar transferencia"
        disagreeText="Cancelar"
        handleAgree={handleConfirmateDeleteUser}
        handleDisagree={() => {
          setIsOpenModalIsConfirmationDelete(false);
        }}
        confirmationText="Aceptar"
      />
      <ModalInfo
        responseData={{
          status: 200,
          data: {
            responseMessage: `El usuario ${buildFullname(
              userToDelete
            )} ha sido eliminado satisfactoriamente. Toda la información asignada a él se ha reasignado a ${buildFullname(
              userToAsign
            )}.`,
          },
        }}
        confirmationText={"Aceptar"}
        title={"Eliminar usuario"}
        open={isOpenModalSucceed}
        onClose={handleTransferFinished}
      />
      <ModalInfo
        title={"Tranferir información"}
        onClose={() => {
          setIsOpenModalDontUsersToTranferInfo(false);
          handleDelete();
        }}
        open={isOpenModalDontUsersToTranferInfo}
        responseData={{
          status: 400,
          data: {
            responseMessage:
              "No tienes usuarios para transferir la información",
          },
        }}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title={"Error"}
        onClose={() => setIsOpenModalInfoError(false)}
        open={isOpenModalInfoError}
        responseData={{
          status: 500,
          data: {
            responseMessage:
              "No has seleccionado un usuario para tranferir información.",
          },
        }}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default TransferUserData;
