import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function ShowDataExternalRequest({
  filterStatus,
  optionsFilterBar,
  filterMyId,
  companyId,
  filterStateByANS,
}) {
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const types = optionsFilterBar.find(
    (option) => option.title === "Solicitante"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Documento requerido")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const states = optionsFilterBar.find(
    (option) => option.title === "Estado de la solicitud"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Estado de la solicitud")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const applicantsIds = optionsFilterBar.find(
    (option) => option.title === "Documento requerido"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Solicitante")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const attorneyId = filterMyId ? payloadToken.userId : null;
  const companyNames = optionsFilterBar.find(
    (option) => option.title === "Empresa"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Empresa")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;

  const corporateUnit = localStorage.getItem("corporateUnitId");

  return axios({
    method: "GET",
    url: `thirdPartyRequest/service-user/requests`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      state: filterStatus,
    },
    params: {
      companyId,
      applicantsIds: applicantsIds,
      states: states,
      names: types,
      attorneyId: attorneyId,
      companies: companyNames,
      corporateUnit,
      filterStateByANS,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export const reasignrequest = (data) => {
  return axios({
    method: "PUT",
    url: "thirdPartyRequest/reasignrequest",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};

export const getAttorneys = (data) => {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getattorneys/${data.companyId}`,
    params: {
      isApplied: true,
      corporateUnit: data?.corporateUnit || "",
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getApplicants = (data) => {
  return axios({
    method: "GET",
    url: `users/external-users/by/company/${data.company}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export function getRequestTypes(data) {
  return axios({
    method: "GET",
    url: `/thirdPartyRequest/getRequestTypes/`,
    validateStatus: null,
    params: {
      formulariesType: data.formType,
      companyId: data.companyId,
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
  });
}

export function getCompaniesList() {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/getCompaniesList",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: { Authorization: GetToken() },
  });
}

export const changeRequestState = (data) => {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/changeRequestState`,
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      requestId: data.requestId,
      state: data.state,
      isActive: data.isActive ? data.isActive : null,
      returnMessage: data.message ? data.message : "",
      timeZone: data.timeZone,
      corporateUnit,
    },
    validateStatus: null,
  });
};

export function getFoldersServiceUser(data) {
  return axios({
    method: "GET",
    baseURL: env.REACT_APP_API_URL,
    url: `/businessFolder/find/all/${data.companyId}`,
    validateStatus: null,
  });
}

export function DownloadDocs(data) {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/get/document",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    responseType: "blob", // IMPORTANT!
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: GetToken(),
    },
    params: {
      consecutive: data.consecutive,
      documentId: data.documentId,
      documentType: data.documentType,
      corporateUnit: data.corporateUnit,
      companyId: data.company
    },
  });
}

export function DownloadDocsByURL(data) {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/get/file-url",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    // responseType: "blob", // IMPORTANT!
    headers: {
      // "Content-Type": "application/json",
      // Accept: "application/pdf",
      Authorization: GetToken(),
    },
    params: {
      consecutive: data.consecutive,
      documentId: data.documentId,
      documentType: data.documentType,
      corporateUnit: data.corporateUnit,
      companyId: data.company
    },
  });
}

export const AddRQTToBusinessFolders = (BFId, RQTId) => {
  return axios({
    method: "PUT",
    url: `businessFolder/addRequest`,
    validateStatus: null,
    baseURL: env.REACT_APP_API_URL,
    data: {
      businessFolderId: BFId,
      requestId: RQTId,
    },
  });
};

export function CreateComment(data) {
  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/createComment",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      requestId: data.requestId,
      commentMessage: data.commentMessage,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function SendInfoToApplicant(data) {
  const {
    companyId,
    consecutive,
    applicantId,
    otherDocuments,
    comment,
    principalDocument,
    attorneyName,
    applicantName,
    requiredDocuments,
    isVersionRelated,
    versionRelated,
    corporateUnit
  } = data;

  const formData = new FormData();
  formData.append("consecutive", consecutive);
  formData.append("applicantId", applicantId);
  formData.append("companyId", companyId);
  formData.append("corporateUnit", corporateUnit);

  if (otherDocuments.length) {
    otherDocuments.map((doc) => {
      formData.append("attorneyDocuments", doc);
    });
  }

  if (principalDocument) {
    formData.append("description", comment);
    formData.append("userRole", "SERVICE");
    formData.append("attorneyName", attorneyName);
    formData.append("applicantName", applicantName);
    formData.append("mainDocuments", principalDocument);
    formData.append("isVersionRelated", isVersionRelated);
    if (versionRelated) formData.append("versionRelated", versionRelated);
  }

  if (requiredDocuments.length) {
    requiredDocuments.map((file) => {
      formData.append("requiredDocuments", file.file, file.name);
    });
  }

  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/add/documents/",
    baseURL: env.REACT_APP_API_URL,
    data: formData,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function findOneDataExternalRequest(data) {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/byConsecutive/${data.consecutive}`,
    params: {
      companyId: data.company,
      corporateUnitId: data.corporateUnit,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

export function SendDocumentToSign(data) {
  return axios({
    method: "POST",
    url: "signatures/save",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
    validateStatus: null,
  });
}

export function SendPersonalDataToSign(data) {
  return axios({
    method: "PUT",
    url: `signatures/add/signatory/info/${data.requestId}`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
    validateStatus: null,
  });
}

export function VerifyTokenToSign(data) {
  return axios({
    method: "GET",
    url: `/signatures/verify/signature/token`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      token: data.token,
      signatureId: data.signatureId,
    },
    validateStatus: null,
  });
}

export function ReturnSignWithComments(data) {
  return axios({
    method: "PUT",
    url: `/signatures/reject/signature/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    data: {
      rejectionMessage: data.message,
    },
    validateStatus: null,
  });
}

export function GenerateOTPCode(signatureId) {
  return axios({
    method: "POST",
    url: `/signatures/send/otp/${signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

export function ValidateOTPCode(data) {
  return axios({
    method: "PUT",
    url: `signatures/sign/document/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      otp: data.otp,
    },
  });
}

export function SaveRequestDocumentsInFolders(data) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/associate/docs/to/folder`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function PreFilterFromHome(data) {
  return axios({
    method: "GET",
    url: "thirdPartyRequest/service-user/requests/",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      state: null,
    },
    params: {
      applicantsIds: data.applicantsIds,
      states: data.states,
      names: data.types,
      attorneyId: data.attorneyId,
      companies: data.companies,
      companyId: data.company,
      corporateUnit: data.corporateUnit,
      filterStateByANS: data.filterStateByANS,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}
