import { Button } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonCreate from "../../components/Datatable/buttonCreate";
import CustomSearch from "../../components/Datatable/customSearch";
import LoadingContent from "../../components/loadingContent";
import ModalDecision from "../../components/Modals/modalDecision";
import ModalInfo from "../../components/Modals/modalInfo";
import SectionTitle from "../../components/sectionTitle";
import Breadcrum from "../breadcrum";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { useDispatch, useSelector } from "react-redux";
import { fetchTags, getTagList, getStatus, deleteTagById } from "./tagSlice";
import { CODES } from "../../utils/codes";
import { onSortHTMLElement } from "../../utils/findComponentHTML";

const ManageClausesTags = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedTag, setDeletedTag] = useState("");
  const tagsList = useSelector(getTagList);
  const tagsStatus = useSelector(getStatus);
  const [searchParams] = useSearchParams();
  const resourceType = searchParams.get("resource");

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Etiquetas
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Descripción
        </label>,
      ],
      field: "description",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Acciones
        </label>,
      ],
      field: "edit",
    },
  ];

  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: [],
    rows: [],
  });

  const [dataTable, setDataTable] = useState({
    columns: [],
    rows: [],
  });

  /* Format to table rows */

  const buildRowsTags = (rows) => {
    const format = rows.map((row, idx) => {
      const sixHours = 21600000;
      const toDate = new Date();
      const currentDate = new Date(row.createdAt);
      const diffDate = toDate - currentDate;
      const isNewTag = diffDate < sixHours;
      return {
        name: (
          <>
            <label className="caption">{row.name}</label>
            {isNewTag && (
              <label
                className="caption custom-badges__secundary"
                style={{
                  width: "max-content",
                  margin: "0 1em",
                  zIndex: "1",
                }}
              >
                {" "}
                Nueva
              </label>
            )}
          </>
        ),
        description: row.description,
        edit: row.company ? (
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                onClick={() => {
                  let route = `${row._id}`;
                  if (resourceType) {
                    route = `${row._id}?resource=${resourceType}`;
                  }
                  navigate(route);
                }}
              >
                <EditOutlinedIcon />
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!!row.company ? false : true}
                variant="contained"
                className={
                  "custom-input__button__primary-color__forced custom-input__data-table-icon"
                }
                onClick={() => handleOpenModalDesicion(row._id)}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Col>
          </Row>
        ) : (
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
              >
                <DoNotDisturbAltIcon />
              </Button>
            </Col>
            <Col>
              <Button
                variant="contained"
                className={
                  "custom-input__button__primary-color__forced custom-input__data-table-icon"
                }
              >
                <DoNotDisturbAltIcon />
              </Button>
            </Col>
          </Row>
        ),
      };
    });
    return format;
  };

  /* UseEffects Fetch Tags */

  useEffect(() => {
    if (tagsStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [tagsStatus]);

  useEffect(() => {
    try {
      if (tagsStatus === "fetch") {
        const resource = resourceType || "CLAUSES";
        dispatch(fetchTags(resource));
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsStatus, dispatch]);

  useEffect(() => {
    if (tagsList && typeof tagsList !== "string") {
      const sortedList = [...tagsList].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const rows = buildRowsTags(sortedList);
      setFilteredDataTable({
        columns: columns,
        rows: rows,
      });
      setDataTable({
        columns: columns,
        rows: rows,
      });
    }
  }, [tagsList]);

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleCloseModalDesicion = async () => {
    setIsOpenModalDecision(false);
  };

  const handleOpenModalDesicion = async (id) => {
    setIsOpenModalDecision(true);
    setDeletedTag(id);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalDecision(false);
      let obj = {
        id: deletedTag,
        resourceType: resourceType || "CLAUSES",
      };
      const deleteTagService = await dispatch(deleteTagById(obj)).unwrap();
      if (deleteTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(deleteTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(deleteTagService);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Administrar etiquetas"} />
      </Row>
      <br />
      <br />
      <Row>
        <Container as={Col} fluid>
          <Row>
            <Col sm={5}>
              <CustomSearch
                dataTableInfo={dataTable}
                setFilteredDataTable={setFilteredDataTable}
              />
            </Col>
            <Col sm={{ span: 2, offset: 5 }}>
              <ButtonCreate
                path={
                  resourceType ? `create?resource=${resourceType}` : "create"
                }
                value={"Crear etiqueta"}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "1%" }}></Row>
          <Row className="dt-list-center-content">
            <MDBDataTableV5
              hover
              pagingTop
              searchBottom={false}
              entries={7}
              data={filteredDataTable}
              entriesLabel=""
              infoLabel={["Mostrando", "a", "de", "etiquetas"]}
              noRecordsFoundLabel="No se encontraron etiquetas"
              fullPagination
              onSort={(value) => {
                onSortHTMLElement({
                  sort: value,
                  filteredDataTable,
                  setFilteredDataTable,
                });
              }}
            />
          </Row>
        </Container>
      </Row>
      <ModalInfo
        title={"Etiqueta eliminada"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Eliminar etiqueta"}
        message={getTitleDeleteTag(resourceType)}
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

const getTitleDeleteTag = (resourceType = "") => {
  switch (resourceType) {
    case "PARTSLIBRARY":
      return "¿Seguro quieres eliminar la etiqueta? Si continúas, se eliminará de las partes que la tenían relacionada.";
    default:
      return "¿Está seguro que quiere eliminar la etiqueta?";
  }
};

export default ManageClausesTags;
