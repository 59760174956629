import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ModalAssociateTags from "./ModalAssociateTags";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import {
  fetchTagsDocumentTempletes,
  getTagDocumentTempletesList,
  getTagDocumentTempleteStatus,
} from "../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalNameAndTags = ({
  onClose,
  open,
  handleAgree,
  text,
  title,
  placeholder,
}) => {
  //States
  const [documentName, setDocumentName] = useState("");

  //dispatch
  const dispatch = useDispatch();
  //Selectors

  const tagsList = useSelector(getTagDocumentTempletesList);
  const statusTags = useSelector(getTagDocumentTempleteStatus);

  const [tagsSelected, setTagsSelected] = useState([]);
  //Functions
  const handleClose = () => {
    onClose();
    handleResetStateValues();
  };

  const handleResetStateValues = () => {
    setTagsSelected([]);
    setDocumentName("");
  };

  const handleTagsSelected = (tags) => {
    if (!tags?.length) return;

    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };
  //Use effect

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTagsDocumentTempletes("DOCUMENT_TEMPLETES"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">{title}</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Row>
            <Col xs={12}>
              <label className="caption">{text}</label>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Control
                className="label"
                size="lg"
                defaultValue=""
                type="text"
                placeholder={placeholder}
                maxLength={250}
                onChange={(e) => setDocumentName(e.target.value)}
              />
            </Col>
            <Col xs={12} className="mt-2">
              <label
                className={
                  documentName?.length === 250
                    ? "caption__warning"
                    : "caption__primary-color"
                }
              >
                Máximo 250 caracteres permitidos
              </label>
            </Col>

            <Row className="mt-3">
              <Col xs={"auto"}>
                <ModalAssociateTags
                  tags={tagsList}
                  handleTagsSelected={(tags) => handleTagsSelected(tags)}
                  previousTagsSelected={tagsSelected}
                  type="DOCUMENT_TEMPLETES"
                />
              </Col>

              {tagsSelected.map((tag) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={tag?._id}
                  >
                    <span className="caption custom-badges__disabled">
                      {tag.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Row>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                onClick={handleClose}
                className="custom-input__button__secondary-color__forced"
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  handleAgree(documentName, tagsSelected);
                  handleResetStateValues();
                }}
                variant="contained"
                className="custom-input__button__primary-color__forced"
                disabled={!documentName}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalNameAndTags;
