import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//Material Import
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Button } from "@mui/material";
import Badge from "@mui/material/Badge";
//Parts
import Breadcrum from "../../../../../parts/breadcrum";
//Components
import DiscussionBoard from "../../../../../components/DiscussionBoard/DiscussionBoard";
import LoadingContent from "../../../../../components/loadingContent";
import { ModalListSelectCheckBox } from "../../../../../components/Modals/ModalListCheckBox";
import SectionTitle from "../../../../../components/sectionTitle";
//Icons
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  getUserCorporateUnits,
  setNewUnits,
} from "../manageUser/currentUserSlice";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "./unitCorporativeSlice";
const UserUnitCorporative = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userCorporateUnits = useSelector(getUserCorporateUnits);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);
  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const [isLoading, setIsLoading] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [unitCorporativeSelected, setUnitCorporativeSelected] = useState([]);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const handleDeleteUnitCorporative = (item) => {
    const deleteFilter = unitCorporativeSelected.filter(
      (unit) => unit._id !== item._id
    );
    setUnitCorporativeSelected(deleteFilter);
  };
  const handleUpdate = () => {
    dispatch(setNewUnits({ units: unitCorporativeSelected }));
    navigate(-1);
  };
  useEffect(() => {
    setUnitCorporativeSelected(userCorporateUnits);
  }, [userCorporateUnits]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
    if (statusFetchUnits === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusFetchUnits]);

  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.log("========== Error Fetch SubType User ==========");
      console.log(error);
      console.log("========== Finish Error Fetch SubType User ==========");
    }
  }, [getListUnitsCorporatives]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Editar unidad corporativa" />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Badge
            style={{ cursor: "pointer" }}
            badgeContent={0}
            color="primary"
            onClick={() => {
              setIsHideDiscussion(false);
            }}
          >
            <NotificationsNoneIcon fontSize="large" color="#00374F" />
          </Badge>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption">
          Agrega y elimina las unidades corporativas de tu usuario de ser
          necesario:
        </Col>
      </Row>
      <br />
      <br />
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <ModalListSelectCheckBox
            icon={<AddIcon />}
            list={listUnitsCorporatives}
            name="Agregar unidades corporativas"
            title={"Añadir unidades corporativas al usuario"}
            itemsSelected={unitCorporativeSelected}
            setItemsSelected={setUnitCorporativeSelected}
          />
        </Col>
        {unitCorporativeSelected.map((item, index) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {item?.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteUnitCorporative(item)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <br />
      <Row>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleUpdate}
          >
            Guardar cambios
          </Button>
        </Col>
      </Row>
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={[]}
        id={""}
        addComment={false}
      />
    </Container>
  );
};

export default UserUnitCorporative;
