import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
const PopoverArrayCards = ({
    type = "default",
    icon,
    classNameButton = "custom-input__button__default-color",
    containerRef,
    setIsOpenExternal,
    items,
    actions,
    ...props
}) => {
    const [show, setShow] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (items?.length) {
            const isLastItem = items.length === 1;
            setItemSelected({ ...items[0], index: 0, isLastItem, isFirstItem: true });
        }
    }, [show]);
    const handleChangeItem = ({ index, items }) => {
        setCopied(false);
        const isLastItem = (items.length - 1) === index;
        const isFirstItem = index === 0;
        if (items?.[index]) {
            setItemSelected({ ...items[index], index, isLastItem, isFirstItem });
        }
    };
    const handleCopyText = ({ text }) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch(err => console.error('Error al copiar al portapapeles:', err));
    };
    const popover = (
        <Popover
            style={{ margin: "0" }}
            className={`custom-popover__${type}`}
            id={props.id}
        >
            <Popover.Body className="custom-popover body-content__center">
                <div
                    style={{
                        width: "max-content",
                        position: "absolute",
                        right: "0",
                        top: "0",
                        zIndex: "10"
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setShow(false);
                            if (setIsOpenExternal) {
                                setIsOpenExternal(false);
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Row style={{ alignItems: "center" }}>
                    <Col xs={3}>
                        <img
                            style={{ width: "100%" }}
                            src={require("../../assets/svg/iaAdvices.svg").default}
                            alt="logo-ia"
                        />
                    </Col>
                    <Col xs={9}>
                        {itemSelected && (<>
                            {itemSelected?.title && (
                                <Row className="custom-input__button__withOutBackground">
                                    <Col style={{ textAlign: "start" }}>{itemSelected.title}</Col>
                                </Row>
                            )}
                            <Row className="custom-popover__body" style={{ textAlign: "justify" }}>
                                <Col>{itemSelected.description}{" "}
                                    <IconButton
                                        title={copied ? '¡Copiado!' : 'Copiar texto'}
                                        aria-label="close"
                                        onClick={() => handleCopyText({ text: itemSelected.textToCopy})}
                                        sx={{ color: (theme) => theme.palette.grey[500], padding: 0 }}
                                    >
                                        {copied ? <AssignmentTurnedInOutlinedIcon /> : <ContentCopyOutlinedIcon />}
                                    </IconButton>
                                </Col>
                            </Row>
                            <Row style={{ alignItems: 'center', justifyContent: 'center', rowGap: "5px" }}>
                                {!itemSelected.isFirstItem && (
                                    <Col key={uuidv4()} xs={"auto"}>
                                        <IconButton
                                            title={'Anterior'}
                                            aria-label="back"
                                            onClick={() => handleChangeItem({
                                                index: itemSelected.index - 1,
                                                items
                                            })}
                                            sx={{
                                                color: (theme) => theme.palette.grey[500]
                                            }}
                                        >
                                            <ArrowCircleLeftOutlinedIcon fontSize="large" />
                                        </IconButton>
                                    </Col>
                                )}
                                {items.length > 1 && (
                                    <Col xs={"auto"} className="custom-input__button__withOutBackground">
                                        {itemSelected.index + 1}/{items.length}
                                    </Col>
                                )}
                                {!itemSelected.isLastItem && (
                                    <Col key={uuidv4()} xs={"auto"}>
                                        <IconButton
                                            title={'Siguiente'}
                                            aria-label="next"
                                            onClick={() => handleChangeItem({
                                                index: itemSelected.index + 1,
                                                items
                                            })}
                                            sx={{ color: (theme) => theme.palette.grey[500] }}
                                        >
                                            <ArrowCircleRightOutlinedIcon fontSize="large" />
                                        </IconButton>
                                    </Col>
                                )}
                                {itemSelected?.actions.length > 0 &&
                                    itemSelected?.actions?.map(
                                        (item) =>
                                            item.show && (
                                                <Col key={uuidv4()} xs={"auto"}>
                                                    <Button
                                                        className="custom-input__button__withOutBackground"
                                                        size="large"
                                                        onClick={item.handleAction}
                                                    >
                                                        {item.text}
                                                    </Button>
                                                </Col>
                                            )
                                    )}
                            </Row>
                        </>
                        )}
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
            show={show}
            placement="auto"
            overlay={popover}
            rootClose
            trigger="click"
            container={containerRef?.current}
        >
            <IconButton
                aria-label="icon"
                className={classNameButton}
                sx={{ borderRadius: "4px" }}
                onClick={() => {
                    setShow((show) => !show);
                    if (setIsOpenExternal) {
                        setIsOpenExternal((show) => !show);
                    }
                }}
            >
                {icon}
                {props?.textIcon || ""}
            </IconButton>
        </OverlayTrigger>
    );
};

export default PopoverArrayCards;
