import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//Material Import
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
//Parts
import Breadcrum from "../../../../../parts/breadcrum";
//Components
import DiscussionBoard from "../../../../../components/DiscussionBoard/DiscussionBoard";
import CustomSearchFilter from "../../../../../components/Search/CustomSearchFilter";
import SectionTitle from "../../../../../components/sectionTitle";
//Icons
//Redux
//Services
//Utils
import { v4 as uuidv4 } from "uuid";
const AdminPermissions = () => {
  const navigate = useNavigate();
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const elements = [
    {
      title: "Permisos frente a unidades corporativas",
      permissions: [
        {
          name: "Ver unidades corportivas",
          isChecked: false,
        },
        {
          name: "Crear unidades corporativas",
          isChecked: false,
        },
        {
          name: "Eliminar unidades corporativas",
          isChecked: false,
        },
      ],
    },
    {
      title:
        "Permiso frente a los usuarios de las unidades corporativas habilitadas",
      permissions: [
        {
          name: "Crear usuarios",
          isChecked: false,
        },
        {
          name: "Editar usuarios",
          isChecked: false,
        },
        {
          name: "Eliminar usuarios",
          isChecked: false,
        },
      ],
    },
  ];
  const [filteredData, setFilteredData] = useState(elements);
  const handleChecked = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      console.warn("Pendiente el checked");
    } else {
      console.warn("Pendiente el !checked");
    }
  };
  const handleUpdateUsersPermissions = async () => {
    try {
      console.warn("Pendiente el handleUpdate");
    } catch (error) {
      console.log("======= Error Handle Update Users Permissions ========");
      console.error(error);
      console.log(
        "======= Error Finish Handle Update Users Permissions ========"
      );
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.warn("Pendiente el fetch de permisos");
      } catch (error) {
        console.log("======= Error Fetch Users Permissions ========");
        console.error(error);
        console.log("======= Error Fetch Update Users Permissions ========");
      }
    };
    fetchData();
  }, []);
  return (
    <Container fluid className="custom-container-scroll">
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Gestionar permisos"} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption">
          Selecciona los permisos que le dejarás a tu usuario de servicios.
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={5}>
          <CustomSearchFilter
            list={elements}
            setFilteredData={setFilteredData}
            placeholder={"Buscar permisos"}
          />
        </Col>
      </Row>
      <br />
      {filteredData?.map((item, index) => {
        const { permissions } = item;
        return (
          <div key={uuidv4()}>
            <Row>
              <p
                className="heading__primary-color"
                style={{ borderBottom: "1px solid #e0e0e0" }}
              >
                {item.title}
              </p>
              {permissions.map((permission) => {
                return (
                  <Row
                    key={permission?.id}
                    style={{
                      alignItems: "center",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    <Col xs={"auto"}>
                      <Checkbox
                        checked={permission.isChecked}
                        onClick={(e) => handleChecked(e, item)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          color: "#C5C7D0",
                          "&.Mui-checked": {
                            color: "#00374f",
                          },
                        }}
                      />
                    </Col>
                    <Col xs={"auto"}>
                      <p className="caption__disabled">{permission.name}</p>
                    </Col>
                  </Row>
                );
              })}
            </Row>
            <br />
          </div>
        );
      })}
      <br />
      <Row style={{ justifyContent: "end", marginRight: "5%" }}>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleUpdateUsersPermissions}
          >
            Actualizar Permisos
          </Button>
        </Col>
      </Row>

      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={[]}
        id={""}
        addComment={false}
      />
    </Container>
  );
};

export default AdminPermissions;
