import { jsPDF } from "jspdf";

const A4_PAPER_DIMENSIONS = {
  width: 210, // millimeters (mm)
  height: 297, // millimeters (mm)
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

const imageDimensionsOnA4 = (dimensions) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;

  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }

  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

export async function getFile(fileSelected) {
  try {
    if (!fileSelected) throw new Error("No hay archivo seleccionado");
    let extension = fileSelected.name.split(".").pop().toLowerCase();

    if (extension === "doc" || extension === "docx" || extension === "docm") {
      return fileSelected;
    }

    let file = fileSelected;

    if (extension !== "pdf") {
      const doc = new jsPDF();
      const tempUrl = URL.createObjectURL(fileSelected);
      const dimensions = await getHeightAndWidthFromDataUrl(tempUrl);
      const dataType = fileSelected.type.split("/")[1];

      const dimensionsA4 = imageDimensionsOnA4({
        width: dimensions.width,
        height: dimensions.height,
      });
      doc.addImage(
        tempUrl,
        dataType,
        (A4_PAPER_DIMENSIONS.width - dimensionsA4.width) / 2,
        (A4_PAPER_DIMENSIONS.height - dimensionsA4.height) / 2,
        dimensionsA4.width,
        dimensionsA4.height,
        undefined,
        "FAST"
      );
      const pdfTransform = new File(
        [doc.output("blob")],
        `${fileSelected.name}.pdf`,
        {
          type: "application/pdf",
        }
      );
      file = pdfTransform;
    }
    return file;
  } catch (error) {
    console.log("================Error getFile====================");
    console.log(error);
    console.log("====================================");
  }
}
