import { createSlice } from "@reduxjs/toolkit";

const backgroundProcessSlice = createSlice({
  name: "background processing",
  initialState: {
    backgroundProcesses: {
      backgroundProcessReview: {
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
      },
      backgroundProcessReviewContract: {
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
      },
      backgroundProcessReviewSavedContract: {
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
      },
    },
  },
  reducers: {
    setIsFetchingInfoBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].isFetchingInfo = value;
    },
    setFinishProcessingBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].finishProcessing = value;
    },

    setUserLeftBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].userLeft = value;
    },
    setFinishAdditionalInfoBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].navigationInfo = value;
    },
    resetInfoForModule: (state, action) => {
      const { module } = action.payload;

      state.backgroundProcesses[module] = {
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
      };
    },
    resetStatesButInfoForModule: (state, action) => {
      const { module } = action.payload;

      state.backgroundProcesses[module] = {
        isFetchingInfo: false,
        finishProcessing: false,
        userLeft: false,
        navigationInfo: state.backgroundProcesses[module].navigationInfo,
      };
    },
  },
});

export const {
  setIsFetchingInfoBackgroundProcess,
  setFinishProcessingBackgroundProcess,
  setFinishAdditionalInfoBackgroundProcess,
  setUserLeftBackgroundProcess,
  resetInfoForModule,
  resetStatesButInfoForModule,
} = backgroundProcessSlice.actions;

export const getBackgroundProcesses = (state) =>
  state.backgroundProcess.backgroundProcesses;

export const selectIsFetchingInfoForModule = (state, moduleName) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.isFetchingInfo || false;
};

export const selectUserLeftForModule = (state, moduleName) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.userLeft || false;
};

export const selectAdditionalInfoForModule = (state, moduleName) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.navigationInfo || {};
};

export const selectAdditionalInfoForFinishedModule = (state) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  const finishedModule = Object.values(backgroundProcesses).find(
    (module) => module?.finishProcessing
  );
  return finishedModule ? finishedModule?.navigationInfo : null;
};

export const setFinishBackgroundProcessingForModule =
  (module, value) => (dispatch) => {
    dispatch(setFinishProcessingBackgroundProcess({ module, value }));
  };

export const setBackgroundProcessingAdditionalInfoForModule =
  (module, value) => (dispatch) => {
    dispatch(setFinishAdditionalInfoBackgroundProcess({ module, value }));
  };

export const resetBackgroundInfoForModule = (module) => (dispatch) => {
  dispatch(resetInfoForModule(module));
};

export const selectIsAnyModuleProcessingFinished = (state) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  for (const module in backgroundProcesses) {
    if (backgroundProcesses[module]?.finishProcessing) {
      return {
        isFinished: true,
        moduleName: module,
        navigationInfo: backgroundProcesses[module].navigationInfo,
        userLeft: backgroundProcesses[module].userLeft,
      };
    }
  }
  return {
    isFinished: false,
    moduleName: "",
    navigationInfo: {},
    userLeft: false,
  };
};

export default backgroundProcessSlice.reducer;
