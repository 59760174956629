import { createSlice, createAction } from "@reduxjs/toolkit";

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    storingUserInfo: false,
    email: "",
    phoneNumber: "",
    firstName: "",
    secondName: "",
    firstSurname: "",
    secondSurname: "",
    documentType: "",
    documentNumber: "",
    role: "",
    countryCode: "",
    extension: "",
    countryCodeTel: "",
    corporateUnits: [],
    permissions: [],
    _id: "",
    telephoneNumber: "",
  },
  reducers: {
    setNewUnits(state, action) {
      const { units } = action.payload;
      state.corporateUnits = units;
    },

    setPermissions(state, action) {
      const { permissions } = action.payload;
      state.permissions = permissions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUserData, (state, action) => {
        state.storingUserInfo = action.payload.storingUserInfo;
        state.email = action.payload.email;
        state.phoneNumber = action.payload.phoneNumber;
        state.firstName = action.payload.firstName;
        state.secondName = action.payload.secondName;
        state.firstSurname = action.payload.firstSurname;
        state.secondSurname = action.payload.secondSurname;
        state.documentType = action.payload.documentType;
        state.documentNumber = action.payload.documentNumber;
        state.role = action.payload.role;
        state.subRole = action.payload.subRole;
        state.countryCode = action.payload.countryCode;
        state.countryCodeTel = action.payload.countryCodeTel;
        state.extension = action.payload.extension;
        state.corporateUnits = action.payload.corporateUnits;
        state.permissions = action.payload.permissions;
        state._id = action.payload._id;
      })
      .addCase(setCurrentUserDataTest, (state, action) => {
        state.storingUserInfo = action.payload.storingUserInfo;
        state.email = action.payload.email;
        state.phoneNumber = action.payload.phoneNumber;
        state.firstName = action.payload.firstName;
        state.secondName = action.payload.secondName;
        state.firstSurname = action.payload.firstSurname;
        state.secondSurname = action.payload.secondSurname;
        state.documentType = action.payload.documentType;
        state.documentNumber = action.payload.documentNumber;
        state.role = action.payload.role;
        state.subRole = action.payload.subRole;
        state.countryCode = action.payload.countryCode;
        state.countryCodeTel = action.payload.countryCodeTel;
        state.extension = action.payload.extension;
        state._id = action.payload._id;
        state.phoneType = action.payload.phoneType;
        state.emailConfirmation = action.payload.emailConfirmation;
        state.telephoneNumber = action.payload.telephoneNumber;
      })
      .addCase(setClearCurrentUser, (state, action) => {
        state.storingUserInfo = false;
        state.email = "";
        state.phoneNumber = "";
        state.firstName = "";
        state.secondName = "";
        state.firstSurname = "";
        state.secondSurname = "";
        state.documentType = "";
        state.documentNumber = "";
        state.role = "";
        state.subRole = "";
        state.countryCode = "";
        state.countryCodeTel = "";
        state.extension = "";
        state.corporateUnits = [];
        state.permissions = [];
        state._id = "";
        state.phoneType = "";
        state.emailConfirmation = "";
        state.telephoneNumber = "";
      });
  },
});

export const { setNewUnits } = currentUserSlice.actions;

export const { setPermissions } = currentUserSlice.actions;

export const getUserCorporateUnits = (state) =>
  state.currentUser.corporateUnits;

export const getUserPermissions = (state) => state.currentUser.permissions;

export const isStoringInfo = (state) => state.currentUser.storingUserInfo;

export const getCurrentUser = (state) => state.currentUser;

export const setClearCurrentUser = createAction(
  "currentUser/setClearCurrentUser",
  () => ({
    payload: {
      currentUserSlice: null,
    },
  })
);

export const setCurrentUserData = createAction(
  "currentUser/setCurrentUserData",
  (data) => ({
    payload: {
      storingUserInfo: data.storingUserInfo,
      email: data.email,
      phoneNumber: data.phoneNumber,
      firstName: data.firstName,
      secondName: data.secondName,
      firstSurname: data.firstSurname,
      secondSurname: data.secondSurname,
      documentType: data.documentType,
      documentNumber: data.documentNumber,
      role: data.role,
      subRole: data.subRole,
      countryCode: data.countryCode,
      countryCodeTel : data.countryCodeTel,
      extension: data.extension,
      corporateUnits: data.corporateUnits,
      permissions: data.permissions,
      _id: data._id,
      telephoneNumber: data.telephoneNumber,
    },
  })
);

export const setCurrentUserDataTest = createAction(
  "currentUser/setCurrentUserDataTest",
  (data) => ({
    payload: {
      storingUserInfo: data.storingUserInfo,
      email: data.email,
      phoneNumber: data.phoneNumber,
      firstName: data.firstName,
      secondName: data.secondName,
      firstSurname: data.firstSurname,
      secondSurname: data.secondSurname,
      documentType: data.documentType,
      documentNumber: data.documentNumber,
      role: data.role,
      subRole: data.subRole,
      countryCode: data.countryCode,
      extension: data.extension,
      _id: data._id,
      telephoneNumber: data.telephoneNumber,
      ...data
    },
  })
);

export default currentUserSlice.reducer;
