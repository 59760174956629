import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import ModalInactivity from "../components/Modals/ModalInactivity";
import Sidebar from "../components/Sidebar/Sidebar";
import { validateEnablePlan } from "../utils/getPlanInfo";
import useIdle from "../utils/useIdleTimer";
import { setStorageInfo } from "./storage/storageSlice";

const Layout = () => {
  const warning = () => {
    setOpenInactivity(true);
  };
  const { isIdle, setIsIdle } = useIdle({ onIdle: warning, idleTime: 240 });
  const [openInactivity, setOpenInactivity] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const isIdle = localStorage.getItem("isIdle");
    if (isIdle === "true") {
      setIsIdle(true);
      setOpenInactivity(true);
    }
  }, []);

  const checkStorage = async () => {
    try {
      const storageInfo = await validateEnablePlan();
      dispatch(setStorageInfo(storageInfo));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkStorage();

      const intervalCallback = () => {
        checkStorage();
      };

      const interval = setInterval(intervalCallback, 5 * 60 * 1000);

      return () => clearInterval(interval);
    };

    fetchData();
  }, []);

  return (
    <Container
      fluid
      style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
    >
      <Row style={{ height: "inherit" }}>
        <Col xl={2} lg={3} md={4} sm={5} xs={6} className="sidebar-base">
          {/**Padding crítico pero necesario */}
          <Sidebar />
        </Col>
        <Col>
          {/* // <-- nested routes rendered here --> */}
          {isIdle ? (
            <ModalInactivity
              isOpen={openInactivity}
              setIsOpen={setOpenInactivity}
            />
          ) : (
            <></>
          )}
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
