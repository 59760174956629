import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { useSelector } from "react-redux";
import { getReports } from "../../../../../../../parts/document/currentDocumentSlice";
import { MDBDataTableV5 } from "mdbreact";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { reportIAResult } from "../../..../../../../../../../services/admin/manageUserService";
import { CODES } from "../../../../../../../utils/codes";
import LoadingContent from "../../../../../../../components/loadingContent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const ReportsPolicies = ({ type }) => {
  const columns = [
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Nombre
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label
          key="2"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Valor asegurado
        </label>,
      ],
      field: "value",
    },
    {
      label: [
        <label
          key="3"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Vigencia
        </label>,
      ],
      field: "currency",
    },
    {
      width: 10,
      label: [
        <label
          key="4"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Otros requisitos
        </label>,
      ],
      field: "others",
    },
    {
      label: [
        <label
          key="5"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const dataReports = useSelector(getReports);
  const [filteredData, setFilteredData] = useState({
    columns: columns,
    rows: [],
  });
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [itemReport, setItemReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleInfoToSupport = async (reportMessage) => {
    let isInfoSentToSupport = false;
    try {
      setIsLoading(true);
      const reportInformation = {
        message: reportMessage,
        functionalityName: `${REPORTS_TYPE.POLICIES.name}`,
        currentPathname: location.pathname,
      };
      const serviceResponse = await reportIAResult(reportInformation);
      if (serviceResponse) {
        if (serviceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          isInfoSentToSupport = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return isInfoSentToSupport;
  };
  const handleAgreeModalInputReport = async (data) => {
    if (data === "") return;
    if (itemReport) {
      const isInfoSentToSupport = await handleInfoToSupport(data);
      setIsOpenModalInputReport(!isInfoSentToSupport);
    }
  };
  const buildRows = (data) => {
    const rows = [];
    for (const report of data) {
      rows.push({
        name: report?.name,
        value: report?.value,
        currency: report?.duration,
        others: report?.others,
        action: (
          <Row style={{ gap: 3 }}>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<ErrorOutlineIcon />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setItemReport(report);
                  setIsOpenModalInputReport(true);
                }}
              >
                <p className="button">Reportar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<RemoveRedEyeOutlinedIcon />}
                className="custom-input__button__primary-color"
                onClick={() => navigate(`viewContract/#${report.id_text}.0.0`)}
              >
                Ver en contrato
              </Button>
            </Col>
          </Row>
        ),
      });
    }
    return rows;
  };
  useEffect(() => {
    if (type) {
      const reportsData = dataReports.find(
        (item) => item.typeReport === type
      ).reports;
      const rows = buildRows(reportsData);
      setFilteredData({
        columns,
        rows: rows,
      });
    }
  }, [dataReports]);
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <br />
      <br />
      <Row className="data-table dt-list-items">
        <MDBDataTableV5
          autoWidth={false}
          noRecordsFoundLabel="No se encontraron resultados"
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={filteredData}
          infoLabel={["Mostrando", "a", "de", "pólizas"]}
          fullPagination
        />
      </Row>
      <ModalInpuText
        title={"Reportar póliza"}
        message={"¿Deseas reportar la siguiente póliza?"}
        agreeText={"Aceptar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={() => {
          setItemReport(null);
          setIsOpenModalInputReport(false);
        }}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
    </Container>
  );
};
