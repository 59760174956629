import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import LoadingContent from "../../../../../../../components/loadingContent";
import Breadcrum from "../../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../../components/sectionTitle";
import { useForm } from "react-hook-form";
import { Button, MenuItem, Select } from "@mui/material";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { countries } from "../../../../../../../utils/countries";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { REGEXP } from "../../../../../../../utils/regexp";
import { createPart } from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../../utils/codes";
import { useNavigate } from "react-router-dom";
import { PERSON_TYPE, TYPE_CHARGES } from "../utils";
import ModalAssociateTags from "../../../../../../../components/Modals/ModalAssociateTags";
import {
  fetchTags,
  getStatus,
  getTagList,
} from "../../../../../../../parts/manageTags/tagSlice";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import {
  resetPartListStatus,
  resetSignatoriesListStatus,
} from "../../../../../../../parts/parts/partsSlice";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../../admin/modules/unitCorporative/unitCorporativeSlice";
const CreatePartLibrary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);

  const [isLoading, setIsLoading] = useState(false);
  const [personType, setPersonType] = useState("");
  const [isShowForm, setIsShowForm] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({});

  const handleCreatePart = async (data) => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const corporateUnitDefault =
        listUnitsCorporatives.find((uc) => uc?.isDefault)?._id || "";
      let identificationData = {};
      if (data?.personType === PERSON_TYPE?.NATURAL) {
        identificationData = {
          documentType: data?.documentType || "",
          documentNumber: data?.documentNumber || "",
          firstName: data?.firstName || "",
          firstSurname: data?.firstSurname || "",
          secondName: data?.lastName || "",
          secondSurname: data?.secondSurname || "",
          comercialName: data?.nameTradeNatural || "",
          charge: TYPE_CHARGES?.NATURAL,
          otherTypeDocument: data?.otherTypeDocument || "",
        };
      } else if (data?.personType === PERSON_TYPE?.LEGAL) {
        identificationData = {
          business: {
            businessName: data?.business || "",
            nit: data?.documentNumberLegal || "",
            type: data?.documentTypeLegal || "",
            checkDigit: "",
          },
          legalRepresentative: {
            documentType: data?.documentType || "",
            documentNumber: data?.documentNumber || "",
            firstName: data?.firstName || "",
            firstSurname: data?.firstSurname || "",
            secondName: data?.lastName || "",
            secondSurname: data?.secondSurname || "",
            limitations: data?.limitationsLegalRepresentative || "",
            otherTypeDocument: data?.otherTypeDocument || "",
          },
          comercialName: data?.nameTradeLegal || "",
          charge: TYPE_CHARGES?.LEGAL,
        };
      }
      const contactInfo = {
        email: data?.email || "",
        countryCodeNumber: data?.countryCode || "",
        countryCodeTelephone: data?.countryCodeTelephone || "",
        cityCode: data?.cityCode || "",
        extension: data?.extension || "",
        phone: data?.numberPhone || "",
        numberTelephone: data?.telephoneNumber || "",
        country: data?.country || "",
        city: data?.city || "",
        province: data?.province || "",
        physicalAddress: data?.address || "",
      };
      const obj = {
        ...contactInfo,
        ...identificationData,
        createdBy: userId,
        company,
        typePart: data?.personType || "",
        relatedSignatories: [],
        labels: data?.labels || [],
        ...(corporateUnitDefault && {
          associatedCorporateUnits: [corporateUnitDefault],
        }),
      };
      const response = await createPart(obj);
      setResponseData(response);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowForm = () => {
    setIsShowForm(true);
  };

  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
  }, [statusFetchUnits]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Crear parte"} />
        </Col>
      </Row>
      <br />
      <Row>
        <h1 className="home-init-cards-row__title-row">
          Completa la información de la parte para crearla en la biblioteca.
        </h1>
      </Row>
      <Form onSubmit={handleSubmit(handleCreatePart)}>
        {!isShowForm && (
          <>
            <Row>
              <Col xs={"auto"} md={"auto"} lg={3}>
                <Form.Group>
                  <Form.Label className="form__label">
                    Tipo de persona<span style={{ color: "red" }}>{"* "}</span>
                  </Form.Label>
                  <Form.Select
                    {...register("personType", {
                      required: {
                        value: true,
                        message: "*Este campo es obligatorio",
                      },
                    })}
                    className="label"
                    defaultValue={""}
                    onChange={(event) => {
                      const { value } = event.target;
                      reset();
                      setValue("personType", value);
                      setPersonType(value);
                    }}
                  >
                    <option value="" disabled defaultValue className="label">
                      Escoge el tipo de persona
                    </option>
                    <option value="NATURAL" className="label">
                      Natural
                    </option>
                    <option value="JURIDICA" className="label">
                      Jurídica
                    </option>
                  </Form.Select>
                  <div className="caption custom-input__error">
                    {errors?.personType?.message}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <h1 className="text-description">
                Recuerda contar con la autorización de titulares de datos
                personales para su tratamiento.
              </h1>
            </Row>
            <br />
            <Row style={{ justifyContent: "end" }}>
              <Col xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={handleSubmit(handleShowForm)}
                >
                  Siguiente
                </Button>
              </Col>
            </Row>
          </>
        )}
        {isShowForm && (
          <>
            {personType === "NATURAL" && (
              <>
                <FormPersonNatural
                  register={register}
                  errors={errors}
                  watch={watch}
                  handleSubmit={handleSubmit}
                  handleShow={setIsShowForm}
                  setValue={setValue}
                />
              </>
            )}
            {personType === "JURIDICA" && (
              <>
                <FormPersonLegal
                  register={register}
                  errors={errors}
                  watch={watch}
                  handleSubmit={handleSubmit}
                  handleShow={setIsShowForm}
                  setValue={setValue}
                />
              </>
            )}
          </>
        )}
      </Form>
      <ModalInfo
        title={
          responseData.status === CODES.COD_RESPONSE_HTTP_CREATED
            ? "Creación exitosa"
            : "Crear Parte"
        }
        responseData={responseData}
        onClose={() => {
          if (
            responseData.status === CODES.COD_RESPONSE_HTTP_CREATED &&
            responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS
          ) {
            navigate(-1);
            dispatch(resetPartListStatus());
            dispatch(resetSignatoriesListStatus());
          }
          setIsOpenModalInfo(false);
        }}
        open={isOpenModalInfo}
        buttonClose={true}
        confirmationText="Aceptar"
        handleCloseDefault={() => setIsOpenModalInfo(false)}
      />
    </Container>
  );
};
const FormContactInfo = (props) => {
  const { register, errors, watch, setValue, handleBack } = props;

  const dispatch = useDispatch();
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);

  const [tagsSelected, setTagsSelected] = useState([]);

  const handleTagsSelected = (tags) => {
    if (!tags?.length) return;
    const tagsId = tags.map((tag) => tag._id);
    setValue("labels", tagsId);
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("PARTSLIBRARY"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);
  return (
    <>
      <Row style={{ rowGap: 10 }}>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Correo electrónico</Form.Label>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.email
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <AlternateEmailOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: REGEXP.EMAIL,
                    message: "*Este campo debe ser un correo válido",
                  },
                  maxLength: {
                    value: 250,
                    message: "Máximo 250 caracteres permitidos",
                  },
                })}
                inputMode="email"
                placeholder={"Correo electrónico"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.email?.message}
            </div>
          </Form.Group>
        </Col>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">
              Confirma correo electrónico
            </Form.Label>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.confirmEmail
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <AlternateEmailOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("confirmEmail", {
                  required: false,
                  pattern: {
                    value: REGEXP.EMAIL,
                    message: "*Este campo debe ser un correo válido",
                  },
                  validate: {
                    confirmateEmail: (value) => {
                      const currentEmail = watch("email");
                      return value === currentEmail || "*El correo no coincide";
                    },
                  },
                  maxLength: {
                    value: 250,
                    message: "Máximo 250 caracteres permitidos",
                  },
                })}
                inputMode="email"
                placeholder={"Correo electrónico"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.confirmEmail?.message}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row style={{ alignItems: "center" }}>
        <Form.Label className="form__label">Número de celular</Form.Label>
        <Form.Group as={Col} md={4}>
          <Select
            defaultValue={"57"}
            className={
              errors.countryCode
                ? "input-group-container__no-icon__country-list__error label"
                : "input-group-container__no-icon__country-list label"
            }
            {...register("countryCode", {
              required: {
                value: true,
                message: "*Este campo es obligatorio",
              },
            })}
            sx={{
              height: "auto !important",
              "& .MuiSelect-outlined": { padding: "0 !important" },
            }}
          >
            {countries.map((c) => (
              <MenuItem
                key={c.code}
                value={c.phone}
                className="input-group-container__no-icon__country-list label"
                sx={{
                  "& .Mui-selected": { height: "fit-content !important" },
                }}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                  alt={`Flag of ${c.label}`}
                />
                {` ${c.label} (${c.code}) +${c.phone}`}
              </MenuItem>
            ))}
          </Select>
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <InputGroup
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={
              errors?.numberPhone
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
          >
            <InputGroup.Text bsPrefix="container-icon">
              <PhoneAndroidOutlinedIcon fontSize="large" />
            </InputGroup.Text>
            <Form.Control
              bsPrefix="custom-input"
              {...register("numberPhone", {
                required: false,
                pattern: {
                  value: REGEXP.ONLY_NUMBERS,
                  message: "*Solo debe incluir numeros",
                },
                maxLength: {
                  value: 10,
                  message: "*Máximo 10 caracteres permitidos",
                },
              })}
              inputMode="email"
              placeholder={"Número de celular"}
            />
          </InputGroup>
          <div className="caption custom-input__error">
            {errors?.numberPhone?.message}
          </div>
        </Form.Group>
      </Row>
      <br />
      <Row style={{ alignItems: "center" }}>
        <Form.Label className="form__label">Teléfono fijo</Form.Label>
        <Form.Group as={Col} md={2}>
          <Select
            defaultValue={"57"}
            className={
              errors.countryCodeTelephone
                ? "input-group-container__no-icon__country-list__error label"
                : "input-group-container__no-icon__country-list label"
            }
            {...register("countryCodeTelephone")}
            sx={{
              height: "auto !important",
              "& .MuiSelect-outlined": { padding: "0 !important" },
            }}
          >
            {countries.map((c) => (
              <MenuItem
                key={c.code}
                value={c.phone}
                className="input-group-container__no-icon__country-list label"
                style={{ border: "none !important" }}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                  alt={`Flag of ${c.label}`}
                />
                {` ${c.label} (${c.code}) +${c.phone}`}
              </MenuItem>
            ))}
          </Select>
        </Form.Group>
        <Form.Group as={Col} md={1}>
          <Form.Control
            placeholder={"Ind. país"}
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={"input-group-container__no-icon label"}
            disabled
            value={watch("countryCodeTelephone")}
          />
        </Form.Group>
        <Form.Group as={Col} md={1}>
          <Form.Control
            {...register("cityCode", {
              required: false,
              pattern: {
                value: REGEXP.ONLY_NUMBERS,
                message: "*Solo debe incluir numeros",
              },
              maxLength: {
                value: 1,
                message: "*Máximo 1 caracteres permitidos",
              },
            })}
            placeholder={"Ind. ciudad"}
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={
              errors?.cityCode
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
          />
          <div className="caption custom-input__error">
            {errors?.cityCode?.message}
          </div>
        </Form.Group>
        <Form.Group as={Col} md={1}>
          <Form.Control
            {...register("extension", {
              required: false,
              pattern: {
                value: REGEXP.ONLY_NUMBERS,
                message: "*Solo debe incluir numeros",
              },
            })}
            placeholder={"Extensión"}
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={
              errors?.extension
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
          />
          <div className="caption custom-input__error">
            {errors?.extension?.message}
          </div>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Group>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.telephoneNumber
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <LocalPhoneOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("telephoneNumber", {
                  required: false,
                  pattern: {
                    value: REGEXP.ONLY_NUMBERS,
                    message: "*Solo debe incluir numeros",
                  },
                  minLength: {
                    value: 7,
                    message: "*Minimo 7 caracteres permitidos",
                  },
                  maxLength: {
                    value: 7,
                    message: "*Máximo 7 caracteres permitidos",
                  },
                })}
                inputMode="email"
                placeholder={"Teléfono fijo"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.telephoneNumber?.message}
            </div>
          </Form.Group>
        </Form.Group>
      </Row>
      <br />
      <Row>
        <Col className="home-init-cards-row__title-row">
          <span>Domicilio</span>
          {/* <PopoverInfo section={{ description: "Es la persona que directamente va a contratar, a tener derechos y/o obligaciones." }} /> */}
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">País</Form.Label>
            <Form.Control
              {...register("country", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              placeholder={"País"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.country
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.country?.message}
            </div>
          </Form.Group>
        </Col>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Provincia o región</Form.Label>
            <Form.Control
              {...register("province", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              placeholder={"Provincia o región"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.province
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.province?.message}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Ciudad</Form.Label>
            <Form.Control
              {...register("city", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              placeholder={"Ciudad"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.city
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.city?.message}
            </div>
          </Form.Group>
        </Col>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Dirección física</Form.Label>
            <Form.Control
              {...register("address", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              placeholder={"Dirección física"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.address
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.address?.message}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row>
        <Col className="home-init-cards-row__title-row">
          <span> Etiquetas</span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateTags
                  tags={tagsList}
                  handleTagsSelected={(tags) => handleTagsSelected(tags)}
                  previousTagsSelected={tagsSelected}
                  type="PARTSLIBRARY"
                />
              </Col>
              {tagsSelected.map((tag) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={tag?._id}
                  >
                    <span className="caption custom-badges__disabled">
                      {tag.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ justifyContent: "end" }}>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={handleBack}
          >
            Regresar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="submit"
            variant="contained"
            className="custom-input__button__primary-color"
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};
const FormPersonNatural = (props) => {
  const { register, errors, watch, handleSubmit, handleShow } = props;
  const [isShowFormInfoContact, setIsShowFormInfoContact] = useState(false);
  const handleShowForm = () => {
    setIsShowFormInfoContact(true);
  };
  return (
    <div>
      {!isShowFormInfoContact && (
        <>
          <Row>
            <Col className="home-init-cards-row__title-row">
              <span>Datos de identificación:</span>
              {/* <PopoverInfo section={{ description: "Es la persona que directamente va a contratar, a tener derechos y/o obligaciones." }} /> */}
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Primer nombre<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Primer nombre"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.firstName
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.firstName?.message}
                </div>
              </Form.Group>
            </Col>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">Segundo nombre</Form.Label>
                <Form.Control
                  {...register("lastName", {
                    required: false,
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Segundo nombre"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.lastName
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.lastName?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Primer apellido<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  {...register("firstSurname", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Primer apellido"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.firstSurname
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.firstSurname?.message}
                </div>
              </Form.Group>
            </Col>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Segundo apellido
                </Form.Label>
                <Form.Control
                  {...register("secondSurname", {
                    required: false,
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Segundo apellido"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.secondSurname
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.secondSurname?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Tipo de documento<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Select
                  {...register("documentType", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                  })}
                  className="label"
                  defaultValue={""}
                >
                  <option value="" disabled defaultValue className="label">
                    Tipo de documento
                  </option>
                  <option value="CEDULA DE CIUDADANIA" className="label">
                    Cédula de ciudadanía - COL
                  </option>
                  <option value="TARJETA DE IDENTIDAD" className="label">
                    Tarjeta de identidad - COL
                  </option>
                  <option value="CEDULA DE EXTRANJERIA" className="label">
                    Cédula de extranjería - COL
                  </option>
                  <option value="INE" className="label">
                    INE - MEX
                  </option>
                  <option value="CURP" className="label">
                    CURP - MEX
                  </option>
                  <option value="PASAPORTE" className="label">
                    Pasaporte
                  </option>
                  <option value="OTRO" className="label">
                    Otros
                  </option>
                </Form.Select>
                <div className="caption custom-input__error">
                  {errors?.documentType?.message}
                </div>
              </Form.Group>
            </Col>
            {watch("documentType") === "OTRO" && (
              <Col xs={"auto"} md={"auto"} lg={4}>
                <Form.Group>
                  <Form.Label className="form__label">
                    Otro tipo de documento
                  </Form.Label>
                  <Form.Control
                    {...register("otherTypeDocument")}
                    placeholder={"Ingrese tipo de documento"}
                    style={{
                      border: "0.1rem solid #c5c7d0",
                    }}
                    bsPrefix={
                      errors?.otherTypeDocument
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div className="caption custom-input__error">
                    {errors?.otherTypeDocument?.message}
                  </div>
                </Form.Group>
              </Col>
            )}
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Número de documento
                  <span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  {...register("documentNumber", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    pattern: {
                      value: REGEXP.LETTERS_AND_NUMBERS,
                      message: "*Solo debe incluir numeros y letras",
                    },
                  })}
                  placeholder={"Número de documento"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.documentNumber
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.documentNumber?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          {watch("documentType") === "TARJETA DE IDENTIDAD" && (
            <>
              <br />
              <Row>
                <Col xs={"auto"} md={"auto"} lg={8}>
                  <h1 className="text-description">
                    Recuerda que los contratos, así como otros documentos
                    legales deben ser firmados por personas que sean plenamente
                    capaces legalmente.
                  </h1>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Nombre comercial
                </Form.Label>
                <Form.Control
                  {...register("nameTradeNatural", {
                    required: false,
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Nombre comercial"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.nameTradeNatural
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.nameTradeNatural?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ justifyContent: "end" }}>
            <Col xs={"auto"}>
              <Button
                type="button"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => handleShow(false)}
              >
                Regresar
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                type="button"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={handleSubmit(handleShowForm)}
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </>
      )}
      {isShowFormInfoContact && (
        <FormContactInfo
          {...props}
          handleBack={() => setIsShowFormInfoContact(false)}
        />
      )}
    </div>
  );
};
const FormPersonLegal = (props) => {
  const { register, errors, watch, handleSubmit, handleShow } = props;
  const [isShowFormInfoContact, setIsShowFormInfoContact] = useState(false);
  const handleShowForm = () => {
    setIsShowFormInfoContact(true);
  };
  return (
    <div>
      {!isShowFormInfoContact && (
        <>
          <br />
          <br />
          <Row>
            <Col xs={"auto"} className="label__subtitle">
              <span>Datos de identificación:</span>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Razón social<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  id={"field-business"}
                  {...register("business", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Razón social"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.business
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.business?.message}
                </div>
              </Form.Group>
            </Col>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Nombre comercial
                </Form.Label>
                <Form.Control
                  id={"field-name-trade"}
                  {...register("nameTradeLegal", {
                    required: false,
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Nombre comercial"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.nameTradeLegal
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.nameTradeLegal?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Tipo de documento<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  id={"field-document-type-legal"}
                  {...register("documentTypeLegal", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Tipo de documento"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.documentTypeLegal
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.documentTypeLegal?.message}
                </div>
              </Form.Group>
            </Col>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Número de documento
                  <span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  id={"field-document-number-legal"}
                  {...register("documentNumberLegal", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Número de documento"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.documentNumberLegal
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.documentNumberLegal?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={"auto"} className="label__subtitle">
              <span>Datos del representante legal</span>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Primer nombre<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  id={"field-firstName-legal"}
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Primer nombre"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.firstName
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.firstName?.message}
                </div>
              </Form.Group>
            </Col>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">Segundo nombre</Form.Label>
                <Form.Control
                  id={"field-lastName-legal"}
                  {...register("lastName", {
                    required: false,
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Segundo nombre"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.lastName
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.lastName?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Primer apellido<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  id={"field-firstSurname-legal"}
                  {...register("firstSurname", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Primer apellido"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.firstSurname
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.firstSurname?.message}
                </div>
              </Form.Group>
            </Col>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Segundo apellido
                </Form.Label>
                <Form.Control
                  id={"field-secondSurname-legal"}
                  {...register("secondSurname", {
                    required: false,
                    maxLength: {
                      value: 250,
                      message: "Máximo 250 caracteres permitidos",
                    },
                  })}
                  placeholder={"Segundo apellido"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.secondSurname
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.secondSurname?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Tipo de documento<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Select
                  id={"field-documentType-legal"}
                  {...register("documentType", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                  })}
                  className="label"
                  defaultValue={""}
                >
                  <option value="" disabled defaultValue className="label">
                    Tipo de documento
                  </option>
                  <option value="CEDULA DE CIUDADANIA" className="label">
                    Cédula de ciudadanía - COL
                  </option>
                  <option value="TARJETA DE IDENTIDAD" className="label">
                    Tarjeta de identidad - COL
                  </option>
                  <option value="CEDULA DE EXTRANJERIA" className="label">
                    Cédula de extranjería - COL
                  </option>
                  <option value="INE" className="label">
                    INE - MEX
                  </option>
                  <option value="CURP" className="label">
                    CURP - MEX
                  </option>
                  <option value="PASAPORTE" className="label">
                    Pasaporte
                  </option>
                  <option value="OTRO" className="label">
                    Otros
                  </option>
                </Form.Select>
                <div className="caption custom-input__error">
                  {errors?.documentType?.message}
                </div>
              </Form.Group>
            </Col>
            {watch("documentType") === "OTRO" && (
              <Col xs={"auto"} md={"auto"} lg={4}>
                <Form.Group>
                  <Form.Label className="form__label">
                    Otro tipo de documento
                  </Form.Label>
                  <Form.Control
                    id={"field-otherTypeDocument-legal"}
                    {...register("otherTypeDocument")}
                    placeholder={"Ingrese tipo de documento"}
                    style={{
                      border: "0.1rem solid #c5c7d0",
                    }}
                    bsPrefix={
                      errors?.otherTypeDocument
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div className="caption custom-input__error">
                    {errors?.otherTypeDocument?.message}
                  </div>
                </Form.Group>
              </Col>
            )}
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Número de documento
                  <span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  id={"field-documentNumber-legal"}
                  {...register("documentNumber", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    pattern: {
                      value: REGEXP.LETTERS_AND_NUMBERS,
                      message: "*Solo debe incluir numeros y letras",
                    },
                  })}
                  placeholder={"Número de documento"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.documentNumber
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.documentNumber?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          {watch("documentType") === "TARJETA DE IDENTIDAD" && (
            <>
              <br />
              <Row>
                <Col xs={"auto"} md={"auto"} lg={8}>
                  <h1 className="text-description">
                    Recuerda que los contratos, así como otros documentos
                    legales deben ser firmados por personas que sean plenamente
                    capaces legalmente.
                  </h1>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={"auto"} md={"auto"} lg={8}>
              <Form.Group>
                <Form.Label className="form__label">
                  Limitaciones a la representación legal
                </Form.Label>
                <Form.Control
                  id={"field-limitationsLegalRepresentative-legal"}
                  {...register("limitationsLegalRepresentative", {
                    required: false,
                    maxLength: {
                      value: 1000,
                      message: "Máximo 1000 caracteres permitidos",
                    },
                  })}
                  placeholder={"Limitaciones a la representación legal"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  as="textarea"
                  rows={3}
                  bsPrefix={
                    errors?.limitationsLegalRepresentative
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.limitationsLegalRepresentative?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ justifyContent: "end" }}>
            <Col xs={"auto"}>
              <Button
                id={"back-button"}
                type="button"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => handleShow(false)}
              >
                Regresar
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                id={"save-button"}
                type="button"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={handleSubmit(handleShowForm)}
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </>
      )}
      {isShowFormInfoContact && (
        <FormContactInfo
          {...props}
          handleBack={() => setIsShowFormInfoContact(false)}
        />
      )}
    </div>
  );
};

export default CreatePartLibrary;
