import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../components/loadingContent";
import SectionTitle from "../../components/sectionTitle";
import Breadcrum from "../breadcrum";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalInfo from "../../components/Modals/modalInfo";
import ModalDecision from "../../components/Modals/modalDecision";
import { updateTagById, fetchTags, getTagList, getStatus } from "./tagSlice";
import { CODES } from "../../utils/codes";

const UpdateTag = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [searchParams] = useSearchParams();
  const resourceType = searchParams.get("resource");

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const params = useParams();
  const tagsList = useSelector(getTagList);
  const tagsStatus = useSelector(getStatus);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es obligatorio")
      .test("maxNumbers", "*Máximo 20 caracteres permitidos", function (value) {
        if (!!value) {
          const schema = yup.string().max(20);
          return schema.isValidSync(value);
        }
        return true;
      }),
    description: yup
      .string()
      .required("*Este campo es obligatorio")
      .test("maxNumbers", "*Máximo 60 caracteres permitidos", function (value) {
        if (!!value) {
          const schema = yup.string().max(60);
          return schema.isValidSync(value);
        }
        return true;
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /* UseEffects Fetch Tags */

  useEffect(() => {
    if (tagsStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [tagsStatus]);

  useEffect(() => {
    try {
      if (tagsStatus === "fetch") {
        dispatch(fetchTags(resourceType || "CLAUSES"));
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsStatus, dispatch]);

  useEffect(() => {
    if (tagsList && typeof tagsList !== "string") {
      const tag = tagsList.find((item) => item._id === params.id);
      if (tag) {
        setValue("name", tag.name);
        setValue("description", tag.description);
      }
    }
  }, [tagsList]);

  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleUpdateTag = async (data) => {
    try {
      setIsLoading(true);

      let obj = {
        id: params.id,
        updateLabel: {
          name: data.name,
          description: data.description,
          resourceType: resourceType || "CLAUSES",
        },
      };
      const updateTagService = await dispatch(updateTagById(obj)).unwrap();
      if (updateTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(updateTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(updateTagService);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Editar etiqueta"} />
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleUpdateTag)}>
          <Form.Group as={Col} xs={{ span: 5, offset: 0 }} className="mb-5">
            <Form.Label className="heading">Título de la etiqueta</Form.Label>
            <Form.Control
              {...register("name")}
              bsPrefix={
                errors.name
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={21}
            />
            <div className="caption custom-input__error">
              {errors.name?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} className="mb-5" xs={{ span: 8, offset: 0 }}>
            <Form.Label className="heading">Descripción</Form.Label>
            <Form.Control
              {...register("description")}
              as="textarea"
              bsPrefix={
                errors.description
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={61}
            />
            <div className="caption custom-input__error">
              {errors.description?.message}
            </div>
          </Form.Group>
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Etiqueta actualizada"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Cancelar"}
        message={
          "¿Seguro que deseas cancelar la edición de la etiqueta? todos los cambios se perderán"
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default UpdateTag;
