import { createAsyncThunk, createAction, createSlice } from "@reduxjs/toolkit";
import {
  getDocumentTemplates,
  updateOtherDocumentTemplete,
} from "../../../../../../services/documentTemplates/templeteServices";
import { CODES } from "../../../../../../utils/codes";

export const templeteSlice = createSlice({
  name: "templete",
  initialState: {
    templeteList: [],
    selectedTemplate: {},
    updateTempleteStatus: "fetch",
    TempleteByIdStatus: "fetch",
    status: "fetch",
    error: null,
    errorUpdateTemplete: null,
  },
  reducers: {
    setStatusTemplete(state, action) {
      const status = action.payload.status;
      state.status = status;
    },
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //Fetch Templete
      .addCase(fetchOtherDocumentTemplates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOtherDocumentTemplates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.templeteList = action.payload.data.responseMessage.data;
      })
      .addCase(fetchOtherDocumentTemplates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      //Update setTempleteByIdStatus fetch
      .addCase(setTempleteByIdStatus, (state, action) => {
        state.TempleteByIdStatus = action.payload.status;
      })
      //Update Templete
      .addCase(modifyTemplete.pending, (state, _) => {
        state.updateTempleteStatus = "loading";
      })
      .addCase(modifyTemplete.fulfilled, (state, action) => {
        state.updateTempleteStatus = "succeeded";
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK) {
          state.templeteList = action.payload.templeteList;
        }
      })
      .addCase(modifyTemplete.rejected, (state, action) => {
        state.updateTempleteStatus = "failed";
        state.errorUpdateTemplete = action.error.message;
      });
  },
});

export const getOtherDocumentTemplates = (state) => state.templete.templeteList;
export const getOtherDocumentTemplatesByid = (state, action) =>
  state.templete.templeteList.find((item) => item._id === action.payload.id);
export const getTempleteStatus = (state) => state.templete.status;
export const { setStatusTemplete } = templeteSlice.actions;

export const { setSelectedTemplate } = templeteSlice.actions;

export const getSelectedTemplate = (state) => state.templete.selectedTemplate;
export const setTempleteByIdStatus = createAction(
  "templete/setTempleteByIdStatus",
  () => ({
    payload: { status: "fetch" },
  })
);

export const fetchOtherDocumentTemplates = createAsyncThunk(
  "templete/fetchOtherDocumentTemplates",
  async () => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const response = await getDocumentTemplates({ company, corporateUnit });
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export const modifyTemplete = createAsyncThunk(
  "templete/updateTemplete",
  async (request, { rejectWithValue }) => {
    try {
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const company = localStorage.getItem("company");
      const response = await updateOtherDocumentTemplete({
        ...request,
        company,
        corporateUnit,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          company: companyId,
          corporateUnit,
        };
        const templeteResponse = await getDocumentTemplates(obj);
        return {
          status: response.status,
          data: response.data,
          templeteList: templeteResponse.data.responseMessage,
        };
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export default templeteSlice.reducer;
