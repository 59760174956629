import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { saveAs } from "file-saver";
import { MDBDataTableV5 } from "mdbreact";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingContent from "../../../../../components/loadingContent";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../components/sectionTitle";
import { CustomToolTip } from "../../../../../components/Tooltip/Tooltip";
import Breadcrum from "../../../../../parts/breadcrum";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import { getFileDocument } from "../../../../../services/documents/documentsRequest";
import { findOneDataExternalRequest } from "../../../../../services/general/CarvajalServices";
import { CODES } from "../../../../../utils/codes";
import { dateNormal } from "../../../../../utils/getDate";
import { validateCompleteSigns } from "./ViewRequest/ViewRequestStandard";
const SignModule = () => {
  const { consecutive } = useParams();
  const navigate = useNavigate();
  const info = JSON.parse(localStorage.payloadToken);
  const companyPlan = useSelector(getCompanyPlan);
  const [value, setValue] = useState("1");
  const [lastDocument, setLastDocument] = useState([]);
  const [showModalLastDoc, setshowModalLastDoc] = useState(false);
  const [currentDocumentToSign, setCurrentDocumentToSign] = useState("");
  const [currentRequest, setCurrentRequest] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [responseDataModalInfo, setResponseDataModalInfo] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [disableSign, setDisableSign] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dataTableDocumentPrincipal, setDataTableDocumentPrincipal] = useState({
    columns: [],
    rows: [],
  });

  const [dataTableRequiredDocs, setDataTableRequiredDocs] = useState({
    columns: [],
    rows: [],
  });

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const handleClickDownload = async (document) => {
    try {
      const file = await getFileDocument(
        document.filenameInBucket,
        document.bucketName,
        false
      );
      const bf = file.data.responseMessage.buffer.data;
      const blob = new Blob([new Uint8Array(bf).buffer]);
      let fileName = file.data.responseMessage.filename;
      const contentType =
        fileName.split(".").length === 1 &&
        file.data.responseMessage.contentType;
      if (
        contentType &&
        contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        fileName = fileName + ".docx";
      } else if (contentType && contentType === "application/pdf") {
        fileName = fileName + ".pdf";
      }

      saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrincipalDocumentToSign = async () => {
    try {
      if (!currentDocumentToSign) return;
      setIsLoading(true);
      if (currentDocumentToSign?._id !== lastDocument[0]._id) {
        setshowModalLastDoc(true);
      } else if (currentDocumentToSign?._id === lastDocument[0]._id) {
        const { userId } = info;
        const company = localStorage.getItem("company");
        const obj = {
          attorney: userId,
          documentType: "mainDocuments",
          documentAssociated: currentDocumentToSign,
          consecutive: parseInt(consecutive),
          companyId: company,
          signatureType: "request",
          documentId: currentDocumentToSign?._id,
          requestId: currentRequest._id,
        };
        navigate(`/service/addSignatories`, { state: obj });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequiredDocumentToSign = async (data) => {
    try {
      if (!currentDocumentToSign) return;
      setIsLoading(true);
      const { userId } = info;
      const company = localStorage.getItem("company");
      const obj = {
        attorney: userId,
        documentType: currentDocumentToSign.type,
        documentAssociated: currentDocumentToSign,
        consecutive: parseInt(consecutive),
        companyId: company,
        signatureType: "request",
        documentId: currentDocumentToSign?._id,
        requestId: currentRequest._id,
      };
      navigate(`/service/addSignatories`, { state: obj });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAgreeToContinue = async () => {
    try {
      setIsLoading(true);
      setshowModalLastDoc(false);
      const { userId } = info;
      const company = localStorage.getItem("company");
      const obj = {
        attorney: userId,
        documentType: "mainDocuments",
        documentAssociated: currentDocumentToSign,
        consecutive: parseInt(consecutive),
        companyId: company,
        signatureType: "request",
        documentId: currentDocumentToSign?._id,
        requestId: currentRequest._id,
      };
      navigate(`/service/addSignatories`, { state: obj });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const company = localStorage.getItem("company");
        const obj = {
          consecutive,
          company,
          corporateUnit,
        };
        const GetDataRequest = await findOneDataExternalRequest(obj);
        if (GetDataRequest.status) {
          if (GetDataRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
            const data = GetDataRequest.data.responseMessage;

            const dataFilter = {
              _id: data._id,
              stateRequest: data.requestStatus.name,
              color:
                data.requestStatus.colorId === 100
                  ? "#FFD7B1"
                  : data.requestStatus.colorId === 300
                  ? "#CCF4E3"
                  : data.requestStatus.colorId === 400
                  ? "#F9DADE"
                  : data.requestStatus.colorId === 200
                  ? "#E6E9EF"
                  : "light",
              applicant:
                data.applicant.firstName + " " + data.applicant.firstSurname,
              applicantId: data.applicant._id,
              attorneyInCharge:
                data.attorneyInCharge.firstName +
                " " +
                data.attorneyInCharge.firstSurname,
              requestSubject: data.requestSubject,
              requestType: data.requestType,
              requestSubType: data.requestSubType?.name,
              responseLimitDate: data.responseLimitDate,
              requestDetails: data.requestDetails,
              documentsOtherAttorney: data.documents.attorneyDocuments,
              documentsAttachments: data.documents.requiredDocuments.concat(
                data.documents.applicantDocuments,
                data.documents.attorneyDocuments
              ),
              documentsOtherApplicant: data.documents.applicantDocuments,
              documentPrincipal: data?.documents?.mainDocuments.filter(
                (doc) =>
                  !(
                    !doc.isRejected &&
                    !validateCompleteSigns(data.signatures, doc) &&
                    doc.subtype === "Signature"
                  )
              ),
              comments: data.comments,
            };

            setCurrentRequest(dataFilter);

            setLastDocument(dataFilter.documentPrincipal.slice(-1));
            setDataTableDocumentPrincipal({
              columns: [
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Seleccionar documento
                    </label>,
                  ],
                  field: "select",
                },
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Seguimiento
                    </label>,
                  ],
                  field: "followup",
                },
                {
                  label: [
                    <label aria-hidden="true" key="2">
                      Descripción
                    </label>,
                  ],
                  field: "description",
                },
                {
                  label: [
                    <label aria-hidden="true" key="3">
                      Creado por
                    </label>,
                  ],
                  field: "createdBy",
                },
                {
                  label: [
                    <label aria-hidden="true" key="4">
                      Dirigido a
                    </label>,
                  ],
                  field: "assignedTo",
                },
                {
                  label: [
                    <label aria-hidden="true" key="5">
                      Fecha de creación
                    </label>,
                  ],
                  field: "createdAt",
                },
                {
                  label: [
                    <label aria-hidden="true" key="6">
                      Documento Adjunto
                    </label>,
                  ],
                  field: "originalFilename",
                },
              ],

              rows: dataFilter?.documentPrincipal
                .filter((document) => {
                  return !document.isRejected;
                })
                .reverse()
                .map((filterDocument) => {
                  return {
                    select: (
                      <input
                        type="radio"
                        name="documentAssociated"
                        onChange={() =>
                          setCurrentDocumentToSign(filterDocument)
                        }
                      />
                    ),
                    description: filterDocument.description,
                    createdAt: dateNormal(filterDocument.createdAt),
                    createdBy: filterDocument.createdBy,
                    assignedTo: filterDocument.assignedTo,
                    followup: filterDocument.isSignatureFinish ? (
                      <label className="caption">
                        {filterDocument.tracking}
                      </label>
                    ) : !filterDocument.isSignatureFinish &&
                      filterDocument.userRole === "EXTERNAL" ? (
                      <label className="caption">
                        {"#" +
                          filterDocument.consecutive +
                          " Respuesta Solicitante"}
                      </label>
                    ) : !filterDocument.isSignatureFinish &&
                      filterDocument.userRole === "SERVICE" ? (
                      <label className="caption">
                        {"#" +
                          filterDocument.consecutive +
                          " Respuesta Abogado"}
                      </label>
                    ) : (
                      <label className="caption">
                        Documento adjunto aprobación
                      </label>
                    ),
                    originalFilename: (
                      <label
                        onClick={() => {
                          handleClickDownload(filterDocument);
                        }}
                        style={{
                          color: "#17B4BC",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          borderRadius: "0.8rem",
                          padding: "0.4rem",
                        }}
                      >
                        {filterDocument.originalFilename}
                      </label>
                    ),
                  };
                }),
            });

            setDataTableRequiredDocs({
              columns: [
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Selecionar documento
                    </label>,
                  ],
                  field: "select",
                },
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Documento anexo
                    </label>,
                  ],
                  field: "requiredDoc",
                },
                {
                  label: [
                    <label aria-hidden="true" key="6">
                      Documento Adjunto
                    </label>,
                  ],
                  field: "originalFilename",
                },
              ],
              rows: dataFilter?.documentsAttachments.map((document) => {
                if (!document.filenameInBucket) {
                  return <></>;
                }
                return {
                  select: (
                    <input
                      type="radio"
                      name="documentAssociated"
                      onChange={() => setCurrentDocumentToSign(document)}
                    />
                  ),
                  requiredDoc: document.originalFilename,
                  originalFilename: (
                    <label
                      onClick={() => {
                        handleClickDownload(document);
                      }}
                      style={{
                        color: "#17B4BC",
                        cursor: "pointer",
                        fontSize: "1.5rem",
                        borderRadius: "0.8rem",
                        padding: "0.4rem",
                      }}
                    >
                      {document.originalFilename}
                    </label>
                  ),
                };
              }),
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isSignatureEnable) {
        setDisableSign(true);
      }
    }
  }, [companyPlan]);
  const handleSignDocument = () => {
    if (value === "1") {
      handlePrincipalDocumentToSign();
    } else if (value === "2") {
      handleRequiredDocumentToSign();
    }
  };

  return (
    <>
      <Container fluid className="custom-container-scroll">
        {isLoading && <LoadingContent />}
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={`Firmar documento - Solicitud ${consecutive}`} />
        </Row>
        <Row xs={"auto"}>
          <p className="caption" style={{ marginTop: "20px" }}>
            Selecciona el documento que quieres firmar
          </p>
        </Row>
        <Row>
          <TabContext value={value}>
            <Tabs
              value={value}
              onChange={handleChange}
              className="label__primary-color"
              sx={{
                "& .MuiTab-root": {
                  color: "#00374f !important",
                  textTransform: "none !important",
                  fontWeight: "400 !important",
                  fontSize: "1.2rem !important",
                },
                "& .Mui-selected": {
                  color: "#00374f !important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#00374f !important",
                },
              }}
            >
              <Tab label="Documentos principales" value={"1"} />
              <Tab label="Documentos anexos" value={"2"} />
            </Tabs>
            <TabPanel
              value={"1"}
              sx={{
                ".css-13xfq8m-MuiTabPanel-root": {
                  padding: "none",
                },
              }}
            >
              <Row className="data-table dt-list-items">
                <MDBDataTableV5
                  noRecordsFoundLabel="No se encontraron resultados"
                  hover
                  infoLabel={["Mostrando", "a", "de", "documentos"]}
                  fullPagination
                  entries={5}
                  pagesAmount={4}
                  data={dataTableDocumentPrincipal}
                  searchBottom={false}
                  pagingTop
                />
              </Row>
            </TabPanel>
            <TabPanel value={"2"}>
              <Row className="data-table dt-list-items">
                <MDBDataTableV5
                  noRecordsFoundLabel="No se encontraron resultados"
                  hover
                  infoLabel={["Mostrando", "a", "de", "documentos"]}
                  entries={5}
                  pagesAmount={4}
                  data={dataTableRequiredDocs}
                  searchBottom={false}
                  pagingTop
                />
              </Row>
            </TabPanel>
          </TabContext>
        </Row>
        <Row>
          <Col md={"auto"}>
            {disableSign ? (
              <CustomToolTip
                direction="top"
                message="Sin firmas disponibles en el plan, contacta al usuario administrador"
              >
                <button
                  type="button"
                  className="btn_disable"
                  style={{ padding: "12px 24px", marginRight: "2rem" }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faFileSignature}
                    style={{ marginRight: "8px" }}
                  />
                  Firmar documento
                </button>
              </CustomToolTip>
            ) : (
              <button
                type="button"
                className="btn_blue_background"
                style={{ padding: "12px 24px", marginRight: "2rem" }}
                onClick={handleSignDocument}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faFileSignature}
                  style={{ marginRight: "8px" }}
                />
                Firmar documento
              </button>
            )}
          </Col>
        </Row>
        <ModalDecision
          title={"Atención"}
          message={
            "El documento que desea firmar no es el ultímo subido en la traza de documentos."
          }
          open={showModalLastDoc}
          onClose={setshowModalLastDoc}
          agreeText="Seguir con la firma"
          disagreeText="Cancelar firma"
          handleAgree={handleAgreeToContinue}
          handleDisagree={() => {
            setshowModalLastDoc(false);
          }}
        />
        <ModalInfo
          title={"Información"}
          responseData={responseDataModalInfo}
          onClose={() => setIsOpenModalInfo(false)}
          open={isOpenModalInfo}
          confirmationText={"Aceptar"}
        />
        <ModalInfo
          title="Sin permisos suficientes"
          onClose={() => setIsOpenModalUnauthorized(false)}
          open={isOpenModalUnauthorized}
          responseData={responseDataUnauthorized}
          confirmationText="Aceptar"
        />
      </Container>
    </>
  );
};

export default SignModule;
