export default function dateCon(createdAt) {
  const date = new Date(createdAt);

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  let minute = date.getMinutes();

  if (minute < 10) {
    minute = "0" + minute;
  }
  const mes = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  if (hour < 12) {
    return `${day} de ${mes[month]} del ${year}, ${hour}:${minute} AM`;
  } else {
    return `${day} de ${mes[month]} del ${year}, ${
      hour % 12 < 10 ? "0" + (hour % 12) : hour % 12
    }:${minute} PM`;
  }
}

export function dateNormal(createdAt) {
  const date = new Date(createdAt);

  const day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }

  return day + "/" + month + "/" + year;
}

export const getDiffTime = (date, diffHours, type = "GREATER_THAN") => {
  // Dos horas en milisegundos.
  const diff = Number(diffHours) * 3600000;
  const differenceDays = Math.abs(new Date() - new Date(date));
  if (type === "LESS_THAN") return differenceDays < diff;
  if (type === "LESS_EQUAL_THAN") return differenceDays <= diff;
  if (type === "GREATER_THAN") return differenceDays > diff;
  if (type === "GREATER_EQUAL_THAN") return differenceDays >= diff;
};
