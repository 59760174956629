import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

//Material Import
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

//Components
import { DialogTitleContainer } from "./DialogTitleContainer";

//Utilities

export const ModalListSelectCheckBoxV2 = ({
  title = "N/A",
  subtitle = "N/A",
  inputLabelText = "N/A",
  textButtonAccept = "Aceptar",
  textButtonCancel = "Cancelar",
  iconAccept,
  iconCancel,
  list = [],
  open,
  onClose,
  onCloseAndDownload,
  setIndicatorsSelected,
}) => {
  //Constants
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [downloadDisable, setDownloadDisable] = useState(true);

  //Handlers
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOptionsSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleAccept = () => {
    const value = optionsSelected.sort((a, b) => a.localeCompare(b));
    onCloseAndDownload(value);
  };

  const handleClose = () => {
    setOptionsSelected([]);
    onClose();
  };

  useEffect(() => {
    if (optionsSelected.length > 0) {
      setDownloadDisable(false);
    } else {
      setDownloadDisable(true);
    }
  }, [optionsSelected]);

  return (
    <>
      <Dialog
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">{title}</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Row>
            <Col xs={"auto"} className="caption">
              {subtitle}
            </Col>
          </Row>
          <br />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              borderTop: "1px solid #e0e0e0",
            }}
          >
            <FormControl>
              <InputLabel style={{ fontSize: "14px" }}>
                {inputLabelText}
              </InputLabel>
              <Select
                multiple
                value={optionsSelected}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        style={{ fontSize: "12px" }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {list.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={optionsSelected.find((item) => item === name)}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              {downloadDisable && (
                <FormHelperText
                  className="caption custom-input__error"
                  style={{ color: "#ff6347" }}
                >
                  Recuerda seleccionar una de las opciones de la barra
                  desplegable
                </FormHelperText>
              )}
            </FormControl>
          </Row>
        </DialogContent>
        <DialogActions>
          <Row xs={"auto"} style={{ justifyContent: "right" }}>
            <Col>
              <Button
                startIcon={iconCancel}
                type="button"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                {textButtonCancel}
              </Button>
            </Col>
            <Col>
              <Button
                startIcon={iconAccept}
                disabled={downloadDisable}
                type="button"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={handleAccept}
              >
                {textButtonAccept}
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </>
  );
};
