import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FolderIcon from "@mui/icons-material/Folder";
import { Button } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import FilterBar from "../../../../../../components/FilterBar/FilterBar";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import {
  fetchFolderTags,
  getFolderTagsList,
  getFolderTagsStatus,
} from "../../../../../../parts/folders/folderTagSlice";
import { getFoldersByCompany } from "../../../../../../services/business_folder/businessFolderService";
import { CODES } from "../../../../../../utils/codes";
const ReviewSelectFolder = () => {
  /**
   * Tabla
   */
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última modificación
        </label>,
      ],
      field: "updatedAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Último acceso
        </label>,
      ],
      field: "lastAccess",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const rows = [];

  /**
   * UseNavigate
   */
  const navigate = useNavigate();

  /**
   * UseDispatch
   */
  const dispatch = useDispatch();

  /**
   * UseSelector
   */

  //Tags
  const tagsList = useSelector(getFolderTagsList);
  const statusTags = useSelector(getFolderTagsStatus);

  /**
   * UseState
   */

  //Tabla
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  //Loading content
  const [isLoading, setIsLoading] = useState(false);

  //Filter Tags
  const [tagsSelected, setTagsSelected] = useState([]);

  //Filterbar Drawner aún no está hecha
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);

  //Drawners
  const [openDrawer, setOpenDrawer] = useState(false);

  //Modals

  const [openModalInfo, setOpenModalInfo] = useState(true);
  /**
   * Handles
   */

  //Tags

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  //View folder

  const handleOpenFolder = (id) => {
    navigate(`viewFolder/${id}`);
  };

  /**
   * UseEffect
   */

  //Cargar tags
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  //Cargar Info
  useEffect(() => {
    const fetchData = async () => {
      try {
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const companyId = localStorage.getItem("company");
        const foldersService = await getFoldersByCompany({
          companyId,
          corporateUnit,
        });
        const processedFolders = foldersService.data.responseMessage.map(
          (folder) => {
            let labels = "";

            for (const [i] of folder.labels.entries()) {
              labels += folder.labels[i].name + ",";
            }

            return {
              element: (
                <Row
                  style={{
                    paddingLeft: "2%",
                    paddingTop: "1%",
                  }}
                >
                  <Col xs={"auto"} className="datatable-CB__tr-name">
                    <FolderIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </Col>
                  <Col className="datatable-CB__tr-name">{folder.name}</Col>
                </Row>
              ),
              type: "Carpeta",
              createdAt: moment(folder?.createdAt).format("DD/MM/YYYY"),
              updatedAt: moment(folder?.updatedAt).format("DD/MM/YYYY"),
              lastAccess: moment(folder?.lastAccessAt).format("DD/MM/YYYY"),
              labels: labels,
              actions: (
                <Row>
                  <Col>
                    <Button
                      className="button-softblue-folders"
                      onClick={(e) => {
                        handleOpenFolder(folder._id);
                      }}
                    >
                      Abrir
                    </Button>
                  </Col>
                </Row>
              ),
            };
          }
        );

        setDataTable({ columns: columns, rows: processedFolders });
        setFilteredDataTable({ columns: columns, rows: processedFolders });
        setTimeout(setIsLoading(false), 50000);
      } catch (error) {
        console.log(error);
      }
    };
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Carpetas" />
        </Col>
        <Col md={9}></Col>
        {/* <Col md={"auto"} className="col-flex">
          <IconButton size="large">
            <NotificationsNoneOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </IconButton>
        </Col> */}
      </Row>
      <Row className="text-description" style={{ height: "10%" }}>
        Haz clic en el botón “Seleccionar” del documento en negociación que
        buscas.
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={5} className="col-flex">
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col md={1}></Col>
        <Col md={3}></Col>
        <Col className="col-flex">
          <FilterTag
            tagsList={tagsList}
            list={filteredDataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
            classStyle="button-softblue-folders__labels"
            widthStyle="153px"
            currentTagSelected={tagsSelected}
          />
        </Col>

        <Col className="col-flex">
          <Button
            className="button-softblue-folders__filter"
            startIcon={<FilterAltIcon />}
            onClick={() => {
              /**NO SE CONOCE FILTRO PARA ESTA PANTALLA */
            }}
          >
            Filtrar
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "2%" }}>
        {tagsSelected.map((tag, idx) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row>
        {
          <MDBDataTableV5
            hover
            pagingTop
            searchBottom={false}
            entries={7}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "carpetas"]}
            fullPagination
            noRecordsFoundLabel="No se encontraron resultados"
          />
        }
      </Row>

      <FilterBar
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setOptionsFilterBar={setOptionsFilterBar}
        folders={true}
      />
      {!isLoading && (
        <ModalInfo
          title={"Revisar documento en negociación"}
          responseData={{
            status: CODES.COD_RESPONSE_HTTP_OK,
            data: {
              responseMessage:
                "Selecciona la versión del documento guardado en Legal AI del que vas a revisar ahora para que queden asociados",
            },
          }}
          open={openModalInfo}
          onClose={() => {
            setOpenModalInfo(false);
          }}
          buttonClose={true}
        />
      )}
    </Container>
  );
};

export default ReviewSelectFolder;
