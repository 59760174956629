import { PartsDtoFactory } from "../../../../../../../../services/parts/factories/partsDtoFactory.js";

/**
 * @function getModifiedParts
 * @description Get the parts to add, delete and update
 * @param {Object} currentSignatory
 * @param {Array} associatedParts
 * @returns {Object} partsToAdd, partsToDelete, partsToUpdate
 */
export const getModifiedParts = (currentSignatory, associatedParts) => {
  const relatedParts = currentSignatory?.relatedParties;
  const partsToAdd = associatedParts?.filter(
    (associatedPart) =>
      !relatedParts?.some(
        (relatedPart) => relatedPart?.part?._id === associatedPart?.part?._id
      )
  );
  const partsToDelete = relatedParts?.filter(
    (relatedPart) =>
      !associatedParts?.some(
        (item) => item?.part?._id === relatedPart?.part?._id
      )
  );
  const partsToUpdate = associatedParts?.filter((associatedPart) =>
    relatedParts?.some(
      (relatedPart) =>
        relatedPart?.part?._id === associatedPart?.part?._id &&
        relatedPart?.position !== associatedPart?.position
    )
  );
  return {
    partsToAdd: partsToAdd.map((item) =>
      PartsDtoFactory.generateManagePartToSignatoryDto(
        currentSignatory?._id,
        item?.part?._id,
        item?.position
      )
    ),
    partsToDelete: partsToDelete.map((item) =>
      PartsDtoFactory.generateManagePartToSignatoryDto(
        currentSignatory?._id,
        item?.part?._id,
        item?.position
      )
    ),
    partsToUpdate: partsToUpdate.map((item) =>
      PartsDtoFactory.generateManagePartToSignatoryDto(
        currentSignatory?._id,
        item?.part?._id,
        item?.position
      )
    ),
  };
};

/**
 * @function getModifiedPartscreate
 * @description Get the parts to add, delete and update
 * @param {Object} currentSignatory
 * @param {Array} associatedParts
 * @returns {Object} partsToAdd, partsToDelete, partsToUpdate
 */
export const getModifiedPartsCreate = (currentSignatory, associatedParts) => {
  const relatedParts = associatedParts;

  return {
    partsToAdd: relatedParts.map((item) =>
      PartsDtoFactory.generateManagePartToSignatoryDto(
        currentSignatory?._id,
        item?._id,
        item?.position
      )
    ),
  };
};
