import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ShowDataExternalRequest } from "../../../../../services/general/CarvajalServices";
import { CODES } from "../../../../../utils/codes";

export const requestSlice = createSlice({
    name: "requests",
    initialState: {
        requestList: [],
        requestStatus: "fetch",
        error: "",
        createFrom: {
            fromCreate: null,
            requestId: null,
        }
    },
    reducers: {
        setStatusRequest(state) { state.requestStatus = "fetch" },
        setCreateFromRequest(state, action) {
          state.createFrom = action.payload;
        },
        cleanStoreRequest(state) {
            state.createFrom = {
                fromCreate: null,
                requestId: null,
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchRequest.pending, (state, __) => {
            state.requestStatus = "loading";
        })
        .addCase(fetchRequest.rejected, (state, __) => {
            state.requestStatus = "loading";
        })
        .addCase(fetchRequest.fulfilled, (state, action) => {
            state.requestStatus = "success";
            if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK && action.payload.data.success) {
                state.requestList = action.payload.data.responseMessage
            }
        })
    }
});

export const getCreateFrom = (state) => state.requests.createFrom;

export const getRequestStatus = (state) => state.requests.requestStatus;

export const getRequestList = (state) => state.requests.requestList;

export const { setStatusRequest } = requestSlice.actions;

export const { setCreateFromRequest } = requestSlice.actions;

export const { cleanStoreRequest } = requestSlice.actions;

export const fetchRequest = createAsyncThunk("requests/fetchRequest", async (data,{rejectWithValue}) => {
    try {
        const { company } = JSON.parse(localStorage.getItem("payloadToken"));
        data["companyId"] = company;
        const response = await ShowDataExternalRequest(data);
        return {
            status: response.status,
            data: response.data
        }
    } catch (error) {
        rejectWithValue(error.response);
    }
});

export default requestSlice.reducer;