import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import LoadingLinear from "../../components/loadings/loadingLinear";
import SectionTitle from "../../components/sectionTitle";
import Breadcrum from "../breadcrum";
import { useForm } from "react-hook-form";
import PopoverInfo from "../../components/Popover/PopoverInfo";
import { Box, Button } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addEditedPart,
  addEditedSignatoriesWithoutPart,
  addEditedSignatoryWithoutPart,
  addEditedSignature,
  deleteSignatoryWithoutPart,
  getCurrentDocumentParts,
  getCurrentSignatoriesWithoutPart,
} from "../document/currentDocumentSlice";
import BlockerPage from "../../components/blocker/blocker";
import ModalDecision from "../../components/Modals/modalDecision";

export const FormSignatories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { indexPart, indexSignatory } = useParams();
  const currentParts = useSelector(getCurrentDocumentParts);
  const currentSignatoriesWithoutPart = useSelector(
    getCurrentSignatoriesWithoutPart
  );

  const [isLoading, setIsLoading] = useState(false);
  const [personType, setPersonType] = useState("Persona natural");
  const [textSignatory, setTextSignatory] = useState("");
  const [indexSaveDataSignatory, setIndexSaveDataSignatory] = useState(0);
  const [currentPartData, setCurrentPartData] = useState({});
  const [sectionTitleDefault, setSectionTitleDefault] =
    useState("Agregar parte");
  const [currentWithoutPartData, setCurrentWithoutPartData] = useState({});
  const [blockNavigate, setBlockNavigate] = useState(true);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);

  const schema = yup.object().shape({
    personType: yup.string().required("*Este campo es obligatorio"),
    designation: location.pathname.includes("without-part")
      ? yup.string().required("*Este campo es obligatorio")
      : yup.string().nullable(),
    fullName: yup.string().required("*Este campo es obligatorio"),
    documentType: yup.string().required("*Este campo es obligatorio"),
    documentNumber: yup.string().required("*Este campo es obligatorio"),
    email: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .notRequired(),
    country: yup.string().notRequired(),
    city: yup.string().notRequired(),
    emailSignatory: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .notRequired(),
    countryAndCitySignatory: yup.string().notRequired(),
    documentNumberSignatory: yup.string().notRequired(),
    documentTypeSignatory: yup.string().notRequired(),
    fullNameSignatory: yup.string().notRequired(),
    charge: yup.string().notRequired(),
    companyName: yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const buildSignatoryData = (data) => {
    let signatories = [];
    let information = {
      address: data?.countryAndCity,
      email: data?.email,
      documentNumber: data?.documentNumber,
      documentType: data?.documentType,
      name: data?.companyName || data?.fullName || "",
    };

    if (data?.personType === "Persona jurídica") {
      signatories = [
        {
          address: data?.countryAndCitySignatory,
          documentNumber: data?.documentNumberSignatory,
          documentType: data?.documentTypeSignatory,
          name: data?.fullName || "",
          email: data?.emailSignatory,
          typeCreate: "principal",
          indexSignatory: 0,
          text: textSignatory,
          type: data?.personType,
          isDefault: true,
          charge: data?.charge,
        },
      ];
    } else if (data?.personType === "Persona natural") {
      signatories = [
        {
          address: data?.countryAndCity,
          email: data?.email,
          documentNumber: data?.documentNumber,
          documentType: data?.documentType,
          name: data?.fullName || "",
          indexSignatory: 0,
          text: textSignatory,
          type: data?.personType,
          isDefault: true,
        },
      ];
    }

    if (signatories.length) {
      information = { ...information, signatory: signatories[0] };
    }
    return {
      gender: "",
      information,
      signatories,
      text: textSignatory,
      type: data?.personType,
    };
  };

  const handleCreateSignatory = (data) => {
    try {
      setBlockNavigate(false);
      setIsLoading(true);
      const values = buildSignatoryData(data);
      if (location.pathname.includes("without-part")) {
        const obj = {
          name: data?.designation,
          signatories: [values],
        };
        dispatch(
          addEditedSignatoryWithoutPart({
            partIndex: indexPart,
            signatoryIndex: indexSaveDataSignatory,
            editedSignatory: obj,
          })
        );
      } else {
        dispatch(
          addEditedSignature({
            partIndex: indexPart,
            signatoryIndex: indexSaveDataSignatory,
            editedSignatory: values,
          })
        );
      }
      navigate(-1);
    } catch (error) {
      console.log(
        "============ Handle Create Signatory Error Start ============"
      );
      console.error(error);
      console.log(
        "============ Handle Create Signatory Error End ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetValues = () => {
    reset();
  };

  const handleSaveChanges = () => {
    const data = getValues();
    const values = buildSignatoryData(data);
    if (location.pathname.includes("without-part")) {
      dispatch(
        addEditedSignatoryWithoutPart({
          partIndex: indexPart,
          signatoryIndex: indexSaveDataSignatory,
          editedSignatory: {
            name: data?.designation || "",
            signatories: [values],
          },
        })
      );
    } else {
      dispatch(
        addEditedSignature({
          partIndex: indexPart,
          signatoryIndex: indexSaveDataSignatory,
          editedSignatory: values,
        })
      );
    }
  };

  const handleCancel = () => {
    if (location.pathname.includes("without-part")) {
      if (currentWithoutPartData) {
        dispatch(
          addEditedSignatoriesWithoutPart({
            index: indexPart,
            editedPart: currentWithoutPartData || [],
          })
        );
      } else {
        dispatch(deleteSignatoryWithoutPart({ partIndex: indexPart }));
      }
    } else {
      dispatch(
        addEditedPart({ index: indexPart, editedPart: currentPartData })
      );
    }
    navigate(-1);
  };

  const PERSON_TYPES = {
    JURIDICA: "Persona jurídica",
    NATURAL: "Persona natural",
    "Persona jurídica": "Persona jurídica",
    "Persona natural": "Persona natural",
  };

  const setValueAllFields = () => {
    let currentData;
    if (location.pathname.includes("without-part")) {
      currentData =
        currentSignatoriesWithoutPart[indexPart]?.signatories[indexSignatory];
      setValue("designation", currentSignatoriesWithoutPart[indexPart]?.name);
    } else {
      currentData = currentParts[indexPart]?.signatories[indexSignatory];
    }

    //Fill information basic
    setValue("documentType", currentData?.information?.documentType);
    setValue("documentNumber", currentData?.information?.documentNumber);
    setValue("email", currentData?.information?.email);
    setValue("countryAndCity", currentData?.information?.address);
    setValue("fullName", currentData?.information?.name);

    const personType = PERSON_TYPES[currentData?.type] || "Persona natural";

    if (personType === "Persona jurídica") {
      setValue("companyName", currentData.information.name);
      setValue("charge", currentData.signatories?.[0]?.charge);
      setValue(
        "documentTypeSignatory",
        currentData.signatories?.[0]?.documentType
      );
      setValue(
        "documentNumberSignatory",
        currentData.signatories?.[0]?.documentNumber
      );
      setValue("emailSignatory", currentData.signatories?.[0]?.email);
      setValue(
        "countryAndCitySignatory",
        currentData.signatories?.[0]?.address
      );
      setValue("fullName", currentData?.signatories?.[0]?.name);
    }
  };

  useEffect(() => {
    watch((data) => {
      const { personType } = data;
      let text = "";
      if (personType === "Persona jurídica") {
        const {
          companyName,
          charge,
          documentType,
          documentNumber,
          fullName,
          documentTypeSignatory,
          documentNumberSignatory,
          countryAndCitySignatory,
        } = data;

        text = ` ${companyName || "¬razón social¬"} que se identifica con ${
          documentType || "¬tipo de documento¬"
        } ${documentNumber || "¬número de documento¬"} con domicilio en ${
          countryAndCitySignatory || "¬domicilio¬"
        } representada por ${fullName || "¬Nombre¬"} quien se identifica con ${
          documentTypeSignatory || "¬tipo de documento¬"
        } número ${
          documentNumberSignatory || "¬número de documento¬"
        } y que actúa en calidad de ${charge || "¬cargo del firmante¬"}`;
      } else if (personType === "Persona natural") {
        const { fullName, documentType, documentNumber, countryAndCity } = data;
        text = `${fullName || "¬Nombre¬"} quien se identifica con ${
          documentType || "¬tipo de documento¬"
        } número ${
          documentNumber || "¬número de documento¬"
        } y con domicilio en ${countryAndCity || "¬domicilio¬"}`;
      }
      setTextSignatory(text);
    });
  }, [watch]);

  useEffect(() => {
    (() => {
      let index = 0;
      const isCreate = location.pathname.includes("create-signatory");
      const isEdit = location.pathname.includes("edit-signatory");
      const isWithoutPart = location.pathname.includes("without-part");
      //Fill type person
      const currentData =
        currentParts?.[indexPart]?.signatories?.[indexSignatory];
      const typePerson = PERSON_TYPES[currentData?.type] || "Persona natural";
      setValue("personType", typePerson);
      setPersonType(typePerson);
      if (isCreate) index = currentParts[indexPart]?.signatories?.length;
      if (isEdit) {
        index = indexSignatory;
        setValueAllFields();
        setSectionTitleDefault("Editar parte");
      }
      if (isWithoutPart) {
        setSectionTitleDefault("Agregar tercero como firmante");
        setCurrentWithoutPartData(currentSignatoriesWithoutPart[indexPart]);
      }
      setIndexSaveDataSignatory(index);
      setCurrentPartData(currentParts[indexPart]);
    })();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingLinear />}
      <BlockerPage
        blockNavigate={blockNavigate}
        message={
          "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
        }
      />
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={sectionTitleDefault} />
        </Col>
      </Row>
      <br />
      <Form onSubmit={handleSubmit(handleCreateSignatory)}>
        <Row>
          {location.pathname.includes("without-part") ? (
            <h1 className="caption">Diligencia lo siguientes campos:</h1>
          ) : (
            <h1 className="caption">
              {`Diligencia los campos de la parte que quieres agregar para ${currentParts[indexPart]?.name}`}
            </h1>
          )}
        </Row>
        <Row>
          {!location.pathname.includes("without-part") && (
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Tipo de persona<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Select
                  {...register("personType")}
                  className="label"
                  defaultValue={""}
                  onChange={(event) => {
                    const { value } = event.target;
                    handleResetValues();
                    setValue("personType", value);
                    setPersonType(value);
                  }}
                >
                  <option value="" disabled defaultValue className="label">
                    Selecciona el tipo de firmante
                  </option>
                  <option value="Persona natural" className="label">
                    Persona natural
                  </option>
                  <option value="Persona jurídica" className="label">
                    Persona jurídica
                  </option>
                </Form.Select>
                <div className="caption custom-input__error">
                  {errors?.personType?.message}
                </div>
              </Form.Group>
            </Col>
          )}
          {location.pathname.includes("without-part") && (
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Calidad del firmante
                  <span style={{ color: "red" }}>{"*"}</span>
                  <PopoverInfo
                    section={{
                      description:
                        "Elige la denominación para entender por qué esta persona firmará el contrato si no es una de las partes. Ejemplo: testigo, aval, entre otros.",
                    }}
                  />
                </Form.Label>
                <Form.Control
                  {...register("designation")}
                  placeholder={"Calidad del firmante"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  bsPrefix={
                    errors?.designation
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  onBlur={handleSaveChanges}
                />
                <div className="caption custom-input__error">
                  {errors?.designation?.message}
                </div>
              </Form.Group>
            </Col>
          )}
        </Row>
        <br />
        {personType && (
          <>
            {personType === "Persona natural" && (
              <>
                <FormPersonNatural
                  register={register}
                  errors={errors}
                  handleSaveChanges={handleSaveChanges}
                />
                <br />
                <BoxSignatoryNatural
                  fullName={watch("fullName")}
                  documentType={watch("documentType")}
                  documentNumber={watch("documentNumber")}
                  designation={watch("designation")}
                  countryAndCity={watch("countryAndCity")}
                />
              </>
            )}
            {personType === "Persona jurídica" && (
              <>
                <FormPersonJuridica
                  register={register}
                  errors={errors}
                  handleSaveChanges={handleSaveChanges}
                  watch={watch}
                />
                <br />
                <BoxSignatoryJuridica
                  fullName={watch("fullName")}
                  documentType={watch("documentTypeSignatory")}
                  documentNumber={watch("documentNumberSignatory")}
                  countryAndCity={watch("countryAndCity")}
                  charge={watch("charge")}
                  companyName={watch("companyName")}
                  documentTypeCompany={watch("documentType")}
                  documentNumberCompany={watch("documentNumber")}
                />
              </>
            )}
          </>
        )}
        <br />
        <br />

        <Row className="sidebar__bottom__container">
          <Col xs={"auto"}>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => {
                setIsOpenModalDecision(true);
                setBlockNavigate(false);
              }}
            >
              Descartar cambios
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Guardar cambios
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalDecision
        title={"Descartar cambios"}
        message={
          "¿Está seguro que desea cancelar? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={() => setIsOpenModalDecision(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => handleCancel()}
        handleDisagree={() => setIsOpenModalDecision(false)}
      />
    </Container>
  );
};

const FormPersonNatural = ({ register, errors, handleSaveChanges }) => (
  <Row style={{ alignItems: "center" }}>
    <Row>
      <Col className="home-init-cards-row__title-row">
        <span className="subheading__default">
          {" "}
          Datos de la persona natural:
        </span>
        <PopoverInfo
          section={{
            description:
              "Es la persona que directamente va a contratar, a tener derechos y/o obligaciones.",
          }}
        />
      </Col>
    </Row>
    <Col xs={"auto"} md={"auto"} lg={8}>
      <Form.Group>
        <Form.Label className="form__label">Nombre completo*</Form.Label>
        <Form.Control
          {...register("fullName")}
          placeholder={"Nombre completo"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.fullName
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.fullName?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Tipo de documento*</Form.Label>
        <Form.Control
          {...register("documentType")}
          placeholder={"Tipo de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentType
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.documentType?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Número de documento*</Form.Label>
        <Form.Control
          {...register("documentNumber")}
          placeholder={"Número de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentNumber
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.documentNumber?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Correo electrónico</Form.Label>
        <Form.Control
          {...register("email")}
          placeholder={"ejemplo@correo.com"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.email
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.email?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Domicilio</Form.Label>
        <Form.Control
          {...register("countryAndCity")}
          className="label"
          placeholder="Ciudad"
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.countryAndCity
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.countryAndCity?.message}
        </div>
      </Form.Group>
    </Col>
  </Row>
);

const FormPersonJuridica = ({ register, errors, handleSaveChanges, watch }) => (
  <Row style={{ alignItems: "center" }}>
    <Row>
      <Col className="home-init-cards-row__title-row">
        <span>Datos de la persona jurídica:</span>
        <PopoverInfo
          section={{
            description:
              "Es una entidad como las  sociedades, entidades sin ánimo de lucro, entre otras.",
          }}
        />
      </Col>
    </Row>
    <Col xs={"auto"} md={"auto"} lg={8}>
      <Form.Group>
        <Form.Label className="form__label">Razón social</Form.Label>
        <Form.Control
          {...register("companyName")}
          placeholder={"Razón social"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.companyName
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.companyName?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Tipo de documento*</Form.Label>
        <Form.Control
          {...register("documentType")}
          placeholder={"Tipo de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentType
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.documentType?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Número de documento*</Form.Label>
        <Form.Control
          {...register("documentNumber")}
          placeholder={"Número de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentNumber
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.documentNumber?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Correo electrónico</Form.Label>
        <Form.Control
          {...register("email")}
          placeholder={"ejemplo@correo.com"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.email
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.email?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Domicilio</Form.Label>
        <Form.Control
          {...register("countryAndCity")}
          className="label"
          placeholder="Ciudad"
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.countryAndCity
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.countryAndCity?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Cargo</Form.Label>
        <Form.Control
          {...register("charge")}
          placeholder={"Cargo"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.charge
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.charge?.message}
        </div>
      </Form.Group>
    </Col>
    <JumpLineDivisor />
    <Row>
      <Col className="home-init-cards-row__title-row">
        <span>{`Datos del ${watch("charge") || "representante legal"}:`}</span>
        <PopoverInfo
          section={{
            description:
              "Es una persona que actúa en nombre de una entidad o de otra persona.",
          }}
        />
      </Col>
    </Row>
    <Col xs={"auto"} md={"auto"} lg={8}>
      <Form.Group>
        <Form.Label className="form__label">Nombre completo*</Form.Label>
        <Form.Control
          {...register("fullName")}
          placeholder={"Nombre completo"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.fullName
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.fullName?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">
          Tipo de documento de identidad*
        </Form.Label>
        <Form.Control
          {...register("documentTypeSignatory")}
          placeholder={"Tipo de documento de identidad"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentTypeSignatory
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.documentTypeSignatory?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Número de documento*</Form.Label>
        <Form.Control
          {...register("documentNumberSignatory")}
          placeholder={"Número de documento"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.documentNumberSignatory
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.documentNumberSignatory?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Correo electrónico</Form.Label>
        <Form.Control
          {...register("emailSignatory")}
          placeholder={"ejemplo@correo.com"}
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.emailSignatory
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.emailSignatory?.message}
        </div>
      </Form.Group>
    </Col>
    <Col xs={"auto"} md={"auto"} lg={4}>
      <Form.Group>
        <Form.Label className="form__label">Domicilio</Form.Label>
        <Form.Control
          {...register("countryAndCitySignatory")}
          className="label"
          placeholder="Ciudad"
          style={{
            border: "0.1rem solid #c5c7d0",
          }}
          bsPrefix={
            errors?.countryAndCitySignatory
              ? "input-group-container__no-icon-error label"
              : "input-group-container__no-icon label"
          }
          onBlur={handleSaveChanges}
        />
        <div className="caption custom-input__error">
          {errors?.countryAndCitySignatory?.message}
        </div>
      </Form.Group>
    </Col>
  </Row>
);

const BoxSignatoryNatural = ({
  fullName,
  documentType,
  documentNumber,
  countryAndCity,
}) => (
  <>
    <h1 className="home-init-cards-row__title-row">
      Así se verá la información en la sección de partes del documento:
    </h1>
    <Box style={{ backgroundColor: "#E6E9EF", padding: "2rem 2rem" }}>
      <p className="caption">
        <b>{fullName || "¬Nombre¬"} </b>quien se identifica con
        <b> {documentType || "¬tipo de documento¬"} </b>
        número <b> {documentNumber || "¬número de documento¬"} </b> y con
        domicilio en
        <b> {countryAndCity || "¬domicilio¬"}</b>
      </p>
    </Box>
  </>
);

const BoxSignatoryJuridica = ({
  fullName,
  documentType,
  documentNumber,
  countryAndCity,
  charge,
  companyName,
  documentTypeCompany,
  documentNumberCompany,
}) => (
  <>
    <h1 className="home-init-cards-row__title-row">
      Así se verá la información en la sección de partes del documento:
    </h1>
    <Box style={{ backgroundColor: "#E6E9EF", padding: "2rem 2rem" }}>
      <p className="caption">
        <b>{companyName || "¬razón social¬"}</b> que se identifica con{" "}
        <b>
          {documentTypeCompany || "¬tipo de documento¬"}{" "}
          {documentNumberCompany || "¬número de documento¬"}
        </b>{" "}
        con domicilio en <b>{countryAndCity || "¬domicilio¬"}</b> representada
        por <b>{fullName || "¬Nombre¬"}</b> quien se identifica con{" "}
        <b>{documentType || "¬tipo de documento¬"}</b> número{" "}
        <b>{documentNumber || "¬número de documento¬"}</b> y que actúa en
        calidad de <b>{charge || "¬cargo del firmante¬"}</b>
      </p>
    </Box>
  </>
);

const JumpLineDivisor = () => (
  <Row>
    <br />
  </Row>
);
