import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import {
  getUnitsCorporatives,
  getOneUnitCorporativeById,
  createUnitCorporative,
  updateUnitCorporative,
  addCorporateUnitToOwnerUser,
  getUnitCorporativeAllowedUserService,
} from "../../../../../services/admin/unitCorporativeService";
import { CODES } from "../../../../../utils/codes";

export const unitCorporativeSlice = createSlice({
  name: "unitsCoporatives",
  initialState: {
    unitsCoporativesList: [],
    unitCoporativeSelect: {},
    unitsCoporativesStatus: "fetch",
    unitsCoporativesError: null,
    unitsCoporativesStatusOne: "fetch",
    unitsCoporativesErrorOne: null,
    unitsCoporativesStatusAdd: "",
    unitsCoporativesErrorAdd: null,
    unitsCoporativesStatusModify: "",
    unitsCoporativesErrorModify: null,
    unitsCoporativesStatusAddOwner: "",
    unitsCoporativesErrorAddOwner: null,
    unitsCoporativeAllowedUsers: [],
    unitsCoporativesAllowedUserStatus: "fetch",
    unitsCoporativeAllowedUsersError: null,
  },
  reducers: {
    cleanStoreUnitCorporativeSlice(state) {
      state.unitCoporativeSelect = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitsCoporativesList.pending, (state) => {
        state.unitsCoporativesStatus = "loading";
      })
      .addCase(fetchUnitsCoporativesList.fulfilled, (state, action) => {
        state.unitsCoporativesStatus = "success";
        state.unitsCoporativesList = action.payload.data.responseMessage.data;
      })
      .addCase(fetchUnitsCoporativesList.rejected, (state, action) => {
        state.unitsCoporativesStatus = "failed";
        state.unitsCoporativesError = action.error.message;
      })

      .addCase(fetchUnitCorporativeAllowedUsers.pending, (state) => {
        state.unitsCoporativesAllowedUserStatus = "loading";
      })
      .addCase(fetchUnitCorporativeAllowedUsers.fulfilled, (state, action) => {
        state.unitsCoporativesAllowedUserStatus = "success";
        state.unitsCoporativeAllowedUsers =
          action.payload.data.responseMessage.data;
      })
      .addCase(fetchUnitCorporativeAllowedUsers.rejected, (state, action) => {
        state.unitsCoporativesAllowedUserStatus = "failed";
        state.unitsCoporativeAllowedUsersError = action.error.message;
      })

      .addCase(addUnitCorporative.pending, (state) => {
        state.unitsCoporativesStatusAdd = "loading";
      })
      .addCase(addUnitCorporative.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.unitsCoporativesStatusAdd = "success";
          state.unitsCoporativesList.push(
            action.payload.data.responseMessage.data
          );
        } else {
          state.unitsCoporativesStatusAdd = "error";
        }
      })
      .addCase(addUnitCorporative.rejected, (state, action) => {
        state.unitsCoporativesStatusAdd = "failed";
        state.unitsCoporativesErrorAdd = action.error.message;
      })
      .addCase(addCorporateUnitToOwner.pending, (state) => {
        state.unitsCoporativesStatusAddOwner = "loading";
      })
      .addCase(addCorporateUnitToOwner.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.unitsCoporativesStatusAddOwner = "success";
        } else {
          state.unitsCoporativesStatusAddOwner = "error";
        }
      })
      .addCase(addCorporateUnitToOwner.rejected, (state, action) => {
        state.unitsCoporativesStatusAddOwner = "failed";
        state.unitsCoporativesErrorAddOwner = action.error.message;
      })
      .addCase(modifyUnitCorporative.pending, (state) => {
        state.unitsCoporativesStatusModify = "loading";
      })
      .addCase(modifyUnitCorporative.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.unitsCoporativesStatusModify = "success";
          const update = state.unitsCoporativesList.map((unit) => {
            const { _id } = action.payload.data.responseMessage.data;
            if (unit._id === _id) {
              return action.payload.data.responseMessage.data;
            }
            return unit;
          });
          state.unitsCoporativesList = update;
        } else {
          state.unitsCoporativesStatusModify = "error";
        }
      })
      .addCase(modifyUnitCorporative.rejected, (state, action) => {
        state.unitsCoporativesStatusModify = "failed";
        state.unitsCoporativesErrorModify = action.error.message;
      })
      .addCase(fetchOneUnitCorporative.pending, (state) => {
        state.unitsCoporativesStatusOne = "loading";
      })
      .addCase(fetchOneUnitCorporative.fulfilled, (state, action) => {
        state.unitsCoporativesStatusOne = "success";
        state.unitCoporativeSelect = action.payload.data.responseMessage.data;
      })
      .addCase(fetchOneUnitCorporative.rejected, (state, action) => {
        state.unitsCoporativesStatusOne = "failed";
        state.unitsCoporativesErrorOne = action.error.message;
      })
      .addCase(setUnitCorporativeStatus, (state, action) => {
        state.unitCoporativeSelect = {};
        state.unitsCoporativesStatusOne = action.payload.status;
      })
      .addCase(setUnitCorporativeAllowedStatus, (state, action) => {
        state.unitsCoporativeAllowedUsers = [];
        state.unitsCoporativesAllowedUserStatus = action.payload.status;
      });
  },
});

export const getUnitsCorporativesList = (state) =>
  state.unitsCoporatives.unitsCoporativesList;

export const getUnitsCorporativesStatus = (state) =>
  state.unitsCoporatives.unitsCoporativesStatus;

export const getUnitsCoporativesStatusAdd = (state) =>
  state.unitsCoporatives.unitsCoporativesStatusAdd;

export const getUnitsCoporativesStatusModify = (state) =>
  state.unitsCoporatives.unitsCoporativesStatusModify;

export const getUnitsCorporativesStatusOne = (state) =>
  state.unitsCoporatives.unitsCoporativesStatusOne;

export const getUnitCorporativeById = (state) =>
  state.unitsCoporatives.unitCoporativeSelect;

export const getUnitCorporativeAllowedUsers = (state) =>
  state.unitsCoporatives.unitsCoporativeAllowedUsers;

export const getUnitCorporativeAllowedUserStatus = (state) =>
  state.unitsCoporatives.unitsCoporativesAllowedUserStatus;

export const setUnitCorporativeStatus = createAction(
  "unitsCoporatives/setUnitCorporativeStatus",
  () => ({
    payload: { status: "fetch" },
  })
);

export const setUnitCorporativeAllowedStatus = createAction(
  "unitsCoporatives/setUnitCorporativeAllowedStatus",
  () => ({
    payload: { status: "fetch" },
  })
);

export const fetchUnitsCoporativesList = createAsyncThunk(
  "unitsCoporatives/fetchUnitsCoporativesList",
  async () => {
    const response = await getUnitsCorporatives();
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export const addUnitCorporative = createAsyncThunk(
  "unitsCorporatives/addUnitsCorporatives",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createUnitCorporative(data);
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addCorporateUnitToOwner = createAsyncThunk(
  "unitsCorporatives/addCorporateUnitToOwnerUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addCorporateUnitToOwnerUser(data);
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const modifyUnitCorporative = createAsyncThunk(
  "unitsCorporatives/modifyUnitsCorporatives",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUnitCorporative(data);
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const fetchOneUnitCorporative = createAsyncThunk(
  "unitsCorporatives/getOneUnitCorporative",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getOneUnitCorporativeById(id);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const { cleanStoreUnitCorporativeSlice } = unitCorporativeSlice.actions;
export const fetchUnitCorporativeAllowedUsers = createAsyncThunk(
  "unitsCorporatives/getOneUnitCorporativeAllowedUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getUnitCorporativeAllowedUserService(data);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export default unitCorporativeSlice.reducer;
