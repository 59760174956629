import React from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { useNavigate } from "react-router-dom";

const ProfileAdmin = () => {
  const navigate = useNavigate();

  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <Container className="cards-edit">
            <Row className="cards-edit__header">
              <Col className="cards-edit__header__title">
                <p className="heading">Información básica</p>
              </Col>
              <Col className="cards-edit__header__icon">
                <img
                  className="clickeable"
                  src={require("../../../assets/svg/editIcon.svg").default}
                />
              </Col>
            </Row>
            <Row className="cards-edit__content">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  placeholder="Dígito de verificación*"
                  value={"Primer Nombre"}
                  disabled
                  bsPrefix="input-group-container__no-icon label custom-input__disabled"
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  placeholder="Dígito de verificación*"
                  value={"Segundo Nombre"}
                  disabled
                  bsPrefix="input-group-container__no-icon label custom-input__disabled"
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  placeholder="Dígito de verificación*"
                  value={"Primer apellido"}
                  disabled
                  bsPrefix="input-group-container__no-icon label custom-input__disabled"
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  placeholder="Dígito de verificación*"
                  value={"Segundo apellido"}
                  disabled
                  bsPrefix="input-group-container__no-icon label custom-input__disabled"
                />
              </Form.Group>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container className="cards-edit">
            <Row className="cards-edit__header">
              <Col className="cards-edit__header__title">
                <p className="heading">Correo</p>
              </Col>
              <Col className="cards-edit__header__icon">
                <img
                  className="clickeable"
                  src={require("../../../assets/svg/editIcon.svg").default}
                />
              </Col>
            </Row>
            <Row className="cards-edit__content">
              <Form.Group as={Col} className="mb-3">
                <InputGroup
                  bsPrefix={"input-group-container custom-input__disabled"}
                >
                  <InputGroup.Text bsPrefix="container-icon">
                    <AlternateEmailIcon fontSize="large" />
                  </InputGroup.Text>
                  <Form.Control
                    bsPrefix="custom-input"
                    disabled
                    placeholder="Correo electrónico"
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container className="cards-edit">
            <Row className="cards-edit__header">
              <Col className="cards-edit__header__title">
                <p className="heading">Contraseña</p>
              </Col>
              <Col className="cards-edit__header__icon">
                <img
                  className="clickeable"
                  src={require("../../../assets/svg/editIcon.svg").default}
                  onClick={() => navigate("/admin/changepassword")}
                />
              </Col>
            </Row>
            <Row className="cards-edit__content">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  placeholder="Dígito de verificación*"
                  value={"Contraseña"}
                  disabled
                  bsPrefix="input-group-container__no-icon label custom-input__disabled"
                />
              </Form.Group>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container className="cards-edit">
            <Row className="cards-edit__header">
              <Col className="cards-edit__header__title">
                <p className="heading">Teléfono</p>
              </Col>
              <Col className="cards-edit__header__icon">
                <img
                  className="clickeable"
                  src={require("../../../assets/svg/editIcon.svg").default}
                />
              </Col>
            </Row>
            <Row className="cards-edit__content">
              <Form.Group as={Col} className="mb-3">
                <InputGroup
                  bsPrefix={"input-group-container custom-input__disabled"}
                >
                  <InputGroup.Text bsPrefix="container-icon">
                    <PhoneAndroidIcon fontSize="large" />
                  </InputGroup.Text>
                  <Form.Control
                    bsPrefix="custom-input"
                    disabled
                    value={"Numero de contacto"}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileAdmin;
