import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";


export const getAllMinutas = () => {
    const companyId = localStorage.getItem('company')
    const corporateUnit = localStorage.getItem("corporateUnitId");
    return axios({
        method: "GET",
        url: 'contractsTypes/getAll',
        baseURL: env.REACT_APP_API_URL,
        validateStatus: null,
        params: {
            company: companyId,
            corporateUnit
        },
        headers: {
            Authorization: GetToken(),
        },
    });
}