import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Breadcrum from "../../../../../../parts/breadcrum";
import { getReports } from "../../../../../../parts/document/currentDocumentSlice";
import { REPORTS_TYPE } from "../../../../../../utils/codesReportsTypes";
import { ReportsMIlestionesAndDate } from "./types/reportsMilestionesAndDates";
import { ReportsObligations } from "./types/reportsObligations";
import { ReportsParts } from "./types/reportsParts";
import { ReportsPolicies } from "./types/reportsPolicies";
import { ReportsSummary } from "./types/reportsSummary";
import { ReportsValues } from "./types/reportsValues";

const ReportsService = () => {
  const reportsData = useSelector(getReports);

  const [value, setValue] = useState("1");
  const handleChange = (_, value) => {
    setValue(value);
  };
  return (
    <Container fluid className="custom-container-scroll">
      <br />
      <Row>
        <Breadcrum />
      </Row>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="label__primary-color"
          sx={{
            "& .MuiTab-root": {
              color: "#00374f !important",
              textTransform: "none !important",
              fontWeight: "400 !important",
              fontSize: "1.6rem !important",
            },
            "& .Mui-selected": {
              color: "#00374f !important",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#00374f !important",
            },
          }}
        >
          {reportsData?.length > 0 &&
            reportsData.map((item, index) => {
              switch (item.typeReport) {
                case REPORTS_TYPE.MILESTONES_AND_DATES.name:
                  return (
                    <Tab
                      key={REPORTS_TYPE.MILESTONES_AND_DATES._id}
                      label={REPORTS_TYPE.MILESTONES_AND_DATES.name}
                      value={`${index + 1}`}
                    />
                  );
                case REPORTS_TYPE.PARTS.name:
                  return (
                    <Tab
                      key={REPORTS_TYPE.PARTS._id}
                      label={REPORTS_TYPE.PARTS.name}
                      value={`${index + 1}`}
                    />
                  );
                case REPORTS_TYPE.VALUES.name:
                  return (
                    <Tab
                      key={REPORTS_TYPE.VALUES._id}
                      label={REPORTS_TYPE.VALUES.name}
                      value={`${index + 1}`}
                    />
                  );
                case REPORTS_TYPE.SUMMARY.name:
                  return (
                    <Tab
                      key={REPORTS_TYPE.SUMMARY._id}
                      label={REPORTS_TYPE.SUMMARY.name}
                      value={`${index + 1}`}
                    />
                  );
                case REPORTS_TYPE.POLICIES.name:
                  return (
                    <Tab
                      key={REPORTS_TYPE.POLICIES._id}
                      label={REPORTS_TYPE.POLICIES.name}
                      value={`${index + 1}`}
                    />
                  );
                case REPORTS_TYPE.OBLIGATIONS.name:
                  return (
                    <Tab
                      key={REPORTS_TYPE.OBLIGATIONS._id}
                      label={REPORTS_TYPE.OBLIGATIONS.name}
                      value={`${index + 1}`}
                    />
                  );
                default:
                  return (
                    <Tab
                      key={"default"}
                      label={"Seleccione"}
                      value={`${index + 1}`}
                    />
                  );
              }
            })}
        </Tabs>
        {reportsData?.length > 0 &&
          reportsData.map((item, index) => {
            switch (item.typeReport) {
              case REPORTS_TYPE.MILESTONES_AND_DATES.name:
                return (
                  <TabPanel
                    key={REPORTS_TYPE.MILESTONES_AND_DATES._id}
                    value={`${index + 1}`}
                  >
                    <ReportsMIlestionesAndDate type={item.typeReport} />
                  </TabPanel>
                );
              case REPORTS_TYPE.PARTS.name:
                return (
                  <TabPanel key={REPORTS_TYPE.PARTS._id} value={`${index + 1}`}>
                    <ReportsParts type={item.typeReport} />
                  </TabPanel>
                );
              case REPORTS_TYPE.VALUES.name:
                return (
                  <TabPanel
                    key={REPORTS_TYPE.VALUES._id}
                    value={`${index + 1}`}
                  >
                    <ReportsValues type={item.typeReport} />
                  </TabPanel>
                );
              case REPORTS_TYPE.SUMMARY.name:
                return (
                  <TabPanel
                    key={REPORTS_TYPE.SUMMARY._id}
                    value={`${index + 1}`}
                  >
                    <ReportsSummary type={item.typeReport} />
                  </TabPanel>
                );
              case REPORTS_TYPE.POLICIES.name:
                return (
                  <TabPanel
                    key={REPORTS_TYPE.POLICIES._id}
                    value={`${index + 1}`}
                  >
                    <ReportsPolicies type={item.typeReport} />
                  </TabPanel>
                );
              case REPORTS_TYPE.OBLIGATIONS.name:
                return (
                  <TabPanel
                    key={REPORTS_TYPE.OBLIGATIONS._id}
                    value={`${index + 1}`}
                  >
                    <ReportsObligations type={item.typeReport} />
                  </TabPanel>
                );
              default:
                return (
                  <TabPanel key={"default"} value={`${index + 1}`}>
                    Seleccione
                  </TabPanel>
                );
            }
          })}
      </TabContext>
    </Container>
  );
};

export default ReportsService;
