import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getTagsByCompanyAndResourceType,
  createTag,
  updateTag,
  deleteTag,
} from "../../services/tags/tagsServices";
import { CODES } from "../../utils/codes";

const folderTagSlice = createSlice({
  name: "folderTags",
  initialState: {
    folderTagsList: [],
    status: "fetch",
    statusCreate: "",
    statusUpdate: "",
    statusDelete: "",
    error: null,
  },
  reducers: {
    setStatusFolderFetch(state, action) {
      const status = action.payload.status;
      state.status = status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFolderTags.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFolderTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.folderTagsList = action.payload;
      })
      .addCase(fetchFolderTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createTags.pending, (state, action) => {
        state.statusCreate = "loading";
      })
      .addCase(createTags.fulfilled, (state, action) => {
        state.statusCreate = "succeeded";
        // Add any fetched posts to the array
        state.status = "fetch";
        if(action.payload.status === 201){
          state.folderTagsList = action.payload.dataToAdd;
        }
      })
      .addCase(createTags.rejected, (state, action) => {
        state.statusCreate = "failed";
        state.errorCreate = action.error.message;
      })
      .addCase(updateTagById.pending, (state, action) => {
        state.statusUpdate = "loading";
      })
      .addCase(updateTagById.fulfilled, (state, action) => {
        state.statusUpdate = "succeeded";
        // Add any fetched posts to the array
        state.status = "fetch";
      })
      .addCase(updateTagById.rejected, (state, action) => {
        state.statusUpdate = "failed";
        state.errorUpdate = action.error.message;
      })
      .addCase(deleteTagById.pending, (state, action) => {
        state.statusDelete = "loading";
      })
      .addCase(deleteTagById.fulfilled, (state, action) => {
        state.statusDelete = "succeeded";
        // Add any fetched posts to the array
        state.status = "fetch";
      })
      .addCase(deleteTagById.rejected, (state, action) => {
        state.statusDelete = "failed";
        state.errorDelete = action.error.message;
      });
  },
});

export const getFolderTagsList = (state) => state.folderTags.folderTagsList;

export const getFolderTagsStatus = (state) => state.folderTags.status;

export const getStatusCreate = (state) => state.tags.statusCreate;

export const getStatusUpdate = (state) => state.tags.statusUpdate;

export const getStatusDelete = (state) => state.tags.statusDelete;

export const { setStatusFolderFetch } = folderTagSlice.actions; 


export const fetchFolderTags = createAsyncThunk(
  "tags/fetchFolderTags",
  async (_, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        resourceType: "BUSINESSFOLDER",
      };
      const response = await getTagsByCompanyAndResourceType(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return response.data.responseMessage;
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createTags = createAsyncThunk("tags/createTag", async (data) => {
  const companyId = localStorage.getItem("company");
  let obj = {
    name: data.name,
    description: data.description,
    company: companyId,
    resourceType: data.resourceType,
  };
  const response = await createTag(obj);
  if (
    response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
    response.data.success
  ) {
    const companyId = localStorage.getItem("company");
    let obj = {
      companyId: companyId,
      resourceType: data.resourceType,
    };
    const responseFetch = await getTagsByCompanyAndResourceType(obj);
    return {
      dataToAdd: responseFetch.data.responseMessage,
      data: response.data,
      status: response.status,
    };
  }
  return {
    data: response.data,
    status: response.status
  }
});

export const updateTagById = createAsyncThunk(
  "tags/updateTagById",
  async (data) => {
    let obj = {
      id: data.id,
      updateLabel: data.updateLabel,
      resourceType: data.resourceType,
    };
    const response = await updateTag(obj);
    return {
      data: response.data,
      status: response.status,
    };
  }
);

export const deleteTagById = createAsyncThunk(
  "tags/deleteTag",
  async (obj) => {
    const responseDelete = await deleteTag(obj);
    return {
      data: responseDelete.data,
      status: responseDelete.status,
    };
  }
);

export default folderTagSlice.reducer;
