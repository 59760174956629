import axios from "axios";
import { env } from "../../env";
import GetToken from "../../utils/getToken";

export function getAlertsByFilter({
  attorneyId,
  expiredAlerts,
  activeAlerts,
  toActivateAlerts,
  corporateUnitId,
  companyId,
}) {
  return axios({
    method: "GET",
    url: `alerts/getAllAlertsHomeFilter`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      attorneyId,
      expiredAlerts,
      activeAlerts,
      toActivateAlerts,
      corporateUnitId,
      companyId,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getAlerts({ company, corporateUnit, filterMyId }) {
  return axios({
    method: "GET",
    url: `alerts/findAllAlerts`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      company,
      corporateUnit,
      filterMyId,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function alertCreate(data) {
  return axios({
    method: "POST",
    url: "alerts/createAlert",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
}

export function GetTasksFromAnAlert(data) {
  return axios({
    method: "GET",
    url: `alerts/findOneAlert`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      id: data.alertId,
      company: data.companyId,
      corporateUnit: data.corporateUnit,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function CreateTaskInAlert(data) {
  return axios({
    method: "PUT",
    url: `alerts/createTaskInAlert`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
}

export function getAlertById(data) {
  return axios({
    method: "GET",
    url: `/alerts/findOneAlert`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      id: data.id,
      company: data.company,
      corporateUnit: data.corporateUnit,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateAlert(data) {
  return axios({
    method: "PUT",
    url: `alerts/updateAlert/${data.id}`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export const DeleteTaskInAlert = (data) => {
  return axios({
    method: "PUT",
    url: `alerts/deleteTaskInAlert?alertId=${data.alertId}&taskId=${data.taskId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const createTask = (data) => {
  return axios({
    method: "POST",
    url: `alerts/createTask`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};

export const updateTask = (data) => {
  return axios({
    method: "PUT",
    url: `tasks/update/${data.id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};

export const alertStates = (data) => {
  return axios({
    method: "GET",
    url: `states/get/alertStates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};

export const updateAlertStates = ({ id, newStatus, isDeleteAllSerie }) => {
  return axios({
    method: "PUT",
    url: `alerts/updateDeletedAlert/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      state: newStatus,
      isDeleteAllSerie: isDeleteAllSerie || false,
    },
  });
};

export const findAllDeletedAlerts = ({ corporateUnit, company }) => {
  return axios({
    method: "GET",
    url: `alerts/findAllDeletedAlerts`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: {
      corporateUnit,
      company,
    },
  });
};

export const deleteAlert = ({ id, company }) => {
  return axios({
    method: "DELETE",
    url: `alerts/deleteAlert`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: { id, company },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const DeleteTaskPaperBasketInAlert = ({ id, newStatus }) => {
  return axios({
    method: "PUT",
    url: `tasks/updateDeletedTask/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      state: newStatus,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const findAllDeletedTasks = ({ corporateUnitId, companyId }) => {
  return axios({
    method: "GET",
    url: `tasks/getAllDeletedTasks`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: {
      corporateUnit: corporateUnitId,
      company: companyId,
    },
  });
};

export const updateDeletedTask = ({ id, newStatus }) => {
  return axios({
    method: "PUT",
    url: `tasks/updateDeletedTask/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      state: newStatus,
    },
  });
};
