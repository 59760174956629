export const DestinationTypeNotification = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
};

export const BandTypesNotification = {
    YEARS: 'years',
    MONTHS: 'months',
    WEEKS: 'weeks',
    DAYS: 'days',
    HOURS: 'hours',
    MINUTES: 'minutes',
};

// export class CreateTaskDto {
//     name: string;
//     assignedUsers: string[];
//     userEmail?: string;
//     alertAssociated?: string;
//     companyId?: string;
//     corporateUnitId?: string;
// };

// export class CreateNotificationDto {
//     destinationType: string;
//     destination: string[];
//     notificationDate: string;
// };

// export class BandsAlertDto {
//     numberBand?: number;
//     frequencyBand?: string;
// };

// export class CreateAlertModel {
//     name: string;
//     alertDate: string;
//     tasksDeadline: string;
//     documentAssociated: string;
//     tasks?: CreateTaskDto[]; // opcional
//     notifications?: CreateNotificationDto[]; // opcional
//     emails: string[];
//     description: string;
//     company: string;
//     corporateUnit?: string; // opcional
//     userEmail?: string; // opcional
//     createdBy?: string; // opcional
//     timeZone?: number; // opcional
//     isRepeated?: boolean; // opcional
//     frequencyRepeats?: BandsAlertDto;
//     durationRepeats?: BandsAlertDto;
//     milestone?: string; // opcional
//     clause?: string; // opcional
//     assignedTo?: string; // opcional
// };