import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CardSelectCorp = ({ description, icon, title }) => {
  return (
    <Container fluid className="informative-cards__medium">
      <Row style={{ height: "inherit" }}>
        <Col md={2} className="informative-cards__medium__icon-section">
          {icon}
        </Col>

        <Col md={10} className="informative-cards__body-section">
          <Container fluid>
            <Row>
              <p className="heading__primary-color">{title}</p>
            </Row>
            <Row>
              <p
                className="caption__justify"
                // onClick={() => navigate(`/service/${"navigateTo"}`)}
              >
                {description}
              </p>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default CardSelectCorp;
