import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./app/store";
import { Provider } from "react-redux";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { env } from "./env";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./app/error.boundary";

// Sentry configuration
if (env.REACT_APP_SENTRY_ENABLED?.toLowerCase() === "true") {
  const environment = env.REACT_APP_ENVIRONMENT ?? "development";
  const debug = environment === "development";

  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: environment,
    // Debugging for issues when sending events to Sentry
    debug: debug,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <GoogleReCaptchaProvider reCaptchaKey={env.REACT_APP_CAPTCHA}>
            <App />
          </GoogleReCaptchaProvider>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
