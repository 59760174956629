import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonCreate from "../../../components/Datatable/buttonCreate";
import ModalDecision from "../../../components/Modals/modalDecision";
import ModalInfo from "../../../components/Modals/modalInfo";
import CustomSearchFilter from "../../../components/Search/CustomSearchFilter";
import LoadingContent from "../../../components/loadingContent";
import SectionTitle from "../../../components/sectionTitle";
import { CODES } from "../../../utils/codes";
import Breadcrum from "../../breadcrum";
import {
  deleteTagDocumentTempletes,
  fetchTagsDocumentTempletes,
  getTagDocumentTempleteStatus,
  getTagDocumentTempletesList,
  getTagDocumentTempletesStatusDelete,
} from "./tagDocumentTempletesSlice";

export const ManageDocumentTempleteTags = () => {
  // REACT HOOCKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // USESTATES
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedTag, setDeletedTag] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [data, setData] = useState([]);
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  // USESELECTORS
  const statusTagsDocumentTempletes = useSelector(getTagDocumentTempleteStatus);
  const statusTagsDocumentTempletesDelete = useSelector(
    getTagDocumentTempletesStatusDelete
  );
  const listTagDocumentTempletes = useSelector(getTagDocumentTempletesList);

  // USEEFFECT
  useEffect(() => {
    const tags = buildRowsTags(listTagDocumentTempletes);
    setFilteredTags(tags);
    setData(tags);
  }, [listTagDocumentTempletes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsDocumentTempletes === "fetch") {
          dispatch(fetchTagsDocumentTempletes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsDocumentTempletes, dispatch]);

  useEffect(() => {
    if (statusTagsDocumentTempletes === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusTagsDocumentTempletes]);

  useEffect(() => {
    if (statusTagsDocumentTempletesDelete === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusTagsDocumentTempletesDelete]);

  // CUSTOM FUNCTIONS
  const buildRowsTags = (tags) => {
    if (typeof tags !== "string") {
      const format = tags?.map((tag) => ({
        name: tag.name,
        description: tag.description,
        edit: tag.company && (
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                className="custom-input__button__secondary-color"
                style={{
                  border: "1px solid #00374F",
                }}
                onClick={() => {
                  navigate(`${tag._id}`);
                }}
              >
                <p className="button">Editar</p>
              </Button>
            </Col>
            <Col>
              <Button
                variant="contained"
                startIcon={<DeleteOutlineOutlinedIcon />}
                className="custom-input__button__secondary-color"
                style={{
                  border: "1px solid #00374F",
                }}
                onClick={() => handleOpenModalDesicion(tag._id)}
              >
                <p className="button">Eliminar</p>
              </Button>
            </Col>
          </Row>
        ),
      }));
      return format;
    }
    return [];
  };

  // HANDLE FUNCTIONS
  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleCloseModalDesicion = async () => {
    setIsOpenModalDecision(false);
  };

  const handleOpenModalDesicion = async (id) => {
    setIsOpenModalDecision(true);
    setDeletedTag(id);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    setIsOpenModalDecision(false);
    handleDeleteTag();
  };

  const handleDeleteTag = async () => {
    try {
      setIsLoading(true);
      const deleteTagService = await dispatch(
        deleteTagDocumentTempletes(deletedTag)
      ).unwrap();

      if (deleteTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(deleteTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(deleteTagService);
      setIsOpenModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Administrar etiquetas"} />
        </Col>
      </Row>
      <br />
      <Row>
        <Container fluid>
          <Row style={{ marginTop: "3rem" }}>
            <Col sm={5}>
              <CustomSearchFilter
                placeholder="Buscar por etiqueta"
                list={data}
                setFilteredData={setFilteredTags}
              />
            </Col>
            <Col />
            <Col xs={"auto"}>
              <ButtonCreate path={"create"} value={"Agregar etiqueta"} />
            </Col>
          </Row>
          <Row style={{ marginBottom: "1%" }}></Row>
          <Row
            style={{
              marginTop: "3rem",
              padding: "2rem",
            }}
          >
            <div className="custom-accordion__container">
              {filteredTags?.length > 0 ? (
                filteredTags?.map((tag, index) => {
                  const CAN_EXPANDED =
                    tag?.description !== "" &&
                    tag?.description !== " " &&
                    undefined;

                  return (
                    <div key={index}>
                      <Accordion
                        className="custom-accordion"
                        expanded={CAN_EXPANDED}
                      >
                        <AccordionSummary
                          expandIcon={tag?.description && <ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            width: "100%",
                            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <Row style={{ width: "100%" }}>
                            <Col sm={6}>
                              <p className="heading__primary-color">
                                {tag.name}
                              </p>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "center",
                                }}
                              >
                                {tag.edit}
                              </div>
                            </Col>
                          </Row>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            width: "100%",
                            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <Typography>{tag?.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <Typography
                  style={{
                    fontSize: "15px", // DELETE: This is a inline style
                  }}
                >
                  No hay etiquetas
                </Typography>
              )}
            </div>
          </Row>
        </Container>
      </Row>

      <ModalInfo
        title={"Eliminar Etiqueta"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Eliminar etiqueta"}
        message={"¿Está seguro que quiere eliminar la etiqueta?"}
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default ManageDocumentTempleteTags;
