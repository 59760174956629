import { createSlice } from "@reduxjs/toolkit";

const otherDocumentSlice = createSlice({
  name: "otherDocument",
  initialState: {
    text: null,
    blankSpaces: [],
    name: null,
  },
  reducers: {
    setDocumentInfo: (state, action) => {
      const { text, blankSpaces, name } = action.payload;
      if (text !== undefined) state.text = text;
      if (blankSpaces !== undefined) state.blankSpaces = blankSpaces;
      if (name !== undefined) state.name = name;
    },
    resetOtherDocumentInfo: (state) => {
      state.text = null;
      state.blankSpaces = [];
      state.name = null;
    },
  },
});
// Acciones
export const { setDocumentInfo, resetOtherDocumentInfo } =
  otherDocumentSlice.actions;

// Selectores
export const getDocumentInfo = (state) => ({
  text: state.otherDocument.text,
  blankSpaces: state.otherDocument.blankSpaces,
  name: state.otherDocument.name,
});

export default otherDocumentSlice.reducer;
