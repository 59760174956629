import { createAction, createSlice } from "@reduxjs/toolkit";

import * as ReactDOMServer from "react-dom/server";
import ReactHtmlParser from "react-html-parser";
import {
  checkPartClause,
  checkText,
  countPartErrors,
  setPartsErrors,
} from "../../utils/partsCorrection";

/**
 * Este Slice emplea tanto reducers como extraReducers a modo de prueba para cambios de estado en el store.
 * IMPORTANTE: Ambos funcionan para el mismo propósito, se puede usar cualquiera de los dos.
 */
export const currentDocumentSlice = createSlice({
  name: "currentDocument",
  initialState: {
    currentDocument: {
      author: "",
      title: "",
      parts: [],
      clauses: [],
      name: "",
      headers: [],
      idDraft: "",
      versions: [],
      versionAssociated: "",
      filenameInBucket: "",
      bucketName: "",
      partsHeaderText: "",
      partsHeader: "",
      otherSignatories: [],
      additionalInfoOtherDocument: {},
    },
    minutaSelected: {},
    reports: [],
    reportSelected: null,
    statusRefNormative: "fetch",
    partsCorrection: false,
  },
  reducers: {
    setAuthor(state, action) {
      state.currentDocument.author = action.payload.author;
    },
    setPartsCorrection(state, action) {
      state.partsCorrection = action.payload;
    },

    setInitialInfoLibrary(state, action) {
      const { title, parts, fileType, letterheadAssociated } = action.payload;
      state.currentDocument.title = title;
      state.currentDocument.parts = parts;
      state.currentDocument.fileType = fileType;
      state.currentDocument.letterheadAssociated = letterheadAssociated;
    },

    setPartsHeaderText(state, action) {
      const { partsHeaderText } = action.payload;
      state.currentDocument.partsHeader = partsHeaderText;
    },

    setSelectedClauses(state, action) {
      const clauses = action.payload.clauses;
      state.currentDocument.clauses = clauses;
    },

    setSelectedClausesWithoutCheckPart(state, action) {
      const clauses = action.payload.clauses;
      state.currentDocument.clauses = clauses;
    },

    setUpdateClauseReview(state, action) {
      const newClauses = state.currentDocument.clauses.map((cla) => {
        if (cla._id === action.payload.id) {
          return action.payload.clause;
        } else {
          return cla;
        }
      });
      state.currentDocument.clauses = newClauses;
    },

    setNewClause(state, action) {
      const clauses = state.currentDocument.clauses;
      clauses.push(action.payload);
      if (state.partsCorrection) {
        state.currentDocument.clauses = checkPartClause({ clauses });
      } else {
        state.currentDocument.clauses = clauses;
      }
    },

    setUpdateClause(state, action) {
      const newClauses = state.currentDocument.clauses.map((cla) => {
        if (cla.clause._id === action.payload.id) {
          return { clause: action.payload.clause };
        } else {
          return cla;
        }
      });
      state.currentDocument.clauses = newClauses;
    },

    setUpdateClauseThisDocument(state, action) {
      const index = action.payload.index;
      const newClauses = action.payload.clauses;
      state.currentDocument.clauses[index].clause = newClauses;
    },

    setUpdateClauseIndex(state, action) {
      const index = action.payload.index;
      state.currentDocument.clauses[index] = action.payload.clause;
    },

    setName(state, action) {
      const name = action.payload.name;
      state.currentDocument.name = name;
    },

    setTitle(state, action) {
      const title = action.payload.title;
      state.currentDocument.title = title;
    },
    setInfavor(state, action) {
      const { inFavor, clauseId } = action.payload;
      state.currentDocument.clauses.forEach((cla) => {
        if (cla.clause._id === clauseId) {
          cla.clause.inFavor = inFavor;
        }
      });
    },
    setNamePartClause(state, action) {
      const { name, clauseId, index } = action.payload;
      state.currentDocument.clauses.forEach(({ clause }) => {
        if (clause._id === clauseId) {
          const partErrorsArray = [];
          clause.parts[index] = name;
          const parts = clause.parts;
          const newText = handleAddPartThisContractType(clause.text, parts);
          clause.text = newText;

          if (state.partsCorrection) {
            let errors = checkText(clause);
            partErrorsArray.push(...errors);
            let newTextClauseParts = setPartsErrors(clause, errors);
            clause.text = newTextClauseParts;
          }

          if (clause?.subclauses?.length) {
            clause.subclauses.forEach(({ subclause }) => {
              const newSubClauseText = handleAddPartThisContractType(
                subclause?.text,
                parts
              );
              subclause.text = newSubClauseText;

              if (state.partsCorrection) {
                const errorsSubclause = checkText(subclause);
                partErrorsArray.push(...errorsSubclause);
                const newTextSubclauseParts = setPartsErrors(
                  subclause,
                  errorsSubclause
                );
                subclause.text = newTextSubclauseParts;
              }

              if (subclause?.childs?.length) {
                subclause.childs.forEach((child) => {
                  const newChildText = handleAddPartThisContractType(
                    child?.text,
                    parts
                  );
                  child.text = newChildText;

                  if (state.partsCorrection) {
                    const errorsChild = checkText(child);
                    partErrorsArray.push(...errorsChild);
                    const newTextChildParts = setPartsErrors(
                      child,
                      errorsChild
                    );
                    child.text = newTextChildParts;
                  }
                });
              }
            });
          }
          if (clause?.paragraphs?.length) {
            clause.paragraphs.forEach(({ paragraph }) => {
              const newParagraphsText = handleAddPartThisContractType(
                paragraph?.text,
                parts
              );
              paragraph.text = newParagraphsText;

              if (state.partsCorrection) {
                const errorsParagraph = checkText(paragraph);
                partErrorsArray.push(...errorsParagraph);
                const newTextParagraphParts = setPartsErrors(
                  paragraph,
                  errorsParagraph
                );
                paragraph.text = newTextParagraphParts;
              }
            });
          }

          clause.partErrors = state.partsCorrection
            ? countPartErrors(partErrorsArray)
            : 0;
        }
      });
    },
    swapParts(state, action) {
      const { clauseId } = action.payload;

      state.currentDocument.clauses.forEach(({ clause }) => {
        if (clause._id === clauseId) {
          const partErrorsArray = [];
          const parts = [clause.parts[0], clause.parts[1]];
          const newTextClause = handleChangeCurrentParts(clause.text, parts);
          clause.text = newTextClause;

          if (state.partsCorrection) {
            let errors = checkText(clause);
            partErrorsArray.push(...errors);

            let newTextClauseParts = setPartsErrors(clause, errors);
            clause.text = newTextClauseParts;
          }

          if (clause?.subclauses?.length) {
            clause.subclauses.forEach(({ subclause }) => {
              const newSubClauseText = handleChangeCurrentParts(
                subclause?.text,
                parts
              );
              subclause.text = newSubClauseText;

              if (state.partsCorrection) {
                const errorsSubclause = checkText(subclause);
                partErrorsArray.push(...errorsSubclause);
                const newTextSubclauseParts = setPartsErrors(
                  subclause,
                  errorsSubclause
                );
                subclause.text = newTextSubclauseParts;
              }

              if (subclause?.childs?.length) {
                subclause.childs.forEach((child) => {
                  const newChildText = handleChangeCurrentParts(
                    child?.text,
                    parts
                  );
                  child.text = newChildText;

                  if (state.partsCorrection) {
                    const errorsChild = checkText(child);
                    partErrorsArray.push(...errorsChild);
                    const newTextChildParts = setPartsErrors(
                      child,
                      errorsChild
                    );

                    child.text = newTextChildParts;
                  }
                });
              }
            });
          }
          if (clause?.paragraphs?.length) {
            clause.paragraphs.forEach(({ paragraph }) => {
              const newParagraphsText = handleChangeCurrentParts(
                paragraph?.text,
                parts
              );
              paragraph.text = newParagraphsText;

              if (state.partsCorrection) {
                const errorsParagraph = checkText(paragraph);
                partErrorsArray.push(...errorsParagraph);
                const newTextParagraphParts = setPartsErrors(
                  paragraph,
                  errorsParagraph
                );
                paragraph.text = newTextParagraphParts;
              }
            });
          }

          clause.partErrors = state.partsCorrection
            ? countPartErrors(partErrorsArray)
            : 0;
        }
      });
    },
    swapParts2(state, action) {
      const { clauseId } = action.payload;
      state.currentDocument.clauses.forEach((cla) => {
        if (cla.clause._id === clauseId) {
          // Se revisa si el a favor tambien necesita ser intercambiado
          const validationInFavor = cla.clause.parts.indexOf(
            cla.clause.inFavor
          );
          if (validationInFavor !== -1) {
            cla.clause.inFavor =
              validationInFavor === 0
                ? cla.clause.parts[1]
                : cla.clause.parts[0];
          }
          // Se cambia la propiedad data-part-number para intercambiar el nombre de la parte en el texto
          const newText = handleChangePartsSwap(cla.clause.text);
          cla.clause.text = decodeHTMLChartoString(newText);

          cla?.clause?.subclauses?.forEach((sub) => {
            const newSubClause = handleChangePartsSwap(sub.subclause.text);
            sub.subclause.text = decodeHTMLChartoString(newSubClause);

            sub?.subclause?.childs?.forEach((child) => {
              const newSubClause = handleChangePartsSwap(child.text);
              child.text = decodeHTMLChartoString(newSubClause);
            });
          });

          cla?.clause?.paragraphs?.forEach((para) => {
            const newParagraphs = handleChangePartsSwap(para.paragraph.text);
            para.paragraph.text = decodeHTMLChartoString(newParagraphs);
          });
        }
      });
    },
    reorderParts(state, action) {
      const { source, destination } = action.payload.result;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }
      const parts = state.currentDocument.parts;
      const [removed] = parts.splice(source.index, 1);
      parts.splice(destination.index, 0, removed);
      state.currentDocument = { ...state.currentDocument, parts };
    },
    reorderPartsNoRol(state, action) {
      const { source, destination } = action.payload.result;
      const partIndex = action.payload.partIndex;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }
      const parts = state.currentDocument.parts[partIndex].signatories;
      const [removed] = parts.splice(source.index, 1);
      parts.splice(destination.index, 0, removed);
      state.currentDocument.parts[partIndex].signatories = parts;
    },
    reorderHeader(state, action) {
      const { source, destination } = action.payload.result;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }
      const headers = state.currentDocument.headers;
      const [removed] = headers.splice(source.index, 1);
      headers.splice(destination.index, 0, removed);
      state.currentDocument = { ...state.currentDocument, headers };
    },
    reorderClause(state, action) {
      const { source, destination } = action.payload.result;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }

      const clauses = state.currentDocument.clauses;
      const [removed] = clauses.splice(source.index, 1);
      clauses.splice(destination.index, 0, removed);
      state.currentDocument.clauses = clauses;
    },
    reorderSignatories(state, action) {
      const { source, destination, partIndex } = action.payload;

      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }
      const part = state.currentDocument.parts[partIndex];
      const [removed] = part.signatories.splice(source.index, 1);
      part.signatories.splice(destination.index, 0, removed);

      //Setteo a currentDocument
      state.currentDocument.parts[partIndex] = part;
    },

    setAllDocument(state, action) {},

    /**
     * Panel de partes
     */

    addNewPart(state, action) {
      const parts = state.currentDocument.parts;
      parts.push(action.payload);
      state.currentDocument.parts = parts;
    },

    addEditedPart(state, action) {
      const { index, editedPart } = action.payload;
      state.currentDocument.parts[index] = editedPart;
    },

    /**
     * Panel de firmas
     */

    addSignatureToPart(state, action) {
      const { indexPart, newSignatory } = action.payload;

      const part = state.currentDocument.parts[indexPart];

      let newSignatories = [];
      for (const [i] of part.signatories.entries()) {
        newSignatories.push(part.signatories[i]);
      }
      newSignatories.push(newSignatory);

      state.currentDocument.parts[indexPart] = {
        ...part,
        signatories: newSignatories,
      };
    },

    addNewSignatoriesToPart(state, action) {
      const { indexPart, newSignatory, index } = action.payload;

      console.log(indexPart);
      console.log(index);
      console.log(newSignatory);

      let role = state.currentDocument.parts[indexPart];

      let part = role?.signatories[index];
      let newSignatories = [];

      let foundSignatory = false;
      part?.signatories?.forEach((element) => {
        if (element._id === newSignatory._id) {
          foundSignatory = true;
        }
        newSignatories.push(element);
      });

      if (!foundSignatory) {
        newSignatories.push(newSignatory);
      }

      part.signatories = newSignatories;

      state.currentDocument.parts[indexPart].signatories[index] = part;
    },
    addEditedSignature(state, action) {
      const { partIndex, signatoryIndex, editedSignatory } = action.payload;

      const part = state.currentDocument.parts[partIndex];

      part.signatories.splice(signatoryIndex, 1, editedSignatory);

      state.currentDocument.parts[partIndex] = part;
    },

    addEditedSignatoryToPart(state, action) {
      const { partIndex, signatoryIndex, editedSignatory, roleIndex } =
        action.payload;

      const role = state.currentDocument.parts[roleIndex];

      const part = role.signatories[partIndex];

      part.signatories.splice(signatoryIndex, 1, editedSignatory);

      state.currentDocument.parts[roleIndex].signatories[partIndex] = part;
    },

    addNewPartWithSignatory(state, action) {
      const { partIndex, newPart } = action.payload;

      const part = state.currentDocument.parts[partIndex];
      if (
        !part?.signatories.find(
          (item) => item.information?._id === newPart?.information?._id
        )
      ) {
        part.signatories.push(newPart);
      }
      state.currentDocument.parts[partIndex] = part;
    },

    deletePartsList(state, action) {
      const { partIndex, deletedPart } = action.payload;

      const part = state.currentDocument.parts[partIndex];
      const newPartSignatories = part.signatories.filter(
        (obj) => obj.information?._id !== deletedPart?._id
      );

      part.signatories = newPartSignatories;

      state.currentDocument.parts[partIndex] = part;
    },

    deleteSignatory(state, action) {
      const { partIndex, signatoryIndex } = action.payload;

      const part = state.currentDocument.parts[partIndex];
      part.signatories.splice(signatoryIndex, 1);

      state.currentDocument.parts[partIndex] = part;
    },

    deleteSignatoryToPart(state, action) {
      const PERSON_TYPES = {
        JURIDICA: "Persona jurídica",
        NATURAL: "Persona natural",
        "Persona jurídica": "Persona jurídica",
        "Persona natural": "Persona natural",
      };

      const { partIndex, signatoryIndexToRol, signatoryIndex, isDefault } =
        action.payload;
      const part =
        state.currentDocument.parts[partIndex].signatories[signatoryIndexToRol];
      const { name, charge, documentType, documentNumber, address } =
        part.information;
      const signatories =
        state.currentDocument.parts[partIndex].signatories[signatoryIndexToRol]
          .signatories;
      signatories.splice(signatoryIndex, 1);
      state.currentDocument.parts[partIndex].signatories[
        signatoryIndexToRol
      ].signatories = signatories;

      let text = "";

      const personType = PERSON_TYPES[part?.type] || "Persona natural";

      if (personType === "Persona jurídica" && isDefault) {
        text = `¬Nombre¬ quien se identifica con ¬tipo de documento¬ número ¬número de documento¬ y que actúa en calidad de ${
          charge || "¬cargo del firmante¬"
        } de ${name || "¬razón social¬"}, que se identifica con ${
          documentType || "¬tipo de documento¬"
        } ${documentNumber || "¬número de documento¬"} y con domicilio en ${
          address || "¬domicilio¬"
        }`;

        text = ` ${name || "¬razón social¬"} que se identifica con ${
          documentType || "¬tipo de documento¬"
        } ${documentNumber || "¬número de documento¬"} con domicilio en ${
          address || "¬domicilio¬"
        } representada por ¬Nombre¬ quien se identifica con ¬tipo de documento¬ número ¬número de documento¬ y que actúa en calidad de ${
          charge || "¬cargo del firmante¬"
        }`;

        state.currentDocument.parts[partIndex].signatories[
          signatoryIndexToRol
        ].text = text;
      } else if (personType === "Persona natural" && isDefault) {
        text = `¬Nombre¬ quien se identifica con ¬tipo de documento¬ número ¬número de documento¬ y con domicilio en ¬domicilio¬`;
        state.currentDocument.parts[partIndex].signatories[
          signatoryIndexToRol
        ].information = {
          name: "Sin nombre",
        };
        state.currentDocument.parts[partIndex].signatories[
          signatoryIndexToRol
        ].text = text;
      }
    },

    //OTros documentos;

    setOtherDocInfo(state, action) {
      const additionalInfoOtherDocument = action.payload;
      state.currentDocument.additionalInfoOtherDocument =
        additionalInfoOtherDocument;
    },

    //Encabezados

    setHeaders(state, action) {
      const headers = action.payload;
      state.currentDocument.headers = headers;
    },

    setHeader(state, action) {
      state.currentDocument.headers.push(action.payload);
    },
    editHeader(state, action) {
      const { index, header } = action.payload;
      state.currentDocument.headers[index] = header;
    },
    deleteHeader(state, action) {
      state.currentDocument.headers.splice(action.payload.index, 1);
    },
    deletePart(state, action) {
      state.currentDocument.parts.splice(action.payload.index, 1);
    },
    deleteClause(state, action) {
      state.currentDocument.clauses.splice(action.payload.index, 1);
    },
    setIdDraft(state, action) {
      const id = action.payload.id;
      state.currentDocument.idDraft = id;
    },
    setIdMongo(state, action) {
      const id = action.payload.id;
      state.currentDocument._id = id;
    },
    setVersionAssociated(state, action) {
      const id = action.payload.id;
      state.currentDocument.versionAssociated = id;
    },
    setFileType(state, action) {
      const fileType = action.payload.fileType;
      state.currentDocument.fileType = fileType;
    },
    setVersions(state, action) {
      state.currentDocument.versions = action.payload;
    },
    deleteAdviceClause(state, action) {
      const index = action.payload.index;
      const newClause = action.payload.clause;
      state.currentDocument.clauses[index] = newClause;
    },
    cleanStore(state, action) {
      state.currentDocument = {
        author: "",
        title: "",
        parts: [],
        clauses: [],
        name: "",
        headers: [],
        idDraft: "",
        versions: [],
        otherSignatories: [],
      };
      state.statusRefNormative = "fetch";
    },

    /*
     Reports
    */

    setReports(state, action) {
      state.reports = action.payload.reports;
    },

    setSelectedReport(state, action) {
      state.reportSelected = action.payload.report;
    },
    //references normatives
    setReferenceNormative(state, action) {
      state.statusRefNormative = "find";
    },
    setReferenceNormativeClearStatus(state, action) {
      state.statusRefNormative = "fetch";
    },
    setInfoDocument(state, action) {
      const { filenameInBucket, bucketName } = action.payload;
      state.currentDocument.filenameInBucket = filenameInBucket;
      state.currentDocument.bucketName = bucketName;
    },
    addSignatoryWithoutPart(state, action) {
      state.currentDocument.otherSignatories = action.payload;
    },
    addEditedSignatoryWithoutPart(state, action) {
      const { partIndex, editedSignatory } = action.payload;

      if (state.currentDocument.otherSignatories[partIndex]) {
        const signatory = state.currentDocument.otherSignatories[partIndex];
        signatory.name = editedSignatory.name;
        signatory.signatories = editedSignatory.signatories;
        state.currentDocument.otherSignatories[partIndex] = signatory;
      } else if (
        editedSignatory?._id &&
        state.currentDocument.otherSignatories.find(
          (item) => item._id === editedSignatory?._id
        )
      ) {
        const signatories = state.currentDocument.otherSignatories;
        const signatoryIndex = signatories.findIndex(
          (signatory) => signatory._id === editedSignatory?._id
        );

        if (signatoryIndex !== -1) {
          signatories.splice(signatoryIndex, 1);

          signatories.splice(signatoryIndex, 0, editedSignatory);
        }
      } else {
        state.currentDocument.otherSignatories[partIndex] = editedSignatory;
      }
    },
    addEditedSignatoriesWithoutPart(state, action) {
      const { index, editedPart } = action.payload;
      state.currentDocument.otherSignatories[index] = editedPart;
    },
    deleteSignatoryWithoutPart(state, action) {
      const { partIndex } = action.payload;
      const parts = state.currentDocument.otherSignatories;
      parts.splice(partIndex, 1);
      state.currentDocument.otherSignatories = parts;
    },
    deleteSignatoryWithoutPartById(state, action) {
      const { signatoryId } = action.payload;
      const signatories = state.currentDocument.otherSignatories;
      const signatoryIndex = signatories.findIndex(
        (signatory) => signatory._id === signatoryId
      );

      if (signatoryIndex !== -1) {
        signatories.splice(signatoryIndex, 1);
        state.currentDocument.otherSignatories = signatories;
      }
    },

    deleteSignatoryWithPartById(state, action) {
      const { indexPart, signatoryId, index } = action.payload;

      let role = state.currentDocument.parts[indexPart];

      let part = role?.signatories[index];
      let newSignatories = part?.signatories?.filter(
        (element) => element._id === signatoryId
      );

      part.signatories = newSignatories;

      role.signatories[indexPart] = part;
    },
    reorderSignatoriesWithoutParts(state, action) {
      const { source, destination } = action.payload.result;
      if (!destination) {
        return;
      }
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      ) {
        return;
      }
      const otherSignatories = state.currentDocument.otherSignatories;
      const [removed] = otherSignatories.splice(source.index, 1);
      otherSignatories.splice(destination.index, 0, removed);
      state.currentDocument = { ...state.currentDocument, otherSignatories };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setMinuteSelected, (state, action) => {
        state.minutaSelected = action.payload.minute;
      })
      .addCase(setClauseSelected, (state, action) => {
        state.clauseSelected = action.payload.clause;
      })
      .addCase(setInitialDocumentInfo, (state, action) => {
        //Datos de documento
        state.currentDocument.title = action.payload.info.title;
        state.currentDocument.parts = action.payload.info.parts;
        state.currentDocument.clauses = state.minutaSelected.clauses;
      })
      .addCase(setClausesSelectedInMinute, (state) => {
        state.currentDocument.clauses = state.minutaSelected.clauses;
      });
  },
});

/**
 *  MINUTAS: Emplea EXTRAREDUCERS
 */

export const getMinutasList = (state) => state.minutas.minutasList;

export const getMinutasListStatus = (state) => state.minutas.status;

export const setMinuteSelected = createAction(
  "currentDocument/minutaSelected",
  (minute) => {
    return {
      payload: {
        minute,
      },
    };
  }
);

export const getMinutaSelected = (state) =>
  state.currentDocument.minutaSelected;

export const setClauseSelected = createAction(
  "currentDocument/clauseSelected",
  (clause) => {
    return {
      payload: {
        clause,
      },
    };
  }
);

export const getClauseSelected = (state) =>
  state.currentDocument.clauseSelected;

/**
 * LIBRARY: Emplea REDUCERS
 */

export const { setSelectedClauses } = currentDocumentSlice.actions;

export const { setSelectedClausesWithoutCheckPart } =
  currentDocumentSlice.actions;

/**
 * CURRENT DOCUMENT: Emplea tanto reducers como extraReducers
 */

export const getCurrentDocument = (state) =>
  state.currentDocument.currentDocument;

//Seteo desde minutas
export const setInitialDocumentInfo = createAction(
  "currentDocument/setInitialInfo",
  (info) => {
    return {
      payload: {
        info,
      },
    };
  }
);

export const setClausesSelectedInMinute = createAction(
  "currentDocument/setClausesSelectedInMinute"
);

//Setteo id borrador
export const { setIdDraft } = currentDocumentSlice.actions;
//Setteo id mongo
export const { setIdMongo } = currentDocumentSlice.actions;
//Setteo id padre borrador
export const { setVersionAssociated } = currentDocumentSlice.actions;
//Setteo tipo de documento
export const { setFileType } = currentDocumentSlice.actions;
//Setteo desde clausulas
export const { setInitialInfoLibrary } = currentDocumentSlice.actions;
//Setteo el texto final y general de las partes
export const { setPartsHeaderText } = currentDocumentSlice.actions;
//Setteo name desde modal de guardarc
export const { setName } = currentDocumentSlice.actions;
//Setteo title desde vista general
export const { setTitle } = currentDocumentSlice.actions;
//Reordenamiento de las partes
export const { reorderParts } = currentDocumentSlice.actions;
//Reordenamiento de las partes dentro del rol
export const { reorderPartsNoRol } = currentDocumentSlice.actions;
//Reordenamiento de las clausulas
export const { reorderClause } = currentDocumentSlice.actions;
//Intercambiar partes
export const { setNamePartClause } = currentDocumentSlice.actions;
//Intercambiar posiciones de las partes en el texto html
export const { swapParts } = currentDocumentSlice.actions;
//Setteo de a favor
export const { setInfavor } = currentDocumentSlice.actions;
//Agregar nueva clausulas
export const { setNewClause } = currentDocumentSlice.actions;
//Agregar nueva clausulas desde revisión
export const { setUpdateClauseReview } = currentDocumentSlice.actions;
//Actualizar una clausula
export const { setUpdateClause } = currentDocumentSlice.actions;
//Actualizar una clausula uso solo en este documento
export const { setUpdateClauseThisDocument } = currentDocumentSlice.actions;
//Actualizar una clausula uso solo en este documento cno un index
export const { setUpdateClauseIndex } = currentDocumentSlice.actions;
//Setteo nuevo encabezado
export const { setHeader } = currentDocumentSlice.actions;
//Setteo todos los encabezados
export const { setHeaders } = currentDocumentSlice.actions;
//Reordenamiento Encabezados
export const { reorderHeader } = currentDocumentSlice.actions;
//Editar un encabezado
export const { editHeader } = currentDocumentSlice.actions;
//Borrar un encabezado
export const { deleteHeader } = currentDocumentSlice.actions;
//Borrar una parte
export const { deletePart } = currentDocumentSlice.actions;
//Borrar una clausula
export const { deleteClause } = currentDocumentSlice.actions;
//Cargar versiones de un documento
export const { setVersions } = currentDocumentSlice.actions;
//Ignorar consejos de IA de una cláusula
export const { deleteAdviceClause } = currentDocumentSlice.actions;
//Limpiar Store
export const { cleanStore } = currentDocumentSlice.actions;
//Reports
export const { setReports } = currentDocumentSlice.actions;
export const { setSelectedReport } = currentDocumentSlice.actions;

export const { setPartsCorrection } = currentDocumentSlice.actions;

//Otros documentos
export const { setOtherDocInfo } = currentDocumentSlice.actions;

export const getPartsCorrection = (state) =>
  state.currentDocument.partsCorrection;
export const getReports = (state) => state.currentDocument.reports;
export const getReportSelected = (state) =>
  state.currentDocument.reportSelected;
//Reference Normatives
export const getReferenceNormativeStatus = (state) =>
  state.currentDocument.statusRefNormative;
export const { setReferenceNormative } = currentDocumentSlice.actions;
export const { setReferenceNormativeClearStatus } = currentDocumentSlice.actions;
//Info location document
export const { setInfoDocument } = currentDocumentSlice.actions;

const handleChangeCurrentParts = (textHtml, parts) => {
  if (!textHtml) return "";

  //Convertimos el string html a un Object Document
  const parser = new DOMParser();
  let htmlElement = parser.parseFromString(textHtml, "text/html");

  //Validamos que exista la data y guardamos el valor
  if (htmlElement?.querySelector(`[data-part-number="0"]`)) {
    const data = htmlElement?.querySelectorAll(`[data-part-number="0"]`);
    if (data?.length) {
      for (const item of data) {
        item.setAttribute("data-part-number", "x");
      }
    }
  }
  if (htmlElement?.querySelector(`[data-part-number="1"]`)) {
    const data = htmlElement?.querySelectorAll(`[data-part-number="1"]`);
    if (data?.length) {
      for (const item of data) {
        item.setAttribute("data-part-number", "y");
      }
    }
  }

  //Validamos que exista la data para cambiar su valor
  if (htmlElement?.querySelector(`[data-part-number="x"]`)) {
    const data = htmlElement?.querySelectorAll(`[data-part-number="x"]`);
    if (data?.length) {
      for (const item of data) {
        const part = parts[1];
        item.textContent = `°${part}°`;
        item.setAttribute("data-part", part);
        item.setAttribute("data-part-number", "1");
      }
    }
  }
  if (htmlElement?.querySelector(`[data-part-number="y"]`)) {
    const data = htmlElement?.querySelectorAll(`[data-part-number="y"]`);
    if (data?.length) {
      for (const item of data) {
        const part = parts[0];
        item.textContent = `°${part}°`;
        item.setAttribute("data-part", part);
        item.setAttribute("data-part-number", "0");
      }
    }
  }

  //Extraemos de Object Document el HTML String
  const stringifiedDocument = htmlElement.documentElement.outerHTML;

  //Limpiamos el HTML string para que no tenga etiquetas principales del html
  const cleanedString = stringifiedDocument
    .replace(/<\/?(html|head|body)>/gi, "")
    .replace(/style\s*=\s*['"]color:\s*red;['"]/gi, "")
    .replace(/<span\b(?![^>]*class=")[^>]*>(.*?)<\/span>/gi, "$1");

  return cleanedString;
};

export const handleAddPartThisContractType = (textHtml, parts) => {
  if (!textHtml) return "";

  //Convertimos el string html a un Object Document
  const parser = new DOMParser();
  let htmlElement = parser.parseFromString(textHtml, "text/html");

  //Validamos que exista la data para cambiar su valor
  for (let index = 0; index < 3; index++) {
    if (
      htmlElement?.querySelector(`[data-part-number="${index}"]`) &&
      parts[index]
    ) {
      const data = htmlElement?.querySelectorAll(
        `[data-part-number="${index}"]`
      );
      if (data?.length) {
        for (const item of data) {
          item.textContent = `°${parts[index]}°`;
          item.setAttribute("data-part", `°${parts[index]}°`);
        }
      }
    }
  }

  //Extraemos de Object Document el HTML String
  const stringifiedDocument = htmlElement.documentElement.outerHTML;

  //Limpiamos el HTML string para que no tenga etiquetas principales del html
  const cleanedString = stringifiedDocument
    .replace(/<\/?(html|head|body)>/gi, "")
    .replace(/style\s*=\s*['"]color:\s*red;['"]/gi, "")
    .replace(/<span\b(?![^>]*class=")[^>]*>(.*?)<\/span>/gi, "$1");

  return cleanedString;
};

export const handleChangePartsSwap = (data) => {
  const text = ReactHtmlParser(data);
  if (typeof text[0] === "string") {
    return data;
  }
  for (const item of text) {
    const children = item.props.children;
    if (children) {
      children.forEach((child, index) => {
        if (typeof child != "string") {
          const part = child.props["data-part-number"];
          if (part === "0") {
            children[index] = {
              ...child,
              props: { ...child.props, "data-part-number": "1" },
            };
          } else if (part === "1") {
            children[index] = {
              ...child,
              props: { ...child.props, "data-part-number": "0" },
            };
          }
        }
      });
    }
  }
  return text;
};

const decodeHTMLChartoString = (textEncoded) => {
  const htmlToString = ReactDOMServer.renderToStaticMarkup(textEncoded);
  let element = document.createElement("div");
  element.innerHTML = htmlToString;
  return element.innerHTML;
};

export const getCurrentDocumentParts = (state) =>
  state.currentDocument.currentDocument.parts;

export const { addNewPart } = currentDocumentSlice.actions;

export const { reorderSignatories, deleteSignatory, deleteSignatoryToPart } =
  currentDocumentSlice.actions;

export const { addEditedPart } = currentDocumentSlice.actions;

export const {
  addSignatureToPart,
  addEditedSignature,
  addNewPartWithSignatory,
  deletePartsList,
} = currentDocumentSlice.actions;

export const { setAuthor } = currentDocumentSlice.actions;

export const getCurrentSignatoriesWithoutPart = (state) =>
  state.currentDocument.currentDocument.otherSignatories;

export const {
  addSignatoryWithoutPart,
  addEditedSignatoryWithoutPart,
  addEditedSignatoriesWithoutPart,
  deleteSignatoryWithoutPart,
  deleteSignatoryWithPartById,
  reorderSignatoriesWithoutParts,
  deleteSignatoryWithoutPartById,
  addNewSignatoriesToPart,
  addEditedSignatoryToPart,
} = currentDocumentSlice.actions;

export default currentDocumentSlice.reducer;
