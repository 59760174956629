import { yupResolver } from "@hookform/resolvers/yup";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Button } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { ModalListSelectCheck } from "../../../../../../components/Modals/modalListCheck";
import SectionTitle from "../../../../../../components/sectionTitle";
import { env } from "../../../../../../env";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  deleteHeading,
  findOneHeading,
  updateHeading,
} from "../../../../../../services/admin/documents/headingsServices";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import { CODES } from "../../../../../../utils/codes";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../unitCorporativeSlice";

export default function EditHeadingsModule() {
  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;

  const ID_STATUS_DELETE = "621fdcd7e1b7dfa85f10bf46";

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    nameHeading: yup.string().required("*Este campo es obligatorio"),
  });

  const statusUnitsCorporativesList = useSelector(getUnitsCorporativesStatus);
  const unitsCorporativesList = useSelector(getUnitsCorporativesList);

  const [openModalNoSave, setOpenModalNoSave] = useState(false);
  const [openModalSelectCorporateUnits, setOpenModalSelectCorporateUnits] =
    useState(false);
  const [openModalDeleteHeading, setOpenModalDeleteHeading] = useState(false);
  const [corporateUnitsList, setCorporateUnitsList] = useState([]);
  const [currentSelectCorporateUnits, setCurrentSelectCorporateUnits] =
    useState([]);
  const [nameHeading, setNameHeading] = useState("");
  const [responseData, setResponseData] = useState({});
  const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCorporateUnits, setIsLoadingCorporateUnits] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSelectedCorporateUnits = (list) => {
    if (!list.length) return;
    setOpenModalSelectCorporateUnits(false);
    handleEditHeading({ name: nameHeading, unitsCorporativesList: list });
  };

  const handleEditHeading = async ({ name, unitsCorporativesList }) => {
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      const responseData = await updateHeading({
        name,
        applicableCorporateUnitIds: unitsCorporativesList.map((uc) => uc._id),
        companyId,
        letterheadId: id,
      });
      setIsOpenModalUpdate(true);
      setResponseData(responseData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveNameHeading = ({ nameHeading }) => {
    setNameHeading(nameHeading);
    if (unitsCorporativesList.length > 1) {
      setOpenModalSelectCorporateUnits(true);
    } else {
      handleEditHeading({ name: nameHeading, unitsCorporativesList });
    }
  };

  const handleCloseModal = () => {
    if (responseData.status === CODES.COD_RESPONSE_HTTP_OK) {
      navigate(-1);
    } else {
      setIsOpenModalUpdate(false);
      setIsOpenModalDelete(false);
    }
  };

  const handleAgreeDeleteHeading = async () => {
    try {
      setIsLoading(true);
      setOpenModalDeleteHeading(false);
      const companyId = localStorage.getItem("company");
      const responseData = await deleteHeading({
        letterheadId: id,
        companyId,
        status: ID_STATUS_DELETE,
      });
      setIsOpenModalDelete(true);
      setResponseData(responseData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem("company");
      const response = await findOneHeading({ letterheadId: id, companyId });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return response.data.responseMessage.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await fetchData();
        if (!data) return;
        let newUrl = null;
        let document = data.documentPdfId;
        let extension = document.filenameInBucket
          .split(".")
          .pop()
          .toLowerCase();
        const response = await getFileDocument(
          document.filenameInBucket,
          document.bucketName,
          false
        );
        if (extension !== "pdf") {
          return;
        }
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          const data = response?.data?.responseMessage?.buffer?.data;
          const newBlob = new Blob([new Uint8Array(data).buffer], {
            type: "application/pdf",
          });
          newUrl = window.URL.createObjectURL(newBlob);
        }
        setDocumentData(newUrl);
        setValue("nameHeading", data.name);
        setCurrentSelectCorporateUnits(data.applicableCorporateUnitIds);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusUnitsCorporativesList === "fetch") {
          dispatch(fetchUnitsCoporativesList());
        }
      } catch (error) {
        console.log("========Fetch Units Corporatives Error========");
        console.log(error);
        console.log("========Finish Fetch Units Corporatives Error========");
      }
    };
    fetchData();
  }, [statusUnitsCorporativesList, dispatch]);

  useEffect(() => {
    if (statusUnitsCorporativesList === "loading") {
      setIsLoadingCorporateUnits(true);
    } else {
      setIsLoadingCorporateUnits(false);
    }
  }, [statusUnitsCorporativesList]);

  useEffect(() => {
    setCorporateUnitsList(unitsCorporativesList);
  }, [unitsCorporativesList]);

  return (
    <Container fluid className="custom-container-scroll">
      {(isLoading || isLoadingCorporateUnits) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Editar membrete"} />
        </Col>
      </Row>
      <br />
      <Row>
        <label className="caption">
          Elige un nombre para el membrete y define su ubicación en la página.
        </label>
      </Row>
      <Form onSubmit={handleSubmit(handleSaveNameHeading)}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={"auto"}>
            <label className="label__description">Nombre del membrete:</label>
          </Col>
          <Form.Group as={Col} xs={4}>
            <Form.Control
              {...register("nameHeading")}
              placeholder={"Nombre de membrete"}
              bsPrefix={
                errors.nameHeading
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors.nameHeading?.message}
            </p>
          </Form.Group>
        </Row>
        <Row className="custom-accordion__container">
          {documentData && (
            <Worker workerUrl={WORKER_URL}>
              <Viewer fileUrl={documentData} />
            </Worker>
          )}
        </Row>
        <br />
        <Row style={{ rowGap: 20 }}>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              type="button"
              className="custom-input__button__secondary-color"
              endIcon={<DeleteOutlinedIcon fontSize="large" />}
              onClick={() => setOpenModalDeleteHeading(true)}
            >
              Eliminar membrete
            </Button>
          </Col>
          <Col></Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              type="button"
              className="custom-input__button__secondary-color"
              onClick={() => setOpenModalNoSave(true)}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              type="submit"
              className="custom-input__button__primary-color"
              endIcon={<SaveOutlinedIcon fontSize="large" />}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalDecision
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeDeleteHeading}
        handleDisagree={() => setOpenModalDeleteHeading(false)}
        message={"¿Está seguro de eliminar este membrete?"}
        onClose={() => setOpenModalDeleteHeading(false)}
        open={openModalDeleteHeading}
        title={"Eliminar membrete"}
      />
      <ModalDecision
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => navigate(-1)}
        handleDisagree={() => setOpenModalNoSave(false)}
        message={
          "¿Seguro quieres cancelar la personalización de membrete? Si aceptas la información no será guardada."
        }
        onClose={() => setOpenModalNoSave(false)}
        open={openModalNoSave}
        title={"Advertencia de no guardado"}
      />
      <ModalListSelectCheck
        title={"Aplicar membrete a unidades corporativas"}
        list={corporateUnitsList}
        open={openModalSelectCorporateUnits}
        handleAgree={handleSelectedCorporateUnits}
        onClose={() => setOpenModalSelectCorporateUnits(false)}
        currentDataSelected={currentSelectCorporateUnits}
        description={
          "A que unidades corporativas quieres aplicar este membrete"
        }
      />
      <ModalInfo
        title={`${
          responseData.status === CODES.COD_RESPONSE_HTTP_OK
            ? "Guardado exitoso"
            : "Error actualizando el membrete"
        }`}
        open={isOpenModalUpdate}
        confirmationText="Aceptar"
        onClose={handleCloseModal}
        handleCloseDefault={handleCloseModal}
        responseData={responseData}
        buttonClose={true}
      />
      <ModalInfo
        title={"Eliminar membrete"}
        open={isOpenModalDelete}
        confirmationText="Aceptar"
        onClose={handleCloseModal}
        handleCloseDefault={handleCloseModal}
        responseData={responseData}
        buttonClose={true}
      />
    </Container>
  );
}
