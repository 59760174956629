import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Container, Form, Row } from "react-bootstrap";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";

import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  addEditedPart,
  deleteSignatory,
  getCurrentDocumentParts,
  reorderSignatories,
} from "../../../../../../parts/document/currentDocumentSlice";
const SignaturesPanel = () => {
  /**
   * UseNavigate
   */

  const navigate = useNavigate();

  /**
   * Dispatch
   */

  const dispatch = useDispatch();
  /**
   * UseSelector
   */

  const parts = useSelector(getCurrentDocumentParts);

  /**
   * UseState
   */
  const [isEditTitlePart, setIsEditTitlePart] = useState(
    parts.map(() => false)
  );
  /**
   * Handles
   */

  const handleDeleteSignatory = (partIndex, signatoryIndex) => {
    dispatch(
      deleteSignatory({ partIndex: partIndex, signatoryIndex: signatoryIndex })
    );
  };

  return (
    <Container fluid style={{ height: "100%" }}>
      {/**Miga de pan */}
      <Row className="col-start" style={{ height: "7%" }}>
        <Breadcrum />
      </Row>
      {/**Título, campana */}
      <Row style={{ height: "10%" }}>
        <Col md={3} className="col-start">
          <SectionTitle title="Panel de firmas" />
        </Col>
        <Col md={8}></Col>
        <Col md={"auto"} className="col-flex">
          <IconButton size="large">
            <NotificationsOutlinedIcon
              className="heading__primary-color"
              fontSize="large"
            />
          </IconButton>
        </Col>
      </Row>

      {/**Texto de la página */}
      <Row style={{ height: "7%" }} className="text-description">
        Agrega y edita las firmas de las diferentes partes
      </Row>

      {/**Partes y sus signatarios */}

      <Row className="custom-accordion__container__small-2">
        {parts?.map((part, index) => (
          <Row key={JSON.stringify(part)}>
            <Container fluid>
              {index > 0 ? (
                <Row style={{ marginTop: "2%" }}>
                  <hr />
                </Row>
              ) : (
                <></>
              )}
              <Row className="title-part" style={{ marginTop: "1%" }}>
                <Col md={"auto"}>
                  {isEditTitlePart[index] ? (
                    <Form.Group
                      style={{ padding: "0%", margin: "2% 0" }}
                      onBlur={() => {
                        setIsEditTitlePart((prev) => {
                          const preList = prev.map((list, index2) => {
                            if (index2 === index) {
                              return false;
                            } else {
                              return list;
                            }
                          });
                          return preList;
                        });
                      }}
                    >
                      <Form.Control
                        placeholder={"Escriba aqui"}
                        bsPrefix={"input-group-container__no-icon label"}
                        value={part.name}
                        onChange={(e) => {
                          dispatch(
                            addEditedPart({
                              index: index,
                              editedPart: { ...part, name: e.target.value },
                            })
                          );
                        }}
                      />
                    </Form.Group>
                  ) : (
                    <p className="heading">{part.name}</p>
                  )}
                </Col>
                <Col md={"auto"}>
                  <IconButton
                    onClick={() => {
                      setIsEditTitlePart((prev) => {
                        const preList = prev.map((list, index2) => {
                          if (index2 === index) {
                            return true;
                          } else {
                            return list;
                          }
                        });
                        return preList;
                      });
                    }}
                  >
                    <ModeEditOutlineOutlinedIcon fontSize="large" />
                  </IconButton>
                </Col>
              </Row>
              <DragDropContext
                onDragEnd={(result) => {
                  const finalResult = { ...result, partIndex: index };
                  dispatch(reorderSignatories(finalResult));
                }}
              >
                <Droppable droppableId="signatories">
                  {(droppableProvided) => (
                    <Col
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {part.signatories.map((signatory, i) => (
                        <Draggable
                          key={"draggable" + signatory.name}
                          draggableId={"draggable" + signatory.name}
                          index={i}
                        >
                          {(draggableProvider) => (
                            <Row>
                              <Accordion
                                className="custom-accordion"
                                {...draggableProvider.draggableProps}
                                {...draggableProvider.dragHandleProps}
                                ref={draggableProvider.innerRef}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Row style={{ width: "100%" }}>
                                    <Col md={"auto"} className="col-start">
                                      <DragIndicatorIcon
                                        fontSize="large"
                                        className="drag-color"
                                      />
                                    </Col>
                                    <Col md={8} className="title-part">
                                      {signatory.name}
                                    </Col>
                                    <Col md={"auto"} className="cols-end">
                                      <Button
                                        className="button-softblue-folders"
                                        startIcon={
                                          <ModeEditOutlineOutlinedIcon fontSize="large" />
                                        }
                                        onClick={() => {
                                          navigate(`edit/${index}/${i}`);
                                        }}
                                      >
                                        Editar firma
                                      </Button>
                                    </Col>
                                    <Col md={"auto"}>
                                      <Button
                                        size="small"
                                        className="button-softblue-folders"
                                        onClick={() => {
                                          handleDeleteSignatory(index, i);
                                        }}
                                      >
                                        <DeleteOutlineOutlinedIcon fontSize="large" />
                                      </Button>
                                    </Col>
                                  </Row>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {signatory.signatures.attributes.length >
                                  0 ? (
                                    signatory.signatures.attributes.map(
                                      (signatureElement, signatureI) => (
                                        <div key={uuidv4()}>
                                          <Row>
                                            <Col
                                              className="caption__strong"
                                              md={"auto"}
                                            >
                                              {signatureElement.name}:
                                            </Col>
                                            <Col
                                              className="caption"
                                              md={"auto"}
                                            >
                                              {signatureElement.value}
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <Row className="caption__strong">
                                      El signatario no posee información de
                                      firma.
                                    </Row>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Row>
                          )}
                        </Draggable>
                      ))}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>
              <Row className="cols-end" style={{ height: "50px" }}>
                <Col md={2} className="cols-end">
                  <Button
                    className="button-softblue-folders"
                    startIcon={<AddIcon fontSize="large" />}
                    onClick={() => {
                      navigate(`create/${index}`);
                    }}
                  >
                    {" "}
                    Agregar firma
                  </Button>
                </Col>
              </Row>
            </Container>
          </Row>
        ))}
      </Row>

      <Row className="cols-end">
        <Col md={1}>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="button-softblue-folders"
          >
            Descartar
          </Button>
        </Col>
        <Col md={1}>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="button-primary-folders"
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SignaturesPanel;
