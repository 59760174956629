import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import SectionCardButtonWithNavButton from "../../../../../../components/Cards/SectionCardButtonWithNavButton";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

export default function ManageDocumentsModule () {
    return (<Container fluid className="custom-container-scroll">
        <Row>
            <Breadcrum />
        </Row>
        <br />
        <Row>
            <Col xs={"auto"}>
                <SectionTitle title={"Personalización de documentos"}/>
            </Col>
        </Row>
        <br />
        <Row>
            <label className="caption">Personaliza los membretes, logos  y la marca de aprobaciones de tus documentos.</label>
        </Row>
        <br />
        <Row style={{rowGap: 20}}>
            {/* <Col xs={12} sm={6} md={6} lg={4}>
                <SectionCardButtonWithNavButton
                    icon={<BusinessCenterIcon className="cards__icon-svg" />}
                    title="Aprobaciones"
                    description="Selecciona la ubicación de la marca de aprobaciones."
                    navigationButton={
                        <ArrowForwardIosOutlinedIcon className="cards__nav-button__icon" />
                    }
                    navigationPath="admin/unitsCorporatives/documents/manage-approvals"
                />
            </Col> */}
            <Col xs={12} sm={6} md={6} lg={4}>
                <SectionCardButtonWithNavButton
                    icon={<BusinessCenterIcon className="cards__icon-svg" />}
                    title="Membretes y logos"
                    description="Elige la marca o imagen a usar en los documentos"
                    navigationButton={
                        <ArrowForwardIosOutlinedIcon className="cards__nav-button__icon" />
                    }
                    navigationPath="admin/unitsCorporatives/documents/manage-headings"
                />
            </Col>
        </Row>
    </Container>)
}