import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { useSelector } from "react-redux";
import { getReports } from "../../../../../../../parts/document/currentDocumentSlice";
import { summaryCreator } from "../../../../../../../components/WordCreator/reportMilestonesDocx";
import {
  convertToPdfService,
  sendEmailDocument,
} from "../../../../../../../services/utils/convertWordToPdf";
import fileDownload from "js-file-download";
import { CODES } from "../../../../../../../utils/codes";
import LoadingContent from "../../../../../../../components/loadingContent";
import { saveAs } from "file-saver";
import ReactHtmlParser from "react-html-parser";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { reportIAResult } from "../../..../../../../../../../services/admin/manageUserService";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

const CURRENCY_SYMBOL = {
  'DÓLAR': 'USD',
  'DOLLAR': 'USD',
  'PESO': 'COP',
  'PESO COLOMBIANO': 'COP',
  'PESO MEXICANO': 'MX',
}

export const ReportsSummary = ({ type }) => {
  const MENU_FILTER_BY = [
    {
      title: "Formato Word",
      selectionClick: 0,
    },
    {
      title: "Formato PDF",
      selectionClick: 1,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const dataReports = useSelector(getReports);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [anchorFilterBy, setAnchorFilterBy] = useState(null);
  const [typeDownloadSelected, setTypeDownloadSelected] = useState(null);
  const [isOpenModalInputDownloadReport, setIsOpenModalInputDownloadReport] =
    useState(false);
  const [isOpenModalInputSendEmail, setIsOpenModalInputSendEmail] =
    useState(false);
  const [
    isOpenModalInputSendEmailResponse,
    setIsOpenModalInputSendEmailResponse,
  ] = useState(false);
  const [responseDataEmail, setResponseDataEmail] = useState("");
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [itemReport, setItemReport] = useState(null);
  const openFilterBy = Boolean(anchorFilterBy);
  const handleGenerateReport = async (name) => {
    try {
      setIsLoading(true);
      setIsOpenModalInputDownloadReport(false);
      setAnchorFilterBy(null);
      const typeDownload = typeDownloadSelected?.selectionClick;
      const doc = await summaryCreator({ title: name, reports: data }); //{ title: name, reports: data }
      if (doc) {
        if (typeDownload === 0) {
          saveAs(doc, `${name || "Resumen_contrato"}.docx`);
        } else if (typeDownload === 1) {
          const pdf = await convertToPdfService(doc);
          if ( pdf.status === CODES.COD_RESPONSE_HTTP_OK ) {
            const dataFile = pdf?.data?.responseMessage?.data;
            const file = new Uint8Array(dataFile?.buffer?.data).buffer;
            fileDownload(file, `${name || "Resumen_contrato"}.pdf`);
          }
        }
      }
    } catch (error) {
      console.log(
        "============ Start Error Download Report Summary ============"
      );
      console.error(error);
      console.log(
        "============ End Error Download Report Summary ============"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleSendEmail = async (email) => {
    try {
      setIsLoading(true);
      setIsOpenModalInputSendEmail(false);
      const doc = await summaryCreator({
        title: "Report_Contract",
        reports: data,
      });
      const filename = "Report_Contract";
      const responseSendEmail = await sendEmailDocument(doc, filename, email);
      if (
        responseSendEmail.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        responseSendEmail.data.success
      ) {
        setResponseDataEmail({
          status: responseSendEmail.status,
          data: {
            ...responseSendEmail.data,
            responseMessage: {
              ...responseSendEmail.data.responseMessage,
              message:
                "Se ha enviado el correo con el resumen de contrato correctamente.",
            },
          },
        });
        setIsOpenModalInputSendEmailResponse(true);
      }
    } catch (error) {
      console.log(
        "============ Start Error Send Email Report Summary PDF ============"
      );
      console.error(error);
      console.log(
        "============ End Error Send Email Report Summary PDF ============"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleInfoToSupport = async (reportMessage) => {
    let isInfoSentToSupport = false;
    try {
      setIsLoading(true);
      const reportInformation = {
        message: reportMessage,
        functionalityName: `${REPORTS_TYPE.SUMMARY.name}`,
        currentPathname: location.pathname,
      };
      const serviceResponse = await reportIAResult(reportInformation);
      if (serviceResponse) {
        if (serviceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          isInfoSentToSupport = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return isInfoSentToSupport;
  };
  const handleAgreeModalInputReport = async (data) => {
    if (data === "") return;
    if (itemReport) {
      const isInfoSentToSupport = await handleInfoToSupport(data);
      setIsOpenModalInputReport(!isInfoSentToSupport);
    }
  };
  useEffect(() => {
    if (type) {
      const reportsData = dataReports.find(
        (item) => item.typeReport === type
      ).reports;
      setData(reportsData);
      setFilteredData(reportsData);
    }
  }, [dataReports]);
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ alignItems: "center", justifyContent: "end" }}>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className={"custom-input__button__secondary-color"}
            onClick={() => setIsOpenModalInputSendEmail(true)}
          >
            <p className="button">Enviar por email</p>
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            endIcon={<ExpandMoreIcon />}
            className={"custom-input__button__secondary-color"}
            onClick={(e) => setAnchorFilterBy(e.currentTarget)}
          >
            <p className="button">Descargar resumen</p>
          </Button>
          <Menu
            id="menu-download-type"
            anchorEl={anchorFilterBy}
            open={openFilterBy}
            onClose={() => setAnchorFilterBy(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {MENU_FILTER_BY.map((option, index) => (
              <MenuItem
                key={uuidv4()}
                onClick={() => {
                  setTypeDownloadSelected(option);
                  setIsOpenModalInputDownloadReport(true);
                }}
              >
                <span className="body-content">{option.title}</span>
              </MenuItem>
            ))}
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Accordion className="custom-accordion" key={"accordion-summary-parts"}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="header-summary-parts"
          >
            <p className="heading__primary-color">Partes del contrato</p>
          </AccordionSummary>
          <AccordionDetails>
            {filteredData?.parts?.length > 0 ? (
              filteredData?.parts.map((report, index) => (
                <div key={uuidv4()}>
                  <Row>
                    <span className="heading__default">
                      {`${report?.name || report?.designation || ""}`}
                    </span>
                  </Row>
                  <br />
                  {report?.signatories &&
                    report?.signatories.map((sign, idxSign) => (
                      <div key={uuidv4()}>
                        <Row>
                          <span className="heading__default">
                            {`Signatario ${idxSign + 1}:`}
                          </span>
                        </Row>
                        <br />
                        {sign?.name && (
                          <Row>
                            <span className="subheading">
                              {"Nombre o razón social:  "}
                              <span className="caption">
                                {`${sign?.name || ""}`}
                              </span>
                            </span>
                          </Row>
                        )}
                        <br />
                        {sign?.id_number && (
                          <Row>
                            <span className="subheading">
                              {"Identificación:  "}
                              <span className="caption">
                                {`${sign?.id_number || ""}`}
                              </span>
                            </span>
                          </Row>
                        )}
                        <br />
                        {sign?.address && (
                          <Row>
                            <span className="subheading">
                              {"Domicilio:  "}
                              <span className="caption">
                                {`${sign?.address || ""}`}
                              </span>
                            </span>
                          </Row>
                        )}
                        <br />
                        <Row style={{ justifyContent: "end" }}>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<ErrorOutlineIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                setItemReport(sign);
                                setIsOpenModalInputReport(true);
                              }}
                            >
                              <p className="button">Reportar</p>
                            </Button>
                          </Col>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<RemoveRedEyeOutlinedIcon />}
                              className="custom-input__button__primary-color"
                              onClick={() => navigate(`viewContract`)}
                            >
                              <p className="button">Ver en contrato</p>
                            </Button>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <span className="caption">No identificado en el documento</span>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="custom-accordion"
          key={"accordion-summary-object"}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="header-summary-object"
          >
            <p className="heading__primary-color">Objeto del contrato</p>
          </AccordionSummary>
          {filteredData?.type_object && (
            <AccordionDetails>
              <Row>
                <span className="subheading">
                  {"Cláusula donde se encuentra:  "}
                  <span className="caption">
                    {`${filteredData?.type_object?.title || ""}`}
                  </span>
                </span>
              </Row>
              <br />
              <Row>
                <span className="caption">
                  {ReactHtmlParser(`${filteredData?.type_object?.text || ""}`)}
                </span>
              </Row>
              <br />
              <Row style={{ justifyContent: "end" }}>
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    startIcon={<ErrorOutlineIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      setItemReport(filteredData?.type_object);
                      setIsOpenModalInputReport(true);
                    }}
                  >
                    <p className="button">Reportar</p>
                  </Button>
                </Col>
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    className="custom-input__button__primary-color"
                    onClick={() =>
                      navigate(
                        `viewContract/#${filteredData?.type_object?.id_text}`
                      )
                    }
                  >
                    <p className="button">Ver en contrato</p>
                  </Button>
                </Col>
              </Row>
            </AccordionDetails>
          )}
        </Accordion>
        <Accordion
          className="custom-accordion"
          key={"accordion-summary-values"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="header-summary-values"
          >
            <p className="heading__primary-color">Valor del contrato</p>
          </AccordionSummary>
          <AccordionDetails>
            {filteredData?.type_clause_value ? (
              <>
                <Row>
                  <span className="subheading">
                    {"Cláusula donde se encuentra:  "}
                    <span className="caption">
                      {`${filteredData?.type_clause_value?.titleClause || ""}`}
                    </span>
                  </span>
                </Row>
                <br />
                <Row>
                  <span className="subheading">
                    {"Valor:  "}
                    <span className="caption">
                      {new Intl.NumberFormat('es', { style: 'currency', currency: CURRENCY_SYMBOL[filteredData?.type_clause_value?.currency?.toUpperCase()] || "COP" }).format(filteredData?.type_clause_value?.value)}
                    </span>
                  </span>
                </Row>
                <br />
                <Row>
                  <span className="subheading">
                    {"Ubicación en frase completa:  "}
                    <span className="caption">
                      ... {filteredData?.type_clause_value?.keyPhrase || ""} ...
                    </span>
                  </span>
                </Row>
                <Row style={{ justifyContent: "end" }}>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      startIcon={<ErrorOutlineIcon />}
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        setItemReport(filteredData?.type_clause_value);
                        setIsOpenModalInputReport(true);
                      }}
                    >
                      <p className="button">Reportar</p>
                    </Button>
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      startIcon={<RemoveRedEyeOutlinedIcon />}
                      className="custom-input__button__primary-color"
                      onClick={() =>
                        navigate(
                          `viewContract/#${filteredData?.type_clause_value?.id_text}`
                        )
                      }
                    >
                      <p className="button">Ver en contrato</p>
                    </Button>
                  </Col>
                </Row>
                <hr />
              </>
            ) : (
              <span className="caption">No identificado en el documento</span>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="custom-accordion"
          key={"accordion-summary-milestones"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="header-summary-milestones"
          >
            <p className="heading__primary-color">Termino de duración</p>
          </AccordionSummary>
          <AccordionDetails>
            {filteredData?.type_clause_milestone?.duration ? (
              <>
                <Row>
                  <span className="subheading">
                    {"Cláusula donde se encuentra:  "}
                    <span className="caption">
                      {filteredData?.type_clause_milestone?.duration
                        ?.titleClause || ""}
                    </span>
                  </span>
                </Row>
                <br />
                <Row>
                  <span className="subheading">
                    {"Fecha o Hito:  "}
                    <span className="caption">
                      {filteredData?.type_clause_milestone?.duration?.text ||
                        ""}
                    </span>
                  </span>
                </Row>
                <br />
                <Row>
                  <span className="subheading">
                    {"Ubicación en frase completa:  "}
                    <span className="caption">
                      ...{" "}
                      {filteredData?.type_clause_milestone?.duration
                        ?.keyPhrase || ""}{" "}
                      ...
                    </span>
                  </span>
                </Row>
                <br />
                <Row style={{ justifyContent: "end" }}>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      startIcon={<ErrorOutlineIcon />}
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        setItemReport(filteredData?.type_clause_milestone);
                        setIsOpenModalInputReport(true);
                      }}
                    >
                      <p className="button">Reportar</p>
                    </Button>
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      startIcon={<RemoveRedEyeOutlinedIcon />}
                      className="custom-input__button__primary-color"
                      onClick={() =>
                        navigate(
                          `viewContract/#${filteredData?.type_clause_milestone?.duration?.id_text}`
                        )
                      }
                    >
                      <p className="button">Ver en contrato</p>
                    </Button>
                  </Col>
                </Row>
                <hr />
              </>
            ) : (
              <span className="caption">No identificado en el documento</span>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="custom-accordion"
          key={"accordion-summary-milestones-termination"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="header-summary-milestones-termination"
          >
            <p className="heading__primary-color">Renovación y terminación</p>
          </AccordionSummary>
          <AccordionDetails>
            {filteredData?.type_clause_milestone?.termination?.length > 0 ? (
              filteredData?.type_clause_milestone?.termination?.map(
                (milestone) => (
                  <>
                    <Row>
                      <span className="subheading">
                        {"Cláusula donde se encuentra:  "}
                        <span className="caption">
                          {milestone?.titleClause || ""}
                        </span>
                      </span>
                    </Row>
                    <br />
                    <Row>
                      <span className="subheading">
                        {"Fecha o Hito:  "}
                        <span className="caption">{milestone?.text || ""}</span>
                      </span>
                    </Row>
                    <br />
                    <Row>
                      <span className="subheading">
                        {"Ubicación en frase completa:  "}
                        <span className="caption">
                          ... {milestone?.keyPhrase || ""} ...
                        </span>
                      </span>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "end" }}>
                      <Col xs={"auto"}>
                        <Button
                          variant="contained"
                          startIcon={<ErrorOutlineIcon />}
                          className="custom-input__button__secondary-color"
                          onClick={() => {
                            setItemReport(milestone);
                            setIsOpenModalInputReport(true);
                          }}
                        >
                          <p className="button">Reportar</p>
                        </Button>
                      </Col>
                      <Col xs={"auto"}>
                        <Button
                          variant="contained"
                          startIcon={<RemoveRedEyeOutlinedIcon />}
                          className="custom-input__button__primary-color"
                          onClick={() =>
                            navigate(`viewContract/#${milestone?.id_text}`)
                          }
                        >
                          <p className="button">Ver en contrato</p>
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                  </>
                )
              )
            ) : (
              <span className="caption">No identificado en el documento</span>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="custom-accordion"
          key={"accordion-summary-applicable-law"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="header-summary-applicable-law"
          >
            <p className="heading__primary-color">Ley aplicable al contrato</p>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              <span className="subheading">
                {"Cláusula donde se encuentra:  "}
                <span className="caption">
                  {filteredData?.type_applicable_law?.titleClause ||
                    "No identificado en el documento"}
                </span>
              </span>
            </Row>
            <br />
            <Row>
              <span className="subheading">
                {"Ley aplicable:  "}
                <span className="caption">
                  {filteredData?.type_applicable_law?.applicable_law ||
                    "No identificado en el documento"}
                </span>
              </span>
            </Row>
            <br />
            <Row>
              <span className="caption">
                {filteredData?.type_applicable_law?.text || ""}
              </span>
            </Row>
            <br />
            <Row style={{ justifyContent: "end" }}>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<ErrorOutlineIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setItemReport(filteredData?.type_applicable_law);
                    setIsOpenModalInputReport(true);
                  }}
                >
                  <p className="button">Reportar</p>
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  className="custom-input__button__primary-color"
                  onClick={() =>
                    navigate(
                      `viewContract/#${filteredData?.type_applicable_law?.id_text}`
                    )
                  }
                >
                  <p className="button">Ver en contrato</p>
                </Button>
              </Col>
            </Row>
            <hr />
          </AccordionDetails>
        </Accordion>
      </Row>
      <ModalInfo
        title={"Información"}
        open={isOpenModalInputSendEmailResponse}
        onClose={() => setIsOpenModalInputSendEmailResponse(false)}
        responseData={responseDataEmail}
        confirmationText={"Aceptar"}
      />
      <ModalInpuText
        title={"Generar reporte"}
        message={"Escriba el nombre que le desea agregar al documento."}
        agreeText={"Descargar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleGenerateReport}
        handleDisagree={() => {
          setIsOpenModalInputDownloadReport(false);
        }}
        onClose={() => setIsOpenModalInputDownloadReport(false)}
        open={isOpenModalInputDownloadReport}
      />
      <ModalInpuText
        title={"Enviar por email"}
        message={
          "Escribe el correo de la persona a quien vas a enviar el resumen del contrato."
        }
        agreeText={"Enviar"}
        placeholder={"Escriba el correo aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleSendEmail}
        handleDisagree={() => {
          setIsOpenModalInputSendEmail(false);
        }}
        onClose={() => setIsOpenModalInputSendEmail(false)}
        open={isOpenModalInputSendEmail}
      />
      <ModalInpuText
        title={"Reportar resumen"}
        message={"¿Deseas reportar el siguiente resumen?"}
        agreeText={"Aceptar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={() => {
          setItemReport(null);
          setIsOpenModalInputReport(false);
        }}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
    </Container>
  );
};
