import { Viewer, Worker } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import React, { useEffect } from "react";
import { env } from "../../env";
import useJumpToPagePlugin from "../../utils/plugins/useJumpToPagePlugin";
import LoadingContent from "../loadingContent";
import { Col, Row } from "react-bootstrap";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
export const DocumentViewer = ({
  documentPath,
  currentDocumentPage,
  isLoading,
  fileName,
}) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;

  const { jumpToPage, install } = useJumpToPagePlugin();

  const jumpToPagePluginInstance = {
    install,
    jumpToPage,
  };

  const handleDownloadDocument = () => {
    try {
      saveAs(documentPath, fileName || "documento");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentDocumentPage && currentDocumentPage > 0) {
      setTimeout(() => {
        jumpToPage(currentDocumentPage - 1);
      }, 500);
    }
  }, [currentDocumentPage, jumpToPage]);

  return (
    <Row className="caption h-100">
      {documentPath && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />

          <Col xs="auto ml-auto">
            <Button
              variant="contained"
              onClick={handleDownloadDocument}
              className={"custom-input__button__secondary-color"}
              startIcon={<DownloadIcon fontSize="large" />}
            >
              Descargar
            </Button>
          </Col>
        </div>
      )}
      <div className="custom-accordion__container-headers">
        {documentPath && (
          <Worker workerUrl={WORKER_URL}>
            <Viewer
              fileUrl={documentPath}
              plugins={[zoomPluginInstance, jumpToPagePluginInstance]}
              defaultScale={1.1}
            />
          </Worker>
        )}

        {isLoading && <LoadingContent />}
      </div>
    </Row>
  );
};
