// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable no-restricted-globals */
  self.addEventListener("message", async (event) => {
    const { paramsContract, serviceUrl, token } = event.data;

    const formData = new FormData();
    formData.append("file", paramsContract.file || null);
    formData.append("company", paramsContract.company);
    formData.append("bucketName", paramsContract.bucketName || "");
    formData.append("filenameInBucket", paramsContract.filenameInBucket || "");
    formData.append("from", paramsContract.from);
    formData.append("document", paramsContract.document || null);
    formData.append("reviewType", paramsContract.typeReview || "");

    try {
      const response = await fetch(
        `${serviceUrl}/documents/segmentDocxAndFunctionalitiesAI?reviewType=${paramsContract.typeReview}`,
        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(response);
      }

      const data = await response.json();
      self.postMessage({
        data: data,
        status: response.status,
        statusText: response.statusText,
      });
    } catch (error) {
      self.postMessage({
        data: {
          success: false,
          responseCode: 1001,
          responseMessage: {
            message: "Error segmentando el documento",
          },
        },
        status: error.response?.status || 500,
        statusText: error.response?.statusText || "Error",
      });
    }
    /* eslint-disable no-restricted-globals */
  });
};
