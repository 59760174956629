import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Breadcrum from "../../../../../parts/breadcrum";
import { useNavigate } from "react-router-dom";
import CustomSearch from "../../../../../components/Datatable/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import LoadingContent from "../../../../../components/loadingContent";

import {
  fetchCompanyUsersList,
  getCompanyUsersList,
  getCompanyUsersListStatus,
} from "./companyUsersSlice";
import { setClearCurrentUser } from "./currentUserSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../../../../../components/sectionTitle";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import { CustomToolTip } from "../../../../../components/Tooltip/Tooltip";
import { validateEnablePlan } from "../../../../../utils/getPlanInfo";
import { setStorageInfo } from "../../../../../parts/storage/storageSlice";

const ManageUserAdmin = () => {
  /**
   * Constantes
   */

  const translateSubRoles = {
    "ADMIN PROPIETARIO": "Usuario propietario",

    "ADMIN ADMINISTRADOR": "Usuario administrador",

    "SERVICE DIRECTOR": "Abogado Director",

    "SERVICE SENIOR": "Abogado Senior",

    "SERVICE JUNIOR": "Abogado Junior",

    "SERVICE PRACTICANTE": "Practicante",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalPlan, setIsOpenModalPlan] = useState(false);
  const [createUserDisabled, setCreateUserDisabled] = useState(false);

  const companyUsersList = useSelector(getCompanyUsersList);
  const companyUsersListStatus = useSelector(getCompanyUsersListStatus);

  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: [],
    rows: [],
  });

  const [dataTable, setDataTable] = useState({
    columns: [],
    rows: [],
  });
  const [companyPlanInfo, setCompanyPlanInfo] = useState(null);

  useEffect(() => {
    dispatch(setClearCurrentUser());
    const fetchInfoPlan = async () => {
      try {
        setIsLoading(true);
        const storageInfo = await validateEnablePlan();
        dispatch(setStorageInfo(storageInfo));
        setCompanyPlanInfo(storageInfo);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInfoPlan();
  }, []);

  useEffect(() => {
    if (companyPlanInfo) {
      if (!companyPlanInfo?.isUserEnable) {
        setIsOpenModalPlan(true);
        setCreateUserDisabled(true);
      }
    }
  }, [companyPlanInfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companyUsersListStatus === "fetch") {
          dispatch(fetchCompanyUsersList());
        }
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [companyUsersListStatus, dispatch]);

  useEffect(() => {
    if (companyUsersListStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [companyUsersListStatus]);

  useEffect(() => {
    setDataTable(buildDataTableInfo());
    setFilteredDataTable(buildDataTableInfo());
  }, [companyUsersList]);

  const buildFullname = (user) => {
    if (user?.personType === "Juridica")
      return user?.companies[0]?.companyData?.companyName;

    let firstName = user?.firstName || "";
    let secondName = user?.secondName || "";
    let firstSurname = user?.firstSurname || "";
    let secondSurname = user?.secondSurname || "";

    return `${firstName} ${secondName} ${firstSurname} ${secondSurname}`;
  };

  const getUserRole = (user) => {
    let role;
    if (user.companies[0].role.name === "SERVICE") {
      role = "de servicios";
    } else if (user.companies[0].role.name === "EXTERNAL") {
      role = "solicitante";
    } else {
      role = "administrador";
    }
    return role;
  };

  const buildDataTableInfo = () => {
    const columns = [
      {
        label: [
          <label aria-hidden="true" key="1">
            Nombre de usuario
          </label>,
        ],
        field: "userFullName",
      },
      {
        label: [
          <label aria-hidden="true" key="2">
            Tipo de usuario
          </label>,
        ],
        field: "role",
      },
      {
        label: [
          <label aria-hidden="true" key="2">
            Rol asignado
          </label>,
        ],
        field: "subRole",
      },
      {
        label: [
          <label aria-hidden="true" key="2">
            Unidades corporativas asociadas
          </label>,
        ],
        field: "corporateUnits",
      },
      {
        label: [
          <label aria-hidden="true" key="3">
            Acciones
          </label>,
        ],
        field: "edit",
      },
    ];

    //Cambiar la forma de validar llega String pero debería llegar []. ¡Buscar otra forma de validar!

    const rows =
      typeof companyUsersList !== "string"
        ? companyUsersList.map((user, idx) => {
            const units = user.corporateUnits ? user.corporateUnits : [];

            let rawUnits = "";
            for (let i = 0; i < units.length; i++) {
              if (i !== units.length - 1) {
                rawUnits += units[i].name + ", ";
              } else {
                rawUnits += units[i].name;
              }
            }
            const fullname = buildFullname(user);
            const role = `Usuario ${getUserRole(user)}`;
            return {
              userFullName: fullname,
              role: `Usuario ${getUserRole(user)}`,
              subRole:
                role === "Usuario solicitante"
                  ? "Usuario solicitante"
                  : translateSubRoles[user.subRole],
              corporateUnits: rawUnits,
              edit: (
                <Row xs={"auto"}>
                  <Col>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                      onClick={() => {
                        navigate(`edit/${idx}`);
                      }}
                    >
                      <EditOutlinedIcon fontSize="large" />
                    </Button>
                  </Col>
                </Row>
              ),
            };
          })
        : [];

    return { columns: columns, rows: rows };
  };

  return (
    <Container
      fluid
      style={{
        height: "100%",
      }}
      className="custom-container-scroll"
    >
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Administrar usuarios" />
        </Col>
      </Row>
      <Row style={{ height: "5%" }} className="text-description">
        Aquí está la lista de usuarios Legal AI. Añade y administra usuarios
        desde este punto de la plataforma.
      </Row>
      <Row>
        <Container fluid>
          <Row style={{ marginTop: "2%" }}>
            <Col sm={5}>
              <CustomSearch
                dataTableInfo={dataTable}
                setFilteredDataTable={setFilteredDataTable}
              />
            </Col>
            <Col sm={{ span: 2, offset: 5 }}>
              {createUserDisabled ? (
                <CustomToolTip
                  direction="left"
                  message="Has alcanzado el máximo de usuarios permitidos en tu plan"
                >
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    disabled
                  >
                    Añadir usuario
                  </Button>
                </CustomToolTip>
              ) : (
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    if (createUserDisabled) return;
                    navigate("/admin/manageUser/create");
                  }}
                >
                  Añadir usuario
                </Button>
              )}
              {/* <ButtonCreate path={"create"} value={"Añadir usuario"} /> */}
            </Col>
          </Row>
          <Row style={{ marginBottom: "1%" }}></Row>
          <Row>
            {
              <MDBDataTableV5
                noRecordsFoundLabel={"No se encuentran usuarios"}
                hover
                pagingTop
                searchBottom={false}
                entries={6}
                data={filteredDataTable}
                entriesLabel="haha"
                infoLabel={["Mostrando", "a", "de", "usuarios"]}
                fullPagination
              />
            }
          </Row>
        </Container>
      </Row>
      <ModalDecision
        title={"Máximo de usuarios creados"}
        agreeText={"Aceptar"}
        disagreeText={"Ver plan"}
        handleAgree={() => setIsOpenModalPlan(false)}
        handleDisagree={() => navigate("/admin/managePlan")}
        message={
          "Has creado el máximo de usuarios disponibles para tu plan activo"
        }
        onClose={() => setIsOpenModalPlan(false)}
        open={isOpenModalPlan}
        type={"warning"}
      />
    </Container>
  );
};

export default ManageUserAdmin;
