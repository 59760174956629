import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingContent from "../../../../../../components/loadingContent";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  Button,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { SectionMultipleTitle } from "../../../../../../components/sectionMultipleTitle";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PersonAddAlt1Outlined } from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { MDBDataTableV5 } from "mdbreact";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PopoverActions from "../../../../../../components/Popover/PopoverActions";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FilterTagParts from "../../../../../../parts/filters/FilterTagParts";
import FilterSigantory from "../../../../../../parts/filters/FilterSignatory";
import { onSortHTMLElement } from "../../../../../../utils/findComponentHTML";
import { CODES } from "../../../../../../utils/codes";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../admin/modules/unitCorporative/unitCorporativeSlice";
import { ModalListSelectCheck } from "../../../../../../components/Modals/modalListCheck";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CustomSearchFilterButton from "../../../../../../components/Search/CustomSearchFilterButton";
import {
  addCorporateUnitToParts,
  deleteTemporalPart,
  manageRelatedPartToSignatory,
  updatePart,
} from "../../../../../../services/parts/partsServices";
import {
  fetchTags,
  getTagList,
} from "../../../../../../parts/manageTags/tagSlice";
import { getStatus } from "../contractTypes/contractTypesSlice";
import { PARTS_LIBRARY_STATE } from "./utils";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import { PanelSignatories } from "./panels/PanelSignatories";
import {
  fetchPartsList,
  fetchSignatoriesList,
  getPartsList,
  getPartsListStatus,
  getSignatoriesList,
  getSignatoriesListStatus,
  resetPartListStatus,
  resetSignatoriesListStatus,
  setSelectedPart,
} from "../../../../../../parts/parts/partsSlice";
import { REGEXP } from "../../../../../../utils/regexp";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import { Buffer } from "buffer";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

import { ModalInfoSignatories } from "../../../../../../components/Modals/ModalInfoSignatories";
import { SIGNATORY_PROPERTIES_TRANSLATION } from "../../../../../../services/parts/consts/partsConsts";
export const Parts = () => {
  //States
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);

  //Router
  const navigate = useNavigate();
  const { state } = useLocation();
  //Const
  const options = [
    {
      label: "Partes",
      value: "0",
      content: <PanelParts navigate={navigate} setIsLoading={setIsLoading} />,
    },
    {
      label: "Firmantes",
      value: "1",
      content: <PanelSignatories setIsLoading={setIsLoading} setTab={setTab} />,
    },
  ];

  //UseEffect
  useEffect(() => {
    if (state?.signatory) {
      setTab("1");
    }
  }, [state]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <label className="home-init-cards-row__title-row">Biblioteca</label>
      </Row>
      <Row className="align-items-center">
        <Col xs={"auto"}>
          <SectionMultipleTitle options={options} setTab={setTab} tab={tab} />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<DeleteOutlineOutlinedIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => {
                navigate(`delete`);
              }}
            >
              <p className="button">Papelera</p>
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<SettingsOutlinedIcon />}
              className="custom-input__button__primary-color"
              onClick={() => navigate("tags?resource=PARTSLIBRARY")}
            >
              <p className="button">Administrar etiquetas</p>
            </Button>
          </Col>
        </Col>
        <Col xs={"auto"}></Col>
      </Row>
      <br />
      <Row className="custom-datatable__container data-table dt-list-items-change-last-child">
        {options[tab].content}
      </Row>
    </Container>
  );
};

const PanelParts = ({ navigate, setIsLoading }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const filterBy = searchParams.get("filterBy");
  const idPart = searchParams.get("id");

  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);

  const signatoriesList = useSelector(getSignatoriesList);
  const signatoriesListStatus = useSelector(getSignatoriesListStatus);

  const columns = [
    {
      label: [
        <label aria-hidden="true" key="0">
          Nombre
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Id
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Unidades corporativas
        </label>,
      ],
      field: "corporateUnits",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Info firmantes
        </label>,
      ],
      field: "actions",
    },
  ];

  const [data, setData] = useState({ rows: [], columns: [] });
  const [filteredData, setFilteredData] = useState({ rows: [], columns: [] });
  const [tagsSelected, setTagsSelected] = useState([]);
  const [signatoriesSelected, setSignatoriesSelected] = useState([]);
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const [unitCorporativeSelected, setUnitCorporativeSelected] = useState([]);
  const [isOpenModalSelectUC, setIsOpenModalSelectUC] = useState(false);
  const [anchorSelected, setAnchorSelected] = useState(null);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingFetchUC, setIsLoadingFetchUCl] = useState(false);
  const [isLoadingAllTags, setIsLoadingAllTags] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [isOpenModalSignatoriesInfo, setIsOpenModalSignatoriesInfo] =
    useState(false);
  const [dataSignatoryModal, setDataSignatoryModal] = useState(null);
  const [partSelected, setPartSelected] = useState(null);
  const [isOpenModalDecisionDeletePart, setIsOpenModalDecisionDeletePart] =
    useState(false);
  const openSelected = Boolean(anchorSelected);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreateMenu = Boolean(anchorElCreate);
  const partsList = useSelector(getPartsList);
  const partsListStatus = useSelector(getPartsListStatus);
  const [isLoadingFetchSignatories, setIsLoadingFetchSignatories] =
    useState(false);

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleSignatoriesSelected = (signatories) => {
    setSignatoriesSelected(signatories);
    setTagsSelected([]);
  };

  const handleDeleteTag = (tag) => {
    const newTags = tagsSelected.filter((t) => t._id !== tag._id);
    setTagsSelected(newTags);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      const selectedList = newTags.map((item) => item._id);
      filteredData.rows.forEach((item) => {
        const labelsList = item.labels;
        filterTags = labelsList.some((tag) => selectedList.includes(tag._id));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });

      setFilteredData({ ...filteredData, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredData(data);
    }
  };

  const handleDeleteSignatorySelected = (signatory) => {
    const newSigantories = signatoriesSelected.filter(
      (t) => t._id !== signatory
    );
    setSignatoriesSelected(newSigantories);
    if (newSigantories.length > 0) {
      let filteredSignatories = [];
      const selectedList = newSigantories.map((item) => item._id);
      filteredData.rows.forEach((item) => {
        const list = item.relatedSignatories;
        const filterList = list.some((item) => selectedList.includes(item._id));
        if (filterList) {
          filteredSignatories.push(item);
        }
      });

      setFilteredData({ ...filteredData, rows: filteredSignatories });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredData(data);
    }
  };

  const handleDeleteCorporateUnit = async ({ id, element }) => {
    try {
      setIsLoadingAll(true);
      const newCorporateUnitsRelated = element.associatedCorporateUnits
        .filter((uc) => uc._id !== id)
        .map((uc) => uc?._id);
      const company = localStorage.getItem("company");

      const obj = {
        company,
        partId: element?._id,
        associatedCorporateUnits: newCorporateUnitsRelated,
        actionToPart: "DELETE",
        relatedSignatories: element.relatedSignatories.map((rs) => ({
          ...rs,
          signatory: rs.signatory._id,
        })),
      };
      const obj2 = {
        company,
        partId: element?._id,
        associatedCorporateUnits: [id],
        actionToPart: "DELETE",
      };

      const [responseDataCorporateUnit, responseDataPart] = await Promise.all([
        addCorporateUnitToParts(obj2),
        updatePart(obj),
      ]);
      setIsOpenModalResponse(true);
      setResponseData(responseDataCorporateUnit);
      if (
        responseDataCorporateUnit.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataPart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataCorporateUnit.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS &&
        responseDataPart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        dispatch(resetPartListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleDeletePart = async (item) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalDecisionDeletePart(false);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const newStatusId = PARTS_LIBRARY_STATE.DELETED._id;
      const responseDeletePart = await deleteTemporalPart({
        company,
        partId: item?._id,
        status: newStatusId,
        modifiedBy: userId,
        associatedCorporateUnits: item?.associatedCorporateUnits,
      });
      setIsOpenModalResponse(true);
      setResponseData(responseDeletePart);
      if (
        responseDeletePart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeletePart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        if (item.relatedSignatories?.length > 0) {
          const transformedArrayForUpdate = item.relatedSignatories.map(
            (signatory) => {
              return {
                part: item?._id,
                signatory: signatory.signatory._id,
                position: signatory.position || "Apoderado",
              };
            }
          );
          const serviceRemovePartsToSignatories =
            await manageRelatedPartToSignatory({
              managePartToSignatories: transformedArrayForUpdate,
              action: "DELETE",
            });
          if (
            serviceRemovePartsToSignatories.status ===
              CODES.COD_RESPONSE_HTTP_OK &&
            serviceRemovePartsToSignatories?.data?.responseCode ===
              CODES.COD_RESPONSE_SUCCESS
          ) {
            setResponseData({
              ...responseDeletePart,
              data: {
                message: "La parte ha sido eliminada correctamente.",
              },
            });
          }
        }
        setResponseData({
          ...responseDeletePart,
          data: {
            message: "La parte ha sido eliminada correctamente.",
          },
        });
        dispatch(resetPartListStatus());
        dispatch(resetSignatoriesListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleOpenDocument = async (actualDocument) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(
        actualDocument.filenameInBucket,
        actualDocument.bucketName,
        false
      );
      setIsLoading(false);
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const finalFile = URL.createObjectURL(
        new Blob([bf], {
          type: "application/pdf",
        })
      );
      window.open(finalFile);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedViewDetails = (item) => {
    setAnchorSelected(null);
    switch (item) {
      case 0:
        if (partSelected.typePart === "JURIDICA") {
          navigate(`update/legal/${partSelected._id}`);
        } else {
          navigate(`update/natural/${partSelected._id}`);
        }

        break;
      case 1:
        setIsOpenModalDecisionDeletePart(true);
        break;
      case 2:
        setOpenDetailsDrawner(true);
        break;
      case 3:
        handleOpenDocument(partSelected);
        break;
      default:
        break;
    }
  };

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;

    //Elimina dobles espacios en blanco
    return fullName.replace(/  +/g, " ");
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      let fullName = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      let contact = `${item?.email || ""}`;
      let address = item?.physicalAddress;
      let country = item?.country;
      let city = `${item?.province || ""}, ${item?.city || ""}`;
      let number = `+${item?.countryCodeNumber || ""} ${item?.phone || ""}`;
      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;
      if (item?.typePart === "JURIDICA") {
        fullName = item?.business?.businessName;
        id = `Nit. ${item?.business?.nit}`;
      }
      formatedData.push({
        name: (
          <Row>
            {moment(item.createdAt).isAfter(moment().subtract(6, "hours")) && (
              <Col xs={6} className="mb-2">
                <span className="tag__success p-1">Nuevo</span>
              </Col>
            )}

            <Col xs={12}>{fullName}</Col>
          </Row>
        ),
        id,
        fullName,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>

                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),
        corporateUnits: (
          <Row style={{ rowGap: 5, alignItems: "center" }}>
            {item?.associatedCorporateUnits?.length > 0 &&
              item.associatedCorporateUnits.map(({ _id, name }) => (
                <Col key={_id} xs={12} lg={6}>
                  <span
                    style={{ cursor: "pointer", display: "inline-block" }}
                    className="caption custom-badges__secundary"
                    onClick={() =>
                      handleDeleteCorporateUnit({ id: _id, element: item })
                    }
                  >
                    {name + " "}{" "}
                    <ClearIcon className="custom-badges__delete-button" />
                  </span>
                </Col>
              ))}
            <Col xs={12} lg={6}>
              <span
                style={{ cursor: "pointer" }}
                className="caption custom-badges__disabled"
                onClick={() => handleOpenModalListCorporateUnits(item)}
              >
                Agregar
                <AddOutlinedIcon
                  fontSize="large"
                  className="custom-badges__delete-button"
                />
              </span>
            </Col>
          </Row>
        ),
        actions: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setPartSelected(item);
                  setIsOpenModalSignatoriesInfo(true);
                  setDataSignatoryModal(item);
                }}
                disabled={!item?.relatedSignatories.length}
              >
                <LaunchOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
            <Col xs={"auto"}>
              <IconButton
                onClick={(event) => {
                  setAnchorSelected(event.currentTarget);
                  setPartSelected(item);
                  dispatch(setSelectedPart(item));
                }}
              >
                <MoreHorizIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
        ),
        ...item,
      });
    }
    return formatedData;
  };

  const filterByCorporateUnit = ({ id, list }) => {
    if (!list.length || !id) return [];
    return list.filter((part) =>
      part?.associatedCorporateUnits.some((uc) => uc._id === id)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = [];
        if (partsListStatus === "fetch") {
          dispatch(fetchPartsList());
        }
        data = partsList;
        if (
          filterBy === "CORPORATE_UNIT" &&
          idPart.match(REGEXP.MONGO_OBJECT_ID)
        ) {
          data = filterByCorporateUnit({ id: idPart, list: partsList });
        }
        const tranformData = buildData(data);
        setData({ rows: tranformData, columns });
        setFilteredData({ rows: tranformData, columns });
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [partsList, partsListStatus, dispatch]);

  const handleOpenModalListCorporateUnits = (info) => {
    setUnitCorporativeSelected(info.associatedCorporateUnits);
    setPartSelected(info);
    setIsOpenModalSelectUC(true);
  };

  const handleAddCorporateUnit = async (data) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalSelectUC(false);
      const company = localStorage.getItem("company");
      const corporateUnitsSelected = data.map((uc) => uc?._id);
      const currentCorporateUnits = corporateUnitsSelected.filter(
        (newUc) =>
          !partSelected.associatedCorporateUnits.some((uc) => uc?._id === newUc)
      );
      const obj = {
        company,
        partId: partSelected?._id,
        associatedCorporateUnits: currentCorporateUnits,
        actionToPart: "ADD",
        relatedSignatories: partSelected.relatedSignatories.map((rs) => ({
          ...rs,
          signatory: rs.signatory._id,
        })),
      };
      if (!currentCorporateUnits.length) return;
      const [responseDataCorporateUnit, responseDataPart] = await Promise.all([
        addCorporateUnitToParts(obj),
        updatePart({
          ...obj,
          associatedCorporateUnits: corporateUnitsSelected,
        }),
      ]);
      setIsOpenModalResponse(true);
      setResponseData(responseDataCorporateUnit);
      if (
        responseDataCorporateUnit.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataPart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataCorporateUnit.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS &&
        responseDataPart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        dispatch(resetPartListStatus());
        dispatch(resetSignatoriesListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
      setPartSelected(null);
    }
  };

  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
    if (statusFetchUnits === "loading") {
      setIsLoadingFetchUCl(true);
    } else {
      setIsLoadingFetchUCl(false);
    }
  }, [statusFetchUnits]);

  useEffect(() => {
    if (
      isLoadingFetchUC ||
      isLoadingAll ||
      isLoadingAllTags ||
      isLoadingFetchSignatories
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingFetchUC, isLoadingAll, isLoadingFetchSignatories]);

  useEffect(() => {
    if (statusTags === "loading") {
      setIsLoadingAllTags(true);
    } else {
      setIsLoadingAllTags(false);
    }
  }, [statusTags]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("PARTSLIBRARY"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (signatoriesListStatus === "fetch") {
          dispatch(fetchSignatoriesList());
        }
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [signatoriesList, signatoriesListStatus, dispatch]);

  useEffect(() => {
    if (signatoriesListStatus === "loading") {
      setIsLoadingFetchSignatories(true);
    } else {
      setIsLoadingFetchSignatories(false);
    }
  }, [signatoriesListStatus]);

  return (
    <div>
      <Row>
        <Col xs={"auto"} className="caption">
          Gestiona las personas y empresas que pueden ser parte en tus
          documentos.
        </Col>
      </Row>
      <br />
      <br />
      <Row style={{ rowGap: 15, alignItems: "center" }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
        <Col />
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<PersonAddAlt1Outlined />}
            className="custom-input__button__primary-color"
            onClick={(event) => setAnchorElCreate(event.currentTarget)}
          >
            <p className="button">Crear parte </p>
          </Button>
          <Menu
            anchorEl={anchorElCreate}
            open={openCreateMenu}
            onClose={() => setAnchorElCreate(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => navigate("create")}>
              <ListItemIcon>
                <AddCircleRoundedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={"Crear desde cero"}
              />
            </MenuItem>
            <MenuItem onClick={() => navigate("create/massive")}>
              <ListItemIcon>
                <AddToPhotosOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={"Creación masiva"}
              />
            </MenuItem>
            <MenuItem onClick={() => navigate("create/certificate")}>
              <ListItemIcon>
                <NoteAddOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={"Desde certificado"}
              />
            </MenuItem>
          </Menu>
        </Col>
        <Col xs={"auto"}>
          <FilterTagParts
            list={data}
            setFilteredInfo={setFilteredData}
            handleTagsSelected={handleTagsSelected}
            tagsList={tagsList}
            currentTagSelected={tagsSelected}
          />
        </Col>
        <Col xs={"auto"}>
          <FilterSigantory
            list={data}
            setFilteredInfo={setFilteredData}
            handleSignatorySelected={handleSignatoriesSelected}
            sigantoryList={signatoriesList}
            currentSignatoriesSelected={signatoriesSelected}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ justifyContent: "end" }}>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
        {signatoriesSelected.map((signatory) => {
          return (
            <Col xs={"auto"} key={signatory?._id}>
              <span className="caption custom-badges__secundary">
                {signatory.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteSignatorySelected(signatory._id)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row className="dt-list-center-content">
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes partes creadas"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "partes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Menu
        id="basic-menu"
        anchorEl={anchorSelected}
        open={openSelected}
        onClose={() => setAnchorSelected(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleSelectedViewDetails(0);
          }}
        >
          <ListItemIcon>
            <EditOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Roboto",
              fontSize: "1.4rem",
              fontWeight: "regular",
              lineHeight: "2rem",
              color: "#676879",
            }}
            primary={"Editar"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectedViewDetails(1);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Roboto",
              fontSize: "1.4rem",
              fontWeight: "regular",
              lineHeight: "2rem",
              color: "#676879",
            }}
            primary={"Eliminar"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectedViewDetails(2);
          }}
        >
          <ListItemIcon>
            <InfoOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Roboto",
              fontSize: "1.4rem",
              fontWeight: "regular",
              lineHeight: "2rem",
              color: "#676879",
            }}
            primary={"Ver detalles"}
          />
        </MenuItem>
        {partSelected?.filenameInBucket && (
          <MenuItem
            onClick={() => {
              handleSelectedViewDetails(3);
            }}
          >
            <ListItemIcon>
              <ContentPasteSearchOutlinedIcon
                fontSize="large"
                className="heading__primary-color"
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Roboto",
                fontSize: "1.4rem",
                fontWeight: "regular",
                lineHeight: "2rem",
                color: "#676879",
              }}
              primary={"Visualizar"}
            />
          </MenuItem>
        )}
      </Menu>
      {!isLoadingAll && (
        <ModalInfo
          title={"Partes"}
          responseData={responseData}
          onClose={() => setIsOpenModalResponse(false)}
          open={isOpenModalResponse}
          buttonClose={true}
          confirmationText="Aceptar"
          handleCloseDefault={() => setIsOpenModalResponse(false)}
        />
      )}
      <ModalListSelectCheck
        handleAgree={handleAddCorporateUnit}
        list={listUnitsCorporatives}
        onClose={() => setIsOpenModalSelectUC(false)}
        open={isOpenModalSelectUC}
        title="Añadir unidades corporativas a una parte"
        currentDataSelected={unitCorporativeSelected}
      />
      <DetailBarParts
        openDetailsDrawner={openDetailsDrawner}
        handleClose={() => {
          setOpenDetailsDrawner(false);
          setPartSelected(null);
        }}
        selectedElement={partSelected}
      />
      {isOpenModalSignatoriesInfo && (
        <ModalInfoSignatories
          data={dataSignatoryModal}
          open={isOpenModalSignatoriesInfo}
          handleClose={() => {
            setIsOpenModalSignatoriesInfo(false);
            setDataSignatoryModal(null);
          }}
          selectedElement={partSelected}
        />
      )}
      <ModalDecision
        title={"Eliminar parte"}
        message={
          "Si eliminas esta persona o empresa dejarás de tener sus datos disponibles para la creación de documentos, ¿seguro quieres eliminarlo?"
        }
        open={isOpenModalDecisionDeletePart}
        onClose={() => setIsOpenModalDecisionDeletePart(false)}
        agreeText="Eliminar"
        disagreeText="Cancelar"
        handleAgree={() => handleDeletePart(partSelected)}
        handleDisagree={() => setIsOpenModalDecisionDeletePart(false)}
      />
    </div>
  );
};

export const DetailBarParts = ({
  openDetailsDrawner,
  handleClose,
  selectedElement,
  selectedElementIsPart = true,
}) => {
  return (
    <Drawer anchor="right" open={openDetailsDrawner} onClose={handleClose}>
      <Container fluid style={{ width: "46.7rem", height: "inherit" }}>
        <Row className="col-flex mt-4">
          <Col md={10}>
            <span className="heading__primary-color">
              {selectedElementIsPart
                ? "Detalles de la parte"
                : "Detalles del firmante"}
            </span>
          </Col>
          <Col md={1}>
            <CloseIcon
              fontSize="large"
              onClick={() => {
                handleClose();
              }}
            />
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <br />

        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            {selectedElementIsPart
              ? "Fecha y hora de creación o de carga en la plataforma:"
              : "Fecha y hora de creación o de carga:"}
          </Col>
          <Col className="caption">
            {moment(selectedElement?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Col>
        </Row>
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            {selectedElementIsPart
              ? "Parte creada por:"
              : "Firmante creado por:"}
          </Col>
          <Col className="caption">
            {`${selectedElement?.createdBy?.firstName || ""} ${
              selectedElement?.createdBy?.firstSurname || ""
            }`}
            {(!selectedElement?.createdBy?.firstName ||
              !selectedElement?.createdBy?.firstSurname) &&
              `${
                selectedElement?.createdBy?.companies?.[0]?.companyData
                  ?.companyName || ""
              }`}
          </Col>
        </Row>
        {!selectedElementIsPart && (
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Nombre completo:
            </Col>
            <Col className="caption">
              {`${selectedElement?.firstName || ""} ${
                selectedElement?.firstSurname || ""
              } ${selectedElement?.lastName || ""} ${
                selectedElement?.secondSurname || ""
              }`}
              {(!selectedElement?.firstName ||
                !selectedElement?.firstSurname) &&
                `Sin nombre.`}
            </Col>
          </Row>
        )}
        {selectedElement?.modifiedBy && (
          <>
            <Row
              style={{ height: "auto", marginBottom: "3%" }}
              className="p-2 ml-2"
            >
              <Col md={"auto"} className="subheading">
                Fecha y hora de última modificación:
              </Col>
              <Col md={"auto"} className="caption">
                {selectedElement?.updatedAt &&
                  moment(selectedElement?.updatedAt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
              </Col>
            </Row>
            <Row
              style={{ height: "auto", marginBottom: "3%" }}
              className="p-2 ml-2"
            >
              <Col md={"auto"} className="subheading">
                {selectedElementIsPart
                  ? "Parte modificada por última vez por:"
                  : "Firmante modificado por última vez por:"}
              </Col>
              <Col className="caption">
                {`${selectedElement?.modifiedBy?.firstName || ""} ${
                  selectedElement?.modifiedBy?.firstSurname || ""
                }`}
                {(!selectedElement?.modifiedBy?.firstName ||
                  !selectedElement?.modifiedBy?.firstSurname) &&
                  `${
                    selectedElement?.modifiedBy?.companies[0]?.companyData
                      ?.companyName || ""
                  }`}
                {` en: ${selectedElement?.lastUpdatesTrace
                  ?.map(
                    (traceabilityEntry) =>
                      SIGNATORY_PROPERTIES_TRANSLATION[
                        traceabilityEntry?.propertyName
                      ]
                  )
                  .join(", ")}`}
              </Col>
            </Row>
          </>
        )}
        {selectedElementIsPart && (
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Etiquetas:
            </Col>
            <Col className="caption">
              <Row style={{ rowGap: "3%", columnGap: "2%" }}>
                {selectedElement?.labels?.length > 0 ? (
                  selectedElement.labels.map((label) => (
                    <Col
                      className="drawner-details__labels"
                      md={"auto"}
                      key={label._id}
                    >
                      {label.name}
                    </Col>
                  ))
                ) : (
                  <Col md={"auto"} className="caption">
                    Sin etiquetas
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </Drawer>
  );
};
