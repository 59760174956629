import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../../../../components/loadingContent";
import ModalInfo from "../../../../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../../../../components/Modals/ModalUploadDocs";
import {
  addComment,
  getApprovalById,
  sendApprovaWithDocument,
  updateOneApproval,
} from "../../../../../../../../services/approvals/createApproval";
import { getFileDocument } from "../../../../../../../../services/documents/documentsRequest";
import { APPROVAL_STATE } from "../../../../../../../../utils/approvalsState";
import { CODES } from "../../../../../../../../utils/codes";

const AdjustmentService = () => {
  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { consecutive, approvalId, approvalParam } = useParams();

  /**
   * Use State
   */
  const [adjustmentMessage, setAdjustmentMessage] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);


  const [isOpenModalSave, setIsOpenModalSave] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const responseData = {
    data: {
      responseMessage:
        "Para el envío del ajuste es necesario un mensaje con los ajustes solicitados.",
    },
  };

  const [currentApproval, setCurrentApproval] = useState({
    approverPosition: "",
    approverName: "",
    state: {
      name: "",
    },
    associatedDocument: {
      name: "",
    },

    approvalComments: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const getPlanInfo = async () => {
      try {
        setIsLoading(true);
        const requestData = await getApprovalById({ approvalId: approvalId });
        if (
          requestData.status === CODES.COD_RESPONSE_HTTP_OK &&
          requestData.data.success
        ) {
          setCompanyInfo(requestData.data.responseMessage.data.company);
          setCurrentApproval({
            ...requestData.data.responseMessage.data,
            attorneyInCharge:
              requestData.data.responseMessage.data.aplicantName,
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getPlanInfo();
  }, []);

  useEffect(() => {
    if (adjustmentMessage) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [adjustmentMessage]);
  /**
   * Handles
   */

  //Download documents
  const handleDownload = async (filenameInBucket, bucketName, fileName) => {
    let newName;
    const file = await getFileDocument(filenameInBucket, bucketName, false);
    const bf = Buffer.from(file.data.responseMessage.buffer.data);
    const blob = new Blob([bf]);
    const extention = filenameInBucket.split(".").pop().toLowerCase();

    if (extention !== "docx" && extention !== "doc" && extention !== "pdf") {
      newName = `${fileName}.pdf`;
    } else {
      newName = `${fileName}.${extention}`;
    }
    saveAs(blob, newName);
  };

  //Enviar
  const handleSend = async () => {
    try {
      setIsLoading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      if (!documentData?.name) {
        const obj = {
          consecutive: consecutive,
          approvalId: approvalId,
          newState:
            approvalParam !== "approveWithComment"
              ? APPROVAL_STATE.RETURNED._id
              : APPROVAL_STATE.APPROVED._id,
          userName: currentApproval.approverName,
          userPosition: currentApproval.approverPosition,
          message: adjustmentMessage,
          associatedDocument: currentApproval.associatedDocument._id,
          isCommentOnly: false,
          sendEmail: true,
          isDiscussionComment: true,
          isApprovedWithComments: approvalParam === "approveWithComment",
          companyId: companyInfo?._id,
          corporateUnit
        };

        const requestData = await addComment(obj);
        if (requestData?.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("confirmation");
        } else if (
          requestData?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
          requestData?.data?.responseCode ===
            CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setIsOpenModalSave(true);
        }
      } else {
        const companyId = companyInfo?._id;
        const obj = {
          approvalId,
          consecutive: consecutive,
          document: documentData,
          documentType: currentApproval?.associatedDocument?.type,
          applicantName: currentApproval.attorneyInCharge,
          attorneyName: currentApproval.approverName,
          description: adjustmentMessage,
          companyId,
        };
        const service = await sendApprovaWithDocument(obj);
        if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { data } = service.data?.responseMessage;
          const obj = {
            consecutive: consecutive,
            approvalId: approvalId,
            updateApproval: {
              associatedDocument: data,
            },
            companyId,
            corporateUnit
          };
          const serviceToUpdate = await updateOneApproval(obj);
          if (serviceToUpdate.status) {
            if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
              const obj = {
                consecutive: consecutive,
                approvalId: approvalId,
                newState:
                  approvalParam !== "approveWithComment"
                    ? APPROVAL_STATE.RETURNED._id
                    : APPROVAL_STATE.APPROVED._id,
                userName: currentApproval.approverName,
                userPosition: currentApproval.approverPosition,
                message: adjustmentMessage,
                associatedDocument: data,
                isCommentOnly: false,
                sendEmail: true,
                isDiscussionComment: true,
                isApprovedWithComments: approvalParam === "approveWithComment",
                companyId,
                corporateUnit
              };
              const requestData = await addComment(obj);
              if (requestData.status) {
                if (requestData.status === CODES.COD_RESPONSE_HTTP_OK) {
                  navigate("confirmation");
                }
              }
            }
          }
        } else if (
          service?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
          service?.data?.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setIsOpenModalSave(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      className="custom-container-scroll"
      style={{ paddingLeft: "4%" }}
    >
      {isLoading && <LoadingContent />}
      <Row className="heading__hover" style={{ marginTop: "5%" }}>
        Información de la aprobación
      </Row>
      <Row className="label__description" style={{ marginTop: "2%" }}>
        {" "}
        <span style={{ padding: "0" }}>
          <span className="label__subtitle">Aprobador: </span>
          {currentApproval.approverName}
        </span>
      </Row>
      <Row className="label__description">
        <span style={{ padding: "0" }}>
          <span className="label__subtitle">Abogado a cargo: </span>
          {currentApproval.attorneyInCharge}
        </span>
      </Row>
      <Row className="label__description">
        <span style={{ padding: "0" }}>
          <span className="label__subtitle">
            {approvalParam !== "approveWithComment" ? (
              <span className="label__subtitle">
                Documento que requiere ajustes:{" "}
              </span>
            ) : (
              <span className="label__subtitle">Documento aprobado: </span>
            )}
          </span>
          <label
            onClick={() => {
              handleDownload(
                currentApproval.associatedDocument.filenameInBucket,
                currentApproval.associatedDocument.bucketName,
                currentApproval.associatedDocument.originalFilename
              );
            }}
            style={{
              color: "#17B4BC",
              cursor: "pointer",
            }}
          >
            {currentApproval.associatedDocument.originalFilename}
          </label>
        </span>
      </Row>
      <Row className="heading__primary-color" style={{ marginTop: "2%" }}>
        {approvalParam !== "approveWithComment"
          ? "Solicitar ajustes"
          : "Añadir comentarios"}
      </Row>
      <Row className="text-again" style={{ marginTop: "2%" }}>
        {approvalParam !== "approveWithComment"
          ? "Por favor escriba un mensaje con los ajustes que solicita"
          : "Por favor escriba un mensaje con los comentarios que tenga:"}
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Col md={6} style={{ paddingLeft: "0" }}>
          <Form.Group>
            <Form.Control
              as="textarea"
              style={{ minHeight: "120px", maxHeight: "200px" }}
              placeholder={"Está incorrecta la cláusula primera."}
              className="input-group-container__no-icon label text-fields"
              onChange={(e) => {
                setAdjustmentMessage(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="heading__primary-color" style={{ marginTop: "2%" }}>
        Adjuntar archivo con comentarios
      </Row>
      <Row className="text-again" style={{ marginTop: "2%" }}>
        Adjunte un archivo con los ajustes que debería tener el documento si así
        lo cree necesario.
      </Row>
      <br />
      <Row>
        <Col md={3}>
          <button
            className="btn_white_background"
            style={{ padding: "15px" }}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Adjuntar archivo
          </button>
        </Col>
        <Col md={2}>
          {documentData.name && (
            <Col
              style={{
                marginTop: "2.5rem",
              }}
            >
              <span
                className="caption custom-badges__disabled"
                style={{ padding: "4px" }}
              >
                {`${documentData.name} `}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => {
                    setDocumentData({});
                  }}
                />
              </span>
            </Col>
          )}
        </Col>
      </Row>
      <br />
      <Row className="text-again" style={{ marginTop: "2%" }}>
        El documento que quedará aprobado fue el que recibiste.
      </Row>
      <br />
      <Row style={{ width: "50%", marginTop: "3%" }} className="cols-end">
        <Button
          className={
            disabledButton
              ? "button-primary-folders__disabled"
              : "button-primary-folders__navigation"
          }
          style={{ width: "99px" }}
          disabled={disabledButton}
          onClick={() => {
            handleSend();
          }}
        >
          Enviar
        </Button>
      </Row>
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setIsOpenModal(false);
        }}
        onClose={() => {
          setIsOpenModal(false);
        }}
        open={isOpenModal}
        documentData={documentData}
        setDocumentData={setDocumentData}
      />
      <ModalInfo
        title="Es necesario escribir mensaje de ajuste"
        onClose={() => setIsOpenModalInfo(false)}
        open={isOpenModalInfo}
        responseData={responseData}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title="¡Caducado!"
        onClose={() => setIsOpenModalSave(false)}
        open={isOpenModalSave}
        responseData={{
          data: {
            responseMessage:
              "La compañía ha alcanzado el máximo de almacenamiento de su plan, por lo que no puedes continuar con el proceso de aprobación.",
          },
        }}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default AdjustmentService;
