import { createSlice } from "@reduxjs/toolkit";

const storageSlice = createSlice({
  name: "storage",
  initialState: {
    isStorageEnable: true,
    isUserEnable: true,
    isSignatureEnable: true,
    isRequestsEnable: true,
    isContractsEnable: true,
    isUserAdminEnable: true,
    isUserExternalEnable: true,
    isUserServiceEnable: true,
  },
  reducers: {
    setStorageEnable: (state, action) => {
      state.isStorageEnable = action.payload;
    },
    setUserEnable: (state, action) => {
      state.isUserEnable = action.payload;
    },

    setSignatureEnable: (state, action) => {
      state.isSignatureEnable = action.payload;
    },

    setStorageInfo: (state, action) => {
      state.isSignatureEnable = action.payload.isSignatureEnable;
      state.isUserEnable = action.payload.isUserEnable;
      state.isStorageEnable = action.payload.isStorageEnable;
      state.isRequestsEnable = action.payload.isRequestsEnable;
      state.isContractsEnable = action.payload.isContractsEnable;
      state.isUserAdminEnable = action.payload.isUserAdminEnable;
      state.isUserExternalEnable = action.payload.isUserExternalEnable;
      state.isUserServiceEnable = action.payload.isUserServiceEnable;
    },
  },
});

export const { setStorageEnable } = storageSlice.actions;
export const getStorageEnable = (state) => state.storage.isStorageEnable;

export const { setUserEnable } = storageSlice.actions;
export const getUsereEnable = (state) => state.storage.isUserEnable;

export const { setSignatureEnable } = storageSlice.actions;
export const getSignatureEnable = (state) => state.storage.isSignatureEnable;

export const { setStorageInfo } = storageSlice.actions;
export const getCompanyPlan = (state) => state.storage;

export default storageSlice.reducer;
