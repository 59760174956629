import { Route, Routes } from "react-router-dom";
import ExternalSign from "../../parts/ExternalSign";
import RegisterPhase1 from "../../parts/RegisterPhases/Phase1";
import RegisterPhase2 from "../../parts/RegisterPhases/Phase2";
import RegisterPhase3 from "../../parts/RegisterPhases/Phase3";
import ApproverLayout from "../../parts/approverLayout";
import Layout from "../../parts/layout";
import Login from "../../parts/login";
import CreateTag from "../../parts/manageTags/CreateTag";
import ManageClausesTags from "../../parts/manageTags/ManageClausesTags";
import UpdateTag from "../../parts/manageTags/UpdateTag";
import ContractTypeCreateTag from "../../parts/manageTags/contractTypes/ContractTypeCreateTag";
import ContractTypeUpdateTag from "../../parts/manageTags/contractTypes/ContractTypeUpdateTag";
import ManageContractTypeTags from "../../parts/manageTags/contractTypes/ManageContractTypeTags";
import DocumentsAlerts from "../../parts/manageTags/folders/DocumentsAlerts";
import FolderAlerts from "../../parts/manageTags/folders/FolderAlerts";
import FolderCreateTag from "../../parts/manageTags/folders/FolderCreateTag";
import FolderUpdateTag from "../../parts/manageTags/folders/FolderUpdateTag";
import ManageFoldersTags from "../../parts/manageTags/folders/ManageFoldersTags";
import { FormSignatories } from "../../parts/parts/formSignatories";
import RegisterUser from "../views/Register";
import AdminHelp from "../views/admin/AdminHelp";
import ChangeAdminPass from "../views/admin/ChangeAdminPass";
import ReportAccountExternal from "../views/admin/HelpExternal";
import BillingAdmin from "../views/admin/modules/billing/billingAdmin";
import ManagePlanAdmin from "../views/admin/modules/managePlan/managePlanAdmin";
import CreateUser from "../views/admin/modules/manageUser/CRUD/createUser";
import EditUser from "../views/admin/modules/manageUser/CRUD/editUser";
import TransferUserData from "../views/admin/modules/manageUser/CRUD/tranferUserData";
import AdminPermissions from "../views/admin/modules/manageUser/adminPermissions";
import ManageUserAdmin from "../views/admin/modules/manageUser/manageUserAdmin";
import UserPermissions from "../views/admin/modules/manageUser/userPermissions";
import RulesReview from "../views/admin/modules/rulesReview/rulesReview";
import CreateUnitCorporative from "../views/admin/modules/unitCorporative/createUnitCorporative";
import CreateHeadingsModule from "../views/admin/modules/unitCorporative/documents/createHeadings";
import EditHeadingsModule from "../views/admin/modules/unitCorporative/documents/editHeadings";
import ManageApprovalsModule from "../views/admin/modules/unitCorporative/documents/manageApprovals";
import ManageDocumentsModule from "../views/admin/modules/unitCorporative/documents/manageDocuments";
import ManageHeadingsModule from "../views/admin/modules/unitCorporative/documents/manageHeadings";
import SelectHeadings from "../views/admin/modules/unitCorporative/documents/selectHeadings";
import EditUnitCorporative from "../views/admin/modules/unitCorporative/editUnitCorporative";
import UnitsCorporatives from "../views/admin/modules/unitCorporative/unitsCoporatives";
import UserUnitCorporative from "../views/admin/modules/unitCorporative/userUnitCorporative";
import ProfileAdmin from "../views/admin/profileAdmin";
import CreatePassword from "../views/auth/CreatePassword";
import ForgotPassword from "../views/auth/forgotPassword";
import SelectRoleLogin from "../views/auth/selectRoleLogin";
import NotFound from "../views/notFound";
import SelectCorp from "../views/userService/SelectCorp";
import HomeService from "../views/userService/homeService";
import AlertDetails from "../views/userService/modules/alert/AlertDetails";
import DocumentAssociateAlert from "../views/userService/modules/alert/DocumentAssociateAlert";
import FolderAssociateAlert from "../views/userService/modules/alert/FolderAssociateAlert";
import AlertService from "../views/userService/modules/alert/alertService";
import CreateAlert from "../views/userService/modules/alert/createAlert";
import EditAlert from "../views/userService/modules/alert/editAlert";
import InfoStatusAlerts from "../views/userService/modules/alert/infoStatusAlerts";
import ViewDocumentAlert from "../views/userService/modules/alert/viewDocumentAlert";
// import ViewMilestones from "../views/userService/modules/alert/viewMilestones";
import FillBlankspaces from "../views/userService/modules/create/blankspaces/blankspaces";
import CreateService from "../views/userService/modules/create/createService";
import BusinessFolderView from "../views/userService/modules/create/folder/businessFolderView";
import SelectedRequest from "../views/userService/modules/create/folder/selectedRequest";
import GeneralEditDocument from "../views/userService/modules/create/generalEditDocument";
import GeneralViewDocument from "../views/userService/modules/create/generalViewDocument";
import FormLibraryContract from "../views/userService/modules/create/library/formContractService";
import SelectClausesContract from "../views/userService/modules/create/library/selectClausesService";
import InfoMinuta from "../views/userService/modules/create/minuta/infoMinuteService";
import SelectMinuta from "../views/userService/modules/create/minuta/selectMinutaService";
import AddHeader from "../views/userService/modules/create/panel-encabezados/addHeader";
import EditHeader from "../views/userService/modules/create/panel-encabezados/editHeader";
import CreateSignature from "../views/userService/modules/create/panel-firmas/CRUD/createSignature";
import EditSignature from "../views/userService/modules/create/panel-firmas/CRUD/editSignature";
import SignaturesPanel from "../views/userService/modules/create/panel-firmas/signaturesPanelService";
import CreatePart from "../views/userService/modules/create/panel-partes/CRUD/createPart";
import EditPart from "../views/userService/modules/create/panel-partes/CRUD/editPart";
import SwapParts from "../views/userService/modules/create/parts/swapParts";
import DashboardPanel from "../views/userService/modules/dashboard/dashboard-panel";
import CrudBusinessFolder from "../views/userService/modules/folder/CRUD/crudBusinessFolder";
import BusinessFolderService from "../views/userService/modules/folder/businessFolderService";
import PaperBasket from "../views/userService/modules/folder/paperBasket/paperBasket";
import ReportsService from "../views/userService/modules/folder/reports/reportsService";
import { ViewContract } from "../views/userService/modules/folder/reports/viewContract";
import UploadDocument from "../views/userService/modules/folder/uploadDocument/uploadDocument";
import ViewBusinessFolder from "../views/userService/modules/folder/viewBusinessFolder";
import ViewFiles from "../views/userService/modules/folder/view_files/viewFiles";
import ViewOthers from "../views/userService/modules/folder/view_files/viewOthers";
import Clauses from "../views/userService/modules/library/clauses/Clauses";
import ClausesBulk from "../views/userService/modules/library/clauses/ClausesBulk";
import CreateClause from "../views/userService/modules/library/clauses/CreateClause";
import CreateClauseBulk from "../views/userService/modules/library/clauses/CreateClauseBulk";
import EditDocumentClause from "../views/userService/modules/library/clauses/EditDocumentClause";
import UpdateClause from "../views/userService/modules/library/clauses/UpdateClause";
import ContractTypes from "../views/userService/modules/library/contractTypes/contractTypes";
import CreateContractTypes from "../views/userService/modules/library/contractTypes/createContractType";
import CreateContractTypesBulk from "../views/userService/modules/library/contractTypes/createContractTypesBulk";
import EditContractTypes from "../views/userService/modules/library/contractTypes/editContractType";
import ViewContractTypes from "../views/userService/modules/library/contractTypes/viewContractType";
import LibraryService from "../views/userService/modules/library/libraryService";
import { CreatePartMassive } from "../views/userService/modules/library/parts/create/CreatePartMassive";
import CreatePartLibrary from "../views/userService/modules/library/parts/create/createPart";
import { CreatePartCertificate } from "../views/userService/modules/library/parts/create/createPartCertificate";
import { PartsPaperBasket } from "../views/userService/modules/library/parts/delete/PartsPaperBasket";
import { UpdatePartLegalLibrary } from "../views/userService/modules/library/parts/edit/updatePartLegal";
import { UpdatePartNaturalLibrary } from "../views/userService/modules/library/parts/edit/updatePartNatural";
import { Parts } from "../views/userService/modules/library/parts/parts";
import { CreateSignatoryLibrary } from "../views/userService/modules/library/parts/signatories/createSignatory";
import { UpdateSignatoryLibrary } from "../views/userService/modules/library/parts/signatories/updateSignatory";
import UpdatePredeterminatedText from "../views/userService/modules/library/pretederminatedTexts/UpdatePredeterminatedText";
import DataTableCarvajal from "../views/userService/modules/request/Carvajal/DataTableCarvajal";
import SignModule from "../views/userService/modules/request/SignModule";
import SignatoryData from "../views/userService/modules/request/SignatoryData";
import AdjustmentConfirmation from "../views/userService/modules/request/ViewRequest/CreateApproval/adjustments/adjustmentConfirmation";
import AdjustmentService from "../views/userService/modules/request/ViewRequest/CreateApproval/adjustments/adjustmentService";
import ApprovalConfirmed from "../views/userService/modules/request/ViewRequest/CreateApproval/adjustments/approvalConfirmed";
import ApprovalsService from "../views/userService/modules/request/ViewRequest/CreateApproval/approvalsService";
import CreateApproval from "../views/userService/modules/request/ViewRequest/CreateApproval/createApproval";
import ViewApproval from "../views/userService/modules/request/ViewRequest/CreateApproval/viewApproval";
import Reasignrequest from "../views/userService/modules/request/ViewRequest/Reasignrequest";
import RequestApprovalAgain from "../views/userService/modules/request/ViewRequest/RequestApprovalAgain/requestApprovalAgain";
import ViewRequestStandard from "../views/userService/modules/request/ViewRequest/ViewRequestStandard";
import InfoStatus from "../views/userService/modules/request/ViewRequest/infoStatus";
import ManageSignature from "../views/userService/modules/request/manageSignature";
import RequestAssociate from "../views/userService/modules/request/requestAssociate";
import RequestAssociateNew from "../views/userService/modules/request/requestAssociateNew";
import RequestFolder from "../views/userService/modules/request/requestFolders";
import { ChooseReviewRule } from "../views/userService/modules/review/first-time/ChooseReviewRule";
import ViewOtherDocument from "../views/userService/modules/review/first-time/OtherDocument";
import UploadFirstTime from "../views/userService/modules/review/first-time/UploadFirstTime";
import ReviewSelectFolder from "../views/userService/modules/review/in-negotiation/selectFolderService";
import ReviewUploadDocument from "../views/userService/modules/review/in-negotiation/upload-document/uploadDocumentService";
import ViewReview from "../views/userService/modules/review/in-negotiation/view/viewDocumentReview";
import ReviewViewFolder from "../views/userService/modules/review/in-negotiation/viewFolderService";
import ReviewService from "../views/userService/modules/review/reviewService";
import ChoseDocumentToSign from "../views/userService/modules/sign/ChoseDocumentToSign";
import SignPrincipalView from "../views/userService/modules/sign/SignPrincipalView";
import ViewPredeterminatedTexts from "./../views/userService/modules/library/pretederminatedTexts/viewPredeterminatedTexts";
import { DownloadFile } from "./downloadFile";
import ProtectedMaintenance, { ModuleMaintenance } from "./maintenance";
import { DocumentTempleteCreateTag } from "../../parts/manageTags/documentTempletes/DocumentTempleteCreateTag";
import { DocumentTempleteUpdateTag } from "../../parts/manageTags/documentTempletes/DocumentTempleteUpdateTag";
import { ManageDocumentTempleteTags } from "../../parts/manageTags/documentTempletes/ManageDocumentTempleteTags";
import { FormSignatoriesForPart } from "../../parts/parts/formSignatoriesForPart";
import TempleteDocument from "../views/userService/modules/library/documentTemplates/templetedocument";
import UpdateTempleteDocument from "../views/userService/modules/library/documentTemplates/UpdateTempleteDocument";
import { SelectDefaultSignatoryForContract } from "../views/userService/modules/library/parts/service/selectDefaultSignatoryForContract";
import { SelectPartForContract } from "../views/userService/modules/library/parts/service/selectPartForContract";
import { SelectSignatoryForContract } from "../views/userService/modules/library/parts/service/selectSignatoryForContract";
import { SelectSignatory } from "../views/userService/modules/library/parts/signatories/selectSignatory";
import { AuthorizeUser } from "./authorizedUser";
import PocSelectionText from "../../poc/SelectionText.poc";
import CreateContract from "../views/userService/modules/create/createContract";
import CreateOtherDocument from "../views/userService/modules/create/createOtherDocument";
import CreateTemplateDocument from "../views/userService/modules/library/documentTemplates/CreateTemplateDocument";
import AlertsPaperBasket from "../views/userService/modules/alert/AlertsPaperBasket";
import { Consults } from "../views/userService/modules/library/consult/Consults";
import ProtectedRoute from "./protectedRoutes";
import CreatePredeterminatedText from "./../views/userService/modules/library/pretederminatedTexts/CreatePredeterminatedText";
import SelectTemplaterOtherDocument from "../views/userService/modules/create/other/SelectTempleteOtherDocument";
import CreateOtherDocumentFromTemplate from "../views/userService/modules/create/other/createOtherDocumentFromTemplate";
import SelectPredeterminedTextOtherDocument from "../views/userService/modules/create/other/selectPredeterminedTextOtherDocument";
import FillBlankspacesOtherDocument from "../views/userService/modules/create/blankspaces/blankspacesOtherDocument";

function AppRoutes() {
  const keysStorageReload = ["payloadToken", "refreshToken", "infoToken"];

  // window.addEventListener("storage", (e) => {
  //   const storage = localStorage;
  //   if (keysStorageReload.includes(e.key) || !storage.length) {
  //     window.location.reload();
  //   }
  // });

  return (
    <Routes>
      <Route element={<ProtectedMaintenance />}>
        <Route index element={<SelectRoleLogin />} />
        <Route path="reportAccount" element={<ReportAccountExternal />} />
        <Route path="login/admin" element={<Login role={"ADMIN"} />} />
        <Route path="login/serviceUser" element={<Login role={"SERVICE"} />} />
        <Route path="register" element={<RegisterUser />}>
          <Route path="phase1" element={<RegisterPhase1 />} />
          <Route path="phase2" element={<RegisterPhase2 />} />
          <Route path="phase3" element={<RegisterPhase3 />} />
        </Route>

        <Route element={<ProtectedRoute requiredRole={"SERVICE"} />}>
          <Route path="selectCorp" element={<SelectCorp />} />
          <Route path="poc" element={<Layout />}>
            <Route path="selection-text" element={<PocSelectionText />} />
          </Route>
          <Route path="service" element={<Layout />}>
            <Route path="home" element={<HomeService />} />
            <Route path="create" element={<CreateService />} />
            <Route path="create/contract" element={<CreateContract />} />
            {/* Other Docs */}
            <Route path="create/other" element={<CreateOtherDocument />} />
            <Route
              path="create/other/from-template/select"
              element={<SelectTemplaterOtherDocument />}
            />
            <Route
              path="create/other/from-text/select"
              element={<SelectPredeterminedTextOtherDocument />}
            />
            <Route
              path="create/other/from-template/create"
              element={<CreateOtherDocumentFromTemplate />}
            />
            <Route
              path="create/other/from-text/create"
              element={<CreateOtherDocumentFromTemplate />}
            />

            <Route
              path="create/other/:type/create/blankspaces"
              element={<FillBlankspacesOtherDocument />}
            />
            <Route
              path="create/other/:type/create/:saveAs/select-folder"
              element={<BusinessFolderView />}
            />
            <Route
              path="create/other/:type/create/:saveAs/select-request"
              element={<SelectedRequest />}
            />
            <Route
              path="create/other/:type/create/:saveAs/create-folder/:action"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="create/other/:type/create/:saveAs/create-folder/:action/:id"
              element={<CrudBusinessFolder />}
            />
            <Route path="help" element={<AdminHelp />} />
            <Route path="create/from-minuta" element={<SelectMinuta />} />
            <Route
              path="create/from-minuta/contract-info"
              element={<InfoMinuta />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document"
              element={<GeneralViewDocument />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/create-clause"
              element={<CreateClause />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/swap-parts/:id"
              element={<SwapParts />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/:saveAs/folder/select-folder"
              element={<BusinessFolderView />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/:saveAs/folder/select-request"
              element={<SelectedRequest />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/:saveAs/folder/create-folder/:action"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/:saveAs/folder/create-folder/:action/:id"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/signatures-panel"
              element={<SignaturesPanel />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/select-signatory/:partIndex/:type"
              element={<SelectSignatoryForContract />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/select-part/:partIndex"
              element={<SelectPartForContract />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/select-default/:roleIndex/:partIndex"
              element={<SelectDefaultSignatoryForContract />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/signatures-panel/create/:partIndex"
              element={<CreateSignature />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/signatures-panel/edit/:partIndex/:sigIndex"
              element={<EditSignature />}
            />
            {/* <Route
              path="create/from-minuta/contract-info/create-document/parts-panel"
              element={<PartsPanel />}
            /> */}
            {/* Panel de firmas con partes */}
            <Route
              path="create/from-minuta/contract-info/create-document/parts-panel/:indexPart/create-signatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/parts-panel/:indexPart/edit-signatory/:indexSignatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/signatories-panel/:indexRole/:indexPart/:type"
              element={<FormSignatoriesForPart />}
            />
            {/* Panel de firmas sin partes */}
            <Route
              path="create/from-minuta/contract-info/create-document/without-part/:indexPart/create-signatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/without-part/:indexPart/edit-signatory/:indexSignatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/parts-panel/create"
              element={<CreatePart />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/parts-panel/edit/:position"
              element={<EditPart />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/add-header-with"
              element={<AddHeader />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/add-header-without"
              element={<AddHeader />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/edit-header/:id"
              element={<EditHeader />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/blankspaces"
              element={<FillBlankspaces />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/blankspaces/:id"
              element={<FillBlankspaces />}
            />
            <Route
              path="create/from-minuta/contract-info/create-document/select-headings"
              element={<SelectHeadings />}
            />
            <Route
              path="create/from-library/clause-selection"
              element={<SelectClausesContract />}
            />
            <Route
              path="create/from-library"
              element={<FormLibraryContract />}
            />
            <Route
              path="create/from-library/clause-selection/create-document"
              element={<GeneralViewDocument />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/swap-parts/:id"
              element={<SwapParts />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/:saveAs/folder/select-folder"
              element={<BusinessFolderView />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/:saveAs/folder/select-request"
              element={<SelectedRequest />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/:saveAs/folder/create-folder/:action"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/:saveAs/folder/create-folder/:action/:id"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/signatures-panel"
              element={<SignaturesPanel />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/select-signatory/:partIndex/:type"
              element={<SelectSignatoryForContract />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/select-part/:partIndex"
              element={<SelectPartForContract />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/select-default/:roleIndex/:partIndex"
              element={<SelectDefaultSignatoryForContract />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/signatures-panel/create/:partIndex"
              element={<CreateSignature />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/signatures-panel/edit/:partIndex/:sigIndex"
              element={<EditSignature />}
            />
            {/* <Route
              path="create/from-library/clause-selection/create-document/parts-panel"
              element={<PartsPanel />}
            /> */}
            {/* Panel de firmas con partes */}
            <Route
              path="create/from-library/clause-selection/create-document/parts-panel/:indexPart/create-signatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/parts-panel/:indexPart/edit-signatory/:indexSignatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/signatories-panel/:indexRole/:indexPart/:type"
              element={<FormSignatoriesForPart />}
            />
            {/* Panel de firmas sin partes */}
            <Route
              path="create/from-library/clause-selection/create-document/without-part/:indexPart/create-signatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/without-part/:indexPart/edit-signatory/:indexSignatory"
              element={<FormSignatories />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/parts-panel/create"
              element={<CreatePart />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/parts-panel/edit/:position"
              element={<EditPart />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/add-header-with"
              element={<AddHeader />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/add-header-without"
              element={<AddHeader />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/edit-header/:id"
              element={<EditHeader />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/create-clause"
              element={<CreateClause />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/create-clause"
              element={<CreateClause />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/blankspaces/:id"
              element={<FillBlankspaces />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/blankspaces"
              element={<FillBlankspaces />}
            />
            <Route
              path="create/from-library/clause-selection/create-document/select-headings"
              element={<SelectHeadings />}
            />
            <Route path="review" element={<ReviewService />} />
            <Route path="review/first-Time" element={<UploadFirstTime />} />
            <Route
              path="review/first-Time/rules-list"
              element={<ChooseReviewRule />}
            />
            <Route
              path="review/first-Time/another-document"
              element={<ViewOtherDocument />}
            />
            <Route
              path="review/first-Time/view-document/:idDocument"
              element={<ViewReview />}
            />
            <Route
              path="review/first-Time/view-document/:idDocument/reports"
              element={<ReportsService />}
            />
            <Route
              path="review/first-Time/view-document/:idDocument/reports/viewContract"
              element={<ViewContract />}
            />
            <Route
              path="review/first-Time/view-document/:idDocument/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route
              path="review/first-Time/another-document/:saveAs/folder/select-folder"
              element={<BusinessFolderView />}
            />
            <Route
              path="review/first-Time/another-document/:saveAs/folder/select-request"
              element={<SelectedRequest />}
            />
            <Route
              path="review/first-Time/another-document/:saveAs/folder/create-folder/:action"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="review/first-Time/another-document/:saveAs/folder/create-folder/:action/:id"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="review/in-negotiation/select-document"
              element={<ReviewSelectFolder />}
            />
            <Route
              path="review/in-negotiation/select-document/viewFolder/:businessFolderId"
              element={<ReviewViewFolder />}
            />
            <Route
              path="review/in-negotiation/uploadDocument/:documentId"
              element={<ReviewUploadDocument />}
            />
            <Route
              path="review/in-negotiation/view-document/:idDocument"
              element={<ViewReview />}
            />
            <Route
              path="review/in-negotiation/view-document/:idDocument/reports"
              element={<ReportsService />}
            />
            <Route
              path="review/in-negotiation/view-document/:idDocument/reports/viewContract"
              element={<ViewContract />}
            />
            <Route
              path="review/in-negotiation/view-document/:idDocument/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route path="folders" element={<BusinessFolderService />} />
            <Route path="folders/:action" element={<CrudBusinessFolder />} />
            <Route
              path="folders/:action/:id"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="folders/upload/:upload/:id"
              element={<UploadDocument />}
            />
            <Route path="folders/tags" element={<ManageFoldersTags />} />
            <Route path="folders/tags/create" element={<FolderCreateTag />} />
            <Route path="folders/tags/:id" element={<FolderUpdateTag />} />
            {/* Cambiar luego esta ruta de abajo */}
            <Route path="folders/alerts/:id" element={<FolderAlerts />} />
            {/* cambio de arriba hasta aquí */}
            <Route path="library" element={<LibraryService />} />
            <Route path="library/concepts" element={<Consults />} />
            <Route path="library/concepts" element={<Consults />} />
            <Route path="library/clauses" element={<Clauses />} />

            <Route path="library/clauses/create" element={<CreateClause />} />
            <Route
              path="library/clauses/create-massive"
              element={<CreateClauseBulk />}
            />
            <Route
              path="library/clauses/create-massive/result"
              element={<ClausesBulk />}
            />
            <Route
              path="library/clauses/create-massive/result/swap-parts/:id"
              element={<SwapParts />}
            />
            <Route
              path="library/clauses/create-massive/result/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route path="library/clauses/:id" element={<UpdateClause />} />
            <Route
              path="library/clauses/tags"
              element={<ManageClausesTags />}
            />
            <Route path="library/clauses/tags/create" element={<CreateTag />} />
            <Route path="library/clauses/tags/:id" element={<UpdateTag />} />
            <Route path="library/contractTypes" element={<ContractTypes />} />
            <Route
              path="library/contractTypes/create"
              element={<CreateContractTypes />}
            />
            <Route
              path="library/contractTypes/create/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route
              path="library/contractTypes/create-massive"
              element={<CreateClauseBulk />}
            />
            <Route
              path="library/contractTypes/create-massive/result"
              element={<CreateContractTypesBulk />}
            />
            <Route
              path="library/contractTypes/view/:id"
              element={<ViewContractTypes />}
            />
            <Route
              path="library/contractTypes/edit/:id"
              element={<EditContractTypes />}
            />
            <Route
              path="library/contractTypes/edit/:id/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route
              path="library/contractTypes/tags"
              element={<ManageContractTypeTags />}
            />
            <Route
              path="library/contractTypes/tags/create"
              element={<ContractTypeCreateTag />}
            />
            <Route
              path="library/contractTypes/tags/:id"
              element={<ContractTypeUpdateTag />}
            />
            <Route
              path="library/documentTemplates"
              element={<TempleteDocument />}
            />
            <Route
              path="library/documentTemplates/create"
              element={<CreateTemplateDocument />}
            />
            <Route
              path="library/documentTemplates/:id"
              element={<UpdateTempleteDocument />}
            />
            {/*  README: Rutas para etiquetas de plantillas de documentos */}
            <Route
              path="library/documentTemplates/tags"
              element={<ManageDocumentTempleteTags />}
            />
            <Route
              path="library/documentTemplates/tags/create"
              element={<DocumentTempleteCreateTag />}
            />
            <Route
              path="library/documentTemplates/tags/:id"
              element={<DocumentTempleteUpdateTag />}
            />
            {/*  README: Rutas para textos predeterminados */}
            <Route
              path="library/predeterminedTexts"
              element={<ViewPredeterminatedTexts />}
            />
            <Route
              path="library/predeterminedTexts/create"
              element={<CreatePredeterminatedText />}
            />
            <Route
              path="library/predeterminedTexts/:id"
              element={<UpdatePredeterminatedText />}
            />
            <Route
              path="library/predeterminedTexts/tags"
              element={<ManageDocumentTempleteTags />}
            />
            <Route
              path="library/predeterminedTexts/tags/create"
              element={<DocumentTempleteCreateTag />}
            />
            <Route
              path="library/predeterminedTexts/tags/:id"
              element={<DocumentTempleteUpdateTag />}
            />
            {/* INICIO RUTAS ALERTAS */}
            <Route path="alerts" element={<AlertService />} />
            <Route path="alerts/infoStatus" element={<InfoStatusAlerts />} />
            <Route path="alerts/create" element={<FolderAssociateAlert />} />
            <Route path="alerts/delete" element={<AlertsPaperBasket />} />
            {/* <Route
              path="alerts/create/:id"
              element={<DocumentAssociateAlert />}
            /> */}
            {/* <Route
              path="alerts/create/:id/document/:id"
              element={<ViewDocumentAlert />}
            /> */}
            {/* <Route
              path="alerts/create/:id/viewMilestones"
              element={<ViewMilestones />}
            /> */}
            {/* <Route
              path="alerts/create/:id/document/:id/selected"
              element={<CreateAlert />}
            /> */}
            <Route path="alerts/create/:id" element={<CreateAlert />} />
            <Route path="alerts/:alertId/edit/:id" element={<CreateAlert />} />
            {/* Crear desde carpetas */}
            <Route
              path="alerts/detail/:alertId/:companyId"
              element={<AlertDetails />}
            />
            <Route
              path="alerts/detail/:alertId/edit/:id"
              element={<CreateAlert />}
            />
            {/* Guardar documento  */}
            <Route
              path="alerts/:saveAs/folder/select-folder"
              element={<BusinessFolderView />}
            />

            <Route
              path="alerts/:saveAs/folder/create-folder/:action"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="alerts/:saveAs/folder/create-folder/:action/:id"
              element={<CrudBusinessFolder />}
            />
            {/* Recibe el id de la alerta cómo parametro */}
            {/* <Route path="alerts/edit/:id" element={<EditAlert />} /> */}
            {/* <Route path="alerts/detail/:alertId" element={<AlertDetails />} /> */}
            {/* FIN RUTAS ALERTAS */}
            {/* INICIO RUTAS PETICIONES  DE TERCEROS*/}
            {/* <Route path="requests" element={<RequestService />} /> */}
            <Route path="requests/" element={<DataTableCarvajal />} />
            <Route path="requests/infoStatus" element={<InfoStatus />} />
            {/* <Route
              path="requests/viewRequest/:consecutive"
              element={<ViewRequestService />}
            /> */}
            {/* <Route
              path="requests/viewRequest/:consecutive"
              element={<ViewRequestServiceCarvajal />}
            /> */}
            <Route
              path="requests/viewRequest/:consecutive"
              element={<ViewRequestStandard />}
            />
            <Route
              path="requests/viewRequest/:consecutive/approvals/create-approval"
              element={<CreateApproval />}
            />
            <Route
              path="requests/viewRequest/:consecutive/approvals/viewApproval/:approvalId/request-again-approval/:type"
              element={<RequestApprovalAgain />}
            />
            <Route
              path="requests/viewRequest/:consecutive/reasign"
              element={<Reasignrequest />}
            />
            <Route
              path="requests/viewRequest/:consecutive/signatures"
              element={<ManageSignature />}
            />
            <Route path="requests/:consecutive/sign" element={<SignModule />} />
            <Route path="addSignatories" element={<SignatoryData />} />
            <Route
              path="requests/associate/:consecutive/:requestId"
              element={<RequestAssociate />}
            />
            <Route
              path="requests/associate/newFolder/:consecutive/:requestId"
              element={<RequestAssociateNew />}
            />
            <Route
              path="requests/associate-folder/:consecutive"
              element={<RequestFolder />}
            />
            <Route
              path="requests/viewRequest/:consecutive/approvals"
              element={<ApprovalsService />}
            />
            <Route
              path="requests/viewRequest/:consecutive/approvals/viewApproval/:approvalId"
              element={<ViewApproval />}
            />
            {/* FIN RUTAS PETICIONES  DE TERCEROS*/}
            <Route path="folders" element={<BusinessFolderService />} />
            <Route
              path="folders/viewFolder/:businessFolderId"
              element={<ViewBusinessFolder />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/reports/:idDocumentToReport"
              element={<ReportsService />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/reports/:idDocumentToReport/viewContract"
              element={<ViewContract />}
            />
            {/* Cambiar o actualizar */}
            <Route
              path="folders/viewFolder/:businessFolderId/alerts/:id"
              element={<DocumentsAlerts />}
            />
            {/* El cambio es hasta aquí */}
            <Route
              path="folders/viewFolder/:businessFolderId/viewContract/:documentId"
              element={<ViewFiles />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/viewOther/:documentId"
              element={<ViewOthers />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/deleteDocument"
              element={<PaperBasket />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument"
              element={<GeneralEditDocument />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/select-headings"
              element={<SelectHeadings />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/add-header-with"
              element={<AddHeader />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/add-header-without"
              element={<AddHeader />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/edit-clause/:id"
              element={<EditDocumentClause />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument"
              element={<GeneralEditDocument />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/select-headings"
              element={<SelectHeadings />}
            />
            {/* <Route
              path="folders/viewFolder/:businessFolderId/editDocument/parts-panel"
              element={<PartsPanel />}
            /> */}
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/parts-panel/:indexPart/create-signatory"
              element={<FormSignatories />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/parts-panel/:indexPart/edit-signatory/:indexSignatory"
              element={<FormSignatories />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/signatories-panel/:indexRole/:indexPart/:type"
              element={<FormSignatoriesForPart />}
            />
            {/* Firmas sin partes */}
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/without-part/:indexPart/create-signatory"
              element={<FormSignatories />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/without-part/:indexPart/edit-signatory/:indexSignatory"
              element={<FormSignatories />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/parts-panel/create"
              element={<CreatePart />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/parts-panel/edit/:position"
              element={<EditPart />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/signatures-panel"
              element={<SignaturesPanel />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/select-signatory/:partIndex/:type"
              element={<SelectSignatoryForContract />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/select-part/:partIndex"
              element={<SelectPartForContract />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/select-default/:roleIndex/:partIndex"
              element={<SelectDefaultSignatoryForContract />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/signatures-panel/create/:partIndex"
              element={<CreateSignature />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/signatures-panel/edit/:partIndex/:sigIndex"
              element={<EditSignature />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/create-clause"
              element={<CreateClause />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/edit-header/:id"
              element={<EditHeader />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/swap-parts/:id"
              element={<SwapParts />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/blankspaces/:id"
              element={<FillBlankspaces />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/blankspaces"
              element={<FillBlankspaces />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/:saveAs/folder/select-folder"
              element={<BusinessFolderView />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/:saveAs/folder/select-request"
              element={<SelectedRequest />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/:saveAs/folder/create-folder/:action"
              element={<CrudBusinessFolder />}
            />
            <Route
              path="folders/viewFolder/:businessFolderId/editDocument/:saveAs/folder/create-folder/:action/:id"
              element={<CrudBusinessFolder />}
            />
            <Route path="folders/delete/document" element={<PaperBasket />} />
            <Route
              path="requests/associate-folder"
              element={<RequestFolder />}
            />
            {/* INICIO RUTAS PARA FIRMAS PANEL LATERAL */}
            <Route path="signView" element={<SignPrincipalView />} />
            <Route path="documentToSign" element={<ChoseDocumentToSign />} />
            {/* FIN RUTAS PARA FIRMAS PANEL LATERAL */}
            <Route path="dashboard" element={<DashboardPanel />} />
          </Route>
        </Route>

        <Route
          element={<ProtectedRoute requiredRole={"ADMIN"} path="managePlan" />}
        >
          <Route path="admin" element={<Layout />}>
            <Route path="managePlan" element={<ManagePlanAdmin />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute requiredRole={"ADMIN"} />}>
          <Route path="admin" element={<Layout />}>
            <Route path="profile" element={<ProfileAdmin />} />
            <Route path="help" element={<AdminHelp />} />

            <Route path="manageUser" element={<ManageUserAdmin />} />
            <Route path="manageUser/create" element={<CreateUser />} />
            <Route
              path="manageUser/create/servicePermissions"
              element={<UserPermissions />}
            />
            <Route
              path="manageUser/create/adminPermissions"
              element={<AdminPermissions />}
            />
            <Route path="manageUser/edit/:id" element={<EditUser />} />
            <Route
              path="manageUser/edit/:id/userUnitCorporative"
              element={<UserUnitCorporative />}
            />
            <Route
              path="manageUser/edit/:id/servicePermissions"
              element={<UserPermissions />}
            />
            <Route
              path="manageUser/edit/:id/adminPermissions"
              element={<AdminPermissions />}
            />

            <Route
              path="manageUser/edit/:id/transferData"
              element={<TransferUserData />}
            />
            <Route path="changePassword" element={<ChangeAdminPass />} />
            <Route path="billing" element={<BillingAdmin />} />
            <Route path="unitsCorporatives" element={<UnitsCorporatives />} />
            <Route
              path="unitsCorporatives/create"
              element={<CreateUnitCorporative />}
            />
            <Route
              path="unitsCorporatives/edit/:id"
              element={<EditUnitCorporative />}
            />
            <Route
              path="unitsCorporatives/documents"
              element={<ManageDocumentsModule />}
            />
            <Route
              path="unitsCorporatives/documents/manage-approvals"
              element={<ManageApprovalsModule />}
            />
            <Route
              path="unitsCorporatives/documents/manage-headings"
              element={<ManageHeadingsModule />}
            />
            <Route
              path="unitsCorporatives/documents/manage-headings/create"
              element={<CreateHeadingsModule />}
            />
            <Route
              path="unitsCorporatives/documents/manage-headings/edit/:id"
              element={<EditHeadingsModule />}
            />
            <Route
              path="unitsCorporatives/rules-review"
              element={<RulesReview />}
            />

            <Route path="library" element={<Parts />} />
            <Route path="library/create" element={<CreatePartLibrary />} />
            <Route
              path="library/update/natural/:id"
              element={<UpdatePartNaturalLibrary />}
            />
            <Route
              path="library/update/legal/:id"
              element={<UpdatePartLegalLibrary />}
            />
            <Route
              path="library/create/massive"
              element={<CreatePartMassive />}
            />
            <Route
              path="library/create/certificate"
              element={<CreatePartCertificate />}
            />
            <Route
              path="library/create/signatory"
              element={<CreateSignatoryLibrary />}
            />
            <Route
              path="library/selectSignatory/:part"
              element={<SelectSignatory />}
            />
            <Route
              path="library/update/:id"
              element={<UpdateSignatoryLibrary />}
            />
            <Route path="library/tags/create" element={<CreateTag />} />
            <Route path="library/tags/:id" element={<UpdateTag />} />
            <Route path="library/tags" element={<ManageClausesTags />} />
            <Route path="library/delete" element={<PartsPaperBasket />} />
          </Route>
        </Route>

        <Route path="approver" element={<ApproverLayout />}>
          <Route
            path="approvalAdjustment/:consecutive/:approvalId/:approvalParam"
            element={<AdjustmentService />}
          />
          <Route
            path="approvalAdjustment/:consecutive/:approvalId/:approvalParam/confirmation"
            element={<AdjustmentConfirmation />}
          />
          <Route
            path=":consecutive/:approvalId/approved"
            element={<ApprovalConfirmed />}
          />
          <Route
            path="unitsCorporatives/rules-review"
            element={<RulesReview />}
          />
        </Route>

        <Route path="forgotPassword/:email" element={<ForgotPassword />} />
        <Route path="createPassword/:email" element={<CreatePassword />} />
        <Route
          path="signatures/sign/:token/:signatureId/:signatureType"
          element={<ExternalSign />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="maintenance" element={<ModuleMaintenance />} />
      <Route path="downloadFile" element={<DownloadFile />} />

      <Route
        path="activations/:email/:isAuthorized"
        element={<AuthorizeUser />}
      />
    </Routes>
  );
}
export default AppRoutes;
