import BadgeIcon from "@mui/icons-material/Badge";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import GestureOutlinedIcon from "@mui/icons-material/GestureOutlined";
import KeyIcon from "@mui/icons-material/Key";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import dateFormat from "dateformat";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../../../../../components/loadingContent";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import CustomSearchFilter from "../../../../../components/Search/CustomSearchFilter";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import {
  fetchSignatures,
  setSignaturesSidePanelStatus,
} from "../../../../../parts/signatures/signaturesSlice";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import {
  DownloadDocsPanel,
  ReturnSigntureSidePanel,
  deleteSignatureSidePanel,
} from "../../../../../services/general/requestServices";
import { CODES } from "../../../../../utils/codes";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const SignPrincipalView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [filteredSignatures, setFilteredSignatures] = useState([]);
  const [anchorElSort, setAnchorElSort] = useState(null);
  //CompanyPlan State-Values
  const [isOpenModalSignaturesLimitPlan, setIsOpenModalSignaturesLimitPlan] =
    useState(false);
  const [isOpenModalStorageLimitPlan, setIsOpenModalStorageLimitPlan] =
    useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);
  const [isSignaturesPlanLimit, setIsSignaturesPlanLimit] = useState(false);
  const [isOpenModalResume, setIsOpenModalResume] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModalDeleteSignature, setIsOpenModalDeleteSignature] = useState({open: false, id: null});
  const [isOpenModalDeleteResponse, setIsOpenModalDeleteResponse] = useState(false);


  const companyPlan = useSelector(getCompanyPlan);
  const { signaturesResume, signatureStatus } = useSelector(
    (state) => state.signatures
  );

  //Constants
  const openSortMenu = Boolean(anchorElSort);
  const SIGN_STATE_PENDING = "Pendiente";
  const SIGN_STATE_SIGNED = "Firmado";
  const menuElement = [
    {
      id: 0,
      selectionClick: 0,
      title: "Identificador (Menor - Mayor)",
      icon: <KeyIcon fontSize="large" className="heading__primary-color" />,
      type: "asc",
    },
    {
      id: 1,
      selectionClick: 0,
      title: "Identificador (Mayor - Menor)",
      icon: <KeyIcon fontSize="large" className="heading__primary-color" />,
      type: "desc",
    },
    {
      id: 2,
      selectionClick: 1,
      title: "Asunto de solicitud (A - Z)",
      icon: <SubjectIcon fontSize="large" className="heading__primary-color" />,
      type: "asc",
    },
    {
      id: 3,
      selectionClick: 1,
      title: "Asunto de solicitud (Z - A)",
      icon: <SubjectIcon fontSize="large" className="heading__primary-color" />,
      type: "desc",
    },
    {
      id: 4,
      selectionClick: 2,
      title: "Nombre del solicitante (A - Z)",
      icon: <BadgeIcon fontSize="large" className="heading__primary-color" />,
      type: "asc",
    },
    {
      id: 5,
      selectionClick: 2,
      title: "Nombre del solicitante (Z - A)",
      icon: <BadgeIcon fontSize="large" className="heading__primary-color" />,
      type: "desc",
    },
    {
      id: 6,
      selectionClick: 3,
      title: "Nombre del primer firmante (A - Z)",
      icon: (
        <FirstPageIcon fontSize="large" className="heading__primary-color" />
      ),
      type: "asc",
    },
    {
      id: 7,
      selectionClick: 3,
      title: "Nombre del primer firmante (Z - A)",
      icon: (
        <FirstPageIcon fontSize="large" className="heading__primary-color" />
      ),
      type: "desc",
    },
    {
      id: 8,
      selectionClick: 4,
      title: "Fecha de creación (Reciente - Antigua)",
      icon: (
        <CalendarMonthIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      type: "asc",
    },
    {
      id: 9,
      selectionClick: 4,
      title: "Fecha de creación (Antigua - Reciente)",
      icon: (
        <CalendarMonthIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      type: "desc",
    },
    {
      id: 10,
      selectionClick: 5,
      title: "Estado firmante (A - Z)",
      icon: (
        <SortOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      type: "asc",
    },
    {
      id: 11,
      selectionClick: 5,
      title: "Estado firmante (Z - A)",
      icon: (
        <SortOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      type: "desc",
    },
  ];

  //Handlers
  const handleDownloadDocument = async (
    filename,
    consecutive,
    documentId,
    documentType,
    signatureId,
    downloadType,
    bucketName,
    filenameInBucket
  ) => {
    try {
      setIsLoading(true);
      const obj = {
        consecutive,
        documentId,
        documentType,
        signatureId,
        downloadType,
        bucketName,
        filenameInBucket
      };
      const response = await DownloadDocsPanel(obj);
      if (response.status) {
        if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(response.data, `${filename.split(".")[0]}.pdf`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterById = (type) => {
    let filter = [...signaturesResume];
    if (type === "asc") {
      filter.sort((a, b) => a?.consecutive - b?.consecutive);
    } else if (type === "desc") {
      filter.sort((a, b) => b?.consecutive - a?.consecutive);
    }
    setFilteredSignatures(filter);
  };

  const filterByDescription = (type) => {
    let filter = [...signaturesResume];
    if (type === "asc") {
      filter.sort((a, b) => a?.description.localeCompare(b?.description));
    } else if (type === "desc") {
      filter.sort((a, b) => b?.description.localeCompare(a?.description));
    }
    setFilteredSignatures(filter);
  };

  const filterByNameApplicant = (type) => {
    let filter = [...signaturesResume];
    if (type === "asc") {
      filter.sort((a, b) => {
        const nameA = `${
          a?.applicant?.firstName ||
          a?.applicant?.companies[0]?.companyData?.companyName ||
          ""
        } ${a?.applicant?.firstSurname || ""}`;
        const nameB = `${
          b?.applicant?.firstName ||
          b?.applicant?.companies[0]?.companyData?.companyName ||
          ""
        } ${b?.applicant?.firstSurname || ""}`;
        return nameA.localeCompare(nameB);
      });
    } else if (type === "desc") {
      filter.sort((a, b) => {
        const nameA = `${
          a?.applicant?.firstName ||
          a?.applicant?.companies[0]?.companyData?.companyName ||
          ""
        } ${a?.applicant?.firstSurname || ""}`;
        const nameB = `${
          b?.applicant?.firstName ||
          b?.applicant?.companies[0]?.companyData?.companyName ||
          ""
        } ${b?.applicant?.firstSurname || ""}`;
        return nameB.localeCompare(nameA);
      });
    }
    setFilteredSignatures(filter);
  };

  const getName = (data) => {
    let name = "";
    if (data?.signatoriesInfo?.length) {
      name = `${
        data?.signatoriesInfo[0]?.firstName ||
        data?.signatoryData?.firstName ||
        ""
      } ${
        data?.signatoriesInfo[0]?.firstSurname ||
        data?.signatoryData?.firstSurname ||
        ""
      }`;
    } else {
      name = `${data?.signatoryData?.firstName || ""} ${
        data?.signatoryData?.firstSurname || ""
      }`;
    }
    return name;
  };

  const filterByNameFirstSignatory = (type) => {
    let filter = [...signaturesResume];
    if (type === "asc") {
      filter.sort((a, b) => {
        let nameA = getName(a);
        let nameB = getName(b);
        return nameA.localeCompare(nameB);
      });
    } else if (type === "desc") {
      filter.sort((a, b) => {
        let nameA = getName(a);
        let nameB = getName(b);
        return nameB.localeCompare(nameA);
      });
    }
    setFilteredSignatures(filter);
  };

  const filterByCreatedAt = (type) => {
    let filter = [...signaturesResume];
    if (type === "asc") {
      filter.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
    } else if (type === "desc") {
      filter.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
    }
    setFilteredSignatures(filter);
  };

  const filterByState = (type) => {
    let filter = [...signaturesResume];
    if (type === "asc") {
      filter.sort((a, b) =>
        a?.signatureState?.name.localeCompare(b?.signatureState?.name)
      );
    } else if (type === "desc") {
      filter.sort((a, b) =>
        b?.signatureState?.name.localeCompare(a?.signatureState?.name)
      );
    }
    setFilteredSignatures(filter);
  };

  const handleClickFilter = (event) => {
    setAnchorElSort(null);
    switch (event.selectionClick) {
      case 0:
        filterById(event.type);
        break;
      case 1:
        filterByDescription(event.type);
        break;
      case 2:
        filterByNameApplicant(event.type);
        break;
      case 3:
        filterByNameFirstSignatory(event.type);
        break;
      case 4:
        filterByCreatedAt(event.type);
        break;
      case 5:
        filterByState(event.type);
        break;
      default:
        break;
    }
  };

  const handleReturnSignature = async (signatureId, restartOTP = false) => {
    try {
      setIsLoading(true);
      const response = await ReturnSigntureSidePanel({ signatureId, restartOTP });
      dispatch(setSignaturesSidePanelStatus({ status: "fetch" }));
      setIsOpenModalResume(true);
      setResponseData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSignature = async (id) => {
    try {
      setIsOpenModalDeleteSignature({open: false, id: null});
      setIsLoading(true);
      const responseDelete = await deleteSignatureSidePanel(id);
      if (responseDelete.status === CODES.COD_RESPONSE_HTTP_OK) {
        dispatch(setSignaturesSidePanelStatus({ status: "fetch" }));
      }
      setResponseData(responseDelete);
      setIsOpenModalDeleteResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //useEffects
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (signatureStatus === "fetch") {
          dispatch(fetchSignatures());
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [signatureStatus, dispatch]);

  useEffect(() => {
    if (signatureStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [signatureStatus]);

  useEffect(() => {
    if (typeof signaturesResume !== "string") {
      setFilteredSignatures(signaturesResume);
    }
  }, [dispatch, signaturesResume]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isSignatureEnable) {
        setIsSignaturesPlanLimit(true);
        setIsOpenModalSignaturesLimitPlan(true);
      }
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
        setIsOpenModalStorageLimitPlan(true);
      }
    }
  }, [companyPlan]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row className="col-start">
        <Breadcrum />
      </Row>
      <Row>
        <Col md={2} className="col-start">
          <SectionTitle title="Firmar" />
        </Col>
      </Row>
      <br />
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col md={5}>
          <CustomSearchFilter
            list={signaturesResume}
            setFilteredData={setFilteredSignatures}
            placeholder={"Buscar firma"}
          />
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<SortOutlinedIcon fontSize="large" />}
            endIcon={<KeyboardArrowDownOutlinedIcon fontSize="large" />}
            onClick={(event) => setAnchorElSort(event.currentTarget)}
          >
            Ordenar
          </Button>
          <Menu
            anchorEl={anchorElSort}
            open={openSortMenu}
            onClose={() => setAnchorElSort(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuElement.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => {
                  handleClickFilter(item);
                }}
                style={{ width: "351px" }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontFamily: "Roboto",
                    fontSize: "1.4rem",
                    fontWeight: "regular",
                    lineHeight: "2rem",
                    color: "#676879",
                  }}
                  primary={item.title}
                />
              </MenuItem>
            ))}
          </Menu>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={() => {
              if (isStoragePlanLimit || isSignaturesPlanLimit) return;
              navigate("/service/documentToSign");
            }}
            startIcon={<GestureOutlinedIcon fontSize={"large"} />}
            disabled={isStoragePlanLimit || isSignaturesPlanLimit}
          >
            Firmar documento
          </Button>
        </Col>
      </Row>
      <Row>
        {signaturesResume !== "No se encontraron firmas" ? (
          <Col>
            <div
              style={{ overflow: "scroll", height: "70vh", padding: "10px" }}
            >
              {filteredSignatures?.length > 0 &&
                filteredSignatures.map((documentInfo) => (
                  <Accordion
                    className="custom-accordion"
                    key={documentInfo.consecutive}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Row style={{ width: "100%" }}>
                        <Col md={"auto"}>
                          <p className="heading__primary-color">
                            {documentInfo.consecutive}
                          </p>
                        </Col>
                        <Col md={2}>
                          <p className="heading__primary-color">
                            {documentInfo.description}
                          </p>
                        </Col>
                        <Col md={2}>
                          <p className="caption" style={{ fontWeight: "bold" }}>
                            Solicitante:{" "}
                            <small className="caption">
                              {`${
                                documentInfo?.applicant?.firstName ||
                                documentInfo?.applicant?.companies[0]
                                  ?.companyData?.companyName ||
                                ""
                              } ${documentInfo?.applicant?.firstSurname || ""}`}
                            </small>
                          </p>
                        </Col>
                        <Col md={2}>
                          <p className="caption" style={{ fontWeight: "bold" }}>
                            1er Firmante:{" "}
                            <small className="caption">
                              {documentInfo?.signatoriesInfo?.length > 0
                                ? `${
                                    documentInfo?.signatoriesInfo[0]
                                      ?.firstName ||
                                    documentInfo?.signatoryData?.firstName ||
                                    ""
                                  } ${
                                    documentInfo?.signatoriesInfo[0]
                                      ?.firstSurname ||
                                    documentInfo?.signatoryData?.firstSurname ||
                                    ""
                                  }`
                                : `${
                                    documentInfo?.signatoryData?.firstName || ""
                                  } ${
                                    documentInfo?.signatoryData?.firstSurname ||
                                    ""
                                  }`}
                            </small>{" "}
                          </p>
                        </Col>
                        <Col md={2}>
                          <p className="caption" style={{ fontWeight: "bold" }}>
                            Fecha:{" "}
                            <small className="caption">
                              {moment(documentInfo.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </small>
                          </p>
                        </Col>
                        {!documentInfo?.signatoryData && (
                          <Col md={"auto"}>
                            <p
                              className="caption"
                              style={{ fontWeight: "bold" }}
                            >
                              Firmas:{" "}
                              <small className="caption">
                                {`${
                                  documentInfo.signatoriesInfo.filter(
                                    (item) => item.hasSigned
                                  ).length
                                }/${documentInfo.signatoriesInfo.length}`}
                              </small>
                            </p>
                          </Col>
                        )}
                        <Col md={"auto"}>
                          <small
                            className="caption"
                            style={{
                              backgroundColor:
                                documentInfo.signatureState.colorId ===
                                CODES.COD_LIGHT_BLUE
                                  ? "#CBECEE"
                                  : documentInfo.signatureState.colorId ===
                                    CODES.COD_LIGHT_ORANGE
                                  ? "#FFD7B1"
                                  : "#CCF4E3",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            {documentInfo.signatureState.name}
                          </small>
                        </Col>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        width: "100%",
                        boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      {documentInfo.signatureState.name ===
                      SIGN_STATE_PENDING ? (
                        <>
                          <Row style={{ alignItems: "center" }}>
                            <Col>
                              <Typography>
                                <small
                                  className="caption"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Estado de la firma:{" "}
                                </small>
                                <small
                                  className="caption"
                                  style={{
                                    backgroundColor:
                                      documentInfo.signatureState.colorId ===
                                      CODES.COD_LIGHT_BLUE
                                        ? "#CBECEE"
                                        : documentInfo.signatureState
                                            .colorId === CODES.COD_LIGHT_ORANGE
                                        ? "#FFD7B1"
                                        : "#CCF4E3",
                                    padding: "5px",
                                    borderRadius: "4px",
                                    margin: "0px 10px",
                                  }}
                                >
                                  {documentInfo.signatureState.name}
                                </small>
                                {isSignaturesPlanLimit && (
                                  <Tooltip
                                    title={
                                      <h5>
                                        Firma no realizada porque no hay firmas
                                        disponibles
                                      </h5>
                                    }
                                  >
                                    <small
                                      className="caption"
                                      style={{
                                        backgroundColor: "#F9DADE",
                                        padding: "5px",
                                        borderRadius: "4px",
                                        cursor: "default",
                                        margin: "0px 10px",
                                      }}
                                    >
                                      Sin firmas
                                    </small>
                                  </Tooltip>
                                )}
                                {" "}
                                {isStoragePlanLimit && (
                                  <Tooltip
                                    title={
                                      <h5>
                                        Firma no realizada porque no hay
                                        almacenamiento disponible
                                      </h5>
                                    }
                                  >
                                    <small
                                      className="caption"
                                      style={{
                                        backgroundColor: "#F9DADE",
                                        padding: "5px",
                                        borderRadius: "4px",
                                        cursor: "default",
                                      }}
                                    >
                                      Sin almacenamiento
                                    </small>
                                  </Tooltip>
                                )}
                                {" "}
                                {documentInfo.noOTPAvailable && (
                                  <small
                                    className="caption"
                                    style={{
                                      backgroundColor: "#F9DADE",
                                      padding: "5px",
                                      borderRadius: "4px",
                                      cursor: "default",
                                    }}
                                  >
                                    Sin OTPs
                                  </small>
                                )}
                              </Typography>
                            </Col>
                          </Row>
                          <br />
                          <Typography>
                            <small
                              className="caption"
                              style={{ fontWeight: "bold" }}
                            >
                              Documento enviado para la firma:{" "}
                            </small>
                            <small
                              className="caption"
                              style={{ color: "#17B4BC" }}
                            onClick={() =>
                              handleDownloadDocument(
                                documentInfo.originalDocument?.documentName,
                                documentInfo?.consecutive,
                                documentInfo.originalDocument?.documentId,
                                documentInfo.originalDocument?.documentType,
                                documentInfo?._id,
                                "original",
                                documentInfo.originalDocument?.bucketName,
                                documentInfo.originalDocument?.filenameInBucket,
                              )
                            }
                            >
                              {documentInfo.originalDocument?.documentName}
                            </small>
                          </Typography>
                        </>
                      ) : (<small
                          className="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Documento enviado para la firma:{" "}
                          <small
                            className="caption"
                            style={{
                              cursor: "pointer",
                              color: "#17B4BC",
                            }}
                            onClick={() =>
                              handleDownloadDocument(
                                documentInfo.originalDocument?.documentName,
                                documentInfo?.consecutive,
                                documentInfo.originalDocument?.documentId,
                                documentInfo.originalDocument?.documentType,
                                documentInfo?._id,
                                "original",
                                documentInfo.originalDocument?.bucketName,
                                documentInfo.originalDocument?.filenameInBucket,
                              )
                            }
                          >
                            {documentInfo.originalDocument?.documentName}
                          </small>
                        </small>)}
                      <br />
                      {documentInfo?.signatureState?.name !==
                        SIGN_STATE_PENDING && (
                        <>
                          {documentInfo?.signatureState?.name !==
                            SIGN_STATE_SIGNED && (
                            <>
                              <Typography>
                                <small
                                  className="caption"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {`${moment(documentInfo?.updatedAt).format(
                                    "DD/MM/YYYY"
                                  )} - Devolución del documento: `}
                                  <small className="caption">
                                    {!documentInfo.rejectionMessage
                                      ? "--"
                                      : documentInfo.rejectionMessage}
                                  </small>
                                </small>
                              </Typography>
                              <br />
                            </>
                          )}
                          {documentInfo?.signatureState?.name ===
                            SIGN_STATE_SIGNED && (
                            <>
                              <Typography>
                                <small
                                  className="caption"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Fecha y hora de la firma:{" "}
                                  <small className="caption">
                                    {documentInfo.signatoriesInfo[0]
                                      ?.signatureDate
                                      ? moment(
                                          documentInfo?.signatoriesInfo[0]
                                            ?.signatureDate
                                        ).format("MM/DD/YYYY HH:MM")
                                      : `Documento no firmado`}
                                  </small>
                                </small>
                              </Typography>
                              <br />
                              <Typography>
                                <small
                                  className="caption"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Documento firmado:{" "}
                                  <small
                                    className="caption"
                                    style={{
                                      cursor: "pointer",
                                      color: "#17B4BC",
                                    }}
                                    onClick={() =>
                                      handleDownloadDocument(
                                        documentInfo.originalDocument
                                          ?.documentName,
                                        documentInfo?.consecutive,
                                        documentInfo.originalDocument
                                          ?.documentId,
                                        documentInfo.originalDocument
                                          ?.documentType,
                                        documentInfo?._id,
                                        "signed",
                                        documentInfo.signedDocument?.bucketName,
                                        documentInfo.signedDocument?.filenameInBucket,
                                      )
                                    }
                                  >
                                    {documentInfo.signedDocument?.filenameInBucket
                                      .split("/")
                                      .pop()}
                                  </small>
                                </small>
                              </Typography>
                              <br />
                            </>
                          )}
                        </>
                      )}
                      {documentInfo?.signatoriesInfo?.length > 0 && (
                        <Typography>
                          <small
                            className="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            {`Firmante(s): `}{" "}
                          </small>
                          <br />
                          {documentInfo?.signatoriesInfo.map(
                            (signatory, index) => (
                              <div key={signatory?._id}>
                                <br />
                                <small className="caption">{`${
                                  signatory?.firstName || ""
                                } ${signatory?.secondName || ""} ${
                                  signatory?.firstSurname || ""
                                } ${signatory?.secondSurname || ""} - `}</small>
                                <small
                                  className="caption"
                                  style={{
                                    backgroundColor: signatory.hasSigned
                                      ? "#CCF4E3"
                                      : signatory.hasRejected
                                      ? "#FFD7B1"
                                      : "#CBECEE",
                                    padding: "5px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {`${
                                    signatory?.hasSigned
                                      ? "Firmado"
                                      : signatory?.hasRejected
                                      ? "Devuelto"
                                      : "Pendiente"
                                  }`}
                                </small>
                                <br />
                              </div>
                            )
                          )}
                        </Typography>
                      )}
                      <hr />
                      <Row style={{ alignItems: "center", justifyContent: "end"}}>
                        <Col xs={"auto"}>
                          <ButtonTooltip
                            variant="contained"
                            type="button"
                            className="custom-input__button__secondary-color"
                            onClick={() => setIsOpenModalDeleteSignature({open: true, id: documentInfo?._id})}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            textTooltip={"Elimina un proceso de firma en curso"}
                            textButton={"Eliminar"}
                          />
                        </Col>
                        {documentInfo?.signatureState?.name === SIGN_STATE_PENDING && (
                          <Col xs={"auto"}>
                            <ButtonTooltip
                              variant="contained"
                              type="button"
                              className="custom-input__button__secondary-color"
                              onClick={() =>
                                handleReturnSignature(documentInfo?._id)
                              }
                              startIcon={<NotificationsActiveOutlinedIcon />}
                              textTooltip={"Reenviar recordatorio para iniciar proceso de firma"}
                              textButton={"Recordar"}
                            />
                          </Col>
                        )}
                        {(!isSignaturesPlanLimit ||
                              !isStoragePlanLimit ||
                              documentInfo.noOTPAvailable) &&
                              documentInfo.isSuspended && (
                                <Col xs={"auto"}>
                                  <ButtonTooltip
                                    variant="contained"
                                    type="button"
                                    className="custom-input__button__secondary-color"
                                    onClick={() =>
                                      handleReturnSignature(documentInfo?._id, true)
                                    }
                                    startIcon={<CachedOutlinedIcon />}
                                    textTooltip={
                                      documentInfo.noOTPAvailable? (
                                        <h5>
                                          El firmante ha alcanzado el máximo de intentos disponibles para firmar.{" "}
                                        </h5>
                                      ) : !isStoragePlanLimit ? (
                                        <h5>
                                        Ya cuentas con almacenamiento disponible,
                                        reanuda tu proceso de firma.{" "}
                                      </h5>
                                      ) : !isSignaturesPlanLimit ? (
                                        <h5>Ya cuentas con firmas disponibles, reanuda tu proceso de firma.{" "}
                                      </h5>
                                      ) : (
                                        <h5>
                                        Ya puedes reanudar tu proceso de firma.{" "}
                                      </h5>
                                      )
                                    }
                                    textButton={"Reanudar"}
                                  />
                                </Col>
                              )}
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </Col>
        ) : (
          <Container
            style={{
              borderRadius: "10px",
              boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.07)",
              padding: "30px",
            }}
          >
            <Row
              className="home-init-cards-row__title-row"
              style={{ fontSize: "20px", justifyContent: "center" }}
            >
              Aún no cuentas con procesos de firmas abiertos.
            </Row>
            <Row>
              <Col>
                <img
                  src={require("../../../../../assets/svg/NotFoundCharacter.png")}
                  alt=""
                  style={{
                    width: "300px",
                    height: "300px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Row>
      <ModalDecision
        title={"Límite de firmas"}
        message={
          "No tienes firmas disponibles para continuar, comunícate con el usuario administrador para adaptar el plan."
        }
        agreeText={"Aceptar"}
        handleAgree={() => {
          setIsOpenModalSignaturesLimitPlan(false);
        }}
        onClose={() => {
          setIsOpenModalSignaturesLimitPlan(false);
        }}
        open={isOpenModalSignaturesLimitPlan}
        type={"warning"}
      />
      <ModalDecision
        title={"Límite de almacenamiento"}
        message={
          "No tienes almacenamiento disponible para continuar, comunícate con el Usuario Administrador para adaptar el plan."
        }
        agreeText={"Aceptar"}
        handleAgree={() => {
          setIsOpenModalStorageLimitPlan(false);
        }}
        onClose={() => {
          setIsOpenModalStorageLimitPlan(false);
        }}
        open={isOpenModalStorageLimitPlan}
        type={"warning"}
      />
      <ModalInfo
        title="Reanudar firma"
        onClose={() => setIsOpenModalResume(false)}
        open={isOpenModalResume}
        responseData={responseData}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Eliminar"}
        message={"¿Seguro que quieres eliminar este proceso de firma?"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => handleDeleteSignature(isOpenModalDeleteSignature.id)}
        onClose={() => {
          setIsOpenModalDeleteSignature({open: false, id: null});
        }}
        handleDisagree={() => {
          setIsOpenModalDeleteSignature({open: false, id: null});
        }}
        open={isOpenModalDeleteSignature.open}
        type={"warning"}
      />
      <ModalInfo
        title="Eliminar firma"
        onClose={() => setIsOpenModalDeleteResponse(false)}
        open={isOpenModalDeleteResponse}
        responseData={responseData}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

const ButtonTooltip = ({ textTooltip, textButton, ...props }) => (<Tooltip title={<h5>{textTooltip}</h5>}>
  <Button
    {...props}
  >
    {textButton}
  </Button>
</Tooltip>);

export default SignPrincipalView;
