import { Button } from "@mui/material";
import { Buffer } from "buffer";
import { MDBBadge, MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import { WordBlobOtherDocument, wordCreatorFile } from "../../../../../../components/WordCreator/WordCreator";
import Breadcrum from "../../../../../../parts/breadcrum";
import { getCurrentDocument } from "../../../../../../parts/document/currentDocumentSlice";
import {
  getSelectedHeader,
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../../parts/headings/headingsSlice";
import { previewHeadingDocument } from "../../../../../../services/admin/documents/headingsServices";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import {
  changeRequestState,
  findOneDataExternalRequest,
  PreFilterFromHome,
  SendInfoToApplicant,
  ShowDataExternalRequest,
} from "../../../../../../services/general/CarvajalServices";
import { CODES } from "../../../../../../utils/codes";
import SearchBarCarvajal from "../../request/Carvajal/SearchBarCarvajal";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
const SelectedRequest = () => {
  const navigate = useNavigate();
  const currentDocument = useSelector(getCurrentDocument);
  const { saveAs } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);
  const [filterMyId, setFilterMyId] = useState(false);
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const isQueryParam = searchParams.get("filter");
  const selectedHeader = useSelector(getSelectedHeader);
  const dispatch = useDispatch();
  const columns = [
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="1"
        >
          Caso No.
        </label>,
      ],
      field: "idCons",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="2"
        >
          Asunto
        </label>,
      ],
      field: "requestMatter",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="3"
        >
          Solicitante
        </label>,
      ],
      field: "applicant",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="4"
        >
          Requiere
        </label>,
      ],
      field: "requestType",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="5"
        >
          Fecha de creación
        </label>,
      ],
      field: "creationDate",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="7"
        >
          Estado
        </label>,
      ],
      field: "status",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="8"
        >
          Empresa
        </label>,
      ],
      field: "company",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="9"
        >
          Acción
        </label>,
      ],
      field: "example",
    },
  ];

  const [DataTable, setDataTable] = useState({
    columns: [],
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: [],
    rows: [],
  });

  const getBackgroundColor = (colorId) => {
    switch (colorId) {
      case CODES.COD_LIGHT_ORANGE:
        return "#FFD7B1";
      case CODES.COD_LIGHT_BLUE:
        return "#CBECEE";
      case CODES.COD_LIGHT_GREEN:
        return "#CCF4E3";
      case CODES.COD_LIGHT_RED:
        return "#F9DADE";
      case CODES.COD_LIGHT_PURPLE:
        return "#E6E9EF";
      default:
        return "#F3DEBC";
    }
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (isQueryParam) {
          let states = [];
          if (isQueryParam === "open") {
            states = ["Nueva", "Revisión solicitante", "Revisión abogado"];
          } else if (isQueryParam === "attorneyReview") {
            states = ["Revisión abogado"];
          } else if (isQueryParam === "expired") {
            states = ["Cancelada"];
          }

          const { company, userId } = JSON.parse(
            localStorage.getItem("payloadToken")
          );

          const corporateUnit = localStorage.getItem("corporateUnitId");

          const obj = {
            applicantsIds: [],
            states: states,
            names: [],
            attorneyId: userId,
            companies: [],
            company: company,
            corporateUnit: corporateUnit,
          };

          const service = await PreFilterFromHome(obj);
          if (service.status) {
            if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
              const { responseMessage } = service.data;
              const ProcessRows = responseMessage?.map((item) => {
                const { firstName, firstSurname } = item.applicant;
                if (
                  item.requestStatus?.name === "Revisión solicitante" ||
                  item.requestStatus?.name === "Revisión abogado"
                ) {
                  return {
                    idCons: !item.consecutive ? " - " : item.consecutive,
                    requestMatter: !item.requestSubject
                      ? " - "
                      : item.requestSubject,
                    applicant: !firstName
                      ? "-"
                      : ` ${firstName} ${firstSurname}`,
                    requestType:
                      item.requestType?.type !== "Otrosí"
                        ? item.requestType?.type
                        : item.requestSubtype,
                    creationDate: !item.createdAt ? "-" : item.createdAt,
                    status: (
                      <MDBBadge
                        color=""
                        pill
                        className="caption"
                        style={{
                          backgroundColor: getBackgroundColor(
                            item.requestStatus.colorId
                          ),
                          fontSize: "1.2rem",
                          borderRadius: "0.8rem",
                          padding: "0.4rem",
                        }}
                      >
                        <span className="custom-badge">
                          {item.requestStatus.name}
                        </span>
                      </MDBBadge>
                    ),
                    example: !item.example ? (
                      <Button
                        variant="contained"
                        size="small"
                        className="custom-input__button__primary-color"
                        onClick={() => {
                          handleAddDocToRequest(item);
                        }}
                      >
                        Seleccionar
                      </Button>
                    ) : (
                      item.example
                    ),
                    company: !item.applicantCompany
                      ? "--"
                      : item.applicantCompany,
                  };
                }
              });

              setDataTable({
                columns: columns,
                rows: ProcessRows,
              });
              setFilteredDataTable({
                columns: columns,
                rows: ProcessRows,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [isQueryParam]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        if (!isQueryParam) {
          const { company } = JSON.parse(localStorage.getItem("payloadToken"));
          const obj = {
            filterStatus: filterStatus,
            optionsFilterBar: optionsFilterBar,
            filterMyId: filterMyId,
            companyId: company,
          };
          const GetDataTable = await ShowDataExternalRequest(obj);

          if (GetDataTable.status === CODES.COD_RESPONSE_HTTP_OK) {
            const { responseMessage } = GetDataTable.data;
            const ProcessRows = responseMessage?.map((item) => {
              const { firstName, firstSurname } = item?.applicant || {
                firstName: "",
                firstSurname: "",
              };
              if (
                item.requestStatus?.name === "Revisión solicitante" ||
                item.requestStatus?.name === "Revisión abogado"
              ) {
                return {
                  idCons: !item.consecutive ? " - " : item.consecutive,
                  requestMatter: !item.requestSubject
                    ? " - "
                    : item.requestSubject,
                  applicant:
                    firstName && firstSurname
                      ? ` ${firstName} ${firstSurname}`
                      : "-",
                  requestType:
                    item.requestType?.type !== "Otrosí"
                      ? item.requestType?.type
                      : item.requestSubtype,
                  creationDate: !item.createdAt ? "-" : item.createdAt,
                  status: (
                    <MDBBadge
                      color=""
                      pill
                      className="caption"
                      style={{
                        backgroundColor: getBackgroundColor(
                          item.requestStatus?.colorId
                        ),
                        fontSize: "1.2rem",
                        borderRadius: "0.8rem",
                        padding: "0.4rem",
                      }}
                    >
                      <span className="custom-badge">
                        {item.requestStatus?.name}
                      </span>
                    </MDBBadge>
                  ),
                  example: !item.example ? (
                    <Button
                      variant="contained"
                      size="small"
                      className="custom-input__button__primary-color"
                      onClick={() => {
                        handleAddDocToRequest(item);
                      }}
                    >
                      Seleccionar
                    </Button>
                  ) : (
                    item.example
                  ),
                  company: !item.applicantCompany
                    ? "--"
                    : item.applicantCompany,
                };
              }
            });

            setDataTable({
              columns: columns,
              rows: ProcessRows,
            });
            setFilteredDataTable({
              columns: columns,
              rows: ProcessRows,
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [filterStatus, optionsFilterBar, filterMyId, isQueryParam]);

  const handleAddDocToRequest = async (infoRequest) => {
    try {
      setIsLoading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const company = localStorage.getItem("company");

      const consecutiveRequestInfo = {
        consecutive: infoRequest.consecutive,
        company,
        corporateUnit,
      };
      const infoSelectedRequest = await findOneDataExternalRequest(
        consecutiveRequestInfo
      );
      const data = infoSelectedRequest.data.responseMessage;
      let fileWord;
      if (saveAs === "otherDoc") {
        fileWord = await WordBlobOtherDocument({
          title: currentDocument.additionalInfoOtherDocument.title,
          content: currentDocument.additionalInfoOtherDocument.text
        })
      } else {
        fileWord = await wordCreatorFile({
          title: currentDocument.title,
          headers: currentDocument.headers,
          parts: currentDocument.parts,
          clauses: currentDocument.clauses,
        });
      }
      let formatedDoc = new File([fileWord], currentDocument.name, {
        type: fileWord.type,
      });

      if (selectedHeader) {
        const obj = {
          file: formatedDoc,
          ...(selectedHeader.documentDocxId && {
            documentDocxTemplate: selectedHeader.documentDocxId._id,
          }),
          documentPdfTemplate: selectedHeader.documentPdfId._id,
          originalname: currentDocument.title + ".docx",
          contentType: fileWord.type,
        };
        const response = await previewHeadingDocument(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          const file = await handleDownloadDocument(
            response.data.responseMessage.data,
            false,
            currentDocument.title + ".docx",
            fileWord.type
          );

          formatedDoc = file;
        }
      }

      let obj = {
        companyId: company,
        corporateUnit,
        consecutive: data.consecutive,
        applicantId: data.applicant._id,
        otherDocuments: [],
        comment: "Documento agregado desde modulo crear",
        principalDocument: formatedDoc,
        attorneyName: `${payloadToken?.firstName || ""} ${
          payloadToken?.firstSurname || ""
        }`,
        requiredDocuments: [],
        applicantName: `${data?.applicant?.firstName || ""} ${
          data?.applicant?.firstSurname || ""
        }`,
      };

      const service = await SendInfoToApplicant(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          let objState = {
            requestId: data._id,
            state: "Revisión solicitante",
            message: "Documentos agregados.",
            isActive: true,
            timeZone: new Date().getTimezoneOffset() / -60,
          };
          const response = await changeRequestState(objState);
          if (response?.status === CODES.COD_RESPONSE_HTTP_OK) {
            navigate("/service/folders");
            setIsLoading(false);
          } else if (response?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
            setIsOpenModalUnauthorized(true);
            setResponseDataUnauthorized(response);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      dispatch(setSelectedHeader(null));
      dispatch(setSelectedHeaderId(null));
    }
  };

  const handleDownloadDocument = async (actualDocument, isWord, name, type) => {
    try {
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      setIsLoading(false);
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const blob = new File([bf], name, {
        type: type,
      });
      return blob;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <>
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={"Solicitudes"} />
        </Row>
        <br />
        <div
          className="data-table dt-list-items"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc(100vh - 10rem)",
          }}
        >
          <Row className="caption" style={{ margin: "0.7rem 0" }}>
            Selecciona la solicitud que quieres vincular
          </Row>
          <Row style={{ display: "flex", marginTop: "1rem" }}>
            <SearchBarCarvajal
              DataTable={DataTable}
              setDataTable={setDataTable}
              filteredDataTable={filteredDataTable}
              setFilteredDataTable={setFilteredDataTable}
              setFilterStatus={setFilterStatus}
              setOptionsFilterBar={setOptionsFilterBar}
              filterMyId={filterMyId}
              setFilterMyId={setFilterMyId}
              setSearchParams={setSearchParams}
            />
          </Row>
          <Row style={{ marginBottom: "2rem" }}></Row>
          <Row>
            {
              <MDBDataTableV5
                hover
                pagingTop
                searchBottom={false}
                entries={7}
                data={filteredDataTable}
                entriesLabel=""
                infoLabel={["Mostrando", "a", "de", "solicitudes"]}
                fullPagination
                noRecordsFoundLabel={"No se han encontrado solicitudes."}
              />
            }
          </Row>
        </div>
      </>
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default SelectedRequest;
