import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, ListItemText, Menu, MenuItem, TextField } from "@mui/material";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";

import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  PersonaJuridicaFields,
  PersonaNaturalFields,
  SignatariAPJ,
  SignatariAPN,
  SignatariOPJ,
  SignatariOPN,
} from "../../../../../../../utils/signatoriesTypes";

import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addNewPart } from "../../../../../../../parts/document/currentDocumentSlice";
const CreatePart = () => {
  /**
   * Validators
   */
  const schemaConstantParts = yup.object().shape({
    partName: yup.string().required("Este campo es obligatorio"),
    partDescription: yup.string().notRequired(),
  });

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Form
   */
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaConstantParts),
  });

  const {
    fields: signatoriesFields,
    append: signatoriesAppend,
    remove: signatoriesRemove,
  } = useFieldArray({
    control,
    name: "signatories",
  });

  /**
   * UseDispatch
   */

  const dispatch = useDispatch();

  /**
   * Use State
   */

  //New Part
  const [newPart, setNewPart] = useState({
    name: "",
    text: "",
    signatories: [],
  });

  //Text part
  const [partText, setPartText] = useState(true);

  //Anchors
  const [anchorSignatories, setAnchorSignatories] = useState(null);
  const openSignatories = Boolean(anchorSignatories);

  //Modals
  const [openDiscard, setOpenDiscard] = useState(false);
  const [openSave, setOpenSave] = useState(false);

  /**
   * Handles
   */

  //Submit, entro la info a un objeto
  const handleSubmitInfo = (data) => {
    if (data.partName !== "") {
      setOpenSave(true);

      const nam = data.partName;
      const descrip = partText ? "" : data.partDescription;
      const signato = data.signatories.length ? data.signatories : [];
      setNewPart({
        name: nam,
        text: descrip,
        signatories: signato,
      });
    }
  };

  //Anchors

  const handleClickSignatories = (event) => {
    setAnchorSignatories(event.currentTarget);
  };

  const handleCloseSignatories = () => {
    setAnchorSignatories(null);
  };

  //Adds new Signatory

  const handleAddSignatory = (signatory, blankspaces) => {
    const newSignatory = {
      ...signatory,
      blankspaces: blankspaces,
      signatures: {
        attributes: [],
      },
    };
    signatoriesAppend(newSignatory);
  };

  //Menu Selection

  const handleMenuClick = (selec) => {
    switch (selec) {
      case 0:
        setAnchorSignatories(null);
        handleAddSignatory(SignatariOPN, PersonaNaturalFields);
        break;
      case 1:
        setAnchorSignatories(null);
        handleAddSignatory(SignatariAPN, PersonaNaturalFields);
        break;

      case 2:
        setAnchorSignatories(null);
        handleAddSignatory(SignatariOPJ, PersonaJuridicaFields);
        break;
      case 3:
        setAnchorSignatories(null);
        handleAddSignatory(SignatariAPJ, PersonaJuridicaFields);
        break;
    }
  };

  //Delete Signatory
  const handleRemoveSignatory = (index) => {
    signatoriesRemove(index);
    const updateText = getValues(`signatories.${index}.text`);
    setValue(`signatories.${index}.text`, updateText);
  };

  //Modals

  const handleAgreeDiscard = () => {
    setOpenDiscard(false);
    navigate(-1);
  };

  const handleDisagreeDiscard = () => {
    setOpenDiscard(false);
  };

  const handleDisagreeSave = () => {
    setOpenSave(false);
  };

  const handleAgreeSave = () => {
    //Setteo a redux
    setOpenSave(false);
    dispatch(addNewPart(newPart));
    navigate(-1);
  };

  //Blankspaces

  const handleBSChange = (index, sigIndex, newText) => {
    //Pido los values
    const signatoryText = getValues(`signatories.${sigIndex}.text`);

    //Spliteo y seteo el nuevo valor en arreglo
    const splittedText = signatoryText.split("¬");
    splittedText[index] = newText;
    const finalText = splittedText.join("¬");

    //Seteo value en form
    setValue(`signatories.${sigIndex}.text`, finalText);
  };

  /**
   * Constantes
   */

  const menuSignatories = [
    {
      title: "SignatariO (Persona natural)",
      selectionClick: 0,
    },
    {
      title: "SignatariA (Persona natural)",
      selectionClick: 1,
    },
    {
      title: "SignatariO (Persona jurídica)",
      selectionClick: 2,
    },
    {
      title: "SignatariA (Persona jurídica)",
      selectionClick: 3,
    },
  ];

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container"
    >
      {/**Breadcrum */}
      <Row style={{ height: "7%" }} className="col-start">
        <Breadcrum />
      </Row>
      {/**Title and notification */}
      <Row style={{ height: "8%" }}>
        <Col md={3} className="col-start">
          <SectionTitle title="Agregar parte y signatarios" />
        </Col>
        <Col md={8}></Col>
        {/* <Col md={"auto"} className="col-flex">
          <IconButton size="large">
            <NotificationsNoneOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </IconButton>
        </Col> */}
      </Row>
      {/**Adding new signatories */}
      <Row style={{ height: "83vh" }}>
        <Form onSubmit={handleSubmit(handleSubmitInfo)}>
          <Row>
            <Container fluid style={{ height: "100%" }}>
              <Row className="crud-titles" style={{ height: "10vh" }}>
                Agregar Signatarios
              </Row>
              <Row>
                {signatoriesFields.map((signatory, index1) => (
                  <Container fluid key={uuidv4()}>
                    <Row
                      style={{
                        height: signatory.type === "natural" ? "36vh" : "68vh",
                      }}
                      key={uuidv4()}
                    >
                      <Col xs={4}>
                        <Container fluid style={{ height: "100%" }}>
                          <Row
                            className="labels-to-sign"
                            style={{ height: "25%" }}
                          >
                            {signatory.type === "natural"
                              ? "Diligenciar 4 espacios:"
                              : "Diligenciar 8 espacios:"}
                          </Row>
                          {signatory.blankspaces.map((field, index2) => (
                            <Row
                              key={JSON.stringify(field)}
                              style={{ height: "6vh" }}
                            >
                              <Form.Group>
                                <Form.Control
                                  {...register(
                                    `signatories.${index1}.blankspaces.${index2}.value`
                                  )}
                                  onChange={(e) => {
                                    handleBSChange(
                                      field.index,
                                      index1,
                                      e.target.value
                                    );
                                  }}
                                  placeholder={field.name}
                                  className="input-group-container__no-icon label text-fields"
                                  bsPrefix={"text-fields"}
                                />
                              </Form.Group>
                            </Row>
                          ))}
                        </Container>
                      </Col>
                      <Col xs={8}>
                        <Container fluid style={{ height: "100%" }}>
                          <Row style={{ height: "25%" }}>
                            <Col md={11} className="crud-titles">
                              {signatory.name} {index1 + 1}
                            </Col>
                            <Col md={1} className="cols-end">
                              <Button
                                variant="contained"
                                size="small"
                                className="custom-input__button__primary-color"
                                onClick={() => {
                                  handleRemoveSignatory(index1);
                                }}
                              >
                                <DeleteOutlineIcon fontSize="large" />
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Controller
                              control={control}
                              name={`signatories.${index1}.text`}
                              defaultValue={signatory.text}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    className: "text-field-text",
                                  }}
                                  variant="outlined"
                                  multiline
                                  rows={5}
                                />
                              )}
                            />
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Row>
              <Row
                className={
                  signatoriesFields.length > 0 ? "cols-end" : "col-start"
                }
                style={{ height: "7vh" }}
              >
                <Col md={2}>
                  <Button
                    className="button-softblue-folders__labels"
                    onClick={handleClickSignatories}
                    startIcon={<AddIcon />}
                    endIcon={<ExpandMoreIcon />}
                  >
                    Agregar
                  </Button>
                </Col>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorSignatories}
                  open={openSignatories}
                  onClose={handleCloseSignatories}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  style={{
                    margin:
                      signatoriesFields.length > 0
                        ? "auto"
                        : "3px 0px 0px 90px",
                  }}
                >
                  {menuSignatories.map((menuElement, index) => (
                    <div key={uuidv4()}>
                      <MenuItem
                        onClick={() => {
                          handleMenuClick(menuElement.selectionClick);
                        }}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontFamily: "Roboto",
                            fontSize: "1.4rem",
                            fontWeight: "regular",
                            lineHeight: "2rem",
                            color: "#676879",
                          }}
                          primary={menuElement.title}
                        />
                      </MenuItem>
                      {index + 1 === menuSignatories.length ? <></> : <hr />}
                    </div>
                  ))}
                </Menu>
              </Row>
            </Container>
          </Row>

          <Row style={{ height: "13vh" }}>
            <Container fluid style={{ height: "100%" }}>
              <Row className="crud-titles" style={{ height: "6vh" }}>
                Parte
              </Row>
              <Row style={{ height: "7vh" }}>
                <Form.Group>
                  <Form.Control
                    {...register("partName")}
                    placeholder="Agregue el nombre de la parte aquí"
                    className="field-container"
                    bsPrefix={
                      errors.partName
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                </Form.Group>
                <div className="caption custom-input__error">
                  {errors.partName?.message}
                </div>
              </Row>
            </Container>
          </Row>
          <Row style={{ height: "13hv" }}>
            <Container fluid>
              <Row className="crud-titles" style={{ height: "7vh" }}>
                Texto final de la parte
              </Row>
              <Row style={{ height: "4vh", marginTop: "1vh" }}>
                <Form.Group className="body-content__disabled">
                  <Form.Check
                    type="checkbox"
                    label="Insertar texto final de la parte"
                    onChange={() => {
                      setPartText(!partText);
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="col-start" style={{ height: "7vh" }}>
                <Form.Group>
                  <Form.Control
                    {...register("partDescription")}
                    placeholder="Quienes en conjunto se denominarán la Parte"
                    className="field-container"
                    disabled={partText}
                    bsPrefix={
                      errors.partDescription
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                </Form.Group>
                <div className="caption custom-input__error">
                  {errors.partDescription?.message}
                </div>
              </Row>
            </Container>
          </Row>
          <Row style={{ height: "13vh" }}>
            <Col md={2} className="col-start">
              <Button
                className="button-softblue-folders__select"
                onClick={() => {
                  setOpenDiscard(true);
                }}
              >
                Descartar
              </Button>
            </Col>
            <Col md={"auto"} className="col-flex">
              <Button type="submit" className="button-primary-folders__select">
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      {/**Modals */}
      <ModalDecision
        title={"¿Descartar?"}
        message={"¿Estás seguro de que quieres descartar esta parte?"}
        open={openDiscard}
        onClose={handleDisagreeDiscard}
        agreeText="Descartar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeDiscard}
        handleDisagree={handleDisagreeDiscard}
      />
      <ModalDecision
        title={"Guardar"}
        message={"¿Quieres confirmar estos cambios?"}
        open={openSave}
        onClose={handleDisagreeSave}
        agreeText="Guardar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeSave}
        handleDisagree={handleDisagreeSave}
      />
    </Container>
  );
};

export default CreatePart;
