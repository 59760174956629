import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";

import CardFilter from "../../components/Cards/CardFilter";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import { Mixpanel } from "../../utils/mixPanel";
const FilterContractTypes = ({
  contractTypesList,
  list,
  setFilteredInfo,
  handleContractTypesSelected,
  classStyle = "custom-input__button__primary-color",
  widthStyle = "auto",
  heightStyle = "auto",
}) => {
  const [show, setShow] = useState(false);
  const [countChecked, setCountChecked] = useState(0);

  const [contractTypes, setContractTypes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [contractTypesSelected, setContractTypesSelected] = useState([]);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleShow = () => {
    setFilteredInfo(list);
    setContractTypes(contractTypesList);
    setFilteredData(contractTypesList);
    setShow(true);
    Mixpanel.track("Btn ver por minuta  ", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  const handleChecked = (e, selected) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setContractTypesSelected((oldArray) => [...oldArray, selected]);
    } else {
      setCountChecked(countChecked - 1);
      setContractTypesSelected(
        contractTypesSelected.filter(
          (contractType) => contractType._id !== selected._id
        )
      );
    }
  };

  const handleClose = () => {
    setContractTypesSelected([]);
    setCountChecked(0);
    setShow(false);
  };

  const handleFilterContractTypes = () => {
    if (contractTypesSelected.length > 0) {
      let clauses = [];
      let filteredClauses = [];
      contractTypesSelected.forEach((contractType) => {
        contractType.clauses.map(({ clause }) => {
          clauses.push(clause?._id);
        });
      });
      list.map((item) => {
        clauses.includes(item._id) && filteredClauses.push(item);
      });
      setFilteredInfo(filteredClauses);
      handleContractTypesSelected(contractTypesSelected);
    } else {
      setFilteredInfo(list);
    }
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<BusinessCenterIcon />}
        className={classStyle}
        onClick={handleShow}
        style={{
          width: `${widthStyle} !important`,
          height: `${heightStyle} !important`,
        }}
      >
        <p className="button">Ver por minutas</p>
      </Button>
      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color__page-title">Ver por minutas</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Por favor seleccione las minutas por las que desea filtrar el
                  listado de cláusualas:
                </p>
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} seleccionadas
                </p>
              </Col>
              <Col>
                <CustomSearchFilter
                  list={contractTypes}
                  setFilteredData={setFilteredData}
                  placeholder="Buscar minuta"
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row sm={3}>
              {typeof filteredData !== "string" &&
                filteredData.map((contractType) => {
                  return (
                    <Col
                      className="filter-tag__check"
                      style={{ marginBottom: "1.5%" }}
                      key={contractType?._id}
                    >
                      <input
                        defaultChecked={contractTypesSelected.find(
                          (item) => item?._id === contractType?._id
                        )}
                        id={contractType?._id}
                        name={contractType?.name}
                        type="checkbox"
                        onClick={(e) => handleChecked(e, contractType)}
                      />
                      <label htmlFor={contractType?._id}>
                        <CardFilter
                          icon={
                            <BusinessCenterIcon
                              fontSize="large"
                              className="filter-tag__icon"
                            />
                          }
                          title={contractType?.name}
                        />
                      </label>
                    </Col>
                  );
                })}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                <p className="button">Cancelar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={handleFilterContractTypes}
              >
                <p className="button">Filtrar</p>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterContractTypes;
