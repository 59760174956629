export const CONST_QUESTION_REQUEST_EXTERNAL = {
    ADDRESS: "Domicilio de la otra parte (ciudad)",
    DOCUMENT_NUMBER: "Número de identificación de la otra parte",
    NAME: "Nombre o razón social de la otra parte",
    TYPE_PERSON: "¿La otra parte es una persona jurídica?",
    NAME_AGENT_LEGAL: "Nombre completo del representante legal",
    DOCUMENT_NUMBER_AGENT_LEGAL: "Tipo y número de documento de identidad del representante legal",
    ADDRESS_AGENT_LEGAL: "Domicilio del representante legal (ciudad y país)",
};

export const buildTextParts = (data) => {
    if (data?.typePerson === "Persona jurídica") {
        return `${data?.nameSignatory || "¬Nombre¬"} quien se identifica con ${data?.documentTypeSignatory || "¬tipo de documento¬"} número ${data?.documentNumberSignatory || "¬número de documento¬"} y que actúa en calidad de ${data?.charge || "¬cargo del firmante¬"} de ${data?.name || "¬razón social¬"}, que se identifica con ${data?.documentType || "¬tipo de documento¬"} ${data?.documentNumber || "¬número de documento¬"} y con domicilio en ${data?.address || "¬domicilio¬"}`;
    } else if (data?.typePerson === "Persona natural") {
        return `${data?.name || "¬Nombre¬"} quien se identifica con ${data?.documentType || "¬tipo de documento¬"} número ${data?.documentNumber || "¬número de documento¬"}, con domicilio en la ciudad de ${data?.address || "¬domicilio¬"}`;
    }
}