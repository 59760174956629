import axios from "axios";
import { env } from "../../env";
import GetToken from "../../utils/getToken";

export function getConsultsByCompany(data) {
  return axios({
    method: "GET",
    url: `consults/getAll`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function saveConsult(data) {
  return axios({
    method: "POST",
    url: "chatbot/createChat",
    baseURL: env.REACT_APP_CONSULT_URL,
    validateStatus: null,
    data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function makeResponse(data) {
  return axios({
    method: "POST",
    url: "chatbot/makeChatResponse",
    baseURL: env.REACT_APP_CONSULT_URL,
    validateStatus: null,
    data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getConsultsByUser(data) {
  return axios({
    method: "GET",
    url: `chatbot/findChatsByUser`,
    baseURL: env.REACT_APP_CONSULT_URL,
    validateStatus: null,
    params: {
      id: data.userId,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateConsult(data, chatId) {
  return axios({
    method: "PUT",
    url: "chatbot/updateChat",
    baseURL: env.REACT_APP_CONSULT_URL,
    validateStatus: null,
    data,
    params: {
      id: chatId,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getConceptsByCorporateUnit(data) {
  return axios({
    method: "GET",
    url: "chatbot/conceptDocuments",
    // baseURL: env.REACT_APP_CONSULT_URL,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function uploadConcept(data) {
  const formData = new FormData();

  formData.append("company", data.company);
  formData.append("corporateUnit", data.corporateUnit);
  if (data.files?.length) {
    data.files.map((file) => {
      formData.append("documents", file);
    });
  }

  return axios({
    method: "POST",
    url: "chatbot/uploadDocument",
    // baseURL: env.REACT_APP_CONSULT_URL,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: formData,
    headers: {
      Authorization: GetToken(),
      "content-type": "application/x-www-form-urlencoded",
    },
  });
}

export function deleteConcept(data) {
  return axios({
    method: "DELETE",
    url: "chatbot/conceptDocument",
    // baseURL: env.REACT_APP_CONSULT_URL,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function uploadDocumentToSearch(data) {
  const formData = new FormData();

  formData.append("company", data.company);
  formData.append("corporateUnit", data.corporateUnit);
  formData.append("createdBy", data.createdBy);

  if (data.files?.length) {
    data.files.map((file) => {
      formData.append("documents", file);
    });
  }

  return axios({
    method: "POST",
    url: "chatbot/uploadDocument",
    // baseURL: env.REACT_APP_CONSULT_URL,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: formData,
    headers: {
      Authorization: GetToken(),
      "content-type": "application/x-www-form-urlencoded",
    },
  });
}

export function ConsultText(data) {
  return axios({
    method: "GET",
    url: "smartSearch/searchText",
    // baseURL: env.REACT_APP_CONSULT_URL,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}
