import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addEditedPart,
  deletePart,
  deleteSignatory,
  deleteSignatoryToPart,
  reorderParts,
  reorderPartsNoRol,
} from "../../../../../parts/document/currentDocumentSlice";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { PostAddOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import { getRefNormativeDocument } from "../../../../../services/documents/documentsRequest";

import { PopoverLimits } from "../../../../../components/Popover/PopoverLimits";
export const DragDropParts = ({
  arrayParts,
  partIndex,
  setOpenModalAddSignatory,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        dispatch(reorderPartsNoRol({ result, partIndex }));
      }}
    >
      <Droppable droppableId="partsToRol">
        {(droppableProvided) => (
          <Col
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {arrayParts?.length > 0 &&
              arrayParts.map((part, index) => (
                <Draggable
                  key={index + "draggableToPart"}
                  draggableId={index + "draggableToPart"}
                  index={index}
                >
                  {(draggablePorvider) => (
                    <Accordion
                      {...draggablePorvider.draggableProps}
                      {...draggablePorvider.dragHandleProps}
                      className="custom-accordion"
                      ref={draggablePorvider.innerRef}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Row style={{ width: "100%", alignItems: "center" }}>
                          <Col
                            xs={"auto"}
                            className="col-start heading__primary-color"
                          >
                            <DragIndicatorIcon
                              fontSize="large"
                              className="drag-color"
                            />
                            <p className="heading">
                              {part?.information?.name || ""}
                            </p>
                          </Col>
                          <Col />
                          {part?.limits && (
                            <>
                              <Col xs={"auto"}>
                                <span className="caption">
                                  Limitaciones a la representación legal
                                </span>
                              </Col>
                              <Col xs={"auto"}>
                                <PopoverLimits
                                  limitationsUpdatedDate={
                                    part?.limitationsUpdatedDate
                                  }
                                  createdAt={part?.limitationsCreatedDate}
                                  limitations={part?.limits}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="caption">- {part?.text}</span>
                        <hr />
                        <Row style={{ justifyContent: "end" }}>
                          <Col md={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<AddIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                setOpenModalAddSignatory({
                                  open: true,
                                  partIndex,
                                  index,
                                  library: part?.information?._id,
                                });
                              }}
                            >
                              Agregar firmante
                            </Button>
                          </Col>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<EditOutlinedIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                navigate(
                                  `parts-panel/${partIndex}/edit-signatory/${index}`
                                );
                              }}
                            >
                              Editar parte
                            </Button>
                          </Col>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                dispatch(
                                  deleteSignatory({
                                    partIndex,
                                    signatoryIndex: index,
                                  })
                                );
                              }}
                            >
                              Eliminar
                            </Button>
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Draggable>
              ))}
            {droppableProvided.placeholder}
          </Col>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const DragDropSignatories = ({ arraySignatories, partIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElDefaultSignatory, setAnchorElDefaultSignatory] = useState([]);

  const PERSON_TYPES = {
    JURIDICA: "Persona jurídica",
    NATURAL: "Persona natural",
    "Persona jurídica": "Persona jurídica",
    "Persona natural": "Persona natural",
  };

  return (
    <DragDropContext
      onDragEnd={(result) => {
        dispatch(reorderPartsNoRol({ result, partIndex }));
      }}
    >
      <Droppable droppableId="signatoriesToRol">
        {(droppableProvided) => (
          <Col
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {arraySignatories?.length > 0 &&
              arraySignatories.map((signatory, index) => (
                <Draggable
                  key={signatory.index + "sigantoriesToRol"}
                  draggableId={signatory.index + "sigantoriesToRol"}
                  index={signatory.index}
                >
                  {(draggablePorvider) => (
                    <Accordion
                      {...draggablePorvider.draggableProps}
                      {...draggablePorvider.dragHandleProps}
                      className="custom-accordion"
                      ref={draggablePorvider.innerRef}
                      key={signatory.index + "sigantoriesToRol"}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Row style={{ width: "100%", alignItems: "center" }}>
                          <Col
                            xs={"auto"}
                            className="col-start heading__primary-color"
                          >
                            <DragIndicatorIcon
                              fontSize="large"
                              className="drag-color"
                            />
                            <p className="heading">{signatory?.name || ""}</p>
                          </Col>
                          <Col />
                          <Col xs={"auto"}>
                            {signatory?.limits && (
                              <Row style={{ alignItems: "center" }}>
                                <Col xs={"auto"}>
                                  <span className="caption">
                                    Limitaciones a la representación legal
                                  </span>
                                </Col>
                                <Col xs={"auto"}>
                                  <PopoverLimits
                                    limitationsUpdatedDate={
                                      signatory?.limitationsUpdatedDate
                                    }
                                    limitations={signatory?.limits}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </AccordionSummary>
                      <AccordionDetails
                        key={signatory.index + "sigantoriesToRol"}
                      >
                        {signatory?.charge && (
                          <p className="caption">- {signatory?.charge}</p>
                        )}
                        {PERSON_TYPES[signatory.type] === "Persona natural" && (
                          <p className="caption">
                            - {signatory?.documentType}{" "}
                            {signatory.documentNumber}
                          </p>
                        )}
                        {PERSON_TYPES[signatory.type] ===
                          "Persona jurídica" && (
                          <p className="caption">- {signatory?.partName}</p>
                        )}
                        <hr />
                        <Row style={{ justifyContent: "end" }}>
                          {signatory.isDefault && (
                            <Col xs={"auto"}>
                              <div key={index}>
                                <Button
                                  variant="contained"
                                  startIcon={<PersonPinOutlinedIcon />}
                                  className="custom-input__button__secondary-color"
                                  onClick={(event) => {
                                    setAnchorElDefaultSignatory({
                                      ...anchorElDefaultSignatory,
                                      [index]: event.currentTarget,
                                    });
                                  }}
                                >
                                  Delegar firma
                                </Button>

                                <Menu
                                  anchorEl={anchorElDefaultSignatory[index]}
                                  open={Boolean(
                                    anchorElDefaultSignatory[index]
                                  )}
                                  onClose={() => {
                                    setAnchorElDefaultSignatory({
                                      ...anchorElDefaultSignatory,
                                      [index]: null,
                                    });
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      navigate(
                                        `signatories-panel/${partIndex}/${signatory.index}/default`,
                                        {
                                          state: {
                                            indexSignatory: index,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <ListItemIcon>
                                      <PostAddOutlined
                                        fontSize="large"
                                        className="heading__primary-color"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        ".MuiListItemText-primary": {
                                          fontSize: "1.4rem",
                                        },
                                      }}
                                    >
                                      Desde cero
                                    </ListItemText>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `select-default/${partIndex}/${signatory.index}`,
                                        {
                                          state: {
                                            indexSignatory:
                                              signatory?.indexSignatory,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    <ListItemIcon>
                                      <LocalLibraryOutlinedIcon
                                        fontSize="large"
                                        className="heading__primary-color"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        ".MuiListItemText-primary": {
                                          fontSize: "1.4rem",
                                        },
                                      }}
                                    >
                                      Desde biblioteca
                                    </ListItemText>
                                  </MenuItem>
                                </Menu>
                              </div>
                            </Col>
                          )}
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<EditOutlinedIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                navigate(
                                  `signatories-panel/${partIndex}/${signatory.index}/edit`,
                                  {
                                    state: {
                                      indexSignatory: signatory?.indexSignatory,
                                    },
                                  }
                                );
                              }}
                            >
                              Editar firmante
                            </Button>
                          </Col>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                dispatch(
                                  deleteSignatoryToPart({
                                    partIndex,
                                    signatoryIndex: signatory?.indexSignatory,
                                    signatoryIndexToRol: signatory?.index,
                                    isDefault: signatory.isDefault,
                                  })
                                );
                              }}
                            >
                              Eliminar
                            </Button>
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Draggable>
              ))}
          </Col>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const DragDropContextSignatories = ({ partsList }) => {
  const dispatch = useDispatch();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        dispatch(reorderParts({ result }));
      }}
    >
      <Droppable droppableId="signatures">
        {(droppableProvided) => (
          <Col
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {partsList.length > 0 &&
              partsList.map((par, index) => {
                const signatoriesList = par?.signatories || [];
                const arraySignatories = signatoriesList.flatMap((s, index) => {
                  const signatoriesArray = s?.signatories || [];
                  return signatoriesArray.map((si, indexSignatory) => ({
                    ...si,
                    index,
                    indexSignatory: indexSignatory,
                    type: s.type,
                    partName: s.information.name,
                  }));
                });

                return (
                  <Draggable
                    key={par._id ? par._id : "draggable" + par.name}
                    draggableId={par._id ? par._id : "draggable" + par.name}
                    index={index}
                  >
                    {(draggablePorvider) => (
                      <Accordion
                        {...draggablePorvider.draggableProps}
                        {...draggablePorvider.dragHandleProps}
                        className="custom-accordion"
                        ref={draggablePorvider.innerRef}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Row style={{ width: "100%" }}>
                            <Col xs={"auto"}>
                              <DragIndicatorIcon
                                fontSize="large"
                                className="drag-color"
                              />
                            </Col>
                            <Col>
                              <p className="heading__primary-color">
                                {`Firma ${par.name}`}
                              </p>
                            </Col>
                            {!arraySignatories?.length && (
                              <Col xs={"auto"}>
                                <span
                                  className="caption"
                                  style={{ color: "red" }}
                                >
                                  Sin firmantes
                                </span>
                              </Col>
                            )}
                          </Row>
                        </AccordionSummary>
                        <AccordionDetails>
                          <DragDropSignatories
                            arraySignatories={arraySignatories}
                            partIndex={index}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Draggable>
                );
              })}
            {droppableProvided.placeholder}
          </Col>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const DragDropContextParts = ({ partsList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editTitlePart, setEditTitlePart] = useState({});
  const [openModalSelectPart, setOpenModalSelectPart] = useState({
    open: false,
    partIndex: null,
  });

  const [openModalAddSignatory, setOpenModalAddSignatory] = useState({
    open: false,
    partIndex: null,
    library: false,
    index: null,
  });

  const handleDeletePartOfDocument = (index) => {
    dispatch(deletePart({ index }));
  };

  const handleUpdateTitlePart = ({ index, part }) => {
    const value = editTitlePart?.value.trim();
    setEditTitlePart({ index, isEdit: false, value });
    dispatch(addEditedPart({ index, editedPart: { ...part, name: value } }));
  };

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          dispatch(reorderParts({ result }));
        }}
      >
        <Droppable droppableId="parts">
          {(droppableProvided) => (
            <Col
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {partsList.length > 0 &&
                partsList.map((par, index) => (
                  <Draggable
                    key={par._id ? par._id : "draggable" + par.name}
                    draggableId={par._id ? par._id : "draggable" + par.name}
                    index={index}
                  >
                    {(draggablePorvider) => (
                      <Accordion
                        {...draggablePorvider.draggableProps}
                        {...draggablePorvider.dragHandleProps}
                        className="custom-accordion"
                        ref={draggablePorvider.innerRef}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Row style={{ width: "100%", alignItems: "center" }}>
                            <Col
                              xs={"auto"}
                              className="col-start heading__primary-color"
                            >
                              <DragIndicatorIcon
                                fontSize="large"
                                className="drag-color"
                              />
                              {editTitlePart?.index === index &&
                              editTitlePart?.isEdit ? (
                                <>
                                  <Form.Group
                                    style={{
                                      padding: "0%",
                                      margin: "2% 0",
                                    }}
                                  >
                                    <Form.Control
                                      placeholder={"Escriba aquí"}
                                      bsPrefix={
                                        "input-group-container__no-icon label"
                                      }
                                      defaultValue={par.name}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (!value)
                                          value = `Sin título ${index + 1}`;
                                        setEditTitlePart({
                                          index,
                                          isEdit: true,
                                          value: value.trim(),
                                        });
                                      }}
                                      onKeyPress={(e) => {
                                        const regExp = new RegExp(/Enter/, "i");
                                        if (regExp.exec(e.key)) {
                                          handleUpdateTitlePart({
                                            index,
                                            part: par,
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                  <IconButton
                                    onClick={() => {
                                      handleUpdateTitlePart({
                                        index,
                                        part: par,
                                      });
                                    }}
                                  >
                                    <CheckOutlinedIcon
                                      className="heading__primary-color"
                                      fontSize="large"
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      setEditTitlePart({
                                        index,
                                        isEdit: false,
                                        value: par.name,
                                      });
                                    }}
                                  >
                                    <CloseIcon
                                      className="heading__primary-color"
                                      fontSize="large"
                                    />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <p className="heading">{par.name}</p>
                                  {"  "}
                                  <IconButton
                                    onClick={() => {
                                      setEditTitlePart({
                                        index,
                                        isEdit: true,
                                        value: par?.name,
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  {partsList.length > 2 && (
                                    <IconButton
                                      onClick={() => {
                                        handleDeletePartOfDocument(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </>
                              )}
                            </Col>
                            <Col />
                            {!par?.signatories?.length && (
                              <Col xs={"auto"}>
                                <span
                                  className="caption"
                                  style={{ color: "red" }}
                                >
                                  Sin partes
                                </span>
                              </Col>
                            )}
                          </Row>
                        </AccordionSummary>
                        <AccordionDetails>
                          <DragDropParts
                            arrayParts={par.signatories}
                            partIndex={index}
                            setOpenModalAddSignatory={setOpenModalAddSignatory}
                          />
                          <Row className="caption">{par.text}</Row>
                          <Row style={{ justifyContent: "right" }}>
                            <Col md={"auto"}>
                              <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                className="custom-input__button__secondary-color"
                                onClick={() =>
                                  setOpenModalSelectPart({
                                    open: true,
                                    partIndex: index,
                                  })
                                }
                              >
                                Agregar parte
                              </Button>
                            </Col>
                          </Row>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </Col>
          )}
        </Droppable>
      </DragDropContext>

      <ModalDecision
        title={"Agregar partes"}
        message={"¿Cómo quieres agregar tus partes?"}
        open={openModalSelectPart.open}
        onClose={() => setOpenModalSelectPart({ open: false, partIndex: null })}
        agreeText="Desde Biblioteca"
        agreeIconStart={<LocalLibraryOutlinedIcon />}
        disagreeIconStart={<AddIcon />}
        disagreeText="Desde Cero"
        handleAgree={() => {
          navigate(`select-part/${openModalSelectPart.partIndex}`);
        }}
        handleDisagree={() => {
          navigate(
            `parts-panel/${openModalSelectPart.partIndex}/create-signatory`
          );
        }}
      />

      <ModalDecision
        title={"Agregar firmante"}
        message={"¿Cómo quieres agregar tus firmantes?"}
        open={openModalAddSignatory.open}
        onClose={() =>
          setOpenModalAddSignatory({
            open: false,
            partIndex: null,
            libray: false,
            index: null,
          })
        }
        agreeText={openModalAddSignatory.library ? "Desde Biblioteca" : ""}
        agreeIcon={<LocalLibraryOutlinedIcon />}
        disagreeIcon={<AddIcon />}
        disagreeText="Desde Cero"
        handleAgree={() => {
          navigate(
            `select-signatory/${openModalAddSignatory.partIndex}/with-part`,
            { state: { roleIndex: openModalAddSignatory.index } }
          );
        }}
        handleDisagree={() => {
          navigate(
            `signatories-panel/${openModalAddSignatory.partIndex}/${openModalAddSignatory.index}/create`
          );
        }}
      />
    </>
  );
};

// Reference Normative

//Plain text
export const plainTextTagsLinks = (text) => {
  return text
    .replace(/<style([\s\S]*?)<\/style>/gi, " ")
    .replace(/<script([\s\S]*?)<\/script>/gi, " ")
    .replace(/style\s*=\s*['"]color:\s*red;['"]/gi, "")
    .replace(/(<a(?:.|\n)*?>)/gm, " ")
    .replace(/(<\/a>)/gm, " ")
    .replace(/<span\b(?![^>]*class=")[^>]*>(.*?)<\/span>/gi, "$1")
    .replace(/\s+/gm, " ");
};

//plain text clauses
export const cleanText = (clauses) => {
  const currentClauses = clauses;
  let newClauses = currentClauses.map((clause) => {
    let subclauses = [];
    let paragraphs = [];
    const newTextClause = plainTextTagsLinks(clause.clause.text);
    for (const subclause of clause.clause.subclauses) {
      subclauses.push({
        ...subclause,
        subclause: {
          ...subclause.subclause,
          text: plainTextTagsLinks(subclause.subclause.text),
        },
      });
    }
    for (const paragraph of clause.clause.paragraphs) {
      paragraphs.push({
        ...paragraph,
        paragraph: {
          ...paragraph.paragraph,
          text: plainTextTagsLinks(paragraph.paragraph.text),
        },
      });
    }
    return {
      ...clause.clause,
      text: newTextClause,
      subclauses,
      paragraphs,
    };
  });
  return newClauses;
};

export const getReferenceNormativeSubClauses = (
  subclauses,
  clausesRefNormative,
  clauseIndex
) =>
  subclauses.map((subclause, subclauseIndex) => {
    const id_text = `${clauseIndex}.${subclauseIndex + 1}.0`;
    const ref = clausesRefNormative.filter((item) => item.id_text === id_text);
    ref.sort((a, b) => {
      if (a.text.length < b.text.length) return 1;
      if (a.text.length > b.text.length) return -1;
      return 0;
    });
    if (ref.length > 0) {
      const newText = plainTextTagsLinks(subclause.subclause.text);
      let refSubclause = {
        ...subclause,
        subclause: { ...subclause.subclause, text: newText },
      };
      for (const item of ref) {
        let link = `<a href="https://www.google.com/search?q=${item.text}" target="_blank">${item.text}</a>`;
        let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
        refSubclause = {
          ...refSubclause,
          subclause: {
            ...refSubclause.subclause,
            text: refSubclause?.subclause?.text.replace(regEx, link),
          },
        };
      }
      return refSubclause;
    }
    return subclause;
  });

export const getReferenceNormativeParagraphs = (
  paragraphs,
  clausesRefNormative,
  clauseIndex
) =>
  paragraphs.map((paragraph, paragraphIndex) => {
    const id_text = `${clauseIndex}.0.${paragraphIndex + 1}`;
    const ref = clausesRefNormative.filter((item) => item.id_text === id_text);
    ref.sort((a, b) => {
      if (a.text.length < b.text.length) return 1;
      if (a.text.length > b.text.length) return -1;
      return 0;
    });
    if (ref.length > 0) {
      const newText = plainTextTagsLinks(paragraph.paragraph.text);
      let refParagraph = {
        ...paragraph,
        paragraph: { ...paragraph.paragraph, text: newText },
      };
      for (const item of ref) {
        let link = `<a target="_blank" href="https://www.google.com/search?q=${item.text}">${item.text}</a>`;
        let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
        refParagraph = {
          ...refParagraph,
          paragraph: {
            ...refParagraph.paragraph,
            text: refParagraph?.paragraph?.text.replace(regEx, link),
          },
        };
      }
      return refParagraph;
    }
    return paragraph;
  });

export const GetReferenceNormative = async (clauses) => {
  const getRefNormativeResponse = await getRefNormativeDocument({
    clauses: cleanText(clauses),
  });
  const currentClauses = clauses;
  const clausesRefNormative = getRefNormativeResponse.data.responseMessage.data;
  let newClauses = currentClauses.map((clause, clauseIndex) => {
    const id_text = `${clauseIndex + 1}.0.0`;
    const ref = clausesRefNormative.filter((item) => item.id_text === id_text);
    const subclausesArray = clause?.clause?.subclauses || [];
    let subclauses = getReferenceNormativeSubClauses(
      subclausesArray,
      clausesRefNormative,
      clauseIndex
    );

    const paragraphsArray = clause?.clause?.paragraphs || [];
    let paragraphs = getReferenceNormativeParagraphs(
      paragraphsArray,
      clausesRefNormative,
      clauseIndex
    );

    if (ref.length > 0) {
      const newText = plainTextTagsLinks(clause.clause.text);
      let refClause = {
        ...clause,
        clause: { ...clause.clause, text: newText },
      };
      ref.sort((a, b) => {
        if (a.text.length < b.text.length) return 1;
        if (a.text.length > b.text.length) return -1;
        return 0;
      });
      for (const item of ref) {
        let link = `<a target='_blank' href='https://www.google.com/search?q=${item.text}'>${item.text}</a>`;
        let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
        refClause = {
          ...refClause,
          clause: {
            ...refClause.clause,
            text: refClause?.clause?.text?.replace(regEx, link),
          },
        };
      }
      return {
        ...refClause,
        clause: {
          ...refClause.clause,
          subclauses,
          paragraphs,
        },
      };
    }
    return {
      ...clause,
      clause: {
        ...clause.clause,
        subclauses,
        paragraphs,
      },
    };
  });
  return newClauses;
};
