import CachedIcon from "@mui/icons-material/Cached";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalChooseNameAndType from "../../../../../../components/Modals/ModalChooseNameAndSaveType";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import SectionTitle from "../../../../../../components/sectionTitle";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
import { env } from "../../../../../../env";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  getCompanyPlan,
  setStorageInfo,
} from "../../../../../../parts/storage/storageSlice";
import { v4 as uuidv4 } from "uuid";
import { validateEnablePlan } from "../../../../../../utils/getPlanInfo";
import useJumpToPagePlugin from "../../../../../../utils/plugins/useJumpToPagePlugin";
const ViewOtherDocument = () => {
  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [expressions, setExpressions] = useState([]);
  const [nameRule, setNameRule] = useState("");

  const [blobUrl, setBlobUrl] = useState(null);
  const [blobDocument, setBlobDocument] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isOpenModalPlan, setIsOpenModalPlan] = useState(false);
  const [saveTypes, setSaveTypes] = useState([]);
  const companyPlan = useSelector(getCompanyPlan);
  const savedReview = location.state.data.savedReview;
  const savedOriginal = location.state.data.savedOriginal;

  const { jumpToPage, install } = useJumpToPagePlugin();

  const jumpToPagePluginInstance = {
    install,
    jumpToPage,
  };
  useEffect(() => {
    const expressions = location.state.data.expressions;

    const documentData = location.state.data.file;
    const fileName = location.state.data.fileName;
    const typesSaved = location.state.typesSaved || [];

    const blob = new Blob([new Uint8Array(documentData.data).buffer], {
      type: "application/pdf",
    });
    const blobUrl = URL.createObjectURL(blob);

    setBlobUrl(blobUrl);
    setExpressions(expressions);
    setNameRule(location.state.rule);
    setFileName(fileName.split(".pdf")[0]);

    setBlobDocument(blob);
    setSaveTypes(typesSaved);
  }, [location]);

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const handleClickDownloadDocument = () => {
    saveAs(blobDocument, `Revisado ${fileName}.pdf`);
  };

  const handleCloseModalDesicion = () => {
    setFolderSelectedModal(false);
  };

  const handleOpenModalSave = () => {
    setFolderSelectedModal(true);
  };

  const getSaveType = () => {
    if (saveTypes.length === 0) {
      return "los documentos";
    } else {
      return "el documento " + saveTypes.join(" y el");
    }
  };

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsOpenModalPlan(true);
        setDisableSave(true);
      }
    }
  }, [companyPlan]);

  useEffect(() => {
    const checkStorage = async () => {
      const storageInfo = await validateEnablePlan();
      dispatch(setStorageInfo(storageInfo));
    };
    checkStorage();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Revisar otros documentos"} />
      </Row>
      <Col md={9} style={{ height: "5%" }}></Col>

      <Accordion>
        <Row>
          <Col className="heading__primary-color">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>Información para revisar</span>
            </AccordionSummary>
          </Col>
        </Row>
        <AccordionDetails>
          <span className="heading__subtitle">{`Regla seleccionada: `}</span>
          <span className="chip__disabled">{nameRule?.name || ""}</span>
          <p className="caption pt-4">{nameRule?.description || ""}</p>
        </AccordionDetails>
      </Accordion>
      <br />
      <Row>
        <Col
          xs={4}
          className=" custom-accordion__container-headers"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            backgroundColor: "#F6F6F7",
          }}
        >
          <Container className="" fluid>
            <Row
              style={{ margin: "1% 0", backgroundColor: "#E6E9EF" }}
              className="caption"
            >
              <Col>
                <span className="heading__primary-color">Expresiones</span>
              </Col>
            </Row>
            <Row>
              <Col>
                {expressions.length == 0 && (
                  <span className="caption">
                    No hemos detectado expresiones importantes en este documento
                  </span>
                )}
                {expressions.map((expression, index) => (
                  <div onClick={() => jumpToPage(expression.page - 1)}>
                    <Col>
                      <br></br>
                      <span
                        key={uuidv4()}
                        className="caption__primary-color__active"
                      >
                        Expresión:
                      </span>
                    </Col>

                    <Col>
                      <li key={uuidv4()} className="heading__subtitle">
                        {expression.text}
                      </li>
                    </Col>
                    <Col>
                      <span key={uuidv4()} className="caption__">
                        {`Ubicación en el texto: Página ${expression.page}`}
                      </span>
                    </Col>
                    <hr></hr>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
          <Row className="caption">
            <div style={{ display: "flex", alignItems: "center" }}>
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </div>
            <div className="custom-accordion__container-headers">
              {blobUrl && (
                <Worker workerUrl={WORKER_URL}>
                  <Viewer
                    fileUrl={blobUrl}
                    plugins={[zoomPluginInstance, jumpToPagePluginInstance]}
                    defaultScale={1.1}
                  />
                </Worker>
              )}
            </div>
          </Row>
        </Col>
      </Row>
      <br />
      <Row xs={"auto"} style={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          className={"custom-input__button__secondary-color"}
          onClick={() => {
            if (!savedReview || !savedOriginal) {
              setIsOpenModalDecision(true);
            } else {
              navigate("/service/review/first-Time");
            }
          }}
          startIcon={<CachedIcon fontSize="large" />}
        >
          Nueva revisión
        </Button>

        <div>
          <Row>
            <Col xs="auto">
              <Button
                variant="contained"
                onClick={handleClickDownloadDocument}
                className={"custom-input__button__secondary-color"}
                startIcon={<DownloadIcon fontSize="large" />}
              >
                Descargar
              </Button>
            </Col>
            <Col xs="auto">
              {disableSave ? (
                <CustomToolTip
                  direction="left"
                  message="La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                >
                  <Button
                    variant="contained"
                    disabled
                    className={"custom-input__button__primary-color"}
                    startIcon={<SaveOutlinedIcon fontSize="large" />}
                  >
                    Guardar
                  </Button>
                </CustomToolTip>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleOpenModalSave}
                  className={"custom-input__button__primary-color"}
                  startIcon={<SaveOutlinedIcon fontSize="large" />}
                >
                  Guardar
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Row>

      <ModalDecision
        title={"Consulta sin guardar"}
        message={`No has guardado ${getSaveType()}, ¿seguro quieres hacer una nueva consulta?`}
        open={isOpenModalDecision}
        onClose={() => {
          setIsOpenModalDecision(false);
        }}
        agreeText="Guardar"
        disagreeText="Aceptar"
        handleAgree={() => {
          setIsOpenModalDecision(false);
          setFolderSelectedModal(true);
        }}
        handleDisagree={() => navigate("/service/review")}
        type="warning"
      />
      <ModalChooseNameAndType
        open={folderSelectedModal}
        onClose={handleCloseModalDesicion}
        fileName={fileName}
        documentData={location.state.data.file}
        originalDocument={location.state.data.originalFile}
        expressions={expressions}
        rule={nameRule}
        savedReview={savedReview}
        savedOriginal={savedOriginal}
      />
      <ModalDecision
        title={"Advertencia de no guardado"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => setIsOpenModalPlan(false)}
        handleDisagree={() => setIsOpenModalPlan(false)}
        message={
          "La compañía ha alcanzado el máximo de almacenamiento de su plan, contacta con el usuario administrador de Legal AI"
        }
        onClose={() => setIsOpenModalPlan(false)}
        open={isOpenModalPlan}
        type={"warning"}
      />
    </Container>
  );
};

export default ViewOtherDocument;
