import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../parts/breadcrum";
import SectionTitle from "../../../../../components/sectionTitle";
import { Button, Dialog, IconButton, Slide } from "@mui/material";
import CustomSearch from "../../../../../components/Datatable/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../../../../components/loadingContent";
import {
  getReports,
  setSelectedReport,
} from "../../../../../parts/document/currentDocumentSlice";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalViewMilestones = ({ open, close }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Cláusula donde proviene el hito
        </label>,
      ],
      field: "clause",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Hito identificado
        </label>,
      ],
      field: "milestone",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Texto entre punto y punto
        </label>,
      ],
      field: "text",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const rows = [];
  const reportsData = useSelector(getReports);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const handleCreateAlert = (report) => {
    dispatch(setSelectedReport({ report }));
    close();
  };
  useEffect(() => {
    if (reportsData) {
      setIsLoading(true);
      const processedFolders = reportsData.map((report) => {
        return {
          clause: `${report.titleClause} ${
            report.titleSubclause || report.titleParagrah || ""
          }`,
          milestone: report.text,
          text: `... ${report.keyPhrase} ...`,
          actions: (
            <Row style={{ justifyContent: "center" }}>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    handleCreateAlert(report);
                  }}
                >
                  Crear Alerta
                </Button>
              </Col>
            </Row>
          ),
        };
      });
      setDataTable({ columns: columns, rows: processedFolders });
      setFilteredDataTable({ columns: columns, rows: processedFolders });
      setIsLoading(false);
    }
  }, [reportsData]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Container fluid className="custom-container-scroll">
        {isLoading && <LoadingContent />}
        <Row className="justify-content-md-end">
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Col>
        </Row>
        <Row>
          <Col md={2} className="col-start">
            <SectionTitle title="Hitos" />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={5} className="col-flex">
            <CustomSearch
              dataTableInfo={dataTable}
              setFilteredDataTable={setFilteredDataTable}
            />
          </Col>
        </Row>
        <Row className="data-table dt-list-items">
          <MDBDataTableV5
            noRecordsFoundLabel={"No se encuentran hitos"}
            hover
            pagingTop
            searchBottom={false}
            entries={5}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "hitos"]}
            fullPagination
          />
        </Row>
      </Container>
    </Dialog>
  );
};

export default ModalViewMilestones;
