import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getDocumentForExternalUser } from "../../services/documents/documentsRequest";
import { CODES } from "../../utils/codes";
import { CircularProgress } from "@mui/material";
import { saveAs } from "file-saver";
export const DownloadFile = () => {
  const [searchParams] = useSearchParams();
  const bucketName = searchParams.get("bucketName");
  const filenameInBucket = searchParams.get("filenameInBucket");

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function downloadFile() {
      if (bucketName && filenameInBucket) {
        setIsLoading(true);
        try {
          const response = await getDocumentForExternalUser(
            filenameInBucket,
            bucketName,
            false
          );
          if (
            response.status &&
            response.status === CODES.COD_RESPONSE_HTTP_OK
          ) {
            const bf = response.data.responseMessage.buffer.data;
            const blob = new Blob([new Uint8Array(bf).buffer]);
            let fileName = response.data.responseMessage.filename;

            saveAs(blob, fileName);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    }
    downloadFile();
  }, [searchParams]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <p className="heading__primary-color">
          Archivo descargado correctamente
        </p>
      )}
    </div>
  );
};
