import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CoachMark = ({ text }) => {
  return (
    <Container className="coachmark__container">
      <Row>
        <Col xs={8}>
          <p className="label justify-text coachmark__paragraph">{text}</p>
        </Col>
        <Col xs={4}>
          <img src={require("../../assets/svg/coachMark.svg").default} />
        </Col>
      </Row>
    </Container>
  );
};

export default CoachMark;
