import { Button, Menu, MenuItem, Paper } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MetricRequestGraph = ({
  iconGeneralCount,
  generalCountDescription,
  generalCount,
  iconButtonFilter,
  textButtonFilter,
  optionFilter,
  filterColor,
}) => {
  //state
  const [anchorMenuFilter, setAnchorMenuFilter] = useState(null);
  const openMenuFilter = Boolean(anchorMenuFilter);
  const [anchorMenuSubFilter, setAnchorMenuSubFilter] = useState(null);
  const openMenuSubFilter = Boolean(anchorMenuSubFilter);
  const [actualFilter, setActualFilter] = useState(optionFilter[0]?.name);
  const [subActualFilter, setSubActualFilter] = useState("");
  const [viewState, setViewState] = useState(false);

  const getData = (options) => {
    let filter = options.find((filter) => filter.name === actualFilter);
    let total = 0;
    filter?.data?.forEach((element) => {
      total += element.value;
    });

    return filter?.data?.map(
      (item) => `${Math.round((item.value / total) * 100)}`
    );
  };
  const dataPieChart = {
    datasets: [
      {
        data: getData(optionFilter),

        backgroundColor: [
          "#00CA72",
          "#16CAD3",
          "#F9600B",
          "#753ABC",
          "#E44258",
          "#84BC00",
        ],
        tooltip: {
          callbacks: {
            label: function (context) {
              let value = context.formattedValue;

              let percentage = value + "%";
              return percentage;
            },
          },
        },
      },
    ],
  };

  const optionsBarChart = {
    indexAxis: "y",
    scales:
      viewState && actualFilter === "Tipo de documento"
        ? {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          }
        : {},
  };
  const dataBarChart = {
    labels: optionFilter
      .find((filter) => filter.name === actualFilter)
      ?.data?.map((items) => items.name),
    datasets: [
      {
        label: actualFilter,
        data: optionFilter
          .find((filter) => filter.name === actualFilter)
          ?.data?.map((items) => items.value),
        backgroundColor: [
          "#00CA72",
          "#16CAD3",
          "#F9600B",
          "#753ABC",
          "#E44258",
          "#84BC00",
        ],
        borderRadius: 8,
        barThickness: 30,
      },
    ],
  };

  const dataExtraTypeRequest = {
    labels: optionFilter
      ?.find((filter) => filter.name === actualFilter)
      ?.data.find((extraData) => extraData.name === subActualFilter)
      ?.byDocumentType?.map((items) => items.name),
    datasets:
      viewState && actualFilter === "Tipo de documento"
        ? filterColor.map((filterLabelList) => {
            const preFilter = {
              label: filterLabelList.name,
              data: optionFilter
                .find((filter) => filter.name === actualFilter)
                .data.find((subFilter) => subFilter.name === subActualFilter)
                .byDocumentType.map(
                  (filterItem) =>
                    filterItem.byStatus
                      .filter(
                        (requestState) =>
                          requestState.name !== "En revisión" &&
                          requestState.name !== "En proceso" &&
                          requestState.name !== "Activa" &&
                          requestState.name !== "Sin asignar"
                      )
                      .find((char) => char.name === filterLabelList.name).value
                ),
              backgroundColor: [
                filterColor.find((color) => color.name === filterLabelList.name)
                  .backgroundColor,
              ],
              borderRadius: 8,
              barThickness: 30,
            };
            return preFilter;
          })
        : [
            {
              label: actualFilter,
              data: optionFilter
                ?.find((filter) => filter.name === actualFilter)
                ?.data.find((extraData) => extraData.name === subActualFilter)
                ?.byDocumentType?.map((items) => items.value),
              backgroundColor: [
                "#00CA72",
                "#16CAD3",
                "#F9600B",
                "#753ABC",
                "#E44258",
                "#84BC00",
              ],
              borderRadius: 8,
              barThickness: 20,
            },
          ],
  };

  //Handle
  const handleCloseAnchorMenuFilter = () => {
    setAnchorMenuFilter(null);
  };
  const handleCloseAnchorMenuSubFilter = () => {
    setAnchorMenuSubFilter(null);
  };
  const handleClickAnchorMenuFilter = (event) => {
    setAnchorMenuFilter(event.currentTarget);
  };
  const handleClickAnchorMenuSubFilter = (event) => {
    setAnchorMenuSubFilter(event.currentTarget);
  };
  return (
    <Paper elevation={1} style={{ padding: "2%" }}>
      <Container fluid>
        <Row>
          <Col
            xs={4}
            style={
              subActualFilter === ""
                ? {}
                : { display: "flex", alignItems: "center" }
            }
          >
            <Container fluid>
              <Row>
                <Pie data={dataPieChart} />
              </Row>
              <Row
                className="heading"
                style={{
                  margin: "10% 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {`Filtro por: ${actualFilter}`}
              </Row>
            </Container>
          </Col>
          <Col xs={8}>
            <Container fluid>
              <Row style={{ borderBottom: "1px solid", padding: "2%" }}>
                <Col style={{ alignItems: "center", display: "flex" }}>
                  <Button
                    startIcon={iconButtonFilter}
                    endIcon={<KeyboardArrowDownIcon fontSize="large" />}
                    className="custom-input__button__secondary-color"
                    onClick={(e) => {
                      handleClickAnchorMenuFilter(e);
                    }}
                    variant="contained"
                  >
                    {`${textButtonFilter}: ${actualFilter}`}
                  </Button>
                  <Menu
                    anchorEl={anchorMenuFilter}
                    open={openMenuFilter}
                    onClose={handleCloseAnchorMenuFilter}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {optionFilter?.map((option) => (
                      <MenuItem
                        key={option.name}
                        onClick={() => {
                          setActualFilter(option.name);
                          setSubActualFilter(
                            option.data[0].byDocumentType
                              ? option.data[0].name
                              : ""
                          );
                          handleCloseAnchorMenuFilter();
                        }}
                      >
                        <span className="body-content">{option.name}</span>
                      </MenuItem>
                    ))}
                  </Menu>
                </Col>
                <Col xs={4} style={{ borderLeft: "1px solid" }}>
                  <Container fluid>
                    <Row>
                      <Col
                        xs={3}
                        className="custom-input__button__secondary-color__icon"
                        style={{ borderLeft: "1px solid" }}
                      >
                        {iconGeneralCount}
                      </Col>
                      <Col>
                        <Container fluid>
                          <Row
                            className="display-large"
                          >
                            {generalCount}
                          </Row>
                          <Row
                            className="caption"
                          >
                            {generalCountDescription}
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row style={{ margin: "1% 0" }}>
                <Col
                  style={
                    subActualFilter === ""
                      ? {}
                      : {
                          maxHeight: "20rem",
                          display: "flex",
                          justifyContent: "center",
                        }
                  }
                >
                  <Bar data={dataBarChart} options={optionsBarChart} />;
                </Col>
              </Row>
              {optionFilter?.find((filter) => filter.name === actualFilter)
                ?.extra === "Request" && (
                <>
                  <Row style={{ margin: "2% 0" }}>
                    <Col xs={5}>
                      <Button
                        startIcon={iconButtonFilter}
                        endIcon={<KeyboardArrowDownIcon fontSize="large" />}
                        className="custom-input__button__secondary-color"
                        onClick={(e) => {
                          handleClickAnchorMenuSubFilter(e);
                        }}
                        variant="contained"
                      >
                        {`Ver subtipos de: ${subActualFilter}`}
                      </Button>
                      <Menu
                        anchorEl={anchorMenuSubFilter}
                        open={openMenuSubFilter}
                        onClose={handleCloseAnchorMenuSubFilter}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setSubActualFilter("Contratos");
                            handleCloseAnchorMenuSubFilter();
                          }}
                        >
                          <span className="body-content">
                            Ver subtipos de contratos
                          </span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setSubActualFilter("Otrosí");
                            handleCloseAnchorMenuSubFilter();
                          }}
                        >
                          <span className="body-content">
                            Ver subtipos de otrosí
                          </span>
                        </MenuItem>
                      </Menu>
                    </Col>
                    <Col xs={1}></Col>
                    <Col xs={3}>
                      <Form.Group className="body" controlId="Terminos">
                        <Form.Check
                          className="label__description__default"
                          checked={viewState}
                          type="checkbox"
                          label="Ver estados"
                          onChange={(e) => {
                            setViewState(e.target.checked);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row
                    style={
                      subActualFilter === ""
                        ? {}
                        : {
                            maxHeight: "30rem",
                            display: "flex",
                            justifyContent: "center",
                          }
                    }
                  >
                    <Bar
                      data={dataExtraTypeRequest}
                      options={optionsBarChart}
                    />
                  </Row>
                </>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </Paper>
  );
};

export default MetricRequestGraph;
