import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getConceptsByCorporateUnit } from "../../services/consults/consultsServices";
import { CODES } from "../../utils/codes";

const chatSlice = createSlice({
  name: "Consulta",
  initialState: {
    selectedChat: {
      history: [],
    },
    currentChat: {
      history: [],
      category: "",
      name: "Consulta",
      id: "",
    },
    conceptsList: {
      conceptsList: [],
      conceptsListStatus: "fetch",
      conceptsListError: null,
    },
  },
  reducers: {
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    addChat: (state, action) => {
      const chats = state.currentChat.messages;
      chats.push(action.payload);
      state.currentChat.messages = chats;
    },
    addHistoryQuestion: (state, action) => {
      const history = state.currentChat.history;

      history.push({ question: action.payload });
      state.currentChat.history = history;
    },
    addHistoryAnswer: (state, action) => {
      const history = state.currentChat.history;

      // Check if the last item in the array is a question
      let lastItem = history[history.length - 1];
      if (lastItem && lastItem.hasOwnProperty("question")) {
        lastItem.answer = action.payload;
      } else {
        history.push({ answer: action.payload });
      }
    },
    cleanChatStore: (state, action) => {
      state.currentChat.history = [];

      state.currentChat.category = "";
      state.currentChat.id = "";
      state.currentChat.name = "Consulta";
    },
    setCurrentCategory: (state, action) => {
      state.currentChat.category = action.payload;
    },
    setChatTitle: (state, action) => {
      state.currentChat.name = action.payload;
    },
    setChatId: (state, action) => {
      state.currentChat.id = action.payload;
    },
    setChatInfo: (state, action) => {
      state.currentChat.category = action.payload.category;
      state.currentChat.history = action.payload.history;
      state.currentChat.name = action.payload.name;
      state.currentChat.id = action.payload._id;
    },
    resetConceptListStatus: (state, action) => {
      state.conceptsList.conceptsListStatus = "fetch";
      state.conceptsList.conceptsListError = "fetch";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConceptsList.pending, (state, _) => {
        state.conceptsList.conceptsListStatus = "loading";
      })
      .addCase(fetchConceptsList.fulfilled, (state, action) => {
        state.conceptsList.conceptsListStatus = "succeeded";
        state.conceptsList.conceptsList =
          action.payload?.data?.responseMessage?.data;
      })
      .addCase(fetchConceptsList.rejected, (state, action) => {
        state.conceptsList.conceptsListStatus = "failed";
        state.conceptsList.conceptsListError = action.error.message;
      });
  },
});

export const { setSelectedChat } = chatSlice.actions;
export const getSelectedChat = (state) => state.chat.selectedChat;

export const { setCurrentChat } = chatSlice.actions;
export const getCurrentChat = (state) => state.chat.currentChat;

export const { addHistoryAnswer } = chatSlice.actions;

export const { addHistoryQuestion } = chatSlice.actions;

export const getCurrentCategory = (state) => state.chat.currentChat.category;
export const { setCurrentCategory } = chatSlice.actions;

export const { setChatInfo } = chatSlice.actions;
export const { cleanChatStore } = chatSlice.actions;

export const { setChatTitle } = chatSlice.actions;
export const getChatTitle = (state) => state.chat.currentChat.name;

export const { setChatId } = chatSlice.actions;
export const getChatId = (state) => state.chat.currentChat.id;

export const getConceptsList = (state) => state.chat.conceptsList.conceptsList;
export const getConceptsListStatus = (state) =>
  state.chat.conceptsList.conceptsListStatus;

export const { resetConceptListStatus } = chatSlice.actions;

export const fetchConceptsList = createAsyncThunk(
  "parts/fetchConceptsList",
  async () => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const response = await getConceptsByCorporateUnit({
      company,
      corporateUnit,
    });
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export default chatSlice.reducer;
