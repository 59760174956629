import { Col, Container, Row } from "react-bootstrap";

const AdjustmentConfirmation = () => {
  return (
    <Container fluid>
      <Row
        style={{
          marginTop: "19%",
        }}
        className="col-flex"
      >
        <Col md={"auto"}>
          <img
            className="confirmation__logo col-end"
            src={
              require("../../../../../../../../assets/svg/singleLogo.svg")
                .default
            }
          />
        </Col>
        <Col md={"auto"} className="confirmation__name col-start">
          Legal AI
        </Col>
      </Row>
      <Row style={{ marginTop: "1%" }} className="col-flex">
        <hr
          style={{
            border: "1px solid #00374F",
            color: "#00374F",
            width: "70%",
          }}
        />
      </Row>
      <Row
        className="confirmation__comment col-flex"
        style={{ marginTop: "2%" }}
      >
        Aprobación remitida con éxito.
      </Row>
      <Row className="body-content col-flex" style={{ marginTop: "2%" }}>
        Ya puede cerrar esta pestaña.
      </Row>
    </Container>
  );
};

export default AdjustmentConfirmation;
