import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
//Material Import
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
//Parts
//Components
import CustomSearchFilter from "../Search/CustomSearchFilter";
//Icons
import CloseIcon from "@mui/icons-material/Close";

//Redux
//Services
//Utils
import { v4 as uuidv4 } from "uuid";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ModalListSelectCheck = ({
  handleAgree,
  list = [],
  onClose,
  open,
  title,
  currentDataSelected = [],
  description = null,
}) => {
  const [dataSelected, setDataSelected] = useState([]);
  const [filteredData, setFilteredData] = useState(list);
  const handleChecked = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setDataSelected((oldArray) => [...oldArray, item]);
    } else {
      setDataSelected(dataSelected.filter((itm) => itm?._id != item?._id));
    }
  };
  useEffect(() => {
    if (open) {
      setFilteredData(list);
      setDataSelected(currentDataSelected);
    }
  }, [open]);
  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitleContainer onClose={onClose}>
        <label className="heading__primary-color">{title}</label>
      </DialogTitleContainer>
      <DialogContent className="caption" dividers>
        <Row>
          <CustomSearchFilter
            list={list}
            setFilteredData={setFilteredData}
            placeholder={"Buscar"}
          />
        </Row>
        {description && (
          <>
            <br />
            <Row>
              <span className="caption">{description}</span>
            </Row>
          </>
        )}
        <br />
        {filteredData.length > 0 &&
          filteredData.map((item, index) => {
            return (
              <Row
                key={uuidv4()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e0e0e0",
                  borderTop: "1px solid #e0e0e0",
                }}
              >
                <Col xs={"auto"}>
                  <Checkbox
                    checked={dataSelected?.some(
                      (itm) => itm?._id === item?._id
                    )}
                    onClick={(e) => handleChecked(e, item)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                      color: "#00374f",
                      "&.Mui-checked": {
                        color: "#00374f",
                      },
                    }}
                  />
                </Col>
                <Col>
                  <p
                    key={uuidv4()}
                    className="heading__primary-color"
                    style={{
                      padding: "1rem",
                    }}
                  >
                    {item.name}
                  </p>
                </Col>
              </Row>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Row xs={"auto"} style={{ justifyContent: "right" }}>
          <Col>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={onClose}
            >
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => handleAgree(dataSelected)}
            >
              Agregar
            </Button>
          </Col>
        </Row>
      </DialogActions>
    </Dialog>
  );
};
