import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { PersonAddAlt1Outlined } from "@mui/icons-material";
import { MDBDataTableV5 } from "mdbreact";
import ClearIcon from "@mui/icons-material/Clear";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import moment from "moment";
import CustomSearchFilterButton from "../../../../../../../components/Search/CustomSearchFilterButton";
import FilterByPart from "../../../../../../../parts/filters/FilterByPart";
import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSignatoriesList,
  getPartsList,
  getSignatoriesList,
  getSignatoriesListStatus,
  resetSignatoriesListStatus,
  setSelectedSignatory,
  resetPartListStatus,
} from "../../../../../../../parts/parts/partsSlice";
import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../../admin/modules/unitCorporative/unitCorporativeSlice";
import { ModalListSelectCheck } from "../../../../../../../components/Modals/modalListCheck";
import { DetailBarParts } from "../parts";
import { CODES } from "../../../../../../../utils/codes";
import {
  addCorporateUnitToSignatory,
  updateSignatory,
} from "../../../../../../../services/parts/partsServices";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import {
  deleteSignatoryTemporarily,
  manageSignatoriesPartsRelation,
} from "../../../../../../../services/parts/partsServices";
import { SIGNATORIES_STATE } from "../utils";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";

import { ModalInfoSignatories } from "../../../../../../../components/Modals/ModalInfoSignatories";
import { PartsDtoFactory } from "../../../../../../../services/parts/factories/partsDtoFactory.js";
import { MANAGE_SIGNATORIES_PARTS_ACTIONS } from "../../../../../../../services/parts/consts/partsConsts.js";

import { PopoverLimits } from "../../../../../../../components/Popover/PopoverLimits";
export const PanelSignatories = ({ setIsLoading, setTab }) => {
  const dispatch = useDispatch();
  const partsList = useSelector(getPartsList);

  const signatoriesList = useSelector(getSignatoriesList);
  const signatoriesListStatus = useSelector(getSignatoriesListStatus);

  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);

  const columns = [
    {
      label: [
        <label aria-hidden="true" key="0">
          Firmante
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Identificación
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Parte relacionada
        </label>,
      ],
      field: "relatedParts",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Unidades corporativas
        </label>,
      ],
      field: "corporateUnits",
    },
    {
      label: "",
      field: "actions",
    },
  ];
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });
  const [partsSelected, setPartsSelected] = useState([]);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingFetchUCl, setIsLoadingFetchUCl] = useState(false);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const [unitCorporativeSelected, setUnitCorporativeSelected] = useState([]);
  const [isOpenModalSelectUC, setIsOpenModalSelectUC] = useState(false);
  const [
    isOpenModalDecisionDeleteSignatory,
    setIsOpenModalDecisionDeleteSignatory,
  ] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [signatorySelected, setSignatorySelected] = useState(null);
  const [anchorSelected, setAnchorSelected] = useState(null);
  const openSelected = Boolean(anchorSelected);
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [deleteSignatoryResponse, setDeleteSignatoryResponse] = useState({});
  const [
    isOpenModalDeleteSignatoryResponse,
    setIsOpenModalDeleteSignatoryResponse,
  ] = useState(false);
  const [isOpenModalSignatoriesInfo, setIsOpenModalSignatoriesInfo] =
    useState(false);
  const [partsWithDefaultSignatory, setPartsWithDefaultSignatory] = useState(
    []
  );
  const [
    selectedPartWithDefaultSignatory,
    setSelectedPartWithDefaultSignatory,
  ] = useState({});

  const navigate = useNavigate();
  const handlePartsSelected = (parts) => {
    setPartsSelected(parts);
  };

  // Functions
  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;

    return fullName.replace(/  +/g, " ");
  };

  const getPartName = (part) => {
    let name = "";
    name = getName(part);
    if (part?.typePart === "JURIDICA") {
      name = part?.business?.businessName;
    }
    return name;
  };

  const handleClickRelatedPart = (part) => {
    setTab("0");
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      const fullName = getName(item);
      const id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;
      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;
      formatedData.push({
        name: (
          <Row>
            {moment(item.createdAt).isAfter(moment().subtract(6, "hours")) && (
              <Col xs={6} className="mb-2">
                <span className="tag__success p-1">Nuevo</span>
              </Col>
            )}

            <Col xs={12}>{fullName}</Col>
          </Row>
        ),
        id,
        fullName,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),
        corporateUnits: (
          <Row style={{ rowGap: 5, alignItems: "center" }}>
            {item?.associatedCorporateUnits?.length > 0 &&
              item.associatedCorporateUnits.map(({ _id, name }) => (
                <Col key={_id} xs={12} lg={6}>
                  <span
                    style={{ cursor: "pointer", display: "inline-block" }}
                    className="caption custom-badges__secundary"
                    onClick={() =>
                      handleDeleteCorporateUnit({ id: _id, element: item })
                    }
                  >
                    {name + " "}{" "}
                    {item.relatedParties.length === 0 && (
                      <ClearIcon className="custom-badges__delete-button" />
                    )}
                  </span>
                </Col>
              ))}
            {item.relatedParties.length === 0 && (
              <Col xs={12} lg={6}>
                <span
                  style={{ cursor: "pointer" }}
                  className="caption custom-badges__disabled"
                  onClick={() => handleOpenModalListCorporateUnits(item)}
                >
                  Agregar
                  <AddOutlinedIcon
                    fontSize="large"
                    className="custom-badges__delete-button"
                  />
                </span>
              </Col>
            )}
          </Row>
        ),
        relatedParts: item.relatedParties.map((part) => (
          <Row className="align-items-center">
            <Col xs={"auto"} lg={8}>
              <span
                className="caption__other-Color-Active"
                onClick={() => handleClickRelatedPart(part?.part)}
              >
                <b>{part?.position}.</b> {getPartName(part?.part)}
              </span>
            </Col>

            {part.part?.legalRepresentative?.limitations && (
              <Col xs={"auto"} lg={4}>
                <PopoverLimits
                  limitationsUpdatedDate={part?.limitationsUpdatedDate}
                  createdAt={part?.part?.createdAt}
                  limitations={part.part?.legalRepresentative?.limitations}
                />
              </Col>
            )}
          </Row>
        )),
        actions: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <IconButton
                onClick={(event) => {
                  setAnchorSelected(event.currentTarget);
                  setSignatorySelected(item);
                  dispatch(setSelectedSignatory(item));
                }}
              >
                <MoreHorizIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
        ),
        ...item,
      });
    }
    return formatedData;
  };

  const getPartsWithDefaultSignatory = (signatory) => {
    const parts = signatory.relatedParties.map((part) => part.part);
    const tempPartsWithDefaultSignatory = [];
    parts.forEach((part) => {
      const relatedSignatories = part.relatedSignatories;
      const isDefault = relatedSignatories.some(
        (relatedSignatory) =>
          relatedSignatory?.signatory?._id === signatory?._id &&
          relatedSignatory?.isDefault
      );
      const areMoreSignatories = relatedSignatories.length > 1;
      if (isDefault && areMoreSignatories) {
        tempPartsWithDefaultSignatory.push(
          deleteNestedIdFromArray(part, signatory._id)
        );
      }
    });
    if (tempPartsWithDefaultSignatory.length > 0) {
      setPartsWithDefaultSignatory(tempPartsWithDefaultSignatory);
      setSelectedPartWithDefaultSignatory(tempPartsWithDefaultSignatory?.[0]);
      setIsOpenModalSignatoriesInfo(true);
    }
    return parts;
  };

  function deleteNestedIdFromArray(part, nestedIdToDelete) {
    let newSignatories = [];
    if (part) {
      newSignatories = part.relatedSignatories.filter(
        (nestedObj) => nestedObj.signatory?._id !== nestedIdToDelete
      );
    }
    const updatedObject = { ...part, relatedSignatories: newSignatories };

    return updatedObject;
  }

  // Handles
  const handleDeletePartSelected = (part) => {
    const newParts = partsSelected.filter((t) => t.id !== part);
    setPartsSelected(newParts);
    if (newParts.length > 0) {
      let filteredParts = [];
      const selectedList = newParts.map((item) => item.id);
      filteredData.rows.forEach((item) => {
        const list = item.relatedParts;
        const filterList = list.some((item) => selectedList.includes(item._id));
        if (filterList) {
          filteredParts.push(item);
        }
      });

      setFilteredData({ ...filteredData, rows: filteredParts });
    } else {
      /**Ninguna parte se está buscando */
      setFilteredData(data);
    }
  };

  const handleAddCorporateUnit = async (data) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalSelectUC(false);
      const company = localStorage.getItem("company");
      const corporateUnitsSelected = data.map((uc) => uc?._id);
      const currentCorporateUnits = corporateUnitsSelected.filter(
        (newUc) =>
          !signatorySelected.associatedCorporateUnits.some(
            (uc) => uc?._id === newUc
          )
      );
      const obj = {
        company,
        signatoryId: signatorySelected?._id,
        associatedCorporateUnits: currentCorporateUnits,
        actionToSignatory: "ADD",
      };
      if (!currentCorporateUnits.length) return;
      const [responseDataCorporateUnit, responseDataPart] = await Promise.all([
        addCorporateUnitToSignatory(obj),
        updateSignatory({
          ...obj,
          associatedCorporateUnits: corporateUnitsSelected,
        }),
      ]);
      setIsOpenModalResponse(true);
      setResponseData(responseDataCorporateUnit);
      if (
        responseDataCorporateUnit.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataPart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataCorporateUnit.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS &&
        responseDataPart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        dispatch(resetSignatoriesListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
      setSignatorySelected(null);
    }
  };

  const handleDeleteCorporateUnit = async ({ id, element }) => {
    try {
      setIsLoadingAll(true);
      const newCorporateUnitsRelated = element.associatedCorporateUnits
        .filter((uc) => uc._id !== id)
        .map((uc) => uc?._id);
      const company = localStorage.getItem("company");
      const obj = {
        company,
        signatoryId: element?._id,
        associatedCorporateUnits: newCorporateUnitsRelated,
        actionToSignatory: "DELETE",
      };

      const obj2 = {
        company,
        signatoryId: element?._id,
        associatedCorporateUnits: [id],
        actionToSignatory: "DELETE",
      };
      const [responseDataCorporateUnit, responseDataPart] = await Promise.all([
        addCorporateUnitToSignatory(obj2),
        updateSignatory(obj),
      ]);
      setIsOpenModalResponse(true);
      setResponseData(responseDataCorporateUnit);
      if (
        responseDataCorporateUnit.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataPart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDataCorporateUnit.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS &&
        responseDataPart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        dispatch(resetSignatoriesListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleOpenModalListCorporateUnits = (info) => {
    setUnitCorporativeSelected(info.associatedCorporateUnits);
    setSignatorySelected(info);
    setIsOpenModalSelectUC(true);
  };

  const handleSelectedViewDetails = (item) => {
    setAnchorSelected(null);
    switch (item) {
      case 0:
        navigate(`update/${signatorySelected._id}`);

        break;
      case 1:
        setIsOpenModalDecisionDeleteSignatory(true);
        break;
      case 2:
        setOpenDetailsDrawner(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModalDeleteSignatory = () => {
    setIsOpenModalDecisionDeleteSignatory(false);
  };

  const handleDeleteSignatory = async (item) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalDecisionDeleteSignatory(false);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const newStatusId = SIGNATORIES_STATE.DELETED._id;
      const responseDeleteSignatory = await deleteSignatoryTemporarily({
        company,
        signatoryId: item?._id,
        status: newStatusId,
        modifiedBy: userId,
      });
      setIsOpenModalDeleteSignatoryResponse(true);
      setDeleteSignatoryResponse(responseDeleteSignatory);
      getPartsWithDefaultSignatory(item);
      if (
        responseDeleteSignatory.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeleteSignatory.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const elementsToDelete = item?.relatedParties?.map((relatedPart) =>
          PartsDtoFactory.generateManagePartToSignatoryDto(
            item?._id,
            relatedPart?.part?._id,
            relatedPart?.position
          )
        );
        if (elementsToDelete?.length) {
          const signatoriesForPart = [];
          const partsForSignatory = [];
          signatoriesForPart.push(
            PartsDtoFactory.generateManageSignatoryToPartsDto(
              elementsToDelete,
              MANAGE_SIGNATORIES_PARTS_ACTIONS.DELETE
            )
          );
          partsForSignatory.push(
            PartsDtoFactory.generateManagePartToSignatoriesDto(
              elementsToDelete,
              MANAGE_SIGNATORIES_PARTS_ACTIONS.DELETE
            )
          );
          await manageSignatoriesPartsRelation(
            PartsDtoFactory.generateManageSignatoriesPartsRelationDto(
              signatoriesForPart,
              partsForSignatory
            )
          );
        }
        setDeleteSignatoryResponse(responseDeleteSignatory);
        dispatch(resetSignatoriesListStatus());
        dispatch(resetPartListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleSelectedDefaultSignatoryForPart = (item) => {
    if (partsWithDefaultSignatory.length > 0) {
      const tempPartsWithDefaultSignatory = partsWithDefaultSignatory.filter(
        (part) => part._id !== item._id
      );
      setPartsWithDefaultSignatory(tempPartsWithDefaultSignatory);
      setSelectedPartWithDefaultSignatory(tempPartsWithDefaultSignatory[0]);
      setIsOpenModalSignatoriesInfo(
        tempPartsWithDefaultSignatory[0] ? true : false
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = [];
        if (signatoriesListStatus === "fetch") {
          dispatch(fetchSignatoriesList());
        }
        data = signatoriesList;
        const tranformData = buildData(data);
        setData({ rows: tranformData, columns });
        setFilteredData({ rows: tranformData, columns });
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [signatoriesList, signatoriesListStatus, dispatch]);

  useEffect(() => {
    if (signatoriesListStatus === "loading") {
      setIsLoadingAll(true);
    } else {
      setIsLoadingAll(false);
    }
  }, [signatoriesListStatus]);

  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
    if (statusFetchUnits === "loading") {
      setIsLoadingFetchUCl(true);
    } else {
      setIsLoadingFetchUCl(false);
    }
  }, [statusFetchUnits]);

  useEffect(() => {
    if (isLoadingFetchUCl || isLoadingAll) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingFetchUCl, isLoadingAll]);

  return (
    <div>
      <Row>
        <Col xs={"auto"} className="caption">
          Gestiona las personas naturales que pueden ser firmantes en tus
          documentos.
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<PersonAddAlt1Outlined />}
              className="custom-input__button__primary-color"
              onClick={() => navigate("create/signatory")}
            >
              <p className="button">Crear firmante </p>
            </Button>
          </Col>
          <Col xs={"auto"}>
            <FilterByPart
              list={data}
              setFilteredInfo={setFilteredData}
              handlePartSelected={handlePartsSelected}
              partList={partsList} //SIGNATORIES LISTT
              currentPartsSelected={partsSelected}
            />
          </Col>
        </Col>
      </Row>
      <br />
      <Row>
        {partsSelected.map((part) => {
          return (
            <Col xs={"auto"} key={part?.id}>
              <span className="caption custom-badges__secundary">
                {part.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeletePartSelected(part.id)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row className="dt-list-center-content">
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes firmantes creados"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Menu
        id="basic-menu"
        anchorEl={anchorSelected}
        open={openSelected}
        onClose={() => setAnchorSelected(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleSelectedViewDetails(0);
          }}
        >
          <ListItemIcon>
            <EditOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Roboto",
              fontSize: "1.4rem",
              fontWeight: "regular",
              lineHeight: "2rem",
              color: "#676879",
            }}
            primary={"Editar"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectedViewDetails(1);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Roboto",
              fontSize: "1.4rem",
              fontWeight: "regular",
              lineHeight: "2rem",
              color: "#676879",
            }}
            primary={"Eliminar"}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectedViewDetails(2);
          }}
        >
          <ListItemIcon>
            <InfoOutlinedIcon
              fontSize="large"
              className="heading__primary-color"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Roboto",
              fontSize: "1.4rem",
              fontWeight: "regular",
              lineHeight: "2rem",
              color: "#676879",
            }}
            primary={"Ver detalles"}
          />
        </MenuItem>
      </Menu>
      <ModalListSelectCheck
        handleAgree={handleAddCorporateUnit}
        list={listUnitsCorporatives}
        onClose={() => setIsOpenModalSelectUC(false)}
        open={isOpenModalSelectUC}
        title="Añadir unidades corporativas a un firmante"
        currentDataSelected={unitCorporativeSelected}
      />
      <DetailBarParts
        openDetailsDrawner={openDetailsDrawner}
        handleClose={() => {
          setOpenDetailsDrawner(false);
          setSignatorySelected(null);
        }}
        selectedElement={signatorySelected}
        selectedElementIsPart={false}
      />
      <ModalInfo
        title={"Firmantes"}
        responseData={responseData}
        onClose={() => setIsOpenModalResponse(false)}
        open={isOpenModalResponse}
        buttonClose={true}
        confirmationText="Aceptar"
        handleCloseDefault={() => setIsOpenModalResponse(false)}
      />
      <ModalDecision
        title={"Eliminar firmante"}
        message={
          "Si eliminas al firmante dejarás de tener sus datos disponibles para la creación de documentos. ¿Seguro quieres eliminarlo?"
        }
        agreeText={"Eliminar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          handleDeleteSignatory(signatorySelected);
        }}
        handleDisagree={handleCloseModalDeleteSignatory}
        onClose={handleCloseModalDeleteSignatory}
        open={isOpenModalDecisionDeleteSignatory}
        type={"warning"}
      />
      <ModalInfo
        title={
          deleteSignatoryResponse?.status === CODES.COD_RESPONSE_HTTP_OK &&
          deleteSignatoryResponse?.data?.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
            ? "Firmante eliminado"
            : "Error eliminando al firmante"
        }
        responseData={deleteSignatoryResponse}
        open={isOpenModalDeleteSignatoryResponse}
        buttonClose={true}
        confirmationText="Aceptar"
        onClose={() => setIsOpenModalDeleteSignatoryResponse(false)}
        handleCloseDefault={() => setIsOpenModalDeleteSignatoryResponse(false)}
      />
      {isOpenModalSignatoriesInfo && (
        <ModalInfoSignatories
          data={selectedPartWithDefaultSignatory}
          open={isOpenModalSignatoriesInfo}
          handleClose={() => {
            setIsOpenModalSignatoriesInfo(false);
            handleSelectedDefaultSignatoryForPart(
              selectedPartWithDefaultSignatory
            );
          }}
          selectedElement={selectedPartWithDefaultSignatory}
          title={"Firmantes por defecto"}
          message={`Elige al firmante por defecto que quieres que aparezca como primera opción al crear documentos con esta parte (${getPartName(
            selectedPartWithDefaultSignatory
          )})`}
          showButtons={false}
        />
      )}
    </div>
  );
};
