import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { Container, Form, InputGroup, Row } from "react-bootstrap";

const CustomSearchFilter = ({
  list,
  setFilteredData,
  placeholder = "Buscar",
}) => {
  const filterSentence = (word) => {
    if (word) {
      const filterWord = word;
      const filter = list?.filter(function (elem) {
        if (elem.hasOwnProperty("title")) {
          return (
            elem?.title.toLowerCase().indexOf(filterWord.toLowerCase()) > -1
          );
        } else if (elem.hasOwnProperty("name")) {
          return (
            elem?.name.toLowerCase().indexOf(filterWord.toLowerCase()) > -1
          );
        } else if (elem.hasOwnProperty("description")) {
          return (
            elem?.description.toLowerCase().indexOf(filterWord.toLowerCase()) >
            -1
          );
        }
      });
      setFilteredData(filter);
    } else {
      setFilteredData(list);
    }
  };

  return (
    <Container fluid>
      <Row>
        <InputGroup bsPrefix="input-group-container" style={{ padding: "8px" }}>
          <Form.Control
            bsPrefix="custom-input"
            placeholder={placeholder}
            onChange={(e) => filterSentence(e.target?.value)}
          />
          <InputGroup.Text bsPrefix="container-icon">
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Row>
    </Container>
  );
};

export default CustomSearchFilter;
