import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function getContractsTypesByCompany({ companyId, isPublish }) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "GET",
    url: "contractsTypes/getAll",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      company: companyId,
      corporateUnit,
      isPublish,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getContractTypeById(data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "GET",
    url: `contractsTypes/getById/${data}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      corporateUnit,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function contractTypeCreate(data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "POST",
    url: "contractsTypes/createContractType",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      ...data,
      corporateUnit,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function contractTypeEdit(id, data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "PUT",
    url: `contractsTypes/modifyContractTypes/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      ...data,
      corporateUnit,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteContractTypeById(data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "DELETE",
    url: `contractsTypes/deleteContractType/${data}`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      corporateUnit,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateDeletedContractTypes({
  id,
  company,
  corporateUnit,
  state,
}) {
  return axios({
    method: "PUT",
    url: `contractsTypes/updateDeletedContractTypes/${id}`,
    baseURL: env.REACT_APP_API_URL,
    data: {
      company,
      corporateUnit,
      state,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getAllDeleteContractTypes({ company, corporateUnit }) {
  return axios({
    method: "GET",
    url: `contractsTypes/getAllDeleteContractTypes`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      company,
      corporateUnit,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function updatePublishStatusContractType({
  company,
  corporateUnit,
  id,
  isPublish,
  description,
  associatedCorporateUnit
}) {
  return axios({
    method: "PUT",
    url: `contractsTypes/updatePublishStatusContractType/${id}`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      company,
      corporateUnit,
    },
    data: { isPublish, description, associatedCorporateUnit },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
