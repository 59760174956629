import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
const PopoverActions = ({
  type = "default",
  description,
  icon,
  classNameButton = "custom-input__button__default-color",
  actions,
  containerRef,
  setIsOpenExternal,
  textToCopy,
  link,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleCopyText = ({ text }) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Error al copiar al portapapeles:', err));
  };
  const popover = (
    <Popover
      style={{ margin: "0" }}
      className={`custom-popover__${type}`}
      id={props.id}
    >
      <Popover.Body className="custom-popover body-content__center">
        <div
          style={{
            width: "max-content",
            position: "absolute",
            right: "0",
            top: "0",
            zIndex: "10"
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setShow(false);
              if (setIsOpenExternal) {
                setIsOpenExternal(false);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Row style={{ alignItems: "center" }}>
          <Col xs={4}>
            <img
              style={{ width: "100%" }}
              src={require("../../assets/svg/iaAdvices.svg").default}
              alt="logo-ia"
            />
          </Col>
          <Col xs={8}>
            <Row className="custom-popover__body" style={{ textAlign: "justify" }}>
              <Col>
                {description}{" "}
                {textToCopy && (
                  <IconButton
                      title={copied ? '¡Copiado!' : 'Copiar texto'}
                      aria-label="close"
                      onClick={() => handleCopyText({ text: textToCopy})}
                      sx={{ color: (theme) => theme.palette.grey[500], padding: 0 }}
                  >
                      {copied ? <AssignmentTurnedInOutlinedIcon /> : <ContentCopyOutlinedIcon />}
                  </IconButton>
                )}
                {link && (
                  <p>
                    <a
                      className="label__link"
                      href={link.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.text}
                    </a>
                  </p>
                )}
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              {actions?.map(
                (item) =>
                  item.show && (
                    <Col key={uuidv4()} xs={"auto"}>
                      <Button
                        className="custom-input__button__withOutBackground"
                        size="large"
                        onClick={item.handleAction}
                      >
                        {item.text}
                      </Button>
                    </Col>
                  )
              )}
            </Row>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={show}
      placement="auto"
      overlay={popover}
      rootClose
      trigger="click"
      container={containerRef?.current}
    >
      <IconButton
        aria-label="icon"
        className={classNameButton}
        sx={{ borderRadius: "4px" }}
        onClick={() => {
          setShow((show) => !show);
          if (setIsOpenExternal) {
            setIsOpenExternal((show) => !show);
          }
        }}
      >
        {icon}
        {props?.textIcon || ""}
      </IconButton>
    </OverlayTrigger>
  );
};

export default PopoverActions;
