
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { VisibilityOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const MENU_OPTIONS_DELETE = [
    {
        title: "Restaurar",
        icon: <RestoreFromTrashOutlinedIcon fontSize="large" />,
        option: 0,
    },
    {
        title: "Eliminar permanentemente",
        icon: <DeleteForeverOutlinedIcon fontSize="large" />,
        option: 1,
    },
    {
        title: "Ver detalles",
        icon: <InfoOutlinedIcon fontSize="large" />,
        option: 2,
    },
    {
        title: "Visualizar",
        icon: <VisibilityOutlined fontSize="large" />,
        option: 3,
    },
].sort((a, b) => a.title.localeCompare(b.title));