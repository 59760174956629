import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Breadcrum from "../../../../../../../parts/breadcrum";
import { Col, Container, Form, Row } from "react-bootstrap";

import { REGEXP } from "../../../../../../../utils/regexp";

import { Button } from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedPart,
  resetPartListStatus,
  resetSignatoriesListStatus,
  setSelectedPart,
} from "../../../../../../../parts/parts/partsSlice";
import {
  findPartById,
  getPartInfoFromFile,
  manageRelatedPartToSignatory,
  updatePart,
} from "../../../../../../../services/parts/partsServices";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../../../components/loadingContent";
import { CODES } from "../../../../../../../utils/codes";
import { UpdateContactInfo } from "./ContactInfo/updateContactInfo";
import ModalUploadDocs from "../../../../../../../components/Modals/ModalUploadDocs";
import { TYPE_CHARGES } from "../utils";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import ClearIcon from "@mui/icons-material/Clear";
import EllipsisComponent from "../../../../../../../components/Text/EllipsisComponent";
import { removeHtmlTags } from "../../../../../../../utils/partsCorrection";
export const UpdatePartLegalLibrary = () => {
  //States
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFormInfoContact, setIsShowFormInfoContact] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [fieldSuggestions, setFieldSuggestions] = useState([]);
  const [newFile, setNewFile] = useState({});
  const [newFileInfo, setNewFileInfo] = useState({});
  const [tagsSelected, setTagsSelected] = useState([]);
  const [selectedSignatories, setSelectedSignatories] = useState([]);
  const [deletedSignatoryList, setDeletedSignatoryList] = useState([]);
  const [isChangeValue, setIsChangeValue] = useState(false);
  //Redux
  const dispatch = useDispatch();
  const currentPart = useSelector(getSelectedPart);

  //Router
  const navigate = useNavigate();

  //React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({});

  //Use Effect
  useEffect(async () => {
    try {
      if (currentPart._id) {
        setValue("business", currentPart?.business?.businessName);
        setValue("nameTradeLegal", currentPart?.comercialName);
        setValue("documentNumberLegal", currentPart?.business?.nit);
        setValue("documentTypeLegal", currentPart?.business?.type);
        setValue(
          "otherTypeDocument",
          currentPart?.legalRepresentative?.otherTypeDocument
        );

        setValue("firstName", currentPart?.legalRepresentative?.firstName);
        setValue("lastName", currentPart?.legalRepresentative?.secondName);
        setValue(
          "firstSurname",
          currentPart?.legalRepresentative?.firstSurname
        );
        setValue(
          "secondSurname",
          currentPart?.legalRepresentative?.secondSurname
        );
        setValue(
          "documentType",
          currentPart?.legalRepresentative?.documentType
        );
        setValue(
          "documentNumber",
          currentPart?.legalRepresentative?.documentNumber
        );
        setValue(
          "limitationsLegalRepresentative",
          removeHtmlTags(currentPart?.legalRepresentative?.limitations)
        );

        setValue("email", currentPart?.email);
        setValue("confirmEmail", currentPart?.email);

        setValue("countryCode", currentPart?.countryCodeNumber);
        setValue("numberPhone", currentPart?.phone);

        setValue("countryCodeTelephone", currentPart?.countryCodeTelephone);
        setValue("cityCode", currentPart?.cityCode);
        setValue("extension", currentPart?.extension);
        setValue("telephoneNumber", currentPart?.numberTelephone);

        setValue("country", currentPart?.country);
        setValue("province", currentPart?.province);
        setValue("city", currentPart?.city);

        setValue("address", currentPart?.physicalAddress);

        const tags = currentPart.labels;
        const tagsId = tags.map((tag) => tag._id);
        setValue("labels", tagsId || []);
        setTagsSelected(tags);
        setSelectedSignatories(currentPart.relatedSignatories);
        if (currentPart.bucketName) {
          setNewFile({
            bucketName: currentPart?.bucketName,
            cerlFileName: currentPart?.cerlFileName,
            filenameInBucket: currentPart?.filenameInBucket,
          });
        }
      } else {
        setIsLoading(true);
        const service = await findPartById({ partId: id });
        if (
          service.status === CODES.COD_RESPONSE_HTTP_OK &&
          service?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          dispatch(setSelectedPart(service.data?.responseMessage?.data));
        }
        setIsLoading(false);
      }
    } catch (error) {}
  }, [currentPart]);

  useEffect(async () => {
    if (documentData.name) {
      await handleUploadDoc();
    }
  }, [documentData]);

  //handles

  const handleShowForm = () => {
    setIsShowFormInfoContact(true);
  };

  const handleUpdatePart = async (data) => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      let identificationData = {};

      identificationData = {
        business: {
          businessName: data?.business || "",
          nit: data?.documentNumberLegal || "",
          type: data?.documentTypeLegal || "",
          checkDigit: "",
        },
        legalRepresentative: {
          documentType: data?.documentType || "",
          documentNumber: data?.documentNumber || "",
          firstName: data?.firstName || "",
          firstSurname: data?.firstSurname || "",
          secondName: data?.lastName || "",
          secondSurname: data?.secondSurname || "",
          limitations: data?.limitationsLegalRepresentative || "",
          otherTypeDocument: data?.otherTypeDocument || "",
        },
        comercialName: data?.nameTradeLegal || "",
        charge: TYPE_CHARGES?.LEGAL,
      };

      const contactInfo = {
        email: data?.email || "",
        countryCodeNumber: data?.countryCode || "",
        countryCodeTelephone: data?.countryCodeTelephone || "",
        cityCode: data?.cityCode || "",
        extension: data?.extension || "",
        phone: data?.numberPhone || "",
        numberTelephone: data?.telephoneNumber || "",
        country: data?.country || "",
        city: data?.city || "",
        province: data?.province || "",
        physicalAddress: data?.address || "",
      };

      const associatedSignatories = selectedSignatories.map((item) => {
        return {
          signatory: item.signatory?._id,
          isDefault: item.isDefault,
          position: item.position,
          limitations: data?.limitationsLegalRepresentative,
          limitationsUpdatedDate: new Date(),
          part: currentPart._id,
        };
      });
      const obj = {
        ...contactInfo,
        ...identificationData,

        modifiedBy: userId,
        company,

        relatedSignatories: associatedSignatories,
        partId: currentPart._id,
        labels: data?.labels || [],

        filenameInBucket: newFile.filenameInBucket || "",

        bucketName: newFile.bucketName || "",
      };

      const response = await updatePart(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        if (deletedSignatoryList.length > 0) {
          const transformedArrayForUpdate = deletedSignatoryList.map((item) => {
            return {
              part: currentPart._id,
              signatory: item.signatory._id,
              position: item.position || "Apoderado",
            };
          });
          const serviceRemovePartsToSignatories =
            await manageRelatedPartToSignatory({
              managePartToSignatories: transformedArrayForUpdate,
              action: "DELETE",
            });
          if (
            serviceRemovePartsToSignatories.status ===
              CODES.COD_RESPONSE_HTTP_OK &&
            serviceRemovePartsToSignatories?.data?.responseCode ===
              CODES.COD_RESPONSE_SUCCESS
          ) {
            setResponseData(response);
            setIsOpenModalInfo(true);
          }
        }
        if (associatedSignatories.length > 0) {
          await manageRelatedPartToSignatory({
            managePartToSignatories: associatedSignatories,
            action: "ADD",
          });
        }
        setResponseData(response);
        setIsOpenModalInfo(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadDoc = async () => {
    const company = localStorage.getItem("company");
    const createdBy = JSON.parse(localStorage.getItem("payloadToken")).userId;
    const obj = {
      file: documentData,
      company,
      createdBy,
    };

    setIsLoading(true);
    const service = await getPartInfoFromFile(obj);
    if (
      service?.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      service?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      let newFileInfoResponse = JSON.parse(
        service?.data?.responseMessage?.data
      );
      setFieldSuggestions(
        newFileInfoResponse ? Object.entries(newFileInfoResponse) : {}
      );

      setNewFileInfo(newFileInfoResponse);

      setOpenModalDecision(true);
    } else {
      setResponseData(service);
      setIsOpenModalInfo(true);
    }
    setDocumentData({});

    setIsLoading(false);
  };

  const handleCloseModalDecision = () => {
    setOpenModalDecision(false);
  };

  const handleAcceptChanges = () => {
    fieldSuggestions.map(([key, value]) => {
      if (value.value) {
        setValue(key, value.value);
        if (key === "email") {
          setValue("confirmEmail", value.value);
        }
      }

      setNewFile({
        bucketName: newFileInfo?.bucketName,
        filenameInBucket: newFileInfo?.filenameInBucket,
        cerlFileName: newFileInfo?.cerlFileName,
      });
    });
    setOpenModalDecision(false);
  };

  return (
    <>
      <Container fluid className="custom-container-scroll">
        {isLoading && <LoadingContent />}
        <Row className="mb-4">
          <Breadcrum />
        </Row>
        <Row>
          <Col className="home-init-cards-row__title-row mb-4">
            <span>Editar Parte</span>
          </Col>
          <Col>
            <Row style={{ justifyContent: "end" }} xs={"auto"}>
              <Col xs={4}>
                <Button
                  variant="contained"
                  type="button"
                  className="custom-input__button__secondary-color"
                  startIcon={<UploadFileIcon fontSize="large" />}
                  onClick={() => {
                    setDocumentData({});
                    setOpenModalUploadDocument(true);
                  }}
                >
                  Cargar certificado
                </Button>
              </Col>

              {newFile?.cerlFileName && (
                <Col xs={4}>
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span className="caption custom-badges__disabled">
                      {`${newFile?.cerlFileName} `}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => {
                          setNewFile({});
                        }}
                      />
                    </span>
                  </Col>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-description mb-5">
            <p>Diligencia los siguientes campos para editar la Parte.</p>
          </Col>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit(handleUpdatePart)}>
            {!isShowFormInfoContact && (
              <>
                <Row>
                  <Col xs={"auto"} className="label__subtitle">
                    <span>Datos de identificación:</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Razón social<span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        id={"field-business"}
                        {...register("business", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("business", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Razón social"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.business
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.business?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Nombre comercial
                      </Form.Label>
                      <Form.Control
                        id={"field-name-trade"}
                        {...register("nameTradeLegal", {
                          required: false,
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("nameTradeLegal", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Nombre comercial"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.nameTradeLegal
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.nameTradeLegal?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Tipo de documento
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        id={"field-document-type-legal"}
                        {...register("documentTypeLegal", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("documentTypeLegal", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Tipo de documento"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.documentTypeLegal
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.documentTypeLegal?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Número de documento
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        id={"field-document-number-legal"}
                        {...register("documentNumberLegal", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("documentNumberLegal", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Número de documento"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.documentNumberLegal
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.documentNumberLegal?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col xs={"auto"} className="label__subtitle">
                    <span>Datos del representante legal</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Primer nombre
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        id={"field-firstName-legal"}
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("firstName", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Primer nombre"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.firstName
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.firstName?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Segundo nombre
                      </Form.Label>
                      <Form.Control
                        id={"field-lastName-legal"}
                        {...register("lastName", {
                          required: false,
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("lastName", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Segundo nombre"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.lastName
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.lastName?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Primer apellido
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        id={"field-firstSurname-legal"}
                        {...register("firstSurname", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("firstSurname", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Primer apellido"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.firstSurname
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.firstSurname?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Segundo apellido
                      </Form.Label>
                      <Form.Control
                        id={"field-secondSurname-legal"}
                        {...register("secondSurname", {
                          required: false,
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("secondSurname", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Segundo apellido"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.secondSurname
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.secondSurname?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Tipo de documento
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Select
                        id={"field-documentType-legal"}
                        {...register("documentType", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                        })}
                        onChange={(event) => {
                          setValue("documentType", event.target.value);
                          setIsChangeValue(true);
                        }}
                        className="label"
                        defaultValue={""}
                      >
                        <option
                          value=""
                          disabled
                          defaultValue
                          className="label"
                        >
                          Tipo de documento
                        </option>
                        <option value="CEDULA DE CIUDADANIA" className="label">
                          Cédula de ciudadanía - COL
                        </option>
                        <option value="TARJETA DE IDENTIDAD" className="label">
                          Tarjeta de identidad - COL
                        </option>
                        <option value="CEDULA DE EXTRANJERIA" className="label">
                          Cédula de extranjería - COL
                        </option>
                        <option value="INE" className="label">
                          INE - MEX
                        </option>
                        <option value="CURP" className="label">
                          CURP - MEX
                        </option>
                        <option value="PASAPORTE" className="label">
                          Pasaporte
                        </option>
                        <option value="OTRO" className="label">
                          Otros
                        </option>
                      </Form.Select>
                      <div className="caption custom-input__error">
                        {errors?.documentType?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  {watch("documentType") === "OTRO" && (
                    <Col xs={"auto"} md={"auto"} lg={4}>
                      <Form.Group>
                        <Form.Label className="form__label">
                          Otro tipo de documento
                        </Form.Label>
                        <Form.Control
                          id={"field-otherTypeDocument-legal"}
                          {...register("otherTypeDocument")}
                          placeholder={"Ingrese tipo de documento"}
                          style={{
                            border: "0.1rem solid #c5c7d0",
                          }}
                          bsPrefix={
                            errors?.otherTypeDocument
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                        />
                        <div className="caption custom-input__error">
                          {errors?.otherTypeDocument?.message}
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Número de documento
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        id={"field-documentNumber-legal"}
                        {...register("documentNumber", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          pattern: {
                            value: REGEXP.LETTERS_AND_NUMBERS,
                            message: "*Solo debe incluir numeros y letras",
                          },
                        })}
                        onChange={(event) => {
                          setValue("documentNumber", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Número de documento"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.documentNumber
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.documentNumber?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {watch("documentType") === "TARJETA DE IDENTIDAD" && (
                  <>
                    <br />
                    <Row>
                      <Col xs={"auto"} md={"auto"} lg={8}>
                        <h1 className="text-description">
                          Recuerda que los contratos, así como otros documentos
                          legales deben ser firmados por personas que sean
                          plenamente capaces legalmente.
                        </h1>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={8}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Limitaciones a la representación legal
                      </Form.Label>
                      <Form.Control
                        id={"field-limitationsLegalRepresentative-legal"}
                        {...register("limitationsLegalRepresentative", {
                          required: false,
                        })}
                        onChange={(event) => {
                          setValue(
                            "limitationsLegalRepresentative",
                            event.target.value
                          );
                          setIsChangeValue(true);
                        }}
                        placeholder={"Limitaciones a la representación legal"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        as="textarea"
                        rows={3}
                        bsPrefix={
                          errors?.limitationsLegalRepresentative
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.limitationsLegalRepresentative?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "end" }}>
                  <Col xs={"auto"}>
                    {isShowFormInfoContact && (
                      <Button
                        id={"back-button"}
                        type="button"
                        variant="contained"
                        className="custom-input__button__secondary-color"
                        // onClick={() => handleShow(false)}
                      >
                        Regresar
                      </Button>
                    )}
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      id={"save-button"}
                      type="button"
                      variant="contained"
                      className="custom-input__button__primary-color"
                      onClick={handleSubmit(handleShowForm)}
                    >
                      Siguiente
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {isShowFormInfoContact && (
              <UpdateContactInfo
                register={register}
                errors={errors}
                watch={watch}
                handleSubmit={handleSubmit}
                control={control}
                handleShow={setIsShowForm}
                setValue={setValue}
                handleBack={() => setIsShowFormInfoContact(false)}
                tagsSelected={tagsSelected}
                setTagsSelected={setTagsSelected}
                selectedSignatories={selectedSignatories}
                setSelectedSignatories={setSelectedSignatories}
                currentPart={currentPart}
                deletedSignatoryList={deletedSignatoryList}
                setDeletedSignatoryList={setDeletedSignatoryList}
                isChangeValue={isChangeValue}
              />
            )}
          </Form>

          <ModalUploadDocs
            open={openModalUploadDocument}
            title={"Selecciona el documento para subir"}
            agreeText={"Continuar"}
            disagreeText={"Cancelar"}
            message={
              "El archivo que estas a punto de subir debe estar en formato PDF, pesar máximo 5mb y tener una vigencia máxima de 30 días."
            }
            documentData={documentData}
            setDocumentData={setDocumentData}
            onClose={() => {
              setOpenModalUploadDocument(false);
            }}
            typeDocument={"ONLY_PDF"}
            messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf"
            maxFileSize={5242880}
          />

          <ModalInfo
            title={
              responseData.status === CODES.COD_RESPONSE_HTTP_OK
                ? "Actualización exitosa"
                : "Problemas actualizando la parte"
            }
            responseData={responseData}
            onClose={() => {
              if (
                responseData.status === CODES.COD_RESPONSE_HTTP_OK &&
                responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS
              ) {
                navigate("/admin/library");
                dispatch(resetPartListStatus());
                dispatch(resetSignatoriesListStatus());
              }
              setIsOpenModalInfo(false);
            }}
            open={isOpenModalInfo}
            buttonClose={true}
            confirmationText="Aceptar"
            handleCloseDefault={() => setIsOpenModalInfo(false)}
          />
          <ModalDecision
            title={"Cambios en la Biblioteca"}
            message={
              <Row>
                <Col xs={12} className="mt-2">
                  <p className="caption">
                    Si cargas este documento, algunos datos de tu biblioteca
                    serán modificados. Antes de continuar, revisa los cambios
                    que se harían:
                  </p>
                </Col>
                <Col xs={12} className="mt-2">
                  <ol>
                    {fieldSuggestions.map(([key, value]) => {
                      if (value.value) {
                        if (key === "limitationsLegalRepresentative") {
                          return (
                            <li key={uuidv4()}>
                              {`${value.name}:`}
                              <EllipsisComponent
                                text={value.value}
                                isWhite={true}
                                isHtml={true}
                              />
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={uuidv4()}
                            >{`${value.name}: ${value.value}`}</li>
                          );
                        }
                      }
                    })}
                  </ol>
                </Col>
                <Col xs={12} className="mt-2">
                  <p className="caption">
                    ¿Estás de acuerdo con los cambios propuestos?
                  </p>
                </Col>
              </Row>
            }
            open={openModalDecision}
            onClose={handleCloseModalDecision}
            agreeText="Aceptar"
            disagreeText="Cancelar"
            handleAgree={handleAcceptChanges}
            handleDisagree={handleCloseModalDecision}
          />
        </Row>
      </Container>
    </>
  );
};
