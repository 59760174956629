import { findCompanyPlan } from "../services/plans/planServices";
import { CODES } from "./codes";

export const GetPlanInfo = async () => {
  const companyId = localStorage.getItem("company");
  const companyPlan = await findCompanyPlan({ companyId });
  if (
    companyPlan.status === CODES.COD_RESPONSE_HTTP_OK &&
    companyPlan.data.success
  ) {
    return companyPlan?.data?.responseMessage?.data?.plan;
  }
  return null;
};

export const GetPlanInfoById = async ({ id }) => {
  const companyPlan = await findCompanyPlan({ companyId: id });
  if (
    companyPlan.status === CODES.COD_RESPONSE_HTTP_OK &&
    companyPlan.data.success
  ) {
    return companyPlan?.data?.responseMessage?.data?.plan;
  }
  return null;
};
export const validateEnablePlan = async () => {
  const companyPlanInfo = await GetPlanInfo();

  const limitExtensions = companyPlanInfo?.limitsExtension;

  const limitUserExtensions = companyPlanInfo?.limitsExtension?.users;
  const storageUsed = companyPlanInfo?.storageUsed;

  const capacityStorage =
    companyPlanInfo?.businessPlan?.storage + limitExtensions?.storage;

  const capacityUserService =
    companyPlanInfo?.businessPlan?.users.serviceUsers +
    limitUserExtensions?.serviceUsers;

  const capacityUserAdmin =
    companyPlanInfo?.businessPlan?.users.adminUsers +
    limitUserExtensions?.adminUsers;

  const capacityUserExternal =
    companyPlanInfo?.businessPlan?.users.externalUsers +
    limitUserExtensions?.externalUsers;

  const capacityTotalUser =
    capacityUserService + capacityUserAdmin + capacityUserExternal;

  let users = companyPlanInfo?.actualUsers;

  const usersCreated =
    users?.serviceUsers + users?.adminUsers + users?.externalUsers;

  const capacitySignature =
    companyPlanInfo?.businessPlan?.signatures + limitExtensions?.signatures;

  const signaturesCreated = companyPlanInfo?.signaturesUsed;
  const capacityRequest =
    companyPlanInfo?.businessPlan?.request + limitExtensions?.requests;

  const requestsCreated = companyPlanInfo?.requestsUsed;

  const capacityContract =
    companyPlanInfo?.businessPlan?.contracts + limitExtensions?.contracts;

  const contractsCreated = companyPlanInfo?.contractsUsed;

  const infoCompanyPlan = {
    isStorageEnable: storageUsed < capacityStorage,
    isUserEnable: usersCreated < capacityTotalUser,
    isSignatureEnable: signaturesCreated < capacitySignature,
    isRequestsEnable: requestsCreated < capacityRequest,
    isContractsEnable: contractsCreated < capacityContract,
    isUserAdminEnable: users?.adminUsers < capacityUserAdmin,
    isUserExternalEnable: users?.externalUsers < capacityUserExternal,
    isUserServiceEnable: users?.serviceUsers < capacityUserService,
  };

  return infoCompanyPlan;
};
