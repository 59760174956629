import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateFolder from "../../../../../../components/Modals/ModalAssociateFolder";
import ModalAssociateVersion from "../../../../../../components/Modals/modalAssociateVersion";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  setHeader,
  setIdMongo,
  setInfoDocument,
  setInitialInfoLibrary,
  setName,
  setReferenceNormativeClearStatus,
  setSelectedClausesWithoutCheckPart,
} from "../../../../../../parts/document/currentDocumentSlice";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../../../../../parts/folders/foldersSlice";
import {
  fetchFolderTags,
  getFolderTagsStatus,
} from "../../../../../../parts/folders/folderTagSlice";
import { getCompanyPlan } from "../../../../../../parts/storage/storageSlice";
import {
  findRulesByCorporateUnit,
  getDocumentReviewRule,
} from "../../../../../../services/admin/rules/rulesServices";
import {
  getDocById,
  getFileDocument,
  segmentDocumentWithFunctIA,
} from "../../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { createNewVersion } from "../../../../../../services/versions/versionRequest";
import {
  FROM_REVIEW,
  REVIEW_TYPES,
} from "../../../../../../utils/approvalsState";
import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import { ChooseReviewRule } from "./ChooseReviewRule";
import { Buffer } from "buffer";
import { wordCreatorFile } from "../../../../../../components/WordCreator/WordCreator";
import { Mixpanel } from "../../../../../../utils/mixPanel";
import axios from "axios";
import LoadingContentInBackground from "../../../../../../components/loadingContentInBackground";
import GetToken from "../../../../../../utils/getToken";
import WorkerBuilder from "../../../../../../workers/worker-builder";
import WorkerOtherDocs from "../../../../../../workers/reviewOtherDocumentWorker";
import WorkerContract from "../../../../../../workers/reviewContractWorker";
import { env } from "../../../../../../env";
import {
  selectAdditionalInfoForModule,
  selectIsFetchingInfoForModule,
  selectUserLeftForModule,
  setBackgroundProcessingAdditionalInfoForModule,
  setFinishProcessingBackgroundProcess,
  setIsFetchingInfoBackgroundProcess,
} from "../../../../../../parts/backgroundProcess/backgroundProcessSlice";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
import {
  deleteFileFromIndexedDB,
  getFileFromIndexedDB,
  saveFileToIndexedDB,
} from "../../../../../../utils/indexdb";

const UploadFirstTime = () => {
  //  worker
  const reviewWorkerRef = useRef(null);
  const reviewWorkerRefContract = useRef(null);
  const serviceUrl = env.REACT_APP_API_URL;
  const token = GetToken();
  //Background process redux
  const isFetchingInfoReview = useSelector((state) =>
    selectIsFetchingInfoForModule(state, "backgroundProcessReview")
  );

  const userLeftReview = useSelector((state) =>
    selectUserLeftForModule(state, "backgroundProcessReview")
  );
  const userLeftReviewRef = useRef(userLeftReview);
  userLeftReviewRef.current = userLeftReview;

  const userLeftContract = useSelector((state) =>
    selectUserLeftForModule(state, "backgroundProcessReviewContract")
  );
  const userLeftContractRef = useRef(userLeftContract);
  userLeftContractRef.current = userLeftContract;

  const additionalInfoContractBackground = useSelector((state) =>
    selectAdditionalInfoForModule(state, "backgroundProcessReviewContract")
  );

  const isFetchingInfoContract = useSelector((state) =>
    selectIsFetchingInfoForModule(state, "backgroundProcessReviewContract")
  );
  const additionalInfoContractBackgroundRef = useRef(
    additionalInfoContractBackground
  );
  additionalInfoContractBackgroundRef.current =
    additionalInfoContractBackground;

  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusTags = useSelector(getFolderTagsStatus);
  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const companyPlan = useSelector(getCompanyPlan);
  const [disableSave, setDisableSave] = useState(false);
  //States
  const [documentData, setDocumentData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [versionSelected, setVersionSelected] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [isLoadingInBackground, setIsLoadingInBackground] = useState(false);
  const [allowBackgroundOptions, setAllowBackgroundOptions] = useState(true);

  const [isSaveinFolder, setIsSaveinFolder] = useState(false);
  const [openModalFolder, setOpenModalFolder] = useState(false);
  const [ShowDocumentOther, SetDocumentOther] = useState(false);
  const [showDocumentContract, setDocumentContract] = useState(false);
  const [SelectedDocument, setSelectedDocument] = useState();
  const [reviewRule, setReviewRule] = useState("");
  const [isReviewRule, setIsReviewRule] = useState(false);
  const [listRules, setListRules] = useState([]);
  const [notFoundRulesMessage, setNotFoundRulesMessage] = useState({});
  const [isOpenModalInfoRulesNotFound, setIsOpenModalInfoRulesNotFound] =
    useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);
  const [isOpenModalLimitPlan, setIsOpenModalLimitPlan] = useState(false);

  const [openRuleList, setOpenRuleList] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [documentSelected, setDocumentSelected] = useState({});
  const [moduleName, setModuleName] = useState("");
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última modificación
        </label>,
      ],
      field: "updatedAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última acceso
        </label>,
      ],
      field: "lastAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];
  const rows = [];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [isEmptyDocumentLoad, setIsEmptyDocumentLoad] = useState(false);
  const [isEmptyFolderSelected, setIsEmptyFolderSelected] = useState(false);

  const [responseDataError, setResponseDataError] = useState({});
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const schema = isSaveinFolder
    ? yup.object().shape({
        owner: yup
          .string()
          .required("*Este campo es obligatorio")
          .max(250, "*Máximo 250 caracteres permitidos"),
        name: yup.string().required("*Este campo es obligatorio"),
      })
    : yup.object().shape({
        owner: yup
          .string()
          .required("*Este campo es obligatorio")
          .max(250, "*Máximo 250 caracteres permitidos"),
      });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const setInfoDocumentState = (document) => {
    const a = {
      ...document.documentContent.parts.partA,
    };
    const b = {
      ...document.documentContent.parts.partB,
    };

    let parts = [a, b];

    if (document.documentContent.parts.partC) {
      const c = {
        ...document.documentContent.parts.partC,
      };
      parts.push(c);
    }

    if (document.documentContent.parts.partD) {
      const d = {
        ...document.documentContent.parts.partD,
      };
      parts.push(d);
    }

    const finalObj = {
      title: document.documentContent.title,
      parts: parts,
    };
    dispatch(setInitialInfoLibrary(finalObj));
    dispatch(
      setSelectedClausesWithoutCheckPart({
        clauses: document.clauses.map((cla) => {
          const partsClause = [cla.parts.partA, cla.parts.partB];
          if (cla.parts.partC) partsClause.push(cla.parts.partC);
          if (cla.parts.partD) partsClause.push(cla.parts.partD);
          return cla?.parts ? { ...cla, parts: partsClause } : cla;
        }),
      })
    );
    dispatch(
      setName({
        name: document.documentContent.name,
      })
    );
    dispatch(
      setIdMongo({
        id: document.documentContent._id,
      })
    );
    for (const [i] of document.documentContent.headers.entries()) {
      dispatch(setHeader(document.documentContent.headers[i]));
    }
    dispatch(
      setInfoDocument({
        filenameInBucket: document.filenameInBucket,
        bucketName: document.bucketName,
      })
    );
  };

  //handle
  const handleRequest = async (data) => {
    if (!documentData.name && !documentSelected?.name) {
      setIsEmptyDocumentLoad(true);
      return;
    }
    if (!versionSelected._id && data.name) {
      setIsEmptyFolderSelected(true);
      return;
    }
    try {
      setOpenModalSave(false);
      setIsOpenModalLimitPlan(false);

      const payloadToken = JSON.parse(localStorage.payloadToken);
      let paramsContract = {};
      if (
        documentSelected?.fileType === codesDocumentType.contract._id ||
        documentSelected?.fileType === codesDocumentType.draft._id
      ) {
        paramsContract = {
          company: payloadToken.company,
          document: JSON.stringify(documentSelected?.documentContent),
          from: FROM_REVIEW.CLAUSES,
          typeReview: REVIEW_TYPES.NEW_LEGAL,
          bucketName: documentSelected?.bucketName,
          filenameInBucket: documentSelected?.filenameInBucket,
        };
      } else if (!documentData?.name) {
        paramsContract = {
          company: payloadToken.company,
          bucketName: documentSelected?.bucketName,
          filenameInBucket: documentSelected?.filenameInBucket,
          from: FROM_REVIEW.DOCUMENTS,
          typeReview: REVIEW_TYPES.NEW_LEGAL,
        };
      } else {
        let dataFile = documentData;
        if (documentData?.type !== "application/pdf") {
          setIsloading(true);
          const convertDoc = await convertToPdfService(documentData, "file");
          const docView = convertDoc.data.responseMessage.data.buffer.data;
          const bf = Buffer.from(docView);
          const name = documentData?.name.substring(
            0,
            documentData?.name.lastIndexOf(".")
          );
          dataFile = new File([bf], name + ".pdf", {
            type: "application/pdf",
          });
          setIsloading(false);
        }
        paramsContract = {
          file: dataFile,
          company: payloadToken.company,
          from: FROM_REVIEW.FILE,
          typeReview: REVIEW_TYPES.NEW,
        };
      }
      if (reviewWorkerRefContract.current) {
        setIsLoadingInBackground(true);
        setModuleName("backgroundProcessReviewContract");
        reviewWorkerRefContract.current.postMessage({
          paramsContract,
          token: token,

          serviceUrl: serviceUrl,
        });
        dispatch(
          setIsFetchingInfoBackgroundProcess({
            module: "backgroundProcessReviewContract",
            value: true,
          })
        );
        dispatch(
          setFinishProcessingBackgroundProcess({
            module: "backgroundProcessReviewContract",
            value: false,
          })
        );
        await saveFileToIndexedDB(documentData, "contractFile");
        dispatch(
          setBackgroundProcessingAdditionalInfoForModule(
            "backgroundProcessReviewContract",
            {
              otherInfo: {
                data: { name: data.name },
                paramsContract: { typeReview: paramsContract.typeReview },
                versionSelected: versionSelected,
              },
            }
          )
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setResponseDataError({
          status: 500,
          data: {
            responseMessage:
              "El proceso esta tomando más tiempo de lo normal, por favor intentelo más tarde.",
          },
        });
        setIsOpenModalError(true);
      }
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleProcessContractInfo = async (
    segmentDoc,
    data,
    paramsContract,
    versionSelected,
    documentData
  ) => {
    const corporateUnit = localStorage.getItem("corporateUnitId");
    if (
      !segmentDoc?.data?.success &&
      segmentDoc?.status !== CODES.COD_RESPONSE_HTTP_OK
    ) {
      setResponseDataError(segmentDoc);
      setIsOpenModalError(true);
      Mixpanel.track("Msj error segmentando este documento ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      return;
    }
    if (data.name) {
      const version = await createNewVersion({
        name:
          documentData.type === "application/pdf"
            ? `${data.name}_revisado.pdf`
            : `${data.name}_revisado.docx`,
        fileType: codesDocumentType.contract._id,
        documentContent: {
          ...segmentDoc.data.responseMessage,
          author: data.owner,
          name:
            documentData.type === "application/pdf"
              ? `${data.name}_revisado.pdf`
              : `${data.name}_revisado.docx`,
        },
        file: documentData,
        folderAssociated: versionSelected._id,
        corporateUnit,
        inReview: true,
        reviewCounts: 1,
      });
      const newDocument = await getDocById(version.data.responseMessage._id);
      await deleteFileFromIndexedDB("contractFile");
      if (
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_ERROR ||
        version.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        version.status === CODES.COD_RESPONSE_HTTP_ERROR
      ) {
        setIsloading(false);
      } else if (
        segmentDoc.data.success &&
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        version.data.success &&
        version.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        setIsloading(false);
        setOpenModalSave(false);
        setInfoDocumentState({
          ...newDocument.data.responseMessage,
          clauses: segmentDoc.data.responseMessage.clauses,
        });
        const idDocument =
          paramsContract.typeReview === REVIEW_TYPES.NEW
            ? "NEW"
            : newDocument?.data?.responseMessage?._id;
        navigate(`view-document/${idDocument}`);
      }
    } else {
      setIsloading(false);
      setOpenModalSave(false);
      const a = { ...segmentDoc.data.responseMessage.parts.partA };
      const b = { ...segmentDoc.data.responseMessage.parts.partB };

      let parts = [a, b];

      if (segmentDoc.data.responseMessage.parts.partC) {
        const c = { ...segmentDoc.data.responseMessage.parts.partC };
        parts.push(c);
      }

      if (segmentDoc.data.responseMessage.parts.partD) {
        const d = { ...segmentDoc.data.responseMessage.parts.partD };
        parts.push(d);
      }

      const finalObj = {
        title: segmentDoc.data.responseMessage.title,
        parts: parts,
      };
      dispatch(setInitialInfoLibrary(finalObj));
      dispatch(
        setSelectedClausesWithoutCheckPart({
          clauses: segmentDoc.data.responseMessage.clauses.map((cla) => {
            return cla.parts
              ? {
                  ...cla,
                  parts: cla.parts.partD
                    ? [
                        cla.parts.partA,
                        cla.parts.partB,
                        cla.parts.partC,
                        cla.parts.partD,
                      ]
                    : cla.parts.partC
                    ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
                    : [cla.parts.partA, cla.parts.partB],
                }
              : cla;
          }),
        })
      );
      dispatch(
        setName({
          name: segmentDoc.data.responseMessage.name,
        })
      );
      for (const [i] of segmentDoc.data.responseMessage.headers.entries()) {
        dispatch(setHeader(segmentDoc.data.responseMessage.headers[i]));
      }
      let bucketName, filenameInBucket;
      if (paramsContract.typeReview === REVIEW_TYPES.NEW) {
        filenameInBucket = segmentDoc.data.responseMessage.filenameInBucket;
        bucketName = segmentDoc.data.responseMessage.bucketName;
      } else {
        bucketName = documentSelected?.bucketName;
        filenameInBucket = documentSelected?.filenameInBucket;
      }
      dispatch(setInfoDocument({ bucketName, filenameInBucket }));
      const idDocument =
        paramsContract.typeReview === REVIEW_TYPES.NEW
          ? "NEW"
          : segmentDoc?.data?.responseMessage?.versionAssociated;

      navigate(`view-document/${idDocument}`);
    }
  };
  const handleClose = () => {
    setFilteredDataTable(dataTable);
    setOpenModalFolder(false);
  };
  const handleSelectedFolder = (folder) => {
    setVersionSelected(folder);
    setOpenModalFolder(false);
  };
  const handleModalSave = (data) => {
    if (!documentData.name && !documentSelected?.name) {
      setIsEmptyDocumentLoad(true);
      return;
    }
    if (isStoragePlanLimit) {
      setIsOpenModalLimitPlan(true);
      return;
    }
    if (
      documentSelected?.fileType === codesDocumentType.contract._id ||
      documentSelected?.fileType === codesDocumentType.draft._id
    ) {
      handleRequest(data);
      return;
    }
    setOpenModalSave(true);
  };
  const handleSelectChange = (event) => {
    setSelectedDocument(event.target.value);

    if (event.target.value === "Contrato") {
      setDocumentContract(true);
      SetDocumentOther(false);
      Mixpanel.track("Revisar contrato ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    } else if (event.target.value === "Otro") {
      Mixpanel.track("Revisar Otro ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      setDocumentContract(false);
      SetDocumentOther(true);
    } else {
      setDocumentContract(false);
      SetDocumentOther(false);
    }
    setDocumentData({});
    setDocumentSelected({});
  };

  const handleClickRule = (event) => {
    setOpenRuleList(true);
  };
  const handleCloseTagReviewRule = () => {
    setIsReviewRule(false);
    setReviewRule(null);
  };

  const getFileToReview = async ({
    fileType,
    filenameInBucket,
    bucketName,
    documentContent,
  }) => {
    try {
      let response = {};

      if (
        fileType === codesDocumentType.word._id ||
        fileType === codesDocumentType.contract._id
      ) {
        const document = await getFileDocument(
          filenameInBucket,
          bucketName,
          true
        );

        const { buffer, contentType } = document.data.responseMessage;
        const blob = new Blob([new Uint8Array(buffer?.data).buffer], {
          type: contentType,
        });

        const dataFile = await convertToPdfService(blob);

        response = dataFile.data.responseMessage?.data;
        response.name = document?.data?.responseMessage?.filename;
      } else if (fileType === codesDocumentType.draft._id) {
        const blob = await wordCreatorFile({
          title: documentContent.title,
          headers: documentContent.headers,
          parts: documentContent.parts,
          clauses: documentContent.clauses,
        });

        const dataFile = await convertToPdfService(blob);

        response = dataFile.data.responseMessage?.data;
        response.name = documentContent?.data?.responseMessage?.name;
      } else {
        const dataFile = await getFileDocument(
          filenameInBucket,
          bucketName,
          false
        );

        response = dataFile?.data?.responseMessage;

        const contentType = dataFile.data.responseMessage.contentType;

        if (
          contentType &&
          contentType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const { buffer, contentType } = dataFile.data.responseMessage;
          const blob = new Blob([new Uint8Array(buffer?.data).buffer], {
            type: contentType,
          });

          const file = await convertToPdfService(blob);

          response = file.data.responseMessage?.data;
          response.name = dataFile?.data?.responseMessage?.filename;
        }
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleReviewOhterDocuments = async () => {
    try {
      setIsLoadingInBackground(true);
      let file = documentData;
      if (documentSelected?.name) {
        setAllowBackgroundOptions(false);
        const docFile = await getFileToReview(documentSelected);
        setAllowBackgroundOptions(true);
        const bf = Buffer.from(docFile?.buffer?.data);
        file = new File([bf], docFile?.originalname, {
          type: "application/pdf",
        });
      }
      const company = localStorage.getItem("company");
      const obj = {
        documentId: Object.keys(documentSelected).length
          ? documentSelected._id
          : null,
        company,
        file,
        type: reviewRule?.name,
      };

      if (reviewWorkerRef.current) {
        setModuleName("backgroundProcessReview");
        dispatch(
          setIsFetchingInfoBackgroundProcess({
            module: "backgroundProcessReview",
            value: true,
          })
        );
        dispatch(
          setFinishProcessingBackgroundProcess({
            module: "backgroundProcessReview",
            value: false,
          })
        );
        reviewWorkerRef.current.postMessage({
          obj,
          token,
          reviewRule,
          serviceUrl,
        });
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const checkDocumentSelected = () => {
    if (documentData.name && !documentSelected.name) {
      return false;
    } else if (!documentData.name && documentSelected.name) {
      return false;
    } else {
      return true;
    }
  };

  //UseEffects
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setDisableSave(true);
      }
    }
  }, [companyPlan]);

  const handleMessageBackgroundOtherDocs = useCallback((event) => {
    const { success, data, reviewRule } = event.data;

    if (success) {
      dispatch(
        setIsFetchingInfoBackgroundProcess({
          module: "backgroundProcessReview",
          value: false,
        })
      );
      dispatch(
        setFinishProcessingBackgroundProcess({
          module: "backgroundProcessReview",
          value: true,
        })
      );
      if (!userLeftReviewRef.current) {
        setIsLoadingInBackground(false);

        navigate(`another-document`, {
          state: {
            data,
            rule: reviewRule,
          },
        });
      } else {
        dispatch(
          setBackgroundProcessingAdditionalInfoForModule(
            "backgroundProcessReview",
            {
              url: `/service/review/first-Time/another-document`,
              state: {
                data,
                rule: reviewRule,
              },
            }
          )
        );
      }

      setIsloading(false);
    }

    reviewWorkerRef.current.removeEventListener(
      "message",
      handleMessageBackgroundOtherDocs
    );
    reviewWorkerRef.current.terminate();
  }, []);
  const handleMessageRef = useRef(handleMessageBackgroundOtherDocs);

  useEffect(() => {
    if (!reviewWorkerRef.current) {
      reviewWorkerRef.current = new WorkerBuilder(WorkerOtherDocs);
    }
    reviewWorkerRef.current.removeEventListener(
      "message",
      handleMessageRef.current
    );

    reviewWorkerRef.current.addEventListener(
      "message",
      handleMessageBackgroundOtherDocs
    );
  }, [handleMessageBackgroundOtherDocs]);

  const handleMessageBackgroundContract = useCallback(async (event) => {
    const segmentDoc = event.data;

    dispatch(
      setIsFetchingInfoBackgroundProcess({
        module: "backgroundProcessReviewContract",
        value: false,
      })
    );
    dispatch(
      setFinishProcessingBackgroundProcess({
        module: "backgroundProcessReviewContract",
        value: true,
      })
    );
    setIsloading(false);
    if (!userLeftContractRef.current) {
      setIsloading(true);
      const file = await getFileFromIndexedDB("contractFile");
      await handleProcessContractInfo(
        segmentDoc,
        additionalInfoContractBackgroundRef.current.otherInfo.data || {},
        additionalInfoContractBackgroundRef.current.otherInfo.paramsContract,
        additionalInfoContractBackgroundRef.current.otherInfo.versionSelected ||
          {},
        file
      );
    } else {
      dispatch(
        setBackgroundProcessingAdditionalInfoForModule(
          "backgroundProcessReviewContract",
          {
            url: `/service/review/first-Time`,
            otherInfo: {
              finishProcess: true,
              segmentDoc,
              data:
                additionalInfoContractBackgroundRef.current.otherInfo.data ||
                {},
              paramsContract:
                additionalInfoContractBackgroundRef.current.otherInfo
                  .paramsContract,
            },
          }
        )
      );
    }
    if (
      segmentDoc.status === CODES.COD_RESPONSE_HTTP_OK ||
      segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED
    ) {
      reviewWorkerRefContract.current.removeEventListener(
        "message",
        handleMessageBackgroundContract
      );
      reviewWorkerRefContract.current.terminate();
    }
  }, []);
  const handleMessageRefContract = useRef(handleMessageBackgroundContract);

  useEffect(() => {
    if (!reviewWorkerRefContract.current) {
      reviewWorkerRefContract.current = new WorkerBuilder(WorkerContract);
    }
    reviewWorkerRefContract.current.removeEventListener(
      "message",
      handleMessageRefContract.current
    );

    reviewWorkerRefContract.current.addEventListener(
      "message",
      handleMessageBackgroundContract
    );
  }, [handleMessageBackgroundContract]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (foldersStatus == "fetch") {
          dispatch(fetchAllFolders());
        }

        const processedFolders = folders.map((folder, index) => {
          let labels = "";

          for (const [i] of folder.labels.entries()) {
            labels += folder.labels[i].name + ",";
          }

          return {
            element: folder.name,
            type: "Carpeta",
            createdAt: folder.createdAt,
            updatedAt: folder.updatedAt,
            lastAt: folder.updatedAt,
            action: (
              <Row key={JSON.stringify(folder)}>
                <Col xs={2}>
                  <Button
                    variant="contained"
                    className="custom-input__button__secondary-color"
                    size="medium"
                    disabled
                    onClick={() => {}}
                  >
                    Abrir
                  </Button>
                </Col>
                <Col xs={1}></Col>
                <Col xs={7}>
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    size="medium"
                    onClick={() => {
                      handleSelectedFolder(folder);
                    }}
                  >
                    Seleccionar carpeta
                  </Button>
                </Col>
              </Row>
            ),
          };
        });

        setDataTable({ columns: columns, rows: processedFolders });
        setFilteredDataTable({ columns: columns, rows: processedFolders });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [dispatch, foldersStatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        if (SelectedDocument === "Otro") {
          const corporateUnit = localStorage.getItem("corporateUnitId");
          const response = await findRulesByCorporateUnit(corporateUnit);
          if (
            response.status === CODES.COD_RESPONSE_HTTP_OK &&
            response.data.success
          ) {
            const rules = response?.data?.responseMessage?.data;
            setListRules(rules);
            return;
          }
          setNotFoundRulesMessage({
            status: 401,
            data: { message: response?.data?.responseMessage },
          });
          setIsOpenModalInfoRulesNotFound(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsloading(false);
      }
    };
    fetchData();
  }, [SelectedDocument]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (additionalInfoContractBackground?.otherInfo?.finishProcess) {
        setIsloading(true);
        const file = await getFileFromIndexedDB("contractFile");
        try {
          await handleProcessContractInfo(
            additionalInfoContractBackgroundRef.current.otherInfo.segmentDoc,
            additionalInfoContractBackgroundRef.current.otherInfo.data || {},
            additionalInfoContractBackgroundRef.current.otherInfo
              .paramsContract,
            additionalInfoContractBackgroundRef.current.otherInfo
              .versionSelected || {},
            file
          );
          dispatch(
            setBackgroundProcessingAdditionalInfoForModule(
              "backgroundProcessReviewContract",
              {}
            )
          );
        } catch (error) {
          console.error(error);
        } finally {
          setIsloading(false);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [additionalInfoContractBackground]);

  useEffect(() => {
    //Se settean los valores de referencias normativas
    dispatch(setReferenceNormativeClearStatus());
  }, []);

  if (!openRuleList) {
    return (
      <Container fluid>
        {isLoading && !isLoadingInBackground && <LoadingContent />}

        {isLoadingInBackground && !isLoading && (
          <LoadingContentInBackground
            actions={allowBackgroundOptions}
            open={isLoadingInBackground}
            setIsOpen={setIsLoadingInBackground}
            moduleName={moduleName}
          />
        )}
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={"Cargar documento para primera revisión"} />
        </Row>
        <Row md={4}>
          <Form onSubmit={handleSubmit(handleRequest)}>
            <Form.Group>
              <Form.Label className="form__label">
                Tipo de documento<span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Select
                className="label"
                size="lg"
                as="select"
                placeholder="Tipo de documento"
                value={SelectedDocument}
                bsPrefix={
                  errors?.firstReview
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("firstReview", {
                  required: {
                    value: true,
                    message: "*Este campo es obligatorio",
                  },
                })}
                onChange={handleSelectChange}
              >
                <option className="label" value="" disabled={false}>
                  Tipo de documento
                </option>
                <option className="label" value="Contrato">
                  Contrato
                </option>
                <option className="label" value="Otro">
                  Otro
                </option>
              </Form.Select>
            </Form.Group>
            <span className="caption custom-input__error">
              {errors?.firstReview?.message}
            </span>
          </Form>
        </Row>

        {ShowDocumentOther && (
          <>
            <br />
            <Row>
              <h1 className="form__label">Seleccione una regla de revisión</h1>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={handleClickRule}
                >
                  Escoger regla de revisión
                </Button>
              </Col>
              {isReviewRule && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${reviewRule?.name || ""}`}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        handleCloseTagReviewRule();
                      }}
                    />
                  </span>
                </Col>
              )}
            </Row>
          </>
        )}

        {showDocumentContract && (
          <>
            <br />
            <Row style={{ alignItems: "center" }}>
              <Col md={"auto"}>
                <h1 className="form__label">
                  Cargue un documento en formato tipo .docx y .pdf
                </h1>
              </Col>
              <Col xs={"auto"}>
                <PopoverInfo
                  section={{
                    description:
                      "Te recordamos que la revisión de los documentos funcionará mejor si tienen una estructura de contratos.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<FolderOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setOpenModalVersion(true);
                    Mixpanel.track("Revisar Doc de LegalAI ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Seleccionar documento de Legal AI
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setOpenModal(true);
                    Mixpanel.track("Revisar cargar documento ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Cargar documento
                </Button>
              </Col>
              {documentData.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentData.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setIsSaveinFolder(false);
                        setDocumentData({});
                      }}
                    />
                  </span>
                </Col>
              )}
              {documentSelected?.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentSelected.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setIsSaveinFolder(false);
                        setDocumentSelected({});
                      }}
                    />
                  </span>
                </Col>
              )}
            </Row>
          </>
        )}
        {ShowDocumentOther && (
          <>
            <br />
            <Row>
              <h1 className="form__label">
                Cargue un documento en formato tipo .pdf
              </h1>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <Button
                  disabled={!isReviewRule}
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setOpenModal(true);
                    Mixpanel.track("Revisar cargar documento ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Cargar documento
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  disabled={!isReviewRule}
                  variant="contained"
                  startIcon={<FolderOutlinedIcon />}
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setOpenModalVersion(true);
                    Mixpanel.track("Revisar Doc de LegalAI ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Seleccionar documento de Legal AI
                </Button>
              </Col>
              {documentData.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentData.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setDocumentData({});
                      }}
                    />
                  </span>
                </Col>
              )}
              {documentSelected?.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentSelected.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setDocumentSelected({});
                      }}
                    />
                  </span>
                </Col>
              )}
            </Row>
          </>
        )}
        <Form onSubmit={handleSubmit(handleRequest)}>
          {showDocumentContract || ShowDocumentOther ? (
            <>
              <br />
              <Row className="heading" style={{ alignItems: "center" }}>
                <Col xs={"auto"}>
                  <h1 className="form__label">
                    ¿De quién procede el documento?
                  </h1>
                </Col>
                <Col xs={"auto"}>
                  <PopoverInfo
                    section={{
                      description:
                        "Con este nombre en un futuro podrás saber de dónde vino esta versión.",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-5">
                    <Form.Control
                      maxLength={251}
                      {...register("owner")}
                      placeholder="Procede de..."
                      bsPrefix={
                        errors.owner
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.owner?.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
          {isSaveinFolder && showDocumentContract && (
            <>
              <Row className="heading" style={{ alignItems: "center" }}>
                <Col xs={"auto"}>Nombre de documento</Col>
                <Col xs={"auto"}>
                  <PopoverInfo
                    section={{
                      description:
                        "El nombre que pongas en el archivo es el que será utilizado para el almacenamiento. Por lo tanto, no se verá reflejado en el documento final",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-5">
                    <Form.Control
                      {...register("name")}
                      placeholder="Documento_1_ver"
                      bsPrefix={
                        errors.name
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.name?.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="heading">
                Selecciona la carpeta para almacenar del documento
              </Row>
              <Row style={{ padding: "2% 0 3% 0" }}>
                <Col md={4}>
                  <Button
                    variant="contained"
                    startIcon={<FolderOutlinedIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      setOpenModalFolder(true);
                    }}
                  >
                    Seleccionar carpeta en Legal AI
                  </Button>
                </Col>
                {versionSelected.name && (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span className="caption custom-badges__disabled">
                      {`${versionSelected.name} `}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => {
                          setVersionSelected({});
                        }}
                      />
                    </span>
                  </Col>
                )}
              </Row>
            </>
          )}
          {isSaveinFolder && showDocumentContract ? (
            <Row>
              <Col md={2}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancelar
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  type="submit"
                  variant="contained"
                  className={
                    watch("owner") === "" ||
                    watch("name") === "" ||
                    checkDocumentSelected()
                      ? "custom-input__button__disabled"
                      : "custom-input__button__primary-color"
                  }
                  disabled={
                    watch("owner") === "" ||
                    watch("name") === "" ||
                    checkDocumentSelected()
                  }
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          ) : (
            <Row>
              {showDocumentContract ? (
                <Col xs={"auto"}>
                  {isFetchingInfoContract ? (
                    <CustomToolTip
                      direction="left"
                      message="Estamos procesando una solicitud, puedes intentar de nuevo en breve."
                    >
                      <Button
                        type="button"
                        variant="contained"
                        className={
                          watch("owner") === "" ||
                          (!documentData.name && !documentSelected.name)
                            ? "custom-input__button__disabled"
                            : "custom-input__button__primary-color"
                        }
                        endIcon={<ArrowForwardIosIcon />}
                        disabled
                      >
                        Continuar
                      </Button>
                    </CustomToolTip>
                  ) : (
                    <Button
                      type="button"
                      variant="contained"
                      className={
                        watch("owner") === "" ||
                        (!documentData.name && !documentSelected.name)
                          ? "custom-input__button__disabled"
                          : "custom-input__button__primary-color"
                      }
                      onClick={handleSubmit(handleModalSave)}
                      endIcon={<ArrowForwardIosIcon />}
                      disabled={
                        watch("owner") === "" ||
                        (!documentData.name && !documentSelected.name)
                      }
                    >
                      Continuar
                    </Button>
                  )}
                </Col>
              ) : (
                ShowDocumentOther && (
                  <Col md={2}>
                    {isFetchingInfoReview ? (
                      <CustomToolTip
                        direction="left"
                        message="Estamos procesando una solicitud, puedes intentar de nuevo en breve."
                      >
                        <Button
                          type="button"
                          variant="contained"
                          className={
                            watch("owner") === "" ||
                            (!documentData.name && !documentSelected?.name) ||
                            !isReviewRule
                              ? "custom-input__button__disabled"
                              : "custom-input__button__primary-color"
                          }
                          endIcon={<ArrowForwardIosIcon />}
                          disabled
                        >
                          Revisar
                        </Button>
                      </CustomToolTip>
                    ) : (
                      <Button
                        type="button"
                        variant="contained"
                        className={
                          watch("owner") === "" ||
                          (!documentData.name && !documentSelected?.name) ||
                          !isReviewRule
                            ? "custom-input__button__disabled"
                            : "custom-input__button__primary-color"
                        }
                        onClick={handleSubmit(handleReviewOhterDocuments)}
                        endIcon={<ArrowForwardIosIcon />}
                        disabled={
                          watch("owner") === "" ||
                          (!documentData.name && !documentSelected?.name) ||
                          !isReviewRule
                        }
                      >
                        Revisar
                      </Button>
                    )}
                  </Col>
                )
              )}
            </Row>
          )}
        </Form>
        <ModalUploadDocs
          message={
            showDocumentContract
              ? "El archivo que estas a punto de subir debe estar en formato .docx y .pdf"
              : "El archivo que estas a punto de subir debe estar en formato .pdf"
          }
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={() => {
            setOpenModal(false);
          }}
          onClose={() => {
            setOpenModal(false);
          }}
          open={openModal}
          documentData={documentData}
          setDocumentData={(file) => {
            if (!file?.name && !file?.filename) return;
            setDocumentData(file);
            setDocumentSelected({});
          }}
          typeDocument={showDocumentContract ? "Contrato" : "Otro"}
          title={"Selecciona el documento a revisar"}
          messageContractValidation={
            "Formato incompatible. Recuerd​a ​cargar documentos en formato .docx y .pdf"
          }
          noStorageAvailable={disableSave}
        />
        <ModalDecision
          title={"Guardar en tus carpetas"}
          message={"¿Quieres almacenar este archivo entre tus carpetas?"}
          open={openModalSave}
          onClose={setOpenModalSave}
          agreeText="Almacenar en carpetas"
          disagreeText="No almacenar"
          isDisabledAgree={isStoragePlanLimit}
          handleAgree={() => {
            if (isStoragePlanLimit) return;
            setIsSaveinFolder(true);
            setOpenModalSave(false);
          }}
          handleDisagree={handleRequest}
        />
        <ModalAssociateFolder
          type="folder"
          open={openModalFolder}
          setDataSelected={setVersionSelected}
          close={handleClose}
        />

        <ModalWarningForm
          title={"Advertencia"}
          description={"No se ha cargado un documento para hacer una revisón"}
          open={isEmptyDocumentLoad}
          onClose={() => setIsEmptyDocumentLoad(false)}
        />
        <ModalWarningForm
          title={"Advertencia"}
          description={"No se ha seleccionado un carpeta para almacenar"}
          open={isEmptyFolderSelected}
          onClose={() => setIsEmptyFolderSelected(false)}
        />
        <ModalInfo
          title={"Error revisando el documento"}
          open={isOpenModalError}
          responseData={responseDataError}
          confirmationText="Aceptar"
          handleCloseDefault={() => setIsOpenModalError(false)}
          onClose={() => setIsOpenModalError(false)}
        />
        <ModalInfo
          title={"Información de interés"}
          responseData={notFoundRulesMessage}
          onClose={() => setIsOpenModalInfoRulesNotFound(false)}
          open={isOpenModalInfoRulesNotFound}
          buttonClose={true}
        />
        <ModalDecision
          title={"Límite de almacenamiento"}
          message={
            "En el momento no está disponible la funcionalidad de guardado, porque la compañía ha alcanzado el máximo de su plan. Comunícate con el usuario administrador para adaptar el plan. ¿Deseas continuar de todas formas?"
          }
          agreeText={"Continuar"}
          disagreeText={"Cancelar"}
          handleDisagree={() => {
            setIsOpenModalLimitPlan(false);
          }}
          handleAgree={handleRequest}
          onClose={() => {
            setIsOpenModalLimitPlan(false);
          }}
          open={isOpenModalLimitPlan}
          type={"warning"}
        />
        <ModalAssociateVersion
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={(file) => {
            setOpenModalVersion(false);
            setDocumentSelected(file);
            setDocumentData({});
          }}
          onClose={() => {
            setOpenModalVersion(false);
          }}
          open={openModalVersion}
          folders={folders}
          versionSelected={documentSelected}
          title="Asociar documento"
        />
      </Container>
    );
  } else {
    return (
      <ChooseReviewRule
        openRuleList={openRuleList}
        setOpenRuleList={setOpenRuleList}
        ruleList={listRules}
        reviewRule={reviewRule}
        setReviewRule={setReviewRule}
        setIsReviewRule={setIsReviewRule}
      />
    );
  }
};

export default UploadFirstTime;
