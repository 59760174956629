import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { Col, Container, Form, Row } from "react-bootstrap";
import {
  getAllBlankSpaces,
  getFillBlankSpaces,
} from "../../utils/countBlankSpaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { flashItemSelected } from "../../utils/ScrollDocItems";
export const FillBlankSpaceClause = ({
  clause,
  indexClause,
  setCurrentDocClauses,
}) => {
  const [isCompleteClause, setIsCompleteClause] = useState(false);

  useEffect(() => {
    if (clause) {
      const fillBlankSpaces = getFillBlankSpaces({ clause });
      const allBlankSpaces = getAllBlankSpaces({ clause });

      if (fillBlankSpaces > 0) {
        if (fillBlankSpaces / allBlankSpaces === 1 || allBlankSpaces === 0) {
          setIsCompleteClause(true);
        } else {
          setIsCompleteClause(false);
        }
      } else {
        setIsCompleteClause(false);
      }
    }
  }, [clause]);

  const handleFillBlankspaces = (textHtml, name, value, originalValue) => {
    if (!textHtml || !name) return "";

    //Convertimos el string html a un Object Document
    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, "text/html");

    //Validamos que exista la data para cambiar su valor
    if (htmlElement?.querySelector(`[data-blank-space="${name}"]`)) {
      htmlElement.querySelector(`[data-blank-space="${name}"]`).innerText =
        value ? `¬${value}¬` : `¬${originalValue}¬`;
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument.replace(
      /<\/?(html|head|body)>/gi,
      ""
    );

    return cleanedString;
  };
  const handleBlankspacesClause = (text, name, value, id, originalValue) => {
    const newText = handleFillBlankspaces(text, name, value, originalValue);

    setCurrentDocClauses((prevClauses) => {
      const newClausesList = prevClauses.map((clause) => {
        if (clause.clause._id === id) {
          return {
            ...clause,
            clause: {
              ...clause.clause,
              text: newText,
              blankspaces: clause.clause.blankspaces.map(
                (blankspace, index) => {
                  if (blankspace.name === name) {
                    return { ...blankspace, value: value };
                  } else return blankspace;
                }
              ),
            },
          };
        } else return clause;
      });
      return newClausesList;
    });
  };

  const handleBlankspacesSubclause = ({
    text,
    name,
    value,
    index,
    clauseId,
    originalValue,
  }) => {
    const newText = handleFillBlankspaces(text, name, value, originalValue);
    setCurrentDocClauses((prevInfo) => {
      const formatedClausesList = prevInfo.map((clause, indexClause) => {
        if (clause.clause._id === clauseId) {
          const newClause = {
            ...clause,
            clause: {
              ...clause.clause,
              subclauses: clause.clause.subclauses.map(
                (subclause, indexSubclause) => {
                  if (index === indexSubclause) {
                    return {
                      ...subclause,
                      subclause: {
                        ...subclause.subclause,
                        text: newText,
                        blankspaces: subclause.subclause.blankspaces.map(
                          (blankspace, index) => {
                            if (blankspace.name === name) {
                              return { ...blankspace, value: value };
                            } else return blankspace;
                          }
                        ),
                      },
                    };
                  } else return subclause;
                }
              ),
            },
          };
          return newClause;
        } else {
          return clause;
        }
      });
      return formatedClausesList;
    });
  };

  const handleBlankspacesSubclauseChild = (
    text,
    name,
    value,
    indexSub,
    indexChild,
    clauseId,
    originalValue
  ) => {
    const newText = handleFillBlankspaces(text, name, value, originalValue);
    setCurrentDocClauses((prev) => {
      const formatedClausesList = prev.map((clause) => {
        if (clause.clause._id === clauseId) {
          const updateClause = {
            ...clause,
            clause: {
              ...clause.clause,
              subclauses: clause.clause.subclauses.map((sub, index) => {
                if (index === indexSub) {
                  return {
                    ...sub,
                    subclause: {
                      ...sub.subclause,
                      childs: sub.subclause.childs.map((child, index2) => {
                        if (index2 === indexChild) {
                          return {
                            ...child,
                            text: newText,
                            blankspaces: child.blankspaces.map(
                              (blankspace, index) => {
                                if (blankspace.name === name) {
                                  return { ...blankspace, value: value };
                                } else return blankspace;
                              }
                            ),
                          };
                        } else return child;
                      }),
                    },
                  };
                } else return sub;
              }),
            },
          };
          return updateClause;
        } else return clause;
      });
      return formatedClausesList;
    });
  };

  const handleBlankspacesParagraphs = (
    text,
    name,
    value,
    index,
    clauseId,
    originalValue
  ) => {
    const newText = handleFillBlankspaces(text, name, value, originalValue);
    setCurrentDocClauses((prev) => {
      const formatedClausesList = prev.map((clause, indexClause) => {
        if (clause.clause._id === clauseId) {
          const prueba = {
            ...clause,
            clause: {
              ...clause.clause,
              paragraphs: clause.clause.paragraphs.map(
                (paragraph, indexSubclause) => {
                  if (index === indexSubclause) {
                    return {
                      ...paragraph,
                      paragraph: {
                        ...paragraph.paragraph,
                        text: newText,
                        blankspaces: paragraph.paragraph.blankspaces.map(
                          (blankspace, index) => {
                            if (blankspace.name === name) {
                              return { ...blankspace, value: value };
                            } else return blankspace;
                          }
                        ),
                      },
                    };
                  } else return paragraph;
                }
              ),
            },
          };
          return prueba;
        } else {
          return clause;
        }
      });
      return formatedClausesList;
    });
  };

  return (
    <Accordion
      className="blankspaces__box"
      defaultExpanded
      id={`c.${indexClause + 1}.0.0`}
    >
      <AccordionSummary>
        <Container
          fluid
          className="container-blankspaces__box"
          style={{ margin: "0 !important" }}
        >
          <Row>
            <Col xs={10} className="heading">
              {clause.title}
            </Col>
            <Col xs={1}>
              {isCompleteClause ? (
                <CheckCircleOutlineIcon fontSize="large" />
              ) : (
                <InfoOutlinedIcon
                  className="heading__error-color"
                  fontSize="large"
                />
              )}
            </Col>
            <Col xs={1}>
              <KeyboardArrowDownIcon fontSize="large" />
            </Col>
          </Row>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        {clause?.blankspaces && (
          <div>
            <Row>
              <Col md={12}>
                {clause.blankspaces.map((blankspace) => (
                  <>
                    <Row>
                      <Col className="mb-3">
                        {" "}
                        {`Espacio # ${blankspace.counter}`}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          key={`blankspace-clause-${blankspace.index}`}
                        >
                          <Form.Control
                            placeholder={`Escribir aquí`}
                            defaultValue={blankspace.value}
                            bsPrefix={"input-group-container__no-icon label"}
                            onChange={(e) => {
                              let value = e.target.value;
                              let originalValue = `ESPACIO #${
                                blankspace?.index || ""
                              }`;
                              handleBlankspacesClause(
                                clause.text,
                                blankspace.name,
                                value,
                                clause._id,
                                originalValue
                              );
                            }}
                            onFocus={() => {
                              const nameBlankspace = `[data-blank-space="${blankspace.name}"]`;
                              flashItemSelected(nameBlankspace, "query", `content-clauses-${indexClause}`);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ))}

                {clause?.subclauses?.map((subclause, index) => (
                  <div key={subclause?.subclause?._id}>
                    {subclause?.subclause?.blankspaces?.map(
                      (blankspace, index2) => (
                        <>
                          <Row>
                            <Col className="mb-3">
                              {" "}
                              {`Espacio # ${blankspace.counter}`}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                key={`blankspace-subclause-${blankspace.index}`}
                              >
                                <Form.Control
                                  placeholder={`Escribir aquí`}
                                  defaultValue={blankspace.value}
                                  bsPrefix={
                                    "input-group-container__no-icon label"
                                  }
                                  onChange={(e) => {
                                    let originalValue = `ESPACIO #${
                                      blankspace?.index || ""
                                    }`;
                                    handleBlankspacesSubclause({
                                      text: subclause.subclause.text,
                                      name: blankspace.name,
                                      value: e.target.value,
                                      index,
                                      clauseId: clause._id,
                                      originalValue,
                                    });
                                  }}
                                  onFocus={() => {
                                    const nameBlankspace = `[data-blank-space="${blankspace.name}"]`;
                                    flashItemSelected(nameBlankspace, "query", `content-subclauses-${indexClause}.${index}`);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      )
                    )}

                    {subclause?.subclause?.childs?.map((child, index2) => (
                      <div key={`${child.title}`}>
                        {child.blankspaces.map((blankspace, index3) => (
                          <>
                            <Row>
                              <Col className="mb-3">
                                {" "}
                                {`Espacio # ${blankspace.counter}`}
                              </Col>
                            </Row>

                            <Row>
                              <Form.Group
                                className="mb-3"
                                key={`blankspace-subclause-childs-${blankspace.index}`}
                              >
                                <Form.Control
                                  placeholder={`Escribir aquí`}
                                  defaultValue={blankspace.value}
                                  // value={blankspace.value}
                                  bsPrefix={
                                    "input-group-container__no-icon label"
                                  }
                                  onChange={(e) => {
                                    let originalValue = `ESPACIO #${
                                      blankspace?.index || ""
                                    }`;
                                    handleBlankspacesSubclauseChild(
                                      child.text,
                                      blankspace.name,
                                      e.target.value,
                                      index,
                                      index2,
                                      clause._id,
                                      originalValue
                                    );
                                  }}
                                  onFocus={() => {
                                    const nameBlankspace = `[data-blank-space="${blankspace.name}"]`;
                                    flashItemSelected(nameBlankspace, "query", `content-subclauses-childs-${indexClause}.${index}.${index2}`);
                                  }}
                                />
                              </Form.Group>
                            </Row>
                          </>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
                {clause?.paragraphs?.map((paragraph, index) => (
                  <div key={`${paragraph?.paragraph?._id}`}>
                    {paragraph?.paragraph?.blankspaces?.map((blankspace) => (
                      <>
                        <Row>
                          <Col className="mb-3">
                            {" "}
                            {`Espacio # ${blankspace.counter}`}
                          </Col>
                        </Row>
                        <Row></Row>
                        <Form.Group
                          className="mb-3"
                          key={`blankspace-paragraph-${blankspace.index}`}
                        >
                          <Form.Control
                            placeholder={`Escribir aquí`}
                            defaultValue={blankspace.value}
                            bsPrefix={"input-group-container__no-icon label"}
                            onChange={(e) => {
                              let originalValue = `ESPACIO #${
                                blankspace?.index || ""
                              }`;
                              handleBlankspacesParagraphs(
                                paragraph.paragraph.text,
                                blankspace.name,
                                e.target.value,
                                index,
                                clause._id,
                                originalValue
                              );
                            }}
                            onFocus={() => {
                              const nameBlankspace = `[data-blank-space="${blankspace.name}"]`;
                              flashItemSelected(nameBlankspace, "query", `content-paragraphs-${indexClause}.${index}`);
                            }}
                          />
                        </Form.Group>
                      </>
                    ))}
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
