import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CustomSearchFilter from "../Search/CustomSearchFilter";
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalAssociateCategories = ({
  categories,
  previousCategoriesSelected,
  handleCategoriesSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);

  const handleClose = () => {
    setCategorySelected([]);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setFilteredCategories(categories);
    setCategorySelected(previousCategoriesSelected);
    setOpen(true);
  };

  const handleChecked = (e, category) => {
    const item = e.target;
    if (item.checked) {
      setCategorySelected((oldArray) => [...oldArray, category]);
    } else {
      setCategorySelected(
        categorySelected.filter((c) => c._id !== category._id)
      );
    }
  };

  const handleFilterCategories = (categoriesSelected) => {
    handleCategoriesSelected(categoriesSelected);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<AddIcon fontSize="large" />}
        className="custom-input__button__secondary-color"
        onClick={handleClickOpen}
      >
        Categorías
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">
            {"Asociar Categorías"}
          </label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Container fluid>
            <Row>
              <Col>
                <CustomSearchFilter
                  list={categories}
                  setFilteredData={setFilteredCategories}
                  placeholder={"Buscar categoría"}
                />
              </Col>
            </Row>
            <Row>
              {filteredCategories.map((category) => {
                return (
                  <Row
                    key={category?._id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col xs={"auto"}>
                      <Checkbox
                        inputProps={{
                          "data-testid": category?._id
                        }}
                        checked={categorySelected.some(
                          (c) => c._id === category._id
                        )}
                        onClick={(e) => handleChecked(e, category)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                          color: "#00374f",
                          "&.Mui-checked": {
                            color: "#00374f",
                          },
                        }}
                      />
                    </Col>
                    <Col>
                      <Accordion className="custom-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <label className="body-content">
                            {category.name}
                          </label>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ backgroundColor: "#F6F6F7" }}
                        >
                          <div className="caption">
                            {ReactHtmlParser(category.description)}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                  </Row>
                );
              })}
            </Row>
          </Container>
        </DialogContent>
        <DialogActions>
          <Container fluid>
            <Row xs={"auto"} style={{ justifyContent: "right" }}>
              <Col>
                <Button
                  data-testid="buttonDisagree"
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  data-testid="buttonAgree"
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => handleFilterCategories(categorySelected)}
                >
                  Aceptar
                </Button>
              </Col>
            </Row>
          </Container>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalAssociateCategories;
