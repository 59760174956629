export const signatoriesTypes = {
  SignatariOPN: {
    text: "¬nombre signatario¬, identificado con ¬tipo de documento¬ ¬número de documento¬.",
    gender: "M",
    type: "PN",
    name: "SignatariO (Persona natural)",
  },

  SignatariAPN: {
    text: "¬nombre signatario¬, identificada con ¬tipo de documento¬ ¬número de documento¬.",
    gender: "F",
    type: "PN",
    name: "SignatariA (Persona natural)",
  },

  SignatariOPJ: {
    text: "¬nombre signatario¬, identificado con ¬tipo de documento¬ ¬número de documento¬, actuando en calidad de ¬cargo¬ de la sociedad ¬nombre de la sociedad¬ identificada con ¬tipo de documento¬ ¬número de documento¬.",
    gender: "M",
    type: "PJ",
    name: "SignatariO (Persona jurídica)",
  },

  SignatariAPJ: {
    text: "¬nombre signatario¬, identificada con ¬tipo de documento¬ ¬número de documento¬, actuando en calidad de ¬cargo¬ de la sociedad ¬nombre de la sociedad¬ identificada con ¬tipo de documento¬ ¬número de documento¬.",
    gender: "F",
    type: "PJ",
    name: "SignatariA (Persona jurídica)",
  },
};
