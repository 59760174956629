import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPrederminatedTextService, getAllPrederminatedTexts } from "../../../../../../services/predeterminatedTexts/predeterminatedTextsService";
import { CODES } from "../../../../../../utils/codes";

export const prederminatedTextsSlice = createSlice({
  name: "prederminatedTexts",
  initialState: {
    prederminatedTextsList: [],
    currentPrederminatedText: null,
    status: "fetch",
    createStatus: "",
    deleteStatus: "",
    updateStatus: "",
  },

  reducers: {
    setStatusPrederminatedTexts(state, action) {
      state.status = "fetch";
    },
    cleanCurrentPrederminatedText(state) {
      state.currentPrederminatedText = null;
    },
    setCurrentPrederminatedText(state, action) {
      state.currentPrederminatedText = action.payload.prederminatedText;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchPrederminatedTexts.pending, (state, _) => {
        state.status = "loading";
      })
      .addCase(fetchPrederminatedTexts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.prederminatedTextsList = action.payload.data;
      })
      .addCase(fetchPrederminatedTexts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(createPrederminatedText.fulfilled, (state, action) => {
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          state.prederminatedTextsList = action.payload.prederminatedTexts;
          state.createStatus = "succeeded";
        }
      })
      .addCase(createPrederminatedText.pending, (state, action) => {
        state.createStatus = "loading";
      })
      .addCase(createPrederminatedText.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePrederminatedText.fulfilled, (state, action) => {
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK) {
          state.prederminatedTextsList = action.payload.prederminatedTexts;
          state.deleteStatus = "succeeded";
        }
      })
      .addCase(deletePrederminatedText.pending, (state, action) => {
        state.deleteStatus = "loading";
      })
      .addCase(deletePrederminatedText.rejected, (state, action) => {
        state.deleteStatus = "failed";
      })
      .addCase(updatePrederminatedText.fulfilled, (state, action) => {
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK) {
          state.prederminatedTextsList = action.payload.prederminatedTexts;
          state.updateStatus = "succeeded";
        }
      })
      .addCase(updatePrederminatedText.pending, (state, action) => {
        state.updateStatus = "loading";
      })
      .addCase(updatePrederminatedText.rejected, (state, action) => {
        state.updateStatus = "failed";
      });
  },
});

export const {
  setStatusPrederminatedTexts,
  cleanCurrentPrederminatedText,
  setCurrentPrederminatedText,
} = prederminatedTextsSlice.actions;

export const getStatusPrederminatedTexts = (state) =>
  state.prederminatedTexts.status;
export const getPrederminatedTextsList = (state) =>
  state.prederminatedTexts.prederminatedTextsList;
export const getCurrentPrederminatedText = (state) =>
  state.prederminatedTexts.currentPrederminatedText;

export const fetchPrederminatedTexts = createAsyncThunk(
  "prederminatedTexts/fetchPrederminatedTexts",
  async (__, { rejectWithValue }) => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");

    let obj = {};
    obj = {
      company,
      corporateUnit,
    };

    let response;

    try {
      response = await getAllPrederminatedTexts(obj);

      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data.responseMessage.data,
        };
      } else {
        return rejectWithValue(new Error("Ha ocurrido un error encontrando los textos predeterminados"));
      }
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const createPrederminatedText = createAsyncThunk(
  "prederminatedTexts/createPrederminatedText",
  async (data, { rejectWithValue }) => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");

    let obj = {};
    obj = {
      ...data,
      company,
      corporateUnit,
    };

    let response;

    try {
      response = await createPrederminatedTextService(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        response.data.success
      ) {
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
        };
        const responseGetAll = await getAllPrederminatedTexts(obj);
        return {
          status: response.status,
          data: response.data.responseMessage.data,
          prederminatedTexts: responseGetAll.data.responseMessage.data,
        };
      }

      console.log({ response })

      return {
        status: response.status,
        data: response.data.responseMessage.data,
      };
    } catch (error) {
      console.log("error", error);
      rejectWithValue(error);
    }
  }
);

export const deletePrederminatedText = createAsyncThunk(
  "prederminatedTexts/deletePrederminatedText",
  async (id, { rejectWithValue }) => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");

    let obj = {};
    obj = {
      id,
      company,
      corporateUnit,
    };

    let response;

    try {
      response = await deletePrederminatedText(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
        };
        const responseGetAll = await getAllPrederminatedTexts(obj);
        return {
          status: response.status,
          data: response.data,
          prederminatedTexts: responseGetAll.data.responseMessage,
        };
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const updatePrederminatedText = createAsyncThunk(
  "prederminatedTexts/updatePrederminatedText",
  async (data, { rejectWithValue }) => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");

    let obj = {};
    obj = {
      ...data,
      company,
      corporateUnit,
    };

    let responseUpdate;

    try {
      responseUpdate = await updatePrederminatedText(obj);
      if (
        responseUpdate.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseUpdate.data.success
      ) {
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
        };
        const responseGetAll = await getAllPrederminatedTexts(obj);
        return {
          status: responseUpdate.status,
          data: responseUpdate.data,
          prederminatedTexts: responseGetAll.data.responseMessage,
        };
      }
      return {
        status: responseUpdate.status,
        data: responseUpdate.data,
        update: data.updateLabel,
        id: data.id,
      };
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export default prederminatedTextsSlice.reducer;
