
import * as yup from "yup";
import { REGEXP } from "../../../../../../../../utils/regexp";

export const signatoryFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("*Este campo es obligatorio")
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  lastName: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  firstSurname: yup
    .string()
    .required("*Este campo es obligatorio")
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  secondSurname: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  documentType: yup.string().required("*Este campo es obligatorio"),
  documentNumber: yup
    .string()
    .required("*Este campo es obligatorio")
    .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir numeros" })
    .max(250, "Máximo 250 caracteres permitidos"),
  email: yup
    .string()
    .email("*Este campo debe ser un correo válido")
    .max(250, "Máximo 250 caracteres permitidos"),
  emailConfirmation: yup
    .string()
    .email("*Este campo debe ser un correo válido")
    .oneOf([yup.ref("email"), null], "*El correo no coincide"),
  countryCode: yup
    .string()
    .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir numeros" })
    .max(250, "Máximo 250 caracteres permitidos"),
  numberPhone: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir numeros" })
    .max(250, "Máximo 250 caracteres permitidos"),
  cityCode: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir numeros" })
    .max(250, "Máximo 250 caracteres permitidos"),
  extension: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir numeros" })
    .max(250, "Máximo 250 caracteres permitidos"),
  telephoneNumber: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_NUMBERS, { message: "*Solo debe incluir numeros" })
    .max(250, "Máximo 250 caracteres permitidos"),

  country: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  province: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  city: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
    .max(250, "Máximo 250 caracteres permitidos"),
  address: yup
    .string()
    .nullable()
    .transform((value, original) => (original === "" ? null : value))
    .max(250, "Máximo 250 caracteres permitidos"),
  parts: yup.array().of(
    yup.object().shape({
      position: yup
        .string()
        .required("**Este campo es requerido")
        .max(250, "Máximo 250 caracteres permitidos"),
    })
  ),
});