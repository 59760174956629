import React, { useState } from "react";
import { Container, Form, InputGroup, Row } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";

const CustomSearchFilterButton = ({
  list,
  setFilteredData,
  placeholder = "Buscar",
  validations = null,
}) => {
  const [textSearch, setTextSearch] = useState("");
  const [errors, setErrors] = useState({});
  const isElementMatchWord = ({ element, word }) => {
    if (typeof element !== "string") return false;
    return element.toLowerCase().includes(word.toLowerCase());
  };
  const getKeys = ({ obj, word }) => {
    return Object.keys(obj).some((key) => {
      if (isElementMatchWord({ element: obj[key], word })) return true;
      const elements = obj[key]?.props?.children;
      if (!Array.isArray(elements)) return false;
      return elements?.some((element) =>
        isElementMatchWord({ element: element?.props?.children, word })
      );
    });
  };
  const filter = ({ dataTableInfo, filterInfo, setFilteredDataTable }) => {
    let filtered = [];
    if (filterInfo === "") {
      filtered = dataTableInfo.rows;
    } else {
      filtered = dataTableInfo.rows.filter((info) => {
        if (getKeys({ obj: info, word: filterInfo })) {
          return info;
        }
        return false;
      });
    }
    setFilteredDataTable({
      columns: dataTableInfo.columns,
      rows: filtered,
    });
  };
  const filterSentence = (word) => {
    if (word) {
      const filterWord = word;

      if (list.rows) {
        filter({
          dataTableInfo: list,
          filterInfo: filterWord,
          setFilteredDataTable: setFilteredData,
        });
      } else {
        const filter = list.filter(function (elem) {
          return ["title", "name", "description", "fullName"].some(
            (prop) =>
              elem.hasOwnProperty(prop) &&
              elem[prop].toLowerCase().indexOf(filterWord.toLowerCase()) > -1
          );
        });
        setFilteredData(filter);
      }
    } else {
      setFilteredData(list);
    }
  };

  return (
    <Container fluid>
      <Row style={{ alignItems: "center" }}>
        <InputGroup bsPrefix="input-group-container">
          <Form.Control
            bsPrefix="custom-input"
            placeholder={placeholder}
            onChange={(event) => {
              const value = event.target.value;
              filterSentence(value);
            }}
            maxLength={validations?.maxLength}
            onKeyDown={(event) => {
              const value = event.target.value;
              if (
                validations?.maxLength &&
                value.length === validations?.maxLength
              ) {
                setErrors({
                  message: `Máximo ${validations?.maxLength} caracteres permitidos`,
                });
              } else {
                setErrors(null);
              }
            }}
          />
          <InputGroup.Text
            bsPrefix="container-icon"
            style={{ cursor: "pointer" }}
          >
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Row>
      <div className="caption custom-input__error">{errors?.message}</div>
    </Container>
  );
};

export default CustomSearchFilterButton;
