import { useLocation } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./pages/appRoutes";
import "./styles/main.scss";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { cleanStore } from "./parts/document/currentDocumentSlice";
import { cleanStoreClausesSelectedSlice } from "./parts/minutas/clausesSelectedSlice";
import { cleanStoreClausesSlice } from "./pages/views/userService/modules/library/clauses/clauseSlice";
import { cleanStoreContractTypeSlice } from "./pages/views/userService/modules/library/contractTypes/contractTypesSlice";
import { cleanStoreUnitCorporativeSlice } from "./pages/views/admin/modules/unitCorporative/unitCorporativeSlice";
import { cleanStoreRequest } from "./pages/views/userService/modules/request/requestSlice";
import { setStatusFetchTag } from "./parts/manageTags/tagSlice";
import { cleanStoreAlert } from "./pages/views/userService/modules/alert/alertSlice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentRoute, setCurrentRoute] = useState("");

  useEffect(() => {
    const newRoute = location.pathname.split("/")[2];
    const noCleanStore = location?.state?.noCleanStore ?? false;
    setCurrentRoute(newRoute);
    if (currentRoute !== newRoute && !noCleanStore) {
      dispatch(cleanStore());
      dispatch(cleanStoreClausesSelectedSlice());
      dispatch(cleanStoreClausesSlice());
      dispatch(cleanStoreContractTypeSlice());
      dispatch(cleanStoreUnitCorporativeSlice());
      dispatch(cleanStoreRequest());
      dispatch(setStatusFetchTag("fetch"));
      dispatch(cleanStoreAlert());
    }
  }, [currentRoute, location.pathname, dispatch]);

  return <AppRoutes />;
}

export default App;
