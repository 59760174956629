import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../components/loadingContent";
import ModalDecision from "../../components/Modals/modalDecision";
import CustomSearchClauses from "../../components/Search/CustomSearchClauses";
import {
  fetchClauses,
  getClausesList,
  getClausesStatus,
} from "../../pages/views/userService/modules/library/clauses/clauseSlice";
import {
  fetchContractTypes,
  getContractsTypesList,
  getStatus,
} from "../../pages/views/userService/modules/library/contractTypes/contractTypesSlice";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../parts/categories/clauseCategorySlice";
import { getClausesByCompany } from "../../services/clauses/clausesServices";
import { CODES } from "../../utils/codes";
import { Mixpanel } from "../../utils/mixPanel";
import { NUMBERINGWORDSO } from "../../utils/numberings";
import {
  getCurrentDocument,
  setSelectedClauses,
} from "../document/currentDocumentSlice";
import FilterCategory from "../filters/FilterCategory";
import FilterContractTypes from "../filters/FilterContractTypes";
import FilterTag from "../filters/FilterTag";
import {
  fetchTags,
  getStatus as getTagsClausesStatus,
  getTagList,
} from "../manageTags/tagSlice";
import AddToQueueOutlinedIcon from "@mui/icons-material/AddToQueueOutlined";

const ImportClause = ({
  classStyle = "custom-input__button__primary-color",
  widthStyle = "auto",
  mixPanelTrack,
}) => {
  const dispatch = useDispatch();
  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const statusContractTypes = useSelector(getStatus);
  const contractTypesList = useSelector(getContractsTypesList);
  const currentDocument = useSelector(getCurrentDocument);
  const clausesStatus = useSelector(getClausesStatus);
  const clausesList = useSelector(getClausesList);
  const tagsClausesStatus = useSelector(getTagsClausesStatus);
  const tagsClausesList = useSelector(getTagList);
  const [show, setShow] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [clauses, setClauses] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [contractTypesSelected, setContractTypesSelected] = useState([]);
  const [clausesSelected, setClausesSelected] = useState([]);
  const [textCustomSearch, setTextCustomSearch] = useState("");
  const [
    isOpenModalDesicionAssociateClauses,
    setIsOpenModalDesicionAssociateClauses,
  ] = useState(false);
  const [
    isOpenModalDesicionCancelAssociateClauses,
    setIsOpenModalDesicionCancelAssociateClauses,
  ] = useState(false);
  const [countChecked, setCountChecked] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleShow = () => {
    for (const item of currentDocument.clauses) {
      setClausesSelected((oldArray) => [...oldArray, item.clause]);
    }
    setCountChecked(0);
    setFilteredClauses(clauses);

    setShow(true);
    if (mixPanelTrack) {
      Mixpanel.track(mixPanelTrack, {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    }
  };
  const handleAgreeModalDesicionAssociateClauses = () => {
    if (countChecked <= 0) {
      setIsOpenModalDesicionAssociateClauses(false);
      return;
    }
    let array = [];
    for (const item of clausesSelected) {
      array.push({ clause: item });
    }
    dispatch(
      setSelectedClauses({
        clauses: array,
      })
    );
    setClausesSelected([]);
    setShow(false);
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleCloseModalDesicionAssociateClauses = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleDisagreeModalDesicionAssociateClauses = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleCloseModalDesicionCancelAssociateClauses = () => {
    setIsOpenModalDesicionAssociateClauses(false);
  };
  const handleAgreeModalDesicionCancelAssociateClauses = () => {
    setClausesSelected([]);
    setCountChecked(0);
    setShow(false);
    setIsOpenModalDesicionCancelAssociateClauses(false);
  };
  const handleDisagreeModalDesicionCancelAssociateClauses = () => {
    setIsOpenModalDesicionCancelAssociateClauses(false);
  };
  const handleChecked = (e, clauses) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setClausesSelected((oldArray) => [...oldArray, clauses]);
    } else {
      setCountChecked(countChecked - 1);
      setClausesSelected(
        clausesSelected.filter((clause) => clause._id !== clauses._id)
      );
    }
  };
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.name));
      });
      setFilteredClauses(filterTags);
    } else if (categoriesSelected.length > 0) {
      refreshCategories();
    } else {
      setFilteredClauses(clauses);
    }
  };
  const refreshTags = () => {
    if (tagsSelected.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => tagsSelected.includes(tag.name));
      });
      setFilteredClauses(filterTags);
    } else {
      setFilteredClauses(clauses);
    }
  };
  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };
  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });
      setFilteredClauses(filterCategories);
    } else if (tagsSelected.length > 0) {
      refreshTags();
    } else {
      setFilteredClauses(clauses);
    }
  };
  const refreshCategories = () => {
    if (categoriesSelected.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          categoriesSelected.includes(category.name)
        );
      });
      setFilteredClauses(filterCategories);
    } else {
      setFilteredClauses(clauses);
    }
  };
  const handleContractTypesSelected = (contractTypes) => {
    setContractTypesSelected(contractTypes);
  };
  const handleDeleteContractType = (contractType) => {
    setContractTypesSelected(
      contractTypesSelected.filter((ct) => ct._id !== contractType._id)
    );
    const newContractTypes = contractTypesSelected.filter(
      (ct) => ct._id !== contractType._id
    );
    if (newContractTypes.length > 0) {
      let contractTypesClauses = [];
      newContractTypes.forEach((contractType) => {
        contractType.clauses.forEach(({ clause }) => {
          contractTypesClauses.push(clause._id);
        });
      });
      const filterContractTypes = clauses.filter((item) =>
        contractTypesClauses.includes(item._id)
      );
      setFilteredClauses(filterContractTypes);
    } else if (categoriesSelected.length > 0) {
      refreshCategories();
    } else if (tagsSelected.length > 0) {
      refreshTags();
    } else {
      setFilteredClauses(clauses);
    }
  };

  const handleCustomSearch = async (textSearch) => {
    try {
      setTextCustomSearch(textSearch);
      setIsLoading(true);
      if (textSearch === "") {
        setFilteredClauses(clauses);
      } else {
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
          searchTerm: textSearch,
        };
        const response = await getClausesByCompany(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          const formatedParts = transformaParts(response.data.responseMessage);
          setFilteredClauses(formatedParts);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /* UseEffects Fetch Clauses Categories */

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  /* UseEffects Fetch Clauses Tags */

  useEffect(() => {
    try {
      if (tagsClausesStatus === "fetch") {
        dispatch(fetchTags("CLAUSES"));
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsClausesStatus, dispatch]);

  useEffect(() => {
    if (tagsClausesList) {
      setFilteredTags(tagsClausesList);
    }
  }, [tagsClausesList]);

  /* UseEffects Fetch Clauses */

  useEffect(() => {
    if (clausesStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [clausesStatus]);

  useEffect(() => {
    try {
      if (clausesStatus === "fetch") {
        dispatch(fetchClauses(textCustomSearch));
      }
    } catch (error) {
      console.error(error);
    }
  }, [clausesStatus, dispatch]);

  /* Formateo de partes:
     se convierte de parts: { partA: "A", partB: "B" } a ["A","B"] */
  const transformaParts = (clauses) => {
    if (!clauses.length) return [];
    return clauses.map((item) => {
      let arrayParts = [];
      if (item.parts.partA) {
        arrayParts.push(item.parts.partA);
      }
      if (item.parts.partB) {
        arrayParts.push(item.parts.partB);
      }
      if (item.parts.partC) {
        arrayParts.push(item.parts.partC);
      }
      if (item.parts.partD) {
        arrayParts.push(item.parts.partD);
      }
      return { ...item, parts: arrayParts };
    });
  };

  useEffect(() => {
    if (clausesList) {
      const formatParts = transformaParts(clausesList);
      setFilteredClauses(formatParts);
      setClauses(formatParts);
    }
  }, [clausesList]);

  /* UseEffects Fetch Clauses */

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusContractTypes === "fetch") {
          dispatch(fetchContractTypes({ isPublish: false }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusContractTypes, dispatch]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddToQueueOutlinedIcon />}
        className={classStyle}
        onClick={handleShow}
        style={{ width: widthStyle }}
      >
        <p className="button">Importar cláusula</p>
      </Button>
      <Modal
        show={show}
        fullscreen
        onHide={() => {
          setShow(false);
        }}
      >
        {isLoading && <LoadingContent />}
        <Modal.Header>
          <Modal.Title>
            <p className="label__primary-color__page-title">Asociar cláusula</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Busca las cláusulas y selecciona los numerales que quieres
                  añadir al contrato:
                </p>
              </Col>
            </Row>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                {/* <CustomSearchFilter
                  list={clauses}
                  setFilteredData={setFilteredClauses}
                  placeholder="Buscar cláusula"
                /> */}
                <CustomSearchClauses
                  placeholder="Buscar cláusula"
                  handleCustomeSearch={handleCustomSearch}
                />
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} cláusulas seleccionadas
                </p>
              </Col>
              <Col xs={"auto"}>
                <FilterContractTypes
                  list={clauses}
                  contractTypesList={contractTypesList}
                  setFilteredInfo={setFilteredClauses}
                  handleContractTypesSelected={handleContractTypesSelected}
                />
              </Col>
              <Col xs={"auto"}>
                <FilterTag
                  list={filteredClauses}
                  tagsList={filteredTags}
                  setFilteredInfo={setFilteredClauses}
                  handleTagsSelected={handleTagsSelected}
                  currentTagSelected={tagsSelected}
                />
              </Col>
              <Col xs={"auto"}>
                <FilterCategory
                  list={filteredClauses}
                  categoriesList={categoriesList}
                  setFilteredInfo={setFilteredClauses}
                  handleCategoriesSelected={handleCategoriesSelected}
                />
              </Col>
            </Row>
            <br />
            <Row sm={"auto"} style={{ justifyContent: "right" }}>
              {tagsSelected.map((tag, idx) => {
                return (
                  <Col xs={"auto"} key={tag?._id}>
                    <span className="caption custom-badges__disabled">
                      {tag + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}

              {categoriesSelected.map((category, idx) => {
                return (
                  <Col xs={"auto"} key={category?._id}>
                    <span className="caption custom-badges__secundary">
                      {category + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteCategory(category)}
                      />
                    </span>
                  </Col>
                );
              })}

              {contractTypesSelected.map((contractType, idx) => {
                return (
                  <Col xs={"auto"} key={contractType?._id}>
                    <span className="caption custom-badges__tertiary">
                      {contractType?.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteContractType(contractType)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
            <br />
            <br />
            <Row>
              <div className="custom-accordion__container">
                {filteredClauses.length > 0 ? (
                  filteredClauses.map((clause, index) => {
                    let state = false;
                    clausesSelected.forEach((item) => {
                      if (item.id === clause.id) {
                        state = true;
                      }
                    });
                    let isDisabled = false;
                    currentDocument.clauses.forEach((item) => {
                      if (item.clause._id === clause.id) {
                        isDisabled = true;
                      }
                    });
                    return (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={clause?._id}
                      >
                        <Col>
                          <Accordion className="custom-accordion">
                            <Row style={{ alignItems: "center" }}>
                              <Col xs={"auto"}>
                                <Checkbox
                                  disabled={currentDocument.clauses.some(
                                    (item) => item?.clause?._id === clause?.id
                                  )}
                                  checked={clausesSelected.some(
                                    (item) => item?._id === clause?.id
                                  )}
                                  onClick={(e) => handleChecked(e, clause)}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 28,
                                    },
                                    ".MuiAccordionSummary-gutters": {
                                      disableGutters: true,
                                    },
                                    color: "#00374f",
                                    "&.Mui-checked": {
                                      color: isDisabled ? "gray" : "#00374f",
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <AccordionSummary
                                  sx={{
                                    ".MuiAccordionSummary-content": {
                                      alignItems: "center",
                                    },
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Col sm={4}>
                                    <p className="heading__primary-color">
                                      {clause.title + " "}
                                    </p>
                                  </Col>
                                  <Col style={{ textAlign: "right" }}>
                                    <span className="subheading">
                                      A favor de: {clause.inFavor + " "}
                                    </span>
                                  </Col>
                                  {clause.categories.length > 0 && (
                                    <Col
                                      xs={"auto"}
                                      style={{ textAlign: "right" }}
                                    >
                                      <Col>
                                        <span className="subheading">
                                          Categorías:{" "}
                                          {clause.categories.map(
                                            (category, i) => {
                                              return (
                                                <span
                                                  key={category?._id}
                                                  style={{ marginRight: "1%" }}
                                                  className="caption custom-badges__secundary"
                                                >
                                                  {category.name}
                                                </span>
                                              );
                                            }
                                          )}
                                        </span>
                                      </Col>
                                    </Col>
                                  )}
                                </AccordionSummary>
                              </Col>
                            </Row>
                            <AccordionDetails
                              style={{
                                width: "100%",
                                boxShadow:
                                  "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                              }}
                            >
                              <Row>
                                <span className="subheading">
                                  Etiquetas:{" "}
                                  {clause.labels.map((tag, i) => {
                                    return (
                                      <span
                                        key={tag?._id}
                                        style={{ marginRight: "1%" }}
                                        className="caption custom-badges__disabled"
                                      >
                                        {tag.name}
                                      </span>
                                    );
                                  })}
                                </span>
                              </Row>
                              <br />
                              <br />
                              <Row>
                                <span className="caption">
                                  {/* Text clause */}
                                  <Row style={{ alignItems: "center" }}>
                                    <Col>{ReactHtmlParser(clause.text)}</Col>
                                  </Row>
                                  <br />
                                  <br />
                                  {/* Mapea estado de las subclausulas para selecionara */}
                                  {clause?.subclauses?.map((item, i) => (
                                    <Row key={item?._id}>
                                      <Col xs={"auto"}>
                                        <b>
                                          {`${i + 1}. `}
                                          {item?.subclause?.title &&
                                            `${item?.subclause?.title}: `}
                                        </b>
                                      </Col>
                                      <Col>
                                        <span className="caption">
                                          {ReactHtmlParser(
                                            item?.subclause?.text
                                          )}
                                        </span>
                                      </Col>
                                      {item?.subclause?.childs &&
                                        item?.subclause?.childs.map(
                                          (child, index) => (
                                            <Row
                                              key={child?._id}
                                              style={{ marginLeft: "2rem" }}
                                            >
                                              <Col xs={"auto"}>
                                                <b>
                                                  {`${i + 1}.${index + 1}. `}
                                                  {child?.title}
                                                </b>
                                              </Col>
                                              <Col>
                                                <span className="caption">
                                                  {ReactHtmlParser(child?.text)}
                                                </span>
                                              </Col>
                                            </Row>
                                          )
                                        )}
                                    </Row>
                                  ))}
                                  <br />
                                  <ol className="paragraphs clauses-number">
                                    {clause.paragraphs.map((el, i) => {
                                      return (
                                        <li
                                          key={el?._id}
                                          className="justify-text clauses-number"
                                          numbering={`Parágrafo ${
                                            NUMBERINGWORDSO[i + 1]
                                          }`}
                                        >
                                          <span className="caption">
                                            {ReactHtmlParser(
                                              el.paragraph?.text
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ol>
                                </span>
                              </Row>
                            </AccordionDetails>
                          </Accordion>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography variant="h5">
                    No se han encontrado resultados
                  </Typography>
                )}
              </div>
            </Row>
            {/* Botones de confirmación */}
            <Row
              xs={"auto"}
              style={{ marginTop: "1.5rem", justifyContent: "end" }}
            >
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() =>
                    setIsOpenModalDesicionCancelAssociateClauses(true)
                  }
                >
                  <p className="button">Regresar</p>
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setIsOpenModalDesicionAssociateClauses(true);
                  }}
                >
                  <p className="button">Importar cláusulas</p>
                </Button>
              </Col>
            </Row>
            <ModalDecision
              title={"Cláusulas asociadas"}
              message={
                countChecked > 0
                  ? `Se asociarán al contrato ${countChecked} cláusulas`
                  : "No se han seleccionado cláusulas"
              }
              agreeText={"Aceptar"}
              disagreeText={"Cancelar"}
              handleAgree={handleAgreeModalDesicionAssociateClauses}
              handleDisagree={handleDisagreeModalDesicionAssociateClauses}
              open={isOpenModalDesicionAssociateClauses}
              onClose={handleCloseModalDesicionAssociateClauses}
            />
            <ModalDecision
              title={"Regresar"}
              message={
                "¿Está seguro que desea regresar? Los cambios no se guardarán."
              }
              agreeText={"Aceptar"}
              disagreeText={"Cancelar"}
              handleAgree={handleAgreeModalDesicionCancelAssociateClauses}
              handleDisagree={handleDisagreeModalDesicionCancelAssociateClauses}
              open={isOpenModalDesicionCancelAssociateClauses}
              onClose={handleCloseModalDesicionCancelAssociateClauses}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { ImportClause };
