import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row, Container, Form } from "react-bootstrap";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ModalUploadDocs = ({
  message,
  agreeText,
  disagreeText,
  onClose,
  open,
  documentData,
  setDocumentData,
  maxFileSize = 5242880,
  typeDocument = "Contrato",
  title = "Selecciona documento para subir",
  description = "",
  messageTypeValidation = "Formato incompatible. Recuerd​a ​cargar documentos en formato .pdf",
  noStorageAvailable,
  messageContractValidation = "Solo se permiten formatos .docx, .pdf o .doc",
  additionalFields,
}) => {
  const [uploadDoc, setUploadDoc] = useState({});
  const SIZE_BYTE_MEGABYTE = 1048576;

  const schema = yup.object().shape({
    file: yup
      .mixed()
      .test("Required", "Debes subir un documento", (value) => value?.[0])
      .test(
        "Storage",
        "La compañía no tiene almacenamiento disponible en el plan para el documento cargado, comunícate con el usuario administrador de Legal AI",
        (value) => !noStorageAvailable
      )
      .test(
        "fileSize",
        `Máximo ${
          maxFileSize / SIZE_BYTE_MEGABYTE
        }MB por archivo, inténtalo de nuevo`,
        (value) => value?.[0] && value[0].size <= maxFileSize
      )
      .test(
        "fileFormat",
        typeDocument === "Contrato"
          ? messageContractValidation
          : messageTypeValidation,
        (value) => {
          if (typeDocument === "Contrato") {
            return (
              value?.[0]?.type === "application/pdf" ||
              value?.[0]?.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              value?.[0]?.type === "application/msword"
            );
          } else if (typeDocument === "ONLY_WORD") {
            return (
              value?.[0]?.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              value?.[0]?.type === "application/msword"
            );
          } else if (typeDocument === "ONLY_CSV") {
            return value?.[0]?.type === "text/csv";
          } else if (typeDocument === "ONLY_PDF") {
            return value?.[0]?.type === "application/pdf";
          } else if (typeDocument === "ONLY_EXCEL") {
            return (
              value?.[0]?.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              value?.[0]?.type === "application/vnd.ms-excel"
            );
          } else {
            return value?.[0]?.type === "application/pdf";
          }
        }
      ),

    ...(additionalFields
      ? {
          additionalFields: yup.string().required("*Este campo es obligatorio"),
        }
      : {}),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleClose = () => {
    onClose();
    setDocumentData({});
  };
  const handleUploadDocs = (data) => {
    setDocumentData(data.file[0]);
    onClose();
  };

  useEffect(() => {
    if (!documentData?.name) {
      setUploadDoc({});
    }
  }, [documentData]);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={open}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle>
        <label className="heading__primary-color">{title}</label>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Form onSubmit={handleSubmit(handleUploadDocs)}>
        <DialogContent className="caption" dividers style={{ height: "35rem" }}>
          {additionalFields && (
            <div>
              <Controller
                name={"additionalFields"}
                control={control}
                render={({ field }) =>
                  React.cloneElement(additionalFields, {
                    ...additionalFields.props,
                    ...field,
                  })
                }
              />
              <p className="caption custom-input__error  pt-1">
                {errors?.additionalFields?.message}
              </p>
            </div>
          )}
          <Row>
            <p
              className="caption"
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {description}
            </p>
          </Row>
          <Form.Group
            controlId="formFile"
            className="mb-3"
            style={{ height: "70%", flexDirection: "column" }}
          >
            <div className="inputFileModificado ">
              <Container className="inputParaMostrar">
                <Row
                  className="justify-content-md-center"
                  style={{ height: "40%", alignContent: "flex-end" }}
                >
                  <Col md={3}>
                    {uploadDoc?.type ? (
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 50 }}
                        className="iconUpload"
                      />
                    ) : (
                      <ArrowCircleUpIcon
                        sx={{ fontSize: 50 }}
                        className="iconUpload"
                      />
                    )}
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center"
                  style={{ height: "30%" }}
                >
                  <Col md={10} className="mt-1">
                    <Button
                      variant="text"
                      className="custom-input__button__secondary-color"
                    >
                      {uploadDoc?.name
                        ? "Documento cargado"
                        : "Agregar documento"}
                    </Button>
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center"
                  style={{ height: "20%" }}
                >
                  {uploadDoc?.name
                    ? uploadDoc.name
                    : "o arrastra el documento para cargar"}
                </Row>
              </Container>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="file"
                    bsPrefix={
                      "input-group-container__no-icon label inputFileOculto"
                    }
                    onChange={(e) => {
                      if (e.target.files.length) {
                        field.onChange(e.target.files);
                        setUploadDoc(e.target.files[0]);
                      }
                    }}
                  />
                )}
              />
            </div>
            <p className="caption custom-input__error text-center pb-2">
              {errors.file?.message}
            </p>
            <p
              className="caption"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {message}
            </p>
            {noStorageAvailable && (
              <p className="caption custom-input__error"></p>
            )}
          </Form.Group>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                variant="text"
                className="custom-input__button__secondary-color__forced"
                onClick={() => {
                  setUploadDoc({});
                  clearErrors("file");
                  onClose();
                  setDocumentData({});
                }}
              >
                {disagreeText}
              </Button>
            </Col>
            <Col>
              <Button
                variant="contained"
                type="submit"
                className="custom-input__button__primary-color__forced"
              >
                {agreeText}
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ModalUploadDocs;
