import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useForm } from "react-hook-form";
import ModalDecision from "../../components/Modals/modalDecision";
import { ConsultText } from "../../services/consults/consultsServices";
import { CODES } from "../../utils/codes";
import { DocumentViewer } from "../Viewer/DocumentViewer";
import PopoverActions from "../Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CustomToolTip } from "../Tooltip/Tooltip";
export default function DrawerSearchConsults({ open, setOpen }) {
  const [hiddenViewPdf, setHiddenViewPdf] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [openModalResponse, setOpenModalResponse] = useState(false);
  const [dataDocuments, setDataDocuments] = useState(null);
  const [widthScreen, setWidthScreen] = useState("25%");

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isIconButton, setIconButton] = useState(true);
  const [documentSelected, setDocumentSelected] = useState(null);
  const containerRef = useRef(null);
  const handleModalDesicion = () => {
    responseData && dataDocuments === null
      ? setOpen(false)
      : setIsOpenModalDecision(true);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleAgreeModalDesicion = (openDrawer = false) => {
    setValue("searchText", "");
    setOpen(openDrawer);
    setIsOpenModalDecision(false);
    setDataDocuments(null);
    setResponseData({});
    setWidthScreen("25%");
    setHiddenViewPdf(false);
  };

  const refInputText = useRef();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleViewPdf = (b64Data, newDocumentId) => {
    if (documentSelected?.id !== newDocumentId) {
      const blob = b64toBlob(b64Data, "application/pdf");
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    } else {
      return documentSelected?.blob;
    }
  };

  const handleConsult = async (data) => {
    try {
      setDocumentSelected(null);
      setIsLoading(true);
      setWidthScreen("100%");
      setHiddenViewPdf(true);
      setIconButton(false);
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const consultation = data?.searchText || "";

      const response = await ConsultText({
        consultation,
        company,
        corporateUnit,
      });

      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const data = response.data.responseMessage.data;
        if (data.length > 0) {
          const blob = handleViewPdf(data?.[0]?.blob);

          setDocumentSelected({
            document: data?.[0],
            word: data?.[0]?.pagesRanking?.[0],
            page: data?.[0]?.pagesRanking?.[0]?.page,
            blob,

            fileName: data?.[0].fileName,
          });
        }
        setDataDocuments(data);
      }

      setResponseData(response);
      setOpenModalResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const handleZoom = (doc, scale) => {
    console.log(`Zoom document to ${scale}`);
  };

  return (
    <Drawer
      open={open}
      onClose={() => toggleDrawer(false)}
      PaperProps={{ sx: { width: widthScreen, overflowX: "hidden" } }}
      BackdropProps={{ sx: { backgroundColor: "#00374F66" } }}
    >
      <Row style={{ height: "100%" }} ref={containerRef} className="pt-2">
        <Col
          style={{ boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)" }}
          className="custom-container-scroll"
        >
          <Row className="m-2" style={{ alignItems: "center" }}>
            <Col xs="auto">
              <CustomToolTip
                direction="bottom"
                message={
                  <p className="caption p-2 gradient-text">
                    Funcionalidad Beta
                  </p>
                }
              >
                <InfoOutlinedIcon
                  style={{ fontSize: "20px", color: "rgba(22, 202, 211, 1)" }}
                />
              </CustomToolTip>
            </Col>
            <Col>
              <span className="heading">Búsqueda inteligente</span>
            </Col>
            <Col xs={"auto"}>
              <IconButton aria-label="close" onClick={handleModalDesicion}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
          <Row className="m-2 mt-4">
            <Col>
              <p className="caption  ">
                Utiliza palabras clave para buscar en tus conceptos jurídicos
              </p>
            </Col>
          </Row>
          <Row className="m-2 mt-4">
            <Col>
              <InputGroup
                bsPrefix="input-group-container"
                style={{ padding: " 8px" }}
              >
                <Form.Control
                  bsPrefix="custom-input"
                  placeholder="Buscar"
                  type="text"
                  style={{ borderRadius: "4px" }}
                  {...register("searchText", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                  })}
                />

                <InputGroup.Text bsPrefix="container-icon">
                  {isIconButton ? (
                    <SearchOutlinedIcon
                      style={{ color: "#C5C7D0" }}
                      fontSize="large"
                    />
                  ) : (
                    <IconButton onClick={() => handleAgreeModalDesicion(true)}>
                      <CloseIcon
                        style={{ color: "#C5C7D0" }}
                        fontSize="medium"
                      />
                    </IconButton>
                  )}
                </InputGroup.Text>
              </InputGroup>
              <span className="caption custom-input__error">
                {errors?.searchText?.message}
              </span>
            </Col>
          </Row>
          <Row className="m-2 justify-content-end">
            <Col xs={"auto"}>
              <Button
                type="button"
                className="custom-input__button__iaColor "
                style={{
                  padding: "1rem 2rem",
                  borderRadius: "10px",
                  border: "none",
                  fontSize: "14px",
                }}
                onClick={handleSubmit(handleConsult)}
              >
                Buscar
              </Button>
            </Col>
          </Row>
          <br />
          {dataDocuments?.length > 0 &&
            dataDocuments.map((element, index) => (
              <Row key={element?.id} as={Col}>
                <Accordion
                  className="custom-accordion"
                  defaultExpanded={index === 0}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <p className="subheading">
                      {element?.type || "Documento"}: {element?.fileName || ""}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        border: "1px solid #c5c7d0",
                        padding: "0px 8px",
                        borderRadius: "8px",
                      }}
                    >
                      {element?.pagesRanking?.length &&
                        element.pagesRanking.map((word, indexWord) => {
                          const classes = {
                            last: "custom-accordion-summary-rows-last-child",
                            normal: "custom-accordion-summary-rows",
                            "last-selected":
                              "custom-accordion-summary-rows-last-child-selected",
                            selected: "custom-accordion-summary-rows-selected",
                          };

                          const isLastItem =
                            indexWord === element.pagesRanking.length - 1;

                          const itemIsSelected =
                            word?._id === documentSelected?.word?.id &&
                            documentSelected?.document?.id === element?.id;

                          let classNameItem = classes["normal"];

                          if (itemIsSelected && isLastItem) {
                            classNameItem = classes["last-selected"];
                          } else if (itemIsSelected && !isLastItem) {
                            classNameItem = classes["selected"];
                          } else if (isLastItem) {
                            classNameItem = classes["last"];
                          }

                          return (
                            <Row
                              key={word?.id}
                              className={classNameItem}
                              onClick={() =>
                                setDocumentSelected({
                                  document: element,
                                  word,
                                  page: word.page,
                                  blob: handleViewPdf(
                                    element?.blob,
                                    element.id
                                  ),
                                  id: element.id,
                                  fileName: element.fileName,
                                })
                              }
                            >
                              <Col xs={"auto"}>
                                <InsertDriveFileOutlinedIcon
                                  fontSize="large"
                                  htmlColor="#00374f"
                                />
                              </Col>
                              <Col>
                                <Row className="caption">
                                  Página {word?.page || ""}
                                </Row>
                                <Row className="caption__disabled">
                                  Puesto {word?.rank || ""}
                                </Row>
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Row>
            ))}

          {!dataDocuments?.length && hiddenViewPdf && !isLoading && (
            <Row className="m-2">
              <Col xs={12}>
                <p className="subheading">
                  No se encontraron resultados para tu busqueda.
                </p>
              </Col>
            </Row>
          )}
        </Col>
        {hiddenViewPdf && (
          <Col xs={9}>
            <DocumentViewer
              isLoading={isLoading}
              onZoom={handleZoom}
              documentPath={documentSelected?.blob}
              currentDocumentPage={documentSelected?.page}
              fileName={documentSelected?.fileName}
            />
          </Col>
        )}
      </Row>
      <ModalDecision
        title={"Atención"}
        message={
          "Ten en cuenta, que si cierras esta ventana tu búsqueda actual se perderá."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleCloseModalDesicion}
      />
    </Drawer>
  );
}
