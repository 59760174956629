
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { signatoriesTypes } from "../../utils/typesSignatures";
import { NUMBERINGWORDSO } from "../../utils/numberings";

export const ViewDocumentLegal = ({ currentDocument }) => {
    const typeUsers = {
        natural: {
            M: signatoriesTypes.SignatariOPN.type,
            F: signatoriesTypes.SignatariAPN.type,
            Femenino: signatoriesTypes.SignatariAPN.type,
            Masculino: signatoriesTypes.SignatariOPN.type,
        },
        legal: {
            M: signatoriesTypes.SignatariOPJ.type,
            F: signatoriesTypes.SignatariAPJ.type,
            Femenino: signatoriesTypes.SignatariAPJ.type,
            Masculino: signatoriesTypes.SignatariOPJ.type,
        },
        getText({type = 'natural', gender = 'M', text = '', index = 0}){
            return `Signatario ${index + 1} (${this?.[type]?.[gender] || ""}), ${text}`
        }
    }
    return (<Container className="document-view">
        <Row className="heading__primary-color">Partes</Row>
        <Row>
            {currentDocument?.parts?.length > 0 &&
                currentDocument.parts.filter(p => p).map((part, index) => (
                    <Container key={part?._id} className="container_text">
                        <Row>
                            <Col className="heading__primary-color">{`${index + 1}. ${part?.name || "Sin titulo"
                                }`}</Col>
                        </Row>
                        <Row className="container_text__subclause">
                            <ul>
                                {part?.signatories?.length > 0 ? (
                                    part.signatories.map((sig, i) => {
                                        return (
                                        <li key={sig?._id} className="label">
                                            {typeUsers.getText({gender: sig?.gender || "", type: sig?.type || "", index: i, text: sig?.name || ""})}
                                        </li>
                                    )})
                                ) : (
                                    <li className="label">Sin signatarios</li>
                                )}
                            </ul>
                        </Row>
                        <Row className="container_text__subclause label">
                            {ReactHtmlParser(part?.text || "Sin texto final")}
                        </Row>
                    </Container>
                ))}
        </Row>
        <Row className="heading__primary-color">Encabezados</Row>
        <Row>
            {currentDocument?.headers?.length > 0 ? (
                currentDocument.headers.map((header, index) => (
                    <Container key={header?._id} className="container_text">
                        <Row>
                            <Col className="heading__primary-color">{`${index + 1}. ${header?.title || "Sin titulo"
                                }`}</Col>
                        </Row>
                        <Row className="container_text__clause-text label">
                            {ReactHtmlParser(header?.text || "")}
                        </Row>
                        <Row className="container_text__clause-text">
                            <ul>
                                {header?.childs?.length > 0 &&
                                    header.childs.map((child, index2) => (
                                        <Container
                                            key={child?._id}
                                            className="container_text__subclause"
                                        >
                                            <Row>
                                                <Col className="heading__primary-color">{`${index + 1
                                                    }.${index2 + 1}`}</Col>
                                            </Row>
                                            <Row className="container_text__clause-text label">
                                                {ReactHtmlParser(child?.text || "")}
                                            </Row>
                                        </Container>
                                    ))}
                            </ul>
                        </Row>
                    </Container>
                ))
            ) : (
                <p className="container_text__clause-text label">
                    Sin encabezados.
                </p>
            )}
        </Row>
        <Row className="heading__primary-color">Cláusulas</Row>
        <Row>
            {currentDocument?.clauses?.length > 0 ? (
                currentDocument.clauses.map((clause, index) => {
                    const subclauses = clause?.clause?.subclauses || clause?.subclauses || "";
                    const paragraphs = clause?.clause?.paragraphs || clause?.paragraphs || "";
                    return (
                        <Row key={clause?._id}>
                            <Container className="container_text" id={`${index + 1}.0.0`}>
                                <Row>
                                    <Col md={4} className="heading__primary-color">{`${index + 1}. ${clause?.title || clause?.clause?.title || "Sin titulo"}`}</Col>
                                </Row>
                                <Row className="container_text__clause-text label">
                                    {ReactHtmlParser(clause?.text || clause?.clause?.text || "")}
                                </Row>
                            </Container>
                            {subclauses?.length > 0 &&
                                subclauses?.map((sub, index2) => (
                                    <Container
                                        key={sub?._id}
                                        className="container_text__subclause"
                                        id={`${index}.${index2 + 1}.0`}
                                    >
                                        <Row style={{ alignItems: "center" }}>
                                            <Col xs={"auto"} className="heading__primary-color">
                                                {`${index + 1}.${index2 + 1}. ${sub?.subclause?.title || ""}`}
                                            </Col>
                                        </Row>
                                        <Row style={{ alignItems: "center" }}>
                                            <Col className="container_text__clause-text label">
                                                {ReactHtmlParser(sub?.subclause?.text || "")}
                                            </Col>
                                        </Row>
                                        <Row className="container_text__clause-text label">

                                        </Row>
                                        {sub?.subclause?.childs.length > 0 &&
                                            sub?.subclause?.childs?.map((child, index4) => (
                                                <Container
                                                    key={child?._id}
                                                    className="container_text__subclause"
                                                >
                                                    <Row>
                                                        <Col className="heading__primary-color">{`${index + 1
                                                            }.${index2 + 1}.${index4 + 1}. ${child?.title || ""
                                                            }`}</Col>
                                                    </Row>
                                                    <Row className="container_text__clause-text label">
                                                        {ReactHtmlParser(child?.text || "")}
                                                    </Row>
                                                </Container>
                                            ))}
                                    </Container>
                                ))}
                            {paragraphs?.length > 0 &&
                                paragraphs?.map((para, index3) => (
                                    <Container
                                        key={"para" + para?._id}
                                        className="container_text__subclause"
                                        id={`${index}.0.${index3 + 1}`}
                                    >
                                        <Row>
                                            <Col className="heading__primary-color">{`Parágrafo ${NUMBERINGWORDSO[index3 + 1]
                                                } ${para?.paragraph?.title || ""}`}</Col>
                                        </Row>
                                        <Row className="container_text__clause-text label">
                                            {ReactHtmlParser(para?.paragraph?.text || "")}
                                        </Row>
                                    </Container>
                                ))}
                        </Row>
                    );
                })
            ) : (
                <p className="container_text__clause-text label">
                    Sin cláusulas.
                </p>
            )}
        </Row>
        <Row className="heading__primary-color">Firmas</Row>
        <Row>
            {currentDocument?.parts?.length > 0 &&
                currentDocument.parts.filter(p => p).map((part, index) => (
                    <Container key={"part" + part?._id} className="container_text">
                        <Row>
                            <Col className="heading__primary-color">{`${index + 1
                                }. Firma ${part.name || "sin titulo"}`}</Col>
                        </Row>
                        <Row className="container_text__subclause">
                            <ul>
                                {part?.signatories?.length > 0 ? (
                                    part?.signatories?.map((sig, i) =>
                                        sig?.signatures?.attributes.map((attri, index) => (
                                            <li key={attri?._id} className="label">
                                                {attri.name + " " + attri.value}
                                            </li>
                                        ))
                                    )
                                ) : (
                                    <li className="label">Sin firmas</li>
                                )}
                            </ul>
                        </Row>
                    </Container>
                ))}
        </Row>
    </Container>)
}