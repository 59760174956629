import CoachMark from "../../../../../../components/CoachMark/CoachMark";

export const steps = [
  {
    target: ".create-clause-coachmark-step-1",
    disableBeacon: true,
    content: (
      <CoachMark
        text={
          "Asígnale un título a la cláusula, este se verá en la previsualización de la cláusula y te ayudara a identificarla. No olvides denominar las partes ya que son importantes en cualquier contrato, puedes añadir un máximo de 4 partes."
        }
      />
    ),
    placement: "auto",
  },
  {
    target: ".create-clause-coachmark-step-2",
    disableBeacon: true,
    content: (
      <CoachMark
        text={
          "Escribe en el campo de texto el contenido de la cláusula que estás creando."
        }
      />
    ),
    placement: "auto",
  },
  {
    target: ".create-clause-coachmark-step-3",
    disableBeacon: true,
    content: (
      <CoachMark
        text={
          "Utiliza los botones de las partes para pegar automaticamente aquella parte en el texto."
        }
      />
    ),
    placement: "auto",
  },
  {
    target: ".create-clause-coachmark-step-4",
    disableBeacon: true,
    content: (
      <CoachMark
        text={
          "Utiliza el botón de “Espacio en blanco” para que este campo se pueda llenar posteriormente en la reutilización de la cláusula. "
        }
      />
    ),
    placement: "auto",
  },
  {
    target: ".create-clause-coachmark-step-5",
    disableBeacon: true,
    content: (
      <CoachMark
        text={
          "Utiliza el botón de “Numeral” para añadir información de la cláusula que tendrá una visualización sangrada."
        }
      />
    ),
    placement: "auto",
  },
  {
    target: ".create-clause-coachmark-step-6",
    disableBeacon: true,
    content: (
      <CoachMark
        text={
          "Marca tu cláusula como “Cláusula Sensible” cuando creas que requieran especial atención por parte del abogado. Luego deberás escribir tu definición de sensible para esta cláusula."
        }
      />
    ),
    placement: "auto",
  },
];
