import AddIcon from "@mui/icons-material/Add";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import CustomSearchFilter from "../../../../../../components/Search/CustomSearchFilter";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import {
  fetchTagsDocumentTempletes,
  getTagDocumentTempleteStatus,
  getTagDocumentTempletesList,
} from "../../../../../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
import {
  fetchPrederminatedTexts,
  getPrederminatedTextsList,
  getStatusPrederminatedTexts,
  setStatusPrederminatedTexts,
} from "./prederminatedTextsSlice";
import ReactHtmlParser from "react-html-parser";
import { deleteOtherDocumentPredeterminedText } from "../../../../../../services/predeterminatedTexts/predeterminatedTextsService";
import { CODES } from "../../../../../../utils/codes";
import { handlePreviewTemplate } from "../../create/other/utils";

const ViewPredeterminatedTexts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // USESTATES
  const [dataPredeterminatedTexts, setDataPredeterminatedTexts] = useState([]);
  const [filterDataPredeterminatedTexts, setFilterDataPredeterminatedTexts] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [deletedTag, setDeletedTag] = useState("");

  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [tagsSelected, setTagsSelected] = useState([]);
  const [modalResponse, setModalResponse] = useState({
    open: false,
    response: {},
    title: "",
  });

  // USESELECTORS
  const listPredeterminatedTexts = useSelector(getPrederminatedTextsList);
  const statusPredeterminatedTexts = useSelector(getStatusPrederminatedTexts);
  const tagListPredeterminatedTexts = useSelector(getTagDocumentTempletesList);
  const statusTagsDocumentTempletes = useSelector(getTagDocumentTempleteStatus);

  // HANDLE FUNCTIONS

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleCloseModalDesicion = async () => {
    setIsOpenModalDecision(false);
  };

  const handleOpenModalDesicion = async (id) => {
    setIsOpenModalDecision(true);
    setDeletedTag(id);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalDecision(false);
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const obj = {
        company,
        corporateUnit,
        userId,
        id: deletedTag,
      };
      const responseDeleteText = await deleteOtherDocumentPredeterminedText(
        obj
      );
      if (
        responseDeleteText?.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeleteText?.data?.success
      ) {
        dispatch(setStatusPrederminatedTexts());
        setModalResponse({
          open: true,
          response: responseDeleteText,
          title: "Elimado correctamente",
        });
        return;
      }

      setModalResponse({
        open: true,
        response: responseDeleteText,
        title: "Elimado correctamente",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected?.filter((t) => t !== tag));
    const newTags = tagsSelected?.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = listPredeterminatedTexts?.filter((item) => {
        return item?.labels?.some((tag) => newTags.includes(tag.title));
      });
      setFilterDataPredeterminatedTexts(filterTags);
    } else {
      setFilterDataPredeterminatedTexts(listPredeterminatedTexts);
    }
  };

  // USEEFFECT

  useEffect(() => {
    const fetchPredeterminatedTextsData = async () => {
      try {
        if (statusPredeterminatedTexts === "fetch") {
          dispatch(fetchPrederminatedTexts());
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPredeterminatedTextsData();
  }, [statusPredeterminatedTexts, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsDocumentTempletes === "fetch") {
          dispatch(fetchTagsDocumentTempletes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsDocumentTempletes, dispatch]);

  useEffect(() => {
    if (statusPredeterminatedTexts === "loading") setIsLoading(true);
    else setIsLoading(false);
  }, [statusPredeterminatedTexts]);

  useEffect(() => {
    try {
      setIsLoading(true);
      setDataPredeterminatedTexts(listPredeterminatedTexts);
      setFilterDataPredeterminatedTexts(listPredeterminatedTexts);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [listPredeterminatedTexts]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Mis Bloques"} />
      </Row>

      <br />
      <Row className="mb-5">
        <p className="caption">Gestiona tus bloques</p>
      </Row>

      <Row className="justify-content-end">
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<BookmarksOutlinedIcon />}
            className="custom-input__button__primary-color"
            onClick={() => navigate("tags")}
          >
            <p className="button">Administrar Etiquetas</p>
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Row style={{ alignItems: "center" }}>
        <Col xs={5}>
          <CustomSearchFilter
            list={dataPredeterminatedTexts}
            setFilteredData={setFilterDataPredeterminatedTexts}
            placeholder="Buscar bloques"
          />
        </Col>
        <Col />
        <Col xs={"auto"}>
          <FilterTag
            classStyle="custom-input__button__secondary-color"
            tagsList={tagListPredeterminatedTexts}
            list={dataPredeterminatedTexts}
            setFilteredInfo={setFilterDataPredeterminatedTexts}
            handleTagsSelected={handleTagsSelected}
            currentTagSelected={tagsSelected}
          />
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            onClick={() => navigate("create")}
          >
            <p className="button">Crear bloque</p>
          </Button>
        </Col>
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right" }}>
        {tagsSelected?.map((tag) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row>
        <div className="custom-accordion__container">
          {filterDataPredeterminatedTexts?.map((predeterminatedText) => {
            return (
              <Accordion
                className="custom-accordion"
                key={predeterminatedText._id}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <p className="heading__primary-color">
                        {predeterminatedText?.name || ""}
                      </p>
                    </Col>
                    <Col md={"auto"}>
                      <p className="subheading">
                        Etiquetas:{" "}
                        {predeterminatedText?.labels?.map((tag) => {
                          return (
                            <span
                              key={tag?._id}
                              className="caption custom-badges__disabled"
                            >
                              {tag?.name}
                            </span>
                          );
                        })}
                      </p>
                    </Col>
                  </Row>
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    <span className="subheading">
                      Etiquetas:{" "}
                      {predeterminatedText?.labels?.length > 0 &&
                        predeterminatedText?.labels?.map((tag) => {
                          return (
                            <span
                              key={tag?._id}
                              className="caption custom-badges__disabled"
                            >
                              {tag?.name}
                            </span>
                          );
                        })}
                    </span>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <span
                      style={{ fontSize: 12 }}
                      className="justify-text clauses-number"
                    >
                      {ReactHtmlParser(predeterminatedText?.text || "")}
                    </span>
                  </Row>
                  <br />
                  {predeterminatedText?.company && (
                    <Row xs={"auto"} style={{ justifyContent: "right" }}>
                      <Col>
                        <Button
                          variant="contained"
                          startIcon={<VisibilityOutlinedIcon />}
                          className="custom-input__button__secondary-color"
                          style={{
                            border: "1px solid #00374F",
                          }}
                          onClick={async () => {
                            try {
                              setIsLoading(true);
                              await handlePreviewTemplate(predeterminatedText);
                            } catch (error) {
                              console.log(error);
                            } finally {
                              setIsLoading(false);
                            }
                          }}
                        >
                          <p className="button">Previsualizar</p>
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant="contained"
                          startIcon={<EditOutlinedIcon />}
                          className="custom-input__button__secondary-color"
                          style={{
                            border: "1px solid #00374F",
                          }}
                          onClick={() => {
                            navigate(`${predeterminatedText._id}`);
                          }}
                        >
                          <p className="button">Editar</p>
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant="contained"
                          startIcon={<DeleteOutlineOutlinedIcon />}
                          className="custom-input__button__secondary-color"
                          style={{
                            border: "1px solid #00374F",
                          }}
                          onClick={() =>
                            handleOpenModalDesicion(predeterminatedText._id)
                          }
                        >
                          <p className="button">Eliminar</p>
                        </Button>
                      </Col>
                    </Row>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Row>

      <br />

      <ModalInfo
        title={"Eliminar Bloque"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Eliminar Bloque"}
        message={"¿Está seguro que quiere eliminar el bloque?"}
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title={modalResponse.title}
        open={modalResponse.open}
        onClose={() =>
          setModalResponse({
            open: false,
            response: {},
            title: "",
          })
        }
        responseData={modalResponse.response}
      />
    </Container>
  );
};

export default ViewPredeterminatedTexts;
