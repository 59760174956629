export const changeNameParts = (textHtml, parts) => {

    if (!textHtml) return "";

    //Convertimos el string html a un Object Document
    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, 'text/html');

    //Validamos que exista la data para cambiar su valor
    for (let index = 0; index < 3; index++) {
        if (htmlElement?.querySelector(`[data-part-number="${index}"]`) && parts[index]) {
            const data = htmlElement?.querySelectorAll(`[data-part-number="${index}"]`)
            if (data?.length) {
                for (const item of data) {
                    item.textContent = `°${parts[index]}°`
                    item.setAttribute("data-part", `${parts[index]}`)
                }
            }
        }
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument.replace(/<\/?(html|head|body)>/gi, '');

    return cleanedString;
};