import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ButtonCreate from "../../../components/Datatable/buttonCreate";
import CustomSearchFilter from "../../../components/Search/CustomSearchFilter";
import LoadingContent from "../../../components/loadingContent";
import ModalDecision from "../../../components/Modals/modalDecision";
import ModalInfo from "../../../components/Modals/modalInfo";
import SectionTitle from "../../../components/sectionTitle";
import Breadcrum from "../../breadcrum";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { fetchFolderTags, deleteTagById } from "../../folders/folderTagSlice";
import { CODES } from "../../../utils/codes";
import { Mixpanel } from "../../../utils/mixPanel";

const ManageFoldersTags = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedTag, setDeletedTag] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  const [filteredTags, setFilteredTags] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (isDeleted) {
      return;
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const getTagsByCompanyAndResourceTypeService = await dispatch(
          fetchFolderTags()
        ).unwrap();
        const tags = getTagsByCompanyAndResourceTypeService;

        const format = tags.map((tag) => {
          return {
            name: tag.name,
            description: tag.description,
            edit: tag.company && (
              <Row xs={"auto"}>
                <Col>
                  <Button
                    variant="contained"
                    startIcon={<EditOutlinedIcon />}
                    className="custom-input__button__secondary-color"
                    style={{
                      border: "1px solid #00374F",
                    }}
                    onClick={() => {
                      navigate(`${tag._id}`);
                    }}
                  >
                    <p className="button">Editar</p>
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="contained"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    className="custom-input__button__secondary-color"
                    style={{
                      border: "1px solid #00374F",
                    }}
                    onClick={() => handleOpenModalDesicion(tag._id)}
                  >
                    <p className="button">Eliminar</p>
                  </Button>
                </Col>
              </Row>
            ),
          };
        });

        setFilteredTags(format);

        setData(format);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isDeleted]);

  const handleClose = () => {
    setIsDeleted(false);
    setIsOpenModal(false);
  };

  const handleCloseModalDesicion = async () => {
    setIsOpenModalDecision(false);
  };

  const handleOpenModalDesicion = async (id) => {
    setIsOpenModalDecision(true);
    setDeletedTag(id);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalDecision(false);
      setIsDeleted(true);
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      let obj = {
        id: deletedTag,
        resourceType: "BUSINESSFOLDER",
      };
      const deleteTagService = await dispatch(deleteTagById(obj)).unwrap();

      if (
        deleteTagService.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        deleteTagService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        Mixpanel.track("Btn eliminar", {
          homeButton: "Carpetas - Eliminar etiquetas",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          state: deleteTagService.status,
        });
      }

      setResponseData(deleteTagService);
      setIsLoading(false);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Container fluid style={{ backgroundColor: "#fff" }}>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Administrar etiquetas"} />
      </Row>
      <br />
      <br />
      <Row>
        <Container as={Col} fluid>
          <Row style={{ marginTop: "3rem" }}>
            <Col sm={5}>
              <CustomSearchFilter
                placeholder="Buscar por etiqueta"
                list={data}
                setFilteredData={setFilteredTags}
              />
            </Col>
            <Col sm={{ span: 2, offset: 5 }}>
              <ButtonCreate
                path={"create"}
                value={"Agregar etiqueta"}
                mixPanelTrack={"Carpetas - Administrar etiquetas"}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "1%" }}></Row>
          <Row
            style={{
              marginTop: "3rem",
              padding: "2rem",
            }}
            className="custom-accordion__container"
          >
            <div>
              {filteredTags.length > 0 ? (
                filteredTags.map((item) => {
                  return (
                    <div key={item._id ?? item.name}>
                      <Accordion className="custom-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Row style={{ width: "100%" }}>
                            <Col sm={6}>
                              <p className="heading__primary-color">
                                {item.name}
                              </p>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <div style={{ alignSelf: "center" }}>
                                {item.edit}
                              </div>
                            </Col>
                          </Row>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            width: "100%",
                            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <Typography>{item.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <Typography>No se han encontrado resultados</Typography>
              )}
            </div>
          </Row>
        </Container>
      </Row>
      <Row xs={"auto"} style={{ justifyContent: "end" }}>
        <Col>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => {
              navigate("/service/folders");
            }}
          >
            <p className="button">Volver</p>
          </Button>
        </Col>
      </Row>
      <ModalInfo
        title={"Eliminar Etiqueta"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Eliminar etiqueta"}
        message={"¿Está seguro que quiere eliminar la etiqueta?"}
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
    </Container>
  );
};

export default ManageFoldersTags;
