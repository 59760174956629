import React from "react";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const IconDiffParts = ({isDiff, containerRef}) => {
    if (isDiff) {
      return (<PopoverInfo
        containerRef={containerRef}
        section={{description: "Incompatible con el número de partes de la minuta"}} 
        icon={<InfoOutlinedIcon fontSize="large" color="warning" />}
      />)
    }
    return (<PopoverInfo 
      containerRef={containerRef}
      section={{description: "Compatible con el número de partes de la minuta"}} 
      icon={<CheckCircleOutlineIcon fontSize="large" color="success" />}
    />)
  }

export const countPartsClause = (parts) => {
    if (Array.isArray(parts)) return parts.length;

    let count = 0;

    if (parts?.partA) count += 1;
    if (parts?.partB) count += 1;
    if (parts?.partC) count += 1;
    if (parts?.partD) count += 1;

    return count;
}

export const isDiffNumberParts = (currentParts, newParts) => {
    const countParts = countPartsClause(newParts);
    return currentParts?.length < countParts;
}