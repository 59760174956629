import { Checkbox } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import { onSortHTMLElement } from "../../../../../../utils/findComponentHTML";

export const ChooseReviewRule = ({
  openRuleList,
  setOpenRuleList,
  ruleList,
  reviewRule,
  setReviewRule,
  setIsReviewRule,
}) => {
  /**
   * Tabla
   */
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Descripción
        </label>,
      ],
      field: "description",
    },
  ];
  const rows = [];

  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  const handleCheckBoxClick = (item, event) => {
    if (reviewRule) {
      setReviewRule(null);
      setIsReviewRule(false);
    } else {
      setReviewRule(item);
      setIsReviewRule(true);
    }
  };
  useEffect(() => {
    if (ruleList) {
      let filteredRows = ruleList.map((item) => {
        return {
          name: (
            <div style={{ width: "30rem", padding: "5px" }}>
              <Checkbox
                edge="start"
                disabled={reviewRule && reviewRule?._id !== item._id}
                checked={reviewRule?._id == item._id}
                tabIndex={-1}
                disableRipple
                onClick={(event) => handleCheckBoxClick(item, event)}
              />{" "}
              {item.name}
            </div>
          ),
          description: item.description,
        };
      });
      setFilteredDataTable({
        ...filteredDataTable,
        rows: filteredRows,
      });
    }
  }, [ruleList, reviewRule]);

  return (
    <Container fluid>
      <Row>
        <Breadcrum state={openRuleList} setState={setOpenRuleList} />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Reglas de revisión"} />
      </Row>

      <Row xs={"auto"} className="pt-3">
        <p className="body-content">
          Elige las reglas de revisión que podrán utilizarse en la revisión de
          otros documentos.
        </p>
      </Row>
      <Row>
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          entries={7}
          data={filteredDataTable}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "carpetas"]}
          fullPagination
          onSort={(value) => {
            onSortHTMLElement({
              sort: value,
              filteredDataTable,
              setFilteredDataTable,
            });
          }}
          noRecordsFoundLabel={"No se han encontrado resultados"}
        />
      </Row>
    </Container>
  );
};
