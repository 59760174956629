import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../utils/mixPanel";

const CardButton = (props) => {
  const {
    title,
    description,
    icon,
    navigationButton,
    navigationPath,
    extendedPath = undefined,
    disabled,
    mixPanelTrack,
  } = props;
  const navigate = useNavigate();
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  return (
    <Container
      fluid
      className={!disabled ? "cards" : "cards__disabled"}
      style={{ padding: "0 !important" }}
    >
      <Row style={{ height: "inherit" }}>
        <Col md={2} className="cards__icon-section">
          {icon}
        </Col>
        <Col className="cards__body-section">
          <Container fluid>
            <Row>
              <p className="display-large__primary-color">{title}</p>
            </Row>
            <br />
            <Row>
              <p className="body-content__justify">{description}</p>
            </Row>
            <br />
            <Row>
              <div
                data-testid="buttonNavigate-test"
                className="cards__nav-button"
                onClick={() => {
                  if (!disabled) {
                    if (mixPanelTrack) {
                      Mixpanel.track(mixPanelTrack, {
                        email: payloadToken?.email,
                        companyName: payloadToken?.companyName,
                      });
                    }
                    extendedPath
                      ? navigate(`${extendedPath}`)
                      : navigate(`/login/${navigationPath}`);
                  }
                }}
              >
                {navigationButton}
              </div>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default CardButton;
