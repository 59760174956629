import EditIcon from "@mui/icons-material/Edit";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  getCurrentDocument,
  setInitialInfoLibrary,
  setName,
  setSelectedClauses,
  setTitle,
} from "../../../../../../parts/document/currentDocumentSlice";
import { getDocById } from "../../../../../../services/documents/documentsRequest";
import { NUMBERINGWORDSO } from "../../../../../../utils/numberings";
import { signatoriesTypes } from "../../../../../../utils/typesSignatures";
const ViewFiles = () => {
  //Hooks
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const currentDocument = useSelector(getCurrentDocument);

  //State
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [typeView, setTypeView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Handle
  const handleRequestGetDocument = async () => {
    setIsLoading(true);
    const documentContent = await getDocById(documentId);

    const a = {
      ...documentContent.data.responseMessage.documentContent.parts.partA,
    };
    const b = {
      ...documentContent.data.responseMessage.documentContent.parts.partB,
    };

    let parts = [a, b];

    if (documentContent.data.responseMessage.documentContent.parts.partC) {
      const c = {
        ...documentContent.data.responseMessage.documentContent.parts.partC,
      };
      parts.push(c);
    }

    if (documentContent.data.responseMessage.documentContent.parts.partD) {
      const d = {
        ...documentContent.data.responseMessage.documentContent.parts.partD,
      };
      parts.push(d);
    }
    const finalObj = {
      title: documentContent.data.responseMessage.documentContent.title,
      parts: parts,
    };
    dispatch(setInitialInfoLibrary(finalObj));
    dispatch(
      setSelectedClauses({
        clauses:
          documentContent.data.responseMessage.documentContent.clauses.map(
            (cla) => {
              return cla.parts
                ? {
                    clause: {
                      ...cla,
                      parts: [
                        cla.parts.partA,
                        cla.parts.partB,
                        cla.parts.partC,
                        cla.parts.partD,
                      ],
                    },
                  }
                : { clause: cla };
            }
          ),
      })
    );
    dispatch(
      setName({
        name: documentContent.data.responseMessage.documentContent.name,
      })
    );
    setIsLoading(false);
  };
  const handleFilter = (text) => {
    const clausesFilter = text
      ? currentDocument.clauses.filter((cla) => cla.clause.title.includes(text))
      : currentDocument.clauses;
    setFilteredClauses(clausesFilter);
  };

  //UseEffect
  useEffect(() => {
    handleRequestGetDocument();
    setFilteredClauses(currentDocument.clauses);
  }, []);
  useEffect(() => {
    setFilteredClauses(currentDocument.clauses);
  }, [currentDocument]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Panel de revisión"} />
      </Row>
      <Row style={{ margin: "5% 0" }}>
        <Col md={6}>
          <InputGroup bsPrefix="input-group-container">
            <Form.Control
              bsPrefix="custom-input"
              placeholder="Buscar"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
            <InputGroup.Text bsPrefix="container-icon">
              <SearchIcon fontSize="large" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5} style={{ display: "flex", alignItems: "center" }}>
          {isEditTitle ? (
            <Form.Group
              style={{ padding: "0%", margin: "2% 0" }}
              onBlur={() => {
                setIsEditTitle(false);
              }}
            >
              <Form.Control
                placeholder={"Escriba aqui"}
                bsPrefix={"input-group-container__no-icon label"}
                value={currentDocument.title}
                onChange={(e) => {
                  dispatch(setTitle({ title: e.target.value }));
                }}
              />
            </Form.Group>
          ) : (
            <p className="heading">{currentDocument.title}</p>
          )}
          <IconButton
            onClick={() => {
              setIsEditTitle(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Col>
        <Col
          md={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <p className="caption">Tipo de enumeración:</p>
          <Form.Select
            className="label mb-1"
            size="lg"
            bsPrefix={"input-group-container__no-icon label"}
            defaultValue=""
            style={{ width: "50%" }}
          >
            <option className="label" value="" disabled>
              Enumeración
            </option>
            <option className="label" value="Natural">
              Por número
            </option>
            <option className="label" value="Juridica">
              Por letras
            </option>
          </Form.Select>
        </Col>
        <Col md={2}>
          <ButtonGroup variant="contained" size="large">
            <Button
              variant="text"
              className={
                !typeView
                  ? "custom-input__link"
                  : "custom-input__link selected-option"
              }
              onClick={() => {
                setTypeView(true);
              }}
            >
              <EventNoteIcon className="logo-primary-color" />
            </Button>
            <Button
              variant="text"
              className={
                typeView
                  ? "custom-input__link"
                  : "custom-input__link selected-option"
              }
              onClick={() => {
                setTypeView(false);
              }}
            >
              <SubjectOutlinedIcon className="logo-primary-color" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <div
        className="custom-accordion__container-document-folder"
        style={{ marginTop: "2%" }}
      >
        <Container style={{ width: "97%" }}>
          <Row className="heading__primary-color">Partes</Row>
          <Row>
            {currentDocument.parts?.map((part, index) => (
              <Container key={part._id} className="container_text">
                <Row>
                  <Col className="heading__primary-color">{`${index + 1}. ${
                    part.name || "Sin titulo"
                  }`}</Col>
                </Row>
                <Row className="container_text__subclause">
                  <ul>
                    {part.signatories.length > 0 ? (
                      part.signatories.map((sig, i) => (
                        <li key={uuidv4()} className="label">
                          {sig.type === "natural" && sig.gender === "M"
                            ? `Signatario ${i + 1} (${
                                signatoriesTypes.SignatariOPN.type
                              }), ${sig.text}`
                            : sig.type === "natural" && sig.gender === "F"
                            ? `Signatario ${i + 1} (${
                                signatoriesTypes.SignatariAPN.type
                              }), ${signatoriesTypes.SignatariAPN.text}`
                            : sig.type === "legal" && sig.gender === "M"
                            ? `Signatario ${i + 1} (${
                                signatoriesTypes.SignatariOPJ.type
                              }), ${signatoriesTypes.SignatariOPJ.text}`
                            : sig.type === "legal" && sig.gender === "F"
                            ? `Signatario ${i + 1} (${
                                signatoriesTypes.SignatariAPJ.type
                              }), ${signatoriesTypes.SignatariAPJ.text}`
                            : `Signatario ${i + 1} (${
                                signatoriesTypes.SignatariOPN.type
                              }), ${sig.text}`}
                        </li>
                      ))
                    ) : (
                      <li className="label">Sin signatarios</li>
                    )}
                  </ul>
                </Row>
                <Row className="container_text__subclause label">
                  {ReactHtmlParser(part.text || "Sin texto final")}
                </Row>
              </Container>
            ))}
          </Row>
          <Row className="heading__primary-color">Encabezado</Row>
          {currentDocument?.headers?.map((header, index) => (
            <Container key={header._id} className="container_text">
              <Row>
                <Col className="heading__primary-color">{`${index + 1}. ${
                  header.title || "Sin titulo"
                }`}</Col>
              </Row>
              <Row className="container_text__clause-text label">
                {ReactHtmlParser(header.text)}
              </Row>
              <Row className="container_text__clause-text">
                <ul>
                  {header?.childs?.map((child, index2) => (
                    <Container
                      key={child._id}
                      className="container_text__subclause"
                    >
                      <Row>
                        <Col className="heading__primary-color">{`${
                          index + 1
                        }.${index2 + 1}`}</Col>
                      </Row>
                      <Row className="container_text__clause-text label">
                        {ReactHtmlParser(child.text)}
                      </Row>
                    </Container>
                  ))}
                </ul>
              </Row>
            </Container>
          ))}
          <Row className="heading__primary-color">Cláusulas</Row>
          <Row>
            {filteredClauses?.map((cla, index) => (
              <Row key={cla._id}>
                <Container className="container_text">
                  <Row>
                    <Col md={4} className="heading__primary-color">{`${
                      index + 1
                    }. ${cla.clause.title || "Sin titulo"}`}</Col>
                  </Row>
                  <Row className="container_text__clause-text label">
                    {ReactHtmlParser(cla.clause.text)}
                  </Row>
                </Container>
                {cla.clause?.subclauses?.map((sub, index2) => (
                  <Container
                    key={sub._id}
                    className="container_text__subclause"
                  >
                    <Row>
                      <Col className="heading__primary-color">{`${index + 1}.${
                        index2 + 1
                      }. ${sub.subclause.title || "Sin titulo"}`}</Col>
                    </Row>
                    <Row className="container_text__clause-text label">
                      {ReactHtmlParser(sub.subclause.text)}
                    </Row>
                    {sub?.subclause?.childs?.map((child, index4) => (
                      <Container
                        key={child._id}
                        className="container_text__subclause"
                      >
                        <Row>
                          <Col className="heading__primary-color">{`${
                            index + 1
                          }.${index2 + 1}.${index4 + 1}. ${
                            child.title || "Sin titulo"
                          }`}</Col>
                        </Row>
                        <Row className="container_text__clause-text label">
                          {ReactHtmlParser(child.text)}
                        </Row>
                      </Container>
                    ))}
                  </Container>
                ))}
                {cla?.clause?.paragraphs?.map((para, index3) => (
                  <Container
                    key={para._id}
                    className="container_text__subclause"
                  >
                    <Row>
                      <Col className="heading__primary-color">{`Parágrafo ${
                        NUMBERINGWORDSO[index3 + 1]
                      } ${para.paragraph.title || "Sin titulo"}`}</Col>
                    </Row>
                    <Row className="container_text__clause-text label">
                      {ReactHtmlParser(para.paragraph.text)}
                    </Row>
                  </Container>
                ))}
              </Row>
            ))}
          </Row>
          <Row className="heading__primary-color">Firmas</Row>
          <Row>
            {currentDocument?.parts?.map((part, index) => (
              <Container key={part._id} className="container_text">
                <Row>
                  <Col className="heading__primary-color">{`${
                    index + 1
                  }. Firma ${part.name || "sin titulo"}`}</Col>
                </Row>
                <Row className="container_text__subclause">
                  <ul>
                    {part.signatories > 0 ? (
                      part.signatories.map((sig, i) =>
                        sig.signatures.attributes.map((attri, index) => (
                          <li key={attri.name} className="label">
                            {attri.name + " " + attri.value}
                          </li>
                        ))
                      )
                    ) : (
                      <li className="label">Sin firmas</li>
                    )}
                  </ul>
                </Row>
              </Container>
            ))}
          </Row>
        </Container>
        <Row className="justify-content-md-end">
          <Col md={2}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              size="large"
            >
              Descartar
            </Button>
          </Col>
          <Col md={3}>
            <Button
              variant="contained"
              startIcon={<FindInPageOutlinedIcon />}
              className="custom-input__button__primary-color"
              size="large"
            >
              Navegar entre versiones
            </Button>
          </Col>
          <Col md={3}>
            <Button
              variant="contained"
              startIcon={<FactCheckOutlinedIcon />}
              className="custom-input__button__primary-color"
              size="large"
            >
              Funcionalidades de IA
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ViewFiles;
