import { Paper } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CustomToolTip } from "../Tooltip/Tooltip";
const MetricCountIcon = ({ title, itemList, tooltip }) => {
  return (
    <Paper elevation={1} style={{ padding: "4%", height: "100%" }}>
      <Container fluid>
        {title && <Row className="display-small">{title}</Row>}
        <Paper
          elevation={title ? 3 : 0}
          style={title ? { padding: "4% 2%", margin: "2% 0" } : {}}
        >
          <Container fluid>
            <Row>
              {itemList?.map((item) => (
                <Col key={item.description}>
                  <Row>
                    <Col
                      xs={title ? 4 : 3}
                      className="custom-input__button__secondary-color__icon"
                    >
                      {item.icon}
                    </Col>
                    <Col xs>
                      <Container fluid>
                        <Row className="display-large">{item.count}</Row>
                        <Row className="caption">{item.description}</Row>
                      </Container>
                    </Col>
                  </Row>
                </Col>
              ))}
              {tooltip && (
                <Col xs={1}>
                  <CustomToolTip
                    direction="top"
                    message="Recuerda que los usuarios administradores puede entrar como usuarios de servicios, por eso, también se contabilizan aquí."
                  >
                    <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                  </CustomToolTip>
                </Col>
              )}
            </Row>
          </Container>
        </Paper>
      </Container>
    </Paper>
  );
};

export default MetricCountIcon;
