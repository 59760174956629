export const NUMBERINGWORDSO = [
  "",
  "primero",
  "segundo",
  "tercero",
  "cuarto",
  "quinto",
  "sexto",
  "séptimo",
  "octavo",
  "noveno",
  "décimo",
  "decimoprimero",
  "decimosegundo",
  "decimotercero",
  "decimocuarto",
  "decimoquinto",
  "decimosexto",
  "decimoséptimo",
  "decimoctavo",
  "decimonoveno",
  "vigésimo",
  "vigésimoprimero",
  "vigésimosegundo",
  "vigésimotercero",
  "vigésimocuarto",
  "vigésimoquinto",
  "vigésimosexto",
  "vigésimoséptimo",
  "vigésimooctavo",
  "vigésimonoveno",
  "trigésimo",
  "trigésimoprimero",
  "trigésimosegundo",
  "trigésimotercero",
  "trigésimocuarto",
  "trigésimoquinto",
  "trigésimosexto",
  "trigésimoséptimo",
  "trigésimooctavo",
  "trigésimonoveno",
  "cuadragésimo",
  "cuadragésimoprimero",
  "cuadragésimosegundo",
  "cuadragésimotercero",
  "cuadragésimocuarto",
  "cuadragésimoquinto",
  "cuadragésimosexto",
  "cuadragésimoséptimo",
  "cuadragésimooctavo",
  "cuadragésimonoveno",
  "quincuagésimo",
  "quincuagésimoprimero",
  "quincuagésimosegundo",
  "quincuagésimotercero",
  "quincuagésimocuarto",
  "quincuagésimoquinto",
  "quincuagésimosexto",
  "quincuagésimoséptimo",
  "quincuagésimooctavo",
  "quincuagésimonoveno",
  "sexagésimo",
  "sexagésimoprimero",
  "sexagésimosegundo",
  "sexagésimotercero",
  "sexagésimocuarto",
  "sexagésimoquinto",
  "sexagésimosexto",
  "sexagésimoséptimo",
  "sexagésimooctavo",
  "sexagésimonoveno",
  "septuagésimo",
  "septuagésimoprimero",
  "septuagésimosegundo",
  "septuagésimotercero",
  "septuagésimocuarto",
  "septuagésimoquinto",
  "septuagésimosexto",
  "septuagésimoséptimo",
];

export const NUMBERINGWORDSA = [
  "",
  "primera",
  "segunda",
  "tercera",
  "cuarta",
  "quinta",
  "sexta",
  "séptima",
  "octava",
  "novena",
  "décima",
  "undécima",
  "duodécima",
  "decimotercera",
  "decimocuarta",
  "decimoquinta",
  "decimosexta",
  "decimoséptima",
  "decimoctava",
  "decimonovena",
  "vigésima",
  "vigésimaprimera",
  "vigésimasegunda",
  "vigésimatercera",
  "vigésimacuarta",
  "vigésimaquinta",
  "vigésimasexta",
  "vigésimaséptima",
  "vigésimaoctava",
  "vigésimanovena",
  "trigésima",
  "trigésimaprimera",
  "trigésimasegunda",
  "trigésimatercera",
  "trigésimacuarta",
  "trigésimaquinta",
  "trigésimasexta",
  "trigésimaséptima",
  "trigésimaoctava",
  "trigésimanovena",
  "cuadragésima",
  "cuadragésimaprimera",
  "cuadragésimasegunda",
  "cuadragésimatercera",
  "cuadragésimacuarta",
  "cuadragésimaquinta",
  "cuadragésimasexta",
  "cuadragésimaséptima",
  "cuadragésimaoctava",
  "cuadragésimanovena",
  "quincuagésima",
  "quincuagésimaprimera",
  "quincuagésimasegunda",
  "quincuagésimatercera",
  "quincuagésimacuarta",
  "quincuagésimaquinta",
  "quincuagésimasexta",
  "quincuagésimaséptima",
  "quincuagésimaoctava",
  "quincuagésimanovena",
  "sexagésima",
  "sexagésimaprimera",
  "sexagésimasegunda",
  "sexagésimatercera",
  "sexagésimacuarta",
  "sexagésimaquinta",
  "sexagésimasexta",
  "sexagésimaséptima",
  "sexagésimaoctava",
  "sexagésimanovena",
  "septuagésima",
  "septuagésimaprimera",
  "septuagésimasegunda",
  "septuagésimatercera",
  "septuagésimacuarta",
  "septuagésimaquinta",
  "septuagésimasexta",
  "septuagésimaséptima",
  "septuagésimaoctava",
];
