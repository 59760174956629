import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export const getRequestSummary = ({ userId, companyId, corporateUnitId }) => {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getRequestsSummaryAttorney`,
    params: {
      companyId,
      corporateUnitId,
      userId
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getAlertsSummary = ({ userId, companyId, corporateUnitId }) => {
  return axios({
    method: "GET",
    url: `alerts/getAlertsSummary`,
    params: {
      companyId,
      corporateUnitId,
      userId
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getFoldersSummary = ({ corporateUnit }) => {
  return axios({
    method: "GET",
    url: `/businessFolder/getLastFoldersAccessed`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      corporateUnit,
    },
    validateStatus: null,
    headers: { Authorization: GetToken() },
  });
};
