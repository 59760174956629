import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

const ErrorBoundary = () => (<div
    style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
    <img
        width="30%"
        src={require("../assets/svg/ErrorBoundary.svg").default}
        alt="logo-error-text"
    />
    <img
        width="30%"
        src={require("../assets/svg/ErrorBoundaryText.svg").default}
        alt="logo-error"
    />
    <br />
    <Row xs={"auto"}>
        <u  style={{
            fontSize: "2rem",
            cursor: "pointer"
        }}
        className="custom-input__button__link"
        onClick={() => {
            window.history.back();
            window.location.reload();
        }}>Recargar</u>
    </Row>
</div>)

export default ErrorBoundary;
