import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../utils/codes";
import { Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../unitCorporativeSlice";
import { useDispatch, useSelector } from "react-redux";
import { ModalListSelectCheck } from "../../../../../../components/Modals/modalListCheck";
import ModalInpuText from "../../../../../../components/Modals/modalInpuText";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import LoadingContent from "../../../../../../components/loadingContent";
import {
  createHeading,
  uploadDocumentLetterhead,
} from "../../../../../../services/admin/documents/headingsServices";
import { env } from "../../../../../../env";

export default function CreateHeadingsModule() {
  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const statusUnitsCorporativesList = useSelector(getUnitsCorporativesStatus);
  const unitsCorporativesList = useSelector(getUnitsCorporativesList);

  const [openModalNoSave, setOpenModalNoSave] = useState(false);
  const [openModalSelectCorporateUnits, setOpenModalSelectCorporateUnits] =
    useState(false);
  const [openModalNameHeading, setOpenModalNameHeading] = useState(false);
  const [corporateUnitsList, setCorporateUnitsList] = useState([]);
  const [currentSelectCorporateUnits, setCurrentSelectCorporateUnits] =
    useState([]);
  const [nameHeading, setNameHeading] = useState("");
  const [responseData, setResponseData] = useState({});
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConvert, setIsLoadingConvert] = useState(false);

  const handleSelectedCorporateUnits = (list) => {
    if (!list.length) return;
    setOpenModalSelectCorporateUnits(false);
    handleCreateHeading({ corporateUnits: list, name: nameHeading });
    setCurrentSelectCorporateUnits(list);
  };

  const handleCreateHeading = async ({ corporateUnits, name }) => {
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      const responseUploadDocument = await uploadDocumentLetterhead({
        file: state?.document,
        companyId,
        nameDocument: state?.document?.name,
        versionFrom: "OTHER",
        letterheadResourceType: "LETTERHEAD",
      });
      if (
        (responseUploadDocument.status !== CODES.COD_RESPONSE_HTTP_CREATED ||
          responseUploadDocument.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_CREATE) &&
        !responseUploadDocument.data.success
      ) {
        setResponseData({
          ...responseUploadDocument,
          status: 400,
        });
        setIsOpenModalCreate(true);
        return;
      }

      const responseData = await createHeading({
        name,
        applicableCorporateUnitIds: corporateUnits.map((uc) => uc._id),
        companyId,
        documentPdfId:
          responseUploadDocument.data.responseMessage.data._doc._id,
        ...(responseUploadDocument.data.responseMessage.data?.docxFile?._doc
          ?._id && {
          documentDocxId:
            responseUploadDocument.data.responseMessage.data?.docxFile?._doc
              ?._id,
        }),
      });
      setResponseData(responseData);
      setIsOpenModalCreate(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveNameHeading = (name) => {
    setNameHeading(name);
    setOpenModalNameHeading(false);
    if (unitsCorporativesList.length > 1) {
      setOpenModalSelectCorporateUnits(true);
    } else {
      handleCreateHeading({ corporateUnits: unitsCorporativesList, name });
    }
  };

  const handleCloseModalCreate = () => {
    if (responseData.status === CODES.COD_RESPONSE_HTTP_CREATED) {
      navigate(-1);
    } else {
      setIsOpenModalCreate(false);
    }
  };

  const getDocument = async (file) => {
    try {
      setIsLoadingConvert(true);
      const documentPdf = await convertToPdfService(file);
      if ( documentPdf.status === CODES.COD_RESPONSE_HTTP_OK ) {
        return documentPdf.data.responseMessage.data.buffer.data;
      }
      return null;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingConvert(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!state?.document) navigate(-1);
      if (state?.document) {
        let newUrl = null;
        let document = state.document;

        if (state.document.type !== "application/pdf") {
          const fileBuffer = await getDocument(state.document);

          document = new Uint8Array(fileBuffer).buffer;
          handleExtractFirstPage(
            new Blob([document], { type: "application/pdf" })
          );
        } else {
          handleExtractFirstPage(document);
        }

        const newBlob = new Blob([document], { type: "application/pdf" });
        newUrl = window.URL.createObjectURL(newBlob);
        setDocumentData(newUrl);
      }
    })();
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusUnitsCorporativesList === "fetch") {
          dispatch(fetchUnitsCoporativesList());
        }
      } catch (error) {
        console.log("========Fetch Units Corporatives Error========");
        console.log(error);
        console.log("========Finish Fetch Units Corporatives Error========");
      }
    };
    fetchData();
  }, [statusUnitsCorporativesList, dispatch]);

  useEffect(() => {
    if (statusUnitsCorporativesList === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusUnitsCorporativesList]);

  useEffect(() => {
    setCorporateUnitsList(unitsCorporativesList);
  }, [unitsCorporativesList]);

  const handleExtractFirstPage = async (doc) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = async () => {
        const arrayBuffer = fileReader.result;
        const pdfjs = await import("pdfjs-dist");

        const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
        const page = await pdf.getPage(1);

        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;

        // Convert the first page to an image (base64 format)
        // const image = canvas.toDataURL("image/jpeg", 1.0);
      };

      fileReader.readAsArrayBuffer(doc);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {(isLoading || isLoadingConvert) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Personalización de membrete"} />
        </Col>
      </Row>
      <br />
      <Row>
        <label className="caption">
          Elige un nombre para el membrete y define su ubicación en la página.
        </label>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <label className="caption custom-badges__disabled">
            {state?.document?.name || ""}
          </label>
        </Col>
      </Row>
      <br />

      <Row className="custom-accordion__container">
        {documentData && (
          <Worker workerUrl={WORKER_URL}>
            <Viewer fileUrl={documentData} />
          </Worker>
        )}
      </Row>

      <br />
      <Row style={{ rowGap: 20, justifyContent: "end" }}>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            onClick={() => setOpenModalNoSave(true)}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__primary-color"
            endIcon={<SaveOutlinedIcon fontSize="large" />}
            onClick={() => setOpenModalNameHeading(true)}
          >
            Guardar
          </Button>
        </Col>
      </Row>
      <ModalInpuText
        title={"Guardar"}
        message={"¿Qué nombre quieres ponerle a tu membrete?"}
        placeholder={"Escriba aquí..."}
        open={openModalNameHeading}
        onClose={() => setOpenModalNameHeading(false)}
        agreeText={"Guardar"}
        disagreeText="Cancelar"
        handleAgree={handleSaveNameHeading}
        handleDisagree={() => setOpenModalNameHeading(false)}
        validation={{
          required: {
            value: true,
            message: "*Este campo es obligatorio",
          },
          maxLength: {
            value: 30,
            message: "*Máximo 30 caracteres permitidos",
          },
        }}
        inputType="input"
      />
      <ModalDecision
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => navigate(-1)}
        handleDisagree={() => setOpenModalNoSave(false)}
        message={
          "¿Seguro quieres cancelar la personalización de membrete? Si aceptas la información no será guardada."
        }
        onClose={() => setOpenModalNoSave(false)}
        open={openModalNoSave}
        title={"Advertencia de no guardado"}
      />
      <ModalListSelectCheck
        title={"Aplicar membrete a unidades corporativas"}
        list={corporateUnitsList}
        open={openModalSelectCorporateUnits}
        handleAgree={handleSelectedCorporateUnits}
        onClose={() => setOpenModalSelectCorporateUnits(false)}
        currentDataSelected={currentSelectCorporateUnits}
        description={
          "A que unidades corporativas quieres aplicar este membrete"
        }
      />
      <ModalInfo
        title={`${
          responseData.status === CODES.COD_RESPONSE_HTTP_CREATED
            ? "Guardado exitoso"
            : "Error guardando membrete"
        }`}
        open={isOpenModalCreate}
        confirmationText="Aceptar"
        onClose={handleCloseModalCreate}
        handleCloseDefault={handleCloseModalCreate}
        responseData={responseData}
        buttonClose={true}
      />
    </Container>
  );
}
