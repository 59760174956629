import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { LoadingContentIA } from "../../../../../../components/loadingContent";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import { CODES } from "../../../../../../utils/codes";
import {
  fetchClausesFromDocument,
  setClearClauseBulk,
} from "./clauseBulkSlice";
import axios from "axios";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { Buffer } from "buffer";

const CreateClauseBulk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = searchParams.get("from");

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalUploadDoc, setIsOpenModalUploadDoc] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isOpenModalDocumentEmpty, setIsOpenModalDocumentEmpty] =
    useState(false);
  const [responseData, setResponseData] = useState("");
  const [progress, setProgress] = useState(0);
  const [openFailedSegModal, setOpenFailedSegModal] = useState(false);

  useEffect(() => {
    if (!["clauses", "contractTypes"].includes(params)) navigate("/");
    dispatch(setClearClauseBulk());
  }, []);

  const schema = yup.object().shape({
    parts: yup.array().of(
      yup.object().shape({
        title: yup.string().required("*Este campo es obligatorio"),
      })
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parts: [{ title: "" }, { title: "" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: partsFields,
    append: partsAppend,
    remove: partsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parts", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const handleDeletePart = (idx) => {
    partsRemove(idx);
  };

  const handleCloseModalDocumentEmpty = () => {
    setIsOpenModalDocumentEmpty(false);
  };

  const onUploadProgress = (progressEvent) => {
    const percentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentage === 100) {
      setProgress(60);
    }
  };

  const progressFuntion = (prevProgress) => {
    if (prevProgress >= 90 && prevProgress < 99) {
      return prevProgress + 0.1;
    }
    if (prevProgress >= 99) {
      return 99;
    }
    if (prevProgress === 100) {
      setIsLoading(false);
      return 0;
    }
    const newProgress = prevProgress + 5;
    return newProgress <= 100 ? newProgress : 100;
  };

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        // Actualizar el progreso de manera incremental
        setProgress(progressFuntion);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isLoading]);

  const handleDestructureDocument = async (data) => {
    try {
      setIsLoading(true);

      if (!documentData.name) {
        setIsOpenModalDocumentEmpty(true);
        setIsLoading(false);
        return;
      }
      const parts = {
        partA: data.parts[0].title,
        partB: data.parts[1].title,
        partC: data.parts[2]?.title || null,
        partD: data.parts[3]?.title || null,
      };
      const payloadToken = JSON.parse(localStorage.payloadToken);

      let dataFile = documentData;
      if (documentData?.type !== "application/pdf") {
        const convertDoc = await convertToPdfService(documentData, "file");
        const docView = convertDoc.data.responseMessage.data.buffer.data;
        const bf = Buffer.from(docView);
        const name = documentData?.name.substring(0, documentData?.name.lastIndexOf("."));
        dataFile = new File([bf], name + ".pdf", {
          type: "application/pdf",
        });
      }

      const responseDispatch = await dispatch(
        fetchClausesFromDocument({
          parts,
          documentData: dataFile,
          payloadToken,
          onUploadProgress,
        })
      ).unwrap();

      if (
        [CODES.COD_RESPONSE_HTTP_OK, CODES.COD_RESPONSE_HTTP_CREATED].includes(
          responseDispatch.status
        )
      ) {
        navigate("result");
      } else {
        setResponseData(responseDispatch);
        setIsOpenModal(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setResponseData({
          status: 500,
          data: {
            responseMessage: "El proceso esta tomando más tiempo de lo normal, por favor intentelo más tarde."
          }
        });
        setOpenFailedSegModal(true);
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && (
        <LoadingContentIA
          progress={progress}
          actions={{
            disagreeText: () => {},
            agreeText: () => {},
          }}
        />
      )}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={`Crear ${
            params === "clauses" ? "cláusula" : "minuta"
          } desde un contrato`}
        />
      </Row>
      <br />
      <br />
      <Row>
        <Row className="mb-3">
          <label className="heading">
            {`Cargue el contrato en donde están las ${
              params === "clauses" ? "cláusulas" : "minutas"
            }`}
          </label>
        </Row>
        <Row className="mb-4">
          <label className="body-content__disabled">
            Las imágenes del documento cargado no serán tenidas en cuenta por el
            sistema*
          </label>
        </Row>
        <Row xs={"auto"} className="mb-5">
          <Col>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => setIsOpenModalUploadDoc(true)}
            >
              Cargar documento
            </Button>
          </Col>
          {documentData.name && (
            <Col xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
              <span className="caption custom-badges__disabled">
                {`${documentData.name} `}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => {
                    setDocumentData({});
                  }}
                />
              </span>
            </Col>
          )}
        </Row>
        <Row>
          <label className="heading">
            Incorporar designación de las partes del contrato
            <PopoverInfo
              section={{
                description:
                  "Escribe la denominación de las partes sin artículo porque el sistema podrá detectarlos.",
              }}
            />
          </label>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit(handleDestructureDocument)}>
            <Form.Group className="mb-3">
              {partsFields.map((item, idx) => {
                return (
                  <Row key={item?.id}>
                    <Col xs={3}>
                      <Form.Control
                        maxLength={50}
                        disabled={!documentData.name}
                        {...register(`parts.${idx}.title`)}
                        placeholder="Ej. Contratista, Contratante, etc."
                        bsPrefix={
                          errors?.parts?.[idx]?.title
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                    </Col>
                    {idx > 1 && (
                      <Col xs={1}>
                        <Button
                          variant="contained"
                          className="custom-input__button__primary-color"
                          onClick={() => handleDeletePart(idx)}
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </Button>
                      </Col>
                    )}
                    <div className="caption custom-input__error  mb-4">
                      {errors?.parts?.[idx]?.title?.message}
                    </div>
                  </Row>
                );
              })}
              {partsFields.length < 4 && (
                <Row xs={"auto"} style={{ justifyContent: "left" }}>
                  <Col>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        partsAppend({
                          title: "",
                        });
                      }}
                    >
                      Parte
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.Group>
            <br />
            <br />
            <Form.Group as={Row}>
              <Col xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<ArticleOutlinedIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                >
                  {`Obtener ${params === "clauses" ? "cláusulas" : "minuta"}`}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Row>

        <ModalUploadDocs
          message={
            "El archivo que estas a punto de subir debe estar en formato PDF o Word."
          }
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={() => {
            setIsOpenModalUploadDoc(false);
          }}
          onClose={() => {
            setIsOpenModalUploadDoc(false);
          }}
          open={isOpenModalUploadDoc}
          documentData={documentData}
          setDocumentData={setDocumentData}
        />

        <ModalWarningForm
          title={"Advertencia"}
          description={
            "No se ha asociado ningún documento a destructurar. Por favor, cargue un documento para poder continuar."
          }
          open={isOpenModalDocumentEmpty}
          onClose={handleCloseModalDocumentEmpty}
        />

        <ModalInfo
          title={"Creación de cláusula"}
          responseData={responseData}
          open={isOpenModal}
          onClose={handleClose}
        />

        <ModalInfo
          title={"Ha ocurrido un error procesando el nuevo documento"}
          responseData={responseData}
          open={openFailedSegModal}
          onClose={() => {
            setOpenFailedSegModal(false);
          }}
        />
      </Row>
    </Container>
  );
};

export default CreateClauseBulk;
