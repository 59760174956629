import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  mockMinuta,
  mockSignatories,
  statusList,
  typeAssociate,
  typePrincipal,
  elementStatus,
  subTypesList
} from "../../utils/statusExternalRequest";
import { getApplicants } from "../../services/general/requestServices";

const FilterBar = ({
  openDrawer,
  setOpenDrawer,
  // listOptions,
  setOptionsFilterBar,
  folders = false,
}) => {
  const [listOptions, setListOptions] = useState([]);

  const handleFilter = (option, value, checked) => {
    setListOptions((listOptions) => {
      const newList = [...listOptions];
      const states = newList.map((list) => {
        if (list.title === option.title) {
          return {
            ...list,
            filters: list.filters.map((filter) => {
              if (filter.value === value) {
                return { ...filter, checked: checked };
              } else {
                return { ...filter };
              }
            }),
            expanded: true,
          };
        } else {
          return list;
        }
      });
      return states;
    });
  };

  const requestAttorneys = async () => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    const obj = {
      company: payloadToken.company,
    };
    const listAttorneys = await getApplicants(obj);

    setListOptions([
      {
        title: "Estado de la solicitud",
        filters: statusList.map((status) => ({
          label: status.Status,
          value: status.Status,
          checked: false,
        })),
      },
      {
        title: "Documento requerido",
        filters: subTypesList.map((subtype) => ({
          label: subtype,
          value: subtype,
          checked: false,
        })),
      },
      {
        title: "Solicitante",
        filters: listAttorneys.data.responseMessage.map((attorney) => ({
          label: `${attorney.firstName} ${attorney.secondName} ${attorney.firstSurname} ${attorney.secondSurname}`,
          value: attorney._id,
          checked: false,
        })),
      },
    ]);
  };

  const prepareForFolders = async () => {
    //PETICION DE SIGNATARIOS

    setListOptions([
      {
        title: "Estado",
        filters: elementStatus.map((status) => ({
          label: status,
          value: status,
          checked: false,
        })),
      },
      {
        title: "Signatario",
        filters: mockSignatories.map((sig) => ({
          label: sig,
          value: sig,
          checked: false,
        })),
      },
      {
        title: "Minuta",
        filters: mockMinuta.map((minuta) => ({
          label: minuta,
          value: minuta,
          checked: false,
        })),
      },
      {
        title: "Tipo de Doc. Principal",
        filters: typePrincipal.map((prin) => ({
          label: prin,
          value: prin,
          checked: false,
        })),
      },
      {
        title: "Tipo de Doc. Asociado",
        filters: typeAssociate.map((assos) => ({
          label: assos,
          value: assos,
          checked: false,
        })),
      },
    ]);
  };

  useEffect(() => {
    if (!folders) {
      requestAttorneys();
    } else {
      prepareForFolders();
    }
  }, []);
  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <Container fluid style={{ width: "25rem", height: "inherit" }}>
          <Row
            style={{
              height: "10%",
              alignContent: "center",
            }}
          >
            <Col md={10} className="heading">
              Filtrar
            </Col>
            <Col md={1}>
              <CloseIcon
                onClick={() => {
                  setOpenDrawer(false);
                }}
              />
            </Col>
          </Row>
          <Row
            style={{
              height: "3%",
              alignContent: "center",
            }}
          ></Row>
{listOptions.map((option) => (
            <Row
              key={option._id||option.title}
              style={{
                alignContent: "center",
              }}       
            >
              <Accordion defaultExpanded={option?.expanded}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="subheading">{option.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {option.filters.map((filter) => (
                    <Form.Group
                      className="mb-1"
                      controlId={filter.label}
                      key={filter.label}
                    >
                      <Form.Check
                        type="checkbox"
                        label={filter.label}
                        checked={filter.checked}
                        onChange={(e) => {
                          handleFilter(option, filter.value, e.target.checked);
                        }}
                      />
                    </Form.Group>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Row>
          ))}
          <Row
            style={{
              height: "55%",
            }}
          ></Row>
          <Row>
            <Col md={7}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setOptionsFilterBar([]);
                  setOpenDrawer(false);
                }}
              >
                Limpiar filtro
              </Button>
            </Col>
            <Col md={5}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => {
                  setOptionsFilterBar(listOptions);
                  setOpenDrawer(false);
                }}
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </Container>
      </Drawer>
    </>
  );
};

export default FilterBar;
