import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import ClearIcon from "@mui/icons-material/Clear";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  setSelectedClauses,
  setSelectedSubClauses,
} from "../../../../../../parts/minutas/clausesSelectedSlice";
import { useDispatch, useSelector } from "react-redux";
import { setMinuteSelected } from "../../../../../../parts/document/currentDocumentSlice.js";
import {
  deleteContractTypes,
  fetchContractTypes,
  getContractsTypesList,
  getStatus,
  setCurrentContractType,
  setStatusFetchContractTypes,
} from "./contractTypesSlice.js";
import {
  fetchTagsContractTypes,
  getTagContractTypesList,
  getTagContractTypesStatus,
} from "../../../../../../parts/manageTags/contractTypes/tagContractTypesSlice";
import { CODES } from "../../../../../../utils/codes";
import DetailsBar from "../../../../../../components/DetailsBar/DetailsBar";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ModalInpuText from "../../../../../../components/Modals/modalInpuText";
import {
  getContractsTypesByCompany,
  updatePublishStatusContractType,
} from "../../../../../../services/contractTypes/contractTypesService";
import { Mixpanel } from "../../../../../../utils/mixPanel";

const ContractTypes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusTagsContractTypes = useSelector(getTagContractTypesStatus);
  const tagContractTypesList = useSelector(getTagContractTypesList);
  const status = useSelector(getStatus);
  const contractTypes = useSelector(getContractsTypesList);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deletedTag, setDeletedTag] = useState("");
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [selectedElement, setSelectedElement] = useState({});
  const [responseData, setResponseData] = useState({ title: "", data: "" });
  const [isOpenModalDecisionDelete, setIsOpenModalDecisionDelete] =
    useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);
  const columns = [
    {
      field: "icon",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Minuta
        </label>,
      ],
      field: "contractType",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          A favor de
        </label>,
      ],
      field: "inFor",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Etiquetas
        </label>,
      ],
      field: "labels",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const rows = [];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const isViewContractTypePublished = false;
  const [isPublishedContractTypeModal, setIsPublishedContractTypeModal] =
    useState(false);
  const [anchorSelectedMenuInfo, setAnchorSelectedMenuInfo] = useState(null);
  const openSelectedMenuInfo = Boolean(anchorSelectedMenuInfo);
  const [anchorSelectedSubMenuInfo, setAnchorSelectedSubMenuInfo] =
    useState(null);
  const openSelectedSubMenuInfo = Boolean(anchorSelectedSubMenuInfo);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreateMenu = Boolean(anchorElCreate);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const buildRows = (rows) => {
    if (typeof rows !== "string") {
      const returnRows = rows.map((row, index) => {
        return {
          _id: row._id,
          icon: (
            <Row style={{ justifyContent: "start", alignItems: "center" }}>
              <Col xs={"auto"}>
                <BusinessCenterIcon color="#00374F" fontSize="large" />
              </Col>
            </Row>
          ),
          contractType: row.name,
          inFor: row.inFavor,
          labels: row.labels.map((label) => label.name).join(", "),
          actions: (
            <Row xs={"auto"}>
              {row.company && row.corporateUnit && (
                <>
                  <Col>
                    <IconButton
                      aria-label="edit"
                      className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                      sx={{ borderRadius: "8px" }}
                      onClick={() => {
                        navigate(`edit/${row._id}`);
                        Mixpanel.track("Biblioteca Editar minuta", {
                          email: payloadToken?.email,
                          companyName: payloadToken?.companyName,
                        });
                      }}
                    >
                      <EditOutlinedIcon fontSize="large" />
                    </IconButton>
                  </Col>
                  <Col>
                    <IconButton
                      aria-label="edit"
                      className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                      sx={{ borderRadius: "8px" }}
                      onClick={() => {
                        Mixpanel.track("Biblioteca eliminar minuta", {
                          email: payloadToken?.email,
                          companyName: payloadToken?.companyName,
                        });
                        handleOpenModalDesicionDelete(row._id);
                      }}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="large" />
                    </IconButton>
                  </Col>
                </>
              )}
              <Col>
                <IconButton
                  className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                  sx={{ borderRadius: "8px" }}
                  onClick={(e) => {
                    setSelectedElement(row);
                    handleClickMenuInfo(e);
                  }}
                >
                  <MoreHorizIcon fontSize="large" />
                </IconButton>
              </Col>
              <Col>
                <Button
                  style={{ marginTop: "0.1rem" }}
                  endIcon={<BusinessCenterIcon fontSize="large" />}
                  variant="contained"
                  className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                  onClick={() => {
                    handleUseMinuta(row);
                    Mixpanel.track("Biblioteca Usar minuta", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Usar minuta
                </Button>
              </Col>
            </Row>
          ),
        };
      });
      return returnRows;
    }
    return [];
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleUseMinuta = (selectedMinuta) => {
    const newClauses = selectedMinuta.clauses
      .filter((cla) => cla?.clause)
      .map((clause) => {
        let arrayParts = [];
        const parts = clause.clause.parts;
        if (parts) {
          if (parts.partA) {
            arrayParts.push(parts.partA);
          }
          if (parts.partB) {
            arrayParts.push(parts.partB);
          }
          if (parts.partC) {
            arrayParts.push(parts.partC);
          }
          if (parts.partD) {
            arrayParts.push(parts.partD);
          }
        }
        return { clause: { ...clause.clause, parts: arrayParts } };
      });

    dispatch(setMinuteSelected({ ...selectedMinuta, clauses: newClauses }));
    navigate("/service/create/from-minuta/contract-info");
  };

  const handleOpenModalDesicionDelete = (id) => {
    setIsOpenModalDecisionDelete(true);
    setDeletedTag(id);
  };

  const handleCloseModalDesicionDelete = () => {
    setIsOpenModalDecisionDelete(false);
    setDeletedTag("");
  };
  const handleDisagreeModalDesicionDelete = () => {
    handleCloseModalDesicionDelete();
  };
  const handleAgreeModalDesicionDelete = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalDecisionDelete(false);
      const deleteContractType = await dispatch(
        deleteContractTypes(deletedTag)
      ).unwrap();
      if (deleteContractType.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(deleteContractType);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setIsOpenModal(true);
      setResponseData({ data: deleteContractType, title: "Eliminar minuta" });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredTags = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredTags.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredTags });
    } else {
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };
  const handleClickAnchorCreate = (event) => {
    setAnchorElCreate(event.currentTarget);
  };
  const handleCloseAnchorCreate = () => {
    setAnchorElCreate(null);
  };
  const handleClickMenuInfo = (event) => {
    setAnchorSelectedMenuInfo(event.currentTarget);
  };
  const handleCloseSelectedMenuInfo = () => {
    setAnchorSelectedMenuInfo(null);
  };
  const handleClickSubMenuInfo = (event) => {
    setAnchorSelectedSubMenuInfo(event.currentTarget);
  };
  const handleCloseSelectedSubMenuInfo = () => {
    setAnchorSelectedSubMenuInfo(null);
    setAnchorSelectedMenuInfo(null);
  };
  const handlePerMenuContractType = (selec, event) => {
    switch (selec) {
      case 0:
        handleCloseSelectedMenuInfo();
        setOpenDetailsDrawner(true);
        break;
      case 1:
        if (
          selectedElement?.associatedCorporateUnits?.length === 1 ||
          selectedElement?.publishData?.isPublish
        ) {
          handleClickSubMenuInfo(event);
        } else {
          handleCloseSelectedMenuInfo();
          setIsPublishedContractTypeModal(true);
        }
        break;
      default:
        break;
    }
  };
  const handlePerSubMenuContractType = (selec) => {
    switch (selec) {
      case 0:
        handleCloseSelectedMenuInfo();
        handleCloseSelectedSubMenuInfo();
        setIsPublishedContractTypeModal(true);
        break;
      case 1:
        handlePublishContractType({ description: "", isPublish: false });
        handleCloseSelectedMenuInfo();
        handleCloseSelectedSubMenuInfo();
        break;
      default:
        break;
    }
  };
  const handlePublishContractType = async ({
    description,
    isPublish = true,
  }) => {
    try {
      setIsLoading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const company = localStorage.getItem("company");
      const isDefaultContractType = !(
        selectedElement.company && selectedElement.corporateUnit
      );
      const associatedCorporateUnit = isDefaultContractType
        ? {
            company: company,
            corporateUnit: corporateUnit,
          }
        : undefined;
      const obj = {
        company,
        corporateUnit,
        id: selectedElement._id,
        isPublish,
        description,
        associatedCorporateUnit,
      };
      const publishContractTypeRequest = await updatePublishStatusContractType(
        obj
      );
      if (
        publishContractTypeRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        publishContractTypeRequest.data.success
      ) {
        setResponseData({
          title: "Publicar a solicitantes",
          data: publishContractTypeRequest,
        });
      } else {
        setResponseData({
          title: "Ocurrió un error",
          data: publishContractTypeRequest,
        });
      }
      dispatch(setStatusFetchContractTypes({ status: "fetch" }));
      setIsLoading(false);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const subMenuContractType = [
    {
      title: "Actualizar descripción",
      icon: (
        <ModeEditOutlineOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Dejar de publicar",
      icon: <ClearIcon fontSize="large" className="heading__primary-color" />,
      selectionClick: 1,
    },
  ];
  const menuContractType = [
    {
      title: "Detalles de minuta",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 0,
    },
    {
      title:
        selectedElement?.associatedCorporateUnits?.length === 1 ||
        selectedElement?.publishData?.isPublish
          ? "Publicada"
          : "Publicar a solicitantes",
      icon: (
        <PersonOutlineOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 1,
    },
  ];

  //useEffect

  useEffect(() => {
    dispatch(setSelectedClauses([]));
    dispatch(setSelectedSubClauses([]));
    dispatch(setCurrentContractType({ contractType: null }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsContractTypes === "fetch") {
          dispatch(fetchTagsContractTypes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsContractTypes, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (status === "fetch") {
          dispatch(
            fetchContractTypes({ isPublish: isViewContractTypePublished })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [status, dispatch]);

  useEffect(() => {
    if (status === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [status]);

  useEffect(() => {
    const formatRows = buildRows(contractTypes);
    setFilteredDataTable({
      columns: columns,
      rows: formatRows,
    });
    setDataTable({
      columns: columns,
      rows: formatRows,
    });
  }, [contractTypes]);

  const handleFilterPublishContractType = async (checked) => {
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        isPublish: checked,
      };
      const response = await getContractsTypesByCompany(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const formatRows = buildRows(response.data.responseMessage);
        setFilteredDataTable({
          columns: columns,
          rows: formatRows,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Mis Minutas"} />
        </Col>
        <Col>
          <Row style={{ justifyContent: "end" }} xs={"auto"}>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<SettingsOutlinedIcon />}
                className="custom-input__button__primary-color"
                onClick={() => navigate("tags")}
              >
                <p className="button">Administrar etiquetas</p>
              </Button>
            </Col>
            <Col xs={"auto"}></Col>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={5}>
          <CustomSearch
            placeholder="Buscar minuta"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col md={7} style={{ display: "flex", justifyContent: "end" }}>
          <Col md={"auto"} style={{ display: "flex", alignItems: "center" }}>
            <Form.Group className="body" controlId="Terminos">
              <Form.Check
                className="label__description__default"
                type="checkbox"
                label="Ver minutas publicadas"
                onChange={(event) =>
                  handleFilterPublishContractType(event.target.checked)
                }
              />
            </Form.Group>
          </Col>
          <Col xs={"auto"}>
            <FilterTag
              tagsList={tagContractTypesList}
              list={dataTable}
              setFilteredInfo={setFilteredDataTable}
              handleTagsSelected={handleTagsSelected}
              currentTagSelected={tagsSelected}
            />
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="custom-input__button__primary-color"
              onClick={handleClickAnchorCreate}
            >
              <p className="button">Crear minuta</p>
            </Button>
            <Menu
              anchorEl={anchorElCreate}
              open={openCreateMenu}
              onClose={handleCloseAnchorCreate}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("create");
                  Mixpanel.track("Biblioteca crear minuta ", {
                    email: payloadToken?.email,
                    companyName: payloadToken?.companyName,
                    type: "Desde cero",
                  });
                }}
              >
                <span className="body-content">Desde cero</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("create-massive?from=contractTypes");
                  Mixpanel.track("Biblioteca crear minuta ", {
                    email: payloadToken?.email,
                    companyName: payloadToken?.companyName,
                    type: "Desde contrato",
                  });
                }}
              >
                <span className="body-content">Desde un contrato</span>
              </MenuItem>
            </Menu>
          </Col>
        </Col>
      </Row>
      <br />
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right" }}>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row className="custom-datatable__container dt-list-icon">
        <MDBDataTableV5
          noRecordsFoundLabel="No se encontraron resultados"
          hover
          pagingTop
          searchBottom={false}
          entries={7}
          data={filteredDataTable}
          infoLabel={["Mostrando", "a", "de", "minutas"]}
          fullPagination
        />
      </Row>

      <Menu
        anchorEl={anchorSelectedMenuInfo}
        open={openSelectedMenuInfo}
        onClose={handleCloseSelectedMenuInfo}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menuContractType.map((menuElement, index) => (
          <div key={menuElement.selectionClick}>
            <MenuItem
              onClick={(event) => {
                handlePerMenuContractType(menuElement.selectionClick, event);
              }}
            >
              <ListItemIcon>{menuElement.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={menuElement.title}
              />
            </MenuItem>
            {index + 1 === menuContractType.length ? <></> : <hr />}
          </div>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorSelectedSubMenuInfo}
        open={openSelectedSubMenuInfo}
        onClose={handleCloseSelectedSubMenuInfo}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {subMenuContractType.map((menuElement, index) => (
          <div key={menuElement.selectionClick}>
            <MenuItem
              onClick={() => {
                handlePerSubMenuContractType(menuElement.selectionClick);
              }}
            >
              <ListItemIcon>{menuElement.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={menuElement.title}
              />
            </MenuItem>
            {index + 1 === subMenuContractType.length ? <></> : <hr />}
          </div>
        ))}
      </Menu>
      <ModalInfo
        title={responseData?.title}
        responseData={responseData?.data}
        open={isOpenModal}
        onClose={handleClose}
      />
      <ModalDecision
        title={"Eliminar minuta"}
        message={"¿Está seguro que quiere eliminar esta minuta?"}
        open={isOpenModalDecisionDelete}
        onClose={handleCloseModalDesicionDelete}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicionDelete}
        handleDisagree={handleDisagreeModalDesicionDelete}
      />
      <ModalInpuText
        title={"Publicar a solicitante"}
        message={
          "Explicar al solicitante para que casos puede utilizar esta minuta."
        }
        placeholder={"Escriba aquí"}
        open={isPublishedContractTypeModal}
        onClose={setIsPublishedContractTypeModal}
        agreeText={
          selectedElement?.associatedCorporateUnits?.[0]?.description ||
          selectedElement?.publishData?.description
            ? "Actualizar"
            : "Publicar"
        }
        disagreeText="Cancelar"
        handleAgree={(description) => {
          if (description) {
            setIsPublishedContractTypeModal(false);
            handlePublishContractType({ description });
          }
        }}
        handleDisagree={() => {
          setIsPublishedContractTypeModal(false);
        }}
        validation={{
          required: {
            value: true,
            message: "Es obligatorio",
          },
          maxLength: {
            value: 150,
            message: "Máximo 150 caracteres",
          },
        }}
        value={
          selectedElement?.associatedCorporateUnits?.[0]?.description ||
          selectedElement?.publishData?.description
        }
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedElement}
        pageName={"Minute"}
      />
    </Container>
  );
};

export default ContractTypes;
