import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";
import { MODULES_WITH_AI_FUNCTIONALITIES } from "../../utils/modulesWithAIFunctionalities";

export function createUser(data) {
  return axios({
    method: "POST",
    url: `users/createUserByAdmin`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createAdminUser(data) {
  return axios({
    method: "POST",
    url: `users/create/admin`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function editUser(data) {
  return axios({
    method: "PUT",
    url: `users/userCreatedByAnAdmin`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function editAdminUser(data) {
  return axios({
    method: "PUT",
    url: `users/adminUser`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function verifyUsersPermissions(data) {
  return axios({
    method: "GET",
    url: `users/comparePermissions?currentAttorneyId=${data.currentAttorneyId}&nextAttorneyId=${data.nextAttorneyId}`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteUserOnly(data) {
  const { email } = data;
  return axios({
    method: "DELETE",
    url: `users/adminUser/${email}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function transferUserDataRequest(data) {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/transferRequestsToAttorney`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function transferUserDataRequestExternal(data) {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/transferRequestsToApplicant`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function transferUserDataAlerts(data) {
  return axios({
    method: "PUT",
    url: `alerts/transferAlertsToAttorney`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function transferUserDataTask(data) {
  return axios({
    method: "PUT",
    url: `/tasks/transferTasksToAttorney`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function ChangeAdminPassword(data) {
  return axios({
    method: "PUT",
    url: `users/changePassword`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function SendInfoToSupport(data) {
  const formData = new FormData();
  formData.append("subject", data.subject);
  formData.append("message", data.message);
  formData.append("userEmail", data.userEmail);
  formData.append("firstName", data.firstName);
  if (data.lastName) {
    formData.append("lastName", data.lastName);
  }

  formData.append("token", data.token);
  formData.append(
    "additionalInfo",
    data.additionalInfo ? JSON.stringify(data.additionalInfo) : "{}"
  );
  if (data.supportDocuments?.length) {
    data.supportDocuments.map((file) => {
      formData.append("supportDocuments", file);
    });
  }
  return axios({
    method: "POST",
    url: `users/send-email/technical-support/`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function getCompanyUsers(data) {
  return axios({
    method: "GET",
    url: `users/usersCreatedByAnAdmin`,
    params: {
      userId: data.userId,
      companyId: data.companyId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getUserPermissionsBySubRole(data) {
  return axios({
    method: "GET",
    url: `permissions/findOneSubroleByName/${data}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function changeAssignmentRequest(data) {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/changeAssignment`,
    baseURL: env.REACT_APP_API_URL,
    data: {
      currentAttorneyId: data.currentAttorneyId,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function changeAssignmenttask(data) {
  return axios({
    method: "PUT",
    url: `tasks/changeAssignmentTask`,
    baseURL: env.REACT_APP_API_URL,
    data: {
      currentAttorneyId: data.currentAttorneyId,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getApplicants({ company, corporateUnits, userId }) {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getApplicants`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: { company, corporateUnits, userId },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function ReportAccount(data) {
  return axios({
    method: "GET",
    url: `users/reportAccount`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      email: data.email,
    },
  });
}

export function reportIAResult(reportInformation) {
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const buildUserFullName = (payloadToken) => {
    let firstName = payloadToken?.firstName ?? "";
    let secondName = payloadToken?.secondName ?? "";
    let firstSurname = payloadToken?.firstSurname ?? "";
    let secondSurname = payloadToken?.secondSurname ?? "";
    const userFullName =
      `${firstName} ${secondName} ${firstSurname} ${secondSurname}`.trim();
    return userFullName.length > 3 ? userFullName : undefined;
  };
  const getUserRole = (payloadToken) => {
    let role;
    if (payloadToken["custom:role"] === "SERVICE") {
      role = "Usuario de servicios";
    } else if (payloadToken["custom:role"] === "EXTERNAL") {
      role = "Usuario solicitante";
    } else {
      role = "Usuario administrador";
    }
    return role;
  };
  const getUserSubRole = (payloadToken) => {
    const subRoles = [
      {
        name: "Administrador",
        value: "ADMIN ADMINISTRADOR",
      },
      {
        name: "Propietario",
        value: "ADMIN PROPIETARIO",
      },
      {
        name: "Director",
        value: "SERVICE DIRECTOR",
      },
      {
        name: "Senior",
        value: "SERVICE SENIOR",
      },
      {
        name: "Junior",
        value: "SERVICE JUNIOR",
      },
      {
        name: "Practicante",
        value: "SERVICE PRACTICANTE",
      },
      {
        name: "Solicitante",
        value: "EXTERNAL SOLICITANTE",
      },
    ];
    const userSubRole = subRoles.find(
      (subRole) => subRole.value === payloadToken?.subRole
    );
    return userSubRole;
  };
  const getModuleName = (pathname) => {
    if (pathname.match(MODULES_WITH_AI_FUNCTIONALITIES.REVIEW.pathRegex)) {
      return MODULES_WITH_AI_FUNCTIONALITIES.REVIEW.name;
    } else if (
      pathname.match(MODULES_WITH_AI_FUNCTIONALITIES.FOLDERS.pathRegex)
    ) {
      return MODULES_WITH_AI_FUNCTIONALITIES.FOLDERS.name;
    } else {
      return;
    }
  };
  const userFullName = buildUserFullName(payloadToken);
  const userRole = getUserRole(payloadToken);
  const userSubRole = getUserSubRole(payloadToken);
  const platformModuleName = getModuleName(reportInformation.currentPathname);
  const completeReportInformation = {
    message: reportInformation.message,
    username: userFullName,
    userEmail: payloadToken?.email,
    userType: userRole,
    userRole: userSubRole?.name,
    companyName: payloadToken?.companyName,
    platformModuleName: platformModuleName,
    functionalityName: reportInformation.functionalityName,
  };
  return axios({
    method: "POST",
    url: `users/reportAIResult`,
    data: completeReportInformation,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function activateUserRegistration(data) {
  return axios({
    method: "PUT",
    url: `users/create/admin/authorize`,
    data: {
      email: data.email,
      isAuthorized: data.isAuthorized === "true" ? true : false,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
