import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getCompanyUsers } from "../../../../../services/admin/manageUserService";
import { CODES } from "../../../../../utils/codes";

export const companyUsersSlice = createSlice({
  name: "companyUsers",
  initialState: {
    companyUsersList: [],
    companyUsersListStatus: "fetch",
    companyUsersListError: null,
  },
  reducers: {
    createUser(state, action) {
      const { createdUser } = action.payload;
      const currentUsers = state.companyUsersList;
      currentUsers.unshift(createdUser);
      state.companyUsersList = currentUsers;
    },

    editUserByIndex(state, action) {
      const { editedUser, index } = action.payload;
      state.companyUsersList[index] = editedUser;
    },

    deleteUser(state, action) {
      const { index } = action.payload;
      const usersArray = state.companyUsersList;
      usersArray.splice(index, 1);
      state.companyUsersList = usersArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyUsersList.pending, (state, _) => {
        state.companyUsersListStatus = "loading";
      })
      .addCase(fetchCompanyUsersList.fulfilled, (state, action) => {
        state.companyUsersListStatus = "succeeded";
        state.companyUsersList = action.payload.data.responseMessage;
      })
      .addCase(fetchCompanyUsersList.rejected, (state, action) => {
        state.companyUsersListStatus = "failed";
        state.companyUsersListError = action.error.message;
      });
  },
});

export const { editUserByIndex, createUser, deleteUser } =
  companyUsersSlice.actions;

export const getCompanyUsersList = (state) =>
  state.companyUsers.companyUsersList;

export const getCompanyUsersListStatus = (state) =>
  state.companyUsers.companyUsersListStatus;

export const fetchCompanyUsersList = createAsyncThunk(
  "companyUsers/fetchCompanyUsersList",
  async () => {
    const companyId = localStorage.getItem("company");
    const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
    let obj = {
      userId,
      companyId: companyId,
    };
    const response = await getCompanyUsers(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export default companyUsersSlice.reducer;
