import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Slide,
} from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../sectionTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalInfoFullScreen = ({
  textBackButton = "Regresar",
  description,
  title,
  open,
  onClose,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Container fluid>
        <br />
        <Row>
          <Col xs={"auto"}>
            <SectionTitle title={title || "Título"} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Col>
        </Row>
        <br />
        <br />
        <Row style={{ margin: "1%", marginRight: "10%" }}>
          <p className="body-content__justify">
            {description || "Descripción"}
          </p>
        </Row>
        <br />
        <br />
        <DialogActions
          sx={{
            justifyContent: "start",
          }}
        >
          <Col xs={"auto"}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={onClose}
            >
              {textBackButton}
            </Button>
          </Col>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default ModalInfoFullScreen;
