export default () => {
  /* eslint-disable no-restricted-globals */
  self.addEventListener("message", async (e) => {
    const { obj, token, serviceUrl, reviewRule } = e.data;

    try {
      const formData = new FormData();
      formData.append("documentId", obj.documentId);
      formData.append("company", obj.company);
      formData.append("file", obj.file);
      formData.append("type", obj.type);

      const headers = new Headers();
      headers.append("Authorization", token);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
      };
      const service = await fetch(
        `${serviceUrl}/review-rules/reviewOtherDocument`,
        requestOptions
      );

      const response = await service.json();

      if (!response.success) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (response.success && response.responseCode === 0) {
        self.postMessage({
          success: true,
          data: response.responseMessage.data,
          reviewRule,
        });
      } else {
        console.log("error de documento");
        throw new Error("Error during document review");
      }
    } catch (error) {
      console.log(error);
      self.postMessage({ success: false, error: error.message });
    }
  });
  /* eslint-disable no-restricted-globals */
};
