import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function findCompanyPlan(data) {
  return axios({
    method: "GET",
    url: `plans/findCompanyPlan`,
    params: { companyId: data.companyId},
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}