import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  addNewFolder,
  fetchAllFolders,
  getStatusFolders,
} from "../../../../../../parts/folders/foldersSlice";
import {
  createBusinessFolder,
  getFolderById,
  updateBusinessFolder,
} from "../../../../../../services/business_folder/businessFolderService";
import { CODES } from "../../../../../../utils/codes";
const CrudBusinessFolder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action, id } = useParams();
  const foldersStatus = useSelector(getStatusFolders);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [responseData, setResponseData] = useState("");
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [responseModalUnauthorized, setResponseModalUnauthorized] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es obligatorio"),
    description: yup.string().nullable().notRequired(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  const handleRequest = async (data) => {
    try {
      setIsLoading(true);
      const payloadToken = JSON.parse(localStorage.payloadToken);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const labels = [];
      for (const select of tagsSelected) {
        labels.push(select._id);
      }
      const create =
        action === "create"
          ? await createBusinessFolder({
              ...data,
              company: payloadToken.company,
              labels,
              corporateUnit,
              businessFolderFatherId: id,
              timeZone: new Date().getTimezoneOffset() / -60,
            })
          : await updateBusinessFolder({
              ...data,
              company: payloadToken.company,
              labels,
              id,
              corporateUnit,
              timeZone: new Date().getTimezoneOffset() / -60,
            });

      if (
        create.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        create.data.responseCode === CODES.COD_RESPONSE_HTTP_ERROR
      ) {
        setResponseDataUnauthorized({
          status: create.data.responseCode,
          data: { responseMessage: create.data.responseMessage },
        });
        setResponseModalUnauthorized(true);
      } else if (
        create.data.success &&
        create.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          status: create.status,
          data: { responseMessage: create.data.responseMessage },
        });

        if (action === "create") {
          if (foldersStatus == "fetch") {
            dispatch(fetchAllFolders());
          }
          const newFolder = create.data.responseMessage.data;
          dispatch(
            addNewFolder({
              folder: newFolder,
            })
          );
        }
        setResponseModal(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const requestTags = async () => {
      try {
        setIsLoading(true);

        if (action === "edit") {
          const companyId = localStorage.getItem("company");
          const corporateUnitId = localStorage.getItem("corporateUnitId");
          const dataBusinessFolder = await getFolderById({
            companyId,
            corporateUnit: corporateUnitId,
            id,
          });
          const nameLabels =
            dataBusinessFolder?.data?.responseMessage?.labels || [];
          setValue("name", dataBusinessFolder?.data?.responseMessage?.name);
          setValue(
            "description",
            dataBusinessFolder?.data?.responseMessage?.description
          );
          setTagsSelected(nameLabels);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    requestTags();
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={action === "create" ? `Crear carpeta` : `Editar carpeta`}
        />
      </Row>
      <Form onSubmit={handleSubmit(handleRequest)}>
        <Row className="heading" style={{ marginTop: "4%" }}>
          Nombre de la carpeta*
        </Row>
        <Row style={{ marginTop: "1%" }}>
          <Col md={4}>
            <Form.Group className="mb-5">
              <Form.Control
                {...register("name")}
                placeholder="Escriba el nombre"
                bsPrefix={
                  errors.name
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.name?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="heading">Descripción de la carpeta</Row>
        <Row style={{ marginTop: "1%" }}>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Control
                {...register("description")}
                placeholder="Escriba la descripción"
                bsPrefix={
                  errors.description
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.description?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="heading" style={{ margin: "1% 0" }}>
          Asociar etiquetas a la carpeta
        </Row>
        <Row style={{ marginBottom: "3%" }}>
          <Col md={2}>
            <ModalAssociateTags
              handleTagsSelected={handleTagsSelected}
              previousTagsSelected={tagsSelected}
              type="BUSINESSFOLDER"
            />
          </Col>

          {tagsSelected.map((tag, idx) => (
            <Col
              xs={"auto"}
              style={{ display: "flex", alignItems: "center" }}
              key={uuidv4()}
            >
              <span className="caption custom-badges__disabled">
                {tag.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          ))}
        </Row>
        <Row>
          <Col md={2}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              className="custom-input__button__primary-color"
              size="large"
            >
              {action === "create" ? `Crear carpeta` : `Editar carpeta`}
            </Button>
          </Col>
        </Row>
        <ModalInfo
          title={"Información"}
          responseData={responseData}
          open={responseModal}
          onClose={() => {
            setResponseModal(false);
            navigate(-1);
          }}
        />
        <ModalInfo
          title={"Sin permisos suficientes"}
          responseData={responseDataUnauthorized}
          open={responseModalUnauthorized}
          onClose={() => {
            setResponseModal(false);
            navigate(-1);
          }}
        />
      </Form>
    </Container>
  );
};

export default CrudBusinessFolder;
