import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Drawer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import LoadingContent from "../../../../../../components/loadingContent";
import {
  getApplicants,
  getCompaniesList,
  getRequestTypes,
} from "../../../../../../services/general/CarvajalServices";
import {
  elementStatus,
  mockMinuta,
  mockSignatories,
  statusList,
  typeAssociate,
  typePrincipal,
} from "../../../../../../utils/statusExternalRequest";
const FilterByCarvajal = ({
  openDrawer,
  setOpenDrawer,
  // listOptions,
  setOptionsFilterBar,
  folders = false,
  setSearchParams,
}) => {
  const [listOptions, setListOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleFilter = (option, value, checked) => {
    setListOptions((listOptions) => {
      const newList = [...listOptions];
      const states = newList.map((list) => {
        if (list.title === option.title) {
          return {
            ...list,
            filters: list.filters.map((filter) => {
              if (filter.value === value) {
                return { ...filter, checked: checked };
              } else {
                return { ...filter };
              }
            }),
            expanded: true,
          };
        } else {
          return { ...list, expanded: false };
        }
      });
      return states;
    });
  };

  const requestAttorneys = async () => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    const { formType } = JSON.parse(localStorage.getItem("corporateUnit"));
    try {
      setIsLoading(true);
      const obj = {
        company: payloadToken.company,
      };

      const formObj = {
        formType: formType,
        companyId: payloadToken.company,
      };
      const listAttorneys = await getApplicants(obj);
      const listOfCompanies = await getCompaniesList();
      const listOfForms = await getRequestTypes(formObj);

      setListOptions([
        {
          title: "Estado de la solicitud",
          filters: statusList.map((status) => ({
            label: status.Status?.toLocaleLowerCase().replace(/^\w/, (c) =>
              c.toUpperCase()
            ),
            value: status.Status,
            checked: false,
          })),
        },
        {
          title: "Documento requerido",
          filters: listOfForms.data.responseMessage.map((type) => ({
            label: type.name
              ?.toLocaleLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase()),
            value: type.name,
            checked: false,
          })),
        },
        {
          title: "Solicitante",
          filters: listAttorneys.data.responseMessage.map((attorney) => ({
            label: `${attorney.firstName} ${attorney.secondName} ${attorney.firstSurname} ${attorney.secondSurname}`,
            value: attorney._id,
            checked: false,
          })),
        },
        {
          title: "Empresa",
          filters: listOfCompanies.data.responseMessage.list.map((company) => ({
            label: company,
            value: company,
            checked: false,
          })),
        },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCleanFilter = () => {
    setListOptions((prevData) => {
      if (prevData.length > 0) {
        const newListOption = prevData.map((newOption) => ({
          ...newOption,
          filters: newOption.filters.map((filter) => ({
            ...filter,
            checked: false,
          })),
        }));
        return newListOption;
      } else {
        return prevData;
      }
    });
    setOptionsFilterBar([]);
    setOpenDrawer(false);
    setSearchParams({});
  };

  const prepareForFolders = async () => {
    //PETICION DE SIGNATARIOS
    const listOfCompanies = await getCompaniesList();

    setListOptions([
      {
        title: "Estado",
        filters: elementStatus.map((status) => ({
          label: status,
          value: status,
          checked: false,
        })),
      },
      {
        title: "Signatario",
        filters: mockSignatories.map((sig) => ({
          label: sig,
          value: sig,
          checked: false,
        })),
      },
      {
        title: "Minuta",
        filters: mockMinuta.map((minuta) => ({
          label: minuta,
          value: minuta,
          checked: false,
        })),
      },
      {
        title: "Tipo de Doc. Principal",
        filters: typePrincipal.map((prin) => ({
          label: prin,
          value: prin,
          checked: false,
        })),
      },
      {
        title: "Tipo de Doc. Asociado",
        filters: typeAssociate.map((assos) => ({
          label: assos,
          value: assos,
          checked: false,
        })),
      },
      {
        title: "Empresa",
        filters: listOfCompanies.data.responseMessage.list.map((company) => ({
          label: company,
          value: company,
          checked: false,
        })),
      },
    ]);
  };

  useEffect(async () => {
    if (!folders) {
      requestAttorneys();
    } else {
      prepareForFolders();
    }
  }, []);
  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <Container fluid style={{ width: "25rem", height: "inherit" }}>
          {isLoading && <LoadingContent />}
          <Row
            style={{
              height: "10%",
              alignContent: "center",
            }}
          >
            <Col md={10} className="heading">
              Filtrar
            </Col>
            <Col md={1}>
              <CloseIcon
                onClick={() => {
                  setOpenDrawer(false);
                }}
              />
            </Col>
          </Row>
          <Row
            style={{
              height: "3%",
              alignContent: "center",
            }}
          ></Row>
          {listOptions.map((option, index) => (
            <Row
              key={uuidv4()}
              style={{
                alignContent: "center",
              }}
            >
              <Accordion defaultExpanded={option?.expanded}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="subheading">{option.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {option.filters.map((filter, index) => (
                    <Form.Group
                      className="mb-1"
                      controlId={filter.label}
                      key={filter._id || filter.label}
                    >
                      <Form.Check
                        type="checkbox"
                        label={filter.label}
                        checked={filter.checked}
                        onChange={(e) => {
                          handleFilter(option, filter.value, e.target.checked);
                        }}
                      />
                    </Form.Group>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Row>
          ))}
          <br />
          <Row style={{ bottom: 10 }}>
            <Col md={7}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleCleanFilter}
              >
                Limpiar filtro
              </Button>
            </Col>
            <Col md={5}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => {
                  setOptionsFilterBar(listOptions);
                  setOpenDrawer(false);
                }}
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </Container>
      </Drawer>
    </>
  );
};

export default FilterByCarvajal;
