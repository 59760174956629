import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function getCategoriesByCompanyAndResourceType(data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "GET",
    url: "categories/getCategoriesByCompanyIdAndResourceType",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      company: data.companyId,
      resourceType: data.resourceType,
      corporateUnit,
      sortBy: "priority",
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}
