//Basics
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
//Loading Content
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
//parts
import CustomSearchFilter from "../../../../../components/Search/CustomSearchFilter";
import Breadcrum from "../../../../../parts/breadcrum";
//Icons
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
//Table
import { MDBBadge, MDBDataTableV5 } from "mdbreact";
//Services
import {
  CreateTaskInAlert,
  DeleteTaskPaperBasketInAlert,
  GetTasksFromAnAlert,
  updateTask,
} from "../../../../../services/alerts/alertsServices";
import { getAttorneys } from "../../../../../services/general/requestServices";
//CODES
import { CODES } from "../../../../../utils/codes";
//CheckBox
import Checkbox from "@mui/material/Checkbox";
//Standar Date
import { Buffer } from "buffer";
import fileDownload from "js-file-download";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import { WordBlobDocument } from "../../../../../components/WordCreator/WordCreator";
import {
  getDocById,
  getFileDocument,
} from "../../../../../services/documents/documentsRequest";
import { codesDocumentType } from "../../../../../utils/codesDocumentType";
import { FOLDER_STATE } from "../../../../../utils/documentStates";
import dateFormat from "dateformat";
import { FormControlLabel, Radio } from "@mui/material";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import { modifyAlert } from "./alertSlice";
import { useDispatch } from "react-redux";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const ViewAlert = () => {
  const { alertId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stateColorId = {
    [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
    [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
    [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
    [CODES.COD_LIGHT_BLUE]: "#FFD7B1",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [responseDataError, setResponseDataError] = useState({});
  const [isOpenModalInfoError, setIsOpenModalInfoError] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleUpdateAlert = async (id) => {
    try {
      setIsLoading(true);
      let obj = {
        id,
        state: CODES.COD_COMPLETED_TASK,
      };
      const response = await dispatch(modifyAlert(obj)).unwrap();
      if (response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataError(response);
        setIsOpenModalInfoError(true);
        return;
      }
      setResponseData(response);
      setIsOpenModalInfo(true);
      setRefresh(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadDoc = async (
    filenameInBucket,
    bucketName,
    name,
    type,
    id
  ) => {
    try {
      setIsLoading(true);
      let newName = name;
      if (type === codesDocumentType.draft._id) {
        const doc = await getDocById(id);
        const { documentContent } = doc.data.responseMessage;
        const blob = await WordBlobDocument({
          title: documentContent.title,
          headers: documentContent.headers,
          parts: documentContent.parts,
          clauses: documentContent.clauses,
          partHeader: "",
          numberingType: "number",
          otherSignatories: [],
        });
        fileDownload(blob, name + ".docx");
      } else {
        const downloadDocument = await getFileDocument(
          filenameInBucket,
          bucketName,
          false
        );
        const contentType =
          downloadDocument.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? ".docx"
            : ".pdf";
        if (!name.match(/\.([0-9a-z]+)(?:[?#]|$)/i)) {
          newName = name + contentType;
        }
        const bf = Buffer.from(
          downloadDocument.data.responseMessage.buffer.data
        );
        fileDownload(bf, newName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        // obj to send as a param in service
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const companyId = localStorage.getItem("company");
        const obj = {
          alertId,
          companyId,
          corporateUnit,
        };
        //Service to take request data

        const service = await GetTasksFromAnAlert(obj);
        if (
          service?.status === CODES.COD_RESPONSE_HTTP_OK &&
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const alertData = service.data.responseMessage.data;
          setAlert(alertData);
          if (alertData.state._id === CODES.COD_COMPLETED_TASK)
            setIsCompleted(true);
        } else {
          setResponseDataError({ ...service, status: 400 });
          setIsOpenModalInfoError(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [refresh]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Información de la alerta"} />
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <p className="subheading">
          Nombre del hito:{" "}
          <span className="caption">{alert?.name || "Nombre del hito"}</span>
        </p>
      </Row>
      <Row>
        <p className="subheading">
          Estado de la alerta: {"  "}
          <span className="caption">
            <MDBBadge
              color=""
              pill
              className="caption"
              style={{
                backgroundColor:
                  stateColorId[alert?.state?.colorId] || `#F9DADE`,
                fontSize: "1.2rem",
                borderRadius: "0.4rem",
                padding: "0.4rem",
              }}
            >
              <span style={{ color: "black" }}>
                {alert?.state?.name || "Inactivo"}
              </span>
            </MDBBadge>
          </span>
          {"  "}
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#676879",
              },
              "&.MuiFormControlLabel-root": {
                margin: "0 !important",
              },
            }}
            control={
              <Checkbox
                checked={isCompleted}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<CheckCircleIcon />}
                className="caption__disabled"
                onClick={() => {
                  if (isCompleted) return;
                  handleUpdateAlert(alertId);
                }}
              />
            }
            label="Marcar como completada"
          />
        </p>
      </Row>
      <Row>
        <p className="subheading">
          Hito: <span className="caption">{alert?.milestone || "Hito"}</span>
        </p>
      </Row>
      <br />
      <Row>
        <p className="subheading">
          Cláusula:{" "}
          <span className="caption">{alert?.clause || "Cláusula"}</span>
        </p>
      </Row>
      <br />
      <Row>
        <p className="subheading">
          Descripción:{" "}
          <span className="caption">{alert?.description || "Descripción"}</span>
        </p>
      </Row>
      <br />
      <Row>
        <p className="subheading">
          Fecha de activación de la alerta:{" "}
          <span className="caption">
            {dateFormat(alert?.alertDate, "dd/mm/yyyy", true) || "DD/MM/YYYY"}
          </span>
        </p>
      </Row>
      <br />
      <Row>
        <p className="subheading">
          Fecha de vencimiento de la alerta:{" "}
          <span className="caption">
            {dateFormat(alert?.tasksDeadline, "dd/mm/yyyy", true) ||
              "DD/MM/YYYY"}
          </span>
        </p>
      </Row>
      <br />
      <Row>
        <p className="subheading">
          Responsable:{" "}
          <span className="caption">
            {alert?.assignedTo?.firstName
              ? `${alert?.assignedTo?.firstName} ${alert?.assignedTo?.firstSurname}`
              : `${alert?.assignedTo?.companies[0]?.companyData?.companyName}` ||
                "Nombre del hito"}
          </span>
        </p>
      </Row>
      <br />
      {alert?.isRemember && (
        <>
          <Row>
            <p className="subheading">
              Fecha de recordatorio:{" "}
              <span className="caption">
                Se avisará de la alerta el día tal{" "}
                {dateFormat(
                  alert?.notifications?.[alert?.notifications?.length - 1]
                    ?.notificationDate,
                  "dd/mm/yyyy",
                  true
                ) || "DD/MM/YYYY"}
              </span>
            </p>
          </Row>
          <br />
        </>
      )}
      {alert?.isRepeated && (
        <>
          <Row>
            <p className="subheading">
              Fecha de repetición:{" "}
              <span className="caption">
                La alerta se repetirá el día tal{" "}
                {dateFormat(
                  alert?.nextRepetitionAlert?.alertNextUpdated,
                  "dd/mm/yyyy",
                  true
                )}
              </span>
            </p>
          </Row>
          <br />
        </>
      )}
      <Row>
        <p className="subheading">
          Interesados:{" "}
          <span className="caption">
            {alert?.emails.join(", ") || "Nombre del hito"}
          </span>
        </p>
      </Row>
      <br />
      <Row>
        <p className="subheading">
          Documento:{" "}
          <span
            className="caption__link"
            onClick={() => {
              if (!alert?.documentAssociated?.name) return;
              handleDownloadDoc(
                alert?.documentAssociated?.filenameInBucket,
                alert?.documentAssociated?.bucketName,
                alert?.documentAssociated?.name,
                alert?.documentAssociated?.fileType,
                alert?.documentAssociated?._id
              );
            }}
          >
            {alert?.documentAssociated?.name || "No hay documento"}
          </span>
        </p>
      </Row>
      {!isCompleted && (
        <Row className="sidebar__bottom__container">
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              className="custom-input__button__primary-color"
              onClick={() => {
                const idDocument =
                  alert?.documentAssociated?._id || "without-document";
                navigate(`edit/${idDocument}`, { state: { alert } });
              }}
            >
              Editar información de la alerta
            </Button>
          </Col>
        </Row>
      )}
      <ModalInfo
        title={"Ha ocurrido un error"}
        responseData={responseDataError}
        open={isOpenModalInfoError}
        onClose={() => {
          setIsOpenModalInfoError(false);
          navigate("/service/home");
        }}
      />
      <ModalInfo
        title={"Actualizar alerta"}
        responseData={responseData}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
      />
    </Container>
  );
};

export default ViewAlert;
