import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SectionCardButtonWithNavButton from "../../../../../components/Cards/SectionCardButtonWithNavButton";

import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
const LibraryService = () => {
  return (
    <Container fluid>
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Biblioteca"} />
      </Row>
      <br />
      <br />
      <Row>
        <p className="caption">¿Qué quieres gestionar?</p>
      </Row>
      <br />
      <br />
      <br />
      <Row style={{ rowGap: "30px" }}>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<FeedOutlinedIcon className="cards__icon-svg" />}
            title="Mis Minutas"
            description="Revisa, edita, agrega y elimina minutas de tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/contractTypes"
            mixPanelTrack="Mis minutas"
          />
        </Col>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<ExtensionOutlinedIcon className="cards__icon-svg" />}
            title="Mis Cláusulas"
            description="Revisa, edita, agrega y elimina cláusulas de tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/clauses"
            mixPanelTrack="Mis cláusulas"
          />
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: "30px" }}>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<FeedOutlinedIcon className="cards__icon-svg" />}
            title="Mis Plantillas"
            description="Revisa, edita, agrega y elimina plantillas de otros documentos en tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/documentTemplates"
            mixPanelTrack="Plantillas de otros documentos"
          />
        </Col>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<ExtensionOutlinedIcon className="cards__icon-svg" />}
            title="Mis Bloques"
            description="Revisa, edita, agrega y elimina otros bloques de texto en tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/predeterminedTexts"
            mixPanelTrack="Textos predeterminados"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LibraryService;
