import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import plugins from "suneditor/src/plugins";
import { transformSelectionToHtml } from "../utils/blankspaces";
import { customListPlugin } from "../utils/plugins/sunEditorPlugins";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ReactDOMServer from "react-dom/server";
const RichEditorText = forwardRef((props, ref) => {
  const { handleSelection = () => {}, minHeight = "10 rem" } = props;

  const listenSelection = () => {
    const selection = transformSelectionToHtml(
      editorRef?.current?.core?.getSelection()
    );
    handleSelection({ event: editorRef?.current, selection: selection || "" });
  };

  const editorRef = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
    editorRef.current.blankSpaces = [];
    sunEditor.core.removeFormat();
  };
  const [forceFocus, setForceFocus] = useState(false);

  const handlePaste = (e) => {
    e.preventDefault();
    const plainText = e.clipboardData.getData("text/plain");
    editorRef.current?.insertHTML(plainText, false);
  };

  useImperativeHandle(ref, () => ({
    addPart(text, partPosition) {
      const htmlPart = `<span data-part="${text}" data-part-number="${partPosition}" class="clause-part-text">°${text}°</span>&nbsp;`;
      editorRef.current?.insertHTML(htmlPart, false);
    },

    addBlankSpace(blankSpaces) {
      let id = blankSpaces.reduce((acc, bs) => (acc > bs.id ? acc : bs.id), 0);
      const htmlPart = `<span data-blank-space="blankSpace-${
        Number(id) + 1
      }" class="blank-space-text">¬ESPACIO #${Number(id) + 1}¬</span>&nbsp;`;
      editorRef.current?.insertHTML(htmlPart, false);
      editorRef.current.blankSpaces.push({
        value: `blankSpace-${Number(id) + 1}`,
        name: `¬ESPACIO #${Number(id) + 1}¬`,
        id: id + 1,
      });
    },
    addSingleBlankspace(blankspace) {
      editorRef.current.blankSpaces.push({
        value: `blankSpace-${Number(blankspace.id)}`,
        name: `¬ESPACIO #${Number(blankspace.id)}¬`,
        id: blankspace.id,
      });
    },
    addBlankspacesArray(blankspaces) {
      blankspaces.forEach((element) => {
        editorRef.current.blankSpaces.push({
          value: `blankSpace-${Number(element.id)}`,
          name: `¬ESPACIO #${Number(element.id)}¬`,
          id: element.id,
        });
      });
    },
    removeBlankSpace(id) {
      if (editorRef.current) {
        // Obtén el contenido actual del editor
        const content = editorRef.current.getContents();

        // Crea un nuevo elemento DOM para manipular el contenido
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = content;

        // Selecciona el elemento basado en el atributo data-blank-space
        const blankSpaceElement = tempDiv.querySelector(
          `span[data-blank-space="blankSpace-${Number(id)}"]`
        );

        // Si el elemento existe, elimínalo
        if (blankSpaceElement) {
          blankSpaceElement.remove();
          // Actualiza el contenido del editor después de eliminar el elemento
          editorRef.current.setContents(tempDiv.innerHTML);
        }
      }
    },

    addText(text) {
      editorRef.current?.insertHTML(text, false);
    },

    importNumeral(htmlContent) {
      editorRef.current?.core.removeFormat();
      editorRef.current?.setContents(htmlContent);
    },

    removeImportedNumeral() {
      editorRef.current?.setContents("");
    },

    setEditorContents(htmlContent) {
      editorRef.current?.core?.removeFormat();
      editorRef.current?.setContents(htmlContent);
    },
    setBlankSpacesValues(id) {
      editorRef.current.blankSpaces.push({
        value: `blankSpace-${Number(id)}`,
        name: `¬ESPACIO #${Number(id)}¬`,
        id,
      });
    },
    setBlur() {
      editorRef.current.core.blur();
    },
  }));

  let tablePlugin = {
    ...plugins.table,
    appendTable: function () {
      const oTable = editorRef.current?.core?.util.createElement("TABLE");
      oTable.className = "contract_table";
      const createCells = this.plugins.table.createCells;
      const x = editorRef.current?.core?.context.table._tableXY[0];
      let y = editorRef.current?.core?.context.table._tableXY[1];
      let tableHTMLHead = "<thead>";
      let tableHTML = "<tbody>";
      let firstRow = y;
      while (y > 0) {
        if (y === firstRow) {
          tableHTMLHead += "<tr>" + createCells.call(this, "th", x) + "</tr>";
        } else {
          tableHTML += "<tr>" + createCells.call(this, "td", x) + "</tr>";
        }
        --y;
      }
      tableHTMLHead += "</thead>";
      tableHTML += "</tbody>";
      oTable.innerHTML = tableHTMLHead + tableHTML;
      const changed = this.insertComponent(oTable, false, true, false);
      if (changed) {
        const firstTd = oTable.querySelector("td div");
        this.setRange(firstTd, 0, firstTd, 0);
        this.plugins.table.reset_table_picker.call(this);
      }
    },
  };

  useEffect(() => {
    if (forceFocus && editorRef.current) {
      editorRef.current.core?.focus();
      setForceFocus(false);
    }
  }, [forceFocus]);

  const handleClickInsideEditor = () => {
    setForceFocus(true);
    listenSelection();
  };

  const handleDeleteBlankSpace = (event) => {
    if (
      event.key === "Backspace" &&
      typeof props?.deleteBlankSpace === "function"
    ) {
      const content = editorRef.current?.getContents();

      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");

      const text = editorRef.current.getText();

      const arrayBlankSpaces = editorRef.current.blankSpaces;

      const arrayIsBlank = arrayBlankSpaces.filter(
        (b) => !text.includes(b.name)
      );

      if (arrayIsBlank.length) {
        arrayIsBlank.forEach((isBlank) => {
          const tagToDelete = isBlank.value;

          if (tagToDelete) {
            const elementToDelete = doc.querySelector(
              `[data-blank-space="${tagToDelete}"]`
            );

            if (elementToDelete)
              elementToDelete.parentNode.removeChild(elementToDelete);

            editorRef.current.blankSpaces = arrayBlankSpaces.filter(
              (item) => item.value !== isBlank.value
            );

            props.deleteBlankSpace(isBlank.id);
          }
        });

        const newContent = doc.documentElement.innerHTML;

        editorRef.current?.setContents(newContent);
      }
    }
  };

  return (
    <div
      className={props.customClassName}
      style={{
        padding: 0,
        "&.se-toolbar": {
          "background-color": "white !important",
        },
      }}
    >
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        lang={"es"}
        name={"clauseEditor"}
        autoFocus={true}
        onPaste={handlePaste}
        height="100%"
        onFocus={() => {
          const text = editorRef.current.getText();
          if (!text) editorRef.current.core.removeFormat();
        }}
        onKeyUp={handleDeleteBlankSpace}
        onChange={(content) => content}
        defaultValue={""}
        placeholder={
          props.placeholder ||
          "Escribe el texto de la cláusula aquí. Utiliza los botones superiores para nombrar alguna parte y agregar espacios en blanco."
        }
        onClick={handleClickInsideEditor}
        setAllPlugins={false}
        setDefaultStyle="font-family: Roboto, sans-serif; font-size: 1.6rem; font-weight: 400; line-height: 2.4rem; word-break: break-word"
        {...props}
        setOptions={{
          plugins: {
            ...plugins,
            tablePlugin,
            customListPlugin,
          },
          value: "",
          minHeight: minHeight,
          alignItems: "center",
          defaultTag: "p",
          mode: "basic",
          height: "100%",
          maxHeight: "393px",
          attributesWhitelist: {
            span: "data-blank-space|class|data-part|data-part-number",
          },
          attributesBlacklist: { script: "*" },
          buttonList: [
            [
              "removeFormat",
              "bold",
              "italic",
              "strike",
              "table",
              "align",
              "font",
              "fontSize",
              "fontColor",
              "hiliteColor",
              {
                name: "customListPlugin",
                dataCommand: "customListPlugin",
                buttonClass: "",
                title: "Lista Avanzadas",
                dataDisplay: "submenu",
                innerHTML: ReactDOMServer.renderToString(<ListAltIcon />),
              },

              "list",
              "formatBlock",
              "lineHeight",
              "link",
            ],
          ],
        }}
      />
    </div>
  );
});

export default RichEditorText;
