import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";

const ModalPDFView = ({ title, content, agreeText, disagreeText, handleAgree, handleDisagree, onClose, open }) => {

    const handleClose = () => {
        onClose();
    }

    const DialogTitleContainer = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth='sm'
                onClose={handleClose}
                open={open}
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitleContainer onClose={handleClose}>
                    <label className="heading__primary-color">{title}</label>
                </DialogTitleContainer>
                <DialogContent className="caption" dividers>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Row>
                        <Col >
                            <Button
                                variant="text"
                                className="custom-input__button__secondary-color__forced"
                                onClick={handleDisagree}
                            >
                                {disagreeText}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="contained"
                                className="custom-input__button__primary-color__forced"
                                onClick={handleAgree}
                            >
                                {agreeText}
                            </Button>
                        </Col>
                    </Row>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalPDFView;