import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import ProgressBarCapacity from "../../../../../components/TimelineBar/ProgressBarCapacity";
import { CODES } from "../../../../../utils/codes";
import LoadingContent from "../../../../../components/loadingContent";
import { LoadingContentIA } from "../../../../../components/loadingContent";
import { findCompanyPlan } from "../../../../../services/plans/planServices";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { CustomToolTip } from "../../../../../components/Tooltip/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BoxContainer = ({ title, content, sx }) => {
  return (
    <Box
      sx={{
        borderRadius: "10px",
        boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.07)",
        padding: "20px",
        height: "max-content",
        ...sx,
      }}
    >
      <Row
        className="home-init-cards-row__title-row"
        style={{ fontSize: "18px" }}
      >
        {title}
      </Row>
      <br />
      <Row>{content}</Row>
    </Box>
  );
};
const CardsInformation = ({ color, icon, info, description, warning }) => {
  return (
    <Row
      style={{
        borderRadius: "0.8rem",
        boxShadow: "rgb(0 0 0 / 5%) 4px 4px 25px 3px",
        alignItems: "center",
        justifyContent: "center !important",
        padding: "8px",
        minHeight: "70px",
        gap: "4px",
      }}
    >
      <Col
        xs={"auto"}
        style={{
          backgroundColor: color,
          borderRadius: "5px",
          height: "40px",
          alignItems: "center",
          display: "flex",
        }}
      >
        {icon}
      </Col>
      <Col xs={"auto"}>
        <Row>
          <p
            className={
              warning
                ? "informative-cards__small__title__warning"
                : "informative-cards__small__title"
            }
            style={{ wordBreak: "break-all" }}
          >
            {info}
          </p>
        </Row>
        <Row>
          <p className="caption__disabled">{description}</p>
        </Row>
      </Col>
    </Row>
  );
};
const ManagePlanAdmin = () => {
  const [responseData, setResponseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [maxSignaturesExceed, setMaxSignaturesExceed] = useState(false);
  const [maxServiceUserExceed, setMaxServiceUserExceed] = useState(false);
  const [maxExternalUserExceed, setMaxExternalUserExceed] = useState(false);
  const [maxAdminUserExceed, setMaxAdminUserExceed] = useState(false);

  const [limitsExtension, setLimitsExtension] = useState({});
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      const response = await findCompanyPlan({ companyId });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        let limitExtensions =
          response.data.responseMessage.data.plan.limitsExtension || {};
        if (
          response.data.responseMessage.data.plan.actualUsers.serviceUsers >=
          response.data.responseMessage.data.plan.businessPlan.users
            .serviceUsers +
            limitExtensions.users.serviceUsers
        ) {
          setMaxServiceUserExceed(true);
        }
        if (
          response.data.responseMessage.data.plan.signaturesUsed >=
          response.data.responseMessage.data.plan.businessPlan.signatures +
            limitExtensions.signatures
        ) {
          setMaxSignaturesExceed(true);
        }

        if (
          response.data.responseMessage.data.plan.actualUsers.externalUsers >=
          response.data.responseMessage.data.plan.businessPlan.users
            .externalUsers +
            limitExtensions.users.externalUsers
        ) {
          setMaxExternalUserExceed(true);
        }

        if (
          response.data.responseMessage.data.plan.actualUsers.adminUsers >=
          response.data.responseMessage.data.plan.businessPlan.users
            .adminUsers +
            limitExtensions.users.adminUsers
        ) {
          setMaxAdminUserExceed(true);
        }
        setLimitsExtension(
          response.data.responseMessage.data.plan.limitsExtension
        );
        setResponseData(response.data.responseMessage.data);
      }
    } catch (error) {
      console.log("========= Start Handle Get Data Account Plan =========");
      console.error(error);
      console.log("========= End Handle Get Data Account Plan =========");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);
  return (
    <Container fluid className="custom-container-scroll">
      <Breadcrum />
      {isLoading && <LoadingContent />}
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Plan activo"} />
        </Col>
      </Row>
      <br />
      <Row>
        <Container
          style={{
            borderRadius: "10px",
            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.07)",
            padding: "30px",
            height: "max-content",
          }}
        >
          <Row
            className="home-init-cards-row__title-row"
            style={{ fontSize: "20px" }}
          >
            {responseData?.plan?.company?.companyName ||
              "Nombre de la compañía"}
          </Row>
          <br />
          <label className="caption">Consumos de tu plan</label>
          <br />
          <br />

          <Row style={{ justifyContent: "center" }}>
            <Col md={8}>
              <BoxContainer
                title={"Usuarios"}
                content={
                  <Row style={{ gap: 10 }}>
                    <Col xs={3}>
                      <CardsInformation
                        info={`${
                          responseData?.plan?.actualUsers?.adminUsers || "0"
                        }/${
                          responseData?.plan?.businessPlan?.users.adminUsers +
                            limitsExtension?.users?.adminUsers || "0"
                        }`}
                        description={"Administradores"}
                        icon={
                          <ManageAccountsIcon color="action" fontSize="large" />
                        }
                        warning={maxAdminUserExceed}
                        color="#E2EAED"
                      />{" "}
                    </Col>
                    <Col xs={3}>
                      <CardsInformation
                        info={`${
                          responseData?.plan?.actualUsers?.externalUsers || "0"
                        }/${
                          responseData?.plan?.businessPlan?.users
                            .externalUsers +
                            limitsExtension?.users?.externalUsers || "0"
                        }`}
                        description={"Solicitantes"}
                        icon={
                          <PersonOutlineOutlinedIcon
                            color="action"
                            fontSize="large"
                          />
                        }
                        color="#E2EAED"
                        warning={maxExternalUserExceed}
                      />
                    </Col>

                    <Col xs={4}>
                      {" "}
                      <CardsInformation
                        info={`${
                          responseData?.plan?.actualUsers?.serviceUsers || "0"
                        }/${
                          responseData?.plan?.businessPlan?.users.serviceUsers +
                            limitsExtension?.users?.serviceUsers || "0"
                        }`}
                        description={"De servicios"}
                        icon={
                          <ManageAccountsOutlinedIcon
                            color="action"
                            fontSize="large"
                          />
                        }
                        warning={maxServiceUserExceed}
                        color="#E2EAED"
                      />{" "}
                    </Col>
                    <Col xs={1}>
                      <CustomToolTip
                        direction="top"
                        message="Recuerda que los usuarios administradores puede entrar como usuarios de servicios, por eso, también se contabilizan aquí."
                      >
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </CustomToolTip>
                    </Col>

                    {maxServiceUserExceed && (
                      <Col xs={12}>
                        <span className="caption__error">
                          Has creado el máximo de usuarios permitidos en tu
                          plan,
                          <span className="caption__link"> contáctanos </span>
                          para ampliar la capacidad.
                        </span>
                      </Col>
                    )}
                  </Row>
                }
              />
            </Col>
            <Col md={4}>
              <BoxContainer
                title={"Almacenamiento en la nube"}
                content={
                  <ProgressBarCapacity
                    used={Number(responseData?.plan?.storageUsed)}
                    capacity={Number(
                      responseData?.plan?.businessPlan?.storage +
                        limitsExtension?.storage
                    )}
                    typeInfo={"GB"}
                    infoLabel={["usadas", "de"]}
                  />
                }
                sx={{ height: "15rem" }}
              />
            </Col>
          </Row>
          <Row className="justify-content-start mt-5">
            {" "}
            <Col md={4}>
              <BoxContainer
                title={"Firmas"}
                content={
                  <Row style={{ gap: 10 }}>
                    <Col xs={"auto"}>
                      <CardsInformation
                        info={`${responseData?.plan?.signaturesUsed || "0"}/${
                          responseData?.plan?.businessPlan?.signatures +
                            limitsExtension?.signatures || "0"
                        }`}
                        description={"Firmas consumidas de mi plan"}
                        icon={
                          <RateReviewOutlinedIcon
                            color="action"
                            fontSize="large"
                          />
                        }
                        warning={maxSignaturesExceed}
                        color="#E2EAED"
                      />
                    </Col>
                    {maxSignaturesExceed && (
                      <Col xs={12}>
                        <span className="caption__error">
                          Has creado el máximo número de firmas permitidas en tu
                          plan,
                          <span className="caption__link"> contáctanos </span>
                          para ampliar la capacidad.
                        </span>
                      </Col>
                    )}
                  </Row>
                }
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default ManagePlanAdmin;
