import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { DialogTitle, DialogContent } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const interval = 14000;

function shuffleArray(array) {
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

function SwipeableTextMobileStepper({ data }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [shuffledData, setShuffledData] = React.useState(shuffleArray(data));

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveStep((prevActiveStep) => (prevActiveStep + 1) % shuffledData.length);
    }, interval);
    return () => clearInterval(intervalId);
  }, [shuffledData.length]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 500, flexGrow: 1, maxHeight: 140 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={interval}
        enableMouseEvents
      >
        {shuffledData.map((step, index) => (
          <Paper
            key={index}
            square
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: 170,
              pl: 2,
              bgcolor: "background.default",
            }}
          >
            <DialogTitle>
              <label className="heading__primary-color">
                {step.title}
              </label>
            </DialogTitle>
            <DialogContent>
              <label className="caption text-aline-justify">
                {step.label}
              </label>
            </DialogContent>
          </Paper>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
