import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTag,
  deleteTag,
  getTagsByCompanyAndResourceType,
  updateTag,
} from "../../../services/tags/tagsServices";
import { CODES } from "../../../utils/codes";

export const tagContractTypesSlice = createSlice({
  name: "tagsContractTypes",
  initialState: {
    tagList: [],
    status: "fetch",
    statusCreate: "",
    statusModify: "",
    statusDelete: "",
    error: null,
    errorCreate: null,
    errorModify: null,
    errorDelete: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTagsContractTypes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTagsContractTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTagsContractTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tagList = action.payload.data.responseMessage;
      })
      .addCase(modifyTagsContractTypes.pending, (state, action) => {
        state.statusModify = "loading";
      })
      .addCase(modifyTagsContractTypes.rejected, (state, action) => {
        state.statusModify = "failed";
        state.errorModify = action.error.message;
      })
      .addCase(modifyTagsContractTypes.fulfilled, (state, action) => {
        state.statusModify = "succeeded";
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK && action.payload.data.success) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(deleteTagContractTypes.pending, (state, action) => {
        state.statusDelete = "loading";
      })
      .addCase(deleteTagContractTypes.rejected, (state, action) => {
        state.statusDelete = "failed";
        state.errorDelete = action.error.message;
      })
      .addCase(deleteTagContractTypes.fulfilled, (state, action) => {
        state.statusDelete = "succeeded";
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK && action.payload.data.success) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(addTagContractType.pending, (state, action) => {
        state.statusCreate = "loading";
      })
      .addCase(addTagContractType.rejected, (state, action) => {
        state.statusCreate = "failed";
        state.errorCreate = action.error.message;
      })
      .addCase(addTagContractType.fulfilled, (state, action) => {
        state.statusCreate = "succeeded";
        if (action.payload.status === CODES.COD_RESPONSE_HTTP_CREATED && action.payload.data.success) {
          state.tagList = action.payload.dataToAdd;
        }
      });
  },
});

export const getTagContractTypesList = (state) =>
  state.tagsContractTypes.tagList;

export const getTagContractTypesStatus = (state) =>
  state.tagsContractTypes.status;

export const getTagContractTypesStatusModify = (state) =>
  state.tagsContractTypes.statusModify;

export const getTagContractTypesStatusDelete = (state) =>
  state.tagsContractTypes.statusDelete;

export const fetchTagsContractTypes = createAsyncThunk(
  "tagsContractTypes/fetchTagsContractTypes",
  async (__, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      const response = await getTagsByCompanyAndResourceType({
        companyId,
        resourceType: "CONTRACTTYPES",
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const modifyTagsContractTypes = createAsyncThunk(
  "tagsContractTypes/modifyTagsContractTypes",
  async (data, { rejectWithValue }) => {
    try {
      let obj = {
        id: data.id,
        updateLabel: data.updateLabel,
        resourceType: "CONTRACTTYPES",
      };
      const response = await updateTag(obj);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK && response.data.success) {
        const companyId = localStorage.getItem("company");
        let obj = {
          companyId: companyId,
          resourceType: "CONTRACTTYPES",
        };
        const responseFetch = await getTagsByCompanyAndResourceType(obj);
        return {
          dataToAdd: responseFetch.data.responseMessage,
          data: response.data,
          status: response.status,
        };
      }
      return {
        status: response.status,
        data: response.data,
        update: data.updateLabel,
        id: data.id,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteTagContractTypes = createAsyncThunk(
  "tagsContractTypes/deleteTagContractTypes",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteTag({ id, resourceType: "CONTRACTTYPES" });
      if (response.status === CODES.COD_RESPONSE_HTTP_OK && response.data.success) {
        const companyId = localStorage.getItem("company");
        let obj = {
          companyId: companyId,
          resourceType: "CONTRACTTYPES",
        };
        const responseFetch = await getTagsByCompanyAndResourceType(obj);
        return {
          dataToAdd: responseFetch.data.responseMessage,
          data: response.data,
          status: response.status,
        };
      }
      return {
        id,
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addTagContractType = createAsyncThunk(
  "tagsContractTypes/addTagContractType",
  async (data, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      let obj = {
        name: data.name,
        description: data.description,
        company: companyId,
        resourceType: "CONTRACTTYPES",
      };
      const response = await createTag(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        response.data.success
      ) {
        const companyId = localStorage.getItem("company");
        let obj = {
          companyId: companyId,
          resourceType: "CONTRACTTYPES",
        };
        const responseFetch = await getTagsByCompanyAndResourceType(obj);
        return {
          dataToAdd: responseFetch.data.responseMessage,
          data: response.data,
          status: response.status,
        };
      }
      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export default tagContractTypesSlice.reducer;
