import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentDocument,
  setSelectedClauses,
} from "../../../../../../parts/document/currentDocumentSlice";
import ReactHtmlParser from "react-html-parser";
import { FillBlankSpaceClause } from "../../../../../../components/Blankspaces/FillBlankSpaceClause";
import { NUMBERINGWORDSO } from "../../../../../../utils/numberings";
import {
  getAllBlankSpaces,
  getFillBlankSpaces,
  setBlankSpacesIndex,
} from "../../../../../../utils/countBlankSpaces";
import ProgressBar from "../../../../../../components/Blankspaces/ProgressBar";
import {
  flashItemSelected,
  scrollToItem,
} from "../../../../../../utils/ScrollDocItems";

const FillBlankspaces = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentDocument = useSelector(getCurrentDocument);

  //state
  const [currentDocClauses, setCurrentDocClauses] = useState([]);
  const [countGeneralBlankspaces, setCountGeneralBlankspaces] = useState({});
  const [globalIndex, setGlobalIndex] = useState(0);

  const handleSubmitBlankspaces = () => {
    dispatch(setSelectedClauses({ clauses: currentDocClauses }));
    navigate(-1);
  };

  const handleCountGeneralFillBlankspaces = () => {
    if (currentDocClauses) {
      let count = 0;
      let total = 0;
      const formatedListClause = currentDocClauses?.filter(
        (clause) => getAllBlankSpaces({ clause: clause.clause }) !== 0
      );
      formatedListClause.forEach((clause) => {
        const fillBlankSpaces = getFillBlankSpaces({ clause: clause.clause });
        const allBlankSpaces = getAllBlankSpaces({ clause: clause.clause });
        count += fillBlankSpaces;
        total += allBlankSpaces;
      });
      const percentageComplete = Math.trunc((count / total) * 100);

      setCountGeneralBlankspaces({
        percentage: percentageComplete,
        countBlank: count,
        totalBlank: total,
      });
    }
  };

  //UseEffect
  useEffect(() => {
    setCurrentDocClauses(setBlankSpacesIndex(currentDocument.clauses));
  }, []);

  useEffect(() => {
    handleCountGeneralFillBlankspaces();
  }, [currentDocClauses]);

  useEffect(() => {
    if (id) {
      const checkElementExists = () => {
        let idElement = `${Number(id) + 1}.0.0`;
        const element = document.getElementById(idElement);

        if (element) {
          flashItemSelected(idElement);
          scrollToItem(`c.${idElement}`);
        } else {
          setTimeout(checkElementExists, 100);
        }
      };

      checkElementExists();
    }
  }, []);

  return (
    <Container fluid className="pb-3">
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={12} className="col-start">
          <SectionTitle title={`Rellenar espacios en blanco`} />
        </Col>
      </Row>
      <Row className="pt-3 pb-3">
        <Col xs={12}>
          <p className="caption">
            Diligencia la información a la izquierda y visualiza tu contrato en
            la sección derecha
          </p>
        </Col>
      </Row>
      <div>
        <Row>
          <Col xs={4} className="blankspaces__container" id="clauses__document">
            {" "}
            {currentDocClauses.map((selectedClause, index) => {
              return (
                getAllBlankSpaces(selectedClause) > 0 && (
                  <FillBlankSpaceClause
                    clause={selectedClause.clause}
                    indexClause={index}
                    setCurrentDocClauses={setCurrentDocClauses}
                    globalIndex={globalIndex}
                    setGlobalIndex={setGlobalIndex}
                  />
                )
              );
            })}
          </Col>
          <Col
            xs={8}
            className="blankspaces__container"
            id={"content-document"}
          >
            <Container fluid>
              <Row
                className="display-large justify-content-center"
                style={{ margin: "1% 0" }}
              >
                {currentDocument?.title}
              </Row>

              <Row style={{ margin: "1% 0" }}>
                {currentDocClauses?.length > 0 ? (
                  currentDocClauses?.map((clause, index) => {
                    const subclauses =
                      clause?.clause?.subclauses || clause?.subclauses;
                    const paragraphs =
                      clause?.clause?.paragraphs || clause?.paragraphs;
                    return (
                      <Row key={clause.clause._id} id={`content-clauses-${index}`}>
                        <Container className="container_text">
                          <Row style={{ margin: "1% 0" }}>
                            <Col md={12} className="heading__primary-color">{`${
                              index + 1
                            }. ${
                              clause?.title ||
                              clause?.clause?.title ||
                              "Sin titulo"
                            }`}</Col>
                          </Row>
                          <Row
                            className="container_text__clause-text label"
                            style={{ padding: "0 0 0 5%" }}
                            id={`${index + 1}.0.0`}
                          >
                            {ReactHtmlParser(
                              clause?.text || clause?.clause?.text
                            )}
                          </Row>
                        </Container>
                        {subclauses?.map((sub, index2) => (
                          <Container
                            key={sub.subclause._id}
                            id={`content-subclauses-${index}.${index2}`}
                            className="container_text__subclause"
                          >
                            <Row style={{ alignItems: "center" }}>
                              <Col
                                xs={"auto"}
                                className="heading__primary-color"
                              >
                                {`${index + 1}.${index2 + 1}. ${
                                  sub?.subclause?.title || ""
                                }`}
                              </Col>
                            </Row>
                            <Row style={{ alignItems: "center" }}>
                              <Col
                                className="container_text__clause-text label"
                                id={`${index + 1}.${index2 + 1}.0`}
                              >
                                {ReactHtmlParser(sub?.subclause?.text)}
                              </Col>
                            </Row>
                            <Row className="container_text__clause-text label"></Row>
                            {sub?.subclause?.childs?.map((child, index4) => (
                              <Container
                                key={child._id}
                                id={`content-subclauses-childs-${index}.${index2}.${index4}`}
                                className="container_text__subclause"
                              >
                                <Row>
                                  <Col className="heading__primary-color">{`${
                                    index + 1
                                  }.${index2 + 1}.${index4 + 1}. ${
                                    child.title || ""
                                  }`}</Col>
                                </Row>
                                <Row
                                  className="container_text__clause-text label"
                                  id={`${index + 1}.${index2 + 1}.${
                                    index4 + 1
                                  }`}
                                >
                                  {ReactHtmlParser(child.text)}
                                </Row>
                              </Container>
                            ))}
                          </Container>
                        ))}
                        {paragraphs?.map((para, index3) => (
                          <Container
                            key={para._id}
                            id={`content-paragraphs-${index}.${index3}`}
                            className="container_text__subclause"
                          >
                            <Row>
                              <Col className="heading__primary-color">{`Parágrafo ${
                                NUMBERINGWORDSO[index3 + 1]
                              } ${para?.paragraph?.title || ""}`}</Col>
                            </Row>
                            <Row
                              className="container_text__clause-text label"
                              id={`${index + 1}.0.${index3 + 1}`}
                            >
                              {ReactHtmlParser(para.paragraph.text)}
                            </Row>
                          </Container>
                        ))}
                      </Row>
                    );
                  })
                ) : (
                  <p className="container_text__clause-text label">
                    Sin cláusulas.
                  </p>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
      <Row className="justify-content-md-end" style={{ margin: "1% 0" }}>
        <Col xs={6}>
          <ProgressBar capacity={100} used={countGeneralBlankspaces} />
        </Col>
        <Col md={1}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => {
              navigate(-1);
            }}
          >
            Descartar
          </Button>
        </Col>
        <Col md={1}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleSubmitBlankspaces}
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FillBlankspaces;
