import React, { useState } from "react";
import Breadcrum from "../../../parts/breadcrum";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import SectionTitle from "../../../components/sectionTitle";
import { useForm } from "react-hook-form";
import LoadingContent from "../../../components/loadingContent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REGEXP } from "../../../utils/regexp";
import { ChangeAdminPassword } from "../../../services/admin/manageUserService";
import ModalInfo from "../../../components/Modals/modalInfo";
import { CODES } from "../../../utils/codes";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ChangeAdminPass = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isNewPasswordConfirmVisible, setIsNewPasswordConfirmVisible] =
    useState(false);

  const schema = yup.object().shape({
    password: yup.string().required("*Este campo es obligatorio"),
    newPassword: yup
      .string()
      .required("*Este campo es obligatorio")
      .min(8, "Mínimo 8 caracteres")
      .test("upperCase", "*Al menos una letra mayúscula", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_UPPER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("lowerCase", "*Al menos una letra minúscula", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_LOWER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("number", "*Al menos un número", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_NUMBER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("specialChar", "*Al menos un caracter especial (#?!@$%^&*-)", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_SPECIAL_CHAR);
          return schema.isValidSync(value);
        }
        return true;
      }),
    confirmPassword: yup
      .string()
      .required("*Este campo es obligatorio")
      .oneOf([yup.ref("newPassword"), null], "*La contraseña no coincide"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChange = (e) => {
    e.preventDefault();
  };

  const handleChangeAdminPass = async (data) => {
    setIsLoading(true);
    try {
      const obj = {
        PreviousPassword: data.password,
        ProposedPassword: data.newPassword,
      };

      const service = await ChangeAdminPassword(obj);
      setResponseData(service);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleCloseModalInfo = () => {
    setIsOpenModal(false);
    if (responseData.status === CODES.COD_RESPONSE_HTTP_OK) {
      navigate("/admin/manageUser");
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Cambiar contraseña"} />
      </Row>
      <Row>
        <Form
          style={{ marginTop: "40px" }}
          onSubmit={handleSubmit(handleChangeAdminPass)}
        >
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="caption">
                  Escriba su contraseña actual:{" "}
                </Form.Label>
                <InputGroup
                  bsPrefix={
                    errors.password
                      ? "input-group-container__error"
                      : "input-group-container"
                  }
                >
                  <Form.Control
                    size="lg"
                    {...register("password")}
                    bsPrefix="custom-input"
                    type={!isCurrentPasswordVisible ? "password" : "text"}
                    placeholder="Contraseña actual"
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={!isCurrentPasswordVisible ? faEye : faEyeSlash}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() =>
                      setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                    }
                  />
                </InputGroup>

                <p
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.password?.message}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="caption">
                  Escriba su nueva contraseña y confirmela:
                </Form.Label>
                <InputGroup
                  bsPrefix={
                    errors.password
                      ? "input-group-container__error"
                      : "input-group-container"
                  }
                >
                  <Form.Control
                    size="lg"
                    {...register("newPassword")}
                    type={!isNewPasswordVisible ? "password" : "text"}
                    placeholder="Nueva Contraseña"
                    bsPrefix="custom-input"
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={!isNewPasswordVisible ? faEye : faEyeSlash}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() =>
                      setIsNewPasswordVisible(!isNewPasswordVisible)
                    }
                  />
                </InputGroup>
                <p
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.newPassword?.message}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <InputGroup
                  bsPrefix={
                    errors.password
                      ? "input-group-container__error"
                      : "input-group-container"
                  }
                >
                  <Form.Control
                    size="lg"
                    {...register("confirmPassword")}
                    type={!isNewPasswordConfirmVisible ? "password" : "text"}
                    placeholder="Confirmar Nueva Contraseña"
                    onCopy={handleChange}
                    onPaste={handleChange}
                    bsPrefix="custom-input"
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={!isNewPasswordConfirmVisible ? faEye : faEyeSlash}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() =>
                      setIsNewPasswordConfirmVisible(
                        !isNewPasswordConfirmVisible
                      )
                    }
                  />
                </InputGroup>
                <p
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.confirmPassword?.message}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                type="submit"
                className="btn_blue_background"
                style={{ padding: "12px 24px" }}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    className="spinner__blue_background"
                  />
                ) : (
                  "Cambiar contraseña"
                )}
              </button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={
          responseData.status === CODES.COD_RESPONSE_HTTP_OK
            ? "Cambios guardados"
            : "Error al cambiar contraseña"
        }
        responseData={responseData}
        open={isOpenModal}
        onClose={handleCloseModalInfo}
      />
    </Container>
  );
};

export default ChangeAdminPass;
