import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
//.._.Material Import
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { MDBBadge, MDBDataTableV5 } from "mdbreact";
//._parts
import Breadcrum from "../../breadcrum";
//_...components
import CustomSearch from "../../../components/Datatable/customSearch";
import LoadingContent from "../../../components/loadingContent";
import SectionTitle from "../../../components/sectionTitle";
//.Icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import dateFormat from "dateformat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
//._.Redux
import { useDispatch, useSelector } from "react-redux";
//Services
import {
  fetchAlerts,
  getAlertsList,
  getAlertsStatus,
  setAlertStatus,
} from "../../../pages/views/userService/modules/alert/alertSlice";
//Utils
import { CODES } from "../../../utils/codes";
import DetailsBar from "../../../components/DetailsBar/DetailsBar";
import {
  getAlerts,
  updateAlertStates,
} from "../../../services/alerts/alertsServices";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ModalUploadDocs from "../../../components/Modals/ModalUploadDocs";
import ModalAssociateVersion from "../../../components/Modals/modalAssociateVersion";
import ModalDecision from "../../../components/Modals/modalDecision";
import { FOLDER_STATE } from "../../../utils/documentStates";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../folders/foldersSlice";
import ModalInfo from "../../../components/Modals/modalInfo";
import { NoResultsFound } from "../../../components/Results/NoResultsFound";
const DocumentsAlerts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  //Cambiar estado del loading
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [filteredWord, setFilteredWord] = useState("");
  const [filterMyId, setFilterMyId] = useState(false);
  const statusAlerts = useSelector(getAlertsStatus);
  const alertsList = useSelector(getAlertsList);
  const openMenu = Boolean(anchorEl);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreate = Boolean(anchorElCreate);
  const [documentData, setDocumentData] = useState({});
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [documentSelected, setDocumentSelected] = useState({});

  const columns = [
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Nombre de la alerta
        </label>,
      ],
      field: "alert",
    },
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Responsable
        </label>,
      ],
      field: "assignedUser",
    },
    {
      label: [
        <label
          key="2"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          {" "}
          Documento
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label
          key="3"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "150px" }}
        >
          Fecha de activación
        </label>,
      ],
      field: "alertdate",
    },
    {
      label: [
        <label
          key="4"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "150px" }}
        >
          Fecha de vencimiento
        </label>,
      ],
      field: "expiration",
      width: 150,
    },
    {
      label: [
        <label
          key="5"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "120px" }}
        >
          Estado
        </label>,
      ],
      field: "state",
    },
    {
      label: [
        <label
          key="6"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          style={{ width: "192px" }}
        >
          Acciones
        </label>,
      ],
      field: "seeAlert",
    },
  ];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [stateSelected, setStateSelected] = useState("");

  const [isOpenModalInfo, setIsOpenModalInfo] = useState({
    title: "",
    isOpen: false,
    response: {},
  });

  /**
   * useSelector
   */

  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const buildRows = () => {
    const alertsFilter = alertsList.filter(
      (alert) => alert.documentAssociated?._id === id
    );
    const getBackgroundColor = (colorId) => {
      if (colorId === CODES.COD_LIGHT_ORANGE) {
        return "#FFD7B1";
      } else if (colorId === CODES.COD_LIGHT_GREEN) {
        return "#CCF4E3";
      } else if (colorId === CODES.COD_LIGHT_PURPLE) {
        return "#E6E9EF";
      } else if (colorId === CODES.COD_COLOR_BLUE) {
        return "#FFD7B1";
      } else {
        return "#F9DADE";
      }
    };
    const rows = alertsFilter.map((alert) => {
      return {
        alert: alert?.name,
        assignedUser: "Nestor Plata",
        document: (
          <label
            onClick={() => {
              console.log("download");
            }}
            style={{
              textDecoration: "underline",
              color: "#067BBD",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          >
            {alert.documentAssociated?.name}
          </label>
        ),
        alertdate: dateFormat(alert.alertDate, "dd/mm/yyyy", true),
        expiration: dateFormat(alert.tasksDeadline, "dd/mm/yyyy", true),
        state: (
          <Row style={{ alignItems: "center" }}>
            <Col xs={"auto"}>
              <MDBBadge
                color=""
                pill
                className="caption"
                style={{
                  backgroundColor: getBackgroundColor(alert.state.colorId),
                  fontSize: "1.2rem",
                  boxShadow: "none",
                  padding: "0.4rem",
                }}
              >
                <span className="custom-badge">{alert.state.name}</span>
              </MDBBadge>
            </Col>
          </Row>
        ),
        seeAlert: (
          <Row>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  setSelectedAlert(alert);
                  setOpenModalDelete(true);
                }}
              >
                <DeleteOutlineOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  navigate(`/service/alerts/detail/${alert._id}/edit/${alert?.documentAssociated?._id}`);
                }}
              >
                <VisibilityOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
            <Col xs={"auto"}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => {
                  setOpenDetailsDrawner(true);
                  setSelectedAlert(alert);
                }}
              >
                <InfoOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
        ),
      };
    });
    return rows;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (statusAlerts === "fetch") {
          const company = localStorage.getItem("company");
          const corporateUnit = JSON.parse(
            localStorage.getItem("corporateUnit")
          );
          const obj = {
            company: company,
            corporateUnit: corporateUnit._id,
            filterMyId: filterMyId,
          };
          dispatch(fetchAlerts(obj));
        }
        const rows = buildRows();
        setDataTable({
          columns: columns,
          rows: rows,
        });
        setFilteredDataTable({
          columns: columns,
          rows: rows,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusAlerts, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusAlerts !== "fetch" && filterMyId) {
          setIsLoading(true);
          const company = localStorage.getItem("company");
          const corporateUnit = JSON.parse(
            localStorage.getItem("corporateUnit")
          );
          const obj = {
            company: company,
            corporateUnit: corporateUnit._id,
            filterMyId: filterMyId.toString(),
          };

          const service = await getAlerts(obj);
          if (service.status) {
            if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
              const rows = buildRows(service.data.responseMessage.data);
              setFilteredDataTable({
                columns: columns,
                rows: rows,
              });
            }
          }

          setIsLoading(false);
        } else if (!filterMyId) {
          const rows = buildRows(alertsList);
          setFilteredDataTable({
            columns: columns,
            rows: rows,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [filterMyId]);

  useEffect(() => {
    if (foldersStatus === "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [foldersStatus]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterByState = (state) => {
    setStateSelected(state);
    setFilteredDataTable({
      columns: columns,
      rows: buildRows(),
    });
    setAnchorEl(null);
  };

  const handleInfoStatus = () => navigate("/service/alerts/infoStatus");
  const handleDeleteFilterState = () => {
    setStateSelected("");
    setFilteredDataTable({
      columns: columns,
      rows: buildRows(),
    });
  };

  const handleClick = (event) => {
    setAnchorElCreate(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElCreate(null);
  };

  const handleDeleteAlert = async (idAlert, isSeries) => {
    try {
      setOpenModalDelete(false);
      setIsLoading(true);
      const updateRequest = await updateAlertStates({
        id: idAlert,
        newStatus: FOLDER_STATE.deleted._id,
        ...(isSeries && { isDeleteAllSerie: true }),
      });
      if (
        updateRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        updateRequest.data.success
      ) {
        dispatch(setAlertStatus({ status: "fetch" }));
        navigate("/service/alerts/delete");
      } else {
        setIsOpenModalInfo({
          isOpen: true,
          title: "Error",
          response: updateRequest.data.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Alertas"} />
        </Col>
        <Col>
          <Row style={{ justifyContent: "end" }} xs={"auto"}>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<AutoDeleteOutlinedIcon />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  navigate(`/service/alerts/delete`);
                }}
              >
                <p className="button">Papelera</p>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row xs={"auto"} className="mt-3">
        <p className="text-description">
          Crea, gestiona y personaliza tus alertas
        </p>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            onClick={handleClick}
          >
            <p className="button">Crear alerta</p>
          </Button>
          <Menu
            anchorEl={anchorElCreate}
            open={openCreate}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenModalUploadDocument(true);
                setAnchorElCreate(null);
              }}
            >
              <ListItemIcon>
                <UploadOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Cargar documento y asignar alerta
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenModalVersion(true);
                setAnchorElCreate(null);
              }}
            >
              <ListItemIcon>
                <FolderOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Seleccionar documento desde Legal AI
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={(event) => {}}>
              <ListItemIcon>
                <NotificationsOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Crear alerta sin asociar a documento
              </ListItemText>
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={4}>
          <CustomSearch
            placeholder="Buscar alerta"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
            maxLength={20}
            setFilteredWord={setFilteredWord}
          />
        </Col>
        <Col
          md={7}
          style={{ display: "flex", justifyContent: "end" }}
          className="ml-auto"
        >
          <Col
            md={4}
            className="body-content d-flex align-items-center justify-content-end"
          >
            <Form.Group controlId="Terminos">
              <Form.Check
                value={filterMyId}
                type="checkbox"
                label="Solo alertas creadas por mí"
                onChange={(e) => {
                  setFilterMyId(e.target.checked);
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              endIcon={<ExpandMoreIcon />}
              className="custom-input__button__secondary-color"
              onClick={handleFilterClick}
            >
              Filtrar por estado
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleFilterByState("Inactiva")}>
                <ListItemText>Inactiva</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleFilterByState("Activa")}>
                <ListItemText>Activa</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleFilterByState("Vencida")}>
                <ListItemText>Vencida</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleFilterByState("Completada")}>
                <ListItemText>Completada</ListItemText>
              </MenuItem>
            </Menu>
          </Col>
        </Col>
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right", marginRight: "1rem" }}>
        {stateSelected != "" && (
          <Col xs={"auto"}>
            <span className="caption custom-badges__disabled">
              {stateSelected + " "}{" "}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilterState()}
              />
            </span>
          </Col>
        )}
      </Row>
      <Row className="data-table dt-list-items">
        <MDBDataTableV5
          noRecordsFoundLabel={
            !filteredWord ? "Aún no tienes alertas creadas" : <NoResultsFound />
          }
          hover
          pagingTop
          searchBottom={false}
          entries={10}
          data={filteredDataTable}
          infoLabel={["Mostrando", "a", "de", "alertas"]}
          fullPagination
        />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <InfoOutlinedIcon fontSize="large" />
        </Col>
        <Col className="caption">
          Si quieres saber más acerca de los estados de una alerta
          <span
            className="caption__other-Color-Active"
            onClick={handleInfoStatus}
          >
            {` Haz click aquí`}
          </span>
        </Col>
      </Row>
      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedAlert}
        pageName={"Alert"}
      />

      <ModalUploadDocs
        open={openModalUploadDocument}
        title={"Selecciona el documento para crear la alerta"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o word y pesar máximo 75mb"
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setOpenModalUploadDocument(false);
        }}
        typeDocument={"Contrato"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf o Word"
        maxFileSize={78643200} //75MB
      />

      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalVersion(false);
          setDocumentSelected(file);
        }}
        onClose={() => {
          setOpenModalVersion(false);
        }}
        open={openModalVersion}
        folders={folders}
        versionSelected={documentSelected}
        title="Asociar documento"
      />
      <ModalDecision
        title={"Eliminar alerta"}
        message={
          selectedAlert?.isRepeated
            ? "¿Qué quieres eliminar?"
            : "¿Seguro que quieres eliminar la alerta?"
        }
        open={openModalDelete}
        onClose={setOpenModalDelete}
        agreeText={selectedAlert?.isRepeated ? "Solo esta alerta" : "Aceptar"}
        disagreeText={
          selectedAlert?.isRepeated
            ? "Todas las repeticiones de la alerta"
            : "Cancelar"
        }
        handleAgree={() => {
          handleDeleteAlert(selectedAlert._id);
        }}
        handleDisagree={() => {
          if (selectedAlert?.isRepeated) {
            handleDeleteAlert(selectedAlert._id, true);
          } else {
            setOpenModalDelete(false);
          }
        }}
      />
      <ModalInfo
        title={isOpenModalInfo.title}
        onClose={() =>
          setIsOpenModalInfo((prev) => ({ ...prev, isOpen: false }))
        }
        open={isOpenModalInfo.isOpen}
        responseData={isOpenModalInfo.response}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default DocumentsAlerts;
