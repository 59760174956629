import React from "react";
import { Row, Col } from "react-bootstrap";

export const generateGridItems = (unidadesCorporativas) => {
  const maxColumnas = 4;

  const totalUnidades = unidadesCorporativas.length;
  const filas = Math.ceil(totalUnidades / maxColumnas);

  const gridItems = [];

  for (let fila = 0; fila < filas; fila++) {
    const unidadInicio = fila * maxColumnas;
    const unidadFin = Math.min(unidadInicio + maxColumnas, totalUnidades);

    const rowItems = [];
    for (let columna = unidadInicio; columna < unidadFin; columna++) {
      rowItems.push(
        <Col key={columna} xs={12}>
          {unidadesCorporativas[columna]}
        </Col>
      );
    }

    gridItems.push(
      <Col xs={3}>
        <Row key={fila}>{rowItems}</Row>
      </Col>
    );
  }

  return gridItems;
};
