import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { IconButton } from "@mui/material";

const PopoverInfo = ({ section, icon = (<InfoOutlinedIcon fontSize="large" />), containerRef }) => {
  const popover = (
    <Popover style={{ margin: "0"}} className="custom-popover" id={`${section.title}`}>
      <Popover.Body className="body-content">{section.description}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger placement="auto" overlay={popover} trigger={["hover", "hover"]} container={containerRef?.current}>
      <IconButton style={{padding: 0}}>
        {icon}
      </IconButton>
    </OverlayTrigger>
  );
};

export default PopoverInfo;
