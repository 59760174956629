import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../components/loadingContent";
import ModalInfo from "../../../components/Modals/modalInfo";
import { CreateNewServicePassword } from "../../../services/general/authServices";
import { CODES } from "../../../utils/codes";
import { REGEXP } from "../../../utils/regexp";

const CreatePassword = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");

  const schema = yup.object().shape({
    temporalPassword: yup.string().required("*Este campo es obligatorio"),
    newPassword: yup
      .string()
      .required("*Este campo es obligatorio")
      .min(8, "Mínimo 8 caracteres")
      .test("upperCase", "*Al menos una letra mayúscula", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_UPPER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("lowerCase", "*Al menos una letra minúscula", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_LOWER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("number", "*Al menos un número", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_NUMBER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test(
        "specialChar",
        "*Al menos un caracter especial (#?!@$%^&*-)",
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(REGEXP.ONE_SPECIAL_CHAR);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    newPasswordConfirm: yup
      .string()
      .required("*Este campo es obligatorio")
      .oneOf([yup.ref("newPassword"), null], "*La contraseña no coincide"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const obj = {
        email: email,
        oldPassword: data.temporalPassword,
        newPassword: data.newPassword,
        role: "SERVICE",
      };
      const service = await CreateNewServicePassword(obj);
      if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
        navigate("/service");
        return;
      }
      setIsOpenModal(true);
      setResponseData({
        ...service,
        data: { responseMessage: service.data.message },
      });
    } catch (error) {
      console.log("========== Start Handle Change Password Error ==========");
      console.error({ error });
      console.log("========== Start Handle Change Password Error ==========");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="login-page-form">
      {isLoading && <LoadingContent />}
      <Container fluid>
        <Row
          className="container-forgot-password"
          style={{
            margin: "0% 20%",
            padding: "5% 0%",
          }}
        >
          <Container>
            <Row className="align-center">
              <p className="display-x-large__primary-color">
                Cambiar contraseña
              </p>
            </Row>
            <Row className="form-container__forgotPassword">
              <Form onSubmit={handleSubmit(handleForgotPassword)}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.code
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("temporalPassword")}
                      onChange={(event) => {
                        const value = event.target.value;
                        const formatedValue = value.trim();
                        setValue("temporalPassword", formatedValue);
                      }}
                      bsPrefix="custom-input"
                      placeholder="Contraseña enviada al correo"
                      type="password"
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.code?.message}
                  </div>
                </Form.Group>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.newPassword
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("newPassword")}
                      type="password"
                      bsPrefix="custom-input"
                      placeholder="Nueva contraseña"
                    />
                  </InputGroup>
                  <div className="caption custom-input__error">
                    {errors.newPassword?.message}
                  </div>
                </Form.Group>

                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.newPasswordConfirm
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("newPasswordConfirm")}
                      type="password"
                      bsPrefix="custom-input"
                      placeholder="Confirmar nueva contraseña"
                    />
                  </InputGroup>
                  <div className="caption custom-input__error">
                    {errors.newPasswordConfirm?.message}
                  </div>
                  <Form.Label className="caption__helper-text">
                    Usa 8 o más caracteres con una combinación de letras en
                    mayúscula y minúscula, números y símbolos
                  </Form.Label>
                </Form.Group>
                <Form.Group style={{ textAlign: "center", padding: "0% 10%" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="custom-input__button__primary-color"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        className="spinner__white-background"
                      />
                    ) : (
                      "Guardar"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            </Row>
          </Container>
        </Row>
      </Container>
      <ModalInfo
        title={"Actualización de contraseña"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </div>
  );
};

export default CreatePassword;
