import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function getTagsByCompanyAndResourceType(data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "GET",
    url: 'labels/getLabelsByCompanyIdAndResourceType',
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      company: data.companyId,
      resourceType: data.resourceType,
      corporateUnit
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateTag(data) {
  return axios({
    method: "PUT",
    url: `labels/update`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createTag(data) {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "POST",
    url: `labels/create`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      ...data,
      corporateUnit
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}


export function getTagById(data) {
  return axios({
    method: "GET",
    url: `labels/getById/${data}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}


export function deleteTag(data) {
  return axios({
    method: "DELETE",
    url: `labels/delete/${data.id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}