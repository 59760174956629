import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import ClearIcon from "@mui/icons-material/Clear";

import CustomSearchFilterButton from "../../../../../../../components/Search/CustomSearchFilterButton";

import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import { useDispatch, useSelector } from "react-redux";

import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import LoadingContent from "../../../../../../../components/loadingContent";
import { findPartByCompany } from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../../utils/codes";
import { PARTS_LIBRARY_STATE } from "../utils";
import FilterSigantory from "../../../../../../../parts/filters/FilterSignatory";
import {
  addNewPartWithSignatory,
  deletePartsList,
  getCurrentDocumentParts,
} from "../../../../../../../parts/document/currentDocumentSlice";

import { PopoverLimits } from "../../../../../../../components/Popover/PopoverLimits";
export const SelectPartForContract = () => {
  //Redux
  const dispatch = useDispatch();

  const currentParts = useSelector(getCurrentDocumentParts);

  //State

  const [signatoriesList, setSignatoriesList] = useState([]);
  const [deletedPartsList, setDeletedPartsList] = useState([]);

  const [signatoriesSelected, setSignatoriesSelected] = useState([]);

  const [openModalNoParts, setOpenModalNoParts] = useState(false);

  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre de la parte
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación de la parte
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Nombre del firmante
        </label>,
      ],
      field: "signatoryName",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Cargo del firmante
        </label>,
      ],
      field: "signatoryPosition",
    },
  ];
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });
  const [partsSelected, setPartsSelected] = useState([]);

  const [partsList, setPartsList] = useState([]);

  const [selectedParts, setSelectedParts] = useState([]);

  const [isLoading, setIsLoading] = useState(null);

  //Router

  const navigate = useNavigate();

  const { partIndex } = useParams();

  //Handles

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedParts((oldArray) => [...oldArray, item]);

      setDeletedPartsList(
        deletedPartsList.filter((itm) => itm?._id !== item?._id)
      );
    } else {
      setDeletedPartsList([...deletedPartsList, item]);
      setSelectedParts(selectedParts.filter((itm) => itm?._id !== item?._id));
    }
  };

  const selectDefaultSignatory = (item) => {
    let defaultSignatory = {};
    item?.relatedSignatories.forEach((signatory) => {
      if (item?.typePart === "JURIDICA") {
        if (signatory.position === "REPRESENTANTE LEGAL") {
          defaultSignatory = signatory;
        }
      } else {
        if (signatory?.isDefault) {
          defaultSignatory = signatory;
        }
      }
    });

    if (
      !Object.keys(defaultSignatory).length &&
      item?.typePart === "JURIDICA"
    ) {
      item?.relatedSignatories.forEach((signatory) => {
        if (signatory?.isDefault) {
          defaultSignatory = signatory;
        }
      });
    }
    return defaultSignatory;
  };

  const handleSignatoriesSelected = (signatories) => {
    setSignatoriesSelected(signatories);
  };

  const getSignatoryText = (signatory, part) => {
    let text = "";
    const currentSignatory = signatory?.signatory;

    if (part.typePart === "JURIDICA") {
      text = ` ${
        part?.business?.businessName || "¬razón social¬"
      } que se identifica con ${
        part?.business?.type || "¬tipo de documento¬"
      } ${part?.business?.nit || "¬número de documento¬"} con domicilio en ${
        part?.physicalAddress || "¬domicilio¬"
      } representada por ${
        getName(currentSignatory) || "¬Nombre¬"
      } quien se identifica con ${
        currentSignatory.otherTypeDocument ||
        currentSignatory?.documentType ||
        "¬tipo de documento¬"
      } número ${
        currentSignatory?.documentNumber || "¬número de documento¬"
      } y que actúa en calidad de ${
        signatory?.position || "¬cargo del firmante¬"
      }`;
    } else {
      text = `${
        getName(currentSignatory) || "¬Nombre¬"
      } quien se identifica con ${
        currentSignatory.otherTypeDocument ||
        currentSignatory?.documentType ||
        "¬tipo de documento¬"
      } número ${
        currentSignatory?.documentNumber || "¬número de documento¬"
      } y con domicilio en ${currentSignatory?.address || "¬domicilio¬"}`;
    }
    return text;
  };

  const handleDeleteSignatorySelected = (signatory) => {
    const newSigantories = signatoriesSelected.filter(
      (t) => t._id !== signatory
    );
    setSignatoriesSelected(newSigantories);
    if (newSigantories.length > 0) {
      let filteredSignatories = [];
      const selectedList = newSigantories.map((item) => item._id);
      filteredData.rows.forEach((item) => {
        const list = item.relatedSignatories;
        const filterList = list.some((item) => selectedList.includes(item._id));
        if (filterList) {
          filteredSignatories.push(item);
        }
      });

      setFilteredData({ ...filteredData, rows: filteredSignatories });
    } else {
      setFilteredData(data);
    }
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    let signatoriesDefaultList = [];
    for (const item of data) {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;

      if (item?.typePart === "JURIDICA") {
        name = item?.business?.businessName;
        id = `Nit. ${item?.business?.nit}`;
      }

      const defaultSignatory = selectDefaultSignatory(item);
      signatoriesDefaultList.push(defaultSignatory);
      formatedData.push({
        name,
        defaultSignatory,
        signatoryName: (
          <Row className="align-items-center">
            <Col xs={12}>
              <span className="caption__primary-color__active ">
                {getName(defaultSignatory?.signatory)}
              </span>
            </Col>
          </Row>
        ),
        signatoryPosition: (
          <Row className="align-items-center">
            <Col xs={"auto"} lg={8}>
              <span className="caption__primary-color__active ">
                {defaultSignatory.position}
              </span>
            </Col>

            {defaultSignatory?.limitations && (
              <Col xs={"auto"} lg={4}>
                <PopoverLimits
                  limitationsUpdatedDate={
                    defaultSignatory?.limitationsUpdatedDate
                  }
                  createdAt={defaultSignatory?.signatory?.createdAt}
                  limitations={defaultSignatory?.limitations}
                />
              </Col>
            )}
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedParts?.some((itm) => itm?._id === item?._id)}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    setSignatoriesList(
      signatoriesDefaultList
        .filter((item) => item.signatory)
        .map((item) => item.signatory)
    );

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const buildPartData = (part) => {
    let information = {
      address: part?.physicalAddress,
      email: part?.email,
      documentNumber:
        part?.typePart === "JURIDICA"
          ? part?.business?.nit
          : part.documentNumber,
      documentType:
        part?.typePart === "JURIDICA"
          ? part?.business?.type
          : part?.documentType,
      name:
        part?.typePart === "JURIDICA"
          ? part?.business?.businessName
          : getName(part),
      _id: part._id,
    };
    const defaultSignatory = [];
    const signatory = selectDefaultSignatory(part);

    let informationText = "";

    if (Object.keys(signatory).length) {
      informationText = getSignatoryText(signatory, part);
      const newSignatory = {
        address: signatory?.signatory?.residence?.physicalAddress,
        documentNumber: signatory?.signatory?.documentNumber,
        documentType: signatory?.signatory?.documentType,
        name: getName(signatory?.signatory) || "",
        email: signatory?.signatory?.email,
        charge: signatory?.position,
        text: informationText,
        isDefault: true,
        _id: signatory.signatory?._id,
      };
      defaultSignatory.push(newSignatory);
      information = { ...information, signatory: newSignatory };
    }

    return {
      gender: "",
      information,
      signatories: defaultSignatory,
      text: informationText,
      type: part?.typePart,
      limits: part?.legalRepresentative?.limitations || "",
      limitationsUpdatedDate: signatory?.limitationsUpdatedDate,
      limitationsCreatedDate: signatory?.signatory?.createdAt,
    };
  };

  const handleAddPart = () => {
    try {
      setIsLoading(true);

      selectedParts.forEach((part) => {
        const values = buildPartData(part);

        dispatch(
          addNewPartWithSignatory({
            partIndex: partIndex,
            newPart: values,
          })
        );
      });

      if (deletedPartsList.length) {
        deletedPartsList.forEach((part) => {
          dispatch(
            deletePartsList({ partIndex: partIndex, deletedPart: part })
          );
        });
      }

      navigate(-1);
    } catch (error) {
      console.log(
        "============ Handle Create Signatory Error Start ============"
      );
      console.error(error);
      console.log(
        "============ Handle Create Signatory Error End ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = localStorage.getItem("company");

        let associatedCorporateUnits = [
          localStorage.getItem("corporateUnitId"),
        ];
        let deletedBy = null;

        if (data?.userId) deletedBy = data.userId;
        const status = PARTS_LIBRARY_STATE.ACTIVE._id;
        setIsLoading(true);
        const response = await findPartByCompany({
          company,
          status,
          associatedCorporateUnits,
          deletedBy,
        });
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const list = response.data?.responseMessage?.data;
          setPartsList(list);

          if (!list.length) {
            setOpenModalNoParts(true);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (currentParts && partsList && partIndex) {
      currentParts[partIndex]?.signatories?.forEach((part) => {
        let foundPart = partsList.find(
          (item) => item?._id === part?.information?._id
        );
        if (foundPart) {
          setSelectedParts((oldArray) => [...oldArray, foundPart]);
        }
      });
    }
  }, [partsList]);

  useEffect(() => {
    const tranformData = buildData(partsList);

    setData({ rows: tranformData, columns });
    setFilteredData({ rows: tranformData, columns });
  }, [partsList, selectedParts]);

  return (
    <Container fluid className="custom-container-scroll ">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Partes guardadas`} />
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"} className="caption">
          {`Escoge la o las partes que quieras agregar en el contrato`}
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={"auto"}>
            <FilterSigantory
              list={data}
              setFilteredInfo={setFilteredData}
              handleSignatorySelected={handleSignatoriesSelected}
              sigantoryList={signatoriesList}
              currentSignatoriesSelected={signatoriesSelected}
            />
          </Col>
        </Col>
      </Row>

      <br />
      <Row style={{ justifyContent: "end" }}>
        {signatoriesSelected.map((signatory) => {
          return (
            <Col xs={"auto"} key={signatory?._id}>
              <span className="caption custom-badges__secundary">
                {signatory.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteSignatorySelected(signatory._id)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row className="dt-list-center-content " style={{ marginBottom: "7rem" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes partes creadas"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckIcon fontSize="large" />}
            disabled={!selectedParts.length && !deletedPartsList.length}
            onClick={handleAddPart}
          >
            Seleccionar
          </Button>
        </Col>
      </Row>

      <ModalInfo
        title="Sin partes guardadas"
        onClose={() => setOpenModalNoParts(false)}
        open={openModalNoParts}
        responseData={{
          data: {
            message: "No existen partes guardadas en biblioteca",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
    </Container>
  );
};
