export const SIGNATORY_PROPERTIES_TRANSLATION = {
  firstName: "Primer nombre",
  firstSurname: "Primer apellido",
  lastName: "Segundo nombre",
  secondSurname: "Segundo apellido",
  documentType: "Tipo de documento",
  documentNumber: "Número de documento",
  otherTypeDocument: "Otro tipo de documento",
  email: "Correo electrónico",
  mobileNumberData: "Número de celular",
  telephoneNumberData: "Número de teléfono",
  residence: "Residencia",
  company: "Compañía",
  relatedParties: "Partes relacionadas",
  associatedCorporateUnits: "Unidades corporativas asociadas",
  business: "Datos de identificación",
  labels: "Etiquetas",
  relatedSignatories: "Firmantes relacionados",
  documentAssociated: "Documento asociado",
  tasksDeadline: "Fecha de vencimiento de tareas",
  name: "Nombre",
  country: "País",
  alertDate: "Fecha de alerta",
  description: "Descripción",
  comercialName: "Nombre comercial",
  province: "Provincia o región",
  city: "Ciudad",
  physicalAddress: "Dirección física",
  legalRepresentative: "Datos del representante legal",
  extension: "Extensión",
  cityCode: "Codigo Ciudad",
  secondName: "Segundo nombre",
  numberTelephone: "Teléfono Fijo",
  countryCodeNumber: "Codigo País Celular",
  countryCodeTelephone: "Codigo País Telefono",
  emails: "Correos",
  tasks: "Tareas",
  createdBy: "Creada por",
  corporateUnit: "Unidad corporativa",
  assignedTo: "Usuario asignado",
};

export const MANAGE_SIGNATORIES_PARTS_ACTIONS = {
  ADD: "ADD",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
};
