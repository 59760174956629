import { getFileDocument } from "../services/documents/documentsRequest";

const { PDFDocument } = require("pdf-lib");

export const handleViewDocument = async ({
  filenameInBucket,
  bucketName,
  filename,
}) => {
  try {
    const downloadDocument = await getFileDocument(
      filenameInBucket,
      bucketName,
      false
    );
    const pdfData = new Uint8Array(
      downloadDocument.data.responseMessage.buffer.data
    );

    const pdfDoc = await PDFDocument.load(pdfData);
    pdfDoc.setTitle(filename);
    const pdfBytes = await pdfDoc.save();
    const pdfbuffer = new Uint8Array(pdfBytes);
    const pdfBlob = new Blob([pdfbuffer], { type: "application/pdf" });
    const pdfURL = URL.createObjectURL(pdfBlob);
    return window.open(pdfURL, "_blank");
  } catch (error) {
    console.error(error);
  }
};
