import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalSaveFileAndType = ({
  onClose,
  open,
  handleAgree,
  saveOptions,
  defaultValues,
}) => {
  const handleClose = () => {
    onClose();
    reset();
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      fileName: defaultValues?.fileName || "",
      saveType: "",
    },
  });

  useEffect(() => {
    if (defaultValues.fileName) {
      setValue("fileName", defaultValues.fileName);
    }
  }, [defaultValues]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">Guardar como</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Row>
            <Col xs={12}>
              <label className="caption">
                Escriba el nombre para este nuevo archivo:
              </label>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Control
                {...register("fileName", {
                  required: "El nombre del archivo es requerido",
                  maxLength: {
                    value: 250,
                    message:
                      "El nombre del archivo no puede exceder los 250 caracteres",
                  },
                })}
                className="label"
                size="lg"
                defaultValue=""
                type="text"
                placeholder=""
                maxLength={250}
              />
            </Col>
            <Col xs={12} className="mt-2">
              <p className="caption custom-input__error">
                {errors.fileName?.message}
              </p>
              <label
                className={
                  watch("fileName") && watch("fileName")?.length === 250
                    ? "caption__warning"
                    : "caption__primary-color"
                }
              >
                Máximo 250 caracteres permitidos
              </label>
            </Col>
            <Col xs={12} className="mt-3">
              <label className="caption">
                Elige como quieres guardar el archivo
              </label>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Select
                {...register("saveType", {
                  required: "El tipo del archivo es requerido",
                })}
                className="label mb-1"
                defaultValue=""
              >
                <option className="label" value="" disabled>
                  Guardar documento como
                </option>
                {saveOptions.map((option, index) => (
                  <option key={index} className="label" value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                onClick={handleClose}
                className="custom-input__button__secondary-color__forced"
              >
                Volver
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleSubmit(handleAgree)}
                variant="contained"
                className="custom-input__button__primary-color__forced"
                disabled={!watch("saveType")}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSaveFileAndType;
