const isOrderItemArray = (item) => {
    let returnElement;
    if (item.every(value => typeof value === 'string')) {
        returnElement = item.join("")
    } else {
        for (const value of item) {
            if (typeof value === 'string') {
                returnElement = value
            }
        }
    }
    return returnElement;
}

const orderChildren = (children, element) => {
    let result = element;
    if (typeof children === 'string') {
        result = children;
    } else {
        result = typeof result === 'string' ? [result, children] : [...result, children];
    }
    return result;
}

export const validateIsArrayComponent = (element) => {
    if (!element) return;
    if (!Array.isArray(element) && typeof element === 'object') return element?.props?.children;
    if (typeof element?.props?.children === 'string') return element?.props?.children;
    let returnElement = [];
    for (const item of element) {
        if (item?.props?.children) {
            returnElement = orderChildren(item?.props?.children, returnElement);
        } else if (typeof item === 'string') {
            returnElement = item;
        } else if (Array.isArray(item)) {
            returnElement = isOrderItemArray(item);
        }
    }
    return returnElement;
}
export const validateElementHTML = (element) => {
    let counter = 1;
    const typeElement = typeof element;
    if (typeElement === "string") return element;
    if (typeElement === "number") return element.toString();
    let newElement = element;
    while (typeof newElement !== "string") {
        if (counter > 100) return;
        if (typeof newElement === 'string') return newElement;
        newElement = validateIsArrayComponent(newElement);
        counter++;
    }
    let value;
    if (typeof newElement === "string") {
        value = newElement;
    }
    return value;
};

function compareValues(a, b, direction) {
    let value1 = validateElementHTML(a) || '';
    let value2 = validateElementHTML(b) || '';
    if (value1.toLowerCase() < value2.toLowerCase()) return direction === "asc" ? -1 : 1;
    if (value1.toLowerCase() > value2.toLowerCase()) return direction === "asc" ? 1 : -1;
    return 0;
}
export const onSortHTMLElement = ({
    excludesColumns = [],
    sort,
    filteredDataTable,
    setFilteredDataTable,
    otherListValues = null,
}) => {
    let newDirection;
    let filter;
    let extraValues;
    if (!sort) return;
    const { column, direction } = sort;
    if (excludesColumns.includes(column) || !filteredDataTable || !setFilteredDataTable) return;
    if (otherListValues) {
        const data = otherListValues.map((value, index) => ({ index, value}));
        const orderData = data.sort((a, b) => {
            return compareValues(a.value[column], b.value[column], direction);
        });
        const result = orderData.map((item) => filteredDataTable.rows[item.index]);
        filter = result;
        extraValues = orderData.map(item => item.value);
    } else {
        filter = filteredDataTable.rows.sort((a, b) => {
            return compareValues(a[column], b[column], direction);
        });
    }
    newDirection = direction === "asc" ? "desc" : "asc";
    const newColumns = filteredDataTable.columns.map((item) =>
        item.field === column ? { ...item, sort: newDirection } : item
    );
    setFilteredDataTable({ columns: newColumns, rows: filter, extraValues });
};
