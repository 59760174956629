import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Drawer } from "@mui/material";
import { Buffer } from "buffer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCurrentDocument,
  setIdMongo,
  setInitialInfoLibrary,
  setName,
  setSelectedClauses,
} from "../../parts/document/currentDocumentSlice";
import {
  compareVersions,
  getDocById,
  getFileDocument,
  segmentDocument,
} from "../../services/documents/documentsRequest";
import { CODES } from "../../utils/codes";
import LoadingContent from "../loadingContent";
import { v4 as uuid } from "uuid";
import { convertToPdfService } from "../../services/utils/convertWordToPdf";
const VersionBar = ({
  isOpen,
  setIsOpen,
  setIsLoadingFetch,
  from = "versions",
  documentId = null,
  handleAction = null,
}) => {
  //hooks
  const dispatch = useDispatch();
  const currentDocument = useSelector(getCurrentDocument);
  const { idDocument } = useParams();

  //state
  const [documentVersions, setDocumentVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(currentDocument._id);

  //handle
  const handleSelectedVersion = (version) => {
    setSelectedVersion(version.versionId._id);
    const a = { ...version.versionId.documentContent.parts.partA };
    const b = { ...version.versionId.documentContent.parts.partB };

    let parts = [a, b];

    if (version.versionId.documentContent.parts.partC) {
      const c = { ...version.versionId.documentContent.parts.partC };
      parts.push(c);
    }

    if (version.versionId.documentContent.parts.partD) {
      const d = { ...version.versionId.documentContent.parts.partD };
      parts.push(d);
    }

    const finalObj = {
      title: version.versionId.documentContent.title,
      parts: parts,
    };
    dispatch(setInitialInfoLibrary(finalObj));
    dispatch(
      setSelectedClauses({
        clauses: version.versionId.documentContent.clauses.map((cla) => {
          return cla.parts
            ? {
                ...cla,
                parts: cla.parts.partD
                  ? [
                      cla.parts.partA,
                      cla.parts.partB,
                      cla.parts.partC,
                      cla.parts.partD,
                    ]
                  : cla.parts.partC
                  ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
                  : [cla.parts.partA, cla.parts.partB],
              }
            : cla;
        }),
      })
    );
    dispatch(
      setName({
        name: version.versionId.documentContent.name,
      })
    );
    dispatch(
      setIdMongo({
        id: version.versionId.documentContent._id,
      })
    );
  };
  const handleCompareVersion = async () => {
    await compareVersions({
      versionToBeComparedId: selectedVersion,
      versionToCompareId:
        documentVersions[documentVersions.length - 1].versionId._id,
    });
  };

  const handleSelectedVersionThirdPartRequest = async (version) => {
    try {
      setIsLoading(true);
      setIsLoadingFetch(true);
      const getRequest = await getFileDocument(
        version.filenameInBucket,
        version.bucketName,
        version.isWord
      );
      if (
        getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        getRequest.data.success
      ) {
        if (version?.versionRelated) {
          buildDocument(version?.versionRelated?.documentContent);
          return;
        }
        const company = localStorage.company;
        const documentName =
          getRequest.data.responseMessage.contentType === "application/pdf"
            ? version.originalFilename + ".pdf"
            : version.originalFilename + ".docx";
        let docView = getRequest.data.responseMessage.buffer;
        if (getRequest.data.responseMessage.contentType !== "application/pdf") {
          const { data } = getRequest.data.responseMessage.buffer || {};
          const { contentType } = getRequest.data?.responseMessage || {};
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);
          docView = pdfConverted?.data?.responseMessage?.data?.buffer;
        }
        const bf = Buffer.from(docView.data);
        const blob = new File([bf], documentName, {
          type: "application/pdf",
        });

        const segmentRequest = await segmentDocument({ file: blob, company });
        if (
          segmentRequest.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          segmentRequest.data.success
        ) {
          buildDocument(segmentRequest.data.responseMessage);
          setIsLoading(false);
        } else {
          alert(segmentRequest.data.responseMessage.message);
          setIsLoading(false);
        }
      } else {
        alert(getRequest.data.responseMessage.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingFetch(false);
    }
    setSelectedVersion(version._id);
  };

  const buildDocument = (document) => {
    const a = { ...document.parts.partA };
    const b = { ...document.parts.partB };

    let parts = [a, b];

    if (document.parts.partC) {
      const c = { ...document.parts.partC };
      parts.push(c);
    }

    if (document.parts.partD) {
      const d = { ...document.parts.partD };
      parts.push(d);
    }

    const finalObj = {
      title: document.title,
      parts: parts,
    };
    dispatch(setInitialInfoLibrary(finalObj));
    const clauses = document.clauses.map((cla) => {
      return cla.parts
        ? {
            ...cla,
            parts: cla.parts.partD
              ? [
                  cla.parts.partA,
                  cla.parts.partB,
                  cla.parts.partC,
                  cla.parts.partD,
                ]
              : cla.parts.partC
              ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
              : [cla.parts.partA, cla.parts.partB],
          }
        : cla;
    });
    dispatch(setSelectedClauses({ clauses }));
    dispatch(setName({ name: document.name }));
    dispatch(setIdMongo({ id: document._id }));
  };

  //useEffect
  useEffect(() => {
    const getVersionsRequest = async (id) => {
      try {
        setIsLoading(true);
        const getRequest = await getDocById(id);
        if (
          getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
          getRequest.data.success
        ) {
          setDocumentVersions(
            getRequest.data.responseMessage.versions.reverse()
          );
        } else {
          alert(getRequest.data.responseMessage);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (isOpen && idDocument !== "0" && from === "versions") {
      getVersionsRequest(idDocument);
    } else if (isOpen && from === "folders") {
      getVersionsRequest(documentId);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentDocument.versions.length) {
      setDocumentVersions(
        currentDocument.versions.map((version) => ({
          versionId: { documentContent: version },
        }))
      );
      const versionSelected = currentDocument.versions.find(
        (version) => version._id === currentDocument._id
      );
      handleSelectedVersionThirdPartRequest(versionSelected);
    }
  }, [currentDocument]);

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      PaperProps={{ style: { width: "28%" } }}
    >
      {isLoading && <LoadingContent />}
      <Container fluid>
        <Row className=" general-container-version__title">
          <Col md={11} className="heading__primary-color">
            Panel de navegación entre versiones
          </Col>
          <Col md={1}>
            <CloseIcon
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Container fluid className="general-container-version">
            <Row className="heading__hover version-container__box__subtitle">
              Historial de versiones
            </Row>
            {documentVersions.length ? (
              documentVersions.map((principal, index) => (
                <Container
                  key={uuid()}
                  className={
                    selectedVersion === principal?.versionId?._id
                      ? "version-container__box__selected"
                      : "version-container__box"
                  }
                  onClick={() => {
                    if (from === "versions") {
                      currentDocument?.versions?.length
                        ? handleSelectedVersionThirdPartRequest(
                            principal?.versionId?.documentContent
                          )
                        : handleSelectedVersion(principal);
                    }
                  }}
                >
                  <Row className="button version-container">
                    {index === 0 ? (
                      <p style={{ padding: "0" }}>
                        {`Version ${documentVersions?.length - index} `}
                        <span className="heading__hover">{`(Version Actual)`}</span>
                      </p>
                    ) : (
                      `Version ${documentVersions?.length - index}`
                    )}
                  </Row>
                  <Row className="caption version-container">
                    {moment(principal?.versionId?.createdAt).format(
                      "DD/MM/YYYY"
                    )}
                  </Row>
                  <Row className="version-container">
                    {from === "versions" && (
                      <>
                        <Col xs={1}>
                          <CircleIcon className="icon-version" />
                        </Col>
                        <Col className="caption">
                          {principal?.versionId?.documentContent?.author ||
                            principal?.versionId?.documentContent?.createdBy ||
                            "Sin autor"}
                        </Col>
                      </>
                    )}
                    {from === "folders" && (
                      <Col xs={"auto"}>
                        <Button
                          variant="contained"
                          className="custom-input__button__primary-color"
                          onClick={() => {
                            handleAction(principal);
                          }}
                        >
                          Ver esta versión
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {selectedVersion === principal?.versionId?._id &&
                    from === "versions" && (
                      <>
                        <Row className="caption justify-content-md-center">
                          Comparar esta versión con:
                        </Row>
                        <Row
                          className="justify-content-md-center"
                          style={{ margin: "3% 0" }}
                        >
                          <Col xs={6}>
                            <Button
                              variant="contained"
                              className="custom-input__button__primary-color"
                              onClick={() => {
                                handleCompareVersion();
                              }}
                            >
                              Versión Anterior
                            </Button>
                          </Col>
                          <Col xs={6}>
                            <Button
                              variant="contained"
                              className="custom-input__button__primary-color"
                              onClick={() => {
                                handleCompareVersion();
                              }}
                            >
                              Primera Versión
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                </Container>
              ))
            ) : (
              <Container className={"version-container__box caption"}>
                No tiene versiones
              </Container>
            )}
          </Container>
        </Row>
        <Row className="justify-content-md-end general-container-version">
          <Col md={5}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => {
                setIsOpen(false);
              }}
              startIcon={<ArrowBackIcon />}
            >
              Volver
            </Button>
          </Col>
        </Row>
      </Container>
    </Drawer>
  );
};

export default VersionBar;
