export const DOCUMENT_STATE = {
    deleted: {
      _id: "621fdcd7e1b7dfa85f10bf46",
      name: "Eliminada",
    },
    archived: {
      name: "Archivada",
      _id: "645e9397d63a6f94afba6766"
    },
    active: {
      _id: "621fdce7e1b7dfa85f10bf48",
      name: "Activo",
    }
  };
  export const FOLDER_STATE = {
    deleted: {
      _id: "621fdcd7e1b7dfa85f10bf46",
      name: "Eliminada",
    },
    new: {
      _id: "621fdca6e1b7dfa85f10bf44",
      name: "nuevo",
    }
  };