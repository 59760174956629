import { Button, ButtonBase, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { countries } from "../../../../../../../../utils/countries";
import { REGEXP } from "../../../../../../../../utils/regexp";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { Controller } from "react-hook-form";
import {
  fetchTags,
  getStatus,
  getTagList,
} from "../../../../../../../../parts/manageTags/tagSlice";
import { useDispatch, useSelector } from "react-redux";
import ModalAssociateTags from "../../../../../../../../components/Modals/ModalAssociateTags";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { ModalInfoSignatories } from "../../../../../../../../components/Modals/ModalInfoSignatories";
export const UpdateContactInfo = (props) => {
  const {
    register,
    errors,
    watch,
    handleBack,
    control,
    setValue,
    tagsSelected,
    setTagsSelected,
    selectedSignatories,
    setSelectedSignatories,
    currentPart,
    deletedSignatoryList,
    setDeletedSignatoryList,
    isChangeValue
  } = props;

  //states

  const [isOpenModalSignatoriesInfo, setIsOpenModalSignatoriesInfo] =
    useState(false);
  const [dataSignatoryModal, setDataSignatoryModal] = useState(null);
  const [enableButtonSave, setEnableButtonSave] = useState(isChangeValue);

  //redux
  const dispatch = useDispatch();
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);

  //handles
  const handleTagsSelected = (tags) => {
    if (!tags?.length) return;
    const tagsId = tags.map((tag) => tag._id);
    setValue("labels", tagsId);
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  const handleDeleteSignatory = (signatory) => {
    setDeletedSignatoryList([...deletedSignatoryList, signatory]);
    setSelectedSignatories(selectedSignatories.filter((t) => t !== signatory));
  };
  const getName = (item) => {
    return `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
  };

  //UseEffect

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("PARTSLIBRARY"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  return (
    <>
      <Row style={{ rowGap: 10 }}>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Correo electrónico</Form.Label>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.email
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <AlternateEmailOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: REGEXP.EMAIL,
                    message: "*Este campo debe ser un correo válido",
                  },
                  maxLength: {
                    value: 250,
                    message: "Máximo 250 caracteres permitidos",
                  },
                })}
                onChange={(event) => {
                  setValue("email", event.target.value);
                  setEnableButtonSave(true);
                }}
                inputMode="email"
                placeholder={"Correo electrónico"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.email?.message}
            </div>
          </Form.Group>
        </Col>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">
              Confirma correo electrónico
            </Form.Label>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.confirmEmail
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <AlternateEmailOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("confirmEmail", {
                  required: false,
                  pattern: {
                    value: REGEXP.EMAIL,
                    message: "*Este campo debe ser un correo válido",
                  },
                  validate: {
                    confirmateEmail: (value) => {
                      const currentEmail = watch("email");
                      return value === currentEmail || "*El correo no coincide";
                    },
                  },
                  maxLength: {
                    value: 250,
                    message: "Máximo 250 caracteres permitidos",
                  },
                })}
                onChange={(event) => {
                  setValue("confirmEmail", event.target.value);
                  setEnableButtonSave(true);
                }}
                inputMode="email"
                placeholder={"Correo electrónico"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.confirmEmail?.message}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row style={{ alignItems: "center", rowGap: 20 }}>
        <Form.Label className="form__label">Número de celular</Form.Label>
        <Form.Group as={Col} md={4}>
          <Controller
            name="countryCode"
            control={control}
            defaultValue={"57"}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.countryCode
                    ? "input-group-container__no-icon__country-list__error label"
                    : "input-group-container__no-icon__country-list label"
                }
                sx={{
                  height: "auto !important",
                  "& .MuiSelect-outlined": { padding: "0 !important" },
                }}
              >
                {countries.map((c) => (
                  <MenuItem
                    key={c.code}
                    value={c.phone}
                    className="input-group-container__no-icon__country-list label"
                    sx={{
                      "& .Mui-selected": { height: "fit-content !important" },
                    }}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                      alt={`Flag of ${c.label}`}
                    />
                    {` ${c.label} (${c.code}) +${c.phone}`}
                  </MenuItem>
                ))}
              </Select>
            )}
            rules={{
              required: {
                value: true,
                message: "*Este campo es obligatorio",
              },
            }}
            onChange={(event) => {
              setValue("countryCode", event.target.value);
              setEnableButtonSave(true);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} md={4}>
          <InputGroup
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={
              errors?.numberPhone
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
          >
            <InputGroup.Text bsPrefix="container-icon">
              <PhoneAndroidOutlinedIcon fontSize="large" />
            </InputGroup.Text>
            <Form.Control
              bsPrefix="custom-input"
              {...register("numberPhone", {
                required: false,
                pattern: {
                  value: REGEXP.ONLY_NUMBERS,
                  message: "*Solo debe incluir numeros",
                },
                maxLength: {
                  value: 10,
                  message: "*Máximo 10 caracteres permitidos",
                },
              })}
              onChange={(event) => {
                setValue("numberPhone", event.target.value);
                setEnableButtonSave(true);
              }}
              inputMode="email"
              placeholder={"Número de celular"}
            />
          </InputGroup>
          <div className="caption custom-input__error">
            {errors?.numberPhone?.message}
          </div>
        </Form.Group>
      </Row>
      <br />
      <Row style={{ alignItems: "center", rowGap: 20 }}>
        <Form.Label className="form__label">Teléfono fijo</Form.Label>
        <Form.Group as={Col} md={2}>
          <Controller
            name="countryCodeTelephone"
            control={control}
            defaultValue={"57"}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.countryCodeTelephone
                    ? "input-group-container__no-icon__country-list__error label"
                    : "input-group-container__no-icon__country-list label"
                }
                sx={{
                  height: "auto !important",
                  "& .MuiSelect-outlined": { padding: "0 !important" },
                }}
              >
                {countries.map((c) => (
                  <MenuItem
                    key={c.code}
                    value={c.phone}
                    className="input-group-container__no-icon__country-list label"
                    style={{ border: "none !important" }}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                      alt={`Flag of ${c.label}`}
                    />
                    {` ${c.label} (${c.code}) +${c.phone}`}
                  </MenuItem>
                ))}
              </Select>
            )}
            onChange={(event) => {
              setValue("countryCodeTelephone", event.target.value);
              setEnableButtonSave(true);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} md={1}>
          <Form.Control
            placeholder={"Ind. país"}
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={"input-group-container__no-icon label"}
            disabled
            value={watch("countryCodeTelephone")}
          />
        </Form.Group>
        <Form.Group as={Col} md={1}>
          <Form.Control
            {...register("cityCode", {
              required: false,
              pattern: {
                value: REGEXP.ONLY_NUMBERS,
                message: "*Solo debe incluir numeros",
              },
              maxLength: {
                value: 1,
                message: "*Máximo 1 caracteres permitidos",
              },
            })}
            onChange={(event) => {
              setValue("cityCode", event.target.value);
              setEnableButtonSave(true);
            }}
            placeholder={"Ind. ciudad"}
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={
              errors?.cityCode
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
          />
          <div className="caption custom-input__error">
            {errors?.cityCode?.message}
          </div>
        </Form.Group>
        <Form.Group as={Col} md={1}>
          <Form.Control
            {...register("extension", {
              required: false,
              pattern: {
                value: REGEXP.ONLY_NUMBERS,
                message: "*Solo debe incluir numeros",
              },
            })}
            onChange={(event) => {
              setValue("extension", event.target.value);
              setEnableButtonSave(true);
            }}
            placeholder={"Extensión"}
            style={{
              border: "0.1rem solid #c5c7d0",
            }}
            bsPrefix={
              errors?.extension
                ? "input-group-container__no-icon-error label"
                : "input-group-container__no-icon label"
            }
          />
          <div className="caption custom-input__error">
            {errors?.extension?.message}
          </div>
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Group>
            <InputGroup
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.telephoneNumber
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            >
              <InputGroup.Text bsPrefix="container-icon">
                <LocalPhoneOutlinedIcon fontSize="large" />
              </InputGroup.Text>
              <Form.Control
                bsPrefix="custom-input"
                {...register("telephoneNumber", {
                  required: false,
                  pattern: {
                    value: REGEXP.ONLY_NUMBERS,
                    message: "*Solo debe incluir numeros",
                  },
                  minLength: {
                    value: 7,
                    message: "*Minimo 7 caracteres permitidos",
                  },
                  maxLength: {
                    value: 7,
                    message: "*Máximo 7 caracteres permitidos",
                  },
                })}
                onChange={(event) => {
                  setValue("telephoneNumber", event.target.value);
                  setEnableButtonSave(true);
                }}
                inputMode="email"
                placeholder={"Teléfono fijo"}
              />
            </InputGroup>
            <div className="caption custom-input__error">
              {errors?.telephoneNumber?.message}
            </div>
          </Form.Group>
        </Form.Group>
      </Row>
      <br />
      <Row className="mt-4">
        <Col>
          <p className="heading__primary-color">Domicilio</p>
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">País</Form.Label>
            <Form.Control
              {...register("country", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              onChange={(event) => {
                setValue("country", event.target.value);
                setEnableButtonSave(true);
              }}
              placeholder={"País"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.country
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.country?.message}
            </div>
          </Form.Group>
        </Col>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Provincia o región</Form.Label>
            <Form.Control
              {...register("province", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              onChange={(event) => {
                setValue("province", event.target.value);
                setEnableButtonSave(true);
              }}
              placeholder={"Provincia o región"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.province
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.province?.message}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Ciudad</Form.Label>
            <Form.Control
              {...register("city", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              onChange={(event) => {
                setValue("city", event.target.value);
                setEnableButtonSave(true);
              }}
              placeholder={"Ciudad"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.city
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.city?.message}
            </div>
          </Form.Group>
        </Col>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Form.Label className="form__label">Dirección física</Form.Label>
            <Form.Control
              {...register("address", {
                required: false,
                maxLength: {
                  value: 250,
                  message: "*Máximo 250 caracteres permitidos",
                },
              })}
              onChange={(event) => {
                setValue("address", event.target.value);
                setEnableButtonSave(true);
              }}
              placeholder={"Dirección física"}
              style={{
                border: "0.1rem solid #c5c7d0",
              }}
              bsPrefix={
                errors?.address
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <div className="caption custom-input__error">
              {errors?.address?.message}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="mb-4">
          <p className="heading__primary-color">Firmantes relacionados</p>
        </Col>
      </Row>
      <Row className="align-items-center mb-3">
        <Col xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
          <Form.Label className="caption ">Firmantes :</Form.Label>
        </Col>

        {selectedSignatories.map((signatory) => {
          return (
            <Col
              xs={"auto"}
              style={{ display: "flex", alignItems: "center" }}
              key={signatory?.signatory?._id}
            >
              <span className="caption custom-badges__disabled">
                {getName(signatory?.signatory)}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => {
                    handleDeleteSignatory(signatory)
                    setEnableButtonSave(true);
                  }}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col xs={3}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<AccountCircleOutlinedIcon fontSize="large" />}
            onClick={() => {
              setIsOpenModalSignatoriesInfo(true);
            }}
          >
            Modificar firmante por defecto
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col className="home-init-cards-row__title-row">
          <span> Etiquetas</span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} md={"auto"} lg={4}>
          <Form.Group>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateTags
                  tags={tagsList}
                  handleTagsSelected={(tags) => {
                    handleTagsSelected(tags);
                    setEnableButtonSave(true);
                  }}
                  previousTagsSelected={tagsSelected}
                  type="PARTSLIBRARY"
                />
              </Col>
              {tagsSelected.map((tag) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={tag?._id}
                  >
                    <span className="caption custom-badges__disabled">
                      {tag.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ justifyContent: "end" }}>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={handleBack}
          >
            Regresar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            disabled={!enableButtonSave}
            type="submit"
            variant="contained"
            className="custom-input__button__primary-color"
          >
            Guardar
          </Button>
        </Col>
      </Row>
      {isOpenModalSignatoriesInfo && (
        <ModalInfoSignatories
          data={currentPart}
          open={isOpenModalSignatoriesInfo}
          handleClose={() => {
            setIsOpenModalSignatoriesInfo(false);
            setDataSignatoryModal(null);
          }}
          selectedElement={currentPart}
        />
      )}
    </>
  );
};
