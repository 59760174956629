import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import ClearIcon from "@mui/icons-material/Clear";
import ReactHtmlParser from "react-html-parser";
import LoadingContent from "../../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ModalInfoFullScreen from "../../../../../../../components/Modals/ModalInfoFullScreen";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { ModalListSelectCheck } from "../../../../../../../components/Modals/modalListCheck";
import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import VersionBar from "../../../../../../../components/VersionBar/versionBar";
import {
  getCurrentDocument,
  getReferenceNormativeStatus,
  setClauseSelected,
  setReferenceNormative,
  setReports,
  setSelectedClauses,
} from "../../../../../../../parts/document/currentDocumentSlice";
import { SendInfoToSupport } from "../../../../../../../services/admin/manageUserService";
import { getRefNormativeDocument } from "../../../../../../../services/documents/documentsRequest";
import {
  REPORTS_TYPE,
  TYPES_RELEVANCE_CLAUSES,
} from "../../../../../../../utils/codesReportsTypes";
import {
  createSummaryContract,
  milestonesReportFromClauses,
  obligationsReportFromClauses,
  partsReport,
  policesReportFromClauses,
  valuesReportFromClauses,
} from "../../../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../../../utils/codes";
import { TEXT_LOREM_IPSUM } from "../../../../../../../utils/loremIpsum";
import {
  NUMBERINGWORDSA,
  NUMBERINGWORDSO,
} from "../../../../../../../utils/numberings";
import { signatoriesTypes } from "../../../../../../../utils/typesSignatures";
import { Mixpanel } from "../../../../../../../utils/mixPanel";
import FilterCategory from "../../../../../../../parts/filters/FilterCategory";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../../parts/categories/clauseCategorySlice";
import PopoverArrayCards from "../../../../../../../components/Popover/PopoverArrayCards";
const ViewReview = () => {
  
  const numeration = 0;
  
  /**
   * Use Selector
   */
  const currentDocument = useSelector(getCurrentDocument);

  const statusRefNormative = useSelector(getReferenceNormativeStatus);

  /**
   * Use Navigate
   */

  const navigate = useNavigate();
  const { idDocument } = useParams();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();
  /**
   * Use state
   */
  const [typeView, setTypeView] = useState(true);

  const [filteredClauses, setFilteredClauses] = useState([]);
  const [isOpenVersions, setIsOpenVersions] = useState(false);
  const [openModalDiscard, setOpenModalDiscard] = useState(false);
  const [isOpenModalInfoReference, setIsOpenModalInfoReference] =
    useState(false);
  const [isOpenModalInfoExample, setIsOpenModalInfoExample] = useState(false);
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [exampleData, setExampleData] = useState("");
  const [adviceReportId, setAdviceReportId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFetchIa, setIsLoadingFetchIa] = useState(false);
  const [isOpenModalInfoReportAdvice, setIsOpenModalInfoReportAdvice] =
    useState(false);
  const [responseData, setResponseData] = useState("");
  const [anchorFuntsIA, setAnchorFuntsIA] = useState(null);
  const openFuntsIA = Boolean(anchorFuntsIA); //Generate report
  const [isOpenModalChooseReportToCreate, setIsOpenModalChooseReportToCreate] =
    useState(false);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const listReport = [
    REPORTS_TYPE.MILESTONES_AND_DATES,
    REPORTS_TYPE.VALUES,
    REPORTS_TYPE.POLICIES,
    REPORTS_TYPE.OBLIGATIONS,
  ];
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  /**
   * Use Effect
   */

  useEffect(() => {
    setFilteredClauses(currentDocument.clauses);
  }, [currentDocument]);
  const handleFilter = (text) => {
    const clausesFilter = text
      ? currentDocument.clauses.filter((cla) =>
          cla.title.toLowerCase().includes(text.toLowerCase())
        )
      : currentDocument.clauses;
    setFilteredClauses(clausesFilter);
  };

  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = currentDocument.clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });

      setFilteredClauses(filterCategories);
    } else {
      setFilteredClauses(currentDocument.clauses);
    }
  };

  const handleAgreeModalInputReport = async (text) => {
    try {
      setIsLoading(true);
      setIsOpenModalInputReport(false);
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const message = `${text}.\n\n cláusula: ${adviceReportId.title}.\n\n Consejo: ${adviceReportId?.advice?.advice || adviceReportId?.advice?.advices?.[adviceReportId?.indexAdvice]?.advice || ""}.`;
      let obj = {
        userEmail: payloadToken.email,
        subject: "Reporte consejo",
        message,
        firstName: payloadToken.firstName || payloadToken.companyName,
        ...(payloadToken.firstSurname && {
          lastName: payloadToken.firstSurname,
        }),
      };
      // Actualizar servicio para reporte de consejos.
      const responseSendInfoToSupport = await SendInfoToSupport(obj);
      setIsOpenModalInfoReportAdvice(true);
      setResponseData(responseSendInfoToSupport);
    } catch (error) {
      console.log(
        "============== Start Error Handle Report Advice =============="
      );
      console.error(error);
      console.log(
        "============== Finish Error Handle Report Advice =============="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisagreeModalInputReport = () => {
    setIsOpenModalInputReport(false);
  };

  //Pronta solución desde back
  const buildPolicies = (data) => {
    const arrayPolicies = [];
    for (const report of data) {
      const addIdpolicie = report.policies.map((item) => ({
        ...item,
        id_text: report.id_text,
        text: report.text,
        usage: report.usage,
      }));
      arrayPolicies.push(addIdpolicie);
    }
    return arrayPolicies.flat();
  };

  const handleCreateReport = async (data) => {
    try {
      setIsOpenModalChooseReportToCreate(false);
      setIsLoading(true);
      Mixpanel.track("Generar Informes AI ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      const clauses = currentDocument.clauses;
      let returnValues = [];
      for (const value of data) {
        if (value._id === REPORTS_TYPE.MILESTONES_AND_DATES._id) {
          const responseClassifyMilestones = await milestonesReportFromClauses({
            clauses,
          });
          if (responseClassifyMilestones) {
            if (
              responseClassifyMilestones.status ===
                CODES.COD_RESPONSE_HTTP_OK &&
              responseClassifyMilestones.data.success
            ) {
              const { sorter } =
                responseClassifyMilestones.data.responseMessage;

              returnValues = [
                ...returnValues,
                {
                  typeReport: REPORTS_TYPE.MILESTONES_AND_DATES.name,
                  reports: sorter,
                },
              ];
            }
          }
        } else if (value._id === REPORTS_TYPE.VALUES._id) {
          const responseValuesReport = await valuesReportFromClauses({
            clauses,
          });
          if (responseValuesReport) {
            if (
              responseValuesReport.status === CODES.COD_RESPONSE_HTTP_OK &&
              responseValuesReport.data.success
            ) {
              const { sorter } = responseValuesReport.data.responseMessage;
              returnValues = [
                ...returnValues,
                {
                  typeReport: REPORTS_TYPE.VALUES.name,
                  reports: sorter,
                },
              ];
            }
          }
        } else if (value._id === REPORTS_TYPE.PARTS._id) {
          const responsePartsReport = await partsReport({
            filenameInBucket: currentDocument?.filenameInBucket,
            bucketName: currentDocument?.bucketName,
          });
          if (responsePartsReport) {
            if (
              responsePartsReport.status === CODES.COD_RESPONSE_HTTP_OK &&
              responsePartsReport.data.success
            ) {
              const resportsParts =
                responsePartsReport?.data?.responseMessage?.data;
              returnValues = [
                ...returnValues,
                {
                  typeReport: REPORTS_TYPE.PARTS.name,
                  reports: resportsParts,
                },
              ];
            }
          }
        } else if (value._id === REPORTS_TYPE.POLICIES._id) {
          const responsePolicesReport = await policesReportFromClauses({
            clauses,
          });
          if (responsePolicesReport) {
            if (
              responsePolicesReport.status === CODES.COD_RESPONSE_HTTP_OK &&
              responsePolicesReport.data.success
            ) {
              const { data } = responsePolicesReport.data.responseMessage;
              returnValues = [
                ...returnValues,
                {
                  typeReport: REPORTS_TYPE.POLICIES.name,
                  reports: buildPolicies(data),
                },
              ];
            }
          }
        } else if (value._id === REPORTS_TYPE.OBLIGATIONS._id) {
          const responseObligationsReport = await obligationsReportFromClauses({
            clauses,
          });
          if (responseObligationsReport) {
            if (
              responseObligationsReport.status === CODES.COD_RESPONSE_HTTP_OK &&
              responseObligationsReport.data.success
            ) {
              const { data } = responseObligationsReport.data.responseMessage;
              returnValues = [
                ...returnValues,
                {
                  typeReport: REPORTS_TYPE.OBLIGATIONS.name,
                  reports: data,
                },
              ];
            }
          }
        }
      }
      dispatch(setReports({ reports: returnValues }));
      navigate(`reports`);
    } catch (error) {
      console.log(
        "============== Start Error Handle Generate Report =============="
      );
      console.error(error);
      console.log(
        "============== Finish Error Handle Generate Report =============="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateSummary = async () => {
    try {
      setAnchorFuntsIA(null);
      setIsLoading(true);
      Mixpanel.track("Funcionalidades AI Resumen ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      const { filenameInBucket, bucketName } = currentDocument;
      const responseSummary = await createSummaryContract({
        filenameInBucket,
        bucketName,
      });
      if (
        responseSummary.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        responseSummary.data.success
      ) {
        const summaryData = [
          {
            typeReport: REPORTS_TYPE.SUMMARY.name,
            reports: responseSummary?.data?.responseMessage?.data,
          },
        ];
        dispatch(setReports({ reports: summaryData }));
        navigate(`reports`);
      }
    } catch (error) {
      console.log("======= Start Error Hanlde Create Summary Contract =======");
      console.error(error);
      console.log(
        "======= Finish Error Hanlde Create Summary Contract ======="
      );
    } finally {
      setIsLoading(false);
    }
  };

  //plain text clauses
  const cleanText = (clauses) => {
    const currentClauses = clauses;
    let newClauses = currentClauses.map((clause) => {
      let subclauses = [];
      let paragraphs = [];
      const newTextClause = clause.text;
      const arraySubclauses = clause?.subclauses || [];
      const arrayParagraphs = clause?.paragraphs || [];
      for (const subclause of arraySubclauses) {
        subclauses.push({
          ...subclause,
          subclause: {
            ...subclause.subclause,
            text: subclause?.subclause?.text || "",
          },
        });
      }
      for (const paragraph of arrayParagraphs) {
        paragraphs.push({
          ...paragraph,
          paragraph: {
            ...paragraph.paragraph,
            text: paragraph?.paragraph?.text || "",
          },
        });
      }
      return {
        ...clause,
        text: newTextClause,
        subclauses,
        paragraphs,
      };
    });
    return newClauses;
  };

  function compareTextLength(a, b) {
    if (a.text.length < b.text.length) {
      return 1;
    } else if (a.text.length > b.text.length) {
      return -1;
    } else {
      return 0;
    }
  }
  // Reference Normative

  const GetReferenceNormative = async (clauses) => {
    const getRefNormativeResponse = await getRefNormativeDocument({
      clauses: cleanText(clauses),
    });
    const currentClauses = clauses;
    const clausesRefNormative =
      getRefNormativeResponse.data.responseMessage.data;
    let newClauses = currentClauses.map((clause, clauseIndex) => {
      const id_text = `${clauseIndex + 1}.0.0`;
      const ref = clausesRefNormative.filter(
        (item) => item.id_text === id_text
      );
      let subclauses = clause.subclauses.map((subclause, subclauseIndex) => {
        const id_text = `${clauseIndex}.${subclauseIndex + 1}.0`;
        const ref = clausesRefNormative.filter(
          (item) => item.id_text === id_text
        );
        ref.sort((a, b) => compareTextLength(a, b));
        if (ref.length > 0) {
          const newText = subclause.subclause.text;
          let refSubclause = {
            ...subclause,
            subclause: { ...subclause.subclause, text: newText },
          };
          for (const item of ref) {
            let link = `<a href="https://www.google.com/search?q=${item.text}" target="_blank">${item.text}</a>`;
            let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
            refSubclause = {
              ...refSubclause,
              subclause: {
                ...refSubclause.subclause,
                text: refSubclause?.subclause?.text.replace(regEx, link),
              },
            };
          }
          return refSubclause;
        }
        return subclause;
      });

      let paragraphs = clause.paragraphs.map((paragraph, paragraphIndex) => {
        const id_text = `${clauseIndex}.0.${paragraphIndex + 1}`;
        const ref = clausesRefNormative.filter(
          (item) => item.id_text === id_text
        );
        ref.sort((a, b) => compareTextLength(a, b));
        if (ref.length > 0) {
          const newText = paragraph.paragraph.text;
          let refParagraph = {
            ...paragraph,
            paragraph: { ...paragraph.paragraph, text: newText },
          };
          for (const item of ref) {
            let link = `<a target="_blank" href="https://www.google.com/search?q=${item.text}">${item.text}</a>`;
            let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
            refParagraph = {
              ...refParagraph,
              paragraph: {
                ...refParagraph.paragraph,
                text: refParagraph?.paragraph?.text.replace(regEx, link),
              },
            };
          }
          return refParagraph;
        }
        return paragraph;
      });
      if (ref.length > 0) {
        const newText = clause.text;
        let refClause = { ...clause, text: newText };
        ref.sort((a, b) => compareTextLength(a, b));
        for (const item of ref) {
          let link = `<a target='_blank' href='https://www.google.com/search?q=${item.text}'>${item.text}</a>`;
          let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
          refClause = {
            ...refClause,
            text: refClause?.text?.replace(regEx, link),
          };
        }
        return {
          ...refClause,
          subclauses,
          paragraphs,
        };
      }
      return {
        ...clause,
        subclauses,
        paragraphs,
      };
    });
    return newClauses;
  };

  const handleReferenceNormative = async () => {
    try {
      setIsLoading(true);
      const updateClauses = await GetReferenceNormative(
        currentDocument.clauses
      );
      dispatch(setSelectedClauses({ clauses: updateClauses }));
    } catch (error) {
      console.log("=======Start Handle Reference Normative=======");
      console.error(error);
      console.log("=======End Handle Reference Normative=======");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentDocument.clauses.length && statusRefNormative === "fetch") {
      handleReferenceNormative();
      dispatch(setReferenceNormative());
    }
  }, [currentDocument, statusRefNormative]);

  useEffect(() => {
    Mixpanel.track("Panel de revisión", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  return (
    <Container fluid>
      {(isLoading || isLoadingFetchIa) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Panel de revisión"} />
        </Col>
        <Col md={7}></Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <InputGroup bsPrefix="input-group-container">
            <Form.Control
              bsPrefix="custom-input"
              placeholder="Buscar"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
            <InputGroup.Text bsPrefix="container-icon">
              <SearchIcon fontSize="large" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col />
        <Col xs={"auto"}>
          <FilterCategory
            categoriesList={categoriesList}
            list={currentDocument.clauses}
            setFilteredInfo={setFilteredClauses}
            handleCategoriesSelected={handleCategoriesSelected}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ flexDirection: "row-reverse", rowGap: "10px" }}>
        {categoriesSelected.map((category) => {
          return (
            <Col xs={"auto"} key={category?._id}>
              <span className="caption custom-badges__secundary">
                {category + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteCategory(category)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} style={{ paddingLeft: "1.5%" }}>
          <Row>
            <Col md={"auto"} className="crud-titles">
              {currentDocument?.title}
            </Col>
          </Row>
        </Col>

        <Col xs={"auto"}>
          <ButtonGroup variant="contained" size="large">
            <Button
              variant="text"
              className={
                !typeView
                  ? "custom-input__link"
                  : "custom-input__link selected-option"
              }
              onClick={() => {
                setTypeView(true);
              }}
            >
              <EventNoteIcon className="logo-primary-color" />
            </Button>
            <Button
              variant="text"
              className={
                typeView
                  ? "custom-input__link"
                  : "custom-input__link selected-option"
              }
              onClick={() => {
                setTypeView(false);
              }}
            >
              <SubjectOutlinedIcon className="logo-primary-color" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <br />
      <div className="custom-accordion__container" style={{ paddingBottom: "5%"}}>
        {!typeView ? (
          <ViewWord
            currentDocument={currentDocument}
            filteredClauses={filteredClauses}
            numeration={numeration}
          />
        ) : (
          <Container fluid>
            <Row className="heading__primary-color">Partes</Row>
            <Row>
              {currentDocument?.parts?.length > 0 &&
                currentDocument.parts.map((rol, index) => (
                  <Accordion
                    key={"part" + rol?._id}
                    className="custom-accordion"
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Row style={{ width: "100%" }}>
                        <Col md={4} className="heading__primary-color">
                          {rol.name ? rol.name : "Parte sin nombre"}
                        </Col>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails>
                      {rol?.signatories?.length > 0 && (
                        rol.signatories.map((part) => (
                          <Accordion
                            className="custom-accordion"
                            key={part?._id}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Row style={{ width: "100%", alignItems: "center" }}>
                                <Col
                                  xs={"auto"}
                                  className="col-start heading__primary-color"
                                >
                                  <p className="heading">
                                    {part?.information?.name || ""}
                                  </p>
                                </Col>
                              </Row>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul>
                                {part?.signatories?.length > 0 && (
                                  part.signatories.map((sig) => {
                                    const cleanNumberDocument = sig?.documentNumber?.replace(/[.,]/g, "")
                                    const stringNumberDocument = Number(cleanNumberDocument);
                                    const documentNumberFormated = stringNumberDocument.toLocaleString('es', { style: 'decimal', useGrouping: true });
                                    return (<li key={uuidv4()} className="caption">
                                      {`${sig?.name ? sig.name + ", " : ""}${sig?.documentType || ""} ${documentNumberFormated !== 'NaN' ? documentNumberFormated : ""}`}
                                    </li>)
                                  })
                                )}
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        ))
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Row>

            <Row
              className="heading__primary-color"
              style={{ marginTop: "0.5%" }}
            >
              Cláusulas
            </Row>
            <Row>
              {filteredClauses?.length > 0 ? (
                filteredClauses.map((cla, index) => (
                  <Accordion
                    index={"cla" + index}
                    className="custom-accordion"
                    key={cla?._id}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Col>
                          
                          <Row style={{ width: "100%" }}>
                            <p className="heading__primary-color">
                              {`${
                                numeration === 0
                                  ? index + 1
                                  : "Cláusula " + NUMBERINGWORDSA[index + 1]
                              }. ${cla.title}`}
                            </p>
                          </Row>
                          <br />
                          <Row>
                            <Col className="subheading">
                              {cla?.categories?.length > 0 && (
                                cla.categories.map((cat, i) => {
                                  return (
                                    <span
                                      key={uuidv4()}
                                      style={{ marginRight: "1%" }}
                                      className="caption custom-badges__secundary"
                                    >
                                      {cat.name}
                                    </span>
                                  );
                                })
                              )}
                            </Col>
                          </Row>
                          </Col>
                        </AccordionSummary>
                      </Col>
                      {cla?.advice && (
                        <Col xs={"auto"}>
                          {cla?.advice?.advices?.length > 0 ? (
                              <PopoverArrayCards
                                icon={<LightbulbOutlinedIcon fontSize="large" />}
                                parentId="clause-parent"
                                type="xlarge"
                                items={cla?.advice?.advices.map((ad, indexAdvice) => ({
                                  ...ad,
                                  description: ad.advice,
                                  textToCopy: ad.advice,
                                  actions: [{
                                      text: "Reportar",
                                      handleAction: () => {
                                        setIsOpenModalInputReport(true);
                                        setAdviceReportId({...cla, indexAdvice});
                                      },
                                      show: true,
                                    }]
                                }))}
                              />
                          ) : (cla?.advice?.advice && (
                                <PopoverActions
                                  parentId="clause-parent"
                                  type="large"
                                  actions={[
                                    {
                                      text: "Ver ejemplo",
                                      handleAction: () => {
                                        if (cla.advice.example) {
                                          setIsOpenModalInfoExample(true);
                                          setExampleData(cla.advice.example);
                                        }
                                      },
                                      show: cla?.advice?.example,
                                    },
                                    {
                                      text: "Reportar",
                                      handleAction: () => {
                                        setIsOpenModalInputReport(true);
                                        setAdviceReportId(cla);
                                      },
                                      show: true,
                                    },
                                  ]}
                                  description={cla.advice.advice}
                                  link={cla?.advice?.norm && {
                                    text: "Ver referencia normativa",
                                    href: cla.advice.norm
                                  }}
                                  textToCopy={cla.advice.advice}
                                  icon={<LightbulbOutlinedIcon fontSize="large" />}
                                />
                          ))}
                        </Col>
                      )}
                      {cla?.importance &&
                        cla.importance.relevance ===
                          TYPES_RELEVANCE_CLAUSES.RELEVANT && (
                          <Col xs={"auto"}>
                            <PopoverActions
                              parentId="clause-parent"
                              classNameButton="custom-input__button__default-other-color-dark"
                              type="large"
                              description={
                                "Legal AI ha detectado que esta cláusula merece especial atención."
                              }
                              icon={<CheckOutlinedIcon fontSize="large" />}
                              textIcon={"Importante"}
                            />
                          </Col>
                        )}
                    </Row>
                    <AccordionDetails>
                      <Row style={{ margin: "2% 0" }}>
                      </Row>

                      <Row style={{ paddingLeft: "1.3%" }}>
                        <span className="caption">
                          {ReactHtmlParser(cla?.text || "")}
                          {cla?.subclauses?.length > 0 &&
                            cla.subclauses.map((sub, i) => {
                              return (
                                <Row
                                  key={uuidv4()}
                                  className="justify-text clauses-number"
                                  style={{ margin: "1% 0" }}
                                >
                                  <Col xs={"auto"}>
                                    <b>{`${index + 1}.${i + 1} ${
                                      sub.subclause.title + ":" || ""
                                    }`}</b>
                                  </Col>
                                  <Col>
                                    <span className="caption">
                                      <p>
                                        {ReactHtmlParser(
                                          sub?.subclause?.text || ""
                                        )}
                                      </p>
                                    </span>
                                  </Col>
                                  <Container>
                                    {sub?.subclause?.childs?.length > 0 &&
                                      sub.subclause.childs.map(
                                        (child, index2) => {
                                          return (
                                            <Row
                                              key={uuidv4()}
                                              className="justify-text clauses-number"
                                              style={{
                                                paddingLeft: "1.3%",
                                                margin: "1% 0",
                                              }}
                                            >
                                              <div
                                                className="caption"
                                                style={{
                                                  display: "inline",
                                                }}
                                              >
                                                <b>{`${index + 1}.${i + 1}.${
                                                  index2 + 1
                                                } ${child?.title || ""}:`}</b>
                                                <p>
                                                  {ReactHtmlParser(
                                                    child?.text || ""
                                                  )}
                                                </p>
                                              </div>
                                            </Row>
                                          );
                                        }
                                      )}
                                  </Container>
                                </Row>
                              );
                            })}
                          <Container fluid style={{ paddingLeft: "1%" }}>
                            {cla?.paragraphs?.length > 0 &&
                              cla.paragraphs.map((paragraph, index3) => {
                                return (
                                  <Row
                                    key={uuidv4()}
                                    className="justify-text mb-3 mt-2"
                                  >
                                    <Col xs={"auto"}>
                                      <b>{`Parágrafo ${
                                        NUMBERINGWORDSO[index3 + 1]
                                      }`}</b>
                                    </Col>
                                    <Col xs={"auto"}>
                                      {ReactHtmlParser(
                                        paragraph?.paragraph?.text || ""
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                          </Container>
                        </span>
                      </Row>
                      <Row
                        style={{
                          justifyContent: "right",
                          margin: "2% 0",
                        }}
                      >
                        <Col md={4}>
                          <Button
                            variant="contained"
                            startIcon={<LocalLibraryOutlinedIcon />}
                            className="custom-input__button__secondary-color"
                            onClick={() => {
                              dispatch(setClauseSelected(cla));
                              navigate(`edit-clause/reviewDoc`);
                            }}
                          >
                            Agregar esta cláusula a mi biblioteca
                          </Button>
                        </Col>
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <p className="container_text__clause-text label">
                  Sin cláusulas.
                </p>
              )}
            </Row>
          </Container>
        )}
      </div>
      <Row className="sidebar__bottom__container">
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            size="large"
            onClick={() => {
              setOpenModalDiscard(true);
            }}
          >
            Descartar
          </Button>
        </Col>
        {idDocument !== "NEW" && (
          <Col xs={"auto"}>
            <Button
              variant="contained"
              startIcon={<FindInPageOutlinedIcon />}
              className="custom-input__button__primary-color"
              size="large"
              onClick={() => {
                setIsOpenVersions(true);
                Mixpanel.track("Btn Navegar entre versiones ", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                });
              }}
            >
              Navegar entre versiones
            </Button>
          </Col>
        )}
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<FactCheckOutlinedIcon />}
            className="custom-input__button__primary-color"
            size="large"
            onClick={(e) => {
              Mixpanel.track("Btn Funcionalidades de AI ", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
              setAnchorFuntsIA(e.currentTarget);
            }}
          >
            Funcionalidades de IA
          </Button>
          <Menu
            anchorEl={anchorFuntsIA}
            open={openFuntsIA}
            onClose={() => setAnchorFuntsIA(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setIsOpenModalChooseReportToCreate(true);
                setAnchorFuntsIA(null);
                Mixpanel.track("Funcionalidades AI Informes ", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                });
              }}
            >
              <span className="body-content">Informes</span>
            </MenuItem>
            <MenuItem onClick={handleCreateSummary}>
              <span className="body-content">Resumen</span>
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <ModalDecision
        title={"Descartar revisión"}
        message={"¿Está seguro?"}
        open={openModalDiscard}
        onClose={() => {
          setOpenModalDiscard(false);
        }}
        agreeText="Descartar"
        disagreeText="Cancelar"
        handleAgree={() => {
          navigate("/service/home");
        }}
        handleDisagree={() => {
          setOpenModalDiscard(false);
        }}
      />
      <ModalInfoFullScreen
        title={"Referencia normativa"}
        description={TEXT_LOREM_IPSUM.MEDIUM} //referenceData
        onClose={() => setIsOpenModalInfoReference(false)}
        open={isOpenModalInfoReference}
        textBackButton={"Volver a la revisión"}
      />
      <ModalInfoFullScreen
        title={"Ejemplo de cláusula"}
        description={exampleData} //exampleData
        onClose={() => setIsOpenModalInfoExample(false)}
        open={isOpenModalInfoExample}
        textBackButton={"Volver a la revisión"}
      />
      <ModalInpuText
        title={"Reportar"}
        message={"Cuéntanos si crees que la sugerencia es irrelevante o imprecisa. ¡Consideraremos tus sugerencias para mejorar Legal AI!"}
        agreeText={"Reportar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={handleDisagreeModalInputReport}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
      <ModalInfo
        title={"Reportar consejo"}
        onClose={() => setIsOpenModalInfoReportAdvice(false)}
        open={isOpenModalInfoReportAdvice}
        responseData={responseData}
        confirmationText={"Aceptar"}
      />
      <ModalListSelectCheck
        open={isOpenModalChooseReportToCreate}
        onClose={() => setIsOpenModalChooseReportToCreate(false)}
        title={"Generar informe de:"}
        list={listReport}
        handleAgree={handleCreateReport}
      />
      <VersionBar
        isOpen={isOpenVersions}
        setIsOpen={setIsOpenVersions}
        setIsLoadingFetch={setIsLoadingFetchIa}
      />
    </Container>
  );
};

const ViewWord = ({ currentDocument, filteredClauses, numeration }) => (
  <Container fluid style={{ height: "100%" }}>
    <Row className="heading__primary-color">Partes</Row>
    <Row>
      {currentDocument?.parts?.length > 0 &&
        currentDocument.parts.map((part, index) => (
          <Container key={part?._id} className="container_text">
            <Row>
              <Col className="heading__primary-color">{`${index + 1}. ${
                part.name || "Sin titulo"
              }`}</Col>
            </Row>
            <Row className="container_text__subclause">
              <ul>
                {part?.signatories?.length > 0 ? (
                  part.signatories.map((sig, i) => (
                    <li key={uuidv4()} className="label">
                      {sig.type === "natural" && sig.gender === "M"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariOPN.type
                          }), ${sig.text}`
                        : sig.type === "natural" && sig.gender === "F"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariAPN.type
                          }), ${signatoriesTypes.SignatariAPN.text}`
                        : sig.type === "legal" && sig.gender === "M"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariOPJ.type
                          }), ${signatoriesTypes.SignatariOPJ.text}`
                        : sig.type === "legal" && sig.gender === "F"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariAPJ.type
                          }), ${signatoriesTypes.SignatariAPJ.text}`
                        : `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariOPN.type
                          }), ${sig.text}`}
                    </li>
                  ))
                ) : (
                  <li className="label">Sin signatarios</li>
                )}
              </ul>
            </Row>
            <Row className="container_text__subclause label">
              {ReactHtmlParser(part.text || "Sin texto final")}
            </Row>
          </Container>
        ))}
    </Row>
    <Row className="heading__primary-color">Encabezados</Row>
    <Row>
      {currentDocument?.headers?.length > 0 ? (
        currentDocument.headers.map((header, index) => (
          <Container key={header?._id} className="container_text">
            <Row>
              <Col className="heading__primary-color">{`${index + 1}. ${
                header.title || "Sin titulo"
              }`}</Col>
            </Row>
            <Row className="container_text__clause-text label">
              {ReactHtmlParser(header?.text || "")}
            </Row>
            <Row className="container_text__clause-text">
              <ul>
                {header?.childs?.length > 0 &&
                  header.childs.map((child, index2) => (
                    <Container
                      key={child?._id}
                      className="container_text__subclause"
                    >
                      <Row>
                        <Col className="heading__primary-color">{`${
                          index + 1
                        }.${index2 + 1}`}</Col>
                      </Row>
                      <Row className="container_text__clause-text label">
                        {ReactHtmlParser(child?.text || "")}
                      </Row>
                    </Container>
                  ))}
              </ul>
            </Row>
          </Container>
        ))
      ) : (
        <p className="container_text__clause-text label">Sin encabezados.</p>
      )}
    </Row>
    <Row className="heading__primary-color">Cláusulas</Row>
    <Row>
      {filteredClauses?.length > 0 ? (
        filteredClauses.map((cla, index) => (
          <Row key={cla?._id}>
            <Container className="container_text">
              <Row>
                <Col md={4} className="heading__primary-color">{`${
                  numeration === 0
                    ? index + 1
                    : "Cláusula " + NUMBERINGWORDSA[index + 1]
                }. ${cla.title || "Sin titulo"}`}</Col>
              </Row>
              <Row className="container_text__clause-text label">
                {ReactHtmlParser(`<p>${cla.text}</p>`)}
              </Row>
            </Container>
            {cla?.subclauses?.length > 0 &&
              cla.subclauses.map((sub, index2) => (
                <Container key={sub?._id} className="container_text__subclause">
                  <Row style={{ alignItems: "center" }}>
                    <Col xs={"auto"} className="heading__primary-color">
                      {`${index + 1}.${index2 + 1}. ${
                        sub?.subclause?.title || ""
                      }`}
                    </Col>
                    <Col className="container_text__clause-text label">
                      {ReactHtmlParser(`<p>${sub?.subclause?.text}</p>`)}
                    </Col>
                  </Row>
                  <Row className="container_text__clause-text label"></Row>
                  {sub?.subclause?.childs?.length > 0 &&
                    sub.subclause.childs.map((child, index4) => (
                      <Container
                        key={child?._id}
                        className="container_text__subclause"
                      >
                        <Row>
                          <Col className="heading__primary-color">{`${
                            index + 1
                          }.${index2 + 1}.${index4 + 1}. ${
                            child?.title || ""
                          }`}</Col>
                        </Row>
                        <Row className="container_text__clause-text label">
                          {ReactHtmlParser(`<p>${child?.text}</p>`)}
                        </Row>
                      </Container>
                    ))}
                </Container>
              ))}
            {cla?.paragraphs?.length > 0 &&
              cla.paragraphs.map((para, index3) => (
                <Container key={uuidv4()} className="container_text__subclause">
                  <Row>
                    <Col className="heading__primary-color">{`Parágrafo ${
                      NUMBERINGWORDSO[index3 + 1]
                    } ${para?.paragraph?.title || ""}`}</Col>
                  </Row>
                  <Row className="container_text__clause-text label">
                    {ReactHtmlParser(`<p>${para?.paragraph?.text}</p>`)}
                  </Row>
                </Container>
              ))}
          </Row>
        ))
      ) : (
        <p className="container_text__clause-text label">Sin cláusulas.</p>
      )}
    </Row>
    <Row className="heading__primary-color">Firmas</Row>
    <Row>
      {currentDocument?.parts?.length > 0 &&
        currentDocument.parts.map((part, index) => (
          <Container key={uuidv4()} className="container_text">
            <Row>
              <Col className="heading__primary-color">{`${index + 1}. Firma ${
                part.name || "sin titulo"
              }`}</Col>
            </Row>
            <Row className="container_text__subclause">
              <ul>
                {part.signatories.length > 0 ? (
                  part.signatories.map((sig, i) =>
                    sig?.signatures?.attributes?.map((attri, index) => (
                      <li key={uuidv4()} className="label">
                        {attri.name + " " + attri.value}
                      </li>
                    ))
                  )
                ) : (
                  <li className="label">Sin firmas</li>
                )}
              </ul>
            </Row>
          </Container>
        ))}
    </Row>
  </Container>
);

export default ViewReview;
