import { yupResolver } from "@hookform/resolvers/yup";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Button, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
import Breadcrum from "../../../../../../parts/breadcrum";
import { getCompanyPlan } from "../../../../../../parts/storage/storageSlice";
import {
  changeAssignmentRequest,
  changeAssignmenttask,
  deleteUserOnly,
  editAdminUser,
  editUser,
  getUserPermissionsBySubRole,
} from "../../../../../../services/admin/manageUserService";
import { CODES } from "../../../../../../utils/codes";
import { countries } from "../../../../../../utils/countries";
import { REGEXP } from "../../../../../../utils/regexp";
import { RESPONSE_UNITS_CORPORATIVES } from "../../../../../../utils/responseUnitsCorporatives";
import HelpButton from "../../../Components/HelpButton";
import {
  deleteUser,
  editUserByIndex,
  fetchCompanyUsersList,
  getCompanyUsersList,
  getCompanyUsersListStatus,
} from "../companyUsersSlice";
import {
  getCurrentUser,
  getUserCorporateUnits,
  getUserPermissions,
  isStoringInfo,
  setCurrentUserDataTest,
  setNewUnits,
  setPermissions,
} from "../currentUserSlice";
const EditUser = () => {
  /**
   * Variables de selección
   */

  let typeOfUser = [
    {
      id: 0,
      name: "Usuario administrador",
      value: "ADMIN",
    },
    {
      id: 1,
      name: "Usuario de Servicios",
      value: "SERVICE",
    },
    {
      id: 2,
      name: "Usuario solicitante",
      value: "EXTERNAL",
    },
  ];

  const adminRoles = [
    {
      id: 1,
      name: "Usuario administrador",
      value: "ADMIN ADMINISTRADOR",
    },
  ];

  const serviceRoles = [
    {
      id: 0,
      name: "Abogado Director",
      value: "SERVICE DIRECTOR",
    },
    {
      id: 1,
      name: "Abogado Senior",
      value: "SERVICE SENIOR",
    },
    {
      id: 2,
      name: "Abogado Junior",
      value: "SERVICE JUNIOR",
    },
    {
      id: 3,
      name: "Practicante",
      value: "SERVICE PRACTICANTE",
    },
  ];

  const externalRoles = [
    {
      id: 0,
      name: "Usuario solicitante",
      value: "EXTERNAL SOLICITANTE",
    },
  ];

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const userCorporateUnits = useSelector(getUserCorporateUnits);
  const userPermissions = useSelector(getUserPermissions);
  const storingInfo = useSelector(isStoringInfo);
  const getCurrentUserInfo = useSelector(getCurrentUser);
  const [documentType, setDocumentType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenModalInitialDelete, setIsOpenModalInitialDelete] =
    useState(false);
  const [responseData, setResponseData] = useState("");
  const [responseDataDeleteOnly, setResponseDataDeleteOnly] = useState({
    status: "",
  });
  const [isOpenModalDeleteOnly, setIsOpenModalDeleteOnly] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [actualCallSign, setActualCallSign] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [initialType, setInitialType] = useState("");
  const [subTypeSelected, setSubTypeSelected] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [unitCorporativeSelected, setUnitCorporativeSelected] = useState([]);
  const companyUsersList = useSelector(getCompanyUsersList);
  const companyUsersListStatus = useSelector(getCompanyUsersListStatus);
  const [isApplyPhoneCheck, setIsApplyPhoneCheck] = useState(false);
  const companyPlan = useSelector(getCompanyPlan);
  const [createUserDisabled, setCreateUserDisabled] = useState(false);
  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [currentTypeSelected, setCurrentTypeSelected] = useState("");

  const TYPE_USERS_OPTIONS = {
    ADMIN: adminRoles,
    SERVICE: serviceRoles,
    EXTERNAL: externalRoles,
    undefined: [],
  };

  const schema = yup.object().shape({
    role: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
    subrole: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
    documentType: yup.string().nullable().default(""),
    documentNumber: yup.string().nullable().default(""),
    firstName: yup.string().nullable().default(""),
    secondName: yup.string().nullable().default(""),
    firstSurname: yup.string().nullable().default(""),
    secondSurname: yup.string().nullable().default(""),
    documentType2: yup.string().nullable().default(""),
    email: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .required("*Este campo es requerido"),
    emailConfirmation: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .required("*Este campo es requerido")
      .oneOf([yup.ref("email"), null], "*El correo no coincide"),

    countryCode:
      getCurrentUserInfo?.userLoginType === "PLATFORM"
        ? yup.string().required("*Este campo es obligatorio")
        : yup.string().nullable().default(""),
    phoneNumber:
      getCurrentUserInfo?.userLoginType === "PLATFORM"
        ? yup
            .string()
            .required("*Este campo es obligatorio")
            .min(10, "Mínimo 10 números")
            .max(10, "Máximo 10 números")
            .matches(REGEXP.ONLY_NUMBERS, {
              message: "*Solo debe incluir números",
            })
        : yup.string().nullable().default(""),

    extension: !isApplyPhoneCheck
      ? yup
          .string()
          .notRequired()
          .test("extension", "*Solo se permiten números", function (value) {
            if (!!value) {
              const schema = yup.string().matches(REGEXP.ONLY_NUMBERS);
              return schema.isValidSync(value);
            }
            return true;
          })
      : yup.string().nullable().default(""),
    cityCode: !isApplyPhoneCheck
      ? yup
          .string()
          .required("*Este campo es obligatorio")
          .min(1, "Mínimo 1 números")
          .max(1, "Máximo 1 números")
          .matches(REGEXP.ONLY_NUMBERS, {
            message: "*Solo debe incluir números",
          })
      : yup.string().nullable().default(""),
    telephoneNumber: !isApplyPhoneCheck
      ? yup
          .string()
          .min(7, "Mínimo 7 números")
          .max(7, "Máximo 7 números")
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_NUMBERS, {
            message: "*Solo debe incluir números",
          })
      : yup.string().nullable().default(""),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSetDocument = (e) => {
    e.target.value === "OTRO" ? setDocumentType(true) : setDocumentType(false);
  };

  const handleEditUser = async (data) => {
    try {
      setIsLoading(true);
      const user = companyUsersList[id];
      if (userCorporateUnits.length <= 0) {
        setResponseData(RESPONSE_UNITS_CORPORATIVES.CREATE_ERROR_WO_UC);
        setIsOpenModal(true);
        return;
      }
      const finalUnits = userCorporateUnits.map((unit) => {
        return unit._id;
      });
      const payloadJWT = JSON.parse(localStorage.getItem("payloadToken"));
      let infoTelephone = {};
      if (!isApplyPhoneCheck) {
        infoTelephone = {
          cityCode: data?.cityCode || "",
          extension: data?.extension || "",
          landlineNumber: data?.telephoneNumber || "",
        };
      }
      let obj = {
        userData: {
          email: data.email,
          firstName: user?.firstName,
          secondName: user?.secondName,
          firstSurname: user?.firstSurname,
          secondSurname: user?.secondSurname,
          documentType: user?.documentType
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.toUpperCase(),
          documentNumber: user?.documentNumber,
          role: data.role,
          subRole: data.subrole,
        },
        contactInfo: {
          phoneNumber: data?.phoneNumber,
          countryCode: data?.countryCode,
          ...infoTelephone,
        },
        corporateUnits: finalUnits,
        permissions: userPermissions,
        tokenInfo: {
          email: payloadJWT.email,
          role: payloadJWT["custom:role"],
          company: payloadJWT.company,
        },
        userId: currentUser._id,
      };

      let editUserResponse = "";
      if (
        obj.userData.role === "ADMIN" &&
        obj.userData.subRole === "ADMIN PROPIETARIO"
      ) {
        editUserResponse = await editAdminUser(obj);
      } else {
        editUserResponse = await editUser(obj);
      }

      if (editUserResponse.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(editUserResponse);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(editUserResponse);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    if (
      responseData.data.responseCode === CODES.COD_RESPONSE_HTTP_OK ||
      responseData.data.success
    ) {
      dispatch(
        editUserByIndex({
          editedUser: responseData.data.responseMessage.data,
          index: id,
        })
      );
      navigate("/admin/manageUser");
    }
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleJustDelete = async () => {
    setIsLoading(true);
    try {
      setIsOpenModalDelete(false);
      let OkAssignmentRequest = false;
      let OkAssignmentTask = false;
      let response;
      const resChangeAssignmentRequest = await changeAssignmentRequest({
        currentAttorneyId: currentUser._id,
      });
      if (
        resChangeAssignmentRequest.status ===
        CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setResponseDataUnauthorized(resChangeAssignmentRequest);
        setIsOpenModalUnauthorized(true);
        return;
      }
      if (resChangeAssignmentRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        OkAssignmentRequest = true;
      } else {
        response = resChangeAssignmentRequest;
      }
      const resChangeAssignmentTask = await changeAssignmenttask({
        currentAttorneyId: currentUser._id,
      });
      if (
        resChangeAssignmentTask.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setResponseDataUnauthorized(resChangeAssignmentTask);
        setIsOpenModalUnauthorized(true);
        return;
      }
      if (resChangeAssignmentTask.status === CODES.COD_RESPONSE_HTTP_OK) {
        OkAssignmentTask = true;
      } else {
        response = resChangeAssignmentTask;
      }
      if (OkAssignmentRequest && OkAssignmentTask) {
        const deleteUserOnlyResponse = await deleteUserOnly({
          email: currentUser.email,
        });
        if (
          deleteUserOnlyResponse.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setResponseDataUnauthorized(deleteUserOnlyResponse);
          setIsOpenModalUnauthorized(true);
          return;
        }
        if (deleteUserOnlyResponse.status === CODES.COD_RESPONSE_HTTP_OK) {
          const resp = (
            <p className="caption">
              Operación realizada con éxito. El usuario
              <span style={{ fontWeight: "bold" }}>
                {` ${currentUser.firstName} ${currentUser.firstSurname} `}
              </span>
              ha sido eliminado para la unidad corporativa
              <span style={{ fontWeight: "bold" }}>
                {` ${
                  companyUsersList[id].corporateUnits
                    ?.map((item) => item?.name)
                    .join(", ") || ""
                }. `}
              </span>
              Podrá reasignar manualmente las tareas y solicitudes asignadas a
              este usuario en el módulo de Usuarios de Servicio.
            </p>
          );
          setResponseDataDeleteOnly({
            ...deleteUserOnlyResponse,
            data: {
              responseMessage: resp,
            },
          });
          setIsOpenModalDeleteOnly(true);
          return;
        }
        setResponseDataDeleteOnly(deleteUserOnlyResponse);
        setIsOpenModalDeleteOnly(true);
      } else {
        setResponseDataDeleteOnly(response);
        setIsOpenModalDeleteOnly(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOnlyFinished = () => {
    if (responseDataDeleteOnly.status == CODES.COD_RESPONSE_HTTP_OK) {
      dispatch(deleteUser({ index: id }));
    }
    navigate("/admin/manageUser");
  };

  const handleSaveChanges = () => {
    const user = companyUsersList[id];
    const value = getValues();
    let data = {
      storingUserInfo: true,
      email: value?.email,
      phoneNumber: user?.contactInfo?.phoneNumber
        ? user?.contactInfo?.phoneNumber
        : user?.contactInfo?.mobile,
      firstName: user?.firstName,
      secondName: user?.secondName,
      firstSurname: user?.firstSurname,
      secondSurname: user?.secondSurname,
      documentType: user?.documentType
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toUpperCase(),
      documentNumber: user?.documentNumber,
      role: value?.role,
      subRole: user?.subRole,
      countryCode: user?.contactInfo?.countryCode,
      cityCode: value?.cityCode,
      extension: value?.extension,
      isNumerTelActive: value?.isNumerTelActive,
      emailConfirmation: value?.emailConfirmation,
      telephoneNumber: value?.telephoneNumber,
    };
    dispatch(setCurrentUserDataTest(data));
  };

  const handleDeleteUnitCorporative = (item) => {
    const deleteFilter = unitCorporativeSelected.filter(
      (unit) => unit._id !== item._id
    );
    setUnitCorporativeSelected(deleteFilter);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companyUsersListStatus === "fetch") {
          dispatch(fetchCompanyUsersList());
        }
      } catch (error) {
        console.log("==================Fetch Users Error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [companyUsersListStatus, dispatch]);

  useEffect(() => {
    if (companyUsersListStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [companyUsersListStatus]);

  useEffect(() => {
    try {
      setValue("documentType", getCurrentUserInfo?.documentType);
      setValue("documentNumber", getCurrentUserInfo?.documentNumber);
      setValue("firstName", getCurrentUserInfo?.firstName);
      setValue("secondName", getCurrentUserInfo?.secondName);
      setValue("firstSurname", getCurrentUserInfo?.firstSurname);
      setValue("secondSurname", getCurrentUserInfo?.secondSurname);
      setValue("email", getCurrentUserInfo?.email);
      setValue("phoneNumber", getCurrentUserInfo?.phoneNumber);
      setValue("countryCode", getCurrentUserInfo?.countryCode);
      setValue("extension", getCurrentUserInfo?.extension);
      setValue("role", getCurrentUserInfo?.role);
      setTypeSelected(getCurrentUserInfo?.role);
      setInitialType(getCurrentUserInfo?.role);
      setCurrentTypeSelected(getCurrentUserInfo?.subRole);
      setValue("subrole", getCurrentUserInfo?.subRole);
      setSubTypeSelected(getCurrentUserInfo?.subRole);
      setValue("isNumerTelActive", getCurrentUserInfo?.isNumerTelActive);
      setActualCallSign(getCurrentUserInfo?.countryCode);
      setValue("emailConfirmation", getCurrentUserInfo?.emailConfirmation);
      if (!getCurrentUserInfo?.telephoneNumber) {
        setIsApplyPhoneCheck(true);
      } else {
        setIsApplyPhoneCheck(false);
      }
      setValue("telephoneNumber", getCurrentUserInfo?.telephoneNumber);
    } catch (error) {
      console.log("========== Error Set Value User Data ==========");
      console.log(error);
      console.log("========== Error Set Value User Data Finish ==========");
    }
  }, [getCurrentUserInfo]);

  useEffect(() => {
    try {
      const user = companyUsersList[id];
      setCurrentUser(user);
      let data = {
        storingUserInfo: user,
        email: user?.email,
        phoneNumber: user?.contactInfo?.phoneNumber
          ? user?.contactInfo?.phoneNumber
          : user?.contactInfo?.mobile,
        firstName: user?.firstName,
        secondName: user?.secondName,
        firstSurname: user?.firstSurname,
        secondSurname: user?.secondSurname,
        documentType: user?.documentType
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toUpperCase(),
        documentNumber: user?.documentNumber,
        role: user?.companies[0]?.role?.name,
        subRole: user?.subRole,
        countryCode: user?.contactInfo?.countryCode,
        extension: user?.contactInfo?.extension,
        isNumerTelActive:
          user?.contactInfo?.extension !== ""
            ? "Teléfono fijo"
            : "Teléfono celular",
        _id: user?._id,
        emailConfirmation: user?.email,
        telephoneNumber: user?.contactInfo?.landlineNumber,
      };
      if (!storingInfo) {
        setActualCallSign(user?.contactInfo?.countryCode);
        dispatch(setPermissions({ permissions: user?.permissions }));
        dispatch(setNewUnits({ units: user?.corporateUnits }));
        dispatch(setCurrentUserDataTest(data));
      }
    } catch (error) {
      console.log("========== Error Set Current User Data ==========");
      console.log(error);
      console.log("========== Error Set Current User Data Finish ==========");
    }
  }, [storingInfo, companyUsersList]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const response = await getUserPermissionsBySubRole(subTypeSelected);
        if (
          response.data.success &&
          response.status === CODES.COD_RESPONSE_HTTP_OK
        ) {
          const { permissions } = response.data.responseMessage.data;
          handleSaveChanges();
          dispatch(setPermissions({ permissions: permissions }));
        }
      } catch (error) {
        console.log("========= Error fetch Permissions By Subrole =========");
        console.log(error);
        console.log(
          "========= Finish Error fetch Permissions By Subrole ========="
        );
      } finally {
        setIsLoading(false);
      }
    };
    if (subTypeSelected !== "") {
      if (subTypeSelected !== getCurrentUserInfo?.subRole) {
        fetch();
      }
    }
  }, [subTypeSelected]);

  useEffect(() => {
    if (companyPlan) {
      setCreateUserDisabled(false);
      if (getDisabledCreation()) {
        setCreateUserDisabled(true);
      }
    }
  }, [companyPlan, typeSelected]);

  const getDisabledCreation = () => {
    if (
      typeSelected === "EXTERNAL" &&
      !companyPlan.isUserExternalEnable &&
      typeSelected !== initialType
    ) {
      return true;
    } else if (
      typeSelected === "SERVICE" &&
      !companyPlan.isUserServiceEnable &&
      typeSelected !== initialType
    ) {
      return true;
    } else if (
      typeSelected === "ADMIN" &&
      !companyPlan.isUserAdminEnable &&
      typeSelected !== initialType
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Editar usuario"} />
      </Row>
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleEditUser)}>
          <Row className="text-description mb-4">
            Asigna un tipo de usuario y rol para brindar permisos específicos a
            tu usuario:
          </Row>
          <Row>
            <Form.Group as={Col} md={3}>
              <Form.Select
                disabled={isLoading || subTypeSelected === "ADMIN PROPIETARIO"}
                className="label mb-4"
                as="select"
                value={typeSelected}
                placeholder="Tipo de usuario"
                bsPrefix={
                  errors.role
                    ? "input-group-container__no-icon-error label text-fields"
                    : "input-group-container__no-icon label text-fields"
                }
                {...register("role")}
                onChange={(e) => {
                  if (currentTypeSelected === "ADMIN PROPIETARIO") return;
                  setTypeSelected(e.target.value);
                  setSubTypeSelected("");
                  setValue("role", e.target.value);
                  setValue("subRole", "");
                }}
              >
                <option className="label" value="" disabled>
                  Tipo de usuario
                </option>
                {typeOfUser.map((type, idx) => (
                  <option key={type.id} className="label" value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.role?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Select
                disabled={
                  subTypeSelected === "ADMIN PROPIETARIO" ||
                  getDisabledCreation()
                }
                className="label mb-4"
                as="select"
                value={subTypeSelected}
                placeholder="Tipo de usuario"
                bsPrefix={
                  errors.subrole
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("subrole")}
                onChange={(e) => {
                  setSubTypeSelected(e.target.value);
                  setValue("subrole", e.target.value);
                }}
              >
                <option className="label" value="" disabled>
                  Rol del usuario
                </option>
                {TYPE_USERS_OPTIONS[typeSelected]?.map((role) => (
                  <option key={role.id} className="label" value={role.value}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.subrole?.message}
              </div>
            </Form.Group>
            <Col xs={12}>
              {createUserDisabled && typeSelected !== "" && (
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  Has alcanzado el máximo de{" "}
                  {typeOfUser
                    .find(({ value }) => value === typeSelected)
                    ?.name?.toLowerCase()}
                </div>
              )}
            </Col>
          </Row>
          <hr style={{ width: "50%" }} />
          <Row className="text-description mt-5 mb-4">
            Edita las unidades corporativas asociadas a este usuario:
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "2%" }}>
            <Col xs={"auto"} className="ml-2">
              <Button
                startIcon={<ModeEditOutlineOutlinedIcon />}
                className="button-softblue-folders"
                onClick={() => {
                  handleSaveChanges();
                  navigate("userUnitCorporative");
                }}
              >
                Editar unidades corporativas
              </Button>
            </Col>
            {unitCorporativeSelected.map((item, index) => {
              return (
                <Col xs={"auto"} key={uuidv4()}>
                  <span className="caption custom-badges__disabled">
                    {item?.name + " "}{" "}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => handleDeleteUnitCorporative(item)}
                    />
                  </span>
                </Col>
              );
            })}
          </Row>
          <hr style={{ width: "50%" }} />
          <br />
          <Row className="text-description">
            Diligencia los datos personales del usuario:
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Select
                disabled
                className="label mb-1"
                defaultValue=""
                bsPrefix={
                  errors.documentType
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("documentType")}
                onChange={(e) => {
                  setValue("documentType", e.target.value);
                  handleSetDocument(e);
                }}
              >
                {/* INE y CURP */}
                <option className="label" value="" disabled>
                  Tipo de documento
                </option>
                <option className="label" value="CEDULA DE CIUDADANIA">
                  Cédula de ciudadanía - COL
                </option>
                <option className="label" value="TARJETA DE IDENTIDAD">
                  Tarjeta de identidad - COL
                </option>
                <option className="label" value="CEDULA DE EXTRANJERIA">
                  Cédula de extranjería - COL
                </option>
                <option className="label" value="INE">
                  INE - MEX
                </option>
                <option className="label" value="CURP">
                  CURP - MEX
                </option>
                <option className="label" value="PASAPORTE">
                  Pasaporte
                </option>
                <option className="label" value="OTRO">
                  Otros
                </option>
              </Form.Select>
              <p className="caption custom-input__error">
                {errors.documentType?.message}
              </p>
            </Form.Group>
            {documentType && (
              <Form.Group as={Col} md={4}>
                <Form.Control
                  disabled
                  {...register("documentType2")}
                  placeholder={"Ingrese tipo de documento"}
                  bsPrefix={
                    errors.documentType2
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors.documentType2?.message}
                </div>
              </Form.Group>
            )}
            <Form.Group as={Col} md={4}>
              <Form.Control
                disabled
                {...register("documentNumber")}
                placeholder={"Número de documento"}
                bsPrefix={
                  errors.documentNumber
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.documentNumber?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Control
                disabled
                {...register("firstName")}
                placeholder={"Primer nombre"}
                bsPrefix={
                  errors.firstName
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.firstName?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Control
                disabled
                {...register("secondName")}
                placeholder={"Segundo nombre"}
                bsPrefix={
                  errors.secondName
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.secondName?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Control
                disabled
                {...register("firstSurname")}
                placeholder={"Primer apellido"}
                bsPrefix={
                  errors.firstSurname
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.firstSurname?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Control
                disabled
                {...register("secondSurname")}
                placeholder={"Segundo apellido"}
                bsPrefix={
                  errors.secondSurname
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.secondSurname?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <InputGroup
                bsPrefix={
                  errors.email
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <AlternateEmailIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("email")}
                  bsPrefix="custom-input"
                  placeholder="Correo electrónico"
                />
              </InputGroup>
              <div className="caption custom-input__error">
                {errors.email?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <InputGroup
                bsPrefix={
                  errors.emailConfirmation
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <AlternateEmailIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("emailConfirmation")}
                  bsPrefix="custom-input"
                  placeholder="Confirmar correo electrónico"
                  onPaste={(e) => e.preventDefault()}
                />
              </InputGroup>
              <div className="caption custom-input__error">
                {errors.emailConfirmation?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Select
                className={
                  errors.countryCode
                    ? "input-group-container__no-icon__country-list__error label"
                    : "input-group-container__no-icon__country-list label"
                }
                {...register("countryCode")}
                value={actualCallSign || ""}
                onChange={(e) => {
                  setActualCallSign(e.target.value);
                }}
              >
                <MenuItem
                  value={""}
                  className="input-group-container__no-icon__country-list label"
                  style={{ border: "none !important" }}
                >
                  Selecciona
                </MenuItem>
                {countries.map((c) => (
                  <MenuItem
                    key={c.code}
                    value={c.phone}
                    className="input-group-container__no-icon__country-list label"
                    style={{ border: "none !important" }}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                      alt={`Flag of ${c.label}`}
                    />
                    {` ${c.label} (${c.code}) +${c.phone}`}
                  </MenuItem>
                ))}
              </Select>
              <p className="caption custom-input__error">
                {errors.countryCode?.message}
              </p>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <InputGroup
                hasValidation={false}
                bsPrefix={
                  errors.phoneNumber
                    ? "input-group-container__error"
                    : "input-group-container-disabled"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <PhoneAndroidIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("phoneNumber")}
                  bsPrefix="custom-input__small"
                  placeholder={"Número de celular*"}
                  type="tel"
                />
              </InputGroup>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.phoneNumber?.message}
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Col xs="auto">
              <span className="caption label">Teléfono fijo</span>
            </Col>
            <Col>
              <Form.Check
                checked={isApplyPhoneCheck}
                className="label"
                label="No aplica"
                onChange={(event) => {
                  setIsApplyPhoneCheck(event.target.checked);
                }}
              ></Form.Check>
            </Col>
          </Row>
          <br />
          {!isApplyPhoneCheck && (
            <Row>
              <Col md={1}>
                <Form.Group className="mb-5">
                  <Form.Control
                    placeholder="Ind. país"
                    disabled
                    value={actualCallSign}
                    bsPrefix={"input-group-container__no-icon label"}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("cityCode")}
                    placeholder="Ind. ciudad"
                    bsPrefix={
                      errors.cityCode
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.cityCode?.message}
                  </div>
                </Form.Group>
              </Col>
              <Col md={1}>
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("extension")}
                    placeholder="Ext"
                    bsPrefix={
                      errors.extension
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.extension?.message}
                  </div>
                </Form.Group>
              </Col>
              <Form.Group as={Col} md={4}>
                <InputGroup
                  bsPrefix={
                    errors.telephoneNumber
                      ? "input-group-container__error"
                      : "input-group-container"
                  }
                >
                  <InputGroup.Text bsPrefix="container-icon">
                    <LocalPhoneRoundedIcon fontSize="large" />
                  </InputGroup.Text>
                  <Form.Control
                    {...register("telephoneNumber")}
                    bsPrefix="custom-input__small"
                    placeholder={"Número de teléfono fijo"}
                  />
                </InputGroup>
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.telephoneNumber?.message}
                </div>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Col xs={"auto"}>
              <Button
                startIcon={<ManageAccountsIcon />}
                className={"custom-input__button__secondary-color"}
                onClick={() => {
                  if (subTypeSelected !== "") {
                    navigate("servicePermissions");
                    handleSaveChanges();
                  }
                }}
              >
                Gestionar permisos
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={"auto"}>
              <Button
                startIcon={<DeleteOutlineOutlinedIcon />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setIsOpenModalInitialDelete(true);
                }}
              >
                Eliminar usuario
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              {createUserDisabled ? (
                <CustomToolTip
                  direction="left"
                  message="Has alcanzado el máximo de usuarios permitidos en tu plan"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="custom-input__button__primary-color"
                    disabled
                  >
                    Guardar cambios
                  </Button>
                </CustomToolTip>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="custom-input__button__primary-color"
                >
                  Guardar cambios
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Edición de usuario"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
      <ModalDecision
        title={"Cancelar edición de usuario"}
        message={"¿Está seguro?"}
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Descartar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalDecision
        title={"Eliminar usuario"}
        message={
          "Eliminar a este usuario lo eliminará también de las unidades corporativas a las que se encuentra asignado. ¿Desea continuar?"
        }
        open={isOpenModalInitialDelete}
        onClose={() => {
          setIsOpenModalInitialDelete(false);
        }}
        agreeText="Eliminar usuario"
        disagreeText="Cancelar"
        handleAgree={() => {
          setIsOpenModalInitialDelete(false);
          setIsOpenModalDelete(true);
        }}
        handleDisagree={() => {
          setIsOpenModalInitialDelete(false);
        }}
      />
      <ModalDecision
        title={"Eliminar usuario"}
        message={
          <p className="caption">
            ¿Desea transferir las asignaciones efectuadas a este usuario dentro
            de la unidad corporativa{" "}
            <span style={{ fontWeight: "bold" }}>
              {` ${
                companyUsersList[id]?.corporateUnits
                  ?.map((item) => item?.name)
                  .join(", ") || ""
              }, `}
            </span>
            a otro Usuario de Servicios?
          </p>
        }
        open={isOpenModalDelete}
        onClose={() => {
          setIsOpenModalDelete(false);
        }}
        agreeText="Transferir a usuario existente"
        disagreeText="No, solo eliminar"
        handleAgree={() => {
          navigate("transferData");
        }}
        handleDisagree={handleJustDelete}
      />
      <ModalInfo
        title={"Usuario eliminado"}
        confirmationText="Continuar"
        responseData={responseDataDeleteOnly}
        open={isOpenModalDeleteOnly}
        onClose={handleDeleteOnlyFinished}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <HelpButton />
    </Container>
  );
};

export default EditUser;
