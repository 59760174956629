export const getBlankSpaceIndex = (blankspaceArray) => {
  let id = blankspaceArray.reduce((acc, bs) => (acc > bs.id ? acc : bs.id), 0);
  return Number(id) + 1;
};
export const transformSelectionToHtml = (selection) => {
  var range = selection?.getRangeAt(0);

  var fragment = range?.cloneContents();

  var div = document?.createElement("div");
  div.appendChild(fragment?.cloneNode(true));

  return div.innerHTML || "";
};

export const replaceBlankSpace1 = (htmlString, oldValue, newValue) => {
  console.log(htmlString);
  const searchValue = 'data-blank-space="blankSpace-' + oldValue + '"';
  const replaceValue = 'data-blank-space="blankSpace-' + newValue + '"';
  const modifiedString = htmlString.replace(searchValue, replaceValue);

  return modifiedString;
};

export function replaceBlankSpace(htmlString) {
  // Crear un elemento DOM temporal para manipular el HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Encontrar todos los elementos con la clase 'blank-space-text'
  const blankSpaces = tempDiv.querySelectorAll(".blank-space-text");

  // Iterar sobre cada elemento de espacio en blanco
  blankSpaces.forEach((blankSpace) => {
    // Extraer el número del contenido del elemento
    const matches = blankSpace.textContent.match(/¬ESPACIO #(\d+)¬/);
    if (matches && matches[1]) {
      // Actualizar el atributo 'data-blank-space' con el nuevo número
      const newNumber = matches[1];
      blankSpace.setAttribute("data-blank-space", `blankSpace-${newNumber}`);
    }
  });

  // Devolver el HTML actualizado como string
  return tempDiv.innerHTML;
}

export const updateStringBlankSpaces = (inputString) => {
  const regex = /¬ESPACIO #(\d+)¬/g;
  const resultArray = [];
  const blankSpacesArray = [];
  let updatedString = "";
  let lastIndex = 0;

  let match;
  while ((match = regex.exec(inputString)) !== null) {
    const espacioNumber = parseInt(match[1], 10);
    resultArray.push(espacioNumber);

    const replacement = `¬ESPACIO #${resultArray.length}¬`;
    let newBlankSpace = {
      id: resultArray.length,
      name: `blankSpace-${resultArray.length}`,
    };
    blankSpacesArray.push(newBlankSpace);
    const substring = inputString.substring(lastIndex, match.index);
    updatedString += substring + replacement;

    lastIndex = regex.lastIndex;
  }

  updatedString += inputString.substring(lastIndex);
  return { text: `<div>${updatedString}</div>`, blankspaces: blankSpacesArray };
};
