import { Button, IconButton } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RichEditorText from "../../../../../../parts/RichEditorText";
import { changeNameParts } from "./utils";
import { plainTextTagsLinks } from "../../../../../../utils/partsCorrection";
import { getBlankSpaceIndex } from "../../../../../../utils/blankspaces";

const SubNumeralsUpdate = ({
  idx,
  control,
  register,
  getValues,
  watch,
  errors,
  setClause,
  clause,
  editTitle,
  onChangeSubNumeralTitle,
  setEditTitle,
  isUpdate,
  setValue,
}) => {
  const [subNumeralTitleSubNumeralIdx, setSubNumeralTitleSubNumeralIdx] =
    useState(null);
  const subNumeralRichEditorTextRef = useRef([]);

  const {
    fields: subNumeralFields,
    append: subNumeralAppend,
    remove: subNumeralRemove,
  } = useFieldArray({
    control,
    name: `subClauses.${idx}.numerals`,
  });

  const addTextInContents = () => {
    const subClauses = getValues("subClauses");

    subClauses.forEach((subClause, index) => {
      const numerals = [];
      if (idx === index) {
        subClause.numerals.forEach((numeral, numeralIdx) => {
          const parts = getValues("parts").map((part) => part.title);
          const text = changeNameParts(numeral.text, parts);
          subNumeralRichEditorTextRef.current[numeralIdx].setEditorContents(
            plainTextTagsLinks(text)
          );

          if (numeral?.blankSpaces?.length) {
            for (const blankSpace of numeral?.blankSpaces) {
              subNumeralRichEditorTextRef?.current?.[numeralIdx]?.setBlankSpacesValues(blankSpace.id)
            }
          }
          numerals.push({
            ...numeral,
            isImported: false,
            text,
          });
        });
      }
      if (numerals.length) {
        setClause((clause) => ({
          ...clause,
          subClauses: clause.subClauses.map((subclause, index) => {
            if (index === idx) return { ...subclause, numerals };
            return subclause;
          }),
        }));
      }
    });
  };

  useEffect(() => {
    subNumeralRichEditorTextRef.current = subNumeralFields.map(
      (_, index) => subNumeralRichEditorTextRef.current[index] ?? createRef()
    );

    addTextInContents();
  }, [subNumeralFields]);

  const handleSubNumeralBlankSpaces = (
    blankSpaces,
    subClauseIndex,
    subNumeralIndex
  ) => {
    let blankSpaceId = getBlankSpaceIndex(
      clause.subClauses[subClauseIndex].numerals[subNumeralIndex].blankSpaces
    );
    let item = {
      id: blankSpaceId,
      name: `blankSpace-${blankSpaceId}`,
    };
    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.map((subClause, idx) => {
        if (idx === subClauseIndex) {
          return {
            ...subClause,
            numerals: subClause.numerals.map((numeral, idx) => {
              if (idx === subNumeralIndex) {
                setValue(
                  `subClauses.${subClauseIndex}.numerals.${subNumeralIndex}`,
                  {
                    ...numeral,
                    blankSpaces: [...numeral.blankSpaces, item],
                  }
                );
                return {
                  ...numeral,
                  blankSpaces: [...numeral.blankSpaces, item],
                };
              }
              return numeral;
            }),
          };
        }
        return subClause;
      }),
    }));
    subNumeralRichEditorTextRef.current[subNumeralIndex].addBlankSpace(
      clause.subClauses[subClauseIndex].numerals[subNumeralIndex].blankSpaces
    );
  };

  const handleCreateSubNumeral = (subClauseIndex) => {
    let item = {
      title: "",
      text: "",
      blankSpaces: [],
    };
    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.map((subClause, subClauseIdx) => {
        if (subClauseIdx === subClauseIndex) {
          return {
            ...subClause,
            numerals: [...(subClause.numerals || []), item],
          };
        }
        return subClause;
      }),
    }));

    subNumeralAppend(item);
  };

  const handleDeleteSubNumeral = (index, subNumeralIndex) => {
    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.map((subClause, subClauseIdx) => {
        if (subClauseIdx === index) {
          return {
            ...subClause,
            numerals: subClause.numerals.filter(
              (_, subNumeralIdx) => subNumeralIdx !== subNumeralIndex
            ),
          };
        }
        return subClause;
      }),
    }));
    subNumeralRemove(subNumeralIndex);
  };

  const handleSubNumeralTitle = (subNumeralIdx) => {
    setSubNumeralTitleSubNumeralIdx(subNumeralIdx);
    setEditTitle(true);
  };

  const handleSubNumeralRemoveBlankSpace = (
    subClauseIndex,
    subNumeralIdx,
    idx
  ) => {
    setClause((clause) => ({
      ...clause,
      subClauses: clause.subClauses.map((subClause, subClauseIdx) => {
        if (subClauseIdx === subClauseIndex) {
          return {
            ...subClause,
            numerals: subClause.numerals.map((numeral, numeralIdx) => {
              if (numeralIdx === subNumeralIdx) {
                return {
                  ...numeral,
                  blankSpaces: numeral.blankSpaces.filter(
                    (blankSpace) => blankSpace.id !== idx
                  ),
                };
              }
              return numeral;
            }),
          };
        }
        return subClause;
      }),
    }));
    subNumeralRichEditorTextRef.current[subNumeralIdx].removeBlankSpace(idx);
  };

  useEffect(() => {
    addTextInContents();
  }, [isUpdate]);

  return (
    <>
      {subNumeralFields.map((subNumeral, subNumeralIdx) => {
        return (
          <Container key={subNumeral?.id} fluid>
            <Row className="mb-5" style={{ paddingLeft: "5%" }}>
              <Col>
                <Row className="mb-4">
                  <Col className="subClause-title">
                    {editTitle &&
                    subNumeralTitleSubNumeralIdx === subNumeralIdx ? (
                      <>
                        <Form.Control
                          {...register(
                            `subClauses.${idx}.numerals.${subNumeralIdx}.title`,
                            {
                              onChange: (e) => {
                                onChangeSubNumeralTitle(e, idx, subNumeralIdx);
                              },
                            }
                          )}
                          bsPrefix="input-group-container__no-icon label"
                        />
                        <IconButton
                          onClick={(e) => {
                            setEditTitle(false);
                          }}
                        >
                          <CheckOutlinedIcon
                            className="heading__primary-color"
                            fontSize="large"
                          />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {watch(
                          `subClauses.${idx}.numerals.${subNumeralIdx}.title`
                        ) ? (
                          <p className="heading__primary-color">
                            {`1.${idx + 1}.${subNumeralIdx + 1} ` +
                              getValues(
                                `subClauses.${idx}.numerals.${subNumeralIdx}.title`
                              )}
                          </p>
                        ) : (
                          <p className="heading__subtitle">
                            {`1.${idx + 1}.${subNumeralIdx + 1} Sin título`}
                          </p>
                        )}
                        <IconButton
                          onClick={() => {
                            handleSubNumeralTitle(subNumeralIdx);
                          }}
                        >
                          <ModeEditOutlineOutlinedIcon
                            className="heading__primary-color"
                            fontSize="large"
                          />
                        </IconButton>
                      </>
                    )}
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      {getValues("parts").map((item, idxParts) => {
                        if (watch(`parts.${idxParts}.title`)) {
                          return (
                            <Col
                              className="create-clause-coachmark-step-3"
                              xs={"auto"}
                              key={item?.title}
                            >
                              <Button
                                onClick={() =>
                                  subNumeralRichEditorTextRef.current[
                                    subNumeralIdx
                                  ].addPart(item.title, idxParts)
                                }
                                variant="contained"
                                className="custom-input__button__secondary-color"
                              >
                                {item.title}
                              </Button>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Col
                        className="create-clause-coachmark-step-4"
                        xs={"auto"}
                      >
                        <Button
                          variant="contained"
                          startIcon={<AddIcon fontSize="large" />}
                          className="custom-input__button__secondary-color"
                          onClick={() =>
                            handleSubNumeralBlankSpaces(
                              clause.subClauses[idx].numerals[subNumeralIdx]
                                .blankSpaces.length + 1,
                              idx,
                              subNumeralIdx
                            )
                          }
                        >
                          Espacio en blanco
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__primary-color"
                      onClick={() => handleDeleteSubNumeral(idx, subNumeralIdx)}
                    >
                      <DeleteOutlineIcon fontSize="large" />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Controller
                    control={control}
                    name={`subClauses.${idx}.numerals.${subNumeralIdx}.text`}
                    render={({ field: { onChange, onBlur } }) => {
                      return (
                        <RichEditorText
                          ref={(el) => {
                            if (el) {
                              subNumeralRichEditorTextRef.current[
                                subNumeralIdx
                              ] = el;
                            }
                          }}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          customClassName={
                            errors?.subClauses?.[idx]?.numerals?.[subNumeralIdx]
                              ?.text?.message
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          deleteBlankSpace={(id) => {
                            handleSubNumeralRemoveBlankSpace(idx, subNumeralIdx, id)
                          }}
                        />
                      );
                    }}
                  />
                  <div className="caption custom-input__error">
                    {
                      errors?.subClauses?.[idx]?.numerals?.[subNumeralIdx]?.text
                        ?.message
                    }
                  </div>
                </Row>
              </Col>
              <Col xs={"auto"}>
                <Container>
                  <Row>
                    <p className="label">Espacios en blanco:</p>
                  </Row>
                  {clause.subClauses[idx].numerals[
                    subNumeralIdx
                  ].blankSpaces.map((blankSpace) => {
                    return (
                      <Row xs={"auto"} key={blankSpace?.id}>
                        <p className="heading__primary-color">
                          ESPACIO #{blankSpace.id}{" "}
                          <IconButton
                            onClick={() =>
                              handleSubNumeralRemoveBlankSpace(
                                idx,
                                subNumeralIdx,
                                blankSpace.id
                              )
                            }
                          >
                            <DeleteOutlineIcon
                              className="heading__primary-color"
                              fontSize="large"
                            />
                          </IconButton>
                        </p>
                      </Row>
                    );
                  })}
                </Container>
              </Col>
            </Row>
          </Container>
        );
      })}
      <Row className="mb-5" xs={"auto"} style={{ justifyContent: "right" }}>
        <Button
          variant="contained"
          startIcon={<AddIcon fontSize="large" />}
          className="custom-input__button__secondary-color"
          onClick={() => handleCreateSubNumeral(idx)}
        >
          Subnumeral
        </Button>
      </Row>
    </>
  );
};

export default SubNumeralsUpdate;
