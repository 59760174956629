import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../components/loadingContent";

import FilterTag from "../filters/FilterTag";
import {
  fetchTags,
  getStatus as getTagsClausesStatus,
  getTagList,
} from "../manageTags/tagSlice";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import {
  fetchPrederminatedTexts,
  getPrederminatedTextsList,
  getStatusPrederminatedTexts,
} from "../../pages/views/userService/modules/library/pretederminatedTexts/prederminatedTextsSlice";
const ImportPredeterminedText = ({ show, setShow, onSelect }) => {
  const dispatch = useDispatch();

  const tagsClausesStatus = useSelector(getTagsClausesStatus);
  const tagsClausesList = useSelector(getTagList);
  const listPredeterminatedTexts = useSelector(getPrederminatedTextsList);
  const statusPredeterminatedTexts = useSelector(getStatusPrederminatedTexts);
  const [filteredText, setFilteredText] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [textList, setTextList] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [predeterminedTextSelected, setpredeterminedTextSelected] = useState(
    []
  );

  const [countChecked, setCountChecked] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChecked = (e, clauses) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setpredeterminedTextSelected((oldArray) => [...oldArray, clauses]);
    } else {
      setCountChecked(countChecked - 1);
      setpredeterminedTextSelected(
        predeterminedTextSelected.filter((clause) => clause._id !== clauses._id)
      );
    }
  };
  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };
  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = textList.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.name));
      });
      setFilteredText(filterTags);
    }
  };

  /* UseEffects Fetch Clauses Tags */

  useEffect(() => {
    try {
      if (tagsClausesStatus === "fetch") {
        dispatch(fetchTags("DOCUMENT_TEMPLETES"));
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsClausesStatus, dispatch]);

  useEffect(() => {
    if (tagsClausesList) {
      setFilteredTags(tagsClausesList);
    }
  }, [tagsClausesList]);

  /* UseEffects Fetch Clauses */

  useEffect(() => {
    const fetchPredeterminatedTextsData = async () => {
      try {
        if (statusPredeterminatedTexts === "fetch") {
          dispatch(fetchPrederminatedTexts());
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPredeterminatedTextsData();
  }, [statusPredeterminatedTexts, dispatch]);

  useEffect(() => {
    if (statusPredeterminatedTexts === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusPredeterminatedTexts]);

  useEffect(() => {
    if (listPredeterminatedTexts) {
      setFilteredText(listPredeterminatedTexts);
      setTextList(listPredeterminatedTexts);
    }
  }, [listPredeterminatedTexts]);

  return (
    <>
      <Modal
        show={show}
        fullscreen
        onHide={() => {
          setShow(false);
        }}
      >
        {isLoading && <LoadingContent />}
        <Modal.Header>
          <Modal.Title>
            <p className="label__primary-color__page-title">Importar bloque</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Busca y selecciona los bloques que quieres utilizar en tu
                  documento.
                </p>
              </Col>
            </Row>
            <br />
            <Row className="filter-tag__headers">
              <Col>
                <CustomSearchFilter
                  list={textList}
                  setFilteredData={setFilteredText}
                  placeholder={"Buscar bloque"}
                />
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} bloques seleccionados
                </p>
              </Col>

              <Col xs={"auto"}>
                <FilterTag
                  list={filteredText}
                  tagsList={filteredTags}
                  setFilteredInfo={setFilteredText}
                  handleTagsSelected={handleTagsSelected}
                  currentTagSelected={tagsSelected}
                />
              </Col>
            </Row>
            <br />
            <Row sm={"auto"} style={{ justifyContent: "right" }}>
              {tagsSelected.map((tag, idx) => {
                return (
                  <Col xs={"auto"} key={tag?._id}>
                    <span className="caption custom-badges__disabled">
                      {tag + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
            <br />
            <br />
            <Row>
              <div className="custom-accordion__container">
                {filteredText.length > 0 ? (
                  filteredText.map((text, index) => {
                    return (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={text?._id}
                      >
                        <Col>
                          <Accordion className="custom-accordion">
                            <Row style={{ alignItems: "center" }}>
                              <Col xs={"auto"}>
                                <Checkbox
                                  checked={predeterminedTextSelected.some(
                                    (item) => {
                                      return item?._id === text?._id;
                                    }
                                  )}
                                  onClick={(e) => handleChecked(e, text)}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 28,
                                    },
                                    ".MuiAccordionSummary-gutters": {
                                      disableGutters: true,
                                    },
                                    color: "#00374f",
                                    "&.Mui-checked": {
                                      color: "#00374f",
                                    },
                                  }}
                                />
                              </Col>
                              <Col>
                                <AccordionSummary
                                  sx={{
                                    ".MuiAccordionSummary-content": {
                                      alignItems: "center",
                                    },
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Col sm={4}>
                                    <p className="heading__primary-color">
                                      {text.name + " "}
                                    </p>
                                  </Col>

                                  {text.labels.length > 0 && (
                                    <Col
                                      className="ml-auto"
                                      xs={"auto"}
                                      style={{ textAlign: "right" }}
                                    >
                                      <span className="subheading">
                                        Etiquetas:{" "}
                                        {text.labels.map((tag, i) => {
                                          return (
                                            <span
                                              key={tag?._id}
                                              style={{ marginRight: "1%" }}
                                              className="caption custom-badges__disabled"
                                            >
                                              {tag.name}
                                            </span>
                                          );
                                        })}
                                      </span>
                                    </Col>
                                  )}
                                </AccordionSummary>
                              </Col>
                            </Row>
                            <AccordionDetails
                              style={{
                                width: "100%",
                                boxShadow:
                                  "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                              }}
                            >
                              <Row className="p-4">
                                <span className="caption">
                                  <Row style={{ alignItems: "center" }}>
                                    <Col>{ReactHtmlParser(text.text)}</Col>
                                  </Row>
                                </span>
                              </Row>
                            </AccordionDetails>
                          </Accordion>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Typography variant="h5">
                    No se han encontrado resultados
                  </Typography>
                )}
              </div>
            </Row>
            {/* Botones de confirmación */}
            <Row
              xs={"auto"}
              style={{ marginTop: "1.5rem", justifyContent: "end" }}
            >
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setShow(false);
                    setpredeterminedTextSelected([]);
                    setCountChecked(0);
                    setFilteredText(textList);
                  }}
                  startIcon={<ClearIcon />}
                >
                  <p className="button">Cancelar</p>
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setShow(false);
                    onSelect(predeterminedTextSelected);
                    setpredeterminedTextSelected([]);
                    setCountChecked(0);
                    setFilteredText(textList);
                  }}
                  startIcon={<CheckCircleOutlineOutlinedIcon />}
                  disabled={!predeterminedTextSelected.length}
                >
                  <p className="button">Importar</p>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { ImportPredeterminedText };
