import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingContent from "../../../../../components/loadingContent";
import Breadcrum from "../../../../../parts/breadcrum";
import SectionTitle from "../../../../../components/sectionTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { CODES } from "../../../../../utils/codes";
import {
  findAllRulesByCompany,
  updateRules,
} from "../../../../../services/admin/rules/rulesServices";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import { useNavigate } from "react-router-dom";
import { generateGridItems } from "../../../../../utils/gridUtils";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../unitCorporative/unitCorporativeSlice";
import { useDispatch, useSelector } from "react-redux";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#ffff !important",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "white" : "#00374F",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#00374F",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },

  "& .MuiSwitch-checked": {
    color: "white !important",
  },
}));

export default function RulesReview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusUnitsCorporativesList = useSelector(getUnitsCorporativesStatus);
  const unitsCorporativesList = useSelector(getUnitsCorporativesList);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpenModalResponseUpdate, setIsOpenModalResponseUpdate] =
    useState(false);
  const [isOpenModalErrorResponseUpdate, setIsOpenModalErrorResponseUpdate] =
    useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoadingCorporateUnits, setIsLoadingCorporateUnits] = useState(false);
  const ID_STATE_ACTIVE = "626a9b5456c1dd7a1b145cb9";
  const ID_STATE_INACTIVE = "626a9acf56c1dd7a1b145cb8";

  const handleActiveRuleForUnitCorporative = (event, item, expression) => {
    const isChecked = event.target.checked;
    const newData = filteredData.map((oldItem) => {
      if (oldItem?._id === expression?._id) {
        if (isChecked) {
          return {
            ...oldItem,
            activeInCorporateUnits: [...oldItem.activeInCorporateUnits, item],
          };
        } else {
          return {
            ...oldItem,
            activeInCorporateUnits: oldItem.activeInCorporateUnits.filter(
              (corporateUnit) => corporateUnit?._id !== item?._id
            ),
          };
        }
      }
      return oldItem;
    });
    setFilteredData(newData);
  };
  const changeState = (data) => {
    const newData = data.map((item) => {
      let newState = null;
      if (!item.activeInCorporateUnits.length) {
        newState = ID_STATE_INACTIVE;
      } else {
        newState = ID_STATE_ACTIVE;
      }
      return {
        ...item,
        status: newState,
        activeInCorporateUnits:
          item?.activeInCorporateUnits?.map(
            (unitCorporative) => unitCorporative?._id
          ) || [],
        applicableCorporateUnits:
          item?.applicableCorporateUnits?.map(
            (unitCorporative) => unitCorporative?._id
          ) || [],
      };
    });
    return newData;
  };
  const buildObjData = (data) => {
    const response = data.map((rule) => ({
      reviewRuleId: rule?._id,
      activeInCorporateUnits: rule?.activeInCorporateUnits,
      applicableCorporateUnits: rule?.applicableCorporateUnits,
      name: rule?.name,
      status: rule?.status,
    }));
    return response;
  };
  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      const data = changeState(filteredData);
      const arrayObj = buildObjData(data);
      const response = await updateRules(arrayObj);
      setResponseData(response);
      if (response.status !== CODES.COD_RESPONSE_HTTP_OK) {
        setIsOpenModalErrorResponseUpdate(true);
        return;
      }
      setIsOpenModalResponseUpdate(true);
    } catch (error) {
      console.log("======= Start update rules data =======");
      console.error(error);
      console.log("======= End update rules data =======");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const companyId = localStorage.getItem("company");
        const response = await findAllRulesByCompany(companyId);
        if (response?.status === CODES.COD_RESPONSE_HTTP_OK) {
          const data = response?.data?.responseMessage?.data;
          setFilteredData(data);
        }
      } catch (error) {
        console.log("======= Start fetch rules data =======");
        console.error(error);
        console.log("======= End fetch rules data =======");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusUnitsCorporativesList === "fetch") {
          dispatch(fetchUnitsCoporativesList());
        }
      } catch (error) {
        console.log("========Fetch Units Corporatives Error========");
        console.log(error);
        console.log("========Finish Fetch Units Corporatives Error========");
      }
    };
    fetchData();
  }, [statusUnitsCorporativesList, dispatch]);
  useEffect(() => {
    if (statusUnitsCorporativesList === "loading") {
      setIsLoadingCorporateUnits(true);
    } else {
      setIsLoadingCorporateUnits(false);
    }
  }, [statusUnitsCorporativesList]);
  return (
    <Container fluid className="custom-container-scroll">
      {(isLoading || isLoadingCorporateUnits) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Reglas de revisión" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption" style={{ fontSize: "1.4rem" }}>
          Elige las reglas de revisión que podrán utilizarse en la revisión de
          otros documentos.
        </Col>
      </Row>
      <br />
      <Row
        style={{
          width: "100%",
          height: "40px",
          background: "#E2EAED",
          color: "#00374F",
          fontWeight: 700,
          padding: "1.2rem",
          alignItems: "center",
          fontSize: "12px",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <Col sm={3}>Nombre</Col>
        <Col sm={4}>Descripción</Col>
        <Col sm={3}>Unidad corporativa</Col>
        <Col sm={2}>Estado</Col>
      </Row>
      <div className="custom-accordion__container">
        {filteredData?.length > 0 &&
          filteredData.map((expression) => {
            return (
              <div key={expression?._id}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Row style={{ width: "100%", alignItems: "center" }}>
                      <Col sm={3}>
                        <p className="heading__primary-color">
                          {expression?.name}
                        </p>
                      </Col>
                      <Col sm={4}>
                        <p className="caption" style={{ textAlign: "justify" }}>
                          {expression?.description}
                        </p>
                      </Col>
                      <Col sm={3} style={{ display: "flex" }}>
                        <span className="subheading">
                          {expression?.activeInCorporateUnits
                            ?.filter((item) =>
                              unitsCorporativesList.some(
                                (uc) => uc?._id === item?._id
                              )
                            )
                            .map((ucActive) => ucActive?.name)
                            .join(", ") ||
                            "No se ha activado la regla para unidades corporativas"}
                        </span>
                      </Col>
                      <Col sm={2} style={{ display: "flex" }}>
                        <span
                          className="subheading custom-badges"
                          style={{
                            backgroundColor:
                              expression?.activeInCorporateUnits?.some((item) =>
                                unitsCorporativesList.some(
                                  (uc) => uc?._id === item?._id
                                )
                              )
                                ? "#FFD7B1"
                                : "#E6E9EF",
                          }}
                        >
                          {expression?.activeInCorporateUnits?.some((item) =>
                            unitsCorporativesList.some(
                              (uc) => uc?._id === item?._id
                            )
                          )
                            ? "Activa"
                            : "Inactiva"}
                        </span>
                      </Col>
                    </Row>
                  </AccordionSummary>
                  <AccordionDetails>
                    {unitsCorporativesList?.length > 0 && (
                      <>
                        <Row>
                          <span className="subheading">
                            Activar unidades corporativas
                          </span>
                        </Row>
                        <br />
                        <Row>
                          {generateGridItems(
                            unitsCorporativesList?.map((item) => (
                              <FormControlLabel
                                key={item?._id}
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={expression?.activeInCorporateUnits?.some(
                                      (corporateUnit) =>
                                        corporateUnit?._id === item?._id
                                    )}
                                  />
                                }
                                label={
                                  <span className="caption">{item?.name}</span>
                                }
                                onChange={(event) =>
                                  handleActiveRuleForUnitCorporative(
                                    event,
                                    item,
                                    expression
                                  )
                                }
                              />
                            ))
                          )}
                        </Row>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
      </div>
      <Row></Row>
      <br />
      <Row style={{ justifyContent: "end" }}>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleSaveChanges}
          >
            <p className="button">Guardar cambios</p>
          </Button>
        </Col>
      </Row>
      <ModalInfo
        title={"Editar reglas"}
        responseData={responseData}
        open={isOpenModalResponseUpdate}
        onClose={() => navigate(-1)}
      />
      <ModalInfo
        title={"Error editando reglas"}
        responseData={responseData}
        open={isOpenModalErrorResponseUpdate}
        onClose={() => setIsOpenModalErrorResponseUpdate(false)}
      />
    </Container>
  );
}
