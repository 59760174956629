import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
const EllipsisComponent = ({ text, isWhite, isHtml }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`ellipsis-container${isExpanded ? "expanded" : ""}`}>
      <div
        className={`ellipsis-text ${isExpanded ? "expanded" : ""}`}
        onClick={() => toggleExpand()}
      >
        {isHtml ? ReactHtmlParser(text) : text}
      </div>
      {!isExpanded && (
        <div
          className={`${
            isWhite ? "ellipsis-overlay__white" : "ellipsis-overlay"
          }`}
          onClick={() => toggleExpand()}
        >
          ...
        </div>
      )}
    </div>
  );
};

export default EllipsisComponent;
