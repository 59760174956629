import React from "react";
import historyConsultsIcon from "../../assets/svg/historyConsults.svg";
export const NoResultsFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img src={historyConsultsIcon} alt="No hay resultados" />
    </div>
  );
};
