import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  getBlankSpaceIndex,
  replaceBlankSpace,
  updateStringBlankSpaces,
} from "../../../../../../utils/blankspaces";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { ImportClauseModal } from "../../../../../../parts/associations/ImportClauseModal";
import { ImportPredeterminedText } from "../../../../../../parts/associations/ImportPredeterminedText";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import { generateClauseHTML } from "../../../../../../utils/clausesUtils";
import ReactDOMServer from "react-dom/server";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../../../../../utils/regexp";
import { Controller, useForm } from "react-hook-form";
import RichEditorText from "../../../../../../parts/RichEditorText";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { WordBlobOtherDocument } from "../../../../../../components/WordCreator/WordCreator";
import moment from "moment";
import { saveAs } from "file-saver";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import {
  setFileType,
  setInitialInfoLibrary,
  setName,
  setOtherDocInfo,
} from "../../../../../../parts/document/currentDocumentSlice";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalNameAndTags from "../../../../../../components/Modals/ModalNameAndTags";
import { convertToHtml } from "mammoth/mammoth.browser";
import ModalSaveFileAndType from "../../../../../../components/Modals/ModalSaveFileAndType";
import {
  getDocumentInfo,
  setDocumentInfo,
} from "../../../../../../parts/document/otherDocumentSlice";
import { createPrederminatedTextService } from "../../../../../../services/predeterminatedTexts/predeterminatedTextsService";
import { CODES } from "../../../../../../utils/codes";
import { setStatusPrederminatedTexts } from "../../library/pretederminatedTexts/prederminatedTextsSlice";

export default function CreateOtherDocumentFromTemplate() {
  //Const
  const { state: templateSelected } = useLocation();
  const { pathname } = useLocation();
  const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
  const saveOptionsValues = {
    DRAFT: "1",
    OTHERDOCEDIT: "2",
    OTHERDOCNOEDIT: "3",
  };

  //Const utils

  const saveOptions = [
    { value: saveOptionsValues.DRAFT, label: "Guardar como un nuevo borrador" },
    {
      value: saveOptionsValues.OTHERDOCEDIT,
      label: "Guardar como versión editable",
    },
    {
      value: saveOptionsValues.OTHERDOCNOEDIT,
      label: "Guardar como versión no editable",
    },
  ];

  const fromCreate = pathname.includes("from-text")
    ? "predeterminatedText"
    : "template";

  const typeCreate = {
    predeterminatedText: {
      name: "bloques",
    },
    template: {
      name: "plantilla",
    },
  };

  //Hooks
  const navigate = useNavigate();

  //Redux
  const dispatch = useDispatch();

  const document = useSelector(getDocumentInfo);

  //Refs
  const documentRichEditorTextRef = useRef();

  const blankspacesRef = useRef(document.blankSpaces);
  blankspacesRef.current = document.blankSpaces;

  //Form schema

  const schema = yup.object().shape({
    title: yup.string().required("*Este campo es obligatorio"),
    documentText: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.RICH_EDITOR_CONTENT, {
        message: "*Este campo no puede estar vacío",
      }),
  });

  //Form
  const {
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  //useStates
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalImportClauseText, setIsOpenModalImportClauseText] =
    useState(false);
  const [isOpenModalUploadDocument, setIsOpenModalUploadDocument] =
    useState(false);
  const [isOpenModalImportText, setIsOpenModalImportText] = useState(false);
  const [isOpenModalNoTextSelected, setIsOpenModalNoTextSelected] =
    useState(false);
  const [isOpenModalSaveFile, setIsOpenModalSaveFile] = useState(false);

  const [documentAdditionalInfoToSave, setDocumentAdditionalInfoToSave] =
    useState({ type: "", placeholder: "" });
  const [isOpenModalSaveDocument, setIsOpenModalModalSaveDocument] =
    useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseDataModalInfo, setResponseDataModalInfo] = useState({});
  //Menus
  const [anchorElImport, setAnchorElImport] = useState(null);

  const isOpenImportMenu = Boolean(anchorElImport);

  //Functions
  const addNewBlankSpace = async (blankspace) => {
    await dispatch(
      setDocumentInfo({
        blankSpaces: [...blankspacesRef.current, blankspace],
      })
    );

    documentRichEditorTextRef.current.addBlankspacesArray([blankspace]);
  };

  const addNewText = (text) => {
    dispatch(
      setDocumentInfo({
        text: text,
      })
    );

    documentRichEditorTextRef.current.addText(text);
  };

  const removeBlankSpace = (index) => {
    dispatch(
      setDocumentInfo({
        blankSpaces: document.blankSpaces.filter(
          (blankSpace) => blankSpace.id !== index
        ),
      })
    );
    documentRichEditorTextRef.current.removeBlankSpace(index);
  };

  const downloadWordDocument = async (htmlString) => {
    try {
      setIsLoading(true);
      let fileWord = await WordBlobOtherDocument({
        title: getValues("title") || "Nueva documento",
        content: htmlString,
      });
      const nameDoc = `${
        getValues("title") || "documento-" + moment().format("DD/MM/YYYY")
      }.docx`;
      saveAs(fileWord, nameDoc);
    } catch (error) {
      console.error("Download document word error", error);
    } finally {
      setIsLoading(false);
    }
  };

  //handles
  const handleBlankSpaces = async () => {
    let blankSpaceId = getBlankSpaceIndex(document.blankSpaces);
    let item = {
      id: blankSpaceId,
      name: `blankSpace-${blankSpaceId}`,
    };
    dispatch(
      setDocumentInfo({
        blankSpaces: [...document.blankSpaces, item],
      })
    );
    documentRichEditorTextRef.current.addBlankSpace(document.blankSpaces);
  };

  const handleAddPredeterminedTextToTemplate = async (selectedTextList) => {
    const regex = /¬ESPACIO #(\d+)¬/g;
    let currentBlankspacesLenght = document.blankSpaces.length;
    selectedTextList.forEach(async (predeterminedText) => {
      let inputString = predeterminedText.text;
      let updatedString = "";
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(inputString)) !== null) {
        currentBlankspacesLenght++;

        const replacement = `¬ESPACIO #${currentBlankspacesLenght}¬`;
        let newBlankSpace = {
          id: currentBlankspacesLenght,
          name: `blankSpace-${currentBlankspacesLenght}`,
        };

        await addNewBlankSpace(newBlankSpace);

        const substring = inputString.substring(lastIndex, match.index);
        updatedString += substring + replacement;

        lastIndex = regex.lastIndex;
      }

      updatedString += inputString.substring(lastIndex);

      const finalText = replaceBlankSpace(updatedString);

      addNewText(finalText);
    });
  };

  const handleAddClauseToTemplate = async (clause, isTitleIncluded) => {
    const clauseStructure = generateClauseHTML({ clause, isTitleIncluded });

    const clauseStructureString =
      ReactDOMServer.renderToStaticMarkup(clauseStructure);
    await handleAddPredeterminedTextToTemplate([
      { text: clauseStructureString },
    ]);
  };

  const handleSelectionChange = ({ event, selection }) => {
    setSelectedText(selection || "");
  };

  const handleSaveInFolder = (data) => {
    setIsOpenModalSaveFile(false);

    const name = data.fileName || `documento-${moment().format("DD/MM/YYYY")}`;

    const fileType =
      data.saveType !== saveOptionsValues.DRAFT
        ? codesDocumentType?.OtherDocs?._id
        : codesDocumentType?.draft?._id;

    dispatch(
      setInitialInfoLibrary({
        title: name,
      })
    );
    dispatch(
      setOtherDocInfo({
        title: getValues("title") || "",

        blankspaces: document.blankSpaces,
        text: getValues("documentText"),
        canEdit: data.saveType !== saveOptionsValues.OTHERDOCNOEDIT,
        otherDocType: fromCreate,
      })
    );
    dispatch(setFileType({ fileType }));
    dispatch(setName({ name }));
    navigate(`otherDoc/select-folder`);
  };

  const handleCreateOtherDocument = async (documentName, tagsSelected) => {
    await handleCreateText(documentName, tagsSelected);
  };

  const handleCreateText = async (documentName, tagsSelected) => {
    const { text, blankspaces } = updateStringBlankSpaces(selectedText);

    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const createTextObject = {
      name: documentName,
      text,
      blankspaces,
      labels: tagsSelected.map((tag) => tag._id),
      company,
      corporateUnit,
      createdBy: userId,
    };

    setIsLoading(true);
    const createTextService = await createPrederminatedTextService(
      createTextObject
    );
    if (
      createTextService.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      createTextService?.data?.success
    ) {
      dispatch(setStatusPrederminatedTexts());
      setIsOpenModalModalSaveDocument(false);
    }
    setIsOpenModalInfo(true);
    setResponseDataModalInfo(createTextService);

    setIsLoading(false);
  };

  const filterOutImages = (htmlString) => {
    return htmlString.replace(/<img[^>]*>/g, "");
  };

  const handleFileChange = useCallback(async (file) => {
    if (file.name) {
      try {
        setIsLoading(true);
        const result = await readWordFile(file);
        const filteredHtml = filterOutImages(result);
        documentRichEditorTextRef.current.addText(filteredHtml);
        setAnchorElImport(null);
      } catch (error) {
        console.error("Error reading Word file:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const readWordFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const arrayBuffer = event.target.result;
        try {
          const result = await convertToHtml({
            arrayBuffer,
          });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    });
  }, []);

  useEffect(() => {
    const name = document.name || templateSelected.name;
    const text = document.text || templateSelected.text;
    const blankspaces = document.text
      ? document.blankSpaces
      : templateSelected?.blankspaces;

    documentRichEditorTextRef.current.setEditorContents(text);
    documentRichEditorTextRef?.current?.addBlankspacesArray(blankspaces);
    setValue("title", name);
    dispatch(
      setDocumentInfo({
        text,
        blankSpaces: blankspaces,
        name,
      })
    );
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={`Nuevo documento desde ${
            typeCreate?.[fromCreate]?.name || ""
          }`}
        />
      </Row>
      <br />
      <br />
      <Row>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            startIcon={<AddIcon fontSize="large" />}
            endIcon={<KeyboardArrowDownOutlinedIcon fontSize="large" />}
            className="custom-input__button__primary-color"
            onClick={(event) => setAnchorElImport(event.currentTarget)}
          >
            Importar
          </Button>
          <Menu
            anchorEl={anchorElImport}
            open={isOpenImportMenu}
            onClose={() => {
              setAnchorElImport(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setIsOpenModalImportClauseText(true);
                setAnchorElImport(null);
              }}
            >
              <ListItemIcon>
                <PostAddOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Texto desde cláusula
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsOpenModalImportText(true);
                setAnchorElImport(null);
              }}
            >
              <ListItemIcon>
                <PlaylistAddOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Texto desde bloque
              </ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setIsOpenModalUploadDocument(true);
                setAnchorElImport(null);
              }}
            >
              <ListItemIcon>
                <NoteAddOutlinedIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Documento word
              </ListItemText>
            </MenuItem>
          </Menu>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            startIcon={<BorderColorOutlinedIcon fontSize="large" />}
            className="custom-input__button__primary-color"
            onClick={() => navigate(`blankspaces`)}
            disabled={!document?.blankSpaces.length}
          >
            Rellenar espacios en blanco
          </Button>
        </Col>
        <Col xs={"auto"} className="ml-auto">
          <Button
            variant="contained"
            startIcon={<FactCheckOutlinedIcon fontSize="large" />}
            className="custom-input__button__secondary-color"
            onClick={() => {
              if (!selectedText.length) {
                setIsOpenModalNoTextSelected(true);
              } else {
                setDocumentAdditionalInfoToSave({
                  type: "other",
                  placeholder: "Escriba el nombre del texto",
                  text: "Por favor, escriba un nombre para este bloque.",
                });
                setIsOpenModalModalSaveDocument(true);
              }
            }}
          >
            Guardar selec. como bloque
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon fontSize="large" />}
            className="custom-input__button__secondary-color"
            onClick={async () => await handleBlankSpaces()}
          >
            Espacio en blanco
          </Button>
        </Col>
      </Row>
      <br />
      <Form onSubmit={handleSubmit(handleCreateOtherDocument)}>
        <Row>
          <Col xs={6}>
            <Form.Group>
              <Form.Label className="form__label">
                Título del documento<span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Control
                {...register("title", {
                  onChange: (e) => {
                    setValue("title", e.target.value.trimLeft());
                    dispatch(
                      setDocumentInfo({
                        name: e.target.value.trimLeft(),
                      })
                    );
                  },
                  setValueAs: (value) => value.trim(),
                })}
                placeholder="Título"
                bsPrefix={
                  errors.title
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.title?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-5" style={{ maxHeight: "393px" }}>
          <Col xs={10} style={{ marginBottom: "8rem" }}>
            <Controller
              control={control}
              name="documentText"
              render={({ field: { onChange, onBlur } }) => {
                const handleEditorChange = (content) => {
                  onChange(content);
                  dispatch(setDocumentInfo({ text: content }));
                };

                return (
                  <RichEditorText
                    onBlur={onBlur}
                    onChange={handleEditorChange}
                    customClassName={
                      errors.documentText
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                    placeholder="Escriba aquí"
                    ref={documentRichEditorTextRef}
                    handleSelection={handleSelectionChange}
                    deleteBlankSpace={(id) => {
                      dispatch(
                        setDocumentInfo({
                          ...document,
                          blankSpaces: blankspacesRef.current.filter(
                            (blankSpace) => blankSpace.id !== id
                          ),
                        })
                      );
                    }}
                    minHeight="40rem"
                  />
                );
              }}
            />

            <div className="caption custom-input__error">
              {errors.documentText?.message}
            </div>
          </Col>
          <Col xs={2}>
            <Container>
              <Row style={{ marginTop: "6%" }}>
                <p className="label">Espacios en blanco:</p>
              </Row>
              {document?.blankSpaces.map((item, index) => {
                return (
                  <Row xs={"auto"} key={`Template ${item?.id} ${index}`}>
                    <p className="heading__primary-color">
                      ESPACIO #{item.id}{" "}
                      <IconButton onClick={() => removeBlankSpace(item.id)}>
                        <DeleteOutlineIcon
                          className="heading__primary-color"
                          fontSize="large"
                        />
                      </IconButton>
                    </p>
                  </Row>
                );
              })}
            </Container>
          </Col>
          <Row className="sidebar__bottom__container">
            <Col xs={"auto"}>
              <Button
                type="button"
                variant="contained"
                className="custom-input__button__secondary-color"
                startIcon={<DownloadOutlinedIcon fontSize="large" />}
                onClick={() => downloadWordDocument(document.text)}
              >
                Descargar word
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                type="button"
                variant="contained"
                className="custom-input__button__primary-color"
                startIcon={<FolderOutlinedIcon fontSize="large" />}
                onClick={() => setIsOpenModalSaveFile(true)}
              >
                Guardar como
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
      <ImportClauseModal
        show={isOpenModalImportClauseText}
        setShow={setIsOpenModalImportClauseText}
        mixPanelTrack="Crear Btn Importar Texto Predeterminado"
        onSelect={handleAddClauseToTemplate}
      />
      <ImportPredeterminedText
        show={isOpenModalImportText}
        setShow={setIsOpenModalImportText}
        mixPanelTrack="Crear Btn Importar Texto Predeterminado"
        onSelect={handleAddPredeterminedTextToTemplate}
      />
      <ModalUploadDocs
        open={isOpenModalUploadDocument}
        title={"Selecciona el documento para subir"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato word y pesar máximo 75mb"
        }
        setDocumentData={(file) => handleFileChange(file)}
        onClose={() => {
          setIsOpenModalUploadDocument(false);
        }}
        typeDocument={"ONLY_WORD"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato Word"
        maxFileSize={78643200} //75MB
      />
      <ModalInfo
        title="Crear bloque"
        onClose={() => setIsOpenModalNoTextSelected(false)}
        open={isOpenModalNoTextSelected}
        responseData={{
          status: 400,
          data: {
            responseMessage:
              "Debe seleccionar primero una sección de texto para almacenarla como bloque",
          },
        }}
        confirmationText="Aceptar"
      />
      <ModalNameAndTags
        open={isOpenModalSaveDocument}
        onClose={setIsOpenModalModalSaveDocument}
        title={"Nombrar y etiquetar"}
        placeholder={documentAdditionalInfoToSave.placeholder}
        text={documentAdditionalInfoToSave.text}
        handleAgree={handleCreateOtherDocument}
      />

      <ModalSaveFileAndType
        open={isOpenModalSaveFile}
        onClose={setIsOpenModalSaveFile}
        handleAgree={handleSaveInFolder}
        saveOptions={saveOptions}
        defaultValues={{ fileName: getValues("title") }}
      />

      <ModalInfo
        title="Crear otro documento"
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
        responseData={responseDataModalInfo}
      />
    </Container>
  );
}
