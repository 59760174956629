import { yupResolver } from "@hookform/resolvers/yup";
import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
//Material Import
import { Button, Checkbox, FormControlLabel, IconButton } from "@mui/material";
//parts
import Breadcrum from "../../../../../parts/breadcrum";
//components
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
//Icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Services
import {
  getDocById,
  getFileDocument,
  segmentDocument,
} from "../../../../../services/documents/documentsRequest";
//Modals
import ModalInfo from "../../../../../components/Modals/modalInfo";
//Utils
import ModalDecision from "../../../../../components/Modals/modalDecision";
import {
  getReportSelected,
  setName,
  setReports,
} from "../../../../../parts/document/currentDocumentSlice";
import { getAttorneys } from "../../../../../services/general/requestServices";
import { CODES } from "../../../../../utils/codes";
import { REGEXP } from "../../../../../utils/regexp";
import { createAlert, modifyAlert } from "./alertSlice";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import {
  BandTypesNotification,
  DestinationTypeNotification,
} from "./alert.model";
import moment from "moment";
import { ViewDocumentLegal } from "../../../../../components/WordCreator/ViewDocumentLegal";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModalUploadDocs from "../../../../../components/Modals/ModalUploadDocs";
import { codesDocumentTypeForAlert } from "../../../../../utils/codesDocumentType";
import {
  addVersion,
  milestonesReport,
} from "../../../../../services/versions/versionRequest";
import ModalIcon from "../../../../../parts/modals/ModalIcon";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import ModalViewMilestones from "./viewMilestones";
import ModalAssociateVersion from "../../../../../components/Modals/modalAssociateVersion";
import { getFolders } from "../../../../../parts/folders/foldersSlice";
import { Buffer } from "buffer";
import { MDBBadge } from "mdbreact";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getAlertById } from "../../../../../services/alerts/alertsServices";
import { convertToPdfService } from "../../../../../services/utils/convertWordToPdf";

const CreateAlertNewModule = () => {
  //navigate
  const navigate = useNavigate();

  //Params
  const { id, alertId } = useParams();

  //Location

  //Selectors
  const getMilestone = useSelector(getReportSelected);
  const folders = useSelector(getFolders);

  const dispatch = useDispatch();

  //Schemas
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es requerido")
      .max(250, "Máximo 250 caracteres permitidos")
      .matches(REGEXP.LETTER_NUMBERS_SPECIALCHAR, {
        message: "*Caracteres permitidos #?!@$%^&*-",
      }),
    milestone: yup
      .string()
      .notRequired()
      .max(250, "Máximo 250 caracteres permitidos"),
    clause: yup.string().notRequired(),
    description: yup
      .string()
      .notRequired()
      .max(1000, "Máximo 1000 caracteres permitidos"),
    inCharge: yup
      .string()
      .required("*Este campo es requerido")
      .matches(REGEXP.MONGO_OBJECT_ID, "*Este campo es requerido"),
    emails: yup
      .array()
      .of(
        yup
          .string()
          .email("*Este campo debe ser de tipo email")
          .required("*Este campo es requerido")
      ),
    alertDate: yup.string().required("*Este campo es obligatorio"),
    alertDateDeadline: yup
      .string()
      .required("*Este campo es obligatorio")
      .test(
        "minDate",
        "*La fecha debe ser mayor a la de activación",
        function (value) {
          if (!!value) {
            const dateInitText = `${this.resolve(yup.ref("alertDate"))}`;
            const dateEndText = `${value}`;
            return dateInitText < dateEndText;
          }
          return true;
        }
      ),
    rememberAlert: yup.bool(),
    numberRemember: yup.number().when("rememberAlert", {
      is: true,
      then: yup
        .number()
        .nullable()
        .typeError("*Este campo es obligatorio")
        .max(30, "*El número debe estar entre 1 - 30")
        .required("*Este campo es obligatorio"),
    }),
    typeRemember: yup.string().when("rememberAlert", {
      is: true,
      then: yup
        .string()
        .required("*Este campo es obligatorio")
        .oneOf(
          [
            BandTypesNotification.DAYS,
            BandTypesNotification.WEEKS,
            BandTypesNotification.MONTHS,
          ],
          "*Este campo es obligatorio"
        )
        .test(
          "minDateNotifications",
          "*La fecha del recordatorio debe ser posterior a la fecha de activación",
          function (value) {
            if (!!value) {
              const amount = this.resolve(yup.ref("numberRemember"));
              const dateActive = this.resolve(yup.ref("alertDateDeadline"));
              const dateMin = moment(this.resolve(yup.ref("alertDate")));
              const date = handleFindDate({
                numberDays: amount,
                type: value,
                date: dateActive,
                typeAction: "SUBSTRACT",
              });
              return date > dateMin;
            }
            return false;
          }
        ),
    }),
    repeatAlert: yup.bool(),
    numberRepeat: yup.number().when("repeatAlert", {
      is: true,
      then: yup
        .number()
        .nullable()
        .typeError("*Este campo es obligatorio")
        .max(30, "El número debe estar entre 1 - 30")
        .required("*Este campo es obligatorio"),
    }),
    typeRepeat: yup.string().when("repeatAlert", {
      is: true,
      then: yup
        .string()
        .oneOf(
          [
            BandTypesNotification.WEEKS,
            BandTypesNotification.MONTHS,
            BandTypesNotification.YEARS,
          ],
          "*Este campo es obligatorio"
        ),
    }),
    durationRepeat: yup.number().when("repeatAlert", {
      is: true,
      then: yup
        .number()
        .nullable()
        .typeError("*Este campo es obligatorio")
        .max(30, "El número debe estar entre 1 - 30")
        .required("*Este campo es obligatorio"),
    }),
    typeDurationRepeat: yup.string().when("repeatAlert", {
      is: true,
      then: yup
        .string()
        .oneOf(
          [
            BandTypesNotification.WEEKS,
            BandTypesNotification.MONTHS,
            BandTypesNotification.YEARS,
          ],
          "*Este campo es obligatorio"
        ),
    }),
  });

  //Use Hook Forms
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields: emailsFields,
    append: emailsAppend,
    remove: emailsRemove,
  } = useFieldArray({
    control,
    name: "emails",
  });

  //Conts
  const stateColorId = {
    [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
    [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
    [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
    [CODES.COD_LIGHT_BLUE]: "#FFD7B1",
  };

  const STATE_COMPLETE = {
    _id: "626a9bbd56c1dd7a1b145cba",
    colorId: 300,
    description: "Se ha marcado la tarea como completa",
    name: "Completada",
  };

  //UseStates
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAlert, setIsLoadingAlert] = useState(false);
  const [users, setUsers] = useState([]);
  const [dateRemember, setDateRemember] = useState("");
  const [dateRepeat, setDateRepeat] = useState("");
  const [documentLegal, setDocumentLegal] = useState(null);
  const [openModalUploadDocs, setOpenModalUploadDocs] = useState(false);
  const [openModalCreateAlert, setOpenModalCreateAlert] = useState(false);
  const [openModalDecisionCancel, setOpenModalDecisionCancel] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [
    isOpenModalResponseCreateVersion,
    setIsOpenModalResponseCreateVersion,
  ] = useState(false);
  const [openModalMilestones, setOpenModalMilestones] = useState(false);
  const [openModalIconLoading, setOpenModalIconLoading] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [openModalDecisionSave, setOpenModalDecisionSave] = useState({
    open: false,
    action: null,
  });
  const [openModalNotFoundMilestones, setOpenModalNotFoundMilestones] =
    useState(false);
  const [documentHasMilestones, setDocumentHasMilestones] = useState(false);
  const [defaultValueCheckRemember, setDefaultValueCheckRemember] =
    useState(false);
  const [defaultValueCheckRepeat, setDefaultValueCheckRepeat] = useState(false);
  const [openModalEditAlert, setOpenModalEditAlert] = useState(false);
  const [documentType, setDocumentType] = useState({});
  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [datesNext, setDatesNext] = useState({
    active: null,
    deadline: null,
    remember: null,
  });
  const [responseDataError, setResponseDataError] = useState({});
  const [isOpenModalInfoError, setIsOpenModalInfoError] = useState(false);
  const [dataAlert, setDataAlert] = useState(null);
  const [milestoneSelected, setMilestoneSelected] = useState({});

  //Functions
  const buildDataAlert = (data) => {
    let notifications, durationRepeats, frequencyRepeats;

    const documentId = id === "without-document" ? "" : id;
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));

    const alertDateTimeText = data?.alertDate || "";

    const alertDate = new Date(alertDateTimeText).toISOString();
    // const alertDate = moment(alertDateTimeText).format();

    const alertDateTimeDeadLineText = data?.alertDateDeadline || "";
    const alertDateDeadline = new Date(alertDateTimeDeadLineText).toISOString();

    if (data?.rememberAlert) {
      notifications = [
        {
          destinationType: DestinationTypeNotification.EMAIL,
          numberBand: data?.numberRemember,
          typeBand: data?.typeRemember,
        },
      ];
    }

    if (data?.repeatAlert) {
      frequencyRepeats = {
        numberBand: data?.numberRepeat,
        frequencyBand: data?.typeRepeat,
      };
      durationRepeats = {
        numberBand: data?.durationRepeat,
        frequencyBand: data?.typeRepeat,
      };
    }

    const emailInCharge = users.find(
      (user) => user._id === data?.inCharge
    ).email;
    const emails = [...data.emails, emailInCharge];

    return {
      name: data?.name || "",
      alertDate,
      tasksDeadline: alertDateDeadline,
      documentAssociated: documentId,
      notifications,
      emails,
      description: data?.description || "",
      company,
      corporateUnit,
      createdBy: userId,
      isRepeated: data?.repeatAlert || false,
      isRemember: data?.rememberAlert || false,
      frequencyRepeats,
      durationRepeats,
      milestone: data?.milestone || "",
      clause: data?.clause,
      assignedTo: data?.inCharge || "",
      timeZone: new Date().getTimezoneOffset() / -60,
      milestoneSelected: milestoneSelected,
    };
  };

  const handleCreateAlert = async (data) => {
    try {
      setIsLoading(true);

      const obj = buildDataAlert(data);

      const response = await dispatch(createAlert(obj)).unwrap();

      setResponseData(response);
      setOpenModalCreateAlert(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const flashItemSelected = (idItem) => {
    if (!idItem) return;
    let item = document.getElementById(idItem);
    const docView = document.getElementById("content-document");
    const keyframes = [
      { backgroundColor: "#FFC4C0" },
      { backgroundColor: "#FFFFFF" },
    ];
    const options = { duration: 1200, iterations: 1 };
    const elementRect = item.getBoundingClientRect();
    const position =
      docView.scrollTop +
      (elementRect.top - docView.getBoundingClientRect().height / 2);
    docView.scrollTo({ top: position, behavior: "smooth" });
    if (item) item.animate(keyframes, options);
  };

  const handleFindDate = ({ numberDays, type, date, typeAction = "ADD" }) => {
    if (date && numberDays && type !== "0") {
      if (typeAction === "SUBSTRACT")
        return moment(date).subtract(numberDays, type);
      if (typeAction === "ADD") return moment(date).add(numberDays, type);
    }
    return "";
  };

  const handleFindMilestones = async ({ version, file }) => {
    try {
      setIsLoading(true);
      setOpenModalUploadDocs(false);
      if (!file?.name) return;

      const company = localStorage.getItem("company");

      let dataFile = file;
      
      if (dataFile?.type !== "application/pdf") {
        const convertDoc = await convertToPdfService(dataFile, "file");
        const docView = convertDoc.data.responseMessage.data.buffer.data;
        const bf = Buffer.from(docView);
        const name = file?.name.substring(
          0,
          dataFile?.name.lastIndexOf(".")
        );
        dataFile = new File([bf], name + ".pdf", {
          type: "application/pdf",
        });
      }

      const segmentDoc = await segmentDocument({
        file: dataFile,
        company,
      });

      const newDocument = segmentDoc.data.responseMessage;

      const responseAddVersion = await addVersion({
        ...version,
        documentContent: {
          ...newDocument,
          fileType: version?.fileType,
        },
        file,
      });

      if (
        responseAddVersion.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseAddVersion.data.success
      ) {
        const id = responseAddVersion?.data?.responseMessage?._id;
        fetchData(id);
        navigate(`../alerts/create/${id}`, {
          replace: true,
        });
      } else {
        setResponseData(responseAddVersion);
        setIsOpenModalResponseCreateVersion(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchMilestones = async (selectedElement) => {
    try {
      if (!alertId) setOpenModalIconLoading(true);
      const response = await milestonesReport({
        versionId: selectedElement?._id,
      });
      if (
        response?.data?.success &&
        response?.status === CODES.COD_RESPONSE_HTTP_OK
      ) {
        const resportsMilestones = response.data.responseMessage.data;
        if (resportsMilestones.length) {
          dispatch(setReports({ reports: resportsMilestones }));
          setDocumentHasMilestones(true);
          if (!alertId) {
            setOpenModalMilestones(true);
          }
        } else {
          setOpenModalNotFoundMilestones(true);
          setDocumentHasMilestones(false);
        }
      } else {
        setOpenModalNotFoundMilestones(true);
        setDocumentHasMilestones(false);
      }
    } catch (error) {
      console.log(
        "=============== Start Search Milestones Error ===================="
      );
      console.error(error);
      console.log(
        "=============== Finish Search Milestones Error ===================="
      );
    } finally {
      setOpenModalIconLoading(false);
    }
  };

  const fetchData = async (idDoc) => {
    try {
      if (idDoc === "without-document") return;
      setIsLoading(true);
      const response = await getDocById(idDoc);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        let responseDocument = response.data.responseMessage;
        const { documentContent, filenameInBucket, bucketName } =
          responseDocument;
        const parts = [
          documentContent?.parts?.partA,
          documentContent?.parts?.partB,
          documentContent?.parts?.partC,
          documentContent?.parts?.partD,
        ];
        setDocumentLegal({ ...documentContent, parts });
        if (documentContent) {
          searchMilestones(responseDocument);
        } else {
          const dataFile = await getFileDocument(
            filenameInBucket,
            bucketName,
            false
          );

          const file = dataFile?.data?.responseMessage;
          const bf = Buffer.from(dataFile.data.responseMessage.buffer.data);

          let filename = file?.filename;

          if (
            file?.contentType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            filename.split(".").length === 1
          ) {
            filename = filename + ".docx";
          }

          const newFile = new File([bf], filename, {
            type: file?.contentType,
          });

          await handleFindMilestones({
            file: newFile,
            version: responseDocument,
          });
        }
      } else {
        setIsOpenModalResponseCreateVersion(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataAlert = async (id) => {
    try {
      setIsLoadingAlert(true);
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        id: id,
        company: companyId,
        corporateUnit,
      };
      const alertResponse = await getAlertById(obj);
      if (
        alertResponse.status === CODES.COD_RESPONSE_HTTP_OK &&
        alertResponse.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const alert = alertResponse.data.responseMessage.data;
        setDataAlert(alert);
        setMilestoneSelected(alert?.milestoneSelected || {});
        setValue("name", alert.name);
        setValue("milestone", alert.milestone);
        setValue("clause", alert.clause);
        setValue("description", alert.description);
        setValue("inCharge", alert?.assignedTo?._id);
        if (alert?.emails?.length) {
          const emails = alert.emails.filter(
            (email) => email !== alert?.assignedTo?.email
          );
          emails.forEach((element, index) => {
            emailsAppend(element);
            setValue(`emails.${index}`, element);
          });
        }
        const dateAlert = dateFormat(
          alert?.alertDate,
          "yyyy-mm-dd",
          true
        ).toString();
        const dateAlertDeadline = dateFormat(
          alert?.tasksDeadline,
          "yyyy-mm-dd",
          true
        ).toString();
        setValue("alertDate", dateAlert);
        setValue("alertDateDeadline", dateAlertDeadline);

        if (alert?.isRemember) {
          setDefaultValueCheckRemember(alert?.isRemember);
          setValue("rememberAlert", alert?.isRemember);
          setValue(
            "numberRemember",
            alert?.notifications?.[alert?.notifications?.length - 1]?.numberBand
          );
          setValue(
            "typeRemember",
            alert?.notifications?.[alert?.notifications?.length - 1]?.typeBand
          );
        }
        if (alert?.isRepeated) {
          setDefaultValueCheckRepeat(alert?.isRepeated);
          setValue("repeatAlert", alert?.isRepeated);
          setValue("numberRepeat", alert?.frequencyRepeats?.numberBand);
          setValue("typeRepeat", alert?.frequencyRepeats?.frequencyBand);
          setValue("durationRepeat", alert?.durationRepeats?.numberBand);
          setValue(
            "typeDurationRepeat",
            alert?.frequencyRepeats?.frequencyBand
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAlert(false);
    }
  };

  const handleEditAlert = async (data) => {
    try {
      setIsLoading(true);
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
      const obj = buildDataAlert(data);
      const response = await dispatch(
        modifyAlert({ ...obj, id: alertId, modifiedBy: userId })
      ).unwrap();
      setResponseData(response);
      setOpenModalEditAlert(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveAlert = (data) => {
    if (alertId) {
      handleEditAlert(data);
    } else {
      handleCreateAlert(data);
    }
  };

  const handleCompleteAlert = async (dataAlert) => {
    try {
      const currentState = dataAlert?.state;
      setDataAlert((alert) => ({ ...alert, state: STATE_COMPLETE }));
      const obj = { id: dataAlert?._id, state: CODES.COD_COMPLETED_TASK };
      const responseComplete = await dispatch(modifyAlert(obj)).unwrap();
      if (responseComplete.status !== CODES.COD_RESPONSE_HTTP_OK) {
        setResponseDataError(responseComplete);
        setIsOpenModalInfoError(true);
        setDataAlert((alert) => ({ ...alert, state: currentState }));
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  //UseEffects
  useEffect(() => {
    if (!users.length) return;
    fetchData(id);
    if (alertId) {
      fetchDataAlert(alertId);
    }
  }, [users]);

  useEffect(() => {
    watch((data) => {
      const dateRemember = handleFindDate({
        numberDays: data?.numberRemember,
        type: data?.typeRemember,
        date: data?.alertDateDeadline,
        typeAction: "SUBSTRACT",
      });
      const dateRepeat = handleFindDate({
        numberDays: data?.numberRepeat,
        type: data?.typeRepeat,
        date: data?.alertDate,
      });
      if (dateRemember) setDateRemember(dateRemember?.format("DD/MM/YYYY"));
      if (dateRepeat) setDateRepeat(dateRepeat?.format("DD/MM/YYYY"));

      const dateNextActive = moment(data?.alertDate)
        .add(data?.numberRepeat, data?.typeRepeat)
        .format("DD/MM/YYYY");
      const dateNextDeadaline = moment(data?.alertDateDeadline).add(
        data?.numberRepeat,
        data?.typeRepeat
      );
      const dateNextRemember = moment(dateNextDeadaline)
        .subtract(data?.numberRemember, data?.typeRemember)
        .format("DD/MM/YYYY");
      setDatesNext({
        active: dateNextActive,
        deadline: dateNextDeadaline.format("DD/MM/YYYY"),
        remember: dateNextRemember,
      });
    });
  }, [watch]);

  useEffect(() => {
    if (getMilestone) {
      if (getMilestone?.time?.timexp?.match(REGEXP.DATE_FORMAT_AMD)) {
        const date = new Date(getMilestone.time.timexp + "T00:00:00");
        setValue("tasksDeadline", dateFormat(date, "isoDate"));
        date.setDate(date.getDate() - 10);
        setValue("alertDate", dateFormat(date, "isoDate"));
      }
      setValue("name", getMilestone.titleClause);
      setValue("milestone", getMilestone.text);
      setValue("clause", getMilestone.titleClause);
      setValue("description", getMilestone.keyPhrase);
      setMilestoneSelected(getMilestone);
    }
  }, [getMilestone]);

  useEffect(() => {
    const fetchDatUsers = async () => {
      try {
        setIsLoading(true);
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const obj = {
          companyId: company,
          corporateUnit: corporateUnit,
        };
        const { data } = await getAttorneys(obj);
        data.responseMessage.forEach((obj) => {
          if (obj.firstName) {
            obj.name =
              `${obj.firstName} ${obj.secondName} ${obj.firstSurname} ${obj.secondSurname}`.replace(
                / {2,}/g,
                " "
              );
          } else {
            obj.name = obj?.companies[0]?.companyData?.companyName;
          }
        });
        setUsers(data.responseMessage);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDatUsers();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {(isLoading || isLoadingAlert) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Datos de la alerta"} />
        </Col>
        <Col />
        {documentHasMilestones && (
          <Col xs={"auto"}>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => setOpenModalMilestones(true)}
            >
              Hitos
            </Button>
          </Col>
        )}
      </Row>
      <Row className="pt-3">
        <Col xs={12}>
          <p className="caption">
            Completa la siguiente información de la alerta para crearla
          </p>
        </Col>
      </Row>
      {alertId && (
        <>
          <br />
          <Row>
            <p className="subheading">
              Estado de la alerta: {"  "}
              <span className="caption">
                <MDBBadge
                  color=""
                  pill
                  className="caption"
                  style={{
                    backgroundColor:
                      stateColorId[dataAlert?.state?.colorId] || `#F9DADE`,
                    fontSize: "1.2rem",
                    borderRadius: "0.4rem",
                    padding: "0.4rem",
                  }}
                >
                  <span style={{ color: "black" }}>
                    {dataAlert?.state?.name || "Inactivo"}
                  </span>
                </MDBBadge>
              </span>
              {"  "}
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: "#676879",
                  },
                  "&.MuiFormControlLabel-root": {
                    margin: "0 !important",
                  },
                }}
                control={
                  <Checkbox
                    checked={dataAlert?.state?._id === CODES.COD_COMPLETED_TASK}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                      color: "#00374f",
                      "&.Mui-checked": {
                        color: "#00374f",
                      },
                    }}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    className="caption__disabled"
                    onClick={() => {
                      if (dataAlert?.state?._id === CODES.COD_COMPLETED_TASK)
                        return;
                      handleCompleteAlert(dataAlert);
                    }}
                  />
                }
                label="Marcar como completada"
              />
            </p>
          </Row>
        </>
      )}
      <br />
      <Form onSubmit={handleSubmit(handleSaveAlert)}>
        <Row>
          <Col
            xs={4}
            style={{ backgroundColor: "#F6F8FC" }}
            className="blankspaces__container"
          >
            <Row
              style={{
                width: "100%",
                alignItems: "center",
                padding: "8px",
                rowGap: "1rem",
              }}
            >
              <Form.Group>
                <Form.Label className="form__label">
                  Nombre del hito:<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  {...register("name")}
                  placeholder={`Escribir aquí`}
                  bsPrefix={
                    errors?.name
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.name?.message}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">Hito:</Form.Label>
                <Form.Control
                  {...register("milestone")}
                  disabled={!documentHasMilestones}
                  placeholder={`Escribir aquí`}
                  bsPrefix={
                    errors?.milestone
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.milestone?.message}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">Cláusula:</Form.Label>
                <Row style={{ alignItems: "center" }}>
                  <Col>
                    <InputGroup
                      bsPrefix={
                        errors?.clause
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                      disabled={!documentHasMilestones}
                    >
                      <Form.Control
                        {...register("clause")}
                        bsPrefix="custom-input"
                        style={{ background: "inherit" }}
                        disabled
                      />
                      <InputGroup.Text bsPrefix="container-icon">
                        <IconButton
                          aria-label="view"
                          onClick={() =>
                            flashItemSelected(milestoneSelected?.id_text)
                          }
                        >
                          <VisibilityOutlinedIcon fontSize="large" />
                        </IconButton>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="caption custom-input__error">
                      {errors?.clause?.message}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">Descripción:</Form.Label>
                <Form.Control
                  {...register("description")}
                  as={"textarea"}
                  rows={3}
                  placeholder={`Escribir aquí`}
                  bsPrefix={"input-group-container__no-icon label"}
                />
                <div className="caption custom-input__error">
                  {errors?.description?.message}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">
                  Responsable:<span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Select
                  {...register("inCharge")}
                  defaultValue={"0"}
                  bsPrefix={
                    errors?.inCharge
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                >
                  <option value={"0"} disabled>
                    Selecciona
                  </option>
                  {users.length > 0 &&
                    users.map((user) => (
                      <option value={user._id}>{user?.name || ""}</option>
                    ))}
                </Form.Select>
                <div className="caption custom-input__error">
                  {errors?.inCharge?.message}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">Interesados:</Form.Label>
                {emailsFields.map((email, index) => (
                  <Row key={email.id} style={{ alignItems: "center" }}>
                    <Col>
                      <InputGroup
                        bsPrefix={
                          errors.emails?.[index]
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                        style={{ background: "#FFFFFF" }}
                      >
                        <InputGroup.Text bsPrefix="container-icon">
                          <AlternateEmailIcon fontSize="large" />
                        </InputGroup.Text>
                        <Form.Control
                          {...register(`emails.${index}`)}
                          placeholder={`Escribir aquí`}
                          bsPrefix="custom-input"
                        />
                      </InputGroup>
                      <div className="caption custom-input__error">
                        {errors?.emails?.[index]?.message}
                      </div>
                    </Col>
                    <Col xs={"auto"}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => emailsRemove(index)}
                      >
                        <DeleteOutlineIcon fontSize="large" />
                      </IconButton>
                    </Col>
                  </Row>
                ))}
                <br />
                {emailsFields.length < 5 && (
                  <Button
                    variant="contained"
                    className="custom-input__button__secondary-color"
                    startIcon={<PersonAddAlt1OutlinedIcon fontSize="large" />}
                    type="button"
                    onClick={() => emailsAppend("")}
                  >
                    Agregar interesado
                  </Button>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">
                  Fecha de activación de la alerta:
                  <span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Row style={{ rowGap: "1rem" }}>
                  <Col>
                    <Form.Control
                      type="date"
                      {...register("alertDate")}
                      placeholder="--/--/---"
                      bsPrefix={
                        errors.alertDate
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                      min={
                        alertId
                          ? undefined
                          : new Date().toISOString().split("T")[0]
                      }
                      style={{
                        textAlign: "justify",
                      }}
                    />
                  </Col>
                  <div className="caption custom-input__error">
                    {errors?.alertDate?.message}
                  </div>
                </Row>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">
                  Fecha de vencimiento de la alerta:
                  <span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Row style={{ rowGap: "1rem" }}>
                  <Col>
                    <Form.Control
                      type="date"
                      min={watch("alertDate")}
                      {...register("alertDateDeadline")}
                      placeholder="--/--/---"
                      bsPrefix={
                        errors.alertDateDeadline
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                  </Col>
                  <div className="caption custom-input__error">
                    {errors?.alertDateDeadline?.message}
                  </div>
                </Row>
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Checkbox
                      {...register("rememberAlert")}
                      onChange={(event) => {
                        setValue("rememberAlert", event.target.checked);
                        setDefaultValueCheckRemember(event.target.checked);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                        "&.MuiButtonBase-root": {
                          padding: "0px 5px 0px 0px !important",
                        },
                        color: "#C5C7D0",
                        "&.Mui-checked": {
                          color: "#00374f",
                        },
                      }}
                      checked={defaultValueCheckRemember}
                    />
                    <p>Crear recordatorio antes del vencimiento</p>
                  </Col>
                </Form.Label>
                {watch("rememberAlert") && (
                  <>
                    <Row>
                      <Col xs={3}>
                        <Form.Control
                          {...register("numberRemember")}
                          type="number"
                          defaultValue={0}
                          min={1}
                          max={30}
                          placeholder={`000`}
                          bsPrefix={
                            errors.numberRemember
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          style={{
                            height: "100%",
                          }}
                        />
                      </Col>
                      <Col xs={9}>
                        <Form.Select
                          {...register("typeRemember")}
                          placeholder={`Escribir aquí`}
                          bsPrefix={
                            errors.typeRemember
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          defaultValue={"0"}
                        >
                          <option value="0" disabled>
                            Selecciona
                          </option>
                          <option value={BandTypesNotification.DAYS}>
                            Días antes
                          </option>
                          <option value={BandTypesNotification.WEEKS}>
                            Semanas antes
                          </option>
                          <option value={BandTypesNotification.MONTHS}>
                            Meses antes
                          </option>
                        </Form.Select>
                      </Col>
                      <div className="caption custom-input__error">
                        {errors?.numberRemember?.message ||
                          errors?.typeRemember?.message}
                      </div>
                    </Row>
                    <Form.Label
                      className="caption__primary-color"
                      style={{ margin: "10px" }}
                    >
                      Te recordaremos la alerta antes del vencimiento, el día{" "}
                      {dateRemember}
                    </Form.Label>
                  </>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="form__label">
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Checkbox
                      {...register("repeatAlert")}
                      onChange={(event) => {
                        setValue("repeatAlert", event.target.checked);
                        setDefaultValueCheckRepeat(event.target.checked);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                        "&.MuiButtonBase-root": {
                          padding: "0px 5px 0px 0px !important",
                        },
                        color: "#C5C7D0",
                        "&.Mui-checked": {
                          color: "#00374f",
                        },
                      }}
                      checked={defaultValueCheckRepeat}
                    />
                    <p>Repetir alerta cada</p>
                  </Col>
                </Form.Label>
                {watch("repeatAlert") && (
                  <>
                    <Row>
                      <Col xs={3}>
                        <Form.Control
                          {...register("numberRepeat")}
                          type="number"
                          defaultValue={0}
                          min={1}
                          max={30}
                          placeholder={`000`}
                          bsPrefix={
                            errors.numberRepeat
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          style={{
                            height: "100%",
                          }}
                        />
                      </Col>
                      <Col xs={9}>
                        <Form.Select
                          {...register("typeRepeat")}
                          placeholder={`Escribir aquí`}
                          bsPrefix={
                            errors.typeRepeat
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          defaultValue={"0"}
                          onChange={(event) => {
                            setValue("typeRepeat", event.target.value);
                            setValue("typeDurationRepeat", event.target.value);
                          }}
                        >
                          <option value="0" disabled>
                            Selecciona
                          </option>
                          <option
                            value={BandTypesNotification.WEEKS}
                          >{`Semana(s)`}</option>
                          <option
                            value={BandTypesNotification.MONTHS}
                          >{`Mes(es)`}</option>
                          <option
                            value={BandTypesNotification.YEARS}
                          >{`Año(s)`}</option>
                        </Form.Select>
                      </Col>
                      <div className="caption custom-input__error">
                        {errors?.numberRepeat?.message ||
                          errors?.typeRepeat?.message}
                      </div>
                    </Row>
                    <Form.Label className="form__label" style={{ margin: 1 }}>
                      durante
                    </Form.Label>
                    <Row>
                      <Col xs={3}>
                        <Form.Control
                          {...register("durationRepeat")}
                          defaultValue={0}
                          type="number"
                          min={1}
                          max={30}
                          placeholder={`000`}
                          bsPrefix={
                            errors.durationRepeat
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          style={{
                            height: "100%",
                          }}
                        />
                      </Col>
                      <Col xs={9}>
                        <Form.Select
                          {...register("typeDurationRepeat")}
                          placeholder={`Escribir aquí`}
                          bsPrefix={
                            errors.typeDurationRepeat
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          defaultValue={"0"}
                          disabled
                        >
                          <option value="0" disabled>
                            Selecciona
                          </option>
                          <option
                            value={BandTypesNotification.WEEKS}
                          >{`Semana(s)`}</option>
                          <option
                            value={BandTypesNotification.MONTHS}
                          >{`Mes(es)`}</option>
                          <option
                            value={BandTypesNotification.YEARS}
                          >{`Año(s)`}</option>
                        </Form.Select>
                      </Col>
                      <div className="caption custom-input__error">
                        {errors?.durationRepeat?.message ||
                          errors?.typeDurationRepeat?.message}
                      </div>
                    </Row>
                    <Form.Label
                      className="caption__primary-color"
                      style={{ margin: "10px" }}
                    >
                      <p>
                        La alerta se repetirá por primera vez el día{" "}
                        {dateRepeat || "dd/mm/aaaa"}
                      </p>
                      <br />
                      <p>
                        La próxima repetición de esta alerta será:{" "}
                        {!watch("alertDate") &&
                          !watch("alertDateDeadline") &&
                          "dd/mm/aaaa"}
                      </p>
                      <ul>
                        {watch("alertDate") && (
                          <li>
                            <p>Activación: {datesNext?.active}</p>
                          </li>
                        )}
                        {watch("alertDateDeadline") && (
                          <li>
                            <p>Vencimiento: {datesNext?.deadline}</p>
                          </li>
                        )}
                        {watch("rememberAlert") &&
                          watch("alertDateDeadline") && (
                            <li>
                              <p>Recordatorio: {datesNext?.remember}</p>
                            </li>
                          )}
                      </ul>
                    </Form.Label>
                  </>
                )}
              </Form.Group>
            </Row>
          </Col>
          <Col
            id="content-document"
            style={{ alignItems: "center" }}
            className="blankspaces__container"
          >
            {id !== "without-document" && (
              <ViewDocumentLegal currentDocument={documentLegal} />
            )}
            {id === "without-document" && (
              <ViewDocumentNotFound
                handleSelectFromFolder={() => {
                  const values = Object.entries(getValues());
                  const isAddValue = values.some((data) => {
                    const [key, value] = data;
                    if (key === "inCharge") return value !== "0";
                    return value && value?.length;
                  });

                  if (isAddValue) {
                    setOpenModalDecisionSave({
                      open: true,
                      action: () => setOpenModalVersion(true),
                    });
                  } else {
                    setOpenModalVersion(true);
                  }
                }}
                handleUploadDocument={() => {
                  const values = Object.entries(getValues());
                  const isAddValue = values.some((data) => {
                    const [key, value] = data;
                    if (key === "inCharge") return value !== "0";
                    return value && value?.length;
                  });

                  if (isAddValue) {
                    setOpenModalDecisionSave({
                      open: true,
                      action: () => setOpenModalUploadDocs(true),
                    });
                  } else {
                    setOpenModalUploadDocs(true);
                  }
                }}
              />
            )}
          </Col>
        </Row>
        <br />
        <Row style={{ justifyContent: "end" }}>
          <Col xs={"auto"}>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => setOpenModalDecisionCancel(true)}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Aceptar
            </Button>
          </Col>
        </Row>
      </Form>

      <ModalUploadDocs
        open={openModalUploadDocs}
        title={"Selecciona el documento para crear la alerta"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o word y pesar máximo 75mb"
        }
        setDocumentData={(file) => {
          if (!file?.name && !file?.filename) return;
          setDocumentData(file);
          setFolderSelectedModal(true);
        }}
        onClose={() => {
          setOpenModalUploadDocs(false);
        }}
        typeDocument={"Contrato"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf o Word"
        maxFileSize={78643200} //75MB
        additionalFields={
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Select
                  className="label"
                  defaultValue={""}
                  onChange={(event) => {
                    const { value } = event.target;
                    setDocumentType(value);
                  }}
                >
                  <option value="" disabled defaultValue className="label">
                    Selecciona el tipo de documento
                  </option>
                  {codesDocumentTypeForAlert.map((type) => {
                    return (
                      <option value={type._id} className="label">
                        {type.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        }
      />
      <ModalIcon
        open={openModalIconLoading}
        onClose={() => setOpenModalIconLoading(false)}
        icon={
          <FindInPageOutlinedIcon
            sx={{ fontSize: 140 }}
            className="iconUpload"
          />
        }
        text={"Verificando si existen hitos para crear alertas."}
      />
      <ModalViewMilestones
        open={openModalMilestones}
        close={() => setOpenModalMilestones(false)}
      />

      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalVersion(false);
          fetchData(file?._id);
          navigate(`../alerts/create/${file?._id}`, {
            replace: true,
          });
        }}
        onClose={() => {
          setOpenModalVersion(false);
        }}
        open={openModalVersion}
        folders={folders}
        title="Asociar documento"
      />
      <ModalInfo
        title={
          responseData?.status === CODES.COD_RESPONSE_HTTP_CREATED
            ? "Creación exitosa"
            : "Ha ocurrido algo creando la alerta"
        }
        onClose={() => {
          setOpenModalCreateAlert(false);
          if (responseData?.status === CODES.COD_RESPONSE_HTTP_CREATED) {
            navigate("/service/alerts");
          }
        }}
        open={openModalCreateAlert}
        responseData={responseData}
        confirmationText="Aceptar"
      />
      <ModalDecision
        open={openModalDecisionCancel}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => navigate("/service/alerts")}
        handleDisagree={() => setOpenModalDecisionCancel(false)}
        message={
          "¿Estás seguro de que quieres cancelar la creación de la alerta?"
        }
        onClose={() => setOpenModalDecisionCancel(false)}
        title={"Cancelar"}
        type={"warning"}
      />
      <ModalDecision
        open={openModalDecisionSave.open}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={openModalDecisionSave.action}
        handleDisagree={() =>
          setOpenModalDecisionSave({ open: false, action: null })
        }
        message={
          "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
        }
        onClose={() => setOpenModalDecisionSave({ open: false, action: null })}
        title={"Atención"}
        type={"warning"}
      />
      <ModalInfo
        title={"Atención"}
        onClose={() => setOpenModalNotFoundMilestones(false)}
        open={openModalNotFoundMilestones}
        responseData={{
          status: 401,
          data: {
            responseMessage: "No se encontraron hitos para crear la alerta",
          },
        }}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title={"Atención"}
        onClose={() => setIsOpenModalResponseCreateVersion(false)}
        open={isOpenModalResponseCreateVersion}
        responseData={{
          status: 401,
          data: {
            responseMessage: "No se ha podido revisar el documento",
          },
        }}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title={
          responseData?.status === CODES.COD_RESPONSE_HTTP_OK
            ? "Edición exitosa"
            : "Ha ocurrido algo creando la alerta"
        }
        onClose={() => {
          setOpenModalEditAlert(false);
          if (responseData?.status === CODES.COD_RESPONSE_HTTP_OK) {
            navigate("/service/alerts");
          }
        }}
        open={openModalEditAlert}
        responseData={responseData}
        confirmationText="Aceptar"
      />

      <ModalInfo
        title={"Ha ocurrido un error"}
        onClose={() => {
          setResponseDataError(false);
        }}
        open={isOpenModalInfoError}
        responseData={responseDataError}
        confirmationText="Aceptar"
      />

      <ModalDecision
        title={"Guardar documento"}
        message={"Almacena este documento en una de tus carpetas"}
        open={folderSelectedModal}
        onClose={setFolderSelectedModal}
        agreeText="Seleccionar carpeta"
        handleAgree={() => {
          dispatch(setName({ name: documentData.name }));
          navigate(`/service/alerts/alert/folder/select-folder`, {
            replace: true,
            state: {
              documentData,
              documentType,
            },
          });
        }}
      />
    </Container>
  );
};

const ViewDocumentNotFound = ({
  handleUploadDocument,
  handleSelectFromFolder,
}) => {
  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Col xs={"auto"}>
        <img
          src={require("../../../../../assets/svg/juezInfo.svg").default}
          alt="logo-juez"
        />
      </Col>
      <Col xs={11} lg={4}>
        <Row>
          <h1
            className="heading__primary-color"
            style={{ textAlign: "center" }}
          >
            ¿Deseas asignar la alerta que estás creando a un documento?
          </h1>
        </Row>
        <Row>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={handleSelectFromFolder}
            startIcon={<FolderOpenOutlinedIcon fontSize="large" />}
          >
            Asignar desde Legal AI
          </Button>
        </Row>
        <br />
        <Row>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={handleUploadDocument}
            startIcon={<CloudUploadOutlinedIcon fontSize="large" />}
          >
            Subir documento y asignar
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default CreateAlertNewModule;
