export const getFillBlankSpaces = ({ clause }) => {
  let count = 0;
  count =
    count +
    (clause.blankspaces.length > 0
      ? clause.blankspaces.filter((blank) => blank.value).length
      : 0);

  clause?.subclauses?.map((sub) => {
    count =
      count +
      (sub.subclause?.blankspaces
        ? sub.subclause?.blankspaces.filter((blank) => blank.value).length
        : 0);

    sub?.subclause?.childs?.map((child) => {
      count =
        count +
        (child?.blankspaces
          ? child?.blankspaces.filter((blank) => blank.value).length
          : 0);
    });
  });

  clause?.paragraphs?.map((para) => {
    count =
      count +
      (para.paragraph.blankspaces
        ? para.paragraph.blankspaces.filter((blank) => blank.value).length
        : 0);
  });
  return count;
};
export const getAllBlankSpaces = ({ clause }) => {
  let count = 0;
  count = count + (clause.blankspaces ? clause.blankspaces.length : 0);

  clause?.subclauses?.map((sub) => {
    count =
      count +
      (sub.subclause?.blankspaces ? sub.subclause?.blankspaces.length : 0);

    sub?.subclause?.childs?.map((child) => {
      count = count + (child?.blankspaces ? child?.blankspaces.length : 0);
    });
  });

  clause?.paragraphs?.map((para) => {
    count =
      count +
      (para.paragraph.blankspaces ? para.paragraph.blankspaces.length : 0);
  });
  return count;
};

export const setBlankSpacesIndex = (currentClauses) => {
  let indexCounter = 0;

  let newClauses = currentClauses.map((clause) => {
    const { blankspaces } = clause.clause;
    const updatedBlankSpaces = blankspaces.map((blankSpace) => {
      indexCounter++;
      const updatedBlankSpace = {
        ...blankSpace,
        counter: indexCounter,
      };

      return updatedBlankSpace;
    });

    const updatedSubClauses = clause.clause.subclauses.map((subclause) => {
      const updatedBlankSpaces = subclause.subclause.blankspaces.map(
        (blankSpace) => {
          indexCounter++;
          const updatedBlankSpace = {
            ...blankSpace,
            counter: indexCounter,
          };

          return updatedBlankSpace;
        }
      );
      const updatedChilds = subclause.subclause.childs.map((child) => {
        const updatedBlankSpaces = child.blankspaces.map((blankSpace) => {
          indexCounter++;
          const updatedBlankSpace = {
            ...blankSpace,
            counter: indexCounter,
          };

          return updatedBlankSpace;
        });
        return {
          ...child,
          blankspaces: updatedBlankSpaces,
        };
      });
      return {
        subclause: {
          ...subclause.subclause,
          childs: updatedChilds,
          blankspaces: updatedBlankSpaces,
        },
      };
    });

    const updatedParagraph = clause.clause.paragraphs.map((paragraph) => {
      const updatedBlankSpaces = paragraph.paragraph.blankspaces.map(
        (blankSpace) => {
          indexCounter++;
          const updatedBlankSpace = {
            ...blankSpace,
            counter: indexCounter,
          };

          return updatedBlankSpace;
        }
      );
      return {
        paragraph: {
          ...paragraph.paragraph,
          blankspaces: updatedBlankSpaces,
        },
      };
    });

    return {
      clause: {
        ...clause.clause,
        blankspaces: updatedBlankSpaces,
        subclauses: updatedSubClauses,
        paragraphs: updatedParagraph,
      },
    };
  });

  return newClauses;
};

export const getFillBlankSpacesOtherDocs = (currentDocInfo) => {
  let count = 0;
  count =
    count +
    (currentDocInfo?.blankSpaces?.length > 0
      ? currentDocInfo?.blankSpaces?.filter((blank) => blank.value).length
      : 0);

  return count;
};
