import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CODES } from "../../../../../utils/codes";
import {
  getAlerts,
  alertCreate,
  updateAlert,
} from "../../../../../services/alerts/alertsServices";

export const alertSlice = createSlice({
  name: "alerts",
  initialState: {
    alertsList: [],
    status: "fetch",
    createAlertStatus: "fetch",
    updateAlertStatus: "fetch",
  },
  reducers: {
    setAlertList(state, action) {
      state.status = "fetch";
      state.alertsList = action.payload.alerts;
    },
    setAlertStatus(state, action) {
      state.status = action.payload.status;
    },
    cleanStoreAlert(state, action) {
      state.status = "fetch";
      state.alertsList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlerts.pending, (state, _) => {
        state.status = "loading";
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alertsList = action.payload?.data?.responseMessage?.data || [];
      })
      .addCase(fetchAlerts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createAlert.pending, (state, _) => {
        state.createAlertStatus = "loading";
      })
      .addCase(createAlert.fulfilled, (state, action) => {
        state.createAlertStatus = "succeeded";
        state.status = "fetch";
      })
      .addCase(createAlert.rejected, (state, action) => {
        state.createAlertStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(modifyAlert.pending, (state, _) => {
        state.updateAlertStatus = "loading";
      })
      .addCase(modifyAlert.fulfilled, (state, action) => {
        state.updateAlertStatus = "succeeded";
        state.status = "fetch";
      })
      .addCase(modifyAlert.rejected, (state, action) => {
        state.updateAlertStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setAlertList } = alertSlice.actions;

export const { setAlertStatus } = alertSlice.actions;

export const { cleanStoreAlert } = alertSlice.actions;

export const getAlertsList = (state) => state.alerts.alertsList;

export const getAlertsStatus = (state) => state.alerts.status;

export const fetchAlerts = createAsyncThunk(
  "alerts/fetchAlerts",
  async (data) => {
    try {
      const response = await getAlerts(data);
      //Obj is not sending companyId, so I'm thinking of adding it to the request
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const createAlert = createAsyncThunk(
  "alert/createAlert",
  async (request, { rejectWithValue }) => {
    try {
      const response = await alertCreate(request);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
          alertsList: response.data.responseMessage.data,
        };
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const modifyAlert = createAsyncThunk(
  "alert/modifyAlert",
  async (request, { rejectWithValue }) => {
    try {
      const timeZone = new Date().getTimezoneOffset() / -60;
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const response = await updateAlert({
        ...request,
        timeZone,
        company,
        corporateUnit,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
          alertsList: response.data.responseMessage.data,
        };
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export default alertSlice.reducer;
