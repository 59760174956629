import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export const createBusinessFolder = ({
  name,
  company,
  description,
  labels,
  corporateUnit,
  businessFolderFatherId,
  timeZone
}) => {
  return axios({
    method: "POST",
    url: `businessFolder/create`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      name,
      company,
      description: description === "" ? null : description,
      labels,
      corporateUnit,
      businessFolderFatherId,
      timeZone
    },
  });
};

export const updateBusinessFolder = ({
  name,
  company,
  description,
  labels,
  id,
  corporateUnit,
  timeZone
}) => {
  return axios({
    method: "PUT",
    url: `businessFolder/update/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      name,
      company,
      description: description === "" ? null : description,
      labels,
      corporateUnit,
      timeZone
    },
  });
};

export const moveDocumentBusinessFolder = (
  sourceFolderId,
  documentId,
  destinaFolderId
) => {
  return axios({
    method: "PUT",
    url: `businessFolder/moveDocumentToBF`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: {
      sourceFolderId,
      documentId,
      destinaFolderId,
    },
  });
};

export const updateBusinessFolderlastAccess = (data) => {
  return axios({
    method: "PUT",
    url: `businessFolder/updateLastOpen/${data.id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      corporateUnit: data.corporateUnit,
      company: data.company,
      lastAccessAt: data.lastAccessAt,
      timeZone: data.timeZone
    },
  });
};

export const moveBusinessFolder = ({
  sourceFolderId,
  currentFolderId,
  destinaFolderId,
}) => {
  return axios({
    method: "PUT",
    url: `businessFolder/moveFolderToBF`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: {
      sourceFolderId,
      currentFolderId,
      destinaFolderId,
    },
  });
};

export function getFolderById({ companyId, id, corporateUnit}) {
  return axios({
      method: "GET",
      url: `businessFolder/find/one`,
      baseURL: env.REACT_APP_API_URL,
      validateStatus: null,
      params: {
          companyId,
          folderId: id,
          corporateUnitId: corporateUnit
      },
      headers: {
          Authorization: GetToken()
      }
  })
}

export function getFoldersByCompany(data) {
  return axios({
      method: "GET",
      url: `businessFolder/find/all`,
      params: {
          companyId: data.companyId,
          corporateUnitId: data.corporateUnit
      },
      baseURL: env.REACT_APP_API_URL,
      validateStatus: null,
      headers: {
          Authorization: GetToken()
      }
  })
}

export function updateDeletedFolder({id,newStatus}) {
  return axios({
      method: "PUT",
      url: `businessFolder/updateDeletedFolder/${id}`,
      data: {
          state: newStatus,
      },
      baseURL: env.REACT_APP_API_URL,
      validateStatus: null,
      headers: {
          Authorization: GetToken()
      }
  })
}

export function deleteFolder({id}) {
  return axios({
      method: "DELETE",
      url: `businessFolder/deleteFolder`,
      data: {
          id,
      },
      baseURL: env.REACT_APP_API_URL,
      validateStatus: null,
      headers: {
          Authorization: GetToken()
      }
  })
}

export function findDeletedFolder({corporateUnit,companyId}) {
  return axios({
      method: "GET",
      url: `businessFolder/find/deleted`,
      params: {
        corporateUnit,
        company: companyId
      },
      baseURL: env.REACT_APP_API_URL,
      validateStatus: null,
      headers: {
          Authorization: GetToken()
      }
  })
}
