import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export const getRefNormativeDocument = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `documents/getReferenceNormative`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      clauses,
    },
  });
};

export const segmentDocument = ({ file, company }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("company", company);

  return axios({
    method: "POST",
    url: `documents/segmentDocx`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
      "content-type": "application/x-www-form-urlencoded",
    },
    data: formData,
  });
};

export const segmentDocumentClassifyClauses = ({
  parts,
  files,
  company,
  onUploadProgress = null,
}) => {
  const formData = new FormData();
  formData.append("parts", JSON.stringify(parts));
  formData.append("file", files);
  formData.append("company", company);

  return axios({
    method: "POST",
    url: `documents/segmentAndClassifyClauses`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
      "content-type": "application/x-www-form-urlencoded",
    },
    data: formData,
    onUploadProgress,
    timeout: env.REACT_APP_TIMEOUT_IA,
  });
};

export const createDraft = ({
  name,
  documentContent,
  folderAssociated,
  versionAssociated,
  versionFrom,
  contractFrom,
  corporateUnit = "",
  modifiedBy,
  letterheadAssociated,
}) => {
  return axios({
    method: "POST",
    url: `versions/saveAsDraft`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      name,
      documentContent,
      folderAssociated,
      versionAssociated,
      versionFrom,
      contractFrom,
      corporateUnit,
      modifiedBy,
      letterheadAssociated,
    },
  });
};

export const autoSaveDraft = ({
  documentContent,
  draftId,
  versionFrom,
  contractFrom,
  fileType,
  letterheadAssociated,
  name,
}) => {
  return axios({
    method: "PUT",
    url: `versions/updateDraft`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      documentContent,
      draftId,
      versionFrom,
      contractFrom,
      fileType,
      letterheadAssociated,
      name,
    },
  });
};

export const getDocById = (id) => {
  return axios({
    method: "GET",
    url: `versions/getbyid`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { id },
  });
};

export const getFileDocument = (filenameInBucket, bucketName, isWord) => {
  return axios({
    method: "GET",
    url: `versions/getfile`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { filenameInBucket, bucketName, isWord },
  });
};

export const deleteVersion = (id, fileType, versionFrom) => {
  return axios({
    method: "DELETE",
    url: `versions/deleteVersions`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { id, fileType, versionFrom },
  });
};

export const compareVersions = ({
  versionToCompareId,
  versionToBeComparedId,
}) => {
  return axios({
    method: "PUT",
    url: `versions/compareVersions`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { versionToCompareId, versionToBeComparedId },
  });
};

export const getAdvices = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `documents/getAdvices`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      clauses,
    },
  });
};

export const segmentDocumentWithFunctIA = ({
  file = null,
  company,
  bucketName = "",
  filenameInBucket = "",
  from,
  document = null,
  typeReview = "",
}) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("company", company);
  formData.append("bucketName", bucketName);
  formData.append("filenameInBucket", filenameInBucket);
  formData.append("from", from);
  formData.append("document", document);
  formData.append("reviewType", typeReview);
  return axios({
    method: "POST",
    url: `documents/segmentDocxAndFunctionalitiesAI`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: { reviewType: typeReview },
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
    timeout: env.REACT_APP_TIMEOUT_IA,
  });
};

export const getSignedURLFronEmail = (filenameInBucket, bucketName) => {
  return axios({
    method: "GET",
    url: `utils/signedURLFronEmail`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { filenameInBucket, bucketName },
  });
};

export const getDocumentForExternalUser = (filenameInBucket, bucketName) => {
  return axios({
    method: "GET",
    url: `versions/getfileToExternalUser`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { filenameInBucket, bucketName },
  });
};
