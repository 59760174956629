import { Button, ListItemText, Menu, MenuItem, Paper } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
} from "chart.js";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MetricBarPie = ({
  title,
  icon,
  generalCount,
  description,
  optionsChart,
  dataChart1,
  dataChart2,
  filter,
}) => {
  //state
  const [actualDataChart, setActualDataChart] = useState(dataChart1);
  const [anchorMenuFilter, setAnchorMenuFilter] = useState(null);
  const [actualFilter, setActualFilter] = useState(
    Array.isArray(filter) ? filter[0]?.labelFilter : ""
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenuFilter = Boolean(anchorMenuFilter);
  const optionsBarChart = {
    indexAxis: "y",
    scales:{
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    responsive: true
  };

  const optionsPieChart = {
    radius: "90%",
    responsive: true
  };

  const getDataByStatus = (options) => {
    let filter = options.find((filter) => filter.name === actualFilter);
    let total = 0;
    filter?.byStatus?.forEach((element) => {
      total += element.value;
    });

    return filter?.byStatus?.map(
      (item) => `${Math.round((item.value / total) * 100)}`
    );
  };

  const getDataBy = (options) => {
    let total = 0;
    options?.forEach((element) => {
      total += element.value;
    });
    return options.map((item) => `${Math.round((item.value / total) * 100)}`);
  };

  const dataBarChart = filter
    ? {
        labels: actualDataChart
          ?.find((data) => data.name === actualFilter)
          ?.byStatus.map((data) => data.name),
        datasets: [
          {
            label: actualFilter,
            data: actualDataChart
              ?.find((data) => data.name === actualFilter)
              ?.byStatus.map((data) => data.value),
            backgroundColor: [
              "#00CA72",
              "#16CAD3",
              "#F9600B",
              "#753ABC",
              "#E44258",
              "#676879",
            ],
            borderRadius: 8,
            barThickness: 10,
          },
        ],
      }
    : {
        labels: actualDataChart.map((data) => data.name),
        datasets: [
          {
            label: title,
            data: actualDataChart.map((data) => data.value),
            backgroundColor: [
              "#00CA72",
              "#16CAD3",
              "#F9600B",
              "#753ABC",
              "#E44258",
              "#676879",
            ],
            borderRadius: 8,
            barThickness: 10,
          },
        ],
      };

  const dataPieChart = filter
    ? {
        datasets: [
          {
            data: getDataByStatus(actualDataChart),
            backgroundColor: [
              "#00CA72",
              "#16CAD3",
              "#F9600B",
              "#753ABC",
              "#E44258",
              "#676879",
            ],
            tooltip: {
              callbacks: {
                label: function (context) {
                  let value = context.formattedValue;
                  let percentage = value + "%";
                  return percentage;
                },
              },
            },
          },
        ],
      }
    : {
        datasets: [
          {
            data: getDataBy(actualDataChart),
            backgroundColor: [
              "#00CA72",
              "#16CAD3",
              "#F9600B",
              "#753ABC",
              "#E44258",
              "#676879",
            ],
            tooltip: {
              callbacks: {
                label: function (context) {
                  let value = context.formattedValue;
                  let percentage = value + "%";
                  return percentage;
                },
              },
            },
          },
        ],
      };

  //Handlers
  const handleCloseAnchorMenuOptions = () => {
    setAnchorEl(null);
  };
  const handleClickAnchorMenuOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchorMenuFilter = () => {
    setAnchorMenuFilter(null);
  };
  const handleClickAnchorMenuFilter = (event) => {
    setAnchorMenuFilter(event.currentTarget);
  };

  const handleChangeData = (option) => {
    if (option === "Procesos") {
      setActualDataChart(dataChart1);
    } else if (option === "Usuarios") {
      setActualDataChart(dataChart2);
    }
    setAnchorEl(null);
  };

  const handleFilterButtons = (optionsChart, filter) => {
    if (optionsChart && filter) {
      return (
        <Row style={{ margin: "4% 0" }}>
          <Col xs={4}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              className="custom-input__button__secondary-color"
              size="medium"
              onClick={handleClickAnchorMenuOptions}
            >
              Ver por:
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseAnchorMenuOptions}
              anchorOrigin={{
                vertical: "bottom",

                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {optionsChart?.map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => {
                    handleChangeData(option);
                    //handleCloseAnchorMenuFilter();
                  }}
                >
                  <ListItemText
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "1.4rem",
                      },
                    }}
                  >
                    {option}
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </Col>
          <Col xs={4}>
            <Button
              fullWidth
              className="custom-input__button__secondary-color"
              startIcon={<FilterAltIcon fontSize="large" />}
              endIcon={<KeyboardArrowDownIcon fontSize="large" />}
              variant="contained"
              onClick={(e) => {
                handleClickAnchorMenuFilter(e);
              }}
            >
              Filtrar
            </Button>
            <Menu
              anchorEl={anchorMenuFilter}
              open={openMenuFilter}
              onClose={handleCloseAnchorMenuFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {filter?.map((option) => (
                <MenuItem
                  key={option.name}
                  onClick={() => {
                    setActualFilter(option.labelFilter);
                    handleCloseAnchorMenuFilter();
                  }}
                >
                  <span className="body-content">{option.labelFilter}</span>
                </MenuItem>
              ))}
            </Menu>
          </Col>
        </Row>
      );
    } else if (!optionsChart && filter) {
      return (
        <Row style={{ margin: "4% 0", justifyContent: "flex-start" }}>
          <Col xs={4}>
            <Button
              fullWidth
              className="custom-input__button__secondary-color"
              startIcon={<FilterAltIcon fontSize="large" />}
              endIcon={<KeyboardArrowDownIcon fontSize="large" />}
              variant="contained"
              onClick={(e) => {
                handleClickAnchorMenuFilter(e);
              }}
            >
              Filtrar
            </Button>
            <Menu
              anchorEl={anchorMenuFilter}
              open={openMenuFilter}
              onClose={handleCloseAnchorMenuFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {filter?.map((option) => (
                <MenuItem
                  key={option.name}
                  onClick={() => {
                    setActualFilter(option.labelFilter);
                    handleCloseAnchorMenuFilter();
                  }}
                >
                  <span className="body-content">{option.labelFilter}</span>
                </MenuItem>
              ))}
            </Menu>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row style={{ margin: "4% 0" }}>
          <Col className="mt-2 mb-2">
            <br />
          </Col>
        </Row>
      );
    }
  };

  return (
    <Paper elevation={1} style={{ padding: "2% 0", height: "100%" }}>
      <Container fluid>
        <Row style={{ height: "100%" }}>
          <Col>
            <Container fluid>
              <Row className="heading" style={{ margin: "2% 0" }}>
                <Col xs={4}>{title}</Col>
              </Row>
              {description && typeof generalCount === "string" ? (
                <Row className="ml-2" style={{ margin: "4% 0" }}>
                  {/* Icono con contador y descripcion*/}
                  <Col
                    xs={1}
                    className="custom-input__button__secondary-color__icon"
                  >
                    {icon}
                  </Col>
                  <Col xs={7}>
                    <Container fluid>
                      <Row className="display-large">{generalCount}</Row>
                      <Row className="caption">{description}</Row>
                    </Container>
                  </Col>
                </Row>
              ) : (
                <Row className="ml-2" style={{ margin: "4% 0" }}>
                  {/* Iconos con contador y descripcion*/}
                  {generalCount.map((count) => (
                    <Col key={count.count}>
                      <Row>
                        <Col
                          xs={2}
                          className="custom-input__button__secondary-color__icon"
                        >
                          {count.icon}
                        </Col>
                        <Col xs={6}>
                          <Container fluid>
                            <Row className="display-large">{count.count}</Row>
                            <Row className="caption">{count.description}</Row>
                          </Container>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              )}

              {/* Boton Seleccionar Data */}
              {handleFilterButtons(optionsChart, filter)}

              <Row style={{  }}>
                {/* Seccion Graficos */}
                <Col xs={8}>
                  {/* Grafico Barras */}
                  <Bar data={dataBarChart} options={optionsBarChart} />;
                </Col>
                <Col xs={4}>
                  {/* Grafico Torta */}
                  <Pie data={dataPieChart}  options={optionsPieChart}/>
                </Col>
                <Col xs={2}></Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Paper>
  );
};

export default MetricBarPie;
