import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ReactHtmlParser from "react-html-parser";
import { Col, Container, Row } from "react-bootstrap";
import { NUMBERINGWORDSO } from "../../utils/numberings";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalPreviewClause = ({ clauseInfo, hookFormInfo }) => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  const handleClickOpen = () => {
    setInfo(hookFormInfo());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<VisibilityOutlinedIcon fontSize="large" />}
        className="custom-input__button__secondary-color"
        scroll={"paper"}
        onClick={handleClickOpen}
      >
        Vista previa
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">Vista previa</label>
        </DialogTitleContainer>
        <DialogContent dividers className="body-content">
          <Container>
            <Row className="mb-4">
              <Col xs={"auto"}>
                <b>{`1. ${info.title || ""}`}</b>
              </Col>
              <Col xs={"auto"} className="justify-text">{ReactHtmlParser(info.clauseText)}</Col>
            </Row>
            {info?.subClauses?.length > 0 &&
              info.subClauses.map((subClause, subClauseIdx) => {
                return (
                  <div key={subClause?._id}>
                    <Row className="mb-3" style={{ paddingLeft: "3%" }}>
                      <Col xs={"auto"}>
                        <b>{`1.${subClauseIdx + 1} ${subClause?.title || ""}`}</b>
                      </Col>
                      <Col xs={"auto"} className="justify-text">{ReactHtmlParser(subClause.text)}</Col>
                    </Row>
                    {subClause.numerals.map((numeral, numeralIdx) => {
                      return (
                        <Row
                          key={numeral?._id}
                          className="mb-3"
                          style={{ paddingLeft: "6%" }}
                        >
                          <Col xs={"auto"}>
                            <b>{`1.${subClauseIdx + 1}.${numeralIdx + 1} ${
                              numeral?.title || ""
                            }`}</b>
                          </Col>
                          <Col xs={"auto"} className="justify-text">{ReactHtmlParser(numeral.text)}</Col>
                        </Row>
                      );
                    })}
                  </div>
                );
              })}
            {info?.paragraphs?.length > 0 &&
              info.paragraphs.map((paragraph, paragraphIdx) => {
                return (
                  <Row
                    key={paragraph?._id}
                    className="mb-3"
                    style={{ paddingLeft: "3%" }}
                  >
                    <Col xs={"auto"}>
                      <b>{`Parágrafo ${NUMBERINGWORDSO[paragraphIdx + 1]}`}</b>
                    </Col>
                    <Col xs={"auto"} className="justify-text">{ReactHtmlParser(paragraph.text)}</Col>
                  </Row>
                );
              })}
          </Container>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleClose}
            >
              Aceptar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalPreviewClause;
