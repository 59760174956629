import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Joyride, { STATUS } from "react-joyride";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../../../../../utils/regexp";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import LoadingContent from "../../../../../../components/loadingContent";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Divider from "@mui/material/Divider";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import { CLAUSES_INFO } from "../../../../../../utils/popoverInfo";
import RichEditorText from "../../../../../../parts/RichEditorText";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ClearIcon from "@mui/icons-material/Clear";
import ModalAssociateCategories from "../../../../../../components/Modals/ModalAssociateCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../parts/categories/clauseCategorySlice";
import { steps } from "./steps";
import ModalPreviewClause from "../../../../../../components/Modals/ModalPreviewClause";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import {
  fetchTags,
  getTagList,
  getStatus,
} from "../../../../../../parts/manageTags/tagSlice";
import { addNewClause } from "./clauseSlice";
import {
  getCurrentDocument,
  setNewClause,
} from "../../../../../../parts/document/currentDocumentSlice";
import AssociateContractTypes from "../../../../../../parts/associations/AssociateContractTypes";
import { setStatusFetchContractTypes } from "../contractTypes/contractTypesSlice";
import { CODES } from "../../../../../../utils/codes";
import BlockerPage from "../../../../../../components/blocker/blocker";
import {
  checkText,
  countPartErrors,
} from "../../../../../../utils/partsCorrection";
import { changeNameParts } from "./utils";
import { getBlankSpaceIndex } from "../../../../../../utils/blankspaces";
import NumeralsModule from "./clausesComponents/numeralsModule";
import ParagraphModule from "./clausesComponents/paragraphsModule";

const CreateClause = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { parts: currentPartsDocument } = useSelector(getCurrentDocument);
  const [clause, setClause] = useState({
    title: "",
    parts: [],
    blankSpaces: [],
    sensitiveText: "",
    subClauses: [],
    paragraphs: [],
    text: "",
    inFavor: "",
    tags: [],
    categories: [],
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalDecisionDelPart, setIsOpenModalDecisionDelPart] =
    useState(false);
  const [isOpenTour, setIsOpenTour] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [isSensitiveClause, setIsSensitiveClause] = useState(false);
  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);

  const [isOpenModalCategoriesEmpty, setIsOpenModalCategoriesEmpty] =
    useState(false);

  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [partDeleted, setPartDeleted] = useState(null);
  const [contractTypesSelected, setContractTypesSelected] = useState([]);
  const [currentData, setCurrentData] = useState({});
  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [blockNavigate, setBlockNavigate] = useState(true);
  const [partsErrorsModal, setPartsErrorsModal] = useState(false);
  const [saveType, setSaveType] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const parts = {
    0: "Parte A",
    1: "Parte B",
    2: "Parte C",
    3: "Parte D",
  };

  const schema = yup.object().shape({
    title: yup.string().required("*Este campo es obligatorio"),
    parts: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
      })
    ),
    clauseText: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.RICH_EDITOR_CONTENT, {
        message: "*Este campo no puede estar vacío",
      }),
    subClauses: yup.array().of(
      yup.object().shape({
        title: yup.string().notRequired(),
        text: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.RICH_EDITOR_CONTENT, {
            message: "*Este campo no puede estar vacío",
          }),
        numerals: yup.array().of(
          yup.object().shape({
            title: yup.string().notRequired(),
            text: yup
              .string()
              .required("*Este campo es obligatorio")
              .matches(REGEXP.RICH_EDITOR_CONTENT, {
                message: "*Este campo no puede estar vacío",
              }),
          })
        ),
      })
    ),
    paragraphs: yup.array().of(
      yup.object().shape({
        text: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.RICH_EDITOR_CONTENT, {
            message: "*Este campo no puede estar vacío",
          }),
      })
    ),
    sensitiveClauseText: !isSensitiveClause
      ? yup.string().nullable().default("")
      : yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.LETTERS_COMMAS, {
            message: "*Solo debe incluir letras",
          }),

    inFavor:
      state?.from === "create"
        ? null
        : yup
            .string("*Este campo debe ser un numero")
            .required("*Este campo es obligatorio"),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parts: [{ title: "" }, { title: "" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: partsFields,
    append: partsAppend,
    remove: partsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parts", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const clauseRichEditorTextRef = useRef();
  const subClauseRichEditorTextRef = useRef([]);
  const paragraphRichEditorTextRef = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("CLAUSES"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  const handleClose = () => {
    setIsOpenModal(false);
    setBlockNavigate(false);
    navigate(-1);
  };

  const handleCloseModalCategoriesEmpty = () => {
    setIsOpenModalCategoriesEmpty(false);
  };

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleBlankSpaces = (blankSpace) => {
    let blankSpaceId = getBlankSpaceIndex(clause.blankSpaces);
    let item = {
      id: blankSpaceId,
      name: `blankSpace-${blankSpaceId}`,
    };
    setClause((clause) => ({
      ...clause,
      blankSpaces: [...clause.blankSpaces, item],
    }));
    clauseRichEditorTextRef.current.addBlankSpace(clause.blankSpaces);
  };

  const removeBlankSpace = (index) => {
    setClause((clause) => ({
      ...clause,
      blankSpaces: clause.blankSpaces.filter(
        (blankSpace) => blankSpace.id !== index
      ),
    }));
    clauseRichEditorTextRef.current.removeBlankSpace(index);
  };

  const handleCloseModalDecision = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDecision = () => {
    setBlockNavigate(true);
    handleCloseModalDecision();
  };

  const handleAgreeModalDecision = () => {
    navigate(-1);
  };

  const handleCloseModalDecisionDelPart = () => {
    setIsOpenModalDecisionDelPart(false);
  };

  const handleDisagreeModalDecisionDelPart = () => {
    handleCloseModalDecisionDelPart();
  };

  const handleAgreeModalDecisionDelPart = () => {
    partsRemove(partDeleted);
    handleCloseModalDecisionDelPart();
  };

  const handleDeletePart = async (idx) => {
    setPartDeleted(idx);
    setIsOpenModalDecisionDelPart(true);
  };

  const buildNumerals = (numerals) => {
    const newNumerals = [];
    for (const [index, subClause] of numerals.entries()) {
      const clauseNumeral = {
        index: index + 1,
        subClauseId: null,
        newSubclause: {
          title: subClause.title,
          text: subClause.text,
          childs: [],
          blankspaces: buildBlankspaces(clause.subClauses[index].blankSpaces),
        },
      };
      for (const [subNumeralIdx, subNumeral] of subClause.numerals.entries()) {
        const numeral = {
          index: subNumeralIdx + 1,
          title: subNumeral.title,
          text: subNumeral.text,
          blankspaces: buildBlankspaces(
            clause.subClauses[index].numerals[subNumeralIdx].blankSpaces
          ),
        };
        clauseNumeral.newSubclause.childs.push(numeral);
      }
      newNumerals.push(clauseNumeral);
    }

    return newNumerals;
  };

  const buildParagraphs = (paragraphs) => {
    const newParagraphs = [];
    for (const [index, paragraph] of paragraphs.entries()) {
      const clauseParagraph = {
        index: index + 1,
        text: paragraph.text,
        blankspaces: buildBlankspaces(clause.paragraphs[index].blankSpaces),
      };
      newParagraphs.push(clauseParagraph);
    }
    return newParagraphs;
  };

  const buildBlankspaces = (blankSpaces) => {
    const blankSpacesArray = blankSpaces ?? [];
    const newBlankspaces = [];
    for (const [index, blankSpace] of blankSpacesArray.entries()) {
      const clauseBlankspaces = {
        index: index + 1,
        name: blankSpace.name,
        value: "",
      };
      newBlankspaces.push(clauseBlankspaces);
    }
    return newBlankspaces;
  };

  const buildCategories = (categories) => {
    const newCategories = [];
    for (const category of categories) {
      newCategories.push(category._id);
    }
    return newCategories;
  };

  const buildTags = (tags) => {
    const newTags = [];
    for (const tag of tags) {
      newTags.push(tag._id);
    }
    return newTags;
  };

  const buildContractTypes = (contractTypes) => {
    const newContractTypes = [];
    for (const contractType of contractTypes) {
      newContractTypes.push(contractType._id);
    }
    return newContractTypes;
  };

  const handleCreateClause = async (data) => {
    try {
      if (data.inFavor === "") {
        setError("inFavor", {
          type: "required",
          message: "*Este campo es obligatorio",
        });
        return;
      }
      setIsLoading(true);
      setSaveType(0);
      if (categoriesSelected.length === 0) {
        setIsOpenModalCategoriesEmpty(true);
        setIsLoading(false);
        return;
      }

      const objToSave = handleSaveChanges(data);

      setCurrentData({ data, objToSave });

      let errorParts = handleCheckParts(data);

      if (errorParts > 0) {
        setPartsErrorsModal(true);
        setIsLoading(false);
        return;
      }

      handleDispatchSave(data, objToSave);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDispatchSave = async (data, objToSave) => {
    try {
      setIsLoading(true);
      dispatch(
        setNewClause({
          clause: objToSave,
        })
      );
      const numerals = buildNumerals(data.subClauses);
      const paragraphs = buildParagraphs(data.paragraphs);
      const clauseBlankSpaces = buildBlankspaces(clause.blankSpaces);
      const categories = buildCategories(categoriesSelected);
      const tags = buildTags(tagsSelected);

      const obj = {
        title: data.title,
        parts: {
          type: "",
          partA: data.parts[0].title,
          partB: data.parts[1].title,
          partC: data.parts[2]?.title || null,
          partD: data.parts[3]?.title || null,
        },
        blankspaces: clauseBlankSpaces,
        text: data.clauseText,
        subclauses: numerals,
        paragraphs: paragraphs,
        categories: categories,
        labels: tags,
        inFavor: data.inFavor,
        isSensitive: isSensitiveClause,
        sensitiveText: data.sensitiveClauseText,
        contractTypeIds: buildContractTypes(contractTypesSelected),
      };

      const responseDispatch = await dispatch(addNewClause(obj)).unwrap();
      dispatch(setStatusFetchContractTypes());
      if (responseDispatch.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(responseDispatch);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(responseDispatch);
      setIsOpenModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsOpenTour(false);
    }
  };

  const handleIsClausesSensitive = (e) => {
    let checked = e.target.checked;
    setIsSensitiveClause(checked);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
  };

  const buildSubclauses = (numerals) => {
    const newNumerals = [];

    for (const [index, subClause] of numerals.entries()) {
      const clauseNumeral = {
        index: index + 1,
        _id: null,
        subclause: null,
      };
      if (subClause.isImported) {
        clauseNumeral._id = subClause.id;
        clauseNumeral.subclause = subClause;
        newNumerals.push(clauseNumeral);
      } else {
        clauseNumeral.subclause = {
          title: subClause.title,
          text: subClause.text,
          blankspaces: buildBlankspaces(clause.subClauses[index].blankSpaces),
          childs: [],
        };
        for (const [
          subNumeralIdx,
          subNumeral,
        ] of subClause.numerals.entries()) {
          const numeral = {
            index: subNumeralIdx + 1,
            title: subNumeral.title,
            text: subNumeral.text,
            blankspaces: buildBlankspaces(
              clause.subClauses[index].numerals[subNumeralIdx].blankSpaces
            ),
          };
          clauseNumeral.subclause.childs.push(numeral);
        }
        newNumerals.push(clauseNumeral);
      }
    }

    return newNumerals;
  };

  const handleSaveChanges = (data) => {
    try {
      const paragraphs = [];
      for (const [index, paragraph] of data.paragraphs.entries()) {
        const clauseParagraph = {
          paragraph: {
            index: index + 1,
            text: paragraph.text,
            blankspaces: buildBlankspaces(clause.paragraphs[index].blankSpaces),
          },
        };
        paragraphs.push(clauseParagraph);
      }
      const parts = [data.parts[0].title, data.parts[1].title];

      if (data?.parts[2]?.title) parts.push(data?.parts[2]?.title);
      if (data?.parts[3]?.title) parts.push(data?.parts[3]?.title);

      const obj = {
        title: data.title,
        parts,
        blankspaces: buildBlankspaces(clause.blankSpaces),
        text: data.clauseText,
        subclauses: buildSubclauses(data.subClauses),
        paragraphs: paragraphs,
        categories: categoriesSelected,
        labels: tagsSelected,
        inFavor: data.inFavor,
        isSensitive: isSensitiveClause,
        sensitiveText: data.sensitiveClauseText,
      };
      return obj;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUseOnlyThisDocument = (data) => {
    try {
      setIsLoading(true);
      const obj = handleSaveChanges(data);
      setSaveType(1);

      setCurrentData(obj);

      let errorParts = handleCheckParts(obj);

      if (errorParts > 0) {
        setPartsErrorsModal(true);
        setIsLoading(false);
        return;
      }
      dispatchUseOnlyThisDocument(obj);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatchUseOnlyThisDocument = (obj) => {
    try {
      dispatch(
        setNewClause({
          clause: obj,
        })
      );
      setResponseData({
        status: 200,
        data: {
          responseMessage: {
            message: "La cláusula ha sido agregada",
          },
        },
      });
      setIsOpenModal(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteContractType = (item) => {
    setContractTypesSelected(contractTypesSelected.filter((i) => i !== item));
  };

  const addTextInContentsNumerals = (validate = true) => {
    const subClauses = getValues("subClauses");
    subClauses.forEach((subClause, idx) => {
      const parts = getValues("parts").map((part) => part.title);
      const text = changeNameParts(subClause.text, parts);
      subClauseRichEditorTextRef.current[idx]?.current?.setEditorContents(text);
      setValue(`subClauses.${idx}`, {
        ...subClause,
        isImported: false,
        text,
      });
      setClause((clause) => ({
        ...clause,
        subClauses: clause.subClauses.map((subclause, index) => {
          if (index === idx) return { ...subclause, isImported: false, text };
          return subclause;
        }),
      }));
    });
  };

  const addTextInContentsParagrahs = () => {
    const paragraphs = getValues("paragraphs");
    paragraphs.forEach((paragraph, idx) => {
      const parts = getValues("parts").map((part) => part.title);
      const text = changeNameParts(paragraph.text, parts);
      paragraphRichEditorTextRef.current[idx].current?.setEditorContents(text);
      setValue(`paragraphs.${idx}`, {
        ...paragraph,
        text,
      });
      setClause((clause) => ({
        ...clause,
        paragraphs: clause.paragraphs.map((paragraph, index) => {
          if (index === idx) return { ...paragraph, isImported: false, text };
          return paragraph;
        }),
      }));
    });
  };

  useEffect(() => {
    const isCreate = pathname.includes("create-clause");
    if (isCreate) {
      setValue(`parts.0.title`, currentPartsDocument[0]?.name);
      setValue(`parts.1.title`, currentPartsDocument[1]?.name);
      if (currentPartsDocument[2]?.name) {
        partsAppend({
          title: currentPartsDocument[2]?.name,
        });
      }
      if (currentPartsDocument[3]?.name) {
        partsAppend({
          title: currentPartsDocument[3]?.name,
        });
      }
    }
  }, [currentPartsDocument]);

  const handleCheckParts = (clause) => {
    let partsErrorsArray = [];
    let errorsClause = checkText({
      text: clause.clauseText ? clause.clauseText : clause.text,
    });

    partsErrorsArray.push(...errorsClause);

    const arraySubclauses = clause?.subClauses || clause?.subclauses || [];

    for (const subclause of arraySubclauses) {
      let errorsSubclause = checkText(subclause);
      partsErrorsArray.push(...errorsSubclause);

      const arrayNumerals =
        subclause?.numerals || subclause?.subclause?.childs || [];

      for (const numeral of arrayNumerals) {
        let errorsNumeral = checkText(numeral);
        partsErrorsArray.push(...errorsNumeral);
      }
    }

    for (const paragraph of clause.paragraphs) {
      let errorsParagraph = checkText(paragraph);
      partsErrorsArray.push(...errorsParagraph);
    }
    return countPartErrors(partsErrorsArray);
  };
  useEffect(() => {
    const parts = getValues("parts").map((part) => part.title);
    const textClause = getValues("clauseText");
    const text = changeNameParts(textClause, parts);
    clauseRichEditorTextRef?.current?.setEditorContents(text);
    setValue("clauseText", text);
    addTextInContentsNumerals(false);
    addTextInContentsParagrahs();
  }, [isUpdate]);

  const navigateToSection = () => {
    setTimeout(() => {
      const section = document.getElementById("partsSection");
      if (section) {
        section.scrollIntoView({ behavior: "auto", block: "start" });
      }
    }, 100);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {state?.from === "create" && (
        <BlockerPage
          blockNavigate={blockNavigate}
          message={
            "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
          }
        />
      )}
      <Row>
        <Joyride
          callback={handleJoyrideCallback}
          run={isOpenTour}
          continuous={true}
          showSkipButton={true}
          hideCloseButton={true}
          steps={steps}
          locale={{
            skip: (
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
              >
                Saltar guía
              </Button>
            ),
            next: (
              <Button
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Continuar
              </Button>
            ),
            back: (
              <Button
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Regresar
              </Button>
            ),
            last: (
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
              >
                Finalizar
              </Button>
            ),
          }}
          styles={{
            options: {
              arrowColor: "rgba(0, 0, 0, 0)",
              backgroundColor: "rgba(255, 0, 0, 0)",
              overlayColor: "rgba(0, 0, 0, 0.37)",
              primaryColor: "#00374F",
              textColor: "#fff",
              width: "40vw",
              zIndex: 1000,
            },

            buttonNext: {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          }}
        />
      </Row>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"} id="partsSection">
        <SectionTitle title={"Crear cláusula"} />
        {/* <IconButton aria-label="delete" onClick={() => setIsOpenTour(true)}>
          <TourIcon fontSize="large" className="heading__primary-color" />
        </IconButton> */}
      </Row>
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleCreateClause)}>
          <Col
            xs={{ span: 6, offset: 0 }}
            className="create-clause-coachmark-step-1"
          >
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Título de la cláusula*
                <PopoverInfo section={CLAUSES_INFO.clauseTitle} />
              </Form.Label>
              <Form.Control
                {...register("title", {
                  onChange: (e) => setValue("title", e.target.value.trimLeft()),
                  setValueAs: (value) => value.trim(),
                })}
                placeholder="Título"
                bsPrefix={
                  errors.title
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.title?.message}
              </div>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Rol contractual*
                <PopoverInfo section={CLAUSES_INFO.partsDefinition} />
              </Form.Label>
              {partsFields.map((item, idx) => {
                return (
                  <Row key={`${JSON.stringify(item)}${idx}`}>
                    <Col xs={8}>
                      <Form.Control
                        maxLength={50}
                        disabled={pathname.includes("create-clause")}
                        {...register(`parts.${idx}.title`, {
                          onChange: (e) => {
                            setValue(`parts.${idx}.title`, e.target.value);
                          },
                        })}
                        placeholder={`${parts[idx]}`}
                        bsPrefix={
                          errors?.parts?.[idx]?.title
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                        onBlur={(e) => {
                          setIsUpdate((oldState) => !oldState);

                          navigateToSection();
                        }}
                      />
                    </Col>
                    {idx > 1 && !pathname.includes("create-document") && (
                      <Col xs={1}>
                        <Button
                          variant="contained"
                          className="custom-input__button__primary-color"
                          onClick={() => handleDeletePart(idx)}
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </Button>
                      </Col>
                    )}
                    <div className="caption custom-input__error  mb-4">
                      {errors?.parts?.[idx]?.title?.message}
                    </div>
                  </Row>
                );
              })}
            </Form.Group>

            {partsFields.length < 4 &&
              pathname.includes("create-clause") &&
              currentPartsDocument.length > partsFields.length && (
                <Row xs={"auto"} style={{ justifyContent: "right" }}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      partsAppend({
                        title:
                          currentPartsDocument[partsFields.length]?.name || "",
                      });
                    }}
                  >
                    Parte
                  </Button>
                </Row>
              )}
            {partsFields.length < 4 && !pathname.includes("create-clause") && (
              <Row xs={"auto"} style={{ justifyContent: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    partsAppend({
                      title: "",
                    });
                  }}
                >
                  Parte
                </Button>
              </Row>
            )}
          </Col>
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Form.Group className="mb-5 create-clause-coachmark-step-2">
            <Row>
              <Col>
                <Row style={{ marginBottom: "3%" }}>
                  <Col md={3} style={{ marginTop: "0.3%" }}>
                    <Form.Label className="heading">
                      1. Texto de la cláusula
                      <PopoverInfo section={CLAUSES_INFO.clauseText} />
                    </Form.Label>
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      {getValues("parts").map((item, idx) => {
                        if (watch(`parts.${idx}.title`)) {
                          return (
                            <Col
                              className="create-clause-coachmark-step-3"
                              xs={"auto"}
                              key={`${JSON.stringify(item)}${idx}`}
                              style={{ marginTop: "1%" }}
                            >
                              <Button
                                onClick={() =>
                                  clauseRichEditorTextRef.current.addPart(
                                    item.title,
                                    idx
                                  )
                                }
                                variant="contained"
                                className="custom-input__button__secondary-color"
                              >
                                {item.title}
                              </Button>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Col
                        className="create-clause-coachmark-step-4"
                        xs={"auto"}
                        style={{ marginTop: "1%", marginBottom: "0.5%" }}
                      >
                        <Button
                          variant="contained"
                          startIcon={<AddIcon fontSize="large" />}
                          className="custom-input__button__secondary-color"
                          onClick={() =>
                            handleBlankSpaces(clause.blankSpaces.length + 1)
                          }
                        >
                          Espacio en blanco
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Controller
                  control={control}
                  name="clauseText"
                  render={({ field: { onChange, onBlur } }) => (
                    <RichEditorText
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange}
                      ref={clauseRichEditorTextRef}
                      customClassName={
                        errors.clauseText
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                      deleteBlankSpace={(id) => {
                        setClause((clause) => ({
                          ...clause,
                          blankSpaces: clause.blankSpaces.filter(
                            (blankSpace) => blankSpace.id !== id
                          ),
                        }));
                      }}
                    />
                  )}
                />
                <div className="caption custom-input__error">
                  {errors.clauseText?.message}
                </div>
              </Col>
              <Col xs={"auto"}>
                <Container>
                  <Row style={{ marginTop: "6%" }}>
                    <p className="label">Espacios en blanco:</p>
                  </Row>
                  {clause.blankSpaces.map((item, index) => {
                    return (
                      <Row xs={"auto"} key={`Clause ${item?.id} ${index}`}>
                        <p className="heading__primary-color">
                          ESPACIO #{item.id}{" "}
                          <IconButton onClick={() => removeBlankSpace(item.id)}>
                            <DeleteOutlineIcon
                              className="heading__primary-color"
                              fontSize="large"
                            />
                          </IconButton>
                        </p>
                      </Row>
                    );
                  })}
                </Container>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <NumeralsModule
              control={control}
              register={register}
              getValues={getValues}
              watch={watch}
              errors={errors}
              setClause={setClause}
              clause={clause}
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              setValue={setValue}
              subClauseRichEditorTextRef={subClauseRichEditorTextRef}
              isUpdate={isUpdate}
            />
          </Form.Group>
          <Divider />
          <br />
          <br />
          <Form.Group style={{ justifyContent: "right" }} className="mb-5">
            <ParagraphModule
              control={control}
              getValues={getValues}
              watch={watch}
              errors={errors}
              clause={clause}
              setClause={setClause}
              paragraphRichEditorTextRef={paragraphRichEditorTextRef}
            />
          </Form.Group>
          <Divider />
          <br />
          <br />

          <Form.Group className="mb-5 create-clause-coachmark-step-6">
            <Form.Label className="heading mb-4">
              Cláusula sensible
              <PopoverInfo section={CLAUSES_INFO.sensitiveClause} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleIsClausesSensitive(e)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          color: "#00374f",
                          "&.Mui-checked": {
                            color: "#00374f",
                          },
                        }}
                      />
                    }
                    label={<p className="body-content">Sensible</p>}
                  />
                </FormGroup>
              </Col>
              {isSensitiveClause && (
                <Col>
                  <Form.Control
                    {...register("sensitiveClauseText")}
                    bsPrefix={
                      errors.sensitiveClauseText
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div className="caption custom-input__error">
                    {errors.sensitiveClauseText?.message}
                  </div>
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Añadir Categorías*
              <PopoverInfo section={CLAUSES_INFO.categories} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateCategories
                  categories={categoriesList}
                  handleCategoriesSelected={handleCategoriesSelected}
                  previousCategoriesSelected={categoriesSelected}
                />
              </Col>
              {categoriesSelected.map((category) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={category?._id}
                  >
                    <span className="caption custom-badges__secundary">
                      {category.name + " "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteCategory(category)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>

          <Form.Group xs={"auto"} className="mb-5">
            <Form.Label className="heading mb-4">
              A favor de*
              <PopoverInfo section={CLAUSES_INFO.inFavor} />
            </Form.Label>
            <Row xs={"auto"}>
              <Form.Select
                className="label mb-1"
                defaultValue=""
                bsPrefix={
                  errors.inFavor
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("inFavor")}
              >
                <option className="label" value="" disabled>
                  Seleccione uno
                </option>
                <option className="label" value="Balanceada">
                  Balanceada
                </option>
                {getValues("parts").map((item, idx) => {
                  if (watch(`parts.${idx}.title`)) {
                    return (
                      <option
                        key={`${JSON.stringify(item)}${idx}`}
                        className="label"
                        value={item.title}
                      >
                        {item.title}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </Form.Select>
              <p className="caption custom-input__error">
                {errors.inFavor?.message}
              </p>
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Etiquetas
              <PopoverInfo section={CLAUSES_INFO.tags} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateTags
                  tags={tagsList}
                  handleTagsSelected={handleTagsSelected}
                  previousTagsSelected={tagsSelected}
                  type="CLAUSES"
                />
              </Col>
              {tagsSelected.map((tag) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={tag?._id}
                  >
                    <span className="caption custom-badges__disabled">
                      {tag.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Agregar a minuta
              <PopoverInfo section={CLAUSES_INFO.contractType} />
            </Form.Label>
            <Row xs={"auto"} style={{ alignItems: "center" }}>
              <AssociateContractTypes
                previousData={contractTypesSelected}
                setPreviousData={setContractTypesSelected}
              />
              {contractTypesSelected.map((item, index) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={item?._id}
                  >
                    <span className="caption custom-badges__disabled">
                      {item.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteContractType(item)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>
          <Divider />
          <br />
          <br />
          <Row xs={"auto"}>
            <Col>
              <ModalPreviewClause clause={clause} hookFormInfo={watch} />
            </Col>
            <Col>
              <Button
                variant="contained"
                startIcon={<DeleteOutlineIcon fontSize="large" />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setIsOpenModalDecision(true);
                  setBlockNavigate(false);
                }}
              >
                Descartar
              </Button>
            </Col>
            {state?.from !== "create" ? (
              <Col>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                >
                  Terminar y guardar
                </Button>
              </Col>
            ) : (
              <>
                <Col>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<LocalLibraryOutlinedIcon fontSize="large" />}
                    className="custom-input__button__primary-color"
                  >
                    Guardar en biblioteca
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="button"
                    variant="contained"
                    startIcon={<ArticleOutlinedIcon fontSize="large" />}
                    className="custom-input__button__primary-color"
                    onClick={handleSubmit(handleUseOnlyThisDocument)}
                  >
                    Usar solo en este contrato
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <br />
          <br />
          <Divider />
        </Form>
      </Row>

      <ModalWarningForm
        title={"Advertencia"}
        description={
          "No se han asociado categorías a la clausula, estas son obligatorias"
        }
        open={isOpenModalCategoriesEmpty}
        onClose={handleCloseModalCategoriesEmpty}
      />

      <ModalInfo
        title={"Cambios guardados"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Eliminar parte"}
        message={"¿Está seguro que desea eliminar la parte seleccionada?."}
        open={isOpenModalDecisionDelPart}
        onClose={handleCloseModalDecisionDelPart}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDecisionDelPart}
        handleDisagree={handleDisagreeModalDecisionDelPart}
      />

      <ModalDecision
        title={"Descartar cambios"}
        message={
          "¿Está seguro que desea realizar esta acción? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDecision}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDecision}
        handleDisagree={handleDisagreeModalDecision}
      />

      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />

      <ModalDecision
        title={"Errores gramaticales encontrados"}
        message={
          <Container fluid className="caption">
            <Row>
              Hemos detectado algunas inconsistencias gramaticales sin corregir,
              ¿seguro quieres continuar?
            </Row>
            <br />
          </Container>
        }
        open={partsErrorsModal}
        onClose={() => setPartsErrorsModal(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          if (saveType === 1) {
            setIsLoading(true);
            setPartsErrorsModal(false);
            dispatchUseOnlyThisDocument(currentData);
          } else {
            setPartsErrorsModal(false);
            handleDispatchSave(currentData.data, currentData.objToSave);
          }
        }}
        handleDisagree={() => {
          setPartsErrorsModal(false);
        }}
      />
    </Container>
  );
};

export default CreateClause;
