export const SignatariOPN = {
  text: "¬nombre signatario¬, identificado con ¬tipo de documento¬ ¬número de documento¬ y domiciliado en ¬domicilio¬. ",
  gender: "M",
  type: "natural",
  name: "Signatario (PN)",
};

export const SignatariAPN = {
  text: "¬nombre signatario¬, identificada con ¬tipo de documento¬ ¬número de documento¬ y domiciliada en ¬domicilio¬.",
  gender: "F",
  type: "natural",
  name: "Signataria (PN)",
};

export const SignatariOPJ = {
  text: "¬nombre signatario¬, identificado con ¬tipo de documento¬ ¬número de documento¬, actuando en calidad de ¬cargo¬ de la sociedad ¬razon social¬ identificada con ¬tipo de documento2¬ ¬número de documento2¬ y domiciliado en ¬domicilio¬.",
  gender: "M",
  type: "legal",
  name: "Signatario (PJ)",
};

export const SignatariAPJ = {
  text: "¬nombre signatario¬, identificada con ¬tipo de documento¬ ¬número de documento¬, actuando en calidad de ¬cargo¬ de la sociedad ¬razon social¬ identificada con ¬tipo de documento2¬ ¬número de documento2¬ y domiciliada en ¬domicilio¬.",
  gender: "F",
  type: "legal",
  name: "Signataria (PJ)",
};

export const PersonaNaturalFields = [
  { index: 1, name: "Nombre completo", value: "" },
  { index: 3, name: "Tipo de Documento", value: "" },
  { index: 5, name: "Número de Documento", value: "" },
  { index: 7, name: "Domicilio", value: "" },
];

export const PersonaJuridicaFields = [
  { index: 1, name: "Nombre completo", value: "" },
  { index: 3, name: "Tipo de Documento", value: "" },
  { index: 5, name: "Número de Documento", value: "" },
  { index: 7, name: "Cargo", value: "" },
  { index: 9, name: "Nombre de la Sociedad", value: "" },
  { index: 11, name: "Tipo de Documento", value: "" },
  { index: 13, name: "Número de Documento", value: "" },
  { index: 15, name: "Domicilio", value: "" },
];
