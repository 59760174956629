import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";

import CustomSearchFilterButton from "../../../../../../../components/Search/CustomSearchFilterButton";

import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import { useDispatch, useSelector } from "react-redux";

import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import LoadingContent from "../../../../../../../components/loadingContent";
import { findPartById } from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../../utils/codes";

import {
  addEditedSignatoryToPart,
  getCurrentDocumentParts,
} from "../../../../../../../parts/document/currentDocumentSlice";

import { PopoverLimits } from "../../../../../../../components/Popover/PopoverLimits";

export const SelectDefaultSignatoryForContract = () => {
  //Redux
  const dispatch = useDispatch();

  const currentParts = useSelector(getCurrentDocumentParts);

  const [part, setPart] = useState({});

  //States

  const [signatoriesList, setSignatoriesList] = useState([]);

  const [openModalNoSignatories, setOpenModalNoSignatories] = useState(false);

  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Parte relacionada.
        </label>,
      ],
      field: "partName",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Cargo del firmante en la Parte
        </label>,
      ],
      field: "partPosition",
    },
  ];

  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });

  const [selectedSignatories, setSelectedSignatories] = useState([]);

  const [isLoading, setIsLoading] = useState(null);

  //Router

  const navigate = useNavigate();

  const { state } = useLocation();
  /**
   * Use Params
   */

  const { roleIndex, partIndex } = useParams();

  //Handles

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedSignatories([item]);
    } else {
      setSelectedSignatories(
        selectedSignatories.filter((itm) => itm?._id !== item?._id)
      );
    }
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      const signatory = item.signatory;
      let name = getName(signatory);
      let id = `${signatory?.documentType}. ${signatory?.documentNumber}`;
      const contact = `${signatory?.email || ""}`;
      const address = signatory?.residence?.physicalAddress;
      const country = signatory?.residence?.country;
      const city = `${signatory?.residence?.province?.concat(", ") || ""} ${
        signatory?.residence?.city || ""
      }`;
      const number = `+${signatory?.mobileNumberData?.countryCode || ""} ${
        signatory?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${signatory?.countryCodeTelephone || ""} ${
        signatory?.cityCode || ""
      } ${signatory?.numberTelephone || ""}`;

      formatedData.push({
        name,

        partName: (
          <Row className="align-items-center">
            <Col xs={12}>
              <span className="caption__primary-color__active ">
                {part?.information?.name}
              </span>
            </Col>
          </Row>
        ),
        partPosition: (
          <Row className="align-items-center">
            <Col xs={"auto"} lg={8}>
              <span className="caption__primary-color__active ">
                {item.position}
              </span>
            </Col>

            {item?.limitations && (
              <Col xs={"auto"} lg={4}>
                <PopoverLimits
                  limitationsUpdatedDate={item?.limitationsUpdatedDate}
                  createdAt={item?.signatory?.createdAt}
                  limitations={item?.limitations}
                />
              </Col>
            )}
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };
  const buildSignatoryData = (signatory) => {
    return {
      address: signatory?.signatory?.residence?.physicalAddress,
      documentNumber: signatory?.signatory?.documentNumber,
      documentType:
        signatory?.signatory?.otherTypeDocument ||
        signatory?.signatory?.documentType,
      name: getName(signatory?.signatory) || "",
      email: signatory?.signatory?.email,
      charge: signatory?.position,
      text: getSignatoryText(signatory?.signatory),
      isDefault: true,
      _id: signatory.signatory?._id,
    };
  };

  const getSignatoryText = (signatory) => {
    let text = `${getName(signatory) || "¬Nombre¬"} quien se identifica con ${
      signatory?.otherTypeDocument ||
      signatory?.documentType ||
      "¬tipo de documento¬"
    } número ${
      signatory?.documentNumber || "¬número de documento¬"
    } y con domicilio en ${
      signatory?.residence?.physicalAddress || "¬domicilio¬"
    }`;
    return text;
  };

  const handleCreateSignatory = () => {
    try {
      setIsLoading(true);
      selectedSignatories.forEach((signatory) => {
        const values = buildSignatoryData(signatory);

        dispatch(
          addEditedSignatoryToPart({
            partIndex: partIndex,
            roleIndex: roleIndex,
            signatoryIndex: state.indexSignatory,
            editedSignatory: values,
          })
        );
      });

      navigate(-1);
    } catch (error) {
      console.log(
        "============ Handle Create Signatory Error Start ============"
      );
      console.error(error);
      console.log(
        "============ Handle Create Signatory Error End ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  //Use effects

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await findPartById({
          partId:
            currentParts[roleIndex]?.signatories[partIndex]?.information?._id,
        });
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          if (
            !response.data?.responseMessage?.data?.relatedSignatories?.length
          ) {
            setOpenModalNoSignatories(true);
          }
          setPart(currentParts[roleIndex]?.signatories[partIndex]);
          setSignatoriesList(
            response.data?.responseMessage?.data?.relatedSignatories
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (part) {
      part?.signatories?.forEach((signatory) => {
        let foundSignatory = signatoriesList.find(
          (item) =>
            item?.signatory?._id === signatory?._id && signatory.isDefault
        );
        if (foundSignatory) {
          setSelectedSignatories((oldArray) => [...oldArray, foundSignatory]);
        }
      });
    }
  }, [part, signatoriesList]);

  useEffect(() => {
    let tableColumns = columns;
    let tranformData = buildData(signatoriesList);

    setData({ rows: tranformData, columns: tableColumns });
    setFilteredData({ rows: tranformData, columns: tableColumns });
  }, [signatoriesList, selectedSignatories]);

  return (
    <Container fluid className="custom-container-scroll ">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Firmantes guardados`} />
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"} className="caption">
          {`Escoge el o los firmantes que quieras:`}
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
      </Row>

      <br />

      <br />
      <Row className="dt-list-center-content " style={{ marginBottom: "7rem" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes partes creadas"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckIcon fontSize="large" />}
            disabled={!selectedSignatories.length}
            onClick={() => {
              handleCreateSignatory();
            }}
          >
            Seleccionar
          </Button>
        </Col>
      </Row>

      <ModalInfo
        title="Sin firmantes guardados"
        onClose={() => setOpenModalNoSignatories(false)}
        open={openModalNoSignatories}
        responseData={{
          data: {
            message: "No existen firmantes guardados en biblioteca",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
    </Container>
  );
};
