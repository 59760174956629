import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate, useLocation } from "react-router-dom";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { useSelector } from "react-redux";
import {
  getCurrentDocument,
  getReports,
} from "../../../../../../../parts/document/currentDocumentSlice";
import { reportMilestoneCreator } from "../../../../../../../components/WordCreator/reportMilestonesDocx";
import LoadingContent from "../../../../../../../components/loadingContent";
import { convertToPdfService } from "../../../../../../../services/utils/convertWordToPdf";
import fileDownload from "js-file-download";
import { CODES } from "../../../../../../../utils/codes";
import { REGEXP } from "../../../../../../../utils/regexp";
import dateFormat from "dateformat";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { reportIAResult } from "../../..../../../../../../../services/admin/manageUserService";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { v4 as uuidv4 } from "uuid";
import { Mixpanel } from "../../../../../../../utils/mixPanel";

export const ReportsMIlestionesAndDate = ({ type }) => {
  const MENU_FILTER_BY = [
    {
      title: "Duración",
      selectionClick: 0,
    },
    {
      title: "Notificación",
      selectionClick: 1,
    },
    {
      title: "Suscripción",
      selectionClick: 2,
    },
    {
      title: "Obligaciones",
      selectionClick: 3,
    },
    {
      title: "Terminación",
      selectionClick: 4,
    },
    {
      title: "Otros hitos",
      selectionClick: 5,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const dataReports = useSelector(getReports);
  const currentDocument = useSelector(getCurrentDocument);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [itemReport, setItemReport] = useState(null);
  const [anchorFilterBy, setAnchorFilterBy] = useState(null);
  const [filterSelected, setFilterSelected] = useState(null);
  const [nameDocument, setNameDocument] = useState("");
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const openFilterBy = Boolean(anchorFilterBy);
  const handleDeleteFilter = () => {
    setFilterSelected(null);
    setFilteredData(data);
  };
  const handleMenuItemClickFilterBy = (option) => {
    setAnchorFilterBy(null);
    setFilterSelected(option);
    const filter = data.filter((item) => item.type === option.title);
    setFilteredData(filter);
  };
  const handleInfoToSupport = async (reportMessage) => {
    let isInfoSentToSupport = false;
    try {
      setIsLoading(true);
      const reportInformation = {
        message: reportMessage,
        functionalityName: `${REPORTS_TYPE.MILESTONES_AND_DATES.name}`,
        currentPathname: location.pathname,
      };
      const serviceResponse = await reportIAResult(reportInformation);
      if (serviceResponse) {
        if (serviceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          isInfoSentToSupport = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return isInfoSentToSupport;
  };
  const handleAgreeModalInputReport = async (data) => {
    if (data === "") return;
    if (itemReport) {
      const isInfoSentToSupport = await handleInfoToSupport(data);
      setIsOpenModalInputReport(!isInfoSentToSupport);
    }
  };
  useEffect(() => {
    if (currentDocument) {
      setNameDocument(
        currentDocument?.title || currentDocument?.name || "Informe_de_hitos"
      );
    }
  }, [currentDocument]);
  useEffect(() => {
    let reportsData = [
      ...dataReports.find((item) => item.typeReport === type).reports,
    ];
    reportsData.sort((a, b) => {
      let dateA = a?.time?.timexp;
      let dateB = b?.time?.timexp;
      if (
        dateA?.match(REGEXP.DATE_FORMAT_AMD) &&
        !dateB?.match(REGEXP.DATE_FORMAT_AMD)
      ) {
        return -1;
      }
      if (
        !dateA?.match(REGEXP.DATE_FORMAT_AMD) &&
        dateB?.match(REGEXP.DATE_FORMAT_AMD)
      ) {
        return 1;
      }
      if (
        dateA?.match(REGEXP.DATE_FORMAT_AMD) &&
        dateB?.match(REGEXP.DATE_FORMAT_AMD)
      ) {
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        }
      }
      return 0;
    });
    setData(reportsData);
    setFilteredData(reportsData);
  }, [dataReports]);
  const handleGenerateReport = async () => {
    try {
      setIsLoading(true);
      Mixpanel.track("Generar PDF IA Informes  ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
        type: "Hitos",
      });
      const doc = await reportMilestoneCreator({
        reports: data,
        contract: nameDocument,
      });
      if (doc) {
        const pdf = await convertToPdfService(doc);
        if (pdf.status === CODES.COD_RESPONSE_HTTP_OK) {
          const dateName = dateFormat(new Date(), "isoDate");
          const filename = `${dateName}-Hitos-${nameDocument}.pdf`;
          const dataFile = pdf?.data?.responseMessage?.data;
          const file = new Uint8Array(dataFile?.buffer?.data).buffer;
          fileDownload(file, filename);
        }
      }
    } catch (error) {
      console.log(
        "============ Start Error Download Report Values PDF ============"
      );
      console.error(error);
      console.log(
        "============ Start Error Download Report Values PDF ============"
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (currentDocument) {
      setNameDocument(
        currentDocument?.title || currentDocument?.name || "Informe_de_hitos"
      );
    }
  }, [currentDocument]);
  useEffect(() => {
    let reportsData = [
      ...dataReports.find((item) => item.typeReport === type).reports,
    ];
    reportsData.sort((a, b) => {
      let dateA = a?.time?.timexp;
      let dateB = b?.time?.timexp;
      if (
        dateA?.match(REGEXP.DATE_FORMAT_AMD) &&
        !dateB?.match(REGEXP.DATE_FORMAT_AMD)
      ) {
        return -1;
      }
      if (
        !dateA?.match(REGEXP.DATE_FORMAT_AMD) &&
        dateB?.match(REGEXP.DATE_FORMAT_AMD)
      ) {
        return 1;
      }
      if (
        dateA?.match(REGEXP.DATE_FORMAT_AMD) &&
        dateB?.match(REGEXP.DATE_FORMAT_AMD)
      ) {
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        }
      }
      return 0;
    });
    setData(reportsData);
    setFilteredData(reportsData);
  }, [dataReports]);
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ alignItems: "center" }}>
        <Col>
          {filterSelected && (
            <span
              style={{ marginRight: "1%" }}
              className="subheading caption custom-badges__disabled"
            >
              {filterSelected.title}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilter()}
              />
            </span>
          )}
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={handleGenerateReport}
          >
            Generar PDF
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            endIcon={<ExpandMoreIcon />}
            className={"custom-input__button__secondary-color"}
            onClick={(e) => setAnchorFilterBy(e.currentTarget)}
          >
            <p className="button">Filtrar por</p>
          </Button>
          <Menu
            id="menu-filter-by"
            anchorEl={anchorFilterBy}
            open={openFilterBy}
            onClose={() => setAnchorFilterBy(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {MENU_FILTER_BY.map((option, index) => (
              <MenuItem
                key={uuidv4()}
                onClick={() => handleMenuItemClickFilterBy(option)}
              >
                <span className="body-content">{option.title}</span>
              </MenuItem>
            ))}
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        {filteredData?.length > 0 &&
          filteredData.map((item, index) => (
            <Accordion className="custom-accordion" key={uuidv4()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="header-reports-milestionesAndDate"
              >
                <Col>
                  <p className="heading__primary-color">
                    {`${index + 1}. ${item.type}`}
                  </p>
                </Col>
              </AccordionSummary>
              <AccordionDetails>
                {item?.titlesClauses?.length > 0 &&
                  item?.titlesClauses?.map((reportByClause, indexTitle) => (
                    <Accordion
                      className="custom-accordion-clauses"
                      key={uuidv4()}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="header-reports-milestionesAndDate-clauses"
                      >
                        <Col>
                          <p className="heading__primary-color">
                            {`${index + 1}.${indexTitle + 1}. ${
                              reportByClause.titleClause
                            }`}
                          </p>
                        </Col>
                      </AccordionSummary>
                      <AccordionDetails>
                        {reportByClause?.keysPhrases?.length > 0 &&
                          reportByClause?.keysPhrases?.map(
                            (reportByKeyPhrase, index) => (
                              <div key={uuidv4()}>
                                {reportByKeyPhrase?.reports.length > 0 &&
                                  reportByKeyPhrase?.reports?.map(
                                    (report, index) => (
                                      <div
                                        key={uuidv4()}
                                        style={{ marginLeft: "1.5rem" }}
                                      >
                                        <Row
                                          style={{
                                            height: "10%",
                                            alignItems: "center",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <Col>
                                            <span className="subheading">
                                              {"Fecha o Hito:  "}
                                              <span className="caption">
                                                {report.text}
                                              </span>
                                            </span>
                                          </Col>
                                          <Col xs={"auto"}>
                                            <Button
                                              variant="contained"
                                              startIcon={<ErrorOutlineIcon />}
                                              className="custom-input__button__secondary-color"
                                              onClick={() => {
                                                setItemReport(report);
                                                setIsOpenModalInputReport(true);
                                              }}
                                            >
                                              <p className="button">Reportar</p>
                                            </Button>
                                          </Col>
                                          <Col xs={"auto"}>
                                            <Button
                                              variant="contained"
                                              startIcon={
                                                <RemoveRedEyeOutlinedIcon />
                                              }
                                              className="custom-input__button__primary-color"
                                              onClick={() =>
                                                navigate(
                                                  `viewContract/#${report.id_text}`
                                                )
                                              }
                                            >
                                              <p className="button">
                                                Ver en contrato
                                              </p>
                                            </Button>
                                          </Col>
                                        </Row>
                                        <br />
                                      </div>
                                    )
                                  )}
                                <Row style={{ marginLeft: "1rem" }}>
                                  <span className="subheading">
                                    {"Ubicación en frase completa:  "}
                                    <span className="caption">
                                      ... {reportByKeyPhrase.keyPhrase} ...
                                    </span>
                                  </span>
                                </Row>
                                <hr />
                              </div>
                            )
                          )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        {!filteredData?.length > 0 && (
          <span className="caption">No se han encontrado resultados</span>
        )}
      </Row>
      <ModalInpuText
        title={"Reportar hito"}
        message={"¿Deseas reportar el siguiente hito?"}
        agreeText={"Aceptar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={() => {
          setItemReport(null);
          setIsOpenModalInputReport(false);
        }}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
    </Container>
  );
};
