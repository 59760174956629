import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CardFilter = ({ icon, title, description, minHeight = null }) => {
  return (
    <Container
      className="section-card cards-filter filter-tag__container"
      style={{ minHeight }}
    >
      <Row>
        <Col xs={"auto"}>
          <p className="heading__primary-color text-ellipsis-o">
            {icon} {title}
          </p>
        </Col>
      </Row>
      <Row>
        <p className="caption justify-text text-ellipsis-t">{description}</p>
      </Row>
    </Container>
  );
};

export default CardFilter;
