import React from "react";
import { createStore } from "@react-pdf-viewer/core";

const useJumpToPagePlugin = () => {
  const store = React.useMemo(() => createStore(), []);

  const jumpToPage = (pageIndex) => {
    const fn = store.get("jumpToPage");
    if (fn) {
      fn(pageIndex);
    }
  };

  const install = (pluginFunctions) => {
    store.update("jumpToPage", pluginFunctions.jumpToPage);
  };

  return {
    install,
    jumpToPage,
  };
};

export default useJumpToPagePlugin;
