import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingContent from "../../../../../components/loadingContent";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import ModalPDFView from "../../../../../components/Modals/modalPDFView";
import SectionTitle from "../../../../../components/sectionTitle";
import {
  WordBlobDocument,
  WordCreator,
  wordCreatorFile,
} from "../../../../../components/WordCreator/WordCreator";
import { ImportClause } from "../../../../../parts/associations/ImportClause";
import Breadcrum from "../../../../../parts/breadcrum";
import {
  addEditedPart,
  cleanStore,
  deleteClause,
  deleteHeader,
  deletePart,
  deleteSignatoryWithoutPart,
  getCurrentDocument,
  getPartsCorrection,
  reorderClause,
  reorderHeader,
  reorderSignatoriesWithoutParts,
  setClauseSelected,
  setIdDraft,
  setName,
  setNamePartClause,
  setPartsCorrection,
  setSelectedClauses,
  setSelectedClausesWithoutCheckPart,
  setTitle,
  swapParts,
} from "../../../../../parts/document/currentDocumentSlice";
import {
  getSelectedHeader,
  getSelectedHeaderId,
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../parts/headings/headingsSlice";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import {
  autoSaveDraft,
  createDraft,
  deleteVersion,
  getFileDocument,
} from "../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../services/utils/convertWordToPdf";
import {
  addVersion,
  createNewVersion,
} from "../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../utils/codes";
import { codesDocumentType } from "../../../../../utils/codesDocumentType";
import {
  NUMBERINGWORDSA,
  NUMBERINGWORDSO,
} from "../../../../../utils/numberings";
import { IconNoSaveInfo } from "./generalViewDocument";

import { Buffer } from "buffer";
import {
  ApplyHeadingToDocument,
  previewHeadingDocument,
} from "../../../../../services/admin/documents/headingsServices";
import {
  checkPartClause,
  cleanCheckPartClause,
} from "../../../../../utils/partsCorrection";
import { IOSSwitch } from "../../../admin/modules/rulesReview/rulesReview";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import {
  DragDropContextParts,
  DragDropContextSignatories,
  GetReferenceNormative,
} from "./utils";
import { PaletteOutlined, PostAddOutlined } from "@mui/icons-material";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { Mixpanel } from "../../../../../utils/mixPanel";

const GeneralEditDocument = () => {
  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const currentDocument = useSelector(getCurrentDocument);
  const selectedHeader = useSelector(getSelectedHeaderId);
  const companyPlan = useSelector(getCompanyPlan);
  const selectedHeaderObject = useSelector(getSelectedHeader);
  const partsCorrection = useSelector(getPartsCorrection);
  //States
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openPreview, setOpenPreview] = useState(false);
  const [draftModal, setDraftModal] = useState(false);
  const [blankSpacesModal, setBlankSpacesModal] = useState(false);
  const [folderSelectedModal, setFolderSelectedModal] = useState(false);
  const [saveAsModal, setSaveAsModal] = useState(false);
  const [saveAsModalVersion, setSaveAsModalVersion] = useState(false);
  const [newNameDocument, setNewNameDocument] = useState("");
  const [missingBlankspaces, setMissingBlankspaces] = useState([]);
  const [saveType, setSaveType] = useState("");
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [initAutoSave, setInitAutoSave] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [lastSave, setLastSave] = useState("");
  const [firstDraftSave, setFirstDraftSave] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countInterval, setCountInterval] = useState(0);
  const [isOpenModalInfoSaveDocument, setIsOpenModalInfoSaveDocument] =
    useState(false);
  const [responseDataModalInfo, setResponseDataModalInfo] = useState({});
  const [numeration, setNumeration] = useState("number");
  const [openModalSaveAsDraft, setOpenModalSaveAsDraft] = useState(false);
  const [responseSaveAsDraft, setResponseSaveAsDraft] = useState({});
  const [isOpenModalLimitPlan, setIsOpenModalLimitPlan] = useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);
  const [partsErrorsModal, setPartsErrorsModal] = useState(false);
  const [checkPartsGrammar, setCheckPartsGrammar] = useState(false);
  const [countGeneralBlankspaces, setCountGeneralBlankspaces] = useState({});
  const [openModalSelectSignatory, setOpenModalSelectSignatory] =
    useState(false);

  const [anchorElSignatory, setAnchorElSignatory] = useState(null);

  const openSignatory = Boolean(anchorElSignatory);

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  //Forms

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  //Handles
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSubMenu = (type) => {
    if (type === "con") {
      navigate("add-header-with");
    } else if (type === "sin") {
      navigate("add-header-without");
    }
    handleClose();
  };
  const handleFilter = (text) => {
    const clausesFilter = text
      ? currentDocument.clauses.filter((cla) => cla.clause.title.includes(text))
      : currentDocument.clauses;
    setFilteredClauses(clausesFilter);
  };
  const getFillBlankSpaces = (clause) => {
    let count = 0;
    count += clause?.blankspaces?.filter((blank) => blank.value)?.length ?? 0;
    const subclauses = clause?.subclauses || [];
    for (const sub of subclauses) {
      count +=
        sub?.subclause?.blankspaces?.filter((blank) => blank.value)?.length ??
        0;
      if (sub?.subclause?.childs?.length) {
        for (const child of sub.subclause.childs) {
          count +=
            child?.blankspaces?.filter((blank) => blank.value)?.length ?? 0;
        }
      }
    }
    const paragraphs = clause?.paragraphs || [];

    for (const para of paragraphs) {
      count +=
        para?.paragraph?.blankspaces?.filter((blank) => blank.value)?.length ??
        0;
    }

    return count;
  };

  const getAllBlankSpaces = (clause) => {
    let count = 0;
    count += clause?.blankspaces?.length ?? 0;
    if (clause?.subclauses?.length) {
      for (const sub of clause.subclauses) {
        count += sub?.subclause?.blankspaces?.length ?? 0;
        if (sub?.subclause?.childs?.length) {
          for (const child of sub?.subclause?.childs) {
            if (child.blankspaces) {
              count += child.blankspaces.length;
            }
          }
        }
      }
    }
    if (clause?.paragraphs?.length) {
      for (const par of clause.paragraphs) {
        count += par?.paragraph?.blankspaces?.length ?? 0;
      }
    }
    return count;
  };
  const handleCleanStore = () => {
    dispatch(cleanStore());
  };

  const validateBlankspaces = () => {
    const listMissingBlankspaces = currentDocument.clauses.filter(
      (cla) => getFillBlankSpaces(cla.clause) !== getAllBlankSpaces(cla.clause)
    );
    setMissingBlankspaces(listMissingBlankspaces);
  };

  const buildPartsDocument = (document) => {
    let parts = {
      partA: document?.parts?.[0] || null,
      partB: document?.parts?.[1] || null,
    };
    if (document?.parts?.[2])
      parts = { ...parts, partC: currentDocument.parts[2] };
    if (document?.parts?.[3])
      parts = { ...parts, partD: currentDocument.parts[3] };

    return parts;
  };

  const buildPartsClauses = (document) => {
    let parts = {
      partA: document?.parts?.[0]?.name || "Sin titulo 1",
      partB: document?.parts?.[1]?.name || "Sin titulo 2",
    };
    if (document?.parts?.[2])
      parts = {
        ...parts,
        partC: currentDocument.parts[2]?.name || "Sin titulo 3",
      };
    if (document?.parts?.[3])
      parts = {
        ...parts,
        partD: currentDocument.parts[3]?.name || "Sin titulo 4",
      };

    return parts;
  };

  const handleAutoDraftRequest = async () => {
    const parts = buildPartsDocument(currentDocument);
    const partsClauses = buildPartsClauses(currentDocument);

    const newDocument = {
      ...currentDocument,
      parts,
      clauses: currentDocument.clauses.map((cla) => {
        return {
          ...cla,
          clause: {
            ...cla.clause,
            parts: partsClauses,
          },
        };
      }),
      name: newNameDocument || "Borrador sin nombre",
    };
    const autoDraftRequest = await autoSaveDraft({
      documentContent: newDocument,
      draftId: currentDocument.idDraft,
      versionFrom: "CONTRACT",
      fileType: currentDocument.fileType._id,
      ...(selectedHeader && { letterheadAssociated: selectedHeader }),
    });
    if (
      autoDraftRequest.data.success &&
      autoDraftRequest.status === CODES.COD_RESPONSE_HTTP_OK
    ) {
      setLastSave(
        moment(autoDraftRequest.data.responseMessage.data.updatedAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )
      );
      setTimeout(() => {
        setCountInterval((prev) => prev + 1);
      }, 60000);
    } else if (
      autoDraftRequest.data.responseCode ===
      CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
    ) {
      setIsOpenModalLimitPlan(true);
    } else {
      alert("Algo salio mal");
    }
  };

  const handleEditClause = (id, clause) => {
    if (id === undefined) {
      id = "UsedThisContract";
    }
    dispatch(setClauseSelected(clause));
    navigate(`edit-clause/${id}`);
  };

  const handleSaveNewVersion = async () => {
    const corporateUnit = localStorage.getItem("corporateUnitId");
    try {
      setIsLoading(true);
      const file = await wordCreatorFile({
        title: currentDocument.title,
        headers: currentDocument.headers,
        parts: currentDocument.parts,
        clauses: currentDocument.clauses,
      });
      if (saveType === "word") {
        const saveRequest = await createNewVersion({
          name: currentDocument.name,
          fileType: codesDocumentType.word._id,
          documentContent: { name: currentDocument.name },
          file,
          versionFrom: "CONTRACT",
          corporateUnit,
          ...(selectedHeader && { letterheadAssociated: selectedHeader }),
        });
        if (
          saveRequest.data.success &&
          saveRequest.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          setFolderSelectedModal(false);

          setIsLoading(true);
        } else {
          alert("Algo salio mal");
        }
      } else if (saveType === "legal") {
        const parts = buildPartsDocument(currentDocument);
        const partsClauses = buildPartsClauses(currentDocument);

        const newDocument = {
          ...currentDocument,
          parts,
          clauses: currentDocument.clauses.map((cla, index) => {
            return {
              ...cla,
              ...cla.clause,
              parts: partsClauses,
            };
          }),
        };
        const saveRequest = currentDocument.versionAssociated
          ? await addVersion({
              _id: currentDocument.versionAssociated,
              name: currentDocument.name,
              fileType: codesDocumentType.contract._id,
              documentContent: {
                ...newDocument,
                fileType: codesDocumentType.contract._id,
              },
              file,
              corporateUnit,
              versionFrom: "CONTRACT",
              ...(selectedHeader && { letterheadAssociated: selectedHeader }),
            })
          : await createNewVersion({
              name: currentDocument.name,
              fileType: codesDocumentType.contract._id,
              documentContent: {
                ...newDocument,
                fileType: codesDocumentType.contract._id,
              },
              file,
              versionFrom: "CONTRACT",
              corporateUnit,
              ...(selectedHeader && { letterheadAssociated: selectedHeader }),
            });
        if (
          saveRequest.data.success &&
          saveRequest.status === CODES.COD_RESPONSE_HTTP_OK
        ) {
          if (selectedHeader) {
            const obj = {
              documentDocxTemplate: selectedHeaderObject.documentDocxId._id,
              documentPdfTemplate: selectedHeaderObject.documentPdfId._id,
              documentContrat: saveRequest.data.responseMessage._id,
            };

            const applyLetterheadRequest = await ApplyHeadingToDocument(obj);
            if (
              applyLetterheadRequest.data.success &&
              applyLetterheadRequest.status === CODES.COD_RESPONSE_HTTP_OK
            ) {
              const draftDelete = await deleteVersion(
                firstDraftSave || currentDocument.idDraft
              );
              if (
                draftDelete.data.success &&
                (draftDelete.status === CODES.COD_RESPONSE_HTTP_OK ||
                  draftDelete.status === CODES.COD_RESPONSE_HTTP_CREATED)
              ) {
                setFolderSelectedModal(false);
                setIsLoading(false);
                navigate("/service/folders");
                handleCleanStore();
              } else {
                alert("Algo salio mal");
              }
            } else {
              alert("Algo salio mal");
            }
          } else {
            const draftDelete = await deleteVersion(
              firstDraftSave || currentDocument.idDraft
            );
            if (
              draftDelete.data.success &&
              (draftDelete.status === CODES.COD_RESPONSE_HTTP_OK ||
                draftDelete.status === CODES.COD_RESPONSE_HTTP_CREATED)
            ) {
              setFolderSelectedModal(false);
              setIsLoading(false);
              navigate("/service/folders");
              handleCleanStore();
            } else {
              alert("Algo salio mal");
            }
          }
        } else {
          alert("Algo salio mal");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateDiffParts = (currentParts, newParts) => {
    const isDiff = newParts.some(
      (part, index) => part !== currentParts[index]?.name
    );

    return !!isDiff;
  };

  const handleUpdatePartsDocument = () => {
    if (currentDocument?.parts?.length < 2) return;
    for (const { clause } of currentDocument.clauses) {
      for (const [index, part] of clause.parts.entries()) {
        const isDiff = validateDiffParts(currentDocument.parts, clause.parts);
        if (index <= currentDocument.parts.length && isDiff) {
          dispatch(
            setNamePartClause({
              name: currentDocument.parts[index]?.name || part,
              clauseId: clause._id,
              index,
            })
          );
        }
      }
    }
  };

  const handleSwitchParts = async (clause) => {
    dispatch(swapParts({ clauseId: clause._id }));
  };

  const handleFirstDraftSave = async () => {
    const parts = buildPartsDocument(currentDocument);
    const partsClauses = buildPartsClauses(currentDocument);
    const newDocument = {
      ...currentDocument,
      parts,
      clauses: currentDocument.clauses.map((cla, index) => {
        return {
          ...cla,
          clause: {
            ...cla.clause,
            parts: partsClauses,
          },
        };
      }),
      name: "Borrador sin nombre",
    };
    if (!currentDocument.idDraft) {
      const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
      const draftRequest = await createDraft({
        name: "Borrador sin nombre",
        documentContent: newDocument,
        folderAssociated: undefined,
        versionAssociated: newDocument.versionAssociated,
        versionFrom: "CONTRACT",
        corporateUnit: corporateUnit._id,
        modifiedBy: userId,
        ...(selectedHeader && { letterheadAssociated: selectedHeader }),
      });

      if (draftRequest) {
        if (
          draftRequest?.data?.responseCode === CODES.COD_RESPONSE_HTTP_OK &&
          draftRequest?.data?.success
        ) {
          setFirstDraftSave(draftRequest.data.responseMessage.data._id);
          dispatch(
            setIdDraft({ id: draftRequest.data.responseMessage.data._id })
          );
        } else if (
          draftRequest.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setIsOpenModalLimitPlan(true);
        } else {
          setResponseDataModalInfo(draftRequest);
          setIsOpenModalInfoSaveDocument(true);
        }
      }
    }
    setInitAutoSave(true);
  };

  const handleClickExternalSignatory = (event) => {
    setAnchorElSignatory(event.currentTarget);
  };
  //Effects
  useEffect(() => {
    validateBlankspaces();
    setFilteredClauses(currentDocument.clauses);
    handleFirstDraftSave();
    state?.newIdDraft && setFirstDraftSave(state.newIdDraft);
    setSaveType("legal");
    setCheckPartsGrammar(partsCorrection);
  }, []);

  useEffect(() => {
    dispatch(setSelectedHeaderId(currentDocument?.letterheadAssociated?._id));
    dispatch(setSelectedHeader(currentDocument?.letterheadAssociated));
    setFilteredClauses(currentDocument.clauses);
    handleUpdatePartsDocument();
    handleCountGeneralFillBlankspaces();
  }, [currentDocument]);

  useEffect(() => {
    if (!checkPartsGrammar) {
      let newClauses = cleanCheckPartClause(currentDocument);

      dispatch(setSelectedClausesWithoutCheckPart({ clauses: newClauses }));
    } else {
      let newClauses = checkPartClause(currentDocument);

      dispatch(setSelectedClauses({ clauses: newClauses }));
    }
  }, [checkPartsGrammar]);

  useEffect(() => {
    const handleIntervalAutoSave = async () => {
      if (initAutoSave) {
        const parts = buildPartsDocument(currentDocument);
        const partsClauses = buildPartsClauses(currentDocument);
        const newDocument = {
          ...currentDocument,
          parts,
          clauses: currentDocument.clauses.map((cla, index) => {
            return {
              ...cla,
              clause: {
                ...cla.clause,
                parts: partsClauses,
              },
            };
          }),
        };
        const autoDraftRequest = await autoSaveDraft({
          documentContent: {
            ...newDocument,
            name: newNameDocument || "Borrador sin nombre",
          },
          draftId: currentDocument.idDraft,
          versionFrom: "CONTRACT",
          ...(selectedHeader && { letterheadAssociated: selectedHeader }),
          fileType: currentDocument.fileType._id,
          ...(selectedHeader && { letterheadAssociated: selectedHeader }),
        });
        if (
          autoDraftRequest.data.success &&
          autoDraftRequest.status === CODES.COD_RESPONSE_HTTP_OK
        ) {
          setLastSave(
            moment(autoDraftRequest.data.responseMessage.data.updatedAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
          );
          setCountInterval((prev) => prev + 1);
        } else if (
          autoDraftRequest.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setIsOpenModalLimitPlan(true);
        } else {
          setResponseDataModalInfo(autoDraftRequest);
          setIsOpenModalInfoSaveDocument(true);
        }
      }
    };
    handleIntervalAutoSave();
  }, [initAutoSave]);

  useEffect(() => {
    if (countInterval > 0) {
      handleAutoDraftRequest();
    }
  }, [countInterval]);

  useEffect(() => {
    const onunload = (e) => {
      e.preventDefault();
      e.returnValue = "hola";
    };
    window.addEventListener("beforeunload", onunload);
    return () => {
      window.removeEventListener("beforeunload", onunload);
    };
  }, []);

  useEffect(() => {
    return () => {
      const idIntervalSave = localStorage.getItem("timerSave");
      clearInterval(idIntervalSave);
    };
  }, []);

  const handlePreviewPdf = async () => {
    try {
      setIsLoading(true);
      let fileWord = await WordBlobDocument({
        title: currentDocument.title,
        headers: currentDocument.headers,
        parts: currentDocument.parts,
        clauses: currentDocument.clauses,
        partHeader: currentDocument.partsHeader,
        numberingType: numeration,
        otherSignatories: currentDocument.otherSignatories,
      });
      const response = await convertToPdfService(fileWord);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        const dataFile = response.data.responseMessage?.data;
        if (!selectedHeader) {
          const file = URL.createObjectURL(
            new Blob([new Uint8Array(dataFile?.buffer?.data).buffer], {
              type: "application/pdf",
            })
          );
          window.open(file);
        } else {
          const docView = response.data.responseMessage.data.buffer.data;

          const bf = Buffer.from(docView);
          fileWord = new File([bf], currentDocument.title + ".pdf", {
            type: "application/pdf",
          });

          const obj = {
            file: fileWord,
            ...(selectedHeaderObject.documentDocxId && {
              documentDocxTemplate: selectedHeaderObject.documentDocxId._id,
            }),
            documentPdfTemplate: selectedHeaderObject.documentPdfId._id,
            originalname: currentDocument.title + ".pdf",
            contentType: "application/pdf",
          };

          const responseHeading = await previewHeadingDocument(obj);
          if (
            responseHeading.status === CODES.COD_RESPONSE_HTTP_OK &&
            responseHeading.data.success
          ) {
            handleDownloadDocument(
              responseHeading.data.responseMessage.data,
              false
            );
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountGeneralFillBlankspaces = () => {
    if (currentDocument) {
      let count = 0;
      let total = 0;

      currentDocument.clauses.forEach((clause) => {
        const fillBlankSpaces = getFillBlankSpaces(clause.clause);

        const allBlankSpaces = getAllBlankSpaces(clause.clause);

        count += fillBlankSpaces;
        total += allBlankSpaces;
      });

      setCountGeneralBlankspaces({
        countBlank: count,
        totalBlank: total,
      });
    }
  };

  const handleAddPartToDocument = () => {
    const index = currentDocument.parts.length;
    if (index < 4) {
      const value = `Sin título ${index + 1}`;
      dispatch(
        addEditedPart({
          index,
          editedPart: { name: value, text: "", signatories: [] },
        })
      );
      // setEditTitlePart({ index, isEdit: true, value });
    }
  };

  const isValidSaveVersion = () => {
    const isMissingSignatories = currentDocument.parts.some(
      (par) => !par?.signatories?.length
    );

    return !!(missingBlankspaces.length > 0 || isMissingSignatories);
  };

  const isValidSaveVersionParts = () => {
    if (checkPartsGrammar) {
      let errorCounter = 0;
      for (const { clause } of currentDocument.clauses) {
        errorCounter += clause.partErrors;
      }
      return errorCounter > 0;
    } else {
      return false;
    }
  };
  const getTotalBlankSpacesToFill = () => {
    let counter = 0;
    for (const cla of currentDocument.clauses) {
      const diff =
        getAllBlankSpaces(cla.clause) - getFillBlankSpaces(cla.clause);
      counter += diff;
    }
    return counter;
  };

  const handleChangeEnumeration = (event) => {
    const { value } = event.target;
    setNumeration(value);
  };

  const handleApplyPersonalization = () => {
    navigate("select-headings");
  };

  const handleSaveAsDraft = async () => {
    try {
      setDraftModal(false);
      setIsLoading(true);
      const parts = buildPartsDocument(currentDocument);
      const partsClauses = buildPartsClauses(currentDocument);
      const newDocument = {
        ...currentDocument,
        parts,
        clauses: currentDocument.clauses.map((cla, index) => {
          return {
            ...cla,
            clause: {
              ...cla.clause,
              parts: partsClauses,
            },
          };
        }),
        name: "Borrador sin nombre",
      };
      const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
      const draftRequest = await createDraft({
        name: "Borrador sin nombre",
        documentContent: newDocument,
        folderAssociated: undefined,
        versionAssociated: newDocument.versionAssociated,
        versionFrom: "CONTRACT",
        corporateUnit: corporateUnit._id,
        modifiedBy: userId,
        ...(selectedHeader && { letterheadAssociated: selectedHeader }),
      });
      if (
        draftRequest?.data?.responseCode === CODES.COD_RESPONSE_HTTP_OK &&
        draftRequest?.data?.success
      ) {
        setResponseSaveAsDraft({
          status: 200,
          data: {
            message: `El documento ha quedado guardado en la carpeta "Borradores"`,
          },
        });
        setOpenModalSaveAsDraft(true);
      } else if (
        draftRequest.status === CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
      ) {
        setResponseDataModalInfo(draftRequest);
        setIsOpenModalInfoSaveDocument(true);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);
  const handleDownloadDocument = async (actualDocument, isWord) => {
    try {
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const finalFile = URL.createObjectURL(
        new Blob([bf], {
          type: "application/pdf",
        })
      );
      window.open(finalFile);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeGrammarCheck = (event) => {
    dispatch(setPartsCorrection(event.target.checked));
    setCheckPartsGrammar(event.target.checked);
  };

  const handleReferenceNormative = async () => {
    try {
      setIsLoading(true);

      const updateClauses = await GetReferenceNormative(
        currentDocument.clauses
      );
      dispatch(setSelectedClauses({ clauses: updateClauses }));
    } catch (error) {
      console.log("=======Start Handle Reference Normative=======");
      console.error(error);
      console.log("=======End Handle Reference Normative=======");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Datos del documento" />
        </Col>

        <Col md={9}></Col>
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col md={3}>
          <InputGroup bsPrefix="input-group-container">
            <Form.Control
              bsPrefix="custom-input"
              placeholder="Buscar"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
            <InputGroup.Text bsPrefix="container-icon">
              <SearchIcon fontSize="large" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col />
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<PolicyOutlinedIcon />}
            className="custom-input__button__iaColor"
            size="medium"
            onClick={() => {
              Mixpanel.track("Edtiar Btn Identificar ref. Normativas ", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
              handleReferenceNormative();
            }}
          >
            Identificar ref. normativas
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<SubjectOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={handleClick}
          >
            Antecedentes o consideraciones
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                handleCloseSubMenu("con");
              }}
            >
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Con numeraciones
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseSubMenu("sin");
              }}
            >
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontSize: "1.4rem",
                  },
                }}
              >
                Sin numeraciones
              </ListItemText>
            </MenuItem>
          </Menu>
        </Col>
        <Col md={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            size="medium"
            onClick={() => {
              navigate("create-clause", {
                state: {
                  from: "create",
                },
              });
            }}
          >
            Crear cláusula
          </Button>
        </Col>
        <Col xs={"auto"}>
          <ImportClause />
        </Col>
      </Row>
      <Row>
        <Col md={4} style={{ display: "flex", alignItems: "center" }}>
          {isEditTitle ? (
            <Form.Group
              style={{ padding: "0%", margin: "2% 0" }}
              onBlur={() => {
                setIsEditTitle(false);
              }}
            >
              <Form.Control
                autoFocus={true}
                placeholder={"Escriba aqui"}
                bsPrefix={"input-group-container__no-icon label"}
                value={currentDocument.title}
                onChange={(e) => {
                  dispatch(setTitle({ title: e.target.value }));
                }}
                maxLength={250}
              />
            </Form.Group>
          ) : (
            <p className="heading word-break__break-all">
              {currentDocument.title}
            </p>
          )}
          <IconButton
            onClick={() => {
              setIsEditTitle(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Col>
        <Col
          md={3}
          className="caption"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isStoragePlanLimit ? (
            <IconNoSaveInfo />
          ) : (
            <label className="caption">
              Último guardado fue: <b>{lastSave}</b>
            </label>
          )}
        </Col>
        <Col
          md={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Row>
            <Col xs={12} className="d-flex gap-2 align-items-center">
              <p className="caption">Tipo de enumeración:</p>
              <Form.Select
                className="label mb-1"
                size="lg"
                bsPrefix={"input-group-container__no-icon label"}
                defaultValue={numeration}
                style={{ width: "50%" }}
                onChange={(event) => {
                  handleChangeEnumeration(event);
                }}
              >
                <option className="label" value="" disabled>
                  Enumeración
                </option>
                <option className="label" value="number">
                  Por número
                </option>
                <option className="label" value="letter">
                  Por letras
                </option>
              </Form.Select>
            </Col>

            <Col xs={12} className="d-flex gap-3 align-items-center">
              <p className="caption">Revisión Gramatical:</p>
              <p className="body-content">Desactivada</p>
              <FormControlLabel
                control={
                  <IOSSwitch checked={checkPartsGrammar} sx={{ m: 1 }} />
                }
                onChange={handleChangeGrammarCheck}
                label=""
              />
              <p className="body-content">Activada</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            flexDirection: "column",
            marginTop: "2%",
            marginBottom: "0.5%",
          }}
        >
          <p className="crud-titles__titles-contract">PARTES</p>
          <DragDropContextParts partsList={currentDocument.parts} />
        </Row>
        <Row style={{ padding: "0 1%" }}>
          {currentDocument.parts.length < 4 && (
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<GroupsOutlinedIcon />}
                className="custom-input__button__primary-color"
                size="medium"
                onClick={handleAddPartToDocument}
              >
                Agregar rol contractual
              </Button>
            </Col>
          )}
        </Row>
        <Divider />
        {currentDocument?.headers?.length > 0 && (
          <>
            <Row
              style={{
                width: "100%",
                padding: "0 1%",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              <p
                className="crud-titles__titles-contract"
                style={{ marginBottom: "1%" }}
              >
                ANTECEDENTES Y CONSIDERACIONES
              </p>
              <DragDropContext
                onDragEnd={(result) => {
                  dispatch(reorderHeader({ result }));
                }}
              >
                <Droppable droppableId="headers">
                  {(droppableProvided) => (
                    <Col
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {currentDocument?.headers &&
                        currentDocument.headers.map((enca, index) => (
                          <Draggable
                            key={
                              enca._id
                                ? enca._id
                                : "draggable" + enca.title + index
                            }
                            draggableId={
                              enca._id
                                ? enca._id
                                : "draggable" + enca.title + index
                            }
                            index={index}
                          >
                            {(draggablePorvider) => (
                              <Accordion
                                {...draggablePorvider.draggableProps}
                                {...draggablePorvider.dragHandleProps}
                                className="custom-accordion"
                                ref={draggablePorvider.innerRef}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Row style={{ width: "100%" }}>
                                    <Col md={"auto"} className="col-start">
                                      <DragIndicatorIcon
                                        fontSize="large"
                                        className="drag-color"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <p className="heading__primary-color">{`Encabezado ${
                                        index + 1
                                      }: ${ReactHtmlParser(enca.title)}`}</p>
                                    </Col>
                                  </Row>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Container>
                                    <Row
                                      className="caption"
                                      style={{ margin: "1% 0" }}
                                    >
                                      {ReactHtmlParser(enca.text)}
                                    </Row>
                                    <Row style={{ paddingLeft: "2%" }}>
                                      {enca.childs.map((sig, index2) => (
                                        <div key={sig?._id}>
                                          <p className="heading__primary-color">{`Numeral ${
                                            index2 + 1
                                          }`}</p>
                                          <span className="caption">
                                            {ReactHtmlParser(sig.text)}
                                          </span>
                                        </div>
                                      ))}
                                    </Row>
                                    <Row className="caption">
                                      {enca.textFinal}
                                    </Row>
                                    <Row style={{ justifyContent: "right" }}>
                                      <Col md={3}>
                                        <Button
                                          variant="contained"
                                          startIcon={<EditIcon />}
                                          className="custom-input__button__secondary-color"
                                          onClick={() => {
                                            navigate(`edit-header/${index}`);
                                          }}
                                        >
                                          Editar encabezado
                                        </Button>
                                      </Col>
                                      <Col md={2}>
                                        <Button
                                          variant="contained"
                                          startIcon={<DeleteIcon />}
                                          className="custom-input__button__primary-color"
                                          onClick={() => {
                                            dispatch(
                                              deleteHeader({ index: index })
                                            );
                                          }}
                                        >
                                          <p className="button">Eliminar</p>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Container>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </Draggable>
                        ))}
                      {droppableProvided.placeholder}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>
            <Divider />
          </>
        )}
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            marginTop: "1%",
            marginBottom: "0.5%",
          }}
        >
          <p className="crud-titles__titles-contract">CLÁUSULAS</p>
          <Row className="align-items-center" style={{ justifyContent: "end" }}>
            {countGeneralBlankspaces.totalBlank > 0 && (
              <>
                <Col xs={3} className="text-right">
                  <span className="subheading__primary-active">{`Campos totales por rellenar: ${countGeneralBlankspaces.countBlank}/${countGeneralBlankspaces.totalBlank} `}</span>
                </Col>
                <Col xs={2}>
                  <Button
                    variant="contained"
                    startIcon={<DescriptionOutlinedIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      // clearInterval(idInterval);
                      navigate(`blankspaces/`);
                    }}
                  >
                    Rellenar todos
                  </Button>
                </Col>
              </>
            )}

            {countGeneralBlankspaces.totalBlank === 0 && (
              <>
                <Col xs={3} className="text-right">
                  <span className="subheading__error-color">{`No tienes espacios en blanco en este documento`}</span>
                </Col>
              </>
            )}
          </Row>
          <DragDropContext
            onDragEnd={(result) => {
              dispatch(reorderClause({ result }));
            }}
          >
            <Droppable droppableId="clauses">
              {(droppableProvided) => (
                <Col
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {filteredClauses?.length > 0 &&
                    filteredClauses.map((cla, index) => {
                      return (
                        <Draggable
                          key={
                            cla.clause._id
                              ? cla.clause._id
                              : "draggable" + index
                          }
                          draggableId={
                            cla.clause._id
                              ? cla.clause._id
                              : "draggable" + index
                          }
                          index={index}
                        >
                          {(draggableProvider) => (
                            <Accordion
                              {...draggableProvider.draggableProps}
                              {...draggableProvider.dragHandleProps}
                              className="custom-accordion"
                              ref={draggableProvider.innerRef}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Row style={{ width: "100%" }}>
                                  <Col md={"auto"} className="col-start">
                                    <DragIndicatorIcon
                                      fontSize="large"
                                      className="drag-color"
                                    />
                                  </Col>
                                  <Col md={7}>
                                    <p className="heading__primary-color">
                                      {`${
                                        numeration === "number"
                                          ? index + 1
                                          : "Cláusula " +
                                            NUMBERINGWORDSA[index + 1]
                                      }. ${cla.clause.title}`}
                                    </p>
                                  </Col>
                                  <Col md={4}>
                                    <Row>
                                      <Col xs={6}>
                                        {" "}
                                        {getAllBlankSpaces(cla.clause) > 0 && (
                                          <p className="subheading__primary-active">
                                            {`Campos por rellenar ${getFillBlankSpaces(
                                              cla.clause
                                            )}/${getAllBlankSpaces(
                                              cla.clause
                                            )}`}
                                          </p>
                                        )}
                                      </Col>
                                      <Col xs={6}>
                                        {" "}
                                        {cla.clause.partErrors > 0 && (
                                          <p className="subheading__error-color ">
                                            {`Errores de partes: ${cla.clause.partErrors}`}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Row style={{ margin: "2% 0" }}>
                                  <Col className="subheading">
                                    <b>Categorías:</b>{" "}
                                    {cla?.clause?.categories &&
                                      cla.clause.categories.map((cat, i) => {
                                        return (
                                          <span
                                            key={cat?._id}
                                            style={{ marginRight: "1%" }}
                                            className="caption custom-badges__secundary"
                                          >
                                            {cat.name}
                                          </span>
                                        );
                                      })}
                                    <b>Etiquetas:</b>{" "}
                                    {cla?.clause?.labels &&
                                      cla.clause.labels.map((tag, i) => {
                                        return (
                                          <span
                                            key={tag?._id}
                                            style={{ marginRight: "1%" }}
                                            className="caption custom-badges__disabled"
                                          >
                                            {tag.name}
                                          </span>
                                        );
                                      })}
                                  </Col>
                                </Row>
                                <Row
                                  style={{ margin: "2% 0", display: "flex" }}
                                >
                                  <p
                                    className="subheading"
                                    style={{ display: "flex", maxWidth: "50%" }}
                                  >
                                    <b>Nombre de las partes:</b>{" "}
                                    {`${cla?.clause?.parts
                                      ?.map((part, index) => {
                                        return index ===
                                          cla.clause.parts.length - 1
                                          ? part
                                          : `${part}/`;
                                      })
                                      .join("")}`}
                                  </p>
                                  {currentDocument?.parts?.length === 2 && (
                                    <Col>
                                      <Button
                                        variant="text"
                                        size="medium"
                                        className="custom-input__button__link"
                                        onClick={() => {
                                          handleSwitchParts(cla?.clause);
                                        }}
                                      >
                                        Click aquí para intercambiar partes
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                                <Row style={{ margin: "1% 0" }}>
                                  <p className="subheading">
                                    <b>A favor de :</b>{" "}
                                    {`${cla.clause.inFavor}`}
                                  </p>
                                </Row>
                                <Row style={{ paddingLeft: "1.3%" }}>
                                  <span className="caption">
                                    {ReactHtmlParser(cla.clause.text)}
                                    {cla?.clause?.subclauses &&
                                      cla.clause.subclauses.map((sub, i) => {
                                        return (
                                          <Row
                                            key={sub?._id}
                                            className="justify-text clauses-number"
                                            style={{ margin: "1% 0" }}
                                          >
                                            <Col xs={"auto"}>
                                              <b>
                                                {`${index + 1}.${i + 1} `}
                                                {sub?.subclause?.title &&
                                                  `${sub?.subclause?.title}: `}
                                              </b>
                                            </Col>
                                            <Col>
                                              <span className="caption">
                                                {ReactHtmlParser(
                                                  sub?.subclause?.text
                                                )}
                                              </span>
                                            </Col>
                                            <Container>
                                              {sub?.subclause?.childs &&
                                                sub.subclause.childs.map(
                                                  (child, index2) => {
                                                    return (
                                                      <Row
                                                        key={child?.title}
                                                        className="justify-text clauses-number"
                                                        style={{
                                                          paddingLeft: "2rem",
                                                          margin: "1% 0",
                                                        }}
                                                      >
                                                        <Col xs={"auto"}>
                                                          <b>
                                                            {`${index + 1}.${
                                                              i + 1
                                                            }.${index2 + 1} `}
                                                            {child?.title}
                                                          </b>
                                                        </Col>
                                                        <Col>
                                                          <span className="caption">
                                                            {ReactHtmlParser(
                                                              child?.text
                                                            )}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    );
                                                  }
                                                )}
                                            </Container>
                                          </Row>
                                        );
                                      })}
                                    <Container
                                      fluid
                                      style={{ paddingLeft: "1%" }}
                                    >
                                      {cla?.clause?.paragraphs &&
                                        cla.clause.paragraphs.map(
                                          (paragraph, index3) => {
                                            return (
                                              <Row
                                                key={paragraph?.paragraph?.text}
                                                className="justify-text mb-3"
                                              >
                                                <Col xs={"auto"}>
                                                  <b>{`Parágrafo ${
                                                    NUMBERINGWORDSO[index3 + 1]
                                                  }`}</b>
                                                </Col>
                                                <Col xs={"auto"}>
                                                  {ReactHtmlParser(
                                                    paragraph?.paragraph?.text
                                                  )}
                                                </Col>
                                              </Row>
                                            );
                                          }
                                        )}
                                    </Container>
                                  </span>
                                </Row>
                                <Row
                                  style={{
                                    justifyContent: "right",
                                    margin: "2% 0",
                                  }}
                                >
                                  <Col md={3}>
                                    {getAllBlankSpaces(cla.clause) > 0 && (
                                      <Button
                                        variant="contained"
                                        startIcon={<DescriptionOutlinedIcon />}
                                        className="custom-input__button__secondary-color"
                                        onClick={() => {
                                          navigate(`blankspaces/${index}`);
                                        }}
                                      >
                                        Rellenar espacios
                                      </Button>
                                    )}
                                  </Col>
                                  <Col md={3}>
                                    <Button
                                      variant="contained"
                                      startIcon={<EditIcon />}
                                      className="custom-input__button__secondary-color"
                                      onClick={() =>
                                        handleEditClause(
                                          cla.clause._id,
                                          cla.clause
                                        )
                                      }
                                    >
                                      Editar cláusula
                                    </Button>
                                  </Col>
                                  <Col md={2}>
                                    <Button
                                      variant="contained"
                                      startIcon={<DeleteIcon />}
                                      className="custom-input__button__primary-color"
                                      onClick={() => {
                                        dispatch(
                                          deleteClause({ index: index })
                                        );
                                      }}
                                    >
                                      <p className="button">Eliminar</p>
                                    </Button>
                                  </Col>
                                </Row>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Draggable>
                      );
                    })}
                  {droppableProvided.placeholder}
                </Col>
              )}
            </Droppable>
          </DragDropContext>
        </Row>
        <Divider />
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            marginTop: "1%",
            marginBottom: "0.5%",
          }}
        >
          <p className="crud-titles__titles-contract">FIRMAS</p>
          <Row>
            <Col md={"auto"}>
              <Button
                variant="contained"
                startIcon={<PersonAddAlt1Icon />}
                endIcon={<KeyboardArrowDownIcon />}
                className="custom-input__button__primary-color"
                onClick={handleClickExternalSignatory}
              >
                Agregar tercero como firmante
              </Button>

              <Menu
                anchorEl={anchorElSignatory}
                open={openSignatory}
                onClose={() => {
                  setAnchorElSignatory(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() =>
                    navigate(
                      `without-part/${currentDocument.otherSignatories.length}/create-signatory`
                    )
                  }
                >
                  <ListItemIcon>
                    <PostAddOutlined
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "1.4rem",
                      },
                    }}
                  >
                    Desde cero
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(
                      `select-signatory/${currentDocument.otherSignatories.length}/without-part`
                    );
                  }}
                >
                  <ListItemIcon>
                    <LocalLibraryOutlinedIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "1.4rem",
                      },
                    }}
                  >
                    Desde biblioteca
                  </ListItemText>
                </MenuItem>
              </Menu>
            </Col>
          </Row>
          <DragDropContextSignatories partsList={currentDocument?.parts} />
        </Row>
        <Row
          style={{
            width: "100%",
            padding: "0 1%",
            marginBottom: "0.5%",
            paddingBottom: "3rem",
          }}
        >
          <DragDropContext
            onDragEnd={(result) => {
              dispatch(reorderSignatoriesWithoutParts({ result }));
            }}
          >
            <Droppable droppableId="signatures-without-part">
              {(droppableProvided) => (
                <Col
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {currentDocument?.otherSignatories &&
                    currentDocument?.otherSignatories.map((par, index) => (
                      <Draggable
                        key={"draggable-without-part" + par.name}
                        draggableId={"draggable-without-part" + par.name}
                        index={index}
                      >
                        {(draggablePorvider) => (
                          <Accordion
                            {...draggablePorvider.draggableProps}
                            {...draggablePorvider.dragHandleProps}
                            className="custom-accordion"
                            ref={draggablePorvider.innerRef}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Row style={{ width: "100%" }}>
                                <Col md={"auto"} className="col-start">
                                  <DragIndicatorIcon
                                    fontSize="large"
                                    className="drag-color"
                                  />
                                </Col>
                                <Col md={4}>
                                  <p className="heading__primary-color">
                                    {`Firma ${par.name}`}
                                  </p>
                                </Col>
                              </Row>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Container>
                                <ul>
                                  {par?.signatories?.length > 0 ? (
                                    par.signatories.map(
                                      (sig, indexSignatory) => (
                                        <li
                                          className="caption__strong"
                                          key={sig?.information?.name}
                                          style={{ listStyle: "none" }}
                                        >
                                          <Row
                                            style={{
                                              alignItems: "center",
                                              backgroundColor: "#E6E9EF",
                                              padding: "2rem 2rem",
                                            }}
                                          >
                                            <Col>
                                              {sig.type ===
                                                "Persona natural" && (
                                                <>
                                                  <p className="caption">{` ${
                                                    sig?.information?.name ||
                                                    "¬Nombre¬"
                                                  }`}</p>
                                                  <p className="caption">{` ${
                                                    sig?.information
                                                      ?.documentType ||
                                                    "¬Tipo de documento¬"
                                                  }, ${
                                                    sig?.information
                                                      ?.documentNumber ||
                                                    "¬número de documento¬"
                                                  }`}</p>
                                                </>
                                              )}
                                              {sig.type ===
                                                "Persona jurídica" && (
                                                <>
                                                  <p className="caption">{` ${
                                                    sig?.signatory?.name ||
                                                    "¬Representante legal¬"
                                                  }`}</p>
                                                  {/* <p className="caption">{` ${sig?.information?.charge || "¬Cargo¬"}`}</p> */}
                                                  <p className="caption">{` ${
                                                    sig?.information?.name ||
                                                    "¬razón social¬"
                                                  }`}</p>
                                                </>
                                              )}
                                            </Col>
                                            <Col xs="auto">
                                              <IconButton
                                                className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                                                sx={{
                                                  borderRadius: "8px",
                                                  color: "#FFFFFF",
                                                }}
                                                onClick={() => {
                                                  navigate(
                                                    `without-part/${index}/edit-signatory/${indexSignatory}`
                                                  );
                                                }}
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Col>
                                            <Col xs="auto">
                                              <IconButton
                                                className="custom-input__button__primary-color__forced custom-input__data-table-icon"
                                                sx={{
                                                  borderRadius: "8px",
                                                  color: "#FFFFFF",
                                                }}
                                                onClick={() => {
                                                  dispatch(
                                                    deleteSignatoryWithoutPart({
                                                      partIndex: index,
                                                    })
                                                  );
                                                }}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Col>
                                          </Row>
                                        </li>
                                      )
                                    )
                                  ) : (
                                    <li className="caption">Sin firmas</li>
                                  )}
                                </ul>
                              </Container>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Draggable>
                    ))}
                  {droppableProvided.placeholder}
                </Col>
              )}
            </Droppable>
          </DragDropContext>
        </Row>
        <br />
      </div>
      <Row className="sidebar__bottom__container">
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<PaletteOutlined />}
            className="custom-input__button__secondary-color"
            onClick={handleApplyPersonalization}
          >
            {selectedHeader ? "Cambiar Membrete" : "Aplicar membretes"}
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<VisibilityIcon />}
            className="custom-input__button__secondary-color"
            onClick={handlePreviewPdf}
          >
            Vista Previa
          </Button>
        </Col>
        <Col xs={"auto"}>
          <WordCreator
            title={currentDocument.title}
            headers={currentDocument.headers}
            parts={currentDocument.parts}
            partHeader={currentDocument.partsHeader}
            clauses={currentDocument.clauses}
            numberingType={numeration}
            otherSignatories={currentDocument.otherSignatories}
            message={"Descargar word"}
            selectedHeader={selectedHeaderObject}
            setIsLoading={setIsLoading}
          />
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            className="custom-input__button__secondary-color"
            onClick={() => {
              dispatch(cleanStore());
              navigate("/service/folders");
            }}
          >
            Descartar
          </Button>
        </Col>

        <Col xs={"auto"}>
          {/* {currentDocument.fileType._id === codesDocumentType.contract._id && ( */}
          <Button
            variant="contained"
            startIcon={<PlaylistAddIcon />}
            className="custom-input__button__primary-color"
            onClick={() => {
              if (isStoragePlanLimit) return;
              setSaveType("draft");
              setDraftModal(true);
            }}
            disabled={isStoragePlanLimit}
          >
            Guardar como borrador
          </Button>
          {/* )} */}
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<ArticleIcon />}
            className="custom-input__button__primary-color"
            onClick={() => {
              if (isStoragePlanLimit) return;
              const isNotValidVersion = isValidSaveVersion();
              const isValidSaveParts = isValidSaveVersionParts();
              if (isValidSaveParts) {
                setPartsErrorsModal(true);
                return;
              } else if (isNotValidVersion) {
                setBlankSpacesModal(true);
                return;
              } else if (
                currentDocument.fileType._id === codesDocumentType.contract._id
              ) {
                setSaveAsModalVersion(true);
                return;
              }
              setSaveAsModal(true);
            }}
            disabled={isStoragePlanLimit}
          >
            Guardar versión
          </Button>
        </Col>
      </Row>
      <ModalPDFView
        title="Preview"
        open={openPreview}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setOpenPreview(false);
        }}
        handleDisagree={() => {
          setOpenPreview(false);
        }}
        onClose={() => {
          setOpenPreview(false);
        }}
        content={<p className="subheading">En progreso</p>}
      />
      <ModalDecision
        title={"Documento incompleto"}
        message={
          <Container fluid className="caption">
            <Row>Tu contrato parece incompleto, encontramos que:</Row>
            <br />
            <Row style={{ paddingLeft: "5%" }}>
              <ul>
                {currentDocument.parts.some(
                  (par) => !par?.signatories?.length
                ) && <li>Falta información de las partes.</li>}
                {getTotalBlankSpacesToFill() > 0 && (
                  <li>{`Hay ${getTotalBlankSpacesToFill()} espacios en blanco sin rellenar.`}</li>
                )}
              </ul>
            </Row>
            <Row> ¿Seguro quieres continuar con el guardado?</Row>
          </Container>
        }
        open={blankSpacesModal}
        onClose={setBlankSpacesModal}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          if (currentDocument.versionAssociated) {
            setSaveAsModalVersion(true);
          } else {
            setSaveAsModal(true);
          }
          setBlankSpacesModal(false);
        }}
        handleDisagree={() => setBlankSpacesModal(false)}
      />
      <ModalDecision
        title={"Guardar versión"}
        message={"¿Está seguro?"}
        open={saveAsModalVersion}
        onClose={setSaveAsModalVersion}
        agreeText="Guardar"
        disagreeText="Volver"
        handleAgree={() => {
          setSaveAsModalVersion(false);
          handleSaveNewVersion();
        }}
        handleDisagree={() => {
          setSaveAsModalVersion(false);
        }}
      />
      <ModalDecision
        title={"Guardar borrador"}
        message={
          "A continuación por favor seleccione una carpeta para guardar el documento."
        }
        open={folderSelectedModal}
        onClose={setFolderSelectedModal}
        agreeText="Seleccionar carpeta"
        disagreeText="Cancelar"
        handleAgree={() => {
          dispatch(setName({ name: newNameDocument }));
          navigate(`${saveType}/folder/select-folder`);
        }}
        handleDisagree={() => {
          setFolderSelectedModal(false);
        }}
      />
      <ModalDecision
        title={"Guardar como"}
        message={
          <Container fluid className="caption">
            <Row>Por favor escriba un nombre para este nuevo archivo:</Row>
            <Row>
              <Form.Group style={{ padding: "0%", margin: "2% 0" }}>
                <Form.Control
                  {...register("name", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio.",
                    },
                  })}
                  placeholder={"Escriba aqui"}
                  bsPrefix={
                    errors.name
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  onChange={(e) => {
                    setNewNameDocument(e.target.value);
                  }}
                />
                <div className="caption custom-input__error">
                  {errors.name?.message}
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Select
                {...register("saveType", {
                  required: {
                    value: true.valueOf,
                    message: "*Este campo es obligatorio.",
                  },
                })}
                className="label mb-1"
                size="lg"
                bsPrefix={
                  errors.saveType
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                defaultValue=""
                onChange={(e) => {
                  setSaveType(e.target.value);
                }}
              >
                <option className="label" value="" disabled>
                  Guardar documento como
                </option>
                <option className="label" value="legal">
                  Guardar en Legal AI como documento Legal AI
                </option>
                <option className="label" value="word">
                  Guardar en Legal AI como Word
                </option>
              </Form.Select>
              <div className="caption custom-input__error">
                {errors.saveType?.message}
              </div>
            </Row>
          </Container>
        }
        open={saveAsModal}
        onClose={setSaveAsModal}
        agreeText="Guardar"
        disagreeText="Volver"
        handleAgree={handleSubmit(() => {
          if (saveType) {
            setFolderSelectedModal(true);
            setSaveAsModal(false);
          }
        })}
        handleDisagree={() => {
          setSaveAsModal(false);
        }}
      />
      <ModalInfo
        title={"Sin permisos suficientes"}
        responseData={responseDataModalInfo}
        onClose={() => {
          setIsOpenModalInfoSaveDocument(false);
          navigate(-1);
        }}
        open={isOpenModalInfoSaveDocument}
        confirmationText={"Aceptar"}
      />
      <ModalDecision
        title={"Guardar borrador"}
        message={`Escoge la carpeta en la que quieras guardar el documento o has clic en "guardar" para que quede en la carpeta de "Borradores".`}
        open={draftModal}
        onClose={() => {
          setDraftModal(false);
        }}
        agreeText="Seleccionar carpeta"
        disagreeText="Guardar"
        handleAgree={() => {
          dispatch(setName({ name: "Borrador sin nombre" }));
          navigate(`draft/folder/select-folder`);
        }}
        handleDisagree={() => {
          handleSaveAsDraft();
        }}
      />
      <ModalInfo
        open={openModalSaveAsDraft}
        onClose={() => navigate("/service/folders")}
        responseData={responseSaveAsDraft}
        title={"Guardado exitoso"}
        confirmationText="Aceptar"
        handleCloseDefault={() => setOpenModalSaveAsDraft(false)}
        buttonClose={true}
      />
      <ModalDecision
        title={"Alerta de no guardado"}
        message={
          "En el momento no está disponible la funcionalidad de guardado ni autoguardado, porque la compañía ha alcanzado el máximo de almacenamiento de su plan. Comunícate con el usuario administrador para adaptar el plan. ¿Deseas continuar de todas formas?"
        }
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        handleDisagree={() => {
          navigate(-1);
        }}
        handleAgree={() => {
          setIsOpenModalLimitPlan(false);
        }}
        onClose={() => {
          setIsOpenModalLimitPlan(false);
        }}
        open={isOpenModalLimitPlan}
        type={"warning"}
      />{" "}
      <ModalDecision
        title={"Agregar firmantes"}
        message={"¿Cómo quieres agregar tus firmantes?"}
        open={openModalSelectSignatory}
        onClose={setOpenModalSelectSignatory}
        agreeText="Desde Biblioteca"
        agreeIcon={<LocalLibraryOutlinedIcon />}
        disagreeIcon={<AddIcon />}
        disagreeText="Desde Cero"
        handleAgree={() => {}}
        handleDisagree={() => {}}
      />
      <ModalDecision
        title={"Errores gramaticales encontrados"}
        message={
          <Container fluid className="caption">
            <Row>
              Hemos detectado algunas inconsistencias gramaticales sin corregir,
              ¿seguro quieres continuar?
            </Row>
            <br />
          </Container>
        }
        open={partsErrorsModal}
        onClose={() => setPartsErrorsModal(false)}
        agreeText="Guardar sin corregir"
        disagreeText="Cancelar"
        handleAgree={() => {
          setPartsErrorsModal(false);
          if (!isValidSaveVersion) {
            setSaveAsModal(true);
          } else {
            setBlankSpacesModal(true);
          }
        }}
        handleDisagree={() => {
          setPartsErrorsModal(false);
        }}
      />
    </Container>
  );
};

export default GeneralEditDocument;
