import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ModalUploadDocs from "../../../../../../../components/Modals/ModalUploadDocs";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  createPart,
  getPartInfoFromFile,
} from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import LoadingContent from "../../../../../../../components/loadingContent";
import { CODES } from "../../../../../../../utils/codes";
import { useNavigate } from "react-router-dom";
import {
  resetPartListStatus,
  resetSignatoriesListStatus,
} from "../../../../../../../parts/parts/partsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../../admin/modules/unitCorporative/unitCorporativeSlice";
import EllipsisComponent from "../../../../../../../components/Text/EllipsisComponent";
import { v4 as uuidv4 } from "uuid";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import { PERSON_TYPE, TYPE_CHARGES } from "../utils";
import { IOSSwitch } from "../../../../../admin/modules/rulesReview/rulesReview";
export const CreatePartCertificate = () => {
  //States
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseDataInfo, setResponseDataInfo] = useState({});
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [validateCerlDate, setValidateCerlDate] = useState(false);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const [fieldSuggestions, setFieldSuggestions] = useState([]);
  //redux
  const dispatch = useDispatch();
  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);
  //Handles

  //Router
  const navigate = useNavigate();

  const handleCloseModalDecision = () => {
    setOpenModalDecision(false);
  };

  const handleAcceptChanges = async () => {
    setOpenModalDecision(false);
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const corporateUnitDefault =
        listUnitsCorporatives.find((uc) => uc?.isDefault)?._id || "";
      let identificationData = {};

      identificationData = {
        business: {
          businessName: fieldSuggestions?.business.value || "",
          nit: fieldSuggestions?.documentNumberLegal.value || "",
          type: fieldSuggestions?.documentTypeLegal.value || "",
          checkDigit: "",
        },
        legalRepresentative: {
          documentType: fieldSuggestions?.documentType.value || "",
          documentNumber: fieldSuggestions?.documentNumber.value || "",
          firstName: fieldSuggestions?.firstName.value || "",
          firstSurname: fieldSuggestions?.firstSurname.value || "",
          secondName: fieldSuggestions?.lastName.value || "",
          secondSurname: fieldSuggestions?.secondSurname.value || "",
          limitations:
            fieldSuggestions?.limitationsLegalRepresentative.value || "",
        },
        comercialName: fieldSuggestions?.comercialName.value || "",
        charge: TYPE_CHARGES?.LEGAL,
      };

      const contactInfo = {
        email: fieldSuggestions?.email.value || "",
        city: fieldSuggestions?.city.value || "",
        province: fieldSuggestions?.province.value || "",
        physicalAddress: fieldSuggestions?.address.value || "",
      };
      const obj = {
        ...contactInfo,
        ...identificationData,
        createdBy: userId,
        company,
        typePart: PERSON_TYPE?.LEGAL,
        relatedSignatories: [],
        labels: [],
        ...(corporateUnitDefault && {
          associatedCorporateUnits: [corporateUnitDefault],
        }),
        ...(fieldSuggestions?.bucketName && {
          bucketName: fieldSuggestions?.bucketName,
        }),
        ...(fieldSuggestions?.cerlFileName && {
          cerlFileName: fieldSuggestions?.cerlFileName,
        }),
        ...(fieldSuggestions?.filenameInBucket && {
          filenameInBucket: fieldSuggestions?.filenameInBucket,
        }),
      };
      const response = await createPart(obj);
      setResponseDataInfo(response);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadDocToVerify = async () => {
    const company = localStorage.getItem("company");
    const createdBy = JSON.parse(localStorage.getItem("payloadToken")).userId;
    const obj = {
      file: documentData,
      company,
      createdBy,
      validateCerlDate,
    };

    setIsLoading(true);
    const service = await getPartInfoFromFile(obj);
    if (
      service?.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      service?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      let newFileInfoResponse = service?.data?.responseMessage?.data;
      setFieldSuggestions(
        newFileInfoResponse ? JSON.parse(newFileInfoResponse) : {}
      );

      setOpenModalDecision(true);
    } else {
      setResponseDataInfo(service);
      setIsOpenModalInfo(true);
    }
    setDocumentData({});

    setIsLoading(false);
  };

  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
    if (responseDataInfo.status === CODES.COD_RESPONSE_HTTP_CREATED) {
      setDocumentData({});

      navigate(-1);
      dispatch(resetPartListStatus());
      dispatch(resetSignatoriesListStatus());
    }
  };
  //useEffect
  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
  }, [statusFetchUnits]);
  return (
    <Container fluid className="h-100">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"} className="mt-4">
        <Col xs={3}>
          <SectionTitle title={"Crear parte desde certificado"} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <p className="caption">
            Cargue el Certificado de Existencia y Representación Legal de la
            parte
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <p className="caption">Recuerda cargar un archivo en formato PDF</p>
        </Col>
      </Row>

      <Row className="mb-3 align-items-center">
        <Col xs={"auto"}>
          <span className="caption">
            ¿Quieres que el sistema compruebe que el Certificado de Existencia y
            Representación Legal haya sido expedido en los últimos 30 días?
          </span>
        </Col>
        <Col xs={"auto"}>
          <span className="caption">No</span>
        </Col>

        <Col xs={"auto"}>
          <IOSSwitch
            onChange={(event) => {
              setValidateCerlDate(event.target.checked);
            }}
            sx={{ m: 1 }}
            colorbase={"#00374F"}
            value={validateCerlDate}
          />
        </Col>
        <Col xs={"auto"}>
          <span className="caption">Si</span>
        </Col>
      </Row>

      {documentData?.name && (
        <Row className="mt-3">
          <Col
            xs={5}
            style={{
              color: "#00374F",
              margin: "1rem 0rem",
              padding: "2rem",
              boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px",
              alignItems: "center",
            }}
          >
            <Row>
              <Col xs={12}>
                <p className="heading__primary-color">Documento</p>
              </Col>
              <Col xs={12}>
                <p className="caption__disabled"> {documentData.name}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col xs={3}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<AddIcon fontSize="large" />}
            onClick={() => {
              setOpenModalUploadDocument(true);
            }}
          >
            Cargar Documento
          </Button>
        </Col>
      </Row>

      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            type="button"
            className={
              documentData?.name
                ? "custom-input__button__primary-color"
                : "custom-input__button__disabled"
            }
            disabled={!documentData?.name}
            startIcon={<SaveOutlinedIcon fontSize="large" />}
            onClick={() => {
              handleUploadDocToVerify();
            }}
          >
            Guardar
          </Button>
        </Col>
      </Row>
      <ModalUploadDocs
        open={openModalUploadDocument}
        title={"Selecciona el documento para subir"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF, pesar máximo 5mb y tener una vigencia máxima de 30 días."
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setOpenModalUploadDocument(false);
        }}
        typeDocument={"ONLY_PDF"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf"
        maxFileSize={5242880}
      />
      <ModalInfo
        title={
          responseDataInfo.status === CODES.COD_RESPONSE_HTTP_CREATED
            ? "Creación de partes exitosa"
            : "Atención"
        }
        responseData={responseDataInfo}
        onClose={handleCloseModalInfo}
        open={isOpenModalInfo}
        buttonClose={true}
      />

      <ModalDecision
        title={"Atención"}
        message={
          <Row>
            <Col xs={12} className="mt-2">
              <p className="caption">
                La información que te presentamos es obtenida mediante
                Inteligencia Artificial, por esta razón es importante que
                revises detalladamente los resultados presentados:
              </p>
            </Col>
            <Col xs={12} className="mt-2">
              <ol>
                {Object.entries(fieldSuggestions).map(([key, value]) => {
                  if (key.includes("title")) {
                    return (
                      <p key={uuidv4()} className="subheading">
                        {`${value.name}:`}
                      </p>
                    );
                  }
                  if (value.value) {
                    if (key === "limitationsLegalRepresentative") {
                      return (
                        <li key={uuidv4()}>
                          {`${value.name}:`}
                          <EllipsisComponent
                            text={value.value}
                            isWhite={true}
                            isHtml={true}
                          />
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={uuidv4()}
                        >{`${value.name}: ${value.value}`}</li>
                      );
                    }
                  }
                })}
              </ol>
            </Col>
            <Col xs={12} className="mt-2">
              <p className="caption">¿Es correcta la información obtenida?</p>
            </Col>
          </Row>
        }
        open={openModalDecision}
        onClose={handleCloseModalDecision}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAcceptChanges}
        handleDisagree={handleCloseModalDecision}
      />
    </Container>
  );
};
