/**
 * @class PartsDtoFactory
 * @classdesc Class factory to create dto objects for parts and signatories
 */
export class PartsDtoFactory {
  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateCreateContactDto
   * @description Function to generate the object to create a contact
   * @param {string} countryCode country code of the contact is required
   * @param {string} cityCode city code of the contact is optional
   * @param {string} number number of the contact is optional
   * @param {string} extension extension of the contact is optional
   * @returns {Object} object to create a contact
   */
  static generateCreateContactDto(
    countryCode,
    cityCode = undefined,
    phone = undefined,
    telephone = undefined,
    extension = undefined
  ) {
    return {
      countryCode,
      cityCode,
      phone,
      telephone,
      extension,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateCreateRelatedPartDto
   * @description Function to generate the object to create a related part
   * @param {string} part part of the related part is required, must be a valid mongo id
   * @param {string} position position of the related part is optional
   * @returns {Object} object to create a related part
   */
  static generateCreateRelatedPartDto(part, position = undefined) {
    return {
      position,
      part,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateCreateResidenceDto
   * @description Function to generate the object to create a residence
   * @param {string} country country of the residence is required
   * @param {string} province province of the residence is required
   * @param {string} city city of the residence is required
   * @param {string} physicalAddress physical address of the residence is required
   * @returns {Object} object to create a residence
   */
  static generateCreateResidenceDto(country, province, city, physicalAddress) {
    return {
      country,
      province,
      city,
      physicalAddress,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateCreateSignatoryDto
   * @description Function to generate the object to create a signatory
   * @param {string} firstName first name of the signatory is required
   * @param {string} firstSurname first surname of the signatory is required
   * @param {string} documentType document type of the signatory is required
   * @param {string} documentNumber document number of the signatory is required
   * @param {Object} mobileNumberData mobile number of the signatory is required
   * @param {Object} telephoneNumberData telephone number of the signatory is required
   * @param {Object} residence residence of the signatory is required
   * @param {string} company company of the signatory is required, must be a valid mongo id
   * @param {string} lastName last name of the signatory is optional
   * @param {string} secondSurname second surname of the signatory is optional
   * @param {string} otherTypeDocument other type document of the signatory is optional
   * @param {string} email email of the signatory is optional
   * @param {string[]} relatedParties related parties of the signatory is optional, must be a array of valid mongo ids
   * @param {string[]} associatedCorporateUnits associated corporate units of the signatory is optional, must be a array of valid mongo ids
   * @param {string} createdBy created by of the signatory is optional, must be a valid mongo id
   * @returns {Object} object to create a signatory
   */
  static generateCreateSignatoryDto(
    firstName,
    firstSurname,
    documentType,
    documentNumber,
    mobileNumberData,
    telephoneNumberData,
    residence,
    company,
    lastName = undefined,
    secondSurname = undefined,
    otherTypeDocument = undefined,
    email = undefined,
    relatedParties = undefined,
    associatedCorporateUnits = undefined,
    createdBy = undefined
  ) {
    return {
      firstName,
      firstSurname,
      documentType,
      documentNumber,
      mobileNumberData,
      telephoneNumberData,
      residence,
      company,
      secondName: lastName,
      secondSurname,
      otherTypeDocument,
      email,
      relatedParties,
      associatedCorporateUnits,
      createdBy,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateUpdateSignatoryDto
   * @description Function to generate the object to update a signatory
   * @param {Object} createSignatoryDtoData object to create a signatory is required
   * @param {string} signatoryId signatory id of the signatory is required, must be a valid mongo id
   * @param {string} status status of the signatory is optional
   * @param {string} modifiedBy modified by of the signatory is optional, must be a valid mongo id
   * @returns {Object} object to update a signatory
   */
  static generateUpdateSignatoryDto(
    createSignatoryDtoData,
    signatoryId,
    status = undefined,
    modifiedBy = undefined
  ) {
    return {
      ...createSignatoryDtoData,
      signatoryId,
      status,
      modifiedBy,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateManagePartToSignatoryDto
   * @description Function to generate the object to manage signatory to part
   * @constructor
   * @param {string} [signatory] signatory of the manage part to signatory is not required, must be a valid mongo id
   * @param {string} [part] part of the manage part to signatory is not required, must be a valid mongo id
   * @param {string} [position] position of the manage part to signatory is not required
   * @returns {Object} object to manage signatory to part
   */
  static generateManagePartToSignatoryDto(
    signatory = undefined,
    part = undefined,
    position = undefined
  ) {
    return {
      signatory,
      part,
      position,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateManageSignatoryToPartsDto
   * @description Function to generate the object to manage signatory to parts
   * @constructor
   * @param {[ManagePartToSignatoryDto]} [ManageSignatoryToParts] manage part to signatories of the manage part to signatories is not required, must be a array of ManagePartToSignatoryDto
   * @param {string} [action] action of the manage part to signatories is not required, must be a value of ['ADD', 'DELETE', 'UPDATE'] in const ACTIONS
   * @returns {Object} object to manage signatory to parts
   */
  static generateManageSignatoryToPartsDto(
    manageSignatoryToParts = undefined,
    action = undefined
  ) {
    return {
      manageSignatoryToParts,
      action,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateManagePartToSignatoriesDto
   * @description Function to generate the object to manage signatory to parts
   * @constructor
   * @param {[ManagePartToSignatoryDto]} [managePartToSignatories] manage part to signatories of the manage part to signatories is not required, must be a array of ManagePartToSignatoryDto
   * @param {string} [action] action of the manage part to signatories is not required, must be a value of ['ADD', 'DELETE', 'UPDATE'] in const ACTIONS
   * @returns {Object} object to manage signatory to parts
   */
  static generateManagePartToSignatoriesDto(
    managePartToSignatories = undefined,
    action = undefined
  ) {
    return {
      managePartToSignatories,
      action,
    };
  }

  /**
   * @static
   * @memberof PartsDtoFactory
   * @function generateManageSignatoriesPartsRelationDto
   * @description Function to generate the object to manage signatory to parts
   * @constructor
   * @param {[ManageSignatoryToPartsDto]} [signatoriesForPart] signatories for part of the manage part to signatories is not required, must be a array of ManageSignatoryToPartsDto
   * @param {[ManagePartToSignatoryDto]} [partsForSignatory] parts for signatory of the manage part to signatories is not required, must be a array of ManagePartToSignatoryDto
   * @returns {Object} object to manage signatory to parts
   */
  static generateManageSignatoriesPartsRelationDto(
    signatoriesForPart = undefined,
    partsForSignatory = undefined
  ) {
    return {
      signatoriesForPart,
      partsForSignatory,
    };
  }
}
