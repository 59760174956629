import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Checkbox } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSearchFilter from "../../../../../../components/Search/CustomSearchFilter";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  fetchTagsDocumentTempletes,
  getTagDocumentTempleteStatus,
  getTagDocumentTempletesList,
} from "../../../../../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
import {
  fetchOtherDocumentTemplates,
  getOtherDocumentTemplates,
  getTempleteStatus,
} from "../../library/documentTemplates/templeteSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { v4 as uuidv4 } from "uuid";
import { handlePreviewTemplate } from "./utils";
import { MDBDataTableV5 } from "mdbreact";
import { resetOtherDocumentInfo } from "../../../../../../parts/document/otherDocumentSlice";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
const SelectTemplaterOtherDocument = () => {
  //Table states
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Plantilla
        </label>,
      ],

      field: "templateName",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Etiquetas
        </label>,
      ],

      field: "labels",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Acciones
        </label>,
      ],

      field: "actions",
    },
  ];

  const rows = [];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filterTagTemplete, setFilterTagTemplete] = useState([]);

  //Router
  const navigate = useNavigate();

  //Redux

  const dispatch = useDispatch();
  const statusTemplete = useSelector(getTempleteStatus);
  const templeteList = useSelector(getOtherDocumentTemplates);
  const tagTempleteList = useSelector(getTagDocumentTempletesList);
  const statusTagsDocumentTempletes = useSelector(getTagDocumentTempleteStatus);

  //States
  const [dataTemplete, setDataTemplete] = useState([]);
  const [filterDataTemplete, setFilterDataTemplete] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [tagTemplete, setTagTemplete] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [selectedTemplatesList, setSelectedTemplateList] = useState([]);
  //Use effects

  useEffect(() => {
    const templeteData = async () => {
      try {
        if (statusTemplete === "fetch") {
          dispatch(fetchOtherDocumentTemplates());
        }
        if (statusTemplete === "loading") {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    templeteData();
  }, [statusTemplete, dispatch]);

  useEffect(() => {
    setDataTemplete(templeteList);
    setFilterDataTemplete(templeteList);
  }, [templeteList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsDocumentTempletes === "fetch") {
          dispatch(fetchTagsDocumentTempletes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsDocumentTempletes, dispatch]);

  useEffect(() => {
    setTagTemplete(tagTempleteList);
  }, [tagTempleteList]);

  useEffect(() => {
    dispatch(resetOtherDocumentInfo());
  }, []);

  // Functions

  const handleTagsSelected = (tags) => {
    setFilterTagTemplete(tags);
  };

  const handleDeleteTag = (tag) => {
    setFilterTagTemplete(filterTagTemplete.filter((t) => t !== tag));
    const newTags = filterTagTemplete.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredTags = [];
      let filterTags = [];
      dataTable.rows.forEach((item) => {
        console.log(item);
        const rawLabels = item.labels;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredTags.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredTags });
    } else {
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  const buildRows = (rows) => {
    if (typeof rows !== "string") {
      const returnRows = rows.map((row) => {
        return {
          templateName: (
            <Row style={{ width: "25rem" }}>
              <Col xs={12}>{row.name}</Col>
            </Row>
          ),
          labels: (
            <Row style={{ width: "30rem" }}>
              {row.labels.map((label) => label.name).join(", ")}
            </Row>
          ),
          actions: row.company && (
            <Row>
              <Col xs="auto">
                <Button
                  variant="contained"
                  startIcon={<VisibilityOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={async () => {
                    try {
                      setIsLoading(true);
                      await handlePreviewTemplate(row);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  <p className="button">Previsualizar</p>
                </Button>
              </Col>

              <Col xs="auto">
                <Button
                  className="button-primary-folders__select"
                  onClick={() => {
                    navigate("./../create", {
                      state: row,
                    });
                  }}
                  startIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
                >
                  Seleccionar
                </Button>
              </Col>
            </Row>
          ),
        };
      });
      return returnRows;
    }
    return [];
  };

  //Use effects

  useEffect(() => {
    const formatRows = buildRows(templeteList);
    setFilteredDataTable({
      columns: columns,
      rows: formatRows,
    });
    setDataTable({
      columns: columns,
      rows: formatRows,
    });
  }, [templeteList]);

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container"
    >
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Biblioteca de plantillas"} />
      </Row>
      <br />
      <Row>
        <p className="caption">
          Busca y selecciona la plantilla que quieres utilizar para tu nuevo
          documento.
        </p>
      </Row>
      <br />

      <br />
      <br />
      <Row style={{ alignItems: "center" }}>
        <Col xs={12} lg={5}>
          <CustomSearch
            placeholder="Buscar plantilla"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col />

        <Col xs={"auto"}>
          <FilterTag
            classStyle="custom-input__button__secondary-color"
            tagsList={tagTempleteList}
            list={dataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ justifyContent: "end" }}>
        {filterTagTemplete.map((tag) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {tag || ""}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right" }}></Row>
      <br />

      <Row sm={"auto"} style={{ justifyContent: "right" }}></Row>
      <br />
      <Row className="dt-list-center-content">
        <MDBDataTableV5
          noRecordsFoundLabel="No se encontraron resultados"
          hover
          pagingTop
          searchBottom={false}
          entries={7}
          data={filteredDataTable}
          infoLabel={["Mostrando", "a", "de", "plantillas"]}
          fullPagination
        />
      </Row>
    </Container>
  );
};

export default SelectTemplaterOtherDocument;
