import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";
import { STATES_PREDETERMINATED_TEXT } from "../predeterminatedTexts/enums.predeterminatedText";

export function getDocumentTemplates(data) {
  return axios({
    method: "GET",
    url: "otherDocumentTemplates/all",
    baseURL: env.REACT_APP_API_URL,
    params: {
      corporateUnit: data.corporateUnit,
      company: data.company,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateOtherDocumentTemplete(data) {
  return axios({
    method: "PUT",
    url: "otherDocumentTemplates/one",
    baseURL: env.REACT_APP_API_URL,
    data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function createOtherDocumentTemplete(data) {
  return axios({
    method: "POST",
    url: "otherDocumentTemplates/create",
    baseURL: env.REACT_APP_API_URL,
    data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function findTemplateById({ otherDocumentTemplateId }) {
  return axios({
    method: "GET",
    url: `otherDocumentTemplates/one`,
    params: { otherDocumentTemplateId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteOtherDocumentTemplete(data) {
  return axios({
    method: "PUT",
    url: "otherDocumentTemplates/updateStatus",
    baseURL: env.REACT_APP_API_URL,
    data: {
      company: data?.company,
      corporateUnit: data?.corporateUnit,
      deletedBy: data?.userId,
      otherDocumentTemplateId: data?.id,
      status: STATES_PREDETERMINATED_TEXT.DELETED,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function restoreTempleteDocument(obj) {
  return axios({
    method: "PUT",
    url: `otherDocumentTemplates/updateStatus`,
    data: {
      company: obj.company,
      corporateUnit: obj.corporateUnit,
      otherDocumentTemplateId: obj.id,
      status: STATES_PREDETERMINATED_TEXT.ACTIVE,
      deletedBy: null
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deletePermanentTemplete(obj) {
  return axios({
    method: "DELETE",
    url: `otherDocumentTemplates/delete`,
    params: {
      otherDocumentTemplateId: obj.id,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getAllDeleteTempleteDocument(obj) {
  return axios({
    method: "GET",
    url: `otherDocumentTemplates/all`,
    params: {
      company: obj.company,
      corporateUnit: obj.corporateUnit,
      status: STATES_PREDETERMINATED_TEXT.DELETED,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
