import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BillingAdmin = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Modulo facturación</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default BillingAdmin;
