const exceptions = [
  "demandante",
  "fideicomitente",
  "promitente",
  "cedente",
  "cotizante",
  "permutante",
  "remitente",
  "depositante",
  "accionante",
  "comerciante",
  "constituyente",
  "aspirante",
  "requirente",
  "solicitante",
  "convocante",
  "postulante",
  "causante",
  "apelante",
  "asambleísta",
  "manifestante",
  "protestante",
  "dependiente",
  "donante",
  "denunciante",
  "aportante",
  "mandante",
  "poderdante",
  "cliente",
  "escribiente",
  "practicante",
  "querellante",
  "demandantes",
  "fideicomitentes",
  "promitentes",
  "cedentes",
  "cotizantes",
  "permutantes",
  "remitentes",
  "depositantes",
  "accionantes",
  "comerciantes",
  "constituyentes",
  "aspirantes",
  "requirentes",
  "solicitantes",
  "convocantes",
  "postulantes",
  "causantes",
  "apelantes",
  "asambleístas",
  "manifestantes",
  "protestantes",
  "dependientes",
  "donantes",
  "denunciantes",
  "aportantes",
  "mandantes",
  "poderdantes",
  "clientes",
  "escribientes",
  "practicantes",
  "querellantes",
  "contratista",
  "contratistas",
  "contratante",
  "contratantes",
];

export function verificarArticulo(oracion) {
  let palabras = tokenizeWord(oracion);

  const articulo = palabras.find((palabra) =>
    [
      "el",
      "la",
      "los",
      "las",
      "al",
      "del",
      "un",
      "una",
      "unos",
      "unas",
    ].includes(palabra.toLowerCase())
  );

  if (articulo) {
    const sustantivo = palabras.find(
      (palabra) =>
        ![
          "el",
          "la",
          "los",
          "las",
          "al",
          "del",
          "un",
          "una",
          "unos",
          "unas",
        ].includes(palabra.toLowerCase())
    );
    if (!sustantivo) {
      return false; // No se encontró sustantivo
    }

    const reglasGramaticales = {
      el: { genero: "masculino", numero: "singular" },
      la: { genero: "femenino", numero: "singular" },
      los: { genero: "masculino", numero: "plural" },
      las: { genero: "femenino", numero: "plural" },
      al: { genero: "masculino", numero: "singular" },
      un: { genero: "masculino", numero: "singular" },
      una: { genero: "femenino", numero: "singular" },
      unos: { genero: "masculino", numero: "plural" },
      unas: { genero: "femenino", numero: "plural" },
      del: { genero: "masculino", numero: "singular" },
      // Agregar más reglas según sea necesario
    };

    if (exceptions.includes(sustantivo.toLowerCase())) {
      const numeroSustantivoExcepciones = getNumeroSustantivo(sustantivo);

      if (reglasGramaticales[articulo.toLowerCase()]) {
        const reglasArticulo = reglasGramaticales[articulo.toLowerCase()];

        if (reglasArticulo.numero === numeroSustantivoExcepciones) {
          return true;
        } else {
          return false;
        }
      }
    }

    // Aquí implementa la lógica para determinar el género del sustantivo

    const generoSustantivo = getGeneroSustantivo(sustantivo);
    const numeroSustantivo = getNumeroSustantivo(sustantivo);

    if (reglasGramaticales[articulo.toLowerCase()]) {
      const reglasArticulo = reglasGramaticales[articulo.toLowerCase()];

      if (
        reglasArticulo.genero === generoSustantivo &&
        reglasArticulo.numero === numeroSustantivo
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false; // Artículo no reconocido
    }
  } else {
    return false; // No se encontró artículo
  }
}

function getGeneroSustantivo(sustantivo) {
  if (
    (sustantivo.endsWith("a") && sustantivo !== "al") ||
    sustantivo.endsWith("as") ||
    sustantivo.toLowerCase().startsWith("parte")
  ) {
    return "femenino";
  } else {
    return "masculino";
  }
}

function getNumeroSustantivo(sustantivo) {
  // Aquí implementa la lógica para determinar el número del sustantivo (singular o plural)
  if (sustantivo.endsWith("s")) {
    return "plural";
  } else {
    return "singular";
  }
}

export function removeHtmlTags(inputHtml) {
  let div = document.createElement("div");
  div.innerHTML = inputHtml;
  return div.textContent || div.innerText || "";
}

export function tokenizeWord(oracion) {
  return oracion.split(/\s+/).filter((word) => word !== "");
}

export function findWordsWithCharacter(wordsArray, character) {
  const indices = [];

  wordsArray.forEach((word, index) => {
    if (word.includes(character)) {
      indices.push(index);
    }
  });

  return indices;
}

export function removeCharacterFromWord(word, charactersToRemove) {
  charactersToRemove.forEach((character) => {
    let escapedCharacter = character.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    let regex = new RegExp(escapedCharacter, "g");
    word = word.replace(regex, "");
  });
  return word;
}

export function checkText(oracion) {
  const removeChars = ["°", ".", ","];
  const plainText = removeHtmlTags(oracion.text);
  const arrayOfWord = tokenizeWord(plainText);
  let arrayOfIndex = findWordsWithCharacter(arrayOfWord, "°");

  let arrayOfErrors = [];

  for (let index of arrayOfIndex) {
    if (index == 0) {
      arrayOfErrors.push("Falta Articulo de la parte");
    } else {
      let word =
        arrayOfWord[index - 1] +
        " " +
        removeCharacterFromWord(arrayOfWord[index], removeChars);

      arrayOfErrors.push(verificarArticulo(word));
    }
  }

  return arrayOfErrors;
}

export function setPartsErrors(currentPart, errors) {
  if (errors.length > 0) {
    let newText = currentPart.text;

    for (let index = 0; index < errors.length; index++) {
      if (errors[index] !== true) {
        newText = addRedColorToNthSurroundedWord(newText, index + 1);
      }
    }

    return newText;
  } else {
    return currentPart.text;
  }
}

function addRedColorToNthSurroundedWord(htmlString, n) {
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  let spanElements = tempDiv.querySelectorAll("span[data-part]");
  let count = 0;

  for (const span of spanElements) {
    count++;

    if (count === n) {
      let previousNode = span.previousSibling;
      while (previousNode && previousNode.nodeType !== Node.TEXT_NODE) {
        previousNode = previousNode.previousSibling;
      }

      if (previousNode && previousNode.nodeType === Node.TEXT_NODE) {
        let previousText = previousNode.textContent.trim();
        let previousWords = previousText.split(/\s+/);
        let previousWord = previousWords.pop();

        // Create a new span element for the previous word with red color
        let previousWordSpan = document.createElement("span");
        previousWordSpan.style.color = "red";
        previousWordSpan.textContent = " " + previousWord + " ";

        // Combine the previous words with whitespace
        let previousWordsWithWhitespace = previousWords.join(" ");

        if (previousWordsWithWhitespace) {
          previousWordsWithWhitespace = " " + previousWordsWithWhitespace + " ";
        }

        // Replace the previous node with the new span and whitespace
        previousNode.textContent = previousWordsWithWhitespace;
        previousNode.parentNode.insertBefore(
          previousWordSpan,
          previousNode.nextSibling
        );
      }

      // Apply red color to the current span
      span.style.color = "red";
      break;
    }
  }

  return tempDiv.innerHTML;
}

export function countPartErrors(arrayOfErrors) {
  let count = 0;
  for (const item of arrayOfErrors) {
    if (item !== true) {
      count++;
    }
  }
  return count;
}

export function checkPartClause(currentDocument) {
  const currentClauses = currentDocument.clauses;
  const cleanClause = cleanText(currentClauses);
  let newClauses = cleanClause.map((clause) => {
    const partErrorsArray = [];
    let subclauses = [];
    let paragraphs = [];

    let errors = checkText(clause);
    let newTextClause = setPartsErrors(clause, errors);
    partErrorsArray.push(...errors);
    for (const subclause of clause.subclauses) {
      let errorsSubclause = checkText(subclause.subclause);
      partErrorsArray.push(...errorsSubclause);
      const newTextSubClause = setPartsErrors(
        subclause.subclause,
        errorsSubclause
      );

      let numerals = [];
      for (const numeral of subclause.subclause.childs) {
        let errorsNumeral = checkText(numeral);
        partErrorsArray.push(...errorsNumeral);
        const newTextNumeral = setPartsErrors(numeral, errorsNumeral);
        numerals.push({
          ...numeral,
          text: newTextNumeral,
        });
      }
      subclause.subclause.childs = numerals;
      subclauses.push({
        subclause: {
          ...subclause.subclause,
          text: newTextSubClause,
        },
      });
    }
    for (const paragraph of clause.paragraphs) {
      let errorsParagraph = checkText(paragraph.paragraph);
      partErrorsArray.push(...errorsParagraph);
      const newTextParagraph = setPartsErrors(
        paragraph.paragraph,
        errorsParagraph
      );
      paragraphs.push({
        paragraph: {
          ...paragraph.paragraph,
          text: newTextParagraph,
        },
      });
    }
    clause.partErrors = countPartErrors(partErrorsArray);
    return {
      clause: {
        ...clause,
        text: newTextClause,
        subclauses,
        paragraphs,
      },
    };
  });

  return newClauses;
}

const cleanText = (clauses) => {
  const currentClauses = clauses;
  let newClauses = currentClauses.map((clause) => {
    let subclauses = [];
    let paragraphs = [];
    const newTextClause = plainTextTagsLinks(clause.clause.text);
    for (const subclause of clause.clause.subclauses) {
      let numeralList = [];
      for (const numeral of subclause.subclause.childs) {
        numeralList.push({
          ...numeral,
          text: plainTextTagsLinks(numeral.text),
        });
      }
      subclauses.push({
        ...subclause,
        subclause: {
          ...subclause.subclause,
          text: plainTextTagsLinks(subclause.subclause.text),
          childs: numeralList,
        },
      });
    }
    for (const paragraph of clause.clause.paragraphs) {
      paragraphs.push({
        ...paragraph,
        paragraph: {
          ...paragraph.paragraph,
          text: plainTextTagsLinks(paragraph.paragraph.text),
        },
      });
    }
    return {
      ...clause.clause,
      text: newTextClause,
      subclauses,
      paragraphs,
    };
  });
  return newClauses;
};

//Plain text
export const plainTextTagsLinks = (text) => {
  return text
    .replace(/<style([\s\S]*?)<\/style>/gi, " ")
    .replace(/<script([\s\S]*?)<\/script>/gi, " ")
    .replace(/style\s*=\s*['"]color:\s*red;['"]/gi, "")
    .replace(/(<a(?:.|\n)*?>)/gm, " ")
    .replace(/(<\/a>)/gm, " ")
    .replace(/<span\b(?![^>]*class=")[^>]*>(.*?)<\/span>/gi, "$1")
    .replace(/\s+/gm, " ");
};

export function countPartsErrorsClauses(currentDocument) {
  const partErrorsArray = [];
  currentDocument.map((clause) => {
    let errors = checkText(clause);
    partErrorsArray.push(...errors);
    for (const { subclause } of clause.subclauses) {
      let errorsSubclause = checkText(subclause);
      partErrorsArray.push(...errorsSubclause);

      for (const numeral of subclause.childs) {
        let errorsNumeral = checkText(numeral);
        partErrorsArray.push(...errorsNumeral);
      }
    }
    for (const { paragraph } of clause.paragraphs) {
      let errorsParagraph = checkText(paragraph);
      partErrorsArray.push(...errorsParagraph);
    }
  });

  return countPartErrors(partErrorsArray);
}

export function cleanCheckPartClause(currentDocument) {
  const currentClauses = currentDocument.clauses;
  const cleanClause = cleanText(currentClauses);
  let newClauses = cleanClause.map((clause) => {
    clause.partErrors = [];
    return {
      clause: {
        ...clause,
      },
    };
  });

  return newClauses;
}
